import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchCustomerContract } from "../../domain/contracts/searchCustomerContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface ICustomerResponse {
  id: string;
  document: string;
  name: string;
}

export class SearchCustomerService implements ISearchCustomerContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCustomer({
    search = "",
    activesOnly,
    payloadOptions,
    companyGroupId,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      order: payloadOptions?.order || [{ column: 1, dir: "asc" }],
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "document",
          name: "document",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/Customers`;

    if (activesOnly) {
      url += `/Actives`;
    }

    const response = await this.api.get<
      IServerSideResponseModel<ICustomerResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(customer => {
        return new RelationshipFilterOption({
          label: customer.name,
          rawValue: customer.id,
          metadata: showMetadata ? customer : undefined,
        });
      }),
    });
  }
}
