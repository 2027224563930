import { IUserEntity } from "../entities/userEntity";

export interface ISetUserLocalUseCase {
  set(user: IUserEntity): void;
}

export interface ISetUserLocalService {
  set(user: IUserEntity): void;
}

export class SetUserLocalUseCase implements ISetUserLocalUseCase {
  constructor(private setUserLocalService: ISetUserLocalService) {}

  set(user: IUserEntity): void {
    this.setUserLocalService.set(user);
  }
}
