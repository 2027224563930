import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IStoreAccountsReceivablePanoamaIdContract } from "../../domain/contracts/storeAccountsReceivablePanoamaIdContract";

export class StoreAccountsReceivablePanoamaIdService
  implements IStoreAccountsReceivablePanoamaIdContract
{
  storeAccountsReceivablePanoamaId(panoramaId: string): void {
    const key = ELocalStorageKeys.AccountsReceivablePanoramaId;

    localStorage.setItem(key, Stringified.stringify(panoramaId) as string);
  }
}
