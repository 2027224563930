import { useRef, useState, useCallback, ChangeEvent } from "react";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { MakeImportationMatches } from "../../../main/makeImportationMatches";
import { useImportationMatchesPaymentAccountsGrid } from "../../hooks/useImportationMatchesPaymentAccountsGrid";
import { IImportationMatchesPaymentAccountEntity } from "../../../domain/entities/importationMatchesPaymentAccountEntitty";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { PaymentAccountBanksModal } from "../PaymentAccountsBanksModal";
import { EImportationMatchesOrigin } from "../../../domain/entities/importationMatchesPayloadEntity";

interface ImportationMatchesPaymentAccountsGridProps {
  origin: EImportationMatchesOrigin;
  useImportationMatches: MakeImportationMatches;
}

export function ImportationMatchesPaymentAccountsGrid(
  props: ImportationMatchesPaymentAccountsGridProps,
) {
  const { origin, useImportationMatches } = props;

  const { listImportationMatchesPaymentAccounts } = useImportationMatches;

  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** Referência da tabela */
  const table = useRef<ISimpleTableHandle>(null);

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Estado de carregamento da tabela */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da tabela */
  const [data, setData] =
    useState<IResponseEntity<IImportationMatchesPaymentAccountEntity[]>>();

  /** Armazena o ID da conta de pagamento selecionada. */
  const [currentPaymentAccount, setCurrentPaymentAccount] =
    useState<IImportationMatchesPaymentAccountEntity>();

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  /**
   * Lida com a abertura do modal de vínculo.
   */
  const openMatchModal = useCallback(
    (paymentAccount: IImportationMatchesPaymentAccountEntity) => {
      setCurrentPaymentAccount(paymentAccount);
    },
    [],
  );

  const { columns } = useImportationMatchesPaymentAccountsGrid({
    origin,
    openMatchModal,
  });

  /**
   * Realiza a requisição para buscar os dados da tabela. Lida com os eventos
   * monitorados pela SimpleTable.
   */
  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setIsLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listImportationMatchesPaymentAccounts(
          payload,
          currentCompanyGroup.id,
        );
        setData(response);
      } catch (err) {
        return;
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      mountedRef,
      generatePayload,
      currentCompanyGroup.id,
      listImportationMatchesPaymentAccounts,
    ],
  );

  const handleCloseModal = useCallback((shouldRefresh: boolean | unknown) => {
    if (typeof shouldRefresh === "boolean" && !!shouldRefresh) {
      table.current?.reload();
    }
    setCurrentPaymentAccount(undefined);
  }, []);

  return (
    <div>
      <div className="search-input">
        <InputSearch
          value={search}
          id="txt-search"
          data-testid="txt-search"
          onChange={handleSearchChange}
        />
      </div>
      <SimpleTable
        data={data}
        tableRef={table}
        columns={columns}
        loading={isLoading}
        getList={getList}
        globalFilter={globalFilter}
      />
      <PaymentAccountBanksModal
        origin={origin}
        onClose={handleCloseModal}
        isOpen={!!currentPaymentAccount}
        useImportationMatches={useImportationMatches}
        currentPaymentAccount={currentPaymentAccount}
      />
    </div>
  );
}
