import styled from "styled-components";

export const Container = styled.div`
  .transaction {
    padding: 1rem;
    border-radius: 12rem;
    display: flex;
    align-items: center;

    & + .transaction {
      margin-top: 1rem;
    }

    & + p {
      margin-top: 1rem;
    }

    div {
      flex: 1 0 0;
      color: white;

      &.value {
        font-size: 1.2rem;
        font-weight: 700;
      }

      &:not(.value) {
        font-size: 0.95rem;
      }
    }

    &.expense {
      background-color: var(--soul-red-button);
    }

    &.revenue {
      background-color: var(--soul-green-button);
    }
  }
`;
