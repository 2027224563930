import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetProviderRemittanceTemplateContract } from "../../domain/contracts/getProviderRemittanceTemplateContract";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";

export class GetProviderRemittanceTemplateService
  implements IGetProviderRemittanceTemplateContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getProviderRemittanceTemplate() {
    const url = `/Remittances/${ERemittanceBankCode.Santander}/${ERemittanceType.Provider}/Template`;
    const userEntity = this.getUserLocalService.get();

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
