import styled from "styled-components";

export const Container = styled.div`
  .column-filter-header {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0.6rem 0;

    .remove-button {
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 600;
      color: var(--soul-red-button);

      span {
        margin-left: 0.5em;
      }
    }
  }

  .column-filter-body {
    padding: 0.6rem;
  }

  .column-filter-footer {
    display: flex;
    justify-content: stretch;

    .clear-button,
    .filter-button {
      flex-grow: 1;
      padding: 0.25rem 0.5rem;
      border: none;
      color: white;
      justify-content: center;
      border-radius: 0;
    }

    .clear-button {
      background: var(--soul-red-button);
      border-bottom-left-radius: 0.375rem;
    }

    .filter-button {
      background: var(--soul-green-button);
      border-bottom-right-radius: 0.375rem;
    }
  }
`;
