import { DataTableDataSelectableParams } from "primereact/datatable";
import { AdvTable } from "../../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../../advTable/presentation/components/ColumnSelector";
import {
  EAccountPayableStatus,
  IAccountPayableListItemEntity,
} from "../../../domain/entities/accountPayableListItemEntity";
import { useAccountsPayablePage } from "../../hooks/useAccountsPayablePage";

export function AccountsPayableTable() {
  const {
    columns,
    tableRef,
    handleColumnSelect,
    handleColumnRemove,
    handleGetList,
    handleSelectionChange,
    rowClassName,
    handleRowDoubleClick,
    handleColReorder,
    handleRowClick,
    ...rest
  } = useAccountsPayablePage();

  const { selectedColumns, data, isFetching, selection, selectedPanorama } =
    rest.state;

  const handleIsDataSelectable = (event: DataTableDataSelectableParams) => {
    const accountPayableListItem = event.data as IAccountPayableListItemEntity;

    return (
      accountPayableListItem.status === EAccountPayableStatus.Open ||
      (accountPayableListItem.status === EAccountPayableStatus.Paid &&
        !accountPayableListItem.isTax)
    );
  };

  return (
    <>
      <ColumnSelector
        columns={columns}
        selectedColumns={selectedColumns}
        onSelect={handleColumnSelect}
      />
      <AdvTable<IAccountPayableListItemEntity>
        selectable
        data={data}
        tableRef={tableRef}
        rowsDefault={50}
        columns={columns}
        removeableColumns
        loading={isFetching}
        selection={selection}
        onPage={handleGetList}
        onSort={handleGetList}
        onClear={handleGetList}
        selectionMode="multiple"
        onFilter={handleGetList}
        selectionAutoFocus={false}
        selectedColumns={selectedColumns}
        panoramaFilters={
          selectedPanorama?.panoramaDefinition.pfsEventEntity.filters
        }
        panoramaSort={
          selectedPanorama?.panoramaDefinition.pfsEventEntity.multiSortMeta
        }
        rowsPerPageOptions={[10, 50, 100]}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        onSelectionChange={handleSelectionChange}
        onColumnRemove={handleColumnRemove}
        onColReorder={handleColReorder}
        rowClassName={rowClassName}
        isDataSelectable={handleIsDataSelectable}
      />
    </>
  );
}
