import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IGenerateAttachmentRpaContract,
  IGenerateAttachmentRpaParams,
} from "../../domain/contracts/generateAttachmentRpaContract";
import { IGenerateAttachmentRpaModel } from "../models/generateAttachmentRpaModel";

export class GenerateAttachmentRpaService
  implements IGenerateAttachmentRpaContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateAttachmentRpa({
    accountPayableListItem,
    generateRPAModalInput,
  }: IGenerateAttachmentRpaParams): Promise<File> {
    const payload: IGenerateAttachmentRpaModel = {
      projectId: accountPayableListItem.projectId || "",
      providerId: accountPayableListItem.providerId || "",
      valueMoney: accountPayableListItem.value,
      issueDate: accountPayableListItem.issueDate || "",
      paymentDetailsRpa: generateRPAModalInput.paymentDetail,
      requestTypeRpa: generateRPAModalInput.requestType,
      serviceTypeAttachmentRpa: generateRPAModalInput.serviceType,
      payUntil: accountPayableListItem.payUntil || "",
    };

    const url = `/Downloads/GenerateAttachmentRpa`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });

    if (response) {
      const file = this.generateFile(response);

      return file;
    }

    return Promise.reject();
  }

  private generateFile(httpResponse: HttpResponseType): File {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    return new File([data], fileName, {
      type: "application/pdf",
    });
  }
}
