import { useCallback, useMemo } from "react";
import { FaEdit, FaList } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  ISimpleHiddenColumn,
  ISimpleSortableColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ECustomerType,
  ICustomerEntity,
} from "../../domain/entities/customerEntity";

interface UseCustomerGridParams {
  openEditModal?(id: string): void;
  openClassificationModal?(customerEntity: ICustomerEntity): void;
  toggle?(id: string): Promise<void> | void;
}

export function useCustomerGrid({
  openEditModal,
  openClassificationModal,
  toggle,
}: UseCustomerGridParams) {
  const documentBodyTemplate = ({ document, type }: ICustomerEntity) => {
    if (document === "") {
      // TODO - colorir o Não informado com cor cinza.
      return <span>Não informado</span>;
    }

    const cpfRegExp = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    const cnpjRegExp = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    let maskedDocument = "";

    if (type === ECustomerType.individualPerson) {
      maskedDocument = document.replace(cpfRegExp, "$1.$2.$3-$4");
      return <span>{maskedDocument}</span>;
    }

    if (type === ECustomerType.legalPerson) {
      maskedDocument = document.replace(cnpjRegExp, "$1.$2.$3/$4-$5");
      return <span>{maskedDocument}</span>;
    }

    return <span>{document}</span>;
  };

  const editBodyTemplate = useCallback(
    (CustomerEntity: ICustomerEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            data-tip="Vincular classificação(ões) contábil(eis)"
            type="button"
            className="outline-button tool"
            id={`btn-vinc-class-account-${rowIndex}`}
            data-testid={`btn-vinc-class-account-${rowIndex}`}
            onClick={() => {
              openClassificationModal?.(CustomerEntity);
            }}
          >
            <FaList />
          </button>
          <button
            data-tip="Editar Cliente"
            type="button"
            className="outline-button tool"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openEditModal?.(CustomerEntity.id);
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [openClassificationModal, openEditModal],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: ICustomerEntity, { rowIndex }) => {
      const props: Record<string, string> = {};

      return (
        <div {...props}>
          <Toggle
            value={active}
            inactiveClassName="toggle-muted"
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            onChange={async _active => {
              const text = !_active ? "inativado" : "ativado";
              const result = await dialog.fire({
                icon: "question",
                title: "Você está certo disso?",
                html: `O Cliente será ${text}. <br /> Deseja prosseguir?`,
                showCancelButton: true,
                cancelButtonText: "Não",
                preConfirm() {
                  dialog.update({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  dialog.showLoading();
                  return toggle?.(id);
                },
              });

              if (result.isConfirmed) {
                return _active;
              }

              return !_active;
            }}
          />
        </div>
      );
    },
    [dialog, toggle],
  );

  // TODO - verificar como melhorar a tipagem dos campos da grid de acordo com a entidade de Clientees.
  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        width: "20%",
      },
      {
        field: "name",
        header: "Nome / Nome Fantasia",
        searchable: true,
        orderable: true,
      },
      {
        field: "typeDescription",
        header: "Tipo",
        searchable: true,
        orderable: true,
        sortField: "type",
      } as ISimpleSortableColumn,
      {
        field: "type",
        hidden: true,
      } as ISimpleHiddenColumn,
      {
        field: "document",
        header: "CNPJ / CPF / Documento",
        searchable: true,
        orderable: true,
        bodyTemplate: documentBodyTemplate,
      },

      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "hasCompanyTax",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
