import { InputMask } from "primereact/inputmask";
import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import {
  BirthdateValidatorErrors,
  useBirthdateValidator,
} from "../../../../core/presentation/hooks/useDateValidator";
import { useEmailValidator } from "../../../../core/presentation/hooks/useEmailValidator";
import { MakeCustomer } from "../../../main/makeCustomer";
import { Container } from "./styles";

interface IndividualPersonFormProps {
  customerId: string;
  useCustomer: MakeCustomer;
}

export function IndividualPersonForm({
  customerId = "",
  useCustomer,
}: IndividualPersonFormProps) {
  const { checkCpf } = useCustomer;

  const {
    register,
    formState: { errors },
    control,
    getFieldState,
  } = useFormContext();

  const birthdateValidator = useBirthdateValidator();
  const emailValidator = useEmailValidator();

  const refPrevDocument = useRef("");

  const validateDocument = async (document: string) => {
    const { error } = getFieldState("document");
    const defaultDocLength = 11;

    if (
      document.length === defaultDocLength &&
      refPrevDocument.current !== document
    ) {
      refPrevDocument.current = document;
      const { Code, success } = await checkCpf(document, customerId);

      if (success === false) {
        return "existingDocument";
      }
      if (Code === 100) {
        return "invalidDocument";
      }
      return true;
    }

    if (
      document.length < defaultDocLength &&
      refPrevDocument.current !== document
    ) {
      refPrevDocument.current = document;
      return "required";
    }

    if (error) return error.message;

    return true;
  };

  const validateBirthDate = (birthDate: string) => {
    if (birthDate.length > 0) {
      const validation = birthdateValidator(
        birthDate,
      ) as BirthdateValidatorErrors;

      if (validation.invalidDate) {
        return "invalidDate";
      }

      if (validation.futureDate) {
        return "futureDate";
      }
    }

    return true;
  };

  const validateEmail = (email: string) => {
    if (email.length > 0) {
      const invalidEmail = emailValidator(email);

      return invalidEmail ? "invalidEmail" : true;
    }
    return true;
  };

  return (
    <Container>
      <div className="form-header">Dados Pessoa Física</div>
      <div className="form-container">
        <div className="form-row">
          <label className="col-6 form-control">
            <span>CPF</span>
            <Controller
              control={control}
              rules={{
                required: true,
                maxLength: 11,
                validate: v => validateDocument(v),
              }}
              name="document"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <InputMask
                    autoClear={false}
                    mask="999.999.999-99"
                    unmask
                    onChange={onChange}
                    className={
                      error?.message || error?.type === "required"
                        ? "isInvalid"
                        : ""
                    }
                    value={value}
                    placeholder="CPF"
                    data-testid="individual-document"
                  />
                  <InvalidFeedback
                    condition={
                      error?.type === "required" ||
                      error?.message === "required"
                    }
                    message="Este campo é de preenchimento obrigatório"
                  />
                  <InvalidFeedback
                    condition={error?.message === "existingDocument"}
                    message="Já temos uma pessoa física cadastrada com este CPF"
                  />
                  <InvalidFeedback
                    condition={error?.message === "invalidDocument"}
                    message="CPF inválido"
                  />
                </>
              )}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              RG <small>(opcional)</small>
            </span>
            <input
              {...register("identityCard", { maxLength: 20 })}
              placeholder="RG"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>Nome</span>
            <input
              {...register("name", { required: true, maxLength: 100 })}
              className={errors.name?.type === "required" ? "isInvalid" : ""}
              maxLength={100}
              placeholder="Nome"
              data-testid="individual-name"
            />
            <InvalidFeedback
              condition={errors?.name?.type === "required"}
              message="Este campo é de preenchimento obrigatório"
            />
          </label>
          <label className="col-6 form-control">
            <span>
              Data de nascimento <small>(opcional)</small>
            </span>
            <Controller
              control={control}
              rules={{
                maxLength: 10,
                validate: v => validateBirthDate(v ?? ""),
              }}
              name="birthDate"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <InputMask
                    className={error?.message ? "isInvalid" : ""}
                    onChange={onChange}
                    autoClear={false}
                    unmask
                    placeholder="dd/mm/aaaa"
                    mask="99/99/9999"
                    value={value}
                  />
                  <InvalidFeedback
                    condition={error?.message === "invalidDate"}
                    message="Este campo é opcional, porém a data inserida está inválida."
                  />
                  <InvalidFeedback
                    condition={error?.message === "futureDate"}
                    message="Data de nascimento não pode ser uma data futura."
                  />
                </>
              )}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Telefone <small>(opcional)</small>
            </span>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                maxLength: 11,
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  onChange={e => {
                    onChange(e);
                  }}
                  autoClear={false}
                  placeholder="Telefone"
                  unmask
                  mask="(99) 99999-9999"
                  value={value}
                  maxLength={11}
                />
              )}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              E-mail <small>(opcional)</small>
            </span>
            <input
              {...register("email", {
                maxLength: 100,
                validate: v => validateEmail(v),
              })}
              maxLength={100}
              className={
                errors?.email?.message === "invalidEmail" ? "isInvalid" : ""
              }
              placeholder="E-mail"
            />
            <InvalidFeedback
              condition={errors?.email?.message === "invalidEmail"}
              message="Formato de e-mail inválido"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              PIS/PASEP <small>(opcional)</small>
            </span>
            <Controller
              control={control}
              name="pisPasep"
              rules={{
                minLength: 11,
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  placeholder="PIS/PASEP"
                  mask="999.99999.99-9"
                  unmask
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              Profissão <small>(opcional)</small>
            </span>
            <input {...register("profession")} placeholder="Profissão" />
          </label>
        </div>
      </div>
    </Container>
  );
}
