import { IEntity } from "../../../core/domain/entities/entity";

export interface ICompetencyEntity extends IEntity {
  companyGroupId: string;
  companyGroupName: string;
  name: string;
  isUsp: boolean;
}

export class CompetencyEntity implements ICompetencyEntity {
  companyGroupId = "";
  companyGroupName = "";
  name = "";
  isUsp = false;
  id = "";
  active = true;

  constructor(params?: Partial<ICompetencyEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<ICompetencyEntity>) {
    return new CompetencyEntity(params);
  }
}
