import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: stretch;

  & + & {
    margin-top: 1rem;
  }

  .raw-card,
  .release-cards {
    display: flex;

    flex: 1 1;
  }

  .raw-card {
    align-items: start;

    .chk-box {
      height: 88px;
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &.hidden {
        visibility: hidden;
      }
    }
  }

  .release-cards {
    flex-direction: column;
    align-items: stretch;

    .wrapper {
      display: flex;
      align-items: stretch;
      flex: 1;

      & + .wrapper {
        margin-top: 1rem;
      }

      .buttons {
        margin: 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }
`;
