import { ColumnBodyOptions, ColumnProps } from "primereact/column";
import { InputMask } from "primereact/inputmask";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { IAccountPayableListItemEntity } from "../../../domain/entities/accountPayableListItemEntity";

export type IPaymentToSuppliersRemittanceFormItem = Pick<
  IAccountPayableListItemEntity,
  "companyId" | "companyName"
> & {
  agreementCode: string;
};

export interface IPaymentToSuppliersRemittanceForm {
  paymentToSuppliers: IPaymentToSuppliersRemittanceFormItem[];
}

interface PaymentToSuppliersRemittanceTypeModalContentProps {
  tableData: IPaymentToSuppliersRemittanceFormItem[];
}

export function PaymentToSuppliersRemittanceTypeModalContent({
  tableData,
}: PaymentToSuppliersRemittanceTypeModalContentProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPaymentToSuppliersRemittanceForm>();

  const inputTemplate = (_: never, { rowIndex }: ColumnBodyOptions) => {
    return (
      <div className="form-row">
        <label className="form-control col-12">
          <Controller
            control={control}
            name={`paymentToSuppliers.${rowIndex}.agreementCode`}
            rules={{
              required: true,
              minLength: 20,
            }}
            render={({ field }) => {
              return (
                <InputMask
                  {...field}
                  unmask
                  slotChar=""
                  autoClear={false}
                  mask="99999999999999999999"
                  className={
                    errors?.paymentToSuppliers?.[rowIndex]?.agreementCode
                      ? "isInvalid"
                      : ""
                  }
                />
              );
            }}
          />
          <InvalidFeedback
            message="Este campo é de preenchimento obrigatório"
            condition={
              errors?.paymentToSuppliers?.[rowIndex]?.agreementCode?.type ===
              "required"
            }
          />
          <InvalidFeedback
            message="O campo deve conter 20 caracteres"
            condition={
              errors?.paymentToSuppliers?.[rowIndex]?.agreementCode?.type ===
              "minLength"
            }
          />
        </label>
      </div>
    );
  };

  const columns: ColumnProps[] = [
    {
      header: "Convênio",
      field: "companyName",
      style: {
        width: "50%",
      },
    },
    {
      style: {
        width: "50%",
      },
      field: "inputField",
      body: inputTemplate,
    },
  ];

  return (
    <form className="form-container">
      <ClientSoulTable data={tableData} columns={columns} paginator={false} />
    </form>
  );
}
