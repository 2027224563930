import styled from "styled-components";

export const Container = styled.div`
  .attachment-button {
    color: #172b4d;
    border-color: #172b4d;

    gap: 0.2rem;
    font-size: 0.9rem;

    &:hover:not(.disabled) {
      color: #fff;
      background-color: #172b4d;
    }

    span {
      color: #fff;
      background: #172b4d;

      font-size: 0.8rem;
      font-weight: 600;
      text-align: center;

      padding: 0.3rem 0.25rem;

      border-radius: 0.375rem;
    }
  }

  .p-datatable tr.p-datatable-row-expansion td {
    padding: unset;
    background-color: #f6f9fc;

    .expanded-template {
      padding: 2rem;
      padding-top: 1rem;

      .form-fields {
        gap: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .form-control {
        flex: 0 0 calc((100% / 3) - 0.335rem);

        > span:first-child {
          display: block;
          color: #525f7f;
          font-weight: 600;
          line-height: 1.5;
          font-size: 0.869rem;
          margin-bottom: 0.5rem;

          small {
            font-weight: 400;
            color: var(--text-muted);
          }
        }

        .barcode-wrapper {
          display: flex;
          align-items: center;

          .copied-tooltip {
            &.place-top::after {
              border-top-color: #187c0f;
            }
          }
        }

        &:has(.barcode-wrapper) {
          display: block;
          margin-top: 0.5rem;
          max-width: calc(50% - 0.375rem);
        }
      }
    }
  }

  .barcode-button {
    border: none;
    border-radius: 0 0.25rem 0.25rem 0;

    &.no-radius {
      border-radius: unset;
    }

    display: flex;
    align-items: center;

    padding: 0.47rem;

    color: white;
    background-color: #5e72e4;

    font-size: 1rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

    &:hover {
      background-color: #324cdd;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
