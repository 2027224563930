import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  ISearchBrazilianCityContract,
  ISearchBrazilianCityParams,
} from "../../domain/contracts/searchBrazilianCityContract";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

interface ICityResponse {
  id: string;
  name: string;
}

export class SearchBrazilianCityService
  implements ISearchBrazilianCityContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchBrazilianCity({
    stateId,
    search = "",
    activesOnly,
    showMetadata,
    payloadOptions,
  }: ISearchBrazilianCityParams) {
    const userEntity = this.getUserLocalService.get();
    let url = "/Cities";

    if (stateId) {
      url = `/States/${stateId}/Cities`;
    }

    if (activesOnly) {
      url += "/Actives";
    }

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      columns: payloadOptions?.columns || [
        {
          name: "name",
          data: "name",
          orderable: false,
          searchable: true,
          search: {
            regex: false,
            value: "",
          },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const response = await this.api.get<IResponseEntity<ICityResponse[]>>(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const mappedResponse = response.data.map(city => {
      return {
        label: city.name,
        rawValue: city.id,
        metadata: showMetadata ? city : undefined,
      };
    });

    return mappedResponse;
  }
}
