import { DecryptService } from "../data/services/decryptService";
import { EncryptService } from "../data/services/encryptService";
import { GetUserLocalService } from "../data/services/getUserLocalService";
import { SetUserLocalService } from "../data/services/setUserLocalService";
import {
  GetUserLocalUseCase,
  IGetUserLocalUseCase,
} from "../domain/usecases/getUserLocalUseCase";
import {
  ISetUserLocalUseCase,
  SetUserLocalUseCase,
} from "../domain/usecases/setUserLocalUseCase";

export type MakeUserLocal = IGetUserLocalUseCase & ISetUserLocalUseCase;

export function makeUserLocal(): MakeUserLocal {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const getUserLocalUseCase = new GetUserLocalUseCase(getUserLocalService);

  const encryptService = new EncryptService();
  const setUserLocalService = new SetUserLocalService(encryptService);
  const setUserLocalUseCase = new SetUserLocalUseCase(setUserLocalService);

  return {
    get: () => getUserLocalUseCase.get(),
    set: users => setUserLocalUseCase.set(users),
  };
}
