import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";

export interface ISearchUsersService {
  searchUsers(
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export interface ISearchUsersUseCase {
  searchUsers(
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export class SearchUsersUseCase implements ISearchUsersUseCase {
  constructor(private searchUsersService: ISearchUsersService) {}
  searchUsers(
    search = "",
    fetchMinLength = 10,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    return this.searchUsersService.searchUsers(search, fetchMinLength);
  }
}
