import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";
import { IResetUserPasswordService } from "../../domain/usecases/resetPasswordUseCase";
import { ResetUserPasswordModel } from "../models/resetUserPasswordModel";

export class ResetPasswordService implements IResetUserPasswordService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async resetUserPassword(userId: string): Promise<IUserEntity> {
    const loggedUser = this.getUserLocalService.get();

    const url = `/Users/${userId}/ResetPassword`;

    const payload = new ResetUserPasswordModel({
      partnerId: process.env.REACT_APP_PARTNER_ID,
    });

    const response = await this.authApi.post<IUserEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${loggedUser?.token}`,
      },
    });

    return new UserEntity(response);
  }
}
