import { ApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { ValidateTokenService } from "../data/services/validateTokenService";
import {
  IValidateTokenUseCase,
  ValidateTokenUseCase,
} from "../domain/usecases/validateTokenUseCase";

export type MakeAuthValidation = IValidateTokenUseCase;

export function makeAuthValidation(): MakeAuthValidation {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseURL = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;

  const api = new ApiService(baseURL);

  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const validateTokenService = new ValidateTokenService(
    getUserLocalService,
    api,
  );
  const validateTokenUseCase = new ValidateTokenUseCase(validateTokenService);

  return {
    validateToken: () => validateTokenUseCase.validateToken(),
  };
}
