import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: inherit;

  .cell-truncate {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .src-fld {
    width: 100%;
    max-width: 20rem;
  }

  .button-wrapper {
    flex-grow: 1;
    display: inline-flex;
    justify-content: center;
  }

  .full-page {
    display: flex;
    height: inherit;
    flex-direction: column;
  }

  .empty-message {
    position: relative;
    height: 2.625rem;

    div {
      background-color: #f1f1f1;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      color: #8898aa;
      border: 0.0625rem solid #e1e1e1;
      position: fixed;
      left: calc(50vw - 3.75rem - 6.1875rem);
    }
  }

  .text-muted {
    color: var(--text-muted);
  }

  .no-padding {
    padding: 0;

    &.fill-height {
      height: inherit;
      display: flex;
      flex-direction: column;
      /**
       * 9rem é basicamente a distancia entre o top do viewport e o top
       * do * elemento .no-padding + uma espaco de respiro entre o bottom
       * do .no-padding e o bottom do viewport.
        *
       * Esta estratégia usando calc com 100vh e um valor fixo está
       * presente na documentacao do PrimeReact > Table > Flexscroll.
       */
      height: calc(100vh - 21.5rem);

      @media screen and (min-width: 992px) {
        height: calc(100vh - 13rem);
      }

      @media screen and (min-width: 1200px) {
        height: calc(100vh - 9rem);
      }
    }
  }

  .p-datatable.p-datatable-selectable.p-datatable-striped {
    .p-datatable-tbody {
      tr,
      tr.p-selectable-row {
        &.soul-row-not-selectable {
          .soul-chkbox-col.p-selection-column {
            .p-checkbox.p-component {
              visibility: hidden;
            }
          }
        }

        &:not(.p-highlight):not(.p-datatable-emptymessage):hover {
          &.row-revenue {
            background: #e4f7f0;
          }

          &.row-expense {
            background: #fddfe5;
          }

          &.p-row-odd {
            &.row-revenue {
              background: #ebf9f4;
            }

            &.row-expense {
              background: #fddfe5;
            }
          }
        }

        &.row-revenue {
          background: #d0f1e6;
        }

        &.row-expense {
          background: #fcc7d2;
        }

        &.p-row-odd {
          &.row-revenue {
            background: #dcf5ec;
          }

          &.row-expense {
            background: #fdd6de;
          }
        }
      }
    }
  }
`;

export const ContextMenuItem = styled.div`
  .p-menuitem-text {
    display: inline-flex;
    justify-content: center;
  }

  svg {
    fill: var(--soul-color02);
    margin-right: 0.5rem;
  }
`;
