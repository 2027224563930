import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IReturnAccountReceivableInvoiceTaxesContract } from "../../domain/contracts/returnAccountReceivableInvoiceTaxesContract";
import { EReturnStatus } from "../../domain/entities/accountReceivableListItemEntity";
import { IReturnAccountReceivablePayloadModel } from "../models/returnAccountReceivableModels";
import { IReturnAccountReceivableFormEntity } from "../../domain/entities/returnAccountReceivableFormEntity";

export class ReturnAccountReceivableInvoiceTaxesService
  implements IReturnAccountReceivableInvoiceTaxesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async returnAccountReceivableInvoiceTaxes(
    params: IReturnAccountReceivableFormEntity,
  ): Promise<void> {
    const {
      returnDate,
      returnValue,
      costCenterLinks,
      accountReceivableId,
      /*
        TODO - #11462
        Só será utilizado quando a devolução de taxas FUSP for liberado para produção.
        ------------------------------------------------------------------------------
        taxReturnDate,
      */
    } = params;

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsReceivable/${accountReceivableId}/ReturnInvoiceTaxes`;

    const payload: IReturnAccountReceivablePayloadModel = {
      returnValue,
      returnDate: this.formatDate(returnDate),
      returnStatus: EReturnStatus.PartiallyReturned,
      /*
        TODO - #11462
        Só será utilizado quando a devolução de taxas FUSP for liberado para produção.
        ------------------------------------------------------------------------------
        taxReturnDate: taxReturnDate ? this.formatDate(taxReturnDate) : undefined,
      */
    };

    if (costCenterLinks) {
      payload.costCenterLinks = {
        costCenter: costCenterLinks.costCenterDestination?.name || "",

        nameInactive: costCenterLinks.costCenterDisabled.name,
        costCenterIdInactive: costCenterLinks.costCenterDisabled.id,

        nameActive: costCenterLinks.costCenterDestination?.name || "",
        costCenterIdActive: costCenterLinks.costCenterDestination?.id || "",
      };
    }

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    const parsedDate = parse(date, "ddMMyyyy", new Date());
    return format(parsedDate, "yyyy-MM-dd");
  }
}
