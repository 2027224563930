import { MenuItem, MenuItemCommandParams } from "primereact/menuitem";
import { useCallback, useMemo } from "react";
import {
  FaEdit,
  FaEye,
  FaPaperPlane,
  FaPaperclip,
  FaRegClone,
  FaTimesCircle,
} from "react-icons/fa";
import { format } from "date-fns";
import { useRequesterPage } from "./useRequesterPage";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { RejectReasonContainer } from "../components/RequesterPage/styles";
import { CancelDialogContent } from "../../../../../core/presentation/components/CancelDialogContent";

export function useRequesterContextMenu() {
  const {
    reload,
    menuRef,
    usePaymentRequests,
    handleHideContextMenu,
    handleOpenAttachmentModal,
    state: { contextMenuData },
  } = useRequesterPage();

  const { cancelPaymentRequest, requestPaymentRequestReview } =
    usePaymentRequests;

  const dialog = useSoulDialog();

  const handleShowRejectReasonDialog = useCallback(
    ({ item }: MenuItemCommandParams) => {
      const date = item?.data?.rejectDate;
      const user = item?.data?.rejectUserName;
      const reason = item?.data?.rejectReason;
      const formattedDate = format(new Date(date || ""), "dd/MM/yyyy");

      dialog.fire({
        icon: "error",
        html: (
          <RejectReasonContainer>
            <p>Esta solicitação foi reprovada pois:</p>
            <div>
              <p>
                Desativado por: <strong>{user}</strong>{" "}
                <small>({formattedDate})</small>
              </p>
              <p>{reason}</p>
            </div>
          </RejectReasonContainer>
        ),
      });
    },
    [dialog],
  );

  /** Lida com o evento de submit do form na dialog de cancelamento de uma conta */
  const handleCancelDialogContentSubmit = useCallback(
    async (paymentRequestId: string, deleteReason: string) => {
      await cancelPaymentRequest(paymentRequestId, deleteReason);
    },
    [cancelPaymentRequest],
  );

  const handleCancelPaymentRequest = useCallback(
    async ({ item }: MenuItemCommandParams) => {
      const paymentRequestId = item?.data.id;

      if (!paymentRequestId) {
        return;
      }

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        html: (
          <CancelDialogContent
            onSubmit={deleteReason =>
              handleCancelDialogContentSubmit(paymentRequestId, deleteReason)
            }
          />
        ),
        showCancelButton: false,
        showDenyButton: false,
        showConfirmButton: false,
        showCloseButton: false,
      });

      if (result.dismiss) {
        return;
      }

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento cancelado com sucesso!",
      });

      reload();
    },

    [dialog, handleCancelDialogContentSubmit, reload],
  );

  const handleReviewRequest = useCallback(
    async ({ item }: MenuItemCommandParams) => {
      const paymentRequestId = item?.data?.id;
      const hasAnyAttachmentNF = item?.data?.anyAttachmentNF;

      if (!paymentRequestId) {
        return;
      }

      if (!hasAnyAttachmentNF) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Não consta nos anexos dessa solicitação uma nota fiscal ou
              documento equivalente. Você deverá entrar nesta mesma solicitação
              e anexar a nota assim que recebê-la.
            </>
          ),
        });
      }

      const response = await dialog.fire({
        icon: "question",
        showDenyButton: true,
        title: "Editar antes de continuar?",
        denyButtonText: "Editar solicitação",
        confirmButtonText: "Solicitar aprovação",
        customClass: {
          denyButton: "form-button blue-bkg",
          confirmButton: "form-button green-bkg no-margin",
        },
        async preConfirm() {
          dialog.showLoading();

          try {
            await requestPaymentRequestReview(paymentRequestId);

            await dialog.fire({
              icon: "success",
              title: "Feito!",
              text: "Aprovação solicitada com sucesso.",
            });
          } catch {
            dialog.close();
          }
        },
        preDeny() {
          dialog.close();
          window.open(
            `${SoulRoutes.PAYMENT_REQUESTS.path}/${paymentRequestId}`,
          );
        },
        html: (
          <>
            Caso queira editar a solicitação <strong>antes de continuar</strong>
            , clique em{" "}
            <strong style={{ color: "#3649f5" }}>Editar solicitação</strong>,
            caso contrário, clique em{" "}
            <strong style={{ color: "var(--soul-green-button)" }}>
              Solicitar aprovação
            </strong>
            .
          </>
        ),
      });

      if (response.isConfirmed) {
        reload();
      }
    },
    [dialog, reload, requestPaymentRequestReview],
  );

  return useMemo(() => {
    const id = contextMenuData?.id || "";
    const status = contextMenuData?.status;

    const editButton: MenuItem = {
      label: "Editar",
      target: "_blank",
      data: contextMenuData,
      url: `${SoulRoutes.PAYMENT_REQUESTS.path}/${id}`,
      visible:
        status === EPaymentRequestStatus.Rejected ||
        status === EPaymentRequestStatus.NotRequested,
      icon: (
        <FaEdit
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const viewButton: MenuItem = {
      target: "_blank",
      label: "Visualizar",
      data: contextMenuData,
      url: `${SoulRoutes.PAYMENT_REQUESTS.path}/${id}`,
      visible:
        status === EPaymentRequestStatus.Requested ||
        status === EPaymentRequestStatus.Approved ||
        status === EPaymentRequestStatus.Paid ||
        status === EPaymentRequestStatus.Canceled,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const changeAttachmentsButton: MenuItem = {
      data: contextMenuData,
      label: "Alterar anexos",
      command: handleOpenAttachmentModal,
      visible: status !== EPaymentRequestStatus.Canceled,
      icon: (
        <FaPaperclip
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const showRejectReasonButton: MenuItem = {
      data: contextMenuData,
      label: "Motivo da Reprovação",
      command: handleShowRejectReasonDialog,
      visible: status === EPaymentRequestStatus.Rejected,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-red-button)",
          }}
        />
      ),
    };

    const cancelButton: MenuItem = {
      label: "Cancelar",
      data: contextMenuData,
      command: handleCancelPaymentRequest,
      visible: status === EPaymentRequestStatus.Requested,
      icon: (
        <FaTimesCircle
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-red-button)",
          }}
        />
      ),
    };

    const reviewRequestButton: MenuItem = {
      data: contextMenuData,
      label: "Solicitar Aprovação",
      command: handleReviewRequest,
      visible: status === EPaymentRequestStatus.NotRequested,
      icon: (
        <FaPaperPlane
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-green-button)",
          }}
        />
      ),
    };

    const duplicateButton: MenuItem = {
      label: "Duplicar",
      target: "_blank",
      data: contextMenuData,
      visible: status !== EPaymentRequestStatus.Canceled,
      command() {
        /**
         * REVIEW - Por alguma razão, apenas esse link passou a apresentar
         * problemas com a dinâmica de estados do menu de contexto.
         *
         * Apesar do link aparecer corretamente na anchor tag, o evento de clique
         * abria uma nova aba com o ID vazio.
         * Isso acontece porque o menu de contexto fecha no evento de clique,
         * limpando os dados presentes no estado `contextMenuData`.
         *
         * Isso não ocorre nos demais links. Portanto, o workaround é usar a
         * navegação via `command` junto com o objeto `window`.
         */
        window.open(`${SoulRoutes.PAYMENT_REQUESTS.path}/new?duplicate=${id}`);
      },
      icon: (
        <FaRegClone
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const menuModel = [
      editButton,
      viewButton,
      changeAttachmentsButton,
      showRejectReasonButton,
      cancelButton,
      reviewRequestButton,
      duplicateButton,
    ];

    return {
      menuRef,
      menuModel,
      handleHideContextMenu,
    };
  }, [
    menuRef,
    contextMenuData,
    handleReviewRequest,
    handleHideContextMenu,
    handleOpenAttachmentModal,
    handleCancelPaymentRequest,
    handleShowRejectReasonDialog,
  ]);
}
