import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IToggleCostCenterContract } from "../../domain/contracts/toggleCostCenterContract";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";

export class ToggleCostCenterService implements IToggleCostCenterContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  toggleCostCenter(payload: ICostCenterEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/CostCenters";

    return this.api.put<ICostCenterEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
