import { IUserEntity } from "../entities/userEntity";

export interface IToggleUserService {
  toggleUser(userId: string): Promise<IUserEntity>;
}

export interface IToggleUserUseCase {
  toggleUser(userId: string): Promise<IUserEntity>;
}

export class ToggleUserUseCase implements IToggleUserUseCase {
  constructor(private toggleUserService: IToggleUserService) {}

  toggleUser(userId: string): Promise<IUserEntity> {
    return this.toggleUserService.toggleUser(userId);
  }
}
