import { ICompetencyEntity } from "../entities/competencyEntity";

export interface IToggleCompetencyService {
  toggleCompetency(id: string): Promise<ICompetencyEntity>;
}

export interface IToggleCompetencyUseCase {
  toggleCompetency(id: string): Promise<ICompetencyEntity>;
}

export class ToggleCompetencyUseCase implements IToggleCompetencyUseCase {
  constructor(private toggleCompetencyService: IToggleCompetencyService) {}

  toggleCompetency(id: string): Promise<ICompetencyEntity> {
    return this.toggleCompetencyService.toggleCompetency(id);
  }
}
