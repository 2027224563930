import styled from "styled-components";

export const Container = styled.div`
  --dark-blue: #040754;
  --light-blue: #e1e2fe;
  --scroll-size: 0.5rem;
  --transition-time: 0.25s;

  @keyframes button-fadein {
    0% {
      max-width: 2.3rem;
    }

    100% {
      max-width: 75%;
    }
  }

  --transition-time: 0.12s;

  font-size: 0.8rem;
  background: var(--dark-blue);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.25rem 1.25rem;
  white-space: nowrap;
  max-height: 3rem;
  transition: max-height 0.4s linear, padding 0.4s linear;

  &.empty {
    padding: 0 1.25rem;
    max-height: 0;
  }

  &::-webkit-scrollbar {
    width: var(--scroll-size);
    height: var(--scroll-size);
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e1e2fe8c;
    border-radius: var(--scroll-size);
    border: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  button {
    position: relative;
    border: none;
    border-radius: 100px;
    padding: 0.25rem 0.35rem 0.25rem 0.75rem;
    background: var(--light-blue);
    color: var(--dark-blue);
    margin-right: 0.5px;
    bottom: 0;
    user-select: none;
    transition: filter var(--transition-time) ease-out,
      bottom var(--transition-time) ease-out;

    max-width: 2.3rem;
    animation: button-fadein forwards 0.5s ease-out;

    &:hover {
      filter: saturate(2) brightness(0.95);
      bottom: 2px;
    }

    & + button {
      margin-left: 0.375rem;
    }

    .label {
      display: inline-flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 0.375rem;
    }

    .icon {
      display: inline-flex;
      background: var(--dark-blue);
      color: var(--light-blue);
      font-size: 0.7rem;
      border-radius: 100%;
      width: 1.2rem;
      height: 1.2rem;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
  }
`;
