import { createContext, ReactNode, useMemo, useContext } from "react";
import {
  IAdvTableColumn,
  ColumnFilterOperationType,
} from "../../domain/entities/advTableColumn";
import { ColumnFilterProps } from "../components/ColumnFilter";

export interface ColumnFilterContextData<T = IAdvTableColumn>
  extends ColumnFilterProps<T> {
  loading: boolean;
  fetchMinLength: number;
  filterValue: unknown;
  operation: ColumnFilterOperationType;
  setLoading(loading: boolean): void;
  setFilterValue?(value: unknown): void;
  setOperation(value: ColumnFilterOperationType): void;
  onFilterButtonClick(): void;
}

const ColumnFilterContext = createContext({} as ColumnFilterContextData);

interface ColumnFilterProviderProps {
  columnFilterProps: ColumnFilterProps;
  fetchMinLength: number;
  loading: boolean;
  filterValue: unknown;
  operation: ColumnFilterOperationType;
  setLoading(loading: boolean): void;
  setFilterValue?(value: unknown): void;
  setOperation(value: ColumnFilterOperationType): void;
  onFilterButtonClick(): void;
  children: ReactNode;
}

export function ColumnFilterProvider({
  children,
  columnFilterProps,
  loading,
  fetchMinLength,
  filterValue,
  operation,
  setLoading,
  setFilterValue,
  setOperation,
  onFilterButtonClick,
}: ColumnFilterProviderProps) {
  const memoizedColumnFilterProps = useMemo<ColumnFilterContextData>(
    () => ({
      ...columnFilterProps,
      loading,
      fetchMinLength,
      filterValue,
      operation,
      setLoading,
      setFilterValue,
      setOperation,
      onFilterButtonClick,
    }),
    [
      columnFilterProps,
      fetchMinLength,
      filterValue,
      loading,
      operation,
      setFilterValue,
      setLoading,
      setOperation,
      onFilterButtonClick,
    ],
  );

  return (
    <ColumnFilterContext.Provider value={memoizedColumnFilterProps}>
      {children}
    </ColumnFilterContext.Provider>
  );
}

export function useColumnFilter() {
  return useContext(ColumnFilterContext);
}
