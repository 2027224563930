// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Stringified<T> extends String {
  static parse<T>(raw: Stringified<T>): T {
    return JSON.parse(raw as string);
  }

  static stringify<T>(value: T): Stringified<T> {
    return JSON.stringify(value);
  }
}
