import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IStoreAccountReceivableIdToDuplicateContract } from "../../domain/contracts/storeAccountReceivableIdToDuplicateContract";

export class StoreAccountReceivableIdToDuplicateService
  implements IStoreAccountReceivableIdToDuplicateContract
{
  storeAccountReceivableIdToDuplicate(accountReceivableId: string): void {
    const key = ELocalStorageKeys.DuplicateAccountReceivableId;

    // NOTE aqui precisa ser stringificado porque o angular faz um
    // JSON.parse do outro lado, mesmo sendo apenas uma string simples
    const stringfiedAccountReceivableId: Stringified<string> =
      Stringified.stringify(accountReceivableId);

    localStorage.setItem(key, stringfiedAccountReceivableId.toString());
  }
}
