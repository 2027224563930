import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import {
  ListBox,
  ListBoxChangeParams,
  ListBoxItemTemplateType,
} from "primereact/listbox";
import { Skeleton } from "primereact/skeleton";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import {
  ColumnFilterOperationType,
  IRelationshipColumnEntity,
  IRelationshipFilterOption,
} from "../../../domain/entities/advTableColumn";
import { ERelationshipListBoxOptions } from "../../../domain/entities/relationshipListBoxOptions";
import { useColumnFilterUtils } from "../../hooks/columnFilterUtils";
import {
  ColumnFilterContextData,
  useColumnFilter,
} from "../../hooks/useColumnFilter";
import { RemoveButton, SelectedToken } from "../SelectedToken";
import { Container } from "./styles";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";

export interface RelationshipColumn extends IRelationshipColumnEntity {
  filterItemTemplate?: ListBoxItemTemplateType;
}

type TemplateCallbackType = (option: unknown) => React.ReactNode;

export function ColumnFilterRelationship() {
  const {
    column,
    loading,
    filterValue,
    fetchMinLength,
    operation: initialOperation,
    setOperation: setFilterOperation,
    setFilterValue,
    setLoading,
  } = useColumnFilter() as ColumnFilterContextData<RelationshipColumn>;

  const { header, filterItemTemplate: listItemTemplate, getList } = column;

  const [search, setSearch] = useState("");
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [options, setOptions] = useState<IRelationshipFilterOption[]>([]);
  const [operation, setOperation] =
    useState<ColumnFilterOperationType>(initialOperation);
  const [selectedOptions, setSelectedOptions] = useState<
    IRelationshipFilterOption[]
  >(() => {
    const initialFilterValue = filterValue as IRelationshipFilterOption[];

    if (
      initialFilterValue &&
      initialFilterValue.length &&
      operation !== "NotEqual"
    ) {
      return [...initialFilterValue];
    }

    return [];
  });

  const [exceptions, setExceptions] = useState<IRelationshipFilterOption[]>(
    () => {
      const initialFilterValue = filterValue as IRelationshipFilterOption[];

      if (
        initialFilterValue &&
        initialFilterValue.length &&
        operation === "NotEqual"
      ) {
        return [...initialFilterValue];
      }

      return [];
    },
  );

  const debounceTime = useDebounceTime();

  const { uniqueItems, isAllOptionsExcepted } = useColumnFilterUtils();

  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const handleGetList = useCallback(
    async (searchStr: string) => {
      setLoading(true);
      const serverSideResponseModel = await getList?.(
        searchStr,
        fetchMinLength,
        currentCompanyGroup?.id,
      );
      const data = serverSideResponseModel?.data ?? [];

      let selectionOptions = [] as IRelationshipFilterOption[];

      if (searchStr) {
        selectionOptions = [
          {
            rawValue: ERelationshipListBoxOptions.selectResults,
            label: "Selecionar todos resultados",
          },
        ];
      } else {
        selectionOptions = [
          {
            rawValue: ERelationshipListBoxOptions.selectAll,
            label: "Selecionar todos",
          },
          {
            rawValue: ERelationshipListBoxOptions.empty,
            label: "Vazios",
          },
        ];
      }

      const newOptions = [
        ...selectionOptions,
        ...data,
        ...selectedOptions
          .filter(() => !searchStr)
          .filter(
            selectedOption =>
              // Se a lista NAO estiver filtrada, dos itens que ja estao
              // selecionados incluimos nas opcoes os que nao estao vindo
              // da consulta
              (!searchStr &&
                operation !== "NotEqual" &&
                !data.some(
                  dataOption => dataOption.rawValue === selectedOption.rawValue,
                ) &&
                // E nao precisamos exibir o item "Selecionar todos resultados"
                selectedOption.rawValue !==
                  ERelationshipListBoxOptions.selectResults) ||
              // Se a lista ESTIVER filtrada nao precisamos
              // exibir o "Vazios" e o "Selecionar todos"
              (searchStr &&
                selectedOption.rawValue !== ERelationshipListBoxOptions.empty &&
                selectedOption.rawValue !==
                  ERelationshipListBoxOptions.selectAll),
          ),
      ];

      setOptions(uniqueItems(newOptions));

      // Isso é necessário para incluir as novas opcoes já marcadas
      // quado o usuario marcou a opcao "Selecionar todos"
      if (operation === "NotEqual") {
        setSelectedOptions(prevSelectedOptions => {
          const isAllOptsExcepted = isAllOptionsExcepted(data, exceptions);

          return uniqueItems(
            [...prevSelectedOptions.filter(() => !searchStr), ...newOptions]
              // Aqui temos que remover os elementos que estão na lista de
              // exceções pois estamos trabalhando com operação de "NotEqual"
              .filter(
                selOption =>
                  !exceptions.some(
                    exception => exception.rawValue === selOption.rawValue,
                  ),
              )
              .filter(
                selOption =>
                  !(
                    selOption.rawValue ===
                      ERelationshipListBoxOptions.selectResults &&
                    isAllOptsExcepted
                  ),
              ),
          );
        });
      } else if (searchStr) {
        setSelectedOptions(prevSelectedOptions => {
          const isEveryOptionSelected =
            data.length &&
            data.every(dataOption =>
              prevSelectedOptions.some(
                prevSelectedOption =>
                  prevSelectedOption.rawValue === dataOption.rawValue,
              ),
            );

          // Aqui incluimos o item "Selecionar todos os resultados" quando o
          // filtro de opcoes muda e todos os resultados ja estao selecionados
          if (isEveryOptionSelected) {
            return [...prevSelectedOptions, ...selectionOptions];
          }

          // Aqui removemos o item "Selecionar todos os resultados"
          // quando o filtro de opcoes muda
          return uniqueItems([
            ...prevSelectedOptions.filter(
              selOption =>
                selOption.rawValue !==
                ERelationshipListBoxOptions.selectResults,
            ),
          ]);
        });
      }

      setRecordsTotal(serverSideResponseModel?.recordsTotal || 0);
      setLoading(false);
    },
    [
      exceptions,
      fetchMinLength,
      getList,
      isAllOptionsExcepted,
      operation,
      selectedOptions,
      setLoading,
      uniqueItems,
      currentCompanyGroup?.id,
    ],
  );

  const handleOnSearchValueChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        handleGetList(value);
      }, 500);
    },
    [debounceTime, handleGetList],
  );

  const handleOnSearchFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const input = event.target;
      input.setSelectionRange(0, input.value.length);
    },
    [],
  );

  const handleOnChange = useCallback(
    (event: ListBoxChangeParams) => {
      const value = event.value as IRelationshipFilterOption[];

      // "Selecionar todos" está marcado
      if (
        selectedOptions.some(
          option => option.rawValue === ERelationshipListBoxOptions.selectAll,
        )
      ) {
        // Marcou/desmarcou um item qualquer quando está tudo selecionado
        if (
          value.some(
            option => option.rawValue === ERelationshipListBoxOptions.selectAll,
          )
        ) {
          const unmarkedOption = selectedOptions.filter(
            option =>
              !value.some(
                valueOption => valueOption.rawValue === option.rawValue,
              ),
          );

          setExceptions(prevExceptions =>
            uniqueItems([
              ...prevExceptions.filter(
                exception =>
                  !value.some(
                    option => option.rawValue === exception.rawValue,
                  ) &&
                  exception.rawValue !==
                    ERelationshipListBoxOptions.selectAll &&
                  exception.rawValue !==
                    ERelationshipListBoxOptions.selectResults,
              ),
              ...unmarkedOption,
            ]),
          );

          setSelectedOptions([...value]);
        }
        // Desmarcou "Selecionar todos"
        else {
          setOperation("Equal");
          setSelectedOptions([]);
          setExceptions([]);
        }
      }
      // "Selecionar todos resultados" está marcado
      else if (
        selectedOptions.some(
          option =>
            option.rawValue === ERelationshipListBoxOptions.selectResults,
        )
      ) {
        // Marcou/desmarcou um item qualquer quando "Selecionar todos os resultados" está marcado
        if (
          value.some(
            option =>
              option.rawValue === ERelationshipListBoxOptions.selectResults,
          )
        ) {
          if (operation === "NotEqual") {
            const unmarkedOption = selectedOptions.filter(
              option =>
                !value.some(
                  valueOption => valueOption.rawValue === option.rawValue,
                ),
            );

            setExceptions(prevExceptions =>
              uniqueItems([
                ...prevExceptions.filter(
                  exception =>
                    !value.some(
                      valueOption =>
                        valueOption.rawValue === exception.rawValue,
                    ) &&
                    exception.rawValue !==
                      ERelationshipListBoxOptions.selectAll &&
                    exception.rawValue !==
                      ERelationshipListBoxOptions.selectResults,
                ),
                ...unmarkedOption,
              ]),
            );
          } else if (
            value.some(
              option =>
                option.rawValue === ERelationshipListBoxOptions.selectAll,
            )
          ) {
            setOperation("NotEqual");
          }

          setSelectedOptions(prevSelectedOptions => {
            const newOptions = [
              ...value,
              ...prevSelectedOptions.filter(
                selOption =>
                  !options.some(
                    option => option.rawValue === selOption.rawValue,
                  ),
              ),
              ...options.filter(() =>
                options.some(
                  option =>
                    option.rawValue === ERelationshipListBoxOptions.selectAll,
                ),
              ),
            ];

            if (
              options
                .filter(
                  newOption =>
                    newOption.rawValue !==
                    ERelationshipListBoxOptions.selectResults,
                )
                .some(
                  newOption =>
                    !newOptions.some(
                      prevOption => newOption.rawValue === prevOption.rawValue,
                    ),
                )
            ) {
              newOptions.splice(
                newOptions.findIndex(
                  newOption =>
                    newOption.rawValue ===
                    ERelationshipListBoxOptions.selectResults,
                ),
                1,
              );

              return uniqueItems([...newOptions]);
            }

            return uniqueItems(newOptions);
          });
        }
        // Desmarcou "Selecionar todos os resultados"
        else {
          setSelectedOptions(prevSelectedOptions => {
            const unmarkedOptions = [
              ...prevSelectedOptions.filter(selOption =>
                options.some(option => option.rawValue === selOption.rawValue),
              ),
            ];

            if (operation === "NotEqual") {
              setExceptions(prevExceptions =>
                uniqueItems([
                  ...prevExceptions,
                  ...unmarkedOptions.filter(
                    unmarkedOption =>
                      unmarkedOption.rawValue !==
                        ERelationshipListBoxOptions.selectAll &&
                      unmarkedOption.rawValue !==
                        ERelationshipListBoxOptions.selectResults,
                  ),
                ]),
              );
            }

            return uniqueItems([
              ...prevSelectedOptions.filter(
                selOption =>
                  !unmarkedOptions.some(
                    option => option.rawValue === selOption.rawValue,
                  ),
              ),
            ]);
          });
        }
      }
      // Marcou "Selecionar todos"
      else if (
        value.some(
          option => option.rawValue === ERelationshipListBoxOptions.selectAll,
        )
      ) {
        setOperation("NotEqual");
        setSelectedOptions(uniqueItems([...options]));
        setExceptions([]);
      }
      // Marcou "Selecionar todos os resultados"
      else if (
        value.some(
          option =>
            option.rawValue === ERelationshipListBoxOptions.selectResults,
        )
      ) {
        setSelectedOptions(prevSelectedOptions => {
          const newSelectedOptions = uniqueItems([
            ...prevSelectedOptions,
            ...options,
          ]);

          setExceptions(prevExceptions => {
            return prevExceptions.filter(
              prevException =>
                !newSelectedOptions.some(
                  selOption => selOption.rawValue === prevException.rawValue,
                ) &&
                prevException.rawValue !==
                  ERelationshipListBoxOptions.selectAll &&
                prevException.rawValue !==
                  ERelationshipListBoxOptions.selectResults,
            );
          });

          return newSelectedOptions;
        });
      }
      // Marcou/desmarcou um item individualmente
      else {
        if (operation === "NotEqual") {
          const unmarkedOption = selectedOptions.filter(
            option =>
              !value.some(
                valueOption => valueOption.rawValue === option.rawValue,
              ),
          );
          setExceptions(prevExceptions => [
            ...prevExceptions,
            ...unmarkedOption,
          ]);
        }

        setSelectedOptions(uniqueItems([...value]));
      }
    },
    [operation, options, selectedOptions, uniqueItems],
  );

  const handleOnRemove = useCallback(value => {
    setSelectedOptions(prevSelectedOptions => {
      const prevSelectedOptionsClone = [...prevSelectedOptions];

      prevSelectedOptionsClone.splice(
        prevSelectedOptionsClone.findIndex(
          selectedIndex => selectedIndex.rawValue === value,
        ),
        1,
      );

      return [...prevSelectedOptionsClone];
    });
  }, []);

  const handleOnClearSearchClick = useCallback(() => {
    if (search) handleGetList("");
    setSearch("");
  }, [handleGetList, search]);

  const renderSelectedOptions = useCallback(() => {
    if (loading) {
      return <div className="filter-display-placeholder">{header}</div>;
    }

    if (operation === "NotEqual") {
      const filteredExceptions = exceptions.filter(
        selOption =>
          selOption.rawValue !== ERelationshipListBoxOptions.selectAll &&
          selOption.rawValue !== ERelationshipListBoxOptions.selectResults &&
          selOption.rawValue !== ERelationshipListBoxOptions.empty,
      );
      const length = recordsTotal - filteredExceptions.length;
      return <SelectedToken label={`${length} itens`} />;
    }

    const filteredSelectedOptions = selectedOptions.filter(
      selOption =>
        selOption.rawValue !== ERelationshipListBoxOptions.selectAll &&
        selOption.rawValue !== ERelationshipListBoxOptions.selectResults &&
        selOption.rawValue !== ERelationshipListBoxOptions.empty,
    );

    if (!filteredSelectedOptions.length) {
      return <div className="filter-display-placeholder">{header}</div>;
    }

    if (filteredSelectedOptions.length < 3) {
      return filteredSelectedOptions.map(({ rawValue, label }) => (
        <SelectedToken
          key={rawValue}
          value={rawValue}
          label={label}
          onRemove={handleOnRemove}
        >
          <RemoveButton />
        </SelectedToken>
      ));
    }

    const { length } = filteredSelectedOptions;
    return <SelectedToken label={`${length} itens`} />;
  }, [
    exceptions,
    handleOnRemove,
    header,
    loading,
    operation,
    recordsTotal,
    selectedOptions,
  ]);

  const renderItemTemplate = useCallback(
    (option: IRelationshipFilterOption) => {
      if (options.length === 0 && loading) {
        return <Skeleton />;
      }

      return (
        <div title={option.label} className="item-wrapper">
          <Checkbox
            checked={selectedOptions.some(
              selOption => selOption.rawValue === option.rawValue,
            )}
          />
          {listItemTemplate
            ? (listItemTemplate as TemplateCallbackType)(option)
            : option.label}
        </div>
      );
    },
    [listItemTemplate, loading, options.length, selectedOptions],
  );

  useEffect(() => {
    if (!options.length) {
      handleGetList("");
    }
  }, [handleGetList, options.length]);

  useEffect(() => {
    setFilterOperation(operation);

    let newFilterValue = selectedOptions;

    if (operation === "NotEqual") {
      newFilterValue = exceptions;
    }

    setFilterValue?.(newFilterValue);
  }, [
    column,
    exceptions,
    operation,
    selectedOptions,
    setFilterOperation,
    setFilterValue,
  ]);

  return (
    <Container>
      <div className="filter-display">{renderSelectedOptions()}</div>
      <div className="filter-header">
        <div className="p-inputgroup">
          {/* FIX substituimos o componente InputText do primereact por um input
           nativo estilizado com as classes do primereact. Por ora isso resolveu
            o problema que fazia com que a navbar desaparecesse quando este
            filtro era aberto na tela */}
          <input
            ref={ref => {
              ref?.focus?.();
            }}
            type="text"
            value={search}
            placeholder="Digite algo..."
            className="p-component p-inputtext"
            onChange={handleOnSearchValueChange}
            onFocus={handleOnSearchFocus}
          />
          <Button
            icon="pi pi-times"
            title="Limpar pesquisa"
            className="p-button-secondary"
            onClick={handleOnClearSearchClick}
          />
        </div>
      </div>
      <ListBox
        value={selectedOptions}
        options={options.length ? options : [{}]}
        listClassName="app-listbox-list"
        dataKey="rawValue"
        onChange={handleOnChange}
        itemTemplate={renderItemTemplate}
        multiple
      />
    </Container>
  );
}
