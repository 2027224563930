import { ColumnProps } from "primereact/column";
import { useMemo } from "react";
import { EUserProfile } from "../../../core/domain/entities/userEntity";

export function useCostCenterUsersGrid() {
  const payloadColumns = useMemo(() => {
    const profilesToShow = [EUserProfile.manager, EUserProfile.requester];
    return [
      {
        header: "id",
        field: "id",
        orderable: false,
        searchable: true,
      },
      {
        header: "name",
        field: "name",
        orderable: true,
        searchable: true,
      },
      {
        header: "email",
        field: "email",
        orderable: true,
        searchable: true,
      },
      {
        header: "profileDescription",
        field: "profileDescription",
        orderable: true,
        searchable: true,
      },
      {
        header: "profile",
        field: "profile",
        orderable: true,
        searchable: true,
        searchParam: profilesToShow.join(","),
      },
    ];
  }, []);

  const columns = useMemo(() => {
    const temp: ColumnProps[] = [
      {
        field: "name",
        header: "Nome",
        sortable: true,
        style: {
          flex: "0 0 40%",
          maxWidth: "40%",
          wordBreak: "break-all",
        },
      },
      {
        field: "email",
        header: "E-mail",
        sortable: true,
        style: {
          flex: "0 0 40%",
          maxWidth: "40%",
          wordBreak: "break-all",
        },
      },
      {
        field: "profileDescription",
        header: "Perfil",
        sortable: true,
        style: {
          flex: "0 0 20%",
          maxWidth: "20%",
        },
      },
    ];
    return temp;
  }, []);

  return {
    columns,
    payloadColumns,
  };
}
