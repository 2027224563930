import styled from "styled-components";

interface IContainerProps {
  "data-rownumber"?: string;
}

export const Container = styled.div<IContainerProps>`
  &.card {
    position: relative;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0px 2px 5px 1px #00000022;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    flex: 1;
    color: #888686;

    &:before {
      content: attr(data-rownumber);
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      font-size: 0.75rem;
      margin-left: 0.75rem;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-weight: 800;
      color: #bbb;
    }

    .left-col {
      flex: 0 1 75%;

      .top-info,
      .bottom-info {
        display: flex;
        text-transform: uppercase;
        justify-content: space-between;

        > div {
          max-width: 13.025rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .top-info {
        font-weight: 900;
      }

      .top-info + .bottom-info {
        margin-top: 0.5rem;
      }

      /* value */
      & + .right-col {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: end;
        flex: 1 0;
        margin-left: 0.5rem;
        font-weight: 900;

        span.value {
          &:not(.negative) {
            color: var(--soul-green-button);
          }

          &.negative {
            color: var(--soul-red-button);
          }
        }
      }
    }

    & + .card {
      margin-top: 1rem;
    }
  }
`;
