export interface ISaveCostCentersTransactionsImportationService {
  saveCostCentersTransactionsImportation(file: File): Promise<unknown>;
}

export interface ISaveCostCentersTransactionsImportationUseCase {
  saveCostCentersTransactionsImportation(file: File): Promise<unknown>;
}

export class SaveCostCentersTransactionsImportationUseCase
  implements ISaveCostCentersTransactionsImportationUseCase
{
  constructor(
    private saveCostCentersTransactionsImportationService: ISaveCostCentersTransactionsImportationService,
  ) {}

  saveCostCentersTransactionsImportation(file: File) {
    return this.saveCostCentersTransactionsImportationService.saveCostCentersTransactionsImportation(
      file,
    );
  }
}
