import styled from "styled-components";

interface ContainerProps {
  selectable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .table-perPage {
      gap: 0.5rem;
      color: #6c757d;
      align-items: center;
      display: inline-flex;

      select {
        max-width: 70px;

        padding: 0.25rem 0.5rem 0.25rem 0.5rem;

        border-radius: 0.25rem;
        border: 1px solid var(--input-border-color);

        font-size: 0.875rem;

        color: #495057;
        background-color: #fff;

        :focus {
          outline: none;
          border-color: rgba(50, 151, 211, 0.25);
          box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }

        ::placeholder {
          color: #d1d1d9;
        }
      }
    }

    .table-selectedOnly {
      gap: 0.5rem;
      display: flex;
      align-items: center;

      color: #6c757d;
      font-size: 0.85rem;
    }

    .table-filter {
      gap: 0.5rem;
      align-items: center;
      display: inline-flex;

      border-radius: 0.25rem;
      border: 1px solid var(--input-border-color);

      padding: 0rem 0.5rem 0rem 0.5rem;

      :focus-within {
        border-color: rgba(50, 151, 211, 0.25);
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      }

      svg {
        color: #b1bac4;
      }

      input {
        border: none;
        outline: none;
        appearance: none;

        color: #495057;
        font-size: 0.875rem;

        border-left: none;

        border-radius: 0 0.25rem 0.25rem 0;

        padding: 0.25rem 0rem 0.25rem 0rem;

        ::placeholder {
          color: #d1d1d9;
        }
      }
    }
  }

  .p-datatable {
    .p-datatable-thead > tr > th {
      background-color: #f6f9fc;
      font-size: 0.65rem;
      text-transform: uppercase;
      color: #8898aa;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .p-datatable-tbody > tr {
      &.p-highlight,
      &.p-row-odd.p-highlight {
        background: ${({ selectable }) => (selectable ? "#525f7f" : "#c4f1de")};

        td {
          color: ${({ selectable }) => (selectable ? "#525f7f" : "#485370")};
        }
      }

      .default-badge {
        gap: 0.5rem;
        align-items: center;
        display: inline-flex;

        span {
          color: #ffffff;
          background: #4a69bd;

          font-size: 0.6rem;
          font-weight: 600;

          padding: 0.25rem;
          border-radius: 0.25rem;
        }
      }

      & > td {
        padding: 0.375rem 0.75rem;
        /* TODO criar uma variavel de css no global.ts para este valor */
        color: #485370;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .table-actionbar {
          display: flex;
          justify-content: center;
          flex-grow: 1;
        }
      }
    }

    /**
      NOTE - Esse caso cobre apenas para tabelas com display: table. O prime
      altera o display da tabela dependo das props passadas, então a regra abaixo
      deve ser sobrescrita no componente para atender a devida demanda.
    */
    tr.p-datatable-emptymessage {
      td {
        padding: 1rem;
        text-align: center;
      }
    }
  }

  .p-datatable-thead {
    display: none;
  }

  .p-paginator.p-component {
    font-size: 1rem;
    border-radius: 0;
    border-bottom: none;

    .p-paginator-page {
      border: 1px solid #e2e5e9;
      font-size: 1rem;

      &.p-highlight {
        background: #090081;
        color: white;
      }
    }

    .p-paginator-element {
      width: 3rem;
      height: 3rem;
    }

    .current-page-report {
      display: flex;
      height: 2.375rem;
      justify-content: start;
      align-items: center;
      flex-grow: 1;
    }

    .p-dropdown-label {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }

    .rows-per-page-dropdown-wrapper,
    .rows-per-page-dropdown-wrapper .p-dropdown-label.p-inputtext {
      display: flex;
      min-width: 40px;
      align-items: center;
    }
  }
`;
