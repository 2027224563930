import { ComponentProps, useState } from "react";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { SoulTypeahead } from "../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";

type TypeaheadFielProps = ComponentProps<typeof SoulTypeahead> & {
  label: string;
  searchFunction(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
};

interface TypeaheadFieldState {
  isLoading: boolean;
  options: ITypeaheadOption[];
}

export function TypeaheadField(props: TypeaheadFielProps) {
  const { label, searchFunction, ...rest } = props;

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const [state, setState] = useState<TypeaheadFieldState>({
    options: [],
    isLoading: false,
  });

  const handleSearchChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchFunction({
        search,
        companyGroupId,
      });

      setState(prevState => ({
        ...prevState,
        isLoading: false,
        options: response.data,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  return (
    <>
      <span>
        {label} {state.isLoading && <i className="pi pi-spin pi-spinner" />}
      </span>
      <SoulTypeahead
        {...rest}
        serverSide
        openOnFocus
        options={state.options}
        loading={state.isLoading}
        onSearchChange={handleSearchChange}
      />
    </>
  );
}
