import styled from "styled-components";

export const Container = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  color: white;
  /* line-height: 1.5; */
  padding: 0.5rem 0;

  .row {
    align-items: center;
    justify-content: flex-end;

    > :nth-child(2) {
      margin-right: 1.5rem;
    }
  }

  .dropdown-button {
    font-weight: 600;
    font-size: 0.875rem !important;
  }

  .context-dropdown-icon {
    font-size: 0.625rem;
  }

  .context-selector {
    display: flex;
    align-items: center;
    padding: 0.25rem 0 0.25rem 0.75rem;

    .context-logo {
      width: 2.25rem;
      height: 2.25rem;
      background-color: #fff;
      display: inline-flex;
      border-radius: 50%;
    }

    .context-title {
      margin-left: 0.5rem;
    }
  }
`;
