import { useCallback, useState } from "react";
import { FieldError, FieldErrors, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import {
  EAccountSubmitMode,
  AccountFormSplitButton,
} from "../../../../../../core/presentation/components/AccountFormSplitButton";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import {
  EAccountReceivableStatus,
  EFormMode,
} from "../../../domain/entities/accountReceivableEnums";
import {
  AccountReceivableFormEntity,
  IAccountReceivableFormEntity,
} from "../../../domain/entities/accountReceivableFormEntity";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";

interface ISectionFooterState {
  total: number;
  loaded: number;
  isUploadModalOpen: boolean;
  isProviderModalOpen: boolean;
  isAttachmentsModalOpen: boolean;
}

interface ISectionFooterProps {
  mode: EFormMode;
  readonly?: boolean;
  useReceivableForm: MakeAccountsReceivableForm;
}

interface ISubmitHandlerParams {
  submitMode: EAccountSubmitMode;
  newReceivableId: string | null;
}

export function SectionFooter(props: ISectionFooterProps) {
  const { mode, readonly, useReceivableForm } = props;

  const [state, setState] = useState<ISectionFooterState>({
    total: 0,
    loaded: 0,
    isUploadModalOpen: false,
    isProviderModalOpen: false,
    isAttachmentsModalOpen: false,
  });

  const {
    uploadAttachments,
    validateAttachments,
    saveAccountReceivable,
    updateAccountReceivable,
    changeInconsistentStatus,
  } = useReceivableForm;

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const form = useFormContext<IAccountReceivableFormEntity>();

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onUploadProgress = (event: ProgressEvent) => {
    setState(prevState => ({
      ...prevState,
      total: event.total,
      loaded: event.loaded,
    }));
  };

  const updateAttachments = useCallback(
    async (receivableId: string, formData: IAccountReceivableFormEntity) => {
      const attachments = formData.storageFiles;

      if (attachments.length === 0) {
        return;
      }

      await uploadAttachments({
        attachments,
        receivableId,
        onUploadProgress,
      });
    },
    [uploadAttachments],
  );

  const handleSendReceivable = useCallback(
    async (formValues: IAccountReceivableFormEntity) => {
      const { id } = formValues;

      if (mode === EFormMode.Conditional) {
        await changeInconsistentStatus(formValues);
        return null;
      }

      if (mode === EFormMode.EditAttachments) {
        await updateAttachments(id, formValues);
        return id;
      }

      const serviceFn =
        mode === EFormMode.Create
          ? saveAccountReceivable
          : updateAccountReceivable;

      const receivableId = await serviceFn(formValues);

      await updateAttachments(receivableId, formValues);

      return receivableId;
    },
    [
      mode,
      updateAttachments,
      saveAccountReceivable,
      updateAccountReceivable,
      changeInconsistentStatus,
    ],
  );

  const successDialog = useCallback(() => {
    const createMessage = (
      <>Lançamento de conta a receber cadastrado com sucesso.</>
    );

    const editMessage = (
      <>Lançamento de conta a receber atualizado com sucesso.</>
    );

    return dialog.fire({
      icon: "success",
      title: "Feito!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      html: mode === EFormMode.Create ? createMessage : editMessage,
    });
  }, [dialog, mode]);

  const handleSubmitMode = useCallback(
    ({ submitMode, newReceivableId }: ISubmitHandlerParams) => {
      if (submitMode === EAccountSubmitMode.AddMore) {
        reset(new AccountReceivableFormEntity());
        navigate(`${SoulRoutes.ACCOUNTS_RECEIVABLE.path}/new`);
        return;
      }

      if (submitMode === EAccountSubmitMode.Duplicate) {
        setValue("duplicateAccountReceivableId", newReceivableId);
        navigate(
          `${SoulRoutes.ACCOUNTS_RECEIVABLE.path}/new?duplicate=${newReceivableId}`,
        );
        return;
      }

      navigate(`${SoulRoutes.ACCOUNTS_RECEIVABLE.path}`);
    },
    [navigate, reset, setValue],
  );

  const handleOnValid = useCallback(
    (submitMode: EAccountSubmitMode) => {
      return async (formData: IAccountReceivableFormEntity) => {
        if (readonly) {
          return;
        }

        const attachments = formData.storageFiles;
        const parcelsList = formData.accountReceivableParcels;

        setState(prevState => ({
          ...prevState,
          isUploadModalOpen: true,
        }));

        try {
          if (attachments.length !== 0 && attachments.some(a => !!a.file)) {
            await validateAttachments(attachments);
          }

          const hasSomePaidParcels = parcelsList.some(parcel => {
            return parcel.status === EAccountReceivableStatus.Paid;
          });

          if (mode !== EFormMode.Create && hasSomePaidParcels) {
            const result = await dialog.fire({
              icon: "question",
              showCancelButton: true,
              cancelButtonText: "Não",
              confirmButtonText: "Sim",
              title: "Você está certo disso?",
              text: "Você deseja salvar as alterações nesse lançamento?",
            });

            if (result.dismiss) {
              setState(prevState => ({
                ...prevState,
                isUploadModalOpen: false,
              }));

              return;
            }
          }

          const newReceivableId = await handleSendReceivable(formData);

          await successDialog();

          handleSubmitMode({
            submitMode,
            newReceivableId,
          });
        } catch (error) {
          const typedError = error as Record<string, string>;

          if (typedError?.type === "invalid-attachments") {
            dialog.fire({
              icon: "error",
              title: "Erro",
              text:
                typedError?.message ||
                "Algo deu errado. Por favor, verifique os anexos e tente novamente.",
            });
          }
        } finally {
          setState(prevState => ({
            ...prevState,
            total: 0,
            loaded: 0,
            isUploadModalOpen: false,
          }));
        }
      };
    },
    [
      mode,
      dialog,
      readonly,
      successDialog,
      handleSubmitMode,
      validateAttachments,
      handleSendReceivable,
    ],
  );

  const handleOnInvalid = useCallback(
    async (errors: FieldErrors<IAccountReceivableFormEntity>) => {
      if (readonly) {
        return;
      }

      const errorsValues = Object.values(errors);
      const errorsTypes = errorsValues.map(error => (error as FieldError).type);

      if (errorsTypes.includes("required")) {
        dialog.fire({
          icon: "warning",
          title: "Atenção",
          confirmButtonText: "Ok",
          showCancelButton: false,
          html: (
            <>
              Existem campos obrigatórios não preenchidos. Preencha-os para
              concluir o lançamento da conta a receber.
            </>
          ),
        });
      }
    },
    [dialog, readonly],
  );

  const handleFormSubmission = (submitMode: EAccountSubmitMode) => {
    handleSubmit(handleOnValid(submitMode), handleOnInvalid)();
  };

  const renderSubmitButton = () => {
    if (readonly) {
      return null;
    }

    if (mode === EFormMode.Conditional) {
      return (
        <button
          type="button"
          disabled={isSubmitting}
          className="form-button green-bkg"
          onClick={() => {
            handleFormSubmission(EAccountSubmitMode.Default);
          }}
        >
          Concluir
        </button>
      );
    }

    return (
      <AccountFormSplitButton
        isSubmitting={isSubmitting}
        onClick={handleFormSubmission}
      />
    );
  };

  const handleBackButton = () => {
    navigate(`${SoulRoutes.ACCOUNTS_RECEIVABLE.path}`);
  };

  return (
    <Container>
      <button
        id="btn-back"
        type="button"
        data-testid="btn-back"
        disabled={isSubmitting}
        onClick={handleBackButton}
        className="form-button red-bkg"
      >
        Voltar
      </button>
      {renderSubmitButton()}
      <RequestProgressModal
        total={state.total}
        loaded={state.loaded}
        isOpen={state.isUploadModalOpen}
      />
    </Container>
  );
}
