import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeletePaymentAccountReportPanoramaContract } from "../../domain/contracts/deletePaymentAccountReportPanoramaContract";

export class DeletePaymentAccountReportPanoramaService
  implements IDeletePaymentAccountReportPanoramaContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deletePaymentAccountReportPanorama(panoramaId: string) {
    const url = `/PaymentAccountReportPanoramas/${panoramaId}`;
    const userEntity = this.getUserLocalService.get();

    await this.api.delete(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
