import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .crud-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  form {
    > .form-container {
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.05);

      &:last-of-type {
        border: unset;
        padding: 0 1rem;
      }

      .form-header {
        color: #525f7f;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
    }

    .label-with-spinner {
      gap: 0.25rem;
      display: flex;
      align-items: center;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .smaller-label {
    font-size: 13px;

    small {
      font-size: 10px;
    }
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }
`;
