import styled from "styled-components";

export const ParcelTableContainer = styled.div`
  td.p-editable-column.p-cell-editing {
    width: 25%;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody {
    & > tr,
    & > tr.p-row-odd,
    & > tr.p-highlight,
    & > tr.p-row-odd.p-highlight {
      height: 4.375rem;

      &.row-paid {
        background: #d0f1e6;

        &:is(.p-row-odd) {
          background: #dcf5ec;
        }

        &:hover {
          background: #ebf9f4;
        }
      }

      &.row-open {
        background: #ffe4bb;

        &:is(.p-row-odd) {
          background: #ffe9c7;
        }

        &:hover {
          background: #fff1db;
        }
      }

      &.row-canceled {
        background-color: #ffffff;

        td {
          color: #b5bccf;
        }
      }
    }
  }

  label.form-control {
    width: 100%;
    display: block;

    input {
      width: 100%;

      font-family: var(--font-family);
      flex: 1 1 auto;
      padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      line-height: 1.5;
      border-style: solid;
      border-width: 1px;
      border-color: var(--input-border-color);
      height: calc(1.5em + 0.5rem + 2px);
      color: #495057 !important;
      background-color: #fff;

      :focus {
        outline: unset;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        border-color: rgba(50, 151, 211, 0.25);
      }

      ::placeholder {
        color: #d1d1d9;
      }

      :disabled {
        background-color: #e9ecef;
        color: #495057 !important;
      }

      &.isInvalid {
        border-color: var(--invalid);

        :focus {
          box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
        }
      }
    }
  }
`;
