import { useMemo } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IAdvTableColumn } from "../../../domain/entities/advTableColumn";
import { Container } from "./styles";

interface ColumnSelectorProps {
  columns: IAdvTableColumn[];
  selectedColumns: IAdvTableColumn[];
  onSelect: (column: IAdvTableColumn) => void;
}

export function ColumnSelector({
  columns,
  selectedColumns,
  onSelect,
}: ColumnSelectorProps) {
  const renderColumns = useMemo(() => {
    return columns
      .filter(column => !(column as ISimpleHiddenColumn).hidden)
      .filter(column => (column as ISimpleColumn).header)
      .filter(column => {
        const col = column as ISimpleColumn;

        return !selectedColumns.some(selectedColum => {
          const selCol = selectedColum as ISimpleColumn;
          return selCol.header === col.header;
        });
      })
      .sort((colA, colB) => {
        const headerA = (colA as ISimpleColumn).header ?? "";
        const headerB = (colB as ISimpleColumn).header ?? "";

        switch (true) {
          case headerA < headerB:
            return -1;

          case headerB < headerA:
            return 1;

          default:
            return 0;
        }
      });
  }, [columns, selectedColumns]);

  return (
    <Container className={!renderColumns.length ? "empty" : undefined}>
      {renderColumns.map(column => {
        const { header, field } = column as ISimpleColumn;

        return (
          <button
            key={field}
            type="button"
            title={header}
            onClick={() => {
              onSelect(column);
            }}
          >
            <span className="label">{header}</span>
            <span className="icon">
              <FaPlus />
            </span>
          </button>
        );
      })}
    </Container>
  );
}
