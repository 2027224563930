import { Tooltip } from "primereact/tooltip";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { IGenericAssessmentEntity } from "../../../domain/entities/genericAssessmentEntitty";
import {
  IAssessmentsGridProps,
  useAssessmentsGrid,
} from "../../hooks/useAssessmentsGrid";
import { AssessmentFormModal } from "../AssessmentFormModal";
import { Card } from "../Card/styles";
import { ClientSoulTable } from "../ClientSoulTable";
import { InputSearch } from "../InputSearch";
import { Container } from "./styles";

export function AssessmentGrid<T extends IGenericAssessmentEntity>({
  assessments,
  accountValue,
  readonly = false,
  searchCostCenter,
  onAssessmentsChange,
  listActiveCostCenters,
  searchClassificationAssessment,
  emptyMessage = "Nenhum rateio encontrado.",
  shouldShowCalculateAssessmentButton = false,
  observationMaxLength = 140,
}: IAssessmentsGridProps<T>) {
  const {
    state,
    fields,
    columns,
    tooltipRef,
    defineRowClassName,
    handleDataTableRef,
    handleRowMouseEnter,
    handleFormModalSubmit,
    handleInputSearchChange,
    handleAddAssessmentButtonClick,
    handleAssessmentFormModalRequestClose,
    handleRecalculateAssessmentButtonClick,
  } = useAssessmentsGrid({
    readonly,
    assessments,
    accountValue,
    emptyMessage,
    searchCostCenter,
    onAssessmentsChange,
    listActiveCostCenters,
    searchClassificationAssessment,
    shouldShowCalculateAssessmentButton,
    observationMaxLength,
  });

  const { search, isModalOpen, isRecalculating, editAssessmentIndex } = state;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [assessments]);

  return (
    <Card className="modal-table">
      <Container>
        <header>
          <span className="modal-table__header__span">
            Informações de Rateios
          </span>
          {shouldShowCalculateAssessmentButton && (
            <button
              type="button"
              id="btn-recalculate"
              disabled={readonly}
              className="default-button"
              onClick={handleRecalculateAssessmentButtonClick}
            >
              Recalcular rateio{" "}
              {isRecalculating ? <i className="pi pi-spin pi-spinner" /> : null}
            </button>
          )}
          <button
            id="btn-add"
            type="button"
            className="default-button"
            disabled={readonly}
            onClick={handleAddAssessmentButtonClick}
          >
            <FaPlus /> Adicionar rateio
          </button>
        </header>
        <div className="searchbar">
          <InputSearch
            value={search}
            id="txt-assessments-search"
            data-testid="txt-assessments-search"
            onChange={handleInputSearchChange}
          />
        </div>
        <ClientSoulTable
          rowHover
          paginator
          data={fields}
          columns={columns}
          globalFilter={search}
          rowsPerPageOptions={[100]}
          emptyMessage={emptyMessage}
          dataTableRef={handleDataTableRef}
          rowClassName={defineRowClassName}
          onRowMouseEnter={handleRowMouseEnter}
          globalFilterFields={[
            "value",
            "percentage",
            "observation",
            "formattedValue",
            "costCenter.label",
            "formattedPercentage",
            "classificationAssessment.label",
          ]}
        />
        <Tooltip position="top" ref={tooltipRef} style={{ maxWidth: "100%" }} />
        <AssessmentFormModal
          isOpen={isModalOpen}
          assessmentsList={fields}
          accountValue={accountValue}
          searchCostCenter={searchCostCenter}
          editAssessmentIndex={editAssessmentIndex}
          onSubmitAssessments={handleFormModalSubmit}
          onRequestClose={handleAssessmentFormModalRequestClose}
          searchClassificationAssessment={searchClassificationAssessment}
          observationMaxLength={observationMaxLength}
        />
      </Container>
    </Card>
  );
}
