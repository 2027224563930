import styled from "styled-components";

export const Container = styled.div`
  .header {
    display: flex;
    padding: 0 1.5rem;

    > div {
      flex: 0 1 auto;
      display: flex;

      &:not(.dropdown) {
        overflow: hidden;
      }

      label {
        display: flex;
        flex: 0 1 auto;
        cursor: pointer;
        overflow: hidden;
        padding: 1rem 0;

        span {
          flex: 0 1 auto;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 0.5rem;
          user-select: none;
        }
      }

      & + div {
        margin-left: 0.75rem;
      }
    }

    > .tgl {
      margin-right: 1rem;
    }

    > .dropdown {
      margin-left: auto;
      padding: 1rem 0;
    }
  }

  .body {
    padding: 0 1.5rem 1.5rem;

    .panel {
      position: relative;
      background: #f6f9fc;
      height: calc(100vh - 20rem);
      overflow: auto;

      > .wrapper {
        padding: 0 1rem;
        width: 71.25rem;
        margin: 1rem auto;

        .row-empty {
          .not-found-msg {
            font-weight: 900;
            line-height: 1.5rem;
            text-transform: uppercase;
            color: #90adcb;
          }
        }

        .spacer {
          margin: 12rem 0;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 0 1.5rem 1.5rem;

    .template-button {
      margin-left: 0;
    }

    .back-button {
      margin-left: auto;
    }
  }
`;
