import { useMemo, useState, useCallback } from "react";

import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";

import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { MakeMacroCategory } from "../../../main/makeMacroCategory";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { Container } from "./styles";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IMacroCategoryFormEntity } from "../../../domain/entities/macroCategoryFormEntity";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";

interface MacroCategoryModalProps {
  isOpen: boolean;
  currentId: string;
  useMacroCategory: MakeMacroCategory;
  onRequestClose(): void;
}

export function MacroCategoryFormModal({
  isOpen,
  currentId,
  onRequestClose,
  useMacroCategory,
}: MacroCategoryModalProps) {
  const { saveMacroCategory, getMacroCategory, updateMacroCategory } =
    useMacroCategory;
  const [isLoading, setIsLoading] = useState(false);

  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();

  const readonly = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.master,
      EUserProfile.supervisor,
      EUserProfile.financialManagement,
    );
  }, [allowedProfiles]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<IMacroCategoryFormEntity>({
    mode: "all",
    defaultValues: {
      id: "",
      name: "",
      active: true,
      companyGroupId: currentCompanyGroup.id,
    },
  });

  const handleAfterClose = () => {
    reset();
  };

  const handleModalOpen = useCallback(async () => {
    if (!currentId) return;

    setIsLoading(true);

    try {
      const formValues = await getMacroCategory(currentId);
      reset(formValues, { keepDefaultValues: true });
    } catch {
      onRequestClose();
    } finally {
      setIsLoading(false);
    }
  }, [currentId, getMacroCategory, onRequestClose, reset]);

  const submitMacroCategory = async (data: IMacroCategoryFormEntity) => {
    const actionText = currentId ? "atualizada" : "cadastrada";

    try {
      if (currentId) {
        await updateMacroCategory(currentId, data);
      } else {
        dialog.fire({
          title: "Aviso",
          backdrop: false,
          text: "Processando dados - Aguarde enquanto os dados de Macrocategorias são atualizados.",
          didOpen: () => {
            dialog.showLoading();
          },
        });

        await saveMacroCategory(data);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Macrocategoria ${actionText} com sucesso!`,
      });

      onRequestClose();
    } catch (error) {
      dialog.close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={handleModalOpen}
      onRequestClose={onRequestClose}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      shouldCloseOnEsc={!isLoading && !isSubmitting}
      shouldCloseOnOverlayClick={!isLoading && !isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{currentId ? "Editar Macrocategoria" : "Nova Macrocategoria"}</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              disabled={isSubmitting}
              onClick={onRequestClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : (
          <form
            className="form-container row"
            onSubmit={handleSubmit(submitMacroCategory)}
          >
            <div className="col-12 react-modal-body">
              <div className="card card-header-border">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{currentCompanyGroup.name}</h3>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    id="name"
                    type="text"
                    placeholder="Nome"
                    disabled={readonly}
                    data-testid="txt-name"
                    {...register("name", { required: true })}
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                id="btn-close"
                onClick={onRequestClose}
                disabled={isSubmitting}
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-save"
                disabled={readonly || isSubmitting}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Salvar {isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
