import { ELocalStorageKeys } from "../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../core/domain/entities/stringfied";
import { IGetStoredPaymentAccountReportPanoramaIdContract } from "../../domain/contracts/getStoredPaymentAccountReportPanoramaIdContract";

export class GetStoredPaymentAccountReportPanoramaIdService
  implements IGetStoredPaymentAccountReportPanoramaIdContract
{
  getStoredPaymentAccountReportPanoramaId(): string {
    const key = ELocalStorageKeys.CostCenterReportPanoramaId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
