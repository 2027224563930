import { IEntity } from "../../../../core/domain/entities/entity";

export interface IProjectEntity extends IEntity {
  description: string;
}

export class ProjectEntity implements IProjectEntity {
  id = "";
  description = "";
  active = true;

  constructor(params?: Partial<IProjectEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IProjectEntity>) {
    return new ProjectEntity(params);
  }
}
