import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICustomerHasClassificationAccountContract } from "../../domain/contracts/customerHasClassificationAccountContract";

export class CustomerHasClassificationAccountService
  implements ICustomerHasClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async customerHasClassificationAccount(
    companyGoupId: string,
    customerId: string,
  ) {
    const url = `/CompanyGroups/${companyGoupId}/Customers/${customerId}/ClassificationAccounts`;

    const userEntity = this.getUserLocalService.get();

    const response = await this.api.get<IServerSideResponseModel<object[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const clsAccountList = response.data;

    return !!clsAccountList.length;
  }
}
