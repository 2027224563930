import FileSaver from "file-saver";
import { ChangeEvent, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaEye, FaTrash } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { IFetchRpaParamOptionsReturn } from "../../../domain/contracts/fetchRpaParamOptionsContract";
import {
  EAttachmentType,
  IAttachmentGridEntity,
  IAttachmentGridFileGeneration,
  IOnAttachmentTypeChangeParams,
  IUpdateAttachmentsBarcodeResult,
  IValidadeMeasurementAttachmentResult,
} from "../../../domain/entities/attachmentsGridTypes";
import { IEnum } from "../../../domain/entities/enum";
import { IGenerateRPAModalInputEntity } from "../../../domain/entities/generateRpaModalInputEntity";
import { useSoulDialog } from "../../hooks/useSoulDialog";
import { AttachmentGenerateRpaModal } from "../AttachmentGenerateRpaModal";
import { FileUpload } from "../FileUpload";
import { InvalidFeedback } from "../InvalidFeedback";
import { SoulSpinner } from "../SoulSpinner";
import { Container } from "./styles";
import { AttachmentGenerateCommercialProposalModal } from "../AttachmentGenerateCommercialProposalModal";
import { CommercialProposalEnums } from "../../../domain/contracts/fetchCommercialProposalEnumsContract";
import { IGenerateCommercialProposalForm } from "../../../domain/entities/generateCommercialProposalForm";

interface IAttachmentsGridFormModalState {
  loading: boolean;
  isRpaModalOpen: boolean;
  attachmentTypeList: IEnum[];
  bulkGeneratedTypes: EAttachmentType[];
  isCommercialProposalModalOpen: boolean;
}

interface IAttachmentsGridFormModalProps<T extends IAttachmentGridEntity> {
  isOpen: boolean;
  onRequestClose(): void;
  activeAttachments: T[];
  editAttachment: T | null;
  onEdit(newAttachments: T[]): void;
  onSubmit(newAttachments: T[], bulkGeneratedTypes: EAttachmentType[]): void;
  listAttachmentTypes(): Promise<IEnum[]>;
  fetchRpaParamOptions?(): Promise<IFetchRpaParamOptionsReturn>;
  generateVariableAdditionalAttachment?(): Promise<IAttachmentGridFileGeneration>;
  updateAttachmentsBarcode?(
    billetAttachment: T,
  ): Promise<IUpdateAttachmentsBarcodeResult<T>>;
  validateMeasurementAttachment?(
    measurementAttachment: T,
  ): Promise<IValidadeMeasurementAttachmentResult<T>>;
  attachmentTypeCheck?(
    params: IOnAttachmentTypeChangeParams<T>,
  ): Promise<boolean>;
  generateRpaAttachment?(
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ): Promise<IAttachmentGridFileGeneration>;
  fetchCommercialProposalEnums?(): Promise<
    Record<CommercialProposalEnums, IEnum[]>
  >;
  generateCommercialProposalAttachment?(
    params: IGenerateCommercialProposalForm,
  ): Promise<IAttachmentGridFileGeneration>;
}

export function AttachmentsGridFormModal<T extends IAttachmentGridEntity>(
  props: IAttachmentsGridFormModalProps<T>,
) {
  const {
    isOpen,
    onEdit,
    onSubmit,
    onRequestClose,
    editAttachment,
    activeAttachments,
    listAttachmentTypes,
    attachmentTypeCheck,
    fetchRpaParamOptions,
    generateRpaAttachment,
    updateAttachmentsBarcode,
    validateMeasurementAttachment,
    generateVariableAdditionalAttachment,
    fetchCommercialProposalEnums,
    generateCommercialProposalAttachment,
  } = props;

  const dialog = useSoulDialog();

  const form = useForm<{ newAttachments: IAttachmentGridEntity[] }>({
    mode: "all",
    defaultValues: {
      newAttachments: [],
    },
  });

  const {
    reset,
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = form;

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: "newAttachments",
  });

  const [state, setState] = useState<IAttachmentsGridFormModalState>({
    loading: true,
    isRpaModalOpen: false,
    attachmentTypeList: [],
    bulkGeneratedTypes: [],
    isCommercialProposalModalOpen: false,
  });

  const handleAfterOpen = async () => {
    if (editAttachment) {
      replace([editAttachment]);
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const attachmentTypeList = await listAttachmentTypes();

      setState(prevState => ({
        ...prevState,
        loading: false,
        attachmentTypeList,
      }));
    } catch {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleDropAccepted = (files: File[]) => {
    const newFiles = files.map(file => ({
      file,
      active: true,
      name: file.name,
      type: EAttachmentType.None,
    }));

    append(newFiles);
  };

  const handleDropRejected = (error: string) => {
    dialog.fire({
      icon: "error",
      title: "Opa!",
      text: error,
    });
  };

  const handleRpaModalOpen = async () => {
    const currentAttachments = getValues("newAttachments");

    const modalAttachments = [
      ...activeAttachments,
      ...currentAttachments,
    ] as T[];

    if (attachmentTypeCheck) {
      const shouldContinue = await attachmentTypeCheck({
        editAttachment,
        modalAttachments,
        typeToCheck: EAttachmentType.RPA,
      });

      if (!shouldContinue) {
        return;
      }
    }

    setState(old => ({
      ...old,
      isRpaModalOpen: true,
    }));
  };

  const handleRpaModalClose = () => {
    setState(old => ({ ...old, isRpaModalOpen: false }));
  };

  const handleGenerateRpa = async (
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ) => {
    if (!generateRpaAttachment) {
      return;
    }

    const { generatedFile, isBulkGeneration } = await generateRpaAttachment(
      genRPAModalInput,
    );

    const newFile = {
      active: true,
      file: generatedFile,
      typeDescription: "RPA",
      name: generatedFile.name,
      type: EAttachmentType.RPA,
    };

    append(newFile);

    const bulkGeneratedTypes = state.bulkGeneratedTypes.concat(
      isBulkGeneration ? [EAttachmentType.RPA] : [],
    );

    setState(old => ({
      ...old,
      bulkGeneratedTypes,
      isRpaModalOpen: false,
    }));
  };

  const handleGenerateAdditionalVariable = async () => {
    if (!generateVariableAdditionalAttachment) {
      return;
    }

    const currentAttachments = getValues("newAttachments");

    const modalAttachments = [
      ...activeAttachments,
      ...currentAttachments,
    ] as T[];

    if (attachmentTypeCheck) {
      const shouldContinue = await attachmentTypeCheck({
        editAttachment,
        modalAttachments,
        typeToCheck: EAttachmentType.VariableAdditional,
      });

      if (!shouldContinue) {
        return;
      }
    }

    const { generatedFile, isBulkGeneration } =
      await generateVariableAdditionalAttachment();

    const newFile = {
      active: true,
      file: generatedFile,
      name: generatedFile.name,
      typeDescription: "Adicional Variável",
      type: EAttachmentType.VariableAdditional,
    };

    append(newFile);

    const bulkGeneratedTypes = state.bulkGeneratedTypes.concat(
      isBulkGeneration ? [EAttachmentType.VariableAdditional] : [],
    );

    setState(old => ({
      ...old,
      bulkGeneratedTypes,
      isRpaModalOpen: false,
    }));
  };

  const handleViewAttachment = (index: number) => {
    const file = fields?.[index]?.file;

    if (file) {
      FileSaver(file, file.name);
    }
  };

  const handleRemoveNewAttachment = async (index: number) => {
    const result = await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      showCancelButton: true,
      html: (
        <>
          O anexo será removido.
          <br />
          Deseja prosseguir?
        </>
      ),
    });

    if (result.dismiss) {
      return;
    }

    const { type } = fields[index];

    const bulkGeneratedTypes = state.bulkGeneratedTypes.filter(
      bulkType => bulkType !== type,
    );

    setState(prevState => ({ ...prevState, bulkGeneratedTypes }));

    remove(index);

    dialog.fire({
      icon: "success",
      title: "Feito!",
      html: <>Anexo removido com sucesso!</>,
    });
  };

  const defineFormSubmission = async (formValues: {
    newAttachments: IAttachmentGridEntity[];
  }) => {
    const { newAttachments } = formValues;

    if (!newAttachments.length) {
      return;
    }

    const billetAttachmentIndex = newAttachments.findIndex(attachment => {
      return attachment.active && attachment.type === EAttachmentType.Billet;
    });

    if (billetAttachmentIndex !== -1 && updateAttachmentsBarcode) {
      const billetAttachment = newAttachments[billetAttachmentIndex];

      const { updatedAttachment, preventSubmit = false } =
        await updateAttachmentsBarcode(billetAttachment as T);

      if (preventSubmit) {
        return;
      }

      newAttachments[billetAttachmentIndex] = updatedAttachment;
    }

    const measurementAttachmentIndex = newAttachments.findIndex(attachment => {
      return (
        attachment.active && attachment.type === EAttachmentType.Measurement
      );
    });

    if (measurementAttachmentIndex !== -1 && validateMeasurementAttachment) {
      const measurementAttachment = newAttachments[measurementAttachmentIndex];

      const { updatedAttachment, preventSubmit = false } =
        await validateMeasurementAttachment(measurementAttachment as T);

      if (preventSubmit) {
        return;
      }

      newAttachments[measurementAttachmentIndex] = updatedAttachment;
    }

    const newAttachmentsWithInfo = newAttachments.map(newAttachment => {
      const typeDescription =
        state.attachmentTypeList.find(attachmentType => {
          return attachmentType.key === newAttachment.type;
        })?.value || "";

      return {
        ...newAttachment,
        typeDescription,
      };
    });

    if (editAttachment) {
      onEdit(newAttachmentsWithInfo as T[]);
      return;
    }

    onSubmit(newAttachmentsWithInfo as T[], state.bulkGeneratedTypes);
  };

  const shouldDisableType = (type: EAttachmentType) => {
    if (editAttachment) {
      return false;
    }

    const typesToDisable = [
      EAttachmentType.RPA,
      EAttachmentType.VariableAdditional,
      EAttachmentType.CommercialProposal,
    ];

    return typesToDisable.includes(type);
  };

  const isTypeBulkGeneration = (type: EAttachmentType) => {
    return state.bulkGeneratedTypes.includes(type);
  };

  const handleTypeChange = async (
    event: ChangeEvent<HTMLSelectElement>,
    currentAttachmentIndex: number,
  ) => {
    const newType = Number(event.target.value);

    const currentAttachments = getValues("newAttachments");

    const currentAttachmentsWithoutCurrentIndex = currentAttachments.filter(
      (_, attachmentIndex) => attachmentIndex !== currentAttachmentIndex,
    );

    const modalAttachments = [
      ...activeAttachments,
      ...currentAttachmentsWithoutCurrentIndex,
    ] as T[];

    if (attachmentTypeCheck) {
      const shouldContinue = await attachmentTypeCheck({
        editAttachment,
        typeToCheck: newType,
        modalAttachments,
      });

      if (!shouldContinue) {
        setValue(
          `newAttachments.${currentAttachmentIndex}.type`,
          EAttachmentType.None,
          {
            shouldValidate: true,
          },
        );
      }
    }
  };

  const verifyIfTypeIsHidden = (attachmentType: EAttachmentType) => {
    if (
      !fetchCommercialProposalEnums &&
      !generateCommercialProposalAttachment &&
      attachmentType === EAttachmentType.CommercialProposal
    ) {
      return true;
    }

    if (editAttachment !== null) {
      return false;
    }

    const conditionsByType: Partial<Record<EAttachmentType, boolean>> = {
      [EAttachmentType.RPA]: !!generateRpaAttachment,
      [EAttachmentType.VariableAdditional]:
        !!generateVariableAdditionalAttachment,
      [EAttachmentType.CommercialProposal]:
        !!generateCommercialProposalAttachment,
    };

    return conditionsByType?.[attachmentType] || false;
  };

  const handleCommercialProposalModalOpen = async () => {
    const currentAttachments = getValues("newAttachments");

    const modalAttachments = [
      ...activeAttachments,
      ...currentAttachments,
    ] as T[];

    if (attachmentTypeCheck) {
      const shouldContinue = await attachmentTypeCheck({
        editAttachment,
        modalAttachments,
        typeToCheck: EAttachmentType.CommercialProposal,
      });

      if (!shouldContinue) {
        return;
      }
    }

    setState(old => ({
      ...old,
      isCommercialProposalModalOpen: true,
    }));
  };

  const handleCommercialProposalModalClose = () => {
    setState(old => ({ ...old, isCommercialProposalModalOpen: false }));
  };

  const handleGenerateCommercialProposal = async (
    params: IGenerateCommercialProposalForm,
  ) => {
    if (!generateCommercialProposalAttachment) {
      return;
    }

    const { generatedFile, isBulkGeneration } =
      await generateCommercialProposalAttachment(params);

    const newFile = {
      active: true,
      file: generatedFile,
      name: generatedFile.name,
      typeDescription: "Proposta Comercial",
      type: EAttachmentType.CommercialProposal,
    };

    append(newFile);

    const bulkGeneratedTypes = state.bulkGeneratedTypes.concat(
      isBulkGeneration ? [EAttachmentType.CommercialProposal] : [],
    );

    setState(old => ({
      ...old,
      bulkGeneratedTypes,
      isCommercialProposalModalOpen: false,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={reset}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container hasAttachments={!!fields.length}>
        <div className="react-modal-header">
          <h4>{editAttachment ? "Editar anexo" : "Adicionar anexos"}</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.loading ? (
          <div className="loading-container">
            <SoulSpinner strokeWidth="6" />
          </div>
        ) : (
          <div className="react-modal-body">
            {!editAttachment ? (
              <div className="attachments-dropzone">
                <FileUpload
                  multiple
                  files={[]}
                  onRemoveFile={() => null}
                  onDropAccepted={handleDropAccepted}
                  onDropRejected={handleDropRejected}
                  placeholder="Solte os arquivos aqui ou clique para navegar"
                />
              </div>
            ) : null}
            <form
              id="new-attachments-form"
              className="attachments-fields"
              onSubmit={handleSubmit(defineFormSubmission)}
            >
              {!editAttachment ? (
                <div className="attachments-generation">
                  {generateRpaAttachment ? (
                    <button
                      type="button"
                      id="btn-generateRpa"
                      data-testid="btn-generateRpa"
                      className="form-button default-button"
                      onClick={handleRpaModalOpen}
                    >
                      Gerar e anexar RPA
                    </button>
                  ) : null}
                  {generateVariableAdditionalAttachment ? (
                    <button
                      type="button"
                      id="btn-generateVariable"
                      data-testid="btn-generateVariable"
                      className="form-button default-button"
                      onClick={handleGenerateAdditionalVariable}
                    >
                      Gerar e anexar Ad. Variável
                    </button>
                  ) : null}
                  {generateCommercialProposalAttachment ? (
                    <button
                      type="button"
                      id="btn-generateCommercialProposal"
                      className="form-button default-button"
                      data-testid="btn-generateCommercialProposal"
                      onClick={handleCommercialProposalModalOpen}
                    >
                      Gerar e anexar Proposta Comercial
                    </button>
                  ) : null}
                </div>
              ) : null}
              <ul className="form-container">
                {fields.map((field, index) => {
                  return (
                    <li key={field.id} className="form-row">
                      <label className="form-control">
                        <span>Tipo de Anexo</span>
                        <select
                          {...register(`newAttachments.${index}.type`, {
                            min: 1,
                            valueAsNumber: true,
                            onChange: e => {
                              handleTypeChange(e, index);
                            },
                          })}
                          className={
                            errors.newAttachments?.[index]?.type
                              ? "isInvalid"
                              : ""
                          }
                          disabled={shouldDisableType(field.type)}
                        >
                          <option value="0" hidden disabled>
                            Tipo de Anexo
                          </option>
                          {state.attachmentTypeList.map(option => {
                            return (
                              <option
                                key={option.value}
                                value={option.key}
                                hidden={verifyIfTypeIsHidden(option.key)}
                              >
                                {option.value}
                              </option>
                            );
                          })}
                        </select>
                        <InvalidFeedback
                          condition={!!errors.newAttachments?.[index]?.type}
                          message="Este campo é obrigatório"
                        />
                      </label>
                      <label className="form-control">
                        <span>Nome do anexo</span>
                        <input
                          {...register(`newAttachments.${index}.name`, {
                            required: true,
                            maxLength: 100,
                          })}
                          type="text"
                          id="txt-name"
                          placeholder="Nome"
                          data-testid="txt-name"
                          disabled={isTypeBulkGeneration(field.type)}
                          className={
                            errors.newAttachments?.[index]?.name
                              ? "isInvalid"
                              : ""
                          }
                        />
                        <InvalidFeedback
                          condition={
                            errors.newAttachments?.[index]?.name?.type ===
                            "required"
                          }
                          message="Este campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={
                            errors.newAttachments?.[index]?.name?.type ===
                            "maxLength"
                          }
                          message="O nome deve ter no máximo 100 caracteres"
                        />
                      </label>
                      {!editAttachment ? (
                        <div>
                          {!isTypeBulkGeneration(field.type) ? (
                            <button
                              type="button"
                              id={`btn-view-${index}`}
                              data-tip="Visualizar anexo"
                              className="outline-button tool"
                              data-testid={`btn-view-${index}`}
                              onClick={() => {
                                handleViewAttachment(index);
                              }}
                            >
                              <FaEye />
                            </button>
                          ) : null}
                          <button
                            type="button"
                            data-tip="Excluir anexo"
                            id={`btn-remove-${index}`}
                            className="outline-button tool"
                            data-testid={`btn-remove-${index}`}
                            onClick={() => {
                              handleRemoveNewAttachment(index);
                            }}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              <div className="attachments-conclusion">
                <button
                  type="button"
                  id="btn-close"
                  data-testid="btn-close"
                  className="form-button red-bkg"
                  onClick={onRequestClose}
                >
                  Fechar
                </button>
                <button
                  type="submit"
                  id="btn-submit"
                  data-testid="btn-submit"
                  form="new-attachments-form"
                  className={`form-button ${
                    isValid && !!fields.length ? "green-bkg" : "invalid-bkg"
                  }`}
                  disabled={isSubmitting}
                >
                  Confirmar{" "}
                  {isSubmitting && <i className="pi pi-spin pi-spinner" />}
                </button>
              </div>
            </form>
          </div>
        )}
      </Container>
      {fetchRpaParamOptions ? (
        <AttachmentGenerateRpaModal
          isOpen={state.isRpaModalOpen}
          onRequestClose={handleRpaModalClose}
          fetchRpaParamOptions={fetchRpaParamOptions}
          onSubmit={handleGenerateRpa}
        />
      ) : null}
      {fetchCommercialProposalEnums ? (
        <AttachmentGenerateCommercialProposalModal
          onSubmit={handleGenerateCommercialProposal}
          isOpen={state.isCommercialProposalModalOpen}
          onRequestClose={handleCommercialProposalModalClose}
          fetchCommercialProposalEnums={fetchCommercialProposalEnums}
        />
      ) : null}
    </Modal>
  );
}
