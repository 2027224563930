import { isAfter, isBefore, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";
import { InputBalance } from "../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { SoulTypeahead } from "../../../../core/presentation/components/SoulTypeahead";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ICostCenterResumedEntity } from "../../../domain/entities/costCenterEntity";
import {
  IProjectFormEntity,
  ProjectFormEntity,
} from "../../../domain/entities/projectFormEntity";
import { MakeProjects } from "../../../main/makeProjects";
import { SearchCostCenterField } from "../SearchCostCenterField";
import { Container } from "./style";
import { companyGroupConfig } from "../../../../admin/domain/entities/companyGroupConfig";

interface ProjectFormModalProps {
  isOpen: boolean;
  readonly?: true;
  projectId: string;
  useProjects: MakeProjects;
  onClose: (shouldRefresh: boolean | unknown) => void;
}

interface ProjectFormModalState {
  isLoading: boolean;
  competency: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
  coordinator: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
  deputyCoordinator: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
  costCenters: {
    loading: boolean;
    options: ICostCenterResumedEntity[];
  };
}

enum ProviderType {
  Coordinator = "coordinator",
  DeputyCoordinator = "deputyCoordinator",
}

export function ProjectFormModal(props: ProjectFormModalProps) {
  const { isOpen, useProjects, projectId, onClose, readonly } = props;

  const {
    getProject,
    saveProject,
    updateProject,
    searchProvider,
    searchCompetency,
    searchCostCenter,
    checkValidCostCenters,
  } = useProjects;

  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();
  const asyncDebounce = useDebounceTimeAsync();
  const allowedProfiles = useAllowedProfiles();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const showHashFusp =
    companyGroupConfig?.[currentCompanyGroup?.name]?.features?.PROJECTS
      ?.formFields?.fuspHash ?? true;

  const isDisabled = useMemo(() => {
    if (readonly) {
      return true;
    }

    return !allowedProfiles(
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles, readonly]);

  const formMethods = useForm<IProjectFormEntity>({
    mode: "all",
    defaultValues: ProjectFormEntity.create(),
  });

  const {
    reset,
    watch,
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = formMethods;

  const activitiesEndDateWatcher = watch("activitiesEndDate");
  const activitiesStartDateWatcher = watch("activitiesStartDate");

  const [state, setState] = useState<ProjectFormModalState>({
    isLoading: false,
    costCenters: {
      options: [],
      loading: false,
    },
    competency: {
      options: [],
      loading: false,
    },
    coordinator: {
      options: [],
      loading: false,
    },
    deputyCoordinator: {
      options: [],
      loading: false,
    },
  });

  const handleSearchCostCenters = async (search: string) => {
    if (search && search.length < 3) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      costCenters: {
        ...prevState.costCenters,
        loading: true,
      },
    }));

    try {
      const { data } = await searchCostCenter({
        search,
        showMetadata: true,
        companyGroupId: currentCompanyGroup.id,
        payloadOptions: {
          length: 50,
        },
      });

      const selected = getValues("costCenters");

      const mappedResponse = data
        .filter(dataObject => {
          return !selected
            .map(v => v.id)
            .includes(dataObject.rawValue as string);
        })
        .map(dataObject => {
          const metadata = dataObject.metadata as ICostCenterResumedEntity;

          return {
            acronym: dataObject.label,
            id: dataObject.rawValue as string,
            isDefaultForAccountReceivableImportation:
              metadata.isDefaultForAccountReceivableImportation,
          };
        });

      setState(prevState => ({
        ...prevState,
        costCenters: {
          ...prevState.costCenters,
          options: [...selected, ...mappedResponse],
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        costCenters: {
          ...prevState.costCenters,
          loading: false,
        },
      }));
    }
  };

  const handleSearchCompetency = async (search = "") => {
    await asyncDebounce(750);

    setState(prevState => ({
      ...prevState,
      competency: {
        ...prevState.competency,
        loading: true,
      },
    }));

    try {
      const response = await searchCompetency({
        search,
        activesOnly: true,
        companyGroupId: currentCompanyGroup.id,
      });

      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          options: response.data,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          loading: false,
        },
      }));
    }
  };

  const handleSearchProvider = (providerType: ProviderType) => {
    return async (search = "") => {
      await asyncDebounce(750);

      setState(prevState => ({
        ...prevState,
        [providerType]: {
          ...prevState[providerType],
          loading: true,
        },
      }));

      try {
        const response = await searchProvider({
          search,
          activesOnly: true,
          companyGroupId: currentCompanyGroup.id,
          payloadOptions: { length: search.length > 3 ? undefined : 25 },
        });

        setState(prevState => ({
          ...prevState,
          [providerType]: {
            loading: false,
            options: response.data,
          },
        }));
      } finally {
        setState(prevState => ({
          ...prevState,
          [providerType]: {
            ...prevState[providerType],
            loading: false,
          },
        }));
      }
    };
  };

  const handleModalOpening = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (projectId) {
        const projectData = await getProject({
          projectId,
          companyGroupId: currentCompanyGroup.id,
        });

        reset(projectData);
      }

      await handleSearchCostCenters("");
    } catch (err) {
      onClose(false);
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      ReactTooltip.rebuild();
    }
  };

  const onValid = async (values: IProjectFormEntity) => {
    const formValues = {
      ...values,
      companyGroupId: currentCompanyGroup.id,
    };

    const service = projectId ? updateProject : saveProject;

    await service(formValues);

    const actionMessage = projectId ? "atualizado" : "cadastrado";

    dialog.fire({
      icon: "success",
      title: "Feito!",
      text: `Projeto ${actionMessage} com sucesso.`,
    });

    onClose(true);
  };

  const validateDate = (value: string) => {
    if (!value) return true;
    return !dateValidator(value);
  };

  const validateStartDate = (value: string, endDate: string) => {
    if (!endDate) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(endDate, "ddMMyyyy", new Date());

    return isBefore(date, dateToCompare);
  };

  const validateEndDate = (value: string, startDate: string) => {
    if (!value) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(startDate, "ddMMyyyy", new Date());

    return isAfter(date, dateToCompare);
  };

  const defineTitle = () => {
    if (readonly) {
      return "Visualizando Projeto";
    }

    return projectId ? "Editar Projeto" : "Novo Projeto";
  };

  const handleModalClose = () => {
    reset(ProjectFormEntity.create());
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      shouldCloseOnEsc={!state.isLoading}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!state.isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{defineTitle()}</h4>
          {!state.isLoading ? (
            <button
              type="button"
              id="btn-crud-cross"
              data-testid="btn-crud-cross"
              className="react-modal-close"
              onClick={onClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <div className="crud-header">
              <p>
                Este registro {projectId ? "está" : "será"} vinculado ao grupo
                de empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <FormProvider {...formMethods}>
                <form
                  id="project-crud-form"
                  className="form-container"
                  onSubmit={handleSubmit(onValid)}
                >
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>Descrição (Sigla)</span>
                      <input
                        maxLength={100}
                        id="txt-description"
                        disabled={isDisabled}
                        data-testid="txt-description"
                        placeholder="Descrição (Sigla)"
                        className={errors?.description ? "isInvalid" : ""}
                        {...register("description", { required: true })}
                      />
                      <InvalidFeedback
                        condition={errors.description?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                    </label>
                    <Controller
                      control={control}
                      name="courseStartDate"
                      rules={{
                        validate: {
                          invalidDate: validateDate,
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-6 form-control">
                            <span>
                              Data de início do curso <small>(opcional)</small>
                            </span>
                            <InputMask
                              {...field}
                              unmask
                              mask="99/99/9999"
                              disabled={isDisabled}
                              id="txt-courseStartDate"
                              data-testid="txt-courseStartDate"
                              placeholder="Data de início do curso"
                              className={fieldState?.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              condition={
                                fieldState?.error?.type === "invalidDate"
                              }
                              message="Este campo é opcional, porém a data inserida está inválida"
                            />
                          </label>
                        );
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <Controller
                      control={control}
                      name="activitiesStartDate"
                      rules={{
                        validate: {
                          validDate: validateDate,
                          isBefore: v =>
                            validateStartDate(v, activitiesEndDateWatcher),
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-6 form-control">
                            <span>
                              Início das atividades <small>(opcional)</small>
                            </span>
                            <InputMask
                              {...field}
                              unmask
                              mask="99/99/9999"
                              disabled={isDisabled}
                              id="txt-activitiesStartDate"
                              placeholder="Período da atividade"
                              data-testid="txt-activitiesStartDate"
                              className={fieldState?.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              condition={
                                fieldState?.error?.type === "validDate"
                              }
                              message="Este campo é opcional, porém a data inserida está inválida"
                            />
                            <InvalidFeedback
                              condition={fieldState?.error?.type === "isBefore"}
                              message="A data inicial deve ser menor que a data final"
                            />
                          </label>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="activitiesEndDate"
                      rules={{
                        validate: {
                          validDate: validateDate,
                          isAfter: v =>
                            validateEndDate(v, activitiesStartDateWatcher),
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-6 form-control">
                            <span>
                              Final das atividades <small>(opcional)</small>
                            </span>
                            <InputMask
                              {...field}
                              unmask
                              mask="99/99/9999"
                              disabled={isDisabled}
                              id="txt-activitiesEndDate"
                              placeholder="Período da atividade"
                              data-testid="txt-activitiesEndDate"
                              className={fieldState?.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              condition={
                                fieldState?.error?.type === "validDate"
                              }
                              message="Este campo é opcional, porém a data inserida está inválida"
                            />
                            <InvalidFeedback
                              condition={fieldState?.error?.type === "isAfter"}
                              message="A data final deve ser maior que a data inicial"
                            />
                          </label>
                        );
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>
                        Nome por extenso <small>(opcional)</small>
                      </span>
                      <input
                        maxLength={250}
                        id="txt-fullName"
                        disabled={isDisabled}
                        data-testid="txt-fullName"
                        placeholder="Nome por extenso"
                        {...register("fullName")}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <Controller
                      control={control}
                      name="openingBalance"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-6 form-control">
                            <span>Saldo inicial</span>
                            <InputBalance
                              {...field}
                              resetToZero
                              placeholder="000,00"
                              disabled={isDisabled}
                              id="txt-openingBalance"
                              data-testid="txt-openingBalance"
                              className={fieldState?.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              condition={fieldState?.error?.type === "required"}
                              message="Este campo é obrigatório"
                            />
                          </label>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="competency"
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-6 form-control">
                            <span>Competência</span>
                            <SoulTypeahead
                              serverSide
                              openOnFocus
                              value={field.value}
                              id="txt-competency"
                              disabled={isDisabled}
                              onChange={field.onChange}
                              placeholder="Competência"
                              data-testid="txt-competency"
                              options={state.competency.options}
                              loading={state.competency.loading}
                              onSearchChange={handleSearchCompetency}
                              className={fieldState.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              condition={fieldState?.error?.type === "required"}
                              message="Este campo é obrigatório"
                            />
                          </label>
                        );
                      }}
                    />
                  </div>
                  {/* centro de custo */}
                  <div className="form-row costCenters-field">
                    <SearchCostCenterField
                      disabled={isDisabled}
                      projectId={projectId}
                      options={state.costCenters.options}
                      isLoading={state.costCenters.loading}
                      handleFilter={handleSearchCostCenters}
                      checkValidCostCenters={checkValidCostCenters}
                    />
                  </div>
                  {/* coordenador */}
                  <div className="form-row">
                    <Controller
                      control={control}
                      name="coordinator"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        return (
                          <label className="col-12 form-control">
                            <span className="coordinator-label">
                              Coordenador
                              <IconTooltip
                                position="right"
                                icon="pi pi-question-circle"
                              >
                                <p>
                                  <strong>Coordenador</strong>
                                </p>
                                <small>
                                  No Soul, o Coordenador é cadastrado como
                                  Fornecedor. A diferença é que ele estará
                                  vinculado a pelo menos um Projeto.
                                </small>
                              </IconTooltip>
                            </span>
                            <SoulTypeahead
                              serverSide
                              openOnFocus
                              value={field.value}
                              id="txt-coordinator"
                              disabled={isDisabled}
                              onChange={field.onChange}
                              data-testid="txt-coordinator"
                              loading={state.coordinator.loading}
                              options={state.coordinator.options}
                              className={fieldState.error ? "isInvalid" : ""}
                              placeholder="Pesquise e selecione um Coordenador"
                              onSearchChange={handleSearchProvider(
                                ProviderType.Coordinator,
                              )}
                            />
                            <InvalidFeedback
                              message="Esse campo é obrigatório"
                              condition={fieldState?.error?.type === "required"}
                            />
                          </label>
                        );
                      }}
                    />
                  </div>
                  {/* vice coordenador */}
                  <div className="form-row">
                    <Controller
                      control={control}
                      name="deputyCoordinator"
                      render={({ field }) => {
                        return (
                          <label className="col-12 form-control">
                            <span>
                              Vice coordenador <small>(opcional)</small>
                            </span>
                            <SoulTypeahead
                              serverSide
                              openOnFocus
                              value={field.value}
                              disabled={isDisabled}
                              id="txt-deputyCoordinator"
                              onChange={field.onChange}
                              data-testid="txt-deputyCoordinator"
                              loading={state.deputyCoordinator.loading}
                              options={state.deputyCoordinator.options}
                              placeholder="Pesquise e selecione um Vice coordenador"
                              onSearchChange={handleSearchProvider(
                                ProviderType.DeputyCoordinator,
                              )}
                            />
                          </label>
                        );
                      }}
                    />
                  </div>
                  {/* hash (fusp) */}
                  {showHashFusp ? (
                    <div className="form-row">
                      <label className="col-12 form-control">
                        <span>
                          HASH (FUSP) <small>(opcional)</small>
                        </span>
                        <input
                          {...register("fuspHash")}
                          id="txt-hash"
                          placeholder="HASH"
                          disabled={isDisabled}
                          data-testid="txt-hash"
                        />
                      </label>
                    </div>
                  ) : null}
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-crud-close"
                data-testid="btn-crud-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                form="project-crud-form"
                data-testid="btn-crud-save"
                disabled={isSubmitting || isDisabled}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
