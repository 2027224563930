import { addSeconds, format } from "date-fns";
import { CheckboxChangeParams } from "primereact/checkbox";
import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import {
  MenuItem,
  MenuItemCommandParams,
  MenuItemOptions,
} from "primereact/menuitem";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaBug } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
  SimpleTableColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { EFuspSendingStatus } from "../../domain/entities/accountPayableListItemEntity";
import { IExportSheetFuspErrorEntity } from "../../domain/entities/exportSheetFuspErrorEntity";
import {
  IFuspAccountPayableIntegrationEntity,
  IFuspAccountPayableIntegrationError,
} from "../../domain/entities/fuspAccountPayableIntegrationEntity";
import { ContextMenuItem } from "../components/AccountsPayablePageContextMenu/styles";
import { ExportSheetFuspSendingFailureDIalogContent } from "../components/ExportSheetFuspSendingFailureDIalogContent";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { useExportSheetFuspFormModalTable } from "./useExportSheetFuspFormModalTable";
import { ProgressModalContent } from "../../../../../core/presentation/components/Modals/ProgressModalContent";

interface IFetchDataProps {
  pfsEventEntity: IPFSEventEntity;
  uiSelectedColumns: IAdvTableColumn[];
  orderedColumnNames: string[];
}
export interface IExportSheetFuspFormFieldValues {
  startDate: string;
  endDate: string;
}

interface IExportSheetFuspFormModalState {
  loading: boolean;
  submitting: boolean;
  lastExportedUserName: string;
  data: IResponseEntity<IFuspAccountPayableIntegrationEntity[]> | undefined;
  selectedColumns: SimpleTableColumn[];
  orderedColNames: string[];
  pfsEvent: IPFSEventEntity;
  isFetching: boolean;
  selection: IFuspAccountPayableIntegrationEntity[] | undefined;
  selectedAllInActiveFilter: boolean;
  contextMenuData: IFuspAccountPayableIntegrationEntity | undefined;
}

export function useExportSheetFuspFormModal() {
  const { columns } = useExportSheetFuspFormModalTable();

  const [state, setState] = useState<IExportSheetFuspFormModalState>({
    loading: true,
    submitting: false,
    lastExportedUserName: "",
    data: undefined,
    selectedColumns: columns.map(col => ({ ...col })),
    orderedColNames: columns.map(col => col.field),
    pfsEvent: new PFSEventEntity({ rows: 50 }),
    isFetching: false,
    selection: undefined,
    selectedAllInActiveFilter: false,
    contextMenuData: undefined,
  });

  const formProps = useForm<IExportSheetFuspFormFieldValues>({
    defaultValues: {
      startDate: "",
      endDate: "",
    },
    mode: "all",
  });

  const { useAccountsPayable, handleExportSheetFuspFormModalClose, ...rest } =
    useAccountsPayablePage();

  const { exportSheetFuspFormModalOpen } = rest.state;

  const { advGeneratePayload } = useTables();
  const {
    fetchLastFuspSheetExported,
    exportSheetFusp,
    listExportedFuspAccounts,
    sendAccountPayableToFusp,
    sendAccountPayableToFuspByIds,
  } = useAccountsPayable;

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const currentCompanyGroupId = useMemo(
    () => currentCompanyGroup.id,
    [currentCompanyGroup.id],
  );

  const finalDateRef = useRef(new Date());
  const intervalRef = useRef<NodeJS.Timer>();
  const mountedRef = useIsMounted();
  const tableRef = useRef<IAdvTableHandle>(null);
  const menuRef = useRef<ContextMenu>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  /** Responsavel por atualizar a data final da exportacao com incremento de 1s */
  const updateFinalDate = useCallback(() => {
    finalDateRef.current = addSeconds(finalDateRef.current, 1);

    const endDate = format(finalDateRef.current, "ddMMyyyy HH:mm:ss");

    formProps.setValue("endDate", endDate);
  }, [formProps]);

  /** Inicia o interval que atualiza a data final de 1 em 1s */
  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(() => updateFinalDate(), 1000);
  }, [updateFinalDate]);

  /** Interrompe o interval que atualiza a data final */
  const endInterval = useCallback(() => {
    clearInterval(intervalRef.current as unknown as number);
  }, []);

  /** Obtem os dados da ultima vez que a planilha FUSP foi exportada */
  const fetchLastFuspSheetExportedData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const { lastExportedDate, currentDate, lastExportedUserName } =
        await fetchLastFuspSheetExported();

      const startDate = format(new Date(lastExportedDate), "ddMMyyyy HH:mm:ss");
      const endDate = format(new Date(currentDate), "ddMMyyyy HH:mm:ss");

      finalDateRef.current = new Date(currentDate);

      formProps.setValue("startDate", startDate);
      formProps.setValue("endDate", endDate);

      setState(prevState => ({
        ...prevState,
        loading: false,
        lastExportedUserName,
      }));

      startInterval();
    } catch {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [fetchLastFuspSheetExported, formProps, startInterval]);

  /** Lida com o reset do estado da modal durante seu fechamento */
  const handleModalAfterClose = useCallback(() => {
    endInterval();

    setState({
      loading: false,
      submitting: false,
      lastExportedUserName: "",
      data: undefined,
      selectedColumns: columns.map(col => ({ ...col })),
      orderedColNames: columns.map(col => col.field),
      pfsEvent: new PFSEventEntity({ rows: 50 }),
      isFetching: false,
      selection: undefined,
      selectedAllInActiveFilter: false,
      contextMenuData: undefined,
    });
  }, [columns, endInterval]);

  /** Lida com o evento de fechamento emitido pela modal */
  const handleClose = useCallback(() => {
    handleExportSheetFuspFormModalClose();
  }, [handleExportSheetFuspFormModalClose]);

  const dialog = useSoulDialog();

  /**
   * Responsavel por fazer o fetch que obtem a lista de dados p/ exibir na tela
   */
  const fetchData = useCallback(
    async ({
      orderedColumnNames,
      pfsEventEntity,
      uiSelectedColumns,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!currentCompanyGroupId) {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
            isFetching: false,
          };
        });

        return;
      }

      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => {
        return {
          ...prevState,
          filtered: isFiltered,
          isFetching: true,
        };
      });

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listExportedFuspAccounts(
          currentCompanyGroupId,
          payload,
        );

        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: response,
          };
        });
      } catch {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
          };
        });
      } finally {
        if (mountedRef.current) {
          setState(prevState => {
            return {
              ...prevState,
              isFetching: false,
            };
          });
        }
      }
    },
    [
      advGeneratePayload,
      currentCompanyGroupId,
      listExportedFuspAccounts,
      mountedRef,
    ],
  );

  /** recarrega os dados da tabela */
  const reload = useCallback(async () => {
    await fetchData({
      orderedColumnNames: state.orderedColNames,
      pfsEventEntity: state.pfsEvent,
      uiSelectedColumns: columns,
    });

    ReactTooltip.rebuild();
  }, [columns, fetchData, state.orderedColNames, state.pfsEvent]);

  /** Exporta a planilha FUSP  */
  const generateSheet = useCallback(async () => {
    dialog.fire({
      html: <ProgressModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const { startDate, endDate } = formProps.getValues();

    try {
      await exportSheetFusp({ startDate, endDate });

      await dialog.fire({
        title: "Feito!",
        text: "Exportação realizada com sucesso.",
      });

      reload();
    } catch (error) {
      dialog.close();

      const exportSheetFuspError = error as IExportSheetFuspErrorEntity;

      if (exportSheetFuspError.noDataToExport) {
        await dialog.fire({
          icon: "error",
          title: "Aviso",
          html: (
            <>
              Não foi possível continuar! Não há dados a serem exportados nesse
              período. Tente novamente mais tarde.
            </>
          ),
        });
      }
    }
  }, [dialog, exportSheetFusp, formProps, reload]);

  /** estado de validez da tabela baseado nos itens selecionados */
  const isTableValid =
    state.selection?.length || state.selectedAllInActiveFilter || false;

  /** Apresenta uma mensagem de sucesso apos o envio dos dados para fusp */
  const showSendingSuccessDialog = useCallback(async () => {
    dialog.fire({
      icon: "success",
      title: "Feito!",
      html: <>Lançamentos enviados com sucesso!</>,
    });
  }, [dialog]);

  /**
   * Apresenta uma mensagem de erro apos o envio dos dados para
   * fusp incluindo os erros de cada registros cujo envio falhou
   */
  const showSendingFailureDialog = useCallback(
    async (messages: string[]) => {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <ExportSheetFuspSendingFailureDIalogContent isList error={messages} />
        ),
      });
    },
    [dialog],
  );

  /**
   * Apresenta uma dialog de progresso informando
   * que os lancamentos estao sendo enviados para a fusp
   */
  const showProgressDialog = useCallback(() => {
    dialog.fire({
      html: (
        <ProgressModalContent message="Por favor, aguarde enquanto enviamos os lançamentos..." />
      ),
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }, [dialog]);

  /** Efetua o envio de todas os registros presentes no filtro atual da tabela */
  const sendAllEntriesInCurrentFilter = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    showProgressDialog();

    try {
      const selectedCols = state.orderedColNames
        .map(fieldName => {
          return columns.find(uiSelCol => uiSelCol.field === fieldName);
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      const payload = advGeneratePayload(
        state.pfsEvent,
        selectedCols as ISimpleColumn[],
      );

      await sendAccountPayableToFusp(payload);
      await showSendingSuccessDialog();
    } catch (error) {
      const fuspError = error as IFuspAccountPayableIntegrationError;
      const { details } = fuspError;

      await showSendingFailureDialog(details);
    } finally {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));

      reload();
    }
  }, [
    advGeneratePayload,
    columns,
    reload,
    sendAccountPayableToFusp,
    showProgressDialog,
    showSendingFailureDialog,
    showSendingSuccessDialog,
    state.orderedColNames,
    state.pfsEvent,
  ]);

  /** Efetua o envio dos registros selecionados pelo usuario na UI */
  const sendSelectedEntriesToFusp = useCallback(async () => {
    if (!state.selection?.length) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    showProgressDialog();

    try {
      await sendAccountPayableToFuspByIds(state.selection);
      await showSendingSuccessDialog();
    } catch (error) {
      const fuspError = error as IFuspAccountPayableIntegrationError;
      const { details } = fuspError;

      await showSendingFailureDialog(details);
    } finally {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));

      reload();
    }
  }, [
    reload,
    sendAccountPayableToFuspByIds,
    showProgressDialog,
    showSendingFailureDialog,
    showSendingSuccessDialog,
    state.selection,
  ]);

  /**
   * Efetua o envio dos registros para a api da fusp. Responsavel por
   * decidir se o envio serah de todos os registros correspondentes ao
   * filtro atual da tabela ou se somente dos itens selecionados
   */
  const sendToFusp = useCallback(async () => {
    const isAllInActiveFilterSelected = state.selectedAllInActiveFilter;

    if (isAllInActiveFilterSelected) {
      sendAllEntriesInCurrentFilter();
    } else {
      sendSelectedEntriesToFusp();
    }
  }, [
    sendAllEntriesInCurrentFilter,
    sendSelectedEntriesToFusp,
    state.selectedAllInActiveFilter,
  ]);

  /**
   * Lida com o evento de submissao do form.
   */
  const handleSendFuspButtonClick = useCallback(async () => {
    if (!isTableValid) {
      dialog.fire({
        icon: "warning",
        title: "Atenção!",
        text: "É necessário selecionar registro(s) para fazer o envio.",
      });

      return;
    }

    const result = await dialog.fire({
      icon: "warning",
      title: "Atenção!",
      showCancelButton: true,
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim",
      html: (
        <>
          Ao confirmar, as datas de exportação serão <b>registradas</b> e a
          planilha FUSP será baixada. Além disso, você receberá os <b>anexos</b>{" "}
          de cada Coordenador por e-mail. <br />
          <br /> Deseja prosseguir?
        </>
      ),
    });

    if (result.dismiss) {
      return;
    }

    sendToFusp();
  }, [dialog, isTableValid, sendToFusp]);

  /**
   * lida com o evento de click do botao "Gerar planilha". valida os inputs
   * evitando o request caso haja invalidez no form e exibe os problemas na
   * tela
   */
  const submitHandler = useCallback(async () => {
    const result = await dialog.fire({
      icon: "warning",
      title: "Atenção!",
      html: (
        <div>
          Ao confirmar, as datas de exportação serão <b>registradas</b> e a
          planilha FUSP será baixada. Além disso, você receberá os <b>anexos</b>{" "}
          de cada Coordenador por e-mail. <br />
          <br />
          Deseja prosseguir?
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: "Não, cancelar",
    });

    if (result.dismiss) {
      return;
    }

    generateSheet();
  }, [dialog, generateSheet]);

  /** Lida com a inicializacao de estado da modal durante sua abertura */
  const handleModalAfterOpen = useCallback(async () => {
    fetchLastFuspSheetExportedData();

    await fetchData({
      orderedColumnNames: state.orderedColNames,
      pfsEventEntity: state.pfsEvent,
      uiSelectedColumns: columns,
    });

    ReactTooltip.rebuild();
  }, [
    columns,
    fetchData,
    fetchLastFuspSheetExportedData,
    state.orderedColNames,
    state.pfsEvent,
  ]);

  /**
   * Lida com o evento de troca de estado interno da AdvTable, equivalente
   * a um on change, usado para fazer a request que obtem os dados que serao
   * exibidos na tabela
   */
  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setState(prevState => {
        return {
          ...prevState,
          pfsEvent: _pfsEvent,
        };
      });

      fetchData({
        orderedColumnNames: state.orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: state.selectedColumns,
      });
    },
    [fetchData, state.orderedColNames, state.selectedColumns],
  );

  /**
   * Lida com o evento de selecao de uma coluna, ou seja, quando
   * o usuario add uma coluna que nao estava na tabela pela ui
   */
  const handleColumnSelect = useCallback(
    (column: IAdvTableColumn) => {
      const newColumns = [column, ...state.selectedColumns];
      const newOrderedColNames = newColumns.map(col => col.field);

      setState(prevState => {
        return {
          ...prevState,
          selectedColumns: newColumns,
          orderedColNames: newOrderedColNames,
        };
      });

      // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
      // na sequencia do update do estado nao tem efeito, nao queremos usar um
      // side-effect selectedColumns pois outros eventos atualizam esse estado e
      // nao queremos que que o applyPanorama seja executado em todos eles
      setTimeout(() => {
        tableRef.current?.applyPanorama();

        fetchData({
          orderedColumnNames: newOrderedColNames,
          pfsEventEntity: state.pfsEvent,
          uiSelectedColumns: newColumns,
        });
      });
    },
    [fetchData, state.pfsEvent, state.selectedColumns],
  );

  /**
   * Lida com eventos de click na linha, exibindo o menu de contexto.
   */
  const handleRowClick = useCallback(
    (
      event: IDataTableRowClickEventParams<IFuspAccountPayableIntegrationEntity>,
    ) => {
      event.originalEvent.stopPropagation();

      const contextMenuRowData = event.data;

      const shouldShowContextMenu =
        contextMenuRowData.fuspSendingStatus ===
        EFuspSendingStatus.SendingFailure;

      setState(prevState => {
        return {
          ...prevState,
          contextMenuData: contextMenuRowData,
        };
      });

      if (shouldShowContextMenu) {
        menuRef?.current?.show(event.originalEvent);
      } else {
        menuRef?.current?.hide(event.originalEvent);
      }
    },
    [],
  );

  /**
   * Lida com o evento de troca de valor do checkbox
   * "Selecionar todos os registros filtrados"
   */
  const handleSelectedAllInActiveFilterCheckboxChange = (
    event: CheckboxChangeParams,
  ) => {
    const allInActiveFilterSelected = event.checked;

    const headerCheckbox =
      containerRef.current?.querySelector("th.soul-chkbox-col");
    const method = allInActiveFilterSelected ? "add" : "remove";

    headerCheckbox?.classList[method]?.("disabled-checkbox");

    setState(prevState => {
      return {
        ...prevState,
        selectedAllInActiveFilter: allInActiveFilterSelected,
      };
    });
  };

  /**
   * Lida com o evento de selecao de linhas na tabela, ou seja quando
   * o usuario marca ou desmarca o checbox das linhas da tabela
   */
  const handleSelectionChange = ({
    value,
    type,
  }: DataTableSelectionChangeParams) => {
    if (type === "row") {
      return;
    }

    setState(prevState => {
      return {
        ...prevState,
        selection: value,
      };
    });
  };

  /**
   * Lida com o evento de remocao de uma coluna, ou seja,
   * quando o usario remove uma coluna da tabela pela ui
   */
  const handleColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setState(prevState => {
      return {
        ...prevState,
        selectedColumns: prevState.selectedColumns.filter(
          selCol => selCol.field !== field,
        ),
      };
    });
  };

  /**
   * Lida com o evento de reordenacao da sequencia
   * em que as colunas aparecem na ui
   */
  const handleColReorder = useCallback(
    (colReorderParams: DataTableColReorderParams) => {
      const cols = colReorderParams.columns as unknown as Array<{
        props: { field: string };
      }>;

      const newOrderedColNames = cols
        .map(col => col.props.field)
        .filter((col: string): col is string => !!col);

      // por ora precisamos incluir as hidden tbm, pois sem elas após
      // reordenar as colunas na UI elas nao sao mais enviadas na request
      const hidden = columns
        .filter(col => {
          const c = col as ISimpleHiddenColumn;
          return c.hidden;
        })
        .map(c => c.field);

      setState(prevState => {
        return {
          ...prevState,
          orderedColNames: [...newOrderedColNames, ...hidden],
        };
      });
    },
    [columns],
  );

  /**
   * Indica quando uma linha pode ser selecionada. Desativado sempre para
   * relatórios originados de Movimentação.
   */
  const handleSelectableData = useCallback(() => {
    return !state.selectedAllInActiveFilter;
  }, [state.selectedAllInActiveFilter]);

  const handleRowClassName = () => {
    return `row-fusp ${
      state.selectedAllInActiveFilter ? "disabled-checkbox" : ""
    }`.trim();
  };

  /** Estado de somente leitura dos campos da modal baseado no retorno da api */
  const readOnly = useMemo(
    () => state.lastExportedUserName !== "",
    [state.lastExportedUserName],
  );

  /** modelo dos botoes do menu de contexto da modal fusp */
  const menuModel = useMemo<MenuItem[]>(() => {
    const showFuspSendingFailureReasonDialog = async (
      entry: IFuspAccountPayableIntegrationEntity,
    ) => {
      dialog.fire({
        icon: "error",
        html: (
          <ExportSheetFuspSendingFailureDIalogContent
            message="Houve falha de envio desse lançamento para a FUSP pois:"
            error={entry.fuspSendingFailureReason}
          />
        ),
      });
    };

    return [
      {
        label: "Motivo da falha FUSP",
        data: state.contextMenuData,
        visible:
          state.contextMenuData?.fuspSendingStatus ===
          EFuspSendingStatus.SendingFailure,
        template(_item: MenuItem, { onClick }: MenuItemOptions) {
          const disabled = _item.disabled ? "disabled" : "";

          return (
            <ContextMenuItem
              onClick={onClick}
              className={`p-menuitem-link ${disabled}`.trim()}
            >
              <span className="p-menuitem-text" title="Motivo da falha FUSP">
                <FaBug />
                Motivo da falha FUSP
              </span>
            </ContextMenuItem>
          );
        },
        command(commandParams: MenuItemCommandParams) {
          const { item } = commandParams;
          const entry = item.data as IFuspAccountPayableIntegrationEntity;

          showFuspSendingFailureReasonDialog(entry);
        },
      },
    ];
  }, [dialog, state.contextMenuData]);

  /**
   * Estado de validez do form baseado nos campos
   */
  const isValid = formProps.formState.isValid || readOnly;

  return {
    state,
    exportSheetFuspFormModalOpen,
    handleClose,
    handleModalAfterOpen,
    handleModalAfterClose,
    formProps,
    submitHandler,
    handleSendFuspButtonClick,
    readOnly,
    isValid,
    columns,
    handleColumnSelect,
    tableRef,
    handleGetList,
    handleRowClick,
    handleSelectionChange,
    handleColumnRemove,
    handleColReorder,
    isTableValid,
    handleSelectedAllInActiveFilterCheckboxChange,
    handleSelectableData,
    handleRowClassName,
    containerRef,
    menuRef,
    menuModel,
  };
}
