import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 33.75rem;

  .crud-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.9rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .p-progress-spinner {
    width: 3.5rem;
    height: 3.5rem;

    svg {
      filter: drop-shadow(0px 0px 0.625rem rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }
`;
