import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IUpdatePecegePayAccountReceivableFeeContract,
  IUpdatePecegePayAccountReceivableFeeParams,
} from "../../domain/contracts/updatePecegePayAccountReceivableFeeContract";
import { IPecegePayAccountReceivableFeeEntity } from "../../domain/entities/pecegePayAccountReceivableFeeEntity";

export class UpdatePecegePayAccountReceivableFeeService
  implements IUpdatePecegePayAccountReceivableFeeContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updatePecegePayAccountReceivableFee({
    formValues,
    companyGroupId,
  }: IUpdatePecegePayAccountReceivableFeeParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PecegePayAccountReceivableFees";

    const payload = {
      companyGroupId,
      id: formValues.id,
      active: formValues.active,
      walletName: formValues?.wallet?.label,
      walletId: formValues?.wallet?.rawValue as string,
      companyId: formValues?.company?.rawValue as string,
      providerId: formValues?.provider?.rawValue as string,
      classificationAssessmentId: formValues?.classificationAssessment
        ?.rawValue as string,
      classificationAccountId: formValues?.classificationAccount
        ?.rawValue as string,
    };

    return this.api.put<IPecegePayAccountReceivableFeeEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
