import BigNumber from "bignumber.js";
import { useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCalculateAssessmentsValues } from "../../../../../../core/presentation/hooks/useCalculateAssessmentsValue";
import { IDebtImportAssessmentEntity } from "../../../domain/entities/debtImportAssessmentEntity";
import { AssessmentObservationField } from "../Fields/AssessmentFields/AssessmentObservationField";
import { AssessmentValueField } from "../Fields/AssessmentFields/AssessmentValueField";
import { ClassificationAssessmentField } from "../Fields/AssessmentFields/ClassificationAssessmentField";
import { CostCenterField } from "../Fields/AssessmentFields/CostCenterField";
import { PercentageField } from "../Fields/AssessmentFields/PercentageField";
import { IGridAssessment } from "../FinalStepExpandedTemplate";
import { Container } from "./styles";

export interface IEditAssessmentForm extends IGridAssessment {
  percentageMask: number;
}

interface IAssessmentFormModalProps {
  accountValue: number;
  assessmentIndex: number | null;
  onRequestClose(): Promise<void>;
  assessmentsList: IGridAssessment[];
  onConfirmEdition(editedAssessment: IGridAssessment): void;
}

export function EditAssessmentFormModal(props: IAssessmentFormModalProps) {
  const {
    accountValue,
    onRequestClose,
    assessmentsList,
    assessmentIndex,
    onConfirmEdition,
  } = props;

  const {
    reset,
    watch,
    control,
    trigger,
    setValue,
    setError,
    formState,
    getValues,
    handleSubmit,
  } = useForm<IEditAssessmentForm>({
    mode: "all",
    defaultValues: {
      id: "",
      value: "0",
      percentage: "0",
      observation: "",
      fieldErrors: {},
      hasError: false,
      costCenter: null,
      valueAssessmentLeft: 0,
      classificationAssessment: null,
    },
  });

  const assessmentValues = watch();

  const {
    getRemaining,
    formattedAccountValue,
    calculatePercentageFromValue,
    calculateValuesFromPercentageMask,
  } = useCalculateAssessmentsValues({
    accountValue,
    originalAssessments: assessmentsList,
    editAssessmentIndex: assessmentIndex,
  });

  const {
    remainingValue,
    formattedRemainingValue,
    formattedRemainingPercentage,
  } = getRemaining([assessmentValues]);

  if (assessmentIndex === null) {
    return null;
  }

  const { isValid } = formState;

  const handleAfterOpen = async () => {
    if (assessmentIndex === null) {
      onRequestClose();
      return;
    }

    const editAssessment = assessmentsList[assessmentIndex];

    const formValue = {
      ...editAssessment,
      percentageMask: new BigNumber(editAssessment.percentage)
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber(),
    };

    reset(formValue);

    await trigger();

    const keyValueErrors = Object.entries(editAssessment.fieldErrors);

    keyValueErrors.map(([key, message]) => {
      const typedKey = key.replace(
        "Id",
        "",
      ) as keyof IDebtImportAssessmentEntity;

      if (key === "percentage") {
        setError("percentageMask", {
          type: "custom",
          message: message || "Insira uma porcentagem válida",
        });
        return null;
      }

      if (key === "valueAssessmentLeft") {
        setError("value", {
          type: "custom",
          message: message || "O valor não deve ultrapassar o limite",
        });
        setError("percentageMask", {
          type: "custom",
          message: message || "A porcentagem não deve ultrapassar o limite",
        });
        return null;
      }

      setError(typedKey, {
        type: "custom",
        message: message || "Este campo é obrigatório",
      });

      return null;
    });
  };

  const handleAfterClose = () => {
    reset({
      id: "",
      value: "0",
      percentage: "",
      observation: "",
      fieldErrors: {},
      hasError: false,
      costCenter: null,
      valueAssessmentLeft: 0,
      classificationAssessment: null,
    });
  };

  const handleValueChange = (value: number) => {
    const { calculatedPercentage, maskedPercentage } =
      calculatePercentageFromValue(value);

    setValue("percentage", calculatedPercentage.toJSON());
    setValue("percentageMask", maskedPercentage.toNumber(), {
      shouldValidate: true,
    });
  };

  const handlePercentageChange = (percentage: number) => {
    const currentFormValuesList = [getValues()];

    const { calculatedPercentage, calculatedValue } =
      calculateValuesFromPercentageMask({
        index: 0,
        percentage,
        currentFormValuesList,
      });

    setValue("percentage", calculatedPercentage.toJSON());
    setValue("value", calculatedValue.toJSON(), {
      shouldValidate: true,
    });
  };

  const handleEdition = (formValues: IEditAssessmentForm) => {
    const finalObject = {
      fieldErrors: {},
      value: formValues.value,
      id: formValues?.id || "",
      percentage: formValues.percentage,
      costCenter: formValues.costCenter,
      hasError: !remainingValue.isZero(),
      observation: formValues.observation,
      valueAssessmentLeft: remainingValue.toNumber(),
      classificationAssessment: formValues.classificationAssessment,
    };

    onConfirmEdition(finalObject);
  };

  return (
    <Modal
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      onRequestClose={onRequestClose}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={false}
      isOpen={assessmentIndex !== null}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar rateio</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            onClick={onRequestClose}
            className="react-modal-close"
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          <div className="card">
            <p className="description">
              Valor total do lançamento: {formattedAccountValue} (100%)
            </p>
            <p className="document-number">
              Valor a ser rateado: {formattedRemainingValue} (
              {formattedRemainingPercentage}%)
            </p>
          </div>
          <form
            id="edit-assessment-form"
            className="form-container"
            onSubmit={handleSubmit(handleEdition)}
          >
            <div className="form-container__body">
              <div className="form-row">
                <ClassificationAssessmentField control={control} />
                <CostCenterField control={control} />
              </div>
              <div className="form-row">
                <AssessmentValueField
                  control={control}
                  onChange={handleValueChange}
                />
                <PercentageField
                  control={control}
                  onChange={handlePercentageChange}
                />
              </div>
              <div className="form-row">
                <AssessmentObservationField control={control} />
              </div>
            </div>
          </form>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            id="btn-close"
            data-testid="btn-close"
            className="form-button red-bkg"
            onClick={onRequestClose}
          >
            Fechar
          </button>
          <button
            type="submit"
            id="btn-edit-submit"
            form="edit-assessment-form"
            data-testid="btn-edit-submit"
            className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
          >
            Confirmar
          </button>
        </div>
      </Container>
    </Modal>
  );
}
