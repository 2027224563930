import { IPanoramaAngularModel } from "../../../../../advTable/data/models/panoramaAngularModel";
import { IParsePanoramaAngularService } from "../../../../../advTable/data/services/parsePanoramaAngularService";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IListAccountsReceivablePanoramasContract } from "../../domain/contracts/listAccountsReceivablePanoramasContract";

export class ListAccountsReceivablePanoramasService
  implements IListAccountsReceivablePanoramasContract
{
  constructor(
    private parsePanoramaAngularService: IParsePanoramaAngularService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listAccountsReceivablePanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]> {
    const url = `/Users/${userId}/AccountReceivablePanoramas`;
    const userEntity = this.getUserLocalService.get();

    const panoramas = await this.api.get<IPanoramaAngularModel[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const accountsReceivablePanoramas = panoramas.map(panorama => {
      return this.parsePanoramaAngularService.parsePanoramaAngular(
        columns,
        panorama,
      );
    });

    return accountsReceivablePanoramas;
  }
}
