import { format } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ProjectEntity } from "../../domain/entities/projectEntity";
import { IProjectTransactionEntity } from "../../domain/entities/projectTransactionEntity";
import {
  IProjectTransactionInputEntity,
  ProjectTransactionInputEntity,
} from "../../domain/entities/projectTransactionInputEntity";
import { IGetProjectTransactionService } from "../../domain/usecases/getProjectTransactionUseCase";
import { IProjectTransactionAttachmentEntity } from "../../domain/entities/projectTransactionAttachmentEntity";

export class GetProjectTransactionsService
  implements IGetProjectTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getProjectTransaction(
    projectTransactionsId: string,
  ): Promise<IProjectTransactionInputEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ProjectTransactions/${projectTransactionsId}`;

    const response = await this.api.get<IProjectTransactionEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const attachments = await this.getAttachments(projectTransactionsId);

    const projectTransactionsEntity = new ProjectTransactionInputEntity({
      id: response.id,
      companyGroupId: response.companyGroupId,
      projectOrigin: new ProjectEntity({
        id: response.projectOriginId,
      }),
      projectDestination: new ProjectEntity({
        id: response.projectDestinationId,
      }),
      transactionDate: format(new Date(response.transactionDate), "ddMMyyyy"),
      value: response.value,
      description: response.description,
      active: response.active,
      attachments,
    });

    return projectTransactionsEntity;
  }

  private getAttachments(id: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `ProjectTransactions/${id}/Attachments`;
    return this.api.get<IProjectTransactionAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
