import { ICompanyEntity } from "../entities/companyEntity";
import { ICompanyTaxFormEntity } from "../entities/companyTaxFormEntity";

export interface ISaveCompanyTaxesService {
  saveCompanyTaxes(
    companyId: string,
    payload: ICompanyTaxFormEntity[],
  ): Promise<ICompanyEntity>;
}

export interface ISaveCompanyTaxesUseCase {
  saveCompanyTaxes(
    companyId: string,
    payload: ICompanyTaxFormEntity[],
  ): Promise<ICompanyEntity>;
}

export class SaveCompanyTaxesUseCase implements ISaveCompanyTaxesUseCase {
  constructor(private saveCompanyTaxesService: ISaveCompanyTaxesService) {}

  saveCompanyTaxes(companyId: string, payload: ICompanyTaxFormEntity[]) {
    return this.saveCompanyTaxesService.saveCompanyTaxes(companyId, payload);
  }
}
