import { format, isAfter, isBefore, isValid, parse, subDays } from "date-fns";
import {
  DropdownChangeParams,
  DropdownFilterParams,
} from "primereact/dropdown";
import {
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTrash } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../advTable/domain/entities/panoramaEntity";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { IFiltersValue } from "../../domain/usecases/listCostCenterReportUseCase";
import { PanoramaOption } from "../components/Toolbar/styles";
import { useCostCenterReportGridManager } from "./useCostCenterReportGridManager";
import { MakeCostCenterReportManager } from "../../main/makeCostCenterReportManager";

export interface IUseToolbarManagerParams {
  filters: IFiltersValue;
  isGridFiltered: boolean;
  onFiltersChange(value: IFiltersValue): void;
  useCostCenterReportManager: MakeCostCenterReportManager;
  onPanoramaChange(panorama: IPanoramaEntity, shouldUpdate?: boolean): void;
}

export function useToolbarManager(params: IUseToolbarManagerParams) {
  const debounceTime = useDebounceTime();

  const {
    isGridFiltered,
    onFiltersChange,
    onPanoramaChange,
    useCostCenterReportManager,
    filters: { startDate, endDate },
  } = params;

  const {
    currentCompanyGroup: { id: currentCompanyGroupId },
  } = useCurrentCompanyGroup();

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /** Esta é a representação do panorama padrão de relatoriod e cc */
  const defaultPanorama = useMemo(() => {
    return PanoramaEntity.create({
      id: "default-panorama",
      name: "Padrão do sistema",
      systemDefault: true,
    });
  }, []);

  const [loading, setLoading] = useState({
    costCenters: true,
    panoramas: true,
  });
  const [dates, setDates] = useState({ startDate, endDate });
  const [costCenterOptions, setCostCenterOptions] =
    useState<IRelationshipFilterOption[]>();
  const [panoramaOptions, setPanoramaOptions] = useState<IPanoramaEntity[]>();
  const [selectedCostCenter, setSelectedCostCenter] =
    useState<IRelationshipFilterOption>();
  const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
  const [selectedPanorama, setSelectedPanorama] =
    useState<IPanoramaEntity>(defaultPanorama);

  const parsedStartDate = useMemo(() => {
    const { startDate: _startDate } = dates;
    return parse(_startDate, "dd/MM/yyyy", new Date());
  }, [dates]);

  const parsedEndDate = useMemo(() => {
    const { endDate: _endDate } = dates;
    return parse(_endDate, "dd/MM/yyyy", new Date());
  }, [dates]);

  const isStartDateValid = useMemo<boolean>(() => {
    if (dates.startDate === "") {
      return true;
    }

    if (!isValid(parsedStartDate)) {
      return false;
    }

    if (isAfter(parsedStartDate, parsedEndDate)) {
      return false;
    }

    return true;
  }, [dates.startDate, parsedEndDate, parsedStartDate]);

  const isEndDateValid = useMemo<boolean>(() => {
    if (dates.endDate === "") {
      return true;
    }

    if (!isValid(parsedEndDate)) {
      return false;
    }

    if (isBefore(parsedEndDate, parsedStartDate)) {
      return false;
    }

    return true;
  }, [dates.endDate, parsedEndDate, parsedStartDate]);

  const dialog = useSoulDialog();

  // TODO rever o o uso do queryClient.invalidateQueries
  // const queryClient = useQueryClient();

  /**
   * Executado quando o usuario clica em "Filtrar". Responsável por disparar
   * o evento que desencadeia uma nova requisição e novo render na grid.
   * Pois páginas de relatório começam sem dados na grid, aguardando que
   * o usuario faça ao menos algum tipo de filtro na UI.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isStartDateValid || !isEndDateValid) {
      return;
    }

    const filtersValue: IFiltersValue = {
      startDate: dates.startDate,
      endDate: dates.endDate,
      costCenter: selectedCostCenter,
    };

    if (
      !dates.startDate &&
      !dates.endDate &&
      !selectedCostCenter &&
      !isGridFiltered
    ) {
      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Nenhum filtro foi selecionado. <br />
            Apenas registros dos últimos 30 dias serão exibidos. <br />
            Deseja continuar?
          </>
        ),
        cancelButtonText: "Não",
        showCancelButton: true,
        confirmButtonText: "Sim",
      });

      if (result.dismiss) {
        return;
      }

      const today = new Date();
      filtersValue.endDate = format(today, "dd/MM/yyyy");
      filtersValue.startDate = format(subDays(today, 30), "dd/MM/yyyy");
    }

    setDates(prevDates => {
      return {
        ...prevDates,
        startDate: filtersValue.startDate,
        endDate: filtersValue.endDate,
      };
    });

    onFiltersChange(filtersValue);

    // TODO precisamos rever direitinho o funcionamento do react query para tentar
    // evitar o uso do invalidateQueries ou utiliza-lo de forma que nao cause req
    // dobrada
    // queryClient.invalidateQueries({ type: "active" });
  };

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const {
    searchManagerCostCenter,
    listCostCenterReportPanoramas,
    deleteCostCenterReportPanorama,
    storeCostCenterReportPanoamaId,
    getStoredCostCenterReportPanoramaId,
  } = useCostCenterReportManager;

  /**
   * Pesquisa por um centro de custo, serve para
   * filtrar o dropdown de centro de custo
   */
  const handleSearchCostCenter = useCallback(
    async (search = "") => {
      if (!id) {
        setLoading(prevLoading => {
          return { ...prevLoading, costCenters: false };
        });
        return;
      }

      setLoading(prevLoading => {
        return { ...prevLoading, costCenters: true };
      });

      try {
        const response = await searchManagerCostCenter({
          search,
          companyGroupId: id,
          payloadOptions: { length: 50 },
        });
        setCostCenterOptions(response.data);
      } finally {
        setLoading(prevLoading => {
          return { ...prevLoading, costCenters: false };
        });
      }
    },
    [id, searchManagerCostCenter],
  );

  const { user } = useUserLocal();

  const { columns } = useCostCenterReportGridManager({
    useCostCenterReportManager,
  });

  /**
   * Obtem lista de panoramaas para preencher o dropdown de panoramas,
   * tambem resgata o ultimo panorama utilizado por este usuário e escolhe
   * este no dropdown automaticaemente
   */
  const fetchPanoramas = useCallback(async () => {
    const { userId } = user;

    setLoading(prevLoading => {
      return { ...prevLoading, panoramas: true };
    });

    const panoramas = await listCostCenterReportPanoramas(userId, columns);

    const panoramaList = [defaultPanorama, ...panoramas];
    setPanoramaOptions(panoramaList);

    setLoading(prevLoading => {
      return { ...prevLoading, panoramas: false };
    });

    const panoramaId = getStoredCostCenterReportPanoramaId();

    if (panoramaId) {
      const panorama = panoramaList.find(pano => pano.id === panoramaId);

      if (panorama) {
        setSelectedPanorama(panorama);
        onPanoramaChange(panorama, false);
      }
    }
  }, [
    columns,
    defaultPanorama,
    getStoredCostCenterReportPanoramaId,
    listCostCenterReportPanoramas,
    onPanoramaChange,
    user,
  ]);

  const handleOnFilter = ({ filter = "" }: DropdownFilterParams) => {
    debounceTime(() => {
      handleSearchCostCenter(filter);
    }, 700);
  };

  const handleOnChange = ({ value }: DropdownChangeParams) => {
    if (value?.rawValue === "EMPTY") return;
    setSelectedCostCenter(value);
  };

  useEffect(() => {
    if (!costCenterOptions) {
      handleSearchCostCenter();
    }
  }, [costCenterOptions, handleSearchCostCenter]);

  useEffect(() => {
    fetchPanoramas();
    // REVIEW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Deleta o panorama cujo botão "Excluir" foi clicado pelo usuario. */
  const handleDeletePanoramaButtonOnClick = useCallback(
    async (option: IPanoramaEntity, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const { name } = option;

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            await deleteCostCenterReportPanorama(option.id);
          } finally {
            dialog.close();
          }

          return true;
        },
        html: (
          <>
            O panorama <b>{name}</b> será excluído permanentemente.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      setPanoramaOptions(prevPanOptions => {
        const index = prevPanOptions?.findIndex(pan => pan.id === option.id);

        if (index && prevPanOptions) {
          prevPanOptions?.splice(index, 1);
          return [...prevPanOptions];
        }

        return prevPanOptions;
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            O panorama <b>{name}</b> foi excluído com sucesso.
          </>
        ),
      });
    },
    [deleteCostCenterReportPanorama, dialog],
  );

  /**
   * Renderiza o jsx de uma opção do dropdown de
   * panoramas, incluir o botão exlcuir
   */
  const renderPanoramaOption = useCallback(
    (option: IPanoramaEntity) => {
      const { name, systemDefault } = option;
      return (
        <PanoramaOption title={name}>
          <span>{name}</span>
          {!systemDefault && (
            <button
              title={`Excluir ${name}`}
              onClick={event =>
                handleDeletePanoramaButtonOnClick(option, event)
              }
              type="button"
            >
              <FaTrash />
            </button>
          )}
        </PanoramaOption>
      );
    },
    [handleDeletePanoramaButtonOnClick],
  );

  /** Abre modal p/ salvar um novo panorama */
  const handleSavePanoramaButtonOnClick = useCallback(() => {
    setIsPanoramaModalOpen(true);
  }, []);

  /**
   * Define o panorama atual quando o usuário escolhe um panorama no dropdown.
   * Também é responsável por armazenar o último panorama escolhido em
   * localStorage e disparar evento que faz nova consulta e novo render na grid.
   */
  const handlePanoramaDropdownOnChange = ({ value }: DropdownChangeParams) => {
    const panorama = value as IPanoramaEntity;
    const panoramaId = panorama.id;

    storeCostCenterReportPanoamaId(panoramaId);
    setSelectedPanorama(panorama);
    onPanoramaChange(panorama);
  };

  /** Atualiza os estados da UI após salvar um novo panorama (post na API). */
  const handleOnPanoramaSave = (newPanorama: IPanoramaEntity) => {
    setPanoramaOptions(prevPanoramaOptions => {
      if (prevPanoramaOptions) {
        return [...prevPanoramaOptions, newPanorama];
      }

      return [newPanorama];
    });
    storeCostCenterReportPanoamaId(newPanorama.id);
    setSelectedPanorama(newPanorama);
    onPanoramaChange(newPanorama, false);
  };

  /**
   * REVIEW - Esse efeito colateral é necessário para que os filtros e seletores
   * da toolbar sejam "reiniciados" quando houver alteração no valor do atual
   * do grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;
      setSelectedCostCenter(undefined);
      setCostCenterOptions(undefined);
      setDates({
        startDate: "",
        endDate: "",
      });
    }
  }, [currentCompanyGroupId, defaultPanorama]);

  return {
    dates,
    loading,
    isEndDateValid,
    panoramaOptions,
    isStartDateValid,
    selectedPanorama,
    costCenterOptions,
    selectedCostCenter,
    isPanoramaModalOpen,
    handleSavePanoramaButtonOnClick,
    handlePanoramaDropdownOnChange,
    setIsPanoramaModalOpen,
    renderPanoramaOption,
    handleOnPanoramaSave,
    setPanoramaOptions,
    handleOnFilter,
    handleOnChange,
    handleSubmit,
    setDates,
  };
}
