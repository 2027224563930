import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: inherit;

  table {
    min-width: 100% !important;
    width: 100% !important;
  }

  .src-fld {
    width: 100%;
    max-width: 20rem;
  }

  .button-wrapper {
    flex-grow: 1;
    display: inline-flex;
    justify-content: center;
  }

  .full-page {
    display: flex;
    height: inherit;
    flex-direction: column;
  }

  .no-padding {
    padding: 0;

    &.fill-height {
      height: inherit;
      display: flex;
      /**
       * 9rem é basicamente a distancia entre o top do viewport e o top
       * do * elemento .no-padding + uma espaco de respiro entre o bottom
       * do .no-padding e o bottom do viewport.
        *
       * Esta estratégia usando calc com 100vh e um valor fixo está
       * presente na documentacao do PrimeReact > Table > Flexscroll.
       */
      height: calc(100vh - 9rem);
    }
  }
`;
