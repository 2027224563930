import { useCallback, useRef, useState, MouseEvent } from "react";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
} from "primereact/autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IClassificationAssessmentEntity } from "../../../../classificationAssessment/domain/entities/classificationAssessment";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { MakeFee } from "../../../main/makeFee";
import { IFeeFormEntity } from "../../../domain/entities/feeFormEntity";

interface Props {
  readonly: boolean;
  value?: IClassificationAssessmentEntity;
  search: MakeFee["searchClassificationAssessment"];
}

export function SearchClassificationAssessmentField(props: Props) {
  const { readonly, search, value } = props;

  const autoCompleteRef = useRef<AutoComplete | null>();

  const {
    control,
    formState: { errors },
  } = useFormContext<IFeeFormEntity>();

  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const [suggestions, setSuggestions] = useState<
    IClassificationAssessmentEntity[]
  >([]);

  const [inputValue, setInputValue] =
    useState<IClassificationAssessmentEntity | null>(() => {
      return value ?? null;
    });

  /**
   * Lida com o preenchimento de sugestões do campo de Classificações de Rateio.
   * @param query - Texto que será utilizado como termo de busca.
   */
  const handleSearch = useCallback(
    async ({ query }: AutoCompleteCompleteMethodParams) => {
      const { data } = await search(query, companyGroupId);
      setSuggestions(data);
    },
    [search, companyGroupId],
  );

  const itemTemplate = useCallback(
    (item: IClassificationAssessmentEntity | "EMPTY") => {
      if (item === "EMPTY") {
        return <span>Nenhum registro encontrado.</span>;
      }

      const isSelected = inputValue?.id === item?.id;

      return <span className={isSelected ? "selected" : ""}>{item.name}</span>;
    },
    [inputValue?.id],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (!inputValue) return;
      autoCompleteRef.current?.search(e, inputValue.name, "dropdown");
    },
    [inputValue],
  );

  /**
   * Lida com o evento de mudança do input, definindo o valor correto no
   * formulário e atualizando o estado do valor selecionado.
   */
  const handleOnChange = useCallback(
    (e: AutoCompleteChangeParams, formOnChange: (value: unknown) => void) => {
      const isString = typeof e.value === "string";
      setInputValue(e.value === "EMPTY" ? "" : e.value);
      formOnChange(isString ? null : e.value?.id);
    },
    [],
  );

  return (
    <>
      <span>Classificação de Rateio</span>
      <Controller
        control={control}
        rules={{ required: true }}
        name="classificationAssessmentId"
        render={({ field: formField }) => {
          const refCallback = formField.ref;
          const formOnChange = formField.onChange;
          return (
            <AutoComplete
              field="name"
              delay={700}
              {...formField}
              forceSelection
              value={inputValue}
              disabled={readonly}
              onClick={handleClick}
              itemTemplate={itemTemplate}
              completeMethod={handleSearch}
              data-testid="ctrlr-cls-assessment"
              panelClassName="soul-auto-complete"
              placeholder="Selecione uma Classificação"
              suggestions={suggestions.length ? suggestions : ["EMPTY"]}
              onChange={inputEvent => {
                handleOnChange(inputEvent, formOnChange);
              }}
              inputClassName={
                errors.classificationAssessmentId ? "isInvalid" : ""
              }
              ref={originalRef => {
                autoCompleteRef.current = originalRef;
                refCallback(originalRef);
              }}
            />
          );
        }}
      />
      <InvalidFeedback
        condition={!!errors.classificationAssessmentId}
        message="Este campo é obrigatório"
      />
    </>
  );
}
