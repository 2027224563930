import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  CostCenterReportEntity,
  ICostCenterReportEntity,
} from "../../domain/entities/costCenterReportEntity";
import { IToggleCostCenterReportervice } from "../../domain/usecases/toggleCostCenterReportUseCase";

export class ToggleCostCenterReportService
  implements IToggleCostCenterReportervice
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleCostCenterReport(
    costCenterReportId: string,
  ): Promise<ICostCenterReportEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/CostCenterReport/${costCenterReportId}`;

    const response = await this.api.delete<ICostCenterReportEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new CostCenterReportEntity(response);
  }
}
