import { useFormContext } from "react-hook-form";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IFeeFormEntity } from "../../../domain/entities/feeFormEntity";
import {
  EFeeExpirationType,
  IFeeExpirationType,
} from "../../../domain/entities/feeExpirationTypeEnum";

interface Props {
  readonly: boolean;
  expirationTypesList: IFeeExpirationType[];
}

const hintsMessage = {
  [EFeeExpirationType.LAST_DAY]: `O vencimento do lançamento do imposto, será gerado no último dia útil do mês subsequente, ou seja, se o imposto for gerado no mês atual, o vencimento será no próximo mês, considerando o último dia útil.`,
  [EFeeExpirationType.FIXED_DAY]: `O vencimento do lançamento do imposto, será gerado conforme o dia escolhido no campo "Dia para vencimento", ou seja, se o dia colocado for 20, então, o vencimento será no dia 20 do mês subsequente.`,
};

export function ExpirationTypeField({ readonly, expirationTypesList }: Props) {
  const {
    watch,
    register,
    resetField,
    formState: { errors },
  } = useFormContext<IFeeFormEntity>();

  /** Observa o valor do próprio input de Tipo de Vencimento */
  const watcher = watch("expirationType");

  /**
   * Altera o título e a mensagem de apoio no campo de Tipo de Vencimento.
   * @returns Um objeto contendo o título e a mensagem a serem expostos.
   */
  const expirationTypeHintMessage = () => {
    if (!watcher) {
      return (
        <div>
          <p>
            <strong>Tipo de vencimento</strong>
          </p>
          <small>
            É um campo obrigatório. Precisa ser informado devido a qual
            parâmetro de vencimento o lançamento deve se basear no momento da
            geração.
          </small>
        </div>
      );
    }

    const typeSelected = expirationTypesList.find(
      t => t.key === Number(watcher),
    );

    return (
      <div>
        <p>
          <strong>{typeSelected?.value}</strong>
        </p>
        <small>É um campo obrigatório. {hintsMessage[watcher]}</small>
      </div>
    );
  };

  return (
    <>
      <span id="expiration-type-label">
        <span>Tipo de Vencimento</span>
        <IconTooltip icon="pi pi-question-circle">
          {expirationTypeHintMessage()}
        </IconTooltip>
      </span>
      <select
        defaultValue=""
        disabled={readonly}
        id="expiration-type"
        data-testid="txt-expiration-type"
        className={errors.expirationType ? "isInvalid" : ""}
        {...register("expirationType", {
          required: true,
          onChange: e => {
            if (e.target.valueAsNumber === EFeeExpirationType.FIXED_DAY) return;
            resetField("expirationDay", { defaultValue: null });
          },
        })}
      >
        <option value="" disabled hidden>
          Tipo de Vencimento
        </option>
        {expirationTypesList.map(type => {
          return (
            <option key={type.key} value={type.key}>
              {type.value}
            </option>
          );
        })}
      </select>
      <InvalidFeedback
        condition={!!errors.expirationType}
        message="Este campo é obrigatório"
      />
    </>
  );
}
