import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding-bottom: 2rem;

  .no-padding {
    padding: unset;
  }

  .dropdown-item {
    color: var(--soul-color02);

    &:hover {
      color: var(--soul-color02);
    }
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody {
    & > tr,
    & > tr.p-row-odd,
    & > tr.p-highlight,
    & > tr.p-row-odd.p-highlight {
      height: 5rem;

      &.row-valid {
        background: #d0f1e6;

        &:is(.p-row-odd) {
          background: #dcf5ec;
        }

        &:hover {
          background: #ebf9f4;
        }
      }

      &.row-edited {
        background: #ffe4bb;

        &:is(.p-row-odd) {
          background: #ffe9c7;
        }

        &:hover {
          background: #fff1db;
        }
      }

      &.row-invalid {
        background-color: #ffd2dd;

        &:is(.p-row-odd) {
          background-color: #ffc7d5;
        }

        &:hover {
          background-color: #ffe0e8;
        }
      }
    }
  }

  span.p-column-title:has(.row-fields) {
    width: 100%;
  }

  .row-fields {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > :nth-child(1) {
      flex: 0 0 15%;
    }

    > :nth-child(2) {
      flex: 0 0 15%;
    }

    > :nth-child(3) {
      flex: 0 0 calc(55% - 4.5rem);
    }

    > :nth-child(4) {
      flex: 0 0 15%;
    }
  }

  .action-buttons {
    display: flex;
    padding: 1.5rem;

    justify-content: flex-end;

    button {
      &.download-template {
        margin: 0;
        display: flex;
        align-items: center;

        i {
          margin-right: 0.4rem;
        }
      }

      &.footer-close-button {
        margin-left: auto;
      }
    }
  }
`;

export const DestinationContainer = styled.div`
  padding: 1rem;

  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > button.form-button {
    &.darkBlue-bkg {
      margin-right: unset;
      background-color: var(--soul-color01);
    }

    &.yellow-bkg {
      margin-right: unset;
      background-color: #f6b93b;
    }
  }
`;

export const StatusContainer = styled.div`
  padding: 1rem 0;
  & > button.form-button {
    &.orange-bkg {
      margin-right: 0.5rem;
      background-color: #e58e26;
    }

    &.green-bkg {
      background-color: #05a42f;
    }
  }
`;

export const ErrorsContainer = styled.div`
  margin: 1rem 0;
  overflow-y: auto;
  max-height: 25rem;

  div {
    text-align: left;
    font-size: 0.875rem;
    color: var(--color-danger);

    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    border-radius: 0.375rem;
    background-color: #f8d7da;
  }
`;
