import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";
import { SoulTypeahead } from "../../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../../core/presentation/hooks/useDebounceTime";
import { ITypeaheadState } from "../../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../../FieldWrapper";
import { IEditAssessmentForm } from "../../../EditAssessmentFormModal";

interface IClassificationAssessmentFieldProps {
  control: Control<IEditAssessmentForm>;
}

export function ClassificationAssessmentField(
  props: IClassificationAssessmentFieldProps,
) {
  const { control } = props;

  const asyncDebounce = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { useDebtImport, handleInputClassName } = useDebtImportPage();

  const { searchClassificationAssessment } = useDebtImport;

  const [state, setState] = useState<ITypeaheadState>({
    options: [],
    loading: false,
  });

  const handleSearchClassificationAssessment = async (search = "") => {
    await asyncDebounce(750);

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchClassificationAssessment({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      const classifications = response.data;

      setState(prevState => ({
        ...prevState,
        loading: false,
        options: classifications,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return (
    <Controller
      control={control}
      name="classificationAssessment"
      rules={{
        required: true,
      }}
      render={({ field, fieldState }) => {
        return (
          <label className="col-6 form-control">
            <span>
              Classificação de Rateio{" "}
              {state.loading && <i className="pi pi-spin pi-spinner" />}
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                value={field.value}
                onBlur={field.onBlur}
                loading={state.loading}
                options={state.options}
                onChange={field.onChange}
                id="txt-classificationAssessment"
                placeholder="Classificação de Rateio"
                data-testid="txt-classificationAssessment"
                onSearchChange={handleSearchClassificationAssessment}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
