import { ReactNode } from "react";
import { Container } from "./styles";

interface IConciliationRowEmptyProps {
  children?: ReactNode;
  className?: string | undefined;
}

export function ConciliationRowEmpty({
  children,
  className,
}: IConciliationRowEmptyProps) {
  return (
    <Container className={className}>
      <div className="raw-card">
        <div className="chk-box">
          <div />
        </div>
        <div className="card" />
      </div>
      <div className="content">{children}</div>
    </Container>
  );
}
