import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IPaymentAccountTransactionEntity } from "../entities/paymentAccountTransactionEntity";

export interface IListPaymentAccountTransactionsService {
  listPaymentAccountTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IPaymentAccountTransactionEntity[]>>;
}

export interface IListPaymentAccountTransactionsUseCase {
  listPaymentAccountTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IPaymentAccountTransactionEntity[]>>;
}

export class ListPaymentAccountTransactionsUseCase
  implements IListPaymentAccountTransactionsUseCase
{
  constructor(
    private listPaymentAccountTransactionsService: IListPaymentAccountTransactionsService,
  ) {}

  listPaymentAccountTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IPaymentAccountTransactionEntity[]>> {
    return this.listPaymentAccountTransactionsService.listPaymentAccountTransactions(
      companyGroupId,
      payload,
    );
  }
}
