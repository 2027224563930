import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useAccountPayableReturnFormModal } from "../../hooks/useAccountPayableReturnFormModal";
import { Container } from "./styles";

export function AccountPayableReturnFormModal() {
  const {
    state,
    columns,
    isInvalid,
    companyLockedErrorMsg,
    accountPayableReturnFormModalOpen,
    handleReturnDateChange,
    handleModalAfterClose,
    handleSubmit,
    handleClose,
  } = useAccountPayableReturnFormModal();

  return (
    <Modal
      isOpen={accountPayableReturnFormModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.loading}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={!state.loading}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Devolução de lançamento</h4>
          {!state.loading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {state.loading && (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        )}
        {!state.loading && (
          <>
            <div className="crud-header">
              <p>
                Você realmente deseja devolver esse lançamento? Isso fará com
                que seja criado um novo registro nos relatórios indicando esta
                devolução.
              </p>
            </div>
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="react-modal-body">
                <div className="form-row">
                  <label className="col-6 form-control">
                    <span>Data de devolução</span>
                    <InputMask
                      onChange={handleReturnDateChange}
                      className={isInvalid ? "isInvalid" : ""}
                      value={state.returnDate}
                      placeholder="dd/mm/aaaa"
                      mask="99/99/9999"
                      unmask
                      id="returnDate"
                      name="returnDate"
                      data-testid="returnDate"
                    />
                    <InvalidFeedback
                      condition={!state.valid}
                      message={state.returnDateInvalidFeedback}
                    />
                    <InvalidFeedback
                      condition={state.companyLocked}
                      message={companyLockedErrorMsg}
                    />
                  </label>
                </div>
                {state.anyCostCenterDisabled && (
                  <div className="any-cost-center-disabled">
                    <hr />
                    <div>
                      <h4>
                        Alerta: O Centro de Custo deste lançamento está inativo
                      </h4>
                      <p>
                        Para que a devolução seja realizada você deverá ativá-lo
                        novamente, ou poderá escolher a seguir outro centro de
                        custo que receberá o lançamento de devolução. Os
                        lançamentos originais não serão alterados.
                      </p>
                    </div>
                    <div>
                      <ClientSoulTable
                        autoLayout={false}
                        emptyMessage="Nenhum registro encontrado."
                        data={state.costCenterLinks}
                        columns={columns}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="react-modal-footer">
                <button
                  id="btn-close"
                  type="button"
                  className="form-button red-bkg"
                  onClick={handleClose}
                >
                  Cancelar
                </button>
                <button
                  id="btn-return"
                  type="submit"
                  disabled={state.submitting}
                  className={`form-button ${
                    state.valid ? "green-bkg" : "invalid-bkg"
                  }`}
                >
                  Fazer devolução{" "}
                  {state.submitting && <i className="pi pi-spin pi-spinner" />}
                </button>
              </div>
            </form>
          </>
        )}
      </Container>
    </Modal>
  );
}
