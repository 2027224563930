import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IUploadCostCenterTransactionAttachmentsContract,
  IUploadCostCenterTransactionAttachmentsParams,
} from "../../domain/contracts/uploadCostCenterTransactionAttachmentsContract";

export class UploadCostCenterTransactionAttachmentsService
  implements IUploadCostCenterTransactionAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async uploadCostCenterTransactionAttachments(
    params: IUploadCostCenterTransactionAttachmentsParams,
  ) {
    const { costCenterTransactionId, attachments, onUploadProgress } = params;

    const userEntity = this.getUserLocalService.get();

    const url = `CostCenterTransactions/${costCenterTransactionId}/Attachments`;

    const formData = new FormData();

    for (let index = 0; index < attachments.length; index += 1) {
      const attachment = attachments[index];

      formData.append(`Uploads[${index}].Name`, attachment.name.toString());
      formData.append(`Uploads[${index}].Active`, attachment.active.toString());
      formData.append(`Uploads[${index}].Type`, attachment.type.toString());

      if (attachment.file) {
        formData.append(`Uploads[${index}].File`, attachment.file);
      }

      if (attachment.barcode) {
        formData.append(`Uploads[${index}].barcode`, attachment.barcode);
      }

      if (attachment.storageFileId) {
        formData.append(
          `Uploads[${index}].storageFileId`,
          attachment.storageFileId,
        );
      }
    }

    return this.api.post(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
      onUploadProgress,
    });
  }
}
