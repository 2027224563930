import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 11.25rem;
  color: #717386;
  font-size: 1rem;
  background: #fff;
  border-radius: 0.75rem;

  border: 0.125rem dashed #b8b9c3;
  width: 100%;

  cursor: pointer;

  overflow-x: auto;

  &.is-invalid {
    border-color: #f5365c !important;
  }

  p {
    margin: auto;
  }

  div.dropzone-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    max-width: 10rem;
    min-width: 10rem;

    img {
      width: 8.4375rem;
      height: 8.4375rem;
    }

    button {
      opacity: 0;
      border: none;
      background: none;

      top: 0.3125rem;
      right: 0.3125rem;
      position: absolute;

      svg {
        width: 1.625rem;
        height: 1.625rem;
        color: #bbb;
      }

      &:hover {
        svg {
          color: #aeaeae;
        }
      }
    }

    &:hover > button {
      opacity: 1;
    }

    p {
      color: #000;
      max-width: 75%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }
  }
`;
