import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IImportSafraTaxesRemittanceFileContract } from "../../domain/contracts/importSafraTaxesRemittanceFileContract";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";
import { RequestProgressCallback } from "../../domain/entities/requestProgressCallbackType";

export class ImportSafraTaxesRemittanceFileService
  implements IImportSafraTaxesRemittanceFileContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async importSafraTaxesRemittanceFile(
    file: File,
    uploadProgressCallback: RequestProgressCallback,
  ) {
    const url = `/Remittances/${ERemittanceBankCode.Safra}/${ERemittanceType.Taxes}/File`;
    const userEntity = this.getUserLocalService.get();

    const formData = new FormData();

    formData.append(`File`, file);

    const response = await this.api.postAndDownload?.(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        uploadProgressCallback(progressEvent.loaded, progressEvent.total);
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data]);

    FileSaver.saveAs(blob, fileName);
  }
}
