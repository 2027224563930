import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableDataSelectableParams,
  DataTableRowClickEventParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaEye } from "react-icons/fa";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import {
  AdvTable,
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../advTable/presentation/components/ColumnSelector";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useOnClickOutside } from "../../../../../core/presentation/hooks/useOnClickOutside";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IFiltersValue } from "../../../domain/contracts/listPaymentAccountReportContract";
import {
  EEditModalMode,
  usePaymentAccountReportGrid,
} from "../../hooks/usePaymentAccountReportGrid";
import { ExportingModalContent } from "../ExportingModalContent";
import { Toolbar } from "../Toolbar";
import { Container, ContextMenuItem } from "./styles";
import {
  MakePaymentAccountReport,
  makePaymentAccountReport,
} from "../../../main/makePaymentAccountReport";
import {
  EOriginEntity,
  EPaymentAccountReportType,
  EReportOrigin,
  IPaymentAccountReportEntity,
} from "../../../domain/entities/paymentAccountReportEntity";
import {
  ApiService,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { useApiResponseErrorHandlers } from "../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { MakeCore, makeCore } from "../../../../../core/main/makeCore";
import { PaymentAccountFormModal } from "../../../../../paymentAccounts/presentation/components/PaymentAccountFormModal";
import {
  MakePaymentAccountTransactions,
  makePaymentAccountTransactions,
} from "../../../../../transactions/paymentAccountTransactions/main/makePaymentAccountTransactions";
import {
  MakePaymentAccounts,
  makePaymentAccounts,
} from "../../../../../paymentAccounts/main/makePaymentAccounts";
import {
  MakeCompany,
  makeCompany,
} from "../../../../../company/main/makeCompany";
import { PaymentAccountTransactionFormModal } from "../../../../../transactions/paymentAccountTransactions/presentation/components/PaymentAccountTransactionFormModal";
import { ExportAttachmentsModal } from "../ExportAttachmentsModal";
import { ProjectTransactionFormModal } from "../../../../../transactions/projectTransactions/presentation/components/ProjectTransactionFormModal";
import {
  MakeProjectTransactions,
  makeProjectTransactions,
} from "../../../../../transactions/projectTransactions/main/makeProjectTransactions";
import { ProjectFormModal } from "../../../../../projects/presentation/components/ProjectFormModal";
import {
  MakeProjects,
  makeProjects,
} from "../../../../../projects/main/makeProjects";

export interface IFetchDataProps {
  filtersValue: IFiltersValue;
  pfsEventEntity: IPFSEventEntity;
  uiSelectedColumns: IAdvTableColumn[];
  orderedColumnNames: string[];
}

interface PaymentAccountReportPageProps {
  useCore: MakeCore;
  useCompany: MakeCompany;
  useProjects: MakeProjects;
  usePaymentAccounts: MakePaymentAccounts;
  useProjectTransactions: MakeProjectTransactions;
  usePaymentAccountReport: MakePaymentAccountReport;
  usePaymentAccountTransactions: MakePaymentAccountTransactions;
}

export function PaymentAccountReportPage({
  useCore,
  useCompany,
  useProjects,
  usePaymentAccounts,
  useProjectTransactions,
  usePaymentAccountReport,
  usePaymentAccountTransactions,
}: PaymentAccountReportPageProps) {
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = usePaymentAccountReportGrid({
    usePaymentAccountReport,
  });
  const { listPaymentAccountReport, exportPaymentAccountReport } =
    usePaymentAccountReport;

  const ref = useRef(null);
  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const menuRef = useRef<ContextMenu>(null);
  const { advGeneratePayload } = useTables();

  const table = useRef<IAdvTableHandle>(null);

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(id);

  const [filtered, setFiltered] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedPanorama, setSelectedPanorama] = useState<IPanoramaEntity>();
  const [selection, setSelection] = useState<IPaymentAccountReportEntity[]>([]);

  const [data, setData] =
    useState<IResponseEntity<IPaymentAccountReportEntity[]>>();

  const [orderedColNames, setOrderedColNames] = useState<string[]>(
    columns.map(col => col.field),
  );

  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity({ rows: 50 }),
  );

  /* Indica quando a opção de 'Exportar para Excel' deve ser desabilitada */
  const disableSheetExportation = !data?.data?.length;

  const [filters, setFilters] = useState<IFiltersValue>({
    startDate: "",
    endDate: "",
    reportFilterDate: "1",
  });

  const [selectedColumns, setSelectedColumns] = useState(() => {
    // comecamos com uma copia da lista de colunas pois alguns estados estão
    // sendo salvos na propria abstracao da coluna de forma mutável e se nao
    // fizermos isso ppodemos causar algumas inconsistencias
    // REVIEW
    return columns.map(col => ({ ...col }));
  });

  /** Indica quando o modal de exportar anexos está aberto. */
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  /** Indica quando a opção de todos os registros filtrados está selecionada. */
  const [selectAllRegisters, setSelectAllRegisters] = useState(false);
  /** Armazena os dados da linha ao exibir o menu de contexto. */
  const [contextMenuData, setContextMenuData] =
    useState<IPaymentAccountReportEntity>();
  /** Após o menu de contexto ser exibido, armazena dados necessários para exibição detalhada. */
  const [currentSelectedRow, setCurrentSelectedRow] = useState({
    id: "",
    origin: 0,
    entity: EOriginEntity.None,
  });

  /**
   * Responsavel por fazer o fetch que obtem a lista de dados p/ exibir na tela
   */
  const fetchData = useCallback(
    async ({
      filtersValue,
      orderedColumnNames,
      pfsEventEntity,
      uiSelectedColumns,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setIsFetching(false);
        return;
      }

      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      setFiltered(isFiltered);
      setIsFetching(true);

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listPaymentAccountReport(
          id,
          payload,
          filtersValue,
        );
        if (!mountedRef.current) {
          return;
        }

        setData(response);

        /**
         * Caso existam relatórios originados de movimentações, desabilita
         * o checkbox principal.
         * */
        const hasTransaction = response.data.some(
          r => r.origin === EReportOrigin.transaction,
        );
        const method = hasTransaction ? "add" : "remove";
        const headerCheckbox = document.querySelector("th.soul-chkbox-col");
        headerCheckbox?.classList[method]("hide-checkbox");
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        /**
         * Caso o checkbox de todos os registros filtrados esteja ativo,
         * bloqueia a seleção dos dados carregados.
         * */
        ReactTooltip.rebuild();
        setIsFetching(false);
      }
    },
    [id, mountedRef, advGeneratePayload, listPaymentAccountReport],
  );

  const handleFiltersChange = useCallback(
    (event: IFiltersValue) => {
      const newFilters = { ...event };
      setFilters(newFilters);

      /** Ao alterar os filtros da toolbar, reseta a seleção. */
      setSelection([]);

      fetchData({
        filtersValue: newFilters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, orderedColNames, pfsEvent, selectedColumns],
  );

  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setPfsEvent(_pfsEvent);

      fetchData({
        filtersValue: filters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, filters, orderedColNames, selectedColumns],
  );

  const rowClassName = useCallback(
    (rowData: IPaymentAccountReportEntity) => {
      const { type, origin } = rowData;

      return {
        "disabled-checkbox": selectAllRegisters,
        "hide-checkbox": origin === EReportOrigin.transaction,
        "row-expense": type === EPaymentAccountReportType.expense,
        "row-revenue": type === EPaymentAccountReportType.revenue,
      };
    },
    [selectAllRegisters],
  );

  const renderEmptyMessage = useCallback(() => {
    return (
      <div className="empty-message">
        <div>
          Clique em <b>&ldquo;Mostrar&rdquo;</b> para carregar os registros.
        </div>
      </div>
    );
  }, []);

  const handleOnClearButtonClick = useCallback(() => {
    table.current?.resetFilters();
  }, []);

  useOnClickOutside(ref, (_, event) => {
    if (event) {
      menuRef?.current?.hide(event as unknown as SyntheticEvent);
    }
  });

  /**
   * Lida com o evento de seleção de linhas.
   * Considera apenas eventos originados a partir da checkbox, desprezando
   * eventos de click na linha.
   */
  const handleSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      if (e.type === "row") return;
      const value = e.value as IPaymentAccountReportEntity[];
      setSelection(old => {
        /**
         * Se for seleção manual, retorna o próprio valor do evento.
         */
        if (e.type === "checkbox") {
          return value;
        }

        /**
         * Caso o usuário deselecione o checkbox geral, remove todos os dados
         * atuais da tabela dos itens selecionados.
         */
        if (!value.length) {
          return old.filter(oldItem => {
            return !data?.data.find(
              currentPageData => currentPageData.id === oldItem.id,
            );
          });
        }

        /**
         * Caso seja a seleção de todos os itens atuais, reúne o valor do evento
         * com o valor atual de seleções, removendo as duplicatas.
         */
        const mixedList = old.concat(value);
        const uniqueList = mixedList.filter((current, i, array) => {
          const currentId = current.id;
          const currentIndex = array.findIndex(a => a.id === currentId);
          return currentIndex === i;
        });

        return uniqueList;
      });
    },
    [data?.data],
  );

  /** Exibe a origem de lancamento selecionado na grid de acordo com seu tipo */
  const handleViewCommand = useCallback((event: unknown) => {
    const eventClone = event as
      | IPaymentAccountReportEntity
      | { item: { data: IPaymentAccountReportEntity } };

    const origin =
      "item" in eventClone ? eventClone.item.data.origin : eventClone.origin;
    const originId =
      "item" in eventClone
        ? eventClone.item.data.originId
        : eventClone.originId;

    const isProject =
      "item" in eventClone
        ? !!eventClone.item.data.projectId
        : !!eventClone.projectId;

    const entity = isProject
      ? EOriginEntity.Project
      : EOriginEntity.PaymentAccount;

    // quando a origem do lançamento é uma conta a pagar
    // enviamos o user p/ a tela de edição de contas a pagar
    if (origin === EReportOrigin.accountPayable) {
      window.open(`/accountsPayable/${originId}`);
    }
    // quando a origem do lançamento é uma conta a receber
    // enviamos o user p/ a tela de edição de contas a receber
    else if (origin === EReportOrigin.accountReceivable) {
      window.open(`/accountsReceivable/${originId}`);
    }
    // quando a origem do lançamento é uma movimentação
    // abrimos a modal p/ exibir a movimentação
    else if (origin === EReportOrigin.transaction) {
      setCurrentSelectedRow({
        entity,
        id: originId,
        origin: EReportOrigin.transaction,
      });
    }
    // quando a origem é o saldo inicial
    else if (origin === EReportOrigin.OpeningBalance) {
      setCurrentSelectedRow({
        entity,
        id: originId,
        origin: EReportOrigin.OpeningBalance,
      });
    }
  }, []);

  const renderViewMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaEye />
            Ver
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  const menuModel = useMemo<MenuItem[]>(
    () => [
      {
        data: contextMenuData,
        command: handleViewCommand,
        template: renderViewMenuItem,
      },
    ],
    [contextMenuData, handleViewCommand, renderViewMenuItem],
  );

  const handleOnColumnSelect = (column: IAdvTableColumn) => {
    const newColumns = [column, ...selectedColumns];
    const newOrderedColNames = newColumns.map(col => col.field);

    setSelectedColumns(newColumns);
    setOrderedColNames(newOrderedColNames);

    // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
    // na sequencia do update do estado nao tem efeito, nao queremos usar um
    // side-effect selectedColumns pois outros eventos atualizam esse estado e
    // nao queremos que que o applyPanorama seja executado em todos eles
    setTimeout(() => {
      table.current?.applyPanorama();

      fetchData({
        filtersValue: filters,
        orderedColumnNames: newOrderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: newColumns,
      });
    });
  };

  const handleOnColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setSelectedColumns(prevSelectedColumns => {
      return prevSelectedColumns.filter(selCol => selCol.field !== field);
    });
  };

  const handleOnColReorder = (colReorderParams: DataTableColReorderParams) => {
    const cols = colReorderParams.columns as unknown as Array<{
      props: { field: string };
    }>;

    const newOrderedColNames = cols
      .map(col => col.props.field)
      .filter((col: string): col is string => !!col);

    // por ora precisamos incluir as hidden tbm, pois sem elas após
    // reordenar as colunas na UI elas nao sao mais enviadas na request
    const hidden = columns
      .filter(col => {
        const c = col as ISimpleHiddenColumn;
        return c.hidden;
      })
      .map(c => c.field);

    setOrderedColNames([...newOrderedColNames, ...hidden]);
  };

  const handleOnRowDoubleClick = (e: DataTableRowClickEventParams) => {
    menuRef.current?.hide(e.originalEvent);
    handleViewCommand(e.data);
  };

  const handleOnExportButtonClick = async () => {
    if (!pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ExportingModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      await exportPaymentAccountReport(
        id,
        pfsEvent,
        selectedColumns as ISimpleColumn[],
        orderedColNames,
        filters,
      );
    } finally {
      dialog.close();
    }
  };

  const handlePanoramaOnChange = (
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate = true,
  ) => {
    const { systemDefault, panoramaDefinition } = _selectedPanorama;

    // este map é necessario pois a prop filterData da coluna é usada como um
    // state mutável (podemos rever isso e utilizar uma abordagem imutável).
    // Mas neste caso nao queremos que ele modifique a coluna original salva no
    // panorama, entao criamos uma nova lista com clones das colunas do panorama
    // para que quando o filterData seja manipulado, aconteca apenas em memoria
    // e nao no que está salvo no panorama
    // REVIEW
    let selectedPanoramaCols = panoramaDefinition.selectedColumns.map(
      selCol => ({
        ...selCol,
      }),
    );

    let pfsEventEntity = {
      ...panoramaDefinition.pfsEventEntity,
      rows: pfsEvent.rows,
    };

    if (systemDefault) {
      selectedPanoramaCols = columns.map(col => ({
        ...col,
      }));
      pfsEventEntity = new PFSEventEntity({ rows: pfsEvent.rows });
    }

    setPfsEvent(prevPfsEvent => {
      return {
        ...pfsEventEntity,
        rows: prevPfsEvent.rows,
      };
    });

    const newOrderedColNames = selectedPanoramaCols.map(selPanCol => {
      return selPanCol.field;
    });

    setOrderedColNames(newOrderedColNames);
    setSelectedColumns(selectedPanoramaCols);
    setSelectedPanorama(_selectedPanorama);

    if (!shouldUpdate) {
      return;
    }

    // refaz a request para garantir que os resultados que o usuario
    // verá estarão de acordo com os filtros do panorama
    fetchData({
      filtersValue: filters,
      orderedColumnNames: newOrderedColNames,
      pfsEventEntity,
      uiSelectedColumns: selectedPanoramaCols,
    });
  };

  /**
   * Lida com eventos de click na linha, exibindo o menu de contexto.
   */
  const handleRowClick = useCallback(
    (e: IDataTableRowClickEventParams<IPaymentAccountReportEntity>) => {
      e.originalEvent.stopPropagation();
      setContextMenuData(e.data);
      menuRef.current?.show(e.originalEvent);
    },
    [],
  );

  /**
   * Reseta o estado que armazena a linha selecionada.
   */
  const handleCloseDetailsModal = useCallback(() => {
    setCurrentSelectedRow({
      id: "",
      origin: 0,
      entity: EOriginEntity.None,
    });
  }, []);

  /**
   * Reseta o estado que armazena os dados do menu de contexto.
   */
  const handleHideContextMenu = useCallback(() => {
    setContextMenuData(undefined);
  }, []);

  /** Exibe o modal de anexos. */
  const handleExportFiles = useCallback(() => {
    setIsAttachmentModalOpen(true);
  }, []);

  /**
   * Lida com a mudança no checkbox de selecionar todos os registros filtrados.
   */
  const handleSelectAllRegistersChange = useCallback(
    (checked: CheckboxChangeParams["checked"]) => {
      const headerCheckbox = document.querySelector("th.soul-chkbox-col");
      const method = checked ? "add" : "remove";

      headerCheckbox?.classList[method]("disabled-checkbox");

      setSelectAllRegisters(checked);
    },
    [],
  );

  /**
   * Indica quando uma linha pode ser selecionada. Desativado sempre para
   * relatórios originados de Movimentação.
   */
  const handleSelectableData = useCallback(
    (e: DataTableDataSelectableParams) => {
      if (e.data.origin === EReportOrigin.transaction) return false;
      return !selectAllRegisters;
    },
    [selectAllRegisters],
  );

  // este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // das colunas internamente de forma mutável, para isso sempre que alteramos
  // states que influenciam o sequenciamento das colunas, precisamos chamar
  // imperativamente o applyPanorama (que por sua vez executa o resetColumnOrder
  // do primereact). Desta forma conseguimos manter sincronizados o estado
  // interno do prime e o render na UI
  useEffect(() => {
    table.current?.applyPanorama();
  }, [selectedPanorama]);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== id) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = id;
      setData(new ResponseEntity({ data: [] }));
      setSelection([]);
      setSelectAllRegisters(false);

      const headerCheckbox = document.querySelector("th.soul-chkbox-col");
      headerCheckbox?.classList.remove("disabled-checkbox");
      headerCheckbox?.classList.remove("hide-checkbox");
    }
  }, [id]);

  return (
    <Container ref={ref}>
      <Page className="full-page">
        <header className="custom-header">
          <Toolbar
            filters={filters}
            pfsEvent={pfsEvent}
            balance={data?.balance}
            disableSheetExportation={disableSheetExportation}
            showClearButton={filtered}
            selectedColumns={selectedColumns}
            orderedColumnNames={orderedColNames}
            onFiltersChange={handleFiltersChange}
            onPanoramaChange={handlePanoramaOnChange}
            onClearButtonClick={handleOnClearButtonClick}
            onExportButtonClick={handleOnExportButtonClick}
            usePaymentAccountReport={usePaymentAccountReport}
            onExportFiles={handleExportFiles}
            hasSelectedData={!!selection.length || selectAllRegisters}
          />
          <Checkbox
            data-tip
            data-for="checkbox-tooltip"
            checked={selectAllRegisters}
            disabled={!data?.data.length}
            id="chk-all-registers-filtered"
            data-testid="chk-all-registers-filtered"
            onChange={e => handleSelectAllRegistersChange(e.checked)}
          />
          <ReactTooltip
            place="right"
            effect="solid"
            id="checkbox-tooltip"
            disable={!data?.data.length}
          >
            Selecionar todos os registros filtrados
          </ReactTooltip>
        </header>
        <article className="no-padding fill-height">
          <ColumnSelector
            columns={columns}
            selectedColumns={selectedColumns}
            onSelect={handleOnColumnSelect}
          />
          <AdvTable
            selectable
            data={data}
            tableRef={table}
            rowsDefault={50}
            columns={columns}
            removeableColumns
            loading={isFetching}
            onPage={handleGetList}
            onSort={handleGetList}
            onClear={handleGetList}
            selectionMode="multiple"
            onFilter={handleGetList}
            selectionAutoFocus={false}
            selectedColumns={selectedColumns}
            panoramaFilters={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.filters
            }
            panoramaSort={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.multiSortMeta
            }
            rowsPerPageOptions={[10, 50, 100]}
            onRowClick={handleRowClick}
            onRowDoubleClick={handleOnRowDoubleClick}
            onSelectionChange={handleSelectionChange}
            onColumnRemove={handleOnColumnRemove}
            onColReorder={handleOnColReorder}
            emptyMessage={renderEmptyMessage}
            rowClassName={rowClassName}
            isDataSelectable={handleSelectableData}
            selection={selectAllRegisters ? data?.data : selection}
          />
          <ContextMenu
            ref={menuRef}
            model={menuModel}
            onHide={handleHideContextMenu}
          />
          {currentSelectedRow.origin === EReportOrigin.transaction &&
            currentSelectedRow.entity === EOriginEntity.PaymentAccount && (
              <PaymentAccountTransactionFormModal
                modalMode={EEditModalMode.edit}
                onRequestClose={handleCloseDetailsModal}
                currentId={currentSelectedRow.id}
                usePaymentAccountTransactions={usePaymentAccountTransactions}
                isOpen={
                  currentSelectedRow.entity === EOriginEntity.PaymentAccount &&
                  currentSelectedRow.origin === EReportOrigin.transaction
                }
              />
            )}
          {currentSelectedRow.origin === EReportOrigin.transaction &&
            currentSelectedRow.entity === EOriginEntity.Project && (
              <ProjectTransactionFormModal
                modalMode={EEditModalMode.edit}
                currentId={currentSelectedRow.id}
                onRequestClose={handleCloseDetailsModal}
                useProjectTransactions={useProjectTransactions}
                isOpen={
                  currentSelectedRow.entity === EOriginEntity.Project &&
                  currentSelectedRow.origin === EReportOrigin.transaction
                }
              />
            )}
          <PaymentAccountFormModal
            readonly
            useCore={useCore}
            onClose={handleCloseDetailsModal}
            currentId={currentSelectedRow.id}
            usePaymentAccounts={usePaymentAccounts}
            listCompanies={useCompany.listCompanies}
            isOpen={
              currentSelectedRow.entity === EOriginEntity.PaymentAccount &&
              currentSelectedRow.origin === EReportOrigin.OpeningBalance
            }
          />
          <ProjectFormModal
            readonly
            useProjects={useProjects}
            onClose={handleCloseDetailsModal}
            projectId={currentSelectedRow.id}
            isOpen={
              currentSelectedRow.entity === EOriginEntity.Project &&
              currentSelectedRow.origin === EReportOrigin.OpeningBalance
            }
          />
          <ExportAttachmentsModal
            companyGroupId={id}
            isOpen={isAttachmentModalOpen}
            selectedData={selectAllRegisters || selection}
            usePaymentAccountReport={usePaymentAccountReport}
            onClose={() => {
              setIsAttachmentModalOpen(false);
            }}
            tableState={{
              filtersValue: filters,
              pfsEventEntity: pfsEvent,
              uiSelectedColumns: selectedColumns,
              orderedColumnNames: orderedColNames,
            }}
          />
        </article>
      </Page>
    </Container>
  );
}

interface PaymentAccountReportPageFactoryProps {
  api: IApiService;
}

export function PaymentAccountReportPageFactory({
  api,
}: PaymentAccountReportPageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();
  const authApi = new ApiService(baseUrl, apiResponseErrorHandlers);

  return (
    <PaymentAccountReportPage
      useCore={makeCore(api)}
      useCompany={makeCompany(api)}
      useProjects={makeProjects(api)}
      usePaymentAccounts={makePaymentAccounts(api)}
      useProjectTransactions={makeProjectTransactions(api, authApi)}
      usePaymentAccountReport={makePaymentAccountReport(api, authApi)}
      usePaymentAccountTransactions={makePaymentAccountTransactions(
        api,
        authApi,
      )}
    />
  );
}
