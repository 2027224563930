import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IChangeFilteredCompaniesLockDatesContract,
  IChangeFilteredCompaniesLockDatesParams,
} from "../../domain/contracts/changeFilteredCompaniesLockDatesContract";

export class ChangeFilteredCompaniesLockDatesService
  implements IChangeFilteredCompaniesLockDatesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  changeFilteredCompaniesLockDates({
    search,
    newLockDate,
  }: IChangeFilteredCompaniesLockDatesParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "Companies/ChangeLockReleasesUntilFromQuery";

    const lockDate = this.formatDate(newLockDate);

    const payload = {
      search,
      lockDate,
    };

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    const parsed = parse(date, "ddMMyyyy", new Date());
    return format(parsed, "yyyy-MM-dd");
  }
}
