import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IBulkTerminateAccountsContract,
  IBulkTerminateAccountsParams,
} from "../../domain/contracts/bulkTerminateAccountsContract";
import { EAccountReceivableStatus } from "../../domain/entities/accountReceivableListItemEntity";

interface IBulkTerminateAccountPayload {
  status: EAccountReceivableStatus;
  date: string | null;
  useReceiveUntil: boolean;
  parcelIds: string[];
}

export class BulkTerminateAccountService
  implements IBulkTerminateAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async bulkTerminateAccounts({
    parcelIds: accountReceivableIds,
    terminationDate,
    useReceiveUntil,
  }: IBulkTerminateAccountsParams): Promise<void> {
    const dtTerminationDate = terminationDate
      ? parse(terminationDate.replace(/\D/g, ""), "ddMMyyyy", new Date())
      : null;

    const fmtTerminationDate = dtTerminationDate
      ? format(dtTerminationDate, "yyyy-MM-dd 00:00:00")
      : null;

    const payload: IBulkTerminateAccountPayload = {
      parcelIds: accountReceivableIds,
      date: fmtTerminationDate,
      status: EAccountReceivableStatus.Paid,
      useReceiveUntil,
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountReceivableParcels/Status`;

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
