import { IProjectTransactionInputEntity } from "../entities/projectTransactionInputEntity";
import { IProjectTransactionEntity } from "../entities/projectTransactionEntity";

export interface ISaveProjectTransactionervice {
  saveProjectTransaction(
    ProjectTransactions: IProjectTransactionInputEntity,
  ): Promise<IProjectTransactionEntity>;
}

export interface ISaveProjectTransactionUseCase {
  saveProjectTransaction(
    ProjectTransactions: IProjectTransactionInputEntity,
  ): Promise<IProjectTransactionEntity>;
}

export class SaveProjectTransactionUseCase
  implements ISaveProjectTransactionUseCase
{
  constructor(
    private saveProjectTransactionervice: ISaveProjectTransactionervice,
  ) {}

  saveProjectTransaction(
    ProjectTransactions: IProjectTransactionInputEntity,
  ): Promise<IProjectTransactionEntity> {
    return this.saveProjectTransactionervice.saveProjectTransaction(
      ProjectTransactions,
    );
  }
}
