import { InputText } from "primereact/inputtext";
import { useColumnFilter } from "../../hooks/useColumnFilter";
import { Container } from "./styles";

export function ColumnFilterText() {
  const { filterValue, setFilterValue } = useColumnFilter();

  return (
    <Container>
      <div className="p-inputgroup">
        <InputText
          ref={ref => {
            ref?.focus?.();
          }}
          value={(filterValue as string) ?? ""}
          onChange={event => setFilterValue?.(event.target.value)}
          placeholder="Digite algo..."
        />
      </div>
    </Container>
  );
}
