import { isBefore, isEqual, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { IConciliationFormInputEntity } from "../../../domain/entitites/conciliationFormInputEntity";

interface IInputStartDateProps {
  disabled?: boolean;
}

export function InputStartDate({ disabled = false }: IInputStartDateProps) {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<IConciliationFormInputEntity>();
  const dateValidator = useDateValidator();

  const validateStartDate = useCallback(
    (value: string) => {
      const values = getValues();
      const { endDate: endDateStr } = values;

      if (value === endDateStr) {
        return true;
      }

      if (value && endDateStr) {
        const initialDate = parse(value, "ddMMyyyy", new Date());
        const endDate = parse(endDateStr, "ddMMyyyy", new Date());

        if (isEqual(initialDate, endDate)) {
          return true;
        }

        return isBefore(initialDate, endDate);
      }

      return true;
    },
    [getValues],
  );

  return (
    <Controller
      control={control}
      name="startDate"
      rules={{
        required: true,
        validate: {
          invalidDate: v => !dateValidator(v),
          validateStartDate,
        },
      }}
      render={({ field }) => (
        <>
          <InputMask
            {...field}
            unmask
            mask="99/99/9999"
            disabled={disabled}
            id="txt-startDate"
            placeholder="Data de emissão"
            data-testid="txt-startDate"
            className={errors?.startDate ? "isInvalid" : ""}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.startDate?.type === "required"}
          />
          <InvalidFeedback
            message="Preencha uma data válida"
            condition={errors.startDate?.type === "invalidDate"}
          />
          <InvalidFeedback
            condition={errors.startDate?.type === "validateStartDate"}
            message="A data inicial deve ser menor que a data final"
          />
        </>
      )}
    />
  );
}
