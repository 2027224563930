import {
  ApiResponseErrorHandlersType,
  ApiService,
  IApiService,
} from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetCompanyGroupService } from "../data/services/getCompanyGroupListService";
import { GetCurrentCompanyGroupService } from "../data/services/getCurrentCompanyGroupService";
import { SetCurrentCompanyGroupService } from "../data/services/setCurrentCompanyGroupService";
import { ICompanyGroupEntity } from "../domain/entities/companyGroupEntity";
import { GetCompanyGroupListUseCase } from "../domain/usecases/getCompanyGroupListUseCase";
import { GetCurrentCompanyGroupUseCase } from "../domain/usecases/getCurrentCompanyGroupUseCase";
import { SetCurrentCompanyGroupUseCase } from "../domain/usecases/setCurrentCompanyGroupUseCase";

export type MakeAdmin = {
  api: IApiService;
  getCompanyGroupList: (
    activeOnly: boolean,
    userId: string,
  ) => Promise<ICompanyGroupEntity[]>;
  getCurrentCompanyGroup: () => ICompanyGroupEntity | null;
  setCurrentCompanyGroup: (companyGroup: ICompanyGroupEntity) => void;
};

export function makeAdmin(
  apiResponseErrorHandlers: ApiResponseErrorHandlersType,
): MakeAdmin {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;
  const baseURL = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;

  const api = new ApiService(baseURL, apiResponseErrorHandlers);

  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const getCompanyGroupListService = new GetCompanyGroupService(
    getUserLocalService,
    api,
  );
  const getCompanyGroupListUseCase = new GetCompanyGroupListUseCase(
    getCompanyGroupListService,
  );

  const setCurrentCompanyGroupService = new SetCurrentCompanyGroupService();
  const setCurrentCompanyGroupUseCase = new SetCurrentCompanyGroupUseCase(
    setCurrentCompanyGroupService,
  );

  const getCurrentCompanyGroupService = new GetCurrentCompanyGroupService();
  const getCurrentCompanyGroupUseCase = new GetCurrentCompanyGroupUseCase(
    getCurrentCompanyGroupService,
  );

  return {
    api,
    getCompanyGroupList: (activeOnly, userId) =>
      getCompanyGroupListUseCase.get(activeOnly, userId),
    getCurrentCompanyGroup: () => getCurrentCompanyGroupUseCase.get(),
    setCurrentCompanyGroup: (companyGroup: ICompanyGroupEntity) =>
      setCurrentCompanyGroupUseCase.set(companyGroup),
  };
}
