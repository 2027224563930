import { IClassificationAssessmentEntity } from "../../../classificationAssessment/domain/entities/classificationAssessment";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchClassificationAssessmentsService } from "../../domain/usecases/searchClassificationAssessmentsUseCase";

export class SearchClassificationAsssesmentsService
  implements ISearchClassificationAssessmentsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  searchClassificationAssessments(
    search: string,
    companyGroupId: string,
    active = true,
  ) {
    const userEntity = this.getUserLocalService.get();

    const searchPayload = new PayloadEntity({
      draw: 0,
      length: 0,
      columns: [
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [{ column: 0, dir: "asc" }],
      search: { value: search, regex: false },
    });

    const params = new URLSearchParams({
      body: JSON.stringify(searchPayload),
    });

    let url = `/CompanyGroups/${companyGroupId}/ClassificationAssessments`;

    if (active) url += "/Actives";

    return this.api.get<
      IServerSideResponseModel<IClassificationAssessmentEntity[]>
    >(`${url}?${params}`, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
