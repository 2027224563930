import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchProjectContract } from "../../domain/contracts/searchProjectContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface IProjectResponse {
  id: string;
  description: string;
}

export class SearchProjectService implements ISearchProjectContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchProject({
    activesOnly,
    search = "",
    companyGroupId,
    payloadOptions,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      order: payloadOptions?.order || [{ column: 1, dir: "asc" }],
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "description",
          name: "description",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/Projects`;

    if (activesOnly) {
      url += "/Actives";
    }

    const response = await this.api.get<
      IServerSideResponseModel<IProjectResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(project => {
        return new RelationshipFilterOption({
          rawValue: project.id,
          label: project.description,
          metadata: showMetadata ? project : undefined,
        });
      }),
    });
  }
}
