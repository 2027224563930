import styled from "styled-components";

interface ButtonContainerProps {
  color: string;
}

export const Container = styled.span<ButtonContainerProps>`
  display: flex;
  flex: 1 0 100%;

  @media (min-width: 992px) {
    flex: 0 0 calc(50% - 1rem);
  }

  @media (min-width: 1205px) {
    flex: 0 0 calc(25% - 1.5rem);
  }

  transition: transform 0.5s;

  &:hover {
    transform: translateY(-5%);
  }

  border-radius: 0.375rem;

  & :first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  & :last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  a {
    text-decoration: none;
  }

  .main-button {
    width: 100%;
    padding: 1.5rem 0.875rem 1.5rem;

    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${props => props.color};

    box-shadow: 0 0.5rem 1rem #00000010;

    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 0 0.75rem 1.5rem #00000020;
    }

    p {
      max-width: 70%;
      text-align: left;
      font-weight: 800;
      font-size: 0.9rem;
      font-family: "Open Sans";
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      max-width: 30%;

      width: 3rem;
      height: 3rem;

      font-size: 1.5rem;

      color: white;

      border-radius: 100%;
      background-color: ${props => props.color};
    }
  }

  &:not(:has(.single-button, .dropdown-button)) {
    .main-button {
      padding-right: 3.375rem;

      @media (min-width: 992px) {
        padding-right: 0.875rem;
      }
    }
  }

  .single-button,
  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.75rem;

    background-color: #f7fafc;
    box-shadow: 0 0 0.5rem #00000020;

    transition: box-shadow 0.5s;

    color: ${props => props.color};

    svg {
      transition: all 0.5s;
    }

    &:hover {
      box-shadow: 0 0 0.5rem #00000040;

      svg {
        filter: brightness(1.5);
      }
    }
  }

  .dropdown-button {
    width: 100%;
    height: 100%;

    padding: 0.75rem;
    border-radius: 0 0.375rem 0.375rem 0;
  }

  div.dropdown-button:hover {
    svg {
      color: ${props => props.color};
      transition: all 0.5s;
      filter: brightness(1.5);
    }
  }
`;
