import { IUserEntity } from "../../domain/entities/userEntity";
import { ISetUserLocalService } from "../../domain/usecases/setUserLocalUseCase";
import { ELocalStorageKeys } from "../utils/localStorageKeys";

interface IEncryptService {
  encrypt(data: object): string;
}

export class SetUserLocalService implements ISetUserLocalService {
  userKey: string = ELocalStorageKeys.User;

  constructor(private encryptService: IEncryptService) {}

  set(user: IUserEntity) {
    const encryptedData = user ? this.encryptService.encrypt(user) : "";
    localStorage.setItem(this.userKey, encryptedData);
  }
}
