import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ILinkClassificationsAccountModel,
  ILinkClassificationsAccountService,
} from "../../domain/usecases/linkClassificationsAccountUseCase";

export class LinkClassificationsAccountService
  implements ILinkClassificationsAccountService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async linkClassificationsAccount(
    providerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Providers/${providerId}/ClassificationAccounts`;

    const linkClsAccountModel = {
      classificationAccounts: classificationAccountEntity.map(({ id }) => id),
    };

    const response = await this.api.post<ILinkClassificationsAccountModel>(
      url,
      linkClsAccountModel,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response;
  }
}
