import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";
import { IListCostCentersService } from "../../domain/usecases/listCostCenterUseCase";

export class ListCostCentersManagerService implements IListCostCentersService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCostCenters(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<ICostCenterEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    let url = `/Manager/CompanyGroups/${companyGroupId}/CostCenters`;

    if (actives) {
      url = `${url}/Actives`;
    }

    const response = await this.api.get<
      IServerSideResponseModel<ICostCenterEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
