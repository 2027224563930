import styled from "styled-components";

export const Container = styled.div`
  width: 700px;

  .loading-container {
    text-align: center;
    margin-bottom: 2rem;
  }

  .react-modal-footer {
    text-align: right;
  }
`;
