import styled from "styled-components";

export const ContainerSection = styled.section`
  .pi.pi-question-circle {
    font-size: 0.869rem;
    cursor: pointer;
  }

  .accounts-payable-form__provider-input__label {
    width: 100%;
    display: flex;

    span {
      margin-left: 0.25rem;

      & + i {
        margin-left: 0.25rem;
      }
    }
  }

  label.form-control.form-control-append {
    span:first-child + div {
      display: flex;
      width: 100%;

      > input:not([type="checkbox"]),
      textarea,
      select,
      .form-input,
      .p-dropdown,
      .p-autocomplete > input {
        font-family: var(--font-family);
        flex: 1 1 auto;
        padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
        font-size: 0.875rem;
        border-radius: 0.25rem 0 0 0.25rem;
        line-height: 1.5;
        border-style: solid;
        border-width: 1px;
        border-color: var(--input-border-color);
        height: calc(1.5em + 0.5rem + 2px);
        color: #495057 !important;
        background-color: #fff;

        :focus {
          outline: unset;
          box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
          border-color: rgba(50, 151, 211, 0.25);
        }

        ::placeholder {
          color: #d1d1d9;
        }

        :disabled {
          background-color: #e9ecef;
          color: #495057 !important;
        }

        &.isInvalid {
          border-color: var(--invalid);

          :focus {
            box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
          }
        }
      }
    }

    input + button,
    input + button + button {
      display: inline-flex;
      border: none;
      width: 1.9375rem;
      height: 1.9375rem;
      background-color: var(--highlight-color);
      color: white;
      justify-content: center;
      align-items: center;
    }

    input + button + button {
      border-left: 1px solid #4a5cc4;
      border-radius: 0 6px 6px 0;
    }
  }

  .provider-field {
    width: 100%;
    display: flex;
    align-items: center;

    > div > input {
      border-right: unset;
      border-radius: 0.25rem 0 0 0.25rem;
    }

    .btn-edit-provider {
      border: none;
      border-radius: 0 0.25rem 0.25rem 0;

      display: flex;
      align-items: center;

      padding: 0.47rem;

      color: white;
      background-color: #5e72e4;

      font-size: 1rem;

      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;

      &:hover {
        background-color: #324cdd;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
