import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGenerateFeeRetentionContract } from "../../domain/contracts/generateFeeRetentionContract";
import { IGenerateFeeRetentionEntity } from "../../domain/entities/generateFeeRetentionEntity";
import {
  IGenerateFeeRetentionModel,
  IGenerateFeeRetentionPayload,
} from "../models/generateFeeRetentionModel";

export class GenerateFeeRetentionService
  implements IGenerateFeeRetentionContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateFeeRetention(
    generateFeeRetentionEntity: IGenerateFeeRetentionEntity,
  ): Promise<void> {
    const { fees, accountPayableId, shouldGenerateFeeRetention } =
      generateFeeRetentionEntity;

    const accountPayableFees: IGenerateFeeRetentionModel[] = fees
      // enviamos somente os impostos cujo valor nao sejam zero
      .filter(({ value }) => Number(value) !== 0)
      .map(({ retentionId, feeId, value, name, id }) => {
        return {
          feeId,
          feeName: name,
          id,
          accountPayableId,
          value: Number(value),
          active: true,
          retentionId,
        };
      });

    if (accountPayableFees.length) {
      const payload: IGenerateFeeRetentionPayload = {
        accountPayableId,
        generateFeeRetention: shouldGenerateFeeRetention,
        accountPayableFees,
      };

      const url = `/AccountPayableFees/GenerateFeeRetention`;
      const userEntity = this.getUserLocalService.get();

      await this.api.post(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
          "Accept-Language": "pt-BR",
        },
      });
    }
  }
}
