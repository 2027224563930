import { Accordion } from "primereact/accordion";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  margin: 1rem 0;

  .p-accordion-tab {
    margin: unset;

    .p-accordion-header:not(.p-disabled),
    .p-accordion-header:not(.p-disabled):hover {
      .p-accordion-header-link {
        gap: 0.5rem;
        padding: 1rem;
        color: #525f7f;
        font-weight: 600;
        font-size: 0.875rem;
        border-radius: unset;
        background-color: unset;
        justify-content: flex-end;
        flex-direction: row-reverse;
      }

      &:hover {
        .p-accordion-header-link {
          background-color: #00000005;
        }

        + .p-toggleable-content {
          .p-accordion-content {
            background-color: #00000005;
          }
        }
      }

      + .p-toggleable-content {
        .p-accordion-content {
          transition: background-color 0.2s;
        }
      }

      &:is(.p-highlight) {
        .p-accordion-header-link {
          border-bottom: unset;
        }
      }
    }
  }

  .p-accordion-content {
    padding: 1rem;
    padding-top: unset;
    border-radius: unset;
  }
`;
