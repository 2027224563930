import {
  useRef,
  useState,
  ReactNode,
  useCallback,
  PropsWithChildren,
} from "react";
import { Tooltip } from "primereact/tooltip";
import { TooltipPositionType } from "primereact/tooltip/tooltipoptions";

type IconTooltipProps = PropsWithChildren<{
  icon: ReactNode;
  mouseTrack?: boolean;
  tooltipClass?: string;
  position?: TooltipPositionType;
}>;

export function IconTooltip({
  icon,
  children,
  position = "top",
  mouseTrack = false,
  tooltipClass = "icon-tooltip",
}: IconTooltipProps) {
  const targetRef = useRef<HTMLSpanElement>();
  const [isRefLoaded, setIsRefLoaded] = useState(false);

  const targetRefCallback = useCallback((spanRef: HTMLSpanElement | null) => {
    if (spanRef) setIsRefLoaded(true);
    targetRef.current = spanRef || undefined;
  }, []);

  return (
    <>
      <span
        ref={targetRefCallback}
        style={{ display: "flex", alignItems: "center", cursor: "help" }}
      >
        {typeof icon === "string" ? <i className={icon} /> : icon}
      </span>
      <Tooltip
        position={position}
        mouseTrack={mouseTrack}
        className={tooltipClass}
        target={isRefLoaded ? targetRef.current : undefined}
      >
        {children}
      </Tooltip>
    </>
  );
}
