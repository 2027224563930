import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SearchClassificationUspService } from "../../core/data/services/searchClassificationUspService";
import { SearchCompetencyService } from "../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../core/data/services/searchCostCenterService";
import { SearchProviderService } from "../../core/data/services/searchProviderService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ISearchClassificationUspContract } from "../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompetencyContract } from "../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../core/domain/contracts/searchCostCenterContract";
import { ISearchProviderContract } from "../../core/domain/contracts/searchProviderContract";
import { CheckValidCostCentersService } from "../data/services/checkValidCostCentersService";
import { ExportProjectsService } from "../data/services/exportProjectsService";
import { GetProjectService } from "../data/services/getProjectService";
import { GetProjectTaxesService } from "../data/services/getProjectTaxesService";
import { ListProjectsService } from "../data/services/listProjectsService";
import { SaveProjectService } from "../data/services/saveProjectService";
import { SaveProjectTaxesService } from "../data/services/saveProjectTaxesService";
import { ToggleProjectService } from "../data/services/toggleProjectService";
import { UpdateProjectService } from "../data/services/updateProjectService";
import { ICheckValidCostCentersContract } from "../domain/contracts/checkValidCostCentersContract";
import { IExportProjectsContract } from "../domain/contracts/exportProjectsContract";
import { IGetProjectContract } from "../domain/contracts/getProjectContract";
import { IGetProjectTaxesContract } from "../domain/contracts/getProjectTaxesContract";
import { IListProjectsContract } from "../domain/contracts/listProjectsContract";
import { ISaveProjectContract } from "../domain/contracts/saveProjectContract";
import { ISaveProjectTaxesContract } from "../domain/contracts/saveProjectTaxesContract";
import { IToggleProjectContract } from "../domain/contracts/toggleProjectContract";
import { IUpdateProjectContract } from "../domain/contracts/updateProjectContract";

export type MakeProjects = IListProjectsContract &
  IGetProjectContract &
  ISaveProjectContract &
  IUpdateProjectContract &
  IToggleProjectContract &
  ICheckValidCostCentersContract &
  ISaveProjectTaxesContract &
  IGetProjectTaxesContract &
  ISearchClassificationUspContract &
  ISearchCostCenterContract &
  ISearchProviderContract &
  ISearchCompetencyContract &
  IExportProjectsContract;

export function makeProjects(api: IApiService): MakeProjects {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listProjectService = new ListProjectsService(getUserLocalService, api);
  const getProjectService = new GetProjectService(getUserLocalService, api);
  const saveProjectService = new SaveProjectService(getUserLocalService, api);
  const updateProjectService = new UpdateProjectService(
    getUserLocalService,
    api,
  );
  const toggleProjectService = new ToggleProjectService(
    getUserLocalService,
    api,
  );
  const checkValidCostCentersService = new CheckValidCostCentersService(
    getUserLocalService,
    api,
  );
  const saveProjectTaxesService = new SaveProjectTaxesService(
    getUserLocalService,
    api,
  );
  const getProjectTaxesService = new GetProjectTaxesService(
    getUserLocalService,
    api,
  );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );
  const searchCostCentersService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchProviderService = new SearchProviderService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const exportProjectsService = new ExportProjectsService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  return {
    listProjects(payload, currentCompanyGroupId, actives) {
      return listProjectService.listProjects(
        payload,
        currentCompanyGroupId,
        actives,
      );
    },
    getProject(projectId) {
      return getProjectService.getProject(projectId);
    },
    saveProject(payload) {
      return saveProjectService.saveProject(payload);
    },
    updateProject(payload) {
      return updateProjectService.updateProject(payload);
    },
    toggleProject(projectId) {
      return toggleProjectService.toggleProject(projectId);
    },
    checkValidCostCenters(payload) {
      return checkValidCostCentersService.checkValidCostCenters(payload);
    },
    saveProjectTaxes(payload, projectId) {
      return saveProjectTaxesService.saveProjectTaxes(payload, projectId);
    },
    getProjectTaxes(projectId) {
      return getProjectTaxesService.getProjectTaxes(projectId);
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCostCenter(params) {
      return searchCostCentersService.searchCostCenter(params);
    },
    searchProvider(params) {
      return searchProviderService.searchProvider(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    exportProjects(params) {
      return exportProjectsService.exportProjects(params);
    },
  };
}
