import styled from "styled-components";
import {
  EAccountReceivableStatus,
  EInvoiceStatus,
} from "../../../domain/entities/accountReceivableListItemEntity";

interface BadgeStatusProps {
  status: EAccountReceivableStatus;
  pill?: boolean;
}

export const BadgeStatus = styled.div<BadgeStatusProps>`
  --open: #ff6f00;
  --canceled: #dcd6d6;
  --inconsistent: var(--color-danger);
  --paid: #2dce89;

  width: 100%;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${props => {
    if (props.status === EAccountReceivableStatus.Open) {
      return "var(--open)";
    }

    if (props.status === EAccountReceivableStatus.Paid) {
      return "var(--paid)";
    }

    if (props.status === EAccountReceivableStatus.Inconsistent) {
      return "var(--inconsistent)";
    }

    return "var(--canceled)";
  }};

  ${props => {
    if (props.status !== EAccountReceivableStatus.Canceled) {
      return `color: white;`;
    }

    return "color: #495057 !important;";
  }}

  padding: 0.12em 1em;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5em;
`;

interface BadgeInvoiceStatusProps {
  invoiceStatus: EInvoiceStatus;
  pill?: boolean;
}

export const BadgeInvoiceStatus = styled.div<BadgeInvoiceStatusProps>`
  --not-generated: #ff6f00;
  --processing: #979ec7;
  --generated: #2dce89;
  --denied: #e92222;

  width: 100%;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${props => {
    if (props.invoiceStatus === EInvoiceStatus.NotGenerated) {
      return "var(--not-generated)";
    }

    if (props.invoiceStatus === EInvoiceStatus.Processing) {
      return "var(--processing)";
    }

    if (props.invoiceStatus === EInvoiceStatus.Generated) {
      return "var(--generated)";
    }

    return "var(--denied)";
  }};

  color: white;
  padding: 0.12em 1em;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5em;
`;
