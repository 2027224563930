import { IApiService } from "../../../core/data/services/apiService";
import { IGetViaCepService } from "../../domain/usecases/getViaCepUseCase";
import { IViaCepMetaData, IViaCepResponse } from "../models/viaCepResponse";

export class GetViaCepService implements IGetViaCepService {
  constructor(private api: IApiService) {}

  async getByZipCode(zipCode: string): Promise<IViaCepMetaData | null> {
    const url = `/ws/${zipCode}/json/`;

    const { bairro, logradouro, uf, ibge, erro } =
      await this.api.get<IViaCepResponse>(url);

    if (erro) return Promise.resolve(null);

    const responseMetadata: IViaCepMetaData = {
      street: logradouro ?? "",
      neighborhood: bairro ?? "",
      uf: uf ?? "",
      ibge: ibge ?? "",
    };

    return responseMetadata;
  }
}
