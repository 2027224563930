import styled from "styled-components";

export const ButtomItem = styled.button`
  width: 100%;

  border: none;

  background-color: white;

  font-size: 0.9375rem;

  &#btn-complete-add-more {
    color: #007fb8;
  }

  &#btn-complete-duplicate {
    color: #090081;
  }
`;
