import { IStateResponse } from "../../data/models/stateResponse";

export interface IGetStateByUfUseCase {
  getStateByUf(uf: string): Promise<IStateResponse | null>;
}

export interface IGetStateByUfService {
  getStateByUf(uf: string): Promise<IStateResponse | null>;
}

export class GetStateByUfUseCase implements IGetStateByUfUseCase {
  constructor(private getStateByUfService: IGetStateByUfService) {}

  getStateByUf(uf: string) {
    return this.getStateByUfService.getStateByUf(uf);
  }
}
