import { IEntity } from "../../../../core/domain/entities/entity";
import { IProjectEntity } from "./projectEntity";
import { IProjectTransactionAttachmentEntity } from "./projectTransactionAttachmentEntity";

export interface IProjectTransactionInputEntity extends IEntity {
  companyGroupId: string;
  projectOrigin: IProjectEntity | undefined;
  projectDestination: IProjectEntity | undefined;
  transactionDate: string;
  value: number;
  description: string;
  attachments: IProjectTransactionAttachmentEntity[];
}

export class ProjectTransactionInputEntity
  implements IProjectTransactionInputEntity
{
  id = "";
  companyGroupId = "";
  projectOrigin = undefined;
  projectDestination = undefined;
  transactionDate = "";
  value = 0;
  description = "";
  active = true;
  attachments = [];

  constructor(params?: Partial<IProjectTransactionInputEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IProjectTransactionInputEntity>) {
    return new ProjectTransactionInputEntity(params);
  }
}
