import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { IListAttachmentTypesContract } from "../../../../../core/domain/contracts/listAttachmentTypesContract";
import { IAttachmentType } from "../../../../../core/domain/entities/attachmentType";

export class ListAttachmentTypesService
  implements IListAttachmentTypesContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async listAttachmentTypes() {
    const userEntity = this.getUserLocalService.get();
    const url = "Enums/AttachmentTypes";

    return this.api.get<IAttachmentType[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
