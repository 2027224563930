import { IPFSEventEntity } from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IFiltersValue } from "./listCostCenterReportUseCase";

export interface IExportCostCenterReportService {
  exportCostCenterReportUseCase(
    companyGroupId: string,
    pfsEvent: IPFSEventEntity,
    selectedColumns: ISimpleColumn[],
    orderedColumnNames: string[],
    filters: IFiltersValue,
  ): Promise<void>;
}

export interface IExportCostCenterReportUseCase {
  exportCostCenterReportUseCase(
    companyGroupId: string,
    pfsEvent: IPFSEventEntity,
    selectedColumns: ISimpleColumn[],
    orderedColumnNames: string[],
    filters: IFiltersValue,
  ): Promise<void>;
}

export class ExportCostCenterReportUseCase
  implements IExportCostCenterReportUseCase
{
  constructor(
    private exportCostCenterReportService: IExportCostCenterReportService,
  ) {}

  exportCostCenterReportUseCase(
    companyGroupId: string,
    pfsEvent: IPFSEventEntity,
    selectedColumns: ISimpleColumn[],
    orderedColumnNames: string[],
    filters: IFiltersValue,
  ): Promise<void> {
    return this.exportCostCenterReportService.exportCostCenterReportUseCase(
      companyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
    );
  }
}
