import { useState } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IDebtImportAttachmentEntity } from "../../domain/entities/debtImportAttachmentEntity";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { useDebtImportPage } from "./useDebtImportPage";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateCommercialProposalForm } from "../../../../../core/domain/entities/generateCommercialProposalForm";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../../../../provider/domain/entities/providerEntity";

interface IUseAttachmentsHandler {
  formIndex: number | null;
  selection: IDebtImportEntity[];
  handleCloseAttachmentModal(): void;
}

interface IProgressModalState {
  loaded: number;
  total: number;
  isOpen: boolean;
}

interface IUseAttachmentsHandlerState {
  progressModal: IProgressModalState;
  rpaOptions: IGenerateRPAModalInputEntity[];
  commercialProposalOptions: IGenerateCommercialProposalForm[];
}

export function useAttachmentsHandler(props: IUseAttachmentsHandler) {
  const { formIndex, selection, handleCloseAttachmentModal } = props;

  const {
    getFormIndex,
    useDebtImport,
    form: { getValues, setValue, unregister },
  } = useDebtImportPage();

  const isBulk = formIndex === null;
  const rowData = isBulk ? null : getValues(`imports.${formIndex}`);
  const providerRowData = rowData?.provider?.metadata as IProviderEntity;

  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const [state, setState] = useState<IUseAttachmentsHandlerState>({
    rpaOptions: [],
    commercialProposalOptions: [],
    progressModal: {
      total: 0,
      loaded: 0,
      isOpen: false,
    },
  });

  const {
    uploadAttachments,
    getStorageFilebyId,
    listAttachmentTypes,
    fetchRpaParamOptions,
    generateRpaAttachment,
    bulkUploadAttachments,
    updateAttachmentsBarcode,
    generateMultipleRpaAttachments,
    generateVariableAdditionalAttachment,
    generateCommercialProposalAttachment,
    fetchCommercialProposalEnums,
    generateMultipleVariableAdditionalAttachments,
    generateMultipleCommercialProposalAttachments,
  } = useDebtImport;

  const handleUploadProgress = (options: IProgressModalState) => {
    setState(prevState => ({
      ...prevState,
      progressModal: options,
    }));
  };

  const uploadProgressCallback = (loaded: number, total: number) => {
    handleUploadProgress({
      loaded,
      total,
      isOpen: true,
    });
  };

  const handleRpaGeneration = async (
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ) => {
    if (isBulk) {
      setState(old => {
        return {
          ...old,
          rpaOptions: [genRPAModalInput],
        };
      });

      return {
        isBulkGeneration: true,
        generatedFile: new File([""], "(Nome gerado automaticamente)", {
          type: "pdf",
        }),
      };
    }

    dialog.fire({
      title: "Aguarde",
      html: "Estamos gerando o anexo de RPA",
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const payload = {
        valueMoney: rowData?.value,
        payUntil: rowData?.payUntil || "",
        issueDate: rowData?.issueDate || "",
        requestTypeRpa: genRPAModalInput?.requestType,
        paymentDetailsRpa: genRPAModalInput?.paymentDetail,
        serviceTypeAttachmentRpa: genRPAModalInput?.serviceType,
        projectId: (rowData?.project?.rawValue as string) || "",
        providerId: (rowData?.provider?.rawValue as string) || "",
      };

      const generatedFile = await generateRpaAttachment(payload);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleVariableAdditionalGeneration = async () => {
    if (isBulk) {
      return {
        isBulkGeneration: true,
        generatedFile: new File([""], "(Nome gerado automaticamente)", {
          type: "pdf",
        }),
      };
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Adicional variável`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const payload = {
        valueMoney: rowData?.value || 0,
        projectId: (rowData?.project?.rawValue as string) || "",
        providerId: (rowData?.provider?.rawValue as string) || "",
      };

      const generatedFile = await generateVariableAdditionalAttachment(payload);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleCommercialProposalGeneration = async (
    modalParams: IGenerateCommercialProposalForm,
  ) => {
    if (isBulk) {
      setState(old => {
        return {
          ...old,
          commercialProposalOptions: [modalParams],
        };
      });

      return {
        isBulkGeneration: true,
        generatedFile: new File([""], "(Nome gerado automaticamente)", {
          type: "pdf",
        }),
      };
    }

    dialog.fire({
      title: "Aguarde",
      html: "Estamos gerando o anexo de Proposta Comercial",
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const params = {
        ...modalParams,
        valueMoney: rowData?.value || 0,
        projectId: (rowData?.project?.rawValue as string) || "",
        providerId: (rowData?.provider?.rawValue as string) || "",
      };

      const generatedFile = await generateCommercialProposalAttachment(params);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleBulkUpload = async (
    attachments: IDebtImportAttachmentEntity[],
  ) => {
    const result = await dialog.fire({
      icon: "question",
      title: "Aviso",
      showCancelButton: true,
      cancelButtonText: "Não",
      text: `Você está vinculando os anexos informados a um total de
            ${selection.length} lançamentos. Ao confirmar, só será
            possível fazer a remoção/alteração desses anexos manualmente.
            Deseja prosseguir?`,
    });

    if (result.dismiss) {
      return;
    }

    const selectionIds = selection.map(d => d.id);

    const formValues = getValues("imports");

    const importData = formValues.filter(form => {
      return selectionIds.includes(form.id);
    });

    handleUploadProgress({
      total: 0,
      loaded: 0,
      isOpen: true,
    });

    let hasRpa = false;
    let hasVariableAdditional = false;
    let hasCommercialProposal = false;

    const singleAttachments = attachments.filter(fileObject => {
      if (fileObject.type === EAttachmentType.VariableAdditional) {
        hasVariableAdditional = true;
      }

      if (fileObject.type === EAttachmentType.RPA) {
        hasRpa = true;
      }

      if (fileObject.type === EAttachmentType.CommercialProposal) {
        hasCommercialProposal = true;
      }

      return (
        fileObject.type !== EAttachmentType.RPA &&
        fileObject.type !== EAttachmentType.VariableAdditional &&
        fileObject.type !== EAttachmentType.CommercialProposal
      );
    });

    const commercialProposalService = hasCommercialProposal
      ? generateMultipleCommercialProposalAttachments
      : () => [];

    const rpaService = hasRpa ? generateMultipleRpaAttachments : () => [];

    const variableAdditionalService = hasVariableAdditional
      ? generateMultipleVariableAdditionalAttachments
      : () => [];

    const localAttachmentService = singleAttachments.length
      ? bulkUploadAttachments
      : () => [];

    try {
      const response = await Promise.all([
        variableAdditionalService(importData),
        localAttachmentService(selectionIds, singleAttachments),
        rpaService(state.rpaOptions[0], importData),
        commercialProposalService(
          state.commercialProposalOptions[0],
          importData,
        ),
      ]);

      response.map(attachmentsLists => {
        attachmentsLists.map(file => {
          if (file.accountPayableId) {
            const bulkFormIndex = getFormIndex(file.accountPayableId);
            const oldFiles = getValues(`imports.${bulkFormIndex}.storageFiles`);
            const newFile = { ...file, index: oldFiles.length };
            const storageFiles = [...oldFiles, newFile];
            setValue(`imports.${bulkFormIndex}.storageFiles`, storageFiles);
          }
          return null;
        });
        return null;
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Anexos adicionados com sucesso.",
      });

      handleCloseAttachmentModal();
    } finally {
      handleUploadProgress({
        total: 0,
        loaded: 0,
        isOpen: false,
      });
    }
  };

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IDebtImportAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        currentCompanyGroup.id,
        billetAttachment,
      );

      return { updatedAttachment };
    } catch {
      return { updatedAttachment: billetAttachment };
    }
  };

  const handleBilletAttachmentValue = (
    attachmentsResponse: IDebtImportAttachmentEntity[],
  ) => {
    if (isBulk) {
      return;
    }

    const billetAttachment = attachmentsResponse.find(attachment => {
      return (
        Number(attachment.type) === EAttachmentType.Billet &&
        attachment?.barcode &&
        attachment.active
      );
    });

    const barcodeValue = billetAttachment?.barcode || "";

    if (!barcodeValue) {
      unregister(`imports.${formIndex}.barcode`);
      return;
    }

    setValue(`imports.${formIndex}.barcode`, barcodeValue, {
      shouldValidate: true,
    });
  };

  const handleAttachmentsSubmission = async (
    attachments: IDebtImportAttachmentEntity[],
  ) => {
    if (isBulk) {
      handleBulkUpload(attachments);
      return;
    }

    handleUploadProgress({
      total: 0,
      loaded: 0,
      isOpen: true,
    });

    const debtImportId = getValues(`imports.${formIndex}.id`);

    try {
      const response = await uploadAttachments(
        debtImportId || "",
        attachments,
        uploadProgressCallback,
      );

      const isEdition = !!getValues(`imports.${formIndex}.storageFiles`).length;

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Anexos ${
          isEdition ? "atualizados" : "adicionados"
        } com sucesso.`,
      });

      setValue(`imports.${formIndex}.storageFiles`, response);

      handleBilletAttachmentValue(response);

      handleCloseAttachmentModal();
    } finally {
      handleUploadProgress({
        total: 0,
        loaded: 0,
        isOpen: false,
      });
    }
  };

  const attachmentTypeCheck = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IDebtImportAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.RPA &&
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.VariableAdditional &&
      typeToCheck !== EAttachmentType.Measurement &&
      typeToCheck !== EAttachmentType.CommercialProposal
    ) {
      return true;
    }

    if (
      !isBulk &&
      typeToCheck === EAttachmentType.CommercialProposal &&
      !!providerRowData?.documentType &&
      providerRowData?.documentType !== ProviderDocumentType.CPF
    ) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Este lançamento é destinado a um fornecedor do tipo pessoa jurídica
            ou pessoa estrangeira.
            <br />
            <br />
            Neste caso, o arquivo <strong>não será gerado</strong>, pois a
            proposta comercial é permitida{" "}
            <strong>somente para pessoas físicas</strong>.
          </>
        ),
      });

      return false;
    }

    const typesName = {
      [EAttachmentType.RPA]: "RPA",
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.VariableAdditional]: "Adicional Variável",
      [EAttachmentType.Measurement]: "Medição",
      [EAttachmentType.CommercialProposal]: "Proposta Comercial",
    };

    const typesBulkIsNotAllowed =
      typeToCheck === EAttachmentType.Billet ||
      typeToCheck === EAttachmentType.Measurement;

    if (typesBulkIsNotAllowed && isBulk) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar em lote anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Por favor, opte por
            adicionar <strong>individualmente</strong>.
          </>
        ),
      });
      return false;
    }

    let selectionHasType = false;

    if (isBulk) {
      const updatedSelectedForms = getValues("imports").filter(({ id }) => {
        const selectionIds = selection.map(({ id: selectedId }) => selectedId);
        return selectionIds.includes(id);
      });

      if (typeToCheck === EAttachmentType.CommercialProposal) {
        const foreignerOrLegalPersonProviderForms = updatedSelectedForms.filter(
          formData => {
            const providerTyped =
              formData?.provider as ITypeaheadOption<IProviderEntity>;

            const documentType = providerTyped?.metadata?.documentType;

            return !!documentType && documentType !== ProviderDocumentType.CPF;
          },
        );

        const noIndividualPersonForm =
          foreignerOrLegalPersonProviderForms.length ===
          updatedSelectedForms.length;

        if (foreignerOrLegalPersonProviderForms.length) {
          const message = noIndividualPersonForm
            ? "Os lançamentos selecionados são"
            : "Existem lançamentos selecionados";

          await dialog.fire({
            icon: "warning",
            title: "Atenção!",
            html: (
              <>
                {message} para pessoas jurídicas e/ou pessoas estrangeiras.
                <br />
                <br />
                Para estes casos, o arquivo <strong>não será gerado</strong>,
                pois a proposta comercial somente é{" "}
                <strong>permitida para pessoas físicas</strong>.
              </>
            ),
          });

          if (noIndividualPersonForm) {
            return false;
          }
        }
      }

      selectionHasType = updatedSelectedForms
        .flatMap(a => a.storageFiles)
        .some(a => a.active && Number(a.type) === typeToCheck);
    }

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (selectionHasType || hasTypeToBeAdded) {
      const documentType = typesName[typeToCheck];

      const html =
        selectionHasType && !hasTypeToBeAdded ? (
          <>
            Algumas das contas selecionadas já possuem anexos do tipo{" "}
            <strong>{documentType}</strong>. Para prosseguir,{" "}
            <strong>remova da seleção</strong> as contas que já possuam o
            respectivo anexo e tente novamente.
          </>
        ) : (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{documentType}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        );

      await dialog.fire({
        icon: "error",
        title: "Erro",
        html,
      });

      return false;
    }

    return true;
  };

  const handleGetStorageFileById = async (storageFileId: string) => {
    const attachment = rowData?.storageFiles?.find(
      attachmentData => attachmentData.storageFileId === storageFileId,
    );

    if (!attachment) {
      return;
    }

    await getStorageFilebyId(attachment);
  };

  return {
    isBulk,
    rowData,
    listAttachmentTypes,
    attachmentTypeCheck,
    fetchRpaParamOptions,
    handleAttachmentsSubmission,
    fetchCommercialProposalEnums,
    progressModalState: state.progressModal,
    generateRpaAttachment: handleRpaGeneration,
    getStorageFilebyId: handleGetStorageFileById,
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
    generateVariableAdditionalAttachment: handleVariableAdditionalGeneration,
    generateCommercialProposalAttachment: handleCommercialProposalGeneration,
  };
}
