import { useState } from "react";
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { IDebtImportAssessmentEntity } from "../../../domain/entities/debtImportAssessmentEntity";
import {
  IDebtImportForm,
  IFormComponentProps,
} from "../../../domain/entities/debtImportTypes";
import { useFinalStepExpandedTemplateGrid } from "../../hooks/useFinalStepExpandedTemplateGrid";
import { EditAssessmentFormModal } from "../EditAssessmentFormModal";

type IAssessmentField = `imports.${number}.assessments`;

export type IGridAssessment = FieldArrayWithId<
  IDebtImportForm,
  IAssessmentField
>;

export function FinalStepExpandedTemplate({ formIndex }: IFormComponentProps) {
  const dialog = useSoulDialog();

  const { getValues, setValue } = useFormContext<IDebtImportForm>();

  const accountValue = getValues(`imports.${formIndex}.value`);

  const { fields, replace } = useFieldArray<IDebtImportForm, IAssessmentField>({
    name: `imports.${formIndex}.assessments`,
  });

  const [assessmentIndex, setAssessmentIndex] = useState<number | null>(null);

  const handleOpenAssessmentModal = (assessmentFormId: string) => {
    const index = fields.findIndex(assessment => {
      return assessment.id === assessmentFormId;
    });

    setAssessmentIndex(index);
  };

  const handleAssessmentModalClose = async () => {
    const response = await dialog.fire({
      icon: "warning",
      title: "Atenção!",
      text: "Você realmente deseja fechar os rateios sem salvar suas alterações?",
      showCancelButton: true,
      confirmButtonText: "Sim, fechar sem salvar",
      cancelButtonText: "Não, continuar editando",
    });

    if (response.dismiss) {
      return;
    }

    setAssessmentIndex(null);
  };

  const handleEditAssessment = async (
    editedAssessment: IDebtImportAssessmentEntity,
  ) => {
    if (assessmentIndex === null) {
      return;
    }

    const updatedAssessmentsList = fields.map((assessment, index) => {
      if (index === assessmentIndex) {
        return editedAssessment;
      }

      const errorsKeysValues = Object.entries(assessment.fieldErrors);

      const errorsWihtouValueLeft = errorsKeysValues.filter(([key]) => {
        return key !== "valueAssessmentLeft";
      });

      const filteredErrors = Object.fromEntries(errorsWihtouValueLeft);

      const hasErrors = !!Object.keys(filteredErrors).length;

      return {
        ...assessment,
        fieldErrors: filteredErrors,
        valueAssessmentLeft: editedAssessment.valueAssessmentLeft,
        hasError: editedAssessment.valueAssessmentLeft !== 0 || hasErrors,
      };
    });

    replace(updatedAssessmentsList);

    setValue(
      `imports.${formIndex}.valueAssessmentLeft`,
      editedAssessment.valueAssessmentLeft,
      {
        shouldValidate: true,
      },
    );

    const hasAssessmentError = updatedAssessmentsList.some(field => {
      return field.hasError;
    });

    setValue(`imports.${formIndex}.hasAssessmentError`, hasAssessmentError, {
      shouldValidate: true,
    });

    dialog.fire({
      icon: "success",
      title: "Feito!",
      text: "Rateio editado com sucesso",
    });

    setAssessmentIndex(null);
  };

  const handleRowClassName = (rowData: IDebtImportAssessmentEntity) => {
    return {
      "row-valid": !rowData.hasError,
      "row-invalid": rowData.hasError,
    };
  };

  const { columns } = useFinalStepExpandedTemplateGrid({
    handleOpenAssessmentModal,
  });

  return (
    <>
      <ClientSoulTable
        removableSort
        data={fields}
        columns={columns}
        paginator={false}
        rowClassName={handleRowClassName}
        rowsPerPageOptions={[fields.length]}
        className="assessment-expanded-table"
      />
      <EditAssessmentFormModal
        assessmentsList={fields}
        accountValue={accountValue}
        assessmentIndex={assessmentIndex}
        onConfirmEdition={handleEditAssessment}
        onRequestClose={handleAssessmentModalClose}
      />
    </>
  );
}
