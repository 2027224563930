import { EConciliationOrigin } from "../../domain/entitites/conciliationEntity";

export enum EPaymentMethod {
  billet = 1,
  creditCard = 2,
  currentAccountTransfer = 3,
  cash = 4,
  check = 5,
  directDebit = 6,
  pix = 7,
}

export interface IAccountConciliationMatchModel {
  accountId: string | null;
  companyAssumedName: string;
  documentNumber: string;
  customerProviderDocument: string;
  customerProviderName: string;
  origin: EConciliationOrigin | null;
  value: number | null;

  issueDate: string | null;
  paymentReceiptDate: string | null;
  paymentMethod: EPaymentMethod | null;
  projectName: string;
  description: string;
  observation: string;
  classificationAssessmentName: string;
  costCenterName: string;

  // Preenchidos na Conciliação
  parcelNumber: number;
  totalParcels: number;

  rowNumber: number;
}

export class AccountConciliationMatchModel
  implements IAccountConciliationMatchModel
{
  accountId = "";
  companyAssumedName = "";
  documentNumber = "";
  customerProviderDocument = "";
  customerProviderName = "";
  origin = EConciliationOrigin.expense;
  value = 0;
  issueDate = "";
  paymentReceiptDate = "";
  paymentMethod = EPaymentMethod.billet;
  projectName = "";
  description = "";
  observation = "";
  classificationAssessmentName = "";
  costCenterName = "";
  parcelNumber = 0;
  totalParcels = 0;
  rowNumber = 0;

  constructor(init?: Partial<IAccountConciliationMatchModel>) {
    Object.assign(this, init);
  }
}

export interface IAccountConciliationResponsehModel {
  providedAccount: IAccountConciliationMatchModel;
  foundAccounts: IAccountConciliationMatchModel[];
}
