import { format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompanyEntity } from "../../domain/entities/companyEntity";
import { ICompanyFormEntity } from "../../domain/entities/companyFormEntity";
import { IGetCompanyContract } from "../../domain/contracts/getCompanyContract";

export class GetCompanyService implements IGetCompanyContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCompany(companyId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Companies/${companyId}`;

    const response = await this.api.get<ICompanyEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const lockReleasesUntil = response?.lockReleasesUntil
      ? format(new Date(response.lockReleasesUntil), "ddMMyyy").toString()
      : "";

    const companyFormEntity: ICompanyFormEntity = {
      assumedName: response?.assumedName || "",
      cnpj: response?.cnpj || "",
      cofinsAliquot: response?.cofinsAliquot || 0,
      corporationName: response?.corporationName || "",
      csllAliquot: response?.csllAliquot || 0,
      hasProject: response?.hasProject || false,
      invoiceServiceDescription: response?.invoiceServiceDescription || "",
      irAliquot: response?.irAliquot || 0,
      issAliquot: response?.issAliquot || 0,
      lockReleasesUntil,
      pisAliquot: response?.pisAliquot || 0,
      municipalActivity: {
        label: response.municipalActivityClassificationCode,
        rawValue: response.municipalActivityClassificationId,
        metadata: {
          description: response.municipalActivityClassificationDescription,
        },
      },
      cnae: {
        rawValue: response.cnaeClassificationId,
        label: response.cnaeClassificationCode?.replace(/\D/g, ""),
        metadata: {
          description: response.cnaeClassificationDescription,
        },
      },
      serviceSupplyCity: {
        rawValue: response.serviceSupplyCityId,
        label: response?.serviceSupplyCityName || "",
      },
      serviceSupplyState: {
        rawValue: response.serviceSupplyStateId,
        label: response?.serviceSupplyStateName || "",
      },
    };

    return companyFormEntity;
  }
}
