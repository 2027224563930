import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;

  div {
    border-right: none;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  button {
    border: none;
    border-radius: 0 0.25rem 0.25rem 0;

    padding: 0 10px;

    color: white;
    background-color: #5e72e4;

    font-size: 1rem;
    line-height: 0.5rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

    &:hover {
      background-color: #324cdd;
    }
  }
`;
