import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IReturnAccountPayableContract } from "../../domain/contracts/returnAccountPayableContract";
import {
  ICostCenterLinkEntity,
  IReturnAccountErrorEntity,
} from "../../domain/entities/returnAccountErrorEntity";
import {
  IReturnPayloadModel,
  IReturnPayloadModelWithLinks,
} from "../models/returnPayloadModel";
import { IReturnResponseModel } from "../models/returnResponseModel";

export class ReturnAccountPayableService
  implements IReturnAccountPayableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async returnAccountPayable(
    accountPayableId: string,
    returnDate: Date,
    costCenterLinks: ICostCenterLinkEntity[],
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    // se eu enviar o costCenterLinks mesmo que array vazio, a devolucao
    // é bem sucedida mesmo quando há centros de custo inativos
    let payload: IReturnPayloadModel = {
      returnDate: format(returnDate, "yyyy-MM-dd"),
    };

    // por isso, somente se possuir links de centros de custo
    // inativos a serem informados eu os incluo no payload
    if (costCenterLinks.length > 0) {
      payload = {
        returnDate: format(returnDate, "yyyy-MM-dd"),
        costCenterLinks: costCenterLinks.map(costCenterLink => {
          return {
            accountPayableAssessmentId:
              costCenterLink.accountPayableAssessmentId,
            classificationAssessmentInactive:
              costCenterLink.classificationAssesmentName,
            valueInactive: costCenterLink.value,
            costCenter: costCenterLink.costCenterDestination?.name || "",

            costCenterIdInactive: costCenterLink.costCenterDisabled.id,
            nameInactive: costCenterLink.costCenterDisabled.name,

            costCenterIdActive: costCenterLink.costCenterDestination?.id || "",
            nameActive: costCenterLink.costCenterDestination?.name || "",
          };
        }),
      } as IReturnPayloadModelWithLinks;
    }

    const url = `/AccountsPayable/${accountPayableId}/Return`;

    const response = await this.api.put<IReturnResponseModel>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (!response.success) {
      let companyLockUntil = "";

      if (response.companyLockUntil) {
        companyLockUntil = format(
          new Date(response.companyLockUntil),
          "dd/MM/yyyy",
        );
      }

      const error: IReturnAccountErrorEntity = {
        companyLockUntil,
        success: response.success,
        companyLocked: response.companyClosedForReleases,
        returnDateIsValid: Boolean(response.returnDateIsValid),
        anyCostCenterDisabled: response.costCenterLinks.length > 0,
        costCenterLinks: response.costCenterLinks.map(costCenterLink => {
          const {
            accountPayableAssessmentId,
            classificationAssessmentInactive,
            valueInactive,
            costCenterIdInactive,
            nameInactive,
          } = costCenterLink;

          return {
            accountPayableAssessmentId,
            classificationAssesmentName: classificationAssessmentInactive,
            value: valueInactive,
            formattedValue: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valueInactive),
            costCenterDisabled: {
              id: costCenterIdInactive,
              name: nameInactive,
            },
            costCenterDestination: null,
          };
        }),
      };

      throw error;
    }
  }
}
