import { IClassificationAssessmentEntity } from "../entities/classificationAssessment";

export interface IToggleClassificationAssessmentService {
  toggleClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export interface IToggleClassificationAssessmentUseCase {
  toggleClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export class ToggleClassificationAssessmentUseCase
  implements IToggleClassificationAssessmentUseCase
{
  constructor(
    private toggleClassificationAssessmentService: IToggleClassificationAssessmentService,
  ) {}

  toggleClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity> {
    return this.toggleClassificationAssessmentService.toggleClassificationAssessment(
      classificationAssessmentId,
    );
  }
}
