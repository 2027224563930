import { format } from "date-fns";
import { Stringified } from "../../../core/domain/entities/stringfied";
import {
  dataTableFilterMatchModeTypeMap,
  DataTableFilterMetaData,
  IPFSEventEntity,
} from "../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
  ISimpleSearchableColumn,
  ISimpleSortableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ColumnFilterOperationType,
  columnTypeMap,
  IAdvTableColumn,
  ITypedColumn,
  RelationshipFilterOption,
} from "../../domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../domain/entities/panoramaEntity";
import { ERelationshipListBoxOptions } from "../../domain/entities/relationshipListBoxOptions";
import {
  IPanoramaAngularDefEntity,
  IPanoramaAngularModel,
  IPanoramaColumnAngularEntity,
} from "../models/panoramaAngularModel";

export interface ITransformPanoramaAngularService {
  transformPanoramaAngular(
    columns: IAdvTableColumn[],
    panoramaDefinition: IPanoramaEntity,
  ): IPanoramaAngularModel;
}

export class TransformPanoramaAngularService
  implements ITransformPanoramaAngularService
{
  transformPanoramaAngular(
    columns: IAdvTableColumn[],
    panorama: IPanoramaEntity,
  ): IPanoramaAngularModel {
    const { active, name, userId, panoramaDefinition } = panorama;
    const { pfsEventEntity } = panoramaDefinition;

    let { selectedColumns } = panoramaDefinition;

    if (selectedColumns.length === 0) {
      selectedColumns = [...columns];
    }

    const panoAngularDef: IPanoramaAngularDefEntity = {
      selectedColumns: selectedColumns.map(selCol => {
        return this.computeSelectedColumn(columns, selCol, pfsEventEntity);
      }),
    };

    const panoAngularModel = {
      active,
      name,
      objectBody: Stringified.stringify(panoAngularDef),
      userId,
    };

    return panoAngularModel;
  }

  computeSelectedColumn(
    columns: IAdvTableColumn[],
    selectedColumn: IAdvTableColumn,
    pfsEventEntity: IPFSEventEntity,
  ): IPanoramaColumnAngularEntity {
    const { field, searchField, searchable } =
      selectedColumn as ISimpleSearchableColumn;
    const { hidden } = selectedColumn as ISimpleHiddenColumn;
    const { columnType } = selectedColumn as ITypedColumn;
    const { sortField } = selectedColumn as ISimpleSortableColumn;
    const defaultWidth = (columns.find(c => c.field === field) as ISimpleColumn)
      ?.width;

    const filter =
      pfsEventEntity.filters[field] || pfsEventEntity.filters[searchField];

    const sort = pfsEventEntity.multiSortMeta?.filter(
      ({ field: sortedField }) => {
        return sortedField === field || sortedField === sortField;
      },
    )?.[0]?.order;

    const order = sort === 1 ? "asc" : sort === -1 ? "desc" : "";

    const type = columnTypeMap[columnType];

    let searchStr = "";
    let searchValue = "";

    let searchStr2 = "";
    let searchValue2 = "";

    let exceptSearchStr = "";
    let exceptSearchValue = "";

    let operation;

    switch (type) {
      case "date":
        operation = "Between";
        break;

      case "decimal":
      case "numeric":
        operation = "EqualAbsolute";
        break;

      case "enum":
      case "relationship":
        operation = "Equal";
        break;

      default:
        operation = "Contains";
    }

    if (filter) {
      const value = (filter as DataTableFilterMetaData)?.value;
      const matchMode = (filter as DataTableFilterMetaData)?.matchMode;

      operation = dataTableFilterMatchModeTypeMap[matchMode];

      // aqui mapeamos o filtro de enumerador
      if (type === "enum") {
        const enumVal = value as number[];
        searchValue = enumVal.join(",");
      }
      // aqui mapeamos o filtro de relacionamento
      else if (type === "relationship") {
        const relVal = value as RelationshipFilterOption[];

        if (operation === "Equal") {
          searchValue = relVal.map(({ rawValue }) => rawValue).join(",");
          searchStr = relVal.map(({ label }) => label).join(",");
        } else if (operation === "NotEqual") {
          // searchValue e searchStr sao necessarios neste caso pois o angular
          // nao utiliza o operation p/ saber se todos devem comecar marcados ou
          // nao, e sim se "selectAll" está presente na lista de selecionados
          searchValue = ERelationshipListBoxOptions.selectAll;
          searchStr = "Selecionar todos";
          exceptSearchValue = relVal.map(({ rawValue }) => rawValue).join(",");
          exceptSearchStr = relVal.map(({ label }) => label).join(",");
        }
      }
      // aqui mapeamos o filtro de data
      else if (type === "date") {
        const dateVal = value as Date[];

        searchValue = format(dateVal[0], "yyyy-MM-dd");
        searchStr = format(dateVal[0], "dd/MM/yyyy");
        searchValue2 = format(dateVal[1], "yyyy-MM-dd");
        searchStr2 = format(dateVal[1], "dd/MM/yyyy");
      }
      // aqui mapeamos o filtro numerico
      else if (type === "numeric") {
        searchValue = `${value as number}`;
        searchStr = `${value as number}`;
      }
      // aqui mapeamos o filtro de porcentagem
      else if (type === "decimal") {
        const decVal = value as number;

        searchValue = `${decVal * 100}`;
        searchStr = searchValue;
      }
    }

    const userWidth = (selectedColumn as ISimpleColumn).width;

    const panoColAngularEntity = {
      defaultWidth: defaultWidth ? parseFloat(defaultWidth) : "0",
      field,
      filtered: !!filter,
      filters: {
        type,
        order,
        field,
        searchStr,
        searchStr2,
        searchValue,
        searchValue2,
        exceptSearchStr,
        exceptSearchValue,
        searchTypeahead: "",
        valueField: searchField,
        operation: operation as ColumnFilterOperationType,
      },
      hidden,
      menuSettings: {},
      searchable,
      selected: false,
      userWidth: userWidth ? parseFloat(userWidth) : defaultWidth,
      visible: !hidden,
    } as IPanoramaColumnAngularEntity;

    return panoColAngularEntity;
  }
}
