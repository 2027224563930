import {
  Accordion,
  AccordionTab,
  AccordionEventParams,
} from "primereact/accordion";
import { useCallback, useState } from "react";
import { Container } from "./style";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { IApiService } from "../../../../core/data/services/apiService";
import {
  MakeImportationMatches,
  makeImportationMatches,
} from "../../../main/makeImportationMatches";
import { ImportationMatchesPaymentAccountsGrid } from "../ImportationMatchesPaymentAccountsGrid";
import { ImportationMatchesCostCenterGrid } from "../ImportationMatchesCostCenterGrid";
import {
  EImportationMatchesOrigin,
  EImportationMatchesTabIndexes,
} from "../../../domain/entities/importationMatchesPayloadEntity";

interface ImportationLMSPageProps {
  useImportationMatches: MakeImportationMatches;
}

function ImportationLMSPage(props: ImportationLMSPageProps) {
  const { useImportationMatches } = props;

  const { getStoredActiveTabs, storeActiveTabs } = useImportationMatches;

  const [activeTabs, setActiveTabs] = useState<EImportationMatchesTabIndexes[]>(
    () => {
      const storedTabs = getStoredActiveTabs(EImportationMatchesOrigin.LMS);
      /** Caso nenhuma informação esteja registrada, inicia o componente com as abas abertas. */
      if (storedTabs === null) {
        return [
          EImportationMatchesTabIndexes.PAYMENT_ACCOUNTS,
          EImportationMatchesTabIndexes.COST_CENTERS,
        ];
      }
      /** Caso a informação armazenada seja uma string vazia, as abas iniciam fechadas. */
      if (storedTabs === "") return [];
      return storedTabs.split(",").map(t => Number(t));
    },
  );

  /** Lida com os eventos de abrir e fechar as abas do acordeão. */
  const handleTabEvents = useCallback(
    (e: AccordionEventParams) => {
      /** UGLY - devido ao erro de tipagem, esse casting deve ser efetuado. */
      const indexesArray =
        e.index as unknown as EImportationMatchesTabIndexes[];
      storeActiveTabs(EImportationMatchesOrigin.LMS, indexesArray.join(","));
      setActiveTabs(indexesArray);
    },
    [storeActiveTabs],
  );

  /** Constrói os cabeçalhos das opções do acordeão. */
  const tabHeaderTemplate = useCallback(
    (index: EImportationMatchesTabIndexes) => {
      const indexesTranslation = {
        [EImportationMatchesTabIndexes.PAYMENT_ACCOUNTS]: "Contas de Pagamento",
        [EImportationMatchesTabIndexes.COST_CENTERS]: "Centros de Custo",
      };

      const isActive = activeTabs.includes(index);

      return (
        <>
          <span>{indexesTranslation[index]}</span>
          <i className={`pi pi-chevron-${isActive ? "down" : "right"}`} />
        </>
      );
    },
    [activeTabs],
  );

  return (
    <Container>
      <Page>
        <Accordion
          multiple
          expandIcon={null}
          collapseIcon={null}
          activeIndex={activeTabs}
          onTabChange={handleTabEvents}
        >
          <AccordionTab
            header={tabHeaderTemplate(
              EImportationMatchesTabIndexes.PAYMENT_ACCOUNTS,
            )}
          >
            <ImportationMatchesPaymentAccountsGrid
              useImportationMatches={useImportationMatches}
              origin={EImportationMatchesOrigin.LMS}
            />
          </AccordionTab>
          <AccordionTab
            header={tabHeaderTemplate(
              EImportationMatchesTabIndexes.COST_CENTERS,
            )}
          >
            <ImportationMatchesCostCenterGrid
              useImportationMatches={useImportationMatches}
              origin={EImportationMatchesOrigin.LMS}
            />
          </AccordionTab>
        </Accordion>
      </Page>
    </Container>
  );
}

interface ImportationLMSPageFactoryProps {
  api: IApiService;
}

export function ImportationLMSPageFactory({
  api,
}: ImportationLMSPageFactoryProps) {
  return (
    <ImportationLMSPage useImportationMatches={makeImportationMatches(api)} />
  );
}
