import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IProjectTransactionEntity } from "../entities/projectTransactionEntity";

export interface IListProjectTransactionsService {
  listProjectTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IProjectTransactionEntity[]>>;
}

export interface IListProjectTransactionsUseCase {
  listProjectTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IProjectTransactionEntity[]>>;
}

export class ListProjectTransactionsUseCase
  implements IListProjectTransactionsUseCase
{
  constructor(
    private listProjectTransactionsService: IListProjectTransactionsService,
  ) {}

  listProjectTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IProjectTransactionEntity[]>> {
    return this.listProjectTransactionsService.listProjectTransactions(
      companyGroupId,
      payload,
    );
  }
}
