export interface ITypeaheadOption<T = object> {
  rawValue: string | number;
  label: string;
  metadata?: T;
}

export class TypeaheadOption<T = object> implements ITypeaheadOption<T> {
  rawValue = "";
  label = "";
  metadata = undefined;

  constructor(params?: Partial<ITypeaheadOption>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<ITypeaheadOption>) {
    return new TypeaheadOption(params);
  }
}
