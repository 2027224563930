import {
  GeneratePayloadUseCase,
  IGeneratePayloadUseCase,
} from "../domain/usecases/generatePayloadUseCase";

export type MakeSimpleTableType = IGeneratePayloadUseCase;

export function makeSimpleTable(): MakeSimpleTableType {
  const generatePayloadUseCase = new GeneratePayloadUseCase();

  return {
    generatePayload(psfEvent, columns) {
      return generatePayloadUseCase.generatePayload(psfEvent, columns);
    },
  };
}
