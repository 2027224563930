import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";

export interface ISearchProjectsService {
  searchProjects(
    companyGroupId: string,
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export interface ISearchProjectsUseCase {
  searchProjects(
    companyGroupId: string,
    search?: string,
    fetchMinLength?: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export class SearchProjectsUseCase implements ISearchProjectsUseCase {
  constructor(private searchProjectsService: ISearchProjectsService) {}

  searchProjects(
    companyGroupId: string,
    search = "",
    fetchMinLength = 10,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    return this.searchProjectsService.searchProjects(
      companyGroupId,
      search,
      fetchMinLength,
    );
  }
}
