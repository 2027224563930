import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { IListRequesterAttachmentTypesContract } from "../../domain/contracts/listRequesterAttachmentTypes";

export class ListRequesterAttachmentTypesService
  implements IListRequesterAttachmentTypesContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async listRequesterAttachmentTypes() {
    const userEntity = this.getUserLocalService.get();
    const url = "Enums/PaymentRequestAttachmentsType";

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
