import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IPaymentAccountEntity } from "../entities/paymentAccountEntity";

export interface IListPaymentAccountsByCompanyService {
  listPaymentAccountsByCompany(
    companyGroupId: string,
    companyId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>>;
}

export interface IListPaymentAccountsByCompanyUseCase {
  listPaymentAccountsByCompany(
    companyGroupId: string,
    companyId: string,
    actives?: boolean,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>>;
}

export class ListPaymentAccountsByCompanyUseCase
  implements IListPaymentAccountsByCompanyUseCase
{
  constructor(
    private listPaymentAccountsByCompanyService: IListPaymentAccountsByCompanyService,
  ) {}

  listPaymentAccountsByCompany(
    companyGroupId: string,
    companyId: string,
    actives = true,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>> {
    return this.listPaymentAccountsByCompanyService.listPaymentAccountsByCompany(
      companyGroupId,
      companyId,
      actives,
    );
  }
}
