import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IClassificationUspEntity } from "../../domain/entities/classificationUspEntity";
import { IToggleClassificationUspService } from "../../domain/usecases/toggleClassificationUspUseCase";

export class ToggleClassificationUspService
  implements IToggleClassificationUspService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleClassificationUsp(id: string): Promise<IClassificationUspEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationsUsp/${id}`;

    const response = await this.api.delete<IClassificationUspEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
