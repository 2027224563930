import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompetencyEntity } from "../../domain/entities/competencyEntity";
import { IGetCompetencyService } from "../../domain/usecases/getCompetencyUseCase";

export class GetCompetencyService implements IGetCompetencyService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCompetency(comptencyId: string): Promise<ICompetencyEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Competencies/${comptencyId}`;

    const response = await this.api.get<ICompetencyEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const { id, name, companyGroupId, companyGroupName, isUsp, active } =
      response;

    const competencyEntity: ICompetencyEntity = {
      id,
      name,
      companyGroupId,
      companyGroupName,
      isUsp,
      active,
    };

    return competencyEntity;
  }
}
