import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeletePaymentRequestPanoramaContract } from "../../domain/contracts/deletePaymentRequestPanoramaContract";

export class DeletePaymentRequestPanoramaService
  implements IDeletePaymentRequestPanoramaContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deletePaymentRequestPanorama(panoramaId: string): Promise<void> {
    const url = `/PaymentRequestPanoramas/${panoramaId}`;
    const userEntity = this.getUserLocalService.get();

    await this.api.delete(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
