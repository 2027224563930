import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { IPecegePayAccountReceivableFeeFormEntity } from "../../../domain/entities/pecegePayAccountReceivableFeeFormEntity";
import { MakePecegePayAccountReceivableFees } from "../../../main/makePecegePayAccountReceivableFees";
import { ModalInfoContainer } from "../ModalComponents/styles";
import { ProviderField } from "../ProviderField";
import { TypeaheadField } from "../TypeaheadField";
import { Container } from "./styles";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { IApiError } from "../../../../core/data/services/apiService";
import { IErrorResponseEntity } from "../../../../core/domain/entities/responseEntity";

interface PecegePayAccountReceivableFeeFormModalProps {
  feeId: string;
  isOpen: boolean;
  onClose: () => void;
  useProvider: MakeProvider;
  usePecegePayAccountReceivableFees: MakePecegePayAccountReceivableFees;
}

interface PecegePayAccountReceivableFeeFormModalState {
  isLoading: boolean;
}

export function PecegePayAccountReceivableFeeFormModal(
  props: PecegePayAccountReceivableFeeFormModalProps,
) {
  const {
    feeId,
    isOpen,
    onClose,
    useProvider,
    usePecegePayAccountReceivableFees,
  } = props;

  const {
    searchCompany,
    searchProvider,
    searchPecegePayWallets,
    searchClassificationAssessment,
    getPecegePayAccountReceivableFee,
    savePecegePayAccountReceivableFee,
    searchProviderClassificationAccount,
    updatePecegePayAccountReceivableFee,
  } = usePecegePayAccountReceivableFees;

  const dialog = useSoulDialog();
  const asyncDebounce = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<IPecegePayAccountReceivableFeeFormEntity>({
    mode: "all",
    defaultValues: {
      id: "",
      active: true,
      wallet: null,
      company: null,
      provider: null,
      classificationAccount: null,
      classificationAssessment: null,
    },
  });

  const provider = watch("provider");

  const [state, setState] =
    useState<PecegePayAccountReceivableFeeFormModalState>({
      isLoading: false,
    });

  const preventClose = state.isLoading || isSubmitting;

  const handleModalOpen = async () => {
    if (!feeId) {
      return;
    }

    setState(prevState => ({ ...prevState, isLoading: true }));

    try {
      const entity = await getPecegePayAccountReceivableFee(feeId);

      const formValues = {
        id: entity.id,
        active: entity.active || false,
        wallet: { label: entity.walletName, rawValue: entity.walletId },
        company: { label: entity.companyName, rawValue: entity.companyId },
        provider: { label: entity.providerName, rawValue: entity.providerId },
        classificationAccount: {
          label: entity.classificationAccountName,
          rawValue: entity.classificationAccountId,
        },
        classificationAssessment: {
          label: entity.classificationAssessmentName,
          rawValue: entity.classificationAssessmentId,
        },
      };

      reset(formValues, { keepDefaultValues: true });
    } finally {
      setState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const onValid = async (
    formValues: IPecegePayAccountReceivableFeeFormEntity,
  ) => {
    try {
      if (feeId) {
        await updatePecegePayAccountReceivableFee({
          formValues,
          companyGroupId: currentCompanyGroup.id,
        });
      } else {
        await savePecegePayAccountReceivableFee({
          formValues,
          companyGroupId: currentCompanyGroup.id,
        });
      }

      dialog.fire({
        icon: "success",
        titleText: "Feito!",
        html: `Tarifa ${feeId ? "atualizada" : "adicionada"} com sucesso!`,
      });

      onClose();
    } catch (error) {
      const typedError = error as IApiError<IErrorResponseEntity>;
      const htmlError = typedError?.response.data.MessageHTML;

      if (!htmlError) {
        return;
      }

      /**
       * UGLY - O interceptor da API renderiza um dialog de maneira "forçada" ("fora do React")
       * utilizando o `setTimeout`. Isso faz com que ele tenha prioridade na renderização,
       * descartando todas as outras chamadas do dialog dentro do ecossistema do React.
       *
       * Para que os dialogs renderizados dentro do fluxo do React tenham prioridade, o
       * uso de um outro `setTimeout` é necessário.
       */
      await asyncDebounce(1);

      await dialog.fire({
        icon: "error",
        titleText: "Opa!",
        html: htmlError,
      });
    }
  };

  const classificationAccountSearchChange = (params: ISearchParams) => {
    if (!provider) {
      return Promise.reject();
    }

    return searchProviderClassificationAccount({
      ...params,
      providerId: provider.rawValue as string,
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterClose={reset}
      onRequestClose={onClose}
      onAfterOpen={handleModalOpen}
      className="react-modal-content"
      shouldCloseOnEsc={!preventClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!preventClose}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{feeId ? "Editar Tarifa" : "Nova Tarifa"}</h4>
          <button
            type="button"
            id="btn-crud-cross"
            disabled={preventClose}
            data-testid="btn-crud-cross"
            className="react-modal-close"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <ModalInfoContainer>
              <p>
                Este registro {feeId ? "está" : "será"} vinculado ao grupo de
                empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </ModalInfoContainer>
            <div className="react-modal-body">
              <form
                id="fee-crud-form"
                className="form-container"
                onSubmit={handleSubmit(onValid)}
              >
                <div className="form-row">
                  <Controller
                    control={control}
                    name="company"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <label className="col-12 form-control">
                          <TypeaheadField
                            label="Empresa"
                            value={field.value}
                            onChange={field.onChange}
                            searchFunction={searchCompany}
                            placeholder="Selecione uma Empresa"
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                          <InvalidFeedback
                            message="Este campo é obrigatório"
                            condition={fieldState?.error?.type === "required"}
                          />
                        </label>
                      );
                    }}
                  />
                </div>
                <div className="form-row">
                  <Controller
                    control={control}
                    name="wallet"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <label className="col-12 form-control">
                          <TypeaheadField
                            label="Carteira"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Selecione uma Carteira"
                            searchFunction={searchPecegePayWallets}
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                          <InvalidFeedback
                            message="Este campo é obrigatório"
                            condition={fieldState?.error?.type === "required"}
                          />
                        </label>
                      );
                    }}
                  />
                </div>
                <div className="form-row">
                  <Controller
                    control={control}
                    name="provider"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <label className="col-12 form-control">
                          <ProviderField
                            label="Fornecedor"
                            value={field.value}
                            useProvider={useProvider}
                            onChange={field.onChange}
                            searchProvider={searchProvider}
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                          <InvalidFeedback
                            message="Este campo é obrigatório"
                            condition={fieldState?.error?.type === "required"}
                          />
                        </label>
                      );
                    }}
                  />
                </div>
                <div className="form-row">
                  <Controller
                    control={control}
                    name="classificationAccount"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <label className="col-6 form-control">
                          <TypeaheadField
                            value={field.value}
                            onChange={field.onChange}
                            id="classificationAccount"
                            label="Classificação Contábil"
                            disabled={!provider?.rawValue}
                            data-testid="txt-classificationAccount"
                            placeholder="Selecione uma Classificação"
                            className={fieldState?.error ? "isInvalid" : ""}
                            searchFunction={classificationAccountSearchChange}
                          />
                          <InvalidFeedback
                            message="Este campo é obrigatório"
                            condition={fieldState?.error?.type === "required"}
                          />
                        </label>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="classificationAssessment"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <label className="col-6 form-control">
                          <TypeaheadField
                            value={field.value}
                            onChange={field.onChange}
                            label="Classificação de Rateio"
                            id="classificationAssessment"
                            placeholder="Selecione uma Classificação"
                            data-testid="txt-classificationAssessment"
                            searchFunction={searchClassificationAssessment}
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                          <InvalidFeedback
                            message="Este campo é obrigatório"
                            condition={fieldState?.error?.type === "required"}
                          />
                        </label>
                      );
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-crud-close"
                disabled={isSubmitting}
                data-testid="btn-crud-close"
                className="form-button red-bkg"
                onClick={onClose}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                form="fee-crud-form"
                disabled={isSubmitting}
                data-testid="btn-crud-save"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </ReactModal>
  );
}
