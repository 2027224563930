import styled from "styled-components";

interface ContainerProps {
  selectable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  .p-datatable {
    .p-datatable-thead > tr > th {
      background-color: #f6f9fc;
      font-size: 0.65rem;
      text-transform: uppercase;
      color: #8898aa;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .p-datatable-tbody > tr {
      &.p-highlight,
      &.p-row-odd.p-highlight {
        background: ${({ selectable }) => (selectable ? "#525f7f" : "#c4f1de")};

        td {
          color: ${({ selectable }) => (selectable ? "#525f7f" : "#485370")};
        }
      }

      &.p-row-odd {
        /* TODO criar uma variavel de css no global.ts para este valor */
        background: #f6f9fc;
      }

      & > td {
        padding: 0.375rem 0.75rem;
        /* TODO criar uma variavel de css no global.ts para este valor */
        color: #485370;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .table-actionbar {
          display: flex;
          justify-content: center;
          flex-grow: 1;
        }
      }
    }

    /**
      NOTE - Esse caso cobre apenas para tabelas com display: table. O prime
      altera o display da tabela dependo das props passadas, então a regra abaixo
      deve ser sobrescrita no componente para atender a devida demanda.
    */
    tr.p-datatable-emptymessage {
      td {
        padding: 1rem;
        text-align: center;
      }
    }
  }

  .p-paginator.p-component {
    font-size: 1rem;
    border-radius: 0;

    .p-paginator-page {
      border: 1px solid #e2e5e9;
      font-size: 1rem;

      &.p-highlight {
        background: #090081;
        color: white;
      }
    }

    .p-paginator-element {
      width: 3rem;
      height: 3rem;
    }

    .current-page-report {
      display: flex;
      height: 2.375rem;
      justify-content: start;
      align-items: center;
      flex-grow: 1;
      padding-right: 2rem;
    }

    .p-dropdown-label {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }

    .rows-per-page-dropdown-wrapper,
    .rows-per-page-dropdown-wrapper .p-dropdown-label.p-inputtext {
      display: flex;
      min-width: 40px;
      align-items: center;
    }
  }
`;
