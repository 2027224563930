import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MultiSelect,
  MultiSelectPanelHeaderTemplateParams,
} from "primereact/multiselect";
import { isAfter, isBefore, parse } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import { FaSpinner } from "react-icons/fa";
import { IApiService } from "../../../../../core/data/services/apiService";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import {
  MakePecegePayFeeReport,
  makePecegePayFeeReport,
} from "../../../main/makePecegePayFeeReports";
import { Container, FilterContainer, ModalContainer } from "./styles";
import { SoulSpinner } from "../../../../../core/presentation/components/SoulSpinner";
import { useDateValidator } from "../../../../../core/presentation/hooks/useDateValidator";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { IPecegePayBillingCompany } from "../../../domain/entities/pecegePayBillingCompanyEntity";
import { IPecegePayFeeReportFormEntity } from "../../../domain/entities/pecegePayFeeReportFormEntity";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { ExportingModalContent } from "../../../../costCenterReport/presentation/components/ExportingModalContent";

export interface PecegePayFeeReportModalProps {
  usePecegePayFeeReport: MakePecegePayFeeReport;
}

export function PecegePayFeeReportPage(props: PecegePayFeeReportModalProps) {
  const { usePecegePayFeeReport } = props;

  const { getPecegePayBillingCompanies, getPecegePayFeeReport } =
    usePecegePayFeeReport;

  const navigate = useNavigate();
  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<IPecegePayFeeReportFormEntity>({
    mode: "all",
  });

  const finalDateWatcher = watch("finalDate");
  const initialDateWatcher = watch("initialDate");

  const [isLoading, setIsLoading] = useState(true);
  const [billingCompanies, setBillingCompanies] = useState<
    IPecegePayBillingCompany[]
  >([]);

  const handleClose = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const handleOpen = useCallback(async () => {
    try {
      const response = await getPecegePayBillingCompanies();
      setBillingCompanies(response);
      reset({
        billingCompanies: response.map(r => r.document),
      });
      setIsLoading(false);
    } catch {
      handleClose();
    }
  }, [getPecegePayBillingCompanies, handleClose, reset]);

  /**
   * Constrói o cabeçalho do painel de opções.
   */
  const headerTemplate = useCallback(
    (params: MultiSelectPanelHeaderTemplateParams) => {
      const { checkboxElement, filterElement } = params;

      return (
        <FilterContainer className="form-control">
          {checkboxElement}
          {filterElement}
        </FilterContainer>
      );
    },
    [],
  );

  /**
   * Valida a data inicial baseada na data final. A ausência de valor no campo
   * de data final automaticamente valida o campo.
   */
  const validateStartDate = useCallback((value: string, endDate: string) => {
    if (!endDate) return true;
    if (value === endDate) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(endDate, "ddMMyyyy", new Date());

    return isBefore(date, dateToCompare);
  }, []);

  /**
   * Valida a data final baseada na data inicial.
   */
  const validateEndDate = useCallback((value: string, startDate: string) => {
    if (value === startDate) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(startDate, "ddMMyyyy", new Date());

    return isAfter(date, dateToCompare);
  }, []);

  /**
   * Realiza a submissão do formulário gerando o relatório de acordo
   * com os dados inseridos.
   */
  const generateReport = useCallback(
    async (values: IPecegePayFeeReportFormEntity) => {
      await Promise.race([
        dialog.fire({
          html: <ExportingModalContent />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }),
        getPecegePayFeeReport(values),
      ]);
      dialog.close();
    },
    [dialog, getPecegePayFeeReport],
  );

  return (
    <Container>
      <Page>
        <Modal
          isOpen
          onAfterOpen={handleOpen}
          shouldCloseOnEsc={false}
          onRequestClose={handleClose}
          className="react-modal-content"
          shouldCloseOnOverlayClick={false}
          overlayClassName="react-modal-overlay"
        >
          <ModalContainer>
            <div className="react-modal-header">
              <h4>Tarifas Pecege Pay</h4>
              <button
                type="button"
                id="btn-cross"
                onClick={handleClose}
                data-testid="btn-cross"
                disabled={isSubmitting}
                className="react-modal-close"
              >
                <IoMdClose />
              </button>
            </div>
            {isLoading ? (
              <div className="loading-container">
                <SoulSpinner />
              </div>
            ) : null}
            {!isLoading ? (
              <>
                <div className="react-modal-body">
                  <form
                    id="pecege-pay-taxes-report-form"
                    onSubmit={handleSubmit(generateReport)}
                  >
                    <div className="form-container">
                      <div className="form-row">
                        <label className="col-6 form-control">
                          <span>Data Inicial</span>
                          <Controller
                            name="initialDate"
                            control={control}
                            rules={{
                              required: true,
                              validate: {
                                invalidDate: v => !dateValidator(v),
                                isBefore: v =>
                                  validateStartDate(v, finalDateWatcher),
                              },
                            }}
                            render={({ field }) => {
                              return (
                                <InputMask
                                  unmask
                                  {...field}
                                  mask="99/99/9999"
                                  id="txt-initialDate"
                                  placeholder="Data Pagamento"
                                  data-testid="txt-initialDate"
                                  className={
                                    errors?.initialDate ? "isInvalid" : ""
                                  }
                                />
                              );
                            }}
                          />
                          <InvalidFeedback
                            condition={errors?.initialDate?.type === "required"}
                            message="Esse campo é obrigatório"
                          />
                          <InvalidFeedback
                            condition={
                              errors?.initialDate?.type === "invalidDate"
                            }
                            message="Data inválida"
                          />
                          <InvalidFeedback
                            condition={errors?.initialDate?.type === "isBefore"}
                            message="Data inicial deve ser menor ou igual a final"
                          />
                        </label>
                        <label className="col-6 form-control">
                          <span>Data Final</span>
                          <Controller
                            name="finalDate"
                            control={control}
                            rules={{
                              required: true,
                              validate: {
                                invalidDate: v => !dateValidator(v),
                                isAfter: v =>
                                  validateEndDate(v, initialDateWatcher),
                              },
                            }}
                            render={({ field }) => {
                              return (
                                <InputMask
                                  unmask
                                  {...field}
                                  mask="99/99/9999"
                                  id="txt-finalDate"
                                  data-testid="txt-finalDate"
                                  placeholder="Data Pagamento"
                                  className={
                                    errors?.finalDate ? "isInvalid" : ""
                                  }
                                />
                              );
                            }}
                          />
                          <InvalidFeedback
                            condition={errors?.finalDate?.type === "required"}
                            message="Esse campo é obrigatório"
                          />
                          <InvalidFeedback
                            condition={
                              errors?.finalDate?.type === "invalidDate"
                            }
                            message="Data inválida"
                          />
                          <InvalidFeedback
                            condition={errors?.finalDate?.type === "isAfter"}
                            message="Data final deve ser maior ou igual a inicial"
                          />
                        </label>
                      </div>
                      <div className="form-row">
                        <label className="col-12 form-control">
                          <span>Empresa</span>
                          <Controller
                            control={control}
                            name="billingCompanies"
                            rules={{ required: true }}
                            render={({ field }) => {
                              return (
                                <MultiSelect
                                  {...field}
                                  filter
                                  appendTo="self"
                                  showClear={false}
                                  optionLabel="name"
                                  optionValue="document"
                                  options={billingCompanies}
                                  id="sel-billing-companies"
                                  selectedItemsLabel="Todos"
                                  panelClassName="multiselect-panel"
                                  data-testid="sel-billing-companies"
                                  placeholder="Selecione uma empresa"
                                  panelHeaderTemplate={headerTemplate}
                                  filterPlaceholder="Pesquise uma empresa"
                                  emptyFilterMessage="Nenhuma empresa encontrada"
                                  maxSelectedLabels={
                                    billingCompanies.length - 1
                                  }
                                  className={`product-multiselect ${
                                    errors?.billingCompanies ? "isInvalid" : ""
                                  }`}
                                />
                              );
                            }}
                          />
                          <InvalidFeedback
                            message="Selecione ao menos uma empresa"
                            condition={!!errors?.billingCompanies}
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="react-modal-footer">
                  <button
                    type="button"
                    id="btn-close"
                    onClick={handleClose}
                    disabled={isSubmitting}
                    className="form-button red-bkg"
                  >
                    Fechar
                  </button>
                  <button
                    type="submit"
                    id="btn-generate"
                    disabled={isSubmitting}
                    form="pecege-pay-taxes-report-form"
                    className={`form-button ${
                      isValid ? "green-bkg" : "invalid-bkg"
                    }`}
                  >
                    {isSubmitting ? "Gerando " : "Confirmar "}
                    {isSubmitting ? <FaSpinner className="spinner" /> : null}
                  </button>
                </div>
              </>
            ) : null}
          </ModalContainer>
        </Modal>
      </Page>
    </Container>
  );
}

interface PecegePayFeeReportPageFactoryProps {
  api: IApiService;
}

export function PecegePayFeeReportPageFactory({
  api,
}: PecegePayFeeReportPageFactoryProps) {
  return (
    <PecegePayFeeReportPage
      usePecegePayFeeReport={makePecegePayFeeReport(api)}
    />
  );
}
