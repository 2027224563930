import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListProjectsContract } from "../../domain/contracts/listProjectsContract";
import { IProjectEntity } from "../../domain/entities/projectEntity";

export class ListProjectsService implements IListProjectsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listProjects(
    payload: IPayloadEntity,
    currentCompanyGroupId: string,
    actives = false,
  ) {
    const userEntity = this.getUserLocalService.get();
    const companyGroupUrl = `CompanyGroups/${currentCompanyGroupId}`;
    const endpoint = `${companyGroupUrl}/Projects${actives ? "/Actives" : ""}`;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `${endpoint}?${params}`;

    return this.api.get<IServerSideResponseModel<IProjectEntity[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
