import { format } from "date-fns";
import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IDownloadConciliationResultContract } from "../../domain/contracts/downloadConciliationResultContract";
import { IConciliationEntity } from "../../domain/entitites/conciliationEntity";
import {
  AccountConciliationMatchModel,
  IAccountConciliationMatchModel,
} from "../models/accountConciliationResponseModel";

export class DownloadConciliationResultService
  implements IDownloadConciliationResultContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async downloadConciliationResult(
    conciliationEntities: IConciliationEntity[],
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsConciliation/Results`;

    const payload: IAccountConciliationMatchModel[] = conciliationEntities
      .filter(conciliationRow => {
        const { foundAccounts } = conciliationRow;

        if (foundAccounts.length === 0) {
          return true;
        }

        return foundAccounts.every(found => !found.conciliated);
      })
      .map(conciliationRow => {
        const { providedAccount } = conciliationRow;

        return new AccountConciliationMatchModel({
          companyAssumedName: providedAccount.companyName,
          documentNumber: providedAccount.documentNumber,
          customerProviderDocument: providedAccount.customerProviderDocument,
          customerProviderName: providedAccount.customerProviderName,
          origin: providedAccount.origin,
          value: providedAccount.value ? Math.abs(providedAccount.value) : null,
          issueDate: providedAccount.issueDate
            ? format(providedAccount.issueDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          paymentReceiptDate: providedAccount.terminationDate
            ? format(providedAccount.terminationDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          paymentMethod: providedAccount.paymentMethod,
          projectName: providedAccount.projectName,
          description: providedAccount.description,
          observation: providedAccount.observation,
          classificationAssessmentName:
            providedAccount.classificationAssessmentName,
          costCenterName: providedAccount.costCenterName,
        });
      })
      .flat();

    // TODO retirar marcador opcional deste método
    // após corrigir todos os mocks dos testes
    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();
    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
