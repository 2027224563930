import styled from "styled-components";

export const Container = styled.section`
  width: 100%;

  .src-fld {
    width: 100%;
    max-width: 20rem;
  }

  .button-wrapper {
    display: inline-flex;
    flex-grow: 1;
    justify-content: center;
  }

  .no-padding {
    padding: 0;
  }
`;
