import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveFeeContract } from "../../domain/contracts/saveFeeContract";
import { FeeEntity, IFeeEntity } from "../../domain/entities/feeEntity";

export class SaveFeeService implements ISaveFeeContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveFee(payload: Partial<IFeeEntity>): Promise<IFeeEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Fee`;

    const finalPayload = {
      id: "",
      name: payload.name,
      active: payload.active,
      providerId: payload.providerId,
      companyGroupId: payload.companyGroupId,
      expirationDay: Number(payload.expirationDay),
      expirationType: Number(payload?.expirationType) || null,
      classificationAssessmentId: payload.classificationAssessmentId,
    };

    const response = await this.api.post<IFeeEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new FeeEntity(response);
  }
}
