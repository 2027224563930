import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompanyTaxEntity } from "../../domain/entities/companyTaxEntity";
import { IGetCompanyTaxesService } from "../../domain/usecases/getCompanyTaxesUseCase";

export class GetCompanyTaxesService implements IGetCompanyTaxesService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getCompanyTaxes(companyId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Companies/${companyId}/taxes`;

    return this.api.get<ICompanyTaxEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
