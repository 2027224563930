import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { Container } from "./styles";
import { PaymentRequestToolbar } from "../PaymentRequestToolbar";
import { PaymentRequestTable } from "../PaymentRequestTable";
import { PaymentRequestContextMenu } from "../PaymentRequestContextMenu";
import { PaymentRequestAttachmentsModal } from "../PaymentRequestAttachmentsModal";

export function PaymentRequestPage() {
  return (
    <Container>
      <Page>
        <header className="custom-header">
          <PaymentRequestToolbar />
        </header>
        <article className="no-padding fill-height">
          <PaymentRequestTable />
          <PaymentRequestContextMenu />
          <PaymentRequestAttachmentsModal />
        </article>
      </Page>
    </Container>
  );
}
