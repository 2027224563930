import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICancelAccountReceivableContract } from "../../domain/contracts/cancelAccountReceivableContract";
import { EAccountReceivableStatus } from "../../domain/entities/accountReceivableListItemEntity";

export class CancelAccountReceivableService
  implements ICancelAccountReceivableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async cancelAccountReceivableContract(
    accountReceivableId: string,
    reason: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsReceivable/${accountReceivableId}/Cancel`;

    const payload = {
      status: EAccountReceivableStatus.Canceled,
      cancelReason: reason,
    };

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
