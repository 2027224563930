import { ColumnProps } from "primereact/column";
import { useCallback } from "react";
import { Control } from "react-hook-form";
import { TaxIgnoreField } from "../components/TaxIgnoreField";
import { TaxField } from "../components/TaxField";
import { ProjectTaxesForm } from "../components/ProjectTaxesModal";
import { IProjectTaxEntity } from "../../domain/entities/projectTaxesEntity";

export interface UseProjectTaxesGridParams {
  projectIndex: number;
  control: Control<ProjectTaxesForm>;
}

export function useProjectTaxesGrid() {
  return useCallback((props: UseProjectTaxesGridParams) => {
    const { projectIndex, control } = props;

    const columns: ColumnProps[] = [
      {
        header: "Taxa",
        field: "name",
        style: {
          width: "25%",
        },
      },
      {
        header: "Data Inicial",
        field: "startDate",
        style: {
          width: "20%",
        },
      },
      {
        header: "Data Final",
        field: "endDate",
        style: {
          width: "20%",
        },
      },
      {
        header: "%",
        field: "tax",
        style: {
          width: "20%",
        },
        body: (_, { rowIndex }) => {
          return (
            <TaxField
              control={control}
              taxIndex={rowIndex}
              projectIndex={projectIndex}
            />
          );
        },
      },
      {
        header: "É USP?",
        field: "isUsp",
        style: {
          width: "7.5%",
        },
        body: (item: IProjectTaxEntity) => {
          return item.isUsp ? "Sim" : "Não";
        },
      },
      {
        header: "Ignorar?",
        field: "ignoreTax",
        style: {
          width: "7.5%",
        },
        body: (_, { rowIndex }) => {
          return (
            <TaxIgnoreField
              control={control}
              taxIndex={rowIndex}
              projectIndex={projectIndex}
            />
          );
        },
      },
    ];

    return {
      columns,
    };
  }, []);
}
