import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetSafraProviderRemittanceTemplateContract } from "../../domain/contracts/getSafraProviderRemittanceTemplateContract";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";

export class GetSafraProviderRemittanceTemplateService
  implements IGetSafraProviderRemittanceTemplateContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getSafraProviderRemittanceTemplate() {
    const url = `/Remittances/${ERemittanceBankCode.Safra}/${ERemittanceType.Provider}/Template`;
    const userEntity = this.getUserLocalService.get();

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
