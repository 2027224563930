import { Accept, useDropzone } from "react-dropzone";
import { IoMdCloseCircle } from "react-icons/io";
import excelThumbnail from "../../../../assets/excel-file-image.png";
import imageThumbnail from "../../../../assets/image-file.png";
import pdfThumbnail from "../../../../assets/pdf-icon.png";
import { Container } from "./styles";

interface IFileUploadProps {
  files: File[];
  placeholder?: string;
  accept?: Accept;
  multiple?: boolean;
  maxSize?: number;
  errorText?: string;
  isInvalid?: boolean;
  onDropAccepted(files: File[]): void;
  onDropRejected(errorText: string): void;
  onRemoveFile(files: File[]): void;
}

export function FileUpload({
  files,
  multiple = false,
  accept = undefined,
  maxSize = 104857600,
  placeholder = "Solte o arquivo aqui ou clique para navegar.",
  errorText = "O formato do arquivo selecionado não é aceito como anexo.",
  isInvalid = false,
  onDropAccepted,
  onDropRejected,
  onRemoveFile,
}: IFileUploadProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    maxSize,
    onDropAccepted,
    onDropRejected: () => {
      onDropRejected(errorText);
    },
  });

  /**
   * Lida com o evento de remocao de arquivo emitido
   * quando o usuario remove um arquivo do dropzone
   */
  const handleRemoveFile = (
    file: File,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    const fileIndex = files.indexOf(file);
    const filteredList = files.filter(
      _file => files.indexOf(_file) !== fileIndex,
    );

    onRemoveFile(filteredList);
  };

  /** Decide qual icone exibir p/ o arquivo selecionado */
  const definePreviewIcon = (file: File) => {
    const fileExt = file.type;

    if (fileExt.toLocaleLowerCase().includes("pdf")) {
      return pdfThumbnail;
    }

    if (fileExt.toLocaleLowerCase().includes("image")) {
      return imageThumbnail;
    }

    return excelThumbnail;
  };

  return (
    <Container
      {...getRootProps()}
      className={isInvalid ? "is-invalid" : undefined}
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      {!files?.length ? <p>{placeholder}</p> : null}
      {files?.length
        ? files.map(file => {
            const fileKey = `${file.name}&${files.indexOf(file)}`;

            return (
              <div
                key={fileKey}
                className="dropzone-preview"
                data-testid="dropzone-preview"
              >
                <button
                  type="button"
                  data-testid="btn-remove"
                  onClick={$event => {
                    handleRemoveFile(file, $event);
                  }}
                >
                  <IoMdCloseCircle />
                </button>
                <img src={definePreviewIcon(file)} alt={file.name} />
                <p>{file.name}</p>
              </div>
            );
          })
        : null}
    </Container>
  );
}
