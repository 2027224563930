import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  EApprovalStatus,
  ICostCenterReportEntity,
} from "../../domain/entities/costCenterReportEntity";
import { IDisapproveService } from "../../domain/usecases/disapproveUseCase";
import { IApprovalPayloadModel } from "../models/approvalStatusModel";

export class DisapproveService implements IDisapproveService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async disapprove(id: string): Promise<ICostCenterReportEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Reports/CostCenters/${id}/Approval`;

    const payload: IApprovalPayloadModel = {
      approvalStatus: EApprovalStatus.Disapproved,
    };

    const response = await this.api.put<ICostCenterReportEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
