import { IClassificationAssessmentEntity } from "../../../classificationAssessment/domain/entities/classificationAssessment";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationAssessmentService } from "../../domain/usecases/getClassificationAssessmentUseCase";

export class GetClassificationAssessmentService
  implements IGetClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getClassificationAssessment(classificationAssessmentId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationAssessments/${classificationAssessmentId}`;

    return this.api.get<IClassificationAssessmentEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
