import { useMemo } from "react";
import { Container } from "./styles";

export enum EBalanceVariant {
  Green,
  Red,
}
interface BalanceProps {
  value: number | undefined;
  variant?: EBalanceVariant;
  label?: string;
}

export function Balance({ value, variant, label = "Total" }: BalanceProps) {
  const formattedValue = useMemo(() => {
    if (value === undefined) {
      return <>&mdash;</>;
    }

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }, [value]);

  return (
    <Container value={value} variant={variant}>
      <div>
        <span>{label}</span>: &nbsp; <span>{formattedValue}</span>
      </div>
    </Container>
  );
}
