import { UserEntity } from "../../domain/entities/userEntity";
import { IIsLoggedInService } from "../../domain/usecases/isLoggedInUseCase";

interface IGetUserLocalService {
  get(): UserEntity | null;
}

export class IsLoggedInService implements IIsLoggedInService {
  constructor(private getUserLocalService: IGetUserLocalService) {}

  isLoggedIn(): boolean {
    const userData = this.getUserLocalService.get();

    if (userData) return Object.keys(userData).length > 0;

    return false;
  }
}
