import styled from "styled-components";

export const Container = styled.div`
  input {
    padding: 0.25rem 0.5rem;
  }

  input + button {
    padding: 0.25rem;
    width: auto;
  }
`;
