import { format } from "date-fns";
import { useMemo } from "react";
import {
  EConciliationOrigin,
  IConciliationEntryEntity,
  IConciliationEntryRawEntity,
} from "../../../domain/entitites/conciliationEntity";
import { Container } from "./styles";

interface IConciliationCardProps {
  conciliationEntry: IConciliationEntryRawEntity | IConciliationEntryEntity;
  rowNumber?: number;
  hideParcelNumber?: boolean;
}

export function ConciliationCard({
  conciliationEntry,
  rowNumber,
  hideParcelNumber = false,
}: IConciliationCardProps) {
  const {
    companyName,
    documentNumber,
    issueDate,
    customerProviderName,
    parcelNumber,
    customerProviderDocument: document,
    origin,
    value,
  } = conciliationEntry;

  const formatDocument = (_document?: string) => {
    const onlyDigits = _document?.replace(/\D/g, "");

    if (onlyDigits && onlyDigits.length > 11) {
      return onlyDigits.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5",
      );
    }
    return onlyDigits?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  };

  const formattedValue = useMemo(() => {
    if (value !== null) {
      return new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        signDisplay: "always",
      }).format(value);
    }

    return "";
  }, [value]);

  const companyNamePlusDocumentNumber = useMemo(() => {
    if (!companyName && documentNumber) {
      return documentNumber.toUpperCase();
    }

    if (companyName && !documentNumber) {
      return companyName.toUpperCase();
    }

    if (!companyName && !documentNumber) {
      return "";
    }

    return `${companyName} - ${documentNumber}`.toUpperCase();
  }, [companyName, documentNumber]);

  return (
    <Container
      className="card"
      data-rownumber={rowNumber ? `Linha ${rowNumber}` : undefined}
    >
      {/* coluna esq */}
      <div className="left-col">
        {/* linha 1 */}
        <div className="top-info">
          <div title={companyNamePlusDocumentNumber}>
            {companyNamePlusDocumentNumber}
          </div>
          <div title={issueDate ? format(issueDate, "dd/MM/yyyy") : ""}>
            {issueDate ? format(issueDate, "dd/MM/yyyy") : ""}
          </div>
        </div>

        {/* linha 2 */}
        <div className="bottom-info">
          <div title={customerProviderName?.toUpperCase?.()}>
            {customerProviderName}
          </div>
          <div title={formatDocument(document)}>{formatDocument(document)}</div>
        </div>
      </div>

      {/* coluna dir */}
      <div className="right-col">
        {origin === EConciliationOrigin.revenue && !hideParcelNumber && (
          <span title={`Parcela ${parcelNumber}`}>Parcela {parcelNumber}</span>
        )}
        <span
          title={formattedValue}
          className={`value ${
            origin === EConciliationOrigin.expense ? "negative" : undefined
          }`.trim()}
        >
          {formattedValue}
        </span>
      </div>
    </Container>
  );
}
