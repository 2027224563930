export enum ELocalStorageKeys {
  User = "user",
  CompanyGroup = "selectedCompanyGroup",
  CostCenterReportPanoramaId = "costCenterReportPanoramaId",
  ImportationLMSActiveTabs = "importationLMSActiveTabs",
  ImportationSolutionActiveTabs = "importationSolutionActiveTabs",
  AccountsPayablePanoramaId = "payablePanoramaId",
  DuplicateAccountPayableId = "duplicateAccountPayableId",
  PaymentRequestPanoramaId = "paymentRequestsPanoramaId",
  AccountsReceivablePanoramaId = "receivablePanoramaId",
  DuplicateAccountReceivableId = "duplicateAccountReceivableId",
  UspIncomeStatementReportId = "uspIncomeStatementReportId",
}
