import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../../advTable/presentation/components/ColumnFilterEnum";
import { SimpleTableColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { MakePaymentRequests } from "../../main/makePaymentRequests";
import {
  EPaymentRequestDestination,
  EPaymentRequestDocumentStatus,
  EPaymentRequestPaymentMethod,
  EPaymentRequestStatus,
  paymentRequestDestinationLang,
  paymentRequestDocumentStatusLang,
  paymentRequestPaymentMethodLang,
  paymentRequestStatusLang,
} from "../../domain/entities/paymentRequestEnums";
import { IPaymentRequestEntity } from "../../domain/entities/paymentRequestEntity";
import { Badge } from "../components/RequesterPage/styles";

interface IUseRequesterGridParams {
  usePaymentRequests: MakePaymentRequests;
}

/**
 * Os usuários que tem acesso a essa grid de solicitações de pagamento
 * **não podem** visualizar status *Não solicitado*, que é
 * exclusivo de usuários de perfil *Gestor* ou *Solicitante*.
 */
enum EAvailablePaymentRequestsStatus {
  Requested = 1,
  Canceled = 2,
  Approved = 3,
  Rejected = 4,
  Paid = 5,
}

export function usePaymentRequestGrid({
  usePaymentRequests,
}: IUseRequesterGridParams) {
  const {
    searchUser,
    searchCompany,
    searchProject,
    searchProvider,
    searchCompetency,
    searchPaymentAccount,
    searchClassificationUsp,
    searchClassificationAccount,
  } = usePaymentRequests;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Destino",
        field: "destinationDescription",
        orderable: true,
        searchable: true,
        searchField: "destination",
        columnType: EColumnType.enum,
        enumObject: EPaymentRequestDestination,
        enumLang: paymentRequestDestinationLang,
        width: "144px",
        className: "text-truncate",
        bodyTemplate(rowData: IPaymentRequestEntity) {
          const { destination, destinationDescription } = rowData;
          return (
            <Badge destination={destination} title={destinationDescription}>
              {destinationDescription}
            </Badge>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EPaymentRequestDestination;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const destination = value as EPaymentRequestDestination;

          return (
            <Badge title={label} destination={destination}>
              {label}
            </Badge>
          );
        },
      },
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "150px",
        async getList(search = "") {
          return searchCompany({
            companyGroupId: id,
            search,
            payloadOptions: {
              length: 50,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Nº documento",
        field: "documentNumber",
        width: "180px",
        className: "text-truncate",
      },
      {
        header: "Valor",
        field: "valueString",
        sortField: "value",
        searchField: "value",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "142px",
        className: "text-truncate",
      },
      {
        header: "Status",
        field: "statusDescription",
        searchable: false,
        orderable: true,
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: EAvailablePaymentRequestsStatus,
        enumLang: paymentRequestStatusLang,
        width: "144px",
        className: "text-truncate",
        bodyTemplate(rowData: IPaymentRequestEntity) {
          const { status, statusDescription } = rowData;
          return (
            <Badge title={statusDescription} status={status}>
              {statusDescription}
            </Badge>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EPaymentRequestStatus;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const status = value as EPaymentRequestStatus;
          return (
            <Badge title={label} status={status}>
              {label}
            </Badge>
          );
        },
      },
      {
        header: "Descrição",
        field: "description",
        width: "160px",
        className: "text-truncate",
      },
      {
        header: "Fornecedor",
        field: "providerName",
        searchField: "providerId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "170px",
        async getList(search = "", fetchMinLength = 50) {
          return searchProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Pagar em",
        field: "payUntilString",
        orderable: true,
        searchable: true,
        sortField: "payUntil",
        searchField: "payUntil",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "150px",
      },
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        sortField: "issueDate",
        searchField: "issueDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "190px",
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EPaymentRequestPaymentMethod,
        enumLang: paymentRequestPaymentMethodLang,
        className: "text-truncate",
        width: "230px",
      },
      {
        header: "Status da documentação",
        field: "documentStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "documentStatus",
        searchField: "documentStatus",
        columnType: EColumnType.enum,
        enumObject: EPaymentRequestDocumentStatus,
        enumLang: paymentRequestDocumentStatusLang,
        className: "text-truncate",
        width: "250px",
      },
      {
        header: "Observação",
        field: "observation",
        width: "165px",
        className: "text-truncate",
      },
      {
        header: "Solicitante",
        field: "userNameCreated",
        orderable: true,
        searchable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Aprovado por",
        field: "approveUserName",
        searchable: true,
        orderable: true,
        searchField: "approveUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "186px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Data de cadastro",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "205px",
      },
      {
        header: "Motivo da reprovação",
        field: "rejectReason",
        width: "234px",
        className: "text-truncate",
      },
      {
        header: "Reprovado por",
        field: "rejectUserName",
        searchable: true,
        orderable: true,
        searchField: "rejectUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Reprovado em",
        field: "rejectDateString",
        searchable: true,
        orderable: true,
        searchField: "rejectDate",
        sortField: "rejectDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "180px",
      },
      {
        header: "Conta de Pagamento",
        field: "paymentAccountName",
        searchField: "paymentAccountId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "220px",
        async getList(search = "", fetchMinLength = 10) {
          return searchPaymentAccount({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        searchable: true,
        orderable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "150px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Classificação Contábil",
        field: "classificationAccountName",
        searchable: true,
        orderable: true,
        searchField: "classificationAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "230px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAccount({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        searchable: true,
        orderable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "200px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Competência",
        field: "competencyName",
        searchable: true,
        orderable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "170px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Data de aprovação",
        field: "approveDateString",
        searchable: true,
        orderable: true,
        searchField: "approveDate",
        sortField: "approveDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "210px",
      },
      {
        header: "Data de modificação",
        field: "dateModifiedString",
        searchable: true,
        orderable: true,
        searchField: "dateModified",
        sortField: "dateModified",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "220px",
      },
      {
        header: "Motivo de cancelamento",
        field: "deleteReason",
        searchable: true,
        orderable: true,
        searchField: "deleteReason",
        sortField: "deleteReason",
        columnType: EColumnType.text,
        className: "text-truncate",
        width: "220px",
      },
      {
        header: "Cancelado por",
        field: "deleteUserName",
        searchable: true,
        orderable: true,
        searchField: "deleteUserName",
        sortField: "deleteUserName",
        columnType: EColumnType.text,
        className: "text-truncate",
        width: "220px",
      },
      {
        header: "Data do cancelamento",
        field: "deleteDateString",
        searchable: true,
        orderable: true,
        searchField: "deleteDate",
        sortField: "deleteDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "236px",
      },
      { field: "status", hidden: true },
      { field: "value", hidden: true },
      { field: "issueDate", hidden: true },
      { field: "payUntil", hidden: true },
      { field: "rejectDate", hidden: true },
      { field: "anyAttachmentNF", hidden: true },
      { field: "destination", hidden: true },
      { field: "paymentMethod", hidden: true },
      { field: "documentStatus", hidden: true },
      { field: "approveDate", hidden: true },
      { field: "selected", hidden: true },
      { field: "active", hidden: true },
      { field: "dateCreated", hidden: true },
      { field: "userCreated", hidden: true },
      { field: "dateModified", hidden: true },
      { field: "userModified", hidden: true },
      { field: "deleteDate", hidden: true },
      { field: "storageFiles", hidden: true },
    ];
  }, [
    id,
    searchUser,
    searchCompany,
    searchProject,
    searchProvider,
    searchCompetency,
    searchPaymentAccount,
    searchClassificationUsp,
    searchClassificationAccount,
  ]);

  return {
    columns,
  };
}
