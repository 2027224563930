import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IListImportationMatchesBanksContract } from "../domain/contracts/listImportationMatchesBanksContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";
import { IImportationMatchesBankEntity } from "../domain/entities/importationMatchesBankEntity";

export class ListImportationMatchesBanksService
  implements IListImportationMatchesBanksContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listImportationMatchesBanks(origin: EImportationMatchesOrigin) {
    const userEntity = this.getUserLocalService.get();
    const endpoint = `/ImportationMatches/${origin}/Banks`;

    const payload = {
      filter: "",
      skip: "0",
      take: "1000",
    };

    const params = new URLSearchParams(payload);

    const url = `${endpoint}?${params}`;

    return this.api.get<IImportationMatchesBankEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
