import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import {
  CompetencyEntity,
  ICompetencyEntity,
} from "../../../domain/entities/competencyEntity";
import { MakeCompetency } from "../../../main/makeCompetency";
import { Container, Loading } from "./styles";

interface CompetencyFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  currentId: string;
  useCompetency: MakeCompetency;
}

export function CompetencyFormModal({
  isOpen,
  onRequestClose,
  currentId,
  useCompetency,
}: CompetencyFormModalProps) {
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { getCompetency, saveCompetency } = useCompetency;
  const dialog = useSoulDialog();

  const [isCreateMode, setIsCreateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  // UGLY por enquanto estou usando o hook "reset" pra colocar os
  // valores no formulario, mas talvez não seja a melhor abordagem.
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ICompetencyEntity>({
    mode: "onChange",
  });

  useEffect(() => {
    const isNew = currentId === "";
    setIsCreateMode(isNew);

    if (isNew) {
      reset(
        CompetencyEntity.create({
          companyGroupId: currentCompanyGroup.id,
        }),
      );
    } else if (!isNew) {
      // get competency
      const getCurrentCompetency = async () => {
        setIsLoading(true);
        const formValues = await getCompetency(currentId);
        reset(formValues);
        setIsLoading(false);
      };
      getCurrentCompetency();
    }
  }, [currentCompanyGroup.id, currentId, getCompetency, reset]);

  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    reset();
    onRequestClose();
  }, [onRequestClose, reset]);

  const allowedProfiles = useAllowedProfiles();
  const readonly = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles]);

  const save = useCallback(
    async (data: ICompetencyEntity) => {
      if (readonly) {
        return;
      }

      setIsLoadingButton(true);

      const msg = isCreateMode
        ? "Competência cadastrada com sucesso."
        : "Competência atualizada com sucesso.";

      try {
        await saveCompetency(data);

        await dialog.fire({
          title: "Feito!",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose();
      } catch (err) {
        dialog.close();
      }

      setIsLoadingButton(false);
    },
    [dialog, isCreateMode, readonly, requestClose, saveCompetency],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>{isCreateMode ? "Nova Competência" : "Editar Competência"}</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {isLoading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              <div className="card card-header-border">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{currentCompanyGroup.name}</h3>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    {...register("name", { required: true })}
                    id="name"
                    data-testid="txt-name"
                    placeholder="Nome"
                    type="text"
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                    disabled={readonly}
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>É USP?</span>
                  <div>
                    <Checkbox
                      label="Sim"
                      {...register("isUsp")}
                      id="chk-usp"
                      disabled={readonly}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-save"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
                disabled={readonly || isLoadingButton}
                onClick={handleSubmit(save)}
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
