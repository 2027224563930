import { IEntity } from "../../../../../core/domain/entities/entity";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IDebtImportAssessmentEntity } from "./debtImportAssessmentEntity";
import { IDebtImportAttachmentEntity } from "./debtImportAttachmentEntity";

export interface IDebtImportEntity extends IEntity {
  accountAlreadyExists: boolean;
  accountAlreadyExistsForAnotherUser: boolean;
  accountAlreadyPaid: boolean;
  assessments: IDebtImportAssessmentEntity[];
  barcode: string;
  classificationAccount: ITypeaheadOption | null;
  classificationUsp: ITypeaheadOption | null;
  company: ITypeaheadOption | null;
  competency: ITypeaheadOption | null;
  description: string;
  documentNumber: string;
  documentStatus: IEnum | null;
  getCompanyHasProject: boolean;
  getCompetencyIsUsp: boolean;
  hasAssessmentError: boolean;
  hasError: boolean;
  issueDate: string;
  observation: string;
  payUntil: string;
  paymentAccount: ITypeaheadOption | null;
  paymentMethod: IEnum | null;
  project: ITypeaheadOption | null;
  provider: ITypeaheadOption | null;
  storageFiles: IDebtImportAttachmentEntity[];
  terminationDate: string;
  value: number;
  valueAssessmentLeft: number;
  fuspPurchaseOrderId: string | null;
  correspondingProviderName: string;
  fieldErrors: {
    [key in keyof Omit<
      IDebtImportEntity,
      | "fieldErrors"
      | "accountAlreadyExists"
      | "accountAlreadyPaid"
      | "getCompanyHasProject"
      | "getCompetencyIsUsp"
      | "hasAssessmentError"
      | "hasError"
    >]?: string;
  };
}

export class DebtImportEntity implements IDebtImportEntity {
  accountAlreadyExists = false;
  accountAlreadyExistsForAnotherUser = false;
  accountAlreadyPaid = false;
  assessments = [];
  barcode = "";
  classificationAccount = null;
  classificationUsp = null;
  company = null;
  competency = null;
  description = "";
  documentNumber = "";
  documentStatus = null;
  getCompanyHasProject = false;
  getCompetencyIsUsp = false;
  hasAssessmentError = false;
  hasError = false;
  issueDate = "";
  observation = "";
  payUntil = "";
  paymentAccount = null;
  paymentMethod = null;
  project = null;
  provider = null;
  storageFiles = [];
  terminationDate = "";
  value = 0;
  valueAssessmentLeft = 0;
  id = "";
  active = false;
  fieldErrors = {};
  fuspPurchaseOrderId = null;
  correspondingProviderName = "";

  constructor(init?: Partial<IDebtImportEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<IDebtImportEntity>) {
    return new DebtImportEntity(init);
  }
}
