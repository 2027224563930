import { AES } from "crypto-js";

interface IEncryptService {
  encrypt(data: { [key: string]: string | number }): string;
}

export class EncryptService implements IEncryptService {
  private cryptoJSKey: string = process.env.REACT_APP_CRYPTOJS_KEY ?? "";

  encrypt(data: { [key: string]: string | number }): string {
    const encryptedData = AES.encrypt(JSON.stringify(data), this.cryptoJSKey)
      .toString()
      .replace(/\//g, "mDfkSla");

    return encryptedData;
  }
}
