import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IReprovePaymentRequestContract } from "../../domain/contracts/reprovePaymentRequestContract";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { IPaymentRequestModel } from "../models/paymentRequestModel";

export class ReprovePaymentRequestService
  implements IReprovePaymentRequestContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  reprovePaymentRequest(paymentRequestId: string, reason: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `PaymentRequests/${paymentRequestId}/Status`;

    const data = {
      reason,
      status: EPaymentRequestStatus.Rejected,
    };

    return this.api.put<IPaymentRequestModel>(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
