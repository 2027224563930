import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IListAccountPayableFeesContract } from "../../domain/contracts/listAccountPayableFeesContract";
import {
  IAccountPayableFeeEntity,
  IListAccountPayableFeesEntity,
} from "../../domain/entities/accountPayableFeeEntity";
import {
  EAccountPayableStatus,
  IAccountPayableListItemEntity,
} from "../../domain/entities/accountPayableListItemEntity";

interface IAccountPayableFeeModel {
  accountPayableId: string;
  active: boolean;
  feeAccountPayableId: string;
  feeId: string;
  id: string;
  value: number;
  retentionId: string;
}

interface IFee {
  active: boolean;
  companyGroupId: string;
  companyGroupName: string;
  dateCreated: string;
  dateCreatedString: string;
  dateModified: string;
  dateModifiedString: string;
  id: string;
  name: string;
  userCreated: string;
  userModified: string;
  userNameCreated: string;
  userNameModified: string;
}

export class ListAccountPayableFeesService
  implements IListAccountPayableFeesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listAccountPayableFees(
    companyGroupId: string,
    accountPayableListItem: IAccountPayableListItemEntity,
  ): Promise<IListAccountPayableFeesEntity> {
    const isCanceledAndHasFeeRetention =
      await this.isCanceledAndHasFeeRetention(accountPayableListItem);

    const accountPayableId = accountPayableListItem.id;

    const paidOrCanceledAccountPayableFee =
      await this.getPaidOrCanceledAccountPayableFee(accountPayableId);

    const activeFees = await this.getActiveFees(companyGroupId);

    const accountPayableFees = await this.getAccountPayableFees(
      accountPayableId,
    );

    const mappedFees: IAccountPayableFeeEntity[] = activeFees.map(fee => {
      const feeId = fee.id;

      const accountPayableFee = accountPayableFees.find(
        _fee => _fee.feeId === feeId,
      );

      if (accountPayableFee) {
        return {
          id: accountPayableFee.id,
          feeId,
          value: accountPayableFee.value,
          name: fee.name,
          active: accountPayableFee.active,
          accountPayableId: accountPayableFee.accountPayableId,
          feeAccountPayableId: accountPayableFee.feeAccountPayableId,
          retentionId: accountPayableFee.retentionId,
          editable: !accountPayableFee.feeAccountPayableId,
        };
      }

      return {
        id: null,
        feeId,
        value: 0,
        name: fee.name,
        active: fee.active,
        accountPayableId: null,
        feeAccountPayableId: null,
        retentionId: null,
        editable: true,
      };
    });

    return {
      accountPayableList: mappedFees,
      paidOrCanceledAccountPayableFee,
      isCanceledAndHasFeeRetention,
    };
  }

  /**
   * Se a conta a pagar estiver cancelada consulta
   * a api para saber se ela tem retencao de impostos
   */
  async isCanceledAndHasFeeRetention(
    accountPayableListItem: IAccountPayableListItemEntity,
  ): Promise<boolean> {
    const { id, status } = accountPayableListItem;

    if (status !== EAccountPayableStatus.Canceled) {
      return false;
    }

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountPayableFees/${id}/HasFeeRetention`;

    const response = await this.api.get<{ hasFeeRetention: boolean }>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.hasFeeRetention;
  }

  /**
   * Obtem a lista de impostos cancelados ou pagos relacionados
   * a conta cujo id corresponde ao id informado
   */
  private async getPaidOrCanceledAccountPayableFee(accountPayableId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountPayableFees/PaidOrCanceledAccountPayableFee`;

    const urlSearchParams = new URLSearchParams({
      accountPayableId,
    });

    const paidOrCanceledAccountPayableFees = await this.api.get<string[]>(
      `${url}?${urlSearchParams}`,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return paidOrCanceledAccountPayableFees;
  }

  /** Obtem os impostos ativos para o grupo de empresas atual */
  private async getActiveFees(companyGroupId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups/${companyGroupId}/Fees/Actives`;

    const activeFeesResponse = await this.api.get<IResponseEntity<IFee[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const activeFees = activeFeesResponse.data;

    return activeFees;
  }

  /** Obtem os impostos vinculados conta cujo id corresponde ao id informado */
  private async getAccountPayableFees(accountPayableId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/${accountPayableId}/Fees`;

    const accountPayableFees = await this.api.get<IAccountPayableFeeModel[]>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return accountPayableFees;
  }
}
