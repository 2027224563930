import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { IGenerateAccountReceivableInvoicesContract } from "../../domain/contracts/generateAccountReceivableInvoicesContract";
import { IAccountsReceivableGenerateInvoiceModalForm } from "../../domain/entities/formt";

interface IGenerateAccountReceivableInvoicesPayload {
  accountReceivableId: string;
  issAliquot: number;
  cnaeClassificationId: string;
  municipalActivityClassificationId: string;
  invoiceServiceDescription: string;
  irAliquot: number;
  csllAliquot: number;
  pisAliquot: number;
  cofinsAliquot: number;
  serviceSupplyCityId: string;
  serviceSupplyStateId: string;
  isIssRetainedAtSource: boolean;
}

export class GenerateAccountReceivableInvoicesService
  implements IGenerateAccountReceivableInvoicesContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async generateAccountReceivableInvoices(
    formData: IAccountsReceivableGenerateInvoiceModalForm,
  ): Promise<void> {
    const url = `/AccountsReceivable/Invoice`;
    const userEntity = this.getUserLocalService.get();

    const payload =
      formData.data.map<IGenerateAccountReceivableInvoicesPayload>(data => {
        return {
          accountReceivableId: data.id,
          cnaeClassificationId: `${data.cnae?.rawValue || ""}`,
          invoiceServiceDescription: data.invoiceDescription.trim() || "",
          issAliquot: data.issAliquot,
          municipalActivityClassificationId: `${
            data.serviceFramework?.rawValue || ""
          }`,
          irAliquot: data.irAliquot,
          csllAliquot: data.csllAliquot,
          pisAliquot: data.pisAliquot,
          cofinsAliquot: data.cofinsAliquot,
          serviceSupplyCityId: `${data.serviceSupplyCity?.rawValue || ""}`,
          serviceSupplyStateId: `${data.serviceSupplyState?.rawValue || ""}`,
          isIssRetainedAtSource: data.isIssRetainedAtSource,
        };
      });

    await this.api.post(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
