import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IBulkUploadAttachmentsContract } from "../../domain/contracts/bulkUploadAttachments";
import { IDebtImportAttachmentEntity } from "../../domain/entities/debtImportAttachmentEntity";
import { IDebtImportAttachmentModel } from "../models/debtImportAttachmentModel";

export class BulkUploadAttachmentService
  implements IBulkUploadAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async bulkUploadAttachments(
    debtImportIds: string[],
    attachmentList: IDebtImportAttachmentEntity[],
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountPayableImportations/Attachments`;

    const formData = new FormData();

    for (let index = 0; index < debtImportIds.length; index += 1) {
      formData.append(`AccountPayableIds[${index}]`, debtImportIds[index]);
    }

    for (let index = 0; index < attachmentList.length; index += 1) {
      const attachment = attachmentList[index];

      formData.append(`Uploads[${index}].Name`, attachment.name.toString());
      formData.append(`Uploads[${index}].Active`, attachment.active.toString());
      formData.append(`Uploads[${index}].Type`, attachment.type.toString());
      formData.append(
        `Uploads[${index}].TypeDescription`,
        attachment.typeDescription.toString(),
      );

      if (attachment.file) {
        formData.append(`Uploads[${index}].File`, attachment.file);
      }

      if (attachment.barcode) {
        formData.append(`Uploads[${index}].barcode`, attachment.barcode);
      }

      if (attachment.storageFileId) {
        formData.append(
          `Uploads[${index}].storageFileId`,
          attachment.storageFileId,
        );
      }
    }

    return this.api.post<IDebtImportAttachmentModel[]>(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
