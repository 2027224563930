import { useEffect } from "react";
import { FaCheck, FaEye } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import {
  EConciliationOrigin,
  IConciliationEntryEntity,
} from "../../../domain/entitites/conciliationEntity";
import { Container } from "./styles";

interface IConciliationButtonsBaseProps {
  className?: string;
  disabled?: boolean;
}

type IConciliationButtonsProps =
  | IConciliationButtonsBaseProps &
      (
        | {
            row: number;
            index: number;
            conciliationReleaseEntry: IConciliationEntryEntity;
            conciliateButtonOnClick(
              row: number,
              index: number,
              conciliationReleaseEntry: IConciliationEntryEntity,
            ): void;
            viewButtonOnClick(
              entryId: string,
              origin: EConciliationOrigin,
            ): void;
          }
        | {
            row?: never;
            index?: never;
            conciliationReleaseEntry?: never;
            conciliateButtonOnClick?: never;
            viewButtonOnClick?: never;
          }
      );

export function ConciliationButtons({
  className = undefined,
  row,
  index,
  disabled = false,
  conciliationReleaseEntry,
  conciliateButtonOnClick,
  viewButtonOnClick,
}: IConciliationButtonsProps) {
  useEffect(() => {
    // Isso é necessário pois temos elementos dinamicos
    // com tooltip e o ReactTooltip precisa escanea-los
    ReactTooltip.rebuild();
  }, []);

  const handleViewButtonClick = () => {
    if (!conciliationReleaseEntry) {
      return;
    }

    const { id, origin } = conciliationReleaseEntry;

    if (!id) {
      return;
    }

    if (!origin) {
      return;
    }

    viewButtonOnClick?.(id, origin);
  };

  const handleConciliateButtonClick = () => {
    if (row !== undefined && index !== undefined && conciliationReleaseEntry) {
      let { conciliated } = conciliationReleaseEntry;

      conciliated = !conciliated;

      const value: IConciliationEntryEntity = {
        ...conciliationReleaseEntry,
        conciliated,
      };

      conciliateButtonOnClick?.(row, index, value);
    }
  };

  const isConciliated = !!conciliationReleaseEntry?.conciliated;

  return (
    <Container className={className}>
      <button
        type="button"
        data-place="right"
        data-effect="solid"
        data-tip="Visualizar conta"
        disabled={disabled}
        onClick={handleViewButtonClick}
      >
        <FaEye />
      </button>
      <button
        type="button"
        data-place="right"
        data-effect="solid"
        data-tip="Conciliar"
        disabled={disabled}
        className={isConciliated ? "conciliated" : undefined}
        onClick={handleConciliateButtonClick}
      >
        <FaCheck />
      </button>
      {/* nao havera funcao de historico ainda */}
      {/* <button type="button">
        <FaInfoCircle />
      </button> */}
    </Container>
  );
}
