import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IChangeSelectedCostCentersLockDatesContract,
  IChangeSelectedCostCentersLockDatesParams,
} from "../../domain/contracts/changeSelectedCostCentersLockDatesContract";

export class ChangeSelectedCostCentersLockDatesService
  implements IChangeSelectedCostCentersLockDatesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  changeSelectedCostCentersLockDates({
    newLockDate,
    selectedCostCenters,
  }: IChangeSelectedCostCentersLockDatesParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "CostCenters/ChangeLockReleasesUntil";

    const costCenterIdList = selectedCostCenters.map(entity => entity.id);

    const lockDate = newLockDate ? this.formatDate(newLockDate) : "";

    const payload = {
      lockDate,
      costCenterIdList,
    };

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    const parsed = parse(date, "ddMMyyyy", new Date());
    return format(parsed, "yyyy-MM-dd");
  }
}
