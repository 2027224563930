import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import {
  IFormComponentProps,
  ITypeaheadState,
  IDebtImportForm,
} from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";
import { ITypeaheadOption } from "../../../../../../../core/domain/entities/typeaheadOption";
import { ICompanyEntity } from "../../../../../../../company/domain/entities/companyEntity";

export function CompanyField(props: IFormComponentProps) {
  const { formIndex } = props;

  const formPrefix = `imports.${formIndex}` as const;

  const [state, setState] = useState<ITypeaheadState>({
    options: [],
    loading: false,
  });

  const asyncDebounce = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { useDebtImport, handleInputClassName } = useDebtImportPage();

  const { searchCompany } = useDebtImport;

  const { setValue, clearErrors, unregister } =
    useFormContext<IDebtImportForm>();

  const handleCompanyChange = (event: ISoulTypeaheadChangeEvent) => {
    const company = event.target?.value as ITypeaheadOption<ICompanyEntity>;
    const hasProject = company?.metadata?.hasProject;

    clearErrors(`${formPrefix}.payUntil`);

    setValue(`${formPrefix}.paymentAccount`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (!hasProject) {
      unregister(`${formPrefix}.project`);
      unregister(`${formPrefix}.competency`);
      unregister(`${formPrefix}.classificationUsp`);
      return;
    }

    setValue(`${formPrefix}.project`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(`${formPrefix}.competency`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(`${formPrefix}.classificationUsp`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });

    const companyIsFusp = company?.label === "FUSP";

    if (!companyIsFusp) {
      unregister(`${formPrefix}.fuspPurchaseOrderId`);
      return;
    }

    setValue(`${formPrefix}.fuspPurchaseOrderId`, "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSearchCompanyChange = async (search = "") => {
    await asyncDebounce(750);

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompany({
        search,
        companyGroupId,
        activesOnly: true,
        showMetadata: true,
        payloadOptions: {
          length: 100,
        },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        options: companies,
        loading: false,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return (
    <Controller
      name={`${formPrefix}.company`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ISoulTypeaheadChangeEvent) => {
          field.onBlur();
          field.onChange(event);
          handleCompanyChange(event);
        };
        return (
          <label className="form-control">
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                value={field.value}
                onChange={onChange}
                placeholder="Empresa"
                options={state.options}
                loading={state.loading}
                id={`txt-company-${formIndex}`}
                data-testid={`txt-company-${formIndex}`}
                onSearchChange={handleSearchCompanyChange}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
