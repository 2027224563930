import { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import styled from "styled-components";
import { CommercialProposalEnums } from "../../../domain/contracts/fetchCommercialProposalEnumsContract";
import { IEnum } from "../../../domain/entities/enum";
import {
  ExecutionDeadLine,
  IGenerateCommercialProposalForm,
} from "../../../domain/entities/generateCommercialProposalForm";
import { InvalidFeedback } from "../InvalidFeedback";
import { SoulSpinner } from "../SoulSpinner";

const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 33.75rem;

  .react-modal-body {
    padding-top: 0;
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }
`;

interface IAttachmentGenerateCommercialProposalModalState {
  isLoading: boolean;
  enums: Record<CommercialProposalEnums, IEnum[]>;
}

interface IAttachmentGenerateCommercialProposalModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  onSubmit(params: IGenerateCommercialProposalForm): Promise<void>;
  fetchCommercialProposalEnums(): Promise<
    Record<CommercialProposalEnums, IEnum[]>
  >;
}

type GenerateCommercialProposalFormFields = {
  [K in keyof IGenerateCommercialProposalForm]:
    | IGenerateCommercialProposalForm[K]
    | "";
};

export function AttachmentGenerateCommercialProposalModal({
  isOpen,
  onSubmit,
  onRequestClose,
  fetchCommercialProposalEnums,
}: IAttachmentGenerateCommercialProposalModalProps) {
  const {
    reset,
    watch,
    control,
    resetField,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<GenerateCommercialProposalFormFields>({
    mode: "all",
    defaultValues: {
      classQuantity: null,
      provisionLocation: "",
      executionDeadline: "",
      proposalValidityPeriod: "",
      serviceProposalObjectType: "",
    },
  });

  const executionDeadline = watch("executionDeadline");

  const isClassDeadLine = executionDeadline === ExecutionDeadLine.Class;

  const [state, setState] =
    useState<IAttachmentGenerateCommercialProposalModalState>({
      isLoading: false,
      enums: {
        executionDeadline: [],
        provisionLocation: [],
        serviceProposalObjectType: [],
      },
    });

  const handleAfterOpen = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const enums = await fetchCommercialProposalEnums();

      setState(prevState => {
        return {
          ...prevState,
          enums,
          isLoading: false,
        };
      });
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const onValid = async (formData: GenerateCommercialProposalFormFields) => {
    try {
      await onSubmit(formData as IGenerateCommercialProposalForm);
    } catch {
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={reset}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerar e anexar Proposta Comercial</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            disabled={isSubmitting}
            className="react-modal-close"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onValid)}>
            <div className="react-modal-body">
              <div className="form-container">
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Objeto da proposta de serviço</span>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="serviceProposalObjectType"
                      render={({ field, fieldState }) => {
                        const onChange = (
                          event: ChangeEvent<HTMLSelectElement>,
                        ) => {
                          field.onChange(Number(event.target.value));
                        };

                        return (
                          <select
                            {...field}
                            onChange={onChange}
                            className={fieldState?.error ? "isInvalid" : ""}
                          >
                            <option value="" hidden disabled>
                              Selecione...
                            </option>
                            {state.enums.serviceProposalObjectType.map(
                              typeEnum => {
                                return (
                                  <option
                                    key={typeEnum.key}
                                    value={typeEnum.key}
                                  >
                                    {typeEnum.value}
                                  </option>
                                );
                              },
                            )}
                          </select>
                        );
                      }}
                    />
                    <InvalidFeedback
                      message="Este campo é obrigatório"
                      condition={
                        errors?.serviceProposalObjectType?.type === "required"
                      }
                    />
                  </label>
                </div>
                <div className="form-row">
                  <label className="col-6 form-control">
                    <span>Prazo de execução</span>
                    <Controller
                      control={control}
                      name="executionDeadline"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        const onChange = (
                          event: ChangeEvent<HTMLSelectElement>,
                        ) => {
                          const chosenValue = Number(event.target.value);

                          if (chosenValue !== ExecutionDeadLine.Class) {
                            resetField("classQuantity");
                          }

                          field.onChange(chosenValue);
                        };

                        return (
                          <select
                            {...field}
                            onChange={onChange}
                            className={fieldState?.error ? "isInvalid" : ""}
                          >
                            <option value="" hidden disabled>
                              Selecione...
                            </option>
                            {state.enums.executionDeadline.map(
                              executionDeadlineEnum => {
                                return (
                                  <option
                                    key={executionDeadlineEnum.key}
                                    value={executionDeadlineEnum.key}
                                  >
                                    {executionDeadlineEnum.value}
                                  </option>
                                );
                              },
                            )}
                          </select>
                        );
                      }}
                    />
                    <InvalidFeedback
                      message="Este campo é obrigatório"
                      condition={errors?.executionDeadline?.type === "required"}
                    />
                  </label>
                  <label className="col-6 form-control">
                    <span>Quantidade de aulas</span>
                    <Controller
                      name="classQuantity"
                      control={control}
                      rules={{ required: isClassDeadLine }}
                      render={({ field, fieldState }) => {
                        const onChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ) => {
                          const eventValue = event.target.value;

                          const filteredValue = eventValue.replace(/\D/g, "");

                          if (!filteredValue || !Number(filteredValue)) {
                            field.onChange("");
                            return;
                          }

                          field.onChange(Number(filteredValue));
                        };

                        return (
                          <input
                            {...field}
                            maxLength={3}
                            onChange={onChange}
                            value={field.value || ""}
                            disabled={!isClassDeadLine}
                            placeholder="Quantidade de aulas"
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                        );
                      }}
                    />
                    <InvalidFeedback
                      message="Este campo é obrigatório"
                      condition={errors?.classQuantity?.type === "required"}
                    />
                  </label>
                </div>
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Local de prestação</span>
                    <Controller
                      control={control}
                      name="provisionLocation"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        const onChange = (
                          event: ChangeEvent<HTMLSelectElement>,
                        ) => {
                          field.onChange(Number(event.target.value));
                        };

                        return (
                          <select
                            {...field}
                            onChange={onChange}
                            className={fieldState?.error ? "isInvalid" : ""}
                          >
                            <option value="" hidden disabled>
                              Selecione...
                            </option>
                            {state.enums.provisionLocation.map(locationEnum => {
                              return (
                                <option
                                  key={locationEnum.key}
                                  value={locationEnum.key}
                                >
                                  {locationEnum.value}
                                </option>
                              );
                            })}
                          </select>
                        );
                      }}
                    />
                    <InvalidFeedback
                      message="Este campo é obrigatório"
                      condition={errors?.provisionLocation?.type === "required"}
                    />
                  </label>
                </div>
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Prazo de validade da proposta</span>
                    <Controller
                      name="proposalValidityPeriod"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        const onChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ) => {
                          const eventValue = event.target.value;

                          const filteredValue = eventValue.replace(/\D/g, "");

                          if (!filteredValue || !Number(filteredValue)) {
                            field.onChange("");
                            return;
                          }

                          field.onChange(Number(filteredValue));
                        };

                        return (
                          <input
                            {...field}
                            maxLength={3}
                            onChange={onChange}
                            placeholder="Prazo"
                            className={fieldState?.error ? "isInvalid" : ""}
                          />
                        );
                      }}
                    />
                    <InvalidFeedback
                      message="Este campo é obrigatório"
                      condition={
                        errors?.proposalValidityPeriod?.type === "required"
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-close"
                data-testid="btn-close"
                className="form-button red-bkg"
                disabled={isSubmitting}
                onClick={onRequestClose}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-submit"
                data-testid="btn-submit"
                disabled={isSubmitting}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Confirmar{" "}
                {isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
