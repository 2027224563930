import { ParsePanoramaAngularService } from "../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../advTable/data/services/transformPanoramaAngularService";
import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { SearchClassificationAccountService } from "../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationAssessmentService } from "../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../core/data/services/searchCompetencyService";
import { SearchCustomerProviderByCorporationNameService } from "../../../core/data/services/searchCustomerProviderByCorporationNameService";
import { SearchCustomerProviderService } from "../../../core/data/services/searchCustomerProviderService";
import { SearchMacroCategoriesService } from "../../../core/data/services/searchMacroCategoriesService";
import { SearchPaymentAccountService } from "../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../core/data/services/searchProjectService";
import { SearchUserService } from "../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../core/data/services/setServerSideService";
import { ISearchClassificationAccountContract } from "../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationAssessmentContract } from "../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCustomerProviderByCorporationNameContract } from "../../../core/domain/contracts/searchCustomerProviderByCorporationNameContract";
import { ISearchCustomerProviderContract } from "../../../core/domain/contracts/searchCustomerProviderContract";
import { ISearchMacroCategoriesContract } from "../../../core/domain/contracts/searchMacroCategoriesContract";
import { ISearchPaymentAccountContract } from "../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../core/domain/contracts/searchProjectContract";
import { ISearchUserContract } from "../../../core/domain/contracts/searchUserContract";
import { GetCostCenterTransactionUseCase } from "../../../transactions/costCenterTransactions/domain/usecases/getCostCenterTransactionsUseCase";
import { ApproveService } from "../data/services/approveService";
import { BulkApproveService } from "../data/services/bulkApproveService";
import { BulkDisapproveService } from "../data/services/bulkDisapproveService";
import { BulkUndoConfirmService } from "../data/services/bulkUndoConfirmService";
import { DeleteCostCenterReportPanoramaService } from "../data/services/deleteCostCenterReportPanoramaService";
import { DisapproveService } from "../data/services/disapproveService";
import { ExportCostCenterReportManagerService } from "../data/services/exportCostCenterReportManagerService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetCostCenterReportService } from "../data/services/getcostCenterReportService";
import { GetCostCenterTransactionsService } from "../data/services/getCostCenterTransactionsService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { GetStoredCostCenterReportPanoramaIdService } from "../data/services/getStoredCostCenterReportPanoramaIdService";
import { ListCostCenterReportManagerService } from "../data/services/listCostCenterReportManagerService";
import { ListCostCenterReportPanoramasService } from "../data/services/listCostCenterReportPanoramaService";
import { ListCostCentersManagerService } from "../data/services/listCostCentersManagerService";
import { SaveCostCenterReportPanoramaService } from "../data/services/saveCostCenterReportPanoramaService";
import { SearchManagerCostCenterService } from "../data/services/searchManagerCostCenterService";
import { StoreCostCenterReportPanoamaIdService } from "../data/services/storeCostCenterReportPanoamaIdService";
import { ToggleCostCenterReportService } from "../data/services/toggleCostCenterReportService";
import { UndoConfirmService } from "../data/services/undoConfirmService";
import { SearchManagerCostCenterContract } from "../domain/contracts/searchManagerCostCenterContract";
import { AdvGeneratePayloadExportUseCase } from "../domain/usecases/advGeneratePayloadExportUseCase";
import {
  ApproveUseCase,
  IApproveUseCase,
} from "../domain/usecases/approveUseCase";
import {
  BulkApproveUseCase,
  IBulkApproveUseCase,
} from "../domain/usecases/bulkApproveUseCase";
import {
  BulkDisapproveUseCase,
  IBulkDisapproveUseCase,
} from "../domain/usecases/bulkDisapproveUseCase";
import {
  BulkUndoConfirmUseCase,
  IBulkUndoConfirmUseCase,
} from "../domain/usecases/bulkUndoConfirmUseCase";
import {
  DeleteCostCenterReportPanoramaUseCase,
  IDeleteCostCenterReportPanoramaUseCase,
} from "../domain/usecases/deleteCostCenterReportPanoramaUseCase";
import {
  DisapproveUseCase,
  IDisapproveUseCase,
} from "../domain/usecases/disapproveUseCase";
import {
  ExportCostCenterReportUseCase,
  IExportCostCenterReportUseCase,
} from "../domain/usecases/exportCostCenterReportUseCase";
import {
  GetAttachmentsUseCase,
  IGetAttachmentsUseCase,
} from "../domain/usecases/getAttachmentsUseCase";
import {
  GetCostCenterReportUseCase,
  IGetCostCenterReportUseCase,
} from "../domain/usecases/getCostCenterReportUseCase";
import { IGetCostCenterTransactionUseCase } from "../domain/usecases/getCostCenterTransactionUseCase";
import {
  GetStorageFilebyIdUseCase,
  IGetStorageFilebyIdUseCase,
} from "../domain/usecases/getStorageFilebyIdUseCase";
import {
  GetStoredCostCenterReportPanoramaIdUseCase,
  IGetStoredCostCenterReportPanoramaIdUseCase,
} from "../domain/usecases/getStoredCostCenterReportPanoramaIdUseCase";
import {
  IListCostCenterReportPanoramasUseCase,
  ListCostCenterReportPanoramasUseCase,
} from "../domain/usecases/listCostCenterReportPanoramasUseCase";
import {
  IListCostCenterReportUseCase,
  ListCostCenterReportUseCase,
} from "../domain/usecases/listCostCenterReportUseCase";
import {
  IListCostCentersUseCase,
  ListCostCentersUseCase,
} from "../domain/usecases/listCostCenterUseCase";
import {
  ISaveCostCenterReportPanoramaUseCase,
  SaveCostCenterReportPanoramaUseCase,
} from "../domain/usecases/saveCostCenterReportPanoramaUseCase";
import {
  IStoreCostCenterReportPanoamaIdUseCase,
  StoreCostCenterReportPanoamaIdUseCase,
} from "../domain/usecases/storeCostCenterReportPanoamaIdUseCase";
import {
  IToggleCostCenterReportUseCase,
  ToggleCostCenterReportUseCase,
} from "../domain/usecases/toggleCostCenterReportUseCase";
import {
  IUndoConfirmUseCase,
  UndoConfirmUseCase,
} from "../domain/usecases/undoConfirmUseCase";

export type MakeCostCenterReportManager = IGetCostCenterReportUseCase &
  IListCostCenterReportUseCase &
  IToggleCostCenterReportUseCase &
  IListCostCentersUseCase &
  IExportCostCenterReportUseCase &
  IListCostCenterReportPanoramasUseCase &
  IDeleteCostCenterReportPanoramaUseCase &
  ISaveCostCenterReportPanoramaUseCase &
  IGetStoredCostCenterReportPanoramaIdUseCase &
  IApproveUseCase &
  IDisapproveUseCase &
  IUndoConfirmUseCase &
  IGetAttachmentsUseCase &
  IGetStorageFilebyIdUseCase &
  IBulkApproveUseCase &
  IBulkDisapproveUseCase &
  IBulkUndoConfirmUseCase &
  IGetCostCenterTransactionUseCase &
  IStoreCostCenterReportPanoamaIdUseCase &
  ISearchClassificationAccountContract &
  ISearchClassificationAssessmentContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  SearchManagerCostCenterContract &
  ISearchCustomerProviderByCorporationNameContract &
  ISearchCustomerProviderContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract &
  ISearchMacroCategoriesContract;

export function makeCostCenterReportManager(
  api: IApiService,
  authApi: IApiService,
): MakeCostCenterReportManager {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSide = new SetServerSideService(getUserLocalService, api);

  const getCostCenterReportService = new GetCostCenterReportService(
    getUserLocalService,
    api,
  );
  const getCostCenterReportUseCase = new GetCostCenterReportUseCase(
    getCostCenterReportService,
  );

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listCostCenterReportService = new ListCostCenterReportManagerService(
    setServerSideService,
    getUserLocalService,
    api,
  );
  const listCostCenterReportUseCase = new ListCostCenterReportUseCase(
    listCostCenterReportService,
  );

  const toggleCostCenterReportService = new ToggleCostCenterReportService(
    getUserLocalService,
    api,
  );
  const toggleCostCenterReportUseCase = new ToggleCostCenterReportUseCase(
    toggleCostCenterReportService,
  );

  const listCostCentersSvc = new ListCostCentersManagerService(
    getUserLocalService,
    api,
  );
  const listCostCentersUseCase = new ListCostCentersUseCase(listCostCentersSvc);

  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const exportCostCenterReportSvc = new ExportCostCenterReportManagerService(
    advGeneratePayloadExportUseCase,
    setServerSideService,
    getUserLocalService,
    api,
  );
  const exportCostCenterReportUseCase = new ExportCostCenterReportUseCase(
    exportCostCenterReportSvc,
  );

  const parsePanoramaAngularService = new ParsePanoramaAngularService();
  const listCostCenterReportPanoramasSvc =
    new ListCostCenterReportPanoramasService(
      parsePanoramaAngularService,
      getUserLocalService,
      api,
    );
  const listCostCenterReportPanoramasUseCase =
    new ListCostCenterReportPanoramasUseCase(listCostCenterReportPanoramasSvc);

  const deleteCostCenterReportPanoramaSvc =
    new DeleteCostCenterReportPanoramaService(getUserLocalService, api);
  const deleteCostCenterReportPanoramaUseCase =
    new DeleteCostCenterReportPanoramaUseCase(
      deleteCostCenterReportPanoramaSvc,
    );

  const transformPanoramaAngularService = new TransformPanoramaAngularService();
  const saveCostCenterReportPanoramaSvc =
    new SaveCostCenterReportPanoramaService(
      transformPanoramaAngularService,
      getUserLocalService,
      api,
    );
  const saveCostCenterReportPanoramaUseCase =
    new SaveCostCenterReportPanoramaUseCase(saveCostCenterReportPanoramaSvc);

  const getStoredCostCenterReportPanoramaIdSvc =
    new GetStoredCostCenterReportPanoramaIdService();
  const getStoredCostCenterReportPanoramaIdUseCase =
    new GetStoredCostCenterReportPanoramaIdUseCase(
      getStoredCostCenterReportPanoramaIdSvc,
    );

  const storeCostCenterReportPanoamaIdSvc =
    new StoreCostCenterReportPanoamaIdService();
  const storeCostCenterReportPanoamaIdUseCase =
    new StoreCostCenterReportPanoamaIdUseCase(
      storeCostCenterReportPanoamaIdSvc,
    );

  const approveSvc = new ApproveService(getUserLocalService, api);
  const approveUseCase = new ApproveUseCase(approveSvc);

  const disapproveSvc = new DisapproveService(getUserLocalService, api);
  const disapproveUseCase = new DisapproveUseCase(disapproveSvc);

  const undoConfirmSvc = new UndoConfirmService(getUserLocalService, api);
  const undoConfirmUseCase = new UndoConfirmUseCase(undoConfirmSvc);

  const getAttachmentsSvc = new GetAttachmentsService(getUserLocalService, api);
  const getAttachmentsUseCase = new GetAttachmentsUseCase(getAttachmentsSvc);

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );
  const getStorageFilebyIdUseCase = new GetStorageFilebyIdUseCase(
    getStorageFilebyIdService,
  );

  const bulkUndoConfirmSvc = new BulkUndoConfirmService(
    getUserLocalService,
    api,
  );
  const bulkUndoConfirmUseCase = new BulkUndoConfirmUseCase(bulkUndoConfirmSvc);

  const bulkDisapproveSvc = new BulkDisapproveService(getUserLocalService, api);
  const bulkDisapproveUseCase = new BulkDisapproveUseCase(bulkDisapproveSvc);

  const bulkApproveSvc = new BulkApproveService(getUserLocalService, api);
  const bulkApproveUseCase = new BulkApproveUseCase(bulkApproveSvc);

  const getCostCenterTransactionSvc = new GetCostCenterTransactionsService(
    getUserLocalService,
    api,
  );

  const getCostCenterTransactionUseCase = new GetCostCenterTransactionUseCase(
    getCostCenterTransactionSvc,
  );

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchManagerCostCenterService = new SearchManagerCostCenterService(
    getUserLocalService,
    api,
  );

  const searchCustomerProviderByCorporationNameService =
    new SearchCustomerProviderByCorporationNameService(
      getUserLocalService,
      api,
    );

  const searchCustomerProviderService = new SearchCustomerProviderService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  const searchMacroCategoriesService = new SearchMacroCategoriesService(
    getUserLocalService,
    api,
    setServerSide,
  );

  return {
    getCostCenterReport(CostCenterReportId) {
      return getCostCenterReportUseCase.getCostCenterReport(CostCenterReportId);
    },
    listCostCenterReport(companyGroupId, payload, filters) {
      return listCostCenterReportUseCase.listCostCenterReport(
        companyGroupId,
        payload,
        filters,
      );
    },
    toggleCostCenterReport(CostCenterReportId: string) {
      return toggleCostCenterReportUseCase.toggleCostCenterReport(
        CostCenterReportId,
      );
    },
    listCostCenters(companyGroupId, actives?) {
      return listCostCentersUseCase.listCostCenters(companyGroupId, actives);
    },
    exportCostCenterReportUseCase(
      companyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
    ) {
      return exportCostCenterReportUseCase.exportCostCenterReportUseCase(
        companyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColumnNames,
        filters,
      );
    },
    listCostCenterReportPanoramas(userId, columns) {
      return listCostCenterReportPanoramasUseCase.listCostCenterReportPanoramas(
        userId,
        columns,
      );
    },
    deleteCostCenterReportPanorama(panoramaId) {
      return deleteCostCenterReportPanoramaUseCase.deleteCostCenterReportPanorama(
        panoramaId,
      );
    },
    saveCostCenterReportPanorama(columns, panorama) {
      return saveCostCenterReportPanoramaUseCase.saveCostCenterReportPanorama(
        columns,
        panorama,
      );
    },
    getStoredCostCenterReportPanoramaId() {
      return getStoredCostCenterReportPanoramaIdUseCase.getStoredCostCenterReportPanoramaId();
    },
    storeCostCenterReportPanoamaId(panoramaId) {
      return storeCostCenterReportPanoamaIdUseCase.storeCostCenterReportPanoamaId(
        panoramaId,
      );
    },
    approve(costCenterReportId) {
      return approveUseCase.approve(costCenterReportId);
    },
    disapprove(costCenterReportI) {
      return disapproveUseCase.disapprove(costCenterReportI);
    },
    undoConfirm(costCenterReportI) {
      return undoConfirmUseCase.undoConfirm(costCenterReportI);
    },
    getAttachments(accountId) {
      return getAttachmentsUseCase.getAttachments(accountId);
    },
    getStorageFilebyId(storageFileId, origin) {
      return getStorageFilebyIdUseCase.getStorageFilebyId(
        storageFileId,
        origin,
      );
    },
    bulkApprove(costCenterReportIds) {
      return bulkApproveUseCase.bulkApprove(costCenterReportIds);
    },
    bulkDisapprove(costCenterReportIds) {
      return bulkDisapproveUseCase.bulkDisapprove(costCenterReportIds);
    },
    bulkUndoConfirm(costCenterReportIds) {
      return bulkUndoConfirmUseCase.bulkUndoConfirm(costCenterReportIds);
    },
    getCostCenterTransaction(costCenterTransactionId) {
      return getCostCenterTransactionUseCase.getCostCenterTransaction(
        costCenterTransactionId,
      );
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchManagerCostCenter(params) {
      return searchManagerCostCenterService.searchManagerCostCenter(params);
    },
    searchCustomerProviderByCorporationName(params) {
      return searchCustomerProviderByCorporationNameService.searchCustomerProviderByCorporationName(
        params,
      );
    },
    searchCustomerProvider(params) {
      return searchCustomerProviderService.searchCustomerProvider(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchMacroCategories(params) {
      return searchMacroCategoriesService.searchMacroCategories(params);
    },
  };
}
