import { ReactNode, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { Button } from "./styles";

interface AsyncButtonProps {
  children?: ReactNode;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  className?: string;
  "data-tip"?: string;
  "data-testid"?: string;
  onClick?: (id: string) => unknown | ((id: string) => Promise<unknown>);
}

/**
 * Botao assícrono. Exibe um indicador de
 * carregando do tipo spinner quando clicado.
 *
 * @param onClick Callback com a implementação do que deve acontecer quando este
 * botão for clicado. Deve retornar uma promise, quando a promise resolve ou
 * falha o spinner é removido do botao.
 */
export function AsyncButton({
  children = "",
  id = "",
  type = "button",
  disabled = false,
  className = "",
  "data-tip": dataTip = "",
  "data-testid": dataTestId = "",
  onClick,
}: AsyncButtonProps) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      id={id}
      type={type}
      disabled={disabled || loading}
      className={className}
      data-tip={dataTip}
      data-testid={dataTestId}
      onClick={async () => {
        setLoading(true);

        try {
          await onClick?.(id);
        } finally {
          setLoading(false);
        }
      }}
    >
      {loading ? <FaSpinner className="pulse" /> : children}
    </Button>
  );
}
