import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICheckDateForPaymentContract,
  ICheckDateForPaymentParams,
} from "../../domain/contracts/checkDateForPaymentContract";

interface ICheckDateForPaymentResponse {
  code: null;
  message: string;
  success: boolean;
}

export class CheckDateForPaymentService
  implements ICheckDateForPaymentContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkDateForPayment({
    payUntil,
    companyGroupId,
  }: ICheckDateForPaymentParams) {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups/${companyGroupId}/PaymentRequests/CheckDateForPayment`;

    const datePayUntil = parse(payUntil, "dd/MM/yyyy", new Date());

    const payload = {
      payUntil: format(datePayUntil, "yyyy-MM-dd"),
    };

    const response = await this.api.post<ICheckDateForPaymentResponse>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (!response.success) {
      return Promise.reject(response.message);
    }

    return Promise.resolve();
  }
}
