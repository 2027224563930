import { IProjectTransactionEntity } from "../entities/projectTransactionEntity";

export interface IToggleProjectTransactionService {
  toggleProjectTransaction(
    projectTransactionId: string,
  ): Promise<IProjectTransactionEntity>;
}

export interface IToggleProjectTransactionUseCase {
  toggleProjectTransaction(
    projectTransactionId: string,
  ): Promise<IProjectTransactionEntity>;
}

export class ToggleProjectTransactionUseCase
  implements IToggleProjectTransactionUseCase
{
  constructor(
    private toggleProjectTransactionService: IToggleProjectTransactionService,
  ) {}

  toggleProjectTransaction(
    projectTransactionId: string,
  ): Promise<IProjectTransactionEntity> {
    return this.toggleProjectTransactionService.toggleProjectTransaction(
      projectTransactionId,
    );
  }
}
