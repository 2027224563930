import styled from "styled-components";

export const ContextMenuItem = styled.div`
  .p-menuitem-text {
    display: inline-flex;
    justify-content: center;
  }

  svg {
    fill: var(--soul-color02);
    margin-right: 0.5rem;
  }

  &.terminate {
    svg {
      fill: var(--soul-green-button);
    }
  }

  &.remove-termination {
    svg {
      fill: var(--soul-yellow-button);
    }
  }

  &.cancel {
    svg {
      fill: var(--soul-red-button);
    }
  }

  &.disabled {
    opacity: 0.5;
  }
`;
