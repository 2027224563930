import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { IApiError } from "../../../../../../core/data/services/apiService";
import { ImportFileModal } from "../../../../../../core/presentation/components/ImportFileModal";
import { useImportFileErrorHandlers } from "../../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { IErrorResponseEntity } from "../../../../../../simpleTable/domain/entities/responseEntity";
import { useAccountsReceivablePage } from "../../hooks/useAccountsReceivablePage";

export function AccountsReceivableImportModal() {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { useAccountsReceivable, reload, openGenerateInvoiceModal } =
    useAccountsReceivablePage();
  const importFileErrorHandler = useImportFileErrorHandlers();
  const dialog = useSoulDialog();
  const { getAccountReceivableImportFileTemplate, importAccountsReceivable } =
    useAccountsReceivable;

  const [importFiles, setImportFiles] = useState<File[]>([]);

  const isOpen = hash.includes("#import");

  /** Fecha a modal de importacao */
  const closeModal = () => {
    navigate(SoulRoutes.ACCOUNTS_RECEIVABLE.path);
  };

  /**
   * Realiza a importacao do arquivo excel de contas a receber. Lida com
   * o retorno da api, apesentando mensagem de erro ou sucesso de acordo
   */
  const importAccountsReceivableFile = async () => {
    try {
      const importedAccounts = await importAccountsReceivable(importFiles[0]);

      await dialog.fire({
        title: "Feito!",
        icon: "success",
        confirmButtonText: "OK",
        text: "Lançamentos realizados com sucesso.",
      });

      closeModal();
      reload();

      if (!importedAccounts.length) {
        return;
      }

      openGenerateInvoiceModal(importedAccounts);
    } catch (error) {
      const apiError = error as IApiError<IErrorResponseEntity>;
      const errorResponse = apiError.response;

      importFileErrorHandler(errorResponse);
    } finally {
      dialog.close();
      setImportFiles([]);
    }
  };

  /**
   * Lida com o evento de submit da modal de imiportacao. Responsavel
   * por apresentar caixas de dialogo com informacoes do processo ao
   * usuario e chamaar a funcao que realiza o upload
   */
  const handleSubmitFiles = () => {
    dialog.fire({
      icon: "warning",
      title: "Atenção!",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Ok",
      html: (
        <div>
          <div>
            Se a <b>planilha</b> informada for <b>válida</b>, os lançamentos de
            Contas a Receber serão realizados e só poderão ser alterados{" "}
            <b>individualmente</b>
            de forma <b>manual</b>.
          </div>
          <br />
          <div>Deseja prosseguir?</div>
        </div>
      ),
      preConfirm() {
        dialog.fire({
          title: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          text: "Realizando as importações de lançamentos",
          didOpen: () => {
            dialog.showLoading();
          },
        });

        importAccountsReceivableFile();
      },
    });
  };

  /**
   * Lida com o evento de click no botao "fechar"
   * da modal de importacao de contas a receber
   */
  const handleRequestClose = () => {
    setImportFiles([]);
    closeModal();
  };

  /**
   * Lida como evento de click no botao "baixar modelo". Realiza a requisicao
   * para o template de importacao de contas a receber. Em caso de sucesso,
   * abre uma nova aba e realiza o download do arquivo
   */
  const handleDownloadTemplate = async () => {
    await getAccountReceivableImportFileTemplate();
  };

  return (
    <ImportFileModal
      files={importFiles}
      setFiles={setImportFiles}
      isOpen={isOpen}
      filesHandler={handleSubmitFiles}
      templateHandler={handleDownloadTemplate}
      modalTitle="Importar Contas a Receber"
      onRequestClose={handleRequestClose}
    />
  );
}
