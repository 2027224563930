import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";

export interface ISearchPaymentAccountsService {
  searchPaymentAccounts(
    companyGroupId: string,
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export interface ISearchPaymentAccountsUseCase {
  searchPaymentAccounts(
    companyGroupId: string,
    search?: string,
    fetchMinLength?: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export class SearchPaymentAccountsUseCase
  implements ISearchPaymentAccountsUseCase
{
  constructor(
    private searchPaymentAccountsService: ISearchPaymentAccountsService,
  ) {}

  searchPaymentAccounts(
    companyGroupId: string,
    search = "",
    fetchMinLength = 10,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    return this.searchPaymentAccountsService.searchPaymentAccounts(
      companyGroupId,
      search,
      fetchMinLength,
    );
  }
}
