import { IListCompaniesPaymentAccountsContract } from "../../domain/contracts/listCompaniesPaymentAccountsContract";
import { IListPaymentAccountsByCompanyContract } from "../../domain/contracts/listPaymentAccountsByCompanyContract";
import { ICompanyEntity } from "../../domain/entitites/companyEntity";
import { IPaymentAccountEntity } from "../../domain/entitites/paymentAccountEntity";

export class ListCompaniesPaymentAccountsService
  implements IListCompaniesPaymentAccountsContract
{
  constructor(
    private listPaymentAccountsByCompanyService: IListPaymentAccountsByCompanyContract,
  ) {}

  async listCompaniesPaymentAccounts(
    companyGroupId: string,
    companies: ICompanyEntity[],
  ): Promise<IPaymentAccountEntity[]> {
    const requests = companies.map(company => {
      const companyId = company.id;

      return this.listPaymentAccountsByCompanyService.listPaymentAccountsByCompany(
        companyGroupId,
        companyId,
      );
    });

    const response = await Promise.all(requests);

    const mappedPaymentAccounts = response.map((list, index) => {
      return list.map(paymentAccount => {
        return {
          ...paymentAccount,
          companyName: companies[index].name,
        };
      });
    });

    let paymentAccounts = mappedPaymentAccounts.flat();

    paymentAccounts = paymentAccounts
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      })
      .map((paymentAccount, index) => {
        return {
          ...paymentAccount,
          key: `${paymentAccount.id}-${index}`,
        };
      });

    return paymentAccounts;
  }
}
