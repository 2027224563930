import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IGetPecegePayBillingCompaniesContract } from "../domain/contracts/getPecegePayBillingCompaniesContract";
import { GetPecegePayBillingCompaniesService } from "../data/services/getPecegePayBillingCompaniesService";
import { IGetPecegePayFeeReportContract } from "../domain/contracts/getPecegePayFeeReportContract";
import { GetPecegePayFeeReportService } from "../data/services/getPecegePayFeeReportService";

export type MakePecegePayFeeReport = IGetPecegePayBillingCompaniesContract &
  IGetPecegePayFeeReportContract;

export function makePecegePayFeeReport(
  api: IApiService,
): MakePecegePayFeeReport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getPecegePayBillingCompaniesService =
    new GetPecegePayBillingCompaniesService(getUserLocalService, api);

  const getPecegePayFeeReportService = new GetPecegePayFeeReportService(
    getUserLocalService,
    api,
  );

  return {
    getPecegePayBillingCompanies() {
      return getPecegePayBillingCompaniesService.getPecegePayBillingCompanies();
    },
    getPecegePayFeeReport(payload) {
      return getPecegePayFeeReportService.getPecegePayFeeReport(payload);
    },
  };
}
