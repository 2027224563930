import styled from "styled-components";
import { EBalanceVariant } from ".";

interface ContainerProps {
  value: number | undefined;
  variant?: EBalanceVariant;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: end;
  }

  > div {
    --green: #2dce89;
    --red: #f5365c;
    --grey: #e9ecef;

    padding: 0.25rem 0.5rem;
    border: 0.0625rem solid #cad1d7;
    font-size: 0.75rem;
    line-height: 1.0333rem;
    border-radius: 0.25rem;
    width: 100%;
    text-align: right;
    white-space: nowrap;

    color: ${({ value, variant }) => {
      if (variant === EBalanceVariant.Red) {
        return "var(--red)";
      }
      if (variant === EBalanceVariant.Green) {
        return "var(--green)";
      }

      if (value === undefined || !value) {
        return "var(--text-muted)";
      }

      return "white";
    }};

    background: ${({ value, variant }) => {
      if (
        variant === EBalanceVariant.Red ||
        variant === EBalanceVariant.Green
      ) {
        return "white";
      }
      if (value === undefined || !value) {
        return "var(--grey)";
      }

      return value > 0 ? "var(--green)" : "var(--red)";
    }};

    @media (min-width: 992px) {
      width: auto;
    }

    > span {
      font-weight: 900;
    }
  }
`;
