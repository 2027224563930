export interface IConciliationResultEntity {
  entries: number;
  conciliated: number;
  missing: number;
}

export class ConciliationResultEntity implements IConciliationResultEntity {
  entries = 0;
  conciliated = 0;
  missing = 0;

  constructor(init?: Partial<IConciliationResultEntity>) {
    Object.assign(this, init);
  }
}
