import styled from "styled-components";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";

const Container = styled.div`
  .dialog-content {
    margin-bottom: 1rem;
  }
`;

interface IResultSuccessDialogProps {
  onNotButtonClick(): void;
  onSameFiltersButtonClick(): void;
  onOtherFiltersButtonClick(): void;
}

export function ResultSuccessDialog({
  onNotButtonClick,
  onSameFiltersButtonClick,
  onOtherFiltersButtonClick,
}: IResultSuccessDialogProps) {
  const dialog = useSoulDialog();

  const handleNotButtonClick = () => {
    onNotButtonClick();
    dialog.close();
  };

  const handleSameFiltersButtonClick = () => {
    onSameFiltersButtonClick();
    dialog.close();
  };

  const handleOtherFiltersButtonClick = () => {
    onOtherFiltersButtonClick();
    dialog.close();
  };

  return (
    <Container>
      <div className="dialog-content">
        Deseja continuar <b>conciliando</b> contas?
      </div>
      <div>
        <button
          className="form-button red-bkg"
          onClick={handleNotButtonClick}
          type="button"
        >
          Não
        </button>
        <button
          className="form-button green-bkg"
          onClick={handleSameFiltersButtonClick}
          type="button"
        >
          Sim, mesmos filtros
        </button>
        <button
          className="form-button green-bkg"
          onClick={handleOtherFiltersButtonClick}
          type="button"
        >
          Sim, outros filtros
        </button>
      </div>
    </Container>
  );
}
