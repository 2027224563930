import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { MouseEvent, useMemo } from "react";
import { FaCaretDown, FaDatabase, FaFileExcel } from "react-icons/fa";
import { RiFilterOffFill } from "react-icons/ri";
import { SoulDropdown } from "../../../../../core/presentation/components/SoulDropdown";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import { IFiltersValue } from "../../../domain/usecases/listCostCenterReportUseCase";
import { MakeCostCenterReport } from "../../../main/makeCostCenterReport";
import { useToolbar } from "../../hooks/useToolbar";
import { CostCenterReportPanoramaFormModal } from "../CostCenterReportPanoramaFormModal";
import {
  ContainerForm,
  FilterToolsContainer,
  PageToolsContainer,
} from "./styles";
import { IPFSEventEntity } from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { Balance } from "../../../../../core/presentation/components/Balance";

export interface IUseToolbarParams {
  isGridFiltered: boolean;
  filters: IFiltersValue;
  useCostCenterReport: MakeCostCenterReport;
  onFiltersChange(value: IFiltersValue): void;
  onPanoramaChange(panorama: IPanoramaEntity, shouldUpdate?: boolean): void;
}

interface ToolbarProps {
  filters: IFiltersValue;
  pfsEvent: IPFSEventEntity;
  disableSheetExportation?: boolean;
  showClearButton?: boolean;
  balance: number | undefined;
  orderedColumnNames: string[];
  selectedColumns: IAdvTableColumn[];
  useCostCenterReport: MakeCostCenterReport;
  onClearButtonClick(event: MouseEvent<HTMLButtonElement>): void;
  onPanoramaChange(panorama: IPanoramaEntity, shouldUpdate?: boolean): void;
  onFiltersChange(value: IFiltersValue): void;
  onExportButtonClick(): void;
}

const EMPTY = [
  {
    rawValue: "EMPTY",
    label: "Nenhum registro encontrado",
  },
];

export function Toolbar({
  filters,
  balance,
  pfsEvent,
  disableSheetExportation,
  selectedColumns,
  orderedColumnNames,
  useCostCenterReport,
  showClearButton = false,
  onExportButtonClick,
  onClearButtonClick,
  onPanoramaChange,
  onFiltersChange,
}: ToolbarProps) {
  const isGridFiltered = useMemo(() => {
    const keys = Object.keys(pfsEvent.filters);

    if (keys.length === 1 && keys.includes("global")) {
      return false;
    }

    return !!keys.length;
  }, [pfsEvent.filters]);

  const {
    dates,
    loading,
    isEndDateValid,
    panoramaOptions,
    selectedPanorama,
    isStartDateValid,
    costCenterOptions,
    selectedCostCenter,
    isPanoramaModalOpen,
    handleSavePanoramaButtonOnClick,
    handlePanoramaDropdownOnChange,
    setIsPanoramaModalOpen,
    renderPanoramaOption,
    handleOnPanoramaSave,
    handleOnFilter,
    handleOnChange,
    handleSubmit,
    setDates,
  } = useToolbar({
    isGridFiltered,
    filters,
    useCostCenterReport,
    onPanoramaChange,
    onFiltersChange,
  });

  return (
    <ContainerForm onSubmit={handleSubmit}>
      <div className="page-tools">
        <PageToolsContainer>
          <div>
            <Dropdown
              id="sel-panorama"
              data-testid="sel-panorama"
              optionLabel="name"
              options={panoramaOptions}
              placeholder="Padrão do sistema"
              filterPlaceholder="Pesquise um panorama"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              itemTemplate={renderPanoramaOption}
              panelStyle={{ width: "230px" }}
              filter
              value={selectedPanorama}
              onChange={handlePanoramaDropdownOnChange}
            />
            {loading.panoramas && <i className="pi pi-spin pi-spinner" />}
          </div>
          <div>
            <SoulDropdown
              toggler={
                <button
                  id="btn-options"
                  data-testid="btn-options"
                  type="button"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                id="btn-pano-save"
                data-testid="btn-pano-save"
                type="button"
                className="dropdown-item"
                onClick={handleSavePanoramaButtonOnClick}
              >
                <FaDatabase />
                <span title="Panorama">Salvar Panorama</span>
              </button>
              <button
                id="btn-export"
                data-testid="btn-export"
                type="button"
                className="dropdown-item"
                onClick={onExportButtonClick}
                disabled={disableSheetExportation}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para Excel</span>
              </button>
            </SoulDropdown>
          </div>
        </PageToolsContainer>
      </div>
      <div className="filter-tools">
        <FilterToolsContainer>
          {showClearButton && (
            <div className="clr-filter-btn-wrapper">
              <button
                id="btn-clear"
                data-testid="btn-clear"
                className="default-button clear-filter-button"
                type="button"
                onClick={onClearButtonClick}
              >
                <RiFilterOffFill />
                Limpar filtros
              </button>
            </div>
          )}
          <div className="cc-filter-dropdown-wrapper">
            <Dropdown
              filter
              showClear
              showFilterClear
              dataKey="rawValue"
              optionLabel="label"
              id="sel-cost-center"
              onChange={handleOnChange}
              onFilter={handleOnFilter}
              value={selectedCostCenter}
              data-testid="sel-cost-center"
              placeholder="Centro de Custo"
              filterPlaceholder="Centro de Custo"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              options={costCenterOptions?.length ? costCenterOptions : EMPTY}
            />
            {loading.costCenters && <i className="pi pi-spin pi-spinner" />}
          </div>
          <div className="start-dt-input-wrapper">
            <InputMask
              id="txt-start-date"
              data-testid="txt-start-date"
              value={dates.startDate}
              mask="99/99/9999"
              placeholder="Data inicial"
              className={!isStartDateValid ? "isInvalid" : ""}
              onChange={({ value }) => setDates({ ...dates, startDate: value })}
            />
          </div>
          <div className="end-dt-input-wrapper">
            <InputMask
              id="txt-end-date"
              data-testid="txt-end-date"
              value={dates.endDate}
              mask="99/99/9999"
              placeholder="Data final"
              className={!isEndDateValid ? "isInvalid" : ""}
              onChange={({ value }) => setDates({ ...dates, endDate: value })}
            />
          </div>
          <div className="show-btn-wrapper">
            <button
              id="btn-show"
              data-testid="btn-show"
              type="submit"
              className="default-button filter-button"
              disabled={!isStartDateValid || !isEndDateValid}
            >
              Mostrar
            </button>
          </div>
        </FilterToolsContainer>
      </div>
      <div className="balance-tool">
        <Balance value={balance} label="Saldo" />
      </div>
      {isPanoramaModalOpen && (
        <CostCenterReportPanoramaFormModal
          pfsEvent={pfsEvent}
          isOpen={isPanoramaModalOpen}
          selectedColumns={selectedColumns}
          orderedColumnNames={orderedColumnNames}
          useCostCenterReport={useCostCenterReport}
          onPanoramaSave={handleOnPanoramaSave}
          onRequestClose={() => {
            setIsPanoramaModalOpen(false);
          }}
        />
      )}
    </ContainerForm>
  );
}
