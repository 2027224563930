import styled from "styled-components";

interface ContainerProps {
  prefix: string;
  isEmpty: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: inline-flex;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;

  background-color: #fff;
  border-color: var(--input-border-color);

  height: calc(1.5em + 0.44rem);

  padding: 0rem 0.5rem 0rem 0.5rem !important;

  :focus-within {
    border-color: rgba(50, 151, 211, 0.25);
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  &.disabled {
    opacity: 1;
    cursor: not-allowed;
    background-color: #e9ecef;
  }

  &.isInvalid {
    border-color: var(--invalid);

    :focus-within {
      box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
  }

  input {
    border: none;
    outline: none;
    width: inherit;
    appearance: none;

    font-size: 0.875rem;
    color: #495057 !important;

    border-radius: 0.25rem;

    padding: 0.25rem 0rem 0.25rem 0rem !important;

    ::placeholder {
      color: #d1d1d9;
    }
  }

  ::before {
    font-size: 0.875rem;
    margin-right: 0.25rem;
    content: "${props => props.prefix}";
    padding: 0.25rem 0rem 0.25rem 0rem !important;
    color: ${props => (props.isEmpty ? "#d1d1d9" : "#495057")};
  }
`;
