import { ChangeEvent, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import {
  IDebtImportForm,
  IFormComponentProps,
} from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";
import { useUserLocal } from "../../../../../../../core/presentation/hooks/useUserLocal";
import { EUserProfile } from "../../../../../../../core/domain/entities/userEntity";

interface IDocumentNumberFieldProps extends IFormComponentProps {
  accountAlreadyPaid: boolean;
  accountAlreadyExists: boolean;
  accountAlreadyExistsForAnotherUser: boolean;
}

export function DocumentNumberField({
  formIndex,
  accountAlreadyPaid,
  accountAlreadyExists,
  accountAlreadyExistsForAnotherUser,
}: IDocumentNumberFieldProps) {
  const {
    user: { profile },
  } = useUserLocal();

  const asyncDebounce = useDebounceTimeAsync();

  const fieldKey = `imports.${formIndex}.documentNumber` as const;

  const {
    getValues,
    formState: { errors },
  } = useFormContext<IDebtImportForm>();

  const { state, validateDocumentNumber, handleInputClassName } =
    useDebtImportPage();

  const { formsValidatingDocumentNumber } = state;

  const currentValue = useRef<string>(getValues(fieldKey));

  let shouldBeDisabled = accountAlreadyExists && !accountAlreadyPaid;

  if (profile === EUserProfile.internal) {
    shouldBeDisabled =
      accountAlreadyExists &&
      !accountAlreadyPaid &&
      !accountAlreadyExistsForAnotherUser;
  }

  const isLoading = formsValidatingDocumentNumber.includes(formIndex);

  if (shouldBeDisabled) {
    const disabledTip = `Essa conta já existe e será alterada.<br />Portanto, não é possível<br />trocar o valor deste campo.`;

    return (
      <FieldWrapper>
        <input
          readOnly
          data-effect="solid"
          data-tip={disabledTip}
          className="disabled-field"
          value={getValues(fieldKey)}
          id={`txt-documentNumber-${formIndex}`}
          data-testid={`txt-documentNumber-${formIndex}`}
        />
      </FieldWrapper>
    );
  }

  const validateExistingDocumentNumberOnServer = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    await asyncDebounce(500);

    const { value } = event.target;

    if (currentValue.current !== value) {
      currentValue.current = value;
      validateDocumentNumber(value, formIndex);
    }
  };

  const validateExistingDocumentNumberOnRows = (value: string) => {
    if (
      errors?.imports?.[formIndex]?.documentNumber?.type === "validDocument"
    ) {
      return false;
    }

    const formValues = getValues("imports");

    const currentId = getValues(`imports.${formIndex}.id`);
    const currentProvider = getValues(`imports.${formIndex}.provider`);

    const currentProviderId = currentProvider?.rawValue;

    return !formValues.some(form => {
      const { id, provider, documentNumber } = form;

      const providerId = provider?.rawValue;

      return (
        id !== currentId &&
        providerId === currentProviderId &&
        documentNumber === value
      );
    });
  };

  return (
    <Controller
      name={fieldKey}
      rules={{
        required: true,
        validate: {
          validDocument: validateExistingDocumentNumberOnRows,
        },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          validateExistingDocumentNumberOnServer(event);
          field.onChange(event);
        };

        return (
          <label className="col-4 form-control">
            <FieldWrapper
              isLoading={isLoading}
              className={`${handleInputClassName(
                fieldState,
              )} document-number-container`}
            >
              <input
                {...field}
                onChange={onChange}
                placeholder="N° documento"
                id={`txt-documentNumber-${formIndex}`}
                data-testid={`txt-documentNumber-${formIndex}`}
              />
              {isLoading ? <i className="pi pi-spin pi-spinner" /> : null}
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="Este nº de documento já existe para esse fornecedor."
              condition={fieldState?.error?.type === "validDocument"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
