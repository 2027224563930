import { IEntity } from "../../../../core/domain/entities/entity";

export enum ECostCenterTransactionType {
  expense = 1, // débito
  revenue = 2, // crédito
}

export const costCenterTransactionTypeLang: Record<string, string> = {
  expense: "Débito",
  revenue: "Crédito",
};

export interface ICostCenterTransactionEntity extends IEntity {
  companyGroupId: string;
  companyGroupName: string;
  costCenterName: string;
  costCenterOriginId: string;
  costCenterDestinationId: string;
  type: ECostCenterTransactionType;
  typeDescription: string;
  value: number;
  valueString: string;
  transactionDate: string;
  transactionDateString: string;
  description: string;
  userNameCreated: string;
  userNameModified: string;
  costCenterActive: boolean | undefined;
}

export class CostCenterTransactionEntity
  implements ICostCenterTransactionEntity
{
  id = "";
  active = true;
  companyGroupId = "";
  companyGroupName = "";
  costCenterName = "";
  costCenterOriginId = "";
  costCenterDestinationId = "";
  type = ECostCenterTransactionType.expense;
  typeDescription = "";
  value = 0;
  valueString = "0.00";
  transactionDate = "";
  transactionDateString = "";
  description = "";
  userNameCreated = "";
  userNameModified = "";
  costCenterActive = undefined;

  constructor(params?: Partial<ICostCenterTransactionEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterTransactionEntity>) {
    return new CostCenterTransactionEntity(params);
  }
}
