import { DataTableSortMeta } from "primereact/datatable";
import { Stringified } from "../../../core/domain/entities/stringfied";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
  ISimpleSortableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EColumnType,
  IAdvTableColumn,
  IRelationshipFilterOption,
  ITypedColumn,
  columnFilterOperationTypeDict,
} from "../../domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../domain/entities/panoramaEntity";
import {
  EColumnFilterType,
  IPanoramaAngularDefEntity,
  IPanoramaAngularModel,
} from "../models/panoramaAngularModel";

export interface IParsePanoramaAngularService {
  parsePanoramaAngular(
    columns: IAdvTableColumn[],
    panoramaAngularModel: IPanoramaAngularModel,
  ): IPanoramaEntity;
}

export class ParsePanoramaAngularService
  implements IParsePanoramaAngularService
{
  parsePanoramaAngular(
    columns: IAdvTableColumn[],
    panoramaModel: IPanoramaAngularModel,
  ): IPanoramaEntity {
    const panoramaDefinition = Stringified.parse(panoramaModel.objectBody);

    const selectedColumns = panoramaDefinition.selectedColumns
      .map(panSelCol => {
        const { field } = panSelCol;
        const hidden = panSelCol.hidden || false;

        const selCol = columns.find(col => {
          const hiddenCol = (col as ISimpleHiddenColumn).hidden || false;
          return col.field === field && hiddenCol === hidden;
        });

        if (selCol) {
          const width = panSelCol.userWidth
            ? `${panSelCol.userWidth}px`
            : (selCol as ISimpleColumn).width;

          const { filters } = panSelCol;

          let operation = filters?.operation;
          const searchValue = filters?.searchValue ?? "";
          const searchStr = filters?.searchStr ?? "";
          const typedCol = selCol as ITypedColumn;

          // FIX #13025 isso corrige um problema de compatibilidade entre panorama
          // angular e panorama react. No react, colunas do tipo **enum**, quando
          // sao marcadas com "Selecionar todos" e tem algumas opcoes desmarcadas,
          // ao contrario, de colunas relationship (que trocam operacao pra NotEqual),
          // continuam a usar operation Equal
          if (typedCol.columnType === EColumnType.enum) {
            operation = "Equal";
          }

          // FIX #11084 isso corrige um problema na serializacao que o angular
          // faz do panorama. Ele salva incorretamente operacao undefined para
          // colunas do tipo texto, mas o correto é "Contains"
          if (
            typedCol.columnType === undefined ||
            typedCol.columnType === EColumnType.text
          ) {
            operation = "Contains";
          }

          return {
            // este spread é necessario pois nao queremos
            // modificar o valor da definicao original da
            // coluna e sim retornar uma nova copia da definicao
            // da coluna
            ...selCol,
            filterData: {
              operation,
              value: this.computeRelationshipFilterValue(
                searchValue,
                searchStr,
              ),
            },
            width,
          } as IAdvTableColumn;
        }

        return undefined;
      })
      .filter((selCol): selCol is IAdvTableColumn => !!selCol);

    const hiddenColumns = columns.filter(
      (col: IAdvTableColumn): col is ISimpleHiddenColumn => {
        const { hidden } = col as ISimpleHiddenColumn;
        return hidden;
      },
    );

    // inclui as colunas obrigatórias esconidas (hidden) na lista de colunas
    // selecionadas desde que já não estejam presentes na lista
    for (let i = 0; i < hiddenColumns.length; i += 1) {
      const hiddenCol = hiddenColumns[i];

      const isNotPresentInSelectedColumns = !selectedColumns.some(
        ({ field }) => field === hiddenCol.field,
      );

      // checa se a coluna esconida ja nao esta
      // presente na lista de colunas selecionadas
      if (isNotPresentInSelectedColumns) {
        selectedColumns.push(hiddenCol);
      }
    }

    const panoramaEntity = PanoramaEntity.create({
      id: panoramaModel.id,
      name: panoramaModel.name,
      active: panoramaModel.active,
      userId: panoramaModel.userId,
      panoramaDefinition: {
        pfsEventEntity: this.computePFSEventEntity(
          selectedColumns,
          panoramaDefinition,
        ),
        selectedColumns,
      },
      systemDefault: false,
    });

    return panoramaEntity;
  }

  computePFSEventEntity(
    columns: IAdvTableColumn[],
    panoramaAngularDef: IPanoramaAngularDefEntity,
  ): IPFSEventEntity {
    const { selectedColumns } = panoramaAngularDef;

    // aqui interpretamos a ordenacao das colunas do panorama
    const multiSortMeta = selectedColumns
      // aqui mapeamos o formato da ordencao do angular
      // para o novo formato que usamos no react
      .map(selColAngular => {
        if (selColAngular?.filtered && selColAngular?.filters?.order) {
          const {
            filters: { field, order },
          } = selColAngular;

          const columnDef = columns.find(
            col => col.field === field,
          ) as ISimpleSortableColumn;

          return {
            field: columnDef.sortField || field,
            order: order === "asc" ? 1 : order === "desc" ? -1 : 0,
          } as DataTableSortMeta;
        }

        return undefined;
      })
      // Aqui removemos do array os campos que nao estão filtrados
      .filter((filter): filter is DataTableSortMeta => !!filter);

    // aqui interpretamos os filtros das colunas do panorama
    const filters = selectedColumns
      .filter(selColAnglar => !selColAnglar.hidden)
      // Aqui mapeamos o formato do filtro salvo no angular
      // para o novo formato que estamos usando no react
      .map(selColAngular => {
        if (selColAngular?.filtered && selColAngular?.filters?.field) {
          const {
            filters: {
              operation,
              searchStr,
              type,
              searchValue,
              searchValue2,
              exceptSearchStr,
              exceptSearchValue,
            },
          } = selColAngular;

          let { field } = selColAngular.filters;

          let value:
            | string
            | string[]
            | number
            | number[]
            | (string | number)[]
            | Date[]
            | IRelationshipFilterOption[] = searchStr;

          let matchMode =
            type === "text"
              ? columnFilterOperationTypeDict.Contains
              : columnFilterOperationTypeDict[operation];

          // aqui mapeamos o filtro de enumerador
          if (type === EColumnFilterType.Enum) {
            // FIX #13025 isso corrige um problema de compatibilidade entre panorama
            // angular e panorama react. No react, colunas do tipo **enum**, quando
            // sao marcadas com "Selecionar todos" e tem algumas opcoes desmarcadas,
            // ao contrario, de colunas relationship (que trocam operacao pra NotEqual),
            // continuam a usar operation Equal
            matchMode = columnFilterOperationTypeDict.Equal;

            field = selColAngular.filters.valueField;
            value = searchValue.split(",").map(val => {
              const nVal = Number(val);

              if (Number.isNaN(nVal)) {
                return val;
              }

              return nVal;
            });
          }
          // aqui mapeamos o filtro de relacionamento
          else if (type === EColumnFilterType.Relationship) {
            field = selColAngular.filters.valueField;
            value = this.computeRelationshipFilterValue(searchValue, searchStr);

            if (operation === "NotEqual") {
              value = this.computeRelationshipFilterValue(
                exceptSearchValue,
                exceptSearchStr,
              );
            }
          }
          // aqui mapeamos o filtro de data
          else if (type === EColumnFilterType.Date) {
            field = selColAngular.filters.valueField;
            value = [
              new Date(`${searchValue} 00:00:00`),
              new Date(`${searchValue2} 00:00:00`),
            ];
          }
          // aqui mapeamos o filtro numerico
          else if (type === EColumnFilterType.Numeric) {
            field = selColAngular.filters.valueField;
            value = Number(searchValue.replace(",", "."));
          }
          // aqui mapeamos o filtro de porcentagem
          else if (type === EColumnFilterType.Decimal) {
            field = selColAngular.filters.valueField;
            value = Number(searchValue.replace(",", ".")) / 100;
          }

          const filterObj = {
            [field]: {
              matchMode,
              value,
            },
          };

          return filterObj;
        }

        return undefined;
      })
      // Aqui removemos do array os campos que nao estão filtrados
      .filter(filter => !!filter)
      // Aqui reduzimos o array de objetos filtrados a um unico objeto onde cada
      // chave é o nome de um campo filtrado e o valor é a abstração do filtro
      .reduce((acc, cur) => {
        return { ...acc, ...cur };
      }, {});

    const pfsEventEntity = new PFSEventEntity({ filters, multiSortMeta });

    return pfsEventEntity;
  }

  computeRelationshipFilterValue(
    searchValue: string,
    searchStr: string,
  ):
    | string
    | number
    | string[]
    | number[]
    | (string | number)[]
    | Date[]
    | IRelationshipFilterOption[] {
    const labels = searchStr.split(",");

    return searchValue.split(",").map((valueId, index) => {
      return {
        label: labels[index],
        rawValue: valueId,
      };
    });
  }
}
