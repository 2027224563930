import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISearchClassificationAccountsService } from "../../domain/usecases/searchClassificationAccountsUseCase";

export class SearchClassificationAccountsService
  implements ISearchClassificationAccountsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  searchClassificationAccounts(
    providerId: string,
    companyGroupId: string,
    active = true,
  ) {
    const userEntity = this.getUserLocalService.get();

    let url = `/CompanyGroups/${companyGroupId}/Providers/${providerId}/ClassificationAccounts`;

    if (active) url += "/Actives";

    return this.api.get<
      IServerSideResponseModel<IClassificationAccountEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
