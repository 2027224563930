import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetCostCenterClassesService } from "../data/getCostCenterClassesService";
import { GetPaymentAccountBanksService } from "../data/getPaymentAccountBanksService";
import { GetStoredActiveTabsService } from "../data/getStoredActiveTabsService";
import { ListImportationMatchesBanksService } from "../data/listImportationMatchesBanksService";
import { ListImportationMatchesClassesService } from "../data/listImportationMatchesClassesService";
import { ListImportationMatchesCostCentersService } from "../data/listImportationMatchesCostCentersService";
import { ListImportationMatchesPaymentAccountsService } from "../data/listImportationMatchesPaymentAccountsService";
import { SaveImportationMatchesService } from "../data/saveImportationMatchesService";
import { StoreActiveTabsService } from "../data/storeActiveTabsService";
import { IGetCostCenterClassesContract } from "../domain/contracts/getCostCenterClassesContract.ts";
import { IGetPaymentAccountBanksContract } from "../domain/contracts/getPaymentAccountBanksContract";
import { IGetStoredActiveTabsContract } from "../domain/contracts/getStoredActiveTabsContract";
import { IListImportationMatchesBanksContract } from "../domain/contracts/listImportationMatchesBanksContract";
import { IListImportationMatchesClassesContract } from "../domain/contracts/listImportationMatchesClassesContract";
import { IListImportationMatchesCostCentersContract } from "../domain/contracts/listImportationMatchesCostCentersContract";
import { IListImportationMatchesPaymentAccountsContract } from "../domain/contracts/listImportationMatchesPaymentAccountsContract";
import { ISaveImportationMatchesContract } from "../domain/contracts/saveImportationMatchesContract";
import { IStoreActiveTabsContract } from "../domain/contracts/storeActiveTabsContract";

export type MakeImportationMatches =
  IListImportationMatchesPaymentAccountsContract &
    IListImportationMatchesCostCentersContract &
    IListImportationMatchesBanksContract &
    IGetPaymentAccountBanksContract &
    IListImportationMatchesClassesContract &
    IGetCostCenterClassesContract &
    ISaveImportationMatchesContract &
    IStoreActiveTabsContract &
    IGetStoredActiveTabsContract;

export function makeImportationMatches(
  api: IApiService,
): MakeImportationMatches {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listImportationMatchesPaymentAccountsService =
    new ListImportationMatchesPaymentAccountsService(getUserLocalService, api);

  const listImportationMatchesCostCentersService =
    new ListImportationMatchesCostCentersService(getUserLocalService, api);

  const listImportationMatchesBanksService =
    new ListImportationMatchesBanksService(getUserLocalService, api);

  const getPaymentAccountBanksService = new GetPaymentAccountBanksService(
    getUserLocalService,
    api,
  );

  const listImportationMatchesClassesService =
    new ListImportationMatchesClassesService(getUserLocalService, api);

  const getCostCenterClassesService = new GetCostCenterClassesService(
    getUserLocalService,
    api,
  );

  const saveImportationMatchesService = new SaveImportationMatchesService(
    getUserLocalService,
    api,
  );

  const storeActiveTabsService = new StoreActiveTabsService();

  const getStoredActiveTabsService = new GetStoredActiveTabsService();

  return {
    listImportationMatchesPaymentAccounts(
      payload,
      currentCompanyGroupId,
      actives,
    ) {
      return listImportationMatchesPaymentAccountsService.listImportationMatchesPaymentAccounts(
        payload,
        currentCompanyGroupId,
        actives,
      );
    },
    listImportationMatchesCostCenters(payload, origin) {
      return listImportationMatchesCostCentersService.listImportationMatchesCostCenters(
        payload,
        origin,
      );
    },
    listImportationMatchesBanks(origin) {
      return listImportationMatchesBanksService.listImportationMatchesBanks(
        origin,
      );
    },
    getPaymentAccountBanks(paymentAccountId, origin) {
      return getPaymentAccountBanksService.getPaymentAccountBanks(
        paymentAccountId,
        origin,
      );
    },
    listImportationMatchesClasses(origin, filter) {
      return listImportationMatchesClassesService.listImportationMatchesClasses(
        origin,
        filter,
      );
    },
    getCostCenterClasses(costCenterId, origin) {
      return getCostCenterClassesService.getCostCenterClasses(
        costCenterId,
        origin,
      );
    },
    saveImportationMatches(payload) {
      return saveImportationMatchesService.saveImportationMatches(payload);
    },
    storeActiveTabs(origin, activeTabs) {
      return storeActiveTabsService.storeActiveTabs(origin, activeTabs);
    },
    getStoredActiveTabs(origin) {
      return getStoredActiveTabsService.getStoredActiveTabs(origin);
    },
  };
}
