import { useState } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiError } from "../../../../../core/data/services/apiService";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { ILowerCaseErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISolicitImportAttachmentEntity } from "../../domain/entities/solicitImportAttachmentEntity";
import { ISolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import { useSolicitImportPage } from "./useSolicitImportPage";

interface IUseAttachmentsHandler {
  formIndex: number | null;
  selection: ISolicitImportEntity[];
  handleCloseAttachmentModal(): void;
}

interface IProgressModalState {
  loaded: number;
  total: number;
  isOpen: boolean;
}

interface IUseAttachmentsHandlerState {
  progressModal: IProgressModalState;
  rpaOptions: IGenerateRPAModalInputEntity[];
}

export function useAttachmentsHandler(props: IUseAttachmentsHandler) {
  const { formIndex, selection, handleCloseAttachmentModal } = props;

  const {
    getFormIndex,
    useDebtImport,
    form: { getValues, setValue, unregister },
  } = useSolicitImportPage();

  const isBulk = formIndex === null;
  const rowData = isBulk ? null : getValues(`imports.${formIndex}`);

  const dialog = useSoulDialog();
  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const [state, setState] = useState<IUseAttachmentsHandlerState>({
    rpaOptions: [],
    progressModal: {
      total: 0,
      loaded: 0,
      isOpen: false,
    },
  });

  const {
    uploadAttachments,
    getStorageFilebyId,
    bulkUploadAttachments,
    updateAttachmentsBarcode,
    listRequesterAttachmentTypes,
    validateMeasurementAttachment,
  } = useDebtImport;

  const onUploadProgress = (event: ProgressEvent) => {
    setState(old => ({
      ...old,
      progressModal: {
        isOpen: true,
        total: event.total,
        loaded: event.loaded,
      },
    }));
  };

  const handleBulkUpload = async (
    attachments: ISolicitImportAttachmentEntity[],
  ) => {
    const result = await dialog.fire({
      icon: "question",
      title: "Aviso",
      showCancelButton: true,
      cancelButtonText: "Não",
      text: `Você está vinculando os anexos informados a um total de
            ${selection.length} lançamentos. Ao confirmar, só será
            possível fazer a remoção/alteração desses anexos manualmente.
            Deseja prosseguir?`,
    });

    if (result.dismiss) {
      return;
    }

    const solicitImportIds = selection.map(d => d.id);

    setState(old => ({
      ...old,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: true,
      },
    }));

    try {
      const response = await bulkUploadAttachments({
        attachments,
        solicitImportIds,
      });

      response.map(attachment => {
        if (attachment.paymentRequestId) {
          const bulkFormIndex = getFormIndex(attachment.paymentRequestId);
          const oldFiles = getValues(`imports.${bulkFormIndex}.storageFiles`);
          const newFile = { ...attachment, index: oldFiles.length };
          const storageFiles = [...oldFiles, newFile];
          setValue(`imports.${bulkFormIndex}.storageFiles`, storageFiles);
        }
        return null;
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Anexos adicionados com sucesso.",
      });

      handleCloseAttachmentModal();
    } finally {
      setState(old => ({
        ...old,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));
    }
  };

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: ISolicitImportAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        currentCompanyGroup.id,
        billetAttachment,
      );

      return {
        updatedAttachment,
        preventSubmit: false,
      };
    } catch {
      return {
        preventSubmit: false,
        updatedAttachment: billetAttachment,
      };
    }
  };

  const handleBilletAttachmentValue = (
    attachmentsResponse: ISolicitImportAttachmentEntity[],
  ) => {
    if (isBulk) {
      return;
    }

    const billetAttachment = attachmentsResponse.find(attachment => {
      return (
        Number(attachment.type) === EAttachmentType.Billet &&
        attachment?.barcode &&
        attachment.active
      );
    });

    const barcodeValue = billetAttachment?.barcode || "";

    if (!barcodeValue) {
      unregister(`imports.${formIndex}.barcode`);
      return;
    }

    setValue(`imports.${formIndex}.barcode`, barcodeValue, {
      shouldValidate: true,
    });
  };

  const handleValidateMeasurementAttachment = async (
    measurementAttachment: ISolicitImportAttachmentEntity,
  ) => {
    const paymentRequestValue = rowData?.value;

    if (!paymentRequestValue) {
      return {
        preventSubmit: false,
        updatedAttachment: measurementAttachment,
      };
    }

    try {
      const response = await validateMeasurementAttachment(
        paymentRequestValue,
        measurementAttachment,
      );

      if (response.success === true) {
        return {
          preventSubmit: false,
          updatedAttachment: measurementAttachment,
        };
      }

      if (response.success === false) {
        await debounceTime(1);

        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: response.message,
        });
      }

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    } catch (error) {
      await debounceTime(1);

      const messageHTMLError = (
        error as IApiError<ILowerCaseErrorResponseEntity>
      ).response.data.messageHTML;

      const messageError = (error as IApiError<ILowerCaseErrorResponseEntity>)
        .response.data.message;

      await dialog.fire({
        icon: "error",
        title: "Opa!",
        html: messageHTMLError || messageError,
      });

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    }
  };

  const handleAttachmentsSubmission = async (
    attachments: ISolicitImportAttachmentEntity[],
  ) => {
    if (isBulk) {
      handleBulkUpload(attachments);
      return;
    }

    setState(old => ({
      ...old,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: true,
      },
    }));

    const solicitImportId = getValues(`imports.${formIndex}.id`);

    try {
      const response = await uploadAttachments({
        attachments,
        solicitImportId,
        onUploadProgress,
      });

      const isEdition = !!getValues(`imports.${formIndex}.storageFiles`).length;

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Anexos ${
          isEdition ? "atualizados" : "adicionados"
        } com sucesso.`,
      });

      setValue(`imports.${formIndex}.storageFiles`, response);

      handleBilletAttachmentValue(response);

      handleCloseAttachmentModal();
    } finally {
      setState(old => ({
        ...old,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<ISolicitImportAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.Measurement]: "Medição",
    };

    if (isBulk) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar em lote anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Por favor, opte por
            adicionar <strong>individualmente</strong>.
          </>
        ),
      });

      return false;
    }

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  return {
    isBulk,
    rowData,
    getStorageFilebyId,
    handleAttachmentsSubmission,
    progressModalState: state.progressModal,
    attachmentTypeCheck: handleAttachmentTypeChange,
    listAttachmentTypes: listRequesterAttachmentTypes,
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
    validateMeasurementAttachment: handleValidateMeasurementAttachment,
  };
}
