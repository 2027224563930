import { IClassificationAssessmentEntity } from "../../../classificationAssessment/domain/entities/classificationAssessment";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchClassificationAssessmentContract } from "../../domain/contracts/searchClassificationAssessmentContract";

export class SearchClassificationAsssesmentService
  implements ISearchClassificationAssessmentContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  searchClassificationAssessment(search: string, companyGroupId: string) {
    const userEntity = this.getUserLocalService.get();

    const searchPayload = new PayloadEntity({
      draw: 0,
      length: 0,
      columns: [
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "corporationName",
          name: "corporationName",
          orderable: false,
          searchable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "document",
          name: "document",
          orderable: false,
          searchable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      search: { value: search, regex: false },
    });

    const params = new URLSearchParams({
      body: JSON.stringify(searchPayload),
    });

    const url = `/CompanyGroups/${companyGroupId}/ClassificationAssessments?${params}`;

    return this.api.get<
      IServerSideResponseModel<IClassificationAssessmentEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
