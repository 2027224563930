import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICheckReturnInvoiceTaxes,
  ICheckReturnInvoiceTaxesContract,
} from "../../domain/contracts/checkReturnInvoiceTaxesContract";

interface ICheckReturnInvoiceTaxesResponse {
  code: number | null;
  message: string | null;
  success: boolean;
}

export class CheckReturnInvoiceTaxesService
  implements ICheckReturnInvoiceTaxesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkReturnInvoiceTaxes(
    accountReceivableId: string,
  ): Promise<ICheckReturnInvoiceTaxes> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsReceivable/${accountReceivableId}/CheckReturnInvoiceTaxes`;

    const response = await this.api.get<ICheckReturnInvoiceTaxesResponse>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const result: ICheckReturnInvoiceTaxes = {
      hasInvoiceTaxes: response.success || false,
    };

    return result;
  }
}
