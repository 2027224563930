import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IPaymentAccountTransactionEntity,
  PaymentAccountTransactionEntity,
} from "../../domain/entities/paymentAccountTransactionEntity";
import { ITogglePaymentAccountTransactionService } from "../../domain/usecases/togglePaymentAccountTransactionUseCase";

export class TogglePaymentAccountTransactionService
  implements ITogglePaymentAccountTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async togglePaymentAccountTransaction(
    paymentAccountTransactionsId: string,
  ): Promise<IPaymentAccountTransactionEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/PaymentAccountTransactions/${paymentAccountTransactionsId}`;

    const response = await this.api.delete<IPaymentAccountTransactionEntity>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return new PaymentAccountTransactionEntity(response);
  }
}
