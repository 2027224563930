import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";

export interface IGetClassificationAccountService {
  getClassificationAccount(id: string): Promise<IClassificationAccountEntity>;
}

export interface IGetClassificationAccountUseCase {
  getClassificationAccount(id: string): Promise<IClassificationAccountEntity>;
}

export class GetClassificationAccountUseCase
  implements IGetClassificationAccountUseCase
{
  constructor(
    private getClassificationAccountService: IGetClassificationAccountService,
  ) {}

  getClassificationAccount(id: string) {
    return this.getClassificationAccountService.getClassificationAccount(id);
  }
}
