import { Dropdown } from "primereact/dropdown";
import { PaginatorTemplate } from "primereact/paginator";
import { useCallback, useMemo } from "react";

export function useSimpleTableSettings() {
  const renderCurrentPageReportTemplate = useCallback(
    (currentPage, totalPages) => {
      return (
        <div className="current-page-report">
          Exibindo página {currentPage} de {totalPages}
        </div>
      );
    },
    [],
  );

  const renderRowsPerPageDropdownTemplate = useCallback(
    (options, value, onChange) => {
      return (
        <div className="rows-per-page-dropdown-wrapper">
          Mostrar
          <Dropdown
            options={options}
            onChange={event => {
              onChange(event);
            }}
            value={value}
          />
        </div>
      );
    },
    [],
  );

  const paginatorTemplate = useMemo(
    () =>
      ({
        layout:
          "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        CurrentPageReport: ({ currentPage, totalPages }) =>
          renderCurrentPageReportTemplate(currentPage, totalPages),
        RowsPerPageDropdown: ({ options, value, onChange }) => {
          return renderRowsPerPageDropdownTemplate(options, value, onChange);
        },
      } as PaginatorTemplate),
    [renderCurrentPageReportTemplate, renderRowsPerPageDropdownTemplate],
  );

  return {
    paginatorTemplate,
  };
}
