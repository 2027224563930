import { IApiService } from "../../../../core/data/services/apiService";
import { IEnum } from "../../../../core/domain/entities/enum";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetAttachmentTypesContract } from "../../domain/contracts/getAttachmentTypesContract";

export class GetAttachmentTypesService implements IGetAttachmentTypesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getAttachmentTypes() {
    const userEntity = this.getUserLocalService.get();

    const url = `/Enums/AttachmentTypes`;

    return this.api.get<IEnum<string>[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
