import { ChangeEvent, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import BigNumber from "bignumber.js";
import { IServerSideResponseModel } from "../../../data/models/serverSideResponseModel";
import { ITypeaheadOption } from "../../../domain/entities/typeaheadOption";
import { useDebounceTimeAsync } from "../../hooks/useDebounceTime";
import { useSoulDialog } from "../../hooks/useSoulDialog";
import { InputBalance } from "../InputBalance";
import { InputPercentage } from "../InputPercentage";
import { InvalidFeedback } from "../InvalidFeedback";
import { SoulTypeahead } from "../SoulTypeahead";
import { CardContainer, Container } from "./styles";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useCalculateAssessmentsValues } from "../../hooks/useCalculateAssessmentsValue";
import {
  IAssessmentFormEntity,
  IGenericAssessmentFormEntity,
} from "../../../domain/entities/genericAssessmentEntitty";
import { ISearchParams } from "../../../domain/entities/searchParams";

interface IAssessmentFormCardState {
  classificationAssessment: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
  costCenter: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface IAssessmentFormModalProps<T extends IGenericAssessmentFormEntity> {
  isOpen: boolean;
  assessmentsList: T[];
  accountValue: number;
  editAssessmentIndex: number | null;
  onRequestClose(): void;
  onSubmitAssessments(assessments: T[]): void;
  searchClassificationAssessment(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<ITypeaheadOption[]>>;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<ITypeaheadOption[]>>;
  observationMaxLength: number;
}

export function AssessmentFormModal<T extends IGenericAssessmentFormEntity>({
  isOpen,
  accountValue,
  assessmentsList,
  editAssessmentIndex,
  onRequestClose,
  searchCostCenter,
  onSubmitAssessments,
  searchClassificationAssessment,
  observationMaxLength,
}: IAssessmentFormModalProps<T>) {
  const dialog = useSoulDialog();
  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const { control, setValue, reset, trigger, handleSubmit, watch, getValues } =
    useForm<IAssessmentFormEntity>({
      mode: "all",
      defaultValues: {
        assessments: [],
      },
    });

  const { fields, remove, append } = useFieldArray({
    control,
    keyName: "formId",
    name: "assessments",
  });

  const formAssessmentsList = watch("assessments");

  const {
    getRemaining,
    formattedAccountValue,
    calculatePercentageFromValue,
    calculateValuesFromPercentageMask,
  } = useCalculateAssessmentsValues({
    accountValue,
    editAssessmentIndex,
    originalAssessments: assessmentsList,
  });

  const {
    remainingValue,
    remainingPercentage,
    formattedRemainingValue,
    formattedRemainingPercentage,
  } = getRemaining(formAssessmentsList);

  const [state, setState] = useState<IAssessmentFormCardState>({
    classificationAssessment: {
      options: [],
      loading: false,
    },
    costCenter: {
      options: [],
      loading: false,
    },
  });

  const handleAfterOpen = () => {
    if (editAssessmentIndex !== null) {
      const editAssessment = assessmentsList[editAssessmentIndex];

      reset({ assessments: [editAssessment] });

      return;
    }

    append({
      value: "0",
      percentage: "0",
      observation: "",
      costCenter: null,
      percentageMask: 0,
      classificationAssessment: null,
    });
  };

  const handleAddAssessment = async () => {
    if (remainingValue.isLessThanOrEqualTo(0)) {
      return;
    }

    const isValid = await trigger();

    if (!isValid) {
      dialog.fire({
        icon: "warning",
        title: "Atenção!",
        text: "Os rateios anteriores devem ser preenchidos.",
      });
      return;
    }

    append({
      observation: "",
      costCenter: null,
      classificationAssessment: null,
      value: remainingValue.toJSON(),
      percentage: remainingPercentage.toJSON(),
      percentageMask: remainingPercentage
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber(),
    });
  };

  const handleSearchClassificationAssessment = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      classificationAssessment: {
        ...prevState.classificationAssessment,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchClassificationAssessment({
        search,
        companyGroupId,
        payloadOptions: { length: 100 },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        classificationAssessment: {
          ...prevState.classificationAssessment,
          options: companies,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        classificationAssessment: {
          ...prevState.classificationAssessment,
          loading: false,
        },
      }));
    }
  };

  const handleSearchCostCenter = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      costCenter: {
        ...prevState.costCenter,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchCostCenter({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: { length: 100 },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          options: companies,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          loading: false,
        },
      }));
    }
  };

  const handleValueChange = (index: number, value: number) => {
    const { calculatedPercentage, maskedPercentage } =
      calculatePercentageFromValue(value);

    setValue(`assessments.${index}.percentage`, calculatedPercentage.toJSON());
    setValue(
      `assessments.${index}.percentageMask`,
      maskedPercentage.toNumber(),
      {
        shouldValidate: true,
      },
    );
  };

  const handlePercentageMaskChange = (index: number, percentage: number) => {
    const currentFormValuesList = getValues("assessments");

    const { calculatedPercentage, calculatedValue } =
      calculateValuesFromPercentageMask({
        index,
        percentage,
        currentFormValuesList,
      });

    setValue(`assessments.${index}.percentage`, calculatedPercentage.toJSON());
    setValue(`assessments.${index}.value`, calculatedValue.toJSON(), {
      shouldValidate: true,
    });
  };

  const handleOnValid = (data: IAssessmentFormEntity) => {
    const assessments = data.assessments.map(assessmentObject => ({
      ...assessmentObject,
      percentageMask: new BigNumber(assessmentObject.percentage)
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber(),
    }));

    onSubmitAssessments(assessments as T[]);
  };

  const handleAfterClose = () => {
    reset({ assessments: [] });

    setState({
      costCenter: { loading: false, options: [] },
      classificationAssessment: { loading: false, options: [] },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            {editAssessmentIndex !== null
              ? "Alterar rateio"
              : "Adicionar rateios"}
          </h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        <form id="assessment-modal-form" onSubmit={handleSubmit(handleOnValid)}>
          <div className="react-modal-body">
            <div className="card">
              <p className="description">
                Valor total do lançamento: {formattedAccountValue} (100%)
              </p>
              <p className="document-number">
                Valor a ser rateado: {formattedRemainingValue} (
                {formattedRemainingPercentage}%)
              </p>
            </div>
            <div className="card-list">
              {fields.map((assessmentField, index) => {
                const shouldShowRemoveButton = index > 0;

                const handleRemoveField = () => {
                  remove(index);
                };

                return (
                  <CardContainer key={assessmentField.formId}>
                    <div className="card form-container">
                      {shouldShowRemoveButton ? (
                        <div className="form-container__header">
                          <button
                            type="button"
                            className="form-button red-bkg btn-remove"
                            onClick={handleRemoveField}
                          >
                            <FaTrashAlt /> Remover rateio
                          </button>
                        </div>
                      ) : null}
                      <div className="form-container__body">
                        <div className="form-row">
                          {/* Classificação de Rateio */}
                          <Controller
                            control={control}
                            rules={{ required: true }}
                            name={`assessments.${index}.classificationAssessment`}
                            render={({ field, fieldState }) => {
                              return (
                                <label className="col-6 form-control">
                                  <span>
                                    Classificação de Rateio{" "}
                                    {state.classificationAssessment.loading && (
                                      <i className="pi pi-spin pi-spinner" />
                                    )}
                                  </span>
                                  <SoulTypeahead
                                    serverSide
                                    openOnFocus
                                    value={field.value}
                                    onChange={field.onChange}
                                    id="classificationAssessment"
                                    placeholder="Classificação de Rateio"
                                    data-testid="txt-classificationAssessment"
                                    loading={
                                      state.classificationAssessment.loading
                                    }
                                    options={
                                      state.classificationAssessment.options
                                    }
                                    className={
                                      fieldState?.error ? "isInvalid" : ""
                                    }
                                    onSearchChange={
                                      handleSearchClassificationAssessment
                                    }
                                  />
                                  <InvalidFeedback
                                    condition={!!fieldState?.error}
                                    message="Este campo é obrigatório"
                                  />
                                </label>
                              );
                            }}
                          />
                          {/* Centro de custos */}
                          <Controller
                            control={control}
                            rules={{ required: true }}
                            name={`assessments.${index}.costCenter`}
                            render={({ field, fieldState }) => {
                              return (
                                <label className="col-6 form-control">
                                  <span>
                                    Centro de Custos{" "}
                                    {state.costCenter.loading && (
                                      <i className="pi pi-spin pi-spinner" />
                                    )}
                                  </span>
                                  <SoulTypeahead
                                    serverSide
                                    openOnFocus
                                    id="costCenter"
                                    value={field.value}
                                    onChange={field.onChange}
                                    data-testid="txt-costCenter"
                                    placeholder="Centro de Custos"
                                    loading={state.costCenter.loading}
                                    options={state.costCenter.options}
                                    onSearchChange={handleSearchCostCenter}
                                    className={
                                      fieldState?.error ? "isInvalid" : ""
                                    }
                                  />
                                  <InvalidFeedback
                                    condition={!!fieldState?.error}
                                    message="Este campo é obrigatório"
                                  />
                                </label>
                              );
                            }}
                          />
                        </div>
                        <div className="form-row">
                          {/* Valor (R$) */}
                          <Controller
                            control={control}
                            name={`assessments.${index}.value`}
                            rules={{
                              validate: {
                                required: value => !!value,
                                min: value =>
                                  new BigNumber(
                                    value || 0,
                                  ).isGreaterThanOrEqualTo(0.01),
                                max: () =>
                                  remainingValue.isGreaterThanOrEqualTo(0),
                              },
                            }}
                            render={({ field, fieldState }) => {
                              const handleChange = (value: number | null) => {
                                field.onChange(value?.toString() || 0);
                                handleValueChange(index, value || 0);
                              };

                              return (
                                <label className="col-6 form-control">
                                  <span>Valor (R$)</span>
                                  <InputBalance
                                    id="txt-value"
                                    placeholder="Valor"
                                    data-testid="txt-value"
                                    onChange={handleChange}
                                    value={Number(field.value)}
                                    className={
                                      fieldState?.error ? "isInvalid" : ""
                                    }
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "max"
                                    }
                                    message="O valor não deve ultrapassar o limite"
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "required"
                                    }
                                    message="Este campo é obrigatório"
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "min"
                                    }
                                    message="O valor deve ser no mínimo igual a R$0,01"
                                  />
                                </label>
                              );
                            }}
                          />
                          {/* Porcentagem (%) */}
                          <Controller
                            control={control}
                            name={`assessments.${index}.percentageMask`}
                            rules={{
                              validate: {
                                required: () => {
                                  const value = getValues(
                                    `assessments.${index}.value`,
                                  );
                                  return new BigNumber(value).isGreaterThan(0);
                                },
                                max: () =>
                                  remainingValue.isGreaterThanOrEqualTo(0),
                              },
                            }}
                            render={({ field, fieldState }) => {
                              const handleChange = (value: number | null) => {
                                field.onChange(value || 0);
                                handlePercentageMaskChange(index, value || 0);
                              };

                              return (
                                <label className="col-6 form-control">
                                  <span>Porcentagem (%)</span>
                                  <InputPercentage
                                    {...field}
                                    precision={2}
                                    id="txt-percentage"
                                    onChange={handleChange}
                                    placeholder="Porcentagem"
                                    data-testid="txt-percentage"
                                    className={
                                      fieldState?.error ? "isInvalid" : ""
                                    }
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "required"
                                    }
                                    message="Este campo é obrigatório"
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "max"
                                    }
                                    message="A porcentagem não deve ultrapassar o limite"
                                  />
                                </label>
                              );
                            }}
                          />
                        </div>
                        <div className="form-row">
                          {/* Descrição do Rateio */}
                          <Controller
                            control={control}
                            name={`assessments.${index}.observation`}
                            rules={{
                              required: true,
                              maxLength: observationMaxLength,
                            }}
                            render={({ field, fieldState }) => {
                              const handleObservationChange = (
                                event: ChangeEvent<HTMLInputElement>,
                              ) => {
                                const eventClone = { ...event };
                                const cursorEnd = event.target.selectionEnd;
                                const eventValue =
                                  event.target.value?.toUpperCase() || "";

                                eventClone.target.value = eventValue;
                                eventClone.target.setSelectionRange(
                                  cursorEnd,
                                  cursorEnd,
                                );

                                field.onChange(eventClone);
                              };

                              return (
                                <label className="col-12 form-control">
                                  <span>Descrição do Rateio</span>
                                  <input
                                    {...field}
                                    id="txt-observation"
                                    data-testid="txt-observation"
                                    placeholder="Descrição do Rateio"
                                    onChange={handleObservationChange}
                                    className={
                                      fieldState?.error ? "isInvalid" : ""
                                    }
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "required"
                                    }
                                    message="Este campo é obrigatório"
                                  />
                                  <InvalidFeedback
                                    condition={
                                      fieldState?.error?.type === "maxLength"
                                    }
                                    message={`Este campo tem um limite de ${observationMaxLength} caracteres`}
                                  />
                                </label>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContainer>
                );
              })}
            </div>
            {editAssessmentIndex === null ? (
              <div className="card-list-appendix">
                <button
                  type="button"
                  className="form-button green-bkg btn-add-more"
                  onClick={handleAddAssessment}
                  disabled={remainingValue.isLessThanOrEqualTo(0)}
                >
                  <FaPlus /> Adicionar mais um rateio
                </button>
              </div>
            ) : null}
          </div>
          <div className="react-modal-footer">
            <button
              type="button"
              id="btn-close"
              data-testid="btn-close"
              className="form-button red-bkg"
              onClick={onRequestClose}
            >
              Fechar
            </button>
            <button
              type="submit"
              data-testid="btn-submit"
              form="assessment-modal-form"
              className="form-button green-bkg"
              id="assessment-form-modal__btn-submit"
            >
              Confirmar
            </button>
          </div>
        </form>
      </Container>
    </Modal>
  );
}
