import { format, parse } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ICostCenterTransactionInputEntity } from "../../domain/entities/costCenterTransactionInputEntity";
import {
  CostCenterTransactionEntity,
  ICostCenterTransactionEntity,
} from "../../domain/entities/costCenterTransactionsEntity";
import { ISaveCostCenterTransactionService } from "../../domain/usecases/saveCostCenterTransactionsUseCase";
import { CostCenterTransactionInputModel } from "../models/costCenterTransactionInputModel";

export class SaveCostCenterTransactionsService
  implements ISaveCostCenterTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveCostCenterTransaction(
    costCenterTransaction: ICostCenterTransactionInputEntity,
  ): Promise<ICostCenterTransactionEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/CostCenterTransactions`;

    const { transactionDate } = costCenterTransaction;

    const payload = new CostCenterTransactionInputModel({
      id: costCenterTransaction.id,
      companyGroupId: costCenterTransaction.companyGroupId,
      costCenterOriginId: costCenterTransaction.costCenterOrigin?.id,
      costCenterDestinationId: costCenterTransaction.costCenterDestination?.id,
      transactionDate: format(
        parse(transactionDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      ),
      value: costCenterTransaction.value,
      description: costCenterTransaction.description,
      active: costCenterTransaction.active,
    });

    let response;

    if (costCenterTransaction.id === "") {
      response = await this.api.post<ICostCenterTransactionEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    } else if (costCenterTransaction.id !== "") {
      response = await this.api.put<ICostCenterTransactionEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    }

    return new CostCenterTransactionEntity(response);
  }
}
