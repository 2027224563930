import { IBankModel } from "../../data/models/bankModel";

export interface IGetBanksUseCase {
  getBanks(): Promise<IBankModel[]>;
}

export interface IGetBanksService {
  getBanks(): Promise<IBankModel[]>;
}

export class GetBanksUseCase implements IGetBanksUseCase {
  constructor(private getBanksService: IGetBanksService) {}

  getBanks() {
    return this.getBanksService.getBanks();
  }
}
