import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateProjectContract } from "../../domain/contracts/updateProjectContract";
import { IProjectEntity } from "../../domain/entities/projectEntity";
import { IProjectFormEntity } from "../../domain/entities/projectFormEntity";

export class UpdateProjectService implements IUpdateProjectContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateProject(payload: IProjectFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/Projects";

    const editedPayload = {
      ...payload,
      fuspHash: payload.fuspHash.trim(),
      competencyName: payload.competency?.label || "",
      competencyId: payload.competency?.rawValue || "",
      coordinatorId: payload.coordinator?.rawValue || "",
      coordinatorName: payload?.coordinator?.label || "",
      courseStartDate: this.formatDate(payload.courseStartDate),
      activitiesEndDate: this.formatDate(payload.activitiesEndDate),
      deputyCoordinatorName: payload?.deputyCoordinator?.label || "",
      deputyCoordinatorId: payload?.deputyCoordinator?.rawValue || "",
      costCenters: payload?.costCenters?.map(object => object.id) || [],
      activitiesStartDate: this.formatDate(payload.activitiesStartDate),
    };

    const mappedPayload = new Map(Object.entries(editedPayload));

    mappedPayload.delete("competency");
    mappedPayload.delete("coordinator");
    mappedPayload.delete("deputyCoordinator");

    const finalPayload = Object.fromEntries(mappedPayload.entries());

    return this.api.put<IProjectEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    if (!date) {
      return null;
    }

    return format(parse(date, "ddMMyyyy", new Date()), "yyyy-MM-dd").toString();
  }
}
