import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import {
  CustomerEntity,
  ICustomerEntity,
} from "../../domain/entities/customerEntity";
import { IToggleCustomerStatusService } from "../../domain/usecases/toggleCustomerUseCase";

export class ToggleCustomerStatusService
  implements IToggleCustomerStatusService
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleCustomerStatus(id: string): Promise<ICustomerEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `Customers/${id}`;

    const response = await this.api.delete<ICustomerEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return CustomerEntity.create(response);
  }
}
