import React from "react";

export interface ISimpleColumn {
  header: string;
  field: string;
  width?: string;
  searchParam?: string;
  className?: string;
}

interface ColumnBodyOptions {
  column: ISimpleColumn;
  field: string;
  rowIndex: number;
  props?: unknown;
  frozenRow?: boolean;
}

type IColumnHeaderType =
  | React.ReactNode
  | ((columnDef: ISimpleColumn) => React.ReactNode);

type IColumnBodyType =
  | React.ReactNode
  | ((data: unknown, options: ColumnBodyOptions) => React.ReactNode);

/**
 * Colunas hidden são colunas que obrigatoriamente serão inclusas no payload.
 * Em alguns casos a coluna pode ja estar na lista de colunas disponiveis na
 * UI e ainda assim estar no final da lista como hidden, pois caso o user venha
 * remover a coluna pela UI, ela permanecer na request.
 */
export interface ISimpleHiddenColumn {
  field: string;
  hidden: boolean;
}

export interface ISimpleCustomColumn extends ISimpleColumn {
  headerTemplate?: IColumnHeaderType;
  bodyTemplate?: IColumnBodyType;
}

export interface ISimpleSearchableColumn extends ISimpleColumn {
  searchable: boolean;
  searchField: string;
  search: {
    regex: boolean;
    value: string;
  };
}

export interface ISimpleSortableColumn extends ISimpleColumn {
  orderable: boolean;
  sortField: string;
}

export type SimpleTableColumn =
  | ISimpleColumn
  | ISimpleHiddenColumn
  | ISimpleSearchableColumn
  | ISimpleCustomColumn
  | ISimpleSortableColumn;

export class SimpleColumn
  implements
    ISimpleHiddenColumn,
    ISimpleSortableColumn,
    ISimpleSearchableColumn
{
  header = "";
  field = "";
  hidden = false;
  orderable = false;
  sortField = "";
  searchable = false;
  searchField = "";
  width = undefined;
  search = {
    regex: false,
    value: "",
  };

  constructor(params?: Partial<SimpleTableColumn>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
