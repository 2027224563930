import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";
import { useAttachmentsHandler } from "../../hooks/useAttachmentsHandler";

interface ISectionAttachmentsProps {
  readonly?: boolean;
  useReceivableForm: MakeAccountsReceivableForm;
}
export function SectionAttachments({
  readonly = false,
  useReceivableForm,
}: ISectionAttachmentsProps) {
  const {
    attachmentsWatcher,
    getStorageFilebyId,
    attachmentTypeCheck,
    listAttachmentTypes,
    canDeleteAttachment,
    handleAttachmentListChange,
  } = useAttachmentsHandler({
    useReceivableForm,
  });

  return (
    <AttachmentsGrid
      disabled={readonly}
      attachments={attachmentsWatcher}
      getStorageFilebyId={getStorageFilebyId}
      attachmentTypeCheck={attachmentTypeCheck}
      listAttachmentTypes={listAttachmentTypes}
      canDeleteAttachment={canDeleteAttachment}
      onAttachmentsChange={handleAttachmentListChange}
    />
  );
}
