import { IEntity } from "../../../core/domain/entities/entity";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { ILinkedCompanyGroupEntity } from "./linkedCompanyGroupEntity";

export interface IUserEntity extends IEntity {
  name: string;
  email: string;
  document: string;
  password?: string;
  passwordConfirm?: string;
  profile: EUserProfile | string;
  profileDescription: string;
  partnerId: string | undefined;
  isFinancial?: boolean;
  isPersonnelDepartment?: boolean;

  companyGroups: ILinkedCompanyGroupEntity[];
}

export class UserEntity implements IUserEntity {
  id = "";
  name = "";
  email = "";
  document = "";
  password = "";
  passwordConfirm = "";
  profile = "";
  profileDescription = "";
  partnerId = undefined;
  companyGroups = [];
  isFinancial = false;
  isPersonnelDepartment = false;
  active = true;

  constructor(params?: Partial<IUserEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IUserEntity>) {
    return new UserEntity(params);
  }
}
