import { Dropdown } from "primereact/dropdown";
import { FaCaretDown, FaDatabase, FaFileExcel, FaPlus } from "react-icons/fa";
import { RiFilterOffFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { PanoramaFormModal } from "../../../../../../advTable/presentation/components/PanoramaFormModal";
import { SoulDropdown } from "../../../../../../core/presentation/components/SoulDropdown";
import { Container, FilterToolsContainer, PageToolsContainer } from "./styles";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { useToolbar } from "../../hooks/useToolbar";
import { useRequesterPage } from "../../hooks/useRequesterPage";
import {
  Balance,
  EBalanceVariant,
} from "../../../../../../core/presentation/components/Balance";

export function RequesterToolbar() {
  const {
    state,
    columns,
    usePaymentRequests,
    handlePanoramaChange,
    handleClearButtonClick,
  } = useRequesterPage();

  const {
    data,
    pfsEvent,
    selectedColumns,
    orderedColNames,
    filtered: showClearButton,
  } = state;

  const {
    loading,
    panoramaOptions,
    selectedPanorama,
    handlePanoramaSave,
    isPanoramaModalOpen,
    renderPanoramaOption,
    setIsPanoramaModalOpen,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
  } = useToolbar({
    columns,
    orderedColNames,
    usePaymentRequests,
    handlePanoramaChange,
  });

  return (
    <Container>
      <div className="page-tools">
        <PageToolsContainer>
          {/* Total */}
          <div className="balance-tool">
            <Balance value={data?.balance} variant={EBalanceVariant.Red} />
          </div>{" "}
          {/* Panoramas */}
          <div>
            <Dropdown
              id="sel-panorama"
              data-testid="sel-panorama"
              optionLabel="name"
              value={selectedPanorama}
              options={panoramaOptions}
              panelStyle={{ width: "230px" }}
              placeholder="Padrão do sistema"
              filterPlaceholder="Pesquise um panorama"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              itemTemplate={renderPanoramaOption}
              onChange={handlePanoramaDropdownChange}
              filter
            />
            {loading && <i className="pi pi-spin pi-spinner" />}
          </div>
          {/* Opções */}
          <div>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-testid="btn-options"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              {/* Salvar Panorama */}
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleSavePanoramaButtonClick}
              >
                <FaDatabase />
                <span title="Panorama">Salvar Panorama</span>
              </button>
              {/* Importar Solicitações */}
              <Link
                target="_blank"
                to={`${SoulRoutes.PAYMENT_REQUESTS.path}/import`}
              >
                <button
                  type="button"
                  id="btn-pano-save"
                  className="dropdown-item"
                  data-testid="btn-pano-save"
                >
                  <FaFileExcel />
                  <span title="Importar Solicitações">
                    Importar Solicitações
                  </span>
                </button>
              </Link>
            </SoulDropdown>
          </div>
          <div>
            <Link
              target="_blank"
              to={`${SoulRoutes.PAYMENT_REQUESTS.path}/new`}
            >
              <button id="btn-add" type="button" className="default-button">
                <FaPlus /> Solicitar Pagamento
              </button>
            </Link>
          </div>
        </PageToolsContainer>
      </div>
      <div className="filter-tools">
        <FilterToolsContainer>
          {showClearButton && (
            <div className="clr-filter-btn-wrapper">
              <button
                id="btn-clear"
                data-testid="btn-clear"
                className="default-button clear-filter-button"
                type="button"
                onClick={handleClearButtonClick}
              >
                <RiFilterOffFill />
                Limpar filtros
              </button>
            </div>
          )}
        </FilterToolsContainer>
      </div>
      {isPanoramaModalOpen && (
        <PanoramaFormModal
          pfsEvent={pfsEvent}
          isOpen={isPanoramaModalOpen}
          selectedColumns={selectedColumns}
          onRequestClose={() => {
            setIsPanoramaModalOpen(false);
          }}
          onPanoramaSave={handlePanoramaSave}
        />
      )}
    </Container>
  );
}
