import { CSSTransitionProps } from "primereact/csstransition";
import {
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
  DataTableSortOrderType,
} from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { RefObject, useCallback, useRef, useState } from "react";
import { useOnClickOutside } from "../../../../core/presentation/hooks/useOnClickOutside";
import {
  ISimpleColumn,
  ISimpleCustomColumn,
  ISimpleSortableColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { ColumnFilter } from "../ColumnFilter";
import { Container } from "./styles";

type ColumnFilterData = object;

interface AdvTableHeaderProps {
  column: SimpleTableColumn;
  sort: DataTableSortOrderType;
  filter?:
    | DataTableFilterMetaData
    | DataTableOperatorFilterMetaData
    | undefined;
  removable?: boolean;
  tableRef: RefObject<HTMLElement | undefined>;
  onSort(field: string, sortOrder: DataTableSortOrderType): void;
  onRemove?(fieldName: string): void;
  onFilter?(filterData: ColumnFilterData): void;
  onClear?(filterData: ColumnFilterData): void;
  onOpen?(overlayPanel: OverlayPanel): void;
}

export function AdvTableHeader({
  column,
  sort,
  filter,
  removable = false,
  tableRef,
  onSort,
  onRemove,
  onFilter,
  onClear,
  onOpen,
}: AdvTableHeaderProps) {
  const { field, header, sortField } = column as ISimpleSortableColumn;
  const { headerTemplate } = column as ISimpleCustomColumn;

  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [title, setTitle] = useState(`Ordenar ${header}`);

  const renderHeader = useCallback(() => {
    if (typeof headerTemplate === "function") {
      return headerTemplate(column as ISimpleColumn);
    }

    return header;
  }, [column, header, headerTemplate]);

  const handleSortOrder = useCallback(
    event => {
      event.stopPropagation();

      let order = sort;

      switch (order) {
        case 1: // asc
          order = -1;
          setTitle("Decrescente");
          break;

        case -1: // desc
          order = 0;
          setTitle(`Ordenar ${header}`);
          break;

        default:
          order = 1;
          setTitle("Crescente");
          break;
      }

      const sortingField = sortField || field;
      onSort(sortingField, order as DataTableSortOrderType);
    },
    [field, header, onSort, sort, sortField],
  );

  /**
   * Helper para identificar se um deterinado elemento do DOM está fora da área
   * útil da tela.
   */
  const isOffScreen = (element: HTMLElement) => {
    const tableEl = tableRef.current;

    if (tableEl) {
      const overlayPanelRect = element.getBoundingClientRect();
      const originRectWidth = overlayPanelRect.x + overlayPanelRect.width;
      const tableRect = tableEl?.getBoundingClientRect();
      const tableRectWidth = tableRect.x + tableRect.width;

      if (originRectWidth >= tableRectWidth) {
        return { right: true };
      }

      if (overlayPanelRect.x <= tableRect.x) {
        return { left: true };
      }
    }

    return null;
  };

  /**
   * Função para corrigir a posicao do elemento DOM do overlayPanel utilizando
   * a largura efetiva da tabela na tela.
   *
   * O comportamento padrao do overlayPanel (primereact) causa alguns bugs
   * quando as colunas da table são redimensionadas na UI.
   */
  const adjustPositionRight = (element: HTMLElement) => {
    const panelEl = element;
    const tableEl = tableRef.current;

    if (tableEl) {
      const panelRect = panelEl.getBoundingClientRect();
      const tableRect = tableEl.getBoundingClientRect();

      const calculatedLeft =
        window.scrollX + tableRect.x + tableRect.width - panelRect.width - 8;

      panelEl.style.left = `${calculatedLeft}px`;
    }
  };

  /**
   * Função para corrigir a posicao do elemento DOM do overlayPanel utilizando
   * a posição efetiva da tabela na tela.
   *
   * O comportamento padrao do overlayPanel (primereact) causa alguns bugs
   * quando as colunas da table são redimensionadas na UI.
   */
  const adjustPositionLeft = (element: HTMLElement) => {
    const panelEl = element;
    const tableEl = tableRef.current;

    if (tableEl) {
      const tableRect = tableEl.getBoundingClientRect();

      const calculatedLeft = window.scrollX + tableRect.x + 8;

      panelEl.style.left = `${calculatedLeft}px`;
    }
  };

  const handleShow = () => {
    if (overlayPanelRef?.current) {
      const overlayPanelElement = overlayPanelRef?.current?.getElement();

      const isOff = isOffScreen(overlayPanelElement);

      if (isOff?.right) {
        adjustPositionRight(overlayPanelElement);
      }

      if (isOff?.left) {
        adjustPositionLeft(overlayPanelElement);
      }
    }
  };

  const handleOnClick = useCallback(
    event => {
      if (overlayPanelRef?.current) {
        overlayPanelRef?.current.toggle(event);
        onOpen?.(overlayPanelRef.current);
      }
    },
    [onOpen],
  );

  const handleOnFilter = useCallback(
    event => {
      onFilter?.(event);
      overlayPanelRef.current?.hide();
    },
    [onFilter],
  );

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    overlayPanelRef?.current?.hide();
  });

  return (
    <Container
      ref={ref}
      title={header}
      onClick={handleOnClick}
      filtered={!!filter || !!sort}
    >
      <span>{renderHeader()}</span>
      <button
        type="button"
        className="sort-button"
        title={title}
        onClick={handleSortOrder}
      >
        {(sort === 0 || !sort) && <i className="pi pi-sort" />}
        {sort === 1 && <i className="pi pi-sort-up" />}
        {sort === -1 && <i className="pi pi-sort-down" />}
      </button>
      <OverlayPanel
        ref={overlayPanelRef}
        onShow={handleShow}
        // removemos a animacao de fade do overlayPanel para melhorar a estetica
        // que foi comprometida quando fizemos o workaround para corrigir o bug
        // do desalinhamento do dropdown do filtro de coluna quando fora da tela
        transitionOptions={{ disabled: true } as unknown as CSSTransitionProps}
      >
        <ColumnFilter
          removeable={removable}
          column={column}
          filter={filter}
          onRemove={onRemove}
          onClear={onClear}
          onFilter={handleOnFilter}
        />
      </OverlayPanel>
    </Container>
  );
}
