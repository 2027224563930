import { Checkbox } from "primereact/checkbox";
import { ContextMenu } from "primereact/contextmenu";
import { ProgressSpinner } from "primereact/progressspinner";
import { FormProvider } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { AdvTable } from "../../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../../advTable/presentation/components/ColumnSelector";
import { IFuspAccountPayableIntegrationEntity } from "../../../domain/entities/fuspAccountPayableIntegrationEntity";
import { useExportSheetFuspFormModal } from "../../hooks/useExportSheetFuspFormModal";
import { ExportSheetFuspInputEndDate } from "../ExportSheetFuspInputEndDate";
import { ExportSheetFuspInputStartDate } from "../ExportSheetFuspInputStartDate";
import { Container } from "./styles";

export function ExportSheetFuspFormModal() {
  const {
    state,
    exportSheetFuspFormModalOpen,
    handleClose,
    handleModalAfterOpen,
    handleModalAfterClose,
    formProps,
    submitHandler,
    handleSendFuspButtonClick,
    readOnly,
    isValid,
    columns,
    handleColumnSelect,
    tableRef,
    handleGetList,
    handleRowClick,
    handleSelectionChange,
    handleColumnRemove,
    handleColReorder,
    isTableValid,
    handleSelectedAllInActiveFilterCheckboxChange,
    handleSelectableData,
    handleRowClassName,
    containerRef,
    menuRef,
    menuModel,
  } = useExportSheetFuspFormModal();

  return (
    <Modal
      isOpen={exportSheetFuspFormModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.loading}
      onAfterOpen={handleModalAfterOpen}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={!state.loading}
      overlayClassName="react-modal-overlay accounts-payable__modal--fusp-export"
    >
      <Container ref={containerRef}>
        <div className="react-modal-header">
          <h4>Exportação de Planilha FUSP</h4>
          {!state.loading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {state.loading && (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        )}

        {!state.loading && (
          <div className="form-container">
            <div className="react-modal-body">
              {/* linha superior: form */}
              <div className="form-row">
                <div className="col-6">
                  <FormProvider {...formProps}>
                    <form onSubmit={formProps.handleSubmit(submitHandler)}>
                      <div className="form-row">
                        {/* campo data inicial */}
                        <label className="col-4 form-control">
                          <span>Data Inicial</span>
                          <ExportSheetFuspInputStartDate disabled={readOnly} />
                        </label>

                        {/* campo data final */}
                        <label className="col-4 form-control">
                          <span>Data Final</span>
                          <ExportSheetFuspInputEndDate disabled={readOnly} />
                        </label>

                        {/* botao gerar planilha */}
                        <div className="col-4 form-control">
                          <span>&nbsp;</span>
                          <button
                            id="btn-generate-sheet"
                            data-testid="btn-generate-sheet"
                            type="submit"
                            className={`default-button ${
                              !isValid ? "invalid-bkg" : ""
                            }`.trim()}
                          >
                            Gerar planilha
                          </button>
                        </div>
                      </div>
                    </form>
                  </FormProvider>

                  <div className="form-row">
                    <div className="col-12">
                      Exportou a última vez: {state.lastExportedUserName}
                    </div>
                  </div>
                </div>
              </div>

              {/* linha inferior: tabela */}
              <div className="form-row fusp-form-modal__form-row--grid">
                <div className="col-12 accounts-payable__modal-fusp--padding">
                  <Checkbox
                    data-tip
                    data-for="checkbox-tooltip"
                    checked={state.selectedAllInActiveFilter}
                    id="chk-all-registers-filtered"
                    data-testid="chk-all-registers-filtered"
                    onChange={handleSelectedAllInActiveFilterCheckboxChange}
                  />
                  <ReactTooltip
                    place="right"
                    effect="solid"
                    id="checkbox-tooltip"
                    disable={!state.data?.data.length}
                  >
                    Selecionar todos os registros filtrados
                  </ReactTooltip>
                </div>
                <div className="col-12">
                  <ColumnSelector
                    columns={columns}
                    selectedColumns={state.selectedColumns}
                    onSelect={handleColumnSelect}
                  />
                  <AdvTable<IFuspAccountPayableIntegrationEntity>
                    selectable
                    data={state.data}
                    tableRef={tableRef}
                    rowsDefault={10}
                    columns={columns}
                    removeableColumns
                    loading={state.isFetching}
                    selection={
                      state.selectedAllInActiveFilter
                        ? state.data?.data
                        : state.selection
                    }
                    onPage={handleGetList}
                    onSort={handleGetList}
                    onClear={handleGetList}
                    selectionMode="multiple"
                    onFilter={handleGetList}
                    selectionAutoFocus={false}
                    selectedColumns={state.selectedColumns}
                    rowsPerPageOptions={[10, 50, 100]}
                    onRowClick={handleRowClick}
                    onSelectionChange={handleSelectionChange}
                    onColumnRemove={handleColumnRemove}
                    onColReorder={handleColReorder}
                    rowClassName={handleRowClassName}
                    isDataSelectable={handleSelectableData}
                  />
                </div>
                <div className="col-12">&nbsp;</div>
              </div>
            </div>

            <div className="react-modal-footer">
              {/* botao cancelar */}
              <button
                id="btn-close"
                type="button"
                className="form-button red-bkg"
                onClick={handleClose}
              >
                Cancelar
              </button>

              {/* botao submit */}
              <button
                id="btn-return"
                type="button"
                disabled={state.submitting}
                className={`form-button ${
                  isTableValid ? "green-bkg" : "invalid-bkg"
                }`}
                onClick={handleSendFuspButtonClick}
              >
                Enviar FUSP{" "}
                {state.submitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </div>
        )}
      </Container>

      <ContextMenu model={menuModel} ref={menuRef} style={{ width: "220px" }} />
    </Modal>
  );
}
