import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IProjectEntity } from "../../../domain/entities/projectEntity";
import { MakeProjects, makeProjects } from "../../../main/makeProjects";
import { useProjectsGrid } from "../../hooks/useProjectsGrid";
import { ExportingModalContent } from "../ExportingModalContent";
import { ProjectFormModal } from "../ProjectFormModal";
import { ProjectTaxesModal } from "../ProjectTaxesModal";
import { Container } from "./style";

interface ProjectsPageState {
  search: string;
  modalOpen: number;
  isLoading: boolean;
  globalFilter: string;
  showActivesOnly: boolean;
  pfsEvent: IPFSEventEntity;
  data?: IServerSideResponseModel<IProjectEntity[]>;
  currentProject: Pick<
    IProjectEntity,
    "id" | "description" | "competencyName"
  > | null;
}

interface ProjectsPageProps {
  useProjects: MakeProjects;
}

export enum ProjectsPageModalType {
  None,
  Crud,
  Taxes,
}

function ProjectsPage({ useProjects }: ProjectsPageProps) {
  const { listProjects, toggleProject, exportProjects } = useProjects;

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const tableRef = useRef<ISimpleTableHandle>(null);

  const canAdd = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const [state, setState] = useState<ProjectsPageState>({
    search: "",
    data: undefined,
    globalFilter: "",
    isLoading: false,
    currentProject: null,
    showActivesOnly: false,
    pfsEvent: new PFSEventEntity(),
    modalOpen: ProjectsPageModalType.None,
  });

  const handleOpenModal = useCallback(
    (info: IProjectEntity, type: ProjectsPageModalType) => {
      setState(prevState => ({
        ...prevState,
        modalOpen: type,
        currentProject: {
          id: info.id,
          description: info.description,
          competencyName: info.competencyName,
        },
      }));
    },
    [],
  );

  const handleCloseModal = useCallback((shouldRefresh: boolean | unknown) => {
    if (typeof shouldRefresh === "boolean" && shouldRefresh)
      tableRef.current?.reload();

    setState(prevState => ({
      ...prevState,
      currentProject: null,
      modalOpen: ProjectsPageModalType.None,
    }));
  }, []);

  const handleToggleProject = useCallback(
    async (projectId: string, actionText: string) => {
      try {
        await toggleProject(projectId);
        tableRef.current?.reload();
        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `O projeto foi ${actionText} com sucesso!`,
        });
      } catch (error) {
        dialog.close();
      }
    },
    [dialog, toggleProject],
  );

  const { columns } = useProjectsGrid({
    handleOpenModal,
    handleToggleProject,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        isLoading: true,
        pfsEvent: _pfsEvent,
      }));

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listProjects(payload, id, state.showActivesOnly);
        setState(prevState => ({
          ...prevState,
          data: response,
        }));
      } catch (error) {
        return;
      } finally {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
        ReactTooltip.rebuild();
      }
    },
    [
      id,
      columns,
      mountedRef,
      listProjects,
      generatePayload,
      state.showActivesOnly,
    ],
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState(prevState => ({
        ...prevState,
        search: e.target.value,
      }));
      debounceTime(() => {
        setState(prevState => ({
          ...prevState,
          globalFilter: e.target.value,
        }));
      }, 700);
    },
    [debounceTime],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showActivesOnly: value,
    }));

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(state.pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportProjects({
        payload,
        companyGroupId: id,
        showActivesOnly: state.showActivesOnly,
      });
    } finally {
      dialog.close();
    }
  };

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            value={state.search}
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={state.showActivesOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-testid="btn-options"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                type="button"
                className="dropdown-item"
                onClick={handleExportSheet}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
            </SoulDropdown>
            {canAdd ? (
              <button
                type="button"
                className="default-button"
                id="btn-add"
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    modalOpen: ProjectsPageModalType.Crud,
                  }));
                }}
              >
                <FaPlus /> Adicionar Projeto
              </button>
            ) : null}
          </GridOptionsContainer>
          <ProjectFormModal
            useProjects={useProjects}
            onClose={handleCloseModal}
            projectId={state.currentProject?.id || ""}
            isOpen={state.modalOpen === ProjectsPageModalType.Crud}
          />
          <ProjectTaxesModal
            useProjects={useProjects}
            onClose={handleCloseModal}
            projectId={state.currentProject?.id || ""}
            isOpen={state.modalOpen === ProjectsPageModalType.Taxes}
            projectDescription={state.currentProject?.description || ""}
            projectCompetencyName={state.currentProject?.competencyName || ""}
          />
        </header>
        <article className="no-padding">
          <SimpleTable
            data={state.data}
            columns={columns}
            getList={getList}
            tableRef={tableRef}
            loading={state.isLoading}
            globalFilter={state.globalFilter}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ProjectsPageFactoryProps {
  api: IApiService;
}

export function ProjectsPageFactory({ api }: ProjectsPageFactoryProps) {
  return <ProjectsPage useProjects={makeProjects(api)} />;
}
