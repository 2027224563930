import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICompanyGroupEntity } from "../../domain/entities/companyGroupEntity";
import { IListCompanyGroupsService } from "../../domain/usecases/listCompanyGroupsUseCase";

export class ListCompanyGroupsService implements IListCompanyGroupsService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICompanyGroupEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<ICompanyGroupEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
