import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ClientSoulTable } from "../../../../core/presentation/components/ClientSoulTable";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { IPecegePayAccountReceivableFeeTaxEntity } from "../../../domain/entities/pecegePayAccountReceivableFeeTaxEntity";
import { MakePecegePayAccountReceivableFees } from "../../../main/makePecegePayAccountReceivableFees";
import { useManagePecegePayAccountReceivableFeesModalGrid } from "../../hooks/useManagePecegePayReceivableFeesModalGrid";
import { ModalInfoContainer } from "../ModalComponents/styles";
import { Container } from "./styles";

interface ManagePecegePayAccountReceivableFeesModalProps {
  feeId: string;
  isOpen: boolean;
  onClose(): void;
  getPecegePayAccountReceivableFeeTaxes: MakePecegePayAccountReceivableFees["getPecegePayAccountReceivableFeeTaxes"];
}

interface ManagePecegePayAccountReceivableFeesModalState {
  isLoading: boolean;
  data: Array<IPecegePayAccountReceivableFeeTaxEntity>;
}

export function ManagePecegePayAccountReceivableFeesModal(
  props: ManagePecegePayAccountReceivableFeesModalProps,
) {
  const { isOpen, feeId, onClose, getPecegePayAccountReceivableFeeTaxes } =
    props;

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { columns } = useManagePecegePayAccountReceivableFeesModalGrid();

  const [state, setState] =
    useState<ManagePecegePayAccountReceivableFeesModalState>({
      data: [],
      isLoading: false,
    });

  const handleAfterOpen = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const data = await getPecegePayAccountReceivableFeeTaxes(feeId);

      setState(prevState => ({
        ...prevState,
        data,
        isLoading: false,
      }));
    } catch {
      setState(prevState => ({
        ...prevState,
        data: [],
        isLoading: false,
      }));

      onClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerenciar Taxas</h4>
          <button
            type="button"
            id="btn-manage-fees-cross"
            disabled={state.isLoading}
            className="react-modal-close"
            data-testid="btn-manage-fees-cross"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <ModalInfoContainer>
              <p>Este registro está vinculado ao grupo de empresa</p>
              <h3>{currentCompanyGroup.name}</h3>
            </ModalInfoContainer>
            <div className="react-modal-body">
              <ClientSoulTable
                scrollable
                data={state.data}
                columns={columns}
                scrollHeight="500px"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage="Nenhuma tarifa encontrada"
              />
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-manage-fees-close"
                className="form-button red-bkg"
                data-testid="btn-manage-fees-close"
                onClick={onClose}
              >
                Fechar
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </ReactModal>
  );
}
