import { IPaymentAccountEntity } from "./paymentAccountEntity";

export type IPaymentAccountFormEntity = Pick<
  IPaymentAccountEntity,
  | "companyId"
  | "name"
  | "bank"
  | "bankDescription"
  | "openingBalance"
  | "bankAccountType"
  | "bankBranch"
  | "bankBranchDigit"
  | "bankAccount"
  | "bankAccountDigit"
>;

export class PaymentAccountFormEntity implements IPaymentAccountFormEntity {
  companyId = "";
  name = "";
  bankAccountType = "";
  openingBalance = 0;
  bank = null;
  bankDescription = "";
  bankBranch = "";
  bankBranchDigit = "";
  bankAccount = "";
  bankAccountDigit = "";

  constructor(init?: Partial<IPaymentAccountFormEntity>) {
    Object.assign(this, init);
  }

  static create(params?: Partial<IPaymentAccountFormEntity>) {
    return new PaymentAccountFormEntity(params);
  }
}
