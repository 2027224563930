export interface IPaymentAccountTransactionInputModel {
  id: string;
  companyGroupId: string;
  companyId: string;
  paymentAccountOriginId: string;
  paymentAccountDestinationId: string;
  transactionDate: string;
  value: number;
  description: string;
  active: boolean;
}

export class PaymentAccountTransactionInputModel
  implements IPaymentAccountTransactionInputModel
{
  id = "";
  companyGroupId = "";
  companyId = "";
  paymentAccountOriginId = "";
  paymentAccountDestinationId = "";
  transactionDate = "";
  value = 0;
  description = "";
  active = true;

  constructor(params?: Partial<IPaymentAccountTransactionInputModel>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IPaymentAccountTransactionInputModel>) {
    return new PaymentAccountTransactionInputModel(params);
  }
}
