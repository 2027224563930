import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IGetPaymentAccountBanksContract } from "../domain/contracts/getPaymentAccountBanksContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";
import { IImportationMatchesSourceEntity } from "../domain/entities/importationMatchesSourceEntity";

export class GetPaymentAccountBanksService
  implements IGetPaymentAccountBanksContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPaymentAccountBanks(
    paymentAccountId: string,
    origin: EImportationMatchesOrigin,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = `/ImportationMatches/${origin}/PaymentAccount/${paymentAccountId}/Banks`;

    return this.api.get<IImportationMatchesSourceEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
