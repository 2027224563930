import { format } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  CostCenterTransactionInputEntity,
  ICostCenterTransactionInputEntity,
} from "../../../../transactions/costCenterTransactions/domain/entities/costCenterTransactionInputEntity";
import { CostCenterEntity } from "../../domain/entities/costCenterEntity";
import { IGetCostCenterTransactionService } from "../../domain/usecases/getCostCenterTransactionUseCase";
import { ICostCenterTransactionModel } from "../models/costCenterTransactionModel";

export class GetCostCenterTransactionsService
  implements IGetCostCenterTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCostCenterTransaction(
    costCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionInputEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Manager/CostCenterTransactions/${costCenterTransactionsId}`;

    const response = await this.api.get<ICostCenterTransactionModel>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const costCenterTransactionsEntity = new CostCenterTransactionInputEntity({
      id: response.id,
      companyGroupId: response.companyGroupId,
      costCenterOrigin: new CostCenterEntity({
        id: response.costCenterOriginId,
        acronym: response.costCenterOriginName,
      }),
      costCenterDestination: new CostCenterEntity({
        id: response.costCenterDestinationId,
        acronym: response.costCenterDestinationName,
      }),
      transactionDate: format(new Date(response.transactionDate), "ddMMyyyy"),
      value: response.value,
      description: response.description,
      active: response.active,
    });

    return costCenterTransactionsEntity;
  }
}
