import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateMacroCategoryContract } from "../../domain/contracts/updateMacroCategoryContract";
import { IMacroCategoryEntity } from "../../domain/entities/macroCategoryEntity";
import { IMacroCategoryFormEntity } from "../../domain/entities/macroCategoryFormEntity";

export class UpdateMacroCategoryService
  implements IUpdateMacroCategoryContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateMacroCategory(
    macroCategoryId: string,
    payload: IMacroCategoryFormEntity,
  ) {
    const userEntity = this.getUserLocalService.get();

    const url = "/MacroBoxes";

    const finalPayload = {
      ...payload,
      id: macroCategoryId,
    };

    return this.api.put<IMacroCategoryEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
