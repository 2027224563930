export interface IResetUserPasswordModel {
  partnerId: string;
}

export class ResetUserPasswordModel implements IResetUserPasswordModel {
  partnerId = "";

  constructor(params?: Partial<IResetUserPasswordModel>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IResetUserPasswordModel>) {
    return new ResetUserPasswordModel(params);
  }
}
