import { useCallback, useMemo } from "react";
import { FaEdit, FaList } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  IProviderEntity,
  ProviderType,
} from "../../domain/entities/providerEntity";
import {
  ProviderPageModalType,
  ProviderPageState,
} from "../components/ProviderPage";

interface UseProviderGridParams {
  handleToggleProvider?(id: string): Promise<void> | void;
  handleOpenModal(newState: Partial<ProviderPageState>): void;
}

export function useProviderGrid(params: UseProviderGridParams) {
  const { handleOpenModal, handleToggleProvider } = params;

  const documentBodyTemplate = ({ document, type }: IProviderEntity) => {
    const providerType = ProviderType;

    if (document === "") {
      return <span>Não informado</span>;
    }

    const cpfRegExp = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    const cnpjRegExp = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    let maskedDocument = "";

    if (type === providerType.individualPerson) {
      maskedDocument = document.replace(cpfRegExp, "$1.$2.$3-$4");
      return <span>{maskedDocument}</span>;
    }

    if (type === providerType.legalPerson) {
      maskedDocument = document.replace(cnpjRegExp, "$1.$2.$3/$4-$5");
      return <span>{maskedDocument}</span>;
    }

    return <span>{document}</span>;
  };

  const editBodyTemplate = useCallback(
    (providerEntity: IProviderEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            data-tip="Vincular classificação(ões) contábil(eis)"
            type="button"
            className="outline-button tool"
            id={`btn-vinc-class-account-${rowIndex}`}
            data-testid={`btn-vinc-class-account-${rowIndex}`}
            onClick={() => {
              handleOpenModal({
                currentProvider: providerEntity,
                currentModal: ProviderPageModalType.ClassificationAccount,
              });
            }}
          >
            <FaList />
          </button>
          <button
            data-tip="Editar fornecedor"
            type="button"
            className="outline-button tool"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              handleOpenModal({
                currentProvider: providerEntity,
                currentModal: ProviderPageModalType.Crud,
              });
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [handleOpenModal],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: IProviderEntity, { rowIndex }) => {
      const props: Record<string, string> = {};

      return (
        <div {...props}>
          <Toggle
            value={active}
            inactiveClassName="toggle-muted"
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            onChange={async _active => {
              const text = !_active ? "inativado" : "ativado";
              const result = await dialog.fire({
                icon: "question",
                title: "Você está certo disso?",
                html: `O Fornecedor será ${text}. <br /> Deseja prosseguir?`,
                showCancelButton: true,
                cancelButtonText: "Não",
                preConfirm() {
                  dialog.update({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  dialog.showLoading();
                  return handleToggleProvider?.(id);
                },
              });

              if (result.isConfirmed) {
                return _active;
              }

              return !_active;
            }}
          />
        </div>
      );
    },
    [dialog, handleToggleProvider],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        width: "20%",
      },
      {
        field: "name",
        header: "Nome / Nome Fantasia",
        searchable: true,
        orderable: true,
      },
      {
        field: "typeDescription",
        header: "Tipo",
        searchable: true,
        orderable: true,
        sortField: "type",
      },
      {
        field: "document",
        header: "CNPJ / CPF / Documento",
        searchable: true,
        orderable: true,
        bodyTemplate: documentBodyTemplate,
      },

      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "type",
        hidden: true,
      },
      {
        field: "hasCompanyTax",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
