import { useState } from "react";
import { FaSpinner, FaTrash } from "react-icons/fa";
import { Button } from "./styles";
import { useIsMounted } from "../../hooks/useIsMounted";

interface ButtonDeleteProps {
  "data-tip"?: string;
  className?: string;
  id?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onClick?: (id: string) => unknown | ((id: string) => Promise<unknown>);
}

export function ButtonDelete({
  "data-tip": dataTip = "",
  className = "",
  id = "",
  "data-testid": dataTestId = "",
  disabled = false,
  onClick,
}: ButtonDeleteProps) {
  const [loading, setLoading] = useState(false);

  const isMountedRef = useIsMounted();

  return (
    <Button
      data-tip={dataTip}
      type="button"
      className={className}
      id={id}
      data-testid={dataTestId}
      disabled={disabled || loading}
      onClick={async () => {
        setLoading(true);

        try {
          await onClick?.(id);
        } finally {
          if (isMountedRef.current) {
            setLoading(false);
          }
        }
      }}
    >
      {loading ? <FaSpinner className="pulse" /> : <FaTrash />}
    </Button>
  );
}
