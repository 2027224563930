import { DropdownChangeParams } from "primereact/dropdown";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTrash } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../../advTable/domain/entities/panoramaEntity";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { PanoramaOption } from "../components/RequesterToolbar/styles";
import { MakePaymentRequests } from "../../main/makePaymentRequests";
import {
  ISimpleColumn,
  SimpleTableColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { ProgressModalContent } from "../../../../../core/presentation/components/Modals/ProgressModalContent";
import { usePaymentRequestPage } from "./usePaymentRequestPage";

interface IUseToolbarParams {
  orderedColNames: string[];
  columns: SimpleTableColumn[];
  usePaymentRequests: MakePaymentRequests;
  handlePanoramaChange(
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate?: boolean,
  ): void;
}

export function useToolbar(params: IUseToolbarParams) {
  const { orderedColNames, usePaymentRequests, handlePanoramaChange, columns } =
    params;

  const { state } = usePaymentRequestPage();

  const {
    savePaymentRequestPanorama,
    listPaymentRequestPanoramas,
    storePaymentRequestPanoramaId,
    deletePaymentRequestPanorama,
    getStoredPaymentRequestPanoramaId,
    exportPaymentRequest,
  } = usePaymentRequests;

  /** Esta é a representação do panorama padrão */
  const defaultPanorama = useMemo(() => {
    return PanoramaEntity.create({
      id: "default-panorama",
      name: "Padrão do sistema",
      systemDefault: true,
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [panoramaOptions, setPanoramaOptions] = useState<IPanoramaEntity[]>();
  const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
  const [selectedPanorama, setSelectedPanorama] =
    useState<IPanoramaEntity>(defaultPanorama);

  const dialog = useSoulDialog();
  const { user } = useUserLocal();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const currentCompanyGroupId = currentCompanyGroup.id;

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /** Abre modal p/ salvar um novo panorama */
  const handleSavePanoramaButtonClick = useCallback(() => {
    setIsPanoramaModalOpen(true);
  }, []);

  /** reseta o panorama selecionado para o panorama default */
  const resetToPanoramaDefault = useCallback(() => {
    const panoId = defaultPanorama.id;

    storePaymentRequestPanoramaId(panoId);
    setSelectedPanorama(defaultPanorama);
    handlePanoramaChange(defaultPanorama);
  }, [defaultPanorama, handlePanoramaChange, storePaymentRequestPanoramaId]);

  /** lida com o evento de troca de valor do dropdown de panoramas */
  const handlePanoramaDropdownChange = ({ value }: DropdownChangeParams) => {
    const panorama = value as IPanoramaEntity;
    const panoramaId = panorama.id;

    storePaymentRequestPanoramaId(panoramaId);
    setSelectedPanorama(panorama);
    handlePanoramaChange(panorama);
  };

  /** Deleta o panorama cujo botão "Excluir" foi clicado pelo usuario. */
  const handleDeletePanoramaButtonOnClick = useCallback(
    async (option: IPanoramaEntity, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const { name } = option;

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            await deletePaymentRequestPanorama(option.id);
          } finally {
            dialog.close();
          }

          return true;
        },
        html: (
          <>
            O panorama <b>{name}</b> será excluído permanentemente.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      setPanoramaOptions(prevPanOptions => {
        const index = prevPanOptions?.findIndex(pan => pan.id === option.id);

        if (index && prevPanOptions) {
          prevPanOptions?.splice(index, 1);
          return [...prevPanOptions];
        }

        return prevPanOptions;
      });

      resetToPanoramaDefault();

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            O panorama <b>{name}</b> foi excluído com sucesso.
          </>
        ),
      });
    },
    [deletePaymentRequestPanorama, dialog, resetToPanoramaDefault],
  );

  /** lida com o render das opcoes de panorama do dropdown */
  const renderPanoramaOption = useCallback(
    (option: IPanoramaEntity) => {
      const { name, systemDefault } = option;
      return (
        <PanoramaOption title={name}>
          <span>{name}</span>
          {!systemDefault && (
            <button
              title={`Excluir ${name}`}
              onClick={event =>
                handleDeletePanoramaButtonOnClick(option, event)
              }
              type="button"
            >
              <FaTrash />
            </button>
          )}
        </PanoramaOption>
      );
    },
    [handleDeletePanoramaButtonOnClick],
  );

  /** lida com o evento de salvamento de um novo panorama */
  const handlePanoramaSave = useCallback(
    async (panoramaData: IPanoramaEntity) => {
      const panoData = {
        ...panoramaData,
        panoramaDefinition: {
          ...panoramaData.panoramaDefinition,
          selectedColumns: orderedColNames
            .map(ordColName => {
              const index =
                panoramaData.panoramaDefinition.selectedColumns.findIndex(
                  selCol => {
                    return selCol.field === ordColName;
                  },
                );

              if (index > -1) {
                const [colDef] =
                  panoramaData.panoramaDefinition.selectedColumns.splice(
                    index,
                    1,
                  );

                return colDef;
              }

              return undefined;
            })
            .filter((col): col is IAdvTableColumn => !!col),
        },
      };

      try {
        const newPanorama = await savePaymentRequestPanorama(columns, panoData);

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Panorama adicionado com sucesso.",
        });

        setPanoramaOptions(prevPanoramaOptions => {
          if (prevPanoramaOptions) {
            return [...prevPanoramaOptions, newPanorama];
          }

          return [newPanorama];
        });
        setSelectedPanorama(newPanorama);
        storePaymentRequestPanoramaId(newPanorama.id);
      } finally {
        dialog.close();
      }
    },
    [
      dialog,
      columns,
      orderedColNames,
      savePaymentRequestPanorama,
      storePaymentRequestPanoramaId,
    ],
  );

  /**
   * Obtém lista para preencher o dropdown de panoramas.
   * Também resgata o último panorama utilizado por este usuário, definindo
   * como atual valor do dropdown de panoramas.
   */
  const fetchPanoramas = useCallback(async () => {
    const { userId } = user;

    setLoading(true);

    const panoramas = await listPaymentRequestPanoramas(userId, columns);

    const panoramaList = [defaultPanorama, ...panoramas];
    setPanoramaOptions(panoramaList);

    setLoading(false);

    const panoramaId =
      getStoredPaymentRequestPanoramaId() || defaultPanorama.id;

    if (panoramaId) {
      const panorama = panoramaList.find(pano => pano.id === panoramaId);

      if (panorama) {
        setSelectedPanorama(panorama);
        handlePanoramaChange(panorama);
      }
    }
  }, [
    user,
    columns,
    defaultPanorama,
    handlePanoramaChange,
    listPaymentRequestPanoramas,
    getStoredPaymentRequestPanoramaId,
  ]);

  /** lida com o evento de click do botao de exportar contas a pagar */
  const handleOnExportButtonClick = async () => {
    if (!state.pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ProgressModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      await exportPaymentRequest(
        currentCompanyGroupId,
        state.pfsEvent,
        state.selectedColumns as ISimpleColumn[],
        state.orderedColNames,
      );
    } finally {
      dialog.close();
    }
  };

  useEffect(() => {
    fetchPanoramas();
    // REVIEW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      resetToPanoramaDefault();
    }
  }, [currentCompanyGroupId, resetToPanoramaDefault]);

  return {
    loading,
    panoramaOptions,
    selectedPanorama,
    handlePanoramaSave,
    isPanoramaModalOpen,
    renderPanoramaOption,
    setIsPanoramaModalOpen,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
    handleOnExportButtonClick,
  };
}
