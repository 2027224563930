import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .cards-container {
    width: 100%;

    gap: 2rem;
    display: flex;
    flex-wrap: wrap;

    .no-padding {
      padding: 0;
    }
  }

  footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    font-size: 0.875rem;
    color: var(--text-muted);

    padding: 6rem 0 2rem;

    @media (min-width: 1205px) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    a {
      color: #5e72e4;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: #233dd2;
      }
    }
  }
`;
