import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchAccountPayableAssessmentListContract } from "../../domain/contracts/fetchAccountPayableAssessmentListContract";
import { IAccountPayableAssessmentListItemEntity } from "../../domain/entities/accountPayableAssessmentListItemEntity";

interface IAccountPayableAssessmentListItemModel {
  observation: string;
  costCenterName: string;
  classificationAssessmentName: string;
  value: number;
  percentage: number;
}

export class FetchAccountPayableAssessmentListService
  implements IFetchAccountPayableAssessmentListContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async fetchAccountPayableAssessmentLiist(
    accountPayableId: string,
  ): Promise<IAccountPayableAssessmentListItemEntity[]> {
    const url = `/AccountsPayable/${accountPayableId}/Assessments`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.get<
      IAccountPayableAssessmentListItemModel[]
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
