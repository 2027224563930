import styled from "styled-components";
import {
  EPaymentRequestDestination,
  EPaymentRequestStatus,
} from "../../../domain/entities/paymentRequestEnums";

export const Container = styled.section`
  width: 100%;
  height: inherit;

  a {
    text-decoration: none;
  }

  .custom-header {
    gap: 0.5rem;
    display: flex;
    padding-left: 1rem;
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    .custom-header {
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  .src-fld {
    width: 100%;
    max-width: 20rem;
  }

  .button-wrapper {
    flex-grow: 1;
    display: inline-flex;
    justify-content: center;
  }

  .full-page {
    display: flex;
    height: inherit;
    flex-direction: column;
  }

  .empty-message {
    position: relative;
    height: 2.625rem;

    div {
      background-color: #f1f1f1;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      color: #8898aa;
      border: 0.0625rem solid #e1e1e1;
      position: fixed;
      left: calc(50vw - 3.75rem - 6.1875rem);
    }
  }

  .text-muted {
    color: var(--text-muted);
  }

  .no-padding {
    padding: 0;

    &.fill-height {
      height: inherit;
      display: flex;
      flex-direction: column;
      /**
       * 9rem é basicamente a distancia entre o top do viewport e o top
       * do * elemento .no-padding + uma espaco de respiro entre o bottom
       * do .no-padding e o bottom do viewport.
        *
       * Esta estratégia usando calc com 100vh e um valor fixo está
       * presente na documentacao do PrimeReact > Table > Flexscroll.
       */
      height: calc(100vh - 21.5rem);

      @media screen and (min-width: 992px) {
        height: calc(100vh - 13rem);
      }

      @media screen and (min-width: 1200px) {
        height: calc(100vh - 9rem);
      }
    }
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody {
    td.text-truncate {
      overflow: hidden;
      white-space: nowrap;
    }

    tr.p-selectable-row {
      :is(.row-completed) {
        background: #d0f1e6;

        &:is(.p-row-odd) {
          background: #dcf5ec;
        }

        &:hover {
          background: #ebf9f4;
        }
      }

      :is(.row-requested) {
        background: #ffdeac;

        &:is(.p-row-odd) {
          background: #ffe4bb;
        }

        &:hover {
          background: #ffe8c6;
        }
      }

      :is(.row-rejected) {
        background: #fcc7d2;

        &:is(.p-row-odd) {
          background: #fdd6de;
        }

        &:hover {
          background: #fddfe5;
        }
      }

      :is(.row-clicked) {
        opacity: 1;
        color: white;
        background: #525f7f;

        :is(.p-row-odd) {
          background: #525f7f;
        }

        &:hover {
          opacity: 1;
          color: white;
          background: #525f7f;
        }
      }
    }
  }
`;

type IBadgeProps =
  | {
      destination?: never;
      status: EPaymentRequestStatus;
    }
  | {
      status?: never;
      destination: EPaymentRequestDestination;
    };

export const Badge = styled.div<IBadgeProps>`
  display: inline-block;

  min-width: 7rem;
  padding: 0.5rem;
  border-radius: 10rem;

  font-weight: 600;
  font-size: 0.7rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;

  background-color: ${props => {
    if (props.status !== undefined) {
      const statusColors = {
        [EPaymentRequestStatus.NotRequested]: "#1700FF",
        [EPaymentRequestStatus.Requested]: "#ff6f00",
        [EPaymentRequestStatus.Canceled]: "#dcd6d6",
        [EPaymentRequestStatus.Approved]: "#009b2f",
        [EPaymentRequestStatus.Rejected]: "#f5365c",
        [EPaymentRequestStatus.Paid]: "#009b2f",
      };

      return statusColors[props.status];
    }

    const destinationColors = {
      [EPaymentRequestDestination.Financial]: "#090081",
      [EPaymentRequestDestination.PersonnelDepartment]: "#e58e26",
    };

    return destinationColors[props.destination];
  }};

  color: ${props => {
    const isCanceled = props?.status === EPaymentRequestStatus.Canceled;

    return isCanceled ? "#495057" : "white";
  }};
`;

export const EmptyMessageContainer = styled.div`
  width: 100%;

  div {
    width: 16rem;
    color: #8898aa;
    position: sticky;
    text-align: center;
    left: calc(50% - 8rem);
  }
`;
