import { useFormContext } from "react-hook-form";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountReceivableAttachmentEntity } from "../../domain/entities/accountReceivableAttachmentEntity";
import { IAccountReceivableFormEntity } from "../../domain/entities/accountReceivableFormEntity";
import { MakeAccountsReceivableForm } from "../../main/makeAccountsReceivableForm";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";

interface IAttachmentsHandlerParams {
  useReceivableForm: MakeAccountsReceivableForm;
}

export function useAttachmentsHandler(params: IAttachmentsHandlerParams) {
  const { useReceivableForm } = params;

  const { getStorageFilebyId, listAttachmentTypes } = useReceivableForm;

  const dialog = useSoulDialog();
  const form = useFormContext<IAccountReceivableFormEntity>();

  const { setValue, watch, getValues } = form;

  const attachmentsWatcher = watch("storageFiles");

  const handleAttachmentListChange = (
    attachments: IAccountReceivableAttachmentEntity[],
  ) => {
    setValue("storageFiles", attachments);
  };

  const attachmentTypeCheck = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IAccountReceivableAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.RPA &&
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.VariableAdditional
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.RPA]: "RPA",
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.VariableAdditional]: "Adicional Variável",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const canDeleteAttachment = (
    attachment: IAccountReceivableAttachmentEntity,
  ) => {
    const isLmsImportation = getValues("isLmsImportation");

    if (isLmsImportation && attachment?.fileUrl) {
      return false;
    }

    return true;
  };

  return {
    attachmentsWatcher,
    getStorageFilebyId,
    attachmentTypeCheck,
    listAttachmentTypes,
    canDeleteAttachment,
    handleAttachmentListChange,
  };
}
