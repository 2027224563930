import { useCallback, useEffect, useRef, useState } from "react";
import { useColumnFilter } from "../../hooks/useColumnFilter";
import { Container } from "./styles";

interface ColumnFilterDecimalProps {
  minimumFractionDigits?: number;
}

export function ColumnFilterDecimal({
  minimumFractionDigits = 1,
}: ColumnFilterDecimalProps) {
  const { setFilterValue, filterValue } = useColumnFilter();

  const [numValue, setNumValue] = useState<number | null>(() => {
    if (!filterValue) {
      return null;
    }

    return (filterValue as number) * 100;
  });

  const formatNumber = useCallback(
    (numVal: number | null) => {
      if (numVal === null) {
        return "";
      }

      return `${new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits,
      }).format(numVal)}%`;
    },
    [minimumFractionDigits],
  );

  const handleOnChange = useCallback(
    ({ target: { value } }) => {
      const formattedZero = `0,${"0".repeat(minimumFractionDigits)}`;

      if (value === formattedZero) {
        setNumValue(null);
        setFilterValue?.(null);
      } else {
        const strVal = value.replace(/\D/g, "");
        const numVal = Number(strVal) / 10;

        setNumValue(numVal);
        setFilterValue?.(numVal / 100);
      }
    },
    [minimumFractionDigits, setFilterValue],
  );

  const handleOnFocus = useCallback(
    event => event.target.setSelectionRange(0, event.target.value.length),
    [],
  );

  const inputRef = useRef<HTMLInputElement | null>();

  // esse useEffect é necessario, este campo exibe a porcentagem formatada com
  // um sinal de % no final da string e para que o backspace e a digitação
  // funcionem adequadamente precisamos posicionar o ponto de inserção sempre
  // antes do sinal de %
  useEffect(() => {
    const element = inputRef.current;
    const value = element?.value ?? "";
    const position = value.length ? value.length - 1 : 0;
    element?.setSelectionRange(position, position);
  }, [numValue]);

  return (
    <Container>
      <div className="p-inputgroup">
        <input
          ref={ref => {
            ref?.focus?.();
            inputRef.current = ref;
          }}
          className="p-inputtext p-component"
          value={formatNumber(numValue)}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          placeholder="Digite algo..."
        />
      </div>
    </Container>
  );
}
