import { useState } from "react";
import { Controller } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { SoulTypeahead } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";

import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";

interface IFormSectionCompanyDataState {
  company: {
    isLoading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
}

interface IFormSectionCompanyDataProps {
  readonly?: boolean;
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function FormSectionCompanyData(props: IFormSectionCompanyDataProps) {
  const { usePaymentRequestForm, readonly = false } = props;

  const { searchCompany } = usePaymentRequestForm;

  const [state, setState] = useState<IFormSectionCompanyDataState>({
    company: {
      options: [],
      isLoading: false,
    },
  });

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const handleSearchCompanyChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      company: {
        ...prevState.company,
        isLoading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompany({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        company: {
          isLoading: false,
          options: companies,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        company: {
          ...prevState.company,
          isLoading: false,
        },
      }));
    }
  };

  return (
    <div className="form-row">
      <Controller
        name="company"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          const { isLoading, options } = state.company;

          return (
            <label className="col-6 form-control">
              <span>
                Empresa{" "}
                {isLoading ? <i className="pi pi-spin pi-spinner" /> : null}
              </span>
              <SoulTypeahead
                serverSide
                openOnFocus
                id="company"
                options={options}
                value={field.value}
                loading={isLoading}
                disabled={readonly}
                placeholder="Empresa"
                data-testid="txt-company"
                onChange={field.onChange}
                onSearchChange={handleSearchCompanyChange}
                className={fieldState?.error ? "isInvalid" : ""}
              />
              <InvalidFeedback
                message="Este campo é obrigatório"
                condition={fieldState?.error?.type === "required"}
              />
            </label>
          );
        }}
      />
    </div>
  );
}
