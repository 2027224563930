import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IListPaymentAccountTransactionAttachmentsContract } from "../../domain/contracts/listPaymentAccountTransactionAttachmentsContract";
import { IPaymentAccountTransactionAttachmentEntity } from "../../domain/entities/paymentAccountTransactionAttachmentEntity";

export class ListPaymentAccountTransactionAttachmentsService
  implements IListPaymentAccountTransactionAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listPaymentAccountTransactionAttachments(
    paymentAccountTransactionId: string,
  ) {
    const userEntity = this.getUserLocalService.get();

    const url = `PaymentAccountTransactions/${paymentAccountTransactionId}/Attachments`;

    return this.api.get<IPaymentAccountTransactionAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
