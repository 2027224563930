import styled from "styled-components";

export const Container = styled.div`
  width: 50rem;

  .card-header-border {
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .react-modal-body {
    .destination-chkbox {
      margin-top: 0.4rem;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  font-size: 1.2rem;
`;
