import { HttpResponseType, IApiService } from "./apiService";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IGenerateCommercialProposalAttachmentContract,
  IGenerateCommercialProposalAttachmentParams,
} from "../../domain/contracts/generateCommercialProposalAttachmentContract";

export class GenerateCommercialProposalAttachmentService
  implements IGenerateCommercialProposalAttachmentContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateCommercialProposalAttachment(
    params: IGenerateCommercialProposalAttachmentParams,
  ) {
    const url = `/Downloads/GenerateAttachmentCommercialProposalFusp`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.postAndDownload?.(url, params, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });

    if (response) {
      const file = this.generateFile(response);

      return file;
    }

    return Promise.reject();
  }

  private generateFile(httpResponse: HttpResponseType): File {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const contentType =
      headers?.["content-type"] ||
      `application/application/vnd.openxmlformats-officedocument.wordprocessingml.document`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    return new File([data], fileName, {
      type: contentType,
    });
  }
}
