import { IClassificationUspEntity } from "../../../classificationUsp/domain/entities/classificationUspEntity";

export interface IGetClassificationUspService {
  getClassificationUsp(id: string): Promise<IClassificationUspEntity>;
}

export interface IGetClassificationUspUseCase {
  getClassificationUsp(id: string): Promise<IClassificationUspEntity>;
}

export class GetClassificationUspUseCase
  implements IGetClassificationUspUseCase
{
  constructor(
    private getClassificationUspService: IGetClassificationUspService,
  ) {}

  getClassificationUsp(id: string) {
    return this.getClassificationUspService.getClassificationUsp(id);
  }
}
