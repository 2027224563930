import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IAccountReceivableFormEntity } from "../../domain/entities/accountReceivableFormEntity";
import { IAccountReceivableModel } from "../models/accountReceivableModel";
import { IUpdateAccountReceivableContract } from "../../domain/contracts/updateAccountReceivableContract";

export class UpdateAccountReceivableService
  implements IUpdateAccountReceivableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async updateAccountReceivable(formValues: IAccountReceivableFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/AccountsReceivable";

    const issueDate = formValues.issueDate
      ? format(
          parse(formValues.issueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const initialReceipt = formValues.initialReceipt
      ? format(
          parse(formValues.initialReceipt, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const accountReceivableParcels = formValues.accountReceivableParcels.map(
      parcel => {
        const receiveUntil = parcel.receiveUntil
          ? format(
              parse(parcel.receiveUntil, "dd/MM/yyyy", new Date()),
              "yyyy-MM-dd",
            )
          : "";
        const terminationDate = parcel.terminationDate
          ? format(
              parse(parcel.terminationDate, "dd/MM/yyyy", new Date()),
              "yyyy-MM-dd",
            )
          : "";

        return {
          ...parcel,
          receiveUntil,
          terminationDate,
          value: parcel.value.toString(),
        };
      },
    );

    const payload = {
      id: formValues.id,
      accountReceivableImportationDetailsId:
        formValues.accountReceivableImportationDetailsId,
      accountReceivableParcels,
      active: formValues.active,
      classificationAccountId: formValues.classificationAccount?.rawValue || "",
      classificationUspId: formValues.classificationUsp?.rawValue || "",
      companyId: formValues.company?.rawValue || "",
      competencyId: formValues.competency?.rawValue || "",
      costCenterId: formValues.costCenter?.rawValue,
      customerId: formValues.customer?.rawValue || "",
      customerName: formValues.customer?.label,
      description: formValues.description || "",
      documentNumber: formValues.documentNumber,
      documentStatus: formValues.documentStatus?.key,
      initialReceipt,
      invoiceServiceDescription: formValues?.invoiceServiceDescription || "",
      issueDate,
      numberOfParcels: formValues.numberOfParcels,
      observation: formValues.observation || "",
      paymentAccountId: formValues.paymentAccount?.rawValue || "",
      paymentMethod: formValues.paymentMethod?.key,
      projectId: formValues.project?.rawValue || "",
      returnStatus: formValues.returnStatus,
      value: formValues.value,
    };

    const response = await this.api.put<IAccountReceivableModel>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.id;
  }
}
