import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICountryResponse } from "../../data/models/countryResponse";

export interface IListCountriesUseCase {
  listCountriesByName(
    activeOnly?: boolean,
    payload?: PayloadEntity | null,
  ): Promise<IResponseEntity<ICountryResponse[]>>;
}

export interface IListCountriesService {
  listCountriesByName(
    activeOnly?: boolean,
    payload?: PayloadEntity | null,
  ): Promise<IResponseEntity<ICountryResponse[]>>;
}

export class ListCountriesUseCase implements IListCountriesUseCase {
  constructor(private listCountriesService: IListCountriesService) {}

  listCountriesByName(activeOnly?: boolean, payload?: PayloadEntity | null) {
    return this.listCountriesService.listCountriesByName(activeOnly, payload);
  }
}
