import { useCallback, useMemo } from "react";
import { FaEdit, FaLink } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IClassificationUspEntity } from "../../domain/entities/classificationUspEntity";

interface UseClassificationUspGridParams {
  openEditModal?(id: string): void;
  toggle?(id: string): Promise<void> | void;
  copyId?(id: string): void;
}

export function useClassificationUspGrid({
  openEditModal,
  toggle,
  copyId,
}: UseClassificationUspGridParams) {
  const editBodyTemplate = useCallback(
    ({ id }: IClassificationUspEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            data-tip="Editar classificação USP"
            type="button"
            className="outline-button tool"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openEditModal?.(id);
            }}
          >
            <FaEdit />
          </button>
          <button
            data-tip="Copiar ID Classificação USP"
            type="button"
            className="outline-button tool"
            id={`btn-copy-${rowIndex}`}
            data-testid={`btn-copy-${rowIndex}`}
            onClick={() => {
              copyId?.(id);
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [copyId, openEditModal],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: IClassificationUspEntity, { rowIndex }) => {
      return (
        <Toggle
          value={active}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          data-testid={`btn-tgl-status-${rowIndex}`}
          onChange={async _active => {
            const text = !_active ? "inativada" : "ativada";
            const result = await dialog.fire({
              icon: "question",
              title: "Você está certo disso?",
              html: `A Classificação USP será ${text}. <br /> Deseja prosseguir?`,
              showCancelButton: true,
              cancelButtonText: "Não",
              preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                return toggle?.(id);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [dialog, toggle],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        width: "20%",
      },
      {
        field: "name",
        header: "Nome",
        searchable: true,
        orderable: true,
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "hasCompanyTax",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
