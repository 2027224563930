import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IInvoiceParametersFormValues,
  IUpdateCompanyInvoiceParametersContract,
} from "../../domain/contracts/updateCompanyInvoiceParametersContract";

export class UpdateCompanyInvoiceParametersService
  implements IUpdateCompanyInvoiceParametersContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateCompanyInvoiceParameters(
    invoiceParametersFormValues: IInvoiceParametersFormValues,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = "Companies/InvoiceParameters";

    const formEntries = Object.entries(invoiceParametersFormValues);
    const mappedValues = new Map(formEntries);

    mappedValues.delete("cnae");
    mappedValues.delete("serviceSupplyCity");
    mappedValues.delete("municipalActivity");
    mappedValues.delete("serviceSupplyState");

    mappedValues.set(
      "serviceSupplyStateId",
      invoiceParametersFormValues.serviceSupplyState?.rawValue || "",
    );
    mappedValues.set(
      "serviceSupplyCityId",
      invoiceParametersFormValues.serviceSupplyCity?.rawValue || "",
    );

    if (invoiceParametersFormValues.cnae) {
      mappedValues.set(
        "cnaeClassificationId",
        invoiceParametersFormValues.cnae.rawValue,
      );
    }

    if (invoiceParametersFormValues.municipalActivity) {
      mappedValues.set(
        "municipalActivityClassificationId",
        invoiceParametersFormValues.municipalActivity.rawValue,
      );
    }

    const payload = Object.fromEntries(mappedValues);

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
