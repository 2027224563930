import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { TypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IImportAccountsReceivableContract } from "../../domain/contracts/importAccountsReceivableContract";
import {
  GenerateInvoiceIListItemEntity,
  IGenerateInvoiceIListItemEntity,
} from "../../domain/entities/generateInvoiceIListItemEntity";

interface IImportAccountsReceivableModel {
  companyName: string;
  documentNumber: string;
  customerName: string;
  value: string;
  municipalActivityClassificationId: string;
  cnaeClassificationId: string;
  issAliquot: number;
  invoiceServiceDescription: string;
  id: string;
}

export class ImportAccountsReceivableService
  implements IImportAccountsReceivableContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async importAccountsReceivable(
    file: File,
  ): Promise<IGenerateInvoiceIListItemEntity[]> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountReceivableImportations`;

    const formData = new FormData();
    formData.append("file", file);

    const response = await this.api.post<IImportAccountsReceivableModel[]>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const generateInvoiceIList = response.map((resp, rowIndex) => {
      const totalValue = resp.value ? Number(resp.value.replace(",", ".")) : 0;

      return new GenerateInvoiceIListItemEntity({
        rowIndex,
        totalValue,
        id: resp.id,
        companyName: resp.companyName,
        documentNumber: resp.documentNumber,
        customerCorporationName: resp.customerName,
        issAliquot: resp.issAliquot,
        invoiceDescription: resp.invoiceServiceDescription,

        // as duas propriedades abaixo serao atualizadas na camada de apresentacao
        // com objetos completos (aqui soh temos os ids dos TypeaheadOptions)
        cnae: new TypeaheadOption({
          rawValue: resp.cnaeClassificationId,
        }),
        serviceFramework: new TypeaheadOption({
          rawValue: resp.municipalActivityClassificationId,
        }),
      });
    });

    return generateInvoiceIList;
  }
}
