import styled from "styled-components";

export const Container = styled.div`
  .body {
    padding: 1.5rem;
  }

  .footer {
    display: flex;
    padding: 0 1.5rem 1.5rem;

    .template-button {
      margin-left: 0;
    }

    .back-button {
      margin-left: auto;
    }
  }
`;
