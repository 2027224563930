import { useCurrentCompanyGroup } from "./useCurrentCompanyGroup";

export function useIsCompanyGroupPecege() {
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  return {
    isCompanyGroupPecege() {
      return currentCompanyGroup.name.toLocaleLowerCase() === "pecege";
    },
  };
}
