export interface ICheckEmailAlreadyExistsPayloadModel {
  email: string;
  id: string;
  partnerId: string;
}

export class CheckEmailAlreadyExistsPayloadModel
  implements ICheckEmailAlreadyExistsPayloadModel
{
  id = "";
  email = "";
  partnerId = "";

  constructor(params?: Partial<ICheckEmailAlreadyExistsPayloadModel>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICheckEmailAlreadyExistsPayloadModel>) {
    return new CheckEmailAlreadyExistsPayloadModel(params);
  }
}

export interface ICheckEmailAlreadyExistsResponseModel {
  message: string | null | undefined;
  success: boolean | undefined;
}

export class CheckEmailAlreadyExistsResponseModel
  implements ICheckEmailAlreadyExistsResponseModel
{
  message = null;
  success = undefined;

  constructor(params?: Partial<ICheckEmailAlreadyExistsResponseModel>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<ICheckEmailAlreadyExistsResponseModel>) {
    return new CheckEmailAlreadyExistsResponseModel(params);
  }
}
