import { IProviderEntity } from "../entities/providerEntity";

export interface IToggleProviderStatusUseCase {
  toggleProviderStatus(id: string): Promise<IProviderEntity>;
}

export interface IToggleProviderStatusService {
  toggleProviderStatus(id: string): Promise<IProviderEntity>;
}

export class ToggleProviderStatusUseCase
  implements IToggleProviderStatusUseCase
{
  constructor(
    private toggleProviderStatusService: IToggleProviderStatusService,
  ) {}

  toggleProviderStatus(id: string) {
    return this.toggleProviderStatusService.toggleProviderStatus(id);
  }
}
