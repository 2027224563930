import { IApiService } from "../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../core/domain/entities/attachmentsGridTypes";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IAttachmentReportEntity } from "../../domain/entities/attachmentEntity";
import {
  ECostCenterReportType,
  ICostCenterReportEntity,
} from "../../domain/entities/costCenterReportEntity";
import { IGetAttachmentsService } from "../../domain/usecases/getAttachmentsUseCase";

export class GetAttachmentsService implements IGetAttachmentsService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getAttachments(
    costCenterReportEntity: ICostCenterReportEntity,
  ): Promise<IAttachmentReportEntity[]> {
    const id = costCenterReportEntity.originId;
    const userEntity = this.getUserLocalService.get();

    let url = `/Manager/AccountsPayable/${id}/Attachments`;

    if (costCenterReportEntity.type === ECostCenterReportType.revenue) {
      url = `/Manager/AccountsReceivable/${id}/Attachments`;
    }

    const response = await this.api.get<IAttachmentReportEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.filter(attachment => {
      return (
        attachment.type === EAttachmentType.NFe ||
        attachment.type === EAttachmentType.NFSe
      );
    });
  }
}
