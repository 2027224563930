import {
  ApiService,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { useApiResponseErrorHandlers } from "../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { makeCostCenter } from "../../../../../costCenter/main/makeCostCenter";
import { makeCostCenterTransactions } from "../../../../../transactions/costCenterTransactions/main/makeCostCenterTransactions";
import { makeCostCenterReport } from "../../../main/makeCostCenterReport";
import { makeCostCenterReportManager } from "../../../main/makeCostCenterReportManager";
import { CostCenterReportManagerPage } from "../CostCenterReportManagerPage";
import { CostCenterReportPage } from "../CostCenterReportPage";

interface CostCenterReportPageFactoryProps {
  api: IApiService;
}

export function CostCenterReportPageFactory({
  api,
}: CostCenterReportPageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();
  const authApi = new ApiService(baseUrl, apiResponseErrorHandlers);
  const useCostCenterTransactions = makeCostCenterTransactions(api, authApi);

  const { user } = useUserLocal();
  const isManager = user.profile === EUserProfile.manager;

  if (isManager) {
    return (
      <CostCenterReportManagerPage
        useCostCenterReport={makeCostCenterReport(api, authApi)}
        useCostCenterReportManager={makeCostCenterReportManager(api, authApi)}
      />
    );
  }

  return (
    <CostCenterReportPage
      useCostCenter={makeCostCenter(api)}
      useCostCenterReport={makeCostCenterReport(api, authApi)}
      useCostCenterTransactions={useCostCenterTransactions}
    />
  );
}
