import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { AuthInputDataset } from "../../../data/models/authInputDataset";
import { makeAuth, MakeAuth } from "../../../main/makeAuth";

interface AuthProps {
  useAuth: MakeAuth;
}

export function Auth({ useAuth }: AuthProps) {
  const navigate = useNavigate();
  const params = useParams();
  const { cyphertext } = params;
  const { isLoggedIn, encrypt, decrypt, clearLocalStorage } = useAuth;
  const { setUser } = useUserLocal();

  useEffect(() => {
    if (!cyphertext) {
      setUser({
        userId: "",
        name: "",
        email: "",
        active: false,
        profile: 0,
        token: "",
      });

      const partnerId = process.env.REACT_APP_PARTNER_ID ?? "";
      const urlRedirect = process.env.REACT_APP_REDIRECT_AUTH_URL ?? "";

      const auth: AuthInputDataset = {
        partnerId,
        urlRedirect,
      };

      clearLocalStorage();

      const encryptedAuthDataset = encrypt(auth);
      const url = `${process.env.REACT_APP_PECEGE_AUTH_URL}/admin/${encryptedAuthDataset}`;
      document.location.href = url;
      return;
    }

    const userData = JSON.parse(decrypt(cyphertext ?? ""));

    if (userData !== "") {
      setUser(userData);
      navigate("/dashboard");
    }
  }, [
    cyphertext,
    decrypt,
    isLoggedIn,
    setUser,
    navigate,
    encrypt,
    clearLocalStorage,
  ]);

  return <div />;
}

export function AuthFactory() {
  return <Auth useAuth={makeAuth()} />;
}
