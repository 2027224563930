import { IGeneratePayloadUseCase } from "../../simpleTable/domain/usecases/generatePayloadUseCase";
import { AdvGeneratePayloadUseCase } from "../domain/usecases/advGeneratePayloadUseCase";

export type MakeAdvTableType = IGeneratePayloadUseCase;

export function makeAdvTable(): MakeAdvTableType {
  const advGeneratePayloadUseCase = new AdvGeneratePayloadUseCase();

  return {
    generatePayload(psfEvent, columns) {
      return advGeneratePayloadUseCase.generatePayload(psfEvent, columns);
    },
  };
}
