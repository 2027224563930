import { ContextMenu } from "primereact/contextmenu";
import { useCallback, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  AdvTable,
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import {
  ApiService,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { ButtonClearFilter } from "../../../../../core/presentation/components/ButtonClearFilter";
import { InputSearch } from "../../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../../core/presentation/hooks/useAllowedProfiles";
import { useApiResponseErrorHandlers } from "../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useDebounceTime } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IProjectTransactionEntity } from "../../../domain/entities/projectTransactionEntity";
import {
  MakeProjectTransactions,
  makeProjectTransactions,
} from "../../../main/makeProjectTransactions";
import {
  EEditModalMode,
  useProjectTransactionsGrid,
} from "../../hooks/useProjectTransactionsGrid";
import { ProjectTransactionFormModal } from "../ProjectTransactionFormModal";
import { Container } from "./styles";
import { ProjectTransactionsContextMenu } from "../ProjectTransactionsContextMenu";
import { ProjectTransactionAttachmentsModal } from "../ProjectTransactionAttachmentsModal";

interface ProjectTransactionsPageProps {
  useProjectTransactions: MakeProjectTransactions;
}

interface IProjectTransactionsPageState {
  isAttachmentsModalOpen: boolean;
  rowData: IProjectTransactionEntity | null;
}

function ProjectTransactionsPage({
  useProjectTransactions,
}: ProjectTransactionsPageProps) {
  const table = useRef<IAdvTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [isProjectTransactionModalOpen, setIsProjectTransactionsModalOpen] =
    useState<boolean>(false);
  const [currentProjectTransactionId, setCurrentProjectTransactionsId] =
    useState("");
  const [modalMode, setModalMode] = useState(EEditModalMode.edit);
  const [filtered, setFiltered] = useState(false);

  const openProjectTransactionsFormModal = useCallback(
    (currentId = "", mode: EEditModalMode = EEditModalMode.edit) => {
      setCurrentProjectTransactionsId(currentId);
      setIsProjectTransactionsModalOpen(true);
      setModalMode(mode);
    },
    [],
  );

  const closeProjectTransactionFormModal = useCallback(() => {
    setIsProjectTransactionsModalOpen(false);
    setCurrentProjectTransactionsId("");
    table.current?.reload();
  }, []);

  const [state, setState] = useState<IProjectTransactionsPageState>({
    rowData: null,
    isAttachmentsModalOpen: false,
  });

  const menuRef = useRef<ContextMenu>(null);

  const {
    getStorageFilebyId,
    listAttachmentTypes,
    listProjectTransactions,
    toggleProjectTransaction,
    listProjectTransactionAttachments,
    uploadProjectTransactionAttachments,
  } = useProjectTransactions;

  const dialog = useSoulDialog();

  const toggle = useCallback(
    async currentId => {
      try {
        await toggleProjectTransaction(currentId);

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Movimentação excluída com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleProjectTransaction],
  );

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useProjectTransactionsGrid({
    useProjectTransactions,
    openEditModal: openProjectTransactionsFormModal,
    toggle,
  });
  const { advGeneratePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IResponseEntity<IProjectTransactionEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setLoading(false);
        return;
      }

      setLoading(true);

      const isFiltered = ((_pfsEvent.filters &&
        Object.keys(_pfsEvent.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (_pfsEvent.multiSortMeta &&
          Object.keys(_pfsEvent.multiSortMeta).length > 0)) as boolean;

      setFiltered(isFiltered);

      // Clone do objeto de evento para que as
      // referências dos filtros não sejam perdidas
      const eventClone: IPFSEventEntity = JSON.parse(JSON.stringify(_pfsEvent));

      try {
        const payload = advGeneratePayload(
          eventClone,
          columns as ISimpleColumn[],
        );

        const projectTransactionsList = await listProjectTransactions(
          id,
          payload,
        );

        if (!mountedRef.current) {
          return;
        }

        setData(projectTransactionsList);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setLoading(false);

        // Isso é necessário pois temos elementos dinamicos
        // com tooltip e o ReactTooltip precisa escanea-los
        ReactTooltip.rebuild();
      }
    },
    [advGeneratePayload, columns, id, listProjectTransactions, mountedRef],
  );

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const allowedProfiles = useAllowedProfiles();

  const clearFilters = useCallback(() => {
    table.current?.resetFilters();
  }, []);

  const handleRowClick = (
    event: IDataTableRowClickEventParams<IProjectTransactionEntity>,
  ) => {
    menuRef.current?.show(event?.originalEvent);
    setState(prevState => ({ ...prevState, rowData: event.data }));
  };

  const handleOpenAttachmentsModal = () => {
    setState(prevState => ({ ...prevState, isAttachmentsModalOpen: true }));
  };

  const handleCloseAttachmentsModal = () => {
    setState(prevState => ({
      ...prevState,
      rowData: null,
      isAttachmentsModalOpen: false,
    }));
  };

  return (
    <Container>
      <Page className="full-page">
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          {filtered && <ButtonClearFilter onClick={() => clearFilters()} />}
          {allowedProfiles(...SoulRoutes.TRANSACTIONS_PROJECT.profile) && (
            <button
              type="button"
              className="default-button"
              id="btn-add"
              onClick={() => openProjectTransactionsFormModal()}
            >
              <FaPlus /> Realizar Movimentação
            </button>
          )}
          {isProjectTransactionModalOpen && (
            <ProjectTransactionFormModal
              currentId={currentProjectTransactionId}
              isOpen={isProjectTransactionModalOpen}
              useProjectTransactions={useProjectTransactions}
              modalMode={modalMode}
              onRequestClose={closeProjectTransactionFormModal}
            />
          )}
          <ProjectTransactionAttachmentsModal
            rowData={state.rowData}
            isOpen={state.isAttachmentsModalOpen}
            onClose={handleCloseAttachmentsModal}
            getStorageFilebyId={getStorageFilebyId}
            listAttachmentTypes={listAttachmentTypes}
            uploadProjectTransactionAttachments={
              uploadProjectTransactionAttachments
            }
            listProjectTransactionAttachments={
              listProjectTransactionAttachments
            }
          />
        </header>
        <ProjectTransactionsContextMenu
          menuRef={menuRef}
          onOpenAttachmentsModal={handleOpenAttachmentsModal}
        />
        <article className="no-padding fill-height">
          <AdvTable<IProjectTransactionEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            rowsDefault={50}
            rowsPerPageOptions={[10, 50, 100]}
            getList={getList}
            onRowClick={handleRowClick}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ProjectTransactionsPageFactoryProps {
  api: IApiService;
}

export function ProjectTransactionsPageFactory({
  api,
}: ProjectTransactionsPageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;

  const apiResponseErrorHandlers = useApiResponseErrorHandlers();

  /**
   * REVIEW talvez possamos fazer essa inicialização
   * em outro lugar, por ora deixaremos aqui
   */
  const authApi = new ApiService(baseUrl, apiResponseErrorHandlers);

  return (
    <ProjectTransactionsPage
      useProjectTransactions={makeProjectTransactions(api, authApi)}
    />
  );
}
