import styled from "styled-components";

export const Container = styled.header`
  color: #525f7f;

  align-items: center;
  justify-content: space-between;

  padding: 1rem 1.5rem !important;

  div {
    gap: 0.5rem;
    display: flex;
    align-items: center;

    span {
      opacity: 0.5;

      &.active-step {
        opacity: 1;
        font-weight: bold;
        color: var(--soul-color01);
      }
    }
  }
`;
