import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IFetchServiceFrameworkContract } from "../../domain/contracts/fetchServiceFrameworkContract";

interface IFetchServiceFrameworkResponse {
  id: string;
  code: string;
  description: string;
}

export class FetchServiceFrameworkService
  implements IFetchServiceFrameworkContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async fetchServiceFramework(): Promise<ITypeaheadOption<object>[]> {
    const url = `/MunicipalActivityClassifictions`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.get<
      IResponseEntity<IFetchServiceFrameworkResponse[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.data.map<ITypeaheadOption>(serviceFramework => {
      return {
        label: serviceFramework.code,
        rawValue: serviceFramework.id,
        metadata: {
          code: serviceFramework.code,
          id: serviceFramework.id,
          description: serviceFramework.description,
        },
      };
    });
  }
}
