import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .card-header-border {
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .custom-margin {
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;

    button {
      &.download-template {
        display: flex;
        margin: 0;
        align-items: center;
      }

      &.footer-close-button {
        margin-left: auto;
      }
    }
  }
`;

export const DropzoneContainer = styled.div`
  display: flex;
  align-items: center;

  height: 180px;
  color: #717386;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;

  border: 2px dashed #b8b9c3;

  cursor: pointer;

  overflow-x: auto;

  &.is-invalid {
    border-color: #fb6340 !important;
  }

  p {
    margin: auto;
  }

  div.dropzone-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    max-width: 160px;
    min-width: 160px;

    img {
      width: 135px;
      height: 135px;
    }

    button {
      opacity: 0;
      border: none;
      background: none;

      top: 5px;
      right: 5px;
      position: absolute;

      svg {
        width: 26px;
        height: 26px;
        color: #bbb;
      }

      &:hover {
        svg {
          color: #aeaeae;
        }
      }
    }

    &:hover > button {
      opacity: 1;
    }

    p {
      color: #000;
      max-width: 75%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }
  }
`;
