import { IFetchCommercialProposalEnumsContract } from "../../domain/contracts/fetchCommercialProposalEnumsContract";
import { IEnum } from "../../domain/entities/enum";
import { IUserEntity } from "../../domain/entities/userEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

export class FetchCommercialProposalEnums
  implements IFetchCommercialProposalEnumsContract
{
  private readonly endpoint = "/Enums";
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async fetchCommercialProposalEnums() {
    const [executionDeadline, provisionLocation, serviceProposalObjectType] =
      await Promise.all([
        this.getExecutionDeadLine(),
        this.getProvisionLocation(),
        this.getServiceProposalObjectType(),
      ]);

    return {
      provisionLocation,
      executionDeadline,
      serviceProposalObjectType,
    };
  }

  private getExecutionDeadLine() {
    const url = `${this.endpoint}/ExecutionDeadLines`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getProvisionLocation() {
    const url = `${this.endpoint}/ProvisionLocations`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getServiceProposalObjectType() {
    const url = `${this.endpoint}/ServiceProposalObjectTypes`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }
}
