import { useState } from "react";
import { DataTableSelectionChangeParams } from "primereact/datatable";
import { ISolicitImportEntity } from "../../domain/entities/solicitImportEntity";

export interface IValidationStepState {
  selection: ISolicitImportEntity[];
  attachmentsModal: {
    isOpen: boolean;
    formIndex: number | null;
  };
}

export function useValidationStep() {
  const [state, setState] = useState<IValidationStepState>(() => {
    return {
      selection: [],
      attachmentsModal: {
        isOpen: false,
        formIndex: null,
      },
    };
  });

  const handleSelectionChange = (event: DataTableSelectionChangeParams) => {
    setState(old => ({
      ...old,
      selection: event.value,
    }));
  };

  const handleOpenAttachmentModal = (formIndex?: number) => {
    setState(old => ({
      ...old,
      attachmentsModal: {
        isOpen: true,
        formIndex: formIndex ?? null,
      },
    }));
  };

  const handleCloseAttachmentModal = () => {
    setState(old => ({
      ...old,
      attachmentsModal: {
        isOpen: false,
        formIndex: null,
      },
    }));
  };

  const handleFormRemoved = (formsIds: string[]) => {
    setState(old => {
      const selection = old.selection.filter(({ id }) => {
        return !formsIds.includes(id);
      });
      return {
        ...old,
        selection,
      };
    });
  };

  return {
    state,
    handleFormRemoved,
    handleSelectionChange,
    handleOpenAttachmentModal,
    handleCloseAttachmentModal,
  };
}
