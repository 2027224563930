import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IProviderEntity } from "../../../../../provider/domain/entities/providerEntity";
import { PayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchProvidersContract } from "../../domain/contracts/searchProvidersContract";
import { EProviderDocumentType } from "../../domain/entities/debtImportEnums";

export class SearchProvidersService implements ISearchProvidersContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchProviders(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      order: [{ column: 1, dir: "asc" }],
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: true,
          search: { regex: false, value: "" },
        },
        {
          data: "document",
          name: "document",
          orderable: false,
          searchable: true,
          search: { regex: false, value: "" },
        },
        {
          data: "documentType",
          name: "documentType",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: {
        regex: false,
        value: search,
      },
      length: 100,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/Providers?${params}`;

    if (active) {
      url = `/CompanyGroups/${companyGroupId}/Providers/Actives?${params}`;
    }

    const response = await this.api.get<
      IServerSideResponseModel<IProviderEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const providerSearchResults = response.data.map(provider => {
      const { id, name, document, documentType } = provider;
      let fmtDocument = document;

      const cpfRegExp = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
      const cnpjRegExp = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

      if (Number(documentType) === EProviderDocumentType.CPF) {
        fmtDocument = document.replace(cpfRegExp, "$1.$2.$3-$4");
      }

      if (Number(documentType) === EProviderDocumentType.CNPJ) {
        fmtDocument = document.replace(cnpjRegExp, "$1.$2.$3/$4-$5");
      }

      const label = `${name} ${document ? `- ${fmtDocument}` : ""}`.trim();
      const rawValue = id;
      const metadata = provider;

      return {
        label,
        rawValue,
        metadata,
      };
    });

    return providerSearchResults;
  }
}
