import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IAccountReceivableHasTaxesContract } from "../../domain/contracts/accountReceivableHasTaxesContract";

export class AccountReceivableHasTaxesService
  implements IAccountReceivableHasTaxesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async accountReceivableHasTaxes(
    accountReceivableId: string,
  ): Promise<boolean> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsReceivable/${accountReceivableId}/HasTaxes`;

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return Boolean(response);
  }
}
