export interface IDecryptUseCase {
  decrypt(cyphertext: string): string;
}

export interface IDecryptService {
  decrypt(cyphertext: string): string;
}

export class DecryptUseCase implements IDecryptUseCase {
  constructor(private decryptService: IDecryptService) {}

  decrypt(cyphertext: string): string {
    return this.decryptService.decrypt(cyphertext);
  }
}
