import { IUserEntity } from "../entities/userEntity";

export interface IResetUserPasswordService {
  resetUserPassword(userId: string): Promise<IUserEntity>;
}

export interface IResetUserPasswordUseCase {
  resetUserPassword(userId: string): Promise<IUserEntity>;
}

export class ResetPasswordUseCase implements IResetUserPasswordUseCase {
  constructor(private resetPasswordService: IResetUserPasswordService) {}

  resetUserPassword(userId: string): Promise<IUserEntity> {
    return this.resetPasswordService.resetUserPassword(userId);
  }
}
