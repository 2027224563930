export enum EAccountReceivableStatus {
  Open = 1,
  Paid,
  Canceled,
  Inconsistent,
}

export enum EAccountReceivableReturnStatus {
  NotReturned = 1,
  PartiallyReturned,
  FullyReturned,
}

/**
 * O formulário possui os seguintes modos:
 * - `Create`: modo de adição, todos os campos ficam disponíveis para o usuário.
 * - `Edit`: modo de edição, todos os campos ficam disponíveis para o usuário.
 * - `EditAttachments`: modo de edição de anexos, todos os campos bloqueados
 * exceto a seção de anexos.
 * - `Conditional`: modo de edição condicional, que desabilita todos os
 * campos *a priori*. Os campos só são habilitados caso não possuam valor.
 * - `Limited`: modo de edição limitado, todos os campos são habilitados,
 * exceto os campos que envolvam a geração de parcelas e os campos *"Empresa"*,
 * *"Centro de Custo"*, *"Projeto"* e *"Conta de Pagamento"*.
 * - `ParcelsBlocked`: modo de edição, com todos os campos disponíveis, exceto
 * os campos de geração de parcelas.
 */
export enum EFormMode {
  Create,
  Edit,
  Limited,
  Conditional,
  ParcelsBlocked,
  EditAttachments,
}

export enum EAccountReceivablePaymentMethod {
  Empty = 0,
  Billet = 1,
  CreditCard = 2,
  Check = 5,
  DirectDebit = 6,
  Cash = 4,
  Pix = 7,
  CurrentAccountTransfer = 3,
}
