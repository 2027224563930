import Modal from "react-modal";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { FaSpinner } from "react-icons/fa";
import { InputMask } from "primereact/inputmask";
import { isBefore, parse } from "date-fns";
import { MakeCostCenter } from "../../../main/makeCostCenter";
import { Container } from "./style";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import {
  CostCenterFormEntity,
  ICostCenterFormEntity,
} from "../../../domain/entities/costCenterFormEntity";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";
import { SearchCostCenterField } from "../SearchCostCenterField";
import { useCostCenterForm } from "../../hooks/useCostCenterForm";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { InputBalance } from "../../../../core/presentation/components/InputBalance";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { OnCloseModalAction } from "../CostCenterPage";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";

export type CostCenterFormModalProps = {
  readonly?: true;
  isOpen: boolean;
  currentId: string;
  useCostCenter: MakeCostCenter;
  onClose(action?: OnCloseModalAction): void;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
};

export function CostCenterFormModal(props: CostCenterFormModalProps) {
  const {
    isOpen,
    onClose,
    readonly,
    currentId,
    useCostCenter,
    searchCostCenter,
  } = props;

  const {
    getCostCenter,
    saveCostCenter,
    updateCostCenter,
    checkCostCenterAcronym,
  } = useCostCenter;

  const dialog = useSoulDialog();
  const invalidDate = useDateValidator();
  const allowedProfiles = useAllowedProfiles();
  const createCostCenterForm = useCostCenterForm();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** Indica se os campos devem ser desabilitados ou não. */
  const isDisabled = useMemo(() => {
    if (readonly) return true;
    return !allowedProfiles(
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles, readonly]);

  /** Armazena o valor do acrônimo inputado. */
  const acronymRef = useRef("");

  /** Indica o estado de carregamento dos dados do modal. */
  const [isLoading, setIsLoading] = useState(false);

  /** Indica se o centro de custo está ativo ou não. */
  const [isActive, setIsActive] = useState(true);

  const formMethods = useForm({
    mode: "all",
    defaultValues: CostCenterFormEntity.create(),
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = formMethods;

  /**
   * Lida com a abertura do modal.
   */
  const handleModalOpening = useCallback(async () => {
    if (!currentId) return;

    setIsLoading(true);

    try {
      const response = await getCostCenter(currentId);

      const { costCenterForm } = createCostCenterForm(response);

      reset(costCenterForm);
      setIsActive(response.active);
    } catch (error) {
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [createCostCenterForm, currentId, getCostCenter, onClose, reset]);

  /**
   * Lida com o fechamento do modal, resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleModalAfterClose = () => {
    reset(CostCenterFormEntity.create());
  };

  /**
   * Lida com o evento de submissão do formulário, enviando os dados para o
   * servidor.
   */
  const submitForm = useCallback(
    async (formValues: ICostCenterFormEntity) => {
      const payload = {
        ...formValues,
        active: isActive,
        companyGroupId: currentCompanyGroup.id,
      };

      const actionMessage = currentId ? "atualizado" : "cadastrado";

      if (!currentId) {
        await saveCostCenter(payload);
      } else {
        await updateCostCenter(payload, currentId);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Centro de Custo ${actionMessage} com sucesso.`,
      });
      onClose(OnCloseModalAction.Refresh);
    },
    [
      dialog,
      onClose,
      isActive,
      currentId,
      saveCostCenter,
      updateCostCenter,
      currentCompanyGroup.id,
    ],
  );

  /**
   * Realiza a verificação assíncrona do campo de Acrônimo, validando se o valor
   * inserido já existe.
   */
  const validAcronym = useCallback(
    async (value: string) => {
      if (value !== acronymRef.current) {
        acronymRef.current = value;

        const response = await checkCostCenterAcronym(
          value,
          currentId,
          currentCompanyGroup.id,
        );

        return response.success;
      }

      if (errors?.acronym?.type === "validAcronym") {
        return false;
      }

      return true;
    },
    [
      currentId,
      errors?.acronym?.type,
      checkCostCenterAcronym,
      currentCompanyGroup.id,
    ],
  );

  /** Valida se a data inserida é anterior a data atual. */
  const isBeforeToday = useCallback((value: string) => {
    if (!value) return true;

    const date = parse(value, "ddMMyyyy", new Date());

    return isBefore(date, new Date());
  }, []);

  /** Define o título a ser exibido no modal. */
  const handleModalTitle = useCallback(() => {
    if (readonly) return "Visualizando Centro de Custo";
    return currentId ? "Editar Centro de Custo" : "Novo Centro de Custo";
  }, [currentId, readonly]);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc={!isLoading}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      onRequestClose={handleModalClose}
      onAfterClose={handleModalAfterClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{handleModalTitle()}</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-crud-cross"
              onClick={handleModalClose}
              data-testid="btn-crud-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="crud-header">
              <p>
                Este registro {currentId ? "está" : "será"} vinculado ao grupo
                de empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <FormProvider {...formMethods}>
                <form
                  id="cost-center-crud-form"
                  className="form-container"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Sigla</span>
                      <Controller
                        name="acronym"
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            validAcronym,
                          },
                        }}
                        render={({ field }) => {
                          const onChange = (
                            e: ChangeEvent<HTMLInputElement>,
                          ) => {
                            const upperCaseOnly = e.target.value.toUpperCase();
                            field.onChange(upperCaseOnly);
                          };
                          return (
                            <input
                              {...field}
                              placeholder="Sigla"
                              onChange={onChange}
                              disabled={isDisabled}
                              className={errors?.acronym ? "isInvalid" : ""}
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.acronym?.type === "required"}
                      />
                      <InvalidFeedback
                        condition={errors.acronym?.type === "validAcronym"}
                        message="Já temos Centro de Custo cadastrado com esta sigla"
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Descrição</span>
                      <input
                        disabled={isDisabled}
                        placeholder="Descrição"
                        className={errors?.description ? "isInvalid" : ""}
                        {...register("description", { required: true })}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.description?.type === "required"}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>
                        Centro de Custo Substituto{" "}
                        {isActive ? <small>(opcional)</small> : null}
                      </span>
                      <SearchCostCenterField
                        required={!isActive}
                        disabled={isDisabled}
                        currentCostCenterId={currentId}
                        searchCostCenter={searchCostCenter}
                      />
                      <InvalidFeedback
                        message="Um Centro de Custo inativo precisa de um substituto"
                        condition={
                          errors.costCenterReplacedId?.type === "required"
                        }
                      />
                      <InvalidFeedback
                        message="Este Centro de Custo não pode ser substituto dele mesmo"
                        condition={
                          errors?.costCenterReplacedId?.type ===
                          "differentCostCenter"
                        }
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Saldo Inicial</span>
                      <Controller
                        control={control}
                        name="openingBalance"
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <InputBalance
                              {...field}
                              resetToZero
                              placeholder="000,00"
                              disabled={isDisabled}
                              id="txt-openingBalance"
                              data-testid="txt-openingBalance"
                              className={
                                errors?.openingBalance ? "isInvalid" : ""
                              }
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.openingBalance?.type === "required"}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>Responsável</span>
                      <input
                        disabled={isDisabled}
                        placeholder="Responsável"
                        className={errors?.responsible ? "isInvalid" : ""}
                        {...register("responsible", { required: true })}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.responsible?.type === "required"}
                      />
                    </label>
                    <label className="col-6 form-control">
                      <span>
                        Travar lançamentos até <small>(opcional)</small>
                      </span>
                      <Controller
                        control={control}
                        name="lockReleasesUntil"
                        rules={{
                          validate: {
                            isBeforeToday,
                            validDate: v => !invalidDate(v),
                          },
                        }}
                        render={({ field }) => {
                          return (
                            <InputMask
                              unmask
                              {...field}
                              mask="99/99/9999"
                              disabled={isDisabled}
                              placeholder="Travar lançamentos até"
                              className={
                                errors?.lockReleasesUntil ? "isInvalid" : ""
                              }
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        message="Este campo é opcional, porém a data inserida está inválida"
                        condition={
                          errors.lockReleasesUntil?.type === "validDate"
                        }
                      />
                      <InvalidFeedback
                        message="A data informada não pode ser superior ao dia atual"
                        condition={
                          errors.lockReleasesUntil?.type === "isBeforeToday"
                        }
                      />
                    </label>
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <label>
                <Controller
                  control={control}
                  name="isDefaultForAccountReceivableImportation"
                  render={({ field }) => {
                    const checkboxField = {
                      ...field,
                      checked: !!field?.value,
                      value: field.value ? "true" : "false",
                    };
                    return (
                      <Checkbox
                        {...checkboxField}
                        disabled={isDisabled}
                        id="chk-isDefaultForAccountReceivableImportation"
                        data-testid="chk-isDefaultForAccountReceivableImportation"
                        label={
                          <>
                            Padrão para imp. de créditos não Pecege Pay em CCs
                            inativos.
                            <IconTooltip icon="pi pi-question-circle">
                              <p>
                                <strong>
                                  Padrão para importação de
                                  <br />
                                  créditos não Pecege Pay em CCs inativos
                                </strong>
                              </p>
                              <small>
                                Quando esta flag estiver marcada, este centro de
                                custo receberá todos os créditos de importações
                                não vindas do Pecege Pay que se depararam com
                                Centros de Custos inativos.
                                <br /> <br />
                                Cada grupo de empresa pode ter apenas um CC
                                padrão.
                              </small>
                            </IconTooltip>
                          </>
                        }
                      />
                    );
                  }}
                />
              </label>
              <button
                type="button"
                id="btn-crud-close"
                onClick={handleModalClose}
                data-testid="btn-crud-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                data-testid="btn-crud-save"
                form="cost-center-crud-form"
                disabled={isSubmitting || isDisabled}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
