import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  CostCenterTransactionEntity,
  ICostCenterTransactionEntity,
} from "../../domain/entities/costCenterTransactionsEntity";
import { IToggleCostCenterTransactionService } from "../../domain/usecases/toggleCostCenterTransactionsUseCase";

export class ToggleCostCenterTransactionsService
  implements IToggleCostCenterTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleCostCenterTransaction(
    costCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/CostCenterTransactions/${costCenterTransactionsId}`;

    const response = await this.api.delete<ICostCenterTransactionEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new CostCenterTransactionEntity(response);
  }
}
