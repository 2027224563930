import { FormProvider, useFieldArray } from "react-hook-form";
import { useValidationStep } from "../../hooks/useValidationStep";
import { StepsToolbar } from "../StepsToolbar";
import { ValidationStepDropdown } from "../ValidationStepDropdown";
import { Container } from "./styles";
import { RequestProgressModal } from "../RequestProgressModal";
import { useAttachmentsHandler } from "../../hooks/useAttachmentsHandler";
import { ValidationGrid } from "../ValidationGrid";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { AttachmentsModal } from "../AttachmentsModal";

export function ValidationStep() {
  const { form, getFormIndex } = useSolicitImportPage();

  const { control, getValues } = form;

  const {
    state,
    handleFormRemoved,
    handleSelectionChange,
    handleOpenAttachmentModal,
    handleCloseAttachmentModal,
  } = useValidationStep();

  const {
    isBulk,
    rowData,
    progressModalState,
    getStorageFilebyId,
    listAttachmentTypes,
    attachmentTypeCheck,
    updateAttachmentsBarcode,
    handleAttachmentsSubmission,
    validateMeasurementAttachment,
  } = useAttachmentsHandler({
    handleCloseAttachmentModal,
    selection: state.selection,
    formIndex: state.attachmentsModal.formIndex,
  });

  const { fields, remove } = useFieldArray<
    ISolicitImportForm,
    "imports",
    "formId"
  >({ control, name: "imports", keyName: "formId" });

  const handleFormsRemoval = (formsIds: string[]) => {
    const indexes = formsIds.map(id => {
      return getFormIndex(id);
    });
    remove(indexes);
    handleFormRemoved(formsIds);
    const finalLength = getValues("imports")?.length || 0;
    return finalLength === 0;
  };

  return (
    <Container>
      <StepsToolbar>
        <ValidationStepDropdown
          selection={state.selection}
          handleFormsRemoval={handleFormsRemoval}
          onOpenAttachmentModal={handleOpenAttachmentModal}
        />
      </StepsToolbar>
      <FormProvider {...form}>
        <ValidationGrid
          fields={fields}
          selection={state.selection}
          handleFormsRemoval={handleFormsRemoval}
          handleSelectionChange={handleSelectionChange}
          handleOpenAttachmentModal={handleOpenAttachmentModal}
        />
      </FormProvider>
      <AttachmentsModal
        rowData={rowData}
        onClose={handleCloseAttachmentModal}
        isOpen={state.attachmentsModal.isOpen}
        onSubmit={handleAttachmentsSubmission}
        getStorageFilebyId={getStorageFilebyId}
        listAttachmentTypes={listAttachmentTypes}
        attachmentTypeCheck={attachmentTypeCheck}
        selectionCount={state.selection?.length || 0}
        updateAttachmentsBarcode={updateAttachmentsBarcode}
        validateMeasurementAttachment={validateMeasurementAttachment}
      />
      {isBulk ? (
        <RequestProgressModal
          mode="indeterminate"
          isOpen={progressModalState.isOpen}
        />
      ) : null}
      {!isBulk ? (
        <RequestProgressModal
          mode="determinate"
          total={progressModalState.total}
          isOpen={progressModalState.isOpen}
          loaded={progressModalState.loaded}
        />
      ) : null}
    </Container>
  );
}
