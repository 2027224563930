import { ECustomerType } from "../../domain/entities/customerEntity";

export interface ICustomerForm {
  type: ECustomerType | "";
  companyGroupId: string;

  active: boolean;

  countryId: string;
  countryName: string;
}

export class CustomerFormBuilder implements ICustomerForm {
  type: ECustomerType | "" = "";
  companyGroupId = "";
  active = true;
  countryId = "";
  countryName = "";

  constructor(init?: Partial<ICustomerForm>) {
    Object.assign(this, init);
  }

  static create(args?: Partial<ICustomerForm>): ICustomerForm {
    return new CustomerFormBuilder(args);
  }
}
