import { isAfter, isEqual, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { IExportSheetFealqFormFieldValues } from "../../hooks/useExportSheetFealqFormModal";

interface IExportSheetFealqInputEndDateProps {
  disabled?: boolean;
}

export function ExportSheetFealqInputEndDate({
  disabled = false,
}: IExportSheetFealqInputEndDateProps) {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<IExportSheetFealqFormFieldValues>();
  const dateValidator = useDateValidator();

  const validateEndDate = useCallback(
    (value: string) => {
      const values = getValues();
      const startDateStr = values.startDate;

      if (value === startDateStr) {
        return true;
      }

      if (startDateStr && value) {
        const initialDate = parse(
          startDateStr,
          "ddMMyyyy HH:mm:ss",
          new Date(),
        );
        const endDate = parse(value, "ddMMyyyy HH:mm:ss", new Date());

        if (isEqual(initialDate, endDate)) {
          return true;
        }

        return isAfter(endDate, initialDate);
      }

      return true;
    },
    [getValues],
  );

  return (
    <Controller
      control={control}
      name="endDate"
      rules={{
        required: true,
        validate: {
          invalidDate: v => !dateValidator(v),
          validateEndDate,
        },
      }}
      render={({ field }) => (
        <>
          <InputMask
            {...field}
            unmask
            mask="99/99/9999 99:99:99"
            disabled={disabled}
            id="txt-endDate"
            placeholder="Data de emissão"
            data-testid="txt-endDate"
            className={errors?.endDate ? "isInvalid" : ""}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.endDate?.type === "required"}
          />
          <InvalidFeedback
            message="Preencha uma data válida"
            condition={errors.endDate?.type === "invalidDate"}
          />
          <InvalidFeedback
            condition={errors.endDate?.type === "validateEndDate"}
            message="A data final deve ser maior que a data inicial"
          />
        </>
      )}
    />
  );
}
