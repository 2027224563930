import { isValid, parse } from "date-fns";
import { ColumnBodyOptions, ColumnProps } from "primereact/column";
import { InputMaskChangeParams } from "primereact/inputmask";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { IRelationshipFilterOption } from "../../../../../advTable/domain/entities/advTableColumn";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import {
  ICostCenterLinkEntity,
  ICostCenterLinkEntityWithValidity,
  IReturnAccountErrorEntity,
} from "../../domain/entities/returnAccountErrorEntity";
import { InputSearchCostCenter } from "../components/InputSearchCostCenter";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

enum EReturnDateInvalidFeedback {
  None = "",
  Required = "Este campo é de preenchimento obrigatório.",
  InvalidFormat = "Formato de data inválido.",
  InvalidDateValue = "A data da devolução deve ser maior ou igual que a data de baixa.",
}

interface IAccountPayableReturnFormModalState {
  loading: boolean;
  submitting: boolean;
  valid: boolean;
  returnDate: string;
  returnDateInvalidFeedback: EReturnDateInvalidFeedback;
  costCenterLinks: ICostCenterLinkEntityWithValidity[];
  anyCostCenterDisabled: boolean;
  companyLocked: boolean;
  companyLockUntil: string;
}

export function useAccountPayableReturnFormModal() {
  const {
    useAccountsPayable,
    handleAccountPayableReturnFormModalClose,
    reload,
    ...rest
  } = useAccountsPayablePage();

  const { contextMenuData, accountPayableReturnFormModalOpen } = rest.state;

  const { returnAccountPayable, searchCostCenter } = useAccountsPayable;

  /** id da conta a pagar a ser devolvida */
  const accountPayableId = useMemo(
    () => contextMenuData?.id,
    [contextMenuData?.id],
  );

  /** Nome da empresa vinculada a conta a pagar a ser devolvida */
  const companyName = useMemo(
    () => contextMenuData?.companyName,
    [contextMenuData?.companyName],
  );

  const [state, setState] = useState<IAccountPayableReturnFormModalState>({
    loading: false,
    submitting: false,
    valid: true,
    returnDateInvalidFeedback: EReturnDateInvalidFeedback.None,
    returnDate: "",
    anyCostCenterDisabled: false,
    costCenterLinks: [],
    companyLocked: false,
    companyLockUntil: "",
  });

  /** Lida com o reset do estado da modal durante o seu fechamento */
  const handleModalAfterClose = useCallback(() => {
    setState({
      loading: false,
      submitting: false,
      valid: true,
      returnDate: "",
      anyCostCenterDisabled: false,
      returnDateInvalidFeedback: EReturnDateInvalidFeedback.None,
      costCenterLinks: [],
      companyLocked: false,
      companyLockUntil: "",
    });
  }, []);

  const dialog = useSoulDialog();

  /**
   * Devolve uma conta a pagar. caso haja erro durante a devolucao da
   * conta devido a empresa estar fechada p/ lctos ou algum centro de custos
   * estar inativo, procede de acordo exibindo na tela inputs para lidar com
   * estes casos
   */
  const returnAccount = useCallback(
    async (accountId: string, returnDate: string) => {
      const parsedReturnDate = parse(returnDate, "ddMMyyyy", new Date());

      setState(prevState => ({
        ...prevState,
        submitting: true,
      }));

      try {
        await returnAccountPayable(
          accountId,
          parsedReturnDate,
          state.costCenterLinks,
        );

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Devolução efetuada com sucesso!",
        });

        reload();
        handleAccountPayableReturnFormModalClose();
      } catch (error) {
        const returnError = error as IReturnAccountErrorEntity;

        if (returnError.companyLocked || returnError.anyCostCenterDisabled) {
          await dialog.fire({
            icon: "error",
            title: "Erro!",
            html: (
              <>
                {returnError.companyLocked && (
                  <div>Empresa fechada para lançamentos.</div>
                )}
                {returnError.anyCostCenterDisabled && (
                  <div>
                    {returnError.costCenterLinks?.map(costCenterLink => {
                      return (
                        <div key={costCenterLink.costCenterDisabled.id}>
                          O centro de custo{" "}
                          {costCenterLink.costCenterDisabled.name} está inativo.
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ),
          });
        }

        setState(prevState => ({
          ...prevState,
          valid: false,
          anyCostCenterDisabled: returnError.anyCostCenterDisabled || false,
          costCenterLinks:
            returnError.costCenterLinks?.map(costCenterLink => {
              return {
                ...costCenterLink,
                invalid: false,
                invalidFeedback: "",
              };
            }) || [],
          companyLocked: returnError.companyLocked || false,
          companyLockUntil: returnError.companyLockUntil || "",
          submitting: false,
          returnDateInvalidFeedback: returnError.returnDateIsValid
            ? EReturnDateInvalidFeedback.None
            : EReturnDateInvalidFeedback.InvalidDateValue,
        }));
      } finally {
        setState(prevState => ({
          ...prevState,
          submitting: false,
        }));
      }
    },
    [
      dialog,
      handleAccountPayableReturnFormModalClose,
      reload,
      returnAccountPayable,
      state.costCenterLinks,
    ],
  );

  /**
   * Lida com o evento de submissao do form. valida os inputs evitando
   * o request caso haja invalidez no form e exibe os problemas na tela
   */
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (state.returnDate === "") {
        setState(prevState => ({
          ...prevState,
          valid: false,
          returnDateInvalidFeedback: EReturnDateInvalidFeedback.Required,
        }));

        return;
      }

      if (
        state.costCenterLinks.some(
          costCenterLink => costCenterLink.costCenterDestination === null,
        )
      ) {
        setState(prevState => ({
          ...prevState,
          valid: false,
          costCenterLinks: prevState.costCenterLinks.map(costCenterLink => {
            const invalid = costCenterLink.costCenterDestination === null;
            const invalidFeedback = "Este campo é obrigatório.";

            return {
              ...costCenterLink,
              invalid,
              invalidFeedback,
            };
          }),
        }));

        return;
      }

      if (!accountPayableId) {
        return;
      }

      await returnAccount(accountPayableId, state.returnDate);
    },
    [accountPayableId, returnAccount, state.costCenterLinks, state.returnDate],
  );

  /** Lida com o evento de mudanca de valor do campo data de devolucao */
  const handleReturnDateChange = useCallback((event: InputMaskChangeParams) => {
    const returnDate = event.value || "";

    let valid = true;
    let returnDateInvalidFeedback = EReturnDateInvalidFeedback.None;

    const returnDateParsed = parse(returnDate, "ddMMyyyy", new Date());

    if (returnDate.length < 8 || !isValid(returnDateParsed)) {
      valid = false;
      returnDateInvalidFeedback = EReturnDateInvalidFeedback.InvalidFormat;
    }

    if (returnDate === "") {
      valid = false;
      returnDateInvalidFeedback = EReturnDateInvalidFeedback.Required;
    }

    setState(prevState => ({
      ...prevState,
      returnDate,
      valid,
      returnDateInvalidFeedback,
      companyLocked: false,
      companyLockUntil: "",
    }));
  }, []);

  /** Lida com o evento de fechamento emitido pela modal */
  const handleClose = useCallback(() => {
    handleAccountPayableReturnFormModalClose();
  }, [handleAccountPayableReturnFormModalClose]);

  /**
   * Estado de validez do form baseado nos campos
   * ou nos erros retornados pela api
   */
  const isInvalid = useMemo(() => {
    return !state.valid || state.companyLocked;
  }, [state.companyLocked, state.valid]);

  /** Configuracao de colunas para a listagem de centros de custos inativos */
  const columns = useMemo<ColumnProps[]>(() => {
    /** Renderiza o corpo da coluna que contem o nome da cls. de rateio */
    const renderClsAssesmentNameBody = (rowData: ICostCenterLinkEntity) => {
      return (
        <span
          className="text-truncate"
          title={rowData.classificationAssesmentName}
        >
          {rowData.classificationAssesmentName}
        </span>
      );
    };

    /** Renderiza o corpo da coluna que contem o valor do rateio */
    const renderValueBody = (rowData: ICostCenterLinkEntity) => {
      return (
        <span
          className="text-truncate"
          title={rowData.classificationAssesmentName}
        >
          {rowData.classificationAssesmentName}
        </span>
      );
    };

    /** Renderiza o corpo da coluna que contem o valor cc inativo */
    const renderCostCenterDisabledNameBody = (
      rowData: ICostCenterLinkEntity,
    ) => {
      return (
        <span className="text-truncate" title={rowData.costCenterDisabled.name}>
          {rowData.costCenterDisabled.name}
        </span>
      );
    };

    /**
     * Lida com o evento de mudanca de valor do
     * dropdown de selecao de cc substituto
     */
    const handleInputSearchCostCenterChange = (
      rowIndex: number,
      value: IRelationshipFilterOption,
    ) => {
      setState(prevState => {
        const newState = { ...prevState };

        const costCenterLinks = [...newState.costCenterLinks];
        const costCenterLink = costCenterLinks[rowIndex];

        if (value) {
          costCenterLinks[rowIndex] = {
            ...costCenterLink,
            costCenterDestination: {
              id: value.rawValue as string,
              name: value.label,
            },
            invalid: false,
            invalidFeedback: "",
          };
        } else {
          costCenterLinks[rowIndex] = {
            ...costCenterLink,
            costCenterDestination: null,
            invalid: true,
            invalidFeedback: "Este campo é obrigatório",
          };
        }

        const valid = costCenterLinks.every(
          _costCenterLink => !_costCenterLink.invalid,
        );

        return { ...newState, valid, costCenterLinks };
      });
    };

    /**
     * Renderiza o corpo da coluna que contem
     * o valor cc que substituira o inativo
     */
    const renderCostCenterDestinationBody = (
      rowData: ICostCenterLinkEntityWithValidity,
      { rowIndex }: ColumnBodyOptions,
    ) => {
      return (
        <label>
          <InputSearchCostCenter
            className={rowData.invalid ? "isInvalid" : undefined}
            searchFn={searchCostCenter}
            onChange={value => {
              handleInputSearchCostCenterChange(rowIndex, value);
            }}
          />
          <InvalidFeedback
            condition={rowData.invalid}
            message={rowData.invalidFeedback}
          />
        </label>
      );
    };

    return [
      {
        header: "Classificação",
        field: "classificationAssesmentName",
        style: { maxWidth: "160px" },
        body: renderClsAssesmentNameBody,
      },
      {
        header: "Valor",
        field: "value",
        style: { maxWidth: "160px" },
        body: renderValueBody,
      },
      {
        header: "Centro de Custo inativo",
        field: "costCenterDisabled.name",
        style: { maxWidth: "160px" },
        body: renderCostCenterDisabledNameBody,
      },
      {
        header: "Centro de Custo destino",
        field: "costCenterDestination",
        body: renderCostCenterDestinationBody,
      },
    ];
  }, [searchCostCenter]);

  /**
   * Mensagem do feedback error p/ data de devolucao caso
   * ultrapasse o limite da data de bloqueio da empresa
   */
  const companyLockedErrorMsg = useMemo(
    () =>
      `Os lançamentos na empresa ${companyName} estão travados até ${state.companyLockUntil}, assim essa devolução só pode ser realizada após essa data.`,
    [companyName, state.companyLockUntil],
  );

  return {
    state,
    columns,
    isInvalid,
    companyLockedErrorMsg,
    accountPayableReturnFormModalOpen,
    handleReturnDateChange,
    handleModalAfterClose,
    handleSubmit,
    handleClose,
  };
}
