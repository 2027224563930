import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { FeeEntity, IFeeEntity } from "../../domain/entities/feeEntity";
import { IToggleFeeContract } from "../../domain/contracts/toggleFeeContract";

export class ToggleFeeService implements IToggleFeeContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleFee(feeId: string): Promise<IFeeEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Fee/${feeId}`;

    const response = await this.api.delete<IFeeEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new FeeEntity(response);
  }
}
