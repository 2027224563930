import { IEntity } from "../../../../../core/domain/entities/entity";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IAccountReceivableAttachmentEntity } from "./accountReceivableAttachmentEntity";
import { EAccountReceivableReturnStatus } from "./accountReceivableEnums";
import { IAccountReceivableParcelEntity } from "./accountReceivableParcelEntity";

export interface IAccountReceivableFormEntity extends IEntity {
  accountReceivableParcels: IAccountReceivableParcelEntity[];
  classificationAccount: ITypeaheadOption | null;
  classificationUsp: ITypeaheadOption | null;
  company: ITypeaheadOption | null;
  competency: ITypeaheadOption | null;
  costCenter: ITypeaheadOption | null;
  customer: ITypeaheadOption | null;
  customerDocument: string;
  description: string;
  documentNumber: string;
  duplicateAccountReceivableId: string | null;
  initialReceipt: string;
  invoiceServiceDescription: string | null;
  issueDate: string;
  numberOfParcels: number;
  observation: string;
  paymentAccount: ITypeaheadOption | null;
  paymentMethod: IEnum | null;
  project: ITypeaheadOption | null;
  returnStatus: EAccountReceivableReturnStatus;
  serial: string;
  storageFiles: IAccountReceivableAttachmentEntity[];
  value: number;
  documentStatus: IEnum | null;
  isLmsImportation: boolean;
  accountReceivableImportationDetailsId: string | null;
}

export class AccountReceivableFormEntity
  implements IAccountReceivableFormEntity
{
  accountReceivableParcels = [];
  classificationAccount = null;
  classificationUsp = null;
  company = null;
  competency = null;
  costCenter = null;
  customer = null;
  customerDocument = "";
  description = "";
  documentNumber = "";
  duplicateAccountReceivableId = null;
  initialReceipt = "";
  invoiceServiceDescription = null;
  issueDate = "";
  isLmsImportation = false;
  numberOfParcels = 1;
  observation = "";
  paymentAccount = null;
  project = null;
  returnStatus = EAccountReceivableReturnStatus.NotReturned;
  serial = "";
  storageFiles = [];
  value = 0;
  id = "";
  active = true;
  paymentMethod = null;
  documentStatus = null;
  accountReceivableImportationDetailsId = null;

  constructor(init?: Partial<IAccountReceivableFormEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<IAccountReceivableFormEntity>) {
    return new AccountReceivableFormEntity(init);
  }
}
