import { IImportationMatchesSourceEntity } from "./importationMatchesSourceEntity";

export enum EImportationMatchesTabIndexes {
  PAYMENT_ACCOUNTS,
  COST_CENTERS,
}

export enum EImportationMatchesType {
  COST_CENTER = 1,
  PAYMENT_ACCOUNT = 2,
}

export enum EImportationMatchesOrigin {
  LMS = 1,
  SOLUTION = 2,
}

export interface IImportationMatchesPayloadEntity {
  companyGroupId: string;
  destinationId: string;
  destinationName: string;
  type: EImportationMatchesType;
  origin: EImportationMatchesOrigin;
  sourceData: IImportationMatchesSourceEntity[];
}
