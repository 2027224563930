import { MouseEventHandler } from "react";
import { RiFilterOffFill } from "react-icons/ri";
import { Container } from "./styles";

interface ButtonClearFilterProps {
  className?: string;
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export function ButtonClearFilter({
  className = "",
  label = "",
  onClick,
}: ButtonClearFilterProps) {
  return (
    <Container className="btn-clear-filter">
      <button
        type="button"
        className={`default-button ${className}`}
        title={label || "Limpar filtros"}
        onClick={onClick}
      >
        <RiFilterOffFill className="icon" />
        {label || `Limpar filtros`}
      </button>
    </Container>
  );
}
