import { useMemo } from "react";
import {
  ECostCenterTransactionType,
  ICostCenterTransactionEntity,
} from "../../../domain/entities/costCenterTransactionsEntity";
import { Container } from "./styles";

interface DeleteCostCenterTransactionsConfirmProps {
  transactions: ICostCenterTransactionEntity[];
}

export function DeleteCostCenterTransactionsConfirm({
  transactions,
}: DeleteCostCenterTransactionsConfirmProps) {
  const expenseTransaction = useMemo(() => {
    return transactions.find(
      t => t.type === ECostCenterTransactionType.expense,
    );
  }, [transactions]);

  const revenueTransaction = useMemo(() => {
    return transactions.find(
      t => t.type === ECostCenterTransactionType.revenue,
    );
  }, [transactions]);

  return (
    <Container>
      <div className="transaction expense">
        <div>{expenseTransaction?.costCenterName}</div>
        <div className="value">
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(expenseTransaction?.value ?? 0)}
        </div>
        <div>{expenseTransaction?.typeDescription}</div>
      </div>
      <div className="transaction revenue">
        <div>{revenueTransaction?.costCenterName}</div>
        <div className="value">
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(revenueTransaction?.value ?? 0)}
        </div>
        <div>{revenueTransaction?.typeDescription}</div>
      </div>
      <p>Deseja prosseguir?</p>
    </Container>
  );
}
