import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ILinkedCompanyGroupEntity } from "../entities/linkedCompanyGroupEntity";

export interface IListLinkedCompanyGroupsService {
  listLinkedCompanyGroups(
    userId: string,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>>;
}

export interface IListLinkedCompanyGroupsUseCase {
  listLinkedCompanyGroups(
    userId: string,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>>;
}

export class ListLinkedCompanyGroupsUseCase
  implements IListLinkedCompanyGroupsUseCase
{
  constructor(
    private listLinkedCompanyGroupsService: IListLinkedCompanyGroupsService,
  ) {}

  listLinkedCompanyGroups(
    userId: string,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>> {
    return this.listLinkedCompanyGroupsService.listLinkedCompanyGroups(userId);
  }
}
