import {
  forwardRef,
  useCallback,
  ChangeEvent,
  InputHTMLAttributes,
} from "react";

import { Container } from "./style";

interface InputBalanceProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: number;
  prefix?: string;
  className?: string;
  disabled?: boolean;
  maxLength?: number;
  resetToZero?: boolean;
  onChange: (value: number | null, event: unknown) => void;
}

/**
 * Exibe valores monetários, podendo assumir ou não valores negativos.
 * @param prefix - Símbolo a ser prefixado no input. Por padrão, é "R$".
 */
export const InputBalance = forwardRef<HTMLInputElement, InputBalanceProps>(
  (props, ref) => {
    const {
      value,
      onChange,
      disabled,
      className,
      resetToZero,
      prefix = "R$",
      maxLength = 22,
      ...rest
    } = props;

    /** Lida com o evento de change do input */
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value: eventValue } = e.target;

        /**
         * Caso o valor digitado seja uma string vazia ou seja o caso do "zero
         * negativo", encerra o evento.
         */
        if (eventValue === "") {
          onChange(resetToZero ? 0 : null, e);
          return;
        }

        /** Antes de filtrar o valor, verifica se é um valor menor que 0. */
        const isNegative = eventValue.includes("-");

        const onlyNumeric = eventValue
          .replace(/(0,00)$/g, "") // Remove qualquer texto que represente o valor 0,00.
          .replace(/\D/g, ""); //     Remove caracter não-numérico.

        /** Divide o valor por 100 a fim de manter sempre duas casas decimais. */
        const valueAsNumber = Number(onlyNumeric) / 100;

        const isZero = valueAsNumber === 0;

        /** Modifica o valor final, evitando apenas "0 negativo". */
        const finalValue = valueAsNumber * (isNegative && !isZero ? -1 : 1);

        onChange(finalValue, e);
      },
      [onChange, resetToZero],
    );

    /**
     * Formata um número para o formato monetário considerado um mínimo de
     * casas decimais.
     */
    const formatCurrency = useCallback(
      (numberToFormat: number, minimumFractionDigits: number) => {
        return new Intl.NumberFormat("pt-BR", {
          currency: "BRL",
          minimumFractionDigits,
        }).format(numberToFormat);
      },
      [],
    );

    return (
      <Container
        prefix={prefix}
        isEmpty={value === null}
        className={`${className} ${disabled ? "disabled" : ""}`}
      >
        <input
          ref={ref}
          {...rest}
          autoComplete="off"
          disabled={disabled}
          maxLength={maxLength}
          onChange={handleChange}
          value={typeof value !== "number" ? "" : formatCurrency(value, 2)}
        />
      </Container>
    );
  },
);
