import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRemoveParcelTerminationContract } from "../../domain/contracts/removeParcelTerminationContract";
import { EAccountReceivableStatus } from "../../domain/entities/accountReceivableListItemEntity";

export class RemoveParcelTerminationService
  implements IRemoveParcelTerminationContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async removeParcelTermination(
    companyGroupId: string,
    accountReceivableParcelId: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountReceivableParcels/${accountReceivableParcelId}/Status`;

    const payload = {
      status: EAccountReceivableStatus.Open,
    };

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
