import { useState } from "react";
import { IApiError } from "../../../../core/data/services/apiService";
import { useImportFileErrorHandlers } from "../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IErrorResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import {
  IConciliationEntity,
  IConciliationEntryEntity,
} from "../../../domain/entitites/conciliationEntity";
import { IConciliationPayloadEntity } from "../../../domain/entitites/conciliationPayloadEntity";
import { IConciliationResultEntity } from "../../../domain/entitites/conciliationResultEntity";
import { useConciliationContext } from "../../hooks/useConciliationContext";
import { EConciliationStep } from "../ConciliationPage";
import { ConciliationStepConciliation } from "../ConciliationStepConciliation";
import { ConciliationStepImportation } from "../ConciliationStepImportation";
import { ConciliationStepResult } from "../ConciliationStepResult";

interface IConciliationStepsState {
  sending: boolean;
  conciliation: IConciliationEntity[];
  showOnlyNotFound: boolean;
  showOnlyConciliated: boolean;
  filteredConciliation: IConciliationEntity[];
  resultEntity: IConciliationResultEntity | null;
}

export function ConciliationSteps() {
  const {
    useConciliation,
    resultHandler,
    dataImportedHandler,
    state: { step, resultEntity, formData },
  } = useConciliationContext();

  const result = resultEntity;

  const { importConciliation } = useConciliation;

  const [state, setState] = useState<IConciliationStepsState>({
    sending: false,
    conciliation: [],
    showOnlyNotFound: false,
    showOnlyConciliated: false,
    filteredConciliation: [],
    resultEntity: null,
  });

  const dialog = useSoulDialog();

  const importFileErrorHandler = useImportFileErrorHandlers();

  const postImportationData = async (data: IConciliationPayloadEntity) => {
    setState(prevState => ({
      ...prevState,
      sending: true,
    }));

    dialog.fire({
      title: "Aguarde...",
      html: "Realizando importações",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    dialog.showLoading();

    try {
      const response = await importConciliation(data);

      setState(prevState => ({
        ...prevState,
        sending: false,
        conciliation: response,
        filteredConciliation: response,
      }));

      dataImportedHandler(response);
    } catch (error) {
      const errorData = error as IApiError<IErrorResponseEntity>;
      const errorResponse = errorData.response;
      importFileErrorHandler(errorResponse);

      setState(prevState => ({
        ...prevState,
        sending: false,
      }));
    } finally {
      dialog.close();

      setState(prevState => ({
        ...prevState,
        sending: false,
      }));
    }
  };

  const handleNextButtonOnClick = (files: File[]) => {
    if (!formData) {
      return;
    }

    const data: IConciliationPayloadEntity = {
      startDate: formData?.startDate,
      endDate: formData?.endDate,
      company: formData?.company,
      paymentAccount: formData?.paymentAccount,
      file: files[0],
    };

    postImportationData(data);
  };

  const handleRowCheckboxChange = (index: number, checked: boolean) => {
    setState(prevState => {
      const conciliationRows = prevState.conciliation;

      const row = conciliationRows[index];
      row.checked = checked;

      return {
        ...prevState,
        conciliation: [...conciliationRows],
      };
    });
  };

  /** filtra os itens somente com registros nao encontrados */
  const handleOnlyNotFoundToggleChange = (value: boolean) => {
    // se estiver filtrado mostra só os vazios
    if (value) {
      setState(prevState => {
        const { conciliation } = prevState;

        return {
          ...prevState,
          showOnlyNotFound: true,
          showOnlyConciliated: false,
          filteredConciliation: conciliation.filter(entry => {
            return entry.foundAccounts.length === 0;
          }),
        };
      });
    }
    // se nao estiver, mostra tudo
    else {
      setState(prevState => {
        return {
          ...prevState,
          showOnlyNotFound: false,
          showOnlyConciliated: false,
          filteredConciliation: [...prevState.conciliation],
        };
      });
    }
  };

  /** filtra os itens somente com registros conciliados */
  const handleOnlyConciliatedToggleChange = (value: boolean) => {
    // se estiver filtrado mostra só os itens com registros conciliados
    if (value) {
      setState(prevState => {
        const { conciliation } = prevState;

        return {
          ...prevState,
          showOnlyNotFound: false,
          showOnlyConciliated: true,
          filteredConciliation: conciliation
            .filter(entry => {
              return entry.foundAccounts.some(release => {
                return release.conciliated;
              });
            })
            .map(entry => {
              return {
                ...entry,
                foundAccounts: entry.foundAccounts.filter(
                  release => release.conciliated,
                ),
              };
            }),
        };
      });
    }
    // se nao estiver, mostra tudo
    else {
      setState(prevState => {
        return {
          ...prevState,
          showOnlyNotFound: false,
          showOnlyConciliated: false,
          filteredConciliation: [...prevState.conciliation],
        };
      });
    }
  };

  const handleRowConciliationChange = (
    rowIndex: number,
    index: number,
    conciliationRow: IConciliationEntryEntity,
  ) => {
    setState(prevState => {
      const conciliationRows = prevState.conciliation;

      const row = conciliationRows[rowIndex];

      row.foundAccounts = row.foundAccounts.map((entry, _index) => {
        // se o index for igual considerar a decisao do usuario
        if (index === _index) {
          return {
            ...conciliationRow,
          };
        }

        // senao desconciliar
        return {
          ...entry,
          conciliated: false,
        };
      });

      // atualizar conciliacao na memoria
      return {
        ...prevState,
        conciliation: [...conciliationRows],
      };
    });

    // atualizar o filtro
    if (state.showOnlyConciliated) {
      handleOnlyConciliatedToggleChange(state.showOnlyConciliated);
    }
  };

  const handleSelectAll = () => {
    setState(prevState => {
      let { conciliation } = prevState;

      conciliation = conciliation.map(_entry => {
        const entry = _entry;
        if (entry.foundAccounts.length > 0) {
          entry.checked = true;
        }

        return entry;
      });

      return {
        ...prevState,
        conciliation,
        filteredConciliation: [...prevState.filteredConciliation],
      };
    });
  };

  const handleDeselectAll = () => {
    setState(prevState => {
      let { conciliation } = prevState;

      conciliation = conciliation.map(_entry => {
        const entry = _entry;
        if (entry.foundAccounts.length > 0) {
          entry.checked = false;
        }

        return entry;
      });

      return {
        ...prevState,
        conciliation,
        filteredConciliation: [...prevState.filteredConciliation],
      };
    });
  };

  /**
   * Marca todos os lançamentos selecionados como conciliados. Se
   * houver mais de um lançamento encontrado no soul marca o primeiro
   * da lista como conciliado
   */
  const conciliateSelected = () => {
    setState(prevState => {
      let { filteredConciliation } = prevState;

      filteredConciliation = filteredConciliation.map(_entry => {
        const entry = _entry;

        const list = entry.foundAccounts;

        if (list && list[0] && entry.checked) {
          list[0].conciliated = true;
        }

        return entry;
      });

      return {
        ...prevState,
        filteredConciliation,
      };
    });

    // atualizar o filtro
    if (state.showOnlyConciliated) {
      handleOnlyConciliatedToggleChange(state.showOnlyConciliated);
    }
  };

  /** Desconcilia todos os lançamentos selecionados. */
  const deconciliateSelected = () => {
    setState(prevState => {
      let { filteredConciliation } = prevState;

      filteredConciliation = filteredConciliation.map(_entry => {
        const entry = _entry;

        const list = entry.foundAccounts;

        if (list && entry.checked) {
          for (let i = 0; i < list.length; i += 1) {
            const element = list[i];
            element.conciliated = false;
          }
        }

        return entry;
      });

      return {
        ...prevState,
        filteredConciliation,
      };
    });

    // atualizar o filtro
    if (state.showOnlyConciliated) {
      handleOnlyConciliatedToggleChange(state.showOnlyConciliated);
    }
  };

  const checkedRows = state.conciliation.filter(con => con.checked);

  const handleConciliateSelectedClick = async () => {
    const cantConciliate = checkedRows.some(row =>
      row.foundAccounts.some(e => e.conciliated),
    );

    if (cantConciliate) {
      await dialog.fire({
        icon: "error",
        title: "Opa!",
        html: (
          <>
            Não é <b>possível fazer</b> essa ação para lançamentos selecionados!
            <br />
            <br />
            Para <b>realizar ações</b> em lote selecione somente lançamentos
            conciliados ou que não foram conciliados.
          </>
        ),
      });

      return;
    }

    const dialogResult = await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      html: (
        <>
          Os lançamentos serão marcados como <b>conciliados</b>.
          <br /> Deseja prosseguir?
        </>
      ),
    });

    if (dialogResult.dismiss) {
      return;
    }

    conciliateSelected();
  };

  const handleDeconciliateSelectedClick = async () => {
    const cantDeconciliate = checkedRows.some(row =>
      row.foundAccounts.every(e => !e.conciliated),
    );

    if (cantDeconciliate) {
      await dialog.fire({
        icon: "error",
        title: "Opa!",
        html: (
          <>
            Não é <b>possível fazer</b> essa ação para lançamentos selecionados!
            <br />
            <br />
            Para <b>realizar ações</b> em lote selecione somente lançamentos
            conciliados ou que não foram conciliados.
          </>
        ),
      });

      return;
    }

    const dialogResult = await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      html: (
        <>
          As conciliações dos lançamentos serão retiradas e voltarão a ficar
          desmarcadas.
          <br /> Deseja prosseguir?
        </>
      ),
    });

    if (dialogResult.dismiss) {
      return;
    }

    deconciliateSelected();
  };

  const handleResult = (
    conciliationResultEntity: IConciliationResultEntity,
  ) => {
    resultHandler(conciliationResultEntity);
  };

  // step 2: conciliacao
  if (step === EConciliationStep.Conciliation) {
    return (
      <ConciliationStepConciliation
        conciliation={state.filteredConciliation}
        onResult={handleResult}
        onSelectAll={handleSelectAll}
        onDeselectAll={handleDeselectAll}
        rowCheckboxOnChange={handleRowCheckboxChange}
        rowConciliationOnChange={handleRowConciliationChange}
        onConciliateSelectedClick={handleConciliateSelectedClick}
        onlyNotFoundToggleOnChange={handleOnlyNotFoundToggleChange}
        onDeconciliateSelectedClick={handleDeconciliateSelectedClick}
        onlyConciliatedToggleOnChange={handleOnlyConciliatedToggleChange}
      />
    );
  }

  // step 3: resultados
  if (step === EConciliationStep.Results) {
    return (
      <ConciliationStepResult
        result={result}
        conciliation={state.filteredConciliation}
      />
    );
  }

  // step 1: importacao
  return (
    <ConciliationStepImportation
      sending={state.sending}
      nextButtonOnClick={handleNextButtonOnClick}
    />
  );
}
