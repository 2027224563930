import { IApiService } from "../data/services/apiService";
import { DecryptService } from "../data/services/decryptService";
import { GetClassificationAccountIssueTypesService } from "../data/services/getClassificationAccountIssueTypesService";
import { GetClassificationAccountTypesService } from "../data/services/getClassificationAccountTypesService";
import { GetUserLocalService } from "../data/services/getUserLocalService";
import { ListAuthUsersService } from "../data/services/listAuthUsersService";
import { ListBanksService } from "../data/services/listBanksService";
import { IGetClassificationAccountIssueTypesContract } from "../domain/contracts/getClassificationAccountIssueTypesContract";
import { IGetClassificationAccountTypesContract } from "../domain/contracts/getClassificationAccountTypesContract";
import { IListAuthUsersContract } from "../domain/contracts/listAuthUsersContract";
import { IListBanksContract } from "../domain/contracts/listBanksContract";

export type MakeCore = IGetClassificationAccountTypesContract &
  IGetClassificationAccountIssueTypesContract &
  IListBanksContract &
  IListAuthUsersContract;

export function makeCore(api: IApiService): MakeCore {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getClassificationAccountTypesService =
    new GetClassificationAccountTypesService(getUserLocalService, api);

  const getClassificationAccountIssueTypesService =
    new GetClassificationAccountIssueTypesService(getUserLocalService, api);

  const listBanksService = new ListBanksService(getUserLocalService, api);

  const listUsersService = new ListAuthUsersService(getUserLocalService, api);

  return {
    getClassificationAccountTypes() {
      return getClassificationAccountTypesService.getClassificationAccountTypes();
    },
    getClassificationAccountIssueTypes() {
      return getClassificationAccountIssueTypesService.getClassificationAccountIssueTypes();
    },
    listBanks() {
      return listBanksService.listBanks();
    },
    listAuthUsers(options) {
      return listUsersService.listAuthUsers(options);
    },
  };
}
