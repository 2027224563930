import { ColumnFilterOperationType } from "../../../advTable/domain/entities/advTableColumn";

export interface DataTablePageParams {
  first: number;
  rows: number;
  page?: number;
  pageCount?: number;
  globalFilter?: string;
}

export type DataTableSortOrderType = 1 | 0 | -1 | undefined | null;

export interface DataTableSortMeta {
  field: string;
  order: DataTableSortOrderType;
}

export type DataTableMultiSortMetaType = DataTableSortMeta[] | undefined | null;

export type DataTableFilterMatchModeType =
  | "startsWith"
  | "contains"
  | "notContains"
  | "endsWith"
  | "equals"
  | "notEquals"
  | "in"
  | "lt"
  | "lte"
  | "gt"
  | "gte"
  | "between"
  | "dateIs"
  | "dateIsNot"
  | "dateBefore"
  | "dateAfter"
  | "custom";

export const dataTableFilterMatchModeTypeMap: Partial<
  Record<DataTableFilterMatchModeType, ColumnFilterOperationType>
> = {
  equals: "Equal",
  between: "Between",
  contains: "Contains",
  notEquals: "NotEqual",
  custom: "EqualAbsolute",
  notContains: "NotContains",
};

export interface DataTableSortParams {
  sortField: string;
  sortOrder: DataTableSortOrderType;
  multiSortMeta: DataTableMultiSortMetaType;
}

export interface DataTableFilterMetaData {
  value: unknown;
  matchMode: DataTableFilterMatchModeType;
}

export interface DataTableOperatorFilterMetaData {
  operator: string;
  constraints: DataTableFilterMetaData[];
}

export interface DataTableFilterMeta {
  [key: string]: DataTableFilterMetaData | DataTableOperatorFilterMetaData;
}

export interface DataTableFilterParams {
  filters: DataTableFilterMeta;
}

export interface IPFSEventEntity
  extends DataTablePageParams,
    DataTableSortParams,
    DataTableFilterParams {
  [key: string]: unknown;
}

export class PFSEventEntity implements IPFSEventEntity {
  [key: string]: unknown;
  first = 0;
  rows = 10;
  page = undefined;
  pageCount = undefined;
  globalFilter = undefined;
  sortField = "";
  sortOrder = undefined;
  multiSortMeta = undefined;
  filters = {};

  constructor(params?: Partial<IPFSEventEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
