import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin-bottom: 2rem;

  .custom-article {
    padding: 0.625rem;
    padding-bottom: 0;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2rem;

    background-color: #fff;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .right-col {
    padding-left: 0.5rem;
  }

  .p-paginator.p-component,
  .rounded-bottom .p-datatable-wrapper {
    border-bottom-right-radius: calc(0.375rem - 0.0625rem);
    border-bottom-left-radius: calc(0.375rem - 0.0625rem);
  }

  span.with-tooltip {
    gap: 0.25rem;
    display: flex;
    align-items: center;

    span {
      line-height: 1;
    }
  }
`;
