import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IExportSelectedAttachmentsContract } from "../../domain/contracts/exportSelectedAttachmentsContract";
import {
  EReportOrigin,
  IPaymentAccountReportEntity,
} from "../../domain/entities/paymentAccountReportEntity";
import { IExportAttachmentsResponse } from "../models/exportAttachmentsResponse";

export class ExportSelectedAttachmentsService
  implements IExportSelectedAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  exportSelectedAttachments(
    selectedReports: IPaymentAccountReportEntity[],
    attachmentTypes: number[],
  ) {
    const userEntity = this.getUserLocalService.get();

    const filters = new Map();

    filters.set("attachmentTypes", attachmentTypes);

    if (selectedReports.some(i => i.origin === EReportOrigin.accountPayable)) {
      const accountPayableIds = selectedReports
        .filter(i => i.origin === EReportOrigin.accountPayable)
        .map(i => i.originId);

      filters.set("accountPayableIds", accountPayableIds);
    }

    if (
      selectedReports.some(i => i.origin === EReportOrigin.accountReceivable)
    ) {
      const accountReceivableIds = selectedReports
        .filter(i => i.origin === EReportOrigin.accountReceivable)
        .map(i => i.originId);

      filters.set("accountReceivableIds", accountReceivableIds);
    }

    const auxObject = Object.fromEntries(filters);

    const params = Object.keys(auxObject)
      .map(key => {
        const k = key;
        const arrId = auxObject[key];

        if (arrId)
          return arrId.map((v: string | number) => `${k}=${v}`).join("&");

        return "";
      })
      .join("&");

    const endpoint =
      "/Downloads/PaymentAccountReport/Attachments/StorageFilesByIds";

    const url = `${endpoint}?${params}`;

    return this.api.get<IExportAttachmentsResponse>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
