import { IUserEntity } from "../entities/userEntity";

export interface IGetUserLocalUseCase {
  get(): IUserEntity | null;
}

export interface IGetUserLocalService {
  get(): IUserEntity | null;
}

export class GetUserLocalUseCase implements IGetUserLocalUseCase {
  constructor(private getUserLocalService: IGetUserLocalService) {}

  get(): IUserEntity | null {
    return this.getUserLocalService.get();
  }
}
