import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SearchCostCenterService } from "../../core/data/services/searchCostCenterService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ISearchCostCenterContract } from "../../core/domain/contracts/searchCostCenterContract";
import { ChangeFilteredCostCentersLockDatesService } from "../data/services/changeFilteredCostCentersLockDatesService";
import { ChangeSelectedCostCentersLockDatesService } from "../data/services/changeSelectedCostCentersLockDatesService";
import { CheckCostCenterAcronymService } from "../data/services/checkCostCenterAcronymService";
import { ExportCostCentersService } from "../data/services/exportCostCentersService";
import { GetCostCenterService } from "../data/services/getCostCenterService";
import { GetCostCenterUsersService } from "../data/services/getCostCenterUsersService";
import { ListCostCentersService } from "../data/services/listCostCentersService";
import { SaveCostCenterService } from "../data/services/saveCostCenterService";
import { SaveCostCenterUsersService } from "../data/services/saveCostCenterUsersService";
import { ToggleCostCenterService } from "../data/services/toggleCostCenterService";
import { UpdateCostCenterService } from "../data/services/updateCostCenterService";
import { IChangeFilteredCostCentersLockDatesContract } from "../domain/contracts/changeFilteredCostCentersLockDatesContract";
import { IChangeSelectedCostCentersLockDatesContract } from "../domain/contracts/changeSelectedCostCentersLockDatesContract";
import { ICheckCostCenterAcronymContract } from "../domain/contracts/checkCostCenterAcronymContract";
import { IExportCostCentersContract } from "../domain/contracts/exportCostCentersContract";
import { IGetCostCenterContract } from "../domain/contracts/getCostCenterContract";
import { IGetCostCenterUsersContract } from "../domain/contracts/getCostCenterUsersContract";
import { IListCostCentersContract } from "../domain/contracts/listCostCentersContract";
import { ISaveCostCenterContract } from "../domain/contracts/saveCostCenterContract";
import { ISaveCostCenterUsersContract } from "../domain/contracts/saveCostCenterUsersContract";
import { IToggleCostCenterContract } from "../domain/contracts/toggleCostCenterContract";
import { IUpdateCostCenterContract } from "../domain/contracts/updateCostCenterContract";

export type MakeCostCenter = IListCostCentersContract &
  IToggleCostCenterContract &
  ICheckCostCenterAcronymContract &
  IGetCostCenterContract &
  ISaveCostCenterContract &
  IUpdateCostCenterContract &
  IGetCostCenterUsersContract &
  ISaveCostCenterUsersContract &
  IExportCostCentersContract &
  IChangeSelectedCostCentersLockDatesContract &
  IChangeFilteredCostCentersLockDatesContract &
  ISearchCostCenterContract;

export function makeCostCenter(api: IApiService): MakeCostCenter {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listCostCenterService = new ListCostCentersService(
    getUserLocalService,
    api,
  );

  const toggleCostCenterService = new ToggleCostCenterService(
    getUserLocalService,
    api,
  );

  const checkCostCenterAcronymService = new CheckCostCenterAcronymService(
    getUserLocalService,
    api,
  );

  const getCostCenterService = new GetCostCenterService(
    getUserLocalService,
    api,
  );

  const saveCostCenterService = new SaveCostCenterService(
    getUserLocalService,
    api,
  );

  const updateCostCenterService = new UpdateCostCenterService(
    getUserLocalService,
    api,
  );

  const getCostCenterUsersService = new GetCostCenterUsersService(
    getUserLocalService,
    api,
  );

  const saveCostCenterUsersService = new SaveCostCenterUsersService(
    getUserLocalService,
    api,
  );

  const exportCostCentersService = new ExportCostCentersService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  const changeSelectedCostCentersLockDateService =
    new ChangeSelectedCostCentersLockDatesService(getUserLocalService, api);

  const changeFilteredCostCenterLockDatesService =
    new ChangeFilteredCostCentersLockDatesService(getUserLocalService, api);

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  return {
    listCostCenters(payload, currentCompanyGroupId, actives) {
      return listCostCenterService.listCostCenters(
        payload,
        currentCompanyGroupId,
        actives,
      );
    },
    toggleCostCenter(payload) {
      return toggleCostCenterService.toggleCostCenter(payload);
    },
    checkCostCenterAcronym(acronym, costCenterId, companyGroupId) {
      return checkCostCenterAcronymService.checkCostCenterAcronym(
        acronym,
        costCenterId,
        companyGroupId,
      );
    },
    getCostCenter(costCenterId) {
      return getCostCenterService.getCostCenter(costCenterId);
    },
    saveCostCenter(payload) {
      return saveCostCenterService.saveCostCenter(payload);
    },
    updateCostCenter(payload, costCenterId) {
      return updateCostCenterService.updateCostCenter(payload, costCenterId);
    },
    getCostCenterUsers(costCenterId) {
      return getCostCenterUsersService.getCostCenterUsers(costCenterId);
    },
    saveCostCenterUsers(costCenterId, payload) {
      return saveCostCenterUsersService.saveCostCenterUsers(
        costCenterId,
        payload,
      );
    },
    exportCostCenters(params) {
      return exportCostCentersService.exportCostCenters(params);
    },
    changeSelectedCostCentersLockDates(params) {
      return changeSelectedCostCentersLockDateService.changeSelectedCostCentersLockDates(
        params,
      );
    },
    changeFilteredCostCentersLockDates(params) {
      return changeFilteredCostCenterLockDatesService.changeFilteredCostCentersLockDates(
        params,
      );
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
  };
}
