import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { SoulRoutes } from "../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  MakeClassificationAssessment,
  makeClassificationAssessment,
} from "../../../../classificationAssessment/main/makeClassificationAssessment";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../core/data/services/viaCepApiService";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  MakeProvider,
  makeProvider,
} from "../../../../provider/main/makeProvider";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IFeeEntity } from "../../../domain/entities/feeEntity";
import { MakeFee, makeFee } from "../../../main/makeFee";
import { useFeeGrid } from "../../hooks/useFeeGrid";
import { FeeFormModal } from "../FeeFormModal";
import { Container } from "./styles";

interface FeePageProps {
  useFee: MakeFee;
  useProvider: MakeProvider;
  useClassificationAssessment: MakeClassificationAssessment;
}

function FeePage({
  useFee,
  useProvider,
  useClassificationAssessment,
}: FeePageProps) {
  const { listFees, toggleFee } = useFee;
  const { getClassificationAssessment } = useClassificationAssessment;

  const table = useRef<ISimpleTableHandle>(null);

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const allowedProfiles = useAllowedProfiles();
  const { generatePayload } = useTables();
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  /** Estado de carregamento da tabela. */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da tabela. */
  const [data, setData] = useState<
    IServerSideResponseModel<IFeeEntity[]> | undefined
  >();

  /** Estados que gerenciam o filtro global da tabela. */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Controla a exibição do modal de edição/criação. */
  const [currentModalOpen, setCurrentModalOpen] = useState<"CRUD">();

  /** Armazena o ID do imposto selecionado para edição */
  const [currentFeeId, setCurrentFeeId] = useState<string>();

  /** Permissão que indica se o usuário pode adicionar/editar um imposto. */
  const canAdd = useMemo(
    () => allowedProfiles(...SoulRoutes.FEE.profile),
    [allowedProfiles],
  );

  /**
   * Lida com a ação de abrir o modal.
   */
  const openFeeModal = useCallback((currentId = "") => {
    setCurrentFeeId(currentId);
    setCurrentModalOpen("CRUD");
  }, []);

  /**
   * Lida com a ação de fechar o modal ativo.
   */
  const closeFeeModal = useCallback((shouldRefresh: unknown) => {
    if (typeof shouldRefresh === "boolean" && shouldRefresh)
      table.current?.reload();

    setCurrentFeeId(undefined);
    setCurrentModalOpen(undefined);
  }, []);

  const toggle = useCallback(
    async currentId => {
      try {
        const { active } = await toggleFee(currentId);
        const text = active ? "ativado" : "inativado";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Imposto ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleFee],
  );

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  const { columns } = useFeeGrid({
    toggle,
    openFeeModal,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setIsLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const feeList = await listFees(id, payload);
        setData(feeList);
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [columns, generatePayload, id, listFees, mountedRef],
  );

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            value={search}
            id="txt-search"
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          {canAdd && (
            <button
              id="btn-add"
              type="button"
              className="default-button"
              onClick={() => {
                setCurrentModalOpen("CRUD");
              }}
            >
              <FaPlus /> Adicionar Imposto
            </button>
          )}
          <FeeFormModal
            useFee={useFee}
            readonly={!canAdd}
            onClose={closeFeeModal}
            currentId={currentFeeId}
            useProvider={useProvider}
            isOpen={currentModalOpen === "CRUD"}
            getClassificationAssessment={getClassificationAssessment}
          />
        </header>
        <article className="no-padding">
          <SimpleTable<IFeeEntity>
            data={data}
            tableRef={table}
            columns={columns}
            getList={getList}
            loading={isLoading}
            globalFilter={globalFilter}
          />
        </article>
      </Page>
    </Container>
  );
}

interface FeePageFactoryProps {
  api: IApiService;
}

export function FeePageFactory({ api }: FeePageFactoryProps) {
  const viaCepApi = new ViaCepApiService();

  return (
    <FeePage
      useFee={makeFee(api)}
      useProvider={makeProvider(api, viaCepApi)}
      useClassificationAssessment={makeClassificationAssessment(api)}
    />
  );
}
