import { StatusCodes } from "http-status-codes";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import {
  IApiError,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import {
  MakeCustomer,
  makeCustomer,
} from "../../../../../../customer/main/makeCustomer";
import {
  EAccountReceivableReturnStatus,
  EAccountReceivableStatus,
  EFormMode,
} from "../../../domain/entities/accountReceivableEnums";
import {
  AccountReceivableFormEntity,
  IAccountReceivableFormEntity,
} from "../../../domain/entities/accountReceivableFormEntity";
import {
  MakeAccountsReceivableForm,
  makeAccountsReceivableForm,
} from "../../../main/makeAccountsReceivableForm";
import { SectionAccountInfo } from "../SectionAccountInfo";
import { SectionAditionalInfo } from "../SectionAdditionalInfo";
import { SectionAttachments } from "../SectionAttachments";
import { SectionCompanyData } from "../SectionCompanyData";
import { SectionFooter } from "../SectionFooter";
import { SectionInvoiceDescription } from "../SectionInvoiceDescription";
import { SectionParcels } from "../SectionParcels";
import { SectionProjectInfo } from "../SectionProjectInfo";
import { Container } from "./styles";

interface IAccountReceivableFormPageState {
  mode: EFormMode;
  isLoading: boolean;
  options: {
    paymentMethods: IEnum[];
  };
}

interface IAccountReceivableFormPageProps {
  useCustomer: MakeCustomer;
  useReceivableForm: MakeAccountsReceivableForm;
}

function AccountReceivableFormPage(props: IAccountReceivableFormPageProps) {
  const { useReceivableForm, useCustomer } = props;

  const { getAccountReceivable, fetchPaymentMethods } = useReceivableForm;

  const [state, setState] = useState<IAccountReceivableFormPageState>({
    isLoading: true,
    mode: EFormMode.Edit,
    options: {
      paymentMethods: [],
    },
  });

  const readonly = [EFormMode.EditAttachments, EFormMode.Conditional].includes(
    state.mode,
  );

  const navigate = useNavigate();
  const dialog = useSoulDialog();
  const { receivableId } = useParams<"receivableId">();
  const [searchParams, setSearchParams] = useSearchParams();

  const form = useForm<IAccountReceivableFormEntity>({
    mode: "all",
    defaultValues: new AccountReceivableFormEntity({
      duplicateAccountReceivableId: searchParams.get("duplicate"),
    }),
  });

  const { reset, getValues } = form;

  const defineFormMode = useCallback(
    async (accountReceivable: IAccountReceivableFormEntity) => {
      const { accountReceivableParcels, returnStatus } = accountReceivable;

      if (receivableId === "new") {
        return EFormMode.Create;
      }

      if (returnStatus !== EAccountReceivableReturnStatus.NotReturned) {
        return EFormMode.EditAttachments;
      }

      const isInconsistent = accountReceivableParcels.some(parcel => {
        return parcel.status === EAccountReceivableStatus.Inconsistent;
      });

      if (isInconsistent) {
        return EFormMode.Conditional;
      }

      const isPaid = accountReceivableParcels.some(parcel => {
        return parcel.status === EAccountReceivableStatus.Paid;
      });

      if (isPaid) {
        return EFormMode.Limited;
      }

      const isCanceled = accountReceivableParcels.some(parcel => {
        return parcel.status === EAccountReceivableStatus.Canceled;
      });

      if (isCanceled) {
        return EFormMode.ParcelsBlocked;
      }

      return EFormMode.Edit;
    },
    [receivableId],
  );

  const defineFormValues = useCallback(async () => {
    if (!receivableId) {
      return Promise.reject();
    }

    const duplicateId = getValues("duplicateAccountReceivableId");

    const isCreating = receivableId === "new";
    const isDuplicating = duplicateId && isCreating;

    if (isCreating && !isDuplicating) {
      return new AccountReceivableFormEntity();
    }

    const idToFetch = isDuplicating ? duplicateId : receivableId;

    const accountReceivable = await getAccountReceivable(idToFetch);

    if (isDuplicating) {
      const duplicatedAccountReceivable = {
        ...accountReceivable,
        storageFiles: [],
        issueDate: "",
        observation: "",
        documentNumber: "",
        initialReceipt: "",
        accountReceivableParcels: [],
        invoiceServiceDescription: "",
        returnStatus: EAccountReceivableReturnStatus.NotReturned,
      };

      reset(duplicatedAccountReceivable);

      return duplicatedAccountReceivable;
    }

    reset(accountReceivable);

    return accountReceivable;
  }, [getAccountReceivable, getValues, receivableId, reset]);

  const loadFormData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const accountReceivable = await defineFormValues();
      const mode = await defineFormMode(accountReceivable);
      const paymentMethods = await fetchPaymentMethods();

      setState(prevState => ({
        ...prevState,
        mode,
        isLoading: false,
        options: {
          paymentMethods,
        },
      }));
    } catch (error) {
      const typedError = error as IApiError;

      if (typedError?.response?.status === StatusCodes.NOT_FOUND) {
        await dialog.fire({
          icon: "error",
          title: "Ops!",
          html: (
            <>
              Conta a receber não encontrada.
              <br />
              Por favor, verifique os dados e tente novamente.
            </>
          ),
        });
      }

      navigate(SoulRoutes.ACCOUNTS_RECEIVABLE.path);
    }
  }, [defineFormValues, defineFormMode, fetchPaymentMethods, navigate, dialog]);

  useEffect(() => {
    const urlHasSearchParam = searchParams.has("duplicate");

    /**
     * Encerra o processo de montagem do formulário caso exista um query param
     * na URL.
     *
     * Isso é necessário para que o componente abra com a URL "limpa".
     *
     * Caso exista um ID de duplicação, ele fica salvo na inicialização do
     * estado do formulário.
     */
    if (urlHasSearchParam) {
      const blankParams = new URLSearchParams();
      setSearchParams(blankParams, { replace: true });
      return;
    }

    loadFormData();
  }, [searchParams, loadFormData, setSearchParams]);

  return (
    <Container>
      <Page>
        <header>
          <h4>
            {receivableId === "new"
              ? "Adicionando lançamento"
              : "Editando lançamento"}
          </h4>
        </header>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        ) : null}
        {!state.isLoading ? (
          <FormProvider {...form}>
            <article className="custom-article fill-height">
              <form className="form-container">
                <div className="row">
                  <div className="col-6">
                    <Card>
                      <section>
                        <SectionCompanyData
                          mode={state.mode}
                          useReceivableForm={useReceivableForm}
                        />
                        <SectionAccountInfo
                          mode={state.mode}
                          readonly={readonly}
                          options={state.options}
                          useCustomer={useCustomer}
                          useReceivableForm={useReceivableForm}
                        />
                        <SectionProjectInfo
                          mode={state.mode}
                          useReceivableForm={useReceivableForm}
                        />
                        <SectionAditionalInfo
                          mode={state.mode}
                          useReceivableForm={useReceivableForm}
                        />
                      </section>
                    </Card>
                  </div>
                  <div className="col-6 right-col">
                    <SectionInvoiceDescription
                      readonly={state.mode === EFormMode.EditAttachments}
                    />
                    <SectionAttachments
                      useReceivableForm={useReceivableForm}
                      readonly={state.mode === EFormMode.Conditional}
                    />
                    <SectionParcels />
                  </div>
                </div>
                <SectionFooter
                  mode={state.mode}
                  useReceivableForm={useReceivableForm}
                />
              </form>
            </article>
          </FormProvider>
        ) : null}
      </Page>
    </Container>
  );
}
interface IAccountReceivableFormPageFactoryProps {
  api: IApiService;
}
export function AccountReceivableFormPageFactory({
  api,
}: IAccountReceivableFormPageFactoryProps) {
  const viaCepApi = new ViaCepApiService();

  const useCustomer = makeCustomer(api, viaCepApi);
  const useReceivableForm = makeAccountsReceivableForm(api);

  return (
    <AccountReceivableFormPage
      useCustomer={useCustomer}
      useReceivableForm={useReceivableForm}
    />
  );
}
