import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 0 0.5rem 0 rgb(136 152 170 / 40%);
`;

export const ToolbarContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;

  padding: 0.5rem 1.5rem;

  > :nth-child(2) {
    margin-left: auto;
  }

  > :last-child {
    margin-left: unset;
  }

  > label {
    gap: 0.5rem;
    display: flex;
  }
`;
