import Modal from "react-modal";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { MakeCostCenter } from "../../../main/makeCostCenter";
import { ICostCenterEntity } from "../../../domain/entities/costCenterEntity";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { Container } from "./style";
import { CostCenterUsersGrid, IUserListData } from "../CostCenterUsersGrid";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { MakeCore } from "../../../../core/main/makeCore";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import { PFSEventEntity } from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { useCostCenterUsersGrid } from "../../hooks/useCostCenterUsersGrid";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { OnCloseModalAction } from "../CostCenterPage";

export interface ICostCenterUsersForm {
  users: { id: string }[];
}

export type CostCenterUsersModalProps = {
  isOpen: boolean;
  useCostCenter: MakeCostCenter;
  currentCostCenter?: ICostCenterEntity;
  listAuthUsers: MakeCore["listAuthUsers"];
  onClose(action?: OnCloseModalAction): void;
};

export function CostCenterUsersModal(props: CostCenterUsersModalProps) {
  const { isOpen, onClose, currentCostCenter, useCostCenter, listAuthUsers } =
    props;

  const { getCostCenterUsers, saveCostCenterUsers } = useCostCenter;

  const { id: currentId, acronym } = currentCostCenter ?? {
    id: "",
    active: true,
  };

  const dialog = useSoulDialog();
  const { user } = useUserLocal();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();
  const { payloadColumns } = useCostCenterUsersGrid();

  const isSupervisor = user.profile === EUserProfile.supervisor;

  const isDisabled = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles]);

  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState<IUserListData>({
    list: [],
    totalRecords: 1,
  });

  const formMethods = useForm<ICostCenterUsersForm>({
    defaultValues: {
      users: [],
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  /**
   * Lida com a abertura do modal, carregando os dados da tabela e de usuários
   * vinculados ao atual centro de custo.
   */
  const handleModalOpening = useCallback(async () => {
    if (!currentId) return;

    setIsLoading(true);

    try {
      const initialEvent = new PFSEventEntity({
        first: 0,
        rows: 10,
        order: [],
      });

      const payload = generatePayload(initialEvent, payloadColumns);

      const usersResponse = await listAuthUsers({
        payload,
        isSupervisor,
        actives: true,
      });
      const costCenterUsers = await getCostCenterUsers(currentId);

      const selectedUsersIds = costCenterUsers.map(id => ({ id }));

      setUsersData({
        list: usersResponse.data,
        totalRecords: usersResponse.recordsFiltered,
      });

      reset({ users: selectedUsersIds });
    } catch (error) {
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [
    reset,
    onClose,
    currentId,
    isSupervisor,
    listAuthUsers,
    payloadColumns,
    generatePayload,
    getCostCenterUsers,
  ]);

  /**
   * Envia os dados de vínculos para o servidor.
   */
  const submitUsers = useCallback(
    async (values: ICostCenterUsersForm) => {
      await saveCostCenterUsers(currentId, values);
      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Vínculo(s) atualizado(s) com sucesso!",
      });

      onClose(OnCloseModalAction.Refresh);
    },
    [currentId, dialog, onClose, saveCostCenterUsers],
  );

  /**
   * Realiza uma verificação com o usuário antes de submeter os vínculos.
   */
  const checkSubmission = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const response = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        html: `A partir do momento que os Usuários de perfil Solicitante e
          Gestor são <strong>vinculados</strong> a um Centro de Custo, nas <strong>Solicitações de
          Pagamento</strong> e no <strong>Relátório de Centros de Custo</strong> somente irão aparecer
          os Centros de Custo vinculados a eles.`,
        showCancelButton: true,
        cancelButtonText: "Não, voltar",
        confirmButtonText: "Sim, estou ciente",
      });

      if (response?.dismiss) return;

      handleSubmit(submitUsers)(e);
    },
    [dialog, handleSubmit, submitUsers],
  );

  const handleModalClose = () => {
    onClose();
  };

  const handleModalAfterClose = () => {
    reset({ users: [] });
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc={!isLoading}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      onRequestClose={handleModalClose}
      onAfterClose={handleModalAfterClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Vínculo de usuários ao Centro de Custo</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-users-cross"
              onClick={handleModalClose}
              data-testid="btn-users-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="users-header">
              <p>Vinculando usuários ao Centro de Custo</p>
              <h3>{acronym}</h3>
              <p>
                Clique sobre o usuário e depois clique em &quot;Salvar&quot;
                para vinculá-lo ao centro de custo.
              </p>
            </div>
            <div className="react-modal-body">
              <form id="cost-center-users-form" onSubmit={checkSubmission} />
              <FormProvider {...formMethods}>
                <CostCenterUsersGrid
                  tableData={usersData}
                  isSupervisor={isSupervisor}
                  listAuthUsers={listAuthUsers}
                />
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-users-close"
                onClick={handleModalClose}
                data-testid="btn-users-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-users-save"
                data-testid="btn-users-save"
                form="cost-center-users-form"
                disabled={isSubmitting || isDisabled || !isDirty}
                className={`form-button ${
                  isDirty ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
