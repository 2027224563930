import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateCompanyContract } from "../../domain/contracts/updateCompanyContract";
import { ICompanyEntity } from "../../domain/entities/companyEntity";
import { ICompanyDefaultFormPayloadEntity } from "../../domain/entities/companyFormEntity";

export class UpdateCompanyService implements IUpdateCompanyContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateCompany(payload: ICompanyDefaultFormPayloadEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/Companies";

    const lockReleasesUntil = format(
      parse(payload.lockReleasesUntil, "ddMMyyyy", new Date()),
      "yyyy-MM-dd",
    ).toString();

    const finalPayload = {
      ...payload,
      lockReleasesUntil,
    };

    return this.api.put<ICompanyEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
