import { useCallback, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { FaSpinner } from "react-icons/fa";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import { MakeCompany } from "../../../main/makeCompany";
import { Container } from "./style";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useCompanyTaxForm } from "../../hooks/useCompanyTaxForm";
import { ICompanyTaxFormEntity } from "../../../domain/entities/companyTaxFormEntity";
import { CompanyTaxesGrid } from "../CompanyTaxesGrid";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { OnCloseModalAction } from "../CompanyPage";

export type CompanyTaxasModalProps = {
  isOpen: boolean;
  useCompany: MakeCompany;
  useProvider: MakeProvider;
  currentCompany?: ICompanyEntity;
  onClose(action?: OnCloseModalAction): void;
};

export function CompanyTaxesModal(props: CompanyTaxasModalProps) {
  const { isOpen, onClose, useCompany, useProvider, currentCompany } = props;

  const { getCompanyTaxes } = useCompany;
  const { id: companyId, assumedName } = currentCompany ?? {
    id: "",
    assumedName: "",
  };

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const createTaxForm = useCompanyTaxForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<ICompanyTaxFormEntity[]>([]);
  const submitState = useState(false);
  const editedState = useState(false);

  /**
   * Carrega os dados do formulário, formatando os valores recebidos do servidor.
   */
  const handleTableData = useCallback(async () => {
    if (!mountedRef.current) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await getCompanyTaxes(companyId);
      const formValues = response.map(r => createTaxForm(r));
      setFormData(formValues);
    } catch (error) {
      onClose();
    } finally {
      setIsLoading(false);
      ReactTooltip.rebuild();
    }
  }, [mountedRef, getCompanyTaxes, companyId, createTaxForm, onClose]);

  /**
   * Lida com o fechamento do modal. Espera uma confirmação do usuário caso
   * o formulário tenha sido modificado.
   */
  const handleCloseModal = useCallback(async () => {
    if (editedState[0]) {
      const result = await dialog.fire({
        icon: "question",
        title: "Atenção!",
        html: "Há alterações que não foram salvas e serão perdidas.<br />Deseja fechar o formulário sem salvar?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (result.dismiss) return;
    }
    editedState[1](false);
    onClose();
  }, [dialog, editedState, onClose]);

  /** Previne que o modal seja aberto caso não exista um ID de empresa */
  if (!companyId) return null;

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={handleTableData}
      shouldCloseOnEsc={!isLoading}
      className="react-modal-content"
      onRequestClose={handleCloseModal}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerenciar taxas automáticas de créditos</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-taxes-cross"
              data-testid="btn-taxes-cross"
              className="react-modal-close"
              onClick={handleCloseModal}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="taxes-modal-header">
              <p>
                Este registro {formData.length ? "está" : "será"} vinculado ao
                grupo de empresa
              </p>
              <h3>{assumedName}</h3>
            </div>
            <div className="react-modal-body">
              <CompanyTaxesGrid
                formData={formData}
                modalClose={onClose}
                companyId={companyId}
                useCompany={useCompany}
                submitState={submitState}
                useProvider={useProvider}
                editedState={editedState}
              />
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-taxes-close"
                onClick={handleCloseModal}
                data-testid="btn-taxes-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-taxes-save"
                form="company-taxes-form"
                disabled={submitState[0]}
                data-testid="btn-taxes-save"
                className="form-button green-bkg"
              >
                {submitState[0] ? "Salvando... " : "Salvar "}
                {submitState[0] ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
