export interface ILinkClassificationsAccountModel {
  classificationAccounts: string[];
}

export class LinkClassificationsAccountModel
  implements ILinkClassificationsAccountModel
{
  classificationAccounts = [];

  constructor(params?: Partial<ILinkClassificationsAccountModel>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
