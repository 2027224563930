import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeleteGeneratedFeesContract } from "../../domain/contracts/deleteGeneratedFeesContract";

export class DeleteGeneratedFeesService
  implements IDeleteGeneratedFeesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deleteGeneratedFees(accountPayableId: string): Promise<void> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountPayableFees`;

    const urlSearchParams = new URLSearchParams({
      accountPayableId,
    });

    await this.api.delete(`${url}?${urlSearchParams}`, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
