import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 35rem;

  div.react-modal-body {
    padding-top: 0;

    .warning {
      gap: 1rem;
      display: flex;
      align-items: center;

      flex: 1;

      color: #69583e;
      font-size: 0.875rem;

      border-radius: 0.375rem;
      border: 0.0625rem solid #ffe69c;
      background-color: #fff3cd;

      margin-top: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  div.react-modal-footer {
    display: flex;
    justify-content: end;
  }
`;
