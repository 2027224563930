import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  CompetencyEntity,
  ICompetencyEntity,
} from "../../domain/entities/competencyEntity";
import { ISaveCompetencyService } from "../../domain/usecases/saveCompetencyUseCase";

export class SaveCompetencyService implements ISaveCompetencyService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveCompetency(
    competency: ICompetencyEntity,
  ): Promise<ICompetencyEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Competencies`;

    let response;

    if (competency.id === "") {
      response = await this.api.post<ICompetencyEntity>(url, competency, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    } else if (competency.id !== "") {
      response = await this.api.put<ICompetencyEntity>(url, competency, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return response as CompetencyEntity;
  }
}
