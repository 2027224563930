import { useCallback, useMemo, useState } from "react";
import { FieldError, FieldErrors, useFormContext } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { IApiError } from "../../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../../core/domain/entities/attachmentsGridTypes";
import { NOT_FOUND_OPTION_ID } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../../../provider/main/makeProvider";
import { ProviderFormModal } from "../../../../../../provider/presentation/components/ProviderFormModal";
import { ILowerCaseErrorResponseEntity } from "../../../../../../simpleTable/domain/entities/responseEntity";
import { IPaymentRequestAttachmentEntity } from "../../../domain/entities/paymentRequestAttachmentEntity";
import {
  EFormMode,
  EPaymentRequestDestination,
  EPaymentRequestStatus,
  paymentRequestDestinationLang,
} from "../../../domain/entities/paymentRequestEnums";
import {
  IPaymentRequestFormEntity,
  PaymentRequestFormEntity,
} from "../../../domain/entities/paymentRequestFormEntity";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container, DestinationContainer, StatusContainer } from "./styles";
import {
  EAccountSubmitMode,
  AccountFormSplitButton,
} from "../../../../../../core/presentation/components/AccountFormSplitButton";

interface IFormSectionFooterState {
  total: number;
  loaded: number;
  isUploadModalOpen: boolean;
  isProviderModalOpen: boolean;
  isAttachmentsModalOpen: boolean;
}

interface IFormSectionFooterProps {
  mode: EFormMode;
  readonly?: boolean;
  useProvider: MakeProvider;
  isRequesterOrManager: boolean;
  usePaymentRequestForm: MakePaymentRequestForm;
  availableDestinations: EPaymentRequestDestination[];
}

interface ISubmitHandlerParams {
  newPaymentRequestId: string;
  submitMode: EAccountSubmitMode;
}

export function FormSectionFooter(props: IFormSectionFooterProps) {
  const {
    mode,
    useProvider,
    readonly = false,
    isRequesterOrManager,
    availableDestinations,
    usePaymentRequestForm,
  } = props;

  const [state, setState] = useState<IFormSectionFooterState>({
    total: 0,
    loaded: 0,
    isUploadModalOpen: false,
    isProviderModalOpen: false,
    isAttachmentsModalOpen: false,
  });

  const {
    savePaymentRequest,
    validateAttachments,
    updatePaymentRequest,
    uploadPaymentRequestAttachments,
    validateMeasurementAttachment,
  } = usePaymentRequestForm;

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const debounceTime = useDebounceTimeAsync();

  const form = useFormContext<IPaymentRequestFormEntity>();

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const provider = watch("provider");
  const providerId = provider?.rawValue as string | undefined;

  const memoizedProviderId = useMemo(() => {
    if (providerId === NOT_FOUND_OPTION_ID) {
      return "";
    }

    return providerId;
  }, [providerId]);

  const onUploadProgress = (event: ProgressEvent) => {
    setState(prevState => ({
      ...prevState,
      total: event.total,
      loaded: event.loaded,
    }));
  };

  const handleOpenProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: true,
    }));
  };

  const handleCloseProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: false,
    }));
  };

  const updateAttachments = useCallback(
    async (paymentRequestId: string, formData: IPaymentRequestFormEntity) => {
      const attachments = formData.storageFiles;

      if (attachments.length === 0) {
        return Promise.resolve();
      }

      return uploadPaymentRequestAttachments({
        attachments,
        onUploadProgress,
        paymentRequestId,
      });
    },
    [uploadPaymentRequestAttachments],
  );

  const handleSendPaymentRequest = useCallback(
    async (formData: IPaymentRequestFormEntity) => {
      const { id, status } = formData;

      const isEditableMode = !!id && mode !== EFormMode.Create;

      const isStatusSkippable = [
        EPaymentRequestStatus.Paid,
        EPaymentRequestStatus.Canceled,
        EPaymentRequestStatus.Approved,
      ].includes(status);

      const shouldSkipRequest = isEditableMode && isStatusSkippable;

      if (shouldSkipRequest) {
        return id;
      }

      let serviceFn = savePaymentRequest;

      if (mode === EFormMode.Edit || mode === EFormMode.EditAttachments) {
        serviceFn = updatePaymentRequest;
      }

      return serviceFn(formData);
    },
    [mode, savePaymentRequest, updatePaymentRequest],
  );

  const successDialog = useCallback(
    async (destination: EPaymentRequestDestination) => {
      const destinationText = paymentRequestDestinationLang[destination];
      const isCreating = [EFormMode.Create, EFormMode.Duplicate].includes(mode);

      const createMessage = (
        <>
          Solicitação para o <strong>{destinationText}</strong> efetuada com
          sucesso.
        </>
      );

      const editMessage = (
        <>
          Lançamento de Solicitação de Pagamento para o{" "}
          <strong>{destinationText}</strong> atualizado com sucesso.
        </>
      );

      return dialog.fire({
        icon: "success",
        title: "Feito!",
        showCancelButton: false,
        confirmButtonText: "Ok",
        html: isCreating ? createMessage : editMessage,
      });
    },
    [dialog, mode],
  );

  const submitWithNoAttachmentDialog = useCallback(
    async (formData: IPaymentRequestFormEntity) => {
      if (formData.storageFiles.length !== 0) {
        return false;
      }

      const result = await dialog.fire({
        icon: "question",
        title: "Atenção",
        confirmButtonText: "Sim",
        showCancelButton: true,
        cancelButtonText: "Não",
        html: (
          <>
            Você realmente deseja concluir esse lançamento sem <br /> colocar
            pelo menos um anexo?
          </>
        ),
      });

      return result.isDismissed;
    },
    [dialog],
  );

  const defineRequestDestination = useCallback(
    async (currentDestination: EPaymentRequestDestination | null) => {
      if (currentDestination) {
        return currentDestination;
      }

      if (availableDestinations.length === 1) {
        return availableDestinations[0];
      }

      const result = await dialog.fire({
        icon: "question",
        allowEnterKey: false,
        showConfirmButton: false,
        title: "Como você quer continuar?",
        html: (
          <>
            Selecione o tipo de solicitação de pagamento que você quer fazer.
            <DestinationContainer>
              <button
                type="button"
                className="form-button darkBlue-bkg"
                onClick={dialog.clickDeny}
              >
                Financeiro
              </button>
              <button
                type="button"
                className="form-button yellow-bkg"
                onClick={dialog.clickConfirm}
              >
                Dep. Pessoal
              </button>
              <button
                type="button"
                className="form-button red-bkg"
                onClick={dialog.clickCancel}
              >
                Ops, me enganei. Quero voltar!
              </button>
            </DestinationContainer>
          </>
        ),
      });

      if (result.isDismissed) {
        return null;
      }

      return result.isConfirmed
        ? EPaymentRequestDestination.PersonnelDepartment
        : EPaymentRequestDestination.Financial;
    },
    [dialog, availableDestinations],
  );

  const defineRequestStatus = useCallback(
    async (currentStatus: EPaymentRequestStatus | null) => {
      if (currentStatus !== EPaymentRequestStatus.NotRequested) {
        return currentStatus;
      }

      const result = await dialog.fire({
        icon: "question",
        allowEnterKey: false,
        showConfirmButton: false,
        title: "Você deseja solicitar aprovação?",
        html: (
          <>
            Caso você ainda tenha campos ou informações não preenchidas, você
            pode salvar a solicitação como <strong>Não solicitado</strong> e
            retomá-la em outro momento.
            <StatusContainer>
              <button
                type="button"
                className="form-button orange-bkg"
                onClick={dialog.clickDeny}
              >
                Solicitar aprovação depois
              </button>
              <button
                type="button"
                className="form-button green-bkg"
                onClick={dialog.clickConfirm}
              >
                Solicitar aprovação agora
              </button>
            </StatusContainer>
          </>
        ),
      });

      if (result.isDismissed) {
        return null;
      }

      return result.isConfirmed
        ? EPaymentRequestStatus.Requested
        : EPaymentRequestStatus.NotRequested;
    },
    [dialog],
  );

  const handleSubmitMode = useCallback(
    ({ submitMode, newPaymentRequestId }: ISubmitHandlerParams) => {
      if (submitMode === EAccountSubmitMode.AddMore) {
        reset(new PaymentRequestFormEntity());
        navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}/new`);
        return;
      }

      if (submitMode === EAccountSubmitMode.Duplicate) {
        setValue("duplicatePaymentRequestId", newPaymentRequestId);
        navigate(
          `${SoulRoutes.PAYMENT_REQUESTS.path}/new?duplicate=${newPaymentRequestId}`,
        );
        return;
      }

      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`);
    },
    [navigate, reset, setValue],
  );

  const warnIfHasNoNfsAttachments = useCallback(
    async (
      attachments: IPaymentRequestAttachmentEntity[],
      status: EPaymentRequestStatus,
    ) => {
      const notNfsAttached = attachments.every(
        a => a.type !== EAttachmentType.NFe && a.type !== EAttachmentType.NFSe,
      );

      if (status !== EPaymentRequestStatus.NotRequested && notNfsAttached) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Não consta nos anexos dessa solicitação uma nota fiscal ou
              documento equivalente. Você deverá entrar nesta mesma solicitação
              e anexar a nota assim que recebê-la.
            </>
          ),
        });
      }
    },
    [dialog],
  );

  const validateMeasurementAttachmentCheck = useCallback(
    async (value: number, allAttachment: IPaymentRequestAttachmentEntity[]) => {
      const measurementAttachmentIndex = allAttachment.findIndex(attachment => {
        return (
          attachment.active && attachment.type === EAttachmentType.Measurement
        );
      });

      if (measurementAttachmentIndex === -1) {
        return {
          preventSubmit: false,
        };
      }

      const measurementAttachment = allAttachment[measurementAttachmentIndex];

      try {
        const response = await validateMeasurementAttachment(
          value,
          measurementAttachment,
        );

        if (response.success === true) {
          return {
            preventSubmit: false,
          };
        }

        if (response.success === false) {
          await debounceTime(1);

          await dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: response.message,
          });
        }

        return {
          preventSubmit: true,
        };
      } catch (error) {
        await debounceTime(1);

        const messageHTMLError = (
          error as IApiError<ILowerCaseErrorResponseEntity>
        ).response.data.messageHTML;

        const messageError = (error as IApiError<ILowerCaseErrorResponseEntity>)
          .response.data.message;

        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: messageHTMLError || messageError,
        });

        return {
          preventSubmit: true,
        };
      }
    },
    [debounceTime, dialog, validateMeasurementAttachment],
  );

  const handleOnValid = useCallback(
    (submitMode: EAccountSubmitMode) => {
      return async (values: IPaymentRequestFormEntity) => {
        if (readonly) {
          return;
        }

        const currentStatus = values.status;
        const attachments = values.storageFiles;
        const currentDestination = values.destination;
        const isDuplicating = mode === EFormMode.Duplicate;
        let assessmentsList = values.assessments;
        const { value } = values;
        const allAttachment = values.storageFiles;

        if (isDuplicating) {
          assessmentsList = assessmentsList.filter(
            assessmentObject => assessmentObject.value !== "0",
          );

          setValue("assessments", assessmentsList);
        }

        const stopAndAttach = await submitWithNoAttachmentDialog(values);

        if (stopAndAttach) {
          return;
        }

        const validateMeasurement = await validateMeasurementAttachmentCheck(
          value,
          allAttachment,
        );

        if (validateMeasurement.preventSubmit) {
          return;
        }

        const destination = await defineRequestDestination(currentDestination);

        if (destination === null) {
          return;
        }

        const status = await defineRequestStatus(currentStatus);

        if (status === null) {
          return;
        }

        await warnIfHasNoNfsAttachments(attachments, status);

        setState(prevState => ({
          ...prevState,
          isUploadModalOpen: true,
        }));

        const formData = {
          ...values,
          destination,
          status,
          assessments: assessmentsList,
        };

        try {
          if (attachments.length !== 0 && attachments.some(a => !!a.file)) {
            await validateAttachments(attachments);
          }

          const newPaymentRequestId = await handleSendPaymentRequest(formData);

          await updateAttachments(newPaymentRequestId, formData);

          setState(prevState => ({
            ...prevState,
            total: 0,
            loaded: 0,
            isUploadModalOpen: false,
          }));

          await successDialog(destination);

          handleSubmitMode({
            submitMode,
            newPaymentRequestId,
          });
        } catch (error) {
          const typedError = error as Record<string, string>;

          if (typedError?.type === "invalid-attachments") {
            dialog.fire({
              icon: "error",
              title: "Erro",
              text:
                typedError?.message ||
                "Algo deu errado. Por favor, verifique os anexos e tente novamente.",
            });
          }
        } finally {
          setState(prevState => ({
            ...prevState,
            total: 0,
            loaded: 0,
            isUploadModalOpen: false,
          }));
        }
      };
    },
    [
      defineRequestDestination,
      defineRequestStatus,
      dialog,
      handleSendPaymentRequest,
      handleSubmitMode,
      mode,
      readonly,
      setValue,
      submitWithNoAttachmentDialog,
      successDialog,
      updateAttachments,
      validateAttachments,
      validateMeasurementAttachmentCheck,
      warnIfHasNoNfsAttachments,
    ],
  );

  const handleOnInvalid = useCallback(
    async (errors: FieldErrors<IPaymentRequestFormEntity>) => {
      if (readonly) {
        return;
      }

      const errorsValues = Object.values(errors);
      const errorsTypes = errorsValues.map(error => (error as FieldError).type);

      if (errorsTypes.includes("required")) {
        dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Existem campos obrigatórios não preenchidos. Preencha-os para
              adicionar o lançamento de solicitação de pagamento.
            </>
          ),
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

        return;
      }

      if (errors.barcode) {
        const isMaxLength = errors?.barcode?.type === "maxLength";

        dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Houve erros na validação da requisição.
              <br />
              Código de barras deve ter{" "}
              {isMaxLength ? "no máximo 48 dígitos." : "no minimo 44 dígitos."}
            </>
          ),
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

        return;
      }

      if (errors.assessments) {
        const assessmentError = errors.assessments as unknown as FieldError;

        if (assessmentError.type === "assessmentValue") {
          dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Os valores de rateio devem bater com o valor da solicitação de
                pagamento.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      if (errors.provider) {
        const providerError = errors.provider as FieldError;

        if (providerError.type === "bankDataRequired") {
          await dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Para prosseguir será necessário preencher os dados bancários do
                fornecedor selecionado.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          handleOpenProviderModal();
        }
      }
    },
    [dialog, readonly],
  );

  const handleFormSubmission = (submitMode: EAccountSubmitMode) => {
    handleSubmit(handleOnValid(submitMode), handleOnInvalid)();
  };

  const renderSubmitButton = () => {
    if (readonly) {
      return null;
    }

    if (mode === EFormMode.EditAttachments && !isRequesterOrManager) {
      return (
        <button
          type="button"
          disabled={isSubmitting}
          className="form-button green-bkg"
          onClick={() => {
            handleFormSubmission(EAccountSubmitMode.Default);
          }}
        >
          Concluir
        </button>
      );
    }

    return (
      <AccountFormSplitButton
        isSubmitting={isSubmitting}
        onClick={handleFormSubmission}
      />
    );
  };

  return (
    <Container>
      <Link to={SoulRoutes.PAYMENT_REQUESTS.path}>
        <button
          id="btn-back"
          type="button"
          data-testid="btn-back"
          disabled={isSubmitting}
          className="form-button red-bkg"
        >
          Voltar
        </button>
      </Link>
      {renderSubmitButton()}
      <RequestProgressModal
        total={state.total}
        loaded={state.loaded}
        isOpen={state.isUploadModalOpen}
      />
      <ProviderFormModal
        bankDataRequired
        useProvider={useProvider}
        isOpen={state.isProviderModalOpen}
        currentId={memoizedProviderId || ""}
        onRequestClose={handleCloseProviderModal}
      />
    </Container>
  );
}
