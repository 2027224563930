import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeleteAccountsPayablePanoramaContract } from "../../domain/contracts/deleteAccountsPayablePanoramaContract";

export class DeleteAccountsPayablePanoramaService
  implements IDeleteAccountsPayablePanoramaContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deleteAccountsPayablePanorama(panoramaId: string): Promise<void> {
    const url = `/AccountPayablePanoramas/${panoramaId}`;
    const userEntity = this.getUserLocalService.get();

    await this.api.delete(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
