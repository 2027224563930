import { ICostCenterTransactionInputEntity } from "../entities/costCenterTransactionInputEntity";
import { ICostCenterTransactionEntity } from "../entities/costCenterTransactionsEntity";

export interface ISaveCostCenterTransactionService {
  saveCostCenterTransaction(
    CostCenterTransactions: ICostCenterTransactionInputEntity,
  ): Promise<ICostCenterTransactionEntity>;
}

export interface ISaveCostCenterTransactionsUseCase {
  saveCostCenterTransaction(
    CostCenterTransactions: ICostCenterTransactionInputEntity,
  ): Promise<ICostCenterTransactionEntity>;
}

export class SaveCostCenterTransactionsUseCase
  implements ISaveCostCenterTransactionsUseCase
{
  constructor(
    private saveCostCenterTransactionsService: ISaveCostCenterTransactionService,
  ) {}

  saveCostCenterTransaction(
    CostCenterTransactions: ICostCenterTransactionInputEntity,
  ): Promise<ICostCenterTransactionEntity> {
    return this.saveCostCenterTransactionsService.saveCostCenterTransaction(
      CostCenterTransactions,
    );
  }
}
