import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchCostCenterContract } from "../../domain/contracts/searchCostCenterContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface ICostCenterResponse {
  id: string;
  acronym: string;
}

export class SearchCostCenterService implements ISearchCostCenterContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCostCenter({
    search = "",
    activesOnly,
    payloadOptions,
    companyGroupId,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "acronym",
          name: "acronym",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "active",
          name: "active",
          orderable: false,
          searchable: false,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/CostCenters`;

    if (activesOnly) {
      url += "/Actives";
    }

    const response = await this.api.get<
      IServerSideResponseModel<ICostCenterResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(costCenter => {
        return new RelationshipFilterOption({
          rawValue: costCenter.id,
          label: costCenter.acronym,
          metadata: showMetadata ? costCenter : undefined,
        });
      }),
    });
  }
}
