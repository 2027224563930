import { format } from "date-fns";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IGetProjectContract,
  IGetProjectParams,
} from "../../domain/contracts/getProjectContract";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";
import { IProjectEntity } from "../../domain/entities/projectEntity";
import { ProjectFormEntity } from "../../domain/entities/projectFormEntity";

export class GetProjectService implements IGetProjectContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getProject(params: IGetProjectParams) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Projects/${params.projectId}`;

    const response = await this.api.get<IProjectEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const costCenters = await this.getLinkedCostCenters(params);

    const coordinator = response.coordinatorId
      ? {
          rawValue: response.coordinatorId,
          label: response.coordinatorName || "",
        }
      : null;

    const deputyCoordinator = response.deputyCoordinatorId
      ? {
          rawValue: response.deputyCoordinatorId,
          label: response.deputyCoordinatorName || "",
        }
      : null;

    const competency = response.competencyId
      ? {
          rawValue: response.competencyId,
          label: response.competencyName || "",
        }
      : null;

    return new ProjectFormEntity({
      id: response.id,
      active: response.active,
      activitiesEndDate: this.formatDate(response.activitiesEndDate),
      activitiesStartDate: this.formatDate(response.activitiesStartDate),
      companyGroupId: response.companyGroupId ?? "",
      competency,
      coordinator,
      costCenters,
      courseStartDate: this.formatDate(response.courseStartDate),
      deputyCoordinator,
      description: response.description ?? "",
      fullName: response.fullName ?? "",
      fuspHash: response.fuspHash ?? "",
      openingBalance: response.openingBalance ?? 0,
    });
  }

  private async getLinkedCostCenters({
    projectId,
    companyGroupId,
  }: IGetProjectParams) {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups/${companyGroupId}/Projects/${projectId}/CostCenters`;

    const { data } = await this.api.get<
      IServerSideResponseModel<ICostCenterEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return data.map(costCenter => ({
      id: costCenter.id,
      acronym: costCenter.acronym,
      isDefaultForAccountReceivableImportation:
        costCenter.isDefaultForAccountReceivableImportation,
    }));
  }

  private formatDate(date: string) {
    if (!date) {
      return "";
    }

    return format(new Date(date), "ddMMyyyy");
  }
}
