import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 52rem;

  .react-modal-body {
    padding-top: 0 !important;

    .truncate {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card {
    padding: 1rem;
    background: #090081;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .description,
    .document-number {
      text-align: center;
    }

    & + div {
      margin-top: 1rem;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }
`;
