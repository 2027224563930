import { FileUpload } from "../../../../../../core/presentation/components/FileUpload";
import { Container } from "./styles";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";

/**
 * Objeto com o tipo padrão de arquivo aceito (no caso, planilhas Excel)
 */
const sheetType = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel": [".xls"],
};

export function FileStep() {
  const dialog = useSoulDialog();

  const {
    handleFileUpdate,
    state: { files },
  } = useSolicitImportPage();

  const onDropAccepted = (acceptedFiles: File[]) => {
    handleFileUpdate(acceptedFiles);
  };

  const onDropRejected = (errorText: string) => {
    dialog.fire({
      icon: "error",
      title: "Formato inválido",
      text: errorText,
    });
  };

  const onRemoveFile = () => {
    handleFileUpdate([]);
  };

  return (
    <Container>
      <div className="dropdown-container">
        <FileUpload
          files={files}
          accept={sheetType}
          onRemoveFile={onRemoveFile}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          placeholder="Selecione ou arraste sua planilha aqui"
        />
      </div>
    </Container>
  );
}
