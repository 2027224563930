import { IListAttachmentTypesContract } from "../../domain/contracts/listAttachmentTypesContract";
import { IAttachmentType } from "../../domain/entities/attachmentType";
import { IApiService } from "./apiService";
import { GetUserLocalService } from "./getUserLocalService";

export class ListAttachmentTypesService
  implements IListAttachmentTypesContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async listAttachmentTypes() {
    const userEntity = this.getUserLocalService.get();
    const url = "Enums/AttachmentTypes";

    return this.api.get<IAttachmentType[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
