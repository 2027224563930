import styled from "styled-components";

interface BadgeActiveProps {
  active: boolean;
}

export const BadgeActive = styled.div<BadgeActiveProps>`
  background-color: ${({ active }) => {
    if (active) {
      return "var(--text-muted-light)";
    }

    return "var(--soul-red-button-light)";
  }};

  color: ${({ active }) => (active ? "var(--text-muted)" : "white")};
  padding: 0.12em 1em;
  font-size: 0.65rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  text-transform: uppercase;
  min-width: 70px;
`;
