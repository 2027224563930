import { useCallback, useEffect } from "react";

/**
 * Monitora eventos de clique nos items de navegação interna da aplicação.
 * @param handler - Callback responsável por lidar com os eventos de clique capturados,
 * recebendo a rota de destino e o evento original como argumentos.
 * @param preventUnload - Parâmetro opcional, utilizado para indicar quando o evento
 * 'beforeunload' deve ser interrompido.
 */
export function useNavLinkClick(
  handler: (destinyRoute: string, originalEvent: Event) => Promise<void>,
  preventUnload?: boolean,
) {
  /**
   * O evento de clique pode ser disparado por filhos do elemento de navegação.
   * Essa função verifica todas as possibilidades de elementos, resgatando
   * a anchor tag para cada caso.
   */
  const getAnchorElement = (target: HTMLElement) => {
    const tagName = target?.tagName?.toLowerCase();

    switch (tagName) {
      case "span":
      case "svg":
        return target.parentElement;
      case "path": {
        const svgElement = target.parentElement;
        return svgElement?.parentElement;
      }
      default:
        return target;
    }
  };

  /**
   * Extrai o atributo 'data-soul-route' utilizado nos elementos de navegação.
   */
  const getDestinyRoute = useCallback((target: HTMLElement) => {
    const anchorElement = getAnchorElement(target);
    const soulRoute = anchorElement?.dataset?.soulRoute || "";
    return soulRoute;
  }, []);

  const routeClickHandler = useCallback(
    async (event: Event) => {
      const destinyRoute = getDestinyRoute(event.target as HTMLElement);
      await handler(destinyRoute, event);
    },
    [getDestinyRoute, handler],
  );

  useEffect(() => {
    const navLinksNodeList = document.querySelectorAll("[data-soul-route]");
    const navLinks = Array.from(navLinksNodeList);

    navLinks.map(link => {
      link.addEventListener("click", routeClickHandler, true);
      return link;
    });

    return () => {
      navLinks.map(link => {
        link.removeEventListener("click", routeClickHandler, true);
        return link;
      });
    };
  }, [routeClickHandler]);

  /**
   * Apenas lida com redirecionamentos para links externos, refresh de tela e
   * fechamento da aba/janela do navegador.
   * Navegação via rotas da aplicação, por padrão, não dispara esse evento.
   */
  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      if (preventUnload) {
        const eventCopy = event;
        eventCopy.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [preventUnload]);
}
