import { format, parse } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IExportAllAttachmentsContract } from "../../domain/contracts/exportAllAttachmentsContract";
import { IFiltersValue } from "../../domain/contracts/listPaymentAccountReportContract";
import { IExportAttachmentsResponse } from "../models/exportAttachmentsResponse";

export class ExportAllAttachmentsService
  implements IExportAllAttachmentsContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async exportAllAttachments(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
    attachmentTypes: number[],
  ) {
    const userEntity = this.getUserLocalService.get();

    const endpoint = "/Downloads/PaymentAccountReport/Attachments/StorageFiles";

    const newPayload = {
      ...payload,
      orderableStringField: {
        totalValueString: "totalValue",
        parcelValueString: "parcelValue",
        operationDateString: "operationDate",
        issueDateString: "issueDate",
        documentStatusDescription: "documentStatus",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        typeDescription: "type",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    let startDate: Date | null = null;
    if (filters.startDate) {
      startDate = parse(filters.startDate, "dd/MM/yyyy", new Date());
    }

    let endDate: Date | null = null;
    if (filters.endDate) {
      endDate = parse(filters.endDate, "dd/MM/yyyy", new Date());
    }

    const paramsOptions = {
      body: serverSideId,
      CompanyGroupId: companyGroupId,
      ReportFilterDate: filters.reportFilterDate,
      FinalFilterDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      InitialFilterDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
    };

    const params = new URLSearchParams(paramsOptions);

    if (filters.paymentAccountId) {
      params.append("PaymentAccountId", filters.paymentAccountId);
    }

    if (filters.projectId) {
      params.append("ProjectId", filters.projectId);
    }

    const types = attachmentTypes
      .map((type, index) => {
        return `attachmentTypes[${index}]=${type}`;
      })
      .join("&");

    const url = `${endpoint}?${params}&${types}`;

    return this.api.get<IExportAttachmentsResponse>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
