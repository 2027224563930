import { Skeleton } from "primereact/skeleton";
import {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { FaInfoCircle } from "react-icons/fa";
import { ITypeaheadOption } from "../../../domain/entities/typeaheadOption";
import { Container, TypeaheadPanel } from "./styles";

export const NOT_FOUND_OPTION_ID = "not-found-option";
export interface ISoulTypeaheadFocusEvent {
  target: {
    value: ITypeaheadOption | null;
  };
  type?: unknown;
  originalEvent: FocusEvent<HTMLInputElement>;
}

export interface ISoulTypeaheadChangeEvent {
  target: {
    value: ITypeaheadOption | null;
  };
  type?: unknown;
}

interface ISoulTypeaheadState {
  searchValue: string;
  open: boolean;
  highlightedOptionIndex: number | undefined;
  scrollIntoView: boolean;
  innerLoading: boolean;
}

interface ISoulTypeaheadBaseProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "value" | "onFocus" | "onBlur" | "type"
  > {
  options?: ITypeaheadOption[];
  value?: ITypeaheadOption | null;
  maxHeight?: string;
  notFoundOptionLabel?: string;
  openOnFocus?: boolean;
  panelMinWidth?: string;
  onChange?(option: ISoulTypeaheadChangeEvent): void;
  onFocus?(event: ISoulTypeaheadFocusEvent): void;
  onBlur?(event: ISoulTypeaheadFocusEvent): void;
  onSearchChange?(searchValue: string): void;
  onNotFoundOptionSelected?(): void;
}

type SoulTypeaheadProps = ISoulTypeaheadBaseProps &
  (
    | {
        serverSide: boolean;
        loading: boolean;
      }
    | {
        serverSide?: never;
        loading?: never;
      }
  );

export function SoulTypeahead({
  options,
  readOnly,
  value = null,
  openOnFocus = false,
  maxHeight = "10.875rem",
  panelMinWidth = "0px",
  notFoundOptionLabel = "Nenhuma opção disponível",
  serverSide = false,
  loading = false,
  onNotFoundOptionSelected,
  onSearchChange,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: SoulTypeaheadProps) {
  const [state, setState] = useState<ISoulTypeaheadState>(() => {
    return {
      searchValue: value?.label || "",
      open: false,
      highlightedOptionIndex: 0,
      scrollIntoView: false,
      innerLoading: false,
    };
  });

  const typeaheadPanelRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const adjustPanelPosition = useCallback(() => {
    if (inputRef.current && typeaheadPanelRef.current) {
      const panelEl = typeaheadPanelRef.current;
      const panelRect = panelEl.getBoundingClientRect();

      if (panelRect.bottom < window.innerHeight) {
        return;
      }

      const inputEl = inputRef.current;
      const inputRect = inputEl.getBoundingClientRect();
      const top = inputRect.top - panelRect.height;

      panelEl.style.top = `${top}px`;
    }
  }, []);

  const updatePanel = useCallback(() => {
    if (inputRef.current) {
      const inputEl = inputRef.current;

      const inputRect = inputEl.getBoundingClientRect();

      const { left, width } = inputRect;
      const top = inputRect.bottom;

      if (typeaheadPanelRef.current) {
        const panelEl = typeaheadPanelRef.current;

        const panelDefaultWidth = parseFloat(panelMinWidth) || width;

        panelEl.style.top = `${top}px`;
        panelEl.style.left = `${left}px`;
        panelEl.style.width = `${panelDefaultWidth}px`;

        adjustPanelPosition();
      }
    }
  }, [adjustPanelPosition, panelMinWidth]);

  const openPanel = useCallback(
    (loadingOptions = false) => {
      setState(prevState => ({
        ...prevState,
        open: true,
        innerLoading: loadingOptions,
      }));

      updatePanel();
    },
    [updatePanel],
  );

  const triggerChange = (option: ITypeaheadOption | null) => {
    let selectedOption = option;

    if (selectedOption?.rawValue === NOT_FOUND_OPTION_ID) {
      onNotFoundOptionSelected?.();
      selectedOption = null;
    }

    onChange?.({
      target: {
        value: selectedOption,
      },
    });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) {
      return;
    }

    const searchValue = event?.target?.value || "";

    if (!searchValue) {
      triggerChange(null);
    }

    setState(prevState => ({
      ...prevState,
      searchValue,
    }));

    if (!openOnFocus) {
      openPanel(serverSide);
    }

    onSearchChange?.(searchValue);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (readOnly) {
      return;
    }

    onSearchChange?.(state.searchValue || "");

    if (openOnFocus) {
      openPanel(serverSide);
    }

    onFocus?.({
      target: {
        value,
      },
      originalEvent: event,
    });
  };

  const filteredOptions = useMemo(() => {
    if (serverSide && options) {
      let memoizedOptions = [...options];

      if (!options.length) {
        memoizedOptions = [
          {
            label: notFoundOptionLabel,
            rawValue: NOT_FOUND_OPTION_ID,
          },
        ];
      }

      return memoizedOptions;
    }

    let filteredOptionsArray = options?.filter(option =>
      option.label
        .toLowerCase()
        .includes(state.searchValue.toLocaleLowerCase()),
    );

    if (!filteredOptionsArray?.length) {
      filteredOptionsArray = [
        {
          label: notFoundOptionLabel,
          rawValue: NOT_FOUND_OPTION_ID,
        },
      ];
    }

    return filteredOptionsArray;
  }, [notFoundOptionLabel, options, serverSide, state.searchValue]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (serverSide && (state.innerLoading || loading)) {
      return;
    }

    if (readOnly) {
      return;
    }

    if (!state.open) {
      return;
    }

    let { highlightedOptionIndex } = state;

    if (highlightedOptionIndex === undefined) {
      highlightedOptionIndex = 0;
    }

    const option = filteredOptions?.[highlightedOptionIndex] || undefined;

    setState(prevState => {
      const isNotFound = option?.rawValue === NOT_FOUND_OPTION_ID;

      return {
        ...prevState,
        open: false,
        searchValue: isNotFound ? "" : option?.label || prevState.searchValue,
      };
    });

    triggerChange(option);

    onBlur?.({
      target: {
        value: option,
      },
      originalEvent: event,
    });
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (readOnly) {
      return;
    }

    const { key } = event;

    const prevState = { ...state };

    let { highlightedOptionIndex, searchValue, open } = prevState;

    // nesta validação temos que verificar se é server-side para
    // evitar bug caso o usuário queira limpar a seleção do campo
    // quando for client-side
    if (serverSide && highlightedOptionIndex === -1) {
      highlightedOptionIndex = undefined;
    }

    const optionsLength = options?.length || 0;

    if (key === "ArrowUp") {
      if (highlightedOptionIndex === undefined) {
        highlightedOptionIndex = optionsLength ? optionsLength - 1 : 0;
      } else {
        highlightedOptionIndex -= 1;

        if (highlightedOptionIndex === -1) {
          highlightedOptionIndex = optionsLength ? optionsLength - 1 : 0;
        }
      }
    }

    if (key === "ArrowDown") {
      if (highlightedOptionIndex === undefined) {
        highlightedOptionIndex = 0;
      } else {
        highlightedOptionIndex += 1;

        if (highlightedOptionIndex === optionsLength) {
          highlightedOptionIndex = 0;
        }
      }
    }

    if (key === "Enter") {
      event.preventDefault();

      if (highlightedOptionIndex === undefined) {
        highlightedOptionIndex = -1;
      }

      const option = filteredOptions?.[highlightedOptionIndex] || undefined;

      if (option) {
        searchValue = option.label;
        open = false;
      }

      triggerChange(option);
    }

    setState({
      ...prevState,
      highlightedOptionIndex,
      scrollIntoView: true,
      searchValue,
      open,
    });
  };

  const renderPanel = () => {
    const escapeRegExp = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const highlightLabel = (label: string) => {
      const escapedSearchValue = escapeRegExp(state.searchValue);
      const regex = new RegExp(escapedSearchValue, "gi");
      const highlightedLabel = label.replace(
        regex,
        word => `<strong>${word}</strong>`,
      );

      return highlightedLabel;
    };

    const renderOption = (option: ITypeaheadOption) => {
      return {
        __html: highlightLabel(option.label),
      };
    };

    const handleOptionClick = (option: ITypeaheadOption) => {
      const { label } = option;

      setState(prevState => ({
        ...prevState,
        searchValue: label,
        open: false,
      }));

      triggerChange(option);
    };

    const handleMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
      const target = event.target as HTMLButtonElement;
      const { index } = target.dataset;

      setState(prevState => ({
        ...prevState,
        highlightedOptionIndex: Number(index),
        scrollIntoView: false,
      }));
    };

    const handleMouseLeave = () => {
      setState(prevState => ({
        ...prevState,
        highlightedOptionIndex: undefined,
        scrollIntoView: false,
      }));
    };

    let shouldOpen = state.open;

    if (serverSide) {
      shouldOpen =
        state.open &&
        !state.innerLoading &&
        document.activeElement === inputRef.current;
    }

    const panelJsx = (
      <TypeaheadPanel
        ref={originalRef => {
          typeaheadPanelRef.current = originalRef;
          adjustPanelPosition();
        }}
        maxHeight={maxHeight}
      >
        <ul className={shouldOpen ? "open" : undefined}>
          {filteredOptions?.map((option, index) => {
            const isNotFound = option.rawValue === NOT_FOUND_OPTION_ID;

            const optionClassName = `${
              state.highlightedOptionIndex === index ? "highlighted" : ""
            } ${option.rawValue === value?.rawValue ? "selected" : ""} ${
              isNotFound ? "not-found" : ""
            }`.trim();

            if (loading) {
              return (
                <li key={option.rawValue}>
                  <div className="loading-option">
                    <Skeleton />
                  </div>
                </li>
              );
            }

            if (isNotFound) {
              return (
                <li key={option.rawValue}>
                  <button
                    type="button"
                    aria-label={option.label}
                    title={option.label}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-index={index}
                    tabIndex={-1}
                    className={optionClassName}
                    onClick={() => handleOptionClick(option)}
                  >
                    <FaInfoCircle className="icon" />
                    {notFoundOptionLabel}
                  </button>
                </li>
              );
            }

            return (
              <li key={option.rawValue}>
                <button
                  type="button"
                  aria-label={option.label}
                  title={option.label}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  data-index={index}
                  tabIndex={-1}
                  className={optionClassName}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={renderOption(option)}
                  onClick={() => handleOptionClick(option)}
                />
              </li>
            );
          })}
        </ul>
      </TypeaheadPanel>
    );

    return createPortal(panelJsx, document.body, rest.id);
  };

  // Este useEffect eh responsavel por atualizar o scroll
  // do painel de opcoes conforme o usuario alterna entre
  // as opcoes utilizando a navegacao por teclado
  useEffect(() => {
    const highlightedOption = typeaheadPanelRef.current?.querySelector(
      "button.highlighted",
    ) as HTMLButtonElement | null;

    if (highlightedOption && state.scrollIntoView) {
      highlightedOption.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [state.highlightedOptionIndex, state.scrollIntoView]);

  // este useEffect monitora a prop loading e utiliza seu valor
  // para decidir quando atualizar o estado interno do componente
  // que controla a exibicao do indicador de carregando
  useEffect(() => {
    if (!loading) {
      setState(prevState => {
        const { searchValue } = prevState;
        let { highlightedOptionIndex } = prevState;

        if (!searchValue) {
          highlightedOptionIndex = -1;
        }

        if (value?.label === searchValue) {
          highlightedOptionIndex = 0;
        }

        return {
          ...prevState,
          innerLoading: false,
          highlightedOptionIndex,
        };
      });
    }
  }, [loading, value?.label]);

  // este useEffect monitora o scroll da janela e eh responsavel
  // por atualizar a posicao do reactPortal que renderiza o painel
  // de opcoes do typeahead
  useEffect(() => {
    updatePanel();

    window.addEventListener("scroll", updatePanel);

    return () => {
      window.removeEventListener("scroll", updatePanel);
    };
  }, [updatePanel]);

  // este useEffect monitora a prop value e utiliza seu valor
  // para decidir quando atualizar o estado interno do componente
  // que controla a exibicao do valor do campo de texto do typeahead
  useEffect(() => {
    if (value === null) {
      setState(prevState => ({
        ...prevState,
        searchValue: "",
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        searchValue: value.label,
      }));
    }
  }, [value]);

  return (
    <Container openOnFocus={openOnFocus}>
      <input
        {...rest}
        type="text"
        ref={inputRef}
        onChange={handleSearchChange}
        value={state.searchValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        readOnly={readOnly}
        autoComplete="off"
      />
      <span className="caret" />
      {renderPanel()}
    </Container>
  );
}
