import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IProviderEntity } from "../../../provider/domain/entities/providerEntity";
import { IGetProviderService } from "../../domain/usecases/getProviderUseCase";

export class GetProviderService implements IGetProviderService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getProvider(id: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Providers/${id}`;

    return this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
