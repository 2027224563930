import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import { StatusCodes } from "http-status-codes";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IExportSheetFealqContract } from "../../domain/contracts/exportSheetFealqContract";
import { IExportSheetFealqErrorEntity } from "../../domain/entities/exportSheetFealqErrorEntity";
import { IFealqExportationEntity } from "../../domain/entities/fealqExportationEntity";
import { IFealqExportationPayloadModel } from "../models/fealqExportationPayloadModel";

export class ExportSheetFealqService implements IExportSheetFealqContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async exportSheetFealq(
    exportSheeFealqEntity: IFealqExportationEntity,
  ): Promise<void> {
    const url = `AccountPayableFealqExportations/Export`;
    const userEntity = this.getUserLocalService.get();

    const dtInitialDate = parse(
      exportSheeFealqEntity.startDate,
      "ddMMyyyy HH:mm:ss",
      new Date(),
    );

    const initialDate = format(dtInitialDate, "yyyy-MM-dd HH:mm:ss");

    const payload: IFealqExportationPayloadModel = {
      initialDate,
    };

    // TODO retirar marcador opcional deste método
    // após corrigir todos os mocks dos testes
    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    // se response vier status 204 No Content,entao para ficar semantico
    // na camada de exibicao rejeitamos a promise com o response da api
    if (response?.status === StatusCodes.NO_CONTENT) {
      const error: IExportSheetFealqErrorEntity = {
        noDataToExport: true,
      };

      await Promise.reject(error);
      return;
    }

    if (response) {
      this.downloadFile(response);
    }
  }

  private downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
