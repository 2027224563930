import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IStorePaymentRequestPanoramaIdContract } from "../../domain/contracts/storePaymentRequestPanoamaIdContract";

export class StorePaymentRequestPanoramaIdService
  implements IStorePaymentRequestPanoramaIdContract
{
  storePaymentRequestPanoramaId(panoramaId: string): void {
    const key = ELocalStorageKeys.PaymentRequestPanoramaId;

    localStorage.setItem(key, Stringified.stringify(panoramaId) as string);
  }
}
