import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";

export function ReviewSectionFuspPurchaseOrderId() {
  return (
    <Card>
      <header>Informações FUSP</header>
      <section>
        <div className="form-row">
          <Controller
            name="fuspPurchaseOrderId"
            rules={{
              validate: {
                onlyDigit: val => val?.replace(/\d/g, "") === "",
                maxLength: val => val.length <= 16,
                max: val => BigInt(val || "") <= Number.MAX_SAFE_INTEGER,
              },
            }}
            render={({ field, fieldState }) => {
              const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                const inputValue = event.target.value;
                const numericValue = inputValue.replace(/\D/g, "");

                field.onBlur();
                field.onChange(numericValue);
              };
              return (
                <label className="col-6 form-control">
                  <span>
                    Nº pedido de compra <small>(opcional)</small>{" "}
                  </span>
                  <input
                    {...field}
                    onChange={onChange}
                    id="fusp-information"
                    placeholder="N° pedido de compra"
                    data-testid="fusp-information"
                  />
                  <InvalidFeedback
                    condition={
                      fieldState?.error?.type === "max" ||
                      fieldState?.error?.type === "maxLength"
                    }
                    message="O número do pedido deve ter no máximo 16 dígitos"
                  />

                  <InvalidFeedback
                    condition={fieldState?.error?.type === "onlyDigit"}
                    message="O n° pedido de compra deve conter apenas números."
                  />
                </label>
              );
            }}
          />
        </div>
      </section>
    </Card>
  );
}
