import { IPFSEventEntity } from "../entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleSearchableColumn,
  ISimpleSortableColumn,
} from "../entities/simpleColumnEntity";
import {
  IOrderEntity,
  IPayloadEntity,
  PayloadEntity,
} from "../entities/simplePayloadEntity";

export interface IGeneratePayloadUseCase {
  generatePayload(
    pfsEvent: IPFSEventEntity,
    columns: ISimpleColumn[],
  ): IPayloadEntity;
}

export class GeneratePayloadUseCase implements IGeneratePayloadUseCase {
  generatePayload(
    {
      first,
      globalFilter,
      rows,
      multiSortMeta = [],
      sortField,
      sortOrder = 1,
    }: IPFSEventEntity,
    columns: ISimpleColumn[],
  ): IPayloadEntity {
    const payloadColumns = columns.filter(column => !!column.field);
    const payloadMultiSortMeta = multiSortMeta?.filter(
      sortMeta => !!sortMeta.field,
    );

    let order: IOrderEntity[] = [];

    // se a tabela comecar com uma coluna ordenada por padrao
    if (sortField) {
      order = [
        {
          column: payloadColumns.findIndex(column => {
            return sortField === column.field;
          }),
          dir: sortOrder === 1 ? "asc" : "desc",
        },
      ];
    }

    // se o usuario tiver clicado para ordenar alguma coluna
    if (payloadMultiSortMeta?.length) {
      order = payloadMultiSortMeta?.map<IOrderEntity>(sortMeta => ({
        column: payloadColumns.findIndex(
          column => sortMeta.field === column.field,
        ),
        dir: sortMeta.order === 1 ? "asc" : "desc",
      }));
    }

    return new PayloadEntity({
      start: first,
      length: rows,
      order,
      search: { regex: false, value: globalFilter || "" },
      columns: payloadColumns.map(column => ({
        data: column.field,
        name: column.field,
        searchable: (column as ISimpleSearchableColumn).searchable,
        orderable: (column as ISimpleSortableColumn).orderable,
        search: { regex: false, value: column.searchParam ?? "" },
      })),
    });
  }
}
