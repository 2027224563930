import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetDebtImportTemplateContract } from "../../domain/contracts/getDebtImportTemplateContract";

export class GetDebtImportTemplateService
  implements IGetDebtImportTemplateContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getDebtImportTemplate() {
    const url = "/AccountPayableImportations/Template";
    const userEntity = this.getUserLocalService.get();

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
