import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ICompanyGroupEntity } from "../../domain/entities/companyGroupEntity";
import { IGetCompanyGroupListService } from "../../domain/usecases/getCompanyGroupListUseCase";

export class GetCompanyGroupService implements IGetCompanyGroupListService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async get(
    activeOnly: boolean,
    userId: string,
  ): Promise<ICompanyGroupEntity[]> {
    const baseUrl = `Users/${userId}/CompanyGroups`;
    const userEntity = this.getUserLocalService.get();
    const url = activeOnly ? `${baseUrl}/Actives` : baseUrl;

    const response = await this.api.get<IResponseEntity<ICompanyGroupEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response.data;
  }
}
