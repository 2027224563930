import { ColumnProps } from "primereact/column";
import { FaPaperclip, FaTrash } from "react-icons/fa";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { IDebtImportForm } from "../../domain/entities/debtImportTypes";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { useDebtImportPage } from "./useDebtImportPage";
import { CompanyField } from "../components/Fields/CompanyField";
import { DocumentNumberField } from "../components/Fields/DocumentNumberField";
import { ProviderField } from "../components/Fields/ProviderFIeld";
import { ValueField } from "../components/Fields/ValueField";
import { BarcodeField } from "../components/Fields/BarcodeField";
import { ClassificationAccountField } from "../components/Fields/ClassificationAccountField";
import { ClassificationUspField } from "../components/Fields/ClassificationUspField";
import { CompetencyField } from "../components/Fields/CompetencyField";
import { DescriptionField } from "../components/Fields/DescriptionField";
import { DocumentStatusField } from "../components/Fields/DocumentStatusField";
import { IssueDateField } from "../components/Fields/IssueDateField";
import { ObservationField } from "../components/Fields/ObservationField";
import { PayUntilField } from "../components/Fields/PayUntilField";
import { PaymentAccountField } from "../components/Fields/PaymentAccountField";
import { PaymentMethodField } from "../components/Fields/PaymentMethodField";
import { ProjectField } from "../components/Fields/ProjectField";
import { TerminationDateField } from "../components/Fields/TerminationDateField";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { ICompanyEntity } from "../../../../../company/domain/entities/companyEntity";
import { ICompetencyEntity } from "../../../../../competency/domain/entities/competencyEntity";
import { FuspPurchaseOrderIdField } from "../components/Fields/FuspPurchaseOrderIdField";
import { CorrespondingProviderField } from "../components/Fields/CorrespondingProviderField";
import { EProviderDocumentType } from "../../domain/entities/debtImportEnums";
import { IProviderEntity } from "../../../../../provider/domain/entities/providerEntity";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";

interface UseValidationStepGridProps {
  handleFormsRemoval(formsIds: string[]): boolean;
  handleOpenAttachmentModal(formIndex: number): void;
}

export function useValidationStepGrid(props: UseValidationStepGridProps) {
  const { handleOpenAttachmentModal, handleFormsRemoval } = props;

  const { getFormIndex, handleRemoveForm } = useDebtImportPage();

  const { getValues, getFieldState, formState } =
    useFormContext<IDebtImportForm>();

  const handleRowClassName = ({ id }: IDebtImportEntity) => {
    const formIndex = getFormIndex(id);
    const fieldState = getFieldState(`imports.${formIndex}`, formState);

    const isInvalid = !!fieldState.error;
    const isEdited = fieldState.isDirty && fieldState.isTouched;

    return {
      "row-edited": isEdited,
      "row-invalid": isInvalid,
      "row-valid": !isEdited && !isInvalid,
    };
  };

  const handleRowExpansionTemplate = useCallback(
    (data: IDebtImportEntity) => {
      const formIndex = getFormIndex(data.id);

      const formRowData = getValues(`imports.${formIndex}`);

      const competency =
        formRowData.competency as ITypeaheadOption<ICompetencyEntity>;
      const company = formRowData.company as ITypeaheadOption<ICompanyEntity>;
      const provider =
        formRowData.provider as ITypeaheadOption<IProviderEntity>;

      const competencyIsUsp = competency?.metadata?.isUsp || false;
      const companyHasProject = company?.metadata?.hasProject || false;
      const providerIsCnpj =
        Number(provider?.metadata?.documentType) === EProviderDocumentType.CNPJ;

      const billetAttachment = formRowData?.storageFiles?.find(a => {
        return Number(a.type) === EAttachmentType.Billet;
      });

      const companyIsFusp = company?.label === "FUSP";

      return (
        <div className="expanded-template">
          <div className="form-fields">
            <DescriptionField formIndex={formIndex} />
            <PaymentMethodField formIndex={formIndex} />
            <DocumentStatusField formIndex={formIndex} />
            <ObservationField formIndex={formIndex} />
            <IssueDateField formIndex={formIndex} />
            <PayUntilField formIndex={formIndex} />
            <TerminationDateField formIndex={formIndex} />
            <ClassificationAccountField formIndex={formIndex} />
            <PaymentAccountField formIndex={formIndex} />
            {providerIsCnpj ? (
              <CorrespondingProviderField formIndex={formIndex} />
            ) : null}
            {companyHasProject ? (
              <>
                <ProjectField formIndex={formIndex} />
                <CompetencyField formIndex={formIndex} />
                {competencyIsUsp ? (
                  <ClassificationUspField formIndex={formIndex} />
                ) : null}
              </>
            ) : null}
            {companyIsFusp ? (
              <FuspPurchaseOrderIdField formIndex={formIndex} />
            ) : null}
          </div>
          {billetAttachment ? <BarcodeField formIndex={formIndex} /> : null}
        </div>
      );
    },
    [getFormIndex, getValues],
  );

  const columns: ColumnProps[] = useMemo(() => {
    return [
      {
        field: "expander",
        expander: true,
        style: {
          width: "3%",
        },
      },
      {
        header: "Empresa",
        field: "companyName",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <CompanyField formIndex={formIndex} />;
        },
      },
      {
        header: "Nº do Documento",
        field: "documentNumber",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          return (
            <DocumentNumberField
              formIndex={formIndex}
              accountAlreadyPaid={data.accountAlreadyPaid}
              accountAlreadyExists={data.accountAlreadyExists}
              accountAlreadyExistsForAnotherUser={
                data.accountAlreadyExistsForAnotherUser
              }
            />
          );
        },
      },
      {
        header: "Fornecedor",
        field: "providerName",
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          return (
            <ProviderField
              formIndex={formIndex}
              accountAlreadyPaid={data.accountAlreadyPaid}
              accountAlreadyExists={data.accountAlreadyExists}
              accountAlreadyExistsForAnotherUser={
                data.accountAlreadyExistsForAnotherUser
              }
            />
          );
        },
      },
      {
        header: "Valor",
        field: "value",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <ValueField formIndex={formIndex} />;
        },
      },
      {
        field: "edit-attachments",
        style: {
          width: "3%",
        },
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          const storageFiles = getValues(`imports.${formIndex}.storageFiles`);
          return (
            <button
              type="button"
              data-effect="solid"
              data-tip="Editar anexos"
              id={`btn-attachment-${formIndex}`}
              data-testid={`btn-attachment-${formIndex}`}
              className="outline-button attachment-button"
              onClick={() => {
                handleOpenAttachmentModal(formIndex);
              }}
            >
              <FaPaperclip />
              <span>{storageFiles?.length || 0}</span>
            </button>
          );
        },
      },
      {
        field: "remove-row",
        style: {
          width: "3%",
        },
        body(data: IDebtImportEntity) {
          const formIndex = getFormIndex(data.id);
          return (
            <button
              type="button"
              data-tip="Remover"
              data-effect="solid"
              className="p-link p-row-toggler"
              id={`btn-remove-row-${formIndex}`}
              data-testid={`btn-remove-row-${formIndex}`}
              onClick={() => {
                handleRemoveForm([data.id], handleFormsRemoval);
              }}
            >
              <FaTrash />
            </button>
          );
        },
      },
    ];
  }, [
    getValues,
    getFormIndex,
    handleRemoveForm,
    handleFormsRemoval,
    handleOpenAttachmentModal,
  ]);

  return {
    columns,
    handleRowClassName,
    handleRowExpansionTemplate,
  };
}
