import { useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiError } from "../../../../../core/data/services/apiService";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { ILowerCaseErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";
import { IPaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";
import { MakePaymentRequestForm } from "../../main/makePaymentRequestForm";

interface IUseAttachmentsHandlerParams {
  isReview?: boolean;
  isRequesterOrManager?: boolean;
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function useAttachmentsHandler(params: IUseAttachmentsHandlerParams) {
  const { isReview, usePaymentRequestForm, isRequesterOrManager } = params;

  const {
    getStorageFilebyId,
    listAttachmentTypes,
    fetchRpaParamOptions,
    generateAttachmentRpa,
    updateAttachmentsBarcode,
    listRequesterAttachmentTypes,
    generateAttachmentVariableAdditional,
    validateMeasurementAttachment,
  } = usePaymentRequestForm;

  const listAttachmentTypesService = isRequesterOrManager
    ? listRequesterAttachmentTypes
    : listAttachmentTypes;

  const dialog = useSoulDialog();
  const form = useFormContext<IPaymentRequestFormEntity>();
  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const { getValues, setValue, watch } = form;

  const debounceTime = useDebounceTimeAsync();

  const attachmentsWatcher = watch("storageFiles");

  const handleRpaGeneration = async (
    rpaInputOptions: IGenerateRPAModalInputEntity,
  ) => {
    dialog.fire({
      title: "Aguarde",
      html: "Estamos gerando o anexo de RPA",
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const paymentRequestForm = getValues();

      const generatedFile = await generateAttachmentRpa({
        paymentRequestForm,
        generateRPAModalInput: rpaInputOptions,
      });

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleAttachmentListChange = (
    attachments: IPaymentRequestAttachmentEntity[],
  ) => {
    setValue("storageFiles", attachments);
  };

  const handleVariableAdditionalGeneration = async () => {
    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Adicional variável`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const paymentRequestForm = getValues();

      const generatedFile = await generateAttachmentVariableAdditional(
        paymentRequestForm,
      );

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IPaymentRequestAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        companyGroupId,
        billetAttachment,
      );

      setValue("barcode", updatedAttachment.barcode);

      return {
        updatedAttachment,
        preventSubmit: false,
      };
    } catch {
      dialog.fire({
        icon: "warning",
        title: "Aviso",
        html: (
          <>
            Não foi possível obter o código de barras desse boleto. Faça a
            inclusão por dentro da solicitação de pagamento e preencha o código
            de barras
          </>
        ),
      });

      return {
        preventSubmit: false,
        updatedAttachment: billetAttachment,
      };
    }
  };

  const handleValidateMeasurementAttachment = async (
    measurementAttachment: IPaymentRequestAttachmentEntity,
  ) => {
    const value = getValues("value");

    try {
      const response = await validateMeasurementAttachment(
        value,
        measurementAttachment,
      );

      if (response.success === true) {
        return {
          preventSubmit: false,
          updatedAttachment: measurementAttachment,
        };
      }

      if (response.success === false) {
        await debounceTime(1);

        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: response.message,
        });
      }

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    } catch (error) {
      await debounceTime(1);

      const messageHTMLError = (
        error as IApiError<ILowerCaseErrorResponseEntity>
      ).response.data.messageHTML;

      const messageError = (error as IApiError<ILowerCaseErrorResponseEntity>)
        .response.data.message;

      await dialog.fire({
        icon: "error",
        title: "Opa!",
        html: messageHTMLError || messageError,
      });

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IPaymentRequestAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.RPA &&
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.VariableAdditional &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.RPA]: "RPA",
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.VariableAdditional]: "Adicional Variável",
      [EAttachmentType.Measurement]: "Medição",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  return {
    attachmentsWatcher,
    getStorageFilebyId,
    handleAttachmentListChange,
    attachmentTypeCheck: handleAttachmentTypeChange,
    listAttachmentTypes: listAttachmentTypesService,
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
    validateMeasurementAttachment: handleValidateMeasurementAttachment,
    fetchRpaParamOptions: isReview ? fetchRpaParamOptions : undefined,
    generateRpaAttachment: isReview ? handleRpaGeneration : undefined,
    generateVariableAdditionalAttachment: isReview
      ? handleVariableAdditionalGeneration
      : undefined,
  };
}
