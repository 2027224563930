import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
} from "react";
import { Container } from "./styles";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  disabled?: boolean;
}

function CheckboxInner(
  props: CheckboxProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) {
  const renderLabel = useCallback(({ label }) => {
    if (label) {
      return <span>{label}</span>;
    }

    return null;
  }, []);

  return (
    <Container>
      <input {...props} ref={forwardedRef} type="checkbox" />
      <div />
      {renderLabel(props)}
    </Container>
  );
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  CheckboxInner,
);

Checkbox.defaultProps = {
  label: "",
  disabled: false,
};
