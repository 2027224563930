import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICostCenterTransactionEntity } from "../entities/costCenterTransactionsEntity";

export interface IListCostCenterTransactionsService {
  listCostCenterTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICostCenterTransactionEntity[]>>;
}

export interface IListCostCenterTransactionsUseCase {
  listCostCenterTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICostCenterTransactionEntity[]>>;
}

export class ListCostCenterTransactionsUseCase
  implements IListCostCenterTransactionsUseCase
{
  constructor(
    private listCostCenterTransactionsService: IListCostCenterTransactionsService,
  ) {}

  listCostCenterTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICostCenterTransactionEntity[]>> {
    return this.listCostCenterTransactionsService.listCostCenterTransactions(
      companyGroupId,
      payload,
    );
  }
}
