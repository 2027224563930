import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IAddressForm } from "../../../data/models/addressForm";
import { ICityResponse } from "../../../data/models/cityResponse";
import {
  ICountryList,
  ICountryResponse,
} from "../../../data/models/countryResponse";
import { IStateResponse } from "../../../data/models/stateResponse";
import { MakeCustomer } from "../../../main/makeCustomer";
import { Container, Loading } from "./styles";

interface AddressFormProps {
  useCustomer: MakeCustomer;
  customerId: string;
}

export function AddressForm({ useCustomer }: AddressFormProps) {
  const {
    getByZipCode,
    getStateByUf,
    getCityByIbge,
    listCountriesByName,
    getStateByName,
    getCityByName,
  } = useCustomer;

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    control,
    resetField,
  } = useFormContext();

  const debounce = useDebounceTime();

  const [countryList, setCountryList] = useState<ICountryList[]>([]);
  const [stateList, setStateList] = useState<IStateResponse[]>([]);
  const [cityList, setCityList] = useState<ICityResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);

  const getCountryList = useCallback(
    async (activeOnly = false, country = "") => {
      let payload = null as PayloadEntity | null;
      if (country) {
        payload = new PayloadEntity({
          draw: 0,
          order: [],
          start: 0,
          search: { value: "", regex: false },
          columns: [
            {
              data: "name",
              name: "",
              searchable: true,
              orderable: true,
              search: { value: country, regex: false },
            },
          ],
        });
      }

      const { data } = await listCountriesByName(activeOnly, payload);
      return data;
    },
    [listCountriesByName],
  );

  const [defaultCountry, setDefaultCountry] = useState<ICountryResponse | null>(
    null,
  );

  const [isSelectedDefaultCountry, setIsSelectedDefaultCountry] = useState(
    () => {
      return defaultCountry?.id === getValues().countryId;
    },
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const [brazilCountryData] = await getCountryList(true, "Brasil");
      setDefaultCountry(brazilCountryData);
      const allCountries = await getCountryList(true);

      setCountryList(allCountries);
      setIsLoading(false);
    })();

    return () => {
      setDefaultCountry(null);
      setCountryList([]);
      reset();
      setIsLoading(false);
    };
  }, [getCountryList, reset]);

  const selectedCountryId = watch("countryId");

  useEffect(() => {
    setIsSelectedDefaultCountry(defaultCountry?.id === selectedCountryId);
  }, [defaultCountry?.id, selectedCountryId]);

  const onChangeCountryId = (e: { target: { value: string } }) => {
    const { value: countryId } = e.target;

    const filteredCountry = countryList.find(
      country => country.id === countryId,
    );

    setValue("countryName", filteredCountry ? filteredCountry.name : "");
    setIsSelectedDefaultCountry(countryId === defaultCountry?.id);
  };

  const onBlurZipCode = async (zipCode: string) => {
    if (!isSelectedDefaultCountry) {
      return;
    }

    const regExp = /\D/g;
    const unmaskedZipCode = zipCode.replace(regExp, "");

    if (unmaskedZipCode.length === 8) {
      setIsLoadingZipCode(true);
      const viaCepMetadata = await getByZipCode(unmaskedZipCode);

      if (viaCepMetadata === null) {
        setIsLoadingZipCode(false);
        return;
      }

      const stateMetadata = await getStateByUf(viaCepMetadata.uf);

      if (!stateMetadata) {
        setIsLoadingZipCode(false);
        return;
      }

      const cityMetadata = await getCityByIbge(viaCepMetadata.ibge);

      if (!cityMetadata) {
        setIsLoadingZipCode(false);
        return;
      }

      const form: Partial<IAddressForm> = {
        neighborhood: viaCepMetadata.neighborhood,
        street: viaCepMetadata.street,
        stateId: stateMetadata.id,
        stateName: stateMetadata.name,
        cityId: cityMetadata.id,
        cityName: cityMetadata.name,
      };

      reset({
        ...getValues(),
        ...form,
        placeNumber: "",
        complement: "",
      });
      setIsLoadingZipCode(false);
    }
  };

  const getStateList = (state: string) => {
    if (!isSelectedDefaultCountry) {
      setStateList([]);
      return;
    }

    debounce(async () => {
      let payload = null as PayloadEntity | null;
      payload = new PayloadEntity({
        draw: 0,
        order: [],
        start: 0,
        search: { value: "", regex: false },
        columns: [
          {
            data: "name",
            name: "",
            searchable: true,
            orderable: true,
            search: { value: state, regex: false },
          },
        ],
      });

      const res = await getStateByName(defaultCountry?.id ?? "", payload);
      setStateList(res.data);
    }, 300);
  };

  const validateState = () => {
    if (isSelectedDefaultCountry && !getValues("stateId")) {
      return "requiredSelection";
    }
    return true;
  };

  const validateCity = () => {
    if (getValues("stateId") && !getValues("cityId")) {
      return "requiredSelection";
    }
    return true;
  };

  const onChangeState = (state: IStateResponse) => {
    resetField("cityName", { defaultValue: "" });
    setValue("stateId", state.id ?? "");
    setValue("stateName", state.name ?? state);
  };

  const getCityList = async (city: string) => {
    if (!getValues("stateId")) {
      setCityList([]);
      return;
    }

    if (city.length >= 3) {
      let payload = null as PayloadEntity | null;
      payload = new PayloadEntity({
        draw: 0,
        order: [],
        start: 0,
        search: { value: "", regex: false },
        columns: [
          {
            data: "name",
            name: "",
            searchable: true,
            orderable: true,
            search: { value: city, regex: false },
          },
        ],
      });

      const res = await getCityByName(getValues("stateId"), payload);
      setCityList(res.data);
    }
  };

  const onChangeCity = (city: ICityResponse) => {
    setValue("cityId", city.id ?? "");
    setValue("cityName", city.name ?? city);
  };

  return (
    <Container>
      <div className="form-header">
        Endereço {isLoadingZipCode && <FaSpinner className="spinner" />}
      </div>
      {isLoading ? (
        <Loading>
          <FaSpinner className="spinner" />
        </Loading>
      ) : (
        <div className="form-container">
          <div className="form-row">
            <label className="col-4 form-control">
              <span>País</span>
              <Controller
                control={control}
                name="countryId"
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <Dropdown
                      options={countryList}
                      filter
                      filterBy="name"
                      optionLabel="name"
                      optionValue="id"
                      onChange={e => {
                        onChangeCountryId(e);
                        onChange(e.value ?? "");
                      }}
                      onBlur={onBlur}
                      value={value ?? ""}
                      placeholder="Selecione um país"
                      showClear
                      className={error?.type === "required" ? "p-invalid" : ""}
                      disabled={isLoadingZipCode}
                      data-testid="customer-country"
                    />
                    <InvalidFeedback
                      condition={error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                )}
              />
            </label>
          </div>
          <div className="form-row">
            <label className="col-4 form-control">
              <span>CEP</span>
              <Controller
                control={control}
                name="zipCode"
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 13,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <InputMask
                      onChange={onChange}
                      onBlur={e => {
                        onBlurZipCode(e.target.value);
                      }}
                      autoClear={isSelectedDefaultCountry}
                      unmask
                      placeholder={
                        isSelectedDefaultCountry ? "99999-999" : "CEP"
                      }
                      mask={
                        isSelectedDefaultCountry ? "99999-999" : "*".repeat(13)
                      }
                      slotChar={isSelectedDefaultCountry ? "_" : ""}
                      value={value}
                      className={error?.type === "required" ? "isInvalid" : ""}
                      disabled={isLoadingZipCode}
                      data-testid="customer-zipCode"
                    />
                    <InvalidFeedback
                      condition={error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                )}
              />
            </label>
            <label className="col-6 form-control">
              <span>Rua</span>
              <input
                {...register("street", {
                  required: true,
                  maxLength: 100,
                })}
                className={
                  errors?.street?.type === "required" ? "isInvalid" : ""
                }
                maxLength={100}
                placeholder="Rua"
                disabled={isLoadingZipCode}
                data-testid="customer-street"
              />
              <InvalidFeedback
                condition={errors?.street?.type === "required"}
                message="Este campo é obrigatório"
              />
            </label>
            <label className="col-2 form-control">
              <span>Número</span>
              <input
                {...register("placeNumber", {
                  required: true,
                  maxLength: 10,
                })}
                maxLength={10}
                placeholder="Número"
                disabled={isLoadingZipCode}
                className={
                  errors?.placeNumber?.type === "required" ? "isInvalid" : ""
                }
                data-testid="customer-number"
              />
              <InvalidFeedback
                condition={errors?.placeNumber?.type === "required"}
                message="Este campo é obrigatório"
              />
            </label>
          </div>

          <div className="form-row">
            <label className="col-3 form-control">
              <span>Estado</span>
              <Controller
                control={control}
                name="stateName"
                rules={{
                  required: true,
                  onChange: e => onChangeState(e.target.value),
                  validate: () => validateState(),
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <AutoComplete
                      field="name"
                      onChange={onChange}
                      onBlur={onBlur}
                      suggestions={stateList}
                      completeMethod={e => {
                        getStateList(e.query);
                      }}
                      value={value}
                      placeholder="Digite"
                      inputClassName={
                        error?.type === "required" || error?.message
                          ? "p-invalid"
                          : ""
                      }
                      disabled={isLoadingZipCode}
                      data-testid="customer-state"
                    />
                    <InvalidFeedback
                      condition={error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                    <InvalidFeedback
                      condition={error?.message === "requiredSelection"}
                      message="Selecione um estado sugerido"
                    />
                  </>
                )}
              />
            </label>
            <label className="col-3 form-control">
              <span>Cidade</span>
              <Controller
                control={control}
                name="cityName"
                rules={{
                  required: true,
                  onChange: e => onChangeCity(e.target.value),
                  validate: () => validateCity(),
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <>
                    <AutoComplete
                      field="name"
                      onChange={onChange}
                      onBlur={onBlur}
                      suggestions={cityList}
                      completeMethod={e => {
                        getCityList(e.query);
                      }}
                      value={value}
                      placeholder="Digite"
                      inputClassName={
                        error?.type === "required" || error?.message
                          ? "p-invalid"
                          : ""
                      }
                      disabled={isLoadingZipCode}
                      data-testid="customer-city"
                    />
                    <InvalidFeedback
                      condition={error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                    <InvalidFeedback
                      condition={error?.message === "requiredSelection"}
                      message="Selecione uma cidade sugerida"
                    />
                  </>
                )}
              />
            </label>
            <label className="col-3 form-control">
              <span>Bairro</span>
              <input
                {...register("neighborhood", {
                  required: true,
                  maxLength: 100,
                })}
                maxLength={100}
                className={
                  errors.neighborhood?.type === "required" ? "isInvalid" : ""
                }
                placeholder="Bairro"
                disabled={isLoadingZipCode}
                data-testid="customer-neighborhood"
              />
              <InvalidFeedback
                condition={errors.neighborhood?.type === "required"}
                message="Este campo é obrigatório"
              />
            </label>
            <label className="col-3 form-control">
              <span>
                Complemento <small>(opcional)</small>
              </span>
              <input
                {...register("complement")}
                placeholder="Complemento"
                disabled={isLoadingZipCode}
              />
            </label>
          </div>
        </div>
      )}
    </Container>
  );
}
