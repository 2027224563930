import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ITogglePaymentAccountContract } from "../../domain/contracts/togglePaymentAccountContract";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";

export class TogglePaymentAccountService
  implements ITogglePaymentAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  togglePaymentAccount(projectId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentAccounts/${projectId}`;

    return this.api.delete<IPaymentAccountEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
