import { ChangeEvent, useCallback } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../../domain/entities/remittancesEnums";

interface SelectRemmitanceTypeModalContentProps {
  showWarning: boolean;
  handleSelectChange(value: string): void;
  hasSelectedPaidData: boolean;
}

export const remittanceValue = {
  SafraTaxes: `${ERemittanceType.Taxes}-${ERemittanceBankCode.Safra}`,
  SafraPayroll: `${ERemittanceType.Payroll}-${ERemittanceBankCode.Safra}`,
  SafraProvider: `${ERemittanceType.Provider}-${ERemittanceBankCode.Safra}`,
  SantanderTaxes: `${ERemittanceType.Taxes}-${ERemittanceBankCode.Santander}`,
  SantanderPayroll: `${ERemittanceType.Payroll}-${ERemittanceBankCode.Santander}`,
  SantanderProvider: `${ERemittanceType.Provider}-${ERemittanceBankCode.Santander}`,
};

export function SelectRemmitanceTypeModalContent(
  props: SelectRemmitanceTypeModalContentProps,
) {
  const { showWarning, handleSelectChange, hasSelectedPaidData } = props;

  /**
   * Lida com a mudança do input de seleção de tipo de remessa.
   */
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      handleSelectChange(value);
    },
    [handleSelectChange],
  );

  return (
    <div className="form-container">
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Tipo da Remessa</span>
          <select defaultValue="" onChange={handleChange}>
            <option value="" hidden disabled>
              Selecione o tipo de remessa a ser gerada
            </option>
            <option value={remittanceValue.SafraPayroll}>
              Folha de Pagamento (Safra)
            </option>
            <option value={remittanceValue.SafraProvider}>
              Pagamento a Fornecedores (Safra)
            </option>
            <option value={remittanceValue.SafraTaxes}>
              Pagamento de Tributos (Safra)
            </option>
            <option
              value={remittanceValue.SantanderProvider}
              disabled={hasSelectedPaidData}
            >
              Pagamento a Fornecedores (Santander)
            </option>
            <option value={remittanceValue.SantanderPayroll}>
              Folha de Pagamento (Santander)
            </option>
            <option value={remittanceValue.SantanderTaxes}>
              Pagamento de Tributos (Santander)
            </option>
          </select>
          {showWarning ? (
            <div className="payroll-warning">
              <FaExclamationCircle />
              <p>Selecione contas na tabela para prosseguir com a geração</p>
            </div>
          ) : null}
        </label>
      </div>
    </div>
  );
}
