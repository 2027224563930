import { format, parse } from "date-fns";
import { IReportServerSideResponseModel } from "../../../../core/data/models/reportServerSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  IFiltersValue,
  IListUspIncomeStatementReportContract,
} from "../../domain/contracts/listUspIncomeStatementReportContract";
import { IUspIncomeStatementReportEntity } from "../../domain/entities/uspIncomeStatementReportEntity";
import { IUspIncomeStatementReportFiltersParams } from "../models/uspIncomeStatementReportFiltersParams";

export type URLSearchParamsType = Partial<
  Record<keyof IUspIncomeStatementReportFiltersParams, string>
>;

export class ListUspIncomeStatementReportService
  implements IListUspIncomeStatementReportContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listUspIncomeStatementReport(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
  ) {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        totalValueString: "totalValue",
        parcelValueString: "parcelValue",
        operationDateString: "operationDate",
        issueDateString: "issueDate",
        documentStatusDescription: "documentStatus",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        typeDescription: "type",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    let startDate: Date | null = null;
    if (filters.startDate) {
      startDate = parse(filters.startDate, "dd/MM/yyyy", new Date());
    }

    let endDate: Date | null = null;
    if (filters.endDate) {
      endDate = parse(filters.endDate, "dd/MM/yyyy", new Date());
    }

    const paramsOptions = {
      body: serverSideId,
      InitialFilterDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      FinalFilterDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      CompanyGroupId: companyGroupId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/Reports/UspIncomeStatement?${params}`;

    const response = await this.api.get<
      IReportServerSideResponseModel<IUspIncomeStatementReportEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
