import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchCustomerProviderByCorporationNameContract } from "../../domain/contracts/searchCustomerProviderByCorporationNameContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface ICustomerProviderResponse {
  id: string;
  corporationName: string;
  document: string;
}
export class SearchCustomerProviderByCorporationNameService
  implements ISearchCustomerProviderByCorporationNameContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCustomerProviderByCorporationName({
    search = "",
    companyGroupId,
    payloadOptions,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/CustomersProviders`;

    const response = await this.api.get<
      IServerSideResponseModel<ICustomerProviderResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(customerProvider => {
        return new RelationshipFilterOption({
          rawValue: customerProvider.id,
          label: customerProvider.corporationName,
          metadata: showMetadata ? customerProvider : undefined,
        });
      }),
    });
  }
}
