import { isValid, parse } from "date-fns";
import { InputMaskChangeParams } from "primereact/inputmask";
import { FormEvent, useCallback, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntity";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { ProviderDocumentType } from "../../../../../provider/domain/entities/providerEntity";
import { IGenerateCommercialProposalForm } from "../../../../../core/domain/entities/generateCommercialProposalForm";

enum EInvalidFeedback {
  None = "",
  Required = "Este campo é obrigatório",
  InvalidFormat = "Formato de data inválido",
}

interface ITerminateModalState {
  loading: boolean;
  search: string;
  submitting: boolean;
  attachments: IAccountPayableAttachmentEntity[];
  progressModal: {
    isOpen: boolean;
    loaded: number;
    total: number;
  };
  terminationDate: string;
  isInvalid: boolean;
  invalidFeedback: EInvalidFeedback;
}

export function useTerminateModal() {
  const [state, setState] = useState<ITerminateModalState>({
    loading: true,
    search: "",
    submitting: false,
    attachments: [],
    progressModal: {
      isOpen: false,
      loaded: 0,
      total: 0,
    },
    terminationDate: "",
    isInvalid: false,
    invalidFeedback: EInvalidFeedback.None,
  });

  const { useAccountsPayable, handleTerminateModalClose, reload, ...rest } =
    useAccountsPayablePage();

  const { contextMenuData, terminateModalOpen } = rest.state;

  const {
    getAttachments,
    getStorageFilebyId,
    uploadAttachments,
    listAttachmentTypes,
    updateAttachmentsBarcode,
    terminateAccount,
    generateAttachmentVariableAdditional,
    generateAttachmentRpa,
    fetchRpaParamOptions,
    generateCommercialProposalAttachment,
    fetchCommercialProposalEnums,
  } = useAccountsPayable;

  const mountedRef = useIsMounted();
  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** Obtem a lista de arquivos anexados a conta a pagar selecionada */
  const fetchAttachments = useCallback(async () => {
    if (!contextMenuData) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const attachmentsResponse = await getAttachments(contextMenuData);

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        loading: false,
        attachments: attachmentsResponse,
      }));
    } catch {
      if (mountedRef.current) {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    } finally {
      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    }
  }, [getAttachments, mountedRef, contextMenuData]);

  /** Lida com a inicializacao de estado da modal durante sua abertura */
  const handleModalAfterOpen = useCallback(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar os estados da modal
   */
  const handleModalAfterClose = () => {
    setState({
      loading: true,
      search: "",
      submitting: false,
      attachments: [],
      progressModal: {
        isOpen: false,
        loaded: 0,
        total: 0,
      },
      terminationDate: "",
      isInvalid: false,
      invalidFeedback: EInvalidFeedback.None,
    });
  };

  /** Lida com o evento de click do botao fechar da modal */
  const handleCloseButtonOnClick = useCallback(() => {
    handleTerminateModalClose();
  }, [handleTerminateModalClose]);

  /**
   * Lida com o evento de progresso de upload dos anexos
   */
  const handleUploadProgress = (loaded: number, total: number) => {
    setState(prevState => ({
      ...prevState,
      progressModal: {
        ...prevState.progressModal,
        loaded,
        total,
      },
    }));
  };

  /**
   * Baixa a conta a pagar e atualiza os anexos na api. Realiza o post dos
   * arquivos e dos novos dados dos anexos que foram atualizados/removidos
   */
  const terminateAccountAndUpdateAttachments = async (
    accountPayableId: string,
    terminationDate: string,
    attachmentList: IAccountPayableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      submitting: true,
      progressModal: {
        ...prevState.progressModal,
        isOpen: true,
      },
    }));

    try {
      // baixa a conta a pagar selecionada
      await terminateAccount({
        accountPayableId,
        terminationDate,
      });

      // atualiza os anexos da conta a pagar selecionada
      if (attachmentList.length > 0) {
        await uploadAttachments(
          accountPayableId,
          attachmentList,
          handleUploadProgress,
        );
      }

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento baixado com sucesso!",
      });

      setState(prevState => ({
        ...prevState,
        submitting: false,
        progressModal: {
          ...prevState.progressModal,
          isOpen: false,
        },
      }));

      handleTerminateModalClose();
    } catch {
      setState(prevState => ({
        ...prevState,
        submitting: false,
        progressModal: {
          ...prevState.progressModal,
          isOpen: false,
        },
      }));
    }
  };

  /** Lida com a validacao do campo de data */
  const validateTerminationDate = (value: string) => {
    let isInvalid = false;
    let invalidFeedback = EInvalidFeedback.None;

    if (value === "") {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.Required;

      return { isInvalid, invalidFeedback };
    }

    const dtTerminationDate = parse(
      value.replace(/\D/g, ""),
      "ddMMyyyy",
      new Date(),
    );

    if (value.length < 8 || !isValid(dtTerminationDate)) {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.InvalidFormat;

      return { isInvalid, invalidFeedback };
    }

    return { isInvalid: false, invalidFeedback: EInvalidFeedback.None };
  };

  /** Lida como submit dos dados da modal */
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const accountPayableId = contextMenuData?.id;

    if (!accountPayableId) {
      return;
    }

    const { terminationDate } = state;

    const { isInvalid, invalidFeedback } =
      validateTerminationDate(terminationDate);

    const submitting = !isInvalid;

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
      submitting,
    }));

    if (isInvalid) {
      return;
    }

    try {
      const attachmentList = state.attachments;

      await terminateAccountAndUpdateAttachments(
        accountPayableId,
        terminationDate,
        attachmentList,
      );

      reload();
    } catch {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));
    }
  };

  /** Lida com o evento de troca de valor do campo de data */
  const handleDateChange = (event: InputMaskChangeParams) => {
    const terminationDate = event.target.value || "";

    const { isInvalid, invalidFeedback } =
      validateTerminationDate(terminationDate);

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
      terminationDate,
    }));
  };

  /**
   * Lida com o evento de troca do valor dos anexos emitido pela grid,
   * responsavel por atualizar a lista de anexos em memoria conforme as
   * edicoes realizadas pelo usuario
   */
  const handleAttachmentListChange = (
    attachments: IAccountPayableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      attachments: [...attachments],
    }));
  };

  /** Lida com o evento de gerar anexo de adicional variavel */
  const handleGenerateVariableAdditional = async () => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Adicional variável.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const accountPayableListItem = contextMenuData;

      const generatedFile = await generateAttachmentVariableAdditional(
        accountPayableListItem,
      );

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  /** Lida com o evento de gerar anexo de RPA */
  const handleOnGenerateRPA = async (
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ) => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de RPA.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const accountPayableListItem = contextMenuData;
      const generateRPAModalInput = genRPAModalInput;

      const generatedFile = await generateAttachmentRpa({
        accountPayableListItem,
        generateRPAModalInput,
      });

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IAccountPayableAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        currentCompanyGroup.id,
        billetAttachment,
      );

      return { updatedAttachment };
    } catch {
      return { updatedAttachment: billetAttachment };
    }
  };

  /**
   * Decide se o form esta invalido ou nao, usado
   * para determinar a aparencia do botao submit
   */
  const isInvalid = useMemo(() => {
    return state.isInvalid || state.terminationDate === "";
  }, [state.isInvalid, state.terminationDate]);

  const handleGenerateCommercialProposal = async (
    modalParams: IGenerateCommercialProposalForm,
  ) => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Proposta Comercial.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const params = {
        ...modalParams,
        valueMoney: contextMenuData?.value || 0,
        projectId: contextMenuData?.projectId || "",
        providerId: contextMenuData?.providerId || "",
      };

      const generatedFile = await generateCommercialProposalAttachment(params);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IAccountPayableAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck === EAttachmentType.CommercialProposal &&
      contextMenuData?.providerDocumentType !== ProviderDocumentType.CPF
    ) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Este lançamento é destinado a um fornecedor do tipo pessoa jurídica
            ou pessoa estrangeira.
            <br />
            <br />
            Neste caso, o arquivo <strong>não será gerado</strong>, pois a
            proposta comercial é permitida{" "}
            <strong>somente para pessoas físicas</strong>.
          </>
        ),
      });

      return false;
    }

    if (
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.Measurement]: "Medição",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const handleGetStorageFileById = async (storageFileId: string) => {
    const attachment = state.attachments.find(
      attachmentData => attachmentData.storageFileId === storageFileId,
    );

    if (!attachment) {
      return;
    }

    await getStorageFilebyId(attachment);
  };

  return {
    terminateModalOpen,
    handleCloseButtonOnClick,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    listAttachmentTypes,
    handleUpdateAttachmentsBarcode,
    handleAttachmentListChange,
    isInvalid,
    handleGenerateVariableAdditional,
    handleOnGenerateRPA,
    fetchRpaParamOptions,
    getStorageFilebyId: handleGetStorageFileById,
    fetchCommercialProposalEnums,
    handleGenerateCommercialProposal,
    handleAttachmentTypeChange,
  };
}
