import { FaCaretDown, FaPaperclip, FaTrash } from "react-icons/fa";
import { useRef, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { SoulDropdown } from "../../../../../../core/presentation/components/SoulDropdown";
import { useOnClickOutside } from "../../../../../../core/presentation/hooks/useOnClickOutside";
import { ISolicitImportEntity } from "../../../domain/entities/solicitImportEntity";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";

interface IValidationStepDropdown {
  onOpenAttachmentModal(): void;
  selection: ISolicitImportEntity[];
  handleFormsRemoval(formsIds: string[]): boolean;
}

export function ValidationStepDropdown(props: IValidationStepDropdown) {
  const { selection, onOpenAttachmentModal, handleFormsRemoval } = props;

  const { handleRemoveForm } = useSolicitImportPage();

  const tooltipContainerRef = useRef<HTMLButtonElement>(null);

  const handleRemoveMultipleForms = async () => {
    const formsIds = selection.map(({ id }) => {
      return id;
    });
    handleRemoveForm(formsIds, handleFormsRemoval);
  };

  /**
   * Quando houverem dados selecionados na tabela, exibe o tooltip que orienta
   * a inclusão de anexos em lote.
   */
  useEffect(() => {
    if (tooltipContainerRef.current) {
      const method = selection?.length ? "show" : "hide";
      ReactTooltip[method](tooltipContainerRef.current);
    }
    ReactTooltip.rebuild();
  }, [selection?.length]);

  /**
   * Responsável por ocultar o tooltip de orientação para inclusão de anexos
   * em lote caso o usuário clique em quaisquer elementos que não sejam
   * checkboxes de seleção da tabela.
   */
  useOnClickOutside(tooltipContainerRef, tar => {
    const target = tar as HTMLElement;

    if (target?.classList?.value?.includes("p-checkbox")) {
      return;
    }

    if (tooltipContainerRef.current) {
      ReactTooltip.hide(tooltipContainerRef.current);
    }
  });

  return (
    <SoulDropdown
      toggler={
        <button
          type="button"
          id="btn-options"
          data-place="left"
          data-event="none"
          data-effect="solid"
          data-event-off="click"
          ref={tooltipContainerRef}
          data-testid="btn-options"
          data-tip-disable={!selection?.length}
          className="default-button options-context"
          data-tip="Acesse as opções para fazer ações em lotes"
        >
          <span>Opções</span>
          <FaCaretDown className="context-dropdown-icon" />
        </button>
      }
    >
      <button
        type="button"
        id="btn-bulk-attachment"
        className="dropdown-item"
        disabled={!selection?.length}
        data-testid="btn-bulk-attachment"
        onClick={() => onOpenAttachmentModal()}
      >
        <FaPaperclip />
        <span title="Incluir vários anexos">Incluir vários anexos</span>
      </button>
      <button
        type="button"
        id="btn-bulk-attachment"
        className="dropdown-item"
        disabled={!selection?.length}
        data-testid="btn-bulk-attachment"
        onClick={handleRemoveMultipleForms}
      >
        <FaTrash />
        <span title="Excluir linhas">
          Excluir selecionados
          {selection.length !== 0 ? ` (${selection.length})` : null}
        </span>
      </button>
    </SoulDropdown>
  );
}
