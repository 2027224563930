import styled from "styled-components";

export const StateRegistrationLabel = styled.label`
  &:is(.form-container .form-row > .form-control) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  > span {
    flex-basis: 100%;
  }

  > label {
    flex-basis: 25%;
  }

  > input {
    flex-basis: 75%;
  }

  > p {
    margin-left: 25%;
  }
`;
