import { Dropdown } from "primereact/dropdown";
import { useCallback, useMemo } from "react";
import { IRelationshipFilterOption } from "../../domain/entities/advTableColumn";
// import { RelationshipFilterOption } from "../../domain/entities/columnEntity";

export function useColumnFilterUtils() {
  return {
    /**
     * Retorna um novo array de ColumnFilterOptionEntity contendo
     * apenas itens únicos (rawValue é usado para comparação)
     */
    uniqueItems: useCallback((items: IRelationshipFilterOption[]) => {
      return items.filter(
        (item, index) =>
          items.findIndex(element => element.rawValue === item.rawValue) ===
          index,
      );
    }, []),

    /**
     * Retorna verdadeiro se todos os itens passados no parametro "options"
     * estivrem presentes no parametro "exceptions"
     */
    isAllOptionsExcepted: useCallback(
      (
        options: IRelationshipFilterOption[],
        exceptions: IRelationshipFilterOption[],
      ) => {
        return options.every(option =>
          exceptions.some(exception => exception.rawValue === option.rawValue),
        );
      },
      [],
    ),

    renderCalendarMonthNavigatorTemplate: useCallback(
      ({ value, options, className, onChange }) => {
        return (
          <Dropdown
            value={value}
            options={options}
            className={className}
            onChange={event => {
              onChange(event.originalEvent, event.value);
            }}
          />
        );
      },
      [],
    ),

    renderCalendarYearNavigatorTemplate: useCallback(
      ({ value, options, className, onChange }) => {
        return (
          <Dropdown
            value={value}
            options={options}
            className={className}
            onChange={event => {
              onChange(event.originalEvent, event.value);
            }}
          />
        );
      },
      [],
    ),

    calendarLocale: useMemo(
      () => ({
        firstDayOfWeek: 1,
        dayNames: [
          "Domingo",
          "Segunda",
          "Terça",
          "Quarta",
          "Quinta",
          "Sexta",
          "Sãbado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
        monthNames: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        monthNamesShort: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Mov",
          "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
      }),
      [],
    ),
  };
}
