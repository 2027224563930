import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface IGetCurrentCompanyGroupUseCase {
  get(): ICompanyGroupEntity | null;
}

export interface IGetCurrentCompanyGroupService {
  get(): ICompanyGroupEntity | null;
}

export class GetCurrentCompanyGroupUseCase
  implements IGetCurrentCompanyGroupUseCase
{
  constructor(
    private getCurrentCompanyGroupService: IGetCurrentCompanyGroupService,
  ) {}

  get(): ICompanyGroupEntity | null {
    return this.getCurrentCompanyGroupService.get();
  }
}
