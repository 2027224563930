import { ELocalStorageKeys } from "../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../core/domain/entities/stringfied";
import { IStoreUspIncomeStatementReportPanoramaIdContract } from "../../domain/contracts/storeUspIncomeStatementReportPanoramaIdContract";

export class StoreUspIncomeStatementReportPanoramaIdService
  implements IStoreUspIncomeStatementReportPanoramaIdContract
{
  storeUspIncomeStatementReportPanoramaId(panoramaId: string): void {
    const key = ELocalStorageKeys.UspIncomeStatementReportId;
    localStorage.setItem(key, Stringified.stringify(panoramaId) as string);
  }
}
