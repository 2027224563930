import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";
import { EFormMode } from "../../../domain/entities/accountReceivableEnums";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";
import { ICostCenterEntity } from "../../../../../../costCenter/domain/entities/costCenterEntity";

interface ISectionProjectInfoState {
  project: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
  classificationUsp: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface ISectionProjectInfoProps {
  mode: EFormMode;
  useReceivableForm: MakeAccountsReceivableForm;
}

export function SectionProjectInfo(props: ISectionProjectInfoProps) {
  const { mode, useReceivableForm } = props;

  const { searchProject, searchClassificationUsp } = useReceivableForm;

  const [state, setState] = useState<ISectionProjectInfoState>({
    project: {
      loading: false,
      options: [],
    },
    classificationUsp: {
      loading: false,
      options: [],
    },
  });

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountReceivableFormEntity>();

  const { setValue, watch, getValues } = form;

  const company = watch("company") as ITypeaheadOption<{
    hasProject: boolean;
  }> | null;

  const competency = watch("competency") as ITypeaheadOption<{
    isUsp: boolean;
  }> | null;

  const isUsp = competency?.metadata?.isUsp;
  const hasProject = company?.metadata?.hasProject || false;

  const handleSearchProjectChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchProject({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          order: [],
          length: 100,
        },
      });

      const projects = response.data;

      setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          options: projects,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          loading: false,
        },
      }));
    }
  };

  const handleSearchClassificationUspChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      classificationUsp: {
        ...prevState.classificationUsp,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const clsUsps = await searchClassificationUsp({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      setState(prevState => ({
        ...prevState,
        classificationUsp: {
          ...prevState.classificationUsp,
          loading: false,
          options: clsUsps.data,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        classificationUsp: {
          ...prevState.classificationUsp,
          loading: false,
        },
      }));
    }
  };

  const isProjectDisabled = useMemo(() => {
    const companyId = getValues("company.rawValue");
    const projectId = getValues("project.rawValue");
    const costCenter = getValues(
      "costCenter",
    ) as ITypeaheadOption<ICostCenterEntity>;
    const isActive = costCenter?.metadata?.active;

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.Limited]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!companyId && !!projectId,
    };

    if (
      costCenter &&
      mode !== EFormMode.Create &&
      mode !== EFormMode.Limited &&
      mode !== EFormMode.Conditional &&
      mode !== EFormMode.EditAttachments
    ) {
      return !isActive;
    }

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isClassificationUspDisabled = useMemo(() => {
    const companyId = getValues("company.rawValue");
    const classificationUspId = getValues("classificationUsp.rawValue");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.Limited]: false,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!companyId && !!classificationUspId,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  if (!hasProject) {
    return null;
  }

  return (
    <Card>
      <header>Informações do Projeto</header>
      <section>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Projeto{" "}
              {state.project.loading && <i className="pi pi-spin pi-spinner" />}
            </span>
            <Controller
              name="project"
              shouldUnregister
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                const onChange = (event: ISoulTypeaheadChangeEvent) => {
                  field.onChange(event);
                  /**
                   * Reseta o centro de custo ao trocar de projeto.
                   * */
                  setValue("costCenter", null, { shouldValidate: true });
                };

                return (
                  <>
                    <SoulTypeahead
                      serverSide
                      openOnFocus
                      id="txt-project"
                      value={field.value}
                      onChange={onChange}
                      placeholder="Projeto"
                      data-testid="txt-project"
                      disabled={isProjectDisabled}
                      options={state.project.options}
                      loading={state.project.loading}
                      onSearchChange={handleSearchProjectChange}
                      className={fieldState?.error ? "isInvalid" : undefined}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
          {isUsp ? (
            <label className="col-6 form-control">
              <span>
                Classificação USP{" "}
                {state.classificationUsp.loading && (
                  <i className="pi pi-spin pi-spinner" />
                )}
              </span>
              <Controller
                shouldUnregister
                name="classificationUsp"
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <SoulTypeahead
                        serverSide
                        openOnFocus
                        value={field.value}
                        onChange={field.onChange}
                        id="txt-classificationUsp"
                        placeholder="Classificação USP"
                        data-testid="txt-classificationUsp"
                        disabled={isClassificationUspDisabled}
                        options={state.classificationUsp.options}
                        loading={state.classificationUsp.loading}
                        onSearchChange={handleSearchClassificationUspChange}
                        className={fieldState.error ? "isInvalid" : undefined}
                      />
                      <InvalidFeedback
                        condition={fieldState?.error?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                    </>
                  );
                }}
              />
            </label>
          ) : null}
        </div>
      </section>
    </Card>
  );
}
