export interface IStoreCostCenterReportPanoamaIdService {
  storeCostCenterReportPanoamaId(panoramaId: string): void;
}

export interface IStoreCostCenterReportPanoamaIdUseCase {
  storeCostCenterReportPanoamaId(panoramaId: string): void;
}

export class StoreCostCenterReportPanoamaIdUseCase
  implements IStoreCostCenterReportPanoamaIdUseCase
{
  constructor(
    private storeCostCenterReportPanoamaIdService: IStoreCostCenterReportPanoamaIdService,
  ) {}

  storeCostCenterReportPanoamaId(panoramaId: string): void {
    return this.storeCostCenterReportPanoamaIdService.storeCostCenterReportPanoamaId(
      panoramaId,
    );
  }
}
