import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { ILinkClassificationsAccountModel } from "../../data/models/linkClassificationsAccountModel";

export interface ILinkClassificationsAccountService {
  linkClassificationsAccount(
    customerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel>;
}

export interface ILinkClassificationsAccountUseCase {
  linkClassificationsAccount(
    customerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel>;
}

export class LinkClassificationsAccountUseCase
  implements ILinkClassificationsAccountUseCase
{
  constructor(
    private linkClsAccountService: ILinkClassificationsAccountService,
  ) {}

  linkClassificationsAccount(
    customerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel> {
    return this.linkClsAccountService.linkClassificationsAccount(
      customerId,
      classificationAccountEntity,
    );
  }
}
