import { ELocalStorageKeys } from "../../../core/data/utils/localStorageKeys";
import { ICompanyGroupEntity } from "../../domain/entities/companyGroupEntity";
import { IGetCurrentCompanyGroupService } from "../../domain/usecases/getCurrentCompanyGroupUseCase";

export class GetCurrentCompanyGroupService
  implements IGetCurrentCompanyGroupService
{
  companyGroupKey: string = ELocalStorageKeys.CompanyGroup;

  get(): ICompanyGroupEntity | null {
    const companyGroupEntity = JSON.parse(
      localStorage.getItem(this.companyGroupKey) as string,
    ) as ICompanyGroupEntity;

    return companyGroupEntity ?? null;
  }
}
