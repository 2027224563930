import { ContextMenu } from "primereact/contextmenu";
import { useAccountsPayableContextMenu } from "../../hooks/useAccountsPayableContextMenu";

export function AccountsPayablePageContextMenu() {
  const { menuRef, menuModel } = useAccountsPayableContextMenu();

  return (
    <ContextMenu model={menuModel} ref={menuRef} style={{ width: "220px" }} />
  );
}
