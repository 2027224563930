import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaEye } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import {
  AdvTable,
  IAdvTableHandle,
} from "../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../advTable/presentation/components/ColumnSelector";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useOnClickOutside } from "../../../../../core/presentation/hooks/useOnClickOutside";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { MakeCostCenter } from "../../../../../costCenter/main/makeCostCenter";
import { CostCenterFormModal } from "../../../../../costCenter/presentation/components/CostCenterFormModal";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { MakeCostCenterTransactions } from "../../../../../transactions/costCenterTransactions/main/makeCostCenterTransactions";
import { CostCenterTransactionFormModal } from "../../../../../transactions/costCenterTransactions/presentation/components/CostCenterTransactionFormModal";
import {
  ECostCenterReportType,
  EReportOrigin,
  ICostCenterReportEntity,
} from "../../../domain/entities/costCenterReportEntity";
import { IFiltersValue } from "../../../domain/usecases/listCostCenterReportUseCase";
import { MakeCostCenterReport } from "../../../main/makeCostCenterReport";
import {
  EEditModalMode,
  useCostCenterReportGrid,
} from "../../hooks/useCostCenterReportGrid";
import { ExportingModalContent } from "../ExportingModalContent";
import { Toolbar } from "../Toolbar";
import { Container, ContextMenuItem } from "./styles";

interface IFetchDataProps {
  filtersValue: IFiltersValue;
  pfsEventEntity: IPFSEventEntity;
  uiSelectedColumns: IAdvTableColumn[];
  orderedColumnNames: string[];
}

interface CostCenterReportPageProps {
  useCostCenter: MakeCostCenter;
  useCostCenterReport: MakeCostCenterReport;
  useCostCenterTransactions: MakeCostCenterTransactions;
}

export function CostCenterReportPage({
  useCostCenter,
  useCostCenterReport,
  useCostCenterTransactions,
}: CostCenterReportPageProps) {
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useCostCenterReportGrid({
    useCostCenterReport,
  });
  const {
    searchCostCenter,
    listCostCenterReport,
    exportCostCenterReportUseCase,
  } = useCostCenterReport;

  const mountedRef = useIsMounted();
  const table = useRef<IAdvTableHandle>(null);

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(id);

  const [selectedColumns, setSelectedColumns] = useState(() => {
    // comecamos com uma copia da lista de colunas pois alguns estados estão
    // sendo salvos na propria abstracao da coluna de forma mutável e se nao
    // fizermos isso ppodemos causar algumas inconsistencias
    // REVIEW
    return columns.map(col => ({ ...col }));
  });
  const [orderedColNames, setOrderedColNames] = useState<string[]>(
    columns.map(col => col.field),
  );
  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity({ rows: 50 }),
  );
  const [filtered, setFiltered] = useState(false);
  const [filters, setFilters] = useState<IFiltersValue>({
    startDate: "",
    endDate: "",
  });
  const [selection, setSelection] = useState<
    ICostCenterReportEntity[] | undefined
  >();
  const [
    isCostCenterTransactionModalOpen,
    setIsCostCenterTransactionsModalOpen,
  ] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] =
    useState<IResponseEntity<ICostCenterReportEntity[]>>();

  /** Indica quando o modal de Centro de Custo deve ser aberto. */
  const [isCostCenterModalOpen, setIsCostCenterModalOpen] = useState(false);

  /* Indica quando a opção de 'Exportar para Excel' deve ser desabilitada */
  const disableSheetExportation = !data?.data?.length;

  const { advGeneratePayload } = useTables();

  /**
   * Responsavel por fazer o fetch que obtem a lista de dados p/ exibir na tela
   */
  const fetchData = useCallback(
    async ({
      filtersValue,
      orderedColumnNames,
      pfsEventEntity,
      uiSelectedColumns,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setIsFetching(false);
        return;
      }
      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      setFiltered(isFiltered);
      setIsFetching(true);

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listCostCenterReport(id, payload, filtersValue);
        if (!mountedRef.current) {
          return;
        }

        setData(response);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setIsFetching(false);
      }
    },
    [
      advGeneratePayload, // nao muda
      id,
      listCostCenterReport, // nao muda
      mountedRef, // nao muda
    ],
  );

  const handleFiltersChange = useCallback(
    (event: IFiltersValue) => {
      const newFilters = { ...event };
      setFilters(newFilters);

      fetchData({
        filtersValue: newFilters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, orderedColNames, pfsEvent, selectedColumns],
  );

  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setPfsEvent(_pfsEvent);

      fetchData({
        filtersValue: filters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, filters, orderedColNames, selectedColumns],
  );

  const rowClassName = useCallback((rowData: ICostCenterReportEntity) => {
    const { type } = rowData;

    return {
      "row-expense": type === ECostCenterReportType.expense,
      "row-revenue": type === ECostCenterReportType.revenue,
    };
  }, []);

  const renderEmptyMessage = useCallback(() => {
    return (
      <div className="empty-message">
        <div>
          Clique em <b>&ldquo;Mostrar&rdquo;</b> para carregar os registros.
        </div>
      </div>
    );
  }, []);

  const handleOnClearButtonClick = useCallback(() => {
    table.current?.resetFilters();
  }, []);

  const menuRef = useRef<ContextMenu>(null);
  const ref = useRef(null);

  useOnClickOutside(ref, (target, event) => {
    const tar = target as HTMLElement;

    // REVIEW o onClickOutside serve para limpar o estado do item selecionado da
    // grid quando o usuario clicar "fora", no entanto, as libs ReactModal e
    // SweetAlert bem como o overlay do componente Dropdown do primereact utilizam
    // um react portal para renderizar alguns elementos na raiz do documento e
    // nao aninhado no elemento onde os estamos chamando, por isso esse tratamento
    // foi necessario para evitar limpar o estado quando clicamos em alguns elementos
    // das modais, dialogs ou dropdowns. O problema dessa abordagem é que ela é
    // fraca por depender de classes css que sao definidas por essas libs de terceiros
    // desta forma estamos muito vulneravais a breaking changes
    if (
      !tar.closest(".ReactModal__Overlay") &&
      !tar.closest(".p-dropdown-items-wrapper") &&
      !tar.closest(".swal2-container")
    ) {
      setSelection(undefined);
    }

    if (event) {
      menuRef?.current?.hide(event as unknown as SyntheticEvent);
    }
  });

  const handleSelectionChange = ({
    value,
    originalEvent,
  }: DataTableSelectionChangeParams) => {
    setSelection(value);
    menuRef?.current?.show(originalEvent);
  };

  const {
    user: { profile },
  } = useUserLocal();

  /** Exibe a origem de lancamento selecionado na grid de acordo com seu tipo */
  const handleViewCommand = useCallback(() => {
    if (profile === EUserProfile.manager) {
      return;
    }

    const item = (
      Array.isArray(selection) ? selection[0] : selection
    ) as ICostCenterReportEntity;
    const { origin, originId } = item;

    // quando a origem do lançamento é uma conta a pagar
    // enviamos o user p/ a tela de edição de contas a pagar
    if (origin === EReportOrigin.accountPayable) {
      window.open(`/accountsPayable/${originId}`);
    }
    // quando a origem do lançamento é uma conta a receber
    // enviamos o user p/ a tela de edição de contas a receber
    else if (origin === EReportOrigin.accountReceivable) {
      window.open(`/accountsReceivable/${originId}`);
    }
    // quando a origem do lançamento é uma movimentação
    // abrimos a modal p/ exibir a movimentação
    else if (origin === EReportOrigin.transaction) {
      setIsCostCenterTransactionsModalOpen(true);
    }
    // quando a origem é o saldo inicial
    else if (origin === EReportOrigin.OpeningBalance) {
      setIsCostCenterModalOpen(true);
    }
  }, [profile, selection]);

  const renderViewMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaEye />
            Ver
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  const menuModel = useMemo<MenuItem[]>(
    () => [
      {
        template: renderViewMenuItem,
        command: handleViewCommand,
      },
    ],
    [handleViewCommand, renderViewMenuItem],
  );

  const handleOnColumnSelect = (column: IAdvTableColumn) => {
    const newColumns = [column, ...selectedColumns];
    const newOrderedColNames = newColumns.map(col => col.field);

    setSelectedColumns(newColumns);
    setOrderedColNames(newOrderedColNames);

    // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
    // na sequencia do update do estado nao tem efeito, nao queremos usar um
    // side-effect selectedColumns pois outros eventos atualizam esse estado e
    // nao queremos que que o applyPanorama seja executado em todos eles
    setTimeout(() => {
      table.current?.applyPanorama();

      fetchData({
        filtersValue: filters,
        orderedColumnNames: newOrderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: newColumns,
      });
    });
  };

  const handleOnColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setSelectedColumns(prevSelectedColumns => {
      return prevSelectedColumns.filter(selCol => selCol.field !== field);
    });
  };

  const handleOnColReorder = (colReorderParams: DataTableColReorderParams) => {
    const cols = colReorderParams.columns as unknown as Array<{
      props: { field: string };
    }>;

    const newOrderedColNames = cols
      .map(col => col.props.field)
      .filter((col: string): col is string => !!col);

    // por ora precisamos incluir as hidden tbm, pois sem elas após
    // reordenar as colunas na UI elas nao sao mais enviadas na request
    const hidden = columns
      .filter(col => {
        const c = col as ISimpleHiddenColumn;
        return c.hidden;
      })
      .map(c => c.field);

    setOrderedColNames([...newOrderedColNames, ...hidden]);
  };

  const handleOnRowDoubleClick = () => {
    handleViewCommand();
  };

  const dialog = useSoulDialog();

  const handleOnExportButtonClick = async () => {
    if (!pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ExportingModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      await exportCostCenterReportUseCase(
        id,
        pfsEvent,
        selectedColumns as ISimpleColumn[],
        orderedColNames,
        filters,
      );
    } finally {
      dialog.close();
    }
  };

  const handleOnRequestCloseCostCenterTransactionFormModal = useCallback(() => {
    setIsCostCenterTransactionsModalOpen(false);
  }, []);

  const [selectedPanorama, setSelectedPanorama] = useState<IPanoramaEntity>();

  const handlePanoramaOnChange = (
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate = true,
  ) => {
    const { systemDefault, panoramaDefinition } = _selectedPanorama;

    // este map é necessario pois a prop filterData da coluna é usada como um
    // state mutável (podemos rever isso e utilizar uma abordagem imutável).
    // Mas neste caso nao queremos que ele modifique a coluna original salva no
    // panorama, entao criamos uma nova lista com clones das colunas do panorama
    // para que quando o filterData seja manipulado, aconteca apenas em memoria
    // e nao no que está salvo no panorama
    // REVIEW
    let selectedPanoramaCols = panoramaDefinition.selectedColumns.map(
      selCol => ({
        ...selCol,
      }),
    );

    let pfsEventEntity = {
      ...panoramaDefinition.pfsEventEntity,
      rows: pfsEvent.rows,
    };

    if (systemDefault) {
      selectedPanoramaCols = columns.map(col => ({
        ...col,
      }));
      pfsEventEntity = new PFSEventEntity({ rows: pfsEvent.rows });
    }

    setPfsEvent(prevPfsEvent => {
      return {
        ...pfsEventEntity,
        rows: prevPfsEvent.rows,
      };
    });

    const newOrderedColNames = selectedPanoramaCols.map(selPanCol => {
      return selPanCol.field;
    });

    setOrderedColNames(newOrderedColNames);
    setSelectedColumns(selectedPanoramaCols);
    setSelectedPanorama(_selectedPanorama);

    if (!shouldUpdate) {
      return;
    }

    // refaz a request para garantir que os resultados que o usuario
    // verá estarão de acordo com os filtros do panorama
    fetchData({
      filtersValue: filters,
      orderedColumnNames: newOrderedColNames,
      pfsEventEntity,
      uiSelectedColumns: selectedPanoramaCols,
    });
  };

  /** Fecha o modal de centros de custo. */
  const handleCostCenterFormModalClose = useCallback(() => {
    setIsCostCenterModalOpen(false);
  }, []);

  // este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // das colunas internamente de forma mutável, para isso sempre que alteramos
  // states que influenciam o sequenciamento das colunas, precisamos chamar
  // imperativamente o applyPanorama (que por sua vez executa o resetColumnOrder
  // do primereact). Desta forma conseguimos manter sincronizados o estado
  // interno do prime e o render na UI
  useEffect(() => {
    table.current?.applyPanorama();
  }, [selectedPanorama]);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== id) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = id;
      setData(new ResponseEntity({ data: [] }));
      setSelection(undefined);
    }
  }, [id]);

  return (
    <Container ref={ref}>
      <Page className="full-page">
        <header>
          <Toolbar
            filters={filters}
            pfsEvent={pfsEvent}
            balance={data?.balance}
            disableSheetExportation={disableSheetExportation}
            showClearButton={filtered}
            selectedColumns={selectedColumns}
            orderedColumnNames={orderedColNames}
            useCostCenterReport={useCostCenterReport}
            onExportButtonClick={handleOnExportButtonClick}
            onClearButtonClick={handleOnClearButtonClick}
            onPanoramaChange={handlePanoramaOnChange}
            onFiltersChange={handleFiltersChange}
          />
        </header>
        <article className="no-padding fill-height">
          <ColumnSelector
            columns={columns}
            selectedColumns={selectedColumns}
            onSelect={handleOnColumnSelect}
          />
          <AdvTable<ICostCenterReportEntity>
            data={data}
            tableRef={table}
            rowsDefault={50}
            columns={columns}
            removeableColumns
            loading={isFetching}
            selection={selection}
            onPage={handleGetList}
            onSort={handleGetList}
            onClear={handleGetList}
            onFilter={handleGetList}
            selectedColumns={selectedColumns}
            panoramaFilters={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.filters
            }
            panoramaSort={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.multiSortMeta
            }
            rowsPerPageOptions={[10, 50, 100]}
            onRowDoubleClick={handleOnRowDoubleClick}
            onSelectionChange={handleSelectionChange}
            onColumnRemove={handleOnColumnRemove}
            onColReorder={handleOnColReorder}
            emptyMessage={renderEmptyMessage}
            rowClassName={rowClassName}
          />
          {isCostCenterTransactionModalOpen && (
            <CostCenterTransactionFormModal
              currentId={selection?.[0]?.originId ?? ""}
              isOpen={isCostCenterTransactionModalOpen}
              useCostCenterTransactions={useCostCenterTransactions}
              modalMode={EEditModalMode.edit}
              onRequestClose={
                handleOnRequestCloseCostCenterTransactionFormModal
              }
            />
          )}
          {profile !== EUserProfile.manager && (
            <ContextMenu model={menuModel} ref={menuRef} />
          )}
          <CostCenterFormModal
            readonly
            useCostCenter={useCostCenter}
            isOpen={isCostCenterModalOpen}
            searchCostCenter={searchCostCenter}
            onClose={handleCostCenterFormModalClose}
            currentId={selection?.[0]?.originId ?? ""}
          />
        </article>
      </Page>
    </Container>
  );
}
