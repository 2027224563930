import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateAttachmentsBarcodeContract } from "../../domain/contracts/updateAttachmentsBarcodeContract";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";

interface IBilletAttachmentInfoResponse {
  barcode: string;
}

export class UpdateAttachmentsBarcodeService
  implements IUpdateAttachmentsBarcodeContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private barcodeApi: IApiService,
  ) {}

  /**
   * Localiza o primeiro anexo do tipo boleto da lista e
   * retorna a lista com este anexo atualizado com a string
   * do codigo de barras correspondente
   */
  async updateAttachmentsBarcode(
    companyGroupId: string,
    billetAttachment: IPaymentRequestAttachmentEntity,
  ) {
    const billetAttachmentCopy = { ...billetAttachment };

    const billetFile = billetAttachmentCopy.file;

    if (!billetFile) {
      return billetAttachmentCopy;
    }

    const barcode = await this.billetAttachmentInfo(billetFile, companyGroupId);

    billetAttachmentCopy.barcode = barcode;

    return billetAttachmentCopy;
  }

  /**
   * Posta o arquivo do boleto no endpoint de OCR
   * e retorna o codigo de barras obtido como string
   */
  async billetAttachmentInfo(
    billetFile: Blob,
    companyGroupId: string,
  ): Promise<string> {
    const formData = new FormData();
    formData.append("file", billetFile);

    const userEntity = this.getUserLocalService.get();
    const url = "/Utils/BilletAttachmentInfo";

    const { barcode } =
      await this.barcodeApi.post<IBilletAttachmentInfoResponse>(url, formData, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
          "Company-Group-Id": companyGroupId,
        },
      });

    return barcode;
  }
}
