export interface ILinkCompanyGroupsModel {
  companyGroups: string[];
}

export class LinkCompanyGroupsModel implements ILinkCompanyGroupsModel {
  companyGroups = [];

  constructor(params?: ILinkCompanyGroupsModel) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: ILinkCompanyGroupsModel) {
    return new LinkCompanyGroupsModel(params);
  }
}
