import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICheckValidCostCentersContract,
  ICheckValidCostCentersParams,
} from "../../domain/contracts/checkValidCostCentersContract";
import { ICheckValidCostCentersResponseEntity } from "../../domain/entities/checkValidCostCentersResponseEntity";

export class CheckValidCostCentersService
  implements ICheckValidCostCentersContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkValidCostCenters({
    projectId,
    costCenters,
  }: ICheckValidCostCentersParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/Projects/ValidCostCenters";

    const tempPayload = new Map();

    tempPayload.set(
      "costCenterIds",
      costCenters.map(c => c.id),
    );

    if (projectId) {
      tempPayload.set("projectId", projectId);
    }

    const payload = Object.fromEntries(tempPayload);

    const response = await this.api.post<ICheckValidCostCentersResponseEntity>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (!response.success || response.code === 100) {
      const responseAsString = Object.entries(response.costCenterLinkedProject)
        .map(([costCenterName, projectName]) => {
          return `${costCenterName},${projectName}`;
        })
        .join(";");

      throw responseAsString;
    }
  }
}
