import Swal, { SweetAlertOptions } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const defaultOpts: SweetAlertOptions = {
  reverseButtons: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: "form-button green-bkg no-margin",
    cancelButton: "form-button red-bkg",
    // TODO - definir cores destes botões especificos se usá-los um dia.
    denyButton: "form-button",
    closeButton: "form-button",
  },
};

const MySwal = withReactContent(Swal).mixin(defaultOpts);

export function useSoulDialog() {
  return MySwal;
}
