import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  .toggler-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    label {
      display: flex;
      gap: 0.5rem;
    }
  }
`;
