import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateClassificationUspContract } from "../../domain/contracts/updateClassificationUspContract";
import { IClassificationUspEntity } from "../../domain/entities/classificationUspEntity";
import { IClassificationUspFormEntity } from "../../domain/entities/classificationUspFormEntity";

export class UpdateClassificationUspService
  implements IUpdateClassificationUspContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateClassificationUsp(formValues: IClassificationUspFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/ClassificationsUsp";

    return this.api.put<IClassificationUspEntity>(url, formValues, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
