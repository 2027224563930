import { Controller } from "react-hook-form";
import { ChangeEvent } from "react";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { FieldWrapper } from "../../FieldWrapper";

export function DescriptionField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.description` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          const eventClone = { ...event };
          const cursorEnd = event.target.selectionEnd;
          const eventValue = event.target.value?.toUpperCase() || "";

          eventClone.target.value = eventValue;
          eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

          field.onBlur();
          field.onChange(eventClone);
        };
        return (
          <label className="form-control">
            <span>
              Descrição <small>(opcional)</small>
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <input
                {...field}
                onChange={onChange}
                placeholder="Descrição"
                id={`txt-description-${formIndex}`}
                data-testid={`txt-description-${formIndex}`}
              />
            </FieldWrapper>
          </label>
        );
      }}
    />
  );
}
