import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetCompanyGroupService } from "../data/services/getCompanyGroupService";
import { ListCompanyGroupsService } from "../data/services/listCompanyGroupsService";
import { SaveCompanyGroupService } from "../data/services/saveCompanyGroupService";
import { ToggleCompanyGroupService } from "../data/services/toggleCompanyGroupService";
import {
  GetCompanyGroupUseCase,
  IGetCompanyGroupUseCase,
} from "../domain/usecases/getCompanyGroupUseCase";
import {
  IListCompanyGroupsUseCase,
  ListCompanyGroupsUseCase,
} from "../domain/usecases/listCompanyGroupsUseCase";
import {
  ISaveCompanyGroupUseCase,
  SaveCompanyGroupUseCase,
} from "../domain/usecases/saveCompanyGroupUseCase";
import {
  IToggleCompanyGroupUseCase,
  ToggleCompanyGroupUseCase,
} from "../domain/usecases/toggleCompanyGroupUseCase";

export type MakeCompanyGroup = IGetCompanyGroupUseCase &
  IListCompanyGroupsUseCase &
  IToggleCompanyGroupUseCase &
  ISaveCompanyGroupUseCase;

export function makeCompanyGroup(api: IApiService): MakeCompanyGroup {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getCompanyGroupService = new GetCompanyGroupService(
    getUserLocalService,
    api,
  );
  const getCompanyGroupUseCase = new GetCompanyGroupUseCase(
    getCompanyGroupService,
  );

  const listCompanyGroupsService = new ListCompanyGroupsService(
    getUserLocalService,
    api,
  );
  const listCompanyGroupsUseCase = new ListCompanyGroupsUseCase(
    listCompanyGroupsService,
  );

  const toggleCompanyGroupService = new ToggleCompanyGroupService(
    getUserLocalService,
    api,
  );
  const toggleCompanyGroupUseCase = new ToggleCompanyGroupUseCase(
    toggleCompanyGroupService,
  );

  const saveCompanyGroupService = new SaveCompanyGroupService(
    getUserLocalService,
    api,
  );
  const saveCompanyGroupUseCase = new SaveCompanyGroupUseCase(
    saveCompanyGroupService,
  );

  return {
    getCompanyGroup(CompanyGroupId) {
      return getCompanyGroupUseCase.getCompanyGroup(CompanyGroupId);
    },
    listCompanyGroups(payload) {
      return listCompanyGroupsUseCase.listCompanyGroups(payload);
    },
    toggleCompanyGroup(CompanyGroupId: string) {
      return toggleCompanyGroupUseCase.toggleCompanyGroup(CompanyGroupId);
    },
    saveCompanyGroup(CompanyGroup) {
      return saveCompanyGroupUseCase.saveCompanyGroup(CompanyGroup);
    },
  };
}
