import styled from "styled-components";

export const Container = styled.section`
  width: 100%;

  .no-padding {
    padding: 0;
  }
`;

export const ModalContainer = styled.div`
  width: 500px;

  .product-multiselect {
    width: inherit;
    min-height: 3rem;

    :hover {
      border-color: var(--input-border-color);
    }

    :focus-within {
      border-color: rgba(50, 151, 211, 0.25);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    &.isInvalid {
      border-style: solid;
      border-width: 1px;
      border-color: var(--invalid);

      :focus-within {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }

  .react-modal-body {
    padding-top: 0 !important;
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0.5rem 0.8rem;

  color: #333;
  background: #fff;

  border-bottom: 1px solid #eaeaea;

  border-radius: 6px 6px 0 0;

  div:first-of-type {
    margin-right: 0.5rem;
  }

  .p-multiselect-filter-icon {
    display: none;
  }

  input {
    flex: 1;

    position: relative;

    line-height: 1.5;
    font-size: 0.75rem;

    padding: 0.25rem 0.5rem;

    border-radius: 0.25rem;
    border: 1px solid var(--input-border-color);

    height: calc(1.5em + 0.5rem + 2px);

    color: #495057;
    background-color: #fff;

    :focus {
      outline: unset;
      border-color: rgba(50, 151, 211, 0.25);
      border-right: 1px solid rgba(50, 151, 211, 0.25);
      box-shadow: 0 0 0 0.15rem rgb(0 123 255 / 25%);
    }

    ::placeholder {
      color: #d1d1d9;
    }
  }
`;
