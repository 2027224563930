import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ILinkedCompanyGroupEntity } from "../../domain/entities/linkedCompanyGroupEntity";
import { ILinkCompanyGroupsService } from "../../domain/usecases/linkCompanyGroupsUseCase";
import {
  ILinkCompanyGroupsModel,
  LinkCompanyGroupsModel,
} from "../models/linkCompanyGroupsModel";

export class LinkCompanyGroupsService implements ILinkCompanyGroupsService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private soulApi: IApiService,
  ) {}

  async linkCompanyGroups(
    userId: string,
    companyGroups: ILinkedCompanyGroupEntity[],
  ): Promise<ILinkCompanyGroupsModel> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Users/${userId}/CompanyGroups`;

    const linkCompanyGroupsModel = new LinkCompanyGroupsModel({
      companyGroups: companyGroups.map(({ id }) => id),
    });

    const response = await this.soulApi.post<ILinkCompanyGroupsModel>(
      url,
      linkCompanyGroupsModel,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return new LinkCompanyGroupsModel(response);
  }
}
