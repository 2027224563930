import { Column, ColumnProps } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import {
  PaginatorCurrentPageReportOptions,
  PaginatorTemplateOptions,
} from "primereact/paginator";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Container } from "./style";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { IImportationMatchesClassEntity } from "../../../domain/entities/importationMatchesClassEntity";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";

export interface IClassesData {
  list: IImportationMatchesClassEntity[];
  selected?: IImportationMatchesClassEntity[];
}

export interface ClassesGridProps {
  columns: ColumnProps[];
  tableData: IClassesData;
  onSelect?: (selection: IImportationMatchesClassEntity[]) => void;
  getList: (filter?: string) => Promise<IImportationMatchesClassEntity[]>;
}

export function ClassesGrid(props: ClassesGridProps) {
  const { tableData, columns, onSelect, getList } = props;

  const debounceTime = useDebounceTime();

  /** Estado que controlam o input de filtro global */
  const [search, setSearch] = useState("");

  /** Controla a quantidade de linhas mostradas na tabela */
  const [rows, setRows] = useState<number>(10);

  /** Indica quando a tabela deve exibir apenas os selecionados. */
  const [selectedOnly, setSelectedOnly] = useState(false);

  /** Armazena as linhas selecionadas */
  const [selection, setSelection] = useState<IImportationMatchesClassEntity[]>(
    tableData?.selected ?? [],
  );

  /** Indica quando a tabela está em estado de carregamento. */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Atualiza a quantidade de linhas a serem mostradas na tabela.
   */
  const handlePerPageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const numberRows = Number(e.target.value);
      setRows(numberRows);
    },
    [],
  );

  /**
   * Lida com o evento de seleção de linhas. Através desse evento, apenas os
   * IDs das classificações são definidos no formulário.
   */
  const handleSelection = useCallback(
    (e: DataTableSelectionChangeParams) => {
      setSelection(e.value);
      onSelect?.(e.value);
    },
    [onSelect],
  );

  /**
   * Template que exibe um texto informando a quantidade de páginas.
   */
  const currentPageReportTemplate = useCallback(
    ({ currentPage, totalPages }: PaginatorCurrentPageReportOptions) => {
      return (
        <div className="current-page-report">
          Exibindo página {currentPage} de {totalPages || 1}
        </div>
      );
    },
    [],
  );

  /**
   * Template da paginação a ser exibido.
   */
  const paginatorTemplate: PaginatorTemplateOptions = useMemo(
    () => ({
      layout:
        "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      PageLinks: "",
      PrevPageLink: "",
      LastPageLink: "",
      NextPageLink: "",
      FirstPageLink: "",
      JumpToPageInput: "",
      CurrentPageReport: currentPageReportTemplate,
    }),
    [currentPageReportTemplate],
  );

  const handleFilter = useCallback(
    async (filter?: string) => {
      setIsLoading(true);
      await getList(filter || "0");
      setIsLoading(false);
    },
    [getList],
  );

  return (
    <Container>
      <div className="table-header">
        <div className="table-perPage">
          <span>Mostrar</span>
          <select defaultValue="10" onChange={handlePerPageChange}>
            {[10, 25, 50, 100].map(value => {
              return (
                <option value={value} key={`${value}-rows`}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <label className="table-selectedOnly">
          <Checkbox
            checked={selectedOnly}
            onChange={e => {
              setSelectedOnly(e.checked);
            }}
          />
          Mostrar apenas selecionados
        </label>
        <InputSearch
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            debounceTime(() => {
              handleFilter(e.target.value);
            }, 700);
          }}
        />
      </div>
      <DataTable
        rowHover
        paginator
        rows={rows}
        dataKey="id"
        removableSort
        loading={isLoading}
        scrollHeight="400px"
        selection={selection}
        metaKeySelection={false}
        selectionMode="multiple"
        responsiveLayout="scroll"
        onSelectionChange={handleSelection}
        paginatorTemplate={paginatorTemplate}
        emptyMessage="Nenhum registro encontrado"
        value={selectedOnly ? selection : tableData.list}
      >
        {columns.map(column => (
          <Column {...column} key={column.field} />
        ))}
      </DataTable>
    </Container>
  );
}
