import { ColumnProps } from "primereact/column";

export function useManagePecegePayAccountReceivableFeesModalGrid() {
  const brlFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format;

  const percentageFormatter = new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format;

  const columns: ColumnProps[] = [
    {
      field: "name",
      header: "Descrição",
      style: {
        flexBasis: "20%",
      },
    },
    {
      field: "creditCardBrand",
      header: "Bandeira",
      body(data) {
        return data.creditCardBrand || "-";
      },
    },
    {
      field: "value_percentage",
      header: "Percentual (%)",
      body(data) {
        if (data.valueType !== "Percentage" || !data.value) {
          return "-";
        }

        return `${percentageFormatter(data.value * 100)}%`;
      },
    },
    {
      field: "value_monetary",
      header: "Valores",
      body(data) {
        if (data.valueType !== "Value" || !data.value) {
          return "-";
        }

        return `${brlFormatter(data.value)}`;
      },
    },
    {
      field: "valueLimit",
      header: "Limite (R$)",
      body(data) {
        if (!data.valueLimit) {
          return "-";
        }

        return `${brlFormatter(data.valueLimit)}`;
      },
    },
  ];

  return {
    columns,
  };
}
