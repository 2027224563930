import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGetStateByNameService } from "../../domain/usecases/getStateByNameUseCase";
import { IStateResponse } from "../models/stateResponse";

export class GetStateByNameService implements IGetStateByNameService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getStateByName(
    countryId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IStateResponse[]>> {
    const userEntity = this.getUserLocalService.get();

    let url = "";

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    url = `Countries/${countryId}/States?${params}`;

    const res = await this.api.get<IResponseEntity<IStateResponse[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return res;
  }
}
