import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface ISetCurrentCompanyGroupUseCase {
  set(companyGroup: ICompanyGroupEntity): void;
}

export interface ISetCurrentCompanyGroupService {
  set(companyGroup: ICompanyGroupEntity): void;
}

export class SetCurrentCompanyGroupUseCase
  implements ISetCurrentCompanyGroupUseCase
{
  constructor(
    private setCurrentCompanyGroupService: ISetCurrentCompanyGroupService,
  ) {}

  set(companyGroup: ICompanyGroupEntity): void {
    this.setCurrentCompanyGroupService.set(companyGroup);
  }
}
