import {
  Dropdown,
  DropdownChangeParams,
  DropdownFilterParams,
} from "primereact/dropdown";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useDebounceTime } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { IServerSideResponseModel } from "../../../../../../core/data/models/serverSideResponseModel";
import { IRelationshipFilterOption } from "../../../../../../advTable/domain/entities/advTableColumn";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ISearchParams } from "../../../../../../core/domain/entities/searchParams";

const Container = styled.div`
  display: flex;
  align-items: center;

  .p-component.p-dropdown {
    font-size: 0.8333rem;
    padding: 0.5em 0.5em;
    border-radius: 0.25rem;

    .p-dropdown-label.p-inputtext.p-placeholder {
      font-size: 0.8333rem;
      line-height: 1.0333em;
      padding-right: 0.5em;
      flex: 1 0;
    }

    .p-dropdown-label.p-inputtext {
      font-size: 0.8333rem;
      line-height: 1.0333em;
      display: inline-block;
      max-width: 9.375em;
    }

    &.isInvalid {
      border-color: var(--invalid);

      :focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }

  i.pi.pi-spin.pi-spinner {
    margin-left: 0.5rem;
  }
`;

interface IInputSearchCostCenterState {
  selectedOption: IRelationshipFilterOption | undefined;
  options: IRelationshipFilterOption[];
  loading: boolean;
}

interface IInputSearchCostCenterProps {
  searchFn(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
  onChange(value: IRelationshipFilterOption): void;
  className?: string;
}

export function InputSearchCostCenter({
  searchFn,
  onChange,
  className = undefined,
}: IInputSearchCostCenterProps) {
  const [state, setState] = useState<IInputSearchCostCenterState>({
    selectedOption: undefined,
    options: [],
    loading: false,
  });

  const EMPTY = useMemo(
    () => [
      {
        rawValue: "EMPTY",
        label: "Nenhum registro encontrado",
      },
    ],
    [],
  );

  const debounceTime = useDebounceTime();

  const handleDropdownChange = (event: DropdownChangeParams) => {
    const { value } = event;

    setState(prevState => ({
      ...prevState,
      selectedOption: value,
    }));

    onChange(value);
  };

  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const handleOnFilter = async (
    event: DropdownFilterParams | undefined = undefined,
  ) => {
    const search = event?.filter || "";
    const currentCompanyGroupId = currentCompanyGroup.id;

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await searchFn({
        search,
        activesOnly: true,
        companyGroupId: currentCompanyGroupId,
      });
      const costCenterList = response.data || [];

      setState(prevState => ({
        ...prevState,
        loading: false,
        options: costCenterList,
      }));
    } catch {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleShow = async () => {
    handleOnFilter();
  };

  return (
    <Container>
      <Dropdown
        filter
        showClear
        showFilterClear
        dataKey="rawValue"
        optionLabel="label"
        id="sel-cost-center"
        onChange={handleDropdownChange}
        value={state.selectedOption}
        data-testid="sel-cost-center"
        placeholder="Centro de Custo"
        filterPlaceholder="Pesquisar Centro de Custo"
        className={className}
        onFilter={e => {
          debounceTime(() => {
            handleOnFilter(e);
          }, 700);
        }}
        onShow={handleShow}
        emptyMessage="Nenhum registro encontrado."
        emptyFilterMessage="Nenhum registro encontrado."
        options={state.options?.length ? state.options : EMPTY}
      />
      {state.loading && <i className="pi pi-spin pi-spinner" />}
    </Container>
  );
}
