import { useCallback, useMemo } from "react";
import { FaEdit, FaLink } from "react-icons/fa";
import { toast } from "react-toastify";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import { SimpleColumn } from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPaymentAccountResumedEntity } from "../../domain/entities/paymentAccountEntity";

export interface PaymentAccountsGridProps {
  toggle: (paymentAccountId: string, actionText: string) => Promise<void>;
  openPaymentAccountModal: (info: IPaymentAccountResumedEntity) => void;
}

export function usePaymentAccountsGrid(props: PaymentAccountsGridProps) {
  const { toggle, openPaymentAccountModal } = props;

  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();

  /**
   * Indica se o usuário atual pode realizar o "soft delete", ou seja, inativar
   * uma conta de pagamento.
   */
  const canDeletePaymentAccount = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  /**
   * Constrói a célula de edição de uma conta de pagamento.
   */
  const editBodyTemplate = useCallback(
    (paymentAccount: IPaymentAccountResumedEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            type="button"
            id={`btn-edit-${rowIndex}`}
            className="outline-button tool"
            data-tip="Editar Conta de Pagamento"
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openPaymentAccountModal(paymentAccount);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            id={`btn-copy-id-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-copy-id-${rowIndex}`}
            data-tip="Copiar ID da Conta de Pagamento"
            onClick={() => {
              navigator.clipboard.writeText(paymentAccount.id);
              toast.success(
                "O ID da Conta de Pagamento foi copiado para sua área de transferência",
                {
                  autoClose: 2000,
                },
              );
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [openPaymentAccountModal],
  );

  /**
   * Constrói a célula de ativar/desativar uma conta de pagamento.
   */
  const activeBodyTemplate = useCallback(
    ({ id, active }: IPaymentAccountResumedEntity, { rowIndex }) => {
      return (
        <Toggle
          value={active}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          readonly={!canDeletePaymentAccount}
          data-testid={`btn-tgl-status-${rowIndex}`}
          onChange={async _active => {
            const text = !_active ? "inativada" : "ativada";
            const result = await dialog.fire({
              icon: "question",
              showCancelButton: true,
              cancelButtonText: "Não",
              confirmButtonText: "Sim",
              title: "Você está certo disso?",
              html: `A Conta de Pagamento será ${text}. <br /> Deseja prosseguir?`,
              async preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                await toggle(id, text);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [canDeletePaymentAccount, dialog, toggle],
  );

  const columns = useMemo(
    () =>
      [
        {
          field: "companyGroupName",
          header: "Grupo de Empresa",
          searchable: false,
          orderable: false,
        },
        {
          field: "companyName",
          header: "Empresa",
          searchable: true,
          orderable: true,
        },
        { field: "name", header: "Nome", searchable: true, orderable: true },
        { header: "", bodyTemplate: editBodyTemplate },
        {
          field: "active",
          header: "Ativo",
          searchable: false,
          orderable: false,
          bodyTemplate: activeBodyTemplate,
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [activeBodyTemplate, editBodyTemplate],
  );

  return {
    columns,
  };
}
