import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { Container } from "./styles";
import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { IDebtImportAttachmentEntity } from "../../../domain/entities/debtImportAttachmentEntity";
import { IDebtImportEntity } from "../../../domain/entities/debtImportEntity";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { IFetchRpaParamOptionsReturn } from "../../../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import {
  IAttachmentGridFileGeneration,
  IOnAttachmentTypeChangeParams,
  IUpdateAttachmentsBarcodeResult,
} from "../../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateRPAModalInputEntity } from "../../../../../../core/domain/entities/generateRpaModalInputEntity";
import { CommercialProposalEnums } from "../../../../../../core/domain/contracts/fetchCommercialProposalEnumsContract";
import { IGenerateCommercialProposalForm } from "../../../../../../core/domain/entities/generateCommercialProposalForm";

interface IAttachmentsModalProps {
  onClose(): void;
  isOpen: boolean;
  selectionCount?: number;
  rowData: IDebtImportEntity | null;
  onAttachmentsSubmit(
    attachments: IDebtImportAttachmentEntity[],
  ): Promise<void>;
  listAttachmentTypes(): Promise<IEnum[]>;
  getStorageFilebyId(id: string): Promise<string | void>;
  fetchRpaParamOptions?(): Promise<IFetchRpaParamOptionsReturn>;
  attachmentTypeCheck(
    params: IOnAttachmentTypeChangeParams<IDebtImportAttachmentEntity>,
  ): Promise<boolean>;
  generateVariableAdditionalAttachment?(): Promise<IAttachmentGridFileGeneration>;
  updateAttachmentsBarcode?(
    billetAttachment: IDebtImportAttachmentEntity,
  ): Promise<IUpdateAttachmentsBarcodeResult<IDebtImportAttachmentEntity>>;
  generateRpaAttachment?(
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ): Promise<IAttachmentGridFileGeneration>;
  fetchCommercialProposalEnums(): Promise<
    Record<CommercialProposalEnums, IEnum[]>
  >;
  generateCommercialProposalAttachment(
    params: IGenerateCommercialProposalForm,
  ): Promise<IAttachmentGridFileGeneration>;
}

interface IAttachmentsModalState {
  isSubmitting: boolean;
  attachments: IDebtImportAttachmentEntity[];
}

export function AttachmentsModal(props: IAttachmentsModalProps) {
  const {
    isOpen,
    onClose,
    rowData,
    selectionCount,
    onAttachmentsSubmit,
    getStorageFilebyId,
    attachmentTypeCheck,
    listAttachmentTypes,
    fetchRpaParamOptions,
    generateRpaAttachment,
    updateAttachmentsBarcode,
    generateVariableAdditionalAttachment,
    fetchCommercialProposalEnums,
    generateCommercialProposalAttachment,
  } = props;

  const [state, setState] = useState<IAttachmentsModalState>({
    attachments: [],
    isSubmitting: false,
  });

  const handleAfterOpen = () => {
    setState({
      isSubmitting: false,
      attachments: rowData?.storageFiles || [],
    });
  };

  const handleAfterClose = () => {
    setState({
      attachments: [],
      isSubmitting: false,
    });
  };

  const handleAttachmentsUpdate = (
    updatedAttachments: IDebtImportAttachmentEntity[],
  ) => {
    setState(old => ({ ...old, attachments: updatedAttachments }));
  };

  const handleSubmitAttachments = async () => {
    if (!state.attachments.length) {
      return;
    }

    setState(old => ({ ...old, isSubmitting: true }));

    try {
      await onAttachmentsSubmit(state.attachments);
    } finally {
      setState(old => ({ ...old, isSubmitting: false }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      shouldCloseOnEsc={!state.isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar anexos</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            disabled={state.isSubmitting}
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="col-12 react-modal-body">
          <div className="card">
            {selectionCount ? (
              <p>
                <strong>Lançamentos selecionados</strong>: {selectionCount}
              </p>
            ) : (
              <>
                <p>
                  <strong>Descrição</strong>:{" "}
                  {rowData?.description || "(sem descrição)"}
                </p>
                <p>
                  <strong>Nº documento</strong>:{" "}
                  {rowData?.documentNumber || "(sem número de documento)"}
                </p>
              </>
            )}
          </div>
          <AttachmentsGrid
            attachments={rowData?.storageFiles || []}
            getStorageFilebyId={getStorageFilebyId}
            attachmentTypeCheck={attachmentTypeCheck}
            listAttachmentTypes={listAttachmentTypes}
            fetchRpaParamOptions={fetchRpaParamOptions}
            generateRpaAttachment={generateRpaAttachment}
            onAttachmentsChange={handleAttachmentsUpdate}
            updateAttachmentsBarcode={updateAttachmentsBarcode}
            generateVariableAdditionalAttachment={
              generateVariableAdditionalAttachment
            }
            fetchCommercialProposalEnums={fetchCommercialProposalEnums}
            generateCommercialProposalAttachment={
              generateCommercialProposalAttachment
            }
          />
        </div>
        <div className="col-12 react-modal-footer">
          <button
            type="button"
            id="btn-close"
            data-testid="btn-close"
            className="form-button red-bkg"
            disabled={state.isSubmitting}
            onClick={onClose}
          >
            Fechar
          </button>
          <button
            type="submit"
            id="btn-submit"
            data-testid="btn-submit"
            disabled={state.isSubmitting}
            className={`form-button ${
              state.attachments.length ? "green-bkg" : "invalid-bkg"
            }`}
            onClick={handleSubmitAttachments}
          >
            Tudo certo{" "}
            {state.isSubmitting && <i className="pi pi-spin pi-spinner" />}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
