import {
  ProgressSpinner,
  ProgressSpinnerProps,
} from "primereact/progressspinner";
import styled from "styled-components";

const SpinnerContainer = styled.span`
  .p-progress-spinner {
    width: 50px;
    height: 50px;

    svg {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }
`;

export function SoulSpinner(props: ProgressSpinnerProps) {
  const { strokeWidth = "6", ...rest } = props;

  return (
    <SpinnerContainer>
      <ProgressSpinner {...rest} strokeWidth={strokeWidth} />
    </SpinnerContainer>
  );
}
