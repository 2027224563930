import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ILowerCaseErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ICheckCnpjService } from "../../domain/usecases/checkCnpjUseCase";

export class CheckCnpjService implements ICheckCnpjService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkCnpj(cnpj: string, id = "") {
    const userEntity = this.getUserLocalService.get();
    const url = "/Companies/CheckCnpj";

    const response = await this.api.post<
      Partial<ILowerCaseErrorResponseEntity>
    >(
      url,
      { id, cnpj },
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const responseEntries = Object.entries(response);
    const loweredResponse = responseEntries.map(r => [
      r[0].toLowerCase(),
      r[1],
    ]);

    return Object.fromEntries(loweredResponse);
  }
}
