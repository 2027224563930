import { IUserProfileEntity } from "../entities/userProfileEntity";

export interface IListUserProfilesService {
  listUserProfiles(): Promise<IUserProfileEntity[]>;
}

export interface IListUserProfilesUseCase {
  listUserProfiles(): Promise<IUserProfileEntity[]>;
}

export class ListUserProfilesUseCase implements IListUserProfilesUseCase {
  constructor(private listUserProfilesService: IListUserProfilesService) {}

  listUserProfiles(): Promise<IUserProfileEntity[]> {
    return this.listUserProfilesService.listUserProfiles();
  }
}
