import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { ListAuthUsersService } from "../../core/data/services/listAuthUsersService";
import { IListAuthUsersContract } from "../../core/domain/contracts/listAuthUsersContract";
import { CheckEmailAlreadyExistsService } from "../data/services/checkEmailAlreadyExistsService";
import { GetUserService } from "../data/services/getUserService";
import { LinkCompanyGroupsService } from "../data/services/linkCompanyGroupsService";
import { ListCompanyGroupsService } from "../data/services/listCompanyGroupsService";
import { ListLinkedCompanyGroupsService } from "../data/services/listLinkedCompanyGroupsService";
import { ListSupervisorCompanyGroupsService } from "../data/services/listSupervisorCompanyGroupsService";
import { ListUserProfilesService } from "../data/services/listUserProfilesService";
import { ResetPasswordService } from "../data/services/resetPasswordService";
import { SaveUserService } from "../data/services/saveUserService";
import { ToggleUserService } from "../data/services/toggleUserService";
import { IListSupervisorCompanyGroupsContract } from "../domain/contracts/listCompanyGroupsForSupervisorContract";
import {
  CheckEmailAlreadyExistsUseCase,
  ICheckEmailAlreadyExistsUseCase,
} from "../domain/usecases/checkEmailAlreadyExistsUseCase";
import {
  GetUserUseCase,
  IGetUserUseCase,
} from "../domain/usecases/getUserUseCase";
import {
  ILinkCompanyGroupsUseCase,
  LinkCompanyGroupsUseCase,
} from "../domain/usecases/linkCompanyGroupsUseCase";
import {
  IListCompanyGroupsUseCase,
  ListCompanyGroupsUseCase,
} from "../domain/usecases/listCompanyGroupsUseCase";
import {
  IListLinkedCompanyGroupsUseCase,
  ListLinkedCompanyGroupsUseCase,
} from "../domain/usecases/listLinkedCompanyGroupsUseCase";
import {
  IListUserProfilesUseCase,
  ListUserProfilesUseCase,
} from "../domain/usecases/listUserProfilesUseCase";

import {
  IResetUserPasswordUseCase,
  ResetPasswordUseCase,
} from "../domain/usecases/resetPasswordUseCase";
import {
  ISaveUserUseCase,
  SaveUserUseCase,
} from "../domain/usecases/saveUserUseCase";
import {
  IToggleUserUseCase,
  ToggleUserUseCase,
} from "../domain/usecases/toggleUserUseCase";

export type MakeUser = IToggleUserUseCase &
  IGetUserUseCase &
  ICheckEmailAlreadyExistsUseCase &
  IResetUserPasswordUseCase &
  IListCompanyGroupsUseCase &
  IListLinkedCompanyGroupsUseCase &
  ILinkCompanyGroupsUseCase &
  IListUserProfilesUseCase &
  ISaveUserUseCase &
  IListSupervisorCompanyGroupsContract &
  IListAuthUsersContract;

export function makeUser(soulApi: IApiService, authApi: IApiService): MakeUser {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const toggleUserService = new ToggleUserService(getUserLocalService, authApi);
  const toggleUserUseCase = new ToggleUserUseCase(toggleUserService);

  const saveUserService = new SaveUserService(
    getUserLocalService,
    authApi,
    soulApi,
  );
  const saveUserUseCase = new SaveUserUseCase(saveUserService);

  const getUserService = new GetUserService(
    getUserLocalService,
    authApi,
    soulApi,
  );
  const getUserUseCase = new GetUserUseCase(getUserService);

  const checkEmailAlreadyExistsService = new CheckEmailAlreadyExistsService(
    getUserLocalService,
    authApi,
  );
  const checkEmailAlreadyExistsUseCase = new CheckEmailAlreadyExistsUseCase(
    checkEmailAlreadyExistsService,
  );

  const resetPasswordService = new ResetPasswordService(
    getUserLocalService,
    authApi,
  );
  const resetPasswordUseCase = new ResetPasswordUseCase(resetPasswordService);

  const listCompanyGroupsService = new ListCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const listCompanyGroupsUseCase = new ListCompanyGroupsUseCase(
    listCompanyGroupsService,
  );

  const listLinkedCompanyGroupsService = new ListLinkedCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const listLinkedCompanyGroupsUseCase = new ListLinkedCompanyGroupsUseCase(
    listLinkedCompanyGroupsService,
  );

  const linkCompanyGroupsService = new LinkCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const linkCompanyGroupsUseCase = new LinkCompanyGroupsUseCase(
    linkCompanyGroupsService,
  );

  const listUserProfilesSvc = new ListUserProfilesService(
    getUserLocalService,
    soulApi,
  );
  const listUserProfilesUseCase = new ListUserProfilesUseCase(
    listUserProfilesSvc,
  );

  const listSupervisorCompanyGroupsService =
    new ListSupervisorCompanyGroupsService(getUserLocalService, soulApi);

  const listUsersService = new ListAuthUsersService(
    getUserLocalService,
    soulApi,
  );

  return {
    toggleUser(userId) {
      return toggleUserUseCase.toggleUser(userId);
    },
    getUser(userId) {
      return getUserUseCase.getUser(userId);
    },
    saveUser(user) {
      return saveUserUseCase.saveUser(user);
    },
    checkEmailAlreadyExists(checkEmailAlreadyExistPayloadModel) {
      return checkEmailAlreadyExistsUseCase.checkEmailAlreadyExists(
        checkEmailAlreadyExistPayloadModel,
      );
    },
    resetUserPassword(userId) {
      return resetPasswordUseCase.resetUserPassword(userId);
    },
    listCompanyGroups(payload) {
      return listCompanyGroupsUseCase.listCompanyGroups(payload);
    },
    listLinkedCompanyGroups(userId) {
      return listLinkedCompanyGroupsUseCase.listLinkedCompanyGroups(userId);
    },
    linkCompanyGroups(userId, companyGroups) {
      return linkCompanyGroupsUseCase.linkCompanyGroups(userId, companyGroups);
    },
    listUserProfiles() {
      return listUserProfilesUseCase.listUserProfiles();
    },
    listSupervisorCompanyGroups(payload) {
      return listSupervisorCompanyGroupsService.listSupervisorCompanyGroups(
        payload,
      );
    },
    listAuthUsers(options) {
      return listUsersService.listAuthUsers(options);
    },
  };
}
