import { IClassificationUspEntity } from "../../../classificationUsp/domain/entities/classificationUspEntity";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchClassificationUspService } from "../../domain/usecases/searchClassificationUspUseCase";

export class SearchClassificationUspService
  implements ISearchClassificationUspService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  searchClassificationUsp(
    search: string,
    companyGroupId: string,
    active = true,
  ) {
    const userEntity = this.getUserLocalService.get();

    const searchPayload = new PayloadEntity({
      draw: 0,
      length: 0,
      columns: [
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      search: { value: search, regex: false },
    });

    const params = new URLSearchParams({
      body: JSON.stringify(searchPayload),
    });

    let url = `/CompanyGroups/${companyGroupId}/ClassificationsUsp`;

    if (active) url += "/Actives";

    return this.api.get<IServerSideResponseModel<IClassificationUspEntity[]>>(
      `${url}?${params}`,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );
  }
}
