import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveClassificationUspContract } from "../../domain/contracts/saveClassificationUspContract";
import { IClassificationUspEntity } from "../../domain/entities/classificationUspEntity";
import { IClassificationUspFormEntity } from "../../domain/entities/classificationUspFormEntity";

export class SaveClassificationUspService
  implements ISaveClassificationUspContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveClassificationUsp(formValues: IClassificationUspFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/ClassificationsUsp";

    return this.api.post<IClassificationUspEntity>(url, formValues, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
