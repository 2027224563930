import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { ICompanyEntity } from "../../../domain/entitites/companyEntity";
import {
  ConciliationFormInputEntity,
  IConciliationFormInputEntity,
} from "../../../domain/entitites/conciliationFormInputEntity";
import { useConciliationContext } from "../../hooks/useConciliationContext";
import { EConciliationStep } from "../ConciliationPage";
import { InputCompany } from "../InputCompany";
import { InputEndDate } from "../InputEndDate";
import { InputPaymentAccount } from "../InputPaymentAccount";
import { InputStartDate } from "../InputStartDate";
import { ContainerForm } from "./styles";

interface IConciliationTermFormModalState {
  loading: {
    companies: boolean;
    paymentAccounts: boolean;
  };
  companies: ICompanyEntity[] | undefined;
}

interface IConciliationTermFormModalProps {
  onRequestClose(): void;
}

export function ConciliationTermFormModal({
  onRequestClose,
}: IConciliationTermFormModalProps) {
  const {
    useConciliation,
    paymentAccounts,
    state: { step, formData },
    setPaymentAccounts,
    submitHandler,
  } = useConciliationContext();

  const { listCompanies, listCompaniesPaymentAccounts } = useConciliation;
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const [state, setState] = useState<IConciliationTermFormModalState>({
    loading: {
      companies: true,
      paymentAccounts: false,
    },
    companies: undefined,
  });

  const defaultValues = useMemo(() => {
    return new ConciliationFormInputEntity();
  }, []);

  const form = useForm<IConciliationFormInputEntity>({
    mode: "all",
    defaultValues,
  });

  const {
    formState: { isSubmitting, isValid },
    reset,
    handleSubmit,
  } = form;

  /** Obtem a lista de empresas para exibir no multiselect */
  const fetchCompanies = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        companies: true,
      },
    }));

    const response = await listCompanies(id);

    setState(prevState => ({
      ...prevState,
      companies: response,
      loading: {
        ...prevState.loading,
        companies: false,
      },
    }));
  }, [id, listCompanies]);

  /**
   * Obtem a lista de contas de pagamento vinculadas
   * a empresa selecionada para exibir no multiselect
   * */
  const fetchPaymentAccounts = useCallback(
    async (companies: ICompanyEntity[]) => {
      setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          paymentAccounts: true,
        },
      }));

      const response = await listCompaniesPaymentAccounts(id, companies);

      setPaymentAccounts(response);

      setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          paymentAccounts: false,
        },
      }));
    },
    [id, listCompaniesPaymentAccounts, setPaymentAccounts],
  );

  const debounce = useDebounceTimeAsync();

  const handleInputCompanyChange = async (value: ICompanyEntity[]) => {
    // aqui aplicamos debounce time no evento pois
    // queremos evitar muitas requisicoes a medida que
    // o user marca e desmarca itens no dropdown de empresas
    await debounce(700);

    fetchPaymentAccounts(value);
  };

  /** lida com o fechamento da modal e o reset dos estados do form */
  const handleOnRequestClose = () => {
    onRequestClose();
  };

  const save = (formValue: IConciliationFormInputEntity) => {
    submitHandler(formValue);
  };

  useEffect(() => {
    if (!state.companies) {
      fetchCompanies();
    }
  }, [fetchCompanies, state.companies]);

  useEffect(() => {
    if (formData) {
      reset(formData);
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, formData, reset]);

  return (
    <Modal
      isOpen={step === EConciliationStep.TermModal}
      onRequestClose={handleOnRequestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <FormProvider {...form}>
        <ContainerForm onSubmit={handleSubmit(save)}>
          {/* modal header */}
          <div className="react-modal-header">
            <h4>Empresa e período de conciliação</h4>
            <button
              className="react-modal-close"
              id="btn-cross"
              data-testid="btn-cross"
              type="button"
              onClick={handleOnRequestClose}
            >
              <IoMdClose />
            </button>
          </div>

          {/* loading inicial da modal */}
          {state.loading.companies ? (
            <div className="loading-container">
              <SoulSpinner />
            </div>
          ) : (
            <div className="form-container">
              {/* modal body */}
              <div className="react-modal-body">
                <div className="form-row">
                  {/* Data inicial */}
                  <label className="form-control col-6">
                    <span>Data inicial</span>
                    <InputStartDate />
                  </label>

                  {/* Data final */}
                  <label className="form-control col-6">
                    <span>Data final</span>
                    <InputEndDate />
                  </label>
                </div>

                {/* Empresa */}
                <div className="form-row">
                  <label className="form-control col-12">
                    <span>Empresa</span>
                    <InputCompany
                      options={state.companies || []}
                      onChange={handleInputCompanyChange}
                    />
                  </label>
                </div>

                {/* Contas de pagamento */}
                <div className="form-row">
                  <label className="form-control col-12">
                    <span>
                      Contas de pagamento
                      {state.loading.paymentAccounts && (
                        <>
                          &nbsp;
                          <FaSpinner className="spinner" />
                        </>
                      )}
                    </span>
                    <InputPaymentAccount
                      disabled={state.loading.paymentAccounts}
                      options={paymentAccounts || []}
                    />
                  </label>
                </div>
              </div>

              {/* modal footer */}
              <div className="react-modal-footer">
                <button
                  type="button"
                  onClick={handleOnRequestClose}
                  id="btn-crud-close"
                  data-testid="btn-crud-close"
                  className="form-button red-bkg"
                >
                  Fechar
                </button>
                <button
                  type="submit"
                  id="btn-crud-save"
                  data-testid="btn-crud-save"
                  disabled={isSubmitting}
                  className={`form-button ${
                    isValid ? "green-bkg" : "invalid-bkg"
                  }`}
                >
                  {isSubmitting ? "Aguarde " : "Confirmar "}
                  {isSubmitting ? <FaSpinner className="spinner" /> : null}
                </button>
              </div>
            </div>
          )}
        </ContainerForm>
      </FormProvider>
    </Modal>
  );
}
