import { isValid } from "date-fns";
import {
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
} from "primereact/datatable";
import { useCallback, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import {
  ISimpleSearchableColumn,
  ISimpleSortableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  IAdvTableColumn,
  ColumnFilterOperationType,
  EColumnType,
  IColumnData,
  IColumnFilterData,
  ITypedColumn,
  TFilterValue,
} from "../../../domain/entities/advTableColumn";
import { ColumnFilterProvider } from "../../hooks/useColumnFilter";
import { ColumnFilterDate } from "../ColumnFilterDate";
import { ColumnFilterDecimal } from "../ColumnFilterDecimal";
import { ColumnFilterEnum } from "../ColumnFilterEnum";
import { ColumnFilterNumeric } from "../ColumnFilterNumeric";
import { ColumnFilterRelationship } from "../ColumnFilterRelationship";
import { ColumnFilterText } from "../ColumnFilterText";
import { Container } from "./styles";

export interface ColumnFilterProps<T = IAdvTableColumn> {
  column: T;
  removeable?: boolean;
  filter?:
    | DataTableFilterMetaData
    | DataTableOperatorFilterMetaData
    | undefined;
  onRemove?(fieldName: string): void;
  onFilter?(value: IColumnFilterData): void;
  onClear?(column: IColumnData): void;
}

export function ColumnFilter(props: ColumnFilterProps) {
  const {
    column,
    removeable = true,
    filter,
    onClear,
    onRemove,
    onFilter,
  } = props;
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<TFilterValue>(() => {
    const filterObj = filter as DataTableFilterMetaData;
    return filterObj?.value;
  });

  const [operation, setOperation] = useState<ColumnFilterOperationType>(() => {
    const typedColumn = column as ITypedColumn;

    if (typedColumn.filterData?.operation) {
      return typedColumn.filterData.operation;
    }

    const { columnType } = typedColumn;

    switch (columnType) {
      case EColumnType.date:
        return "Between";

      case EColumnType.decimal:
      case EColumnType.numeric:
        return "EqualAbsolute";

      case EColumnType.enum:
      case EColumnType.relationship:
        return "Equal";

      default:
        return "Contains";
    }
  });

  const [isFilterDisabled, setIsFilterDisabled] = useState(true);

  const renderBody = useCallback(() => {
    const typedColumn = column as ITypedColumn;
    const { columnType } = typedColumn;

    switch (columnType) {
      case EColumnType.date:
        return <ColumnFilterDate setIsFilterDisabled={setIsFilterDisabled} />;

      case EColumnType.decimal:
        return <ColumnFilterDecimal />;

      case EColumnType.enum:
        return <ColumnFilterEnum />;

      case EColumnType.numeric:
        return <ColumnFilterNumeric />;

      case EColumnType.relationship:
        return <ColumnFilterRelationship />;

      default:
        return <ColumnFilterText />;
    }
  }, [column]);

  const handleOnFilterButtonClick = useCallback(() => {
    const typedColumn = column as ITypedColumn;

    const { columnType, field } = typedColumn;
    const { searchField } = column as ISimpleSearchableColumn;

    const onFilterEvent = {
      column: {
        columnType: columnType ?? EColumnType.text,
        field,
        searchField,
      },
      operation,
      value: filterValue,
    };

    // Isto é necessário pois este é o unico tipo de filtro que varia
    // o opration e nao temos um lugar pra persistir isso, entao estamos
    // persistindo este estado na propria coluna. Poderiamos pensar em
    // outra forma de guardar este estado pois aqui estamos infringindo
    // a regra da imutabilidade. Quando formos refatorar, importante lembrar
    // que filterData é usado também pelos panoramas não somente pelos filtros
    // REVIEW
    typedColumn.filterData = {
      operation,
      value: filterValue,
    };

    onFilter?.(onFilterEvent);
  }, [column, filterValue, onFilter, operation]);

  const handleOnKeyUp = useCallback(
    ({ key }) => {
      const { columnType } = column as ITypedColumn;

      if (columnType === EColumnType.date && isFilterDisabled) return;

      if (key === "Enter" && filterValue) {
        handleOnFilterButtonClick();
      }
    },
    [column, filterValue, handleOnFilterButtonClick, isFilterDisabled],
  );

  const handleOnClearButtonClick = useCallback(() => {
    setOperation("Equal");

    const typedColumn = column as ITypedColumn;

    const { columnType, field } = typedColumn;
    const { searchField } = column as ISimpleSearchableColumn;
    const { sortField } = column as ISimpleSortableColumn;

    // Isto é necessário pois filtros de relationship utilizam o filterData p/
    // guardar o operation e estamos salvando este estado na propria coluna.
    // Poderiamos pensar em outra forma de guardar este estado pois aqui
    // estamos infringindo a regra da imutabilidade
    // REVIEW
    typedColumn.filterData = null;

    onClear?.({
      columnType,
      field,
      searchField,
      sortField,
    });
  }, [column, onClear]);

  useEffect(() => {
    if (Array.isArray(filterValue)) {
      if (operation === "NotEqual") {
        setIsFilterDisabled(false);
      } else if (filterValue.length) {
        if ((column as ITypedColumn).columnType === EColumnType.date) {
          if (!isValid(filterValue[0]) || !isValid(filterValue[1])) {
            setIsFilterDisabled(true);
          } else {
            setIsFilterDisabled(false);
          }
        } else {
          setIsFilterDisabled(false);
        }
      } else {
        setIsFilterDisabled(true);
      }
    } else if (
      filterValue !== undefined &&
      filterValue !== "" &&
      filterValue !== null
    ) {
      setIsFilterDisabled(false);
    } else {
      setIsFilterDisabled(true);
    }
  }, [column, filterValue, operation]);

  return (
    <Container data-testid="col-filter-container" onKeyUp={handleOnKeyUp}>
      <ColumnFilterProvider
        loading={loading}
        columnFilterProps={props}
        fetchMinLength={25}
        filterValue={filterValue}
        operation={operation}
        setLoading={setLoading}
        setFilterValue={setFilterValue}
        setOperation={setOperation}
        onFilterButtonClick={handleOnFilterButtonClick}
      >
        <div className="column-filter-header">
          {loading && <i className="pi pi-spin pi-spinner" />}
          {removeable && (
            <button
              onClick={() => {
                const { field } = column as ITypedColumn;
                onRemove?.(field);
              }}
              type="button"
              className="link-button remove-button"
            >
              <FaTrash />
              <span>Remover coluna</span>
            </button>
          )}
        </div>

        <div
          className="column-filter-body"
          onClick={event => event.stopPropagation()}
          aria-hidden
        >
          {renderBody()}
        </div>

        <div className="column-filter-footer">
          <button
            onClick={handleOnClearButtonClick}
            type="button"
            className="clear-button"
          >
            Limpar
          </button>
          <button
            onClick={handleOnFilterButtonClick}
            type="button"
            className="filter-button"
            disabled={isFilterDisabled}
          >
            Filtrar
          </button>
        </div>
      </ColumnFilterProvider>
    </Container>
  );
}
