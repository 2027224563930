import { format, parse } from "date-fns";
import { IReportServerSideResponseModel } from "../../../../core/data/models/reportServerSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICostCenterReportEntity } from "../../domain/entities/costCenterReportEntity";
import {
  IFiltersValue,
  IListCostCenterReportService,
} from "../../domain/usecases/listCostCenterReportUseCase";
import { ICostCenterReportFiltersParams } from "../models/costCenterReportFiltersParams";

type URLSearchParamsType = Partial<
  Record<keyof ICostCenterReportFiltersParams, string>
>;

export class ListCostCenterReportManagerService
  implements IListCostCenterReportService
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCostCenterReport(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
  ): Promise<IResponseEntity<ICostCenterReportEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        totalValueString: "totalValue",
        operationValueString: "operationValue",
        operationDateString: "operationDate",
        issueDateString: "issueDate",
        documentStatusDescription: "documentStatus",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        percentageString: "percentage",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        typeDescription: "type",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    let startDate: Date | null = null;
    if (filters.startDate) {
      startDate = parse(filters.startDate, "dd/MM/yyyy", new Date());
    }

    let endDate: Date | null = null;
    if (filters.endDate) {
      endDate = parse(filters.endDate, "dd/MM/yyyy", new Date());
    }

    const paramsOptions: URLSearchParamsType = {
      body: serverSideId,
      InitialFilterDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      FinalFilterDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      CompanyGroupId: companyGroupId,
    };

    if (filters.costCenter) {
      paramsOptions.CostCenterId = filters.costCenter.rawValue as string;
    }

    const params = new URLSearchParams(paramsOptions);

    const url = `/Manager/CostCentersReport?${params}`;

    const response = await this.api.get<
      IReportServerSideResponseModel<ICostCenterReportEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
