import { ICustomerEntity } from "../entities/customerEntity";

export interface ISaveCustomerUseCase {
  saveCustomer(customerData: ICustomerEntity): Promise<ICustomerEntity>;
}

export interface ISaveCustomerService {
  saveCustomer(customerData: ICustomerEntity): Promise<ICustomerEntity>;
}

export class SaveCustomerUseCase implements ISaveCustomerUseCase {
  constructor(private saveCustomerService: ISaveCustomerService) {}

  saveCustomer(customerData: ICustomerEntity) {
    return this.saveCustomerService.saveCustomer(customerData);
  }
}
