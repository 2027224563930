import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IProviderHasClassificationAccountContract } from "../../domain/contracts/providerHasClassificationAccountContract";

export class ProviderHasClassificationAccountService
  implements IProviderHasClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async providerHasClassificationAccount(
    companyGoupId: string,
    providerId: string,
  ): Promise<boolean> {
    const url = `/CompanyGroups/${companyGoupId}/Providers/${providerId}/ClassificationAccounts`;

    const userEntity = this.getUserLocalService.get();
    const response = await this.api.get<IResponseEntity<object[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const clsAccountList = response.data;

    return !!clsAccountList.length;
  }
}
