import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IGetProviderImportationTemplateService } from "../../domain/usecases/getProviderImportationTemplateUseCase";

export class GetProviderImportationTemplateService
  implements IGetProviderImportationTemplateService
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getProviderImportationTemplate() {
    const userEntity = this.getUserLocalService.get();

    const url = "ProviderImportations/Template";

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
