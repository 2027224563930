import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { ColumnProps } from "primereact/column";
import { format, parseISO } from "date-fns";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { MakeImportationMatches } from "../../../main/makeImportationMatches";
import { Container } from "./style";
import { IImportationMatchesCostCenterEntity } from "../../../domain/entities/importationMatchesCostCenterEntity";
import { IImportationMatchesClassEntity } from "../../../domain/entities/importationMatchesClassEntity";
import {
  EImportationMatchesOrigin,
  EImportationMatchesType,
} from "../../../domain/entities/importationMatchesPayloadEntity";
import { ClassesGrid } from "../ClassesGrid";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";

export interface ICostCenterClassesForm {
  classes: IImportationMatchesClassEntity[];
}

export type CostCenterClassesModalProps = {
  isOpen: boolean;
  origin: EImportationMatchesOrigin;
  useImportationMatches: MakeImportationMatches;
  currentCostCenter?: IImportationMatchesCostCenterEntity;
  onClose: (shoulRefresh: boolean | unknown) => void;
};

export function CostCenterClassesModal(props: CostCenterClassesModalProps) {
  const { origin, isOpen, onClose, useImportationMatches, currentCostCenter } =
    props;

  const {
    getCostCenterClasses,
    saveImportationMatches,
    listImportationMatchesClasses,
  } = useImportationMatches;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const currentId = currentCostCenter?.id ?? "";
  const description = currentCostCenter?.description ?? "";

  const dialog = useSoulDialog();

  const [isLoading, setIsLoading] = useState(false);

  /** Armazena dados iniciais da tabela. */
  const [classesData, setClassesData] = useState({
    list: [] as IImportationMatchesClassEntity[],
    selected: [] as IImportationMatchesClassEntity[],
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<ICostCenterClassesForm>({
    defaultValues: {
      classes: [],
    },
  });

  const columns = useMemo(() => {
    const temp: ColumnProps[] = [
      {
        field: "acronym",
        sortable: true,
        style: {
          width: "34%",
        },
      },
      {
        field: "name",
        sortable: true,
        style: {
          width: "34%",
        },
      },
      {
        field: "startDate",
        sortable: true,
        style: {
          width: "34%",
        },
        body(data: IImportationMatchesClassEntity) {
          return format(parseISO(data.startDate), "dd/MM/yyyy");
        },
      },
    ];
    return temp;
  }, []);

  /**
   * Lida com a abertura do modal, carregando os dados da tabela e de bancos
   * vinculados à atual conta de pagamento.
   */
  const getClassesList = useCallback(
    async (filter?: string) => {
      const classesListResponse = await listImportationMatchesClasses(
        origin,
        filter,
      );
      const matchedClassesResponse = await getCostCenterClasses(
        currentId,
        origin,
      );

      const selectedClasses = classesListResponse.filter(_class => {
        return matchedClassesResponse.find(
          matched => matched.sourceId === _class.id,
        );
      });

      setClassesData({
        selected: selectedClasses,
        list: classesListResponse,
      });

      return selectedClasses;
    },
    [origin, currentId, getCostCenterClasses, listImportationMatchesClasses],
  );

  /**
   * Envia os dados de vínculos para o servidor.
   */
  const submitMatches = useCallback(
    async (value: ICostCenterClassesForm) => {
      const sourceData = value.classes.map(_class => ({
        sourceId: _class.id,
        sourceName: _class.name,
      }));

      const payload = {
        companyGroupId: id,
        origin,
        sourceData,
        destinationId: currentId,
        destinationName: description,
        type: EImportationMatchesType.COST_CENTER,
      };

      await saveImportationMatches(payload);

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Vínculo(s) atualizado(s) com sucesso!",
      });
      onClose(true);
    },
    [
      currentId,
      description,
      dialog,
      id,
      onClose,
      origin,
      saveImportationMatches,
    ],
  );

  /**
   * Lida com o fechamento do modal, resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    reset({ classes: [] });
    setClassesData({
      list: [],
      selected: [],
    });
    onClose(false);
  }, [onClose, reset]);

  /**
   * Lida com a seleção da tabela, atualizando os valores do formulário.
   */
  const handleSelection = useCallback(
    (selection: IImportationMatchesClassEntity[]) => {
      setValue("classes", selection, { shouldDirty: true });
    },
    [setValue],
  );

  const handleModalOpening = useCallback(async () => {
    if (!currentId) return;

    setIsLoading(true);

    try {
      const selectedClasses = await getClassesList();
      reset({ classes: selectedClasses });
    } catch (error) {
      onClose(false);
    } finally {
      setIsLoading(false);
    }
  }, [currentId, getClassesList, onClose, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isLoading}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            Vínculo de Centros de Custo (Soul) e Turma (
            {origin === EImportationMatchesOrigin.LMS ? "LMS" : "Solution"})
          </h4>
          {!isLoading ? (
            <button
              type="button"
              onClick={onClose}
              id="btn-classes-cross"
              className="react-modal-close"
              data-testid="btn-classes-cross"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="classes-header">
              <h3>{description}</h3>
              <p>
                Clique sobre a turma que deseja vincular à este Centro de Custo
                e depois clique em salvar.
              </p>
            </div>
            <div className="react-modal-body">
              <form
                id="cost-center-classes-form"
                onSubmit={handleSubmit(submitMatches)}
              />
              <ClassesGrid
                columns={columns}
                tableData={classesData}
                getList={getClassesList}
                onSelect={handleSelection}
              />
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-classes-close"
                className="form-button red-bkg"
                data-testid="btn-classes-close"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-classes-save"
                data-testid="btn-classes-save"
                form="cost-center-classes-form"
                disabled={isSubmitting || !isDirty}
                className={`form-button ${
                  isDirty ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
