import { InputMask } from "primereact/inputmask";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useEmailValidator } from "../../../../core/presentation/hooks/useEmailValidator";
import { MakeCustomer } from "../../../main/makeCustomer";
import { Container } from "./styles";

interface LegalPersonFormProps {
  customerId: string;
  useCustomer: MakeCustomer;
}

export function LegalPersonForm({
  customerId = "",
  useCustomer,
}: LegalPersonFormProps) {
  const { checkCnpj, fetchByCnpj } = useCustomer;

  const [fetchingCnpj, setFetchingCnpj] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
    reset,
    control,
    watch,
    getFieldState,
  } = useFormContext();

  const onBlurDocument = async (cnpj: string) => {
    const regExp = /[.]*\/*-*/g;
    const unmaskedCnpj = cnpj.replace(regExp, "");

    setFetchingCnpj(true);
    const res = await fetchByCnpj(unmaskedCnpj);
    if (res) {
      reset(
        {
          ...watch(),
          document: res.document,
          name: res.name,
          corporationName: res.corporationName,
          phoneNumber: res.phoneNumber,
          email: res.email,
          municipalRegistration: res.municipalRegistration,
          stateRegistration: res.stateRegistration,
        },
        { keepErrors: true },
      );
    }
    setFetchingCnpj(false);
  };

  const [isCreateMode, setIsCreateMode] = useState(true);

  useEffect(() => {
    const isNew = customerId === "";
    setIsCreateMode(isNew);

    return () => {
      setIsCreateMode(true);
    };
  }, [customerId]);

  const emailValidator = useEmailValidator();

  const refPrevDocument = useRef("");

  const validateDocument = useCallback(
    async (document: string) => {
      const { error } = getFieldState("document");
      const defaultDocLength = 14;

      if (
        document.length === defaultDocLength &&
        refPrevDocument.current !== document
      ) {
        refPrevDocument.current = document;
        const { Code, success } = await checkCnpj(
          document,
          isCreateMode ? "" : customerId,
        );

        if (success === false) {
          return "existingDocument";
        }
        if (Code === 100) {
          return "invalidDocument";
        }
        return true;
      }

      if (
        document.length < defaultDocLength &&
        refPrevDocument.current !== document
      ) {
        refPrevDocument.current = document;
        return "required";
      }

      if (error) return error.message;

      return true;
    },
    [checkCnpj, getFieldState, isCreateMode, customerId],
  );

  const validateEmail = (email: string) => {
    if (email?.length > 0) {
      const invalidEmail = emailValidator(email);

      return invalidEmail ? "invalidEmail" : true;
    }

    return true;
  };

  const isCheckedStateRegistrationExempt = () =>
    watch("isStateRegistrationExempt") as boolean;

  const changeIsStateRegistrationExempt = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValue("stateRegistration", "");
    }
  };

  return (
    <Container>
      <div className="form-header">Dados Pessoa Jurídica</div>
      <div className="form-container">
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              CNPJ &nbsp;
              {fetchingCnpj && <FaSpinner className="spinner" />}
            </span>
            <Controller
              control={control}
              rules={{
                required: true,
                maxLength: 14,
                validate: v => validateDocument(v),
              }}
              name="document"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <InputMask
                    autoClear={false}
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    unmask
                    onChange={onChange}
                    onBlur={e => {
                      if (!error?.type) {
                        onBlurDocument(e.target.value);
                      }
                    }}
                    className={
                      error?.message || error?.type === "required"
                        ? "isInvalid"
                        : ""
                    }
                    value={value}
                    data-testid="legal-document"
                  />

                  <InvalidFeedback
                    condition={
                      error?.type === "required" ||
                      error?.message === "required"
                    }
                    message="Este campo é de preenchimento obrigatório"
                  />
                  <InvalidFeedback
                    condition={error?.message === "existingDocument"}
                    message="Já temos uma pessoa jurídica cadastrada com este CNPJ"
                  />
                  <InvalidFeedback
                    condition={error?.message === "invalidDocument"}
                    message="CNPJ inválido"
                  />
                </>
              )}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>Razão Social</span>
            <input
              {...register("corporationName", {
                required: true,
                maxLength: 100,
              })}
              maxLength={100}
              className={
                errors.corporationName?.type === "required" ? "isInvalid" : ""
              }
              placeholder="Razão Social"
              data-testid="legal-corporation-name"
            />
            <InvalidFeedback
              condition={errors?.name?.type === "required"}
              message="Este campo é de preenchimento obrigatório"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>Nome Fantasia</span>
            <input
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
              className={errors.name?.type === "required" ? "isInvalid" : ""}
              maxLength={100}
              placeholder="Nome Fantasia"
              data-testid="legal-name"
            />
            <InvalidFeedback
              condition={errors?.corporationName?.type === "required"}
              message="Este campo é de preenchimento obrigatório"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Telefone <small>(opcional)</small>
            </span>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                maxLength: 11,
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  onChange={onChange}
                  autoClear={false}
                  unmask
                  placeholder="Telefone"
                  mask="(99) 99999-9999"
                  value={value}
                />
              )}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              E-mail <small>(opcional)</small>
            </span>
            <input
              {...register("email", {
                maxLength: 100,
                validate: v => validateEmail(v),
              })}
              maxLength={100}
              className={
                errors?.email?.message === "invalidEmail" ? "isInvalid" : ""
              }
              placeholder="E-mail"
            />
            <InvalidFeedback
              condition={errors?.email?.message === "invalidEmail"}
              message="Formato de e-mail inválido"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Inscrição Municipal <small>(opcional)</small>
            </span>
            <input
              {...register("municipalRegistration", { maxLength: 100 })}
              maxLength={100}
              placeholder="Inscrição Municipal"
            />
          </label>
          <label className="col-6 form-control custom-form-control">
            <span>Inscrição Estadual</span>
            <div>
              <Checkbox
                label="Isento"
                {...register("isStateRegistrationExempt", {
                  onChange: e => {
                    changeIsStateRegistrationExempt(e);
                  },
                  onBlur(e) {
                    changeIsStateRegistrationExempt(e);
                  },
                })}
              />
              <div className="custom-form-input">
                <input
                  {...register("stateRegistration", {
                    required: isCheckedStateRegistrationExempt() === false,
                    maxLength: 100,
                    disabled: isCheckedStateRegistrationExempt() === true,
                  })}
                  className={
                    isCheckedStateRegistrationExempt() === false &&
                    errors.stateRegistration?.type === "required"
                      ? "form-input isInvalid"
                      : "form-input"
                  }
                  maxLength={100}
                  placeholder="Inscrição Estadual"
                  data-testid="legal-state-registration"
                />
                <InvalidFeedback
                  condition={
                    isCheckedStateRegistrationExempt() === false &&
                    errors.stateRegistration?.type === "required"
                  }
                  message="Este campo é de preenchimento obrigatório"
                />
              </div>
            </div>
          </label>
        </div>
      </div>
    </Container>
  );
}
