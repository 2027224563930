import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IFiltersValue {
  costCenter?: IRelationshipFilterOption;
  startDate: string;
  endDate: string;
}
export interface IListCostCenterReportService {
  listCostCenterReport(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
  ): Promise<IResponseEntity<ICostCenterReportEntity[]>>;
}

export interface IListCostCenterReportUseCase {
  listCostCenterReport(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
  ): Promise<IResponseEntity<ICostCenterReportEntity[]>>;
}

export class ListCostCenterReportUseCase
  implements IListCostCenterReportUseCase
{
  constructor(
    private listCostCenterReportService: IListCostCenterReportService,
  ) {}

  listCostCenterReport(
    companyGroupId: string,
    payload: IPayloadEntity,
    filters: IFiltersValue,
  ): Promise<IResponseEntity<ICostCenterReportEntity[]>> {
    return this.listCostCenterReportService.listCostCenterReport(
      companyGroupId,
      payload,
      filters,
    );
  }
}
