import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputPercentage } from "../../../../core/presentation/components/InputPercentage";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IClassificationUspFormEntity } from "../../../domain/entities/classificationUspFormEntity";
import { MakeClassificationUsp } from "../../../main/makeClassificationUsp";
import { Container } from "./styles";

interface ClassificationUspFormModalProps {
  isOpen: boolean;
  currentId: string;
  useClassificationUsp: MakeClassificationUsp;
  onRequestClose(): void;
}

export function ClassificationUspFormModal({
  isOpen,
  currentId,
  onRequestClose,
  useClassificationUsp,
}: ClassificationUspFormModalProps) {
  const {
    getClassificationUsp,
    saveClassificationUsp,
    updateClassificationUsp,
  } = useClassificationUsp;

  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const [isLoading, setIsLoading] = useState(false);

  const readonly = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financialManagement,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles]);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<IClassificationUspFormEntity>({
    mode: "all",
    defaultValues: {
      id: "",
      name: "",
      active: true,
      percentageEmployerInss: 0,
      companyGroupId: currentCompanyGroup.id,
    },
  });

  const handleAfterOpen = async () => {
    if (!currentId) {
      return;
    }

    setIsLoading(true);

    try {
      const formValues = await getClassificationUsp(currentId);
      reset(formValues, { keepDefaultValues: true });
    } catch {
      onRequestClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleAfterClose = () => {
    reset();
  };

  const onValid = async (data: IClassificationUspFormEntity) => {
    const actionText = currentId ? "atualizada" : "cadastrada";

    try {
      if (currentId) {
        await updateClassificationUsp(data);
      } else {
        dialog.fire({
          title: "Aviso",
          backdrop: false,
          text: "Processando dados - Aguarde enquanto os dados de relatórios são atualizados.",
          didOpen: () => {
            dialog.showLoading();
          },
        });

        await saveClassificationUsp(data);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Classificação USP ${actionText} com sucesso.`,
      });

      onRequestClose();
    } catch (err) {
      dialog.close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      shouldCloseOnEsc={!isLoading && !isSubmitting}
      shouldCloseOnOverlayClick={!isLoading && !isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            {currentId ? "Nova Classificação USP" : "Editar Classificação USP"}
          </h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              disabled={isSubmitting}
              onClick={onRequestClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : (
          <form className="form-container row" onSubmit={handleSubmit(onValid)}>
            <div className="col-12 react-modal-body">
              <div className="card card-header-border">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{currentCompanyGroup.name}</h3>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    id="name"
                    type="text"
                    placeholder="Nome"
                    disabled={readonly}
                    data-testid="txt-name"
                    {...register("name", { required: true })}
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    INSS Patronal % <small>(opcional)</small>
                  </span>
                  <Controller
                    control={control}
                    name="percentageEmployerInss"
                    render={({ field }) => {
                      const onChange = (value: number | null) => {
                        field.onChange(value || 0);
                      };

                      return (
                        <InputPercentage
                          {...field}
                          precision={4}
                          onChange={onChange}
                          placeholder="Alíquota %"
                        />
                      );
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                id="btn-close"
                onClick={onRequestClose}
                disabled={isSubmitting}
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-save"
                disabled={readonly || isSubmitting}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Salvar {isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
