import React, { ReactNode, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Container, Dropdown } from "./styles";

interface SoulDropdownProps {
  toggler: ReactNode;
  headerText?: string;
  children: ReactNode;
}

export function SoulDropdown({
  toggler,
  children,
  headerText,
}: SoulDropdownProps) {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  const onPressEscape = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsActive(false);
    }
  };

  useOnClickOutside(ref, () => {
    setIsActive(false);
  });

  return (
    <Container ref={ref}>
      <div
        className="dropdown-button"
        role="button"
        tabIndex={0}
        onClick={() => setIsActive(!isActive)}
        onKeyUp={onPressEscape}
      >
        {toggler}
      </div>
      <Dropdown isActive={isActive}>
        {headerText ? (
          <div className="dropdown-header">{headerText}</div>
        ) : null}
        {children}
      </Dropdown>
    </Container>
  );
}
