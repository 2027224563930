import styled from "styled-components";

interface IContainerProps {
  hasAttachments: boolean;
}

export const Container = styled.div<IContainerProps>`
  min-width: 50rem;

  .react-modal-body {
    padding: 1.25rem;
    padding-top: 0 !important;

    .attachments-dropzone {
      margin-bottom: 1.25rem;
    }

    .attachments-fields {
      display: flex;
      flex-direction: ${props => (props.hasAttachments ? "column" : "row")};

      .attachments-generation {
        ${props => {
          if (props.hasAttachments) {
            return "margin-bottom: 1.25rem;";
          }

          return "margin-right: 1.25rem;";
        }}
      }

      ul.form-container {
        margin-bottom: 1.25rem;

        list-style-type: none;

        li.form-row {
          gap: 0.5rem;
          margin: unset;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }

          label.form-control {
            flex: 1;
            padding: unset;
          }

          div:last-child {
            display: flex;
            align-items: end;
            max-height: 3.5rem;
            flex-basis: 3.115rem;
            justify-content: center;
          }
        }
      }

      .attachments-conclusion {
        flex-grow: 1;
        text-align: right;
      }
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
    justify-content: center;
  }
`;
