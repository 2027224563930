import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IApproveService {
  approve(id: string): Promise<ICostCenterReportEntity>;
}

export interface IApproveUseCase {
  approve(id: string): Promise<ICostCenterReportEntity>;
}

export class ApproveUseCase implements IApproveUseCase {
  constructor(private approveService: IApproveService) {}

  approve(id: string): Promise<ICostCenterReportEntity> {
    return this.approveService.approve(id);
  }
}
