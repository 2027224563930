import { ChangeEvent } from "react";
import { Control, Controller } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";
import { useSolicitImportPage } from "../../../../hooks/useSolicitImportPage";
import { IEditAssessmentForm } from "../../../EditAssessmentFormModal";
import { FieldWrapper } from "../../../FieldWrapper";

interface IAssessmentObservationFieldProps {
  control: Control<IEditAssessmentForm>;
}

export function AssessmentObservationField(
  props: IAssessmentObservationFieldProps,
) {
  const { control } = props;

  const { handleInputClassName } = useSolicitImportPage();

  return (
    <Controller
      control={control}
      name="observation"
      rules={{
        required: true,
        validate: {
          maxLength: v => v?.length <= 140,
        },
      }}
      render={({ field, fieldState }) => {
        const handleObservationChange = (
          event: ChangeEvent<HTMLInputElement>,
        ) => {
          const eventClone = { ...event };
          const cursorEnd = event.target.selectionEnd;
          const eventValue = event.target.value?.toUpperCase() || "";

          eventClone.target.value = eventValue;
          eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

          field.onBlur();
          field.onChange(eventClone);
        };
        return (
          <label className="col-12 form-control">
            <span>Descrição do Rateio</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <input
                {...field}
                id="txt-observation"
                data-testid="txt-observation"
                placeholder="Descrição do Rateio"
                onChange={handleObservationChange}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              condition={fieldState?.error?.type === "maxLength"}
              message="Este campo tem um limite de 140 caracteres"
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
