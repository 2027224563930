import { IApiService } from "../../../core/data/services/apiService";
import {
  EUserProfile,
  userProfileDictionary,
} from "../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { departmentDestinationProfiles } from "../../domain/entities/departmentDestinationProfiles";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";
import { ISaveUserService } from "../../domain/usecases/saveUserUseCase";
import { UserModel, UserRequestProfilesModel } from "../models/userModel";

export class SaveUserService implements ISaveUserService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
    private soulApi: IApiService,
  ) {}

  async saveUser(user: IUserEntity): Promise<IUserEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Users`;

    const userModel = new UserModel({
      id: user.id,
      name: user.name,
      email: user.email,
      document: user.document.replace(/\D/g, ""),
      password: user.password,
      passwordCheck: user.password,
      profile: Number(user.profile),
      profileDescription: userProfileDictionary[user.profile as EUserProfile],
      partnerId: process.env.REACT_APP_PARTNER_ID,
    });

    let response;

    if (userModel.id === "") {
      response = await this.authApi.post<IUserEntity>(url, userModel, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    } else if (userModel.id !== "") {
      response = await this.authApi.put<IUserEntity>(url, userModel, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    if (departmentDestinationProfiles.includes(userModel.profile)) {
      const usrRequestProfilesUrl = `/Users/${response?.id}/UserRequestProfiles`;

      const usrRequestProfilesData = UserRequestProfilesModel.create({
        isFinancial: user.isFinancial,
        isPersonnelDepartment: user.isPersonnelDepartment,
        userProfile: Number(user.profile),
      });

      await this.soulApi.post(usrRequestProfilesUrl, usrRequestProfilesData, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return new UserEntity(response);
  }
}
