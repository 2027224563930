import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useCallback, useState } from "react";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { IPaymentRequestAttachmentEntity } from "../../../../form/domain/entities/paymentRequestAttachmentEntity";
import { usePaymentRequestsAttachmentsHandler } from "../../hooks/usePaymentRequestsAttachmentsHandler";
import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";

interface IPaymentRequestAttachmentsModalState {
  isLoading: boolean;
  isSubmitting: boolean;
  attachments: IPaymentRequestAttachmentEntity[];
}

export function PaymentRequestAttachmentsModal() {
  const {
    isOpen,
    rowData,
    progressModalState,
    getStorageFilebyId,
    listAttachmentTypes,
    attachmentTypeCheck,
    updateAttachmentsBarcode,
    handleCloseAttachmentModal,
    handleAttachmentsSubmission,
    handleAttachmentsModalAfterOpen,
  } = usePaymentRequestsAttachmentsHandler();

  const [state, setState] = useState<IPaymentRequestAttachmentsModalState>({
    attachments: [],
    isLoading: true,
    isSubmitting: false,
  });

  const handleAfterOpen = useCallback(async () => {
    setState(old => ({ ...old, isLoading: true }));

    try {
      const attachments = await handleAttachmentsModalAfterOpen();

      setState(old => ({
        ...old,
        attachments,
      }));
    } catch {
      handleCloseAttachmentModal();
    } finally {
      setState(old => ({ ...old, isLoading: false }));
    }
  }, [handleAttachmentsModalAfterOpen, handleCloseAttachmentModal]);

  const handleAfterClose = () => {
    setState({
      attachments: [],
      isLoading: false,
      isSubmitting: false,
    });
  };

  const handleAttachmentsUpdate = (
    updatedAttachments: IPaymentRequestAttachmentEntity[],
  ) => {
    setState(old => ({ ...old, attachments: updatedAttachments }));
  };

  const handleSubmitAttachments = async () => {
    setState(old => ({ ...old, isSubmitting: true }));

    try {
      await handleAttachmentsSubmission(state.attachments);
    } finally {
      setState(old => ({ ...old, isSubmitting: false }));
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        className="react-modal-content"
        shouldCloseOnOverlayClick={false}
        overlayClassName="react-modal-overlay"
        onRequestClose={handleCloseAttachmentModal}
        shouldCloseOnEsc={state.isSubmitting || state.isLoading}
      >
        <Container>
          <div className="react-modal-header">
            <h4>Alterar anexos</h4>
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              disabled={state.isSubmitting || state.isLoading}
              onClick={handleCloseAttachmentModal}
            >
              <IoMdClose />
            </button>
          </div>
          <div className="col-12 react-modal-body">
            <div className="card">
              <p>
                <strong>Descrição</strong>:{" "}
                {rowData?.description || "(sem descrição)"}
              </p>
              <p>
                <strong>Nº documento</strong>:{" "}
                {rowData?.documentNumber || "(sem número de documento)"}
              </p>
            </div>
            {state.isLoading ? (
              <div className="loading-container">
                <SoulSpinner />
              </div>
            ) : (
              <AttachmentsGrid
                attachments={state.attachments}
                getStorageFilebyId={getStorageFilebyId}
                listAttachmentTypes={listAttachmentTypes}
                attachmentTypeCheck={attachmentTypeCheck}
                onAttachmentsChange={handleAttachmentsUpdate}
                updateAttachmentsBarcode={updateAttachmentsBarcode}
              />
            )}
          </div>
          <div className="col-12 react-modal-footer">
            <button
              type="button"
              id="btn-close"
              data-testid="btn-close"
              className="form-button red-bkg"
              disabled={state.isSubmitting || state.isLoading}
              onClick={handleCloseAttachmentModal}
            >
              Fechar
            </button>
            <button
              type="submit"
              id="btn-submit"
              data-testid="btn-submit"
              className="form-button green-bkg"
              disabled={
                state.isSubmitting ||
                state.isLoading ||
                !state.attachments.length
              }
              onClick={handleSubmitAttachments}
            >
              Tudo certo{" "}
              {state.isSubmitting && <i className="pi pi-spin pi-spinner" />}
            </button>
          </div>
        </Container>
      </Modal>
      <RequestProgressModal
        total={progressModalState.total}
        loaded={progressModalState.loaded}
        isOpen={progressModalState.isOpen}
      />
    </>
  );
}
