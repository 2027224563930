import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 36rem;

  div.react-modal-header {
    span {
      gap: 1rem;
      display: flex;
      align-items: center;
    }
  }

  div.react-modal-body {
    padding-top: 0;

    .p-datatable {
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom: unset;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;

    button {
      &.download-template {
        margin: 0;
        gap: 0.5rem;
        display: flex;
        align-items: center;
      }

      &.footer-close-button {
        margin-left: auto;
      }

      &#btn-return {
        margin: 0;
        gap: 0.5rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .payroll-warning {
    width: 100%;

    gap: 1rem;
    display: flex;
    align-items: center;

    flex: 1 0 auto;

    color: #69583e;
    font-size: 0.875rem;

    border-radius: 0.375rem;
    border: 0.0625rem solid #ffe69c;
    background-color: #fff3cd;

    margin-top: 1rem;
    padding: 0.5rem 1rem;
  }
`;
