import { MultiSelect } from "primereact/multiselect";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IConciliationFormInputEntity } from "../../../domain/entitites/conciliationFormInputEntity";
import { Container } from "./styles";
import { IPaymentAccountEntity } from "../../../domain/entitites/paymentAccountEntity";

interface IInputPaymentAccountProps {
  options: IPaymentAccountEntity[];
  disabled?: boolean;
}

export function InputPaymentAccount({
  options,
  disabled = false,
}: IInputPaymentAccountProps) {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IConciliationFormInputEntity>();

  const renderItem = (option: IPaymentAccountEntity) => {
    return (
      <span className="option-item">
        {option.name}
        <small>{option.companyName}</small>
      </span>
    );
  };

  const removeSelection = (value: IPaymentAccountEntity) => {
    let { paymentAccount } = getValues();

    paymentAccount = paymentAccount.filter(selected => {
      return selected.id !== value.id;
    });

    setValue("paymentAccount", paymentAccount);
  };

  const renderSelectedItems = (value: IPaymentAccountEntity | null) => {
    if (!value) {
      return (
        <div className="p-multiselect-label p-placeholder">
          Contas de Pagamento
        </div>
      );
    }

    return (
      <div className="p-multiselect-token">
        <span className="p-multiselect-token-label">
          {value.name}
          <small>({value.companyName})</small>
        </span>
        <button
          type="button"
          className="p-multiselect-token-icon"
          onClick={() => removeSelection(value)}
        >
          &times;
        </button>
      </div>
    );
  };

  return (
    <Container>
      <Controller
        control={control}
        name="paymentAccount"
        rules={{
          validate: {
            required: value => {
              if (value.length === 0) {
                return false;
              }

              return true;
            },
          },
        }}
        render={({ field }) => {
          return (
            <MultiSelect
              {...field}
              itemTemplate={renderItem}
              selectedItemTemplate={renderSelectedItems}
              display="chip"
              appendTo="self"
              disabled={disabled}
              optionLabel="name"
              removeIcon="pi pi-times"
              placeholder="Contas de pagamento"
              filterPlaceholder="Pesquise uma opção"
              panelClassName="multiselect-panel"
              dataKey="key"
              options={options}
              id="sel-payment-accounts"
              data-testid="sel-payment-accounts"
              filter
              className={`paymentAccount-multiselect ${
                errors.paymentAccount ? "isInvalid" : ""
              }`}
            />
          );
        }}
      />
      <InvalidFeedback
        message="Este campo é obrigatório"
        condition={
          // cast necessario pois a tipagem do rhf nao
          // bate com a abstracao de campos do tipo array
          (errors?.paymentAccount as unknown as FieldError)?.type === "required"
        }
      />
    </Container>
  );
}
