import { ChangeEvent, useState } from "react";
import {
  Controller,
  UseFormRegisterReturn,
  useFormContext,
} from "react-hook-form";
import { ISearchBrazilianCityParams } from "../../../../../../core/domain/contracts/searchBrazilianCityContract";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Checkbox } from "../../../../../../core/presentation/components/Checkbox";
import { InputPercentage } from "../../../../../../core/presentation/components/InputPercentage";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { IAccountsReceivableGenerateInvoiceModalForm } from "../../../domain/entities/formt";
import { IGenerateInvoiceIListItemEntity } from "../../../domain/entities/generateInvoiceIListItemEntity";
import { Container } from "./styles";

export type IFieldsWithPreRegistration = Extract<
  keyof IGenerateInvoiceIListItemEntity,
  "invoiceDescription"
>;

interface IAccountReceivableGenerateInvoiceExtendedFormProps {
  rowIndex: number;
  stateList: ITypeaheadOption[];
  fieldsPreRegistration: Record<
    IFieldsWithPreRegistration,
    UseFormRegisterReturn
  >[];
  searchBrazilianCity(
    params: ISearchBrazilianCityParams,
  ): Promise<ITypeaheadOption[]>;
}

interface IAccountReceivableGenerateInvoiceExtendedFormState {
  cities: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
}

export function AccountReceivableGenerateInvoiceExtendedForm(
  props: IAccountReceivableGenerateInvoiceExtendedFormProps,
) {
  const { rowIndex, stateList, searchBrazilianCity, fieldsPreRegistration } =
    props;

  const asyncDebounce = useDebounceTimeAsync();

  const {
    watch,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext<IAccountsReceivableGenerateInvoiceModalForm>();

  const [state, setState] =
    useState<IAccountReceivableGenerateInvoiceExtendedFormState>({
      cities: {
        options: [],
        loading: false,
      },
    });

  const isIssRetainedAtSource = watch(`data.${rowIndex}.isIssRetainedAtSource`);

  const handleCitySearchChange = async (search: string) => {
    setState(prevState => ({
      ...prevState,
      cities: { ...prevState.cities, loading: true },
    }));

    await asyncDebounce(750);

    const stateId = getValues(
      `data.${rowIndex}.serviceSupplyState.rawValue`,
    ) as string;

    try {
      const response = await searchBrazilianCity({
        search,
        stateId,
        payloadOptions: {
          length: 50,
        },
      });

      setState(prevState => ({
        ...prevState,
        cities: { ...prevState.cities, options: response },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        cities: { ...prevState.cities, loading: false },
      }));
    }
  };

  return (
    <Container className="form-container">
      <div className="form-row">
        <Controller
          name={`data.${rowIndex}.isIssRetainedAtSource`}
          render={({ field }) => {
            const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
              field.onChange(event);
              resetField(`data.${rowIndex}.serviceSupplyState`);
              resetField(`data.${rowIndex}.serviceSupplyCity`);
            };

            return (
              <label className="checkbox-label form-control">
                <Checkbox
                  checked={field.value}
                  onChange={handleChange}
                  label="ISS Retido na fonte"
                  id={`chk-data.${rowIndex}.isIssRetained`}
                />
              </label>
            );
          }}
        />
        <Controller
          name={`data.${rowIndex}.serviceSupplyState`}
          rules={{ required: isIssRetainedAtSource }}
          render={({ field, fieldState }) => {
            const handleChange = (event: ISoulTypeaheadChangeEvent) => {
              field.onChange(event);
              setValue(`data.${rowIndex}.serviceSupplyCity`, null, {
                shouldValidate: true,
              });
            };
            return (
              <label className="form-control">
                <span>Estado prestação de serviço</span>
                <SoulTypeahead
                  openOnFocus
                  options={stateList}
                  value={field.value}
                  placeholder="Estado"
                  onChange={handleChange}
                  disabled={!isIssRetainedAtSource}
                  id={`sel-data.${rowIndex}.serviceSupplyState`}
                  className={fieldState?.error ? "isInvalid" : ""}
                  data-testid={`sel-data.${rowIndex}.serviceSupplyState`}
                />
                <InvalidFeedback
                  message="Este campo é obrigatório"
                  condition={fieldState?.error?.type === "required"}
                />
              </label>
            );
          }}
        />

        <Controller
          name={`data.${rowIndex}.serviceSupplyCity`}
          rules={{ required: isIssRetainedAtSource }}
          render={({ field, fieldState }) => {
            return (
              <label className="form-control">
                <span>
                  Cidade prestação de serviço{" "}
                  {state.cities.loading ? (
                    <i className="pi pi-spin pi-spinner" />
                  ) : null}
                </span>
                <SoulTypeahead
                  serverSide
                  openOnFocus
                  placeholder="Cidade"
                  value={field.value}
                  onChange={field.onChange}
                  loading={state.cities.loading}
                  options={state.cities.options}
                  disabled={!isIssRetainedAtSource}
                  onSearchChange={handleCitySearchChange}
                  id={`sel-data.${rowIndex}.serviceSupplyCity`}
                  className={fieldState?.error ? "isInvalid" : ""}
                  data-testid={`sel-data.${rowIndex}.serviceSupplyCity`}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.type === "required"}
                  message="Este campo é obrigatório"
                />
              </label>
            );
          }}
        />

        <Controller
          name={`data.${rowIndex}.irAliquot`}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <label className="form-control">
                <span>Alíquota IR (%)</span>
                <InputPercentage
                  {...field}
                  precision={4}
                  placeholder="00,0000 %"
                  id={`txt-data.${rowIndex}.irAliquot`}
                  data-testid={`txt-data.${rowIndex}.irAliquot`}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.type === "required"}
                  message="Este campo é obrigatório"
                />
              </label>
            );
          }}
        />

        <Controller
          name={`data.${rowIndex}.csllAliquot`}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <label className="form-control">
                <span>Alíquota CSLL (%)</span>
                <InputPercentage
                  {...field}
                  precision={4}
                  placeholder="00,0000 %"
                  id={`txt-data.${rowIndex}.csllAliquot`}
                  data-testid={`txt-data.${rowIndex}.csllAliquot`}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.type === "required"}
                  message="Este campo é obrigatório"
                />
              </label>
            );
          }}
        />

        <Controller
          name={`data.${rowIndex}.pisAliquot`}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <label className="form-control">
                <span>Alíquota PIS (%)</span>
                <InputPercentage
                  {...field}
                  precision={4}
                  placeholder="00,0000 %"
                  id={`txt-data.${rowIndex}.pisAliquot`}
                  data-testid={`txt-data.${rowIndex}.pisAliquot`}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.type === "required"}
                  message="Este campo é obrigatório"
                />
              </label>
            );
          }}
        />

        <Controller
          name={`data.${rowIndex}.cofinsAliquot`}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <label className="form-control">
                <span>Alíquota COFINS (%)</span>
                <InputPercentage
                  {...field}
                  precision={4}
                  placeholder="00,0000 %"
                  id={`txt-data.${rowIndex}.cofinsAliquot`}
                  data-testid={`txt-data.${rowIndex}.cofinsAliquot`}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.type === "required"}
                  message="Este campo é obrigatório"
                />
              </label>
            );
          }}
        />
      </div>
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Descrição da Nota Fiscal</span>
          <input
            type="text"
            placeholder="Descrição"
            data-testid={`txt-data.${rowIndex}.invoiceDescription`}
            {...fieldsPreRegistration[rowIndex].invoiceDescription}
            className={
              errors?.data?.[rowIndex]?.invoiceDescription ? "isInvalid" : ""
            }
          />
          <InvalidFeedback
            message="Campo obrigatório"
            condition={
              errors?.data?.[rowIndex]?.invoiceDescription?.type === "required"
            }
          />
        </label>
      </div>
    </Container>
  );
}
