import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableRowClickEventParams,
} from "primereact/datatable";
import {
  MutableRefObject,
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuItemCommandParams } from "primereact/menuitem";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import {
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
  SimpleTableColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { MakePaymentRequests } from "../../main/makePaymentRequests";
import { IPaymentRequestEntity } from "../../domain/entities/paymentRequestEntity";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { usePaymentRequestGrid } from "./usePaymentRequestGrid";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";

interface IFetchDataProps {
  pfsEventEntity: IPFSEventEntity;
  orderedColumnNames: string[];
  uiSelectedColumns: IAdvTableColumn[];
}

interface IPaymentRequestPageState {
  isFetching: boolean;
  filtered: boolean;
  orderedColNames: string[];
  pfsEvent: IPFSEventEntity;
  selectedColumns: SimpleTableColumn[];
  contextMenuData: IPaymentRequestEntity | null;
  selectedPanorama: IPanoramaEntity | undefined;
  data: IResponseEntity<IPaymentRequestEntity[]> | undefined;
  attachmentsModal: {
    isOpen: boolean;
    rowData: IPaymentRequestEntity | null;
  };
}

interface IPaymentRequestPageContextProps {
  reload(): void;
  columns: SimpleTableColumn[];
  handleHideContextMenu(): void;
  handleClearButtonClick(): void;
  state: IPaymentRequestPageState;
  handleCloseAttachmentModal(): void;
  tableRef: RefObject<IAdvTableHandle>;
  usePaymentRequests: MakePaymentRequests;
  ref: MutableRefObject<HTMLElement | null>;
  menuRef: MutableRefObject<ContextMenu | null>;
  handleGetList(_pfsEvent: IPFSEventEntity): void;
  handleColumnSelect(column: IAdvTableColumn): void;
  handleColumnRemove(column: IAdvTableColumn): void;
  handleOpenAttachmentModal(params: MenuItemCommandParams): void;
  handleRowDoubleClick(event: DataTableRowClickEventParams): void;
  handleColReorder(colReorderParams: DataTableColReorderParams): void;
  rowClassName(rowData: IPaymentRequestEntity): Record<string, boolean>;
  handleRowClick(e: IDataTableRowClickEventParams<IPaymentRequestEntity>): void;
  handlePanoramaChange(
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate?: boolean,
  ): void;
}

const PaymentRequestPageContext = createContext(
  {} as IPaymentRequestPageContextProps,
);

interface IPaymentRequestPageProviderProps {
  children: ReactNode;
  usePaymentRequests: MakePaymentRequests;
}

export function PaymentRequestPageProvider({
  children,
  usePaymentRequests,
}: IPaymentRequestPageProviderProps) {
  const { listPaymentRequests } = usePaymentRequests;

  const mountedRef = useIsMounted();
  const { advGeneratePayload } = useTables();
  const tableRef = useRef<IAdvTableHandle>(null);
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const ref = useRef(null);
  const menuRef = useRef<ContextMenu>(null);

  const currentCompanyGroupId = currentCompanyGroup.id;
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  const { columns } = usePaymentRequestGrid({
    usePaymentRequests,
  });

  const [state, setState] = useState<IPaymentRequestPageState>(() => {
    return {
      filtered: false,
      data: undefined,
      isFetching: false,
      selection: undefined,
      contextMenuData: null,
      selectedPanorama: undefined,
      pfsEvent: new PFSEventEntity({ rows: 50 }),
      orderedColNames: columns.map(col => col.field),
      selectedColumns: columns.map(col => ({ ...col })),
      attachmentsModal: {
        rowData: null,
        isOpen: false,
      },
    };
  });

  const fetchData = useCallback(
    async ({
      pfsEventEntity,
      uiSelectedColumns,
      orderedColumnNames,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!currentCompanyGroupId) {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
            isFetching: false,
          };
        });

        return;
      }

      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => {
        return {
          ...prevState,
          filtered: isFiltered,
          isFetching: true,
        };
      });

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listPaymentRequests(
          currentCompanyGroupId,
          payload,
        );

        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: response,
          };
        });
      } catch {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
          };
        });
      } finally {
        if (mountedRef.current) {
          setState(prevState => {
            return {
              ...prevState,
              isFetching: false,
            };
          });
        }
      }
    },
    [
      mountedRef,
      advGeneratePayload,
      listPaymentRequests,
      currentCompanyGroupId,
    ],
  );

  const handleColumnSelect = useCallback(
    (column: IAdvTableColumn) => {
      const newColumns = [column, ...state.selectedColumns];
      const newOrderedColNames = newColumns.map(col => col.field);

      setState(prevState => {
        return {
          ...prevState,
          selectedColumns: newColumns,
          orderedColNames: newOrderedColNames,
        };
      });

      // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
      // na sequencia do update do estado nao tem efeito, nao queremos usar um
      // side-effect selectedColumns pois outros eventos atualizam esse estado e
      // nao queremos que que o applyPanorama seja executado em todos eles
      setTimeout(() => {
        tableRef.current?.applyPanorama();

        fetchData({
          orderedColumnNames: newOrderedColNames,
          pfsEventEntity: state.pfsEvent,
          uiSelectedColumns: newColumns,
        });
      });
    },
    [fetchData, state.pfsEvent, state.selectedColumns],
  );

  const handleColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setState(prevState => {
      return {
        ...prevState,
        selectedColumns: prevState.selectedColumns.filter(
          selCol => selCol.field !== field,
        ),
      };
    });
  };

  const handleColReorder = useCallback(
    (colReorderParams: DataTableColReorderParams) => {
      const cols = colReorderParams.columns as unknown as Array<{
        props: { field: string };
      }>;

      const newOrderedColNames = cols
        .map(col => col.props.field)
        .filter((col: string): col is string => !!col);

      // por ora precisamos incluir as hidden tbm, pois sem elas após
      // reordenar as colunas na UI elas nao sao mais enviadas na request
      const hidden = columns
        .filter(col => {
          const c = col as ISimpleHiddenColumn;
          return c.hidden;
        })
        .map(c => c.field);

      setState(prevState => {
        return {
          ...prevState,
          orderedColNames: [...newOrderedColNames, ...hidden],
        };
      });
    },
    [columns],
  );

  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setState(prevState => {
        return {
          ...prevState,
          pfsEvent: _pfsEvent,
        };
      });

      fetchData({
        orderedColumnNames: state.orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: state.selectedColumns,
      });
    },
    [fetchData, state.orderedColNames, state.selectedColumns],
  );

  const handleClearButtonClick = useCallback(() => {
    tableRef.current?.resetFilters();
  }, []);

  const handlePanoramaChange = useCallback(
    (_selectedPanorama: IPanoramaEntity, shouldUpdate = true) => {
      const { systemDefault, panoramaDefinition } = _selectedPanorama;

      // este map é necessario pois a prop filterData da coluna é usada como um
      // state mutável (podemos rever isso e utilizar uma abordagem imutável).
      // Mas neste caso nao queremos que ele modifique a coluna original salva no
      // panorama, entao criamos uma nova lista com clones das colunas do panorama
      // para que quando o filterData seja manipulado, aconteca apenas em memoria
      // e nao no que está salvo no panorama
      // REVIEW
      let selectedPanoramaCols = panoramaDefinition.selectedColumns.map(
        selCol => ({
          ...selCol,
        }),
      );

      let pfsEventEntity = {
        ...panoramaDefinition.pfsEventEntity,
        rows: state.pfsEvent.rows,
      };

      if (systemDefault) {
        selectedPanoramaCols = columns.map(col => ({
          ...col,
        }));
        pfsEventEntity = new PFSEventEntity({ rows: state.pfsEvent.rows });
      }

      setState(prevState => {
        return {
          ...prevState,
          pfsEvent: {
            ...pfsEventEntity,
            rows: prevState.pfsEvent.rows,
          },
        };
      });

      const newOrderedColNames = selectedPanoramaCols.map(selPanCol => {
        return selPanCol.field;
      });

      setState(prevState => {
        return {
          ...prevState,
          orderedColNames: newOrderedColNames,
          selectedColumns: selectedPanoramaCols,
          selectedPanorama: _selectedPanorama,
        };
      });
      if (systemDefault) {
        tableRef.current?.resetFilters();
        return;
      }
      if (!shouldUpdate) {
        return;
      }

      // refaz a request para garantir que os resultados que o usuario
      // verá estarão de acordo com os filtros do panorama
      fetchData({
        orderedColumnNames: newOrderedColNames,
        pfsEventEntity,
        uiSelectedColumns: selectedPanoramaCols,
      });
    },
    [columns, fetchData, state.pfsEvent.rows],
  );

  const handleRowClick = useCallback(
    (event: IDataTableRowClickEventParams<IPaymentRequestEntity>) => {
      event.originalEvent.stopPropagation();

      setState(old => ({
        ...old,
        contextMenuData: event.data,
      }));

      menuRef?.current?.show(event.originalEvent);
    },
    [],
  );

  const handleHideContextMenu = useCallback(() => {
    setState(old => ({
      ...old,
      contextMenuData: null,
    }));
  }, []);

  const rowClassName = useCallback(
    (rowData: IPaymentRequestEntity) => {
      const { status, id } = rowData;

      return {
        "row-clicked": state?.contextMenuData?.id === id,
        "row-requested": status === EPaymentRequestStatus.Requested,
        "row-rejected": status === EPaymentRequestStatus.Rejected,
        "row-completed":
          status === EPaymentRequestStatus.Paid ||
          status === EPaymentRequestStatus.Approved,
      };
    },
    [state?.contextMenuData?.id],
  );

  const handleRowDoubleClick = useCallback(
    (event: DataTableRowClickEventParams) => {
      const paymentRequest = event.data as IPaymentRequestEntity;

      const paymentRequestId = paymentRequest.id;
      const isRequested =
        paymentRequest.status === EPaymentRequestStatus.Requested;

      if (isRequested) {
        window.open(
          `${SoulRoutes.PAYMENT_REQUESTS.path}/${paymentRequestId}/review`,
        );
        return;
      }

      window.open(`${SoulRoutes.PAYMENT_REQUESTS.path}/${paymentRequestId}`);
    },
    [],
  );

  const reload = useCallback(() => {
    fetchData({
      orderedColumnNames: state.orderedColNames,
      pfsEventEntity: state.pfsEvent,
      uiSelectedColumns: state.selectedColumns,
    });
  }, [fetchData, state.orderedColNames, state.pfsEvent, state.selectedColumns]);

  const handleOpenAttachmentModal = useCallback(
    async ({ item }: MenuItemCommandParams) => {
      setState(old => ({
        ...old,
        attachmentsModal: {
          isOpen: true,
          rowData: item?.data || null,
        },
      }));
    },
    [],
  );

  const handleCloseAttachmentModal = useCallback(() => {
    setState(old => ({
      ...old,
      attachmentsModal: {
        rowData: null,
        isOpen: false,
      },
    }));
  }, []);

  // NOTE este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // das colunas internamente de forma mutável, para isso sempre que alteramos
  // states que influenciam o sequenciamento das colunas, precisamos chamar
  // imperativamente o applyPanorama (que por sua vez executa o resetColumnOrder
  // do primereact). Desta forma conseguimos manter sincronizados o estado
  // interno do prime e o render na UI
  useEffect(() => {
    tableRef.current?.applyPanorama();
  }, [state.selectedPanorama]);

  useEffect(() => {
    fetchData({
      orderedColumnNames: state.orderedColNames,
      pfsEventEntity: state.pfsEvent,
      uiSelectedColumns: columns,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      reload();
    }
  }, [currentCompanyGroupId, reload]);

  const memoizedValue = useMemo(() => {
    return {
      ref,
      state,
      reload,
      columns,
      menuRef,
      tableRef,
      rowClassName,
      handleGetList,
      handleRowClick,
      handleColReorder,
      usePaymentRequests,
      handleColumnSelect,
      handleColumnRemove,
      handlePanoramaChange,
      handleRowDoubleClick,
      handleHideContextMenu,
      handleClearButtonClick,
      handleOpenAttachmentModal,
      handleCloseAttachmentModal,
    };
  }, [
    state,
    reload,
    columns,
    rowClassName,
    handleGetList,
    handleRowClick,
    handleColReorder,
    handleColumnSelect,
    usePaymentRequests,
    handlePanoramaChange,
    handleRowDoubleClick,
    handleHideContextMenu,
    handleClearButtonClick,
    handleOpenAttachmentModal,
    handleCloseAttachmentModal,
  ]);

  return (
    <PaymentRequestPageContext.Provider value={memoizedValue}>
      {children}
    </PaymentRequestPageContext.Provider>
  );
}

export function usePaymentRequestPage() {
  return useContext(PaymentRequestPageContext);
}
