import styled from "styled-components";

export const Container = styled.div`
  .body {
    padding: 1.5rem;

    .panel {
      position: relative;
      background: #f6f9fc;
      height: calc(100vh - 20rem);
      overflow: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 2rem;
      gap: 2rem;

      > div {
        color: var(--soul-color01);
        flex: 1;

        > div:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          gap: 0.25em;
          font-weight: 900;

          .conciliated {
            color: var(--soul-green-button);
          }

          .missing {
            color: var(--soul-red-button);
          }

          & + div {
            text-transform: uppercase;
            font-weight: 900;
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 0 1.5rem 1.5rem;

    .template-button {
      margin-left: 0;
    }

    .back-button {
      margin-left: auto;
    }
  }
`;
