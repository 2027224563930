import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin-bottom: 2rem;

  .custom-article {
    padding: 0.625rem;
    padding-bottom: 0;

    .user-requested {
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.5);

      padding: 0.75rem 1.23rem;

      background-color: #bac1c8;

      border-radius: 0.375rem;
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2rem;

    background-color: #fff;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .right-col {
    padding-left: 0.5rem;
  }
`;
