import { ServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IProviderEntity } from "../../../provider/domain/entities/providerEntity";

export interface ISearchProvidersService {
  searchProviders(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<ServerSideResponseModel<IProviderEntity[]>>;
}

export interface ISearchProvidersUseCase {
  searchProviders(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<ServerSideResponseModel<IProviderEntity[]>>;
}

export class SearchProvidersUseCase implements ISearchProvidersUseCase {
  constructor(private listProviderService: ISearchProvidersService) {}

  searchProviders(search: string, companyGroupId: string, active?: boolean) {
    return this.listProviderService.searchProviders(
      search,
      companyGroupId,
      active,
    );
  }
}
