import { IServerSideResponseModel } from "../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../simpleTable/domain/entities/simplePayloadEntity";
import { IListImportationMatchesCostCentersContract } from "../domain/contracts/listImportationMatchesCostCentersContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";
import { IImportationMatchesCostCenterEntity } from "../domain/entities/importationMatchesCostCenterEntity";

export class ListImportationMatchesCostCentersService
  implements IListImportationMatchesCostCentersContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listImportationMatchesCostCenters(
    payload: IPayloadEntity,
    origin: EImportationMatchesOrigin,
  ) {
    const userEntity = this.getUserLocalService.get();
    const endpoint = `ImportationMatches/${origin}/CostCenters/Actives`;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `${endpoint}?${params}`;

    return this.api.get<
      IServerSideResponseModel<IImportationMatchesCostCenterEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
