import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetCompetencyService } from "../data/services/getCompetencyService";
import { ListCompetenciesService } from "../data/services/listCompetenciesServices";
import { SaveCompetencyService } from "../data/services/saveCompetencyService";
import { ToggleCompetencyService } from "../data/services/toggleCompetencyService";
import {
  GetCompetencyUseCase,
  IGetCompetencyUseCase,
} from "../domain/usecases/getCompetencyUseCase";
import {
  IListCompetenciesUseCase,
  ListCompetencyUseCase,
} from "../domain/usecases/listCompetenciesUseCase";
import {
  ISaveCompetencyUseCase,
  SaveCompetencyUseCase,
} from "../domain/usecases/saveCompetencyUseCase";
import {
  IToggleCompetencyUseCase,
  ToggleCompetencyUseCase,
} from "../domain/usecases/toggleCompetencyUseCase";

export type MakeCompetency = IListCompetenciesUseCase &
  IToggleCompetencyUseCase &
  IGetCompetencyUseCase &
  ISaveCompetencyUseCase;

export function makeCompetency(api: IApiService): MakeCompetency {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const listCompetenciesService = new ListCompetenciesService(
    getUserLocalService,
    api,
  );
  const listCompetenciesUseCase = new ListCompetencyUseCase(
    listCompetenciesService,
  );

  const toggleCompetencyService = new ToggleCompetencyService(
    getUserLocalService,
    api,
  );
  const toggleCompetencyUseCase = new ToggleCompetencyUseCase(
    toggleCompetencyService,
  );

  const getCompetencyService = new GetCompetencyService(
    getUserLocalService,
    api,
  );
  const getCompetencyUseCase = new GetCompetencyUseCase(getCompetencyService);

  const saveCompetencyService = new SaveCompetencyService(
    getUserLocalService,
    api,
  );
  const saveCompetencyUseCase = new SaveCompetencyUseCase(
    saveCompetencyService,
  );

  return {
    listCompetencies(companyGroupId, payload) {
      return listCompetenciesUseCase.listCompetencies(companyGroupId, payload);
    },
    toggleCompetency(id) {
      return toggleCompetencyUseCase.toggleCompetency(id);
    },
    getCompetency(comptencyId) {
      return getCompetencyUseCase.getCompetency(comptencyId);
    },
    saveCompetency(competency) {
      return saveCompetencyUseCase.saveCompetency(competency);
    },
  };
}
