import { differenceInDays, format, formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/locale/pt-BR/index";
import { useCallback } from "react";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ICostCenterEntity } from "../../../domain/entities/costCenterEntity";
import {
  DeactivateCostCenterForm,
  DeactivateCostCenterFormEntity,
} from "../DeactivateCostCenterForm";
import { ActivateContainer } from "./style";

export interface CostCenterCustomToggleProps {
  index: number;
  readonly: boolean;
  costCenter: ICostCenterEntity;
  handleToggle(payload: ICostCenterEntity): Promise<void>;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export function CostCenterCustomToggle(props: CostCenterCustomToggleProps) {
  const { costCenter, index, readonly, handleToggle, searchCostCenter } = props;

  const { active, deleteDate, deleteReason, deleteUserName } = costCenter;

  const dialog = useSoulDialog();
  const {
    currentCompanyGroup: { id: currentCompanyGroupId },
  } = useCurrentCompanyGroup();

  const changeSubmissionButton = useCallback(
    (isValid: boolean) => {
      const confirmButton = dialog.getConfirmButton();
      const classList = confirmButton?.classList;

      const classToAdd = isValid ? "green-bkg" : "invalid-bkg";
      const classToRemove = isValid ? "invalid-bkg" : "green-bkg";

      if (!classList) return;

      classList.add(classToAdd);
      classList.remove(classToRemove);
    },
    [dialog],
  );

  const submitDeactivateForm = useCallback(
    async (values: DeactivateCostCenterFormEntity) => {
      if (!values.costCenterReplaced) {
        return;
      }

      dialog.showLoading();
      await handleToggle({
        ...costCenter,
        active: false,
        deleteReason: values.deleteReason || "",
        costCenterReplacedName: values.costCenterReplaced.label,
        costCenterReplacedId: values.costCenterReplaced.rawValue as string,
      });
    },
    [costCenter, dialog, handleToggle],
  );

  const calculateReasonDate = useCallback(() => {
    if (deleteDate) {
      const difference = differenceInDays(new Date(), new Date(deleteDate));

      // Se tiver sido mais de 30 dias atrás mostrar data e hora
      if (difference > 30) {
        return format(new Date(deleteDate), "dd/MM/yyyy 'às' HH:mm");
      }

      return formatDistanceToNow(new Date(deleteDate), {
        locale: ptBR,
      });
    }
    return "";
  }, [deleteDate]);

  const activateDialog = useCallback(() => {
    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showLoaderOnConfirm: true,
      title: "Você está certo disso?",
      async preConfirm() {
        await handleToggle({
          ...costCenter,
          active: true,
        });
      },
      html: (
        <>
          <p>Este Centro de Custo foi inativado pois:</p>
          <ActivateContainer>
            {deleteUserName ? (
              <span>
                Desativado por: <strong>{deleteUserName}</strong>
              </span>
            ) : null}
            {!deleteUserName ? (
              <em className="no-info">Sem informações</em>
            ) : null}{" "}
            <small>{calculateReasonDate()}</small>
            <br />
            {deleteReason}
          </ActivateContainer>
          <p>Tem certeza que deseja reativá-lo?</p>
        </>
      ),
    });
  }, [
    dialog,
    costCenter,
    handleToggle,
    deleteReason,
    deleteUserName,
    calculateReasonDate,
  ]);

  const deactivateDialog = useCallback(() => {
    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      didOpen() {
        const confirmButton = dialog.getConfirmButton();
        confirmButton?.setAttribute("type", "submit");
        confirmButton?.setAttribute("form", "deactivate-form");
        changeSubmissionButton(!!costCenter.costCenterReplacedId);
      },
      preConfirm() {
        return false;
      },
      html: (
        <DeactivateCostCenterForm
          costCenter={costCenter}
          onValid={submitDeactivateForm}
          searchCostCenter={searchCostCenter}
          currentCompanyGroupId={currentCompanyGroupId}
          changeSubmissionButton={changeSubmissionButton}
        />
      ),
    });
  }, [
    dialog,
    costCenter,
    searchCostCenter,
    submitDeactivateForm,
    currentCompanyGroupId,
    changeSubmissionButton,
  ]);

  return (
    <Toggle
      value={active}
      readonly={readonly}
      inactiveClassName="toggle-muted"
      id={`btn-tgl-status-${index}`}
      data-testid={`btn-tgl-status-${index}`}
      onChange={async _active => {
        const dialogToDisplay = _active ? activateDialog : deactivateDialog;
        const result = await dialogToDisplay();
        return result.isConfirmed ? _active : !_active;
      }}
    />
  );
}
