import styled from "styled-components";

interface ContainerProps {
  isEmpty: boolean;
  suffixSpace: number;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: relative;

  border-radius: 0.25rem;
  border: 1px solid var(--input-border-color);

  background-color: #fff;

  height: calc(1.5em + 0.44rem);

  :focus-within {
    border-color: rgba(50, 151, 211, 0.25);
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  &.disabled {
    opacity: 1;
    cursor: not-allowed;
    background-color: #e9ecef;
  }

  &.isInvalid {
    border-color: var(--invalid);

    :focus-within {
      box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
  }

  input {
    border: none;
    outline: none;
    appearance: none;

    height: 100%;
    width: inherit;

    color: #495057;
    line-height: 1.5;
    font-size: 0.875rem;

    border-radius: 0.25rem;

    padding: 0.25rem 0rem 0.25rem 0.5rem;

    ::placeholder {
      color: #d1d1d9;
    }
  }

  ::after {
    color: #495057;
    line-height: 1.5;
    position: absolute;
    font-size: 0.875rem;
    padding: 0.25rem 0 0.25rem 0rem;
    left: ${props => `${props.suffixSpace}ch`};
    content: "${props => (props.isEmpty ? "" : "%")}";
  }
`;
