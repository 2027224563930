import { ProgressSpinner } from "primereact/progressspinner";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import styled from "styled-components";
import { IFetchRpaParamOptionsReturn } from "../../../domain/contracts/fetchRpaParamOptionsContract";
import { IEnum } from "../../../domain/entities/enum";
import { IGenerateRPAModalInputEntity } from "../../../domain/entities/generateRpaModalInputEntity";
import { InvalidFeedback } from "../InvalidFeedback";

const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 33.75rem;

  .react-modal-body {
    padding-top: 0;
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }
`;

interface IAttachmentGenerateRpaModalState {
  loading: boolean;
  submitting: boolean;
  options: {
    detailsTypes: IEnum[];
    requestTypes: IEnum[];
    serviceTypes: IEnum[];
  };
}

interface IAttachmentGenerateRpaModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  fetchRpaParamOptions?(): Promise<IFetchRpaParamOptionsReturn> | undefined;
  onSubmit(formData: IGenerateRPAModalInputEntity): Promise<void>;
}

export function AttachmentGenerateRpaModal({
  isOpen,
  onRequestClose,
  fetchRpaParamOptions,
  onSubmit,
}: IAttachmentGenerateRpaModalProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<IGenerateRPAModalInputEntity>({
    mode: "all",
    defaultValues: {
      paymentDetail: null,
      requestType: null,
      serviceType: null,
    },
  });

  const [state, setState] = useState<IAttachmentGenerateRpaModalState>({
    loading: true,
    submitting: false,
    options: {
      detailsTypes: [],
      requestTypes: [],
      serviceTypes: [],
    },
  });

  /**
   * lida com o evento de abertura da modal, responsavel
   * pela inicializacao dos estados da modal
   */
  const handleAfterOpen = async () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const fetchedOptions = await fetchRpaParamOptions?.();

      setState(prevState => {
        let { options } = prevState;

        if (fetchedOptions) {
          options = { ...fetchedOptions };
        }

        return {
          ...prevState,
          loading: false,
          options,
        };
      });
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  /** Dispara evento de on submit para o componente pai tratar */
  const submit = async (formData: IGenerateRPAModalInputEntity) => {
    setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    try {
      await onSubmit(formData);
    } finally {
      setState(prevState => ({
        ...prevState,
        submitting: true,
      }));
    }
  };

  /**
   * Lida com o evento de fechamento da modal,
   * responsavel por resetar o estado da modal
   */
  const handleAfterClose = () => {
    setState({
      loading: true,
      submitting: false,
      options: {
        detailsTypes: [],
        requestTypes: [],
        serviceTypes: [],
      },
    });
    reset({
      paymentDetail: null,
      requestType: null,
      serviceType: null,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerar e anexar RPA</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>

        {state.loading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(submit)}>
            <div className="react-modal-body">
              <div className="form-container">
                {/* Tipo de requisição */}
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Tipo de requisição</span>
                    <select
                      {...register("requestType", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      className={
                        errors?.requestType?.type === "required"
                          ? "isInvalid"
                          : undefined
                      }
                    >
                      <option value="" disabled>
                        Tipo de requisição
                      </option>
                      {state.options.requestTypes.map(({ key, value }) => {
                        return (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                    <InvalidFeedback
                      condition={errors?.requestType?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </label>
                </div>

                {/* Tipo de serviço */}
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Tipo de serviço</span>
                    <select
                      {...register("serviceType", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      className={
                        errors?.serviceType?.type === "required"
                          ? "isInvalid"
                          : undefined
                      }
                    >
                      <option value="" disabled>
                        Tipo de serviço
                      </option>
                      {state.options.serviceTypes.map(({ key, value }) => {
                        return (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                    <InvalidFeedback
                      condition={errors?.serviceType?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </label>
                </div>

                {/* Detalhes do pagamento */}
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Detalhes do pagamento</span>
                    <select
                      {...register("paymentDetail", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      className={
                        errors?.paymentDetail?.type === "required"
                          ? "isInvalid"
                          : undefined
                      }
                    >
                      <option value="" disabled>
                        Detalhes do pagamento
                      </option>
                      {state.options.detailsTypes.map(({ key, value }) => {
                        return (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                    <InvalidFeedback
                      condition={errors?.paymentDetail?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                data-testid="btn-close"
                onClick={onRequestClose}
              >
                Fechar
              </button>
              <button
                type="submit"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
                id="btn-submit"
                data-testid="btn-submit"
                disabled={state.loading}
              >
                Confirmar{" "}
                {state.loading && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
