import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetAttachmentsContract } from "../../domain/contracts/getAttachmentsContract";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntitty";
import { IAccountPayableFormEntity } from "../../domain/entities/accountPayableFormEntity";

export class GetAttachmentsService implements IGetAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getAttachments(accountPayableFormEntity: IAccountPayableFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const { id } = accountPayableFormEntity;

    const url = `/AccountsPayable/${id}/Attachments`;

    return this.api.get<IAccountPayableAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
