import { IEntity } from "../../../../core/domain/entities/entity";
import { ICostCenterEntity } from "./costCenterEntity";

export interface ICostCenterReportInputEntity extends IEntity {
  companyGroupId: string;
  costCenterOrigin: ICostCenterEntity | undefined;
  costCenterDestination: ICostCenterEntity | undefined;
  transactionDate: string;
  value: number;
  description: string;
}

export class CostCenterReportInputEntity
  implements ICostCenterReportInputEntity
{
  id = "";
  companyGroupId = "";
  costCenterOrigin = undefined;
  costCenterDestination = undefined;
  transactionDate = "";
  value = 0;
  description = "";
  active = true;

  constructor(params?: Partial<ICostCenterReportInputEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterReportInputEntity>) {
    return new CostCenterReportInputEntity(params);
  }
}
