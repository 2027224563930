import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { useMemo } from "react";
import { FaFileExcel } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import soulLogo from "../../../../assets/logo-soul-160x154.png";
import { Container } from "./styles";

interface IConciliationRowHeaderProps {
  disabled?: boolean;
  selectAllChecked: boolean;
  onSelectAllChange(value: boolean): void;
}

export function ConciliationRowHeader({
  disabled = false,
  selectAllChecked,
  onSelectAllChange,
}: IConciliationRowHeaderProps) {
  const handleCheckboxChange = (event: CheckboxChangeParams) => {
    const { checked } = event;
    onSelectAllChange(checked);
    ReactTooltip.rebuild();
  };

  const tooltipText = useMemo(() => {
    if (selectAllChecked) {
      return "Deselecionar todos os lançamentos";
    }

    return "Selecionar todos os lançamentos";
  }, [selectAllChecked]);

  return (
    <Container>
      <div className="wrapper">
        <div className="raw-col">
          <div className="chk-box">
            <div data-tip={tooltipText} data-effect="solid" data-place="right">
              <Checkbox
                disabled={disabled}
                checked={selectAllChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
          <div className="content">
            <FaFileExcel />
          </div>
        </div>
        <div className="release-col">
          <div className="wrapper">
            <div className="buttons" />
            <div className="content">
              <img src={soulLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
