import styled from "styled-components";
import { ECostCenterTransactionType } from "../../../domain/entities/costCenterTransactionsEntity";

interface BadgeTypeProps {
  type: ECostCenterTransactionType;
  pill?: boolean;
}

export const BadgeType = styled.div<BadgeTypeProps>`
  width: 100%;
  max-width: 128px;

  overflow: hidden;

  background-color: ${props => {
    if (props.type === ECostCenterTransactionType.expense) {
      return "var(--soul-red-button)";
    }

    return "var(--soul-green-button)";
  }};

  color: white;
  padding: 0.12em 0;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5em;
`;
