import { useCallback } from "react";
import { IBankEntity } from "../../../core/domain/entities/bankEntity";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";
import { PaymentAccountFormEntity } from "../../domain/entities/paymentAccountFormEntity";

export function usePaymentAccountForm() {
  return useCallback(
    (paymentAccount: IPaymentAccountEntity, banks: IBankEntity[]) => {
      const bankValue = banks.find(b => b?.key === paymentAccount?.bank);
      const bankDescription = bankValue
        ? `${bankValue.key} - ${bankValue.value}`
        : "";
      return new PaymentAccountFormEntity({
        bank: bankValue?.key || null,
        bankDescription,
        companyId: paymentAccount?.companyId || "",
        name: paymentAccount?.name || "",
        openingBalance: paymentAccount?.openingBalance || 0,
        bankAccountType: paymentAccount?.bankAccountType || "",
        bankBranch: paymentAccount?.bankBranch || "",
        bankBranchDigit: paymentAccount?.bankBranchDigit || "",
        bankAccount: paymentAccount?.bankAccount || "",
        bankAccountDigit: paymentAccount?.bankAccountDigit || "",
      });
    },
    [],
  );
}
