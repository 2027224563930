import { ICustomerEntity } from "../entities/customerEntity";

export interface IToggleCustomerStatusUseCase {
  toggleCustomerStatus(id: string): Promise<ICustomerEntity>;
}

export interface IToggleCustomerStatusService {
  toggleCustomerStatus(id: string): Promise<ICustomerEntity>;
}

export class ToggleCustomerStatusUseCase
  implements IToggleCustomerStatusUseCase
{
  constructor(
    private toggleCustomerStatusService: IToggleCustomerStatusService,
  ) {}

  toggleCustomerStatus(id: string) {
    return this.toggleCustomerStatusService.toggleCustomerStatus(id);
  }
}
