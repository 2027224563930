import FileSaver from "file-saver";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetStorageFilebyIdContract } from "../../domain/contracts/getStorageFilebyIdContract";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntitty";

export class GetStorageFilebyIdService implements IGetStorageFilebyIdContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getStorageFilebyId(attachment: IAccountPayableAttachmentEntity) {
    const userEntity = this.getUserLocalService.get();

    const { storageFileId, name: fileName } = attachment;

    const url = `/Downloads/AccountsPayable/Attachments/StorageFiles/File/${storageFileId}`;

    const blob = await this.api.get<Blob>(url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (blob.type === "application/pdf") {
      const fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
    }

    FileSaver.saveAs(blob, fileName);
  }
}
