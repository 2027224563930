import { EGenerateTaxStatus, ICompanyTaxEntity } from "./companyTaxEntity";

export type ICompanyTaxFormEntity = Pick<
  ICompanyTaxEntity,
  | "id"
  | "name"
  | "tax"
  | "startDate"
  | "endDate"
  | "isUsp"
  | "providerId"
  | "generateTax"
  | "paymentAccountId"
  | "classificationUspId"
  | "classificationAccountId"
  | "classificationAssessmentId"
>;

export type TaxFormValue = {
  isEdited: boolean;
  taxForm: ICompanyTaxFormEntity[];
};

export class CompanyTaxFormEntity implements ICompanyTaxFormEntity {
  id = "";
  tax = 0;
  name = "";
  endDate = "";
  isUsp = false;
  startDate = "";
  providerId = "";
  paymentAccountId = "";
  classificationUspId = "";
  classificationAccountId = "";
  classificationAssessmentId = "";
  generateTax = EGenerateTaxStatus.Empty;

  constructor(init?: Partial<ICompanyTaxFormEntity>) {
    Object.assign(this, init);
  }

  static create(params?: Partial<ICompanyTaxFormEntity>) {
    return new CompanyTaxFormEntity(params);
  }
}
