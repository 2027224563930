import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { attachmentTypeLang } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveDebtImportFileContract } from "../../domain/contracts/saveDebtImportFileContract";
import { IDebtImportAssessmentEntity } from "../../domain/entities/debtImportAssessmentEntity";
import { DebtImportEntity } from "../../domain/entities/debtImportEntity";
import { IDebtImportResponseModel } from "../models/debtImportModel";
import { MainDebtImportService } from "./mainDebtImportService";

export class SaveDebtImportFileService
  extends MainDebtImportService
  implements ISaveDebtImportFileContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async saveDebtImportFile(file: File) {
    const userEntity = this.getUserLocalService.get();
    const formData = new FormData();

    formData.append("file", file);

    const url = "/AccountPayableImportations";

    const importList = await this.api.post<IDebtImportResponseModel[]>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    /** É necessário que as linhas inconsistentes sejam as primeiras linhas. */
    const orderedList = importList.sort(a => {
      return a.hasError ? -1 : 0;
    });

    const importData = orderedList.map(data => {
      const payUntil = data?.payUntil
        ? format(new Date(data.payUntil), "dd/MM/yyyy")
        : "";
      const issueDate = data?.issueDate
        ? format(new Date(data.issueDate), "dd/MM/yyyy")
        : "";
      const terminationDate = data?.terminationDate
        ? format(new Date(data.terminationDate), "dd/MM/yyyy")
        : "";

      const provider = this.buildTypeaheadObject(data, "provider");
      const company = this.buildTypeaheadObject(data, "company");
      const competency = this.buildTypeaheadObject(data, "competency");

      if (company) {
        company.metadata = {
          hasProject: data.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: data.getCompetencyIsUsp,
        };
      }

      if (provider && typeof data.providerDocumentType === "number") {
        provider.metadata = {
          documentType: data.providerDocumentType,
        };
      }

      return new DebtImportEntity({
        id: data.id,
        accountAlreadyExists: data?.accountAlreadyExists || false,
        accountAlreadyExistsForAnotherUser:
          data?.accountAlreadyExistsForAnotherUser || false,
        accountAlreadyPaid: data?.accountAlreadyPaid || false,
        assessments:
          data?.assessments?.map<IDebtImportAssessmentEntity>(assessment => ({
            hasError: assessment.hasError,
            fieldErrors: assessment.fieldErrors,
            observation: assessment.observation || "",
            value: new BigNumber(assessment.value).toJSON(),
            valueAssessmentLeft: assessment.valueAssessmentLeft,
            percentage: new BigNumber(assessment.percentage).toJSON(),
            costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
            classificationAssessment: this.buildTypeaheadObject(
              assessment,
              "classificationAssessment",
            ),
          })) || [],
        barcode: data?.barcode || "",
        classificationAccount: this.buildTypeaheadObject(
          data,
          "classificationAccount",
        ),
        classificationUsp: this.buildTypeaheadObject(data, "classificationUsp"),
        company,
        competency,
        description: data?.description?.toUpperCase() || "",
        documentNumber: data?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: data?.documentStatus ?? "",
          value: data.documentStatusDescription,
        },
        getCompanyHasProject: data.getCompanyHasProject,
        getCompetencyIsUsp: data.getCompetencyIsUsp,
        hasAssessmentError: data.hasAssessmentError,
        hasError: data.hasError,
        issueDate,
        observation: data?.observation?.toUpperCase() || "",
        payUntil,
        paymentAccount: this.buildTypeaheadObject(data, "paymentAccount"),
        paymentMethod: {
          key: data?.paymentMethod ?? "",
          value: data.paymentMethodDescription,
        },
        project: this.buildTypeaheadObject(data, "project"),
        provider,
        storageFiles:
          data?.storageFiles?.map(_file => {
            const active =
              typeof _file.active === "undefined" ? true : _file.active;

            let typeDescription = _file.typeDescription || "";

            if (!typeDescription) {
              typeDescription =
                typeof _file.type === "undefined"
                  ? ""
                  : attachmentTypeLang[_file.type];
            }

            return {
              ..._file,
              active,
              typeDescription,
            };
          }) || [],
        terminationDate,
        value: data.value,
        valueAssessmentLeft: data.valueAssessmentLeft,
        fieldErrors: data.fieldErrors,
        fuspPurchaseOrderId: data.fuspPurchaseOrderId?.toString() || "",
        correspondingProviderName: data?.correspondingProviderName || "",
      });
    });

    return importData;
  }
}
