import { ProgressBar } from "primereact/progressbar";
import styled from "styled-components";
import { SoulSpinner } from "../../SoulSpinner";

const ProgressModalContentContainer = styled.div`
  font-size: 0.975rem;

  p,
  .progress-bar {
    margin: 1rem 0;
  }

  .export-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
    height: 2em;

    .export-spinner {
      width: 1rem;
      height: 1rem;
      position: relative;
      top: 0.125rem;
      margin-left: 0.5rem;

      .p-progress-spinner-svg {
        .p-progress-spinner-circle {
        }
      }
    }
  }
`;

interface IProgressModalContentProps {
  message?: string;
  info?: string;
}

export function ProgressModalContent({
  message = "Por favor, aguarde enquanto processamos o seu arquivo...",
  info = "Processando dados",
}: IProgressModalContentProps) {
  return (
    <ProgressModalContentContainer>
      <p>{message}</p>

      <div className="progress-bar">
        <ProgressBar mode="indeterminate" style={{ height: "0.375rem" }} />
      </div>

      <div className="export-modal-footer">
        {info}
        <SoulSpinner className="export-spinner" />
      </div>
    </ProgressModalContentContainer>
  );
}
