import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListCompaniesContract } from "../../domain/contracts/listCompaniesContract";
import { ICompanyEntity } from "../../domain/entitites/companyEntity";
import { ICompanyResponseModel } from "../models/companyResponseModel";

export class ListCompaniesService implements IListCompaniesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCompanies(companyGroupId: string): Promise<ICompanyEntity[]> {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "cnpj",
          name: "cnpj",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "assumedName",
          name: "assumedName",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "corporationName",
          name: "corporationName",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: {
        regex: false,
        value: "",
      },
      length: 0,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Companies/Actives?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<ICompanyResponseModel[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    let companies: ICompanyEntity[] = response.data.map(company => {
      return {
        id: company.id,
        name: company.assumedName,
      };
    });

    const pcgIdx = companies.findIndex(c =>
      c.name.toUpperCase().includes("IPECEGE"),
    );

    const pecege = companies.splice(pcgIdx, 1) ?? [];

    const fealqIdx = companies.findIndex(c =>
      c.name.toUpperCase().includes("FEALQ"),
    );

    const fealq = companies.splice(fealqIdx, 1) ?? [];

    companies = [
      ...pecege,
      ...fealq,
      ...companies.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      }),
    ];

    return companies;
  }
}
