import { ILowerCaseErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";

export interface ICheckCnpjService {
  checkCnpj(
    cnpj: string,
    id?: string,
  ): Promise<Partial<ILowerCaseErrorResponseEntity>>;
}

export interface ICheckCnpjUseCase {
  checkCnpj(
    cnpj: string,
    id?: string,
  ): Promise<Partial<ILowerCaseErrorResponseEntity>>;
}

export class CheckCnpjUseCase implements ICheckCnpjUseCase {
  constructor(private checkCnpjService: ICheckCnpjService) {}

  checkCnpj(cnpj: string, id?: string) {
    return this.checkCnpjService.checkCnpj(cnpj, id);
  }
}
