import styled from "styled-components";

export const Container = styled.div`
  max-width: 48rem;

  .react-modal-body {
    padding-top: 0 !important;
  }

  .card {
    padding: 1rem;
    background: #090081;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchbar {
    padding: 0.5rem 1rem;
  }

  .card + .modal-table {
    margin-top: 1.5rem;
    border-radius: 0.375rem;
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  font-size: 1.2rem;
`;
