import { IEntity } from "../../../../core/domain/entities/entity";

export enum EProjectTransactionType {
  expense = 1, // débito
  revenue = 2, // crédito
}

export const projectTransactionTypeLang: Record<string, string> = {
  expense: "Débito",
  revenue: "Crédito",
};

export interface IProjectTransactionEntity extends IEntity {
  companyGroupId: string;
  companyGroupName: string;
  projectName: string;
  projectOriginId: string;
  projectDestinationId: string;
  type: EProjectTransactionType;
  typeDescription: string;
  value: number;
  valueString: string;
  transactionDate: string;
  transactionDateString: string;
  description: string;
  userNameCreated: string;
  userNameModified: string;
  projectActive: boolean | undefined;
}

export class ProjectTransactionEntity implements IProjectTransactionEntity {
  id = "";
  active = true;
  companyGroupId = "";
  companyGroupName = "";
  projectName = "";
  projectOriginId = "";
  projectDestinationId = "";
  type = EProjectTransactionType.expense;
  typeDescription = "";
  value = 0;
  valueString = "0.00";
  transactionDate = "";
  transactionDateString = "";
  description = "";
  userNameCreated = "";
  userNameModified = "";
  projectActive = undefined;

  constructor(params?: Partial<IProjectTransactionEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IProjectTransactionEntity>) {
    return new ProjectTransactionEntity(params);
  }
}
