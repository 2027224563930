export enum ERemittanceBankCode {
  Safra = "422",
  Santander = "033",
}

export enum ERemittanceType {
  Provider = 1,
  Payroll = 2,
  Taxes = 3,
}
