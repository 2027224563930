import { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";
import { Container } from "./styles";

export function RemoveButton() {
  return <FaTimes />;
}

interface SelectedTokenProps {
  label: string;
  value?: string | number;
  children?: ReactNode;
  onRemove?(e?: unknown): void;
}

export function SelectedToken({
  children,
  value,
  label,
  onRemove,
}: SelectedTokenProps) {
  return (
    <Container>
      <span title={label}>{label}</span>
      {children && (
        <button type="button" onClick={() => onRemove?.(value)}>
          {children}
        </button>
      )}
    </Container>
  );
}

SelectedToken.defaultProps = {
  children: null,
  value: null,
  onRemove: () => null,
};
