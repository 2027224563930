import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IBulkUndoConfirmService {
  bulkUndoConfirm(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity>;
}

export interface IBulkUndoConfirmUseCase {
  bulkUndoConfirm(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity>;
}

export class BulkUndoConfirmUseCase implements IBulkUndoConfirmUseCase {
  constructor(private approveService: IBulkUndoConfirmService) {}

  bulkUndoConfirm(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity> {
    return this.approveService.bulkUndoConfirm(costCenterReportIds);
  }
}
