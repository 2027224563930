import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  display: flex;
  justify-content: end;
  bottom: 0;
  padding: 0.5rem 0;
  pointer-events: none;

  .p-splitbutton {
    transition: all 0.15s ease;

    &:hover {
      transform: translateY(-1px);
    }

    button {
      border: none;
      transform: none;
      margin-right: unset;
      font-size: 0.8333125rem;
      font-family: Arial, Helvetica, sans-serif;

      &.form-button:hover {
        transform: none;
      }

      &:enabled:hover {
        background-color: #28b87a;
      }

      &:first-child {
        margin-right: unset;
        border-radius: 0.375rem 0 0 0.375rem;
        padding-right: 1rem;

        span {
          gap: 0.5rem;
          display: flex;
          align-items: center;
        }
      }

      &:last-child {
        border-radius: 0 0.375rem 0.375rem 0;
        background-color: var(--soul-green-button);
      }

      &.p-disabled {
        opacity: 0.5;
        background-color: var(--soul-green-button) !important;
      }
    }
  }
`;
