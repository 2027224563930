import { IProjectTransactionInputEntity } from "../entities/projectTransactionInputEntity";

export interface IGetProjectTransactionService {
  getProjectTransaction(
    ProjectTransactionsId: string,
  ): Promise<IProjectTransactionInputEntity>;
}

export interface IGetProjectTransactionUseCase {
  getProjectTransaction(
    ProjectTransactionsId: string,
  ): Promise<IProjectTransactionInputEntity>;
}

export class GetProjectTransactionUseCase
  implements IGetProjectTransactionUseCase
{
  constructor(
    private getProjectTransactionsService: IGetProjectTransactionService,
  ) {}

  getProjectTransaction(
    ProjectTransactionsId: string,
  ): Promise<IProjectTransactionInputEntity> {
    return this.getProjectTransactionsService.getProjectTransaction(
      ProjectTransactionsId,
    );
  }
}
