import { InputMask } from "primereact/inputmask";
import { useMemo, useRef, useState } from "react";
import { Controller, FieldError, FormProvider, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import { ICompanyFormEntity } from "../../../domain/entities/companyFormEntity";
import { MakeCompany } from "../../../main/makeCompany";
import { OnCloseModalAction } from "../CompanyPage";
import { InvoiceParametersField } from "../InvoiceParametersField";
import { Container } from "./style";

export type CompanyFormModalProps = {
  isOpen: boolean;
  useCompany: MakeCompany;
  currentCompany?: ICompanyEntity;
  onClose(action?: OnCloseModalAction): void;
};

interface ICompanyFormModalState {
  isLoading: boolean;
  isCnpjValidating: boolean;
  cnaeList: ITypeaheadOption[];
  stateList: ITypeaheadOption[];
  municipalActivityList: ITypeaheadOption[];
}

const updateAlertOptions = {
  allowEscapeKey: false,
  allowOutsideClick: false,
  showConfirmButton: false,
  html: (
    <>
      <p
        style={{
          fontWeight: 600,
          marginTop: "0.5em",
          fontSize: "1.25rem",
          marginBottom: "0.5em",
        }}
      >
        Processando dados...
      </p>
      <p style={{ fontSize: "1.125rem" }}>
        Aguarde enquanto os dados de relatórios são atualizados.
      </p>
      <SoulSpinner
        style={{
          width: "50px",
          height: "50px",
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      />
    </>
  ),
};

export function CompanyFormModal(props: CompanyFormModalProps) {
  const { isOpen, currentCompany, onClose, useCompany } = props;

  const currentId = currentCompany?.id;

  const {
    listCnae,
    checkCnpj,
    getCompany,
    saveCompany,
    updateCompany,
    searchBrazilianCity,
    searchBrazilianState,
    listMunicipalActivity,
    updateCompanyInvoiceParameters,
  } = useCompany;

  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();
  const asyncDebounce = useDebounceTimeAsync();
  const allowedProfiles = useAllowedProfiles();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const form = useForm<ICompanyFormEntity>({
    mode: "all",
    defaultValues: {
      assumedName: "",
      cnae: null,
      cnpj: "",
      cofinsAliquot: 0,
      corporationName: "",
      csllAliquot: 0,
      hasProject: false,
      invoiceServiceDescription: "",
      irAliquot: 0,
      issAliquot: 0,
      lockReleasesUntil: "",
      municipalActivity: null,
      pisAliquot: 0,
      serviceSupplyCity: null,
      serviceSupplyState: null,
    },
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid: isFormValid, isSubmitting },
  } = form;

  const isDisabled = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financialAccounting,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles]);

  const refPrevCnpj = useRef("");

  const [state, setState] = useState<ICompanyFormModalState>({
    cnaeList: [],
    stateList: [],
    isLoading: false,
    isCnpjValidating: false,
    municipalActivityList: [],
  });

  const loadModalTypeaheadOptions = async () => {
    const cnaeList = await listCnae();
    const municipalActivityList = await listMunicipalActivity();
    const stateList = await searchBrazilianState({ search: "" });

    setState(prevState => ({
      ...prevState,
      cnaeList,
      stateList,
      municipalActivityList,
    }));
  };

  const handleModalOpening = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));

    try {
      if (!state.cnaeList.length && !state.municipalActivityList.length) {
        await loadModalTypeaheadOptions();
      }

      if (!currentId) {
        return;
      }

      const companyInfo = await getCompany(currentId);

      reset(companyInfo, { keepDefaultValues: true });

      refPrevCnpj.current = companyInfo.cnpj;
    } catch (err) {
      onClose();
    } finally {
      setState(prevState => ({ ...prevState, isLoading: false }));
      ReactTooltip.rebuild();
    }
  };

  const handleModalAfterClose = () => {
    reset();
  };

  const handleModalClose = () => {
    onClose();
  };

  /**
   * Validação assíncrona do CNPJ.
   * FIXME/REVIEW - Por alguma razão, o RHF dispara a validação no change de quaisquer
   * outros inputs, ocasionando múltiplas requisições nos eventos de blur e change.
   * Além disso, muitas requisições ocorrem até na inicialização do componente.
   * Usar a referência é um bom workaround, mas o melhor é revisar tal comportamento.
   */
  const validateCnpj = async (value: string, fieldErrors?: FieldError) => {
    const defaultCnpjLength = 14;

    if (value?.length === defaultCnpjLength && refPrevCnpj.current !== value) {
      /** Atualiza o valor do CNPJ armazenado. */
      refPrevCnpj.current = value;

      setState(prevState => ({ ...prevState, isCnpjValidating: true }));

      try {
        const { code, success } = await checkCnpj(value, currentId || "");

        if (success === false && !code) {
          return "existing";
        }

        if (success === false && code) {
          return "invalid";
        }

        return true;
      } catch (err) {
        return false;
      } finally {
        setState(prevState => ({ ...prevState, isCnpjValidating: false }));
      }
    }

    if (!value || (value?.length < 14 && refPrevCnpj.current !== value)) {
      refPrevCnpj.current = value;
      return "required";
    }

    if (fieldErrors) {
      return fieldErrors.message;
    }

    return true;
  };

  const onValid = async (values: ICompanyFormEntity) => {
    let companyId = currentId;

    const {
      assumedName,
      cnpj,
      corporationName,
      hasProject,
      lockReleasesUntil,
      ...invoiceParameters
    } = values;

    const formPayload = {
      id: companyId || "",
      assumedName,
      cnpj,
      corporationName,
      hasProject: hasProject || false,
      lockReleasesUntil,
      active: currentCompany?.active || true,
      companyGroupId: currentCompanyGroup.id,
      companyGroupName: currentCompanyGroup.name,
    };

    try {
      const actionMessage = companyId ? "atualizada" : "cadastrada";

      if (!companyId) {
        const newCompany = await saveCompany(formPayload);
        companyId = newCompany.id;
      } else {
        dialog.fire(updateAlertOptions);
        await updateCompany(formPayload);
        await asyncDebounce(4000);
      }

      const invoiceParametersFormValues = {
        ...invoiceParameters,
        id: companyId,
      };

      await updateCompanyInvoiceParameters(invoiceParametersFormValues);

      dialog.fire({
        icon: "success",
        titleText: "Feito!",
        html: `Empresa ${actionMessage} com sucesso!`,
      });

      onClose(OnCloseModalAction.Refresh);
    } finally {
      Promise.resolve();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      onRequestClose={handleModalClose}
      shouldCloseOnEsc={!state.isLoading}
      onAfterClose={handleModalAfterClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!state.isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{currentId ? "Editar Empresa" : "Nova Empresa"}</h4>
          {!state.isLoading ? (
            <button
              type="button"
              id="btn-crud-cross"
              data-testid="btn-crud-cross"
              className="react-modal-close"
              onClick={handleModalClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <div className="crud-header">
              <p>
                Este registro {currentId ? "está" : "será"} vinculado ao grupo
                de empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <form id="company-crud-form" onSubmit={handleSubmit(onValid)}>
                <div className="form-container">
                  <p className="form-header">Dados da Empresa</p>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span className="label-with-spinner">
                        CNPJ
                        {state.isCnpjValidating ? (
                          <i className="pi pi-spin pi-spinner" />
                        ) : null}
                      </span>
                      <Controller
                        name="cnpj"
                        control={control}
                        rules={{
                          validate: v => validateCnpj(v, errors?.cnpj),
                        }}
                        render={({ field }) => {
                          return (
                            <InputMask
                              unmask
                              {...field}
                              id="txt-cnpj"
                              placeholder="CNPJ"
                              disabled={isDisabled}
                              data-testid="txt-cnpj"
                              mask="99.999.999/9999-99"
                              className={errors?.cnpj ? "isInvalid" : ""}
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        condition={errors.cnpj?.message === "required"}
                        message="Este campo é obrigatório"
                      />
                      <InvalidFeedback
                        condition={errors.cnpj?.message === "invalid"}
                        message="Insira um CNPJ válido"
                      />
                      <InvalidFeedback
                        condition={errors.cnpj?.message === "existing"}
                        message="CNPJ já cadastrado"
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Razão Social</span>
                      <input
                        disabled={isDisabled}
                        id="txt-corporationName"
                        placeholder="Razão Social"
                        data-testid="txt-corporationName"
                        className={errors?.corporationName ? "isInvalid" : ""}
                        {...register("corporationName", { required: true })}
                      />
                      <InvalidFeedback
                        condition={errors.corporationName?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Nome Fantasia</span>
                      <input
                        id="txt-assumedName"
                        disabled={isDisabled}
                        placeholder="Nome Fantasia"
                        data-testid="txt-assumedName"
                        className={errors?.assumedName ? "isInvalid" : ""}
                        {...register("assumedName", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                      <InvalidFeedback
                        condition={errors.assumedName?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                      <InvalidFeedback
                        condition={errors.assumedName?.type === "maxLength"}
                        message="Máximo de caracteres excedido"
                      />
                    </label>
                  </div>
                </div>
                <FormProvider {...form}>
                  <InvoiceParametersField
                    isDisabled={isDisabled}
                    cnaeList={state.cnaeList}
                    stateList={state.stateList}
                    searchBrazilianCity={searchBrazilianCity}
                    municipalActivityList={state.municipalActivityList}
                  />
                </FormProvider>
                <div className="form-container">
                  <div className="form-row">
                    <label className="col-4 form-control">
                      <span>Travar lançamentos até</span>
                      <Controller
                        control={control}
                        name="lockReleasesUntil"
                        rules={{
                          required: true,
                          validate: {
                            invalidDate: v => !dateValidator(v),
                          },
                        }}
                        render={({ field }) => (
                          <InputMask
                            {...field}
                            unmask
                            mask="99/99/9999"
                            disabled={isDisabled}
                            id="txt-lockReleasesUntil"
                            placeholder="Insira uma data"
                            data-testid="txt-lockReleasesUntil"
                            className={
                              errors?.lockReleasesUntil ? "isInvalid" : ""
                            }
                          />
                        )}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={
                          errors.lockReleasesUntil?.type === "required"
                        }
                      />
                      <InvalidFeedback
                        message="Preencha uma data válida"
                        condition={
                          errors.lockReleasesUntil?.type === "invalidDate"
                        }
                      />
                    </label>
                    <label
                      className="col-2 form-control"
                      data-tip-disable={!currentCompany?.hasTaxes}
                      data-tip="Não é possível desmarcar,<br />pois essa empresa possui<br />taxa(s) automática(s)<br />cadastrada(s)"
                    >
                      <span>Possui projeto?</span>
                      <Controller
                        name="hasProject"
                        control={control}
                        render={({ field }) => {
                          const checkboxField = {
                            ...field,
                            checked: !!field?.value,
                            value: field.value ? "true" : "false",
                          };
                          return (
                            <Checkbox
                              {...checkboxField}
                              label="Sim"
                              id="chk-hasProject"
                              data-testid="chk-hasProject"
                              disabled={currentCompany?.hasTaxes || isDisabled}
                            />
                          );
                        }}
                      />
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-crud-close"
                onClick={handleModalClose}
                data-testid="btn-crud-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                form="company-crud-form"
                data-testid="btn-crud-save"
                disabled={state.isCnpjValidating || isSubmitting || isDisabled}
                className={`form-button ${
                  isFormValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
