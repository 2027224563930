import { ChangeEvent } from "react";
import { FaSearch } from "react-icons/fa";
import { Container } from "./styles";

interface InputSearchProps {
  id?: string;
  "data-testid"?: string;
  value: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export function InputSearch({
  id,
  "data-testid": dataTestId,
  value,
  onChange,
}: InputSearchProps) {
  return (
    <Container className="form-container">
      <div className="form-row">
        <div className="form-control">
          <span className="prepend">
            <FaSearch />
          </span>
          <input
            className="src-fld"
            id={id}
            data-testid={dataTestId}
            type="text"
            placeholder="Pesquisar"
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </Container>
  );
}
