import { DecryptService } from "../../core/data/services/decryptService";
import { EncryptService } from "../../core/data/services/encryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { IsLoggedInService } from "../data/services/isLoggedInService";
import {
  ClearLocalStorageUseCase,
  IClearLocalStorageUseCase,
} from "../domain/usecases/clearLocalStorageUseCase";
import {
  DecryptUseCase,
  IDecryptUseCase,
} from "../domain/usecases/decryptUseCase";
import {
  EncryptUseCase,
  IEncryptUseCase,
} from "../domain/usecases/encryptUseCase";
import {
  IIsLoggedInUseCase,
  IsLoggedInUseCase,
} from "../domain/usecases/isLoggedInUseCase";

export type MakeAuth = IIsLoggedInUseCase &
  IDecryptUseCase &
  IEncryptUseCase &
  IClearLocalStorageUseCase;

export function makeAuth(): MakeAuth {
  const decryptService = new DecryptService();
  const decryptUseCase = new DecryptUseCase(decryptService);

  const getUserLocalService = new GetUserLocalService(decryptService);
  const isLoggedInService = new IsLoggedInService(getUserLocalService);
  const isLoggedInUseCase = new IsLoggedInUseCase(isLoggedInService);

  const encryptService = new EncryptService();
  const encryptUseCase = new EncryptUseCase(encryptService);

  const clearLocalStorageUseCase = new ClearLocalStorageUseCase();

  return {
    isLoggedIn: () => isLoggedInUseCase.isLoggedIn(),
    decrypt: cyphertext => decryptUseCase.decrypt(cyphertext),
    encrypt: data => encryptUseCase.encrypt(data),
    clearLocalStorage() {
      clearLocalStorageUseCase.clearLocalStorage();
    },
  };
}
