import styled from "styled-components";

export const Container = styled.div`
  --transition-time: 0.12s;

  button {
    position: relative;
    border: none;
    background: white;
    padding: 0.5em;
    border-radius: 0.5rem;
    display: flex;
    box-shadow: 0px 2px 5px 1px #00000022;
    bottom: 0;
    transition: bottom var(--transition-time) ease-out,
      background var(--transition-time) ease-out,
      box-shadow var(--transition-time) ease-out;

    &.conciliated {
      background: var(--soul-green-button);
      box-shadow: 0px 2px 5px 1px #2dce898a;

      svg {
        fill: white;
      }
    }

    &:hover {
      bottom: 0.125rem;
    }

    & + button {
      margin-top: 0.25rem;
    }

    svg {
      fill: var(--soul-color01);
    }
  }
`;
