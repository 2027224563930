import { isValid, parse } from "date-fns";
import { InputMaskChangeParams } from "primereact/inputmask";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useAccountsReceivablePage } from "./useAccountsReceivablePage";

enum EInvalidFeedback {
  None = "",
  Required = "Este campo é obrigatório",
  InvalidFormat = "Formato de data inválido",
}

interface ITerminateModalState {
  search: string;
  submitting: boolean;
  progressModal: {
    isOpen: boolean;
    loaded: number;
    total: number;
  };
  terminationDate: string;
  isInvalid: boolean;
  invalidFeedback: EInvalidFeedback;
}

export function useAccountReceivableTerminateModal() {
  const [state, setState] = useState<ITerminateModalState>({
    search: "",
    submitting: false,
    progressModal: {
      isOpen: false,
      loaded: 0,
      total: 0,
    },
    terminationDate: "",
    isInvalid: false,
    invalidFeedback: EInvalidFeedback.None,
  });

  const { useAccountsReceivable, handleTerminateModalClose, reload, ...rest } =
    useAccountsReceivablePage();

  const { contextMenuData, terminateModalOpen } = rest.state;

  const { terminateAccountReceivableParcel, fetchRpaParamOptions } =
    useAccountsReceivable;

  const dialog = useSoulDialog();

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar os estados da modal
   */
  const handleModalAfterClose = () => {
    setState({
      search: "",
      submitting: false,
      progressModal: {
        isOpen: false,
        loaded: 0,
        total: 0,
      },
      terminationDate: "",
      isInvalid: false,
      invalidFeedback: EInvalidFeedback.None,
    });
  };

  /** Lida com o evento de click do botao fechar da modal */
  const handleCloseButtonOnClick = useCallback(() => {
    handleTerminateModalClose();
  }, [handleTerminateModalClose]);

  /**
   * Baixa a conta a pagar e atualiza os anexos na api. Realiza o post dos
   * arquivos e dos novos dados dos anexos que foram atualizados/removidos
   */
  const terminateParcel = async (
    accountReceivableId: string,
    terminationDate: string,
  ) => {
    setState(prevState => ({
      ...prevState,
      submitting: true,
      progressModal: {
        ...prevState.progressModal,
        isOpen: true,
      },
    }));

    try {
      // baixa a conta a pagar selecionada
      await terminateAccountReceivableParcel({
        accountReceivableId,
        terminationDate,
      });

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento baixado com sucesso!",
      });

      setState(prevState => ({
        ...prevState,
        submitting: false,
        progressModal: {
          ...prevState.progressModal,
          isOpen: false,
        },
      }));

      handleTerminateModalClose();
    } catch {
      setState(prevState => ({
        ...prevState,
        submitting: false,
        progressModal: {
          ...prevState.progressModal,
          isOpen: false,
        },
      }));
    }
  };

  /** Lida com a validacao do campo de data */
  const validateTerminationDate = (value: string) => {
    let isInvalid = false;
    let invalidFeedback = EInvalidFeedback.None;

    if (value === "") {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.Required;

      return { isInvalid, invalidFeedback };
    }

    const dtTerminationDate = parse(
      value.replace(/\D/g, ""),
      "ddMMyyyy",
      new Date(),
    );

    if (value.length < 8 || !isValid(dtTerminationDate)) {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.InvalidFormat;

      return { isInvalid, invalidFeedback };
    }

    return { isInvalid: false, invalidFeedback: EInvalidFeedback.None };
  };

  /** Lida como submit dos dados da modal */
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const accountReceivableParcelId = contextMenuData?.id;

    if (!accountReceivableParcelId) {
      return;
    }

    const { terminationDate } = state;

    const { isInvalid, invalidFeedback } =
      validateTerminationDate(terminationDate);

    const submitting = !isInvalid;

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
      submitting,
    }));

    if (isInvalid) {
      return;
    }

    try {
      await terminateParcel(accountReceivableParcelId, terminationDate);

      reload();
    } catch {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));
    }
  };

  /** Lida com o evento de troca de valor do campo de data */
  const handleDateChange = (event: InputMaskChangeParams) => {
    const terminationDate = event.target.value || "";

    const { isInvalid, invalidFeedback } =
      validateTerminationDate(terminationDate);

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
      terminationDate,
    }));
  };

  /** Lida com o evento de troca do valor do campo de pesquisa da grid de anexos */
  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    setState(prevState => ({
      ...prevState,
      search,
    }));
  };

  /**
   * Decide se o form esta invalido ou nao, usado
   * para determinar a aparencia do botao submit
   */
  const isInvalid = useMemo(() => {
    return state.isInvalid || state.terminationDate === "";
  }, [state.isInvalid, state.terminationDate]);

  return {
    terminateModalOpen,
    handleCloseButtonOnClick,
    state,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    handleInputSearchChange,
    isInvalid,
    fetchRpaParamOptions,
  };
}
