import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdatePaymentAccountContract } from "../../domain/contracts/updatePaymentAccountContract";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";
import { IPaymentAccountFormEntity } from "../../domain/entities/paymentAccountFormEntity";

export class UpdatePaymentAccountService
  implements IUpdatePaymentAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updatePaymentAccount(payload: IPaymentAccountFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PaymentAccounts";

    return this.api.put<IPaymentAccountEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
