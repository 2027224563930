import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IListFeeExpirationTypesContract } from "../../domain/contracts/listFeeExpirationTypesContract";
import { IFeeExpirationType } from "../../domain/entities/feeExpirationTypeEnum";

export class ListFeeExpirationTypesService
  implements IListFeeExpirationTypesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listFeeExpirationTypes() {
    const userEntity = this.getUserLocalService.get();
    const url = "/Enums/FeeExpirationType";

    return this.api.get<IFeeExpirationType[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
