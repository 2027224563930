import {
  MultiSelect,
  MultiSelectChangeParams,
  MultiSelectPanelHeaderTemplateParams,
} from "primereact/multiselect";
import { useCallback, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import { ISimpleColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPaymentAccountReportEntity } from "../../../domain/entities/paymentAccountReportEntity";
import { MakePaymentAccountReport } from "../../../main/makePaymentAccountReport";
import { ExportingAttachmentsModalContent } from "../ExportingAttachmentsModalContent";
import { IFetchDataProps } from "../PaymentAccountReportPage";
import { Container, FilterContainer } from "./style";

export interface ExportAttachmentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  companyGroupId: string;
  tableState: IFetchDataProps;
  usePaymentAccountReport: MakePaymentAccountReport;
  selectedData: boolean | IPaymentAccountReportEntity[];
}

export function ExportAttachmentsModal(props: ExportAttachmentsModalProps) {
  const {
    isOpen,
    onClose,
    tableState,
    selectedData,
    companyGroupId,
    usePaymentAccountReport,
  } = props;

  const {
    getAttachmentTypes,
    exportAllAttachments,
    exportSelectedAttachments,
  } = usePaymentAccountReport;

  const dialog = useSoulDialog();
  const { advGeneratePayload } = useTables();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [attachmentTypes, setAttachmentTypes] = useState<IEnum<string>[]>([]);

  const handleModalOpening = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getAttachmentTypes();
      setSelectedTypes(response.map(t => t.key));
      setAttachmentTypes(response);
    } catch {
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [getAttachmentTypes, onClose]);

  /**
   * Lida com a mudança no campo de tipos de anexos.
   */
  const handleChange = useCallback((e: MultiSelectChangeParams) => {
    setSelectedTypes(e.value);
  }, []);

  /**
   * Constrói o cabeçalho do painel de opções.
   */
  const headerTemplate = useCallback(
    (params: MultiSelectPanelHeaderTemplateParams) => {
      const { checkboxElement, filterElement } = params;

      return (
        <FilterContainer className="form-control">
          {checkboxElement}
          {filterElement}
        </FilterContainer>
      );
    },
    [],
  );

  const attachmentsService = useCallback(() => {
    if (typeof selectedData !== "boolean") {
      return exportSelectedAttachments(selectedData, selectedTypes);
    }

    const {
      filtersValue,
      pfsEventEntity,
      uiSelectedColumns,
      orderedColumnNames,
    } = tableState;

    const selectedCols = orderedColumnNames
      .map(fieldName => {
        return uiSelectedColumns.find(uiSelCol => uiSelCol.field === fieldName);
      })
      .filter((col): col is IAdvTableColumn => col !== undefined);

    const payload = advGeneratePayload(
      pfsEventEntity,
      selectedCols as ISimpleColumn[],
    );

    return exportAllAttachments(
      companyGroupId,
      payload,
      filtersValue,
      selectedTypes,
    );
  }, [
    advGeneratePayload,
    companyGroupId,
    exportAllAttachments,
    exportSelectedAttachments,
    selectedData,
    selectedTypes,
    tableState,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!selectedTypes.length) return;

    onClose();

    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingAttachmentsModalContent />,
    });

    try {
      const response = await attachmentsService();

      if (response.code === "err-max-attachment") {
        dialog.fire({
          icon: "error",
          title: "Opa!",
          width: "auto",
          allowEscapeKey: true,
          allowOutsideClick: true,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Fechar",
          html: (
            <div style={{ fontSize: "1rem" }}>
              <p>
                Você <strong>excedeu</strong> o limite de <strong>1200</strong>{" "}
                anexos por exportação em lote.
              </p>
              <br />
              <p>
                <strong>Você selecionou</strong>: 1 arquivos encontrados e
                enviados anexos
              </p>
              <br />
              <p>
                <strong>Desmarque</strong> alguns lançamentos e{" "}
                <strong>tente novamente!</strong>
              </p>
            </div>
          ),
        });
        return;
      }

      dialog.update({
        icon: "success",
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Fechar",
        html: <ExportingAttachmentsModalContent message={response.message} />,
      });
    } catch (err) {
      dialog.close();
    }
  }, [selectedTypes.length, onClose, dialog, attachmentsService]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isLoading}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Filtrar Anexo(s)</h4>
          {!isLoading ? (
            <button
              type="button"
              onClick={onClose}
              id="btn-attachment-cross"
              className="react-modal-close"
              data-testid="btn-attachment-cross"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="react-modal-body">
              <form className="form-container">
                <div className="form-row">
                  <label className="col-12 form-control">
                    <span>Tipo de Anexo</span>
                    <MultiSelect
                      id="sel-attachment-types"
                      filter
                      value={selectedTypes}
                      appendTo="self"
                      showClear={false}
                      optionValue="key"
                      optionLabel="value"
                      onChange={handleChange}
                      selectedItemsLabel="Todos"
                      options={attachmentTypes}
                      placeholder="Tipo do anexo"
                      panelClassName="multiselect-panel"
                      data-testid="sel-attachment-types"
                      panelHeaderTemplate={headerTemplate}
                      filterPlaceholder="Pesquise uma opção"
                      emptyFilterMessage="Nenhum tipo encontrado"
                      maxSelectedLabels={attachmentTypes.length - 1}
                      className={`attachments-multiselect ${
                        !selectedTypes.length ? "isInvalid" : ""
                      }`}
                    />
                    <InvalidFeedback
                      condition={!selectedTypes.length}
                      message="Selecione ao menos um tipo de anexo"
                    />
                  </label>
                </div>
              </form>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-attachment-close"
                className="form-button red-bkg"
                data-testid="btn-attachment-close"
              >
                Fechar
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                id="btn-attachment-confirm"
                data-testid="btn-attachment-confirm"
                className={`form-button ${
                  selectedTypes.length ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Confirmar
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
