import { FilterMatchMode } from "primereact/api";
import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableProps } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import {
  PaginatorCurrentPageReportOptions,
  PaginatorRowsPerPageDropdownOptions,
  PaginatorTemplateOptions,
} from "primereact/paginator";
import { LegacyRef, useCallback, useMemo } from "react";

import { Container } from "./style";

export interface ClientSoulTableProps<T> extends DataTableProps {
  data: T[];
  selectable?: boolean;
  columns: ColumnProps[];
  dataTableRef?: LegacyRef<DataTable>;
}

export function ClientSoulTable<T>(props: ClientSoulTableProps<T>) {
  const {
    data,
    rows,
    columns,
    dataTableRef,
    globalFilter,
    rowHover = false,
    paginator = true,
    selectable = false,
    selectionMode = "multiple",
    responsiveLayout = "scroll",
    rowsPerPageOptions = [10, 25, 50],
    ...rest
  } = props;

  const definedRows = paginator ? rows ?? rowsPerPageOptions[0] : rows;
  const columnSelectionMode = ["multiple", "checkbox"].includes(selectionMode)
    ? "multiple"
    : "single";

  const filters = useMemo(
    () => ({
      global: { value: globalFilter, matchMode: FilterMatchMode.CONTAINS },
    }),
    [globalFilter],
  );

  const currentPageReportTemplate = useCallback(
    ({ currentPage, totalPages }: PaginatorCurrentPageReportOptions) => {
      const realCurrentPage = totalPages ? currentPage : 1;

      return (
        <div className="current-page-report">
          Exibindo página {realCurrentPage} de {totalPages || 1}
        </div>
      );
    },
    [],
  );

  const rowsPerPageDropdownTemplate = useCallback(
    ({ options, onChange, value }: PaginatorRowsPerPageDropdownOptions) => {
      return (
        <div className="rows-per-page-dropdown-wrapper">
          Mostrar
          <Dropdown options={options} onChange={onChange} value={value} />
        </div>
      );
    },
    [],
  );

  const paginatorTemplate: PaginatorTemplateOptions = useMemo(
    () => ({
      layout:
        "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      PageLinks: "",
      PrevPageLink: "",
      LastPageLink: "",
      NextPageLink: "",
      FirstPageLink: "",
      JumpToPageInput: "",
      CurrentPageReport: currentPageReportTemplate,
      RowsPerPageDropdown: rowsPerPageDropdownTemplate,
    }),
    [currentPageReportTemplate, rowsPerPageDropdownTemplate],
  );

  return (
    <Container>
      <DataTable
        {...rest}
        value={data}
        filters={filters}
        rows={definedRows}
        ref={dataTableRef}
        rowHover={rowHover}
        paginator={paginator}
        responsiveLayout={responsiveLayout}
        paginatorTemplate={paginatorTemplate}
        rowsPerPageOptions={rowsPerPageOptions}
        selectionMode={selectable ? selectionMode : undefined}
      >
        {selectable ? (
          <Column
            headerStyle={{ width: "10px" }}
            selectionMode={columnSelectionMode}
          />
        ) : null}
        {columns.map(column => (
          <Column {...column} key={column.field} />
        ))}
      </DataTable>
    </Container>
  );
}
