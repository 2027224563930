import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountPayableFeeEntity } from "../../domain/entities/accountPayableFeeEntity";
import { IAccountPayableFeeFormInputEntity } from "../../domain/entities/accountPayableFeeFormInputEntity";
import { FeeErrorList } from "../components/AccountPayableFeeFormModal/styles";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

interface IAccountPayableFeeFormModalState {
  loading: boolean;
  submitting: boolean;
  deletingFees: boolean;
  fees: IAccountPayableFeeEntity[];
  /** Armazena nomes de impostos com contas já geradas em algum status específico. */
  generatedFeesStatus: string[];
  isCanceledAndHasFeeRetention: boolean;
}

export function useAccountPayableFeeFormModal() {
  const {
    useAccountsPayable,
    closeAccountPayableFeeFormModal,
    reload,
    ...rest
  } = useAccountsPayablePage();

  const { listAccountPayableFees, deleteGeneratedFees, generateFeeRetention } =
    useAccountsPayable;

  const { feeModalOpen, contextMenuData } = rest.state;

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const companyGroupId = currentCompanyGroup.id;

  const [state, setState] = useState<IAccountPayableFeeFormModalState>({
    loading: true,
    submitting: false,
    deletingFees: false,
    fees: [],
    generatedFeesStatus: [],
    isCanceledAndHasFeeRetention: false,
  });

  const form = useForm<IAccountPayableFeeFormInputEntity>({
    mode: "all",
    defaultValues: {
      fees: [],
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = form;

  const dialog = useSoulDialog();

  /**
   * Obtem a lista de impostos de conta de pagamento, responsavel
   * pela maior parte dos estados inciais da modal
   */
  const fetchAccountPayableFees = async () => {
    const accountPayableListItem = contextMenuData;

    if (!accountPayableListItem) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const {
        accountPayableList,
        paidOrCanceledAccountPayableFee,
        isCanceledAndHasFeeRetention,
      } = await listAccountPayableFees(companyGroupId, accountPayableListItem);

      reset({
        fees: accountPayableList,
      });

      setState(prevState => ({
        ...prevState,
        loading: false,
        fees: accountPayableList,
        generatedFeesStatus: paidOrCanceledAccountPayableFee,
        isCanceledAndHasFeeRetention,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  /**
   * Lida com o evento de click no botao limpar impostos.
   * Responsável por limpar os impostos existentes para a
   * conta a pagar selecioanda. Caso haja impostos pagos ou
   * cancelados exibe uma dialog informando quais sao
   */
  const handleClearFeesButtonClick = async () => {
    let message = (
      <>
        Todos os lançamentos dos impostos serão <strong>excluídos</strong>.
        <br />
        <br />
        Você tem <strong>certeza</strong> que deseja <strong>continuar</strong>?
      </>
    );

    const hasFeeGenerated = state.generatedFeesStatus.length;

    if (hasFeeGenerated) {
      message = (
        <>
          Existem lançamentos baixados e/ou cancelados!
          <br />
          <br />
          Você tem <strong>certeza</strong> que deseja{" "}
          <strong>excluí-los</strong>?
          {hasFeeGenerated && (
            <FeeErrorList>
              {state.generatedFeesStatus.map((feeGeneratedInfo, index) => {
                const key = `${feeGeneratedInfo}${index}`;

                return (
                  <div
                    key={key}
                    className="card card-err text-danger text-left"
                  >
                    {feeGeneratedInfo}
                  </div>
                );
              })}
            </FeeErrorList>
          )}
        </>
      );
    }

    const result = await dialog.fire({
      icon: "warning",
      title: "Atenção!",
      cancelButtonText: "Não",
      showCancelButton: true,
      confirmButtonText: "Sim",
      html: message,
    });

    if (result.dismiss) {
      return;
    }

    const accountPayableListItem = contextMenuData;
    const accountPayableId = accountPayableListItem?.id;

    if (!accountPayableId) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      deletingFees: true,
    }));

    try {
      await deleteGeneratedFees(accountPayableId);

      await dialog.fire({
        icon: "success",
        title: "Feito",
        text: "Lançamentos excluídos com sucesso.",
      });

      const { isCanceledAndHasFeeRetention } = state;

      if (isCanceledAndHasFeeRetention) {
        closeAccountPayableFeeFormModal();
        return;
      }

      reset();
      fetchAccountPayableFees();
    } finally {
      setState(prevState => ({
        ...prevState,
        deletingFees: false,
      }));
    }
  };

  /** Lida com o evento de fechament da modal */
  const handleRequestClose = () => {
    closeAccountPayableFeeFormModal();
  };

  /** Realiza o submit dos dados inputados no form para a api */
  const submit = async (formData: IAccountPayableFeeFormInputEntity) => {
    const { fees } = formData;

    const accountPayableListItem = contextMenuData;
    const accountPayableId = accountPayableListItem?.id;

    if (!accountPayableId) {
      return;
    }

    const result = await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      html: (
        <>
          Os impostos serão gerados em Contas a Pagar.
          <br />
          <br />
          Deseja prosseguir?
        </>
      ),
      showCancelButton: true,
      cancelButtonText: "Não, gerar depois",
      confirmButtonText: "Sim",
    });

    const shouldGenerateFeeRetention = result.isConfirmed;

    setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    try {
      await generateFeeRetention({
        fees,
        accountPayableId,
        shouldGenerateFeeRetention,
      });

      let message = "Impostos atualizados com sucesso.";

      if (shouldGenerateFeeRetention) {
        message = "Lançamentos gerados com sucesso.";
      }

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: message,
      });

      closeAccountPayableFeeFormModal();
      reload();
    } finally {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));
    }
  };

  /**
   * Lida com o evento de abertura da modal, responsavel por
   * fazer as requests e preparar os estados iniciais da modal
   */
  const handleAfterOpen = () => {
    fetchAccountPayableFees();
  };

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar estados iniciais da modal
   */
  const handleAfterClose = () => {
    setState({
      loading: true,
      submitting: false,
      deletingFees: false,
      fees: [],
      generatedFeesStatus: [],
      isCanceledAndHasFeeRetention: false,
    });
  };

  /** Responsavel por formatar qualquer valor numerico para BRL */
  const format = useMemo(() => {
    const fmt = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return fmt.format;
  }, []);

  /** Flag responsável por dizer se o valor de todos os impostos está vazio */
  const allFeesValueEmpty = useMemo(() => {
    return state.fees.every(fee => !fee.value);
  }, [state.fees]);

  /**
   * Flag responsável por decidir se o botao de
   * limpar impostos deve estar desabilitado ou nao
   */
  const disableClearButton = useMemo(() => {
    return allFeesValueEmpty || state.deletingFees || state.submitting;
  }, [allFeesValueEmpty, state.deletingFees, state.submitting]);

  /** Flag responsavel por dizer se nenhum dos impostos é editável ou se tem impostos editaveis */
  const noFeeIsEditable = useMemo(
    () => state.fees.every(fee => !fee.editable),
    [state.fees],
  );

  return {
    feeModalOpen,
    handleRequestClose,
    handleAfterOpen,
    handleAfterClose,
    state,
    form,
    handleSubmit,
    submit,
    format,
    disableClearButton,
    handleClearFeesButtonClick,
    isValid,
    noFeeIsEditable,
  };
}
