import { ContextMenu } from "primereact/contextmenu";
import { useAccountsReceivableContextMenu } from "../../hooks/useAccountsReceivableContextMenu";

export function AccountsReceivablePageContextMenu() {
  const { menuRef, menuModel } = useAccountsReceivableContextMenu();

  return (
    <ContextMenu model={menuModel} ref={menuRef} style={{ width: "230px" }} />
  );
}
