import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGenerateAttachmentVariableAdditionalContract } from "../../domain/contracts/generateAttachmentVariableAdditionalContract";
import { IAccountPayableListItemEntity } from "../../domain/entities/accountPayableListItemEntity";
import { IGenerateAttachmentVariableAdditionalModel } from "../models/generateAttachmentVariableAdditionalModel";

export class GenerateAttachmentVariableAdditionalService
  implements IGenerateAttachmentVariableAdditionalContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateAttachmentVariableAdditional(
    accountPayableListItem: IAccountPayableListItemEntity,
  ): Promise<File> {
    const payload: IGenerateAttachmentVariableAdditionalModel = {
      projectId: accountPayableListItem.projectId || "",
      providerId: accountPayableListItem.providerId || "",
      valueMoney: accountPayableListItem.value,
    };

    const url = `/Downloads/GenerateAttachmentVariableAdditional`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });

    if (response) {
      const file = this.generateFile(response);

      return file;
    }

    return Promise.reject();
  }

  private generateFile(httpResponse: HttpResponseType): File {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    return new File([data], fileName, {
      type: "application/pdf",
    });
  }
}
