import styled from "styled-components";

export const Container = styled.div`
  --highlight-color: #007ad9;

  width: 16rem;

  .filter-display {
    display: flex;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }

  .filter-header {
    padding: 0.375rem;
    border: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    input {
      padding: 0.25rem 0.5rem;
    }

    input + button {
      padding: 0.25rem;
      width: auto;
    }
  }

  .p-listbox {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;

    .p-listbox-header {
      .p-listbox-filter {
        padding-right: 2.3rem;
      }
    }

    .p-listbox-list {
      padding: 0;

      .p-listbox-item {
        padding: 0.25rem 0.5rem;

        & + .p-listbox-item {
          margin-top: 1px;
        }

        &.p-highlight {
          background: var(--highlight-color);
          color: white;
        }

        .item-wrapper {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;

          .p-checkbox {
            margin-right: 0.5em;

            .p-checkbox-box.p-highlight {
              background: var(--highlight-color);
              border-color: white;
              border-width: 1px;
            }
          }
        }
      }
    }

    .app-listbox-list {
      max-height: 200px;
    }
  }
`;
