import { useCallback, useMemo } from "react";
import { FaEdit, FaLink, FaList } from "react-icons/fa";
import { toast } from "react-toastify";
import { IRelationshipFilterOption } from "../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { ISearchParams } from "../../../core/domain/entities/searchParams";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";
import { CostCenterCustomToggle } from "../components/CostCenterCustomToggle";
import { CostCenterPageModalType } from "../components/CostCenterPage";

interface UseCostCenterGridProps {
  handleToogleCostCenter(payload: ICostCenterEntity): Promise<void>;
  handleOpenModal(info: ICostCenterEntity, type: CostCenterPageModalType): void;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export function useCostCenterGrid({
  handleOpenModal,
  searchCostCenter,
  handleToogleCostCenter,
}: UseCostCenterGridProps) {
  const allowedProfiles = useAllowedProfiles();

  const canDeleteCostCenter = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  const activeBodyTemplate = useCallback(
    (costCenter: ICostCenterEntity, { rowIndex }) => {
      return (
        <CostCenterCustomToggle
          index={rowIndex}
          costCenter={costCenter}
          readonly={!canDeleteCostCenter}
          searchCostCenter={searchCostCenter}
          handleToggle={handleToogleCostCenter}
        />
      );
    },
    [canDeleteCostCenter, handleToogleCostCenter, searchCostCenter],
  );

  const editBodyTemplate = useCallback(
    (costCenter: ICostCenterEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            type="button"
            data-tip="Vincular usuários ao<br />Centro de Custo"
            className="outline-button tool"
            id={`btn-manage-${rowIndex}`}
            data-testid={`btn-manage-${rowIndex}`}
            onClick={() => {
              handleOpenModal(costCenter, CostCenterPageModalType.Users);
            }}
          >
            <FaList />
          </button>
          <button
            type="button"
            id={`btn-edit-${rowIndex}`}
            className="outline-button tool"
            data-tip="Editar Centro de Custo"
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              handleOpenModal(costCenter, CostCenterPageModalType.Crud);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            id={`btn-copy-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-copy-${rowIndex}`}
            data-tip="Copiar ID do Centro de Custo"
            onClick={() => {
              navigator.clipboard.writeText(costCenter.id);
              toast.success(
                "O ID do Centro de Custo foi copiado para sua área de transferência",
                {
                  autoClose: 2000,
                },
              );
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [handleOpenModal],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        selectionMode: "multiple",
        style: {
          width: "1%",
          paddingLeft: "1rem",
        },
      },
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        searchable: false,
        orderable: false,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "acronym",
        header: "Sigla",
        searchable: true,
        orderable: true,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "description",
        header: "Descrição",
        searchable: true,
        orderable: true,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "hasManagerLink",
        header: "Possui vínculo?",
        searchable: true,
        orderable: true,
        bodyTemplate: (costCenter: ICostCenterEntity) => {
          return costCenter.hasManagerLink ? "SIM" : "NÃO";
        },
        style: {
          width: "16.5%",
        },
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
        style: {
          width: "16.5%",
        },
      },
      {
        field: "active",
        header: "Ativo",
        orderable: false,
        searchable: false,
        bodyTemplate: activeBodyTemplate,
        style: {
          width: "16.5%",
        },
      },
      /* Abaixo são as colunas necessárias para ativação e desativação de um Centro de Custo */
      {
        field: "deleteReason",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "deleteUserName",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "deleteDate",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "costCenterReplacedId",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "costCenterReplacedName",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "companyGroupId",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "openingBalance",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "responsible",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "lockReleasesUntil",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        hidden: true,
        field: "activeDescription",
      },
      {
        hidden: true,
        orderable: true,
        searchable: true,
        field: "dateCreated",
      },
      {
        hidden: true,
        orderable: true,
        searchable: true,
        field: "hasmanagerlinkdescription",
      },
      {
        hidden: true,
        orderable: true,
        searchable: true,
        field: "managerName",
      },
      {
        hidden: true,
        orderable: true,
        searchable: true,
        field: "managerEmail",
      },
      {
        hidden: true,
        field: "userid",
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
