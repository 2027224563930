import { Control, Controller } from "react-hook-form";
import { InputPercentage } from "../../../../../../../../core/presentation/components/InputPercentage";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { IEditAssessmentForm } from "../../../EditAssessmentFormModal";
import { FieldWrapper } from "../../../FieldWrapper";

interface IPercentageFieldProps {
  onChange(value: number): void;
  control: Control<IEditAssessmentForm>;
}

export function PercentageField(props: IPercentageFieldProps) {
  const { onChange, control } = props;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      control={control}
      name="percentageMask"
      render={({ field, fieldState }) => {
        const handleChange = (value: number | null) => {
          onChange(value || 0);
          field.onChange(value || 0);
          field.onBlur();
        };
        return (
          <label className="col-6 form-control">
            <span>Porcentagem (%)</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputPercentage
                {...field}
                precision={2}
                id="txt-percentage"
                onChange={handleChange}
                className="numeric-field"
                placeholder="Porcentagem"
                value={Number(field.value)}
                data-testid="txt-percentage"
              />
            </FieldWrapper>
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
