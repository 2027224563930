import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IDisapproveService {
  disapprove(id: string): Promise<ICostCenterReportEntity>;
}

export interface IDisapproveUseCase {
  disapprove(id: string): Promise<ICostCenterReportEntity>;
}

export class DisapproveUseCase implements IDisapproveUseCase {
  constructor(private approveService: IDisapproveService) {}

  disapprove(id: string): Promise<ICostCenterReportEntity> {
    return this.approveService.disapprove(id);
  }
}
