import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ClassificationAssessmentEntity,
  IClassificationAssessmentEntity,
} from "../../domain/entities/classificationAssessment";
import { IToggleClassificationAssessmentService } from "../../domain/usecases/toggleClassificationAssessmentUseCase";

export class ToggleClassificationAssessmentService
  implements IToggleClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationAssessments/${classificationAssessmentId}`;

    const response = await this.api.delete<IClassificationAssessmentEntity>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return new ClassificationAssessmentEntity(response);
  }
}
