import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUndoReturnAccountReceivableContract } from "../../domain/contracts/undoReturnAccountReceivableContract";

export class UndoReturnAccountReceivableService
  implements IUndoReturnAccountReceivableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async undoReturn(
    companyGroupId: string,
    accountReceivableId: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsReceivable/${accountReceivableId}/UndoReturn`;

    await this.api.put(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
