export enum EAttachmentType {
  None,
  NFe = 1,
  NFSe = 2,
  Receipt = 3,
  Contract = 4,
  Others = 5,
  PaymentVoucher = 6,
  Billet = 7,
  VariableAdditional = 8,
  Certificate = 9,
  RPA = 10,
  Refund = 11,
  Measurement = 12,
  CommercialProposal = 13,
}

export const attachmentTypeLang = {
  [EAttachmentType.None]: "",
  [EAttachmentType.NFe]: "NFe",
  [EAttachmentType.NFSe]: "NFSe",
  [EAttachmentType.Receipt]: "Recibo",
  [EAttachmentType.Contract]: "Contrato",
  [EAttachmentType.Others]: "Outros",
  [EAttachmentType.PaymentVoucher]: "Comprovante",
  [EAttachmentType.Billet]: "Boleto",
  [EAttachmentType.VariableAdditional]: "Adicional Variável",
  [EAttachmentType.Certificate]: "Certidão",
  [EAttachmentType.RPA]: "RPA",
  [EAttachmentType.Refund]: "Reembolso",
  [EAttachmentType.Measurement]: "Medição",
  [EAttachmentType.CommercialProposal]: "Proposta Comercial",
};

export interface IAttachmentGridEntity {
  active: boolean;
  barcode: string | null;
  file: File | null;
  fileUrl?: string;
  name: string;
  storageFileId?: string;
  type: EAttachmentType;
  typeDescription: string;
}

export interface IAttachmentGridFileGeneration {
  generatedFile: File;
  isBulkGeneration: boolean;
}

export interface IAttachmentsGridForm {
  attachments: IAttachmentGridEntity[];
}

export interface IUpdateAttachmentsBarcodeResult<
  T extends IAttachmentGridEntity,
> {
  updatedAttachment: T;
  preventSubmit?: boolean;
}

export interface IValidadeMeasurementAttachmentResult<
  T extends IAttachmentGridEntity,
> {
  updatedAttachment: T;
  preventSubmit?: boolean;
}

export interface IOnAttachmentTypeChangeParams<
  T extends IAttachmentGridEntity,
> {
  modalAttachments: T[];
  editAttachment: T | null;
  typeToCheck: EAttachmentType;
}

export type IAttachmentsGridFormField = IAttachmentGridEntity & {
  formId: string;
};
