import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { DownloadConciliationResultService } from "../data/services/downloadConciliationResultService";
import { GetConciliationTemplateUrlService } from "../data/services/getConciliationTemplateUrlService";
import { ImportConciliationService } from "../data/services/importConciliationService";
import { ListCompaniesPaymentAccountsService } from "../data/services/listCompaniesPaymentAccountsService";
import { ListCompaniesService } from "../data/services/listCompaniesService";
import { ListPaymentAccountsByCompanyService } from "../data/services/listPaymentAccountsByCompanyService";
import { IDownloadConciliationResultContract } from "../domain/contracts/downloadConciliationResultContract";
import { IGetConciliationTemplateUrlContract } from "../domain/contracts/getConciliationTemplateUrlContract";
import { IImportConciliationContract } from "../domain/contracts/importConciliationContract";
import { IListCompaniesContract } from "../domain/contracts/listCompaniesContract";
import { IListCompaniesPaymentAccountsContract } from "../domain/contracts/listCompaniesPaymentAccountsContract";

export type MakeConciliation = IListCompaniesContract &
  IGetConciliationTemplateUrlContract &
  IImportConciliationContract &
  IDownloadConciliationResultContract &
  IListCompaniesPaymentAccountsContract;

export function makeConciliation(api: IApiService): MakeConciliation {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listCompaniesSvc = new ListCompaniesService(getUserLocalService, api);
  const listPaymentAccountsByCompanySvc =
    new ListPaymentAccountsByCompanyService(getUserLocalService, api);

  const listCompaniesPaymentAccountsSvc =
    new ListCompaniesPaymentAccountsService(listPaymentAccountsByCompanySvc);

  const getConciliationTemplateUrlSvc = new GetConciliationTemplateUrlService(
    getUserLocalService,
    api,
  );

  const importConciliationSvc = new ImportConciliationService(
    getUserLocalService,
    api,
  );

  const downloadConciliationResultSvc = new DownloadConciliationResultService(
    getUserLocalService,
    api,
  );

  return {
    listCompanies(companyGroupId) {
      return listCompaniesSvc.listCompanies(companyGroupId);
    },
    listCompaniesPaymentAccounts(companyGroupId, companyIds) {
      return listCompaniesPaymentAccountsSvc.listCompaniesPaymentAccounts(
        companyGroupId,
        companyIds,
      );
    },
    getConciliationTemplateUrl() {
      return getConciliationTemplateUrlSvc.getConciliationTemplateUrl();
    },
    importConciliation(payload) {
      return importConciliationSvc.importConciliation(payload);
    },
    downloadConciliationResult(conciliationEntity) {
      return downloadConciliationResultSvc.downloadConciliationResult(
        conciliationEntity,
      );
    },
  };
}
