import { IClassificationAssessmentEntity } from "../../../classificationAssessment/domain/entities/classificationAssessment";

export interface IGetClassificationAssessmentService {
  getClassificationAssessment(
    id: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export interface IGetClassificationAssessmentUseCase {
  getClassificationAssessment(
    id: string,
  ): Promise<IClassificationAssessmentEntity>;
}

export class GetClassificationAssessmentUseCase
  implements IGetClassificationAssessmentUseCase
{
  constructor(
    private getClassificationAssessmentService: IGetClassificationAssessmentService,
  ) {}

  getClassificationAssessment(id: string) {
    return this.getClassificationAssessmentService.getClassificationAssessment(
      id,
    );
  }
}
