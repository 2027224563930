import { MultiSelect } from "primereact/multiselect";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { ICompanyEntity } from "../../../domain/entitites/companyEntity";
import { IConciliationFormInputEntity } from "../../../domain/entitites/conciliationFormInputEntity";
import { Container } from "./styles";

interface IInputCompanyProps {
  options: ICompanyEntity[];
  disabled?: boolean;
  onChange?(value: ICompanyEntity[]): void;
}

export function InputCompany({
  options,
  disabled = false,
  onChange,
}: IInputCompanyProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IConciliationFormInputEntity>();

  return (
    <Container>
      <Controller
        control={control}
        name="company"
        rules={{
          validate: {
            required: value => {
              if (value.length === 0) {
                return false;
              }

              return true;
            },
          },
        }}
        render={({ field: { onChange: onFieldChange, ...rest } }) => {
          return (
            <MultiSelect
              {...rest}
              display="chip"
              appendTo="self"
              disabled={disabled}
              optionLabel="name"
              removeIcon="pi pi-times"
              placeholder="Empresa"
              filterPlaceholder="Pesquise uma opção"
              panelClassName="multiselect-panel"
              dataKey="id"
              options={options}
              id="sel-companies"
              data-testid="sel-companies"
              filter
              className={`company-multiselect ${
                errors.company ? "isInvalid" : ""
              }`}
              onChange={changeEvent => {
                const { value } = changeEvent;
                onFieldChange(value);
                onChange?.(value);
              }}
            />
          );
        }}
      />
      <InvalidFeedback
        message="Este campo é obrigatório"
        condition={
          // cast necessario pois a tipagem do rhf nao
          // bate com a abstracao de campos do tipo array
          (errors?.company as unknown as FieldError)?.type === "required"
        }
      />
    </Container>
  );
}
