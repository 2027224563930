import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IProjectTransactionEntity,
  ProjectTransactionEntity,
} from "../../domain/entities/projectTransactionEntity";
import { IToggleProjectTransactionService } from "../../domain/usecases/toggleProjectTransactionUseCase";

export class ToggleProjectTransactionService
  implements IToggleProjectTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleProjectTransaction(
    projectTransactionsId: string,
  ): Promise<IProjectTransactionEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ProjectTransactions/${projectTransactionsId}`;

    const response = await this.api.delete<IProjectTransactionEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ProjectTransactionEntity(response);
  }
}
