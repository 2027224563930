import { format, parse } from "date-fns";
import { ColumnProps } from "primereact/column";
import { useCallback, useMemo } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ICompanyTaxEntity } from "../../domain/entities/companyTaxEntity";

interface UseCompanyTaxesGridProps {
  openTaxEditModal(taxIndex: number): void;
  deleteTax(taxIndex: number, hasId: boolean): void;
}

export function useCompanyTaxesGrid({
  deleteTax,
  openTaxEditModal,
}: UseCompanyTaxesGridProps) {
  const formatDate = useCallback((date: string) => {
    if (!date) return null;
    const parseDate = parse(date, "ddMMyyyy", new Date());
    return format(parseDate, "dd/MM/yyyy");
  }, []);

  /**
   * Constrói a célula de edição e gerenciamento de uma empresa.
   */
  const manageBodyTemplate = useCallback(
    (taxInfo: ICompanyTaxEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            type="button"
            data-tip="Editar"
            id={`btn-edit-tax-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-edit-tax-${rowIndex}`}
            onClick={() => {
              openTaxEditModal(rowIndex);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            data-tip="Excluir"
            id={`btn-delete-tax-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-delete-tax-${rowIndex}`}
            onClick={() => {
              deleteTax(rowIndex, !!taxInfo?.id);
            }}
          >
            <FaTrash />
          </button>
        </div>
      );
    },
    [deleteTax, openTaxEditModal],
  );

  const columns = useMemo(() => {
    const temp: ColumnProps[] = [
      {
        field: "name",
        header: "Taxa",
      },
      {
        field: "startDate",
        header: "Data Inicial",
        excludeGlobalFilter: true,
        body: (v: ICompanyTaxEntity) => formatDate(v.startDate),
      },
      {
        field: "endDate",
        header: "Data Final",
        excludeGlobalFilter: true,
        body: (v: ICompanyTaxEntity) =>
          formatDate(v.endDate) || "Indeterminado",
      },
      {
        field: "tax",
        header: "%",
        excludeGlobalFilter: true,
        body: (v: ICompanyTaxEntity) => {
          const [intPart, decimalNumbers] = v.tax.toString().split(".");
          const decimalPart = (decimalNumbers || "").padEnd(2, "0");
          return `${intPart},${decimalPart}%`;
        },
      },
      {
        field: "isUsp",
        header: "É USP?",
        excludeGlobalFilter: true,
        body: (v: ICompanyTaxEntity) => (v.isUsp ? "Sim" : "Não"),
      },
      {
        field: "generateTax",
        header: "Gerada na",
        excludeGlobalFilter: true,
        body: (v: ICompanyTaxEntity) =>
          v.generateTax === 1 ? "Baixa" : "Emissão da NF",
      },
      {
        field: "management",
        body: manageBodyTemplate,
        excludeGlobalFilter: true,
      },
    ];
    return temp;
  }, [formatDate, manageBodyTemplate]);

  return {
    columns,
  };
}
