import { IEntity } from "../../../core/domain/entities/entity";

export interface ICompanyGroupEntity extends IEntity {
  name: string;
  daysForPayment: number | string;
  paymentRequestBlockStartDate: string;
  paymentRequestBlockEndDate: string;
}

export class CompanyGroupEntity implements ICompanyGroupEntity {
  id = "";
  daysForPayment = "";
  name = "";
  active = true;
  paymentRequestBlockStartDate = "";
  paymentRequestBlockEndDate = "";

  constructor(params?: Partial<ICompanyGroupEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICompanyGroupEntity>): ICompanyGroupEntity {
    return new CompanyGroupEntity(params);
  }
}
