import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface IGetCompanyGroupService {
  getCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity>;
}

export interface IGetCompanyGroupUseCase {
  getCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity>;
}

export class GetCompanyGroupUseCase implements IGetCompanyGroupUseCase {
  constructor(private getCompanyGroupService: IGetCompanyGroupService) {}

  getCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity> {
    return this.getCompanyGroupService.getCompanyGroup(CompanyGroupId);
  }
}
