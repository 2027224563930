import styled from "styled-components";

export const Container = styled.div`
  width: 900px;

  .loading-container {
    text-align: center;
    margin-bottom: 2rem;
  }

  .react-modal-body > div {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: none;

    .p-datatable-emptymessage > td {
      justify-content: center;
    }
  }

  .react-modal-footer {
    text-align: right;
  }
`;
