export interface IGetProviderImportationTemplateService {
  getProviderImportationTemplate(): Promise<string>;
}

export interface IGetProviderImportationTemplateUseCase {
  getProviderImportationTemplate(): Promise<string>;
}

export class GetProviderImportationTemplateUseCase
  implements IGetProviderImportationTemplateUseCase
{
  constructor(
    private getProviderImportationTemplateService: IGetProviderImportationTemplateService,
  ) {}

  getProviderImportationTemplate(): Promise<string> {
    return this.getProviderImportationTemplateService.getProviderImportationTemplate();
  }
}
