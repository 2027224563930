import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import { useCallback, useState } from "react";
import { isAfter, isBefore, parse } from "date-fns";
import { FaExclamationCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Container } from "./styles";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { Checkbox } from "../../../../../../core/presentation/components/Checkbox";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { IReportByCostCenterForm } from "../../../domain/entities/reportByCostCenterForm";
import { RequestProgressModal } from "../RequestProgressModal";
import { MakePaymentRequests } from "../../../main/makePaymentRequests";

interface IReportsByCostCenterModalProps {
  isOpen: boolean;
  onClose(): void;
  generateReportByCostCenter: MakePaymentRequests["generateReportByCostCenter"];
}

interface IRequestProgressModalState {
  total: number;
  loaded: number;
  isOpen: boolean;
}

export function ReportsByCostCenterModal(
  props: IReportsByCostCenterModalProps,
) {
  const { isOpen, onClose, generateReportByCostCenter } = props;

  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();

  const [progressModalState, setProgressModalState] =
    useState<IRequestProgressModalState>({
      total: 0,
      loaded: 0,
      isOpen: false,
    });

  const {
    reset,
    watch,
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<IReportByCostCenterForm>({
    mode: "all",
    defaultValues: {
      isFinancial: false,
      FinalFilterDate: "",
      InitialFilterDate: "",
      isPersonnelDepartment: false,
    },
  });

  const isFinancial = watch("isFinancial");
  const isPersonnelDepartment = watch("isPersonnelDepartment");

  const onDownloadProgress = (event: ProgressEvent) => {
    setProgressModalState({
      total: event.total,
      loaded: event.loaded,
      isOpen: event.loaded !== event.total,
    });
  };

  const handleOnValid = async (formValues: IReportByCostCenterForm) => {
    setProgressModalState({
      total: 0,
      loaded: 0,
      isOpen: true,
    });

    try {
      await generateReportByCostCenter({ formValues, onDownloadProgress });
      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Download do relatório concluído com sucesso.",
      });
    } catch {
      dialog.close();
    }
  };

  const handleOnInvalid = async (
    errors: FieldErrors<IReportByCostCenterForm>,
  ) => {
    if (errors.FinalFilterDate || errors.InitialFilterDate) {
      return;
    }

    if (errors.isFinancial && errors.isPersonnelDepartment) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        text: "Para gerar o relatório é preciso selecionar ao menos um dos destinos: Dep. Pessoal e/ou Financeiro.",
      });
    }
  };

  /**
   * Valida a data inicial baseada na data final. A ausência de valor no campo
   * de data final automaticamente valida o campo.
   */
  const validateInitialDate = useCallback(
    (value: string) => {
      const finalDate = getValues("FinalFilterDate");

      if (!finalDate) return true;
      if (value === finalDate) return true;

      const date = parse(value, "ddMMyyyy", new Date());
      const dateToCompare = parse(finalDate, "ddMMyyyy", new Date());

      return isBefore(date, dateToCompare);
    },
    [getValues],
  );

  /**
   * Valida a data final baseada na data inicial.
   */
  const validateFinalDate = useCallback(
    (value: string) => {
      const initialDate = getValues("InitialFilterDate");

      if (value === initialDate) return true;

      const date = parse(value, "ddMMyyyy", new Date());
      const dateToCompare = parse(initialDate, "ddMMyyyy", new Date());

      return isAfter(date, dateToCompare);
    },
    [getValues],
  );

  const handleAfterOpen = () => {
    ReactTooltip.rebuild();
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={reset}
      onRequestClose={onClose}
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      shouldCloseOnEsc={!isSubmitting}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Relatório de Solicitações de Pagamento por Centro de Custo</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          <form
            id="generate-report-form"
            className="form-container"
            onSubmit={handleSubmit(handleOnValid, handleOnInvalid)}
          >
            <div className="form-row">
              <label className="col-6 form-control">
                <span>Data Inicial</span>
                <Controller
                  name="InitialFilterDate"
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      validDate: v => !dateValidator(v),
                      isBefore: validateInitialDate,
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <InputMask
                          unmask
                          {...field}
                          mask="99/99/9999"
                          id="txt-initialDate"
                          placeholder="Data incial"
                          data-testid="txt-initialDate"
                          className={fieldState?.error ? "isInvalid" : ""}
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "validDate"}
                          message="Data inválida"
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "isBefore"}
                          message="Data inicial deve ser menor ou igual a final"
                        />
                      </>
                    );
                  }}
                />
              </label>
              <label className="col-6 form-control">
                <span>Data Final</span>
                <Controller
                  name="FinalFilterDate"
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      validDate: v => !dateValidator(v),
                      isAfter: validateFinalDate,
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <InputMask
                          unmask
                          {...field}
                          mask="99/99/9999"
                          id="txt-finalDate"
                          data-testid="txt-finalDate"
                          placeholder="Data final"
                          className={fieldState?.error ? "isInvalid" : ""}
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "validDate"}
                          message="Data inválida"
                        />
                        <InvalidFeedback
                          condition={fieldState?.error?.type === "isAfter"}
                          message="Data final deve ser maior ou igual a inicial"
                        />
                      </>
                    );
                  }}
                />
              </label>
            </div>
            <div className="form-row">
              <label className="col-6 form-control">
                <div
                  data-effect="solid"
                  data-tip="O solicitante fará<br />solicitações para o Dep.<br />Pessoal, como salários,<br />por exemplo."
                >
                  <Checkbox
                    label="Departamento Pessoal"
                    id="chk-personnel-department"
                    {...register("isPersonnelDepartment", {
                      required: !isFinancial,
                    })}
                  />
                </div>
              </label>
              <label className="col-6 form-control">
                <div
                  data-effect="solid"
                  data-tip="O solicitante fará<br />solicitações para o<br />Financeiro, como compras,<br />por exemplo."
                >
                  <Checkbox
                    label="Financeiro"
                    id="chk-personnel-department"
                    {...register("isFinancial", {
                      required: !isPersonnelDepartment,
                    })}
                  />
                </div>
              </label>
            </div>
          </form>
          <div className="warning">
            <FaExclamationCircle />
            <p>
              O relatório não considera filtros realizados na GRID anterior.
            </p>
          </div>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            id="btn-close"
            disabled={isSubmitting}
            className="form-button red-bkg"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            type="submit"
            id="btn-return"
            disabled={isSubmitting}
            form="generate-report-form"
            className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
          >
            {isSubmitting ? "Gerando " : "Gerar "}
            {isSubmitting && <i className="pi pi-spin pi-spinner" />}
          </button>
        </div>
      </Container>
      <RequestProgressModal {...progressModalState} />
    </Modal>
  );
}
