import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchCustomerClassificationAccountContract } from "../../domain/contracts/searchCustomerClassificationAccountContract";

interface IClassificationAccountResponse {
  id: string;
  name: string;
}

export class SearchCustomerClassificationAccountService
  implements ISearchCustomerClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCustomerClassificationAccount(
    companyGroupId: string,
    customerId: string,
    search = "",
    fetchMinLength = 10,
    onlyActive = false,
  ): Promise<ITypeaheadOption[]> {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: "", regex: false },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: "", regex: false },
        },
        {
          data: "active",
          name: "",
          searchable: false,
          orderable: false,
          search: { value: "", regex: false },
        },
      ],
      order: [{ column: 1, dir: "asc" }],
      search: {
        regex: false,
        value: search,
      },
      length: search ? undefined : fetchMinLength,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let baseUrl = `/CompanyGroups/${companyGroupId}/Customers/${customerId}/ClassificationAccounts`;

    if (onlyActive) {
      baseUrl = `/CompanyGroups/${companyGroupId}/Customers/${customerId}/ClassificationAccounts/Actives`;
    }

    const url = `${baseUrl}?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IClassificationAccountResponse[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.data.map(clsAccount => {
      return {
        metadata: clsAccount,
        label: clsAccount.name,
        rawValue: clsAccount.id,
      };
    });
  }
}
