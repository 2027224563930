import { Dropdown } from "primereact/dropdown";
import {
  FaArrowAltCircleLeft,
  FaCaretDown,
  FaCheckCircle,
  FaDatabase,
  FaFileExcel,
  FaPlus,
} from "react-icons/fa";
import { RiFilterOffFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { PanoramaFormModal } from "../../../../../../advTable/presentation/components/PanoramaFormModal";
import { SoulDropdown } from "../../../../../../core/presentation/components/SoulDropdown";
import { useToolbar } from "../../hooks/useToolbar";
import { Container, FilterToolsContainer, PageToolsContainer } from "./styles";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import {
  Balance,
  EBalanceVariant,
} from "../../../../../../core/presentation/components/Balance";
import { useAccountsPayablePage } from "../../hooks/useAccountsPayablePage";

export function Toolbar() {
  const { state } = useAccountsPayablePage();

  const { data } = state;

  const {
    loading,
    panoramaOptions,
    selectedPanorama,
    hasSelectedData,
    renderPanoramaOption,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
    showClearButton,
    handleClearButtonClick,
    isPanoramaModalOpen,
    setIsPanoramaModalOpen,
    pfsEvent,
    selectedColumns,
    handlePanoramaSave,
    handleOnExportButtonClick,
    handleBulkTerminateButtonClick,
    openExportSheetFealqFormModal,
    openExportSheetFuspFormModal,
    tooltipContainerRef,
    hideTooltip,
    openGenerateFeeModal,
    openGenerateRemittancesModal,
    handleRemoveBulkTerminateButtonClick,
    shouldShowRemittanceButton,
    shouldDisableTerminateButton,
    shouldDisableRemoveTerminateButton,
    canExportFealqSheet,
    canExportFuspSheet,
  } = useToolbar();

  return (
    <Container>
      <div className="page-tools">
        <PageToolsContainer>
          {/* Total */}
          <div className="balance-tool">
            <Balance value={data?.balance} variant={EBalanceVariant.Red} />
          </div>
          {/* panoramas */}
          <div>
            <Dropdown
              id="sel-panorama"
              data-testid="sel-panorama"
              optionLabel="name"
              value={selectedPanorama}
              options={panoramaOptions}
              panelStyle={{ width: "230px" }}
              placeholder="Padrão do sistema"
              filterPlaceholder="Pesquise um panorama"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              itemTemplate={renderPanoramaOption}
              onChange={handlePanoramaDropdownChange}
              filter
            />
            {loading && <i className="pi pi-spin pi-spinner" />}
          </div>

          {/* opcoes */}
          <div>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-testid="btn-options"
                  ref={tooltipContainerRef}
                  className="default-button options-context"
                  data-event="none"
                  data-place="left"
                  data-effect="solid"
                  data-event-off="click"
                  data-tip-disable={!hasSelectedData}
                  data-tip="Clique aqui para baixar selecionado(s)"
                  onClick={hideTooltip}
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              {/* salvar panorama */}
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleSavePanoramaButtonClick}
              >
                <FaDatabase />
                <span title="Panorama">Salvar Panorama</span>
              </button>

              {/* exportar para excel */}
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleOnExportButtonClick}
              >
                <FaFileExcel />
                <span title="Exportar para excel">Exportar para excel</span>
              </button>

              {/* Baixar selecionados */}
              <button
                type="button"
                id="btn-pano-save"
                className={`dropdown-item btn-terminate ${
                  !shouldDisableTerminateButton ? "btn-active" : ""
                }`.trim()}
                data-testid="btn-pano-save"
                onClick={handleBulkTerminateButtonClick}
                disabled={shouldDisableTerminateButton}
              >
                <FaCheckCircle />
                <span title="Baixar selecionados">Baixar selecionado(s)</span>
              </button>

              {/* Importar Contas a Pagar */}
              <Link to={SoulRoutes.DEBT_IMPORT.path} target="_blank">
                <button
                  type="button"
                  id="btn-pano-save"
                  className="dropdown-item"
                  data-testid="btn-pano-save"
                >
                  <FaFileExcel />
                  <span title="Importar Contas a Pagar">
                    Importar Contas a Pagar
                  </span>
                </button>
              </Link>

              {/* Gerar remessas */}
              {shouldShowRemittanceButton && (
                <button
                  type="button"
                  className="dropdown-item"
                  id="btn-generate-remittances"
                  data-testid="btn-generate-remittances"
                  onClick={openGenerateRemittancesModal}
                >
                  <FaFileExcel />
                  <span title="Gerar remessas">Gerar remessas</span>
                </button>
              )}

              {/* Exportar planilha FEALQ */}
              {canExportFealqSheet && (
                <button
                  type="button"
                  id="btn-pano-save"
                  className="dropdown-item"
                  data-testid="btn-pano-save"
                  onClick={openExportSheetFealqFormModal}
                >
                  <FaFileExcel />
                  <span title="Exportar planilha FEALQ">
                    Exportar planilha FEALQ
                  </span>
                </button>
              )}

              {/* Exportar planilha FUSP */}
              {canExportFuspSheet && (
                <button
                  type="button"
                  id="btn-pano-save"
                  className="dropdown-item"
                  data-testid="btn-pano-save"
                  onClick={openExportSheetFuspFormModal}
                >
                  <FaFileExcel />
                  <span title="Exportar planilha FUSP">
                    Exportar planilha FUSP
                  </span>
                </button>
              )}

              {/* Gerar relação de impostos */}
              <button
                type="button"
                id="btn-generate-fee"
                className="dropdown-item"
                data-testid="btn-generate-fee"
                onClick={openGenerateFeeModal}
              >
                <FaFileExcel />
                <span title="Gerar relação de impostos">
                  Gerar relação de impostos
                </span>
              </button>

              {/* Retirar baixa selecionado(s) */}
              <button
                type="button"
                id="btn-remove-terminate-selected"
                className={`dropdown-item btn-terminate ${
                  !shouldDisableRemoveTerminateButton ? "btn-active" : ""
                }`.trim()}
                data-testid="btn-remove-terminate-selected"
                onClick={handleRemoveBulkTerminateButtonClick}
                disabled={shouldDisableRemoveTerminateButton}
              >
                <FaArrowAltCircleLeft />
                <span title="Retirar baixa selecionado(s)">
                  Retirar baixa selecionado(s)
                </span>
              </button>
            </SoulDropdown>
          </div>

          <div>
            <Link to="/accountsPayable/new" target="_blank">
              <button id="btn-add" type="button" className="default-button">
                <FaPlus /> Contas a Pagar
              </button>
            </Link>
          </div>
        </PageToolsContainer>
      </div>

      <div className="filter-tools">
        <FilterToolsContainer>
          {showClearButton && (
            <div className="clr-filter-btn-wrapper">
              <button
                id="btn-clear"
                data-testid="btn-clear"
                className="default-button clear-filter-button"
                type="button"
                onClick={handleClearButtonClick}
              >
                <RiFilterOffFill />
                Limpar filtros
              </button>
            </div>
          )}
        </FilterToolsContainer>
      </div>

      {isPanoramaModalOpen && (
        <PanoramaFormModal
          isOpen={isPanoramaModalOpen}
          pfsEvent={pfsEvent}
          selectedColumns={selectedColumns}
          onRequestClose={() => {
            setIsPanoramaModalOpen(false);
          }}
          onPanoramaSave={handlePanoramaSave}
        />
      )}
    </Container>
  );
}
