import styled from "styled-components";

export const Container = styled.div`
  width: 1000px;

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }

  .taxes-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 1rem;
      color: var(--text-muted);
    }
  }

  .react-modal-body {
    form {
      border: 1px solid rgba(0, 0, 0, 0.05);

      .table-header {
        padding: 1rem;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        p {
          color: #32325d;
          font-size: 1rem;
          font-weight: 600;
        }

        .table-filter {
          gap: 0.5rem;
          align-items: center;
          display: inline-flex;

          border-radius: 0.25rem;
          border: 1px solid var(--input-border-color);

          padding: 0rem 0.5rem 0rem 0.5rem;

          :focus-within {
            border-color: rgba(50, 151, 211, 0.25);
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
          }

          svg {
            color: #b1bac4;
          }

          input {
            border: none;
            outline: none;
            appearance: none;

            color: #495057;
            font-size: 0.875rem;

            border-left: none;

            border-radius: 0 0.25rem 0.25rem 0;

            padding: 0.25rem 0rem 0.25rem 0rem;

            ::placeholder {
              color: #d1d1d9;
            }
          }
        }
      }

      .main-table > tbody > tr {
        &.p-datatable-row-expansion {
          > :first-child {
            padding: unset;
          }

          :hover {
            background: unset !important;
          }
        }

        &.p-row-odd {
          background: #fff;
        }
      }
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;
