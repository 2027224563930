import { IEntity } from "../../../../core/domain/entities/entity";

export enum ECostCenterReportType {
  expense = 1, // débito
  revenue = 2, // crédito
}

export const costCenterReportTypeLang: Record<string, string> = {
  expense: "Despesa",
  revenue: "Receita",
};

export enum EReturnStatus {
  notReturned = 1,
  partiallyReturned = 2,
  fullyReturned = 3,
}

export const returnStatusLang: Record<string, string> = {
  notReturned: "Não devolvido",
  partiallyReturned: "Parcialmente devolvido ",
  fullyReturned: "Totalmente devolvido",
};

export enum ECostCenterReportStatus {
  open = 1,
  paid = 2,
  canceled = 3,
}

export const costCenterReportStatusLang: Record<string, string> = {
  open: "Aberto",
  paid: "Pago",
  canceled: "Cancelado",
};

export enum EDocumentStatus {
  complete = 1,
  incomplete = 2,
}

export const costCenterDocumentStatusLang: Record<string, string> = {
  complete: "Completo",
  incomplete: "Incompleto",
};

export enum EPaymentMethod {
  billet = 1,
  creditCard = 2,
  currentAccountTransfer = 3,
  cash = 4,
  check = 5,
  directDebit = 6,
  pix = 7,
  forecast = 8,
}

export const costCenterPaymentMethodLang: Record<string, string> = {
  billet: "Boleto",
  creditCard: "Cartão de Crédito",
  currentAccountTransfer: "Transferência em conta corrente",
  cash: "Dinheiro",
  check: "Cheque",
  directDebit: "Débito em conta corrente",
  pix: "Pix",
  forecast: "Previsão",
};

export enum EReportOrigin {
  accountPayable = 1,
  accountReceivable = 2,
  transaction = 3,
  OpeningBalance = 4,
}

export const costCenterReportOriginLang = {
  accountPayable: "Contas a Pagar",
  accountReceivable: "Contas a Receber",
  transaction: "Movimentação",
  OpeningBalance: "Saldo inicial",
};

export enum EApprovalStatus {
  Pending = 1,
  Approved = 2,
  Disapproved = 3,
}

export const approvalStatusLang = {
  Pending: "Pendente",
  Approved: "Aprovado",
  Disapproved: "Reprovado",
};

export interface ICostCenterReportEntity extends IEntity {
  documentNumber: string;
  observation: string;
  description: string;

  // id do usuario que lancou
  userCreated: string;
  userNameCreated: string;

  costCenterValue: number | string;
  costCenterValueString: string;

  // valor do lancamento
  totalValue: number | string;
  totalValueString: string;

  percentage: number | string;
  percentageString: string;

  type: ECostCenterReportType;
  typeDescription: string;

  returnStatusDescription: string;
  returnStatus: EReturnStatus;

  status: ECostCenterReportStatus;
  statusDescription: string;

  documentStatus: EDocumentStatus;
  documentStatusDescription: string;

  paymentMethod: EPaymentMethod;
  paymentMethodDescription: string;

  // id da conta a receber que originou a parcela (se for conta a receber)
  originId: string;
  origin: EReportOrigin;
  originDescription: string;

  // receber/pagar até
  operationDate: string;
  operationDateString: string;

  // data do lançamento
  issueDate: string;
  issueDateString: string;

  costCenterId: string;
  costCenterName: string;

  companyGroupId: string;
  companyGroupName: string;

  companyId: string;
  companyName: string;

  // id do cliente ou fornecedor ao qual este lancamento esta vinculado
  customerProviderId: string;
  customerProviderName: string;
  customerProviderCorporationName: string;

  customerProviderCityId: string;
  customerProviderStateId: string;
  customerProviderCityState: string;

  classificationAccountId: string;
  classificationAccountName: string;

  classificationUspId: string;
  classificationUspName: string;

  competencyId: string;
  competencyName: string;

  paymentAccountId: string;
  paymentAccountName: string;

  projectId: string;
  projectName: string;

  classificationAssessmentId: string;
  classificationAssessmentName: string;

  dateModified: string; // Data de modificação
  dateModifiedString: string;

  // id do usuário que modificou
  userModified: string;
  userNameModified: string;

  // valor da parcela
  operationValue: number;
  operationValueString: string;

  // numero da parcela
  parcelNumber: number;
  numberOfParcels: number;

  // campos exclusivos para relatorio de gestor
  approvalStatus: EApprovalStatus;
  approvingStatusDescription: string;

  approvingDate: string;
  approvingDateString: string;

  // id do usuário que aprovou
  userApproved: string;
  userNameApproved: string;

  // usuario que solicitou
  requestedUserId: string;
  requestedUserName: string;

  macroBoxId: string;
  macroBoxName: string;
}

export class CostCenterReportEntity implements ICostCenterReportEntity {
  id = "";
  documentNumber = "";
  observation = "";
  description = "";
  userCreated = "";
  userNameCreated = "";
  costCenterValue = "";
  costCenterValueString = "";
  totalValue = "";
  totalValueString = "";
  percentage = "";
  percentageString = "";
  type = ECostCenterReportType.expense;
  typeDescription = costCenterReportTypeLang[ECostCenterReportType.expense];
  status = ECostCenterReportStatus.open;
  statusDescription = costCenterReportStatusLang[ECostCenterReportStatus.open];
  returnStatus = EReturnStatus.notReturned;
  returnStatusDescription = "";
  documentStatus = EDocumentStatus.complete;
  documentStatusDescription =
    costCenterDocumentStatusLang[EDocumentStatus.complete];

  paymentMethod = EPaymentMethod.cash;
  paymentMethodDescription = costCenterPaymentMethodLang[EPaymentMethod.cash];
  originId = "";
  origin = EReportOrigin.OpeningBalance;
  originDescription = "";
  operationDate = "";
  operationDateString = "";
  issueDate = "";
  issueDateString = "";
  costCenterId = "";
  costCenterName = "";
  companyGroupId = "";
  companyGroupName = "";
  companyId = "";
  companyName = "";
  customerProviderId = "";
  customerProviderName = "";
  customerProviderCorporationName = "";
  customerProviderCityId = "";
  customerProviderStateId = "";
  customerProviderCityState = "";
  classificationAccountId = "";
  classificationAccountName = "";
  classificationUspId = "";
  classificationUspName = "";
  competencyId = "";
  competencyName = "";
  paymentAccountId = "";
  paymentAccountName = "";
  projectId = "";
  projectName = "";
  classificationAssessmentId = "";
  classificationAssessmentName = "";
  dateModified = "";
  dateModifiedString = "";
  userModified = "";
  userNameModified = "";
  operationValue = 0;
  operationValueString = "";
  parcelNumber = 1;
  numberOfParcels = 0;
  active = true;
  approvalStatus = EApprovalStatus.Pending;
  approvingStatusDescription = "";
  approvingDate = "";
  approvingDateString = "";
  userApproved = "";
  userNameApproved = "";
  requestedUserId = "";
  requestedUserName = "";
  macroBoxId = "";
  macroBoxName = "";

  constructor(params?: Partial<ICostCenterReportEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterReportEntity>) {
    return new CostCenterReportEntity(params);
  }
}
