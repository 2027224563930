import { IPaymentRequestAttachmentEntity } from "../../../accounts/requests/form/domain/entities/paymentRequestAttachmentEntity";
import { IValidateMeasurementAttachmentContract } from "../../domain/contracts/validateMeasurementAttachmentContract";
import { ISucessResponseEntityTeste } from "../../domain/entities/responseEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

export class ValidateMeasurementAttachmentService
  implements IValidateMeasurementAttachmentContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  /**
   * Posta o arquivo da medição e o valor no endpoint de OCR
   * e retorna a resposta de verificação
   */
  async validateMeasurementAttachment(
    value: number,
    measurementAttachment: IPaymentRequestAttachmentEntity,
  ): Promise<ISucessResponseEntityTeste> {
    const measurementCopy = { ...measurementAttachment };

    const measurementFile = measurementCopy?.file;

    const measurementStorageFileId = measurementCopy?.storageFileId;

    const newValue = value.toString();

    const formData = new FormData();

    if (measurementFile) {
      formData.append("file", measurementFile);
      formData.set("value", newValue);
    } else if (measurementStorageFileId) {
      formData.set("storageFileId", measurementStorageFileId);
      formData.set("value", newValue);
      formData.append("file", new File([], "emptyFile"));
    }

    const userEntity = this.getUserLocalService.get();
    const url = `/Utils/CheckMeasurementValue`;

    const response = await this.api.post<ISucessResponseEntityTeste>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response;
  }
}
