import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;

  .no-padding {
    padding: 0;
  }

  .actives-only {
    gap: 0.5rem;
    display: flex;
  }

  tbody.p-datatable-tbody tr.p-datatable-emptymessage {
    td {
      padding: 1rem;
      text-align: center;
      display: table-cell;
    }
  }
`;
