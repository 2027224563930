import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import {
  EAccountPayableStatus,
  IAccountPayableFormEntity,
} from "../../../domain/entities/accountPayableFormEntity";
import { MakeAccountsPayableForm } from "../../../main/makeAccountPayableForm";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { ICompanyEntity } from "../../../../../../company/domain/entities/companyEntity";

interface ISectionCompanyDataState {
  company: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
  competency: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface ISectionCompanyDataProps {
  readonly: boolean;
  useAccountsPayableForm: MakeAccountsPayableForm;
}

export function SectionCompanyData({
  readonly,
  useAccountsPayableForm,
}: ISectionCompanyDataProps) {
  const { searchCompany, searchCompetency } = useAccountsPayableForm;

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountPayableFormEntity>();

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = form;

  const status = watch("status");
  const competency = watch("competency");
  const company = watch("company") as ITypeaheadOption<ICompanyEntity>;

  const shouldShowCompetencyField = !!company?.metadata?.hasProject;

  const isCompanyDisabled = readonly || status === EAccountPayableStatus.Paid;

  const isCompetencyDisabled =
    readonly || status === EAccountPayableStatus.Paid;

  const [state, setState] = useState<ISectionCompanyDataState>({
    company: {
      loading: false,
      options: [],
    },
    competency: {
      loading: false,
      options: [],
    },
  });

  const handleCompanyChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;

    setValue("company", value, { shouldValidate: true });

    // reseta os campos que cascateam valor a partir de empresa
    setValue("competency", null, { shouldValidate: true });
    setValue("project", null, { shouldValidate: true });
    setValue("classificationUsp", null, { shouldValidate: true });
    setValue("paymentAccount", null, { shouldValidate: true });
  };

  const handleCompetencyChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;

    setValue("competency", value, { shouldValidate: true });

    // reseta cls usp pq soh preciso selecionar cls usp se a competencia for usp
    setValue("classificationUsp", null, { shouldValidate: true });
  };

  const handleSearchCompanyChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      company: {
        ...prevState.company,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompany({
        search,
        companyGroupId,
        activesOnly: true,
        showMetadata: true,
        payloadOptions: {
          length: 100,
        },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        company: {
          ...prevState.company,
          options: companies,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        company: {
          ...prevState.company,
          loading: false,
        },
      }));
    }
  };

  const handleSearchCompetencyChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      competency: {
        ...prevState.competency,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompetency({
        search,
        companyGroupId,
        activesOnly: true,
        showMetadata: true,
        payloadOptions: {
          length: 100,
        },
      });

      const competencies = response.data;

      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          options: competencies,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          loading: false,
        },
      }));
    }
  };

  useEffect(() => {
    register("company", { required: true });
    register("competency", { required: company?.metadata?.hasProject });
  }, [company?.metadata?.hasProject, register]);

  return (
    <div className="form-row">
      {/* TODO substiuir o col-6 por regras css para fazer responsividade */}
      <label className="col-6 form-control">
        <span>
          Empresa{" "}
          {state.company.loading && <i className="pi pi-spin pi-spinner" />}
        </span>
        <SoulTypeahead
          id="company"
          data-testid="txt-company"
          placeholder="Empresa"
          options={state.company.options}
          openOnFocus
          onSearchChange={handleSearchCompanyChange}
          onChange={handleCompanyChange}
          value={company}
          serverSide
          loading={state.company.loading}
          disabled={isCompanyDisabled}
          className={errors?.company ? "isInvalid" : undefined}
        />
        <InvalidFeedback
          condition={(errors?.company as FieldError)?.type === "required"}
          message="Este campo é obrigatório"
        />
      </label>
      {shouldShowCompetencyField && (
        <label className="col-6 form-control">
          <span>
            Competência{" "}
            {state.competency.loading && (
              <i className="pi pi-spin pi-spinner" />
            )}
          </span>
          <SoulTypeahead
            id="competency"
            data-testid="txt-competency"
            placeholder="Competência"
            options={state.competency.options}
            openOnFocus
            onSearchChange={handleSearchCompetencyChange}
            onChange={handleCompetencyChange}
            value={competency}
            serverSide
            loading={state.competency.loading}
            disabled={isCompetencyDisabled}
            className={errors?.competency ? "isInvalid" : undefined}
          />
          <InvalidFeedback
            condition={(errors?.competency as FieldError)?.type === "required"}
            message="Este campo é obrigatório"
          />
        </label>
      )}
    </div>
  );
}
