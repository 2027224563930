import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRemoveTerminationContract } from "../../domain/contracts/removeTerminationContract";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";

export class RemoveTerminationService implements IRemoveTerminationContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async removeTermination(
    companyGroupId: string,
    accountPayableId: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsPayable/${accountPayableId}/Status`;

    const payload = {
      status: EAccountPayableStatus.Open,
      deleteReason: "",
    };

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
