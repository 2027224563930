import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { ICompanyEntity } from "../../../../../company/domain/entities/companyEntity";
import { IApiService } from "../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IUserEntity } from "../../../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPaymentRequestForReviewContract } from "../../domain/contracts/getPaymentRequestForReviewContract";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { PaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";
import { IProviderEntity } from "../../domain/entities/providerEntity";
import { IPaymentRequestsAssessmentModel } from "../models/paymentRequestAssessmentModel";
import { IPaymentRequestModel } from "../models/paymentRequestModel";
import { IPaymentRequestsAssessmentEntity } from "../../domain/entities/paymentRequestAssessmentEntity";

export class GetPaymentRequestForReviewService
  implements IGetPaymentRequestForReviewContract
{
  private readonly endpoint = "/PaymentRequests";
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async getPaymentRequestForReview(paymentRequestId: string) {
    const requestData = await this.getData(paymentRequestId);

    /**
     * Não é possível revisar uma solicitação de pagamento que tenha status
     * diferente de "Solicitado".
     * Para esses casos, o processo deve ser encerrado.
     */
    if (requestData.status !== EPaymentRequestStatus.Requested) {
      return Promise.reject();
    }

    const dtIssueDate = new Date(requestData.issueDate);
    const dtPayUntil = new Date(requestData.payUntil);
    const userNameCreated = requestData.userNameCreated || "Não identificado";

    const assessments = await this.getAssessments(paymentRequestId);
    const storageFiles = await this.getStorageFiles(paymentRequestId);
    const company = await this.getCompany(requestData.companyId);
    const provider = await this.getProvider(requestData.providerId);

    const billetAttachment = storageFiles.find(att => {
      return att.type === EAttachmentType.Billet;
    });

    const paymentRequest = new PaymentRequestFormEntity({
      id: requestData.id,
      assessments,
      storageFiles,
      duplicatePaymentRequestId: "",
      value: requestData.value,
      payUntil: format(dtPayUntil, "dd/MM/yyyy"),
      issueDate: format(dtIssueDate, "dd/MM/yyyy"),
      documentNumber: requestData.documentNumber,
      description: requestData.description || "",
      observation: requestData.observation || "",
      barcode: billetAttachment ? billetAttachment.barcode || null : null,
      paymentMethod: {
        value: "",
        key: requestData.paymentMethod,
      },
      documentStatus: {
        value: "",
        key: requestData.documentStatus,
      },
      destination: requestData.destination,
      status: requestData.status,
      company: {
        metadata: company,
        rawValue: company?.id || "",
        label: company?.assumedName || "",
      },
      provider: {
        metadata: provider,
        rawValue: provider?.id || "",
        label: provider?.name || "",
      },
    });

    return {
      paymentRequest,
      userNameCreated,
    };
  }

  private async getData(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}`;

    return this.api.get<IPaymentRequestModel>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getProvider(providerId: string) {
    const url = `/Providers/${providerId}`;

    return this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getCompany(companyId: string) {
    const url = `/Companies/${companyId}`;

    return this.api.get<ICompanyEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getAssessments(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}/Assessments`;

    const response = await this.api.get<IPaymentRequestsAssessmentModel[]>(
      url,
      {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      },
    );

    return response.map<IPaymentRequestsAssessmentEntity>(assessmentObject => {
      return {
        accountPayableId: null,
        id: assessmentObject.id,
        observation: assessmentObject.observation,
        value: new BigNumber(assessmentObject.value).toJSON(),
        percentage: new BigNumber(assessmentObject.percentage).toJSON(),
        classificationAssessment: {
          label: assessmentObject.classificationAssessmentName,
          rawValue: assessmentObject.classificationAssessmentId,
        },
        costCenter: {
          label: assessmentObject.costCenterName,
          rawValue: assessmentObject.costCenterId,
        },
      };
    });
  }

  private async getStorageFiles(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}/Attachments`;

    return this.api.get<IPaymentRequestAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }
}
