import styled from "styled-components";

interface IContainerProps {
  openOnFocus?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  width: 100%;

  input {
    width: 100%;

    ${props => {
      if (props.openOnFocus) {
        return `padding: 0.25rem 1.7rem 0.25rem 0.5rem;`;
      }

      return `padding: 0.25rem 0.5rem 0.25rem 0.5rem;`;
    }}

    font-family: var(--font-family);
    font-size: 0.875rem;
    border-radius: 0.25rem;
    line-height: 1.5;
    border: 1px solid var(--input-border-color);
    height: calc(1.5em + 0.5rem + 2px);

    color: #495057 !important;
    background-color: #fff;

    :focus {
      outline: unset;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      border-color: rgba(50, 151, 211, 0.25);
    }

    ::placeholder {
      color: #d1d1d9;
    }

    :disabled {
      background-color: #e9ecef;
      color: #495057 !important;
    }

    &.isInvalid {
      border-color: var(--invalid);

      :focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }

  ${props => {
    if (props.openOnFocus) {
      return `
          &:after {
            --dimension: 0.5rem;
            --border-style: 1px solid #888;

            content: "";
            display: block;
            position: absolute;
            width: var(--dimension);
            height: var(--dimension);
            border-bottom: var(--border-style);
            border-right: var(--border-style);
            background: transparent;
            top: 0;
            right: 0;
            transform: rotateZ(45deg);
            margin-top: calc(1.5em / 2.7);
            margin-right: 0.6rem;
          }
        `;
    }

    return "";
  }}
`;

interface IPanelProps {
  maxHeight: string;
}

export const TypeaheadPanel = styled.div<IPanelProps>`
  font-family: var(--font-family);
  position: fixed;
  z-index: 1060;

  ul {
    list-style-type: none;
    padding: 0.5em 0;
    background: white;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.25rem;
    max-height: ${props => props.maxHeight};
    overflow: auto;
    display: none;
    box-shadow: 0px 8px 8px #00000009;

    &.open {
      display: block;
    }

    li {
      .loading-option {
        padding: 0.5rem 1rem;
      }

      button {
        font-family: var(--font-family);
        font-size: 0.9rem;
        display: block;
        border: none;
        width: 100%;
        height: 100%;
        background: white;
        text-align: left;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(.not-found) {
          text-transform: uppercase;
        }

        &.highlighted {
          background: var(--highlight-color);
          color: white;
        }

        &:not(.highlighted).selected {
          background: #ebefff;
        }

        strong {
          font-weight: 900;
        }

        &.not-found {
          .icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
`;
