import { FaTrash } from "react-icons/fa";
import { useCallback, useMemo } from "react";
import { ColumnProps } from "primereact/column";
import { Controller, useFormContext } from "react-hook-form";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { useDebtImportPage } from "./useDebtImportPage";
import { InputBalance } from "../../../../../core/presentation/components/InputBalance";
import { FinalStepExpandedTemplate } from "../components/FinalStepExpandedTemplate";
import { FieldWrapper } from "../components/FieldWrapper";
import { IDebtImportForm } from "../../domain/entities/debtImportTypes";

export function useFinalStepGrid(
  handleFormsRemoval: (formsIds: string[]) => boolean,
) {
  const { getFormIndex, handleRemoveForm, handleInputClassName } =
    useDebtImportPage();

  const { getFieldState, formState, register } =
    useFormContext<IDebtImportForm>();

  const handleRowClassName = ({ id }: IDebtImportEntity) => {
    const formIndex = getFormIndex(id);
    const fieldState = getFieldState(`imports.${formIndex}`, formState);

    const isInvalid = !!fieldState.error;

    return {
      "row-valid": !isInvalid,
      "row-invalid": isInvalid,
    };
  };

  const handleRowExpansionTemplate = useCallback(
    (data: IDebtImportEntity) => {
      const formIndex = getFormIndex(data.id);

      return <FinalStepExpandedTemplate formIndex={formIndex} />;
    },
    [getFormIndex],
  );

  const columns: ColumnProps[] = useMemo(() => {
    return [
      {
        field: "expander",
        expander: true,
        style: {
          width: "4rem",
        },
      },
      {
        header: "Empresa",
        field: "companyName",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity, { rowIndex }) {
          return (
            <FieldWrapper>
              <input
                disabled
                value={data.company?.label || ""}
                id={`txt-company-${rowIndex}`}
                data-testid={`txt-company-${rowIndex}`}
              />
            </FieldWrapper>
          );
        },
      },
      {
        header: "Nº do Documento",
        field: "documentNumber",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity, { rowIndex }) {
          return (
            <FieldWrapper>
              <input
                disabled
                value={data?.documentNumber || ""}
                id={`txt-documentNumber-${rowIndex}`}
                data-testid={`txt-documentNumber-${rowIndex}`}
              />
            </FieldWrapper>
          );
        },
      },
      {
        header: "Fornecedor",
        field: "providerName",
        body(data: IDebtImportEntity, { rowIndex }) {
          return (
            <FieldWrapper>
              <input
                disabled
                value={data.provider?.label || ""}
                id={`txt-provider-${rowIndex}`}
                data-testid={`txt-provider-${rowIndex}`}
              />
            </FieldWrapper>
          );
        },
      },
      {
        header: "Valor",
        field: "value",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity, { rowIndex }) {
          return (
            <FieldWrapper>
              <InputBalance
                disabled
                value={data.value}
                onChange={() => null}
                className="numeric-field"
                id={`txt-value-${rowIndex}`}
                data-testid={`txt-value-${rowIndex}`}
              />
            </FieldWrapper>
          );
        },
      },
      {
        header: "Valor restante a ser rateado",
        field: "valueAssessmentLeft",
        style: {
          width: "15%",
        },
        body(data: IDebtImportEntity, { rowIndex }) {
          const formIndex = getFormIndex(data.id);
          return (
            <>
              <input
                type="hidden"
                {...register(`imports.${formIndex}.hasAssessmentError`, {
                  validate: {
                    isFalse: v => !v,
                  },
                })}
              />
              <label className="form-control">
                <Controller
                  name={`imports.${formIndex}.valueAssessmentLeft`}
                  rules={{
                    validate: {
                      isZero: v => v === 0,
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <FieldWrapper
                        className={handleInputClassName(fieldState)}
                      >
                        <InputBalance
                          disabled
                          {...field}
                          onChange={() => null}
                          className="numeric-field"
                          id={`txt-valueAssessmentLeft-${rowIndex}`}
                          data-testid={`txt-valueAssessmentLeft-${rowIndex}`}
                        />
                      </FieldWrapper>
                    );
                  }}
                />
              </label>
            </>
          );
        },
      },
      {
        field: "remove-row",
        style: {
          width: "3%",
        },
        body(data: IDebtImportEntity, { rowIndex }) {
          return (
            <button
              type="button"
              data-tip="Remover"
              data-effect="solid"
              className="p-link p-row-toggler"
              id={`btn-remove-row-${rowIndex}`}
              data-testid={`btn-remove-row-${rowIndex}`}
              onClick={() => {
                handleRemoveForm([data.id], handleFormsRemoval);
              }}
            >
              <FaTrash />
            </button>
          );
        },
      },
    ];
  }, [
    register,
    getFormIndex,
    handleRemoveForm,
    handleFormsRemoval,
    handleInputClassName,
  ]);

  return {
    columns,
    handleRowClassName,
    handleRowExpansionTemplate,
  };
}
