import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .importation-badge {
    width: 60px;

    padding: 6px;

    color: #fff;

    font-size: 0.65rem;
    font-weight: 600;

    line-height: 1;

    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;

    border-radius: 0.375rem;

    &.blue-bkg {
      background-color: #5e72e4;
    }

    &.red-bkg {
      background-color: #f3a4b5;
    }
  }

  .p-paginator {
    border-bottom: none;
  }

  .p-datatable-thead {
    border-top: 1px solid #dee2e6;
  }

  .search-input {
    padding: 0.5rem;
  }

  .table-hasLink {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .p-accordion {
    margin-bottom: 2rem;

    .p-accordion-content {
      padding: unset;
    }

    .p-accordion-tab:first-child {
      margin: unset;

      a.p-accordion-header-link {
        border-radius: 6px 6px 0 0;
      }

      .p-accordion-content {
        border-radius: 0;
      }
    }

    .p-accordion-tab:last-child {
      &:not(.p-accordion-tab-active) a.p-accordion-header-link {
        border-radius: 0 0 6px 6px;
      }
      a.p-accordion-header-link {
        border-top: unset;
        border-radius: 0;
      }
    }

    .p-accordion-header-text {
      gap: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 600;
      color: var(--soul-color01);
    }
  }
`;
