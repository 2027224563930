import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: inherit;

  a {
    text-decoration: none;
  }

  .custom-header {
    gap: 0.5rem;
    display: flex;
    padding-left: 1rem;
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    .custom-header {
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  .src-fld {
    width: 100%;
    max-width: 20rem;
  }

  .button-wrapper {
    flex-grow: 1;
    display: inline-flex;
    justify-content: center;
  }

  .full-page {
    display: flex;
    height: inherit;
    flex-direction: column;
  }

  .empty-message {
    position: relative;
    height: 2.625rem;

    div {
      background-color: #f1f1f1;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      color: #8898aa;
      border: 0.0625rem solid #e1e1e1;
      position: fixed;
      left: calc(50vw - 3.75rem - 6.1875rem);
    }
  }

  .text-muted {
    color: var(--text-muted);
  }

  .no-padding {
    padding: 0;

    &.fill-height {
      height: inherit;
      display: flex;
      flex-direction: column;
      /**
       * 9rem é basicamente a distancia entre o top do viewport e o top
       * do * elemento .no-padding + uma espaco de respiro entre o bottom
       * do .no-padding e o bottom do viewport.
        *
       * Esta estratégia usando calc com 100vh e um valor fixo está
       * presente na documentacao do PrimeReact > Table > Flexscroll.
       */
      height: calc(100vh - 21.5rem);

      @media screen and (min-width: 992px) {
        height: calc(100vh - 13rem);
      }

      @media screen and (min-width: 1200px) {
        height: calc(100vh - 9rem);
      }
    }
  }

  .disabled-checkbox {
    cursor: not-allowed;

    .p-checkbox {
      pointer-events: none;

      div {
        border-color: #6366f122;
        background-color: #6366f133;
      }
    }
  }

  .p-datatable {
    .hide-checkbox {
      .p-checkbox-box {
        display: none;
      }
    }

    .p-datatable-tbody {
      --canceled-text: #aaa;

      tr {
        &:not(.p-highlight):not(.p-datatable-emptymessage):hover {
          &.row-paid {
            background: #e4f7f0;
          }

          &.row-open {
            background: #fff1db;
          }

          &.row-inconsistent {
            background-color: #fde2e7;
          }

          &.row-canceled {
            color: var(--canceled-text);

            .p-checkbox.p-component {
              visibility: hidden;
            }
          }

          &.p-row-odd {
            &.row-paid {
              background: #ebf9f4;
            }

            &.row-open {
              background: #fff1db;
            }

            &.row-inconsistent {
              background-color: #fde2e7;
            }

            &.row-canceled {
              color: var(--canceled-text);

              .p-checkbox.p-component {
                visibility: hidden;
              }
            }
          }
        }

        &.row-paid {
          background: #d0f1e6;
        }

        &.row-open {
          background: #ffe4bb;
        }

        &.row-inconsistent {
          background-color: #fcc7d2;
        }

        &.row-canceled {
          color: var(--canceled-text);

          .p-checkbox.p-component {
            visibility: hidden;
          }
        }

        &.p-row-odd {
          &.row-paid {
            background: #dcf5ec;
          }

          &.row-open {
            background: #ffe9c7;
          }

          &.row-inconsistent {
            background-color: #fdd6de;
          }

          &.row-canceled {
            color: var(--canceled-text);

            .p-checkbox.p-component {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
`;

export const ContextMenuItem = styled.div`
  .p-menuitem-text {
    display: inline-flex;
    justify-content: center;
  }

  svg {
    fill: var(--soul-color02);
    margin-right: 0.5rem;
  }
`;

export const InvoiceDeniedReasonContent = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  > p {
    font-size: 1.25rem;
  }

  div {
    width: 85%;
    height: 6.75rem;

    padding: 0.8rem;

    font-size: 0.95rem;
    text-align: left;

    border: 1px solid var(--input-border-color);

    small {
      color: var(--text-muted);
    }
  }
`;
