import { Checkbox } from "primereact/checkbox";
import {
  MultiSelect,
  MultiSelectPanelHeaderTemplateParams,
} from "primereact/multiselect";
import { useCallback, useRef, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { ICheckValidCostCentersParams } from "../../../domain/contracts/checkValidCostCentersContract";
import { ICostCenterResumedEntity } from "../../../domain/entities/costCenterEntity";
import { IProjectFormEntity } from "../../../domain/entities/projectFormEntity";
import { Container, FilterContainer, LoadingContainer } from "./style";

interface SearchCostCenterFieldProps {
  projectId: string;
  disabled: boolean;
  isLoading: boolean;
  options: ICostCenterResumedEntity[];
  handleFilter(search: string): Promise<void>;
  checkValidCostCenters(payload: ICheckValidCostCentersParams): Promise<void>;
}

/** Utilizado para indicar quando não houverem opções disponíveis */
const EMPTY = {
  emptied: true,
};

export function SearchCostCenterField(props: SearchCostCenterFieldProps) {
  const {
    options,
    disabled,
    isLoading,
    projectId,
    handleFilter,
    checkValidCostCenters,
  } = props;

  const debounceTime = useDebounceTime();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IProjectFormEntity>();

  const refPrevSelected = useRef(getValues("costCenters") || []);

  const [search, setSearch] = useState("");

  const validateCostCenters = useCallback(
    async (currentSelected: ICostCenterResumedEntity[]) => {
      const costCentersError = errors?.costCenters as unknown as FieldError;

      if (isLoading) {
        return costCentersError?.message;
      }

      if (currentSelected !== refPrevSelected.current) {
        refPrevSelected.current = currentSelected;

        try {
          await checkValidCostCenters({
            projectId,
            costCenters: currentSelected,
          });

          return true;
        } catch (error) {
          if (typeof error === "string") {
            return error;
          }
        }
      }

      if (costCentersError?.type === "alreadyLinked") {
        return costCentersError?.message;
      }

      return true;
    },
    [errors?.costCenters, isLoading, checkValidCostCenters, projectId],
  );

  const headerTemplate = useCallback(
    (params: MultiSelectPanelHeaderTemplateParams) => {
      const { checkboxElement } = params;

      return (
        <FilterContainer className="form-control">
          {options.length ? checkboxElement : <Checkbox disabled />}
          <input
            value={search}
            placeholder="Pesquise uma opção"
            onChange={c => {
              setSearch(c.target.value);
              debounceTime(() => {
                handleFilter(c.target.value);
              }, 700);
            }}
          />
          <button
            type="button"
            onClick={() => {
              setSearch("");
              handleFilter("");
            }}
          >
            <FaTimes />
          </button>
        </FilterContainer>
      );
    },
    [debounceTime, handleFilter, options.length, search],
  );

  const itemTemplate = useCallback(
    (option: ICostCenterResumedEntity | typeof EMPTY) => {
      if ("emptied" in option) {
        return "Nenhuma opção disponível";
      }
      return option.acronym;
    },
    [],
  );

  const alignPanelOverlay = useCallback(
    (ref: MultiSelect | null, fieldError?: FieldError) => {
      if (!ref) {
        return;
      }

      const elementHeight = ref.getElement().offsetHeight;
      const overlayRef = ref.getOverlay();

      if (!overlayRef) {
        return;
      }

      const overlayHeight = overlayRef.offsetHeight;
      const hasAlreadyLinkedError = fieldError?.type === "alreadyLinked";

      const finalHeight = hasAlreadyLinkedError
        ? `-${overlayHeight + 5}`
        : elementHeight;

      overlayRef.style.top = `${finalHeight}px`;
    },
    [],
  );

  const renderCostCentersErrors = useCallback(() => {
    const costCentersError = errors?.costCenters as unknown as FieldError;

    if (costCentersError?.type !== "alreadyLinked") {
      return null;
    }

    if (!costCentersError?.message) {
      return null;
    }

    const errorsAsList = costCentersError?.message
      ?.split(";")
      .map(e => e?.split(","));

    return errorsAsList?.map((c, index) => {
      const key = `${c[0] + c[1] + index}error`;
      return (
        <p key={key} className="invalid-costcenter">
          O Centro de Custo <strong>{c[0]}</strong> já está vinculado ao projeto{" "}
          <strong>{c[1]}.</strong>
        </p>
      );
    });
  }, [errors.costCenters]);

  return (
    <Controller
      control={control}
      name="costCenters"
      rules={{
        required: true,
        validate: {
          alreadyLinked: validateCostCenters,
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <>
            <label className="col-12 form-control">
              <span>Centro de Custo</span>
              <Container>
                <MultiSelect
                  {...field}
                  display="chip"
                  appendTo="self"
                  disabled={disabled}
                  optionLabel="acronym"
                  removeIcon="pi pi-times"
                  itemTemplate={itemTemplate}
                  placeholder="Pesquise uma opção"
                  panelClassName="multiselect-panel"
                  panelHeaderTemplate={headerTemplate}
                  options={options.length ? options : [EMPTY]}
                  itemClassName={options.length ? "" : "empty-message"}
                  className={`costCenters-multiselect ${
                    fieldState.error ? "isInvalid" : ""
                  }`}
                  ref={originalRef => {
                    alignPanelOverlay(originalRef, fieldState.error);
                    field.ref(originalRef);
                  }}
                />
                {isLoading ? (
                  <LoadingContainer>
                    <i className="pi pi-spin pi-spinner" />
                  </LoadingContainer>
                ) : null}
                <InvalidFeedback
                  message="Este campo é obrigatório"
                  condition={fieldState?.error?.type === "required"}
                />
              </Container>
            </label>
            {renderCostCentersErrors()}
          </>
        );
      }}
    />
  );
}
