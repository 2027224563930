import { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useSoulDialog } from "../../hooks/useSoulDialog";
import { InvalidFeedback } from "../InvalidFeedback";
import { SoulSpinner } from "../SoulSpinner";

const Container = styled.div`
  div + div {
    margin-top: 1rem;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    stroke: white;

    & .submitting-spinner {
      width: 1rem;
      height: 1rem;
      position: relative;
      top: 0.125rem;
      margin-left: 0.5rem;
      stroke: white;

      .p-progress-spinner-svg {
        .p-progress-spinner-circle {
          stroke: white !important;
        }
      }
    }
  }

  .txt-area-reason {
    width: 24.75rem;
    margin: 0 auto;

    textarea {
      width: 100%;
      padding: 0.75rem;
      font-family: "Open Sans", sans-serif;
      font-size: 1rem;
      border-radius: 2.84766px;

      &::placeholder {
        color: #bbb;
      }

      + p {
        text-align: left;
      }

      &.isInvalid {
        border: 1px solid var(--soul-red-button);
      }
    }
  }
`;

interface ICancelDialogContentState {
  reason: string;
  isInvalid: boolean;
  sending: boolean;
}

interface ICancelDialogContentProps {
  onSubmit?(reason: string): Promise<void>;
}

export function CancelDialogContent({ onSubmit }: ICancelDialogContentProps) {
  const dialog = useSoulDialog();

  const [state, setState] = useState<ICancelDialogContentState>({
    reason: "",
    isInvalid: false,
    sending: false,
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event?.target?.value || "";

    const isInvalid = value.length === 0;

    setState(prevState => ({
      ...prevState,
      reason: value,
      isInvalid,
    }));
  };

  const handleNotButtonClick = () => {
    dialog.close({
      isConfirmed: false,
      isDenied: false,
      isDismissed: true,
      dismiss: Swal.DismissReason.cancel,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (state.reason === "") {
      setState(prevState => ({
        ...prevState,
        isInvalid: true,
      }));

      return;
    }

    setState(prevState => ({
      ...prevState,
      sending: true,
    }));

    try {
      await onSubmit?.(state.reason);
    } finally {
      setState(prevState => ({
        ...prevState,
        sending: false,
      }));
    }

    dialog.close();
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div>Para cancelar este lançamento, nos diga o motivo:</div>
        <div>
          <div className="txt-area-reason">
            <textarea
              maxLength={500}
              rows={4}
              placeholder="Digite aqui o motivo do cancelamento..."
              className={state.isInvalid ? "isInvalid" : undefined}
              onChange={handleChange}
              value={state.reason}
              disabled={state.sending}
            />
            <InvalidFeedback
              condition={state.isInvalid}
              message="Informar uma razão é obrigatório"
            />
          </div>
        </div>
        <div>Certeza que deseja cancelá-lo?</div>
        <div>
          <button
            type="button"
            className="form-button red-bkg"
            onClick={handleNotButtonClick}
            disabled={state.sending}
          >
            Não
          </button>
          <button
            type="submit"
            className="form-button green-bkg"
            disabled={state.sending}
          >
            Sim
            {state.sending && <SoulSpinner className="submitting-spinner" />}
          </button>
        </div>
      </form>
    </Container>
  );
}
