import BigNumber from "bignumber.js";
import { useFormContext } from "react-hook-form";
import { AssessmentGrid } from "../../../../../../core/presentation/components/AssessmentGrid";
import { IPaymentRequestsAssessmentEntity } from "../../../domain/entities/paymentRequestAssessmentEntity";
import { IPaymentRequestFormEntity } from "../../../domain/entities/paymentRequestFormEntity";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";

interface ISectionAssessmentProps {
  readonly?: boolean;
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function SectionAssessment(props: ISectionAssessmentProps) {
  const { readonly, usePaymentRequestForm } = props;

  const {
    searchCostCenter,
    listActiveCostCenters,
    searchClassificationAssessment,
  } = usePaymentRequestForm;

  const form = useFormContext<IPaymentRequestFormEntity>();

  const { watch, register, setValue } = form;

  const value = watch("value");
  const assessments = watch("assessments");

  const validateAssessments = () => {
    if (readonly) {
      return true;
    }

    const totalAssessmentValue = assessments.reduce((accumulated, current) => {
      return accumulated.plus(current.value);
    }, new BigNumber(0));

    const remainingValue = new BigNumber(value).minus(totalAssessmentValue);

    const isRemainingZero = remainingValue.isZero();

    if (
      !isRemainingZero &&
      remainingValue.isLessThan(0.01) &&
      remainingValue.isGreaterThan(-0.01)
    ) {
      return true;
    }

    return isRemainingZero;
  };

  register("assessments", {
    validate: {
      assessmentValue: validateAssessments,
    },
  });

  const handleAssessmentsChange = (
    newAssessments: IPaymentRequestsAssessmentEntity[],
  ) => {
    setValue("assessments", newAssessments);
  };

  return (
    <AssessmentGrid
      readonly={readonly}
      accountValue={value}
      assessments={assessments}
      searchCostCenter={searchCostCenter}
      shouldShowCalculateAssessmentButton={false}
      listActiveCostCenters={listActiveCostCenters}
      onAssessmentsChange={handleAssessmentsChange}
      searchClassificationAssessment={searchClassificationAssessment}
    />
  );
}
