import { ILinkCompanyGroupsModel } from "../../data/models/linkCompanyGroupsModel";
import { ILinkedCompanyGroupEntity } from "../entities/linkedCompanyGroupEntity";

export interface ILinkCompanyGroupsService {
  linkCompanyGroups(
    userId: string,
    companyGroups: ILinkedCompanyGroupEntity[],
  ): Promise<ILinkCompanyGroupsModel>;
}

export interface ILinkCompanyGroupsUseCase {
  linkCompanyGroups(
    userId: string,
    companyGroups: ILinkedCompanyGroupEntity[],
  ): Promise<ILinkCompanyGroupsModel>;
}

export class LinkCompanyGroupsUseCase implements ILinkCompanyGroupsUseCase {
  constructor(private linkCompanyGroupsService: ILinkCompanyGroupsService) {}

  linkCompanyGroups(
    userId: string,
    companyGroups: ILinkedCompanyGroupEntity[],
  ): Promise<ILinkCompanyGroupsModel> {
    return this.linkCompanyGroupsService.linkCompanyGroups(
      userId,
      companyGroups,
    );
  }
}
