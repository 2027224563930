import Modal from "react-modal";
import { FaDownload } from "react-icons/fa";
import { Container } from "./styles";
import { useGenerateRemittancesModal } from "../../hooks/useGenerateRemittancesModal";
import { RequestProgressModal } from "../RequestProgressModal";

export function GenerateRemittancesModal() {
  const {
    modalState,
    renderModalBody,
    renderModalHeader,
    isCurrentStepValid,
    handleConfirmButton,
    handleTemplateDownload,
    isTemplateButtonVisible,
    generateRemittancesModalOpen,
    handleCloseGenerateRemittancesModal,
  } = useGenerateRemittancesModal();

  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={generateRemittancesModalOpen}
      onRequestClose={handleCloseGenerateRemittancesModal}
    >
      <Container>
        <div className="react-modal-header">{renderModalHeader()}</div>
        <div className="react-modal-body">{renderModalBody()}</div>
        <div className="react-modal-footer">
          {isTemplateButtonVisible && (
            <button
              type="button"
              data-testid="btn-template"
              className="form-button default-button download-template"
              onClick={handleTemplateDownload}
            >
              {modalState.isDownloadingTemplate ? (
                <i className="pi pi-spin pi-spinner" />
              ) : (
                <FaDownload />
              )}
              <span>Baixar Modelo</span>
            </button>
          )}
          <button
            type="button"
            id="btn-close"
            className="form-button red-bkg footer-close-button"
            onClick={handleCloseGenerateRemittancesModal}
          >
            Cancelar
          </button>
          <button
            type="button"
            id="btn-return"
            disabled={modalState.isSubmitting}
            className={`form-button ${
              isCurrentStepValid() ? "green-bkg" : "invalid-bkg"
            }`}
            onClick={handleConfirmButton}
          >
            {modalState.selectValue ? "Confirmar " : "Continuar "}
            {modalState.isSubmitting && <i className="pi pi-spin pi-spinner" />}
          </button>
        </div>
        <RequestProgressModal
          total={modalState.progressModal.total}
          loaded={modalState.progressModal.loaded}
          isOpen={modalState.progressModal.isOpen}
        >
          <p>Por favor, aguarde enquanto processamos o seu arquivo...</p>
        </RequestProgressModal>
      </Container>
    </Modal>
  );
}
