import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IProjectEntity } from "../entities/projectEntity";

export interface IListProjectsService {
  listProjects(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<IProjectEntity[]>>;
}

export interface IListProjectsUseCase {
  listProjects(
    companyGroupId: string,
    actives?: boolean,
  ): Promise<IServerSideResponseModel<IProjectEntity[]>>;
}

export class ListProjectsUseCase implements IListProjectsUseCase {
  constructor(private listProjectsService: IListProjectsService) {}

  listProjects(
    companyGroupId: string,
    actives = true,
  ): Promise<IServerSideResponseModel<IProjectEntity[]>> {
    return this.listProjectsService.listProjects(companyGroupId, actives);
  }
}
