import styled from "styled-components";

export const ActivateContainer = styled.div`
  border: 0;
  border-radius: 5px;

  margin: 1rem;
  padding: 0.75rem;

  text-align: left;
  font-size: 0.9rem;

  background: #fbfbfb;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

  :has(.no-info) {
    text-align: center;
  }

  .no-info {
    color: var(--text-muted);
  }

  small {
    color: var(--text-muted);
  }
`;
