import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetMacroCategoryContract } from "../../domain/contracts/getMacroCategoryContract";
import { IMacroCategoryEntity } from "../../domain/entities/macroCategoryEntity";

export class GetMacroCategoryService implements IGetMacroCategoryContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getMacroCategory(macroCategoryId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/MacroBoxes/${macroCategoryId}`;

    const response = await this.api.get<IMacroCategoryEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const { id, name, active, companyGroupId } = response;

    return {
      id,
      name,
      active,
      companyGroupId,
    };
  }
}
