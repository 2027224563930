import { IEntity } from "../../../core/domain/entities/entity";
import { EFeeExpirationType } from "./feeExpirationTypeEnum";

export interface IFeeEntity extends IEntity {
  companyGroupId: string;
  companyGroupName: string;
  name: string;

  classificationAssessmentId: string;
  providerId: string;
  expirationDay: number | null;
  expirationType: EFeeExpirationType | null;

  value?: number;
}

export class FeeEntity implements IFeeEntity {
  id = "";
  active = true;
  companyGroupId = "";
  companyGroupName = "";
  name = "";
  value?: number | undefined;

  classificationAssessmentId = "";
  providerId = "";
  expirationDay = null;
  expirationType = null;

  constructor(params?: Partial<IFeeEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IFeeEntity>) {
    return new FeeEntity(params);
  }
}

export interface IAccountPayableFeeEntity extends IEntity {
  feeId: string;
  value: number;
  active: boolean;
}

export class AccountPayableFeeEntity implements IAccountPayableFeeEntity {
  id = "";
  feeId = "";
  value = 0;
  active = true;

  constructor(params?: Partial<IAccountPayableFeeEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IAccountPayableFeeEntity>) {
    return new AccountPayableFeeEntity(params);
  }
}
