import { ELocalStorageKeys } from "../../../core/data/utils/localStorageKeys";
import { ICompanyGroupEntity } from "../../domain/entities/companyGroupEntity";
import { ISetCurrentCompanyGroupService } from "../../domain/usecases/setCurrentCompanyGroupUseCase";

export class SetCurrentCompanyGroupService
  implements ISetCurrentCompanyGroupService
{
  companyGroupKey: string = ELocalStorageKeys.CompanyGroup;

  set(companyGroup: ICompanyGroupEntity): void {
    const jsonString = JSON.stringify(companyGroup);
    localStorage.setItem(this.companyGroupKey, jsonString);
  }
}
