import { FaChevronRight } from "react-icons/fa";
import { useWatch } from "react-hook-form";
import { useDebtImportPage } from "../../hooks/useDebtImportPage";
import { Container } from "./styles";
import { IDebtImportForm } from "../../../domain/entities/debtImportTypes";
import { EDebtImportSteps } from "../../../domain/entities/debtImportEnums";

export function DebtImportPageHeader() {
  const {
    form: { control },
    state: { currentStep },
  } = useDebtImportPage();

  const formValues = useWatch<IDebtImportForm, "imports">({
    control,
    name: "imports",
  });

  const stepStates = {
    isFileStep: currentStep === EDebtImportSteps.FILE,
    isValidationStep: currentStep === EDebtImportSteps.VALIDATION,
    isFinalStep: currentStep === EDebtImportSteps.FINAL,
  };

  return (
    <Container>
      <div>
        <span className={stepStates.isFileStep ? "active-step" : ""}>
          1. Importação
        </span>
        <FaChevronRight />
        <span className={stepStates.isValidationStep ? "active-step" : ""}>
          2. Informação das Contas a Pagar
        </span>
        <FaChevronRight />
        <span className={stepStates.isFinalStep ? "active-step" : ""}>
          3. Informações dos Rateios
        </span>
      </div>
      {stepStates.isFileStep ? null : (
        <span>Total de Lançamentos: {formValues.length}</span>
      )}
    </Container>
  );
}
