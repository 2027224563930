import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetCostCenterUsersContract } from "../../domain/contracts/getCostCenterUsersContract";

export class GetCostCenterUsersService implements IGetCostCenterUsersContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getCostCenterUsers(costCenterId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `CostCenters/${costCenterId}/Users`;

    return this.api.get<string[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
