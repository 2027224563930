import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface ISaveCompanyGroupService {
  saveCompanyGroup(
    CompanyGroup: ICompanyGroupEntity,
  ): Promise<ICompanyGroupEntity>;
}

export interface ISaveCompanyGroupUseCase {
  saveCompanyGroup(
    CompanyGroup: ICompanyGroupEntity,
  ): Promise<ICompanyGroupEntity>;
}

export class SaveCompanyGroupUseCase implements ISaveCompanyGroupUseCase {
  constructor(private saveCompanyGroupService: ISaveCompanyGroupService) {}

  saveCompanyGroup(
    CompanyGroup: ICompanyGroupEntity,
  ): Promise<ICompanyGroupEntity> {
    return this.saveCompanyGroupService.saveCompanyGroup(CompanyGroup);
  }
}
