import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .form-row {
    .form-control {
      span {
        white-space: nowrap;
      }

      &.checkbox-label {
        align-self: center;
        justify-content: center;
        min-width: 10rem;
        flex-grow: 0;

        div > span {
          color: #525f7f;
          font-size: 0.869rem;
          font-weight: 600;
          line-height: 1.5;
        }
      }
    }
  }
`;
