import styled, { css } from "styled-components";

interface SideNavProps {
  isExpanded: boolean;
}

export const SideNav = styled.nav<SideNavProps>`
  background: white;
  left: 0;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: block;
  position: fixed;

  /* todo - verificar se é necessario */
  z-index: 900;

  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 60px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  transition: all 0.15s ease;

  .sidenav-header {
    height: 78px;
    display: flex;
    align-items: center;

    .sidenav-brand {
      img {
        height: auto;
        max-height: 100%;
        width: 42px;
        max-width: 100%;
        position: absolute;
        left: 9px;
      }
    }
  }

  .sidenav-collapse-main {
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -1.5rem 0 -1.5rem;
    padding: 0 1.5rem 0 1.5rem;
    display: flex;
    width: 250px;

    ::before {
      content: "";
      margin: 1rem -1rem;
      display: block;
    }

    > ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      min-width: 100%;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      font-size: 1rem;
      line-height: 1.5;
      text-align: left;
    }
  }

  #setExpanded {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) and (min-width: 768px) {
    &:hover {
      max-width: 250px;

      .sidenav-header {
        .sidenav-brand {
          height: 5.625rem;
          padding: 0 1.5rem 0 1.5rem;

          img {
            width: auto;
            position: unset;
          }
        }
      }

      /* UGLY esta foi a unica forma de fazer os textos de cada item da nav
      aparecerem somente quando ela está aberta. O problema é que o elemento
      .nav-link pertence a outro componente da aplicação*/
      .sidenav-collapse-main ul .nav-link {
        span,
        .caret {
          display: block;
        }
      }

      ul.children {
        display: block;
      }
    }
  }

  .spacer {
    height: 12vh;
  }

  @media (pointer: coarse), (max-width: 767px) {
    #setExpanded {
      color: white;
      display: block;
      position: fixed;
      left: 60px;
      top: 0;
      z-index: 910;
      padding: 4px 8px 4px 8px;
      font-size: 18px;
      transition: all 0.15s ease;
    }
  }

  ${props =>
    props.isExpanded &&
    css`
      @media (pointer: coarse), (max-width: 767px) {
        #setExpanded {
          left: 250px;
        }

        max-width: 250px;

        .sidenav-header {
          .sidenav-brand {
            height: 5.625rem;
            padding: 0 1.5rem 0 1.5rem;

            img {
              width: auto;
              position: unset;
            }
          }
        }

        .sidenav-collapse-main > ul .nav-item > .nav-link > span {
          display: block !important;
        }
      }
    `}
`;
