import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { departmentDestinationProfiles } from "../../domain/entities/departmentDestinationProfiles";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";
import { IGetUserService } from "../../domain/usecases/getUserUseCase";
import { IUserRequestProfilesResponse } from "../models/userModel";

export class GetUserService implements IGetUserService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
    private soulApi: IApiService,
  ) {}

  async getUser(userId: string): Promise<IUserEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Users/${userId}`;

    const response = await this.authApi.get<IUserEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const userEntityResponse = new UserEntity(response);

    if (
      departmentDestinationProfiles.includes(Number(userEntityResponse.profile))
    ) {
      const usrRequestProfilesUrl = `/Users/${response?.id}/UserRequestProfiles`;

      const usrRequestProfileResponse =
        await this.soulApi.get<IUserRequestProfilesResponse>(
          usrRequestProfilesUrl,
          {
            headers: {
              Authorization: `Bearer ${userEntity?.token}`,
            },
          },
        );

      userEntityResponse.isFinancial = usrRequestProfileResponse.isFinancial;
      userEntityResponse.isPersonnelDepartment =
        usrRequestProfileResponse.isPersonnelDepartment;
    }

    userEntityResponse.document =
      userEntityResponse.document?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/g,
        "$1.$2.$3-$4",
      ) || "";

    return userEntityResponse;
  }
}
