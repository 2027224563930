import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IFetchCnaeContract } from "../../domain/contracts/fetchCnaeContract";

interface ICnaeResponse {
  id: string;
  code: string;
  description: string;
}

export class FetchCnaeService implements IFetchCnaeContract {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async fetchCnae(): Promise<ITypeaheadOption<object>[]> {
    const url = `/CnaeClassifications`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.get<IResponseEntity<ICnaeResponse[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.data.map<ITypeaheadOption>(cnae => {
      let { code } = cnae;
      code = code.replace(/\D/g, "");

      return {
        label: code,
        rawValue: cnae.id,
        metadata: {
          code,
          id: cnae.id,
          description: cnae.description,
        },
      };
    });
  }
}
