import { format } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { CostCenterEntity } from "../../domain/entities/costCenterEntity";
import {
  CostCenterTransactionInputEntity,
  ICostCenterTransactionInputEntity,
} from "../../domain/entities/costCenterTransactionInputEntity";
import { ICostCenterTransactionEntity } from "../../domain/entities/costCenterTransactionsEntity";
import { IGetCostCenterTransactionService } from "../../domain/usecases/getCostCenterTransactionsUseCase";
import { ICostCenterTransactionAttachmentEntity } from "../../domain/entities/costCenterTransactionAttachmentEntity";

export class GetCostCenterTransactionsService
  implements IGetCostCenterTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCostCenterTransaction(
    costCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionInputEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/CostCenterTransactions/${costCenterTransactionsId}`;

    const response = await this.api.get<ICostCenterTransactionEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const attachments = await this.getAttachments(costCenterTransactionsId);

    const costCenterTransactionsEntity = new CostCenterTransactionInputEntity({
      id: response.id,
      companyGroupId: response.companyGroupId,
      costCenterOrigin: new CostCenterEntity({
        id: response.costCenterOriginId,
      }),
      costCenterDestination: new CostCenterEntity({
        id: response.costCenterDestinationId,
      }),
      transactionDate: format(new Date(response.transactionDate), "ddMMyyyy"),
      value: response.value,
      description: response.description,
      active: response.active,
      attachments,
    });

    return costCenterTransactionsEntity;
  }

  private getAttachments(id: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `CostCenterTransactions/${id}/Attachments`;

    return this.api.get<ICostCenterTransactionAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
