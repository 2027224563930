import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IValidateAttachmentsContract } from "../../domain/contracts/validateAttachmentsContract";
import { IAccountReceivableAttachmentEntity } from "../../domain/entities/accountReceivableAttachmentEntity";

interface IValidateAttachmentsResponse {
  code: null;
  message: string;
  success: boolean;
}

export class ValidateAttachmentsService
  implements IValidateAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async validateAttachments(attachments: IAccountReceivableAttachmentEntity[]) {
    const userEntity = this.getUserLocalService.get();
    const url = "/StorageFiles/Validate";

    let index = 0;
    const formData = new FormData();

    attachments.map(attachmentObject => {
      if (attachmentObject.file) {
        formData.append(`StorageFiles[${index}].File`, attachmentObject.file);
        formData.append(`StorageFiles[${index}].Name`, attachmentObject.name);
        index += 1;
      }

      return null;
    });

    const response = await this.api.post<IValidateAttachmentsResponse>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (!response.success) {
      const errorObject = {
        type: "invalid-attachments",
        ...response,
      };

      throw errorObject;
    }
  }
}
