import { IApiService } from "../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGenerateMultipleVariableAdditionalAttachmentsContract } from "../../domain/contracts/generateMultipleVariableAdditionalAttachmentsContract";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { IDebtImportAttachmentModel } from "../models/debtImportAttachmentModel";

export class GenerateMultipleVariableAdditionalAttachmentsService
  implements IGenerateMultipleVariableAdditionalAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateMultipleVariableAdditionalAttachments(
    importData: IDebtImportEntity[],
  ) {
    const formData = new FormData();
    const userEntity = this.getUserLocalService.get();
    const url = "/AccountPayableImportations/Attachments/AdditionalVariable";

    formData.append("IsPaymentRequest", "false");

    importData.map((data, index) => {
      formData.append(`Uploads[${index}].Name`, "");
      formData.append(`Uploads[${index}].Active`, "true");
      formData.append(`Uploads[${index}].AccountPayableId`, data.id);
      formData.append(
        `Uploads[${index}].Type`,
        EAttachmentType.VariableAdditional.toString(),
      );
      formData.append(
        `Uploads[${index}].TypeDescription`,
        "Adicional Variável",
      );
      formData.append(
        `Uploads[${index}].AdditionalVariableData[ProjectId]`,
        (data.project?.rawValue as string) || "",
      );
      formData.append(
        `Uploads[${index}].AdditionalVariableData[ProviderId]`,
        (data.provider?.rawValue as string) || "",
      );
      formData.append(
        `Uploads[${index}].AdditionalVariableData[ValueMoney]`,
        data.value ? data.value.toString() : "0",
      );
      return data;
    });

    return this.api.post<IDebtImportAttachmentModel[]>(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });
  }
}
