import { Link } from "react-router-dom";
import { Container } from "./styles";
import notFoundImg from "../../../../assets/not-found.svg";
import { Page } from "../Page/styles";

export function NotFoundPage() {
  return (
    <Container>
      <div className="admin-header bg-gradient-soul" />
      <div className="page-content">
        <Page>
          <header>Página não encontrada</header>

          <article>
            <img src={notFoundImg} alt="404" />
            <p>Ah não!</p>

            <p>
              Não conseguimos encontrar a página que você estava procurando.
            </p>

            <Link to="/">Voltar para o dashboard</Link>
          </article>
        </Page>
      </div>
    </Container>
  );
}
