import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveProjectTaxesContract } from "../../domain/contracts/saveProjectTaxesContract";
import { IProjectTaxesEntity } from "../../domain/entities/projectTaxesEntity";

export class SaveProjectTaxesService implements ISaveProjectTaxesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveProjectTaxes(payload: IProjectTaxesEntity[], projectId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Projects/${projectId}/taxes`;

    const formatDate = (date: string) => {
      if (date === "Indeterminado") return "";
      return format(parse(date, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    };

    const finalPayload = payload.map(taxes => {
      const projectTaxes = taxes.projectTaxes.map(tax => {
        return {
          ...tax,
          startDate: tax.startDate ? formatDate(tax.startDate) : "",
          endDate: tax.endDate ? formatDate(tax.endDate) : "",
        };
      });

      return {
        ...taxes,
        projectTaxes,
      };
    });

    return this.api.post<IProjectTaxesEntity[]>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
