import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListAccountsReceivableContract } from "../../domain/contracts/listAccountsReceivableContract";
import { IAccountReceivableParcelListItemEntity } from "../../domain/entities/accountReceivableListItemEntity";

export class ListAccountsReceivableService
  implements IListAccountsReceivableContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listAccountsReceivable(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IAccountReceivableParcelListItemEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        creditCardLimitTransactionId: "creditCardLimitTransactionId",
        valueString: "value",
        launchValueString: "launchValue",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        invoiceStatusString: "invoiceStatus",
        issueDateString: "issueDate",
        receiveUntilString: "receiveUntil",
        terminationDateString: "terminationDate",
        chargebackDateString: "chargebackDate",
        paymentMethodDescription: "paymentMethod",
        documentStatusDescription: "documentStatus",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        returnValueString: "returnValue",
        returnDateString: "returnDate",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsOptions = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/CompanyGroups/${companyGroupId}/AccountsReceivable?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IAccountReceivableParcelListItemEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return {
      ...response,
      data: response.data.map(accountReceivableListEntity => {
        return { ...accountReceivableListEntity };
      }),
    };
  }
}
