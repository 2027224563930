import { EPaymentMethod } from "../../data/models/accountConciliationResponseModel";

export enum EConciliationOrigin {
  expense = 1, // débito
  revenue = 2, // crédito
}

export interface IConciliationEntryEntity {
  id: string | null;
  companyName: string;
  documentNumber: string;
  issueDate: Date | null;
  customerProviderName: string;
  customerProviderDocument: string;
  origin: EConciliationOrigin | null;
  value: number | null;
  parcelNumber: string;
  conciliated: boolean;
  sheetRowNumber: number;

  // as propriedades abaixo nao sao exibiadas na tela mas sao necessarias p/ api
  terminationDate: Date | null;
  paymentMethod: EPaymentMethod | null;
  projectName: string;
  description: string;
  observation: string;
  classificationAssessmentName: string;
  costCenterName: string;
}

export class ConciliationEntryEntity implements IConciliationEntryEntity {
  id = "";
  companyName = "";
  documentNumber = "";
  issueDate = new Date();
  customerProviderName = "";
  customerProviderDocument = "";
  origin = null;
  value = null;
  parcelNumber = "";
  conciliated = false;
  sheetRowNumber = 0;

  terminationDate = new Date();
  paymentMethod = null;
  projectName = "";
  description = "";
  observation = "";
  classificationAssessmentName = "";
  costCenterName = "";

  constructor(init?: Partial<IConciliationEntryEntity>) {
    Object.assign(this, init);
  }
}

export type IConciliationEntryRawEntity = Omit<
  IConciliationEntryEntity,
  "conciliated"
>;

export interface IConciliationEntity {
  checked: boolean;
  providedAccount: IConciliationEntryRawEntity;
  foundAccounts: IConciliationEntryEntity[];
  rowIndex: number;
}

export class ConciliationEntity implements IConciliationEntity {
  checked = false;
  providedAccount = new ConciliationEntryEntity();
  foundAccounts = [];
  rowIndex = 0;

  constructor(init?: Partial<IConciliationEntity>) {
    Object.assign(this, init);
  }
}
