import { format, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { Controller, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import { ConfirmDialogContainer, Container } from "./styles";
import { IChangeSelectedCompaniesLockDatesParams } from "../../../domain/contracts/changeSelectedCompaniesLockDatesContract";
import { IChangeFilteredCompaniesLockDatesParams } from "../../../domain/contracts/changeFilteredCompaniesLockDatesContract";
import { OnCloseModalAction } from "../CompanyPage";

interface CompanyLockDateModalProps {
  isOpen: boolean;
  currentFilter: string;
  selectedCompanies: ICompanyEntity[];
  onClose(action?: OnCloseModalAction): void;
  changeSelectedCompaniesLockDates(
    params: IChangeSelectedCompaniesLockDatesParams,
  ): Promise<unknown>;
  changeFilteredCompaniesLockDates(
    params: IChangeFilteredCompaniesLockDatesParams,
  ): Promise<unknown>;
}

interface CompanyLockDateForm {
  newLockDate: string;
}

export function CompanyLockDateModal(props: CompanyLockDateModalProps) {
  const {
    isOpen,
    onClose,
    currentFilter,
    selectedCompanies,
    changeSelectedCompaniesLockDates,
    changeFilteredCompaniesLockDates,
  } = props;

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<CompanyLockDateForm>({
    mode: "all",
    defaultValues: {
      newLockDate: "",
    },
  });

  const dialog = useSoulDialog();
  const invalidDate = useDateValidator();

  const displayConfirmDialog = ({ newLockDate }: CompanyLockDateForm) => {
    const isAllRegisters = !selectedCompanies.length;

    const parsedDate = parse(newLockDate, "ddMMyyyy", new Date());
    const formattedLockDate = format(parsedDate, "dd/MM/yyyy");

    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      html: (
        <>
          A data de travamento será <strong>alterada para todas</strong> as
          Empresas{" "}
          {isAllRegisters ? <strong>existentes</strong> : "selecionadas"}.
          <br />
          <br />
          Deseja prosseguir?
          <br />
          {!isAllRegisters ? (
            <ConfirmDialogContainer>
              {selectedCompanies.map(company => {
                const currentLockDate = company?.lockReleasesUntil
                  ? new Date(company.lockReleasesUntil)
                  : "";

                const currentFormattedDate = currentLockDate
                  ? format(currentLockDate, "dd/MM/yyyy")
                  : "";

                return (
                  <div key={company.id} className="warning-lines">
                    <span>{company.assumedName}</span>
                    <span>
                      {currentFormattedDate || <em>(sem valor)</em>} para{" "}
                      {formattedLockDate}
                    </span>
                  </div>
                );
              })}
            </ConfirmDialogContainer>
          ) : null}
        </>
      ),
    });
  };

  const handleChangeLockDates = ({ newLockDate }: CompanyLockDateForm) => {
    const isAllRegisters = !selectedCompanies.length;

    if (isAllRegisters) {
      return changeFilteredCompaniesLockDates({
        newLockDate,
        search: currentFilter,
      });
    }

    return changeSelectedCompaniesLockDates({
      newLockDate,
      selectedCompanies,
    });
  };

  const onValid = async (formValues: CompanyLockDateForm) => {
    const response = await displayConfirmDialog(formValues);

    if (response.dismiss) {
      onClose();
      return;
    }

    try {
      await handleChangeLockDates(formValues);
    } catch {
      return;
    }

    dialog.fire({
      icon: "success",
      title: "Feito!",
      html: "Operação realizada com sucesso.",
    });

    onClose(OnCloseModalAction.RefreshAndDeselect);
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalAfterClose = () => {
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      shouldCloseOnEsc={!isSubmitting}
      onRequestClose={handleModalClose}
      onAfterClose={handleModalAfterClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar data de travamento</h4>
          <button
            type="button"
            id="btn-lock-date-cross"
            onClick={handleModalClose}
            className="react-modal-close"
            data-testid="btn-lock-date-cross"
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          <form
            className="form-container"
            id="company-lock-date-form"
            onSubmit={handleSubmit(onValid)}
          >
            <div className="form-row">
              <Controller
                control={control}
                name="newLockDate"
                rules={{
                  required: true,
                  validate: {
                    validDate: v => !invalidDate(v),
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <label className="col-12 form-control">
                      <span>Travar lançamentos até</span>
                      <InputMask
                        unmask
                        {...field}
                        autoClear={false}
                        mask="99/99/9999"
                        placeholder="Insira uma data"
                        className={fieldState.error ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        condition={fieldState.error?.type === "validDate"}
                        message="A data inserida está inválida"
                      />
                      <InvalidFeedback
                        condition={fieldState.error?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                    </label>
                  );
                }}
              />
            </div>
          </form>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            id="btn-lock-date-close"
            onClick={handleModalClose}
            className="form-button red-bkg"
            data-testid="btn-lock-date-close"
          >
            Voltar
          </button>
          <button
            type="submit"
            id="btn-lock-date-save"
            disabled={isSubmitting}
            data-testid="btn-lock-date-save"
            form="company-lock-date-form"
            className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
          >
            {isSubmitting ? "Salvando " : "Salvar "}
            {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
