import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveProviderImportationService } from "../../domain/usecases/saveProviderImportationUseCase";

export class SaveProviderImportationService
  implements ISaveProviderImportationService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveProviderImportationService(file: File): Promise<unknown> {
    const userEntity = this.getUserLocalService.get();

    const url = "ProviderImportations";

    const data = new FormData();

    data.append("file", file);

    const response = this.api.post<unknown>(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
