import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../domain/usecases/setServerSideUseCase";
import { IApiService } from "./apiService";

export class SetServerSideService implements ISetServerSideService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async setServerSide(payload: IPayloadEntity): Promise<string> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Utils/Cache/SetServerSide`;

    return this.api.post<string>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
