import { format, parse } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IPaymentAccountTransactionEntity,
  PaymentAccountTransactionEntity,
} from "../../domain/entities/paymentAccountTransactionEntity";
import { IPaymentAccountTransactionInputEntity } from "../../domain/entities/paymentAccountTransactionInputEntity";
import { ISavePaymentAccountTransactionService } from "../../domain/usecases/savePaymentAccountTransactionUseCase";
import { PaymentAccountTransactionInputModel } from "../models/paymentAccountTransactionInputModel";

export class SavePaymentAccountTransactionService
  implements ISavePaymentAccountTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async savePaymentAccountTransaction(
    paymentAccountTransaction: IPaymentAccountTransactionInputEntity,
  ): Promise<IPaymentAccountTransactionEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentAccountTransactions`;

    const { transactionDate } = paymentAccountTransaction;

    const payload = new PaymentAccountTransactionInputModel({
      id: paymentAccountTransaction.id,
      companyGroupId: paymentAccountTransaction.companyGroupId,
      companyId: paymentAccountTransaction.company?.id,
      paymentAccountOriginId:
        paymentAccountTransaction.paymentAccountOrigin?.id,
      paymentAccountDestinationId:
        paymentAccountTransaction.paymentAccountDestination?.id,
      transactionDate: format(
        parse(transactionDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      ),
      value: paymentAccountTransaction.value,
      description: paymentAccountTransaction.description,
      active: paymentAccountTransaction.active,
    });

    let response;

    if (paymentAccountTransaction.id === "") {
      response = await this.api.post<IPaymentAccountTransactionEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    } else if (paymentAccountTransaction.id !== "") {
      response = await this.api.put<IPaymentAccountTransactionEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    }

    return new PaymentAccountTransactionEntity(response);
  }
}
