import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .loading-container {
    padding: 1.25rem;
    text-align: center;
  }

  div.react-modal-header {
    padding-bottom: unset;
  }

  .crud-header {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  form {
    > .form-section {
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.05);

      &:first-of-type {
        border: unset;
        padding: unset;
      }

      &:last-of-type {
        margin: unset;
      }

      > p {
        color: #525f7f;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;
