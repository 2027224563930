import { InputMask } from "primereact/inputmask";
import { ChangeEvent, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { ProviderFormModalState } from "../ProviderFormModal";
import { ProviderType } from "../../../domain/entities/providerEntity";

interface IndividualPersonFormProps {
  useProvider: MakeProvider;
  sexTypes: ProviderFormModalState["enums"]["sexTypes"];
  maritalStatus: ProviderFormModalState["enums"]["sexTypes"];
}

export function IndividualPersonForm(props: IndividualPersonFormProps) {
  const { useProvider, sexTypes, maritalStatus } = props;

  const { checkCpf } = useProvider;

  const dateValidator = useDateValidator();

  const {
    watch,
    register,
    getFieldState,
    formState: { errors },
  } = useFormContext<ProviderForm<"individualPerson">>();

  const type = watch("type");
  const providerId = watch("id");
  const refPrevDocument = useRef("");

  const validateDocument = async (document: string) => {
    const { error } = getFieldState("document");
    const defaultDocLength = 11;

    if (
      document.length === defaultDocLength &&
      refPrevDocument.current !== document
    ) {
      refPrevDocument.current = document;
      const { Code, success } = await checkCpf(document, providerId);

      if (success === false) {
        return "existingDocument";
      }
      if (Code === 100) {
        return "invalidDocument";
      }
      return true;
    }

    if (
      document.length < defaultDocLength &&
      refPrevDocument.current !== document
    ) {
      refPrevDocument.current = document;
      return "required";
    }

    if (error) return error.message;

    return true;
  };

  const validateBirthDate = (birthDate: string) => {
    if (birthDate.length > 0) {
      const invalidDate = dateValidator(birthDate);

      return invalidDate ? "invalidDate" : true;
    }
    return true;
  };

  if (type !== ProviderType.individualPerson) {
    return null;
  }

  return (
    <div className="form-section">
      <p>Dados Pessoa Física</p>
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Nome</span>
          <input
            maxLength={100}
            placeholder="Nome"
            data-testid="txt-name"
            className={errors?.name ? "isInvalid" : ""}
            {...register("name", { required: true, shouldUnregister: true })}
          />
          <InvalidFeedback
            condition={errors?.name?.type === "required"}
            message="Este campo é obrigatório"
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Sexo <small>(opcional)</small>
          </span>
          <Controller
            name="sexType"
            shouldUnregister
            defaultValue=""
            render={({ field }) => {
              const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                const chosenValue = event.target.value;

                if (chosenValue === "") {
                  field.onChange("");
                  return;
                }

                field.onChange(Number(chosenValue));
              };

              return (
                <select {...field} onChange={onChange}>
                  <option value="">Selecione</option>
                  {sexTypes.map(sexEnum => {
                    return (
                      <option key={sexEnum.key} value={sexEnum.key}>
                        {sexEnum.value}
                      </option>
                    );
                  })}
                </select>
              );
            }}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            Estado civil <small>(opcional)</small>
          </span>
          <Controller
            name="maritalStatus"
            defaultValue=""
            shouldUnregister
            render={({ field }) => {
              const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                const chosenValue = event.target.value;

                if (chosenValue === "") {
                  field.onChange("");
                  return;
                }

                field.onChange(Number(chosenValue));
              };

              return (
                <select {...field} onChange={onChange}>
                  <option value="">Selecione</option>
                  {maritalStatus.map(statusEnum => {
                    return (
                      <option key={statusEnum.key} value={statusEnum.key}>
                        {statusEnum.value}
                      </option>
                    );
                  })}
                </select>
              );
            }}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>CPF</span>
          <Controller
            name="document"
            shouldUnregister
            rules={{
              required: true,
              maxLength: 11,
              validate: v => validateDocument(v),
            }}
            render={({ field, fieldState }) => (
              <>
                <InputMask
                  unmask
                  autoClear={false}
                  placeholder="CPF"
                  value={field.value}
                  mask="999.999.999-99"
                  onChange={field.onChange}
                  data-testid="txt-document"
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={
                    fieldState?.error?.type === "required" ||
                    fieldState?.error?.message === "required"
                  }
                  message="Este campo é de preenchimento obrigatório"
                />
                <InvalidFeedback
                  condition={fieldState?.error?.message === "existingDocument"}
                  message="Já temos uma pessoa física cadastrada com este CPF"
                />
                <InvalidFeedback
                  condition={fieldState?.error?.message === "invalidDocument"}
                  message="CPF inválido"
                />
              </>
            )}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            RG <small>(opcional)</small>
          </span>
          <input
            maxLength={20}
            placeholder="RG"
            data-testid="txt-rg"
            {...register("identityCard", { shouldUnregister: true })}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Órgão Expedidor <small>(opcional)</small>
          </span>
          <input
            maxLength={15}
            placeholder="Órgão expedidor"
            {...register("issuingAuthority", { shouldUnregister: true })}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            Validade do RNE <small>(opcional)</small>
          </span>
          <Controller
            name="validityRNE"
            shouldUnregister
            rules={{
              validate: v => validateBirthDate(v ?? ""),
            }}
            render={({ field, fieldState }) => (
              <>
                <InputMask
                  unmask
                  autoClear={false}
                  mask="99/99/9999"
                  value={field.value}
                  placeholder="dd/mm/aaaa"
                  onChange={field.onChange}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.message === "invalidDate"}
                  message="Este campo é opcional, porém a data inserida está inválida"
                />
              </>
            )}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Data de nascimento <small>(opcional)</small>
          </span>
          <Controller
            name="birthDate"
            shouldUnregister
            rules={{
              validate: v => validateBirthDate(v ?? ""),
            }}
            render={({ field, fieldState }) => (
              <>
                <InputMask
                  unmask
                  autoClear={false}
                  mask="99/99/9999"
                  value={field.value}
                  placeholder="dd/mm/aaaa"
                  onChange={field.onChange}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={fieldState?.error?.message === "invalidDate"}
                  message="Este campo é opcional, porém a data inserida está inválida"
                />
              </>
            )}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            E-mail <small>(opcional)</small>
          </span>
          <input
            maxLength={100}
            placeholder="E-mail"
            className={errors?.email ? "isInvalid" : ""}
            {...register("email", {
              shouldUnregister: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Formato de e-mail inválido",
              },
            })}
          />
          <InvalidFeedback
            condition={errors?.email?.type === "pattern"}
            message={errors?.email?.message || "E-mail inválido"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            PIS/PASEP <small>(opcional)</small>
          </span>
          <Controller
            name="pisPasep"
            shouldUnregister
            render={({ field }) => (
              <InputMask
                unmask
                value={field.value}
                mask="999.99999.99-9"
                placeholder="PIS/PASEP"
                onChange={field.onChange}
              />
            )}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            Profissão <small>(opcional)</small>
          </span>
          <input
            placeholder="Profissão"
            {...register("profession", { shouldUnregister: true })}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Telefone Residencial <small>(opcional)</small>
          </span>
          <Controller
            name="landlinePhoneNumber"
            shouldUnregister
            rules={{
              maxLength: 10,
              minLength: 10,
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <InputMask
                    unmask
                    maxLength={10}
                    autoClear={false}
                    value={field.value}
                    mask="(99) 9999-9999"
                    onChange={field.onChange}
                    placeholder="Telefone Residencial"
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={!!fieldState?.error}
                    message="Formato de telefone inválido"
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            Telefone Celular <small>(opcional)</small>
          </span>
          <Controller
            name="phoneNumber"
            shouldUnregister
            rules={{
              maxLength: 11,
              minLength: 11,
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <InputMask
                    unmask
                    maxLength={11}
                    autoClear={false}
                    value={field.value}
                    mask="(99) 99999-9999"
                    onChange={field.onChange}
                    placeholder="Telefone Celular"
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={!!fieldState?.error}
                    message="Formato de telefone inválido"
                  />
                </>
              );
            }}
          />
        </label>
      </div>
    </div>
  );
}
