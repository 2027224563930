import { useCallback } from "react";

export function useDocumentFormatter() {
  return useCallback((document: string) => {
    const cpfRegExp = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    const cnpjRegExp = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

    /** Caso o documento seja vazio, retorna uma string vazia. */
    if (!document) return "";

    /** Caso o documento não seja nem CPF nem CNPJ, retorna o documento sem formatação. */
    if (document.length !== 11 && document.length !== 14) return document;

    const regex = document.length === 11 ? cpfRegExp : cnpjRegExp;
    const format = document.length === 11 ? "$1.$2.$3-$4" : "$1.$2.$3/$4-$5";

    return document.replace(regex, format);
  }, []);
}
