import styled from "styled-components";

export const Toggler = styled.div`
  pointer-events: none;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  background-color: var(--soul-color01);
  margin-left: 0;
  transition: margin-left 0.12s ease-out;

  &.toggled {
    margin-left: 1.875rem;
  }
`;

export const Label = styled.div`
  pointer-events: none;
  position: absolute;
  color: var(--soul-color01);
  left: 1.45rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  transition: left 0.12s ease-out;

  &.toggled {
    left: 0.45rem;
  }
`;

export const Container = styled.div`
  button {
    pointer-events: auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: start;
    width: 3.4375rem;
    height: 1.5rem;
    border: 1px solid var(--soul-color01);
    border-radius: 3rem;
    padding: 0.125rem;
    background-color: transparent;
    transition: all 0.15s ease-in;

    &:hover:not(:disabled) {
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
      transform: translateY(-1px);
    }

    &:disabled {
      filter: opacity(0.5);
    }
  }

  &.toggle-muted {
    button {
      filter: opacity(0.5);
      border: 1px solid var(--text-muted);

      ${Toggler} {
        background-color: var(--text-muted);
      }

      ${Label} {
        color: var(--text-muted);
      }
    }
  }
`;
