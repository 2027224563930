import jsonp from "jsonp";
import {
  CnpjData,
  IGetCnpjDataContract,
} from "../../domain/contracts/getCnpjDataContract";

export class GetCnpjDataService implements IGetCnpjDataContract {
  getCnpjData(cnpj: string) {
    const url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;

    return new Promise<CnpjData | null>(resolve => {
      jsonp(url, { timeout: 3000 }, (error, data) => {
        if (error) {
          resolve(null);
          return;
        }

        const response = {
          email: data.email,
          name: data.fantasia,
          stateRegistration: "",
          municipalRegistration: "",
          corporationName: data.nome,
          document: data.cnpj.replace(/\D/g, ""),
          phoneNumber: data.telefone.replace(/\D/g, ""),
        };

        resolve(response);
      });
    });
  }
}
