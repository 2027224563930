import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISavePaymentAccountContract } from "../../domain/contracts/savePaymentAccountContract";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";
import { IPaymentAccountFormEntity } from "../../domain/entities/paymentAccountFormEntity";

export class SavePaymentAccountService implements ISavePaymentAccountContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  savePaymentAccount(payload: IPaymentAccountFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PaymentAccounts";

    return this.api.post<IPaymentAccountEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
