import { Accept, useDropzone } from "react-dropzone";
import { FaDownload } from "react-icons/fa";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import Modal from "react-modal";
import { Container, DropzoneContainer } from "./style";
import excelThumbnail from "../../../../assets/excel-file-image.png";
import pdfThumbnail from "../../../../assets/pdf-icon.png";
import imageThumbnail from "../../../../assets/image-file.png";
import { useSoulDialog } from "../../hooks/useSoulDialog";

/**
 * Objeto com o tipo padrão de arquivo aceito (no caso, planilhas Excel)
 */
const sheetType = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel": [".xls"],
};

interface ImportFileModalProps {
  files: File[]; // Lista de arquivos
  accept?: Accept; // Objeto contendo os MIME types e suas extensões que são permitidas
  isOpen: boolean; // Indicador para exibir o diálogo
  multiple?: boolean; // Indica se haverão múltiplas importações
  errorText?: string; // Texto de erro a ser exibido caso um arquivo inválido seja importado
  modalTitle?: string; // Título do diálogo de importação
  placeholder?: string; // Texto a ser exibido na área de importação
  closeButtonText?: string; // Texto do botão de rodapé que fecha o diálogo
  submitButtonText?: string; // Texto do botão de rodapé que finaliza a importação
  filesHandler: () => void; // Função que lida com os arquivos importados
  templateHandler?: () => void; // Função que lida com a solicitação para baixar um modelo
  onRequestClose: () => void; // Função que fecha o diálogo
  setFiles: (files: File[]) => void; // Função que atualiza a lista de arquivos
  _ariaHideApp?: boolean; // TEST ONLY - Não utilizar para código produtivo, apenas para testes.
}

/**
 * Lida com importações de arquivos. Por padrão, a importação é única e permite
 * apenas arquivos de Excel (.xlsx).
 */
export function ImportFileModal({
  files,
  isOpen,
  setFiles,
  modalTitle,
  filesHandler,
  onRequestClose,
  templateHandler,
  multiple = false,
  accept = sheetType,
  closeButtonText = "Voltar",
  submitButtonText = "Confirmar",
  placeholder = "Selecione ou arraste sua planilha aqui",
  errorText = "Apenas planilhas em formato .xls/.xlsx são aceitas",
  _ariaHideApp = true,
}: ImportFileModalProps) {
  const alertDialog = useSoulDialog();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    maxSize: 104857600,
    onDropAccepted: accepted => {
      setFiles(accepted);
    },
    onDropRejected: () => {
      alertDialog.fire("Formato Inválido", errorText, "error");
    },
  });

  /**
   * Remove um determinado arquivo da lista de arquivos selecionados.
   * @param file - Arquivo que será removido.
   * @param event - Evento emitido pelo clique do botão de remover arquivo.
   */
  const removeFile = (
    file: File,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const fileIndex = files.indexOf(file);
    const filteredList = files.filter(
      _file => files.indexOf(_file) !== fileIndex,
    );
    setFiles(filteredList);
  };

  /**
   * Define o ícone a ser exibido na prévia de arquivos.
   * @param file - Arquivo a ser exibido.
   * @returns O caminho para o ícone a ser exibido.
   */
  const definePreviewIcon = (file: File) => {
    const fileExt = file.type;
    if (fileExt.includes("pdf")) return pdfThumbnail;
    if (fileExt.includes("image")) return imageThumbnail;
    return excelThumbnail;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      ariaHideApp={_ariaHideApp}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{modalTitle || "Importar"}</h4>
          <button
            type="button"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          {/* TODO substituir esse bloco do dropzone pelo componente generico FileUpload */}
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} data-testid="dropzone-input" />
            {!files?.length ? <p>{placeholder}</p> : null}
            {files?.length
              ? files.map(file => {
                  const fileKey = `${file.name}&${files.indexOf(file)}`;
                  return (
                    <div
                      key={fileKey}
                      className="dropzone-preview"
                      data-testid="dropzone-preview"
                    >
                      <button
                        type="button"
                        data-testid="btn-remove"
                        onClick={$event => {
                          removeFile(file, $event);
                        }}
                      >
                        <IoMdCloseCircle />
                      </button>
                      <img src={definePreviewIcon(file)} alt={file.name} />
                      <p>{file.name}</p>
                    </div>
                  );
                })
              : null}
          </DropzoneContainer>
        </div>
        <div className="col-12 react-modal-footer">
          {templateHandler ? (
            <button
              type="button"
              data-testid="btn-template"
              className="default-button download-template"
              onClick={() => {
                templateHandler();
              }}
            >
              <FaDownload />
              <span>Baixar Modelo</span>
            </button>
          ) : null}
          <button
            type="button"
            className="form-button red-bkg footer-close-button"
            data-testid="btn-close"
            onClick={onRequestClose}
          >
            {closeButtonText}
          </button>
          <button
            type="button"
            disabled={!files?.length}
            data-testid="btn-finish"
            className={`form-button ${
              files?.length ? "green-bkg" : "invalid-bkg"
            }`}
            onClick={() => {
              filesHandler();
            }}
          >
            {submitButtonText}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
