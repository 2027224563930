import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../../advTable/domain/entities/advTableColumn";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IProjectEntity } from "../../domain/entities/projectEntity";
import { ISearchProjectsService } from "../../domain/usecases/searchProjectsUseCase";

export class SearchProjectsService implements ISearchProjectsService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchProjects(
    companyGroupId: string,
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "description",
          name: "description",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: {
        regex: false,
        value: search,
      },
      length: fetchMinLength,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Projects?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IProjectEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(project => {
        return new RelationshipFilterOption({
          rawValue: project.id,
          label: project.description,
        });
      }),
    });
  }
}
