import { useCallback, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { SoulRoutes } from "../../../../admin/domain/entities/soulRoutes";
import { useIsCompanyGroupPecege } from "../../../../admin/presentation/hooks/useIsCompanyGroupPecege";
import soulLogo from "../../../../assets/logo-soul-160x154.png";
import { useSideBarLinks } from "../../hooks/useSideBarLinks";
import { useUserLocal } from "../../hooks/useUserLocal";
import { NavItem } from "../NavItem";
import { SideNav } from "./styles";

export function Sidebar() {
  const { pathname } = useLocation();

  const { sideBarLinks } = useSideBarLinks();
  const { isCompanyGroupPecege } = useIsCompanyGroupPecege();

  const {
    user: { profile },
  } = useUserLocal();

  /** Indica quando a sidebar está expandida. */
  const [isExpanded, setIsExpanded] = useState(true);

  /** Indica qual menu está expandido. */
  const [expandedMenuId, setExpandedMenuId] = useState<string>(() => {
    const currentMenu = sideBarLinks.find(link => {
      const computedLink = link;
      const isChildrenRoute = computedLink?.children?.some(
        ({ path }) => pathname === path,
      );
      return isChildrenRoute;
    });

    return currentMenu ? currentMenu.id : "";
  });

  const handleToggleNavItem = useCallback((id: string) => {
    setExpandedMenuId(old => {
      if (old === id) return "";
      return id;
    });
  }, []);

  return (
    <SideNav isExpanded={isExpanded} className="sidenav">
      <div className="sidenav-header">
        <Link
          className="sidenav-brand"
          id="nav-logo"
          to="/"
          data-soul-route="/"
        >
          <img src={soulLogo} alt="ícone Soul" />
        </Link>
      </div>

      <div className="sidenav-collapse-main">
        <ul>
          {sideBarLinks
            .filter(
              ({ profile: _profile }) => _profile?.includes(profile) ?? true,
            )
            .map(sideBarLink => {
              const { icon, path, title, id, children, childId } = sideBarLink;

              if (children && !children.length) {
                return null;
              }

              return (
                <NavItem
                  id={id}
                  to={path}
                  icon={icon}
                  title={title}
                  key={path || childId}
                  sideBarExpanded={isExpanded}
                  isExpanded={expandedMenuId === id}
                  onExpand={() => {
                    handleToggleNavItem(id);
                  }}
                  data-soul-route={path}
                >
                  {children?.length &&
                    children &&
                    children
                      .sort((childA, childB) => {
                        if (childA.title > childB.title) {
                          return 1;
                        }

                        if (childA.title < childB.title) {
                          return -1;
                        }

                        return 0;
                      })
                      /**
                       * NOTE Aqui incluimos uma regra para mostrar o subitem
                       * Grupos de Empresas (na seção Administração) somente
                       * se o grupo de empresa atual for Pecege, pois somente
                       * usuarios de perfil "Supervisor" do grupo "Pecege"
                       * podem administrar grupos dempresa
                       */
                      .filter(({ profile: _profile, title: _title }) => {
                        if (isCompanyGroupPecege()) {
                          return _profile?.includes(profile) ?? true;
                        }

                        const isCompanyGroupLink =
                          _title === SoulRoutes.COMPANY_GROUP.title;

                        if (isCompanyGroupLink) {
                          return false;
                        }

                        return _profile?.includes(profile) ?? true;
                      })
                      .map(child => {
                        return (
                          <NavItem
                            id={child.id}
                            key={child.path}
                            to={child.path}
                            title={child.title}
                          />
                        );
                      })}
                </NavItem>
              );
            })}
        </ul>
      </div>

      {/* essa div "spacer" colocamos somente para melhorar o conforto do usuário
      ao utilizar a sidebar quando ela apresentar rolagem vertical, desta forma
      o ultimo item da sidebar nao fica no rodapé da página e sim um pouco
      acima */}
      <div className="spacer" />

      <button
        id="setExpanded"
        type="button"
        className="link-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <FaBars />
      </button>
    </SideNav>
  );
}
