import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListPaymentAccountsByCompanyContract } from "../../domain/contracts/listPaymentAccountsByCompanyContract";
import { IPaymentAccountEntity } from "../../domain/entitites/paymentAccountEntity";

export class ListPaymentAccountsByCompanyService
  implements IListPaymentAccountsByCompanyContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listPaymentAccountsByCompany(
    companyGroupId: string,
    companyId: string,
  ): Promise<IPaymentAccountEntity[]> {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: {
        regex: false,
        value: "",
      },
      length: 0,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Companies/${companyId}/PaymentAccounts?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IPaymentAccountEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const paymentAccounts = response.data;

    return paymentAccounts;
  }
}
