export interface IIsLoggedInUseCase {
  isLoggedIn(): boolean;
}

export interface IIsLoggedInService {
  isLoggedIn(): boolean;
}

export class IsLoggedInUseCase implements IIsLoggedInUseCase {
  constructor(private isLoggedInService: IIsLoggedInService) {}

  isLoggedIn(): boolean {
    return this.isLoggedInService.isLoggedIn();
  }
}
