import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGenerateAttachmentVariableAdditionalModel } from "../../../../payable/index/data/models/generateAttachmentVariableAdditionalModel";
import { IGenerateAttachmentVariableAdditionalContract } from "../../domain/contracts/generateAttachmentVariableAdditionalContract";
import { IPaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";

export class GenerateAttachmentVariableAdditionalService
  implements IGenerateAttachmentVariableAdditionalContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateAttachmentVariableAdditional(
    paymentRequestForm: IPaymentRequestFormEntity,
  ): Promise<File> {
    let projectId = paymentRequestForm.project?.rawValue;
    projectId = projectId ? (projectId as string) : "";

    let providerId = paymentRequestForm.provider?.rawValue;
    providerId = providerId ? (providerId as string) : "";

    const payload: IGenerateAttachmentVariableAdditionalModel = {
      projectId,
      providerId,
      valueMoney: paymentRequestForm.value,
    };

    const url = "/Downloads/GenerateAttachmentVariableAdditional";
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });

    if (response) {
      const file = this.generateFile(response);

      return file;
    }

    return Promise.reject();
  }

  private generateFile(httpResponse: HttpResponseType): File {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    return new File([data], fileName, {
      type: "application/pdf",
    });
  }
}
