import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IChangeFilteredCostCentersLockDatesContract,
  IChangeFilteredCostCentersLockDatesParams,
} from "../../domain/contracts/changeFilteredCostCentersLockDatesContract";

export class ChangeFilteredCostCentersLockDatesService
  implements IChangeFilteredCostCentersLockDatesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  changeFilteredCostCentersLockDates({
    search,
    newLockDate,
  }: IChangeFilteredCostCentersLockDatesParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "CostCenters/ChangeLockReleasesUntilFromQuery";

    const lockDate = newLockDate ? this.formatDate(newLockDate) : "";

    const payload = {
      search,
      lockDate,
    };

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    const parsed = parse(date, "ddMMyyyy", new Date());
    return format(parsed, "yyyy-MM-dd");
  }
}
