import { addDays, isValid, parse } from "date-fns";
import { useFormContext } from "react-hook-form";
import { IAccountReceivableParcelEntity } from "../../domain/entities/accountReceivableParcelEntity";
import { EAccountReceivableStatus } from "../../domain/entities/accountReceivableEnums";
import { IAccountReceivableFormEntity } from "../../domain/entities/accountReceivableFormEntity";

interface IUseParcelsGeneratorParams {
  value: number;
  isInconsistent: boolean;
  initialReceipt: string;
  numberOfParcels: number | "";
  parcelList: IAccountReceivableParcelEntity[];
}

export function useParcelsGenerator() {
  const { setValue } = useFormContext<IAccountReceivableFormEntity>();

  return (params: IUseParcelsGeneratorParams) => {
    const {
      value,
      parcelList,
      isInconsistent,
      initialReceipt,
      numberOfParcels,
    } = params;

    const safeNumberOfParcels = numberOfParcels || 1;

    const initialReceiptString = parse(
      initialReceipt,
      "dd/MM/yyyy",
      new Date(),
    );

    const isValidDate =
      isValid(initialReceiptString) && initialReceipt.length === 10;

    if (value <= 0 || !isValidDate) {
      return;
    }

    const parcels: IAccountReceivableParcelEntity[] = [];
    let accumulator = 0;

    for (let index = 0; index < safeNumberOfParcels; index += 1) {
      const receiveMonths = addDays(initialReceiptString, index * 30);

      const parcelValue = Number(value) / safeNumberOfParcels;

      accumulator += Number(parcelValue.toFixed(2));

      const status = isInconsistent
        ? EAccountReceivableStatus.Paid
        : EAccountReceivableStatus.Open;

      const terminationDate = isInconsistent
        ? parcelList[index].terminationDate
        : "";

      parcels.push({
        id: "",
        status,
        active: true,
        terminationDate,
        parcelNumber: index + 1,
        receiveUntil: receiveMonths.toLocaleDateString(),
        value: Number(parcelValue.toFixed(2)),
      });
    }

    if (Number(value) - Number(accumulator.toFixed(2)) !== 0.0)
      parcels[safeNumberOfParcels - 1].value = Number(
        (
          Number(parcels[safeNumberOfParcels - 1].value) +
          Number(value) -
          Number(accumulator.toFixed(2))
        ).toFixed(2),
      );

    setValue("accountReceivableParcels", parcels);
  };
}
