import { format, parse } from "date-fns";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { HttpResponseType } from "../../../../../core/data/services/apiService";

export class MainDebtImportService {
  /**
   * Define o valor um campo de typeahead. Quando qualquer um dos campos do
   * objeto, sendo eles `nomeDoCampoId` ou `nomeDoCampoName`, não possuírem
   * um valor definido, o retorno é nulo.
   */
  protected buildTypeaheadObject<T extends object>(
    data: T,
    fieldName: string,
  ): ITypeaheadOption | null {
    const typedFieldName = `${fieldName}Name` as keyof T;
    const typedFieldId = `${fieldName}Id` as keyof T;

    const label = data?.[typedFieldName] as unknown as string;
    const rawValue = data?.[typedFieldId] as unknown as string;

    if (!label || !rawValue) {
      return null;
    }

    return {
      label,
      rawValue,
      metadata: data,
    };
  }

  /**
   * Formata uma data para o formato esperado pelo servidor.
   */
  protected dateFormatter(date: string) {
    if (!date) {
      return "";
    }
    const parsedDate = parse(date, "dd/MM/yyyy", new Date());
    return format(parsedDate, "yyyy-MM-dd");
  }

  /**
   * Gera um arquivo a partir da resposta da requisição, extraindo seu nome
   * e seus dados.
   */
  protected generateFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    return new File([data], fileName, {
      type: "application/pdf",
    });
  }
}
