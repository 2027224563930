import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { useApiResponseErrorHandlers } from "../../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { makeAccountsPayable } from "../../../main/makeAccountsPayable";
import {
  AccountsPayablePageProvider,
  useAccountsPayablePage,
} from "../../hooks/useAccountsPayablePage";
import { AccountPayableFeeFormModal } from "../AccountPayableFeeFormModal";
import { AccountPayableReturnFormModal } from "../AccountPayableReturnFormModal";
import { AccountsPayablePageContextMenu } from "../AccountsPayablePageContextMenu";
import { AccountsPayableTable } from "../AccountsPayableTable";
import { AssessmentModal } from "../AssessmentModal";
import { AttachmentModal } from "../AttachmentModal";
import { BulkTerminateModal } from "../BulkTerminateModal";
import { ExportSheetFealqFormModal } from "../ExportSheetFealqFormModal";
import { ExportSheetFuspFormModal } from "../ExportSheetFuspFormModal";
import { GenerateFeeReportModal } from "../GenerateFeeReportModal";
import { GenerateRemittancesModal } from "../GenerateRemittancesModal";
import { TerminateModal } from "../TerminateModal";
import { Toolbar } from "../Toolbar";
import { Container } from "./styles";

function AccountsPayablePage() {
  const { ref } = useAccountsPayablePage();

  return (
    <Container ref={ref}>
      <Page>
        <header className="custom-header">
          <Toolbar />
        </header>

        <article className="no-padding fill-height">
          <AccountsPayableTable />
          <AccountsPayablePageContextMenu />
          <AccountPayableReturnFormModal />
          <ExportSheetFealqFormModal />
          <ExportSheetFuspFormModal />
          <AssessmentModal />
          <AttachmentModal />
          <TerminateModal />
          <BulkTerminateModal />
          <GenerateFeeReportModal />
          <GenerateRemittancesModal />
          <AccountPayableFeeFormModal />
        </article>
      </Page>
    </Container>
  );
}

interface IAccountsPayablePageFactoryProps {
  api: IApiService;
}

export function AccountsPayablePageFactory({
  api,
}: IAccountsPayablePageFactoryProps) {
  const {
    REACT_APP_SERVER_URL,
    REACT_APP_PECEGE_AUTH_API_URL,
    REACT_APP_API_VERSION,
  } = process.env;

  const authBaseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();
  const authApi = new ApiService(authBaseUrl, apiResponseErrorHandlers);

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const useAccountsPayable = makeAccountsPayable(api, authApi, barcodeApi);

  return (
    <AccountsPayablePageProvider useAccountsPayable={useAccountsPayable}>
      <AccountsPayablePage />
    </AccountsPayablePageProvider>
  );
}
