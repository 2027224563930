import styled from "styled-components";

export const SvgContainer = styled.svg<{ shouldAnimateLogo: boolean }>`
  opacity: 0;
  width: 160px;
  animation: fade-in 1s forwards ease;

  .base {
    offset-rotate: 0deg;
    transform-origin: center;
    offset-path: path(
      "m642.5,614c0,13.53-10.97,24.5-24.5,24.5s-24.5-10.97-24.5-24.5,10.97-24.5,24.5-24.5,24.5,10.97,24.5,24.5Z"
    );

    fill: url(#base-gradient);
    animation: counter-clockwise 3s infinite ease-in-out;
  }

  .middle {
    offset-rotate: 0deg;
    transform-origin: center;
    offset-path: path(
      "m642.5,614c0,13.53-10.97,24.5-24.5,24.5s-24.5-10.97-24.5-24.5,10.97-24.5,24.5-24.5,24.5,10.97,24.5,24.5Z"
    );

    fill: url(#variant-gradient-1);
    mix-blend-mode: multiply;
    animation: clockwise 6s infinite ease;
  }

  .front {
    offset-rotate: 0deg;
    transform-origin: center;
    offset-path: path(
      "m642.5,614c0,13.53-10.97,24.5-24.5,24.5s-24.5-10.97-24.5-24.5,10.97-24.5,24.5-24.5,24.5,10.97,24.5,24.5Z"
    );

    fill: url(#variant-gradient-2);
    mix-blend-mode: multiply;
    animation: counter-clockwise 9s infinite ease-in;
  }

  .text {
    fill: white;
    fill-opacity: 0;

    stroke: white;
    stroke-width: 2px;
    stroke-dasharray: 800;
    stroke-dashoffset: 800;

    animation: dash 4s linear forwards, filling 3s ease-in forwards;

    animation-play-state: ${props =>
      props.shouldAnimateLogo ? "running" : "paused"};
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes filling {
    to {
      fill: white;
      fill-opacity: 1;
    }
  }

  @keyframes counter-clockwise {
    to {
      offset-distance: -100%;
    }
  }

  @keyframes clockwise {
    to {
      offset-distance: 100%;
    }
  }
`;
