import { ProgressSpinner } from "primereact/progressspinner";
import { FormProvider } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useExportSheetFealqFormModal } from "../../hooks/useExportSheetFealqFormModal";
import { ExportSheetFealqInputEndDate } from "../ExportSheetFealqInputEndDate";
import { ExportSheetFealqInputStartDate } from "../ExportSheetFealqInputStartDate";
import { Container } from "./styles";

export function ExportSheetFealqFormModal() {
  const {
    exportSheeFealqFormModalOpen,
    handleClose,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    formProps,
    handleSubmit,
    readOnly,
    isValid,
  } = useExportSheetFealqFormModal();

  return (
    <Modal
      isOpen={exportSheeFealqFormModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.loading}
      onAfterOpen={handleModalAfterOpen}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={!state.loading}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Exportação de Planilha FEALQ</h4>
          {!state.loading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {state.loading && (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        )}

        {!state.loading && (
          <FormProvider {...formProps}>
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="react-modal-body">
                <div className="form-row">
                  <label className="col-6 form-control">
                    <span>Data Inicial</span>
                    <ExportSheetFealqInputStartDate disabled={readOnly} />
                  </label>
                  <label className="col-6 form-control">
                    <span>Data Final</span>
                    <ExportSheetFealqInputEndDate disabled={readOnly} />
                  </label>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    Exportou a última vez: {state.lastExportedUserName}
                  </div>
                </div>
              </div>

              <div className="react-modal-footer">
                <button
                  id="btn-close"
                  type="button"
                  className="form-button red-bkg"
                  onClick={handleClose}
                >
                  Cancelar
                </button>
                <button
                  id="btn-return"
                  type="submit"
                  disabled={state.submitting}
                  className={`form-button ${
                    isValid ? "green-bkg" : "invalid-bkg"
                  }`}
                >
                  Gerar{" "}
                  {state.submitting && <i className="pi pi-spin pi-spinner" />}
                </button>
              </div>
            </form>
          </FormProvider>
        )}
      </Container>
    </Modal>
  );
}
