import { matchPath } from "react-router-dom";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { companyGroupConfig } from "../../domain/entities/companyGroupConfig";
import {
  SoulPages,
  SoulRoute,
  SoulRoutes,
} from "../../domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "./useCurrentCompanyGroup";

interface UseRouterGuardProps {
  currentRoute: string;
  currentProfile: EUserProfile;
}

export function useRouterGuard(props: UseRouterGuardProps) {
  const { currentRoute, currentProfile } = props;
  const {
    currentCompanyGroup: { name: companyGroupName },
  } = useCurrentCompanyGroup();

  const [routeName, routeObject] = Object.entries(SoulRoutes).find(([, data]) =>
    matchPath({ path: data.path, end: false }, currentRoute),
  ) as [SoulPages, SoulRoute];

  const allowedRoutes = companyGroupConfig?.[companyGroupName]?.routes;

  if (allowedRoutes && !allowedRoutes.includes(routeName)) {
    return false;
  }

  return routeObject.profile.includes(currentProfile);
}
