import { IClassificationAssessmentFormEntity } from "../entities/classificationAssessmentFormEntity";

export interface IGetClassificationAssessmentService {
  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentFormEntity>;
}

export interface IGetClassificationAssessmentUseCase {
  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentFormEntity>;
}

export class GetClassificationAssessmentUseCase
  implements IGetClassificationAssessmentUseCase
{
  constructor(
    private getClassificationAssessmentService: IGetClassificationAssessmentService,
  ) {}

  getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentFormEntity> {
    return this.getClassificationAssessmentService.getClassificationAssessment(
      classificationAssessmentId,
    );
  }
}
