import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef } from "react";

export function useCurrentFilterOverlayPanelRef() {
  const ref = useRef<OverlayPanel | null>();

  const setCurrentFilterOverlayPanelRef = (panel: OverlayPanel) => {
    ref.current = panel;
  };

  useEffect(() => {
    return () => {
      ref.current = null;
    };
  });

  return {
    currentFilterOverlayPanelRef: ref,
    setCurrentFilterOverlayPanelRef,
  };
}
