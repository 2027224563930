import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { MakeAccountsPayableForm } from "../../../main/makeAccountPayableForm";
import { useAttachmentsHandler } from "../../hooks/useAttachmentsHandler";

interface ISectionAttachmentsProps {
  readonly?: boolean;
  useAccountsPayableForm: MakeAccountsPayableForm;
}

export function SectionAttachment({
  readonly,
  useAccountsPayableForm,
}: ISectionAttachmentsProps) {
  const {
    attachments,
    attachmentTypeCheck,
    generateVariableAdditionalAttachment,
    generateRpaAttachment,
    updateAttachmentsBarcode,
    handleAttachmentListChange,
    getStorageFilebyId,
    listAttachmentTypes,
    fetchRpaParamOptions,
    fetchCommercialProposalEnums,
    generateCommercialProposalAttachment,
  } = useAttachmentsHandler({ useAccountsPayableForm });

  return (
    <AttachmentsGrid
      disabled={readonly}
      attachments={attachments}
      attachmentTypeCheck={attachmentTypeCheck}
      getStorageFilebyId={getStorageFilebyId}
      listAttachmentTypes={listAttachmentTypes}
      generateRpaAttachment={generateRpaAttachment}
      onAttachmentsChange={handleAttachmentListChange}
      updateAttachmentsBarcode={updateAttachmentsBarcode}
      fetchRpaParamOptions={fetchRpaParamOptions}
      generateVariableAdditionalAttachment={
        generateVariableAdditionalAttachment
      }
      fetchCommercialProposalEnums={fetchCommercialProposalEnums}
      generateCommercialProposalAttachment={
        generateCommercialProposalAttachment
      }
    />
  );
}
