import { useCallback, useRef, useState } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ICompanyGroupEntity } from "../../../domain/entities/companyGroupEntity";
import { useCurrentCompanyGroup } from "../../hooks/useCurrentCompanyGroup";
import { Container, Dropdown } from "./style";
import { useOnClickOutside } from "../../../../core/presentation/hooks/useOnClickOutside";
import { SoulRoutes } from "../../../domain/entities/soulRoutes";

const disabledRoutes = [
  SoulRoutes.CONCILIATION.path,
  SoulRoutes.DEBT_IMPORT.path,
];

export function CompanyGroupDropdown() {
  const ref = useRef(null);

  const [isActive, setIsActive] = useState(false);

  const location = useLocation();
  const { companyGroupList, currentCompanyGroup, setCurrentCompanyGroup } =
    useCurrentCompanyGroup();

  /** Indica se o dropdown deve ser desabilitado. */
  const isDisabled = disabledRoutes.includes(location.pathname);

  /** Gera o texto exibido no tooltip quando o dropdown estiver desabilitado. */
  const disabledTipText = useCallback(() => {
    const actionTexts = {
      [SoulRoutes.CONCILIATION.path]: "conciliação",
      [SoulRoutes.DEBT_IMPORT.path]: "importação",
    };

    const finalText = actionTexts[location.pathname] || "";

    if (!finalText) {
      return "";
    }

    return (
      <p className="disabled-tip">
        Não é possível alterar o<br /> grupo de empresa durante
        <br /> o processo de {finalText}
      </p>
    );
  }, [location.pathname]);

  /** Lida com o click no botão que dispara a abertura do dropdown. */
  const handleTogglerClick = useCallback(() => {
    if (isDisabled) return;
    setIsActive(old => !old);
  }, [isDisabled]);

  /** Lida com a alteração de grupo de empresa. */
  const changeCompanyGroup = useCallback(
    (companyGroup: ICompanyGroupEntity) => {
      setCurrentCompanyGroup(companyGroup);
      setIsActive(false);
    },
    [setCurrentCompanyGroup],
  );

  /** Indica quando um grupo de empresa do dropdown está selecionado. */
  const isActiveSelection = useCallback(
    (companyGroup: ICompanyGroupEntity) => {
      return companyGroup.id === currentCompanyGroup.id ? "active" : "";
    },
    [currentCompanyGroup.id],
  );

  /** Lida com o evento de pressionar o botão Escape do teclado, fechando o dropdown. */
  const onPressEscape = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsActive(false);
    }
  };

  useOnClickOutside(ref, () => {
    setIsActive(false);
  });

  if (location.pathname === SoulRoutes.USERS.path) {
    return null;
  }

  return (
    <Container ref={ref}>
      <button
        type="button"
        data-tip
        onKeyUp={onPressEscape}
        onClick={handleTogglerClick}
        data-for="company-group-dropdown"
        className={`dropdown-button ${isDisabled ? "disabled" : ""}`}
      >
        <span title={currentCompanyGroup?.name}>
          {`${currentCompanyGroup?.name} `}
        </span>
        {companyGroupList?.length > 1 && !isDisabled && (
          <FaAngleDoubleDown className="context-dropdown-icon" />
        )}
      </button>
      <ReactTooltip
        multiline
        place="bottom"
        effect="solid"
        disable={!isDisabled}
        id="company-group-dropdown"
      >
        {disabledTipText()}
      </ReactTooltip>
      <Dropdown isActive={isActive}>
        <div className="dropdown-header">Grupos de Empresa</div>
        {companyGroupList.map(companyGroup => (
          <button
            type="button"
            key={companyGroup.id}
            onClick={() => changeCompanyGroup(companyGroup)}
            className={`dropdown-item ${isActiveSelection(companyGroup)}`}
          >
            <span title={companyGroup.name}>{companyGroup.name}</span>
          </button>
        ))}
      </Dropdown>
    </Container>
  );
}
