import { useCallback } from "react";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";
import { ClassificationAccountFormEntity } from "../../domain/entities/classificationAccountFormEntity";

export function useClassificationAccountForm() {
  return useCallback((classificationAccount: IClassificationAccountEntity) => {
    return new ClassificationAccountFormEntity({
      code: classificationAccount?.code || "",
      name: classificationAccount?.name || "",
      type: classificationAccount?.type || "",
      issueType: classificationAccount?.issueType || "",
    });
  }, []);
}
