import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { RequestProgressCallback } from "../../../index/domain/entities/requestProgressCallbackType";
import { IUploadAttachmentsContract } from "../../domain/contracts/uploadAttachmentContract";
import { IDebtImportAttachmentEntity } from "../../domain/entities/debtImportAttachmentEntity";
import { IDebtImportAttachmentModel } from "../models/debtImportAttachmentModel";

interface IProgressEvent {
  loaded: number;
  total: number;
}

export class UploadAttachmentService implements IUploadAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async uploadAttachments(
    accountPayableId: string,
    attachmentList: IDebtImportAttachmentEntity[],
    uploadProgressCallback: RequestProgressCallback,
  ) {
    const formData = new FormData();

    for (let index = 0; index < attachmentList.length; index += 1) {
      const attachment = attachmentList[index];

      formData.append(`Uploads[${index}].Name`, attachment.name.toString());
      formData.append(`Uploads[${index}].Active`, attachment.active.toString());
      formData.append(`Uploads[${index}].Type`, attachment.type.toString());
      formData.append(
        `Uploads[${index}].TypeDescription`,
        attachment.typeDescription.toString(),
      );

      if (attachment.file) {
        formData.append(`Uploads[${index}].File`, attachment.file);
      }

      if (attachment.barcode) {
        formData.append(`Uploads[${index}].barcode`, attachment.barcode);
      }

      if (attachment.storageFileId) {
        formData.append(
          `Uploads[${index}].storageFileId`,
          attachment.storageFileId,
        );
      }
    }

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountPayableImportations/${accountPayableId}/Attachments`;

    return this.api.post<IDebtImportAttachmentModel[]>(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
      onUploadProgress(progressEvent: IProgressEvent) {
        const { loaded, total } = progressEvent;
        uploadProgressCallback(loaded, total);
      },
    });
  }
}
