import { IEntity } from "../../../core/domain/entities/entity";

export enum EClassificationAssessmentType {
  empty = "", // vazio
  expense = 1, // despesa
  revenue = 2, // receita
}

export interface IClassificationAssessmentEntity extends IEntity {
  name: string;
  type: EClassificationAssessmentType;
  companyGroupId: string;
  companyGroupName?: string;
  paymentType: number | undefined;
  macroBoxId: string;
  macroBoxName: string;
}

export class ClassificationAssessmentEntity
  implements IClassificationAssessmentEntity
{
  name = "";
  type = EClassificationAssessmentType.empty;
  companyGroupId = "";
  id = "";
  active = true;
  paymentType = undefined;
  macroBoxId = "";
  macroBoxName = "";

  constructor(params?: Partial<IClassificationAssessmentEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IClassificationAssessmentEntity>) {
    return new ClassificationAssessmentEntity(params);
  }
}
