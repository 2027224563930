import { useCallback } from "react";
import { IFeeEntity } from "../../domain/entities/feeEntity";
import { FeeFormEntity } from "../../domain/entities/feeFormEntity";

export function useFeeForm() {
  return useCallback((fee: IFeeEntity) => {
    return new FeeFormEntity({
      name: fee?.name || "",
      providerId: fee?.providerId || "",
      expirationDay: fee?.expirationDay || null,
      expirationType: fee?.expirationType || null,
      classificationAssessmentId: fee?.classificationAssessmentId || "",
    });
  }, []);
}
