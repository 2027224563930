import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetProviderByIdContract } from "../../domain/contracts/getProviderByIdContract";
import { IProviderEntity } from "../../../form/domain/entities/providerEntity";

export class GetProviderByIdService implements IGetProviderByIdContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getProviderById(providerId: string): Promise<IProviderEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Providers/${providerId}`;

    const response = await this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    response.birthDate =
      response.birthDate !== "" && response.birthDate !== null
        ? format(new Date(response.birthDate ?? ""), "ddMMyyyy").toString()
        : "";

    response.email = response.email ?? "";

    return response;
  }
}
