import { IApiService } from "../../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../../core/data/services/getUserLocalService";
import { IGetAccountReceivableImportFileTemplateContract } from "../../domain/contracts/getAccountReceivableImportFileTemplateContract";

export class GetAccountReceivableImportFileTemplateService
  implements IGetAccountReceivableImportFileTemplateContract
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getAccountReceivableImportFileTemplate(): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = "/AccountReceivableImportations/Template";

    const fileUrl = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    window.open(fileUrl);
  }
}
