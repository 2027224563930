import { ICustomerTypeEntity } from "../entities/customerTypeEntity";

export interface IGetCustomerTypesUseCase {
  getCustomerTypes(): Promise<ICustomerTypeEntity[]>;
}

export interface IGetCustomerTypesService {
  getCustomerTypes(): Promise<ICustomerTypeEntity[]>;
}

export class GetCustomerTypesUseCase implements IGetCustomerTypesUseCase {
  constructor(private getCustomerTypesService: IGetCustomerTypesService) {}

  getCustomerTypes() {
    return this.getCustomerTypesService.getCustomerTypes();
  }
}
