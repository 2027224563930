import { DropdownChangeParams } from "primereact/dropdown";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTrash } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../../advTable/domain/entities/panoramaEntity";
import { IApiError } from "../../../../../core/data/services/apiService";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { IErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EAccountReceivableStatus,
  EInvoiceStatus,
} from "../../domain/entities/accountReceivableListItemEntity";
import { DialogTextDanger, PanoramaOption } from "../components/Toolbar/styles";
import { useAccountsReceivableGrid } from "./useAccountsReceivableGrid";
import { useAccountsReceivablePage } from "./useAccountsReceivablePage";
import { ProgressModalContent } from "../../../../../core/presentation/components/Modals/ProgressModalContent";

export function useToolbar() {
  const {
    state,
    useAccountsReceivable,
    handlePanoramaChange,
    handleClearButtonClick,
    openBulkTerminateModal,
    accountReceivableParcelListToGenerateInvoiceList,
    openGenerateInvoiceModal,
    clearSelection,
    reload,
  } = useAccountsReceivablePage();

  const {
    storeAccountsReceivablePanoamaId,
    getStoredAccountsReceivablePanoramaId,
    listAccountsReceivablePanoramas,
    saveAccountsReceivablePanorama,
    deleteAccountsReceivablePanorama,
    exportAccountsReceivable,
    removeBulkTerminateAccounts,
  } = useAccountsReceivable;

  /** Esta é a representação do panorama padrão contas a receber */
  const defaultPanorama = useMemo(() => {
    return PanoramaEntity.create({
      id: "default-panorama",
      name: "Padrão do sistema",
      systemDefault: true,
    });
  }, []);

  /** Estados do Panorama */
  const [panoramaOptions, setPanoramaOptions] = useState<IPanoramaEntity[]>();
  const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
  const [selectedPanorama, setSelectedPanorama] =
    useState<IPanoramaEntity>(defaultPanorama);

  const [loading, setLoading] = useState(true);

  const { selection } = state;

  /** Abre modal p/ salvar um novo panorama */
  const handleSavePanoramaButtonClick = useCallback(() => {
    setIsPanoramaModalOpen(true);
  }, []);

  const dialog = useSoulDialog();

  const debounceTime = useDebounceTimeAsync();

  /** reseta o panorama selecionado para o panorama default */
  const resetToPanoramaDefault = useCallback(() => {
    const panoId = defaultPanorama.id;

    storeAccountsReceivablePanoamaId(panoId);
    setSelectedPanorama(defaultPanorama);
    handlePanoramaChange(defaultPanorama);
  }, [defaultPanorama, handlePanoramaChange, storeAccountsReceivablePanoamaId]);

  /** lida com o evento de troca de valor do dropdown de panoramas */
  const handlePanoramaDropdownChange = ({ value }: DropdownChangeParams) => {
    const panorama = value as IPanoramaEntity;
    const panoramaId = panorama.id;

    storeAccountsReceivablePanoamaId(panoramaId);
    setSelectedPanorama(panorama);
    handlePanoramaChange(panorama);
  };

  /** Deleta o panorama cujo botão "Excluir" foi clicado pelo usuario. */
  const handleDeletePanoramaButtonOnClick = useCallback(
    async (option: IPanoramaEntity, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const { name } = option;

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            await deleteAccountsReceivablePanorama(option.id);
          } finally {
            dialog.close();
          }

          return true;
        },
        html: (
          <>
            O panorama <b>{name}</b> será excluído permanentemente.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      setPanoramaOptions(prevPanOptions => {
        const index = prevPanOptions?.findIndex(pan => pan.id === option.id);

        if (index && prevPanOptions) {
          prevPanOptions?.splice(index, 1);
          return [...prevPanOptions];
        }

        return prevPanOptions;
      });

      resetToPanoramaDefault();

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            O panorama <b>{name}</b> foi excluído com sucesso.
          </>
        ),
      });
    },
    [deleteAccountsReceivablePanorama, dialog, resetToPanoramaDefault],
  );

  /** lida com o render das opcoes de panorama do dropdown */
  const renderPanoramaOption = useCallback(
    (option: IPanoramaEntity) => {
      const { name, systemDefault } = option;
      return (
        <PanoramaOption title={name}>
          <span>{name}</span>
          {!systemDefault && (
            <button
              title={`Excluir ${name}`}
              onClick={event =>
                handleDeletePanoramaButtonOnClick(option, event)
              }
              type="button"
            >
              <FaTrash />
            </button>
          )}
        </PanoramaOption>
      );
    },
    [handleDeletePanoramaButtonOnClick],
  );

  const { user } = useUserLocal();
  const { columns } = useAccountsReceivableGrid({ useAccountsReceivable });

  /** lida com o evento de salvamento de um novo panorama */
  const handlePanoramaSave = useCallback(
    async (panoramaData: IPanoramaEntity) => {
      const panoData = {
        ...panoramaData,
        panoramaDefinition: {
          ...panoramaData.panoramaDefinition,
          selectedColumns: state.orderedColNames
            .map(ordColName => {
              const index =
                panoramaData.panoramaDefinition.selectedColumns.findIndex(
                  selCol => {
                    return selCol.field === ordColName;
                  },
                );

              if (index > -1) {
                const [colDef] =
                  panoramaData.panoramaDefinition.selectedColumns.splice(
                    index,
                    1,
                  );

                return colDef;
              }

              return undefined;
            })
            .filter((col): col is IAdvTableColumn => !!col),
        },
      };

      try {
        const newPanorama = await saveAccountsReceivablePanorama(
          columns,
          panoData,
        );

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Panorama adicionado com sucesso.",
        });

        setPanoramaOptions(prevPanoramaOptions => {
          if (prevPanoramaOptions) {
            return [...prevPanoramaOptions, newPanorama];
          }

          return [newPanorama];
        });
        setSelectedPanorama(newPanorama);
        storeAccountsReceivablePanoamaId(newPanorama.id);
      } finally {
        dialog.close();
      }
    },
    [
      columns,
      dialog,
      saveAccountsReceivablePanorama,
      state.orderedColNames,
      storeAccountsReceivablePanoamaId,
    ],
  );

  /**
   * Obtem lista de panoramaas para preencher o dropdown de panoramas,
   * tambem resgata o ultimo panorama utilizado por este usuário e escolhe
   * este no dropdown automaticaemente
   */
  const fetchPanoramas = useCallback(async () => {
    const { userId } = user;

    setLoading(true);

    const panoramas = await listAccountsReceivablePanoramas(userId, columns);

    const panoramaList = [defaultPanorama, ...panoramas];
    setPanoramaOptions(panoramaList);

    setLoading(false);

    const panoramaId =
      getStoredAccountsReceivablePanoramaId() || defaultPanorama.id;

    if (panoramaId) {
      const panorama = panoramaList.find(pano => pano.id === panoramaId);

      if (panorama) {
        setSelectedPanorama(panorama);
        handlePanoramaChange(panorama);
      }
    }
  }, [
    columns,
    defaultPanorama,
    getStoredAccountsReceivablePanoramaId,
    handlePanoramaChange,
    listAccountsReceivablePanoramas,
    user,
  ]);

  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** lida com o evento de click do botao de exportar contas a receber */
  const handleOnExportButtonClick = async () => {
    if (!state.pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ProgressModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const currentCompanyGroupId = currentCompanyGroup.id;

    try {
      await exportAccountsReceivable(
        currentCompanyGroupId,
        state.pfsEvent,
        state.selectedColumns as ISimpleColumn[],
        state.orderedColNames,
      );
    } finally {
      dialog.close();
    }
  };

  /** lida com o evento de botao de baixar contas a receber em lote */
  const handleBulkTerminateButtonClick = () => {
    if (!state.selection?.length) {
      return;
    }

    openBulkTerminateModal();
  };

  const hasSelectedData = !!state.selection?.length;
  const hasNoOpenAccountSelected = !!state.selection?.every(
    a => a.status !== EAccountReceivableStatus.Open,
  );

  const hasNoPaidAccountSelected = !!state.selection?.every(
    a => a.status !== EAccountReceivableStatus.Paid,
  );

  /** Lida com o evento de clique no botão "Retirar baixa selecionado(s)" */
  const handleRemoveBulkTerminateButtonClick = async () => {
    if (!selection) {
      return;
    }

    const parcelsIds = selection.map(selectedAccount => {
      return selectedAccount.id;
    });

    await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      html: (
        <>
          <p>
            Serão retiradas as <strong>baixas</strong> dos lançamentos
            selecionados.
          </p>
          <br />
          <p>Deseja prosseguir?</p>
        </>
      ),
      showCancelButton: true,
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim",
      async preConfirm() {
        dialog.update({
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        dialog.showLoading();

        try {
          await removeBulkTerminateAccounts(parcelsIds);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            html: <p>As baixas foram retiradas com sucesso.</p>,
            showCancelButton: false,
            confirmButtonText: "Sim",
          });

          clearSelection();
          reload();
        } catch (error) {
          const messageHTMLError = (error as IApiError<IErrorResponseEntity>)
            .response.data.MessageHTML;

          const messageError = (error as IApiError<IErrorResponseEntity>)
            .response.data.Details;

          const listErrors = (
            <ul>
              {messageError.map(detailsError => (
                <li>{detailsError}</li>
              ))}
            </ul>
          );

          // UGLY esse timeout foi necessario para chamar a dialog após o interceptor,
          // aparentemente o interceptor está acontecendo após o catch

          await debounceTime(1);

          await dialog.fire({
            icon: "error",
            title: "Opa!",
            html: messageHTMLError || listErrors,
            showCancelButton: false,
          });
        }
        return true;
      },
    });
  };

  const tooltipContainerRef = useRef<HTMLButtonElement>(null);

  const showTooltip = () => {
    if (tooltipContainerRef.current) {
      const tooltipElement = tooltipContainerRef.current;
      ReactTooltip.show(tooltipElement);
    }
  };

  const hideTooltip = () => {
    if (tooltipContainerRef.current) {
      const tooltipElement = tooltipContainerRef.current;
      ReactTooltip.hide(tooltipElement);
    }
  };

  const shouldDisableTerminateButton = useMemo(() => {
    return !hasSelectedData || hasNoOpenAccountSelected;
  }, [hasNoOpenAccountSelected, hasSelectedData]);

  const shouldDisableRemoveTerminateButton = useMemo(() => {
    return !hasSelectedData || hasNoPaidAccountSelected;
  }, [hasSelectedData, hasNoPaidAccountSelected]);

  const handleGenerateInvoiceButtonClick = async () => {
    if (!state.selection) {
      return;
    }

    const hasInconsistent = state.selection.some(account => {
      return account.status === EAccountReceivableStatus.Inconsistent;
    });

    if (hasInconsistent) {
      dialog.fire({
        icon: "warning",
        text: "Atenção!",
        html: (
          <div>
            <div>
              Não é <b>permitido gerar notas fiscais</b> para lançamentos com
              status Inconsistente!
            </div>
            <br />
            <div>
              Você <b>deve retirar</b> a seleção desses lançamentos para poder
              realizar a geração das notas fiscais.
            </div>
          </div>
        ),
      });

      return;
    }

    const filteredSelection = state.selection.filter(account => {
      const { invoiceStatus } = account;

      return (
        invoiceStatus === EInvoiceStatus.Denied ||
        invoiceStatus === EInvoiceStatus.NotGenerated
      );
    });

    const filteredSelectionIds = filteredSelection.map(
      account => account.accountReceivableId,
    );

    // utilizamos o new Set aqui pois queremos uma contagem
    // de lançamentos únicos e não de contagem de parcelas
    const count = new Set(filteredSelectionIds).size;

    let msg = (
      <div>
        <div>
          Você está iniciando o processo de geração de nota fiscal em um total
          de <b>{count} lançamento(s)</b>. Ao confirmar, esse processo{" "}
          <b>não poderá ser desfeito</b>.
        </div>
        <br />
        <div>Deseja prosseguir?</div>
      </div>
    );

    const shouldShowAltMsg =
      state.selection.some(account => {
        const { invoiceStatus } = account;

        return (
          invoiceStatus !== EInvoiceStatus.NotGenerated &&
          invoiceStatus !== EInvoiceStatus.Denied
        );
      }) || false;

    // apresenta caixa de dialogo com texto alternativo pois ha contas
    // selecionadas com status de NF diferentes de "nao gerado" e "negado"
    if (shouldShowAltMsg) {
      msg = (
        <div>
          <div>
            Você está iniciando o processo de geração de nota fiscal em um total
            de <b>{count} lançamento(s)</b>. Ao confirmar, esse processo{" "}
            <b>não poderá ser desfeito</b>.
          </div>
          <br />
          <div>
            <DialogTextDanger>
              <b>
                Somente lançamentos sem nota ou com nota negada serão
                considerados.
              </b>
            </DialogTextDanger>
          </div>
          <br />
          <div>Deseja prosseguir?</div>
        </div>
      );
    }

    const result = await dialog.fire({
      icon: "question",
      title: "Atenção!",
      html: msg,
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
    });

    if (result.isDismissed) {
      return;
    }

    const noSelectionNotGeneratedOrDenied =
      state.selection.every(account => {
        const { invoiceStatus } = account;

        return (
          invoiceStatus !== EInvoiceStatus.NotGenerated &&
          invoiceStatus !== EInvoiceStatus.Denied
        );
      }) || false;

    // se nenhuma das contas selecionadas possui
    // status de NF "nao gerado" ou "negado"
    if (noSelectionNotGeneratedOrDenied) {
      dialog.fire({
        icon: "error",
        title: "Opa!",
        html: (
          <div>
            Nenhum lançamento com status de nota fiscal{" "}
            <b>&ldquo;Não Gerado&rdquo;</b> ou <b>&ldquo;Negado&rdquo;</b> foi
            selecionado.
          </div>
        ),
        confirmButtonText: "Ok",
      });

      return;
    }

    const generateInvoiceList =
      accountReceivableParcelListToGenerateInvoiceList(state.selection);

    openGenerateInvoiceModal(generateInvoiceList);
  };

  useEffect(() => {
    fetchPanoramas();
    // REVIEW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Quando houver dados selecionados na tabela,
   * exibe o tooltip que orienta as acoes em lote
   */
  useEffect(() => {
    if (hasSelectedData) {
      showTooltip();
    } else {
      hideTooltip();
    }
  }, [hasSelectedData]);

  const currentCompanyGroupId = useMemo(
    () => currentCompanyGroup.id,
    [currentCompanyGroup.id],
  );

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      resetToPanoramaDefault();
    }
  }, [currentCompanyGroupId, resetToPanoramaDefault]);

  return {
    loading,
    panoramaOptions,
    selectedPanorama,
    isPanoramaModalOpen,
    setIsPanoramaModalOpen,
    hasSelectedData,
    renderPanoramaOption,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
    showClearButton: state.filtered,
    handleClearButtonClick,
    pfsEvent: state.pfsEvent,
    selectedColumns: state.selectedColumns,
    orderedColNames: state.orderedColNames,
    useAccountsReceivable,
    handlePanoramaSave,
    handleOnExportButtonClick,
    handleBulkTerminateButtonClick,
    tooltipContainerRef,
    hideTooltip,
    shouldDisableTerminateButton,
    shouldDisableRemoveTerminateButton,
    handleGenerateInvoiceButtonClick,
    handleRemoveBulkTerminateButtonClick,
  };
}
