import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListUserRequestsContract } from "../../domain/contracts/listUserRequestsContract";
import { IPaymentRequestEntity } from "../../domain/entities/paymentRequestEntity";

export class ListUserRequestsService implements IListUserRequestsContract {
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listUserRequests(companyGroupId: string, payload: IPayloadEntity) {
    const userEntity = this.getUserLocalService.get();
    const userId = userEntity?.userId || "";

    if (!userId) {
      return Promise.reject();
    }

    const newPayload = {
      ...payload,
      orderableStringField: {
        deleteDateString: "deleteDate",
        valueString: "value",
        payUntilString: "payUntil",
        issueDateString: "issueDate",
        approveDateString: "approveDate",
        rejectDateString: "rejectDate",
        dateModifiedString: "dateModified",
        dateCreatedString: "dateCreated",
        statusDescription: "status",
        destinationDescription: "destination",
        paymentMethodDescription: "paymentMethod",
        documentStatusDescription: "documentStatus",
        deleteReason: "delateReason",
        deleteUserName: "deleteUserName",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsOptions = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsOptions);

    const endpoint = `/CompanyGroups/${companyGroupId}/Users`;
    const url = `${endpoint}/${userId}/PaymentRequests?${params}`;

    return this.api.get<IServerSideResponseModel<IPaymentRequestEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );
  }
}
