import { IUserEntity } from "../../domain/entities/userEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { ELocalStorageKeys } from "../utils/localStorageKeys";

interface IDecryptService {
  decrypt(cyphertext: string): string;
}

export class GetUserLocalService implements IGetUserLocalService {
  userKey: string = ELocalStorageKeys.User;

  constructor(private decryptService: IDecryptService) {}

  get(): IUserEntity | null {
    const encryptedData = localStorage.getItem(this.userKey);

    if (encryptedData) {
      const decryptedData = this.decryptService.decrypt(encryptedData);
      return JSON.parse(decryptedData);
    }

    return null;
  }
}
