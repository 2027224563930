import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateFeeContract } from "../../domain/contracts/updateFeeContract";
import { FeeEntity, IFeeEntity } from "../../domain/entities/feeEntity";

export class UpdateFeeService implements IUpdateFeeContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async updateFee(payload: Partial<IFeeEntity>) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Fee`;

    const finalPayload = {
      id: payload.id,
      name: payload.name,
      active: payload.active,
      providerId: payload.providerId,
      companyGroupId: payload.companyGroupId,
      expirationType: Number(payload.expirationType),
      expirationDay: Number(payload?.expirationDay) || null,
      classificationAssessmentId: payload.classificationAssessmentId,
    };

    const response = await this.api.put<IFeeEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new FeeEntity(response);
  }
}
