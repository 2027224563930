import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { ICheckDocumentEntity } from "../../domain/entities/customerEntity";
import { ICheckCustomerCnpjService } from "../../domain/usecases/checkCustomerCnpjUseCase";

export class CheckCustomerCnpjService implements ICheckCustomerCnpjService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async checkCnpj(cnpj: string, id?: string): Promise<ICheckDocumentEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `Customers/CheckCnpj`;

    const data: { id?: string; cnpj: string } = {
      id: id ?? "",
      cnpj,
    };

    const response = await this.api.post<ICheckDocumentEntity>(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
