import { ComponentProps, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ISearchProviderContract } from "../../../../core/domain/contracts/searchProviderContract";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../core/presentation/components/SoulTypeahead";
import { useDocumentFormatter } from "../../../../core/presentation/hooks/useDocumentFormatter";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../../../provider/domain/entities/providerEntity";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { ClassificationAccountLinkFormModal } from "../../../../provider/presentation/components/ClassificationAccountLinkFormModal";
import { ProviderFormModal } from "../../../../provider/presentation/components/ProviderFormModal";
import { Container } from "./styles";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";

interface ProviderFieldState {
  isLoading: boolean;
  providerModalOpen: boolean;
  options: ITypeaheadOption[];
  classificationModalOpen: boolean;
  providerEntity?: IProviderEntity;
}

type ProviderFieldProps = ComponentProps<typeof SoulTypeahead> & {
  label?: string;
  useProvider: MakeProvider;
  searchProvider: ISearchProviderContract["searchProvider"];
};

export function ProviderField(props: ProviderFieldProps) {
  const { label, value, onChange, useProvider, searchProvider, ...rest } =
    props;

  const { listLinkedClassificationsAccount, getProvider } = useProvider;

  const asyncDebounce = useDebounceTimeAsync();
  const documentFormatter = useDocumentFormatter();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const [state, setState] = useState<ProviderFieldState>({
    options: [],
    isLoading: false,
    providerModalOpen: false,
    classificationModalOpen: false,
  });

  const handleOpenProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: true,
    }));
  };

  const handleCloseProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: false,
    }));
  };

  const handleOpenClassificationAccountLinkModal = (
    providerEntity: IProviderEntity,
  ) => {
    setState(prevState => ({
      ...prevState,
      providerEntity,
      classificationModalOpen: true,
    }));
  };

  const handleCloseClassificationModal = (hasLinked = false) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: false,
      providerEntity: undefined,
    }));

    if (!hasLinked) {
      onChange?.({
        target: { value: null },
      });
    }
  };

  const handleSearchChange = async (search = "") => {
    await asyncDebounce(750);

    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchProvider({
        search,
        companyGroupId,
        payloadOptions: {
          length: search ? undefined : 50,
        },
      });

      setState(prevState => ({
        ...prevState,
        isLoading: false,
        options: response.data,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleProviderChange = async (event: ISoulTypeaheadChangeEvent) => {
    onChange?.(event);

    const selectedProvider = event?.target?.value;

    if (!selectedProvider) {
      return;
    }

    const selectedProviderId = selectedProvider.rawValue as string;

    const classificationAccountList = await listLinkedClassificationsAccount(
      currentCompanyGroup.id,
      selectedProviderId,
    );

    if (
      Array.isArray(classificationAccountList.data) &&
      classificationAccountList.data.length
    ) {
      return;
    }

    const providerEntity = await getProvider(selectedProviderId);

    handleOpenClassificationAccountLinkModal(providerEntity);
  };

  const handleProviderCreated = async (newProvider: IProviderEntity) => {
    const { id, documentType, document, name } = newProvider;

    const isOtherDocType = documentType === ProviderDocumentType.other;

    const docValue = isOtherDocType ? document : documentFormatter(document);

    const optionLabel = `${name} ${document ? `- ${docValue}` : ""}`.trim();

    onChange?.({
      target: {
        value: {
          rawValue: id,
          label: optionLabel,
          metadata: newProvider,
        },
      },
    });

    const classificationAccountList = await listLinkedClassificationsAccount(
      currentCompanyGroup.id,
      newProvider.id,
    );

    if (
      Array.isArray(classificationAccountList.data) &&
      classificationAccountList.data.length
    ) {
      return;
    }

    handleOpenClassificationAccountLinkModal(newProvider);
  };

  return (
    <>
      {label ? (
        <span>
          {label}{" "}
          {state.isLoading ? <i className="pi pi-spin pi-spinner" /> : ""}
        </span>
      ) : null}
      <Container>
        <SoulTypeahead
          serverSide
          openOnFocus
          value={value}
          id="provider"
          options={state.options}
          placeholder="Fornecedor"
          loading={state.isLoading}
          data-testid="txt-provider"
          onChange={handleProviderChange}
          onSearchChange={handleSearchChange}
          notFoundOptionLabel="+ Adicionar Fornecedor"
          onNotFoundOptionSelected={handleOpenProviderModal}
          {...rest}
        />
        <button
          type="button"
          disabled={!value}
          data-effect="solid"
          id="btn-edit-provider"
          data-tip="Editar Fornecedor"
          data-testid="btn-edit-provider"
          className={`btn-edit-provider  ${!value ? "disabled" : ""}`}
          onClick={handleOpenProviderModal}
        >
          <FaEdit />
        </button>
        <ProviderFormModal
          useProvider={useProvider}
          isOpen={state.providerModalOpen}
          onRequestClose={handleCloseProviderModal}
          onProviderCreated={handleProviderCreated}
          currentId={(value?.rawValue as string) || ""}
        />
        <ClassificationAccountLinkFormModal
          required
          useProvider={useProvider}
          isOpen={state.classificationModalOpen}
          providerEntity={state.providerEntity}
          onRequestClose={handleCloseClassificationModal}
        />
      </Container>
    </>
  );
}
