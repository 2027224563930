import { ELocalStorageKeys } from "../../core/data/utils/localStorageKeys";
import { IStoreActiveTabsContract } from "../domain/contracts/storeActiveTabsContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";

export class StoreActiveTabsService implements IStoreActiveTabsContract {
  storeActiveTabs(origin: EImportationMatchesOrigin, activeTabs: string) {
    let storeKey = ELocalStorageKeys.ImportationLMSActiveTabs;

    if (origin === EImportationMatchesOrigin.SOLUTION) {
      storeKey = ELocalStorageKeys.ImportationSolutionActiveTabs;
    }

    localStorage.setItem(storeKey, activeTabs);
  }
}
