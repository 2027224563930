import { ChangeEvent, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IFeeFormEntity } from "../../../domain/entities/feeFormEntity";
import { EFeeExpirationType } from "../../../domain/entities/feeExpirationTypeEnum";

interface ExpirationDayFieldProps {
  readonly: boolean;
}

export function ExpirationDayField({ readonly }: ExpirationDayFieldProps) {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<IFeeFormEntity>();

  /** Observa o valor do input Tipo de Vencimento. */
  const expirationTypeWatcher = watch("expirationType");

  /** Indica se o tipo de vencimento selecionado é o "Dia útil fixo". */
  const isFixedDay =
    Number(expirationTypeWatcher) === EFeeExpirationType.FIXED_DAY;

  /**
   * Lida com o evento de onChange no campo de Data de Vencimento.
   * @param e - Objeto do evento change para o input de texto.
   */
  const handleExpirationDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const digitsOnly = value.replace(/\D/g, "");
    e.target.value = digitsOnly;
  };

  /**
   * Verifica se o dia para vencimento é um valor válido.
   */
  const validExpirationDay = useCallback(
    value =>
      !isFixedDay || (parseInt(value, 10) >= 1 && parseInt(value, 10) < 32),
    [isFixedDay],
  );

  return (
    <>
      <span>Dia para vencimento</span>
      <input
        type="text"
        maxLength={2}
        id="expiration-day"
        data-testid="txt-expiration-day"
        placeholder="Dia para vencimento"
        disabled={readonly || !isFixedDay}
        className={errors.expirationDay ? "isInvalid" : ""}
        {...register("expirationDay", {
          required: isFixedDay,
          onChange: handleExpirationDayChange,
          validate: {
            validExpirationDay,
          },
        })}
      />
      <InvalidFeedback
        message="Este campo é obrigatório"
        condition={errors.expirationDay?.type === "required"}
      />
      <InvalidFeedback
        message="Insira uma data válida"
        condition={errors.expirationDay?.type === "validExpirationDay"}
      />
    </>
  );
}
