import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveCostCentersTransactionsImportationService } from "../../domain/usecases/saveCostCentersTransactionsImportationUseCase";

export class SaveCostCentersTransactionsImportationService
  implements ISaveCostCentersTransactionsImportationService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveCostCentersTransactionsImportation(file: File): Promise<unknown> {
    const userEntity = this.getUserLocalService.get();

    const url = "CostCenterTransactionsImportation";

    const data = new FormData();

    data.append("file", file);

    const response = this.api.post<unknown>(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
