import { IServerSideResponseModel } from "../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../simpleTable/domain/entities/simplePayloadEntity";
import { IListImportationMatchesPaymentAccountsContract } from "../domain/contracts/listImportationMatchesPaymentAccountsContract";
import { IImportationMatchesPaymentAccountEntity } from "../domain/entities/importationMatchesPaymentAccountEntitty";

export class ListImportationMatchesPaymentAccountsService
  implements IListImportationMatchesPaymentAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listImportationMatchesPaymentAccounts(
    payload: IPayloadEntity,
    currentCompanyGroupId: string,
    actives = false,
  ) {
    const userEntity = this.getUserLocalService.get();
    const companyGroupUrl = `CompanyGroups/${currentCompanyGroupId}`;
    const endpoint = `${companyGroupUrl}/PaymentAccounts${
      actives ? "/Actives" : ""
    }`;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `${endpoint}?${params}`;

    return this.api.get<
      IServerSideResponseModel<IImportationMatchesPaymentAccountEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
