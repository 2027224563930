import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveProviderContract } from "../../domain/contracts/saveProviderContract";
import {
  IProviderEntity,
  ProviderDocumentType,
  ProviderType,
} from "../../domain/entities/providerEntity";
import { ProviderForm } from "../../domain/entities/providerForm";

export class SaveProviderService implements ISaveProviderContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveProvider(data: ProviderForm) {
    const url = "/Providers";
    const userEntity = this.getUserLocalService.get();

    const payload = this.generatePayload(data);

    if (data?.id) {
      return this.api.put<IProviderEntity>(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return this.api.post<IProviderEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private generatePayload(data: ProviderForm) {
    const documentTypeByProviderType = {
      [ProviderType.foreign]: ProviderDocumentType.other,
      [ProviderType.individualPerson]: ProviderDocumentType.CPF,
      [ProviderType.legalPerson]: ProviderDocumentType.CNPJ,
    };

    const payload = {
      ...data,
      cityName: data.city?.label,
      cityId: data.city?.rawValue,
      stateName: data.state?.label,
      stateId: data.state?.rawValue,
      countryName: data.country?.label,
      countryId: data.country?.rawValue,
      documentType: documentTypeByProviderType[data.type],
    };

    const mapPayload = new Map(Object.entries(payload));

    if (data.type === ProviderType.individualPerson) {
      const individualPersonForm = data as ProviderForm<"individualPerson">;

      if (individualPersonForm?.birthDate) {
        const formatted = this.formatDate(individualPersonForm.birthDate);
        mapPayload.set("birthDate", formatted);
      }

      if (individualPersonForm?.validityRNE) {
        const formatted = this.formatDate(individualPersonForm.validityRNE);
        mapPayload.set("validityRNE", formatted);
      }
    }

    mapPayload.delete("city");
    mapPayload.delete("state");
    mapPayload.delete("country");

    return Object.fromEntries(mapPayload.entries());
  }

  private formatDate(date: string) {
    const parsedDate = parse(date, "ddMMyyyy", new Date());
    return format(parsedDate, "yyyy-MM-dd");
  }
}
