import { FaChevronRight } from "react-icons/fa";
import styled from "styled-components";
import { EConciliationStep } from "../ConciliationPage";
import { useConciliationContext } from "../../hooks/useConciliationContext";

const Container = styled.div`
  svg {
    fill: var(--soul-color01);
  }

  span {
    color: var(--soul-color01);

    &.active {
      font-weight: 900;
    }
  }

  span + svg {
    margin-left: 0.5rem;
  }

  svg + span {
    margin-left: 0.5rem;
  }
`;

export function Breadcrumb() {
  const {
    state: { step },
  } = useConciliationContext();

  return (
    <Container>
      <span
        className={
          step === EConciliationStep.Importation ? "active" : undefined
        }
      >
        1. Importação
      </span>
      <FaChevronRight />
      <span
        className={
          step === EConciliationStep.Conciliation ? "active" : undefined
        }
      >
        2. Conciliação dos dados
      </span>
      <FaChevronRight />
      <span
        className={step === EConciliationStep.Results ? "active" : undefined}
      >
        3. Resultados
      </span>
    </Container>
  );
}
