import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
} from "react";

type ISoulDocumentInputProps = InputHTMLAttributes<HTMLInputElement>;

// NOTE futuramente podemos incluir opcao de formatacao de cnpj
// considerando o comprimento da string (até 11 digitos é cpf, maior
// que isso formatariamos como cnpj)
function ForwardedSoulDocumentInput(
  {
    id,
    placeholder,
    type,
    className,
    onChange,
    ...rest
  }: ISoulDocumentInputProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) {
  /** formata a string recebida como um numero de cpf, ex.: (123.456.789-00) */
  const maskCPF = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  /**
   * lida com o evento mudanca de valor do campo, responsavel
   * por formatar o valor digitado como um numero de cpf
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEvt = { ...event };

    let value = newEvt?.target?.value || "";

    value = maskCPF(value);

    newEvt.target.value = value;

    onChange?.(newEvt);
  };

  return (
    <input
      ref={forwardedRef}
      id={id}
      placeholder={placeholder}
      type={type}
      className={className}
      onChange={handleChange}
      {...rest}
    />
  );
}

// NOTE utilizamos forwardRef para oferecer suporte a react-hook-form
export const SoulDocumentInput = forwardRef(ForwardedSoulDocumentInput);
