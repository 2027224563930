import { format, isBefore, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { Controller, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ICostCenterEntity } from "../../../domain/entities/costCenterEntity";
import { ConfirmDialogContainer, Container } from "./styles";
import { IChangeSelectedCostCentersLockDatesParams } from "../../../domain/contracts/changeSelectedCostCentersLockDatesContract";
import { IChangeFilteredCostCentersLockDatesParams } from "../../../domain/contracts/changeFilteredCostCentersLockDatesContract";
import { OnCloseModalAction } from "../CostCenterPage";

interface CostCenterLockDateModalProps {
  isOpen: boolean;
  currentFilter: string;
  selectedCostCenters: ICostCenterEntity[];
  onClose(action?: OnCloseModalAction): void;
  changeSelectedCostCentersLockDates(
    params: IChangeSelectedCostCentersLockDatesParams,
  ): Promise<unknown>;
  changeFilteredCostCentersLockDates(
    params: IChangeFilteredCostCentersLockDatesParams,
  ): Promise<unknown>;
}

interface CostCenterLockDateForm {
  newLockDate: string;
}

export function CostCenterLockDateModal(props: CostCenterLockDateModalProps) {
  const {
    isOpen,
    onClose,
    currentFilter,
    selectedCostCenters,
    changeSelectedCostCentersLockDates,
    changeFilteredCostCentersLockDates,
  } = props;

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<CostCenterLockDateForm>({
    mode: "all",
    defaultValues: {
      newLockDate: "",
    },
  });

  const dialog = useSoulDialog();
  const invalidDate = useDateValidator();

  const displayConfirmDialog = ({ newLockDate }: CostCenterLockDateForm) => {
    const isAllRegisters = !selectedCostCenters.length;

    const parsedDate = newLockDate
      ? parse(newLockDate, "ddMMyyyy", new Date())
      : "";

    const formattedNewLockDate = parsedDate
      ? format(parsedDate, "dd/MM/yyyy")
      : "";

    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      html: (
        <>
          A data de travamento será <strong>alterada para todos</strong> os
          Centros de Custo{" "}
          {isAllRegisters ? <strong>existentes</strong> : "selecionados"}.
          <br />
          <br />
          Deseja prosseguir?
          <br />
          {!isAllRegisters ? (
            <ConfirmDialogContainer>
              {selectedCostCenters.map(costCenter => {
                const currentLockDate = costCenter?.lockReleasesUntil
                  ? new Date(costCenter.lockReleasesUntil)
                  : "";

                const formattedDate = currentLockDate
                  ? format(currentLockDate, "dd/MM/yyyy")
                  : "";

                return (
                  <div key={costCenter.id} className="warning-lines">
                    <span>{costCenter.acronym}</span>
                    <span>
                      {formattedDate || <em>(sem valor)</em>} para{" "}
                      {formattedNewLockDate || <em>(sem valor)</em>}
                    </span>
                  </div>
                );
              })}
            </ConfirmDialogContainer>
          ) : null}
        </>
      ),
    });
  };

  const handleChangeLockDates = ({ newLockDate }: CostCenterLockDateForm) => {
    const isAllRegisters = !selectedCostCenters.length;

    if (isAllRegisters) {
      return changeFilteredCostCentersLockDates({
        newLockDate,
        search: currentFilter,
      });
    }

    return changeSelectedCostCentersLockDates({
      newLockDate,
      selectedCostCenters,
    });
  };

  const onValid = async (formValues: CostCenterLockDateForm) => {
    const response = await displayConfirmDialog(formValues);

    if (response.dismiss) {
      onClose();
      return;
    }

    try {
      await handleChangeLockDates(formValues);
    } catch {
      return;
    }

    dialog.fire({
      icon: "success",
      title: "Feito!",
      html: "Operação realizada com sucesso.",
    });

    onClose(OnCloseModalAction.RefreshAndDeselect);
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleAfterClose = () => {
    reset();
  };

  const isBeforeToday = (value: string) => {
    if (!value) {
      return true;
    }

    const date = parse(value, "ddMMyyyy", new Date());

    return isBefore(date, new Date());
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      onAfterClose={handleAfterClose}
      shouldCloseOnEsc={!isSubmitting}
      onRequestClose={handleModalClose}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar data de travamento</h4>
          <button
            type="button"
            id="btn-lock-date-cross"
            onClick={handleModalClose}
            className="react-modal-close"
            data-testid="btn-lock-date-cross"
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          <form
            className="form-container"
            id="cost-center-lock-date-form"
            onSubmit={handleSubmit(onValid)}
          >
            <div className="form-row">
              <Controller
                control={control}
                name="newLockDate"
                rules={{
                  validate: {
                    validDate: v => !invalidDate(v),
                    isBeforeToday,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <label className="col-12 form-control">
                      <span>
                        Travar lançamentos até <small>(opcional)</small>
                      </span>
                      <InputMask
                        unmask
                        {...field}
                        autoClear={false}
                        mask="99/99/9999"
                        placeholder="Insira uma data"
                        className={fieldState.error ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        condition={fieldState.error?.type === "validDate"}
                        message="Este campo é opcional, porém a data inserida está inválida"
                      />
                      <InvalidFeedback
                        condition={fieldState.error?.type === "isBeforeToday"}
                        message="A data informada não pode ser superior ao dia atual"
                      />
                    </label>
                  );
                }}
              />
            </div>
          </form>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            id="btn-lock-date-close"
            onClick={handleModalClose}
            className="form-button red-bkg"
            data-testid="btn-lock-date-close"
          >
            Voltar
          </button>
          <button
            type="submit"
            id="btn-lock-date-save"
            disabled={isSubmitting}
            data-testid="btn-lock-date-save"
            form="cost-center-lock-date-form"
            className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
          >
            {isSubmitting ? "Salvando " : "Salvar "}
            {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
