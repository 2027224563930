import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IBulkTerminateAccountsContract,
  IBulkTerminateAccountsParams,
} from "../../domain/contracts/bulkTerminateAccountsContract";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";

interface IBulkTerminateAccountPayload {
  accountPayableIds: string[];
  deleteReason: "";
  status: EAccountPayableStatus.Paid;
  terminationDate: string | null;
  usePayUntil: boolean;
}

export class BulkTerminateAccountService
  implements IBulkTerminateAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async bulkTerminateAccounts({
    accountPayableIds,
    terminationDate,
    usePayUntil,
  }: IBulkTerminateAccountsParams): Promise<void> {
    const dtTerminationDate = terminationDate
      ? parse(terminationDate.replace(/\D/g, ""), "ddMMyyyy", new Date())
      : null;

    const fmtTerminationDate = dtTerminationDate
      ? format(dtTerminationDate, "yyyy-MM-dd 00:00:00")
      : null;

    const payload: IBulkTerminateAccountPayload = {
      accountPayableIds,
      terminationDate: fmtTerminationDate,
      status: EAccountPayableStatus.Paid,
      deleteReason: "",
      usePayUntil,
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/Status
    `;

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
