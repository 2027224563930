import axios, { AxiosInstance } from "axios";
import { IErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import {
  ApiResponseErrorHandlersType,
  HttpResponseType,
  IApiError,
  IApiService,
} from "./apiService";

export class ViaCepApiService implements IApiService {
  private axiosInstance: AxiosInstance;

  constructor(private responseErrorHandlers?: ApiResponseErrorHandlersType) {
    const baseURL = process.env.REACT_APP_VIACEP_URL;
    this.axiosInstance = axios.create({ baseURL });

    // enquanto o site estiver atendendo apenas usuários da lingua portuguesa,
    // é viável manter essa configuração de forma global no projeto.
    this.axiosInstance.interceptors.request.use(request => {
      const header = {
        ...request.headers,
        // tradução no retorno das rotas do projeto.
        "Accept-Language": "pt-br",
      };
      request.headers = {
        ...header,
      };
      return request;
    });

    this.axiosInstance.interceptors.response.use(
      undefined,
      (error: IApiError<IErrorResponseEntity>) => {
        const status = error?.response?.status;

        const errorHandler = this.responseErrorHandlers?.[status];

        if (errorHandler) {
          errorHandler(error?.response?.data);
        } else {
          this.responseErrorHandlers?.[9999]?.();
          // eslint-disable-next-line no-console
          console.log(error);
        }

        return Promise.reject(error);
      },
    );
  }

  async get<T>(url: string, config?: Record<string, unknown>): Promise<T> {
    const axiosResponse = await this.axiosInstance.get<T>(url, config);
    return axiosResponse.data;
  }

  async post<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.post(url, data, config);
    return axiosResponse.data;
  }

  async put<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.put(url, data, config);
    return axiosResponse.data;
  }

  async patch<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.patch(url, data, config);
    return axiosResponse.data;
  }

  async delete<T>(url: string, config?: Record<string, unknown>): Promise<T> {
    const axiosResponse = await this.axiosInstance.delete(url, config);
    return axiosResponse.data;
  }

  async download?(
    url: string,
    config?: Record<string, unknown>,
  ): Promise<HttpResponseType> {
    const axiosResponse = await this.axiosInstance.get(url, config);
    return axiosResponse;
  }

  setCustomHeaders(headers: Record<string, string>): void {
    const axiosDefaults = this.axiosInstance?.defaults;

    if (axiosDefaults) {
      Object.assign(axiosDefaults.headers.common, headers);
    }
  }
}
