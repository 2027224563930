import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
} from "primereact/autocomplete";
import { FaExclamationCircle } from "react-icons/fa";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";

export type SearchFieldName =
  | "paymentAccountId"
  | "classificationUspId"
  | "classificationAccountId"
  | "classificationAssessmentId";

interface SearchFieldProps<T> {
  value?: T;
  id: string;
  disabled?: boolean;
  isInvalid: boolean;
  placeholder: string;
  name: SearchFieldName;
  search: (query: string, name: SearchFieldName) => Promise<T[]>;
}

export function SearchField<T extends { name: string; id: string }>(
  props: SearchFieldProps<T>,
) {
  const { id, name, value, search, disabled, isInvalid, placeholder } = props;

  const mountedRef = useIsMounted();
  const autoCompleteRef = useRef<AutoComplete | null>();

  const [suggestions, setSuggestions] = useState<T[]>();
  const [inputValue, setInputValue] = useState(value ?? null);

  const { control } = useFormContext();

  /**
   * Lida com a mudança do input, atribuindo apenas o ID como valor do
   * formulário.
   */
  const handleOnChange = useCallback(
    (e: AutoCompleteChangeParams, formOnChange: (value: unknown) => void) => {
      const isString = typeof e.value === "string";
      setInputValue(e.value === "EMPTY" ? null : e.value);
      formOnChange(isString ? null : e.value?.id);
    },
    [],
  );

  /** Aciona a busca a partir do texto digitado. */
  const handleSearch = useCallback(
    async ({ query }: AutoCompleteCompleteMethodParams) => {
      const response = await search(query, name);
      setSuggestions(response);
    },
    [name, search],
  );

  /** Constrói o template das sugestões disponíveis. */
  const itemTemplate = useCallback(
    (item: T | "EMPTY") => {
      if (item === "EMPTY") {
        return (
          <span className="no-registers">
            <FaExclamationCircle />
            Nenhuma opção disponível
          </span>
        );
      }
      const isSelected = item?.id === inputValue?.id;
      return <span className={isSelected ? "selected" : ""}>{item.name}</span>;
    },
    [inputValue?.id],
  );

  /** Abre o dropdown de sugestões de maneira imperativa. */
  const openDropdown = useCallback(
    (e: SyntheticEvent) => {
      autoCompleteRef.current?.search(e, inputValue?.name ?? "", "dropdown");
    },
    [inputValue?.name],
  );

  /**
   * UGLY - Muito feio.
   * Atualiza o valor do input quando a prop disabled é atualizada.
   * Isso deve acontecer - apenas - para o input de Classificação Contábil.
   * */
  useEffect(() => {
    if (!mountedRef.current) return;
    if (disabled) {
      setInputValue(null);
    }
  }, [disabled, mountedRef]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          const formOnChange = field.onChange;
          const refCallback = field.ref;
          return (
            <AutoComplete
              {...field}
              id={id}
              delay={700}
              field="name"
              forceSelection
              data-testid={id}
              value={inputValue}
              disabled={disabled}
              onClick={openDropdown}
              onFocus={openDropdown}
              placeholder={placeholder}
              itemTemplate={itemTemplate}
              completeMethod={handleSearch}
              panelClassName="input-search-providers"
              suggestions={suggestions?.length ? suggestions : ["EMPTY"]}
              ref={originalRef => {
                autoCompleteRef.current = originalRef;
                refCallback(originalRef);
              }}
              onChange={e => {
                handleOnChange(e, formOnChange);
              }}
              inputClassName={isInvalid ? "isInvalid" : ""}
            />
          );
        }}
      />
      <InvalidFeedback
        condition={isInvalid}
        message="Esse campo é obrigatório"
      />
    </>
  );
}
