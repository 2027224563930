import { IListBanksContract } from "../../domain/contracts/listBanksContract";
import { IBankEntity } from "../../domain/entities/bankEntity";
import { IApiService } from "./apiService";
import { GetUserLocalService } from "./getUserLocalService";

export class ListBanksService implements IListBanksContract {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  listBanks() {
    const userEntity = this.getUserLocalService.get();
    const url = "Enums/Banks";

    return this.api.get<IBankEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
