import { ChangeEvent, useCallback, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IImportationMatchesCostCenterEntity } from "../../../domain/entities/importationMatchesCostCenterEntity";
import { EImportationMatchesOrigin } from "../../../domain/entities/importationMatchesPayloadEntity";
import { MakeImportationMatches } from "../../../main/makeImportationMatches";
import { useImportationMatchesCostCentersGrid } from "../../hooks/useImportationMatchesCostCentersGrid";
import { CostCenterClassesModal } from "../CostCenterClassesModal";

interface ImportationMatchesCostCenterGridProps {
  origin: EImportationMatchesOrigin;
  useImportationMatches: MakeImportationMatches;
}

export function ImportationMatchesCostCenterGrid(
  props: ImportationMatchesCostCenterGridProps,
) {
  const { origin, useImportationMatches } = props;

  const { listImportationMatchesCostCenters } = useImportationMatches;

  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();

  /** Referência da tabela */
  const table = useRef<ISimpleTableHandle>(null);

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Estado de carregamento da tabela */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da tabela */
  const [data, setData] =
    useState<IResponseEntity<IImportationMatchesCostCenterEntity[]>>();

  /** Armazena o ID do centro de custo selecionado. */
  const [currentCostCenter, setCurrentCostCenter] =
    useState<IImportationMatchesCostCenterEntity>();

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  /**
   * Lida com a abertura do modal de vínculo.
   */
  const openMatchModal = useCallback(
    (costCenter: IImportationMatchesCostCenterEntity) => {
      setCurrentCostCenter(costCenter);
    },
    [],
  );

  const { columns } = useImportationMatchesCostCentersGrid({
    origin,
    openMatchModal,
  });

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const prevCurrentCompanyGroupRef = useRef(currentCompanyGroup);

  /**
   * Realiza a requisição para buscar os dados da tabela. Lida com os eventos
   * monitorados pela SimpleTable.
   */
  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setIsLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listImportationMatchesCostCenters(
          payload,
          origin,
        );
        setData(response);

        if (currentCompanyGroup.id !== prevCurrentCompanyGroupRef.current.id) {
          prevCurrentCompanyGroupRef.current = currentCompanyGroup;
        }
      } catch (err) {
        return;
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      currentCompanyGroup,
      generatePayload,
      listImportationMatchesCostCenters,
      mountedRef,
      origin,
    ],
  );

  const handleCloseModal = useCallback((shouldRefresh: boolean | unknown) => {
    if (typeof shouldRefresh === "boolean" && !!shouldRefresh) {
      table.current?.reload();
    }
    setCurrentCostCenter(undefined);
  }, []);

  return (
    <div>
      <div className="search-input">
        <InputSearch
          value={search}
          id="txt-search"
          data-testid="txt-search"
          onChange={handleSearchChange}
        />
      </div>
      <SimpleTable
        data={data}
        tableRef={table}
        columns={columns}
        loading={isLoading}
        getList={getList}
        globalFilter={globalFilter}
      />
      <CostCenterClassesModal
        origin={origin}
        onClose={handleCloseModal}
        isOpen={!!currentCostCenter}
        currentCostCenter={currentCostCenter}
        useImportationMatches={useImportationMatches}
      />
    </div>
  );
}
