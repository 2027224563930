import { format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICompanyGroupEntity,
  CompanyGroupEntity,
} from "../../domain/entities/companyGroupEntity";
import { IGetCompanyGroupService } from "../../domain/usecases/getCompanyGroupUseCase";

export class GetCompanyGroupService implements IGetCompanyGroupService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCompanyGroup(companyGroupId: string): Promise<ICompanyGroupEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/CompanyGroups/${companyGroupId}`;

    const response = await this.api.get<ICompanyGroupEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const companyGroupEntity = new CompanyGroupEntity(response);

    if (companyGroupEntity.paymentRequestBlockStartDate) {
      const { paymentRequestBlockStartDate } = companyGroupEntity;

      companyGroupEntity.paymentRequestBlockStartDate = format(
        new Date(paymentRequestBlockStartDate),
        "ddMMyyyy",
      );
    }

    if (companyGroupEntity.paymentRequestBlockEndDate) {
      const { paymentRequestBlockEndDate } = companyGroupEntity;

      companyGroupEntity.paymentRequestBlockEndDate = format(
        new Date(paymentRequestBlockEndDate),
        "ddMMyyyy",
      );
    }

    return companyGroupEntity;
  }
}
