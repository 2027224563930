import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICostCenterTransactionEntity } from "../../domain/entities/costCenterTransactionsEntity";
import { IListCostCenterTransactionsService } from "../../domain/usecases/listCostCenterTransactionssUseCase";

export class ListCostCenterTransactionsService
  implements IListCostCenterTransactionsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCostCenterTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICostCenterTransactionEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/CostCenterTransactions?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<ICostCenterTransactionEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
