import { ICompanyEntity } from "./companyEntity";
import { IPaymentAccountEntity } from "./paymentAccountEntity";

export interface IConciliationFormInputEntity {
  startDate: string;
  endDate: string;
  company: ICompanyEntity[];
  paymentAccount: IPaymentAccountEntity[];
}

export class ConciliationFormInputEntity
  implements IConciliationFormInputEntity
{
  startDate = "";
  endDate = "";
  company = [];
  paymentAccount = [];

  constructor(init?: Partial<IConciliationFormInputEntity>) {
    Object.assign(this, init);
  }
}
