import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { EReportOrigin } from "../../domain/entities/costCenterReportEntity";
import { IGetStorageFilebyIdService } from "../../domain/usecases/getStorageFilebyIdUseCase";

export class GetStorageFilebyIdService implements IGetStorageFilebyIdService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getStorageFilebyId(
    storageFileId: string,
    origin: EReportOrigin,
  ): Promise<string> {
    const userEntity = this.getUserLocalService.get();

    let url = `/Downloads/AccountsPayable/Attachments/StorageFiles`;

    if (origin === EReportOrigin.accountReceivable) {
      url = `/Downloads/AccountsReceivable/Attachments/StorageFiles`;
    }

    url = `${url}/${storageFileId}`;

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
