import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  ISimpleHiddenColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EAccountPayablePaymentMethod,
  EFuspSendingStatus,
  accountPayablePaymentMethodLang,
  fuspSendingStatusLang,
} from "../../domain/entities/accountPayableListItemEntity";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

export function useExportSheetFuspFormModalTable() {
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const { useAccountsPayable } = useAccountsPayablePage();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    const {
      searchProject,
      searchCompetency,
      searchProvider,
      searchClassificationUsp,
    } = useAccountsPayable;

    return [
      {
        header: "Nº documento",
        field: "documentNumber",
        width: "200px",
        className: "text-truncate",
      },

      {
        header: "Valor",
        field: "valueString",
        sortField: "value",
        searchField: "value",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Status FUSP",
        field: "fuspSendingStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "fuspSendingStatus",
        searchField: "fuspSendingStatus",
        columnType: EColumnType.enum,
        enumObject: EFuspSendingStatus,
        enumLang: fuspSendingStatusLang,
        className: "text-truncate",
        width: "158px",
      },
      {
        header: "Competência",
        field: "competencyName",
        orderable: true,
        searchable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "158px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        orderable: true,
        searchable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Coordenador",
        field: "coordinatorName",
        searchField: "coordinatorId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Pagar em",
        field: "payUntilString",
        orderable: true,
        searchable: true,
        searchField: "payUntil",
        sortField: "payUntil",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "200px",
      },
      {
        header: "Fornecedor",
        field: "providerName",
        searchField: "providerId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EAccountPayablePaymentMethod,
        enumLang: accountPayablePaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        orderable: true,
        searchable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Descrição",
        field: "description",
        width: "200px",
        className: "text-truncate",
      },
      {
        hidden: true,
        field: "fuspSendingStatus",
      } as ISimpleHiddenColumn,
      {
        hidden: true,
        field: "fuspSendingFailureReason",
      } as ISimpleHiddenColumn,
    ].map(colParams => new SimpleColumn(colParams));
  }, [id, useAccountsPayable]);

  return {
    columns,
  };
}
