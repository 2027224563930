import { EUserProfile } from "../../../core/domain/entities/userEntity";

export interface IUserModel {
  id?: string;
  name: string;
  email: string;
  document: string;
  password: string;
  passwordCheck: string;
  profile: EUserProfile;
  profileDescription: string;
  partnerId: string;
  passwordReset: boolean;
  active: boolean;
}

export class UserModel implements IUserModel {
  id = "";
  name = "";
  email = "";
  document = "";
  password = "";
  passwordCheck = "";
  profile = EUserProfile.financial;
  profileDescription = "";
  partnerId = "";
  passwordReset = true;
  active = true;

  constructor(params?: Partial<IUserModel>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IUserModel>) {
    return new UserModel(params);
  }
}

export interface IUserRequestProfilesModel {
  isFinancial: boolean;
  isPersonnelDepartment: boolean;
  userProfile: EUserProfile;
}

export interface IUserRequestProfilesResponse
  extends IUserRequestProfilesModel {
  userId: string;
}

export class UserRequestProfilesModel implements IUserRequestProfilesModel {
  isFinancial = false;
  isPersonnelDepartment = false;
  userProfile = EUserProfile.financial;

  constructor(params?: Partial<IUserRequestProfilesModel>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IUserRequestProfilesModel>) {
    return new UserRequestProfilesModel(params);
  }
}
