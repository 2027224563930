import { IEntity } from "./entity";
import { EUserProfile } from "./userEntity";

export interface IAuthUserEntity extends IEntity {
  name: string;
  email: string;
  partnerId: string;
  profile: EUserProfile;
  profileDescription: string;
}

export class AuthUserEntity implements IAuthUserEntity {
  id = "";
  name = "";
  email = "";
  profile = 0;
  partnerId = "";
  active = false;
  profileDescription = "";

  constructor(params?: Partial<IAuthUserEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IAuthUserEntity>) {
    return new AuthUserEntity(params);
  }
}
