import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  display: flex;
  justify-content: end;
  bottom: 0;
  padding: 0.5rem 0;
  pointer-events: none;

  button {
    gap: 0.5rem;
    display: flex;
    align-items: center;

    &.red-bkg {
      &:enabled:hover {
        background-color: #b82828;
      }
    }

    &.green-bkg {
      &:enabled:hover {
        background-color: #28b87a;
      }
    }
  }
`;
