import { useCallback, useRef } from "react";

export function useDebounceTime() {
  const ref = useRef<NodeJS.Timeout | undefined>();

  return useCallback((callback: () => void, delay: number) => {
    clearTimeout(ref.current as NodeJS.Timeout);
    ref.current = setTimeout(() => {
      callback();
      clearTimeout(ref.current as NodeJS.Timeout);
    }, delay);
  }, []);
}

export function useDebounceTimeAsync() {
  const ref = useRef<NodeJS.Timeout | undefined>();

  return useCallback(async (delay: number) => {
    return new Promise<void>(resolve => {
      clearTimeout(ref.current as NodeJS.Timeout);
      ref.current = setTimeout(() => {
        resolve();
        clearTimeout(ref.current as NodeJS.Timeout);
      }, delay);
    });
  }, []);
}
