import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IStateResponse } from "../../data/models/stateResponse";

export interface IGetStateByNameUseCase {
  getStateByName(
    countryId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IStateResponse[]>>;
}

export interface IGetStateByNameService {
  getStateByName(
    countryId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IStateResponse[]>>;
}

export class GetStateByNameUseCase implements IGetStateByNameUseCase {
  constructor(private getStateByNameService: IGetStateByNameService) {}

  getStateByName(countryId: string, payload: IPayloadEntity) {
    return this.getStateByNameService.getStateByName(countryId, payload);
  }
}
