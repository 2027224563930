import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IUndoConfirmService {
  undoConfirm(id: string): Promise<ICostCenterReportEntity>;
}

export interface IUndoConfirmUseCase {
  undoConfirm(id: string): Promise<ICostCenterReportEntity>;
}

export class UndoConfirmUseCase implements IUndoConfirmUseCase {
  constructor(private approveService: IUndoConfirmService) {}

  undoConfirm(id: string): Promise<ICostCenterReportEntity> {
    return this.approveService.undoConfirm(id);
  }
}
