import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ISaveImportDataContract,
  ISaveImportDataParams,
} from "../../domain/contracts/saveImportDataContract";
import { ISolicitImportAssessmentEntity } from "../../domain/entities/solicitImportAssessmentEntity";
import { SolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import {
  ISolicitImportPayloadModel,
  ISolicitImportResponseModel,
} from "../models/solicitImportModel";
import { MainSolicitImportService } from "./mainSolicitImportService";

export class SaveImportDataService
  extends MainSolicitImportService
  implements ISaveImportDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async saveImportData({
    status,
    importData,
    destination,
  }: ISaveImportDataParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PaymentRequestImportations/Save";

    const payload = importData.map<Partial<ISolicitImportPayloadModel>>(
      accountData => {
        return {
          assessments: accountData.assessments.map(assessment => ({
            classificationAssessmentId:
              (assessment.classificationAssessment?.rawValue as string) || "",
            classificationAssessmentName:
              (assessment.classificationAssessment?.label as string) || "",
            costCenterId: (assessment.costCenter?.rawValue as string) || "",
            costCenterName: (assessment.costCenter?.label as string) || "",
            fieldErrors: assessment.fieldErrors,
            hasError: assessment.hasError,
            observation: assessment.observation,
            percentage: assessment.percentage,
            value: assessment.value,
            valueAssessmentLeft: assessment.valueAssessmentLeft,
          })),
          barcode: accountData.barcode || "",
          companyId: (accountData.company?.rawValue as string) || "",
          companyName: accountData.company?.label,
          description: accountData.description,
          destination,
          documentNumber: accountData.documentNumber,
          documentStatus: accountData.documentStatus?.key,
          getCompanyHasProject: accountData.getCompanyHasProject,
          getCompetencyIsUsp: accountData.getCompetencyIsUsp,
          id: accountData.id,
          issueDate: this.dateFormatter(accountData.issueDate),
          observation: accountData.observation,
          paymentMethod: accountData.paymentMethod?.key,
          payUntil: this.dateFormatter(accountData.payUntil),
          providerId: (accountData.provider?.rawValue as string) || "",
          providerName: accountData.provider?.label || "",
          status,
          storageFiles: accountData.storageFiles.map(f => ({
            ...f,
            file: f.file || null,
            barcode: f.barcode || "",
            storageFileId: f.storageFileId || "",
          })),
          value: accountData.value,
        };
      },
    );

    const response = await this.api.post<ISolicitImportResponseModel[]>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const dataResponse = response.map(responseData => {
      const datePayUntil = new Date(responseData.payUntil);
      const dateIssueDate = new Date(responseData.issueDate);

      const company = this.buildTypeaheadObject(responseData, "company");
      const competency = this.buildTypeaheadObject(responseData, "competency");

      if (company) {
        company.metadata = {
          hasProject: responseData.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: responseData.getCompetencyIsUsp,
        };
      }

      return new SolicitImportEntity({
        id: responseData.id,
        accountAlreadyExists: responseData?.accountAlreadyExists || false,
        assessments:
          responseData?.assessments?.map<ISolicitImportAssessmentEntity>(
            assessment => ({
              hasError: assessment.hasError,
              fieldErrors: assessment.fieldErrors,
              observation: assessment.observation || "",
              value: new BigNumber(assessment.value).toJSON(),
              valueAssessmentLeft: assessment.valueAssessmentLeft,
              percentage: new BigNumber(assessment.percentage).toJSON(),
              costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
              classificationAssessment: this.buildTypeaheadObject(
                assessment,
                "classificationAssessment",
              ),
            }),
          ) || [],
        barcode: responseData?.barcode || "",
        company,
        description: responseData?.description?.toUpperCase() || "",
        documentNumber: responseData?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: responseData?.documentStatus ?? "",
          value: responseData.documentStatusDescription,
        },
        getCompanyHasProject: responseData.getCompanyHasProject,
        getCompetencyIsUsp: responseData.getCompetencyIsUsp,
        hasAssessmentError: responseData.hasAssessmentError,
        hasError: responseData.hasError,
        issueDate: format(dateIssueDate, "dd/MM/yyyy"),
        observation: responseData?.observation?.toUpperCase() || "",
        payUntil: format(datePayUntil, "dd/MM/yyyy"),
        paymentMethod: {
          key: responseData?.paymentMethod ?? "",
          value: responseData.paymentMethodDescription,
        },
        provider: this.buildTypeaheadObject(responseData, "provider"),
        storageFiles: responseData?.storageFiles,
        value: responseData.value,
        valueAssessmentLeft: responseData.valueAssessmentLeft,
        fieldErrors: responseData.fieldErrors,
      });
    });

    return dataResponse;
  }
}
