import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveCostCenterUsersContract } from "../../domain/contracts/saveCostCenterUsersContract";
import { ICostCenterUsersForm } from "../../presentation/components/CostCenterUsersModal";

export class SaveCostCenterUsersService
  implements ISaveCostCenterUsersContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveCostCenterUsers(costCenterId: string, payload: ICostCenterUsersForm) {
    const userEntity = this.getUserLocalService.get();
    const url = `CostCenters/${costCenterId}/Users`;

    const finalPayload = {
      users: payload.users.map(({ id }) => id),
    };

    return this.api.post<{ users: string[] }>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
