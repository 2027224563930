import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 71.25rem;

  .crud-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.9rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .react-modal-body {
    padding-top: 0;

    .searchbar {
      margin-bottom: 1rem;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .p-progress-spinner {
    width: 50px;
    height: 50px;

    svg {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }

  span.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;
