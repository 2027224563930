import { IClassificationUspEntity } from "../../../classificationUsp/domain/entities/classificationUspEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationUspService } from "../../domain/usecases/getClassificationUspUseCase";

export class GetClassificationUspService
  implements IGetClassificationUspService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getClassificationUsp(classificationUspId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationsUsp/${classificationUspId}`;

    return this.api.get<IClassificationUspEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
