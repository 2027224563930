import { ProgressBar } from "primereact/progressbar";
import styled from "styled-components";
import Modal from "react-modal";

const Container = styled.div`
  padding: 2rem;
  font-size: 0.975rem;

  .progress-modal__body,
  .progress-bar {
    margin: 1rem 0;
  }

  .export-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
    height: 2em;

    i.pi {
      margin-left: 0.5rem;
    }
  }
`;

interface IRequestProgressModalProps {
  total: number;
  loaded: number;
  isOpen: boolean;
}

export function RequestProgressModal({
  total,
  loaded,
  isOpen,
}: IRequestProgressModalProps) {
  const value = loaded && total ? Math.round((loaded / total) * 100) : null;

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="progress-modal__body">
          <p>Por favor, não feche a página até o fim da operação</p>
        </div>
        <div className="progress-bar">
          <ProgressBar
            value={value}
            showValue={false}
            mode="determinate"
            style={{ height: "0.375rem" }}
          />
        </div>
        <div className="export-modal-footer">
          Processando dados <i className="pi pi-spin pi-spinner" />
        </div>
      </Container>
    </Modal>
  );
}
