import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetAttachmentsContract } from "../../domain/contracts/getAttachmentsContract";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";

export class GetAttachmentsService implements IGetAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getAttachments(paymentRequestId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/PaymentRequests/${paymentRequestId}/Attachments`;

    return this.api.get<IPaymentRequestAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
