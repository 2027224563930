import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompanyEntity } from "../../domain/entities/companyEntity";
import { IToggleCompanyService } from "../../domain/usecases/toggleCompanyUseCase";

export class ToggleCompanyService implements IToggleCompanyService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  toggleCompany(companyId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Companies/${companyId}`;

    return this.api.delete<ICompanyEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
