import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SearchClassificationAssessmentService } from "../../core/data/services/searchClassificationAssessmentService";
import { SearchCompanyService } from "../../core/data/services/searchCompanyService";
import { SearchPecegePayWalletsService } from "../../core/data/services/searchPecegePayWalletsService";
import { SearchProviderClassificationAccountService } from "../../core/data/services/searchProviderClassificationAccountService";
import { SearchProviderService } from "../../core/data/services/searchProviderService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ISearchClassificationAssessmentContract } from "../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchCompanyContract } from "../../core/domain/contracts/searchCompanyContract";
import { ISearchPecegePayWalletsContract } from "../../core/domain/contracts/searchPecegePayWalletsContract";
import { ISearchProviderClassificationAccountContract } from "../../core/domain/contracts/searchProviderClassificationAccountContract";
import { ISearchProviderContract } from "../../core/domain/contracts/searchProviderContract";
import { ExportPecegePayAccountReceivableFeesService } from "../data/services/exportPecegePayAccountReceivableFeesService";
import { GetPecegePayAccountReceivableFeeService } from "../data/services/getPecegePayAccountReceivableFeeService";
import { GetPecegePayAccountReceivableFeeTaxesService } from "../data/services/getPecegePayAccountReceivableFeeTaxesService";
import { ListPecegePayAccountReceivableFeesService } from "../data/services/listPecegePayAccountReceivableFeesService";
import { SavePecegePayAccountReceivableFeeService } from "../data/services/savePecegePayAccountReceivableFeeService";
import { TogglePecegePayAccountReceivableFeeService } from "../data/services/togglePecegePayAccountReceivableFeeService";
import { UpdatePecegePayAccountReceivableFeeService } from "../data/services/updatePecegePayAccountReceivableFeeService";
import { IExportPecegePayAccountReceivableFeesContract } from "../domain/contracts/exportPecegePayAccountReceivableFeesContract";
import { IGetPecegePayAccountReceivableFeeContract } from "../domain/contracts/getPecegePayAccountReceivableFeeContract";
import { IGetPecegePayAccountReceivableFeeTaxesContract } from "../domain/contracts/getPecegePayAccountReceivableFeeTaxesContract";
import { IListPecegePayAccountReceivableFeesContract } from "../domain/contracts/listPecegePayAccountReceivableFeesContract";
import { ISavePecegePayAccountReceivableFeeContract } from "../domain/contracts/savePecegePayAccountReceivableFeeContract";
import { ITogglePecegePayAccountReceivableFeeContract } from "../domain/contracts/togglePecegePayAccountReceivableFeeContract";
import { IUpdatePecegePayAccountReceivableFeeContract } from "../domain/contracts/updatePecegePayAccountReceivableFeeContract";

export type MakePecegePayAccountReceivableFees =
  IListPecegePayAccountReceivableFeesContract &
    IGetPecegePayAccountReceivableFeeContract &
    ISavePecegePayAccountReceivableFeeContract &
    IUpdatePecegePayAccountReceivableFeeContract &
    ITogglePecegePayAccountReceivableFeeContract &
    IExportPecegePayAccountReceivableFeesContract &
    IGetPecegePayAccountReceivableFeeTaxesContract &
    ISearchCompanyContract &
    ISearchProviderContract &
    ISearchClassificationAssessmentContract &
    ISearchProviderClassificationAccountContract &
    ISearchPecegePayWalletsContract;

export function makePecegePayAccountReceivableFees(
  api: IApiService,
): MakePecegePayAccountReceivableFees {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listPecegePayAccountReceivableFeesService =
    new ListPecegePayAccountReceivableFeesService(
      getUserLocalService,
      api,
      setServerSideService,
    );

  const getPecegePayAccountReceivableFeeService =
    new GetPecegePayAccountReceivableFeeService(getUserLocalService, api);

  const savePecegePayAccountReceivableFeeService =
    new SavePecegePayAccountReceivableFeeService(getUserLocalService, api);

  const updatePecegePayAccountReceivableFeeService =
    new UpdatePecegePayAccountReceivableFeeService(getUserLocalService, api);

  const togglePecegePayAccountReceivableFeeService =
    new TogglePecegePayAccountReceivableFeeService(getUserLocalService, api);

  const exportPecegePayAccountReceivableFeesService =
    new ExportPecegePayAccountReceivableFeesService(
      getUserLocalService,
      api,
      setServerSideService,
    );

  const getPecegePayAccountReceivableFeeTaxesService =
    new GetPecegePayAccountReceivableFeeTaxesService(getUserLocalService, api);

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchProviderService = new SearchProviderService(
    getUserLocalService,
    api,
  );

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchProviderClassificationAccountService =
    new SearchProviderClassificationAccountService(getUserLocalService, api);

  const searchPecegePayWalletsService = new SearchPecegePayWalletsService(
    getUserLocalService,
    api,
  );

  return {
    listPecegePayAccountReceivableFees(payload) {
      return listPecegePayAccountReceivableFeesService.listPecegePayAccountReceivableFees(
        payload,
      );
    },
    getPecegePayAccountReceivableFee(feeId) {
      return getPecegePayAccountReceivableFeeService.getPecegePayAccountReceivableFee(
        feeId,
      );
    },
    savePecegePayAccountReceivableFee(params) {
      return savePecegePayAccountReceivableFeeService.savePecegePayAccountReceivableFee(
        params,
      );
    },
    updatePecegePayAccountReceivableFee(params) {
      return updatePecegePayAccountReceivableFeeService.updatePecegePayAccountReceivableFee(
        params,
      );
    },
    togglePecegePayAccountReceivableFee(feeId) {
      return togglePecegePayAccountReceivableFeeService.togglePecegePayAccountReceivableFee(
        feeId,
      );
    },
    exportPecegePayAccountReceivableFees(params) {
      return exportPecegePayAccountReceivableFeesService.exportPecegePayAccountReceivableFees(
        params,
      );
    },
    getPecegePayAccountReceivableFeeTaxes(feeId) {
      return getPecegePayAccountReceivableFeeTaxesService.getPecegePayAccountReceivableFeeTaxes(
        feeId,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchProvider(params) {
      return searchProviderService.searchProvider(params);
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchProviderClassificationAccount(params) {
      return searchProviderClassificationAccountService.searchProviderClassificationAccount(
        params,
      );
    },
    searchPecegePayWallets(params) {
      return searchPecegePayWalletsService.searchPecegePayWallets(params);
    },
  };
}
