import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0.6em;
  margin: 0 0.286em 0 0;
  display: inline-flex;
  align-items: center;
  border-radius: 0.25em;
  white-space: nowrap;
  max-width: calc(50% - 1em);
  color: white;
  background: #4a69bd;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    background: transparent;
    border: none;
    color: white;
    display: inline-flex;
    align-items: center;
    margin-left: 0.5em;
    position: relative;
    top: 1px;
  }
`;
