import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 80rem;

  .highlight-color {
    color: var(--highlight-color) !important;
  }

  .crud-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;

    p {
      font-size: 0.9rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .table-wrapper {
    margin: 1.5rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;

    .p-datatable {
      tr.p-datatable-row-expansion {
        & > td {
          padding: 1rem;
          background-color: #f6f9fd;
        }
      }

      .p-paginator {
        border: none;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }

  .generate-invoice-modal__table-wrapper__header {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
  }

  .txt-search-wrapper {
    margin-left: auto;
  }

  #expiration-type-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .p-autocomplete {
    width: 100%;
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .p-progress-spinner {
    width: 50px;
    height: 50px;

    svg {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }

  .any-cost-center-disabled {
    hr {
      border-color: var(--soul-red-button);
      margin: 1rem 0;
    }

    div + div {
      margin-top: 1rem;
    }

    color: var(--soul-red-button);
  }

  span.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 2rem;
  }
`;
