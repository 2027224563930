import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .info-card {
    color: white;
    flex: 1 1 auto;
    padding: 1.5rem;
    margin: 0 1.5rem;
    border-radius: 0.375rem;
    background-color: var(--soul-color01);
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }

  .react-modal-body {
    .form-container {
      .form-row {
        position: relative;

        .gap {
          gap: 1.5rem;
        }

        .form-control {
          .p-autocomplete {
            width: 100%;
          }

          .accounts-info {
            font-size: 0.8rem;
            margin-top: 0.25rem;
            color: var(--text-muted);
          }

          .smaller-label {
            color: #525f7f;
            font-size: 0.869rem;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 0.5rem;

            gap: 0.25rem;
            display: flex;
            align-items: center;
          }
          .with-tooltip {
            gap: 0.25rem;
            display: flex;
          }
        }
      }
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;
