import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IListActiveCostCentersContract } from "../../domain/contracts/listActiveCostCentersContract";

interface ICostCenterResponse {
  id: string;
  acronym: string;
  active: boolean;
}

export class ListActiveCostCentersService
  implements IListActiveCostCentersContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listActiveCostCenters(
    companyGroupId: string,
  ): Promise<IRelationshipFilterOption[]> {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups/${companyGroupId}/CostCenters/Actives`;

    const response = await this.api.get<
      IServerSideResponseModel<ICostCenterResponse[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.data.map(costCenter => {
      return new RelationshipFilterOption({
        rawValue: costCenter.id,
        label: costCenter.acronym,
        metadata: costCenter,
      });
    });
  }
}
