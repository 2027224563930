import { InputMask } from "primereact/inputmask";
import { Controller } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useAccountReceivableReturnFormModal } from "../../hooks/useAccountReceivableReturnFormModal";
import { Container } from "./styles";

export function AccountReceivableReturnFormModal() {
  const {
    columns,
    returnForm,
    launchValue,
    handleClose,
    dateValidator,
    handleAfterOpen,
    isPartialReturn,
    handleAfterClose,
    handleModalTexts,
    handleFormSubmission,
    accountReceivableReturnFormModalOpen,
    /*
      TODO - #11462
      Só será utilizado quando a devolução de taxas FUSP for liberado para produção.
      ------------------------------------------------------------------------------
      hasProject,
    */
  } = useAccountReceivableReturnFormModal();

  const {
    watch,
    control,
    formState: { isValid, isSubmitting },
  } = returnForm;

  const { title, headerText } = handleModalTexts();

  const costCenterLinksWatcher = watch("costCenterLinks");

  const brlFmt = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format;

  return (
    <Modal
      onRequestClose={handleClose}
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      onAfterClose={handleAfterClose}
      overlayClassName="react-modal-overlay"
      isOpen={accountReceivableReturnFormModalOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{title}</h4>
          <button
            type="button"
            id="btn-cross"
            onClick={handleClose}
            data-testid="btn-cross"
            className="react-modal-close"
          >
            <IoMdClose />
          </button>
        </div>
        <div className="crud-header">
          <p>{headerText}</p>
        </div>
        <form className="form-container" onSubmit={handleFormSubmission}>
          <div className="react-modal-body">
            <div className="form-row">
              {/* Data de devolução do lançamento */}
              <Controller
                control={control}
                name="returnDate"
                rules={{
                  required: true,
                  validate: {
                    validDate: v => !dateValidator(v),
                  },
                }}
                render={({ field, fieldState }) => {
                  const customErrors = fieldState?.error?.types as Record<
                    string,
                    string
                  >;

                  return (
                    <label className="col-4 form-control">
                      <span>Data de devolução do lançamento</span>
                      <InputMask
                        unmask
                        {...field}
                        id="returnDate"
                        name="returnDate"
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        data-testid="returnDate"
                        className={fieldState?.error ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={fieldState?.error?.type === "required"}
                      />
                      <InvalidFeedback
                        message="Formato de data inválido"
                        condition={fieldState?.error?.type === "validDate"}
                      />
                      <InvalidFeedback
                        message={customErrors?.isAfterOrEqualTerminationDate}
                        condition={
                          !!customErrors?.isAfterOrEqualTerminationDate
                        }
                      />
                      <InvalidFeedback
                        message={customErrors?.companyCanRelease}
                        condition={!!customErrors?.companyCanRelease}
                      />
                    </label>
                  );
                }}
              />
              {/* valor */}
              {isPartialReturn ? (
                <Controller
                  control={control}
                  name="returnValue"
                  rules={{
                    min: 0.01,
                    required: true,
                    max: launchValue,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <label className="col-4 form-control">
                        <span>Valor</span>
                        <InputBalance
                          {...field}
                          resetToZero
                          id="txt-value"
                          placeholder="000,00"
                          data-testid="txt-value"
                          className={fieldState.error ? "isInvalid" : ""}
                        />
                        <InvalidFeedback
                          message="Este campo é obrigatório"
                          condition={
                            fieldState?.error?.type === "min" ||
                            fieldState?.error?.type === "required"
                          }
                        />
                        {fieldState?.error?.type === "max" ? (
                          <p className="custom-invalid-feedback">
                            O valor devolvido não pode ser maior que{" "}
                            <strong>{brlFmt(launchValue)}</strong>
                          </p>
                        ) : null}
                      </label>
                    );
                  }}
                />
              ) : null}
              {/* Data de devolução da Taxa */}
              {/*
                  TODO - #11462
                  Só será utilizado quando a devolução de taxas FUSP for liberado para produção.
                  ------------------------------------------------------------------------------
                  {hasProject ? (
                    <Controller
                      control={control}
                      name="taxReturnDate"
                      rules={{
                        required: true,
                        validate: {
                          validDate: v => !dateValidator(v),
                        },
                      }}
                      render={({ field, fieldState }) => {
                         const customErrors = fieldState?.error?.types as Record<string, string>;

                        return (
                          <label className="col-4 form-control">
                            <span>Data de devolução da taxa</span>
                            <InputMask
                              unmask
                              {...field}
                              mask="99/99/9999"
                              id="taxReturnDate"
                              name="taxReturnDate"
                              placeholder="dd/mm/aaaa"
                              data-testid="taxReturnDate"
                              className={fieldState?.error ? "isInvalid" : ""}
                            />
                            <InvalidFeedback
                              message="Este campo é obrigatório"
                              condition={fieldState?.error?.type === "required"}
                            />
                            <InvalidFeedback
                              message="Formato de data inválido"
                              condition={fieldState?.error?.type === "validDate"}
                            />
                            <InvalidFeedback
                              message={customErrors?.isAfterOrEqualTerminationDate}
                              condition={
                                !!customErrors?.isAfterOrEqualTerminationDate
                              }
                            />
                            <InvalidFeedback
                              message={customErrors?.companyCanRelease}
                              condition={!!customErrors?.companyCanRelease}
                            />
                          </label>
                        );
                      }}
                    />
                  ) : null}
              */}
            </div>
            {costCenterLinksWatcher ? (
              <div className="any-cost-center-disabled">
                <hr />
                <div>
                  <h4>
                    Alerta: O Centro de Custo deste lançamento está inativo
                  </h4>
                  <p>
                    Para que a devolução seja realizada você deverá ativá-lo
                    novamente ou poderá escolher a seguir outro centro de custo
                    que receberá o lançamento de devolução. O lançamento
                    original não será alterado.
                  </p>
                </div>
                <div>
                  <ClientSoulTable
                    columns={columns}
                    autoLayout={false}
                    data={[costCenterLinksWatcher]}
                    emptyMessage="Nenhum registro encontrado."
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="react-modal-footer">
            <button
              id="btn-close"
              type="button"
              className="form-button red-bkg"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button
              type="submit"
              id="btn-return"
              disabled={isSubmitting}
              className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
            >
              Fazer devolução{" "}
              {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
            </button>
          </div>
        </form>
      </Container>
    </Modal>
  );
}
