import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { useApiResponseErrorHandlers } from "../../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { makeAccountsReceivable } from "../../../main/makeAccountsReceivable";
import {
  AccountsReceivablePageProvider,
  useAccountsReceivablePage,
} from "../../hooks/useAccountsReceivablePage";
import { AccountReceivableReturnFormModal } from "../AccountReceivableReturnFormModal";
import { AccountsReceivableAttachmentModal } from "../AccountsReceivableAttachmentModal";
import { AccountsReceivableBulkTerminateModal } from "../AccountsReceivableBulkTerminateModal";
import { AccountsReceivableGenerateInvoiceModal } from "../AccountsReceivableGenerateInvoiceModal";
import { AccountsReceivableImportModal } from "../AccountsReceivableImportModal";
import { AccountsReceivablePageContextMenu } from "../AccountsReceivablePageContextMenu";
import { AccountsReceivableTable } from "../AccountsReceivableTable";
import { AccountsReceivableTerminateModal } from "../AccountsReceivableTerminateModal";
import { Toolbar } from "../Toolbar";
import { Container } from "./styles";

function AccountsReceivablePage() {
  const { ref } = useAccountsReceivablePage();

  return (
    <Container ref={ref}>
      <Page>
        <header className="custom-header">
          <Toolbar />
        </header>

        <article className="no-padding fill-height">
          <AccountsReceivableTable />
          <AccountsReceivablePageContextMenu />
          <AccountsReceivableTerminateModal />
          <AccountsReceivableAttachmentModal />
          <AccountsReceivableBulkTerminateModal />
          <AccountReceivableReturnFormModal />
          <AccountsReceivableImportModal />
          <AccountsReceivableGenerateInvoiceModal />
        </article>
      </Page>
    </Container>
  );
}

interface IAccountsReceivablePageFactoryProps {
  api: IApiService;
}

export function AccountsReceivablePageFactory({
  api,
}: IAccountsReceivablePageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;

  const authBaseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();
  const authApi = new ApiService(authBaseUrl, apiResponseErrorHandlers);

  const useAccountsReceivable = makeAccountsReceivable(api, authApi);

  return (
    <AccountsReceivablePageProvider
      useAccountsReceivable={useAccountsReceivable}
    >
      <AccountsReceivablePage />
    </AccountsReceivablePageProvider>
  );
}
