import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface IListCompanyGroupsService {
  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICompanyGroupEntity[]>>;
}

export interface IListCompanyGroupsUseCase {
  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICompanyGroupEntity[]>>;
}

export class ListCompanyGroupsUseCase implements IListCompanyGroupsUseCase {
  constructor(private listCompanyGroupService: IListCompanyGroupsService) {}

  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<ICompanyGroupEntity[]>> {
    return this.listCompanyGroupService.listCompanyGroups(payload);
  }
}
