import { IAdvTableColumn } from "../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../advTable/domain/entities/panoramaEntity";

export interface ISaveCostCenterReportPanoramaService {
  saveCostCenterReportPanorama(
    columns: IAdvTableColumn[],
    panorama: IPanoramaEntity,
  ): Promise<IPanoramaEntity>;
}

export interface ISaveCostCenterReportPanoramaUseCase {
  saveCostCenterReportPanorama(
    columns: IAdvTableColumn[],
    panorama: IPanoramaEntity,
  ): Promise<IPanoramaEntity>;
}

export class SaveCostCenterReportPanoramaUseCase
  implements ISaveCostCenterReportPanoramaUseCase
{
  constructor(
    private saveCostCenterReportPanoramaSvc: ISaveCostCenterReportPanoramaService,
  ) {}

  saveCostCenterReportPanorama(
    columns: IAdvTableColumn[],
    panorama: IPanoramaEntity,
  ): Promise<IPanoramaEntity> {
    return this.saveCostCenterReportPanoramaSvc.saveCostCenterReportPanorama(
      columns,
      panorama,
    );
  }
}
