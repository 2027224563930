import { IPaymentAccountEntity } from "../../../transactions/paymentAccountTransactions/domain/entities/paymentAccountEntity";

export interface IGetPaymentAccountService {
  getPaymentAccount(id: string): Promise<IPaymentAccountEntity>;
}

export interface IGetPaymentAccountUseCase {
  getPaymentAccount(id: string): Promise<IPaymentAccountEntity>;
}

export class GetPaymentAccountUseCase implements IGetPaymentAccountUseCase {
  constructor(private getPaymentAccountService: IGetPaymentAccountService) {}

  getPaymentAccount(id: string) {
    return this.getPaymentAccountService.getPaymentAccount(id);
  }
}
