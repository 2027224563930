import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";

import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";

interface ISectionAditionalInfoState {
  classificationAccount: {
    loading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
  paymentAccount: {
    loading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
}

interface IReviewSectionAditionalInfoProps {
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function ReviewSectionAditionalInfo(
  props: IReviewSectionAditionalInfoProps,
) {
  const { usePaymentRequestForm } = props;

  const { searchCompanyPaymentAccounts, searchProviderClassificationAccount } =
    usePaymentRequestForm;

  const [state, setState] = useState<ISectionAditionalInfoState>({
    classificationAccount: {
      loading: false,
      options: [],
    },
    paymentAccount: {
      loading: false,
      options: [],
    },
  });

  const form = useFormContext();
  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = form;

  const company = watch("company");
  const provider = watch("provider");
  const paymentAccount = watch("paymentAccount");
  const classificationAccount = watch("classificationAccount");

  const companyId = company?.rawValue as string | undefined;
  const providerId = provider?.rawValue as string | undefined;

  const isCompanySelected = !!companyId;
  const isProviderSelected = !!providerId;

  const handleSearchClsAccountChange = async (search = "") => {
    if (!providerId) {
      return;
    }

    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      classificationAccount: {
        ...prevState.classificationAccount,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const clsAccounts = await searchProviderClassificationAccount(
        companyGroupId,
        providerId,
        search,
        100,
        true,
      );

      setState(prevState => ({
        ...prevState,
        classificationAccount: {
          ...prevState.classificationAccount,
          options: clsAccounts,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        classificationAccount: {
          ...prevState.classificationAccount,
          loading: false,
        },
      }));
    }
  };

  const handleClsAccountChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;

    setValue("classificationAccount", value, { shouldValidate: true });
  };

  const handleSearchPaymentAccountChange = async (search = "") => {
    if (!companyId) {
      return;
    }

    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      paymentAccount: {
        ...prevState.paymentAccount,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        100,
        true,
      );

      const paymentAccounts = response;

      setState(prevState => ({
        ...prevState,
        paymentAccount: {
          ...prevState.paymentAccount,
          options: paymentAccounts,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        paymentAccount: {
          ...prevState.paymentAccount,
          loading: false,
        },
      }));
    }
  };

  const handlePaymentAccountChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;

    setValue("paymentAccount", value, { shouldValidate: true });
  };

  useEffect(() => {
    register("paymentAccount", { required: isCompanySelected });
  }, [isCompanySelected, register]);

  useEffect(() => {
    register("classificationAccount", { required: isProviderSelected });
  }, [isProviderSelected, register]);

  return (
    <Card>
      <header>Informações adicionais</header>
      <section>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Classificação contábil{" "}
              {state.classificationAccount.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <SoulTypeahead
              serverSide
              openOnFocus
              id="classificationAccount"
              value={classificationAccount}
              disabled={!isProviderSelected}
              onChange={handleClsAccountChange}
              placeholder="Classificação contábil"
              data-testid="txt-classificationAccount"
              options={state.classificationAccount.options}
              loading={state.classificationAccount.loading}
              onSearchChange={handleSearchClsAccountChange}
              className={
                errors?.classificationAccount ? "isInvalid" : undefined
              }
            />
            <InvalidFeedback
              condition={
                (errors?.classificationAccount as FieldError)?.type ===
                "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-6 form-control">
            <span>
              Conta de pagamento{" "}
              {state.paymentAccount.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <SoulTypeahead
              serverSide
              openOnFocus
              value={paymentAccount}
              id="txt-paymentAccount"
              disabled={!isCompanySelected}
              data-testid="txt-paymentAccount"
              placeholder="Conta de pagamento"
              onChange={handlePaymentAccountChange}
              loading={state.paymentAccount.loading}
              options={state.paymentAccount.options}
              onSearchChange={handleSearchPaymentAccountChange}
              className={errors.paymentAccount ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={
                (errors?.paymentAccount as FieldError)?.type === "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
        </div>
      </section>
    </Card>
  );
}
