import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .paymentAccount-multiselect {
    width: inherit;

    :hover {
      border-color: var(--input-border-color);
    }

    :focus-within {
      border-color: rgba(50, 151, 211, 0.25);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    &.isInvalid {
      border-style: solid;
      border-width: 1px;
      border-color: var(--invalid);

      :focus-within {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        gap: 0.3rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;

        height: 100%;

        .p-multiselect-token {
          color: #ffffff;
          background: #4a69bd;

          margin: 0;
          padding: 0 0.5rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;

          .p-multiselect-token-label {
            display: flex;
            align-items: center;

            small {
              margin-left: 0.25em;
              opacity: 0.75;
            }
          }

          .p-multiselect-token-icon {
            background: transparent;
            color: white;
            border: none;
            font-size: 1rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .option-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      > small {
        margin-left: 0.5em;
        font-size: 0.7em;
        padding: 0 0.5em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        background: var(--soul-color02);
        border-radius: 0.25em;
      }
    }
  }
`;
