import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { IPecegePayAccountReceivableFeeTaxEntity } from "../../../pecegePayAccountReceivableFees/domain/entities/pecegePayAccountReceivableFeeTaxEntity";
import { ISearchPecegePayWalletsContract } from "../../domain/contracts/searchPecegePayWalletsContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { ServerSideResponseModel } from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface IPecegePayWalletsResponse {
  id: string;
  description: string;
  fees: Array<IPecegePayAccountReceivableFeeTaxEntity>;
}

export class SearchPecegePayWalletsService
  implements ISearchPecegePayWalletsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchPecegePayWallets({
    search,
    payloadOptions,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PecegePay/BillingCompanies/Wallets";

    const params = {
      pageNumber: payloadOptions?.start,
      walletDescription: search || undefined,
      pageSize: payloadOptions?.length || 100,
    };

    const response = await this.api.get<Array<IPecegePayWalletsResponse>>(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      recordsTotal: response.length,
      recordsFiltered: response.length,
      data: response.map(soulFee => {
        return new RelationshipFilterOption({
          rawValue: soulFee.id,
          label: soulFee.description,
          metadata: showMetadata ? soulFee : undefined,
        });
      }),
    });
  }
}
