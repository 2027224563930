import { useCallback, useEffect, useState } from "react";
import { FaExclamationCircle, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IClassificationAccountEntity } from "../../../../classificationAccount/domain/entities/classificationAccountEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useClassName } from "../../../../core/presentation/hooks/useClassName";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { SimpleTable } from "../../../../simpleTable/presentation/components/SimpleTable";
import { ICustomerEntity } from "../../../domain/entities/customerEntity";
import { MakeCustomer } from "../../../main/makeCustomer";
import { useClassificationAccountGrid } from "../../hooks/useClassificationAccountGrid";
import { Container } from "./styles";

interface ClassificationAccountLinkFormModalProps {
  isOpen: boolean;
  isRequired?: boolean;
  useCustomer: MakeCustomer;
  customerEntity?: ICustomerEntity;
  onRequestClose: (hasLinked?: boolean) => void;
}

export function ClassificationAccountLinkFormModal({
  isOpen,
  useCustomer,
  customerEntity,
  onRequestClose,
  isRequired = false,
}: ClassificationAccountLinkFormModalProps) {
  const { columns } = useClassificationAccountGrid();
  const { generatePayload } = useTables();
  const {
    listClassificationsAccount,
    linkClassificationsAccount,
    listLinkedClassificationsAccount,
  } = useCustomer;

  const dialog = useSoulDialog();

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingLinkedClsAccounts, setLoadingLinkedClsAccounts] =
    useState(true);
  const [linkedClsAccounts, setLinkedClsAccounts] =
    useState<IClassificationAccountEntity[]>();
  const [data, setData] = useState<
    IResponseEntity<IClassificationAccountEntity[]> | undefined
  >();

  const requestClose = useCallback(
    (hasLinked: boolean) => {
      setIsLoadingButton(false);
      setLinkedClsAccounts(undefined);
      onRequestClose(hasLinked);
    },
    [onRequestClose],
  );

  const getLinkedClassificationAccountList = useCallback(
    async (customerId: string) => {
      setLoadingLinkedClsAccounts(true);

      const clsAccountList = await listLinkedClassificationsAccount(
        id,
        customerId,
      );
      setLoadingLinkedClsAccounts(false);

      setLinkedClsAccounts(clsAccountList.data);
    },
    [id, listLinkedClassificationsAccount],
  );

  const handleOnSelectionChange = useCallback(({ value }) => {
    setLinkedClsAccounts(value);
  }, []);

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      if (id) {
        setLoading(true);

        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const clsAccountList = await listClassificationsAccount(id, payload);

        if (!mountedRef.current) {
          return;
        }

        setData(clsAccountList);
        setLoading(false);
      }
    },
    [columns, generatePayload, id, listClassificationsAccount, mountedRef],
  );

  const handleSubmit = useCallback(async () => {
    if (linkedClsAccounts?.length === 0) {
      return;
    }

    if (customerEntity && linkedClsAccounts) {
      setIsLoadingButton(true);

      try {
        await linkClassificationsAccount(customerEntity.id, linkedClsAccounts);

        setIsLoadingButton(false);

        await dialog.fire({
          title: "Feito!",
          text: "Classificação(ões) contábil(eis) vinculada(s) com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose(true);
      } catch {
        dialog.close();
      }
    }
  }, [
    dialog,
    linkClassificationsAccount,
    linkedClsAccounts,
    customerEntity,
    requestClose,
  ]);

  const submitClassName = useClassName(
    {
      "form-button": true,
      "invalid-bkg": !!(linkedClsAccounts?.length === 0),
      "green-bkg": !!linkedClsAccounts?.length,
    },
    [linkedClsAccounts?.length],
  );

  useEffect(() => {
    const customerId = customerEntity?.id;

    if (customerId) {
      getLinkedClassificationAccountList(customerId);
    }
  }, [customerEntity?.id, getLinkedClassificationAccountList]);

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      onRequestClose={() => {
        requestClose(false);
      }}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Vínculo de classificações contábeis ao cliente</h4>
          <button
            className="react-modal-close"
            type="button"
            id="btn-cross-class-account"
            data-testid="btn-cross-class-account"
            onClick={() => {
              requestClose(false);
            }}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          {isRequired ? (
            <div className="required-warning">
              <FaExclamationCircle />
              <p>
                Para prosseguir é obrigatório vincular pelo menos uma
                Classificação Contábil ao Cliente.
              </p>
            </div>
          ) : null}
          <div className="card card-header-border">
            <p>Vinculando classificações contábeis ao cliente</p>
            <h3>{customerEntity?.name}</h3>
            <h5>
              Clique sobre a classificação contábil e depois clique em &ldquo;
              Salvar&ldquo; para vinculá-la ao cliente.
            </h5>
          </div>
          <Page>
            <header>
              <h4>
                Vincular Classificações Contábeis (
                {linkedClsAccounts?.length ?? 0})
              </h4>
            </header>
            <article>
              <InputSearch
                id="btn-search-class-account"
                value={search}
                onChange={handleSearchOnChange}
              />
            </article>
            <article className="no-padding">
              <SimpleTable<IClassificationAccountEntity>
                data={data}
                columns={columns}
                getList={getList}
                selectionMode="multiple"
                metaKeySelection={false}
                globalFilter={globalFilter}
                selection={linkedClsAccounts}
                onSelectionChange={handleOnSelectionChange}
                loading={loading || loadingLinkedClsAccounts}
              />
            </article>
          </Page>
        </div>
        <div className="col-12 react-modal-footer">
          <button
            type="button"
            className="form-button red-bkg"
            id="btn-close-class-account"
            onClick={() => {
              requestClose(false);
            }}
          >
            Fechar
          </button>
          <button
            type="submit"
            className={submitClassName}
            id="btn-save-class-account"
            disabled={isLoadingButton || linkedClsAccounts?.length === 0}
            onClick={handleSubmit}
          >
            Salvar {isLoadingButton && <FaSpinner className="spinner" />}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
