import { useCallback, useMemo } from "react";
import { FaEye, FaPaperclip, FaThumbsUp } from "react-icons/fa";
import { MenuItem, MenuItemCommandParams } from "primereact/menuitem";
import { format } from "date-fns";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { usePaymentRequestPage } from "./usePaymentRequestPage";
import { RejectReasonContainer } from "../components/RequesterPage/styles";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";

export function usePaymentRequestContextMenu() {
  const {
    menuRef,
    handleHideContextMenu,
    handleOpenAttachmentModal,
    state: { contextMenuData },
  } = usePaymentRequestPage();

  const dialog = useSoulDialog();

  const handleShowRejectReasonDialog = useCallback(
    ({ item }: MenuItemCommandParams) => {
      const date = item?.data?.rejectDate;
      const user = item?.data?.rejectUserName;
      const reason = item?.data?.rejectReason;
      const formattedDate = format(new Date(date || ""), "dd/MM/yyyy");

      dialog.fire({
        icon: "error",
        html: (
          <RejectReasonContainer>
            <p>Esta solicitação foi reprovada pois:</p>
            <div>
              <p>
                Desativado por: <strong>{user}</strong>{" "}
                <small>({formattedDate})</small>
              </p>
              <p>{reason}</p>
            </div>
          </RejectReasonContainer>
        ),
      });
    },
    [dialog],
  );

  return useMemo(() => {
    const id = contextMenuData?.id || "";
    const status = contextMenuData?.status;

    const reviewButton: MenuItem = {
      target: "_blank",
      label: "Revisar solicitação",
      visible: status === EPaymentRequestStatus.Requested,
      url: `${SoulRoutes.PAYMENT_REQUESTS.path}/${id}/review`,
      icon: (
        <FaThumbsUp
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const viewButton: MenuItem = {
      target: "_blank",
      label: "Visualizar",
      url: `${SoulRoutes.PAYMENT_REQUESTS.path}/${id}`,
      visible:
        status === EPaymentRequestStatus.Approved ||
        status === EPaymentRequestStatus.Paid ||
        status === EPaymentRequestStatus.Rejected,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const changeAttachmentsButton: MenuItem = {
      data: contextMenuData,
      label: "Alterar anexos",
      visible:
        status === EPaymentRequestStatus.NotRequested ||
        status === EPaymentRequestStatus.Approved ||
        status === EPaymentRequestStatus.Paid,
      command: handleOpenAttachmentModal,
      icon: (
        <FaPaperclip
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const showRejectReasonButton: MenuItem = {
      data: contextMenuData,
      label: "Motivo da Reprovação",
      command: handleShowRejectReasonDialog,
      visible: status === EPaymentRequestStatus.Rejected,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-red-button)",
          }}
        />
      ),
    };

    const menuModel = [
      viewButton,
      reviewButton,
      showRejectReasonButton,
      changeAttachmentsButton,
    ];

    return {
      menuRef,
      menuModel,
      handleHideContextMenu,
    };
  }, [
    menuRef,
    contextMenuData,
    handleHideContextMenu,
    handleOpenAttachmentModal,
    handleShowRejectReasonDialog,
  ]);
}
