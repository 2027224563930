import styled from "styled-components";

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: end;
  }

  > div + div {
    margin-top: 0.5rem;

    @media (min-width: 1200px) {
      margin-top: unset;
    }
  }

  .page-tools {
    width: 100%;

    @media (min-width: 1200px) {
      width: unset;
      order: 10;
      margin-left: 0.5rem;
    }
  }

  .filter-tools {
    width: 100%;

    @media (min-width: 1200px) {
      width: unset;
    }
  }

  .balance-tool {
    width: 100%;

    @media (min-width: 1200px) {
      margin-left: 0.5rem;
      width: unset;
    }
  }
`;

export const FilterToolsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;

    & + div {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: end;

    > div {
      & + div {
        margin-top: unset;
        margin-left: 0.5rem;
      }
    }
  }

  @media (min-width: 1200px) {
    /* limpar filtro */
    > div.clr-filter-btn-wrapper {
      flex: 0 0 7.5rem;
    }

    /* seletor de entidade */
    > div.cc-filter-dropdown-wrapper {
      flex: 1 0 8.725rem;
    }

    /* data inicial */
    > div.start-dt-input-wrapper {
      flex: 1 0 5.6rem;
    }

    /* data final */
    > div.end-dt-input-wrapper {
      flex: 1 0 5.6rem;
    }

    /* mostrar */
    > div.show-btn-wrapper {
      flex: 1 0;
    }
  }

  input {
    &.isInvalid {
      border-color: var(--invalid);

      :focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }

  select,
  .p-component.p-inputtext,
  .p-component.p-dropdown {
    font-size: 0.8333rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-radius: 0.25rem;

    color: #6c757d;
    border: 1px solid #ced4da;

    .p-dropdown-label.p-inputtext.p-placeholder {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      padding-right: 0.5rem;
    }

    .p-dropdown-label.p-inputtext {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      display: inline-block;
      max-width: 150px;
    }
  }

  i.pi.pi-spin.pi-spinner {
    line-height: 1.7rem;
    margin-left: 0.4rem;
  }

  .clear-filter-button {
    line-height: normal;
    margin: unset;
    background-color: var(--soul-red-button);
    border-color: var(--soul-red-button);
    width: 100%;
    justify-content: center;
  }

  .filter-button {
    line-height: normal;
    margin: unset;
    background-color: var(--soul-color02);
    border-color: var(--soul-color02);
    width: 100%;
    justify-content: center;
  }
`;

export const PageToolsContainer = styled.div`
  display: flex;

  > div {
    display: flex;
    flex: 1 1;

    & + div {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    justify-content: end;

    > div {
      flex: unset;
    }
  }

  .p-component.p-inputtext,
  .p-component.p-dropdown {
    font-size: 0.8333rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-radius: 0.25rem;

    .p-dropdown-label.p-inputtext.p-placeholder {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      padding-right: 0.5rem;
    }

    .p-dropdown-label.p-inputtext {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      display: inline-block;
      max-width: 150px;
    }
  }

  i.pi.pi-spin.pi-spinner {
    line-height: 1.7rem;
    margin-left: 0.4rem;
  }

  .dropdown-item {
    color: var(--soul-color02);
  }

  .options-context {
    margin: unset;
    background-color: var(--soul-color02);
    border-color: var(--soul-color02);
    width: 100%;
    justify-content: center;
  }
`;

export const PanoramaOption = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background-color: transparent;

    svg {
      fill: var(--soul-red-button);
    }
  }
`;
