import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function CorrespondingProviderField(props: IFormComponentProps) {
  const { formIndex } = props;

  const formPrefix = `imports.${formIndex}` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={`${formPrefix}.correspondingProviderName`}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span>
              Fornecedor Correspondente <small>(opcional)</small>{" "}
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <input
                {...field}
                onChange={onChange}
                placeholder="Fornecedor correspondente"
                id={`txt-correspondingProvider-${formIndex}`}
                data-testid={`txt-correspondingProvider-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
