import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IGetCityByIbgeService } from "../../domain/usecases/getCityByIbgeUseCase";
import { ICityResponse } from "../models/cityResponse";

export class GetCityByIbgeService implements IGetCityByIbgeService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getCityByIbge(ibge: string): Promise<ICityResponse> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Cities/IBGE/${ibge}`;
    const { id, name, ibgeCode }: ICityResponse =
      await this.api.get<ICityResponse>(url, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });

    const res: ICityResponse = {
      id,
      name,
      ibgeCode,
    };

    return res;
  }
}
