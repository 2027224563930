import { useCallback } from "react";

export function useEmailValidator() {
  return useCallback((email: string) => {
    const regExp = /\S*@\S*[.]\S*/;
    const invalidEmail = !regExp.test(email);

    return invalidEmail;
  }, []);
}
