import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRemoveBulkTerminateAccountsContract } from "../../domain/contracts/removeBulkTerminateAccountsContract";

export class RemoveBulkTerminateAccountsService
  implements IRemoveBulkTerminateAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  removeBulkTerminateAccounts(parcelIds: string[]): Promise<void> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountReceivableParcels/UndoTermination`;
    const payload = { parcelIds };
    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
