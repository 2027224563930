import { IUserEntity } from "../entities/userEntity";

export interface IGetUserService {
  getUser(userId: string): Promise<IUserEntity>;
}

export interface IGetUserUseCase {
  getUser(userId: string): Promise<IUserEntity>;
}

export class GetUserUseCase implements IGetUserUseCase {
  constructor(private getUserService: IGetUserService) {}

  getUser(userId: string): Promise<IUserEntity> {
    return this.getUserService.getUser(userId);
  }
}
