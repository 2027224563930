import styled from "styled-components";

export const Page = styled.section`
  --pageBackground: #fff;
  --pageColor: #32325d;

  box-shadow: 0 0 0.5rem 0 rgb(136 152 170 / 40%);
  border-radius: var(--border-radius);
  width: 100%;

  /* cabecalho */
  & > header {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: var(--pageBackground);
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid rgb(222 226 230 / 60%);
    display: flex;
    flex-direction: row;

    h4 {
      font-size: 0.94rem;
      line-height: 2.4rem;
      font-weight: 600;
      color: #32325d;
    }

    button {
      &.reload {
        font-size: 1.1rem;
      }

      &.clear-filters {
        .icon {
          font-size: 1.1rem;
          margin-right: 0.5rem;
        }
      }
    }

    button + button {
      margin-left: 0.5rem;
    }

    /* body */
    & + article {
      background: var(--pageBackground);
      padding: 0.5rem 1.5rem;

      & + article {
        border-top: 1px solid rgb(222 226 230 / 60%);
      }
    }
  }

  /* footer */
  & > footer {
    padding: 0.5rem 1.5rem;
    border-top: 1px solid rgb(222 226 230 / 60%);
    background: var(--pageBackground);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
`;
