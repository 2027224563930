import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchDestinationsContract } from "../../domain/contracts/fetchDestinationsContract";
import { ESolicitImportDestination } from "../../domain/entities/solicitImportEnums";

export class FetchDestinationsService implements IFetchDestinationsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  fetchDestinations() {
    const userEntity = this.getUserLocalService.get();
    const url = "/Users/UserPaymentRequestDestination";

    return this.api.get<ESolicitImportDestination[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
