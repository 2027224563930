import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  ISearchCountriesContract,
  ISearchCountriesParams,
} from "../../domain/contracts/searchCountriesContract";
import { IEntity } from "../../domain/entities/entity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

interface ICountryResponse extends IEntity {
  name: string;
  code: string; // Sigla
  states: Array<unknown>;
}

export class SearchCountriesService implements ISearchCountriesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCountries({
    search = "",
    activesOnly,
    showMetadata,
    payloadOptions,
  }: ISearchCountriesParams) {
    const userEntity = this.getUserLocalService.get();
    let url = "/Countries";

    if (activesOnly) {
      url += "/Actives";
    }

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      columns: [
        {
          name: "name",
          data: "name",
          orderable: false,
          searchable: true,
          search: {
            regex: false,
            value: "",
          },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const response = await this.api.get<IResponseEntity<ICountryResponse[]>>(
      url,
      {
        params,
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const mappedResponse = response.data.map(country => {
      return {
        label: country.name,
        rawValue: country.id,
        metadata: showMetadata ? country : undefined,
      };
    });

    return mappedResponse;
  }
}
