import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  CustomerFormBuilder,
  ICustomerForm,
} from "../../../data/models/customerForm";
import {
  ECustomerType,
  ICustomerEntity,
} from "../../../domain/entities/customerEntity";
import { ICustomerTypeEntity } from "../../../domain/entities/customerTypeEntity";
import { MakeCustomer } from "../../../main/makeCustomer";
import { AddressForm } from "../AddressForm";
import { ForeignPersonForm } from "../ForeignPersonForm";
import { IndividualPersonForm } from "../IndividualPersonForm";
import { LegalPersonForm } from "../LegalPersonForm";
import { Container, Loading } from "./styles";

interface CustomerFormModalProps {
  currentId: string;
  isOpen: boolean;
  useCustomer: MakeCustomer;
  onRequestClose: () => void;
  onCustomerCreated(customerEntity: ICustomerEntity): void;
}

export function CustomerFormModal({
  currentId,
  isOpen,
  useCustomer,
  onRequestClose,
  onCustomerCreated,
}: CustomerFormModalProps) {
  const { getCustomerTypes, saveCustomer, getCustomer, listCountriesByName } =
    useCustomer;

  const {
    currentCompanyGroup: { id, name: companyGroupName },
  } = useCurrentCompanyGroup();

  const [isCreateMode, setIsCreateMode] = useState(true);
  const dialog = useSoulDialog();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<ICustomerForm>({
    mode: "all",
    defaultValues: CustomerFormBuilder.create(),
  });

  const [customerTypeList, setCustomerTypeList] = useState<
    ICustomerTypeEntity[]
  >([]);

  const brazilRef = useRef<{ id: string; name: string }>();

  const getCountryList = useCallback(
    async (activeOnly = false, country = "") => {
      let payload = null as PayloadEntity | null;
      if (country) {
        payload = new PayloadEntity({
          draw: 0,
          order: [],
          start: 0,
          search: { value: "", regex: false },
          columns: [
            {
              data: "name",
              name: "",
              searchable: true,
              orderable: true,
              search: { value: country, regex: false },
            },
          ],
        });
      }

      const { data } = await listCountriesByName(activeOnly, payload);
      return data;
    },
    [listCountriesByName],
  );

  useEffect(() => {
    (async () => {
      /**
       * Ação necessária para garantir que o componente
       * esteja sendo reiniciado toda vez que abre/fecha.
       */
      if (!isOpen) {
        return;
      }

      setIsLoading(true);

      const isNew = currentId === "";
      setIsCreateMode(isNew);

      const res = await getCustomerTypes();

      if (res) {
        setCustomerTypeList(res);
      }

      if (isNew) {
        const [brazilCountryData] = await getCountryList(true, "Brasil");

        brazilRef.current = brazilCountryData;

        methods.reset(
          {
            ...CustomerFormBuilder.create({
              companyGroupId: id,
              active: true,
              countryId: brazilCountryData.id,
              countryName: brazilCountryData.name,
            }),
          } as Partial<ICustomerForm>,
          { keepValues: false },
        );
      } else if (!isNew) {
        (async () => {
          const customer = await getCustomer(currentId);
          methods.reset({ ...customer }, { keepIsValid: true });
        })();
      }
      setIsLoading(false);
    })();

    return () => {
      setIsCreateMode(true);
      setCustomerTypeList([]);
      setIsLoading(false);
      setIsLoadingButton(false);
    };
  }, [
    isOpen,
    currentId,
    getCountryList,
    getCustomer,
    getCustomerTypes,
    id,
    methods,
  ]);

  // reseta variaveis quando a modal fechar
  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    methods.reset();
    onRequestClose();
  }, [methods, onRequestClose]);

  const save = async (data: ICustomerForm) => {
    setIsLoadingButton(true);
    const customerResult = { ...data };

    const customer = {
      ...customerResult,
      id: currentId,
    } as unknown as ICustomerEntity;

    const msg = isCreateMode
      ? "Cliente cadastrado com sucesso."
      : "Cliente atualizado com sucesso.";

    try {
      const newCustomer = await saveCustomer(customer);

      await dialog.fire({
        title: "Feito!",
        text: msg,
        icon: "success",
        confirmButtonText: "OK",
      });

      onCustomerCreated(newCustomer);
      requestClose();
    } catch (err) {
      dialog.close();
    }

    setIsLoadingButton(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      ariaHideApp={false}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{isCreateMode ? "Novo Cliente" : "Editar Cliente"}</h4>
          <button
            className="react-modal-close"
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>
        {isLoading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <>
            <div className="react-modal-body">
              <div className="card card-header-border">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{companyGroupName}</h3>
              </div>
              <FormProvider {...methods}>
                <div className="form-container">
                  <div className="form-row custom-margin">
                    <label className="col-6 form-control">
                      <span>Tipo</span>
                      <select
                        {...methods.register("type", {
                          required: true,
                          onChange: e => {
                            // utilizamos o reset aqui, assim limpamos os demais
                            // campos para remover qualquer status de erro
                            let brazil = {
                              countryName: brazilRef.current?.name,
                              countryId: brazilRef.current?.id,
                            };

                            if (
                              Number(e.target.value) === ECustomerType.foreign
                            ) {
                              brazil = {
                                countryName: "",
                                countryId: "",
                              };
                            }

                            methods.reset({
                              type: e.target.value,
                              active: true,
                              companyGroupId: id,
                              ...brazil,
                            });
                          },
                        })}
                        className={
                          methods.formState.errors.type?.type === "required"
                            ? "isInvalid"
                            : ""
                        }
                        data-testid="select-customer-type"
                      >
                        <option value="" disabled>
                          Tipo de cliente
                        </option>
                        {customerTypeList.map(customerType => {
                          return (
                            <option
                              key={customerType.key}
                              value={customerType.key}
                            >
                              {customerType.value}
                            </option>
                          );
                        })}
                      </select>
                      <InvalidFeedback
                        condition={
                          methods.formState.errors.type?.type === "required"
                        }
                        message="Este campo é obrigatório"
                      />
                    </label>
                  </div>

                  {methods.getValues("type") !== "" && (
                    <div>
                      <div className="card shadow custom-margin">
                        {/* TODO - melhorar tipagem que este campo
                        // está recebendo durante as seleções */}
                        {Number(methods.getValues("type")) ===
                          ECustomerType.foreign && <ForeignPersonForm />}
                        {Number(methods.getValues("type")) ===
                          ECustomerType.individualPerson && (
                          <IndividualPersonForm
                            customerId={currentId}
                            useCustomer={useCustomer}
                          />
                        )}
                        {Number(methods.getValues("type")) ===
                          ECustomerType.legalPerson && (
                          <LegalPersonForm
                            customerId={currentId}
                            useCustomer={useCustomer}
                          />
                        )}
                      </div>
                      <div className="card shadow custom-margin">
                        <AddressForm
                          useCustomer={useCustomer}
                          customerId={currentId}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormProvider>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
                data-testid="btn-fechar"
              >
                Fechar
              </button>
              <button
                type="submit"
                className={`form-button ${
                  methods.formState.isValid ? "green-bkg" : "invalid-bkg"
                }`}
                id="btn-save"
                disabled={isLoadingButton}
                onClick={methods.handleSubmit(save)}
                data-testid="btn-save"
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
}
