import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { SoulTypeahead } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { ICostCenterEntity } from "../../../../../../costCenter/domain/entities/costCenterEntity";
import { EFormMode } from "../../../domain/entities/accountReceivableEnums";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";

interface ISectionAditionalInfoState {
  classificationAccount: {
    loading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
  paymentAccount: {
    loading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
  costCenter: {
    loading: boolean;
    options: ITypeaheadOption[] | undefined;
  };
}

interface ISectionAditionalInfoProps {
  mode: EFormMode;
  useReceivableForm: MakeAccountsReceivableForm;
}

export function SectionAditionalInfo(props: ISectionAditionalInfoProps) {
  const { mode, useReceivableForm } = props;

  const {
    listActiveCostCenters,
    searchCostCenterByProject,
    searchCompanyPaymentAccounts,
    searchCustomerClassificationAccount,
  } = useReceivableForm;

  const [state, setState] = useState<ISectionAditionalInfoState>({
    classificationAccount: {
      loading: false,
      options: [],
    },
    paymentAccount: {
      loading: false,
      options: [],
    },
    costCenter: {
      loading: false,
      options: [],
    },
  });

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountReceivableFormEntity>();

  const { watch, control, getValues } = form;

  const companyId = watch("company.rawValue") as string | undefined;
  const customerId = watch("customer.rawValue") as string | undefined;
  const projectId = watch("project.rawValue") as string | undefined;

  const handleSearchClassificationAccount = async (search = "") => {
    if (!customerId) {
      return;
    }

    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      classificationAccount: {
        ...prevState.classificationAccount,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const clsAccounts = await searchCustomerClassificationAccount(
        companyGroupId,
        customerId,
        search,
        100,
        true,
      );

      setState(prevState => ({
        ...prevState,
        classificationAccount: {
          ...prevState.classificationAccount,
          options: clsAccounts,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        classificationAccount: {
          ...prevState.classificationAccount,
          loading: false,
        },
      }));
    }
  };

  const handleSearchPaymentAccount = async (search = "") => {
    if (!companyId) {
      return;
    }

    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      paymentAccount: {
        ...prevState.paymentAccount,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const response = await searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        100,
        true,
      );

      const paymentAccounts = response;

      setState(prevState => ({
        ...prevState,
        paymentAccount: {
          ...prevState.paymentAccount,
          options: paymentAccounts,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        paymentAccount: {
          ...prevState.paymentAccount,
          loading: false,
        },
      }));
    }
  };

  const handleCostCenterSearch = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      costCenter: {
        ...prevState.costCenter,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      let costCenterOptions: ITypeaheadOption[] = [];

      if (projectId) {
        costCenterOptions = await searchCostCenterByProject({
          search,
          projectId,
          companyGroupId,
        });
      } else {
        costCenterOptions = await listActiveCostCenters({
          search,
          companyGroupId,
        });
      }

      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          loading: false,
          options: costCenterOptions,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          loading: false,
        },
      }));
    }
  };

  const isClassificationAccountDisabled = useMemo(() => {
    const classificationAccountId = getValues("classificationAccount.rawValue");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!classificationAccountId,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isPaymentAccountDisabled = useMemo(() => {
    const paymentAccountId = getValues("paymentAccount.rawValue");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: true,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!paymentAccountId,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isCostCenterDisabled = useMemo(() => {
    const costCenter = getValues(
      "costCenter",
    ) as ITypeaheadOption<ICostCenterEntity>;
    const costCenterId = costCenter?.rawValue;
    const isActive = costCenter?.metadata?.active;

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: true,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!costCenterId,
    };

    if (
      costCenter &&
      mode !== EFormMode.Create &&
      mode !== EFormMode.Limited &&
      mode !== EFormMode.Conditional &&
      mode !== EFormMode.EditAttachments
    ) {
      return !isActive;
    }

    return disabledByMode[mode];
  }, [getValues, mode]);

  return (
    <Card>
      <header>Informações adicionais</header>
      <section>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Classificação contábil{" "}
              {state.classificationAccount.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <Controller
              control={control}
              rules={{ required: true }}
              name="classificationAccount"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <SoulTypeahead
                      serverSide
                      openOnFocus
                      value={field.value}
                      onChange={field.onChange}
                      id="txt-classificationAccount"
                      placeholder="Classificação contábil"
                      data-testid="txt-classificationAccount"
                      options={state.classificationAccount.options}
                      loading={state.classificationAccount.loading}
                      onSearchChange={handleSearchClassificationAccount}
                      className={fieldState?.error ? "isInvalid" : undefined}
                      disabled={isClassificationAccountDisabled || !customerId}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              Conta de pagamento{" "}
              {state.paymentAccount.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <Controller
              control={control}
              name="paymentAccount"
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <SoulTypeahead
                      serverSide
                      openOnFocus
                      value={field.value}
                      id="txt-paymentAccount"
                      onChange={field.onChange}
                      data-testid="txt-paymentAccount"
                      placeholder="Conta de pagamento"
                      loading={state.paymentAccount.loading}
                      options={state.paymentAccount.options}
                      onSearchChange={handleSearchPaymentAccount}
                      className={fieldState?.error ? "isInvalid" : ""}
                      disabled={isPaymentAccountDisabled || !companyId}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Centro de custo{" "}
              {state.costCenter.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <Controller
              control={control}
              name="costCenter"
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <SoulTypeahead
                      serverSide
                      openOnFocus
                      id="txt-costCenter"
                      value={field.value}
                      onChange={field.onChange}
                      data-testid="txt-costCenter"
                      placeholder="Centro de Custo"
                      disabled={isCostCenterDisabled}
                      options={state.costCenter.options}
                      loading={state.costCenter.loading}
                      onSearchChange={handleCostCenterSearch}
                      className={fieldState?.error ? "isInvalid" : undefined}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
        </div>
      </section>
    </Card>
  );
}
