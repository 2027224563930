import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetStorageFilebyIdContract } from "../../domain/contracts/getStorageFileByIdContract";

export class GetStorageFilebyIdService implements IGetStorageFilebyIdContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getStorageFilebyId(storageFileId: string) {
    const userEntity = this.getUserLocalService.get();

    let url = `/Downloads/PaymentAccountTransactions/Attachments/StorageFiles`;

    url = `${url}/${storageFileId}`;

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
