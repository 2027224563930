import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeleteCostCenterReportPanoramaService } from "../../domain/usecases/deleteCostCenterReportPanoramaUseCase";

export class DeleteCostCenterReportPanoramaService
  implements IDeleteCostCenterReportPanoramaService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deleteCostCenterReportPanorama(panoramaId: string): Promise<void> {
    const url = `/CostCenterReportPanoramas/${panoramaId}`;
    const userEntity = this.getUserLocalService.get();

    await this.api.delete(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
