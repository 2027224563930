import styled from "styled-components";

export const ModalInfoContainer = styled.div`
  padding: 1rem;
  margin: 0 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);

  color: #8898aa;
  font-weight: 600;
  font-size: 0.8125rem;

  h3 {
    color: #32325d;
    font-weight: 600;
    font-size: 1.0625rem;
  }
`;
