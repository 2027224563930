import { MutableRefObject, useRef } from "react";

type UsePreviousValueGenerics = unknown | undefined;

export function usePreviousValue<T extends UsePreviousValueGenerics>(
  initialValue?: T,
): [MutableRefObject<T | undefined> | undefined, (arg: T) => void] {
  const ref = useRef<T | undefined>(initialValue);

  const setPrevValue = (value: T) => {
    if (value !== ref.current) {
      ref.current = value;
    }
  };

  return [ref, setPrevValue];
}
