import { ColumnFilterOperationType } from "../../../advTable/domain/entities/advTableColumn";
import { DataTableFilterMatchModeType } from "./PSFEventEntity";

export const columnFilterOperationPayloadTypeDict: Record<
  DataTableFilterMatchModeType,
  ColumnFilterOperationType
> = {
  between: "Between",
  contains: "Contains",
  equals: "Equal",
  custom: "EqualAbsolute",
  notContains: "NotContains",
  notEquals: "NotEqual",
  startsWith: "",
  endsWith: "",
  in: "",
  lt: "",
  lte: "",
  gt: "",
  gte: "",
  dateIs: "",
  dateIsNot: "",
  dateBefore: "",
  dateAfter: "",
};

interface ISearchEntity {
  operation?: ColumnFilterOperationType;
  regex: boolean;
  value: string;
  // para casos de pesquisa por range de data
  value2?: string;
}

export interface IOrderEntity {
  column: number;
  dir: "asc" | "desc";
}

export interface IPayloadColumnEntity {
  data: string;
  name: string;
  searchable: boolean;
  orderable: boolean;
  search: ISearchEntity;
}

export class PayloadColumnEntity implements IPayloadColumnEntity {
  data = "";
  name = "";
  searchable = false;
  orderable = false;
  search = {
    regex: false,
    value: "",
  };

  constructor(params?: Partial<IPayloadColumnEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export interface IPayloadEntity {
  draw?: number;
  start: number;
  length?: number;
  data?: string;
  order: IOrderEntity[] | null | undefined;
  search: ISearchEntity;
  columns: IPayloadColumnEntity[];
  orderableStringField?: Record<string, string>;
  showRecordsTotal?: boolean;
}

export class PayloadEntity implements IPayloadEntity {
  draw = 10;
  start = 0;
  length = undefined;
  data = undefined;
  order = [];
  search = {
    regex: false,
    value: "",
  };

  columns = [];

  constructor(params?: Partial<IPayloadEntity>) {
    Object.assign(this, params);
  }
}
