import { ELocalStorageKeys } from "../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../core/domain/entities/stringfied";
import { IGetStoredCostCenterReportPanoramaIdService } from "../../domain/usecases/getStoredCostCenterReportPanoramaIdUseCase";

export class GetStoredCostCenterReportPanoramaIdService
  implements IGetStoredCostCenterReportPanoramaIdService
{
  getStoredCostCenterReportPanoramaId(): string {
    const key = ELocalStorageKeys.CostCenterReportPanoramaId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
