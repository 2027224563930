import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICustomerEntity } from "../../../../../customer/domain/entities/customerEntity";
import { IGetCustomerByIdContract } from "../../domain/contracts/getCustomerByIdContract";

export class GetCustomerByIdService implements IGetCustomerByIdContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCustomerById(providerId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Customers/${providerId}`;

    const response = await this.api.get<ICustomerEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
