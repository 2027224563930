import { useCallback, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  SimpleTable,
  ISimpleTableHandle,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import {
  CompetencyEntity,
  ICompetencyEntity,
} from "../../../domain/entities/competencyEntity";
import { MakeCompetency, makeCompetency } from "../../../main/makeCompetency";
import { useCompetencyGrid } from "../../hooks/useCompetencyGrid";
import { CompetencyFormModal } from "../CompetencyFormModal";
import { Container } from "./styles";

interface CompetencyPageProps {
  useCompetency: MakeCompetency;
}

export function CompetencyPage({ useCompetency }: CompetencyPageProps) {
  const table = useRef<ISimpleTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [isCompetencyModalOpen, setIsCompetencyModalOpen] =
    useState<boolean>(false);
  const [currentCompetencyId, setCurrentCompetencyId] = useState("");

  const openCompetencyFormModal = useCallback((currentId = "") => {
    setCurrentCompetencyId(currentId);
    setIsCompetencyModalOpen(true);
  }, []);

  const closeCompetencyFormModal = useCallback(() => {
    setIsCompetencyModalOpen(false);
    setCurrentCompetencyId("");
    table.current?.reload();
  }, []);

  const { listCompetencies, toggleCompetency } = useCompetency;

  const dialog = useSoulDialog();

  const toggle = useCallback(
    async currentId => {
      try {
        const { active } = await toggleCompetency(currentId);
        const text = active ? "ativada" : "inativada";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Competência ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleCompetency],
  );

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useCompetencyGrid({
    openEditModal: openCompetencyFormModal,
    toggle,
  });
  const { generatePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IResponseEntity<CompetencyEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const competencyList = await listCompetencies(id, payload);

        if (!mountedRef.current) {
          return;
        }

        setData(competencyList);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setLoading(false);

        // Isso é necessário pois temos elementos dinamicos
        // com tooltip e o ReactTooltip precisa escanea-los
        ReactTooltip.rebuild();
      }
    },
    [columns, generatePayload, id, listCompetencies, mountedRef],
  );

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const allowedProfiles = useAllowedProfiles();

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          {allowedProfiles(
            EUserProfile.financialManagement,
            EUserProfile.supervisor,
          ) && (
            <button
              type="button"
              id="btn-add"
              className="default-button"
              onClick={() => openCompetencyFormModal()}
            >
              <FaPlus /> Adicionar Competência
            </button>
          )}
          <CompetencyFormModal
            isOpen={isCompetencyModalOpen}
            onRequestClose={closeCompetencyFormModal}
            currentId={currentCompetencyId}
            useCompetency={useCompetency}
          />
        </header>
        <article className="no-padding">
          <SimpleTable<ICompetencyEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface CompetencyPageFactoryProps {
  api: IApiService;
}

export function CompetencyPageFactory({ api }: CompetencyPageFactoryProps) {
  return <CompetencyPage useCompetency={makeCompetency(api)} />;
}
