import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPaymentTypeEntity } from "../../domain/entities/paymentTypeEntity";
import { IListPaymentTypeService } from "../../domain/usecases/listPaymentTypeUseCase";

export class ListPaymentTypeService implements IListPaymentTypeService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listPaymentType(): Promise<IPaymentTypeEntity[]> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Enums/PaymentTypes`;

    const response = await this.api.get<IPaymentTypeEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
