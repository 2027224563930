import { IPanoramaAngularModel } from "../../../../advTable/data/models/panoramaAngularModel";
import { ITransformPanoramaAngularService } from "../../../../advTable/data/services/transformPanoramaAngularService";
import { IAdvTableColumn } from "../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../advTable/domain/entities/panoramaEntity";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveCostCenterReportPanoramaService } from "../../domain/usecases/saveCostCenterReportPanoramaUseCase";

export class SaveCostCenterReportPanoramaService
  implements ISaveCostCenterReportPanoramaService
{
  constructor(
    private transformPanoramaAngularService: ITransformPanoramaAngularService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveCostCenterReportPanorama(
    columns: IAdvTableColumn[],
    panorama: IPanoramaEntity,
  ): Promise<IPanoramaEntity> {
    const payload =
      this.transformPanoramaAngularService.transformPanoramaAngular(
        columns,
        panorama,
      );

    const url = `/CostCenterReportPanoramas`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.post<IPanoramaAngularModel>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return PanoramaEntity.create({
      id: response.id,
      name: response.name,
      systemDefault: false,
      userId: response.userId,
      active: response.active,
      panoramaDefinition: panorama.panoramaDefinition,
    });
  }
}
