import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function FuspPurchaseOrderIdField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.fuspPurchaseOrderId` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{
        maxLength: 16,
        validate: {
          max: val => BigInt(val || "") <= Number.MAX_SAFE_INTEGER,
        },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value;
          const numericValue = inputValue.replace(/\D/g, "");

          field.onBlur();
          field.onChange(numericValue);
        };

        return (
          <label className="form-control">
            <span>
              N° pedido de compra <small>(opcional)</small>
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <input
                {...field}
                onChange={onChange}
                placeholder="N° pedido de compra"
                id={`txt-fusp-purchase-order-${formIndex}`}
                data-testid={`txt-fusp-purchase-order-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              condition={fieldState?.error?.type === "maxLength"}
              message="O número do pedido deve ter no máximo 16 dígitos"
            />
            <InvalidFeedback
              condition={fieldState?.error?.type === "max"}
              message="Número do pedido inválido"
            />
            <InvalidFeedback
              condition={fieldState?.error?.type === "custom"}
              message={
                fieldState?.error?.message || "Número do pedido inválido"
              }
            />
          </label>
        );
      }}
    />
  );
}
