import { useState } from "react";
import { Controller } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import {
  IFormComponentProps,
  ITypeaheadState,
} from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function ProjectField(props: IFormComponentProps) {
  const { formIndex } = props;

  const formPrefix = `imports.${formIndex}` as const;

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { handleInputClassName, useDebtImport } = useDebtImportPage();

  const { searchProject } = useDebtImport;

  const [state, setState] = useState<ITypeaheadState>({
    options: [],
    loading: false,
  });

  const handleProjectSearch = async (search = "") => {
    await debounceTime(750);

    setState(prevState => {
      return {
        loading: true,
        options: prevState?.options || [],
      };
    });

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchProject({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      const projects = response.data;

      setState({
        loading: false,
        options: projects,
      });
    } finally {
      setState(prevState => {
        return {
          loading: false,
          options: prevState?.options || [],
        };
      });
    }
  };

  return (
    <Controller
      name={`${formPrefix}.project`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ISoulTypeaheadChangeEvent) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span>
              Projeto{" "}
              {state?.loading && <i className="pi pi-spin pi-spinner" />}
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                value={field.value}
                onChange={onChange}
                placeholder="Projeto"
                id={`txt-project-${formIndex}`}
                options={state?.options || []}
                loading={state?.loading || false}
                onSearchChange={handleProjectSearch}
                data-testid={`txt-project-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
