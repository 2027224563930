import { Control, Controller } from "react-hook-form";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { ProjectTaxesForm } from "../ProjectTaxesModal";

interface TaxIgnoreFieldProps {
  taxIndex: number;
  projectIndex: number;
  control: Control<ProjectTaxesForm>;
}

export function TaxIgnoreField(props: TaxIgnoreFieldProps) {
  const { control, projectIndex, taxIndex } = props;

  return (
    <span
      data-tip={'Caso "Sim", essa taxa será<br />ignorada para este projeto.'}
    >
      <Controller
        control={control}
        name={`taxesList.${projectIndex}.projectTaxes.${taxIndex}.ignoreTax`}
        render={({ field }) => {
          const handleOnChange = async (newValue: boolean) => {
            field.onChange(newValue);
            return newValue;
          };

          return (
            <Toggle
              value={field.value}
              onChange={handleOnChange}
              id={`btn-tax-${projectIndex}-ignore-${taxIndex}`}
              data-testid={`btn-tax-${projectIndex}-ignore-${taxIndex}`}
            />
          );
        }}
      />
    </span>
  );
}
