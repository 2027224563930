import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { ICostCenterEntity } from "../entities/costCenterEntity";

export interface IListCostCentersService {
  listCostCenters(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<ICostCenterEntity[]>>;
}

export interface IListCostCentersUseCase {
  listCostCenters(
    companyGroupId: string,
    actives?: boolean,
  ): Promise<IServerSideResponseModel<ICostCenterEntity[]>>;
}

export class ListCostCentersUseCase implements IListCostCentersUseCase {
  constructor(private listCostCentersService: IListCostCentersService) {}

  listCostCenters(
    companyGroupId: string,
    actives = true,
  ): Promise<IServerSideResponseModel<ICostCenterEntity[]>> {
    return this.listCostCentersService.listCostCenters(companyGroupId, actives);
  }
}
