import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICheckReviewDocumentNumberContract,
  ICheckReviewDocumentNumberParams,
} from "../../domain/contracts/checkReviewDocumentNumberContract";

interface ICheckDocumentNumberResponse {
  accountPayableId: string | null;
  code: null;
  message: string;
  success: boolean;
}

interface ICheckDocumentNumberError {
  accountPayableId: string;
}

export class CheckReviewDocumentNumberService
  implements ICheckReviewDocumentNumberContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkReviewDocumentNumber(
    payload: ICheckReviewDocumentNumberParams,
  ): Promise<boolean> {
    const userEntity = this.getUserLocalService.get();
    const url = "/AccountsPayable/CheckDocumentNumber";

    const response = await this.api.post<ICheckDocumentNumberResponse>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (response.success) {
      return true;
    }

    const error: ICheckDocumentNumberError = {
      accountPayableId: response.accountPayableId as string,
    };

    return Promise.reject(error);
  }
}
