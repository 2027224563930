import { useCallback, useMemo } from "react";
import { BsFillPieChartFill } from "react-icons/bs";
import { FaArchive, FaCog, FaRedo } from "react-icons/fa";
import {
  RouteType,
  SoulPages,
  SoulRoute,
  SoulRoutes,
} from "../../../admin/domain/entities/soulRoutes";
import { EUserProfile } from "../../domain/entities/userEntity";
import { companyGroupConfig } from "../../../admin/domain/entities/companyGroupConfig";
import { useCurrentCompanyGroup } from "../../../admin/presentation/hooks/useCurrentCompanyGroup";

type BasicRoute = Omit<SoulRoute, "dashboard" | "routeType">;

interface SideBarLink extends BasicRoute {
  childId?: string;
  children?: BasicRoute[];
}

export function useSideBarLinks() {
  const {
    currentCompanyGroup: { name: companyGroupName },
  } = useCurrentCompanyGroup();

  const [routes, allowedRoutes] = useMemo(
    () => [
      Object.entries(SoulRoutes) as [SoulPages, SoulRoute][],
      companyGroupConfig?.[companyGroupName]?.routes,
    ],
    [companyGroupName],
  );

  const filterRoutesByType = useCallback(
    (type: RouteType) => {
      return routes
        .filter(([, routeObject]) => routeObject.routeType === type)
        .filter(([routeName]) => allowedRoutes?.includes(routeName) ?? true)
        .map(([, routeObject]) => {
          const ignoredTitles = {
            [RouteType.REPORT]: "Relatórios de ",
            [RouteType.TRANSACTION]: "Movimentações de ",
          };

          const ignoredText = ignoredTitles[type as keyof typeof ignoredTitles];

          return {
            id: routeObject.id,
            path: routeObject.path,
            icon: routeObject?.icon,
            profile: routeObject?.profile,
            title: routeObject.title.replace(ignoredText ?? "", ""),
          };
        });
    },
    [allowedRoutes, routes],
  );

  const sideBarLinks: SideBarLink[] = useMemo(() => {
    const singleLinks = filterRoutesByType(RouteType.SINGLE);
    const reportLinks = {
      id: "nav-reports",
      title: "Relatórios",
      path: "",
      icon: <BsFillPieChartFill />,
      childId: "report-child",
      children: filterRoutesByType(RouteType.REPORT),
      profile: [
        EUserProfile.master,
        EUserProfile.manager,
        EUserProfile.financial,
        EUserProfile.financialAccounting,
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ],
    };

    const transactionLinks = {
      id: "nav-transactions",
      path: "",
      title: "Movimentações",
      icon: <FaRedo />,
      childId: "bank-movement-child",
      children: filterRoutesByType(RouteType.TRANSACTION),
      profile: [
        EUserProfile.master,
        EUserProfile.financial,
        EUserProfile.financialAccounting,
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
        EUserProfile.internal,
      ],
    };

    const registerLinks = {
      id: "nav-registers",
      path: "",
      title: "Cadastros",
      icon: <FaArchive />,
      childId: "registrations-child",
      children: filterRoutesByType(RouteType.REGISTER),
      profile: [
        EUserProfile.master,
        EUserProfile.financial,
        EUserProfile.requester,
        EUserProfile.financialAccounting,
        EUserProfile.financialManagement,
        EUserProfile.manager,
        EUserProfile.supervisor,
        EUserProfile.internal,
      ],
    };

    const managementLinks = {
      id: "nav-management",
      path: "",
      title: "Administração",
      icon: <FaCog />,
      childId: "management-child",
      children: filterRoutesByType(RouteType.MANAGEMENT),
      profile: [EUserProfile.master, EUserProfile.supervisor],
    };

    return [
      ...singleLinks,
      reportLinks,
      transactionLinks,
      registerLinks,
      managementLinks,
    ];
  }, [filterRoutesByType]);

  return { sideBarLinks };
}
