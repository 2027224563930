import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IGetCostCenterClassesContract } from "../domain/contracts/getCostCenterClassesContract.ts";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";
import { IImportationMatchesSourceEntity } from "../domain/entities/importationMatchesSourceEntity";

export class GetCostCenterClassesService
  implements IGetCostCenterClassesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getCostCenterClasses(
    costCenterId: string,
    origin: EImportationMatchesOrigin,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = `/ImportationMatches/${origin}/CostCenter/${costCenterId}/Classes`;

    return this.api.get<IImportationMatchesSourceEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
