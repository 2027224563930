import { useCallback, useState } from "react";
import { useIsMounted } from "../../hooks/useIsMounted";
import { Container, Label, Toggler } from "./styles";

interface ToggleProps {
  id?: string;
  value?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  inactiveClassName?: string;
  "data-testid"?: string;
  onChange?(value: boolean): Promise<boolean>;
}

export function Toggle({
  id,
  value,
  disabled,
  readonly = false,
  inactiveClassName,
  "data-testid": dataTestId,
  onChange,
}: ToggleProps) {
  const [toggled, setToggled] = useState(!!value);

  const mountedRef = useIsMounted();

  const handleTogglerClick = useCallback(async () => {
    if (!mountedRef.current) {
      return;
    }

    if (disabled) {
      return;
    }

    if (onChange) {
      const toggleResult: boolean = await onChange(!toggled);

      if (!mountedRef.current) {
        return;
      }

      setToggled(toggleResult);
    } else {
      setToggled(!toggled);
    }
  }, [disabled, mountedRef, onChange, toggled]);

  return (
    <Container className={!toggled ? inactiveClassName : undefined}>
      <button
        id={id}
        type="button"
        disabled={!!disabled}
        data-testid={dataTestId}
        onClick={readonly ? undefined : handleTogglerClick}
      >
        <Toggler className={toggled ? "toggled" : undefined} />
        <Label className={toggled ? "toggled" : undefined}>
          {toggled && "Sim"}
          {!toggled && "Não"}
        </Label>
      </button>
    </Container>
  );
}

Toggle.defaultProps = {
  inactiveClassName: "",
  "data-testid": undefined,
};
