import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 33.75rem;

  .react-modal-body {
    &.pt-0 {
      padding-top: 0;
    }

    .card {
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      background-color: #fff;
      border: 0.0625rem solid rgba(0, 0, 0, 0.05);
      border-radius: 0.375rem;

      .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.5rem;
      }

      & + table {
        margin-top: 1.25rem;
      }
    }

    table {
      margin: 0 auto;
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      border: 0.0625rem solid rgba(0, 0, 0, 0.05);

      thead {
        th {
          padding: 0.75rem 0.5rem;
          font-size: 0.65rem;
          text-transform: uppercase;
          letter-spacing: 0.0625rem;
          border-bottom: 0.0625rem solid #e9ecef;
          border-top: 0.0625rem solid #e9ecef;
          background-color: #f6f9fc;
          color: #8898aa;
          text-align: left;
        }
      }

      tbody {
        tr {
          &:nth-of-type(even) {
            background-color: #f6f9fc;
          }

          td {
            padding: 0.5rem;

            &.no-fee-row {
              text-align: center;
            }
          }

          &:hover {
            background-color: #ecf2f9;
          }

          & + tr {
            border-top: 0.0625rem solid #e9ecef;
          }
        }
      }
    }
  }

  .react-modal-footer {
    display: flex;

    #btn-close {
      margin-left: auto;
    }

    .clear-button {
      background: var(--soul-color03);
    }
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .fee-error-list {
    background: green;
  }
`;

export const FeeErrorList = styled.div`
  font-size: 0.875rem;
  margin-top: 1rem;
  overflow: auto;
  max-height: 12.25rem;

  div {
    padding: 0.5rem 1rem;
    color: var(--soul-red-button);
    text-align: left;
    border-radius: 0.375rem;
    border: 1px solid #f5365c24;
    background: #f8d7da;

    + div {
      margin-top: 0.5rem;
    }
  }
`;

export const ReadOnlyFormControl = styled.div`
  background: #e9ecef;
  font-size: 0.875rem;

  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  display: block;
  width: 100%;
  font-weight: 400;
  border: 0.0625rem solid #cad1d7;
`;
