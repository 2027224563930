import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";

const TextArea = styled.textarea`
  max-width: 100%;
  min-width: 100%;

  min-height: 4.5rem;
`;

interface ISectionInvoiceDescriptionProps {
  readonly: boolean;
}

export function SectionInvoiceDescription(
  props: ISectionInvoiceDescriptionProps,
) {
  const { readonly } = props;

  const { register } = useFormContext<IAccountReceivableFormEntity>();

  return (
    <Card>
      <header>Informações da Nota Fiscal</header>
      <section>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Descrição da Nota Fiscal <small>(opcional)</small>
            </span>
            <TextArea
              disabled={readonly}
              id="txt-invoiceServiceDescription"
              placeholder="Descrição da Nota Fiscal"
              data-testid="txt-invoiceServiceDescription"
              {...register("invoiceServiceDescription")}
            />
          </label>
        </div>
      </section>
    </Card>
  );
}
