import { DataTableFilterMetaData } from "primereact/datatable";
import {
  ITypedColumn,
  EColumnType,
} from "../../../../advTable/domain/entities/advTableColumn";
import { IPFSEventEntity } from "../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleSortableColumn,
  ISimpleSearchableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  IPayloadEntity,
  IPayloadColumnEntity,
  PayloadColumnEntity,
  columnFilterOperationPayloadTypeDict,
  IOrderEntity,
  PayloadEntity,
} from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGeneratePayloadUseCase } from "../../../../simpleTable/domain/usecases/generatePayloadUseCase";

export class AdvGeneratePayloadExportUseCase
  implements IGeneratePayloadUseCase
{
  generatePayload(
    { first, globalFilter, rows, multiSortMeta = [], filters }: IPFSEventEntity,
    columns: ISimpleColumn[],
  ): IPayloadEntity {
    let filterableColumns: IPayloadColumnEntity[] = [];

    // mapeia as colunas do formato do cliente (soul) para o formato do servidor
    const mappedColumns = columns.map(column => {
      const data = column.field;
      const { sortField: _sortField } = column as ISimpleSortableColumn;
      const { searchField: _searchField } = column as ISimpleSearchableColumn;
      let orderable = true;

      // quando a coluna é uma coluna formatada server-side adicionamos
      // a coluna com o dado original (Ex.: statusString => status)
      if (!!_sortField || !!_searchField) {
        orderable = false;

        filterableColumns = [
          ...filterableColumns,
          {
            data: _sortField || _searchField,
            orderable: true,
          } as PayloadColumnEntity,
        ];
      }

      return {
        data,
        orderable,
      };
    });

    if (filters) {
      Object.entries(filters)
        // A table atual do primereact inclui no objeto filters uma "coluna"
        // chamada global quando temos uma pesquisa feita atraves do globalFilter
        // nao podemos enviá-la para a API backend pois essa coluna nao existe e
        // isso causa erro
        .filter(([fieldName]) => fieldName !== "global")
        .map(([fieldName, fieldValue]) => {
          const { matchMode, value } = fieldValue as DataTableFilterMetaData;
          const typedColumn = columns.find(
            column =>
              (column as ISimpleSearchableColumn).searchField === fieldName,
          );

          const columnType = (typedColumn as ITypedColumn)?.columnType;

          let searchValue = value as unknown as string;

          if (Array.isArray(value) && matchMode !== "between") {
            searchValue = value.join(",");
          }

          // aqui tratamos o caso especifico de filtro de chave estrangeira, que
          // deve retornar uma string com os ids das entidades estrangeiras
          // separadas por virgulas
          if (Array.isArray(value) && columnType === EColumnType.relationship) {
            searchValue = value.map(v => v.rawValue).join(",");
          }

          const searchValues: Partial<IPayloadColumnEntity> = {
            searchable: true,
            search: {
              operation: columnFilterOperationPayloadTypeDict[matchMode],
              regex: false,
              value: searchValue,
            },
          };

          // aqui tratamos o caso especifico de filtro por data, que
          // deve incluir um value2 pra formar o range de data
          if (columnType === EColumnType.date && searchValues.search) {
            const [search1, search2] = value as Array<string>;

            if (search1) {
              searchValues.search.value = search1;
            }

            if (search2) {
              searchValues.search.value2 = search2;
            }
          }

          // se está presente no array de colunas mapeadas, identifdicamos
          // a coluna e atribuimos os valores de busca
          if (
            mappedColumns.some(mappedColumn => mappedColumn.data === fieldName)
          ) {
            const column = mappedColumns.find(
              mappedColumn => mappedColumn.data === fieldName,
            );

            if (column) {
              Object.assign(column, searchValues);
            }
          }
          // se está presente no array de colunas filtráveis, identifdicamos
          // a coluna e atribuimos os valores de busca
          else if (
            filterableColumns.some(
              filterableColumn => filterableColumn.data === fieldName,
            )
          ) {
            const column = filterableColumns.find(
              filterableColumn => filterableColumn.data === fieldName,
            );

            if (column) {
              Object.assign(column, searchValues);
            }
          }
          // se nao estiver em nenhum dos dois arrays, incluimos uma nova coluna
          // com os valores de busca no array de colunas filtraveis
          else {
            filterableColumns = [
              ...filterableColumns,
              {
                data: fieldName,
                name: fieldName,
                searchable: true,
                orderable: true,
                search: {
                  operation: columnFilterOperationPayloadTypeDict[matchMode],
                  regex: false,
                  value: searchValue,
                },
              },
            ];
          }

          return fieldName;
        });
    }

    // combina as colunas server-side com as colunas ordenáveis e filtráveis
    // const requestColumns = [...mappedColumns, ...filterableColumns];
    const requestColumns: IPayloadColumnEntity[] = [];

    for (let i = 0; i < columns.length; i += 1) {
      const { field, sortField } = columns[i] as ISimpleSortableColumn;
      const { searchField } = columns[i] as ISimpleSearchableColumn;

      const mappedCol = mappedColumns.find(col => col.data === field);
      const filterCol = filterableColumns.find(
        fCol => fCol.data === sortField || fCol.data === searchField,
      );

      if (mappedCol) {
        requestColumns.push(mappedCol as IPayloadColumnEntity);
      }

      if (filterCol) {
        requestColumns.push(filterCol);
      }
    }

    let order = multiSortMeta
      ?.map(
        metaField =>
          ({
            column: requestColumns.findIndex(c => metaField.field === c.data),
            dir: metaField.order === 1 ? "asc" : "desc",
          } as IOrderEntity),
      )
      // odenamos as colunas do order na mesma sequencia
      // em que elas aparecem na lista de colunas
      .sort((orderA, orderB) => {
        if (orderA.column < orderB.column) {
          return -1;
        }

        if (orderA.column > orderB.column) {
          return 1;
        }

        return 0;
      });

    // remove coluna com indice -1 do array de ordenacao (isso pode acontecer
    // quando eu troco de um panorama para outro com menos colunas ou colunas
    // diferentes ordenadas)
    order = order?.filter(c => c.column !== -1);

    const payloadEntity = new PayloadEntity({
      start: first,
      length: rows,
      columns: requestColumns.map(requestColumn => {
        return new PayloadColumnEntity(requestColumn);
      }),
      order,
      search: {
        regex: false,
        value: globalFilter || "",
      },
    });

    return payloadEntity;
  }
}
