import { format } from "date-fns";
import { useCallback } from "react";
import {
  EGenerateTaxStatus,
  ICompanyTaxEntity,
} from "../../domain/entities/companyTaxEntity";
import { CompanyTaxFormEntity } from "../../domain/entities/companyTaxFormEntity";

export function useCompanyTaxForm() {
  const formatDate = useCallback((date: string) => {
    if (!date) return "";
    return format(new Date(date), "ddMMyyyy");
  }, []);

  const createForm = useCallback(
    (tax?: ICompanyTaxEntity) => {
      return new CompanyTaxFormEntity({
        tax: tax?.tax,
        id: tax?.id || "",
        name: tax?.name || "",
        isUsp: !!tax?.isUsp,
        providerId: tax?.providerId || "",
        paymentAccountId: tax?.paymentAccountId || "",
        classificationUspId: tax?.classificationUspId || "",
        generateTax: tax?.generateTax || EGenerateTaxStatus.Empty,
        endDate: tax?.endDate ? formatDate(tax.endDate) : "",
        classificationAccountId: tax?.classificationAccountId || "",
        startDate: tax?.startDate ? formatDate(tax.startDate) : "",
        classificationAssessmentId: tax?.classificationAssessmentId || "",
      });
    },
    [formatDate],
  );

  return createForm;
}
