import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { Controller } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import { Container } from "./styles";
import { useGenerateFeeModal } from "../../hooks/useGenerateFeeModal";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { RequestProgressModal } from "../RequestProgressModal";

export function GenerateFeeReportModal() {
  const {
    formProps,
    handleClose,
    validateEndDate,
    generateFeeReport,
    validateStartDate,
    progressModalState,
    generateFeeModalOpen,
  } = useGenerateFeeModal();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = formProps;

  const dateValidator = useDateValidator();

  return (
    <Modal
      onRequestClose={handleClose}
      isOpen={generateFeeModalOpen}
      className="react-modal-content"
      shouldCloseOnEsc={!isSubmitting}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Relatório de Impostos por Despesas</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={handleClose}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="react-modal-body">
          <form
            id="generate-fee-form"
            className="form-container"
            onSubmit={handleSubmit(generateFeeReport)}
          >
            <div className="form-row">
              <label className="col-6 form-control">
                <span>Data Inicial</span>
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: true,
                    validate: {
                      invalidDate: v => !dateValidator(v),
                      validateStartDate,
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputMask
                        {...field}
                        unmask
                        mask="99/99/9999"
                        id="txt-startDate"
                        placeholder="Data de emissão"
                        data-testid="txt-startDate"
                        className={errors?.startDate ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.startDate?.type === "required"}
                      />
                      <InvalidFeedback
                        message="Preencha uma data válida"
                        condition={errors.startDate?.type === "invalidDate"}
                      />
                      <InvalidFeedback
                        condition={
                          errors.startDate?.type === "validateStartDate"
                        }
                        message="A data inicial deve ser menor que a data final"
                      />
                    </>
                  )}
                />
              </label>
              <label className="col-6 form-control">
                <span>Data Final</span>
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: true,
                    validate: {
                      invalidDate: v => !dateValidator(v),
                      validateEndDate,
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputMask
                        {...field}
                        unmask
                        id="txt-endDate"
                        mask="99/99/9999"
                        data-testid="txt-endDate"
                        placeholder="Data de emissão"
                        className={errors?.endDate ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.endDate?.type === "required"}
                      />
                      <InvalidFeedback
                        message="Preencha uma data válida"
                        condition={errors.endDate?.type === "invalidDate"}
                      />
                      <InvalidFeedback
                        condition={errors.endDate?.type === "validateEndDate"}
                        message="A data final deve ser maior que a data inicial"
                      />
                    </>
                  )}
                />
              </label>
            </div>
          </form>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            id="btn-close"
            onClick={handleClose}
            disabled={isSubmitting}
            className="form-button red-bkg"
          >
            Cancelar
          </button>
          <button
            type="submit"
            id="btn-return"
            disabled={isSubmitting}
            form="generate-fee-form"
            className={`form-button ${isValid ? "green-bkg" : "invalid-bkg"}`}
          >
            Gerar {isSubmitting && <i className="pi pi-spin pi-spinner" />}
          </button>
        </div>
        <RequestProgressModal
          total={progressModalState.total}
          loaded={progressModalState.loaded}
          isOpen={progressModalState.isOpen}
        >
          <p>Por favor, aguarde enquanto processamos o seu arquivo...</p>
        </RequestProgressModal>
      </Container>
    </Modal>
  );
}
