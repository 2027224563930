import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../core/data/services/listAttachmentTypesService";
import { IListAttachmentTypesContract } from "../../../core/domain/contracts/listAttachmentTypesContract";
import { GetProjectTransactionsService } from "../data/services/getProjectTransactionsService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFileByIdService";
import { ListProjectsService } from "../data/services/listProjectsService";
import { ListProjectTransactionAttachmentsService } from "../data/services/listProjectTransactionAttachmentsService";
import { ListProjectTransactionsService } from "../data/services/listProjectTransactionsService";
import { SearchUsersService } from "../data/services/listUsersService";
import { SaveProjectTransactionervice } from "../data/services/saveProjectTransactionService";
import { SearchProjectsService } from "../data/services/searchProjectsService";
import { ToggleProjectTransactionService } from "../data/services/toggleProjectTransactionService";
import { UploadProjectTransactionAttachmentsService } from "../data/services/uploadProjectTransactionAttachmentsService";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFileByIdContract";
import { IListProjectTransactionAttachmentsContract } from "../domain/contracts/listProjectTransactionAttachmentsContract";
import { IUploadProjectTransactionAttachmentsContract } from "../domain/contracts/uploadProjectTransactionAttachmentsContract";
import {
  GetProjectTransactionUseCase,
  IGetProjectTransactionUseCase,
} from "../domain/usecases/getProjectTransactionUseCase";
import {
  IListProjectsUseCase,
  ListProjectsUseCase,
} from "../domain/usecases/listProjectsUseCase";
import {
  IListProjectTransactionsUseCase,
  ListProjectTransactionsUseCase,
} from "../domain/usecases/listProjectTransactionsUseCase";
import {
  ISaveProjectTransactionUseCase,
  SaveProjectTransactionUseCase,
} from "../domain/usecases/saveProjectTransactionUseCase";
import {
  ISearchProjectsUseCase,
  SearchProjectsUseCase,
} from "../domain/usecases/searchProjectsUseCase";
import {
  ISearchUsersUseCase,
  SearchUsersUseCase,
} from "../domain/usecases/searchUsersUseCase";
import {
  IToggleProjectTransactionUseCase,
  ToggleProjectTransactionUseCase,
} from "../domain/usecases/toggleProjectTransactionUseCase";

export type MakeProjectTransactions = IGetProjectTransactionUseCase &
  IListProjectTransactionsUseCase &
  IToggleProjectTransactionUseCase &
  IListProjectsUseCase &
  ISearchUsersUseCase &
  ISearchProjectsUseCase &
  ISaveProjectTransactionUseCase &
  IListAttachmentTypesContract &
  IGetStorageFilebyIdContract &
  IListProjectTransactionAttachmentsContract &
  IUploadProjectTransactionAttachmentsContract;

export function makeProjectTransactions(
  api: IApiService,
  authApi: IApiService,
): MakeProjectTransactions {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getProjectTransactionsService = new GetProjectTransactionsService(
    getUserLocalService,
    api,
  );
  const getProjectTransactionsUseCase = new GetProjectTransactionUseCase(
    getProjectTransactionsService,
  );

  const listProjectTransactionsService = new ListProjectTransactionsService(
    getUserLocalService,
    api,
  );
  const listProjectTransactionsUseCase = new ListProjectTransactionsUseCase(
    listProjectTransactionsService,
  );

  const toggleProjectTransactionService = new ToggleProjectTransactionService(
    getUserLocalService,
    api,
  );
  const toggleProjectTransactionUseCase = new ToggleProjectTransactionUseCase(
    toggleProjectTransactionService,
  );

  const saveProjectTransactionervice = new SaveProjectTransactionervice(
    getUserLocalService,
    api,
  );
  const saveProjectTransactionUseCase = new SaveProjectTransactionUseCase(
    saveProjectTransactionervice,
  );

  const listProjectsSvc = new ListProjectsService(getUserLocalService, api);
  const listProjectsUseCase = new ListProjectsUseCase(listProjectsSvc);

  const searchUsersService = new SearchUsersService(
    getUserLocalService,
    authApi,
  );
  const searchUsersUseCase = new SearchUsersUseCase(searchUsersService);

  const searchProjectsService = new SearchProjectsService(
    getUserLocalService,
    api,
  );
  const searchProjectsUseCase = new SearchProjectsUseCase(
    searchProjectsService,
  );

  const listAttachmentTypeService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listProjectTransactionAttachmentsService =
    new ListProjectTransactionAttachmentsService(getUserLocalService, api);

  const uploadProjectTransactionAttachmentsService =
    new UploadProjectTransactionAttachmentsService(getUserLocalService, api);

  return {
    getProjectTransaction(ProjectTransactionsId) {
      return getProjectTransactionsUseCase.getProjectTransaction(
        ProjectTransactionsId,
      );
    },
    listProjectTransactions(companyGroupId, payload) {
      return listProjectTransactionsUseCase.listProjectTransactions(
        companyGroupId,
        payload,
      );
    },
    toggleProjectTransaction(ProjectTransactionsId: string) {
      return toggleProjectTransactionUseCase.toggleProjectTransaction(
        ProjectTransactionsId,
      );
    },
    saveProjectTransaction(ProjectTransactions) {
      return saveProjectTransactionUseCase.saveProjectTransaction(
        ProjectTransactions,
      );
    },
    listProjects(companyGroupId, actives?) {
      return listProjectsUseCase.listProjects(companyGroupId, actives);
    },
    searchUsers(search, fetchMinLength) {
      return searchUsersUseCase.searchUsers(search, fetchMinLength);
    },
    searchProjects(companyGroupId, search?, fetchMinLength?) {
      return searchProjectsUseCase.searchProjects(
        companyGroupId,
        search,
        fetchMinLength,
      );
    },
    listAttachmentTypes() {
      return listAttachmentTypeService.listAttachmentTypes();
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listProjectTransactionAttachments(projectTransactionId) {
      return listProjectTransactionAttachmentsService.listProjectTransactionAttachments(
        projectTransactionId,
      );
    },
    uploadProjectTransactionAttachments(params) {
      return uploadProjectTransactionAttachmentsService.uploadProjectTransactionAttachments(
        params,
      );
    },
  };
}
