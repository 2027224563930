import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { RequesterTable } from "../RequesterTable";
import { RequesterToolbar } from "../RequesterToolbar";
import { Container } from "./styles";
import { RequesterContextMenu } from "../RequesterContextMenu";
import { RequesterAttachmentsModal } from "../RequesterAttachmentsModal";

export function RequesterPage() {
  return (
    <Container>
      <Page>
        <header className="custom-header">
          <RequesterToolbar />
        </header>
        <article className="no-padding fill-height">
          <RequesterTable />
          <RequesterContextMenu />
          <RequesterAttachmentsModal />
        </article>
      </Page>
    </Container>
  );
}
