import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ICheckDocumentNumberContract,
  ICheckDocumentNumberParams,
} from "../../domain/contracts/checkDocumentNumberContract";

interface ICheckDocumentNumberResponse {
  accountPayableId: string | null;
  code: null;
  message: string;
  success: boolean;
}

interface ICheckDocumentNumberError {
  accountPayableId: string;
}

export class CheckDocumentNumberService
  implements ICheckDocumentNumberContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkDocumentNumber({
    providerId,
    documentNumber,
    accountPayableId: payableId,
  }: ICheckDocumentNumberParams): Promise<boolean> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/CheckDocumentNumber`;

    const params = {
      providerId,
      documentNumber,
      payableId,
    };

    const response = await this.api.post<ICheckDocumentNumberResponse>(
      url,
      params,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (response.success) {
      return true;
    }

    const error: ICheckDocumentNumberError = {
      accountPayableId: response.accountPayableId as string,
    };

    return Promise.reject(error);
  }
}
