import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUserProfileEntity } from "../../domain/entities/userProfileEntity";
import { IListUserProfilesService } from "../../domain/usecases/listUserProfilesUseCase";

export class ListUserProfilesService implements IListUserProfilesService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listUserProfiles(): Promise<IUserProfileEntity[]> {
    const userEntity = this.getUserLocalService.get();

    const url = "/Enums/UserSoulTypes";

    const response = await this.api.get<IUserProfileEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
