import { ChangeEvent, FormEvent, useCallback, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../../advTable/domain/entities/panoramaEntity";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { IPFSEventEntity } from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import { Container } from "./styles";
import { MakePaymentAccountReport } from "../../../main/makePaymentAccountReport";
import { usePaymentAccountReportGrid } from "../../hooks/usePaymentAccountReportGrid";

interface PaymentAccountReportPanoramaFormModalProps {
  isOpen: boolean;
  pfsEvent: IPFSEventEntity;
  orderedColumnNames: string[];
  selectedColumns: IAdvTableColumn[];
  usePaymentAccountReport: MakePaymentAccountReport;
  onRequestClose: () => void;
  onPanoramaSave(newPanorama: IPanoramaEntity): void;
}

export function PaymentAccountReportPanoramaFormModal({
  isOpen,
  pfsEvent,
  selectedColumns,
  orderedColumnNames,
  usePaymentAccountReport,
  onRequestClose,
  onPanoramaSave,
}: PaymentAccountReportPanoramaFormModalProps) {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [formState, setFormState] = useState({
    panoramaName: "",
    isInvalid: false,
  });

  const handleOnrequestClose = useCallback(() => {
    setIsLoadingButton(false);
    onRequestClose();
  }, [onRequestClose]);

  const dialog = useSoulDialog();

  const { savePaymentAccountReportPanorama } = usePaymentAccountReport;

  const { columns } = usePaymentAccountReportGrid({ usePaymentAccountReport });

  const savePanorama = useCallback(
    async (panoramaData: IPanoramaEntity) => {
      setIsLoadingButton(true);

      const panoData = {
        ...panoramaData,
        panoramaDefinition: {
          ...panoramaData.panoramaDefinition,
          selectedColumns: orderedColumnNames
            .map(ordColName => {
              const index =
                panoramaData.panoramaDefinition.selectedColumns.findIndex(
                  selCol => {
                    return selCol.field === ordColName;
                  },
                );

              if (index > -1) {
                const [colDef] =
                  panoramaData.panoramaDefinition.selectedColumns.splice(
                    index,
                    1,
                  );

                return colDef;
              }

              return undefined;
            })
            .filter((col): col is IAdvTableColumn => !!col),
        },
      };

      try {
        const newPanorama = await savePaymentAccountReportPanorama(
          columns,
          panoData,
        );

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Panorama adicionado com sucesso.",
        });

        onPanoramaSave(newPanorama);
      } finally {
        dialog.close();

        setIsLoadingButton(false);
      }
    },
    [
      columns,
      dialog,
      onPanoramaSave,
      orderedColumnNames,
      savePaymentAccountReportPanorama,
    ],
  );

  const { user } = useUserLocal();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnAfterOpen = () => {
    inputRef.current?.focus();
  };

  const handleOnSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!formState.panoramaName) {
      setFormState(prev => ({ ...prev, isInvalid: true }));
      return;
    }

    if (formState.isInvalid) {
      return;
    }

    const panoramaData = PanoramaEntity.create({
      userId: user.userId,
      name: formState.panoramaName,
      panoramaDefinition: {
        pfsEventEntity: pfsEvent,
        selectedColumns: [...selectedColumns],
      },
    });

    await savePanorama(panoramaData);
    onRequestClose();
  };

  const handlePanoramaNameOnChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      panoramaName: value,
      isInvalid: !value,
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      onRequestClose={handleOnrequestClose}
      onAfterOpen={handleOnAfterOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Adicionar Panorama</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={handleOnrequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        <form onSubmit={handleOnSubmit} className="form-container row">
          <div className="col-12 react-modal-body">
            <div className="form-row">
              <label className="col-12 form-control">
                <span>Nome do panorama</span>
                <input
                  id="txt-name"
                  value={formState.panoramaName}
                  onChange={handlePanoramaNameOnChange}
                  data-testid="txt-name"
                  placeholder="Nome do panorama"
                  type="text"
                  className={formState.isInvalid ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  condition={formState.isInvalid}
                  message="Este campo é obrigatório."
                />
              </label>
            </div>
          </div>

          <div className="col-12 react-modal-footer">
            <button
              type="button"
              className="form-button red-bkg"
              id="btn-close"
              onClick={handleOnrequestClose}
            >
              Fechar
            </button>
            <button
              type="submit"
              className={`form-button ${
                !formState.isInvalid && formState.panoramaName
                  ? "green-bkg"
                  : "invalid-bkg"
              }`}
              id="btn-save"
              disabled={isLoadingButton}
            >
              Salvar {isLoadingButton && <FaSpinner className="spinner" />}
            </button>
          </div>
        </form>
      </Container>
    </ReactModal>
  );
}
