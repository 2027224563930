import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICityResponse } from "../../data/models/cityResponse";

export interface IGetCityByNameUseCase {
  getCityByName(
    stateId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ICityResponse[]>>;
}

export interface IGetCityByNameService {
  getCityByName(
    stateId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ICityResponse[]>>;
}

export class GetCityByNameUseCase implements IGetCityByNameUseCase {
  constructor(private getCityByNameService: IGetCityByNameService) {}

  getCityByName(stateId: string, payload: IPayloadEntity) {
    return this.getCityByNameService.getCityByName(stateId, payload);
  }
}
