import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IToggleProjectContract } from "../../domain/contracts/toggleProjectContract";
import { IProjectEntity } from "../../domain/entities/projectEntity";

export class ToggleProjectService implements IToggleProjectContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  toggleProject(projectId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Projects/${projectId}`;

    return this.api.delete<IProjectEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
