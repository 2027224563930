import React, { useEffect } from "react";

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: (target: EventTarget | null, event?: MouseEvent) => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback(event.target, event);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [callback, ref]);
}
