import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ILinkClassificationsAccountService } from "../../domain/usecases/linkClassificationsAccountUseCase";
import {
  ILinkClassificationsAccountModel,
  LinkClassificationsAccountModel,
} from "../models/linkClassificationsAccountModel";

export class LinkClassificationsAccountService
  implements ILinkClassificationsAccountService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async linkClassificationsAccount(
    customerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Customers/${customerId}/ClassificationAccounts`;

    const linkClsAccountModel = new LinkClassificationsAccountModel({
      classificationAccounts: classificationAccountEntity.map(({ id }) => id),
    });

    const response = await this.api.post<ILinkClassificationsAccountModel>(
      url,
      linkClsAccountModel,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return new LinkClassificationsAccountModel(response);
  }
}
