import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdatePaymentRequestContract } from "../../domain/contracts/updatePaymentRequestContract";
import { IPaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";
import { IPaymentRequestModel } from "../models/paymentRequestModel";

export class UpdatePaymentRequestService
  implements IUpdatePaymentRequestContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async updatePaymentRequest(data: IPaymentRequestFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PaymentRequests";

    const issueDate = data.issueDate
      ? format(parse(data.issueDate, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : "";

    const payUntil = data.payUntil
      ? format(parse(data.payUntil, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      : "";

    const prePayload = {
      id: data.id,
      active: true,
      assessments: data.assessments.map(ass => {
        return {
          id: ass.id || "",
          classificationAssessmentId: ass.classificationAssessment
            ?.rawValue as string,
          costCenterId: ass.costCenter?.rawValue as string,
          observation: ass.observation.replace(/\s+/g, " ").trim(),
          percentage: ass.percentage,
          value: ass.value,
        };
      }),
      barcode: data.barcode || "",
      companyId: data.company?.rawValue as string,
      description: data.description.replace(/\s+/g, " ").trim(),
      destination: data.destination,
      documentNumber: data.documentNumber,
      documentStatus: data.documentStatus?.key,
      issueDate,
      observation: data.observation.replace(/\s+/g, " ").trim(),
      paymentMethod: data.paymentMethod?.key,
      paymentRequestId: "",
      payUntil,
      providerId: data.provider?.rawValue,
      providerName: data.provider?.label,
      status: data.status,
      value: data.value,
    };

    const mappedPayload = new Map(Object.entries(prePayload));

    if (data.duplicatePaymentRequestId) {
      mappedPayload.delete("paymentRequestId");
      mappedPayload.set(
        "duplicatePaymentRequestId",
        data.duplicatePaymentRequestId,
      );
    }

    const finalPayload = Object.fromEntries(mappedPayload);

    const response = await this.api.put<IPaymentRequestModel>(
      url,
      finalPayload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response.id;
  }
}
