import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaMinusSquare, FaPlusSquare, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InputCurrency } from "../../../../../core/presentation/components/InputCurrency";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { useAllowedProfiles } from "../../../../../core/presentation/hooks/useAllowedProfiles";
import { useDateValidator } from "../../../../../core/presentation/hooks/useDateValidator";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IProjectEntity } from "../../../domain/entities/projectEntity";
import {
  ProjectTransactionInputEntity,
  IProjectTransactionInputEntity,
} from "../../../domain/entities/projectTransactionInputEntity";
import { MakeProjectTransactions } from "../../../main/makeProjectTransactions";
import { EEditModalMode } from "../../hooks/useProjectTransactionsGrid";
import { Container, Loading } from "./styles";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { IProjectTransactionAttachmentEntity } from "../../../domain/entities/projectTransactionAttachmentEntity";
import { RequestProgressModal } from "../RequestProgressModal";
import { AttachmentsGrid } from "../../../../../core/presentation/components/AttachmentsGrid";

interface ProjectTransactionFormModalProps {
  isOpen: boolean;
  currentId: string;
  useProjectTransactions: MakeProjectTransactions;
  modalMode: EEditModalMode;
  onRequestClose: () => void;
}

interface IProjectTransactionFormModalState {
  progressModal: {
    total: number;
    loaded: number;
    isOpen: boolean;
  };
}

export function ProjectTransactionFormModal({
  isOpen,
  currentId,
  useProjectTransactions,
  modalMode = EEditModalMode.edit,
  onRequestClose,
}: ProjectTransactionFormModalProps) {
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const {
    getProjectTransaction,
    saveProjectTransaction,
    listProjects,
    getStorageFilebyId,
    listAttachmentTypes,
    uploadProjectTransactionAttachments,
  } = useProjectTransactions;
  const dialog = useSoulDialog();

  const [isCreateMode, setIsCreateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [projects, setProjects] = useState<IProjectEntity[]>();
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);

  const [state, setState] = useState<IProjectTransactionFormModalState>({
    progressModal: {
      total: 0,
      loaded: 0,
      isOpen: false,
    },
  });

  const {
    formState: { errors, isValid },
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<IProjectTransactionInputEntity>({
    mode: "onChange",
  });

  const mountedRef = useIsMounted();

  const attachments = watch("attachments");

  const fetchProjects = useCallback(
    async (actives = true) => {
      if (mountedRef.current) {
        setIsLoadingProjects(true);
      }

      const { id } = currentCompanyGroup;
      const projectList = await listProjects(id, actives);

      if (mountedRef.current) {
        setProjects(projectList.data);
        setIsLoadingProjects(false);
      }

      return projectList.data;
    },
    [currentCompanyGroup, listProjects, mountedRef],
  );

  useEffect(() => {
    const isNew = currentId === "";
    setIsCreateMode(isNew);

    // popula como uma movimentacao default
    if (isNew) {
      const populateForm = async () => {
        await fetchProjects(true);

        reset(
          ProjectTransactionInputEntity.create({
            companyGroupId: currentCompanyGroup.id,
          }),
        );
      };

      populateForm();
    }
    // carrega a movimentação selecionada
    else if (!isNew) {
      const getCurrentProjectTransaction = async () => {
        const projectList = await fetchProjects(false);

        setIsLoading(true);

        const formValues = await getProjectTransaction(currentId);

        formValues.projectOrigin = projectList?.find(project => {
          return project.id === formValues.projectOrigin?.id;
        });

        formValues.projectDestination = projectList?.find(project => {
          return project.id === formValues.projectDestination?.id;
        });

        reset(formValues);

        setIsLoading(false);
      };

      getCurrentProjectTransaction();
    }
  }, [
    currentCompanyGroup.id,
    currentId,
    fetchProjects,
    getProjectTransaction,
    reset,
  ]);

  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    reset();
    onRequestClose();
    setState({
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: false,
      },
    });
  }, [onRequestClose, reset]);

  const allowedProfiles = useAllowedProfiles();
  const readonly = useMemo(() => {
    return (
      !allowedProfiles(...SoulRoutes.TRANSACTIONS_PROJECT.profile) ||
      modalMode === EEditModalMode.readonly
    );
  }, [allowedProfiles, modalMode]);

  const save = useCallback(
    async (data: IProjectTransactionInputEntity) => {
      if (readonly) {
        return;
      }

      setIsLoadingButton(true);

      const msg = isCreateMode
        ? "Movimentação criada com sucesso."
        : "Movimentação atualizada com sucesso.";

      try {
        const { id } = await saveProjectTransaction(data);

        if (data.attachments.length) {
          await uploadProjectTransactionAttachments({
            projectTransactionId: id,
            attachments: data.attachments,
            onUploadProgress(progressEvent) {
              setState(old => ({
                ...old,
                progressModal: {
                  isOpen: true,
                  total: progressEvent.total,
                  loaded: progressEvent.loaded,
                },
              }));
            },
          });
        }

        await dialog.fire({
          title: "Feito!",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose();
      } catch (err) {
        dialog.close();
      } finally {
        setState(old => ({
          ...old,
          progressModal: {
            total: 0,
            loaded: 0,
            isOpen: false,
          },
        }));
      }

      setIsLoadingButton(false);
    },
    [
      dialog,
      isCreateMode,
      readonly,
      requestClose,
      saveProjectTransaction,
      uploadProjectTransactionAttachments,
    ],
  );

  const dateValidator = useDateValidator();

  const projectOrigin = watch("projectOrigin");

  const projectDestinationOptions = useMemo(() => {
    return projects?.filter(project => project.id !== projectOrigin?.id);
  }, [projectOrigin, projects]);

  const [maxLengthReached, setMaxLengthReached] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 255) {
      setMaxLengthReached(true);
    } else {
      setMaxLengthReached(false);
    }
  };

  const handleAttachmentsChange = (
    updatedAttachments: IProjectTransactionAttachmentEntity[],
  ) => {
    setValue("attachments", updatedAttachments);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            {isCreateMode
              ? "Adicionar Movimentação de Projeto"
              : "Editar Movimentação de Projeto "}
          </h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {isLoading || isLoadingProjects ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    Projeto de Origem <FaMinusSquare />
                  </span>
                  <Controller
                    control={control}
                    name="projectOrigin"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Dropdown
                            id="sel-origin"
                            data-testid="sel-origin"
                            className={error?.type ? "isInvalid" : ""}
                            options={projects}
                            value={value}
                            dataKey="id"
                            optionLabel="description"
                            filterPlaceholder="Pesquise uma opção"
                            placeholder="Selecione uma opção"
                            readOnly={readonly}
                            disabled={readonly}
                            emptyMessage="Nenhum registro encontrado."
                            emptyFilterMessage="Nenhum registro encontrado."
                            onChange={(selectedValue: DropdownChangeParams) => {
                              onChange(selectedValue);
                              setValue("projectDestination", undefined);
                            }}
                            filter
                          />
                          <InvalidFeedback
                            condition={error?.type === "required"}
                            message="Este campo é obrigatório."
                          />
                        </>
                      );
                    }}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    Projeto de Destino <FaPlusSquare />
                  </span>
                  <Controller
                    control={control}
                    name="projectDestination"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Dropdown
                            id="sel-destination"
                            data-testid="sel-destination"
                            className={error?.type ? "isInvalid" : ""}
                            options={projectDestinationOptions}
                            value={value}
                            dataKey="id"
                            optionLabel="description"
                            filterPlaceholder="Pesquise uma opção"
                            placeholder="Selecione uma opção"
                            readOnly={readonly}
                            disabled={readonly}
                            emptyMessage="Nenhum registro encontrado."
                            emptyFilterMessage="Nenhum registro encontrado."
                            onChange={onChange}
                            filter
                          />
                          <InvalidFeedback
                            condition={error?.type === "required"}
                            message="Este campo é obrigatório."
                          />
                        </>
                      );
                    }}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Data da Movimentação</span>
                  <Controller
                    control={control}
                    rules={{
                      maxLength: 10,
                      validate: {
                        required: date => {
                          if (date.replace(/\D/g, "").length === 0) {
                            return "Este campo é obrigatório";
                          }

                          return true;
                        },
                        invalidDate: date => {
                          if (dateValidator(date)) {
                            return "Formato de data inválido.";
                          }

                          return true;
                        },
                      },
                    }}
                    name="transactionDate"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <InputMask
                          id="txt-date"
                          data-testid="txt-date"
                          className={error?.message ? "isInvalid" : ""}
                          onChange={onChange}
                          autoClear={false}
                          unmask
                          placeholder="dd/mm/aaaa"
                          mask="99/99/9999"
                          value={value}
                          readOnly={readonly}
                          disabled={readonly}
                        />
                        <InvalidFeedback
                          condition={error?.type === "invalidDate"}
                          message={error?.message || ""}
                        />
                        <InvalidFeedback
                          condition={error?.type === "required"}
                          message={error?.message || ""}
                        />
                      </>
                    )}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Valor</span>
                  <Controller
                    control={control}
                    rules={{ required: true, min: 0.01 }}
                    name="value"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <InputCurrency
                            id="txt-value"
                            data-testid="txt-value"
                            placeholder="Valor"
                            className={
                              errors.value?.type === "required" ||
                              errors.value?.type === "min"
                                ? "isInvalid"
                                : ""
                            }
                            value={value}
                            onChange={onChange}
                            disabled={readonly}
                          />
                          <InvalidFeedback
                            condition={error?.type === "required"}
                            message="Este campo é obrigatório"
                          />
                          <InvalidFeedback
                            condition={error?.type === "min"}
                            message="O valor deve ser maior que 0,00."
                          />
                        </>
                      );
                    }}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Descrição</span>
                  <input
                    {...register("description", {
                      required: true,
                      maxLength: 255,
                    })}
                    id="txt-name"
                    data-testid="txt-name"
                    placeholder="Descrição"
                    type="text"
                    onInput={handleInput}
                    className={
                      errors.description?.type === "required" ||
                      maxLengthReached
                        ? "isInvalid"
                        : ""
                    }
                    autoComplete="off"
                    disabled={readonly}
                  />
                  <InvalidFeedback
                    condition={errors.description?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                  <InvalidFeedback
                    condition={maxLengthReached}
                    message="O campo deve conter no máximo 255 caracteres"
                  />
                </label>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <AttachmentsGrid
                    disabled={readonly}
                    attachments={attachments}
                    getStorageFilebyId={getStorageFilebyId}
                    listAttachmentTypes={listAttachmentTypes}
                    onAttachmentsChange={handleAttachmentsChange}
                  />
                </div>
              </div>
              <RequestProgressModal
                mode="determinate"
                {...state.progressModal}
              />
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
              <button
                type="submit"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
                id="btn-save"
                disabled={isLoadingButton || readonly}
                onClick={handleSubmit(save)}
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
