import { IPaymentAccountTransactionInputEntity } from "../entities/paymentAccountTransactionInputEntity";
import { IPaymentAccountTransactionEntity } from "../entities/paymentAccountTransactionEntity";

export interface ISavePaymentAccountTransactionService {
  savePaymentAccountTransaction(
    PaymentAccountTransaction: IPaymentAccountTransactionInputEntity,
  ): Promise<IPaymentAccountTransactionEntity>;
}

export interface ISavePaymentAccountTransactionUseCase {
  savePaymentAccountTransaction(
    paymentAccountTransaction: IPaymentAccountTransactionInputEntity,
  ): Promise<IPaymentAccountTransactionEntity>;
}

export class SavePaymentAccountTransactionUseCase
  implements ISavePaymentAccountTransactionUseCase
{
  constructor(
    private savePaymentAccountTransactionService: ISavePaymentAccountTransactionService,
  ) {}

  savePaymentAccountTransaction(
    paymentAccountTransaction: IPaymentAccountTransactionInputEntity,
  ): Promise<IPaymentAccountTransactionEntity> {
    return this.savePaymentAccountTransactionService.savePaymentAccountTransaction(
      paymentAccountTransaction,
    );
  }
}
