import { SvgContainer } from "./styles";

interface SoulAnimationProps {
  shouldAnimateLogo: boolean;
}

export function SoulAnimation(props: SoulAnimationProps) {
  const { shouldAnimateLogo } = props;

  return (
    <SvgContainer viewBox="0 0 1352 1161" shouldAnimateLogo={shouldAnimateLogo}>
      <linearGradient
        id="base-gradient"
        x1="290.77"
        y1="559.4"
        x2="1049.6"
        y2="559.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#52e5e7" />
        <stop offset=".99" stopColor="#130cb7" />
      </linearGradient>
      <linearGradient
        id="variant-gradient-1"
        x1="300.46"
        y1="605.2"
        x2="1072.31"
        y2="605.2"
        xlinkHref="#base-gradient"
      />
      <linearGradient
        id="variant-gradient-2"
        x1="273.45"
        y1="579.35"
        x2="947.81"
        y2="579.35"
        xlinkHref="#base-gradient"
      />
      <ellipse
        className="base"
        cx="670.19"
        cy="559.4"
        rx="379.42"
        ry="363.36"
      />
      <ellipse
        className="middle"
        cx="686.38"
        cy="605.2"
        rx="385.92"
        ry="345.3"
      />
      <ellipse
        className="front"
        cx="610.63"
        cy="579.35"
        rx="337.18"
        ry="313.7"
      />
      <g>
        <path
          className="text"
          d="m426.36,688.51c-9.67,0-18.1-1.23-25.29-3.7-7.2-2.47-12.86-5.4-16.97-8.79-4.12-3.39-6.17-6.63-6.17-9.72,0-1.43.31-2.82.92-4.16.62-1.34,1.49-2.52,2.62-3.55,1.13-1.03,2.31-1.54,3.55-1.54,2.26,0,4.78,1.19,7.56,3.55,2.78,2.37,6.68,4.78,11.72,7.25,5.04,2.47,12.19,3.7,21.44,3.7,11.72,0,19.95-2.21,24.68-6.63,4.73-4.42,7.1-9.51,7.1-15.27,0-6.79-1.8-12.08-5.4-15.89-3.6-3.8-8.33-6.79-14.19-8.95-5.86-2.16-12.03-4.11-18.51-5.86-6.48-1.75-12.65-4.01-18.51-6.79-5.86-2.77-10.6-6.63-14.19-11.57-3.6-4.94-5.4-11.62-5.4-20.05,0-6.58,1.65-13.11,4.94-19.59,3.29-6.48,8.48-11.82,15.58-16.04,7.1-4.21,16.51-6.32,28.23-6.32,6.79,0,13.36.72,19.74,2.16,6.37,1.44,11.57,3.35,15.58,5.71,4.01,2.37,6.02,4.78,6.02,7.25,0,1.03-.36,2.37-1.08,4.01-.72,1.65-1.7,3.14-2.93,4.47-1.23,1.34-2.67,2.01-4.32,2.01s-3.86-.77-6.63-2.32c-2.77-1.54-6.32-2.98-10.64-4.32-4.32-1.33-9.46-2-15.42-2-7.4,0-13.37,1.34-17.89,4.01-4.53,2.68-7.82,5.81-9.87,9.41-2.06,3.6-3.08,7.15-3.08,10.65,0,5.97,1.85,10.49,5.55,13.57,3.7,3.09,8.48,5.61,14.35,7.56,5.86,1.96,12.03,3.81,18.51,5.55,6.48,1.75,12.65,4.17,18.51,7.25,5.86,3.09,10.59,7.4,14.19,12.96,3.6,5.55,5.4,12.96,5.4,22.21,0,12.14-4.32,21.8-12.96,29-8.64,7.2-20.88,10.8-36.71,10.8Z"
        />
        <path
          className="text"
          d="m559.02,688.51c-10.49,0-20.16-2.72-29-8.17-8.84-5.45-15.99-12.65-21.44-21.59-5.45-8.95-8.17-18.56-8.17-28.84v-33.63c0-10.28,2.67-19.79,8.02-28.54,5.35-8.74,12.44-15.78,21.29-21.13,8.84-5.35,18.61-8.02,29.31-8.02s20.21,2.62,29.15,7.87,16.09,12.18,21.44,20.82c5.35,8.64,8.02,18.31,8.02,29v33.63c0,10.08-2.67,19.64-8.02,28.69-5.35,9.05-12.5,16.3-21.44,21.75-8.95,5.45-18.66,8.17-29.15,8.17Zm0-18.51c7.2,0,13.67-1.9,19.44-5.71,5.76-3.8,10.38-8.79,13.88-14.96,3.5-6.17,5.24-12.65,5.24-19.43v-33.63c0-6.58-1.7-12.85-5.09-18.82-3.39-5.96-8.02-10.84-13.88-14.65-5.86-3.8-12.39-5.71-19.59-5.71s-13.68,1.91-19.44,5.71c-5.76,3.81-10.39,8.69-13.88,14.65-3.5,5.97-5.24,12.24-5.24,18.82v33.63c0,6.79,1.75,13.26,5.24,19.43,3.49,6.17,8.17,11.16,14.03,14.96,5.86,3.81,12.29,5.71,19.28,5.71Z"
        />
        <path
          className="text"
          d="m708.32,688.51c-10.9,0-20.77-2.67-29.62-8.02-8.84-5.34-15.94-12.49-21.29-21.44-5.35-8.95-8.02-18.46-8.02-28.54v-82.36c0-1.85,1.02-3.6,3.08-5.24,2.06-1.65,4.42-2.47,7.1-2.47s4.98.82,6.94,2.47c1.95,1.65,2.93,3.39,2.93,5.24v82.36c0,6.79,1.75,13.17,5.24,19.13,3.5,5.97,8.12,10.85,13.88,14.66,5.76,3.81,12.23,5.71,19.44,5.71s13.98-1.85,19.74-5.55c5.76-3.7,10.33-8.58,13.73-14.65,3.39-6.06,5.09-12.5,5.09-19.28v-82.67c0-2.06,1.03-3.8,3.09-5.24,2.05-1.44,4.42-2.16,7.1-2.16,3.08,0,5.5.72,7.25,2.16,1.75,1.44,2.62,3.19,2.62,5.24v82.67c0,10.49-2.62,20.1-7.87,28.85-5.24,8.74-12.34,15.79-21.29,21.13-8.95,5.35-18.66,8.02-29.15,8.02Z"
        />
        <path
          className="text"
          d="m816.29,686.35c-2.68,0-4.99-.82-6.94-2.47-1.96-1.64-2.93-3.5-2.93-5.55v-219.95c0-2.26.97-4.06,2.93-5.4,1.95-1.34,4.27-2.01,6.94-2.01,2.88,0,5.29.67,7.25,2.01,1.95,1.34,2.93,3.14,2.93,5.4v219.95c0,2.06-.98,3.91-2.93,5.55-1.96,1.65-4.37,2.47-7.25,2.47Z"
        />
      </g>
    </SvgContainer>
  );
}
