import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveMacroCategoryContract } from "../../domain/contracts/saveMacroCategoryContract";
import { IMacroCategoryEntity } from "../../domain/entities/macroCategoryEntity";
import { IMacroCategoryFormEntity } from "../../domain/entities/macroCategoryFormEntity";

export class SaveMacroCategoryService implements ISaveMacroCategoryContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveMacroCategory(payload: IMacroCategoryFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/MacroBoxes";

    return this.api.post<IMacroCategoryEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
