import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  EApprovalStatus,
  ICostCenterReportEntity,
} from "../../domain/entities/costCenterReportEntity";
import { IBulkDisapproveService } from "../../domain/usecases/bulkDisapproveUseCase";
import { IBulkApprovalPayloadModel } from "../models/approvalStatusModel";

export class BulkDisapproveService implements IBulkDisapproveService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async bulkDisapprove(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Reports/CostCenters/Approval`;

    const payload: IBulkApprovalPayloadModel = {
      approvalStatus: EApprovalStatus.Disapproved,
      costCenterReportIds,
    };

    const response = await this.api.put<ICostCenterReportEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
