import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IUserEntity } from "../../domain/entities/userEntity";
import { MakeUserLocal } from "../../main/makeUserLocal";

interface UserLocalProviderProps {
  userLocal: MakeUserLocal;
  children: ReactNode;
}

interface ContextData {
  user: IUserEntity;
  setUser(user: IUserEntity): void;
}

const UserLocalContext = createContext({} as ContextData);

export function UserLocalProvider({
  userLocal,
  children,
}: UserLocalProviderProps) {
  const { get, set } = userLocal;
  const [user, setUser] = useState<IUserEntity>(
    get() ?? {
      userId: "",
      name: "",
      email: "",
      active: false,
      profile: 0,
      token: "",
    },
  );

  const storeUser = useCallback(
    (userEntity: IUserEntity) => {
      setUser(userEntity);
      set(userEntity);
    },
    [set],
  );

  const value = useMemo(() => {
    return { user, setUser: storeUser };
  }, [user, storeUser]);

  return (
    <UserLocalContext.Provider value={value}>
      {children}
    </UserLocalContext.Provider>
  );
}

export function useUserLocal() {
  const context = useContext(UserLocalContext);

  return context;
}
