import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetFeeReportContract } from "../../domain/contracts/getFeeReportContract";
import { IGenerateFeeForm } from "../../presentation/hooks/useGenerateFeeModal";
import { RequestProgressCallback } from "../../domain/entities/requestProgressCallbackType";

export class GetFeeReportService implements IGetFeeReportContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getFeeReport(
    values: IGenerateFeeForm,
    companyGroupId: string,
    handleDownloadProgress: RequestProgressCallback,
  ): Promise<void> {
    const url = `Downloads/AccountsPayableFeeReport/SpreadSheets`;
    const userEntity = this.getUserLocalService.get();

    const dtStartDate = parse(values.startDate, "ddMMyyyy", new Date());
    const dtEndDate = parse(values.endDate, "ddMMyyyy", new Date());

    const InitialFilterDate = format(dtStartDate, "yyyy-MM-dd");
    const FinalFilterDate = format(dtEndDate, "yyyy-MM-dd");

    const params = {
      InitialFilterDate,
      FinalFilterDate,
      companyGroupId,
    };

    const response = await this.api.download?.(url, {
      params,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
      onDownloadProgress(progressEvent: ProgressEvent) {
        handleDownloadProgress(progressEvent.loaded, progressEvent.total);
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
