import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useTerminateModal } from "../../hooks/useTerminateModal";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";
import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";

export function TerminateModal() {
  const {
    terminateModalOpen,
    handleCloseButtonOnClick,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    listAttachmentTypes,
    handleUpdateAttachmentsBarcode,
    handleAttachmentListChange,
    isInvalid,
    handleGenerateVariableAdditional,
    handleOnGenerateRPA,
    fetchRpaParamOptions,
    getStorageFilebyId,
    handleAttachmentTypeChange,
    fetchCommercialProposalEnums,
    handleGenerateCommercialProposal,
  } = useTerminateModal();

  return (
    <Modal
      isOpen={terminateModalOpen}
      onRequestClose={handleCloseButtonOnClick}
      shouldCloseOnEsc={!state.loading}
      onAfterOpen={handleModalAfterOpen}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Baixar lançamento</h4>
          {!state.loading && !state.submitting ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleCloseButtonOnClick}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {state.loading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : (
          <form
            className="form-container"
            id="terminate-modal__form"
            onSubmit={handleSubmit}
          >
            <div className="col-12 react-modal-body">
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Data da baixa</span>
                  <InputMask
                    value={state.terminationDate}
                    onChange={handleDateChange}
                    unmask
                    mask="99/99/9999"
                    disabled={state.submitting}
                    id="txt-termination-date"
                    data-testid="txt-termination-date"
                    placeholder="Data da baixa"
                    className={state.isInvalid ? "isInvalid" : undefined}
                  />
                  <InvalidFeedback
                    condition={state.isInvalid}
                    message={state.invalidFeedback}
                  />
                </label>
              </div>

              <div className="grid-row">
                <AttachmentsGrid
                  attachments={state.attachments}
                  getStorageFilebyId={getStorageFilebyId}
                  listAttachmentTypes={listAttachmentTypes}
                  updateAttachmentsBarcode={handleUpdateAttachmentsBarcode}
                  onAttachmentsChange={handleAttachmentListChange}
                  generateVariableAdditionalAttachment={
                    handleGenerateVariableAdditional
                  }
                  generateRpaAttachment={handleOnGenerateRPA}
                  fetchRpaParamOptions={fetchRpaParamOptions}
                  emptyMessage="Para adicionar um anexo clique no botão '+ Adicionar anexo'"
                  attachmentTypeCheck={handleAttachmentTypeChange}
                  fetchCommercialProposalEnums={fetchCommercialProposalEnums}
                  generateCommercialProposalAttachment={
                    handleGenerateCommercialProposal
                  }
                />
              </div>
            </div>

            <div className="col-12 react-modal-footer">
              <button
                type="button"
                id="btn-close"
                data-testid="btn-close"
                className="form-button red-bkg"
                disabled={state.submitting}
                onClick={handleCloseButtonOnClick}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="terminate-modal__form__btn-submit"
                form="terminate-modal__form"
                data-testid="btn-submit"
                className={`form-button ${
                  isInvalid ? "invalid-bkg" : "green-bkg"
                }`}
                disabled={state.submitting}
              >
                Baixar{" "}
                {state.submitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>

            <RequestProgressModal
              loaded={state.progressModal.loaded}
              total={state.progressModal.total}
              isOpen={state.progressModal.isOpen}
            />
          </form>
        )}
      </Container>
    </Modal>
  );
}
