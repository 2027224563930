import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { FaCopy, FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Checkbox } from "../../../../../../core/presentation/components/Checkbox";
import { IconTooltip } from "../../../../../../core/presentation/components/IconTooltip";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InputDate } from "../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  NOT_FOUND_OPTION_ID,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { useDocumentFormatter } from "../../../../../../core/presentation/hooks/useDocumentFormatter";
import { MakeProvider } from "../../../../../../provider/main/makeProvider";
import { ClassificationAccountLinkFormModal } from "../../../../../../provider/presentation/components/ClassificationAccountLinkFormModal";
import { ProviderFormModal } from "../../../../../../provider/presentation/components/ProviderFormModal";
import {
  EAccountPayablePaymentMethod,
  EAccountPayableStatus,
  IAccountPayableFormEntity,
} from "../../../domain/entities/accountPayableFormEntity";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../../domain/entities/providerEntity";
import { MakeAccountsPayableForm } from "../../../main/makeAccountPayableForm";
import { ContainerSection } from "./styles";
import { EAttachmentType } from "../../../../../../core/domain/entities/attachmentsGridTypes";

interface ISectionAccountInfoState {
  documentNumber: {
    loading: boolean;
  };
  provider: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
  barcodeFieldDisabled: boolean;
  providerModalOpen: boolean;
  classificationModalOpen: boolean;
  newlyCreatedProvider: IProviderEntity | undefined;
}

interface ISectionAccountInfoProps {
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
  };
  useAccountsPayableForm: MakeAccountsPayableForm;
  useProvider: MakeProvider;
  readonly: boolean;
}

export function SectionAccountInfo({
  readonly,
  useProvider,
  useAccountsPayableForm,
  options: { documentStatuses, paymentMethods },
}: ISectionAccountInfoProps) {
  const {
    searchProvider,
    getProviderById,
    checkDocumentNumber,
    providerHasBankingData,
    providerHasClassificationAccount,
  } = useAccountsPayableForm;

  const invalidDate = useDateValidator();
  const debounceTime = useDebounceTimeAsync();
  const { payableId } = useParams<"payableId">();
  const documentFormatter = useDocumentFormatter();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountPayableFormEntity>();

  const {
    watch,
    control,
    trigger,
    setValue,
    register,
    getValues,
    resetField,
    formState: { errors },
  } = form;

  const value = watch("value");
  const status = watch("status");
  const issueAsPaid = watch("issueAsPaid");
  const attachments = watch("attachments");
  const paymentMethod = watch("paymentMethod");
  const provider = watch("provider") as ITypeaheadOption<IProviderEntity>;

  const providerId = provider?.rawValue as string | undefined;

  const isProviderCnpj =
    provider?.metadata?.documentType === ProviderDocumentType.CNPJ;

  const companyGroupId = currentCompanyGroup.id;
  const disabled = readonly || status === EAccountPayableStatus.Paid;

  const [state, setState] = useState<ISectionAccountInfoState>({
    providerModalOpen: false,
    barcodeFieldDisabled: true,
    classificationModalOpen: false,
    newlyCreatedProvider: undefined,
    documentNumber: {
      loading: false,
    },
    provider: {
      options: [],
      loading: false,
    },
  });

  const handleValueChange = (newValue: number | null): void => {
    setValue("value", newValue || 0, { shouldValidate: true });
  };

  const validateDocumentNumber = useCallback(
    async (documentNumberValue: string) => {
      await debounceTime(750);

      if (!providerId || !documentNumberValue) {
        return true;
      }

      let success = true;
      const accountPayableId = payableId === "new" ? undefined : payableId;

      setState(prevState => ({
        ...prevState,
        documentNumber: {
          ...prevState.documentNumber,
          loading: true,
        },
      }));

      try {
        await checkDocumentNumber({
          providerId,
          companyGroupId,
          accountPayableId,
          documentNumber: documentNumberValue,
        });
      } catch {
        success = false;
      } finally {
        setState(prevState => ({
          ...prevState,
          documentNumber: {
            ...prevState.documentNumber,
            loading: false,
          },
        }));
      }

      return success;
    },
    [checkDocumentNumber, companyGroupId, debounceTime, payableId, providerId],
  );

  const closeClassificationAccountLinkModal = () => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: false,
      newlyCreatedProvider: undefined,
    }));
  };

  const openClassificationAccountLinkModal = (
    newlyCreatedProvider: IProviderEntity,
  ) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: true,
      newlyCreatedProvider,
    }));
  };

  const openProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: true,
    }));
  };

  const closeProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: false,
    }));
  };

  const { listLinkedClassificationsAccount } = useProvider;

  const handleProviderCreated = async (newProvider: IProviderEntity) => {
    const clsAccountList = await listLinkedClassificationsAccount(
      companyGroupId,
      newProvider.id,
    );

    const { id, documentType, document, name } = newProvider;

    const isOtherDocType = documentType === ProviderDocumentType.other;
    const isCnpjType = documentType === ProviderDocumentType.CNPJ;

    const docValue = isOtherDocType ? document : documentFormatter(document);

    const label = `${name} ${document ? `- ${docValue}` : ""}`.trim();

    setValue(
      "provider",
      {
        label,
        rawValue: id,
        metadata: newProvider,
      },
      { shouldValidate: true },
    );

    if (!isCnpjType) {
      setValue("correspondingProviderName", "");
    }

    if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
      return;
    }

    openClassificationAccountLinkModal(newProvider);
  };

  const handleCloseClassificationModal = (hasLinked = false) => {
    closeClassificationAccountLinkModal();

    if (!hasLinked) {
      setValue("provider", null, { shouldValidate: true });
      setValue("correspondingProviderName", "");
      resetField("classificationAccount", { keepError: false });

      const docNumber = getValues().documentNumber;

      resetField("documentNumber", { keepError: false });
      setValue("documentNumber", docNumber);
    }
  };

  const handleProviderModalRequestClose = () => {
    closeProviderModal();
  };

  const handleNotFoundOptionSelected = () => {
    openProviderModal();
  };

  const handleSearchProviderChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      provider: {
        ...prevState.provider,
        loading: true,
      },
    }));

    try {
      const response = await searchProvider(companyGroupId, search, 100, true);

      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          options: response.data,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: false,
        },
      }));
    }
  };

  const handleProviderChange = async (event: ISoulTypeaheadChangeEvent) => {
    const selectedProvider = event?.target?.value;

    setValue("provider", selectedProvider, { shouldValidate: true });
    setValue("classificationAccount", null, { shouldValidate: true });

    trigger("documentNumber");

    if (!selectedProvider) {
      setValue("correspondingProviderName", "");
      return;
    }

    const selectedProviderId = selectedProvider.rawValue as string;
    const selectedProviderDocumentType = (
      selectedProvider.metadata as IProviderEntity
    )?.documentType;

    if (selectedProviderDocumentType !== ProviderDocumentType.CNPJ) {
      setValue("correspondingProviderName", "");
    }

    setState(prevState => ({
      ...prevState,
      provider: {
        ...prevState.provider,
        loading: true,
      },
    }));

    try {
      const providerHasClsAccount = await providerHasClassificationAccount(
        companyGroupId,
        selectedProviderId,
      );

      if (providerHasClsAccount) {
        return;
      }

      const providerEntity = await getProviderById(selectedProviderId);

      openClassificationAccountLinkModal(providerEntity);
    } finally {
      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: false,
        },
      }));
    }
  };

  const shouldShowBarcodeField = useMemo(() => {
    if (attachments.length <= 0) {
      return false;
    }

    return attachments.some(
      att => att.type === EAttachmentType.Billet && att.active,
    );
  }, [attachments]);

  const barcodeFieldValue = useMemo(() => {
    if (attachments.length <= 0) {
      return "";
    }

    const attBillet = attachments.find(
      att => att.type === EAttachmentType.Billet && att.active,
    );

    if (!attBillet) {
      return "";
    }

    return attBillet.barcode || "";
  }, [attachments]);

  const barcodeFieldDisabled = useMemo(() => {
    let hasBarcode = true;

    const attBillet = attachments.find(
      att => att.type === EAttachmentType.Billet,
    );

    if (attBillet) {
      hasBarcode = !!attBillet.barcode;
      return hasBarcode && state.barcodeFieldDisabled;
    }

    return false && state.barcodeFieldDisabled;
  }, [attachments, state.barcodeFieldDisabled]);

  const handleEditBarcodeButtonClick = () => {
    setState(prevState => ({
      ...prevState,
      barcodeFieldDisabled: !prevState.barcodeFieldDisabled,
    }));
  };

  const handleCopyBarcodeButtonMouseOut = () => {
    setState(prevState => ({
      ...prevState,
      copyCodeTooltipText: "Copiar código de barras",
    }));

    ReactTooltip.rebuild();
  };

  const copyBarcode = useCallback((barcode: string) => {
    navigator.clipboard.writeText(barcode);

    toast.success("Código de barras copiado", {
      autoClose: 2000,
    });
  }, []);

  const handleCopyBarcodeButtonClick = () => {
    const formValue = getValues();
    const { barcode } = formValue;

    if (!barcode) {
      return;
    }

    copyBarcode(barcode);
  };

  const updateBilletBarcodeValue = (barcode: string) => {
    const updatedAtts = [...attachments];

    const index = updatedAtts.findIndex(
      att => att.type === EAttachmentType.Billet && att.active,
    );

    if (index === -1) {
      return;
    }

    updatedAtts[index].barcode = barcode;

    setValue("attachments", updatedAtts);
  };

  const handleBarcodeInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const barcode = event.target.value || "";

    updateBilletBarcodeValue(barcode);

    setState(prevState => ({
      ...prevState,
      barcodeFieldDisabled: true,
    }));
  };

  const validateBankDataRequired = useCallback(
    async (val: ITypeaheadOption | null) => {
      if (paymentMethod === null) {
        return true;
      }

      const selectedProviderId = val?.rawValue;

      if (!selectedProviderId || selectedProviderId === NOT_FOUND_OPTION_ID) {
        return true;
      }

      if (
        paymentMethod.key !==
        EAccountPayablePaymentMethod.CurrentAccountTransfer
      ) {
        return true;
      }

      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: true,
        },
      }));

      try {
        const providerHasBankData = await providerHasBankingData(
          selectedProviderId as string,
        );

        if (providerHasBankData) {
          return true;
        }
      } finally {
        setState(prevState => ({
          ...prevState,
          provider: {
            ...prevState.provider,
            loading: false,
          },
        }));
      }

      return false;
    },
    [paymentMethod, providerHasBankingData],
  );

  const handleEditProvider = () => {
    if (!provider) {
      return;
    }

    setState(prevState => ({ ...prevState, providerModalOpen: true }));
  };

  useEffect(() => {
    if (shouldShowBarcodeField) {
      ReactTooltip.rebuild();
    }

    setValue("barcode", barcodeFieldValue);
  }, [barcodeFieldValue, setValue, shouldShowBarcodeField]);

  useEffect(() => {
    register("provider", {
      validate: {
        required: val => !!val?.rawValue,
        bankDataRequired: validateBankDataRequired,
      },
    });
    register("value", {
      validate: {
        required: valValue => !!valValue.toString(),
        greaterThanZero: valValue => valValue > 0,
      },
    });
  }, [register, validateBankDataRequired]);

  return (
    <Card>
      <header>Informações do lançamento</header>
      <ContainerSection>
        <div className="form-row">
          <label className="col-8 form-control">
            <span className="accounts-payable-form__provider-input__label">
              Fornecedor{" "}
              <IconTooltip icon="pi pi-question-circle" position="right">
                <p>
                  <strong>Fornecedor</strong>
                </p>
                <small>
                  Você pode pesquisar pela Razão Social, Nome Fantasia ou pelo
                  documento (CPF/CNPJ sem pontuação).
                </small>
              </IconTooltip>{" "}
              {state.provider.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <div className="provider-field">
              <SoulTypeahead
                id="txt-provider"
                data-testid="txt-provider"
                placeholder="Fornecedor"
                options={state.provider.options}
                onSearchChange={handleSearchProviderChange}
                notFoundOptionLabel="+ Adicionar Fornecedor"
                onNotFoundOptionSelected={handleNotFoundOptionSelected}
                onChange={handleProviderChange}
                value={provider}
                serverSide
                loading={state.provider.loading}
                disabled={readonly}
                className={errors?.provider ? "isInvalid" : undefined}
              />
              <button
                type="button"
                data-effect="solid"
                disabled={disabled}
                id="btn-edit-provider"
                data-tip="Editar Fornecedor"
                data-testid="btn-edit-provider"
                className={`btn-edit-provider  ${!provider ? "disabled" : ""}`}
                onClick={handleEditProvider}
              >
                <FaEdit />
              </button>
            </div>
            <InvalidFeedback
              condition={(errors?.provider as FieldError)?.type === "required"}
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-4 form-control">
            <span>
              N° documento{" "}
              {state.documentNumber.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <input
              {...register("documentNumber", {
                validate: {
                  required: txtValue => !!txtValue,
                  existingDocument: validateDocumentNumber,
                },
              })}
              id="documentNumber"
              data-testid="txt-documentNumber"
              placeholder="N° documento"
              type="text"
              disabled={readonly}
              className={errors.documentNumber ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "existingDocument"}
              message="Este nº de documento já existe para esse fornecedor."
            />
          </label>
        </div>
        {isProviderCnpj ? (
          <div className="form-row">
            <label className="col-12 form-control">
              <span>
                Fornecedor correspondente <small>(opcional)</small>{" "}
              </span>
              <input
                disabled={readonly}
                id="txt-correspondingProvider"
                data-testid="txt-correspondingProvider"
                placeholder="Fornecedor correspondente"
                {...register("correspondingProviderName")}
              />
            </label>
          </div>
        ) : null}
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Descrição <small>(opcional)</small>
            </span>
            <Controller
              name="description"
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };
                return (
                  <input
                    {...field}
                    disabled={readonly}
                    onChange={onChange}
                    id="txt-description"
                    placeholder="Descrição"
                    data-testid="txt-description"
                  />
                );
              }}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-3 form-control">
            <span>Valor</span>
            <InputBalance
              resetToZero
              placeholder="000,00"
              disabled={disabled}
              id="txt-value"
              data-testid="txt-value"
              value={value}
              onChange={handleValueChange}
              className={errors?.value ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={
                errors.value?.type === "required" ||
                errors.value?.type === "greaterThanZero"
              }
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-5 form-control">
            <span>Método de pagamento</span>
            <Controller
              control={control}
              name="paymentMethod"
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const selVal = event.target.value;

                  const sel = paymentMethods.find(
                    option => option.key === Number(selVal),
                  );

                  onChange(sel);
                };

                const selVal = rest.value?.key || "";

                return (
                  <select
                    {...rest}
                    id="paymentMethod"
                    data-testid="txt-paymentMethod"
                    placeholder="Método de pagamento"
                    disabled={readonly}
                    className={errors.paymentMethod ? "isInvalid" : ""}
                    onChange={handleChange}
                    value={selVal}
                  >
                    <option value="" disabled>
                      Método de pagamento
                    </option>
                    {paymentMethods.map(option => {
                      const { key } = option;
                      const optVal = option.value;

                      return (
                        <option key={key} value={key}>
                          {optVal}
                        </option>
                      );
                    })}
                  </select>
                );
              }}
            />
            <InvalidFeedback
              condition={
                (errors?.paymentMethod as FieldError)?.type === "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-4 form-control">
            <span>Status da documentação</span>
            <Controller
              control={control}
              name="documentStatus"
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const selVal = event.target.value;

                  const sel = documentStatuses.find(
                    option => option.key === Number(selVal),
                  );

                  onChange(sel);
                };

                const selVal = rest.value?.key || "";

                return (
                  <select
                    {...rest}
                    id="documentStatus"
                    data-testid="txt-documentStatus"
                    placeholder="Método de pagamento"
                    disabled={readonly}
                    className={errors.documentStatus ? "isInvalid" : ""}
                    onChange={handleChange}
                    value={selVal}
                  >
                    <option value="" disabled>
                      Status da documentação
                    </option>
                    {documentStatuses.map(option => {
                      const { key } = option;
                      const optVal = option.value;

                      return (
                        <option key={key} value={key}>
                          {optVal}
                        </option>
                      );
                    })}
                  </select>
                );
              }}
            />
            <InvalidFeedback
              condition={
                (errors?.documentStatus as FieldError)?.type === "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-3 form-control">
            <span>Dt. emissão</span>
            <InputDate
              {...register("issueDate", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                },
              })}
              id="issueDate"
              data-testid="txt-issueDate"
              placeholder="00/00/0000"
              type="text"
              disabled={readonly}
              className={errors?.issueDate ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "validDate"}
              message="Formato de data inválida"
            />
          </label>
          <label className="col-3 form-control">
            <span>Pagar em</span>
            <InputDate
              {...register("payUntil", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                },
              })}
              id="payUntil"
              data-testid="txt-payUntil"
              placeholder="00/00/0000"
              type="text"
              disabled={disabled}
              className={errors?.payUntil ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.payUntil?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.payUntil?.type === "validDate"}
              message="Formato de data inválida"
            />
          </label>
          {issueAsPaid && (
            <label className="col-3 form-control">
              <span>Dt. baixa</span>
              <InputDate
                {...register("terminationDate", {
                  validate: {
                    required: dtValue => issueAsPaid && !!dtValue,
                    validDate: dtValue =>
                      !invalidDate(dtValue || "", "dd/MM/yyyy"),
                  },
                })}
                id="terminationDate"
                data-testid="txt-terminationDate"
                placeholder="00/00/0000"
                type="text"
                disabled={disabled}
                className={errors.terminationDate ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={errors.terminationDate?.type === "required"}
                message="Este campo é obrigatório"
              />
              <InvalidFeedback
                condition={errors.terminationDate?.type === "validDate"}
                message="Formato de data inválida"
              />
            </label>
          )}
          <label className="col-12 form-control">
            <span>&nbsp;</span>
            <div>
              <Checkbox
                id="chk-issue-as-paid"
                label="Lançar como pago"
                disabled={disabled}
                {...register("issueAsPaid", {
                  onChange(event: ChangeEvent<HTMLInputElement>) {
                    if (!event.target.checked) {
                      setValue("terminationDate", null);
                    }
                  },
                })}
              />
            </div>
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Observação <small>(opcional)</small>
            </span>
            <Controller
              name="observation"
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };
                return (
                  <input
                    {...field}
                    disabled={readonly}
                    onChange={onChange}
                    id="txt-observation"
                    placeholder="Observação"
                    data-testid="txt-observation"
                  />
                );
              }}
            />
          </label>
        </div>
        {shouldShowBarcodeField && (
          <div className="form-row">
            <label className="col-8 form-control form-control-append">
              <span>Código de barras do boleto</span>
              <div>
                <input
                  {...register("barcode", {
                    validate: {
                      required: val => shouldShowBarcodeField && !!val,
                      minLength: val => (val?.length || 0) >= 44,
                      maxLength: val => (val?.length || 0) <= 48,
                      onlyDigit: val => val?.replace(/\d/g, "") === "",
                    },
                    onBlur: handleBarcodeInputBlur,
                  })}
                  id="barcode"
                  data-testid="txt-barcode"
                  placeholder="Código de barras do boleto"
                  type="text"
                  disabled={barcodeFieldDisabled}
                  className={errors?.barcode ? "isInvalid" : undefined}
                />
                <button
                  data-tip="Editar código de barras"
                  data-effect="solid"
                  type="button"
                  onClick={handleEditBarcodeButtonClick}
                >
                  <FaEdit />
                </button>
                <button
                  data-tip="Copiar código de barras"
                  data-effect="solid"
                  type="button"
                  onClick={handleCopyBarcodeButtonClick}
                  onMouseOut={handleCopyBarcodeButtonMouseOut}
                  onBlur={handleCopyBarcodeButtonMouseOut}
                >
                  <FaCopy />
                </button>
              </div>

              <InvalidFeedback
                condition={errors.barcode?.type === "onlyDigit"}
                message="O código de barras deve conter apenas números."
              />
              <InvalidFeedback
                condition={errors.barcode?.type === "required"}
                message="Campo obrigatório"
              />
              <InvalidFeedback
                condition={
                  errors.barcode?.type === "minLength" ||
                  errors.barcode?.type === "maxLength"
                }
                message="O código de barras deve conter entre 44 e 48 dígitos."
              />
            </label>
          </div>
        )}
      </ContainerSection>
      <ProviderFormModal
        bankDataRequired={false}
        useProvider={useProvider}
        currentId={providerId || ""}
        isOpen={state.providerModalOpen}
        onProviderCreated={handleProviderCreated}
        onRequestClose={handleProviderModalRequestClose}
      />
      <ClassificationAccountLinkFormModal
        isOpen={state.classificationModalOpen}
        providerEntity={state.newlyCreatedProvider}
        onRequestClose={handleCloseClassificationModal}
        useProvider={useProvider}
        required
      />
    </Card>
  );
}
