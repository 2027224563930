import { IEntity } from "../../../../core/domain/entities/entity";
import { ICompanyEntity } from "./companyEntity";
import { IPaymentAccountEntity } from "./paymentAccountEntity";
import { IPaymentAccountTransactionAttachmentEntity } from "./paymentAccountTransactionAttachmentEntity";

export interface IPaymentAccountTransactionInputEntity extends IEntity {
  companyGroupId: string;
  company: ICompanyEntity | undefined;
  paymentAccountOrigin: IPaymentAccountEntity | undefined;
  paymentAccountDestination: IPaymentAccountEntity | undefined;
  transactionDate: string;
  value: number;
  description: string;
  attachments: IPaymentAccountTransactionAttachmentEntity[];
}

export class PaymentAccountTransactionInputEntity
  implements IPaymentAccountTransactionInputEntity
{
  id = "";
  companyGroupId = "";
  company = undefined;
  paymentAccountOrigin = undefined;
  paymentAccountDestination = undefined;
  transactionDate = "";
  value = 0;
  description = "";
  active = true;
  attachments = [];

  constructor(params?: Partial<IPaymentAccountTransactionInputEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IPaymentAccountTransactionInputEntity>) {
    return new PaymentAccountTransactionInputEntity(params);
  }
}
