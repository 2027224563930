import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";

export interface IListLinkedClassificationsAccountService {
  listLinkedClassificationsAccount(
    companyGroupId: string,
    customerId: string,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>>;
}

export interface IListLinkedClassificationsAccountUseCase {
  listLinkedClassificationsAccount(
    companyGroupId: string,
    customerId: string,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>>;
}

export class ListLinkedClassificationsAccountUseCase
  implements IListLinkedClassificationsAccountUseCase
{
  constructor(
    private listClassificationsAccountService: IListLinkedClassificationsAccountService,
  ) {}

  listLinkedClassificationsAccount(
    companyGroupId: string,
    customerId: string,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>> {
    return this.listClassificationsAccountService.listLinkedClassificationsAccount(
      companyGroupId,
      customerId,
    );
  }
}
