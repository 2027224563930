export enum EPaymentRequestDestination {
  Financial = 1,
  PersonnelDepartment = 2,
}

export const paymentRequestDestinationLang = {
  [EPaymentRequestDestination.Financial]: "Financeiro",
  [EPaymentRequestDestination.PersonnelDepartment]: "Dep. Pessoal",
};

export enum EPaymentRequestDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export enum EPaymentRequestStatus {
  NotRequested = 0,
  Requested = 1,
  Canceled = 2,
  Approved = 3,
  Rejected = 4,
  Paid = 5,
}

export enum EPaymentRequestPaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
  Pix = 7,
}

/**
 * O formulário possui os seguintes modos:
 * - `Create`: modo de adição, todos os campos ficam disponíveis para o usuário.
 * - `Duplicate`: modo de duplicação, que se comporta como a criação. Mas o *ID*
 * da solicitação de pagamento deve ser passado na requisição.
 * - `Edit`: modo de edição, todos os campos ficam disponíveis para o usuário.
 * - `Readonly`: modo somente leitura, todos os campos ficam bloqueados.
 * - `EditAttachments`: modo de edição de anexos, todos os campos bloqueados
 * exceto a seção de anexos.
 */
export enum EFormMode {
  Create,
  Duplicate,
  Edit,
  Readonly,
  EditAttachments,
}
