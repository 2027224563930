import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .no-padding {
    padding: 0;
  }

  .actives-only {
    gap: 0.5rem;
    display: flex;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
