import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableRowClickEventParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import {
  MutableRefObject,
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import {
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
  SimpleTableColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EAccountReceivableStatus,
  EInvoiceStatus,
  EReturnStatus,
  IAccountReceivableParcelListItemEntity,
} from "../../domain/entities/accountReceivableListItemEntity";
import {
  GenerateInvoiceIListItemEntity,
  IGenerateInvoiceIListItemEntity,
} from "../../domain/entities/generateInvoiceIListItemEntity";
import { MakeAccountsReceivable } from "../../main/makeAccountsReceivable";
import { AccountsReceivableReturnConfirm } from "../components/AccountsReceivableReturnConfirm";
import { useAccountsReceivableGrid } from "./useAccountsReceivableGrid";
import { InvoiceDeniedReasonContent } from "../components/AccountsReceivablePage/styles";
import { CancelDialogContent } from "../../../../../core/presentation/components/CancelDialogContent";

export enum EReturnFormModalType {
  Partial,
  Total,
}

interface IFetchDataProps {
  pfsEventEntity: IPFSEventEntity;
  uiSelectedColumns: IAdvTableColumn[];
  orderedColumnNames: string[];
}

interface IAccountsReceivablePageProviderState {
  selectedColumns: SimpleTableColumn[];
  orderedColNames: string[];
  pfsEvent: IPFSEventEntity;
  filtered: boolean;
  selection: IAccountReceivableParcelListItemEntity[] | undefined;
  contextMenuData: IAccountReceivableParcelListItemEntity | undefined;
  isFetching: boolean;
  data: IResponseEntity<IAccountReceivableParcelListItemEntity[]> | undefined;
  selectedPanorama: IPanoramaEntity | undefined;
  accountReceivableReturnFormModalOpen: boolean;
  returnFormModalType: EReturnFormModalType;
  exportSheetFealqFormModalOpen: boolean;
  assessmentModalOpen: boolean;
  attachmentModalOpen: boolean;
  terminateModalOpen: boolean;
  bulkTerminateModalOpen: boolean;
  generateFeeModalOpen: boolean;
  generateRemittancesModalOpen: boolean;
  feeModalOpen: boolean;
  generateInvoiceIListItems: IGenerateInvoiceIListItemEntity[];
}

interface IAccountsReceivablePageContextProps {
  useAccountsReceivable: MakeAccountsReceivable;
  state: IAccountsReceivablePageProviderState;
  columns: SimpleTableColumn[];
  ref: MutableRefObject<HTMLElement | null>;
  tableRef: RefObject<IAdvTableHandle>;
  menuRef: MutableRefObject<ContextMenu | null>;
  handleColumnSelect(column: IAdvTableColumn): void;
  handleColumnRemove(column: IAdvTableColumn): void;
  handleGetList(_pfsEvent: IPFSEventEntity): void;
  handleSelectionChange(
    dataTableSelectionChangeParams: DataTableSelectionChangeParams,
  ): void;
  handlePanoramaChange(
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate?: boolean,
  ): void;
  rowClassName(
    rowData: IAccountReceivableParcelListItemEntity,
  ): Record<string, boolean>;
  handleRowDoubleClick(event: DataTableRowClickEventParams): void;
  handleColReorder(colReorderParams: DataTableColReorderParams): void;
  handleClearButtonClick(): void;
  handleRowClick(
    e: IDataTableRowClickEventParams<IAccountReceivableParcelListItemEntity>,
  ): void;
  openAccountReceivableInNewWindow(accountReceivableId: string): void;
  handleRemoveTerminationItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): Promise<void>;
  handleCancelItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): Promise<void>;
  handleInvoiceDenialReasonEmitterClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): Promise<void>;
  handleDuplicateItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleReturnItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleAccountReceivableReturnFormModalClose(): void;
  handleUndoReturnItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleAssessmentsItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleAssessmentModalClose(): void;
  handleAttachmentItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleAttachmentModalClose(): void;
  handleTerminateItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  handleTerminateModalClose(): void;
  openBulkTerminateModal(): void;
  closeBulkTerminateModal(): void;
  handleBulkTerminateModalClose(): void;
  clearSelection(): void;
  reload(): void;
  closeGenerateFeeModal(): void;
  closeGenerateRemittancesModal(): void;
  handleFeeItemClick(
    accountReceivable: IAccountReceivableParcelListItemEntity,
  ): void;
  closeAccountReceivableFeeFormModal(): void;
  accountReceivableParcelListToGenerateInvoiceList(
    receivableParceList: IAccountReceivableParcelListItemEntity[],
  ): IGenerateInvoiceIListItemEntity[];
  openGenerateInvoiceModal(
    generateInvoiceIListItems: IGenerateInvoiceIListItemEntity[],
  ): void;
}

const AccountsReceivablePageContext = createContext(
  {} as IAccountsReceivablePageContextProps,
);

interface IAccountsReceivablePageProviderProps {
  children: ReactNode;
  useAccountsReceivable: MakeAccountsReceivable;
}

export function AccountsReceivablePageProvider({
  children,
  useAccountsReceivable,
}: IAccountsReceivablePageProviderProps) {
  const navigate = useNavigate();

  const {
    listAccountsReceivable,
    removeParcelTermination,
    cancelAccountReceivableContract,
    storeAccountReceivableIdToDuplicate,
    undoReturn,
    removeAccountTermination,
    accountReceivableHasTaxes,
  } = useAccountsReceivable;

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const currentCompanyGroupId = useMemo(
    () => currentCompanyGroup.id,
    [currentCompanyGroup.id],
  );

  const { columns } = useAccountsReceivableGrid({ useAccountsReceivable });

  const [state, setState] = useState<IAccountsReceivablePageProviderState>(
    () => {
      return {
        // comecamos com uma copia da lista de colunas pois alguns estados estão
        // sendo salvos na propria abstracao da coluna de forma mutável e se nao
        // fizermos isso ppodemos causar algumas inconsistencias
        // REVIEW
        selectedColumns: columns.map(col => ({ ...col })),
        orderedColNames: columns.map(col => col.field),
        pfsEvent: new PFSEventEntity({ rows: 50 }),
        filtered: false,
        selection: undefined,
        // Armazena os dados da linha ao exibir o menu de contexto
        contextMenuData: undefined,
        isFetching: false,
        data: undefined,
        selectedPanorama: undefined,
        accountReceivableReturnFormModalOpen: false,
        returnFormModalType: EReturnFormModalType.Partial,
        exportSheetFealqFormModalOpen: false,
        assessmentModalOpen: false,
        attachmentModalOpen: false,
        terminateModalOpen: false,
        bulkTerminateModalOpen: false,
        generateFeeModalOpen: false,
        generateRemittancesModalOpen: false,
        feeModalOpen: false,
        generateInvoiceIListItems: [],
      };
    },
  );

  const mountedRef = useIsMounted();
  const tableRef = useRef<IAdvTableHandle>(null);
  const { advGeneratePayload } = useTables();

  /**
   * Responsavel por fazer o fetch que obtem a lista de dados p/ exibir na tela
   */
  const fetchData = useCallback(
    async ({
      orderedColumnNames,
      pfsEventEntity,
      uiSelectedColumns,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!currentCompanyGroupId) {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
            isFetching: false,
          };
        });

        return;
      }

      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => {
        return {
          ...prevState,
          filtered: isFiltered,
          isFetching: true,
        };
      });

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listAccountsReceivable(
          currentCompanyGroupId,
          payload,
        );

        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: response,
          };
        });
      } catch {
        if (!mountedRef.current) {
          return;
        }

        setState(prevState => {
          return {
            ...prevState,
            data: new ResponseEntity({ data: [] }),
          };
        });
      } finally {
        if (mountedRef.current) {
          setState(prevState => {
            return {
              ...prevState,
              isFetching: false,
            };
          });
        }
      }
    },
    [
      advGeneratePayload,
      currentCompanyGroupId,
      listAccountsReceivable,
      mountedRef,
    ],
  );

  /**
   * Lida com o evento de selecao de uma coluna, ou seja, quando
   * o usuario add uma coluna que nao estava na tabela pela ui
   */
  const handleColumnSelect = useCallback(
    (column: IAdvTableColumn) => {
      const newColumns = [column, ...state.selectedColumns];
      const newOrderedColNames = newColumns.map(col => col.field);

      setState(prevState => {
        return {
          ...prevState,
          selectedColumns: newColumns,
          orderedColNames: newOrderedColNames,
        };
      });

      // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
      // na sequencia do update do estado nao tem efeito, nao queremos usar um
      // side-effect selectedColumns pois outros eventos atualizam esse estado e
      // nao queremos que que o applyPanorama seja executado em todos eles
      setTimeout(() => {
        tableRef.current?.applyPanorama();

        fetchData({
          orderedColumnNames: newOrderedColNames,
          pfsEventEntity: state.pfsEvent,
          uiSelectedColumns: newColumns,
        });
      });
    },
    [fetchData, state.pfsEvent, state.selectedColumns],
  );

  /**
   * Lida com o evento de remocao de uma coluna, ou seja,
   * quando o usario remove uma coluna da tabela pela ui
   */
  const handleColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setState(prevState => {
      return {
        ...prevState,
        selectedColumns: prevState.selectedColumns.filter(
          selCol => selCol.field !== field,
        ),
      };
    });
  };

  /**
   * Lida com o evento de reordenacao da sequencia
   * em que as colunas aparecem na ui
   */
  const handleColReorder = useCallback(
    (colReorderParams: DataTableColReorderParams) => {
      const cols = colReorderParams.columns as unknown as Array<{
        props: { field: string };
      }>;

      const newOrderedColNames = cols
        .map(col => col.props.field)
        .filter((col: string): col is string => !!col);

      // por ora precisamos incluir as hidden tbm, pois sem elas após
      // reordenar as colunas na UI elas nao sao mais enviadas na request
      const hidden = columns
        .filter(col => {
          const c = col as ISimpleHiddenColumn;
          return c.hidden;
        })
        .map(c => c.field);

      setState(prevState => {
        return {
          ...prevState,
          orderedColNames: [...newOrderedColNames, ...hidden],
        };
      });
    },
    [columns],
  );

  /**
   * Lida com o evento de troca de estado interno da AdvTable, equivalente
   * a um on change, usado para fazer a request que obtem os dados que serao
   * exibidos na tabela
   */
  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setState(prevState => {
        return {
          ...prevState,
          pfsEvent: _pfsEvent,
        };
      });

      fetchData({
        orderedColumnNames: state.orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: state.selectedColumns,
      });
    },
    [fetchData, state.orderedColNames, state.selectedColumns],
  );

  /**
   * Lida com o evento de clique no botão "Limpar filtros"
   */
  const handleClearButtonClick = useCallback(() => {
    tableRef.current?.resetFilters();
  }, []);

  const menuRef = useRef<ContextMenu>(null);
  const ref = useRef(null);

  /**
   * Lida com o evento de selecao de linhas na tabela, ou seja quando
   * o usuario marca ou desmarca o checbox das linhas da tabela
   */
  const handleSelectionChange = ({
    value,
    type,
  }: DataTableSelectionChangeParams) => {
    if (type === "row") {
      return;
    }

    setState(prevState => {
      return {
        ...prevState,
        selection: value,
      };
    });
  };

  /**
   * Lida com o evento de troca do panorama ativo no dropdown de panoramas da
   * ui, sempre que o panorama e trocado a table é reconfigurada para refletir
   * as configuracoes do panorama ativo e uma nova request é feita
   */
  const handlePanoramaChange = useCallback(
    (_selectedPanorama: IPanoramaEntity, shouldUpdate = true) => {
      const { systemDefault, panoramaDefinition } = _selectedPanorama;

      // este map é necessario pois a prop filterData da coluna é usada como um
      // state mutável (podemos rever isso e utilizar uma abordagem imutável).
      // Mas neste caso nao queremos que ele modifique a coluna original salva no
      // panorama, entao criamos uma nova lista com clones das colunas do panorama
      // para que quando o filterData seja manipulado, aconteca apenas em memoria
      // e nao no que está salvo no panorama
      // REVIEW
      let selectedPanoramaCols = panoramaDefinition.selectedColumns.map(
        selCol => ({
          ...selCol,
        }),
      );

      let pfsEventEntity = {
        ...panoramaDefinition.pfsEventEntity,
        rows: state.pfsEvent.rows,
      };

      if (systemDefault) {
        selectedPanoramaCols = columns.map(col => ({
          ...col,
        }));
        pfsEventEntity = new PFSEventEntity({ rows: state.pfsEvent.rows });
      }

      setState(prevState => {
        return {
          ...prevState,
          pfsEvent: {
            ...pfsEventEntity,
            rows: prevState.pfsEvent.rows,
          },
        };
      });

      const newOrderedColNames = selectedPanoramaCols.map(selPanCol => {
        return selPanCol.field;
      });

      setState(prevState => {
        return {
          ...prevState,
          orderedColNames: newOrderedColNames,
          selectedColumns: selectedPanoramaCols,
          selectedPanorama: _selectedPanorama,
        };
      });
      if (systemDefault) {
        tableRef.current?.resetFilters();
        return;
      }
      if (!shouldUpdate) {
        return;
      }

      // refaz a request para garantir que os resultados que o usuario
      // verá estarão de acordo com os filtros do panorama
      fetchData({
        orderedColumnNames: newOrderedColNames,
        pfsEventEntity,
        uiSelectedColumns: selectedPanoramaCols,
      });
    },
    [columns, fetchData, state.pfsEvent.rows],
  );

  /**
   * Lida com eventos de click na linha, exibindo o menu de contexto.
   */
  const handleRowClick = useCallback(
    (
      event: IDataTableRowClickEventParams<IAccountReceivableParcelListItemEntity>,
    ) => {
      event.originalEvent.stopPropagation();

      const contextMenuRowData = event.data;

      setState(prevState => {
        return {
          ...prevState,
          contextMenuData: contextMenuRowData,
        };
      });

      menuRef?.current?.show(event.originalEvent);
    },
    [],
  );

  /**
   * Lida com a decisão de qual className atribuir ao tr da tabela
   * para colorir cada linha de acordo com o status da conta
   */
  const rowClassName = useCallback(
    (rowData: IAccountReceivableParcelListItemEntity) => {
      const { status } = rowData;

      return {
        "row-canceled": status === EAccountReceivableStatus.Canceled,
        "row-open": status === EAccountReceivableStatus.Open,
        "row-paid": status === EAccountReceivableStatus.Paid,
        "row-inconsistent": status === EAccountReceivableStatus.Inconsistent,
      };
    },
    [],
  );

  /** Abre uma conta a receber em uma nova janela */
  const openAccountReceivableInNewWindow = (accountReceivableId: string) => {
    window.open(`/accountsReceivable/${accountReceivableId}`);
  };

  /** Lida com o evento de duplo clique na linha da tabela */
  const handleRowDoubleClick = useCallback(
    (event: DataTableRowClickEventParams) => {
      const accountReceivable =
        event.data as IAccountReceivableParcelListItemEntity;

      const accountId = accountReceivable.accountReceivableId;

      openAccountReceivableInNewWindow(accountId);
    },
    [],
  );

  /** Recarrega os dados da tabela com os mesmos filtros, pagina e ordenacao */
  const reload = useCallback(() => {
    fetchData({
      orderedColumnNames: state.orderedColNames,
      pfsEventEntity: state.pfsEvent,
      uiSelectedColumns: state.selectedColumns,
    });
  }, [fetchData, state.orderedColNames, state.pfsEvent, state.selectedColumns]);

  const dialog = useSoulDialog();

  const callRemoveTerminationSingle = useCallback(
    async (accountReceivableParcel: IAccountReceivableParcelListItemEntity) => {
      const parcelId = accountReceivableParcel.id;
      await removeParcelTermination(currentCompanyGroupId, parcelId);

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Baixa retirada com sucesso!",
      });

      reload();
    },
    [currentCompanyGroupId, dialog, reload, removeParcelTermination],
  );

  /** Remove a baixa de uma unica parcela */
  const removeTerminationSingle = useCallback(
    async (accountReceivableParcel: IAccountReceivableParcelListItemEntity) => {
      const { accountReceivableId } = accountReceivableParcel;

      dialog.fire({
        title: "Aguarde",
        text: "Processando",
      });

      dialog.showLoading();

      const hasTaxes = await accountReceivableHasTaxes(accountReceivableId);

      if (hasTaxes) {
        await dialog.fire({
          icon: "question",
          title: "Atenção!",
          html: (
            <>
              Detectamos a existência de uma ou mais taxas que foram geradas
              automaticamente na baixa dessa(s) parcela(s). Deseja continuar e
              excluir essa(s) taxa(s)?
            </>
          ),
          showCancelButton: true,
          cancelButtonText: "Não",
          async preConfirm() {
            dialog.showLoading();
            await callRemoveTerminationSingle(accountReceivableParcel);
          },
        });

        return;
      }

      callRemoveTerminationSingle(accountReceivableParcel);
    },
    [accountReceivableHasTaxes, callRemoveTerminationSingle, dialog],
  );

  /**
   * Chama o servico que cuminca a api para remover
   * a baixa de todas as parcelas da conta
   */
  const callRemoveTerminationAll = useCallback(
    async (accountReceivableId: string) => {
      await removeAccountTermination(
        currentCompanyGroupId,
        accountReceivableId,
      );

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Baixa retirada com sucesso!",
      });

      reload();
    },
    [currentCompanyGroupId, dialog, reload, removeAccountTermination],
  );

  /**
   * Remove a baixa de todas as parcelas da conta
   */
  const removeTerminationAll = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      const { accountReceivableId } = accountReceivable;

      dialog.fire({
        title: "Aguarde",
        text: "Processando",
      });

      dialog.showLoading();

      const hasTaxes = await accountReceivableHasTaxes(accountReceivableId);

      if (hasTaxes) {
        await dialog.fire({
          icon: "question",
          title: "Atenção!",
          html: (
            <>
              Detectamos a existência de uma ou mais taxas que foram geradas
              automaticamente na baixa dessa(s) parcela(s). Deseja continuar e
              excluir essa(s) taxa(s)?
            </>
          ),
          showCancelButton: true,
          cancelButtonText: "Não",
          async preConfirm() {
            dialog.showLoading();
            await callRemoveTerminationAll(accountReceivableId);
          },
        });

        return;
      }

      callRemoveTerminationAll(accountReceivableId);
    },
    [accountReceivableHasTaxes, callRemoveTerminationAll, dialog],
  );

  /** Lida com o evento de clique no item "Baixar" do menu de contexto */
  const handleRemoveTerminationItemClick = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      if (
        accountReceivable.status === EAccountReceivableStatus.Canceled ||
        accountReceivable.status === EAccountReceivableStatus.Open
      ) {
        return;
      }

      await dialog.fire({
        icon: "warning",
        html: (
          <>
            Você pode escolher entre retirar a baixa apenas desta parcela ou de
            todas àquelas baixadas neste mesmo lançamento. Quais parcelas devem
            ter a baixa retirada?
          </>
        ),
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: "Cancelar",
        denyButtonText: "Somente esta",
        customClass: {
          cancelButton: "swal2-cancel form-button red-bkg no-margin",
          denyButton: "swal2-deny form-button green-bkg",
          confirmButton: "swal2-confirm form-button green-bkg no-margin",
        },
        confirmButtonText: "Todas",

        // preDeny eh chamado quando o usuario clicar em "Somente esta"
        async preDeny() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });

          try {
            await removeTerminationSingle(accountReceivable);
          } catch {
            dialog.update({
              allowEscapeKey: true,
              allowOutsideClick: true,
            });
          }

          dialog.showLoading();
        },

        // preConfirm eh chamado quando o usuario clicar em "Todas"
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });

          try {
            await removeTerminationAll(accountReceivable);
          } catch {
            dialog.update({
              allowEscapeKey: true,
              allowOutsideClick: true,
            });
          }

          dialog.showLoading();
        },
      });
    },
    [dialog, removeTerminationAll, removeTerminationSingle],
  );

  /** Lida com o evento de submit do form na dialog de cancelamento de uma conta */
  const handleCancelDialogContentSubmit = useCallback(
    async (
      reason: string,
      accountReceivable: IAccountReceivableParcelListItemEntity,
    ) => {
      const { accountReceivableId } = accountReceivable;

      await cancelAccountReceivableContract(accountReceivableId, reason);
    },
    [cancelAccountReceivableContract],
  );

  /** Lida com o evento de clique no item "Cancelar" do menu de contexto */
  const handleCancelItemClick = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      if (accountReceivable.status === EAccountReceivableStatus.Paid) {
        return;
      }

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        html: (
          <CancelDialogContent
            onSubmit={reason =>
              handleCancelDialogContentSubmit(reason, accountReceivable)
            }
          />
        ),
        showCancelButton: false,
        showDenyButton: false,
        showConfirmButton: false,
        showCloseButton: false,
      });

      if (result.dismiss) {
        return;
      }

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento cancelado com sucesso!",
      });

      reload();
    },
    [dialog, handleCancelDialogContentSubmit, reload],
  );

  /** Lida com o evento de clique no item "Cancelar" do menu de contexto */
  const handleInvoiceDenialReasonEmitterClick = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      const { invoiceDeniedReason } = accountReceivable;

      await dialog.fire({
        icon: "error",
        html: (
          <InvoiceDeniedReasonContent>
            <p>Esta solicitação foi reprovada pois:</p>
            <div>
              <p>{invoiceDeniedReason}</p>
            </div>
          </InvoiceDeniedReasonContent>
        ),
        showCancelButton: false,
        showDenyButton: false,
        showConfirmButton: true,
        showCloseButton: false,
      });
    },
    [dialog],
  );

  /** Lida com o evento de clique no item "Duplicar" do menu de contexto */
  const handleDuplicateItemClick = useCallback(
    (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      const { accountReceivableId } = accountReceivable;

      storeAccountReceivableIdToDuplicate(accountReceivableId);
      openAccountReceivableInNewWindow("0");
    },
    [storeAccountReceivableIdToDuplicate],
  );

  /** Abre a modal de devolucao de contas a receber */
  const openAccountReceivableReturnFormModal = (
    returnFormModalType: EReturnFormModalType,
  ) => {
    setState(prevState => {
      return {
        ...prevState,
        accountReceivableReturnFormModalOpen: true,
        returnFormModalType,
      };
    });
  };

  /** Fecha a modal de devolucao de contas a receber */
  const closeAccountReceivableReturnFormModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        accountReceivableReturnFormModalOpen: false,
      };
    });
  };

  /**
   * Lida com o evento de clique no botao de devolucao parcial.
   * Responsavel por apresentar a modal de devolucao parcial
   */
  const handlePartialButtonClick = useCallback(() => {
    openAccountReceivableReturnFormModal(EReturnFormModalType.Partial);

    dialog.close({
      isConfirmed: true,
      isDenied: false,
      isDismissed: false,
    });
  }, [dialog]);

  /**
   * Lida com o evento de clique no botao de devolucao total.
   * Responsavel por apresentar a modal de devolucao total
   */
  const handleTotalButtonClick = useCallback(() => {
    openAccountReceivableReturnFormModal(EReturnFormModalType.Total);

    dialog.close({
      isConfirmed: true,
      isDenied: false,
      isDismissed: false,
    });
  }, [dialog]);

  const handleBackButtonClick = useCallback(() => {
    dialog.close({
      isConfirmed: false,
      isDenied: false,
      isDismissed: true,
      dismiss: Swal.DismissReason.cancel,
    });
  }, [dialog]);

  /** Lida com o evento de clique no botão "Devolução" do menu de contexto */
  const handleReturnItemClick = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      if (
        accountReceivable.status === EAccountReceivableStatus.Canceled ||
        (accountReceivable.status === EAccountReceivableStatus.Open &&
          !accountReceivable.anyParcelPaid) ||
        accountReceivable.returnStatus !== EReturnStatus.NotReturned
      ) {
        return;
      }

      await dialog.fire({
        icon: "question",
        title: "Como você quer continuar?",
        showConfirmButton: false,
        html: (
          <AccountsReceivableReturnConfirm
            onPartialButtonClick={handlePartialButtonClick}
            onTotalButtonClick={handleTotalButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        ),
      });
    },
    [
      dialog,
      handleBackButtonClick,
      handlePartialButtonClick,
      handleTotalButtonClick,
    ],
  );

  /** Lida com o evento de fechamento disparado pela modal de devolucao */
  const handleAccountReceivableReturnFormModalClose = useCallback(() => {
    closeAccountReceivableReturnFormModal();
  }, []);

  /** Lida com o evento de clique no botão "Desfazer devolução" do menu de contexto */
  const handleUndoReturnItemClick = useCallback(
    async (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      if (
        accountReceivable.status === EAccountReceivableStatus.Canceled ||
        accountReceivable.status === EAccountReceivableStatus.Open
      ) {
        return;
      }

      await dialog.fire({
        icon: "question",
        title: "Atenção!",
        html: (
          <>
            <div>
              Todos os lançamentos de devolução para essa conta serão{" "}
              <b>apagados</b> e o status de devolução voltará para &quot;Não
              devolvido.&quot;
            </div>
            <br />
            <div>Deseja prosseguir?</div>
          </>
        ),
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            const { accountReceivableId } = accountReceivable;

            await undoReturn(currentCompanyGroupId, accountReceivableId);

            await dialog.fire({
              icon: "success",
              title: "Feito!",
              text: "Devolução desfeita com sucesso!",
            });

            reload();
          } catch {
            dialog.update({
              allowEscapeKey: true,
              allowOutsideClick: true,
            });

            dialog.hideLoading();
          }
        },
      });
    },
    [currentCompanyGroupId, dialog, reload, undoReturn],
  );

  /** Abre a modal de rateios */
  const openAssessmentModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        assessmentModalOpen: true,
      };
    });
  }, []);

  /** Lida com o evento de clique no botão "Rateios" do menu de contexto */
  const handleAssessmentsItemClick = useCallback(
    (accountReceivable: IAccountReceivableParcelListItemEntity) => {
      if (accountReceivable.status === EAccountReceivableStatus.Canceled) {
        return;
      }

      openAssessmentModal();
    },
    [openAssessmentModal],
  );

  /** Fecha a modal de rateios */
  const closeAssessmentModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        assessmentModalOpen: false,
      };
    });
  }, []);

  /** Lida com o evento de fechamento disparado pela modal de rateios */
  const handleAssessmentModalClose = useCallback(() => {
    closeAssessmentModal();
  }, [closeAssessmentModal]);

  /** Abre a modal de anexos */
  const openAttachmentModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        attachmentModalOpen: true,
      };
    });
  }, []);

  /** Lida com o evento de clique no botão "Anexos" do menu de contexto */
  const handleAttachmentItemClick = useCallback(() => {
    openAttachmentModal();
  }, [openAttachmentModal]);

  /** Fecha a modal de anexos */
  const closeAttachmentModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        attachmentModalOpen: false,
      };
    });
  }, []);

  /** Lida com o evento de fechamento disparado pela modal de anexos */
  const handleAttachmentModalClose = useCallback(() => {
    closeAttachmentModal();
  }, [closeAttachmentModal]);

  /** Abre a modal de baixa */
  const openTerminateModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        terminateModalOpen: true,
      };
    });
  };

  /** Lida com o evento de clique no botão "Baixar" do menu de contexto */
  const handleTerminateItemClick = useCallback(() => {
    openTerminateModal();
  }, []);

  /** Fecha a modal de baixa */
  const closeTerminateModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        terminateModalOpen: false,
      };
    });
  };

  /** Lida com o evento de fechamento disparado pela modal de baixa */
  const handleTerminateModalClose = useCallback(() => {
    closeTerminateModal();
  }, []);

  /** Abre a modal de baixa em lote */
  const openBulkTerminateModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        bulkTerminateModalOpen: true,
      };
    });
  };

  /** Fecha a modal de baixa em lote */
  const closeBulkTerminateModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        bulkTerminateModalOpen: false,
      };
    });
  };

  /** Lida com o evento de fechamento disparado pela modal de baixa em lote */
  const handleBulkTerminateModalClose = useCallback(() => {
    closeBulkTerminateModal();
  }, []);

  /** Limpa a selecao atual, ou seja, desmarca todos os checkboxes */
  const clearSelection = () => {
    setState(prevState => {
      return {
        ...prevState,
        selection: undefined,
      };
    });
  };

  /** Fecha o modal de geração de impostos. */
  const closeGenerateFeeModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        generateFeeModalOpen: false,
      };
    });
  }, []);

  /** Fecha o modal de geração de remessas. */
  const closeGenerateRemittancesModal = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        generateRemittancesModalOpen: false,
      };
    });
  }, []);

  const openFeeModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      feeModalOpen: true,
    }));
  }, []);

  const closeAccountReceivableFeeFormModal = () => {
    setState(prevState => ({
      ...prevState,
      feeModalOpen: false,
    }));
  };

  const handleFeeItemClick = useCallback(() => {
    openFeeModal();
  }, [openFeeModal]);

  const accountReceivableParcelListToGenerateInvoiceList = useCallback(
    (receivableParceList: IAccountReceivableParcelListItemEntity[]) => {
      const validSelection = receivableParceList.filter(account => {
        return (
          account.invoiceStatus === EInvoiceStatus.NotGenerated ||
          account.invoiceStatus === EInvoiceStatus.Denied
        );
      });

      const generateInvoiceIListItems =
        validSelection.map<IGenerateInvoiceIListItemEntity>(
          (account, rowIndex) => {
            let serviceFramework = null;

            // se tem dados de enquadramento de servico
            // pupula o campo com os dados existentes
            if (
              account.municipalActivityClassificationId &&
              account.municipalActivityClassifictionCode
            ) {
              serviceFramework = {
                label: account.municipalActivityClassifictionCode,
                rawValue: account.municipalActivityClassificationId,
              };
            }

            // se tem dados de cane popula o campo com os dados existentes
            let cnae = null;

            if (
              account.cnaeClassificationId &&
              account.cnaeClassificationCode
            ) {
              cnae = {
                label: account.cnaeClassificationCode.replace(/\D/g, ""),
                rawValue: account.cnaeClassificationId,
              };
            }

            let serviceSupplyCity = null;

            if (
              account?.serviceSupplyCityId &&
              account?.serviceSupplyCityName
            ) {
              serviceSupplyCity = {
                label: account.serviceSupplyCityName,
                rawValue: account.serviceSupplyCityId,
              };
            }

            let serviceSupplyState = null;

            if (
              account?.serviceSupplyStateId &&
              account?.serviceSupplyStateName
            ) {
              serviceSupplyState = {
                label: account.serviceSupplyStateName,
                rawValue: account.serviceSupplyStateId,
              };
            }

            return new GenerateInvoiceIListItemEntity({
              rowIndex,
              id: account.accountReceivableId,
              companyName: account.companyName,
              documentNumber: account.documentNumber,
              customerCorporationName: account.customerName,
              totalValue: account.value,
              serviceFramework,
              cnae,
              issAliquot: account.issAliquot || 0,
              invoiceDescription: account.invoiceServiceDescription || "",
              irAliquot: account?.irAliquot || 0,
              pisAliquot: account?.pisAliquot || 0,
              cofinsAliquot: account?.cofinsAliquot || 0,
              csllAliquot: account?.csllAliquot || 0,
              serviceSupplyCity,
              serviceSupplyState,
            });
          },
        );

      return generateInvoiceIListItems;
    },
    [],
  );

  const openGenerateInvoiceModal = useCallback(
    (generateInvoiceIListItems: IGenerateInvoiceIListItemEntity[]) => {
      setState(prevState => ({
        ...prevState,
        generateInvoiceIListItems,
      }));

      navigate(`${SoulRoutes.ACCOUNTS_RECEIVABLE.path}#generate-invoice`);
    },
    [navigate],
  );

  // NOTE este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // das colunas internamente de forma mutável, para isso sempre que alteramos
  // states que influenciam o sequenciamento das colunas, precisamos chamar
  // imperativamente o applyPanorama (que por sua vez executa o resetColumnOrder
  // do primereact). Desta forma conseguimos manter sincronizados o estado
  // interno do prime e o render na UI
  useEffect(() => {
    tableRef.current?.applyPanorama();
  }, [state.selectedPanorama]);

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      reload();
    }
  }, [currentCompanyGroupId, reload]);

  const memoizedValue = useMemo(() => {
    return {
      state,
      columns,
      tableRef,
      handleColumnSelect,
      handleColumnRemove,
      handleGetList,
      handleSelectionChange,
      ref,
      menuRef,
      handlePanoramaChange,
      rowClassName,
      handleRowDoubleClick,
      handleColReorder,
      useAccountsReceivable,
      handleClearButtonClick,
      handleRowClick,
      openAccountReceivableInNewWindow,
      handleRemoveTerminationItemClick,
      handleCancelItemClick,
      handleInvoiceDenialReasonEmitterClick,
      handleDuplicateItemClick,
      handleReturnItemClick,
      handleAccountReceivableReturnFormModalClose,
      handleUndoReturnItemClick,
      reload,
      handleAssessmentModalClose,
      handleAssessmentsItemClick,
      handleAttachmentItemClick,
      handleAttachmentModalClose,
      handleTerminateItemClick,
      handleTerminateModalClose,
      openBulkTerminateModal,
      closeBulkTerminateModal,
      handleBulkTerminateModalClose,
      clearSelection,
      closeGenerateFeeModal,
      closeGenerateRemittancesModal,
      handleFeeItemClick,
      closeAccountReceivableFeeFormModal,
      accountReceivableParcelListToGenerateInvoiceList,
      openGenerateInvoiceModal,
    };
  }, [
    state,
    columns,
    handleColumnSelect,
    handleGetList,
    handlePanoramaChange,
    rowClassName,
    handleRowDoubleClick,
    handleColReorder,
    useAccountsReceivable,
    handleClearButtonClick,
    handleRowClick,
    handleRemoveTerminationItemClick,
    handleCancelItemClick,
    handleInvoiceDenialReasonEmitterClick,
    handleDuplicateItemClick,
    handleReturnItemClick,
    handleAccountReceivableReturnFormModalClose,
    handleUndoReturnItemClick,
    reload,
    handleAssessmentModalClose,
    handleAssessmentsItemClick,
    handleAttachmentItemClick,
    handleAttachmentModalClose,
    handleTerminateItemClick,
    handleTerminateModalClose,
    handleBulkTerminateModalClose,
    closeGenerateFeeModal,
    closeGenerateRemittancesModal,
    handleFeeItemClick,
    accountReceivableParcelListToGenerateInvoiceList,
    openGenerateInvoiceModal,
  ]);

  return (
    <AccountsReceivablePageContext.Provider value={memoizedValue}>
      {children}
    </AccountsReceivablePageContext.Provider>
  );
}

export function useAccountsReceivablePage() {
  return useContext(AccountsReceivablePageContext);
}
