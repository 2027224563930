import { StatusCodes } from "http-status-codes";
import { FaTimesCircle } from "react-icons/fa";
import { SweetAlertOptions } from "sweetalert2";
import { ReactNode } from "react";
import { IErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IApiErrorResponse } from "../../data/services/apiService";
import { useSoulDialog } from "./useSoulDialog";

export function useImportFileErrorHandlers() {
  const dialog = useSoulDialog();

  /**
   * Monta um objeto de configuração para o caso de erro de timeout do servidor.
   * @returns Um objeto de configuração do diálogo.
   */
  function badGatewayOptions(): SweetAlertOptions {
    return {
      icon: "warning",
      title: "Importação em andamento",
      html: (
        <div>
          <p>
            O processo de importação <strong>ainda está acontecendo</strong>.
            <br />
            Por favor, <strong>aguarde</strong> um momento ou tente novamente
            mais tarde.
          </p>
        </div>
      ),
    };
  }

  /**
   * Monta um objeto de configuração para o caso de erro de usuário.
   * @param details - Lista de mensagens de erro
   * @returns Um objeto de configuração do diálogo.
   */
  function badRequestOptions(
    error: IErrorResponseEntity,
    customMessage?: ReactNode,
  ): SweetAlertOptions {
    const { Message, Details } = error;

    const isErrorString = typeof error === "string";

    return {
      icon: "error",
      title: "Opa!",
      width: Details?.length ? "48rem" : undefined,
      html: (
        <div className="import-file-error-dialog">
          {Details?.length ? (
            <>
              <p className="import-file-error-message">
                {customMessage || (
                  <>
                    Essa planilha <strong>não pôde</strong> ser importada.
                    Corrija os problemas e tente novamente.
                  </>
                )}
              </p>
              <div className="import-file-error-container">
                {Details.map((detail, index) => {
                  const detailKey = `detail#${index}`;
                  return (
                    <div key={detailKey} className="import-file-error-warning">
                      <FaTimesCircle />
                      <p>{detail}</p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {!Details?.length ? (
            <p className="import-file-error-message">
              {isErrorString ? error : Message}
            </p>
          ) : null}
        </div>
      ),
    };
  }

  /**
   * Lida com erros que aconteçam durante a requisição de importação de planilha
   * de cadastro de Fornecedores.
   * @param error - Objeto de error
   */
  function errorHandler(
    error: IApiErrorResponse<IErrorResponseEntity>,
    errorMessage?: ReactNode,
  ) {
    const status = error?.status;
    const data = error?.data;

    if (
      status !== StatusCodes.BAD_REQUEST &&
      status !== StatusCodes.BAD_GATEWAY
    ) {
      return;
    }

    const dialogOptions = {
      [StatusCodes.BAD_GATEWAY]: badGatewayOptions(),
      [StatusCodes.BAD_REQUEST]: badRequestOptions(data, errorMessage),
    };

    // UGLY por algum motivo esse dialog abre e fecha automaticamente se nao
    // estiver dentro de um setTimeout, mas precisamos entender qual o erro
    // no lifecycle e encontrar uma forma de resolver sem o setTimeout
    setTimeout(async () => {
      await dialog.fire(dialogOptions[status]);
    });
  }

  return errorHandler;
}
