import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .classes-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;

      &:last-child {
        color: #5e72e4;
      }
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .react-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }
`;
