import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetConciliationTemplateUrlContract } from "../../domain/contracts/getConciliationTemplateUrlContract";

export class GetConciliationTemplateUrlService
  implements IGetConciliationTemplateUrlContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getConciliationTemplateUrl(): Promise<string> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsConciliation/Template`;

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
