import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IChangeSelectedCompaniesLockDatesContract,
  IChangeSelectedCompaniesLockDatesParams,
} from "../../domain/contracts/changeSelectedCompaniesLockDatesContract";

export class ChangeSelectedCompanyLockDatesService
  implements IChangeSelectedCompaniesLockDatesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  changeSelectedCompaniesLockDates({
    newLockDate,
    selectedCompanies,
  }: IChangeSelectedCompaniesLockDatesParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "Companies/ChangeLockReleasesUntil";

    const companyIdList = selectedCompanies.map(entity => entity.id);

    const lockDate = this.formatDate(newLockDate);

    const payload = {
      lockDate,
      companyIdList,
    };

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }

  private formatDate(date: string) {
    const parsed = parse(date, "ddMMyyyy", new Date());
    return format(parsed, "yyyy-MM-dd");
  }
}
