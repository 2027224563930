import { useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntitty";
import { IAccountPayableFormEntity } from "../../domain/entities/accountPayableFormEntity";
import { MakeAccountsPayableForm } from "../../main/makeAccountPayableForm";
import { IGenerateCommercialProposalForm } from "../../../../../core/domain/entities/generateCommercialProposalForm";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../domain/entities/providerEntity";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";

interface IUseAttachmentsHandler {
  useAccountsPayableForm: MakeAccountsPayableForm;
}

export function useAttachmentsHandler({
  useAccountsPayableForm,
}: IUseAttachmentsHandler) {
  const {
    updateAttachmentsBarcode,
    generateAttachmentVariableAdditional,
    generateAttachmentRpa,
    getStorageFilebyId,
    listAttachmentTypes,
    fetchRpaParamOptions,
    generateCommercialProposalAttachment,
    fetchCommercialProposalEnums,
  } = useAccountsPayableForm;

  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const form = useFormContext<IAccountPayableFormEntity>();

  const { watch, getValues, setValue } = form;

  const attachments = watch("attachments");
  const provider = watch("provider") as ITypeaheadOption<IProviderEntity>;

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IAccountPayableAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        currentCompanyGroup.id,
        billetAttachment,
      );

      return { updatedAttachment };
    } catch {
      return { updatedAttachment: billetAttachment };
    }
  };

  /**
   * Lida com o evento de troca do valor dos anexos emitido pela grid,
   * responsavel por atualizar a lista de anexos em memoria conforme as
   * edicoes realizadas pelo usuario
   */
  const handleAttachmentListChange = (
    _attachments: IAccountPayableAttachmentEntity[],
  ) => {
    setValue("attachments", _attachments);
  };

  /** Lida com o evento de gerar anexo de adicional variavel */
  const handleGenerateVariableAdditional = async () => {
    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Adicional variável.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const accountPayableFormEntity = getValues();

      const generatedFile = await generateAttachmentVariableAdditional(
        accountPayableFormEntity,
      );

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  /** Lida com o evento de gerar anexo de RPA */
  const handleGenerateRPA = async (
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ) => {
    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de RPA.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const accountPayableFormEntity = getValues();
      const generateRPAModalInput = genRPAModalInput;

      const generatedFile = await generateAttachmentRpa({
        accountPayableFormEntity,
        generateRPAModalInput,
      });

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleGenerateCommercialProposal = async (
    modalParams: IGenerateCommercialProposalForm,
  ) => {
    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Proposta Comercial.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const formValues = getValues();

      const params = {
        ...modalParams,
        valueMoney: formValues?.value || 0,
        projectId: (formValues?.project?.rawValue as string) || "",
        providerId: (formValues?.provider?.rawValue as string) || "",
      };

      const generatedFile = await generateCommercialProposalAttachment(params);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IAccountPayableAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck === EAttachmentType.CommercialProposal &&
      provider?.metadata?.documentType !== ProviderDocumentType.CPF
    ) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Este lançamento é destinado a um fornecedor do tipo pessoa jurídica
            ou pessoa estrangeira.
            <br />
            <br />
            Neste caso, o arquivo <strong>não será gerado</strong>, pois a
            proposta comercial é permitida{" "}
            <strong>somente para pessoas físicas</strong>.
          </>
        ),
      });

      return false;
    }

    if (
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.Measurement]: "Medição",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const handleGetStorageFileById = async (storageFileId: string) => {
    const attachment = attachments.find(
      attachmentData => attachmentData.storageFileId === storageFileId,
    );

    if (!attachment) {
      return;
    }

    await getStorageFilebyId(attachment);
  };

  return {
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
    handleAttachmentListChange,
    generateVariableAdditionalAttachment: handleGenerateVariableAdditional,
    generateRpaAttachment: handleGenerateRPA,
    generateCommercialProposalAttachment: handleGenerateCommercialProposal,
    attachmentTypeCheck: handleAttachmentTypeChange,
    attachments,
    getStorageFilebyId: handleGetStorageFileById,
    listAttachmentTypes,
    fetchRpaParamOptions,
    fetchCommercialProposalEnums,
  };
}
