import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRemoveAccountTerminationContract } from "../../domain/contracts/removeAccountTerminationContract";

export class RemoveAccountTerminationService
  implements IRemoveAccountTerminationContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async removeAccountTermination(
    companyGroupId: string,
    accountReceivableId: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsReceivable/${accountReceivableId}/Reopen`;

    await this.api.put(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
