import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { CompetencyEntity } from "../../domain/entities/competencyEntity";
import { IListCompetenciesService } from "../../domain/usecases/listCompetenciesUseCase";

export class ListCompetenciesService implements IListCompetenciesService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCompetencies(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<CompetencyEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Competencies?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<CompetencyEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
