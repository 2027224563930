import { format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICustomerEntity } from "../../domain/entities/customerEntity";
import { IGetCustomerService } from "../../domain/usecases/getCustomerUseCase";

export class GetCustomerService implements IGetCustomerService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCustomer(customerId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Customers/${customerId}`;

    const response = await this.api.get<ICustomerEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (response.birthDate) {
      response.birthDate =
        response.birthDate !== "" && response.birthDate !== null
          ? format(new Date(response.birthDate ?? ""), "ddMMyyyy").toString()
          : "";
    }

    response.email = response.email ?? "";

    return response;
  }
}
