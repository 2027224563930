import { IParsePanoramaAngularService } from "../../../../advTable/data/services/parsePanoramaAngularService";
import { IAdvTableColumn } from "../../../../advTable/domain/entities/advTableColumn";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IPanoramaEntity } from "../../../../advTable/domain/entities/panoramaEntity";
import { IListCostCenterReportPanoramasService } from "../../domain/usecases/listCostCenterReportPanoramasUseCase";
import { IPanoramaAngularModel } from "../../../../advTable/data/models/panoramaAngularModel";

export class ListCostCenterReportPanoramasService
  implements IListCostCenterReportPanoramasService
{
  constructor(
    private parsePanoramaAngularService: IParsePanoramaAngularService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCostCenterReportPanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]> {
    const url = `/Users/${userId}/CostCenterReportPanoramas`;
    const userEntity = this.getUserLocalService.get();

    const panoramas = await this.api.get<IPanoramaAngularModel[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const costCenterPanoramas = panoramas.map(panorama => {
      return this.parsePanoramaAngularService.parsePanoramaAngular(
        columns,
        panorama,
      );
    });

    return costCenterPanoramas;
  }
}
