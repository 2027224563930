import { StatusCodes } from "http-status-codes";

export interface IResponseEntity<T = unknown> {
  data: T;
  recordsTotal: number;
  recordsFiltered: number;
  error?: string;
  balance?: number;
}

export class ResponseEntity<T = unknown> implements IResponseEntity<T> {
  data = {} as T;
  recordsTotal = 0;
  recordsFiltered = 0;
  balance = 0;

  constructor(params?: Partial<IResponseEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export interface IErrorResponseEntity {
  Success: boolean;
  Code: number;
  Message: string;
  MessageHTML: string;
  Details: string[];
  InnerError: object;
}

export class ErrorResponseEntity implements IErrorResponseEntity {
  Success = false;
  Code = StatusCodes.BAD_REQUEST;
  Message = "";
  MessageHTML = "";
  Details = [""];
  InnerError = {};

  constructor(init?: Partial<IErrorResponseEntity>) {
    Object.assign(this, init);
  }
}

export type ILowerCaseErrorResponseEntity = {
  [P in keyof IErrorResponseEntity as `${Uncapitalize<
    Exclude<P, "InnerError">
  >}`]: IErrorResponseEntity[P];
};
