import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { ISaveImportationMatchesContract } from "../domain/contracts/saveImportationMatchesContract";
import { IImportationMatchesPayloadEntity } from "../domain/entities/importationMatchesPayloadEntity";

export class SaveImportationMatchesService
  implements ISaveImportationMatchesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveImportationMatches(payload: IImportationMatchesPayloadEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "/ImportationMatches";

    return this.api.post(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
