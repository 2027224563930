import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetStorageFilebyIdContract } from "../../domain/contracts/getStorageFileByIdContract";

export class GetStorageFilebyIdService implements IGetStorageFilebyIdContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getStorageFilebyId(storageFileId: string): Promise<string> {
    const userEntity = this.getUserLocalService.get();

    let url = `/Downloads/CostCenterTransaction/Attachments/StorageFiles`;

    url = `${url}/${storageFileId}`;

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
