import { useState } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiError } from "../../../../../core/data/services/apiService";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { ILowerCaseErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";
import { useRequesterPage } from "./useRequesterPage";

export function useRequesterAttachmentsHandler() {
  const {
    reload,
    usePaymentRequests,
    handleCloseAttachmentModal,
    state: {
      attachmentsModal: { rowData, isOpen },
    },
  } = useRequesterPage();

  const dialog = useSoulDialog();
  const debounceTime = useDebounceTimeAsync();
  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const [progressModalState, setProgressModalState] = useState({
    total: 0,
    loaded: 0,
    isOpen: false,
  });

  const {
    getAttachments,
    uploadAttachments,
    getStorageFilebyId,
    updateAttachmentsBarcode,
    listRequesterAttachmentTypes,
    validateMeasurementAttachment,
  } = usePaymentRequests;

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IPaymentRequestAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        companyGroupId,
        billetAttachment,
      );

      return {
        updatedAttachment,
        preventSubmit: false,
      };
    } catch {
      dialog.fire({
        icon: "warning",
        title: "Aviso",
        html: (
          <>
            Não foi possível obter o código de barras desse boleto. Faça a
            inclusão por dentro da solicitação de pagamento e preencha o código
            de barras
          </>
        ),
      });

      return {
        preventSubmit: true,
        updatedAttachment: billetAttachment,
      };
    }
  };

  const handleValidateMeasurementAttachment = async (
    measurementAttachment: IPaymentRequestAttachmentEntity,
  ) => {
    const paymentRequestValue = rowData?.value;

    if (!paymentRequestValue) {
      return {
        preventSubmit: false,
        updatedAttachment: measurementAttachment,
      };
    }

    try {
      const response = await validateMeasurementAttachment(
        paymentRequestValue,
        measurementAttachment,
      );

      if (response.success === true) {
        return {
          preventSubmit: false,
          updatedAttachment: measurementAttachment,
        };
      }

      if (response.success === false) {
        await debounceTime(1);

        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: response.message,
        });
      }

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    } catch (error) {
      await debounceTime(1);

      const messageHTMLError = (
        error as IApiError<ILowerCaseErrorResponseEntity>
      ).response.data.messageHTML;

      const messageError = (error as IApiError<ILowerCaseErrorResponseEntity>)
        .response.data.message;

      await dialog.fire({
        icon: "error",
        title: "Opa!",
        html: messageHTMLError || messageError,
      });

      return {
        preventSubmit: true,
        updatedAttachment: measurementAttachment,
      };
    }
  };

  const onUploadProgress = (event: ProgressEvent) => {
    setProgressModalState({
      isOpen: true,
      total: event.total,
      loaded: event.loaded,
    });
  };

  const handleAttachmentsSubmission = async (
    formAttachments: IPaymentRequestAttachmentEntity[],
  ) => {
    const paymentRequestId = rowData?.id;

    setProgressModalState({
      total: 0,
      loaded: 0,
      isOpen: true,
    });

    if (!paymentRequestId) {
      return;
    }

    try {
      await uploadAttachments({
        onUploadProgress,
        paymentRequestId,
        attachments: formAttachments,
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            Lançamento de Solicitação de Pagamento para o{" "}
            <strong>{rowData.destinationDescription}</strong> atualizado com
            sucesso.
          </>
        ),
      });

      reload();
    } finally {
      setProgressModalState({
        total: 0,
        loaded: 0,
        isOpen: false,
      });

      handleCloseAttachmentModal();
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IPaymentRequestAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.Measurement]: "Medição",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const handleAttachmentsModalAfterOpen = () => {
    const paymentRequestId = rowData?.id;

    if (!paymentRequestId) {
      handleCloseAttachmentModal();
      return [];
    }

    return getAttachments(paymentRequestId);
  };

  return {
    isOpen,
    rowData,
    progressModalState,
    getStorageFilebyId,
    handleCloseAttachmentModal,
    handleAttachmentsSubmission,
    handleAttachmentsModalAfterOpen,
    attachmentTypeCheck: handleAttachmentTypeChange,
    listAttachmentTypes: listRequesterAttachmentTypes,
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
    validateMeasurementAttachment: handleValidateMeasurementAttachment,
  };
}
