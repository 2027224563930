import { IEntity } from "../../../../core/domain/entities/entity";

export enum EPaymentAccountTransactionType {
  expense = 1, // débito
  revenue = 2, // crédito
}

export const paymentAccountTransactionTypeLang: Record<string, string> = {
  expense: "Débito",
  revenue: "Crédito",
};

export interface IPaymentAccountTransactionEntity extends IEntity {
  companyGroupId: string;
  companyGroupName: string;
  companyId: string;
  paymentAccountName: string;
  paymentAccountOriginId: string;
  paymentAccountDestinationId: string;
  type: EPaymentAccountTransactionType;
  typeDescription: string;
  value: number;
  valueString: string;
  transactionDate: string;
  transactionDateString: string;
  description: string;
  userNameCreated: string;
  userNameModified: string;
  paymentAccountActive: boolean | undefined;
}

export class PaymentAccountTransactionEntity
  implements IPaymentAccountTransactionEntity
{
  id = "";
  active = true;
  companyGroupId = "";
  companyGroupName = "";
  companyId = "";
  paymentAccountName = "";
  paymentAccountOriginId = "";
  paymentAccountDestinationId = "";
  type = EPaymentAccountTransactionType.expense;
  typeDescription = "";
  value = 0;
  valueString = "0.00";
  transactionDate = "";
  transactionDateString = "";
  description = "";
  userNameCreated = "";
  userNameModified = "";
  paymentAccountActive = undefined;

  constructor(params?: Partial<IPaymentAccountTransactionEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IPaymentAccountTransactionEntity>) {
    return new PaymentAccountTransactionEntity(params);
  }
}
