import { AdvTable } from "../../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../../advTable/presentation/components/ColumnSelector";
import { useRequesterPage } from "../../hooks/useRequesterPage";
import { EmptyMessageContainer } from "../PaymentRequestPage/styles";

export function RequesterTable() {
  const {
    state,
    columns,
    tableRef,
    rowClassName,
    handleGetList,
    handleRowClick,
    handleColReorder,
    handleColumnRemove,
    handleColumnSelect,
    handleRowDoubleClick,
  } = useRequesterPage();

  const { data, isFetching, selectedColumns, selectedPanorama } = state;

  return (
    <>
      <ColumnSelector
        columns={columns}
        selectedColumns={selectedColumns}
        onSelect={handleColumnSelect}
      />
      <AdvTable
        data={data}
        rowsDefault={50}
        columns={columns}
        removeableColumns
        tableRef={tableRef}
        loading={isFetching}
        onPage={handleGetList}
        onSort={handleGetList}
        onClear={handleGetList}
        onFilter={handleGetList}
        rowClassName={rowClassName}
        onRowClick={handleRowClick}
        onColReorder={handleColReorder}
        selectedColumns={selectedColumns}
        rowsPerPageOptions={[10, 50, 100]}
        onColumnRemove={handleColumnRemove}
        onRowDoubleClick={handleRowDoubleClick}
        emptyMessage={
          <EmptyMessageContainer>
            <div>Nenhum registro encontrado</div>
          </EmptyMessageContainer>
        }
        panoramaFilters={
          selectedPanorama?.panoramaDefinition.pfsEventEntity.filters
        }
        panoramaSort={
          selectedPanorama?.panoramaDefinition.pfsEventEntity.multiSortMeta
        }
      />
    </>
  );
}
