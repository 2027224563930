import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchClassificationAccountContract } from "../../domain/contracts/searchClassificationAccountContract";
import { ISearchParams } from "../../domain/entities/searchParams";

interface IClassificationAccountResponse {
  id: string;
  name: string;
}

export class SearchClassificationAccountService
  implements ISearchClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchClassificationAccount({
    search = "",
    activesOnly,
    payloadOptions,
    companyGroupId,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      length: search ? undefined : payloadOptions?.length,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/ClassificationAccounts`;

    if (activesOnly) {
      url += "/Actives";
    }

    const response = await this.api.get<
      IServerSideResponseModel<IClassificationAccountResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(classificationAccount => {
        return new RelationshipFilterOption({
          label: classificationAccount.name,
          rawValue: classificationAccount.id,
          metadata: showMetadata ? classificationAccount : undefined,
        });
      }),
    });
  }
}
