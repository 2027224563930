import { IValidateTokenModel } from "../../data/models/validateTokenModel";

export interface IValidateTokenUseCase {
  validateToken(): Promise<IValidateTokenModel>;
}

export interface IValidateTokenService {
  validateToken(): Promise<IValidateTokenModel>;
}

export class ValidateTokenUseCase implements IValidateTokenUseCase {
  constructor(private validateTokenService: IValidateTokenService) {}

  validateToken(): Promise<IValidateTokenModel> {
    return this.validateTokenService.validateToken();
  }
}
