import { isAfter, isValid, parse } from "date-fns";
import { useCallback } from "react";

export function useDateValidator() {
  return useCallback((dateString: string, dateFormat = "ddMMyyyy") => {
    const currentLength = dateString?.length;

    if (dateString) {
      const date = parse(dateString, dateFormat, new Date()) as Date;

      const invalidDate = !isValid(date) || currentLength !== dateFormat.length;

      if (invalidDate) return invalidDate;
    }

    return false;
  }, []);
}

export interface BirthdateValidatorErrors {
  invalidDate?: boolean;
  futureDate?: boolean;
}

export type UseBirthdateValidatorFnReturnType =
  | boolean
  | BirthdateValidatorErrors;

export type UseBirthdateValidatorReturnType<T = unknown> = (
  dateString: string,
  dateFormat?: string,
) => T;

export function useBirthdateValidator(): UseBirthdateValidatorReturnType<UseBirthdateValidatorFnReturnType> {
  const validate = useDateValidator();

  return useCallback(
    (dateString: string, dateFormat = "ddMMyyyy") => {
      if (dateString) {
        const invalidDate = validate(dateString, dateFormat);

        if (invalidDate) {
          return { invalidDate } as UseBirthdateValidatorFnReturnType;
        }

        const date = parse(dateString, dateFormat, new Date()) as Date;

        const futureDate = isAfter(date, new Date());

        if (futureDate) {
          return { futureDate } as UseBirthdateValidatorFnReturnType;
        }
      }

      return false;
    },
    [validate],
  );
}
