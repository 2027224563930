import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaDownload, FaSpinner } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import excelThumbnail from "../../../../assets/excel-file-image.png";
import imageThumbnail from "../../../../assets/image-file.png";
import pdfThumbnail from "../../../../assets/pdf-icon.png";
import { DropzoneContainer } from "../../../../core/presentation/components/ImportFileModal/style";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useConciliationContext } from "../../hooks/useConciliationContext";
import { Container } from "./styles";

const sheetType = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel": [".xls"],
};

interface IConciliationStepImportationProps {
  errorText?: string;
  placeholder?: string;
  sending: boolean;
  nextButtonOnClick(files: File[]): void;
}

export function ConciliationStepImportation({
  placeholder = "Selecione ou arraste sua planilha aqui",
  errorText = "Apenas planilhas em formato .xls/.xlsx são aceitas",
  sending,
  nextButtonOnClick,
}: IConciliationStepImportationProps) {
  const { useConciliation, backButtonClickHandler, files, setFiles } =
    useConciliationContext();
  const [loading, setLoading] = useState(false);

  const dialog = useSoulDialog();

  const { getRootProps, getInputProps } = useDropzone({
    accept: sheetType,
    multiple: false,
    maxSize: 104857600,
    onDropAccepted: accepted => {
      setFiles(accepted);
    },
    onDropRejected: () => {
      dialog.fire("Formato Inválido", errorText, "error");
    },
  });

  const renderFileIcon = (file: File) => {
    const fileExt = file.type;
    if (fileExt.includes("pdf")) return pdfThumbnail;
    if (fileExt.includes("image")) return imageThumbnail;
    return excelThumbnail;
  };

  const removeFile = (
    file: File,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    const fileIndex = files.indexOf(file);
    const filteredList = files.filter(
      _file => files.indexOf(_file) !== fileIndex,
    );

    setFiles(filteredList);
  };

  const { getConciliationTemplateUrl } = useConciliation;

  const handleDownloadTemplateButtonClick = async () => {
    setLoading(true);

    try {
      const url = await getConciliationTemplateUrl();
      window.open(url);
    } finally {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    backButtonClickHandler();
  };

  const handleNextButtonClick = () => {
    if (!files?.length || sending) {
      return;
    }

    nextButtonOnClick(files);
  };

  return (
    <Container>
      <div className="body">
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} data-testid="dropzone-input" />
          {!files?.length ? <p>{placeholder}</p> : null}
          {files?.length
            ? files.map(file => {
                const fileKey = `${file.name}&${files.indexOf(file)}`;
                return (
                  <div
                    key={fileKey}
                    className="dropzone-preview"
                    data-testid="dropzone-preview"
                  >
                    <button
                      type="button"
                      data-testid="btn-remove"
                      onClick={$event => {
                        removeFile(file, $event);
                      }}
                    >
                      <IoMdCloseCircle />
                    </button>
                    <img src={renderFileIcon(file)} alt={file.name} />
                    <p>{file.name}</p>
                  </div>
                );
              })
            : null}
        </DropzoneContainer>
      </div>
      <div className="footer">
        <button
          type="button"
          data-testid="btn-template"
          className="form-button default-button template-button"
          disabled={loading}
          onClick={handleDownloadTemplateButtonClick}
        >
          <FaDownload />
          <span>Baixar Modelo&nbsp;</span>
          {loading && <FaSpinner className="spinner" />}
        </button>
        <button
          type="button"
          className="form-button red-bkg back-button"
          data-testid="btn-close"
          onClick={handleBackButtonClick}
        >
          Voltar
        </button>
        <button
          type="button"
          data-testid="btn-finish"
          className={`form-button next-button ${
            files?.length ? "green-bkg" : "invalid-bkg"
          }`}
          onClick={handleNextButtonClick}
        >
          Próximo {sending && <FaSpinner className="spinner" />}
        </button>
      </div>
    </Container>
  );
}
