import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ITerminateAccountContract,
  ITerminateAccountParams,
} from "../../domain/contracts/terminateAccountContract";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";

interface ITerminateAccountPayload {
  deleteReason: "";
  status: EAccountPayableStatus.Paid;
  terminationDate: string;
}

export class TerminateAccountService implements ITerminateAccountContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async terminateAccount({
    accountPayableId,
    terminationDate,
  }: ITerminateAccountParams): Promise<void> {
    const dtTerminationDate = parse(
      terminationDate.replace(/\D/g, ""),
      "ddMMyyyy",
      new Date(),
    );

    const formatedTerminationDate = format(
      dtTerminationDate,
      "yyyy-MM-dd 00:00:00",
    );

    const payload: ITerminateAccountPayload = {
      terminationDate: formatedTerminationDate,
      status: EAccountPayableStatus.Paid,
      deleteReason: "",
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/${accountPayableId}/Status`;

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
