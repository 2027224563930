import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IGetStoredAccountsPayablePanoramaIdContract } from "../../domain/contracts/getStoredAccountsPayablePanoramaIdContract";

export class GetStoredAccountsPayablePanoramaIdService
  implements IGetStoredAccountsPayablePanoramaIdContract
{
  getStoredAccountsPayablePanoramaId(): string {
    const key = ELocalStorageKeys.AccountsPayablePanoramaId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
