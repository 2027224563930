import { AccordionTab } from "primereact/accordion";
import { useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { InputPercentage } from "../../../../core/presentation/components/InputPercentage";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { ICompanyFormEntity } from "../../../domain/entities/companyFormEntity";
import { StyledAccordion } from "./styles";
import { ISearchBrazilianCityParams } from "../../../../core/domain/contracts/searchBrazilianCityContract";

interface IInvoiceParametersFieldProps {
  isDisabled: boolean;
  cnaeList: ITypeaheadOption[];
  stateList: ITypeaheadOption[];
  municipalActivityList: ITypeaheadOption[];
  searchBrazilianCity(
    params: ISearchBrazilianCityParams,
  ): Promise<ITypeaheadOption[]>;
}

interface IInvoiceParametersFieldState {
  cities: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
}

export function InvoiceParametersField(props: IInvoiceParametersFieldProps) {
  const {
    cnaeList,
    stateList,
    isDisabled,
    searchBrazilianCity,
    municipalActivityList,
  } = props;

  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ICompanyFormEntity>();

  const asyncDebounce = useDebounceTimeAsync();

  const serviceSupplyCityValue = watch("serviceSupplyCity");
  const serviceSupplyStateValue = watch("serviceSupplyState");

  const [state, setState] = useState<IInvoiceParametersFieldState>({
    cities: {
      options: [],
      loading: false,
    },
  });

  const handleCitySearchChange = async (search: string) => {
    setState(prevState => ({
      ...prevState,
      cities: { ...prevState.cities, loading: true },
    }));

    await asyncDebounce(750);

    const stateId = getValues("serviceSupplyState.rawValue") as string;

    try {
      const response = await searchBrazilianCity({
        search,
        stateId,
        payloadOptions: {
          length: 50,
        },
      });

      setState(prevState => ({
        ...prevState,
        cities: { ...prevState.cities, options: response },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        cities: { ...prevState.cities, loading: false },
      }));
    }
  };

  const handleStateChange = (event: ISoulTypeaheadChangeEvent) => {
    setValue("serviceSupplyState", event.target.value, {
      shouldValidate: true,
    });
    setValue("serviceSupplyCity", null, {
      shouldValidate: true,
    });
  };

  const handleCityChange = (event: ISoulTypeaheadChangeEvent) => {
    setValue("serviceSupplyCity", event.target.value, {
      shouldValidate: true,
    });
  };

  /**
   * Os campos de prestação de serviço precisam ser registrados dessa forma
   * pois o AccordionTab só monta os campos quando é aberto.
   *
   * Por padrão, campos que utilizam Controllers só são registrados se estiverem
   * renderizados.
   * */
  register("serviceSupplyCity", {
    required: true,
  });
  register("serviceSupplyState", {
    required: true,
  });

  return (
    <StyledAccordion>
      <AccordionTab header="Parâmetros Fiscais NFS-e">
        <div className="form-container">
          <div className="form-row">
            <label className="col-4 form-control">
              <span className="smaller-label">
                Enquadramento de Serviço <small>(opcional)</small>
              </span>
              <Controller
                control={control}
                name="municipalActivity"
                render={({ field }) => {
                  return (
                    <SoulTypeahead
                      openOnFocus
                      value={field.value}
                      disabled={isDisabled}
                      onChange={field.onChange}
                      id="sel-municipalActivity"
                      data-testid="sel-municipalActivity"
                      options={municipalActivityList}
                      placeholder="Enquadramento de Serviço"
                    />
                  );
                }}
              />
            </label>
            <label className="col-4 form-control">
              <span>
                CNAE <small>(opcional)</small>
              </span>
              <Controller
                name="cnae"
                control={control}
                render={({ field }) => {
                  return (
                    <SoulTypeahead
                      openOnFocus
                      id="sel-cnae"
                      placeholder="CNAE"
                      value={field.value}
                      disabled={isDisabled}
                      data-testid="sel-cnae"
                      onChange={field.onChange}
                      options={cnaeList}
                    />
                  );
                }}
              />
            </label>
            <label className="col-4 form-control">
              <span>
                Alíquota ISS% <small>(opcional)</small>
              </span>
              <Controller
                name="issAliquot"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPercentage
                      {...field}
                      precision={4}
                      id="txt-issAliquot"
                      disabled={isDisabled}
                      placeholder="00,0000 %"
                      data-testid="txt-issAliquot"
                    />
                  );
                }}
              />
            </label>
          </div>
          <div className="form-row">
            <label className="col-4 form-control">
              <span>
                Alíquota IR% <small>(opcional)</small>
              </span>
              <Controller
                name="irAliquot"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPercentage
                      {...field}
                      precision={4}
                      id="txt-irAliquot"
                      disabled={isDisabled}
                      placeholder="00,0000 %"
                      data-testid="txt-irAliquot"
                    />
                  );
                }}
              />
            </label>
            <label className="col-4 form-control">
              <span>
                Alíquota CSLL% <small>(opcional)</small>
              </span>
              <Controller
                name="csllAliquot"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPercentage
                      {...field}
                      precision={4}
                      id="txt-csllAliquot"
                      disabled={isDisabled}
                      placeholder="00,0000 %"
                      data-testid="txt-csllAliquot"
                    />
                  );
                }}
              />
            </label>
            <label className="col-4 form-control">
              <span>
                Alíquota PIS% <small>(opcional)</small>
              </span>
              <Controller
                name="pisAliquot"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPercentage
                      {...field}
                      precision={4}
                      id="txt-pisAliquot"
                      disabled={isDisabled}
                      placeholder="00,0000 %"
                      data-testid="txt-pisAliquot"
                    />
                  );
                }}
              />
            </label>
          </div>
          <div className="form-row">
            <label className="col-4 form-control">
              <span>
                Alíquota COFINS% <small>(opcional)</small>
              </span>
              <Controller
                name="cofinsAliquot"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPercentage
                      {...field}
                      precision={4}
                      id="txt-cofinsAliquot"
                      disabled={isDisabled}
                      placeholder="00,0000 %"
                      data-testid="txt-cofinsAliquot"
                    />
                  );
                }}
              />
            </label>
            <label className="col-4 form-control">
              <span>Estado prestação de serviço</span>
              <SoulTypeahead
                openOnFocus
                options={stateList}
                placeholder="Estado"
                disabled={isDisabled}
                onChange={handleStateChange}
                id="sel-serviceSupplyState"
                value={serviceSupplyStateValue}
                data-testid="sel-serviceSupplyState"
                className={errors?.serviceSupplyState ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={
                  (errors?.serviceSupplyState as FieldError)?.type ===
                  "required"
                }
                message="Este campo é obrigatório"
              />
            </label>
            <label className="col-4 form-control">
              <span className="label-with-loading">
                Cidade prestação de serviço{" "}
                {state.cities.loading ? (
                  <i className="pi pi-spin pi-spinner" />
                ) : null}
              </span>
              <SoulTypeahead
                serverSide
                openOnFocus
                placeholder="Cidade"
                disabled={isDisabled}
                id="sel-serviceSupplyCity"
                onChange={handleCityChange}
                value={serviceSupplyCityValue}
                loading={state.cities.loading}
                options={state.cities.options}
                data-testid="sel-serviceSupplyCity"
                onSearchChange={handleCitySearchChange}
                className={errors?.serviceSupplyCity ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={
                  (errors?.serviceSupplyCity as FieldError)?.type === "required"
                }
                message="Este campo é obrigatório"
              />
            </label>
          </div>
          <div className="form-row">
            <label className="col-12 form-control">
              <span>
                Descrição da nota fiscal <small>(opcional)</small>
              </span>
              <input
                disabled={isDisabled}
                id="txt-invoiceServiceDescription"
                placeholder="Descrição da nota fiscal"
                data-testid="txt-invoiceServiceDescription"
                className={errors?.invoiceServiceDescription ? "isInvalid" : ""}
                {...register("invoiceServiceDescription", {
                  maxLength: 2000,
                })}
              />
              <InvalidFeedback
                condition={
                  errors.invoiceServiceDescription?.type === "maxLength"
                }
                message="Máximo de caracteres excedido"
              />
            </label>
          </div>
        </div>
      </AccordionTab>
    </StyledAccordion>
  );
}
