import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListFeesContract } from "../../domain/contracts/listFeesContract";
import { IFeeEntity } from "../../domain/entities/feeEntity";

export class ListFeesService implements IListFeesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listFees(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IFeeEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Fees?${params}`;

    const response = await this.api.get<IServerSideResponseModel<IFeeEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response;
  }
}
