import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../core/presentation/components/SoulTypeahead";
import { ICostCenterEntity } from "../../../domain/entities/costCenterEntity";
import { Container } from "./style";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";

interface DeactivateCostCenterFormState {
  costCenter: {
    loading: boolean;
    options?: ITypeaheadOption[];
  };
}

export interface DeactivateCostCenterFormEntity {
  deleteReason: string;
  costCenterReplaced: ITypeaheadOption | null;
}

export interface DeactivateCostCenterFormProps {
  costCenter: ICostCenterEntity;
  currentCompanyGroupId: string;
  changeSubmissionButton(isValid: boolean): void;
  onValid(values: DeactivateCostCenterFormEntity): Promise<void>;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export function DeactivateCostCenterForm(props: DeactivateCostCenterFormProps) {
  const {
    onValid,
    costCenter,
    searchCostCenter,
    currentCompanyGroupId,
    changeSubmissionButton,
  } = props;

  const asyncDebouce = useDebounceTimeAsync();

  const [state, setState] = useState<DeactivateCostCenterFormState>({
    costCenter: {
      loading: false,
      options: [],
    },
  });

  const { control, register, handleSubmit } =
    useForm<DeactivateCostCenterFormEntity>({
      mode: "all",
      defaultValues: {
        deleteReason: "",
        costCenterReplaced: costCenter?.costCenterReplacedId
          ? {
              label: costCenter?.costCenterReplacedName,
              rawValue: costCenter?.costCenterReplacedId,
            }
          : null,
      },
    });

  const handleSearchCostCenter = async (search: string) => {
    await asyncDebouce(750);

    setState(prevState => ({
      ...prevState,
      costCenter: {
        ...prevState.costCenter,
        loading: true,
      },
    }));

    try {
      const { data } = await searchCostCenter({
        search,
        activesOnly: true,
        companyGroupId: currentCompanyGroupId,
      });

      const options = data.filter(costCenterOption => {
        return costCenterOption?.rawValue !== costCenter.id;
      });

      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          options,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        costCenter: {
          ...prevState.costCenter,
          loading: false,
        },
      }));
    }
  };

  return (
    <Container>
      <p>Para desativar este Centro de Custo, nos diga o motivo:</p>
      <form
        id="deactivate-form"
        className="form-container"
        onSubmit={handleSubmit(onValid)}
      >
        <div className="form-row">
          <label className="col-8 form-control">
            <InputTextarea
              autoResize
              id="txt-reason"
              {...register("deleteReason")}
              placeholder="Digite aqui o motivo da inativação"
            />
          </label>
        </div>
        <div className="form-row">
          <Controller
            control={control}
            name="costCenterReplaced"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              const handleChange = (option: ISoulTypeaheadChangeEvent) => {
                const isValid = !!option.target.value;
                changeSubmissionButton(isValid);
                field.onChange(option);
              };

              return (
                <label className="col-8 form-control">
                  <span>Centro de Custo Substituto</span>
                  <SoulTypeahead
                    serverSide
                    openOnFocus
                    value={field.value}
                    onChange={handleChange}
                    id="txt-search-cost-center"
                    loading={state.costCenter.loading}
                    options={state.costCenter.options}
                    data-testid="txt-search-cost-center"
                    onSearchChange={handleSearchCostCenter}
                    placeholder="Centro de Custo Substituto"
                    disabled={!!costCenter.costCenterReplacedId}
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    message="Esse campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </label>
              );
            }}
          />
        </div>
      </form>
      <p>Tem certeza que deseja desativá-lo?</p>
    </Container>
  );
}
