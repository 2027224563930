import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICompanyEntity } from "../entities/companyEntity";

export interface IListCompaniesService {
  listCompanies(
    payload: IPayloadEntity,
    companyGroupId: string,
    actives?: boolean,
  ): Promise<IResponseEntity<ICompanyEntity[]>>;
}

export interface IListCompaniesUseCase {
  listCompanies(
    payload: IPayloadEntity,
    companyGroupId: string,
    actives?: boolean,
  ): Promise<IResponseEntity<ICompanyEntity[]>>;
}

export class ListCompaniesUseCase implements IListCompaniesUseCase {
  constructor(private listCompaniesService: IListCompaniesService) {}

  listCompanies(
    payload: IPayloadEntity,
    companyGroupId: string,
    actives?: boolean,
  ) {
    return this.listCompaniesService.listCompanies(
      payload,
      companyGroupId,
      actives,
    );
  }
}
