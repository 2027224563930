import { Navigate, useLocation } from "react-router-dom";
import { useRouterGuard } from "../../hooks/useRouterGuard";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { SoulRoutes } from "../../../domain/entities/soulRoutes";

interface GuardedRouteProps {
  children: JSX.Element;
}

export function GuardedRoute(props: GuardedRouteProps) {
  const { children } = props;

  const location = useLocation();
  const { user } = useUserLocal();

  const isUserAllowed = useRouterGuard({
    currentProfile: user.profile,
    currentRoute: location.pathname,
  });

  if (isUserAllowed) {
    return children;
  }

  return <Navigate to={SoulRoutes.DASHBOARD.path} replace />;
}
