import { FilterMatchMode } from "primereact/api";
import { Column, ColumnBodyOptions } from "primereact/column";
import {
  DataTable,
  DataTableProps,
  DataTableRowClassNameOptions,
} from "primereact/datatable";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {
  ForwardedRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { IEntity } from "../../../../core/domain/entities/entity";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleCustomColumn,
  ISimpleHiddenColumn,
  ISimpleSortableColumn,
  SimpleTableColumn,
} from "../../../domain/entities/simpleColumnEntity";
import { useSimpleTableSettings } from "../../hooks/useSimpleTable";
import { Container } from "./styles";

interface SimpleTableProps<T> extends Omit<DataTableProps, "value"> {
  columns: SimpleTableColumn[];
  data: IResponseEntity<T[]> | undefined;
  getList(params: IPFSEventEntity): void;
}

export interface ISimpleTableHandle {
  reload(): void;
}

const SimpleTableWrapper = forwardRef(function SimpleTableRef<T>(
  {
    data,
    columns,
    getList,
    rowClassName,
    rowHover = true,
    paginator = true,
    scrollable = true,
    stripedRows = true,
    sortMode = "single",
    breakpoint = "576px",
    globalFilter = undefined,
    responsiveLayout = "scroll",
    rowsPerPageOptions = [10, 25, 50],
    emptyMessage = "Nenhum registro encontrado.",
    ...rest
  }: SimpleTableProps<T>,
  forwardedRef: ForwardedRef<ISimpleTableHandle>,
) {
  const { paginatorTemplate } = useSimpleTableSettings();
  const [rows, setRows] = useState(rowsPerPageOptions?.[0] ?? 10);

  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity({
      globalFilter: globalFilter ?? "",
      filters: {
        global: {
          value: globalFilter ?? "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      rows,
      first: 0,
    }),
  );

  const rowClass = useCallback(
    (rowData: IEntity, options: DataTableRowClassNameOptions) => {
      const { active } = rowData;

      const definedObject = rowClassName?.(rowData, options) || {};

      const externalClassName =
        typeof definedObject === "string"
          ? { [definedObject]: true }
          : definedObject;

      return {
        "row-disabled": !active,
        ...externalClassName,
      };
    },
    [rowClassName],
  );

  const onPage = useCallback(
    _pfsEvent => {
      setRows(_pfsEvent.rows);
      setPfsEvent({
        ..._pfsEvent,
        globalFilter: globalFilter ?? "",
        filters: {
          ..._pfsEvent.filters,
          global: {
            ..._pfsEvent.filters.global,
            value: globalFilter ?? "",
          },
        },
      });
    },
    [globalFilter],
  );

  const onSort = useCallback(
    _pfsEvent => {
      setPfsEvent({
        ..._pfsEvent,
        globalFilter: globalFilter ?? "",
        filters: {
          ..._pfsEvent.filters,
          global: {
            ..._pfsEvent.filters.global,
            value: globalFilter ?? "",
          },
        },
      });
    },
    [globalFilter],
  );

  const renderBody = useCallback(
    (rowData: Record<string, unknown>, { field }: ColumnBodyOptions) => {
      const content = `${rowData[field]}` ?? "";
      return <span title={content}>{content}</span>;
    },
    [],
  );

  useImperativeHandle(forwardedRef, () => ({
    reload() {
      getList(pfsEvent);
    },
  }));

  useEffect(() => {
    if (globalFilter !== undefined) {
      setPfsEvent(prevPfsEvent => {
        const computedPsfEvent = {
          ...prevPfsEvent,
          globalFilter: globalFilter || "",
          filters: {
            ...prevPfsEvent.filters,
            global: {
              ...prevPfsEvent.filters.global,
              value: globalFilter,
            },
          },
        };

        return computedPsfEvent;
      });
    }
  }, [globalFilter, getList]);

  useEffect(() => {
    getList(pfsEvent);
  }, [getList, pfsEvent]);

  return (
    <Container>
      <DataTable
        {...rest}
        lazy
        rows={rows}
        dataKey="id"
        onSort={onSort}
        onPage={onPage}
        value={data?.data}
        rowHover={rowHover}
        sortMode={sortMode}
        paginator={paginator}
        breakpoint={breakpoint}
        first={pfsEvent.first}
        rowClassName={rowClass}
        scrollable={scrollable}
        stripedRows={stripedRows}
        filters={pfsEvent.filters}
        emptyMessage={emptyMessage}
        sortField={pfsEvent.sortField}
        sortOrder={pfsEvent.sortOrder}
        responsiveLayout={responsiveLayout}
        paginatorTemplate={paginatorTemplate}
        multiSortMeta={pfsEvent.multiSortMeta}
        rowsPerPageOptions={rowsPerPageOptions}
        totalRecords={data?.recordsFiltered || 1}
      >
        {columns
          .filter(column => !(column as ISimpleHiddenColumn).hidden)
          .map(column => {
            const { field, header, className } = column as ISimpleColumn;
            const { orderable, sortField } = column as ISimpleSortableColumn;
            const { bodyTemplate } = column as ISimpleCustomColumn;

            return (
              <Column
                {...column}
                key={field}
                field={field}
                header={header}
                sortable={orderable}
                className={className}
                sortField={sortField ?? field}
                body={bodyTemplate ?? renderBody}
              />
            );
          })}
      </DataTable>
    </Container>
  );
});

type SimpleTableRefProps<T> = SimpleTableProps<T> & {
  tableRef?: Ref<ISimpleTableHandle>;
};

// esse wrapper é necessário para podermos manter a abstração
// da SimpleTable fazendo uso de TypeScript Generics
export function SimpleTable<T>({ tableRef, ...props }: SimpleTableRefProps<T>) {
  return <SimpleTableWrapper ref={tableRef} {...props} />;
}
