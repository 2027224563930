import { useCallback } from "react";
import { Container, Label, Toggler } from "./styles";

interface ToggleProps {
  id?: string;
  value?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  inactiveClassName?: string;
  "data-testid"?: string;
  onChange?(value: boolean): void;
}

export function Toggle({
  id,
  value,
  disabled,
  readonly = false,
  inactiveClassName,
  "data-testid": dataTestId,
  onChange,
}: ToggleProps) {
  const handleTogglerClick = useCallback(async () => {
    if (disabled) {
      return;
    }

    onChange?.(!value);
  }, [disabled, onChange, value]);

  return (
    <Container className={!value ? inactiveClassName : undefined}>
      <button
        id={id}
        type="button"
        disabled={!!disabled}
        data-testid={dataTestId}
        onClick={readonly ? undefined : handleTogglerClick}
      >
        <Toggler className={value ? "toggled" : undefined} />
        <Label className={value ? "toggled" : undefined}>
          {value && "Sim"}
          {!value && "Não"}
        </Label>
      </button>
    </Container>
  );
}

Toggle.defaultProps = {
  inactiveClassName: "",
  "data-testid": undefined,
};
