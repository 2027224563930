import {
  DataTableExpandedRows,
  DataTableRowExpansionTemplate,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { ClientSoulTable } from "../../../../core/presentation/components/ClientSoulTable";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IProjectTaxesEntity } from "../../../domain/entities/projectTaxesEntity";
import { MakeProjects } from "../../../main/makeProjects";
import { useProjectTaxesGrid } from "../../hooks/useProjectTaxesGrid";
import { Container } from "./style";

interface ProjectTaxesModalProps {
  isOpen: boolean;
  projectId: string;
  useProjects: MakeProjects;
  projectDescription: string;
  projectCompetencyName: string;
  onClose: (shouldRefresh: boolean | unknown) => void;
}

interface ProjectTaxesModalState {
  search: string;
  isLoading: boolean;
  expandedRows?: DataTableExpandedRows;
}

export interface ProjectTaxesForm {
  taxesList: IProjectTaxesEntity[];
}

const mainColumns = [
  {
    expander: true,
    field: "expander",
    style: {
      width: "3rem",
    },
  },
  {
    header: "Empresa",
    field: "assumedName",
  },
];

export function ProjectTaxesModal(props: ProjectTaxesModalProps) {
  const {
    isOpen,
    onClose,
    projectId,
    useProjects,
    projectDescription,
    projectCompetencyName,
  } = props;

  const { getProjectTaxes, saveProjectTaxes } = useProjects;

  const dialog = useSoulDialog();
  const generateTaxColumns = useProjectTaxesGrid();

  const {
    reset,
    control,
    getValues,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<ProjectTaxesForm>({
    mode: "all",
    defaultValues: {
      taxesList: [],
    },
  });

  const [state, setState] = useState<ProjectTaxesModalState>({
    search: "",
    isLoading: false,
  });

  if (!projectId) {
    return null;
  }

  const handleModalOpening = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const taxesList = await getProjectTaxes(projectId);

      reset({ taxesList });
    } catch (err) {
      onClose(false);
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({ ...prevState, search: e.target.value }));
  };

  const handleRowToggle = ({ data }: DataTableRowToggleParams) => {
    setState(prevState => ({
      ...prevState,
      expandedRows: data as DataTableExpandedRows,
    }));
  };

  const rowExpansionTemplate = (
    data: IProjectTaxesEntity,
    { index }: DataTableRowExpansionTemplate,
  ) => {
    const { columns } = generateTaxColumns({
      control,
      projectIndex: index,
    });

    return (
      <ClientSoulTable
        paginator={false}
        columns={columns}
        data={data.projectTaxes}
        globalFilter={state.search}
        emptyMessage="Nenhum registro encontrado"
        rowsPerPageOptions={[data.projectTaxes.length]}
      />
    );
  };

  const defineTableData = () => {
    const tableData = getValues("taxesList");

    if (state.search) {
      const filteredData = tableData.filter(data => {
        return data.projectTaxes.some(projectTaxData => {
          return projectTaxData.name.toLowerCase().includes(state.search);
        });
      });

      return filteredData;
    }

    return tableData;
  };

  const resetModalStates = () => {
    setState({
      search: "",
      isLoading: false,
      expandedRows: undefined,
    });

    reset({ taxesList: [] });
  };

  const onValid = async (values: ProjectTaxesForm) => {
    if (isDirty) {
      const response = await dialog.fire({
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        title: "Deseja realmente salvar as alterações?",
        text: "As taxas de crédito geradas automaticamente a partir de agora para esse Projeto passarão a utilizar esses valores.",
      });

      if (response.dismiss) {
        return;
      }
    }

    await saveProjectTaxes(values.taxesList, projectId);

    dialog.fire({
      icon: "success",
      title: "Feito!",
      text: "Operação realizada com sucesso!",
    });

    resetModalStates();

    onClose(true);
  };

  const handleCloseModal = async () => {
    if (isDirty) {
      const result = await dialog.fire({
        icon: "question",
        title: "Deseja realmente fechar?",
        html: "Há alterações que ainda não foram salvas e serão perdidas.",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (result.dismiss) {
        return;
      }
    }

    resetModalStates();

    onClose(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={!state.isLoading}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!state.isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerenciar Taxas</h4>
          {!state.isLoading ? (
            <button
              type="button"
              id="btn-tax-cross"
              onClick={handleCloseModal}
              data-testid="btn-tax-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <div className="taxes-header">
              <p>
                Este registro será vinculado ao Projeto: {projectDescription} -
                Competência: {projectCompetencyName}
              </p>
            </div>
            <div className="react-modal-body">
              <form
                className="form-container"
                onSubmit={handleSubmit(onValid)}
                id="company-with-project-taxes-form"
              >
                <div className="table-header">
                  <p>Empresas com taxa de crédito cadastradas</p>
                  <div className="table-filter">
                    <FaSearch />
                    <input
                      value={state.search}
                      placeholder="Pesquisar"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <ClientSoulTable
                  rowHover
                  dataKey="id"
                  columns={mainColumns}
                  data={defineTableData()}
                  responsiveLayout="scroll"
                  tableClassName="main-table"
                  onRowToggle={handleRowToggle}
                  expandedRows={state.expandedRows}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  emptyMessage="Nenhum registro encontrado"
                  rowExpansionTemplate={rowExpansionTemplate}
                />
              </form>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-tax-close"
                onClick={handleCloseModal}
                data-testid="btn-tax-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-tax-save"
                disabled={isSubmitting}
                data-testid="btn-tax-save"
                form="company-with-project-taxes-form"
                className={`form-button ${
                  isDirty && isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando... " : "Salvar "}
                {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
