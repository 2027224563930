import { parse, format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  CompanyGroupEntity,
  ICompanyGroupEntity,
} from "../../domain/entities/companyGroupEntity";
import { ISaveCompanyGroupService } from "../../domain/usecases/saveCompanyGroupUseCase";

export class SaveCompanyGroupService implements ISaveCompanyGroupService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveCompanyGroup(
    companyGroup: ICompanyGroupEntity,
  ): Promise<ICompanyGroupEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups`;

    const companyGroupPayload = CompanyGroupEntity.create(companyGroup);

    if (companyGroupPayload.paymentRequestBlockStartDate) {
      const { paymentRequestBlockStartDate } = companyGroup;

      companyGroupPayload.paymentRequestBlockStartDate = format(
        parse(paymentRequestBlockStartDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      );
    }

    if (companyGroupPayload.paymentRequestBlockEndDate) {
      const { paymentRequestBlockEndDate } = companyGroup;

      companyGroupPayload.paymentRequestBlockEndDate = format(
        parse(paymentRequestBlockEndDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      );
    }

    let response;
    if (companyGroup.id === "") {
      response = await this.api.post<ICompanyGroupEntity>(
        url,
        companyGroupPayload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    } else if (companyGroup.id !== "") {
      response = await this.api.put<ICompanyGroupEntity>(
        url,
        companyGroupPayload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    }

    return new CompanyGroupEntity(response);
  }
}
