import { IAdvTableColumn } from "../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../advTable/domain/entities/panoramaEntity";

export interface IListCostCenterReportPanoramasService {
  listCostCenterReportPanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]>;
}

export interface IListCostCenterReportPanoramasUseCase {
  listCostCenterReportPanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]>;
}

export class ListCostCenterReportPanoramasUseCase
  implements IListCostCenterReportPanoramasUseCase
{
  constructor(
    private listCostCenterReportPanoramasService: IListCostCenterReportPanoramasService,
  ) {}

  listCostCenterReportPanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]> {
    return this.listCostCenterReportPanoramasService.listCostCenterReportPanoramas(
      userId,
      columns,
    );
  }
}
