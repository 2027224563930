import styled from "styled-components";

export const Container = styled.div`
  form {
    padding: 1rem;

    .form-row {
      align-items: center;
      justify-content: center;

      #txt-reason {
        height: 20ch;
      }

      .p-autocomplete {
        width: 100%;
      }

      .invalid-message {
        display: none;
        font-size: 0.8rem;
        margin-top: 0.25rem;
        color: var(--invalid);
      }
    }
  }
`;
