import { IEntity } from "../../../../core/domain/entities/entity";

export enum EUspIncomeStatementReportType {
  expense = 1, // débito
  revenue = 2, // crédito
}

export const uspIncomeStatementReportTypeLang: Record<string, string> = {
  expense: "Despesa",
  revenue: "Receita",
};

export enum EReturnStatus {
  notReturned = 1,
  partiallyReturned = 2,
  fullyReturned = 3,
}

export const returnStatusLang: Record<string, string> = {
  notReturned: "Não devolvido",
  partiallyReturned: "Parcialmente devolvido ",
  fullyReturned: "Totalmente devolvido",
};

export enum EUspIncomeStatementReportStatus {
  open = 1,
  paid = 2,
  canceled = 3,
}

export const uspIncomeStatementReportStatusLang: Record<string, string> = {
  open: "Aberto",
  paid: "Pago",
  canceled: "Cancelado",
};

export enum EDocumentStatus {
  complete = 1,
  incomplete = 2,
}

export const uspIncomeStatementReportDocumentStatusLang: Record<
  string,
  string
> = {
  complete: "Completo",
  incomplete: "Incompleto",
};

export enum EPaymentMethod {
  billet = 1,
  creditCard = 2,
  currentAccountTransfer = 3,
  cash = 4,
  check = 5,
  directDebit = 6,
  pix = 7,
}

export const uspIncomeStatementReportPaymentMethodLang: Record<string, string> =
  {
    billet: "Boleto",
    creditCard: "Cartão de Crédito",
    currentAccountTransfer: "Transferência em conta corrente",
    cash: "Dinheiro",
    check: "Cheque",
    directDebit: "Débito em conta corrente",
    pix: "Pix",
  };

export enum EReportOrigin {
  accountPayable = 1,
  accountReceivable = 2,
  transaction = 3,
  OpeningBalance = 4,
}

export const uspIncomeStatementReportOriginLang = {
  accountPayable: "Contas a Pagar",
  accountReceivable: "Contas a Receber",
  transaction: "Movimentação",
  OpeningBalance: "Saldo inicial",
};

export enum EOriginEntity {
  None,
  Project,
  PaymentAccount,
}

export interface IUspIncomeStatementReportEntity extends IEntity {
  documentNumber: string;
  observation: string;
  description: string;

  serial: string;

  // id do usuario que lancou
  userCreated: string;
  userNameCreated: string;

  userNameModified: string;

  // valor do lancamento
  totalValue: number | string;
  totalValueString: string;

  parcelNumber: number;
  numberOfParcels: number;

  type: EUspIncomeStatementReportType;
  typeDescription: string;

  status: EUspIncomeStatementReportStatus;
  statusDescription: string;

  returnStatus: EReturnStatus;
  returnStatusDescription: string;

  documentStatus: EDocumentStatus;
  documentStatusDescription: string;

  paymentMethod: EPaymentMethod;
  paymentMethodDescription: string;

  originId: string;
  origin: EReportOrigin;
  originDescription: string;

  operationDate: string;
  operationDateString: string;

  issueDate: string;
  issueDateString: string;

  dateCreated: string;
  dateCreatedString: string;

  dateModified: string;
  dateModifiedString: string;

  costCenterId: string;
  costCenterName: string;

  companyGroupName: string;

  companyName: string;

  customerProviderName: string;
  customerProviderCorporationName: string;

  classificationAccountName: string;

  classificationUspName: string;

  competencyName: string;

  paymentAccountId: string;
  paymentAccountName: string;

  projectId: string;
  projectName: string;

  parcelValue: number;
  parcelValueString: string;

  totalParcels: string;

  customerProviderCityState: string;
}

export class UspIncomeStatementReportEntity
  implements IUspIncomeStatementReportEntity
{
  description = "";
  documentNumber = "";
  observation = "";
  serial = "";
  userCreated = "";
  userNameCreated = "";
  userNameModified = "";
  totalValue = "";
  totalValueString = "";
  parcelNumber = 0;
  numberOfParcels = 0;
  type = EUspIncomeStatementReportType.expense;
  typeDescription =
    uspIncomeStatementReportTypeLang[EUspIncomeStatementReportType.expense];

  status = EUspIncomeStatementReportStatus.open;
  statusDescription =
    uspIncomeStatementReportStatusLang[EUspIncomeStatementReportStatus.open];

  returnStatus = EReturnStatus.notReturned;
  returnStatusDescription = "";

  documentStatus = EDocumentStatus.complete;
  documentStatusDescription =
    uspIncomeStatementReportDocumentStatusLang[EDocumentStatus.complete];

  paymentMethod = EPaymentMethod.cash;
  paymentMethodDescription =
    uspIncomeStatementReportPaymentMethodLang[EPaymentMethod.cash];

  originId = "";
  origin = EReportOrigin.accountPayable;
  originDescription = "";

  operationDate = "";
  operationDateString = "";

  issueDate = "";
  issueDateString = "";

  dateCreated = "";
  dateCreatedString = "";

  dateModified = "";
  dateModifiedString = "";

  costCenterId = "";
  costCenterName = "";

  companyName = "";

  companyGroupName = "";

  customerProviderName = "";

  customerProviderCorporationName = "";

  classificationAccountName = "";

  classificationUspName = "";

  competencyName = "";

  paymentAccountId = "";

  paymentAccountName = "";

  projectId = "";
  projectName = "";

  parcelValue = 0;
  parcelValueString = "";

  totalParcels = "";
  customerProviderCityState = "";

  id = "";
  active = false;

  constructor(params?: Partial<IUspIncomeStatementReportEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IUspIncomeStatementReportEntity>) {
    return new UspIncomeStatementReportEntity(params);
  }
}
