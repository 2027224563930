import { useCallback, useMemo, useState } from "react";
import { IAccountPayableAssessmentListItemEntity } from "../../domain/entities/accountPayableAssessmentListItemEntity";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

interface IAssessmentModalState {
  assessmentList: IAccountPayableAssessmentListItemEntity[];
  loading: boolean;
  search: string;
}

export function useAssessmentModal() {
  const [state, setState] = useState<IAssessmentModalState>({
    assessmentList: [],
    loading: true,
    search: "",
  });

  const { useAccountsPayable, handleAssessmentModalClose, ...rest } =
    useAccountsPayablePage();

  const { assessmentModalOpen, contextMenuData } = rest.state;

  const { fetchAccountPayableAssessmentLiist } = useAccountsPayable;

  /** id da conta a pagar a ser devolvida */
  const accountPayableId = useMemo(
    () => contextMenuData?.id,
    [contextMenuData?.id],
  );

  /** Lida com o evento de fechamento emitido pela modal */
  const handleClose = useCallback(() => {
    handleAssessmentModalClose();
  }, [handleAssessmentModalClose]);

  /** Obtem a lista de rateios vinculados a conta selecionada */
  const fetchAssessmentList = useCallback(async () => {
    if (!accountPayableId) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const assessmentList = await fetchAccountPayableAssessmentLiist(
        accountPayableId,
      );

      setState(prevState => ({
        ...prevState,
        assessmentList,
        loading: false,
      }));
    } catch {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [accountPayableId, fetchAccountPayableAssessmentLiist]);

  /** Lida com a inicializacao de estado da modal durante sua abertura */
  const handleModalAfterOpen = useCallback(() => {
    fetchAssessmentList();
  }, [fetchAssessmentList]);

  /** Lida com o reset do estado da modal durante seu fechamento */
  const handleModalAfterClose = useCallback(() => {
    setState({
      assessmentList: [],
      loading: true,
      search: "",
    });
  }, []);

  /** Configuracao de colunas para a listagem dos rateios */
  const columns = useMemo(() => {
    /** Formata um valor number como valor em BRL */
    const formatCurrency = (numVal: number) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(numVal);
    };

    /**
     * Formata um valor number como valor em porcentagem
     * com até 2 casas decimais. Ex.: 9,01
     */
    const formatPercentage = (numVal: number) => {
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
      }).format(numVal);
    };

    /** Renderiza o campo observacao do rateio */
    const renderObservationBody = (
      assessment: IAccountPayableAssessmentListItemEntity,
    ) => {
      return (
        <span className="text-truncate" title={assessment.observation}>
          {assessment.observation}
        </span>
      );
    };

    return [
      {
        header: "Observação",
        field: "observation",
        style: { maxWidth: "440px" },
        body: renderObservationBody,
      },
      {
        header: "Centro de Custo",
        field: "costCenterName",
        style: { width: "140px" },
      },
      {
        header: "Classificação",
        field: "classificationAssessmentName",
        style: { width: "140px" },
      },
      {
        header: "%",
        field: "percentage",
        style: { width: "120px" },
        body: (assessment: IAccountPayableAssessmentListItemEntity) => {
          return `${formatPercentage(assessment.percentage * 100)} %`;
        },
      },
      {
        header: "Valor",
        field: "value",
        style: { width: "140px" },
        body: (assessment: IAccountPayableAssessmentListItemEntity) => {
          return formatCurrency(assessment.value);
        },
      },
    ];
  }, []);

  return {
    assessmentModalOpen,
    handleClose,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    setState,
    columns,
  };
}
