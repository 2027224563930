import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationUspContract } from "../../domain/contracts/getClassificationUspContract";
import { IClassificationUspEntity } from "../../domain/entities/classificationUspEntity";

export class GetClassificationUspService
  implements IGetClassificationUspContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getClassificationUsp(classificationUspId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationsUsp/${classificationUspId}`;

    const response = await this.api.get<IClassificationUspEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const { id, name, active, percentageEmployerInss, companyGroupId } =
      response;

    return {
      id,
      name,
      active,
      companyGroupId,
      percentageEmployerInss,
    };
  }
}
