import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetCostCenterContract } from "../../domain/contracts/getCostCenterContract";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";

export class GetCostCenterService implements IGetCostCenterContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getCostCenter(costCenterId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `CostCenters/${costCenterId}`;

    return this.api.get<ICostCenterEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
