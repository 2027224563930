import { format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetProjectTaxesContract } from "../../domain/contracts/getProjectTaxesContract";
import { IProjectTaxesEntity } from "../../domain/entities/projectTaxesEntity";

export class GetProjectTaxesService implements IGetProjectTaxesContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getProjectTaxes(projectId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Projects/${projectId}/taxes`;

    const response = await this.api.get<IProjectTaxesEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const formattedResponse = response.map(c => {
      const projectTaxes = c.projectTaxes.map(p => ({
        ...p,
        startDate: this.formatDate(p.startDate),
        endDate: this.formatDate(p.endDate) || "Indeterminado",
      }));

      return {
        ...c,
        projectTaxes,
      };
    });

    return formattedResponse;
  }

  private formatDate(date: string) {
    if (!date) {
      return "";
    }

    return format(new Date(date), "dd/MM/yyyy");
  }
}
