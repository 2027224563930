import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface IGetCompanyGroupListService {
  get(activeOnly: boolean, userId: string): Promise<ICompanyGroupEntity[]>;
}

export interface IGetCompanyGroupListUseCase {
  get(activeOnly: boolean, userId: string): Promise<ICompanyGroupEntity[]>;
}

export class GetCompanyGroupListUseCase implements IGetCompanyGroupListUseCase {
  constructor(private getCompanyGroupService: IGetCompanyGroupListService) {}

  get(activeOnly: boolean, userId: string): Promise<ICompanyGroupEntity[]> {
    return this.getCompanyGroupService.get(activeOnly, userId);
  }
}
