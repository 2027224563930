import { format } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { CompanyEntity } from "../../domain/entities/companyEntity";
import { PaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";
import { IPaymentAccountTransactionEntity } from "../../domain/entities/paymentAccountTransactionEntity";
import {
  IPaymentAccountTransactionInputEntity,
  PaymentAccountTransactionInputEntity,
} from "../../domain/entities/paymentAccountTransactionInputEntity";
import { IGetPaymentAccountTransactionService } from "../../domain/usecases/getPaymentAccountTransactionUseCase";
import { IPaymentAccountTransactionAttachmentEntity } from "../../domain/entities/paymentAccountTransactionAttachmentEntity";

export class GetPaymentAccountTransactionService
  implements IGetPaymentAccountTransactionService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getPaymentAccountTransaction(
    paymentAccountTransactionsId: string,
  ): Promise<IPaymentAccountTransactionInputEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/PaymentAccountTransactions/${paymentAccountTransactionsId}`;

    const response = await this.api.get<IPaymentAccountTransactionEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const attachments = await this.getAttachments(paymentAccountTransactionsId);

    const paymentAccountTransactionsEntity =
      new PaymentAccountTransactionInputEntity({
        id: response.id,
        companyGroupId: response.companyGroupId,
        company: new CompanyEntity({
          id: response.companyId,
        }),
        paymentAccountOrigin: new PaymentAccountEntity({
          id: response.paymentAccountOriginId,
        }),
        paymentAccountDestination: new PaymentAccountEntity({
          id: response.paymentAccountDestinationId,
        }),
        transactionDate: format(new Date(response.transactionDate), "ddMMyyyy"),
        value: response.value,
        description: response.description,
        active: response.active,
        attachments,
      });

    return paymentAccountTransactionsEntity;
  }

  private getAttachments(id: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `PaymentAccountTransactions/${id}/Attachments`;
    return this.api.get<IPaymentAccountTransactionAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
