import { ICityResponse } from "../../data/models/cityResponse";

export interface IGetCityByIbgeUseCase {
  getCityByIbge(ibge: string): Promise<ICityResponse>;
}

export interface IGetCityByIbgeService {
  getCityByIbge(ibge: string): Promise<ICityResponse>;
}

export class GetCityByIbgeUseCase implements IGetCityByIbgeUseCase {
  constructor(private getCityByIbgeService: IGetCityByIbgeService) {}

  getCityByIbge(ibge: string) {
    return this.getCityByIbgeService.getCityByIbge(ibge);
  }
}
