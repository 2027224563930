import { Column, ColumnProps } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import {
  PaginatorCurrentPageReportOptions,
  PaginatorTemplateOptions,
} from "primereact/paginator";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode } from "primereact/api";
import { Container } from "./style";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { IImportationMatchesBankEntity } from "../../../domain/entities/importationMatchesBankEntity";

export interface IBanksGridData {
  list: IImportationMatchesBankEntity[];
  selected?: IImportationMatchesBankEntity[];
}

export interface BanksGridProps {
  columns: ColumnProps[];
  tableData: IBanksGridData;
  onSelect?: (selection: IImportationMatchesBankEntity[]) => void;
}

export function BanksGrid(props: BanksGridProps) {
  const { tableData, columns, onSelect } = props;

  /** Estado que controlam o input de filtro global */
  const [search, setSearch] = useState("");

  /** Controla a quantidade de linhas mostradas na tabela */
  const [rows, setRows] = useState<number>(10);

  /** Indica quando a tabela deve exibir apenas os selecionados. */
  const [selectedOnly, setSelectedOnly] = useState(false);

  /** Armazena as linhas selecionadas */
  const [selection, setSelection] = useState<IImportationMatchesBankEntity[]>(
    tableData?.selected ?? [],
  );

  /** Objeto de filtros que indica os valores dos filtros atuais. */
  const filters = useMemo(
    () => ({
      global: { value: search, matchMode: FilterMatchMode.CONTAINS },
    }),
    [search],
  );

  /**
   * Atualiza a quantidade de linhas a serem mostradas na tabela.
   */
  const handlePerPageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const numberRows = Number(e.target.value);
      setRows(numberRows);
    },
    [],
  );

  /**
   * Lida com o evento de seleção de linhas. Através desse evento, apenas os
   * IDs das classificações são definidos no formulário.
   */
  const handleSelection = useCallback(
    (e: DataTableSelectionChangeParams) => {
      setSelection(e.value);
      onSelect?.(e.value);
    },
    [onSelect],
  );

  /**
   * Template que exibe um texto informando a quantidade de páginas.
   */
  const currentPageReportTemplate = useCallback(
    ({ currentPage, totalPages }: PaginatorCurrentPageReportOptions) => {
      return (
        <div className="current-page-report">
          Exibindo página {totalPages ? currentPage : 1} de {totalPages || 1}
        </div>
      );
    },
    [],
  );

  /**
   * Template da paginação a ser exibido.
   */
  const paginatorTemplate: PaginatorTemplateOptions = useMemo(
    () => ({
      layout:
        "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      PageLinks: "",
      PrevPageLink: "",
      LastPageLink: "",
      NextPageLink: "",
      FirstPageLink: "",
      JumpToPageInput: "",
      CurrentPageReport: currentPageReportTemplate,
    }),
    [currentPageReportTemplate],
  );

  return (
    <Container>
      <div className="table-header">
        <div className="table-perPage">
          <span>Mostrar</span>
          <select defaultValue="10" onChange={handlePerPageChange}>
            {[10, 25, 50, 100].map(value => {
              return (
                <option value={value} key={`${value}-rows`}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <label className="table-selectedOnly">
          <Checkbox
            checked={selectedOnly}
            onChange={e => {
              setSelectedOnly(e.checked);
            }}
          />
          Mostrar apenas selecionados
        </label>
        <InputSearch
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <DataTable
        rowHover
        paginator
        rows={rows}
        dataKey="id"
        removableSort
        filters={filters}
        scrollHeight="400px"
        selection={selection}
        metaKeySelection={false}
        selectionMode="multiple"
        responsiveLayout="scroll"
        onSelectionChange={handleSelection}
        paginatorTemplate={paginatorTemplate}
        emptyMessage="Nenhum registro encontrado"
        value={selectedOnly ? selection : tableData.list}
      >
        {columns.map(column => (
          <Column {...column} key={column.field} />
        ))}
      </DataTable>
    </Container>
  );
}
