import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListAccountsPayableContract } from "../../domain/contracts/listAccountsPayableContract";
import {
  EFuspSendingStatus,
  IAccountPayableListItemEntity,
  fuspSendingStatusLang,
} from "../../domain/entities/accountPayableListItemEntity";

export class ListAccountsPayableService
  implements IListAccountsPayableContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listAccountsPayable(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IAccountPayableListItemEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        totalValueString: "totalValue",
        operationValueString: "operationValue",
        operationDateString: "operationDate",
        issueDateString: "issueDate",
        documentStatusDescription: "documentStatus",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        percentageString: "percentage",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        typeDescription: "type",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsOptions = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/CompanyGroups/${companyGroupId}/AccountsPayable?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IAccountPayableListItemEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return {
      ...response,
      data: response.data.map(accountPayableListEntity => {
        const { fuspSendingStatus } = accountPayableListEntity;

        const statusKey =
          fuspSendingStatus !== null && fuspSendingStatus !== undefined
            ? (EFuspSendingStatus[
                fuspSendingStatus
              ] as keyof typeof EFuspSendingStatus)
            : null;

        const fuspSendingStatusDescription =
          statusKey !== null ? fuspSendingStatusLang[statusKey] : "";

        return { ...accountPayableListEntity, fuspSendingStatusDescription };
      }),
    };
  }
}
