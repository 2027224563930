import { EReportOrigin } from "../entities/costCenterReportEntity";

export interface IGetStorageFilebyIdService {
  getStorageFilebyId(
    storageFileId: string,
    origin: EReportOrigin,
  ): Promise<string>;
}

export interface IGetStorageFilebyIdUseCase {
  getStorageFilebyId(
    storageFileId: string,
    origin: EReportOrigin,
  ): Promise<string>;
}

export class GetStorageFilebyIdUseCase implements IGetStorageFilebyIdUseCase {
  constructor(private getStorageFilebyIdService: IGetStorageFilebyIdService) {}

  getStorageFilebyId(
    storageFileId: string,
    origin: EReportOrigin,
  ): Promise<string> {
    return this.getStorageFilebyIdService.getStorageFilebyId(
      storageFileId,
      origin,
    );
  }
}
