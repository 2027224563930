import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetSolicitImportTemplateContract } from "../../domain/contracts/getSolicitImportTemplateContract";

export class GetSolicitImportTemplateService
  implements IGetSolicitImportTemplateContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getSolicitImportTemplate() {
    const url = "/PaymentRequestImportations/Template";
    const userEntity = this.getUserLocalService.get();

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
