import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  .default-button {
    background-color: var(--soul-red-button);
    border-color: var(--soul-red-button);
  }
`;
