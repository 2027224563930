import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { ChangeEvent, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { ProviderFormModalState } from "../ProviderFormModal";
import { OptionalLabel } from "./styles";
import { ProviderType } from "../../../domain/entities/providerEntity";

interface BankFormProps {
  useProvider: MakeProvider;
  bankDataRequired: boolean;
  banks: ProviderFormModalState["enums"]["banks"];
  bankAccountTypes: ProviderFormModalState["enums"]["bankAccountTypes"];
}

export function BankForm(props: BankFormProps) {
  const { bankDataRequired, bankAccountTypes, banks } = props;

  const bankOptions = useMemo(() => {
    return banks.map(bankEnum => {
      const bankCode = bankEnum.key.toString().padStart(3, "0");
      const value = `${bankCode} - ${bankEnum.value}`;

      return {
        value,
        key: bankEnum.key,
      };
    });
  }, [banks]);

  const {
    watch,
    register,
    resetField,
    formState: { errors },
  } = useFormContext<ProviderForm>();

  const type = watch("type");
  const isBankAccountSelected = Boolean(watch("bankAccountType"));

  const isRequired = bankDataRequired || isBankAccountSelected;
  const showDocumentHolder = type === ProviderType.legalPerson;

  const onChangeBankAccountType = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      resetField("bank", { defaultValue: "" });
      resetField("bankBranch", { defaultValue: "" });
      resetField("bankAccount", { defaultValue: "" });
      resetField("documentHolder", { defaultValue: "" });
      resetField("bankBranchDigit", { defaultValue: "" });
      resetField("bankAccountDigit", { defaultValue: "" });
    }
  };

  if (typeof type !== "number") {
    return null;
  }

  return (
    <div className="form-section">
      <p>Dados Bancários</p>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Tipo de conta bancária{" "}
            {bankDataRequired ? null : <small>(opcional)</small>}
          </span>
          <select
            className={errors.bankAccountType ? "isInvalid" : ""}
            {...register("bankAccountType", {
              required: bankDataRequired,
              onChange: onChangeBankAccountType,
            })}
          >
            <option value="">Tipo de conta bancária</option>
            {bankAccountTypes.map(bankAccountEnum => {
              return (
                <option key={bankAccountEnum.key} value={bankAccountEnum.key}>
                  {bankAccountEnum.value}
                </option>
              );
            })}
          </select>
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.bankAccountType?.type === "required"}
          />
        </label>
        {showDocumentHolder ? (
          <label className="col-6 form-control">
            <span>
              Documento do Titular <small>(opcional)</small>
            </span>
            <input
              disabled={!isBankAccountSelected}
              placeholder="Documento do Titular da Conta"
              {...register("documentHolder", { shouldUnregister: true })}
            />
          </label>
        ) : null}
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>Banco {isRequired ? null : <small>(opcional)</small>}</span>
          <Controller
            name="bank"
            rules={{ required: isRequired }}
            render={({ field, fieldState }) => {
              const onChange = (event: DropdownChangeParams) => {
                field.onChange(event.value);
              };

              return (
                <>
                  <Dropdown
                    filter
                    showClear
                    {...field}
                    filterBy="value"
                    optionValue="key"
                    optionLabel="value"
                    onChange={onChange}
                    options={bankOptions}
                    placeholder="Selecione um banco"
                    disabled={!isBankAccountSelected}
                    className={fieldState.error ? "p-invalid" : ""}
                  />
                  <InvalidFeedback
                    message="Este campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-2 form-control">
          <span>Agência</span>
          <input
            placeholder="Agência"
            disabled={!isBankAccountSelected}
            className={errors.bankBranch ? "isInvalid" : ""}
            {...register("bankBranch", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.bankBranch?.type === "required"}
          />
        </label>
        <label className="col-1 form-control">
          <span>DV</span>
          <input
            maxLength={1}
            placeholder="DV"
            disabled={!isBankAccountSelected}
            {...register("bankBranchDigit")}
          />
          <OptionalLabel>(opcional)</OptionalLabel>
        </label>
        <label className="col-2 form-control">
          <span>Conta</span>
          <input
            placeholder="Conta"
            disabled={!isBankAccountSelected}
            className={errors.bankAccount ? "isInvalid" : ""}
            {...register("bankAccount", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.bankAccount?.type === "required"}
          />
        </label>
        <label className="col-1 form-control">
          <span>DV</span>
          <input
            maxLength={1}
            placeholder="DV"
            disabled={!isBankAccountSelected}
            className={errors?.bankAccountDigit ? "isInvalid" : ""}
            {...register("bankAccountDigit", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.bankAccountDigit?.type === "required"}
          />
        </label>
      </div>
    </div>
  );
}
