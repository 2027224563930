import { AES, enc } from "crypto-js";

interface IDecryptService {
  decrypt(cyphertext: string): string;
}

export class DecryptService implements IDecryptService {
  private cryptoJSKey: string = process.env.REACT_APP_CRYPTOJS_KEY ?? "";

  decrypt(cyphertext: string): string {
    try {
      const replaced = cyphertext.replace(/mDfkSla/g, "/");
      const bytes = AES.decrypt(replaced, this.cryptoJSKey);
      return bytes.toString(enc.Utf8);
    } catch (error) {
      return "";
    }
  }
}
