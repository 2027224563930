import BigNumber from "bignumber.js";
import { ColumnProps } from "primereact/column";
import { DataTable, DataTableRowMouseEventParams } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IRelationshipFilterOption } from "../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../data/models/serverSideResponseModel";
import {
  IAssessmentFormEntity,
  IGenericAssessmentEntity,
  IGenericAssessmentFormEntity,
} from "../../domain/entities/genericAssessmentEntitty";
import { ISearchParams } from "../../domain/entities/searchParams";
import { ITypeaheadOption } from "../../domain/entities/typeaheadOption";
import { ButtonDelete } from "../components/ButtonDelete";
import { useSoulDialog } from "./useSoulDialog";

BigNumber.set({ DECIMAL_PLACES: 15 });

type IAssessmentGridFieldEntity = FieldArrayWithId<
  IAssessmentFormEntity,
  "assessments",
  "gridId"
>;

interface IAssessmentsGridState {
  search: string;
  isModalOpen: boolean;
  isRecalculating: boolean;
  editAssessmentIndex: number | null;
}

export interface IAssessmentsGridProps<T extends IGenericAssessmentEntity> {
  assessments: T[];
  readonly?: boolean;
  accountValue: number;
  emptyMessage?: string;
  shouldShowCalculateAssessmentButton: boolean;
  onAssessmentsChange(assessmentsForm: T[]): void;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<ITypeaheadOption[]>>;
  listActiveCostCenters(
    companyGroupId: string,
  ): Promise<IRelationshipFilterOption<object>[]>;
  searchClassificationAssessment(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<ITypeaheadOption[]>>;
  observationMaxLength?: number;
}

export function useAssessmentsGrid<T extends IGenericAssessmentEntity>(
  props: IAssessmentsGridProps<T>,
) {
  const {
    readonly,
    assessments,
    accountValue,
    onAssessmentsChange,
    listActiveCostCenters,
  } = props;

  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /**
   * Os dados dos rateios originais precisam ser armazenados para preservar
   * as precisões dos valores e das porcentagens presentes na lista de rateios
   * advindos do servidor.
   */
  const originalSeverData = useRef(assessments);

  const { control, handleSubmit } = useForm<IAssessmentFormEntity>({
    mode: "all",
    defaultValues: {
      assessments: assessments.map(assessmentObject => ({
        ...assessmentObject,
        percentageMask: new BigNumber(assessmentObject.percentage)
          .multipliedBy(100)
          .decimalPlaces(2)
          .toNumber(),
      })),
    },
  });

  const { fields, remove, append, replace, update } = useFieldArray({
    control,
    keyName: "gridId",
    name: "assessments",
  });

  const [state, setState] = useState<IAssessmentsGridState>({
    search: "",
    isModalOpen: false,
    isRecalculating: false,
    editAssessmentIndex: null,
  });

  const tooltipRef = useRef<Tooltip | null>(null);
  const activeCostCentersListRef = useRef<IRelationshipFilterOption[]>([]);

  const defineRowClassName = (data: IAssessmentGridFieldEntity) => {
    return `assessment-row row-id-${data.gridId}`;
  };

  const fetchActiveCostCenters = async () => {
    setState(prevState => ({ ...prevState, isRecalculating: true }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const activeCostCenterList = await listActiveCostCenters(companyGroupId);

      activeCostCentersListRef.current = activeCostCenterList;
    } finally {
      setState(prevState => ({ ...prevState, isRecalculating: false }));
    }
  };

  const checkIfIsAnyCostCenterInactive = async () => {
    if (!activeCostCentersListRef.current.length) {
      try {
        await fetchActiveCostCenters();
      } catch {
        return true;
      }
    }

    const activeCostCenters = activeCostCentersListRef?.current;

    const inactiveCostCenters = fields
      .filter(assessmentField => {
        return !activeCostCenters.some(costCenter => {
          return costCenter.rawValue === assessmentField.costCenter?.rawValue;
        });
      })
      .map(assessmentField => assessmentField.costCenter);

    if (inactiveCostCenters.length) {
      await dialog.fire({
        icon: "warning",
        title: "Aviso!",
        html: (
          <>
            Não é possível fazer o recalculo dos rateios pois os seguintes
            centros de custo estão inativos:
            <br />
            <br />
            <ul>
              {inactiveCostCenters.map((costCenter, index) => {
                const key = `${costCenter?.label}-${index}`;
                return (
                  <li key={key}>
                    <b title={costCenter?.label || ""}>
                      {costCenter?.label || ""}
                    </b>
                  </li>
                );
              })}
            </ul>
          </>
        ),
      });

      return true;
    }

    return false;
  };

  const calculateRemainingValueToAssess = (
    assessmentsToCalculate: IGenericAssessmentFormEntity[],
  ) => {
    const totalAssessmentsValue = assessmentsToCalculate.reduce((acc, curr) => {
      return acc.plus(curr.value);
    }, new BigNumber(0));

    return new BigNumber(accountValue).minus(totalAssessmentsValue);
  };

  const handleSubmitForm = useCallback(() => {
    handleSubmit(gridForm => {
      const assessmentsForm = gridForm.assessments.map(assessmentObject => {
        const entries = Object.entries(assessmentObject);

        const mappedEntries = new Map(entries);

        mappedEntries.delete("percentageMask");

        return Object.fromEntries(mappedEntries) as Omit<
          IGenericAssessmentFormEntity,
          "percentageMask"
        >;
      });

      onAssessmentsChange(assessmentsForm as T[]);
    })();
  }, [handleSubmit, onAssessmentsChange]);

  const recalculateAssessments = async () => {
    const newAssessmentsList = fields.map(assessmentRow => {
      const newValue = new BigNumber(accountValue)
        .multipliedBy(assessmentRow.percentageMask)
        .dividedBy(100)
        .decimalPlaces(2);

      const newPercentage = newValue.dividedBy(accountValue);

      return {
        ...assessmentRow,
        value: newValue.toJSON(),
        percentage: newPercentage.toJSON(),
      };
    });

    const remainingValue = calculateRemainingValueToAssess(newAssessmentsList);

    if (!remainingValue.isZero()) {
      const lastIndex = newAssessmentsList.length - 1;

      const valueSum = new BigNumber(newAssessmentsList[lastIndex].value)
        .plus(remainingValue)
        .decimalPlaces(2);

      const newValue = valueSum.toJSON();
      const newPercentage = valueSum.dividedBy(accountValue);
      const newPercentaMask = newPercentage.multipliedBy(100).decimalPlaces(2);

      newAssessmentsList[lastIndex].value = newValue;
      newAssessmentsList[lastIndex].percentage = newPercentage.toJSON();
      newAssessmentsList[lastIndex].percentageMask = newPercentaMask.toNumber();
    }

    replace(newAssessmentsList);

    handleSubmitForm();
  };

  const handleRowMouseEnter = (rowData: DataTableRowMouseEventParams) => {
    const target = rowData?.originalEvent?.currentTarget;
    tooltipRef?.current?.updateTargetEvents(target);
  };

  const handleDataTableRef = (originalRef: DataTable | null) => {
    ReactTooltip.rebuild();

    if (!originalRef) {
      return;
    }

    const tableElement = originalRef.getTable();
    const htmlCollection = tableElement.children;
    const [, tbodyElement] = Array.from(htmlCollection);
    const rowNodesList = tbodyElement.querySelectorAll("tr");
    const rowsList = Array.from(rowNodesList);

    rowsList.map(rowElement => {
      tooltipRef?.current?.updateTargetEvents(rowElement);

      const classFilteredList = rowElement.className
        .split(" ")
        .filter(className => {
          return className.includes("row-id");
        })
        .map(className => {
          return className.replace("row-id-", "");
        });

      if (!classFilteredList.length) {
        return null;
      }

      const currentAssessment = fields.find(assessment => {
        return assessment.gridId === classFilteredList[0];
      });

      if (!currentAssessment) {
        return null;
      }

      const tooltipText = currentAssessment?.observation || "";

      const elementDataSetObject = rowElement.dataset;

      elementDataSetObject.prTooltip = tooltipText;
      elementDataSetObject.prPosition = "top";

      return null;
    });
  };

  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({ ...prevState, search: event.target.value }));
  };

  const handleRecalculateAssessmentButtonClick = async () => {
    const result = await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      html: (
        <>
          Os valores dos rateios serão atualizados de acordo com a porcentagem
          de cada um.
          <br />É indicado que seja conferido todos valores de rateio após a
          operação.
        </>
      ),
    });

    if (result.dismiss) {
      return;
    }

    const isAnyCostCenterInactive = await checkIfIsAnyCostCenterInactive();

    if (isAnyCostCenterInactive) {
      return;
    }

    await recalculateAssessments();

    dialog.fire({
      icon: "success",
      title: "Feito!",
      html: <>Recálculo de rateios feito com sucesso.</>,
    });
  };

  const handleAddAssessmentButtonClick = () => {
    const remainingValue = calculateRemainingValueToAssess(fields);

    if (remainingValue.isZero() || remainingValue.isNegative()) {
      dialog.fire({
        icon: "warning",
        title: "Atenção",
        confirmButtonText: "Ok",
        text: "Os valores de rateio já alcançaram o limite de valor.",
      });

      return;
    }

    if (!accountValue) {
      dialog.fire({
        icon: "warning",
        title: "Atenção",
        text: 'Para adicionar um rateio, preencha o campo "Valor".',
      });

      return;
    }

    setState(prevState => ({
      ...prevState,
      isModalOpen: true,
    }));
  };

  const handleAssessmentFormModalRequestClose = async () => {
    if (state.editAssessmentIndex !== null) {
      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        showCancelButton: true,
        cancelButtonText: "Não, continuar editando",
        confirmButtonText: "Sim, fechar sem salvar",
        html: (
          <>
            Você realmente deseja fechar os rateios <br />
            sem salvar suas alterações?
          </>
        ),
      });

      if (result.isDenied || result.isDismissed) {
        return;
      }
    }

    setState(prevState => ({
      ...prevState,
      isModalOpen: false,
      editAssessmentIndex: null,
    }));
  };

  const handleDeleteButtonClick = useCallback(
    async (assessmentIndex: number) => {
      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        html: (
          <>
            O rateio será excluído.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      remove(assessmentIndex);

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: <>Rateio excluído com sucesso!</>,
      });

      handleSubmitForm();
    },
    [dialog, remove, handleSubmitForm],
  );

  const handleNewAssessmentsValues = (
    newValues: IAssessmentGridFieldEntity,
    editIndex: number,
  ): IAssessmentGridFieldEntity => {
    const newValuesCopy = { ...newValues };

    const originalData = originalSeverData.current[editIndex];

    const isValueEqual = new BigNumber(originalData?.value || 0).isEqualTo(
      newValues.value,
    );

    if (isValueEqual) {
      newValuesCopy.value = originalData.value;
      newValuesCopy.percentage = originalData.percentage;
      newValuesCopy.percentageMask = new BigNumber(originalData.percentage)
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber();
    }

    return newValuesCopy;
  };

  const handleFormModalSubmit = async (
    modalAssessments: IAssessmentGridFieldEntity[],
  ) => {
    let successMessage = "";

    if (state.editAssessmentIndex !== null) {
      const newValues = modalAssessments[0];
      const editIndex = state.editAssessmentIndex;

      const editedAssessment = handleNewAssessmentsValues(newValues, editIndex);

      update(state.editAssessmentIndex, editedAssessment);

      successMessage = "Rateio atualizado com sucesso!";
    } else {
      append(modalAssessments);
      successMessage = "Rateios adicionados com sucesso!";
    }

    handleSubmitForm();

    await dialog.fire({
      icon: "success",
      title: "Feito!",
      html: successMessage,
    });

    setState(prevState => ({
      ...prevState,
      isModalOpen: false,
      editAssessmentIndex: null,
    }));
  };

  const columns = useMemo<ColumnProps[]>(() => {
    const brlFmt = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format;

    const percentageFmt = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format;

    const renderClsAssessmentBody = (data: IAssessmentGridFieldEntity) => {
      return (
        <span title={data.classificationAssessment?.label}>
          {data.classificationAssessment?.label || ""}
        </span>
      );
    };

    const renderCostCenterBody = (data: IAssessmentGridFieldEntity) => {
      return (
        <span title={data.costCenter?.label}>
          {data.costCenter?.label || ""}
        </span>
      );
    };

    const renderValueBody = (data: IAssessmentGridFieldEntity) => {
      const formattedValue = brlFmt(Number(data?.value || "0"));

      return <span title={formattedValue}>{formattedValue}</span>;
    };

    const renderPercentage = (data: IAssessmentGridFieldEntity) => {
      const formattedPercentage = percentageFmt(data?.percentageMask || 0);

      return <span title={formattedPercentage}>{formattedPercentage}%</span>;
    };

    const renderActions = (data: IAssessmentGridFieldEntity) => {
      const assessmentIndex = fields.findIndex(a => a.gridId === data.gridId);

      const handleDeleteAssessmentButtonClick = () => {
        handleDeleteButtonClick(assessmentIndex);
      };

      const handleEditAssessmentButtonClick = () => {
        setState(prevState => ({
          ...prevState,
          isModalOpen: true,
          editAssessmentIndex: assessmentIndex,
        }));
      };

      return (
        <div className="table-actionbar">
          <ButtonDelete
            disabled={readonly}
            data-tip="Excluir rateio"
            className="outline-button tool"
            onClick={handleDeleteAssessmentButtonClick}
            id={`btn-del-acc-pay-att-${assessmentIndex}`}
            data-testid={`btn-del-acc-pay-att-${assessmentIndex}`}
          />
          <button
            type="button"
            disabled={readonly}
            data-tip="Editar rateio"
            className="outline-button tool"
            onClick={handleEditAssessmentButtonClick}
            id={`btn-edit-acc-pay-att-${assessmentIndex}`}
            data-testid={`btn-edit-acc-pay-att-${assessmentIndex}`}
          >
            <FaEdit />
          </button>
        </div>
      );
    };

    return [
      {
        header: "Classificação",
        field: "classificationAssessment.label",
        body: renderClsAssessmentBody,
      },
      {
        header: "Centro de Custo",
        field: "costCenter.label",
        body: renderCostCenterBody,
      },
      {
        header: "Valor",
        field: "formattedValue",
        body: renderValueBody,
      },
      {
        header: "%",
        field: "formattedPercentage",
        body: renderPercentage,
      },
      {
        header: "",
        field: "actions",
        body: renderActions,
        excludeGlobalFilter: true,
      },
    ];
  }, [fields, handleDeleteButtonClick, readonly]);

  return {
    state,
    fields,
    columns,
    tooltipRef,
    defineRowClassName,
    handleDataTableRef,
    handleRowMouseEnter,
    handleFormModalSubmit,
    handleInputSearchChange,
    handleAddAssessmentButtonClick,
    handleAssessmentFormModalRequestClose,
    handleRecalculateAssessmentButtonClick,
  };
}
