import { ICheckDocumentEntity } from "../entities/customerEntity";

export interface ICheckCustomerCnpjUseCase {
  checkCnpj(cnpj: string, id?: string): Promise<ICheckDocumentEntity>;
}

export interface ICheckCustomerCnpjService {
  checkCnpj(cnpj: string, id?: string): Promise<ICheckDocumentEntity>;
}

export class CheckCustomerCnpjUseCase implements ICheckCustomerCnpjUseCase {
  constructor(private checkCustomerCnpjService: ICheckCustomerCnpjService) {}

  checkCnpj(cnpj: string, id?: string) {
    return this.checkCustomerCnpjService.checkCnpj(cnpj, id);
  }
}
