import { IClassificationUspEntity } from "../entities/classificationUspEntity";

export interface IToggleClassificationUspService {
  toggleClassificationUsp(id: string): Promise<IClassificationUspEntity>;
}

export interface IToggleClassificationUspUseCase {
  toggleClassificationUsp(id: string): Promise<IClassificationUspEntity>;
}

export class ToggleClassificationUspUseCase
  implements IToggleClassificationUspUseCase
{
  constructor(
    private toggleClassificationUsService: IToggleClassificationUspService,
  ) {}

  toggleClassificationUsp(id: string): Promise<IClassificationUspEntity> {
    return this.toggleClassificationUsService.toggleClassificationUsp(id);
  }
}
