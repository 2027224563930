import { useCallback, useState } from "react";
import { parse, isEqual, isBefore, isAfter } from "date-fns";
import { useForm } from "react-hook-form";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";

export interface IGenerateFeeForm {
  startDate: string;
  endDate: string;
}

export function useGenerateFeeModal() {
  const { useAccountsPayable, state, closeGenerateFeeModal } =
    useAccountsPayablePage();

  const { generateFeeModalOpen } = state;

  const { getFeeReport } = useAccountsPayable;

  const dialog = useSoulDialog();
  const asyncDebounce = useDebounceTimeAsync();

  const [progressModalState, setProgressModalState] = useState({
    loaded: 0,
    total: 0,
    isOpen: false,
  });

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const formProps = useForm({
    defaultValues: {
      startDate: "",
      endDate: "",
    },
    mode: "all",
  });

  const { watch, reset } = formProps;

  const endDateStr = watch("endDate");
  const startDateStr = watch("startDate");

  /** Lida com o evento de fechamento emitido pela modal */
  const handleClose = useCallback(() => {
    reset({});
    closeGenerateFeeModal();
  }, [closeGenerateFeeModal, reset]);

  /**
   * Lida com o progresso do download. Caso o download esteja completo, finaliza
   * o fluxo.
   */
  const handleDownloadProgress = useCallback(
    async (loaded: number, total: number) => {
      setProgressModalState(old => ({
        ...old,
        total,
        loaded,
      }));
      if (loaded / total < 1) {
        return;
      }
      await asyncDebounce(1200);
      setProgressModalState({
        total: 0,
        loaded: 0,
        isOpen: false,
      });
      dialog.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Download concluído.",
      });
    },
    [asyncDebounce, dialog],
  );

  /** Realiza a requisição de geração de impostos */
  const generateFeeReport = useCallback(
    async (values: IGenerateFeeForm) => {
      setProgressModalState(old => ({
        ...old,
        isOpen: true,
      }));
      try {
        getFeeReport(values, id, handleDownloadProgress);
      } catch {
        setProgressModalState({
          loaded: 0,
          total: 0,
          isOpen: false,
        });
      }
    },
    [getFeeReport, id, handleDownloadProgress],
  );

  /** Valida o campo de data inicial */
  const validateStartDate = useCallback(
    (value: string) => {
      if (value === endDateStr) {
        return true;
      }

      if (value && endDateStr) {
        const initialDate = parse(value, "ddMMyyyy", new Date());
        const endDate = parse(endDateStr, "ddMMyyyy", new Date());

        if (isEqual(initialDate, endDate)) {
          return true;
        }

        return isBefore(initialDate, endDate);
      }

      return true;
    },
    [endDateStr],
  );

  /** Valida o campo de data final */
  const validateEndDate = useCallback(
    (value: string) => {
      if (value === startDateStr) {
        return true;
      }

      if (startDateStr && value) {
        const initialDate = parse(startDateStr, "ddMMyyyy", new Date());
        const endDate = parse(value, "ddMMyyyy", new Date());

        if (isEqual(initialDate, endDate)) {
          return true;
        }

        return isAfter(endDate, initialDate);
      }

      return true;
    },
    [startDateStr],
  );

  return {
    formProps,
    handleClose,
    validateEndDate,
    validateStartDate,
    generateFeeReport,
    progressModalState,
    generateFeeModalOpen,
  };
}
