import { IGetClassificationAccountIssueTypesContract } from "../../domain/contracts/getClassificationAccountIssueTypesContract";
import { IEnum } from "../../domain/entities/enum";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

export class GetClassificationAccountIssueTypesService
  implements IGetClassificationAccountIssueTypesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getClassificationAccountIssueTypes() {
    const userEntity = this.getUserLocalService.get();
    const url = "/Enums/ClassificationAccountIssueTypes";

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
