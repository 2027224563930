import { SoulRoutes } from "../../../admin/domain/entities/soulRoutes";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../core/presentation/hooks/useUserLocal";
import { LinkCardProps } from "../components/LinkCard";

export function useDashboardLinks(): LinkCardProps[] {
  const { user } = useUserLocal();

  const dashboardLinks = Object.values(SoulRoutes)
    .filter(route => {
      return !!route?.dashboard && route.profile.includes(user.profile);
    })
    .sort((a, b) => {
      return (a.dashboard?.order || 0) < (b.dashboard?.order || 0) ? -1 : 1;
    })
    .map(route => {
      const isPaymentRequest = route.path.includes("paymentRequest");
      const isRequesterOrManager = [
        EUserProfile.manager,
        EUserProfile.requester,
      ].includes(user.profile);

      const shouldHideDropdown = isPaymentRequest && !isRequesterOrManager;

      return {
        icon: route.icon,
        path: route.path,
        title: route.title,
        color: route.dashboard?.color || "",
        dropdown: shouldHideDropdown ? undefined : route.dashboard?.dropdown,
      };
    });

  return dashboardLinks;
}
