import { addSeconds, format } from "date-fns";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IExportSheetFealqErrorEntity } from "../../domain/entities/exportSheetFealqErrorEntity";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { ProgressModalContent } from "../../../../../core/presentation/components/Modals/ProgressModalContent";

export interface IExportSheetFealqFormFieldValues {
  startDate: string;
  endDate: string;
}

interface IExportSheetFealqFormModalState {
  loading: boolean;
  submitting: boolean;
  lastExportedUserName: string;
}

export function useExportSheetFealqFormModal() {
  const [state, setState] = useState<IExportSheetFealqFormModalState>({
    loading: true,
    submitting: false,
    lastExportedUserName: "",
  });

  const formProps = useForm<IExportSheetFealqFormFieldValues>({
    defaultValues: {
      startDate: "",
      endDate: "",
    },
    mode: "all",
  });

  const { useAccountsPayable, handleExportSheetFealqFormModalClose, ...rest } =
    useAccountsPayablePage();

  const { exportSheetFealqFormModalOpen } = rest.state;

  const { fetchLastFealqSheetExported, exportSheetFealq } = useAccountsPayable;

  const finalDateRef = useRef(new Date());
  const intervalRef = useRef<NodeJS.Timer>();

  /** Responsavel por atualizar a data final da exportacao com incremento de 1s */
  const updateFinalDate = useCallback(() => {
    finalDateRef.current = addSeconds(finalDateRef.current, 1);

    const endDate = format(finalDateRef.current, "ddMMyyyy HH:mm:ss");

    formProps.setValue("endDate", endDate);
  }, [formProps]);

  /** Inicia o interval que atualiza a data final de 1 em 1s */
  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(() => updateFinalDate(), 1000);
  }, [updateFinalDate]);

  /** Interrompe o interval que atualiza a data final */
  const endInterval = useCallback(() => {
    clearInterval(intervalRef.current as unknown as number);
  }, []);

  /** Obtem os dados da ultima vez que a planilha FEALQ foi exportada */
  const fetchLastFealqSheetExportedData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const { lastExportedDate, currentDate, lastExportedUserName } =
        await fetchLastFealqSheetExported();

      const startDate = format(new Date(lastExportedDate), "ddMMyyyy HH:mm:ss");
      const endDate = format(new Date(currentDate), "ddMMyyyy HH:mm:ss");

      finalDateRef.current = new Date(currentDate);

      formProps.setValue("startDate", startDate);
      formProps.setValue("endDate", endDate);

      setState(prevState => ({
        ...prevState,
        loading: false,
        lastExportedUserName,
      }));

      startInterval();
    } catch {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [fetchLastFealqSheetExported, formProps, startInterval]);

  /** Lida com a inicializacao de estado da modal durante sua abertura */
  const handleModalAfterOpen = useCallback(() => {
    fetchLastFealqSheetExportedData();
  }, [fetchLastFealqSheetExportedData]);

  /** Lida com o reset do estado da modal durante seu fechamento */
  const handleModalAfterClose = useCallback(() => {
    endInterval();

    setState({
      loading: false,
      submitting: false,
      lastExportedUserName: "",
    });
  }, [endInterval]);

  /** Lida com o evento de fechamento emitido pela modal */
  const handleClose = useCallback(() => {
    handleExportSheetFealqFormModalClose();
  }, [handleExportSheetFealqFormModalClose]);

  const dialog = useSoulDialog();

  /** Exporta a planilha FEALQ  */
  const exportSheet = useCallback(async () => {
    dialog.fire({
      html: <ProgressModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const { startDate } = formProps.getValues();

    try {
      await exportSheetFealq({ startDate });

      await dialog.fire({
        title: "Feito!",
        text: "Exportação realizada com sucesso.",
      });

      handleClose();
    } catch (error) {
      dialog.close();

      const exportSheetFealqError = error as IExportSheetFealqErrorEntity;

      if (exportSheetFealqError.noDataToExport) {
        await dialog.fire({
          icon: "error",
          title: "Aviso",
          html: (
            <>
              Não foi possível continuar! Não há dados a serem exportados nesse
              período. Tente novamente mais tarde.
            </>
          ),
        });
      }
    }
  }, [dialog, exportSheetFealq, formProps, handleClose]);

  /**
   * Lida com o evento de submissao do form. valida os inputs evitando
   * o request caso haja invalidez no form e exibe os problemas na tela
   */
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <div>
            Ao confirmar, as datas de exportação serão <b>registradas</b> e a
            planilha FEALQ será baixada. Além disso, você receberá os{" "}
            <b>anexos</b> de cada Coordenador por e-mail. <br />
            <br />
            Deseja prosseguir?
          </div>
        ),
        showCancelButton: true,
        cancelButtonText: "Não, cancelar",
      });

      if (result.dismiss) {
        return;
      }

      exportSheet();
    },
    [dialog, exportSheet],
  );

  /** Estado de somente leitura dos campos da modal baseado no retorno da api */
  const readOnly = useMemo(
    () => state.lastExportedUserName !== "",
    [state.lastExportedUserName],
  );

  /**
   * Estado de validez do form baseado nos campos
   */
  const isValid = formProps.formState.isValid || readOnly;

  return {
    exportSheeFealqFormModalOpen: exportSheetFealqFormModalOpen,
    handleClose,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    formProps,
    handleSubmit,
    readOnly,
    isValid,
  };
}
