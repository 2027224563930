import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  ITerminateAccountReceivableParcelContract,
  ITerminateAccountParams,
} from "../../domain/contracts/terminateAccountReceivableParcelContract";
import { EAccountReceivableStatus } from "../../domain/entities/accountReceivableListItemEntity";

interface ITerminateAccountPayload {
  status: EAccountReceivableStatus;
  date: string;
  useReceiveUntil: boolean;
}

export class TerminateAccountReceivableParcelService
  implements ITerminateAccountReceivableParcelContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async terminateAccountReceivableParcel({
    accountReceivableId,
    terminationDate,
  }: ITerminateAccountParams): Promise<void> {
    const dtTerminationDate = parse(
      terminationDate.replace(/\D/g, ""),
      "ddMMyyyy",
      new Date(),
    );

    const formatedTerminationDate = format(
      dtTerminationDate,
      "yyyy-MM-dd 00:00:00",
    );

    const payload: ITerminateAccountPayload = {
      date: formatedTerminationDate,
      status: EAccountReceivableStatus.Paid,
      useReceiveUntil: false,
    };

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountReceivableParcels/${accountReceivableId}/Status`;

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
