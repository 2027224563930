import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  .dropdown-button {
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      color: hsla(0, 0%, 100%, 0.5);
    }
  }
`;

interface DropdownProps {
  isActive: boolean;
}

export const Dropdown = styled.div<DropdownProps>`
  display: ${props => (props.isActive ? "block" : "none")};

  animation: show-navbar-dropdown 0.25s ease forwards;

  @keyframes show-navbar-dropdown {
    0% {
      opacity: 0;
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
      transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    }

    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  position: absolute;

  min-width: 160px;
  width: max-content;

  border-radius: 0.4375rem;
  background: #fff;
  padding: 0.5rem 0;
  box-shadow: 0 50px 100px #32325d1a, 0 15px 35px #32325d26,
    0 5px 15px #0000001a;

  top: 105%;
  right: 0;
  left: auto;

  z-index: 5;

  ::before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    right: 20px;
    left: auto;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(12px);
    z-index: -5;
    border-radius: 2px;
  }

  .dropdown-header {
    padding: 0.5rem 1rem;
    color: #32325d;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    min-width: 160px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 1rem;

    margin: 0;
    text-align: left;
    width: 100%;
    border: none;
    background-color: #fff;
    text-decoration: none;

    color: #212529;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;

    &:hover {
      color: #16181b;
      background-color: #e9ecef;
    }

    &.active {
      cursor: default;
      background-color: #2c399f;
      color: #fff;
    }
  }
`;
