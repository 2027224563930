import { ELocalStorageKeys } from "../../core/data/utils/localStorageKeys";
import { IGetStoredActiveTabsContract } from "../domain/contracts/getStoredActiveTabsContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";

export class GetStoredActiveTabsService
  implements IGetStoredActiveTabsContract
{
  getStoredActiveTabs(origin: EImportationMatchesOrigin) {
    let storedKey = ELocalStorageKeys.ImportationLMSActiveTabs;

    if (origin === EImportationMatchesOrigin.SOLUTION) {
      storedKey = ELocalStorageKeys.ImportationSolutionActiveTabs;
    }

    const storedValue = localStorage.getItem(storedKey);

    return storedValue;
  }
}
