import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;

  header {
    align-items: center;
    padding-left: 1rem;

    #chk-all-registers-filtered {
      margin-right: 0.5rem;
    }
  }

  .no-padding {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .actives-only {
    gap: 0.5rem;
    display: flex;
  }

  .disabled-header-checkbox,
  .disabled-checkbox {
    .p-checkbox {
      pointer-events: none;

      div {
        border-color: #6366f122;
        background-color: #6366f133;
      }
    }
  }

  tbody.p-datatable-tbody tr.p-datatable-emptymessage {
    td {
      padding: 1rem;
      text-align: center;
      display: table-cell;
    }
  }
`;
