import { FaEdit, FaCopy } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSolicitImportPage } from "../../../hooks/useSolicitImportPage";
import {
  ISolicitImportForm,
  IFormComponentProps,
} from "../../../../domain/entities/solicitImportTypes";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { FieldWrapper } from "../../FieldWrapper";

interface IBarcodeState {
  isCopied: boolean;
  enableEdition: boolean;
}

export function BarcodeField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.barcode` as const;

  const { getValues } = useFormContext<ISolicitImportForm>();

  const { handleInputClassName } = useSolicitImportPage();

  const [state, setState] = useState<IBarcodeState>({
    isCopied: false,
    enableEdition: !getValues(fieldKey),
  });

  const handleCopyToClipboard = (value: string) => {
    if (!value) {
      return;
    }
    navigator.clipboard.writeText(value);
    setState(old => ({ ...old, isCopied: true }));
  };

  const tooltipRefCallback = (originalRef: unknown) => {
    const typedRef = originalRef as {
      tooltipRef: HTMLDivElement;
    };

    if (!typedRef || !typedRef.tooltipRef) {
      return;
    }

    typedRef.tooltipRef.style.backgroundColor = state.isCopied
      ? "#187c0f"
      : "#222";
    typedRef.tooltipRef.classList.add("copied-tooltip");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Controller
      name={fieldKey}
      rules={{ minLength: 44, maxLength: 48, required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
          setState(old => ({ ...old, enableEdition: true }));
        };

        const isDisabled = state.enableEdition
          ? false
          : !!field.value && !fieldState?.error;

        return (
          <label className="form-control">
            <span>Código de barras do boleto</span>
            <div className="barcode-wrapper">
              <FieldWrapper
                className={`${handleInputClassName(fieldState)} barcode-input`}
              >
                <input
                  {...field}
                  onChange={onChange}
                  disabled={isDisabled}
                  className="no-right-radius"
                  placeholder="Código de barras"
                  id={`txt-barcode-${formIndex}`}
                  data-testid={`txt-barcode-${formIndex}`}
                />
              </FieldWrapper>
              <button
                type="button"
                data-effect="solid"
                id="btn-edit-barcode"
                data-testid="btn-edit-barcode"
                data-tip="Editar código de barras"
                className={`barcode-button no-radius ${
                  !isDisabled ? "disabled" : ""
                }`}
                onClick={() => {
                  setState(old => ({
                    ...old,
                    enableEdition: true,
                  }));
                }}
              >
                <FaEdit />
              </button>
              <button
                type="button"
                className="barcode-button"
                data-testid="btn-copy-barcode"
                data-tip
                data-for={`copied-barcode-tooltip-${formIndex}`}
                onClick={() => {
                  handleCopyToClipboard(field.value);
                }}
                onMouseLeave={() => {
                  setState(old => ({
                    ...old,
                    isCopied: false,
                  }));
                }}
              >
                <FaCopy />
              </button>
              <ReactTooltip
                effect="solid"
                ref={tooltipRefCallback}
                id={`copied-barcode-tooltip-${formIndex}`}
              >
                {state.isCopied
                  ? "Código de barras copiado"
                  : "Copiar código de barras"}
              </ReactTooltip>
            </div>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              condition={fieldState?.error?.type === "minLength"}
              message="O código de barras deve ser maior ou igual 44 dígitos"
            />
            <InvalidFeedback
              condition={fieldState?.error?.type === "maxLength"}
              message="O código de barras deve ter no máximo 48 dígitos"
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
