import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);

  .table-header {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

export const UspCheckContainer = styled.div`
  font-size: 1rem;
  text-align: left;

  & :last-child {
    text-align: center;
  }
`;
