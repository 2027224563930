import { ParsePanoramaAngularService } from "../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../advTable/data/services/transformPanoramaAngularService";
import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { SearchClassificationAccountService } from "../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationAssessmentService } from "../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../core/data/services/searchCostCenterService";
import { SearchCustomerProviderByCorporationNameService } from "../../../core/data/services/searchCustomerProviderByCorporationNameService";
import { SearchCustomerProviderService } from "../../../core/data/services/searchCustomerProviderService";
import { SearchPaymentAccountService } from "../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../core/data/services/searchProjectService";
import { SearchUserService } from "../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../core/data/services/setServerSideService";
import { ISearchClassificationAccountContract } from "../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationAssessmentContract } from "../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../core/domain/contracts/searchCostCenterContract";
import { ISearchCustomerProviderByCorporationNameContract } from "../../../core/domain/contracts/searchCustomerProviderByCorporationNameContract";
import { ISearchCustomerProviderContract } from "../../../core/domain/contracts/searchCustomerProviderContract";
import { ISearchPaymentAccountContract } from "../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../core/domain/contracts/searchProjectContract";
import { ISearchUserContract } from "../../../core/domain/contracts/searchUserContract";
import { DeleteUspIncomeStatementReportPanoramaService } from "../data/services/deleteUspIncomeStatementReportPanoramaService";
import { ExportUspIncomeStatementReportService } from "../data/services/exportUspIncomeStatementReportService";
import { GenerateUspIncomeStatementArchiveService } from "../data/services/generateUspIncomeStatementArchiveService";
import { GetStoredUspIncomeStatementReportPanoramaIdService } from "../data/services/getStoredUspIncomeStatementReportPanoramaIdService";
import { ListUspIncomeStatementReportPanoramasService } from "../data/services/listUspIncomeStatementReportPanoramasService";
import { ListUspIncomeStatementReportService } from "../data/services/listUspIncomeStatementReportService";
import { SaveUspIncomeStatementReportPanoramaService } from "../data/services/saveUspIncomeStatementReportPanoramaService";
import { StoreUspIncomeStatementReportPanoramaIdService } from "../data/services/storeUspIncomeStatementReportPanoramaIdService";
import { AdvGeneratePayloadExportService } from "../data/services/advGeneratePayloadExportService";
import { IDeleteUspIncomeStatementReportPanoramaContract } from "../domain/contracts/deleteUspIncomeStatementReportPanoramaContract";
import { IExportUspIncomeStatementReportContract } from "../domain/contracts/exportUspIncomeStatementReportContract";
import { IGenerateUspIncomeStatementArchiveContract } from "../domain/contracts/generateUspIncomeStatementArchiveContract";
import { IGetStoredUspIncomeStatementReportPanoramaIdContract } from "../domain/contracts/getStoredUspIncomeStatementReportPanoramaIdContract";
import { IListUspIncomeStatementReportContract } from "../domain/contracts/listUspIncomeStatementReportContract";
import { IListUspIncomeStatementReportPanoramasContract } from "../domain/contracts/listUspIncomeStatementReportPanoramasContract";
import { ISaveUspIncomeStatementReportPanoramaContract } from "../domain/contracts/saveUspIncomeStatementReportPanoramaContract";
import { IStoreUspIncomeStatementReportPanoramaIdContract } from "../domain/contracts/storeUspIncomeStatementReportPanoramaIdContract";

export type MakeUspIncomeStatementReport =
  IListUspIncomeStatementReportContract &
    IListUspIncomeStatementReportPanoramasContract &
    ISaveUspIncomeStatementReportPanoramaContract &
    IGetStoredUspIncomeStatementReportPanoramaIdContract &
    IStoreUspIncomeStatementReportPanoramaIdContract &
    IExportUspIncomeStatementReportContract &
    IDeleteUspIncomeStatementReportPanoramaContract &
    ISearchClassificationAccountContract &
    ISearchClassificationAssessmentContract &
    ISearchClassificationUspContract &
    ISearchCompanyContract &
    ISearchCompetencyContract &
    ISearchCostCenterContract &
    ISearchCustomerProviderByCorporationNameContract &
    ISearchCustomerProviderContract &
    ISearchPaymentAccountContract &
    ISearchProjectContract &
    ISearchUserContract &
    IGenerateUspIncomeStatementArchiveContract;

export function makeUspIncomeStatementReport(
  api: IApiService,
  authApi: IApiService,
  noErrorHandlerApi: IApiService,
): MakeUspIncomeStatementReport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const parsePanoramaAngularService = new ParsePanoramaAngularService();
  const transformPanoramaAngularService = new TransformPanoramaAngularService();
  const advGeneratePayloadExportService = new AdvGeneratePayloadExportService();

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listUspIncomeStatementReportService =
    new ListUspIncomeStatementReportService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  const listUspIncomeStatementReportPanoramasService =
    new ListUspIncomeStatementReportPanoramasService(
      parsePanoramaAngularService,
      getUserLocalService,
      api,
    );

  const saveUspIncomeStatementReportPanoramaService =
    new SaveUspIncomeStatementReportPanoramaService(
      transformPanoramaAngularService,
      getUserLocalService,
      api,
    );

  const getStoredUspIncomeStatementReportPanoramaIdService =
    new GetStoredUspIncomeStatementReportPanoramaIdService();

  const storeUspIncomeStatementReportPanoramaIdService =
    new StoreUspIncomeStatementReportPanoramaIdService();

  const deleteUspIncomeStatementReportPanoramaService =
    new DeleteUspIncomeStatementReportPanoramaService(getUserLocalService, api);

  const exportUspIncomeStatementReportService =
    new ExportUspIncomeStatementReportService(
      advGeneratePayloadExportService,
      setServerSideService,
      getUserLocalService,
      api,
    );

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchCustomerProviderByCorporationNameService =
    new SearchCustomerProviderByCorporationNameService(
      getUserLocalService,
      api,
    );

  const searchCustomerProviderService = new SearchCustomerProviderService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  const generateUspIncomeStatementArchiveService =
    new GenerateUspIncomeStatementArchiveService(
      advGeneratePayloadExportService,
      setServerSideService,
      getUserLocalService,
      noErrorHandlerApi,
    );

  return {
    listUspIncomeStatementReport(companyGroupId, payload, filters) {
      return listUspIncomeStatementReportService.listUspIncomeStatementReport(
        companyGroupId,
        payload,
        filters,
      );
    },
    listUspIncomeStatementReportPanoramas(userId, columns) {
      return listUspIncomeStatementReportPanoramasService.listUspIncomeStatementReportPanoramas(
        userId,
        columns,
      );
    },
    saveUspIncomeStatementReportPanorama(columns, panorama) {
      return saveUspIncomeStatementReportPanoramaService.saveUspIncomeStatementReportPanorama(
        columns,
        panorama,
      );
    },
    getStoredUspIncomeStatementReportPanoramaId() {
      return getStoredUspIncomeStatementReportPanoramaIdService.getStoredUspIncomeStatementReportPanoramaId();
    },
    storeUspIncomeStatementReportPanoramaId(panoramaId) {
      return storeUspIncomeStatementReportPanoramaIdService.storeUspIncomeStatementReportPanoramaId(
        panoramaId,
      );
    },
    exportUspIncomeStatementReport(
      companyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
    ) {
      return exportUspIncomeStatementReportService.exportUspIncomeStatementReport(
        companyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColumnNames,
        filters,
      );
    },
    deleteUspIncomeStatementReportPanorama(panoramaId) {
      return deleteUspIncomeStatementReportPanoramaService.deleteUspIncomeStatementReportPanorama(
        panoramaId,
      );
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchCustomerProviderByCorporationName(params) {
      return searchCustomerProviderByCorporationNameService.searchCustomerProviderByCorporationName(
        params,
      );
    },
    searchCustomerProvider(params) {
      return searchCustomerProviderService.searchCustomerProvider(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    generateUspIncomeStatementArchive(
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
      companyGroupId,
    ) {
      return generateUspIncomeStatementArchiveService.generateUspIncomeStatementArchive(
        pfsEvent,
        selectedColumns,
        orderedColumnNames,
        filters,
        companyGroupId,
      );
    },
  };
}
