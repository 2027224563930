import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 82.5rem;

  .react-modal-body {
    padding-top: 0;

    #btn-generate-sheet {
      margin-left: unset;
      height: 100%;

      &.invalid-bkg {
        background-color: var(--soul-invalid-button);
      }
    }

    .p-datatable {
      .p-datatable-tbody {
        tr {
          &:not(.p-highlight):not(.p-datatable-emptymessage):hover {
            &.row-fusp {
              background: #fff1db;
            }
          }

          &.row-fusp {
            background: #ffe4bb;
          }

          &.p-row-odd {
            &.row-fusp {
              background: #ffe9c7;
            }
          }
        }
      }
    }

    .fusp-form-modal__form-row--grid {
      flex-direction: column;
      border: solid 1px #e8ebed;
      border-radius: 0.5rem;

      .accounts-payable__modal-fusp--padding {
        padding: 0.5rem 1rem;
      }
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  .p-progress-spinner {
    width: 3.5rem;
    height: 3.5rem;

    svg {
      filter: drop-shadow(0px 0px 0.625rem rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }

  .disabled-checkbox {
    &.soul-chkbox-col {
      pointer-events: none;
    }

    .p-checkbox {
      pointer-events: none;

      div {
        border-color: #6366f122;
        background-color: #6366f133;
      }
    }
  }
`;
