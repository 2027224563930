export interface ISaveProviderImportationUseCase {
  saveProviderImportationService(file: File): Promise<unknown>;
}

export interface ISaveProviderImportationService {
  saveProviderImportationService(file: File): Promise<unknown>;
}

export class SaveProviderImportationUseCase
  implements ISaveProviderImportationUseCase
{
  constructor(private service: ISaveProviderImportationService) {}

  saveProviderImportationService(file: File): Promise<unknown> {
    return this.service.saveProviderImportationService(file);
  }
}
