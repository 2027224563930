import { InputMask } from "primereact/inputmask";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { ProviderType } from "../../../domain/entities/providerEntity";

export function ForeignPersonForm() {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<ProviderForm<"foreign">>();

  const type = watch("type");

  if (type !== ProviderType.foreign) {
    return null;
  }

  return (
    <div className="form-section">
      <p>Dados Estrangeiro</p>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Documento <small>(opcional)</small>
          </span>
          <input
            maxLength={100}
            id="txt-document"
            placeholder="Documento"
            data-testid="txt-document"
            {...register("document", { shouldUnregister: true })}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Nome</span>
          <input
            id="txt-name"
            maxLength={100}
            placeholder="Nome"
            data-testid="txt-name"
            className={errors?.name ? "isInvalid" : ""}
            {...register("name", { required: true, shouldUnregister: true })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.name?.type === "required"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Telefone <small>(opcional)</small>
          </span>
          <Controller
            name="phoneNumber"
            shouldUnregister
            rules={{
              maxLength: 11,
              minLength: 10,
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <InputMask
                    unmask
                    maxLength={11}
                    autoClear={false}
                    value={field.value}
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    onChange={field.onChange}
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={!!fieldState?.error}
                    message="Formato de telefone inválido"
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            E-mail <small>(opcional)</small>
          </span>
          <input
            type="email"
            maxLength={100}
            placeholder="E-mail"
            className={errors?.email ? "isInvalid" : ""}
            {...register("email", {
              shouldUnregister: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Formato de e-mail inválido",
              },
            })}
          />
          <InvalidFeedback
            condition={errors?.email?.type === "pattern"}
            message={errors?.email?.message || "E-mail inválido"}
          />
        </label>
      </div>
    </div>
  );
}
