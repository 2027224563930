import { IProviderEntity } from "../../../provider/domain/entities/providerEntity";

export interface IGetProviderService {
  getProvider(id: string): Promise<IProviderEntity>;
}

export interface IGetProviderUseCase {
  getProvider(id: string): Promise<IProviderEntity>;
}

export class GetProviderUseCase implements IGetProviderUseCase {
  constructor(private getProviderService: IGetProviderService) {}

  getProvider(id: string) {
    return this.getProviderService.getProvider(id);
  }
}
