import { FormProvider, useFieldArray } from "react-hook-form";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";
import { StepsToolbar } from "../StepsToolbar";
import { Container } from "./styles";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { FinalStepGrid } from "../FinalStepGrid";

export function FinalStep() {
  const { form, getFormIndex } = useSolicitImportPage();

  const { control, getValues } = form;

  const { fields, remove } = useFieldArray<
    ISolicitImportForm,
    "imports",
    "formId"
  >({ control, name: "imports", keyName: "formId" });

  const handleFormsRemoval = (formsIds: string[]) => {
    const indexes = formsIds.map(id => {
      return getFormIndex(id);
    });
    remove(indexes);
    const finalLength = getValues("imports")?.length || 0;
    return finalLength === 0;
  };

  return (
    <Container>
      <StepsToolbar />
      <FormProvider {...form}>
        <FinalStepGrid
          fields={fields}
          handleFormsRemoval={handleFormsRemoval}
        />
      </FormProvider>
    </Container>
  );
}
