import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";

export interface ILinkClassificationsAccountModel {
  classificationAccounts: string[];
}

export interface ILinkClassificationsAccountService {
  linkClassificationsAccount(
    providerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel>;
}

export interface ILinkClassificationsAccountUseCase {
  linkClassificationsAccount(
    providerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel>;
}

export class LinkClassificationsAccountUseCase
  implements ILinkClassificationsAccountUseCase
{
  constructor(
    private linkClsAccountService: ILinkClassificationsAccountService,
  ) {}

  linkClassificationsAccount(
    providerId: string,
    classificationAccountEntity: IClassificationAccountEntity[],
  ): Promise<ILinkClassificationsAccountModel> {
    return this.linkClsAccountService.linkClassificationsAccount(
      providerId,
      classificationAccountEntity,
    );
  }
}
