import styled from "styled-components";

export const Button = styled.button`
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(1turn);
    }
  }

  .pulse {
    animation-name: fa-spin;
    animation-direction: normal;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(8);
  }
`;
