import { ColumnBodyOptions, ColumnProps } from "primereact/column";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaEye, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { AsyncButton } from "../../../../../core/presentation/components/AsyncButton";
import { ClientSoulTable } from "../../../../../core/presentation/components/ClientSoulTable";
import { InputSearch } from "../../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { IAttachmentReportEntity } from "../../../domain/entities/attachmentEntity";
import { ICostCenterReportEntity } from "../../../domain/entities/costCenterReportEntity";
import { MakeCostCenterReportManager } from "../../../main/makeCostCenterReportManager";
import { Container, Loading } from "./styles";

interface CostCenterReportAttachmentModalProp {
  isOpen: boolean;
  reportData: ICostCenterReportEntity;
  useCostCenterReportManager: MakeCostCenterReportManager;
  onRequestClose: () => void;
}

export function CostCenterReportAttachmentModal({
  isOpen,
  reportData,
  onRequestClose,
  useCostCenterReportManager,
}: CostCenterReportAttachmentModalProp) {
  const { getAttachments, getStorageFilebyId } = useCostCenterReportManager;

  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<IAttachmentReportEntity[]>();
  const [search, setSearch] = useState("");

  const handleCloseButtonOnClick = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const mountedRef = useIsMounted();

  const fetchAttachments = useCallback(async () => {
    if (!reportData) {
      return;
    }

    setLoading(true);

    try {
      const attachmentsResponse = await getAttachments(reportData);

      if (!mountedRef.current) {
        return;
      }

      setAttachments(attachmentsResponse);
    } finally {
      if (mountedRef.current) {
        setLoading(false);
      }

      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    }
  }, [getAttachments, mountedRef, reportData]);

  /**
   * Visualizar anexo
   */
  const viewAttachment = useCallback(
    async (attachment: IAttachmentReportEntity) => {
      if (attachment.fileUrl) {
        window.open(attachment.fileUrl);
        return true;
      }

      if (attachment.storageFileId) {
        const url = await getStorageFilebyId(
          attachment.storageFileId,
          reportData.origin,
        );
        window.open(url);
        return true;
      }

      return false;
    },
    [getStorageFilebyId, reportData.origin],
  );

  const renderActions = useCallback(
    (attachment: IAttachmentReportEntity, { rowIndex }: ColumnBodyOptions) => {
      return (
        <div className="table-actionbar">
          <AsyncButton
            data-tip="Visualizar anexo"
            type="button"
            className="outline-button tool"
            id={`btn-vinc-class-account-${rowIndex}`}
            data-testid={`btn-vinc-class-account-${rowIndex}`}
            onClick={() => {
              return viewAttachment(attachment);
            }}
          >
            <FaEye />
          </AsyncButton>
        </div>
      );
    },
    [viewAttachment],
  );

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        header: "Nome",
        field: "name",
      },
      {
        header: "Tipo",
        field: "typeDescription",
      },
      {
        header: "",
        field: "actions",
        body: renderActions,
      },
    ];
  }, [renderActions]);

  useEffect(() => {
    if (!attachments) {
      fetchAttachments();
    }
  }, [attachments, fetchAttachments]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseButtonOnClick}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Consultar anexos</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={handleCloseButtonOnClick}
          >
            <IoMdClose />
          </button>
        </div>

        {loading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <>
            <div className="col-12 react-modal-body">
              <div className="card">
                <p className="description">
                  <b>Descrição</b>: {reportData.description}
                </p>
                <p className="document-number">
                  <b>Nº documento</b>: {reportData.documentNumber}
                </p>
              </div>
              <Page className="modal-table">
                <header>Anexos</header>
                <div className="searchbar">
                  <InputSearch
                    value={search}
                    id="txt-taxes-search"
                    data-testid="txt-taxes-search"
                    onChange={e => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
                <ClientSoulTable
                  globalFilter={search}
                  data={attachments ?? []}
                  columns={columns}
                  emptyMessage="Nenhum anexo encontrado."
                />
              </Page>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={handleCloseButtonOnClick}
              >
                Fechar
              </button>
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
}
