export interface IGetCostCentersTransactionsImportationTemplateService {
  getCostCentersTransactionsImportationTemplate(): Promise<string>;
}

export interface IGetCostCentersTransactionsImportationTemplateUseCase {
  getCostCentersTransactionsImportationTemplate(): Promise<string>;
}

export class GetCostCentersTransactionsImportationTemplateUseCase
  implements IGetCostCentersTransactionsImportationTemplateUseCase
{
  constructor(
    private getCostCentersTransactionsImportationTemplateService: IGetCostCentersTransactionsImportationTemplateService,
  ) {}

  getCostCentersTransactionsImportationTemplate() {
    return this.getCostCentersTransactionsImportationTemplateService.getCostCentersTransactionsImportationTemplate();
  }
}
