import { DropdownChangeParams } from "primereact/dropdown";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTrash } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../../advTable/domain/entities/panoramaEntity";
import { IApiError } from "../../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { ProgressModalContent } from "../../../../../core/presentation/components/Modals/ProgressModalContent";
import { useDebounceTimeAsync } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { IErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";
import { PanoramaOption } from "../components/Toolbar/styles";
import { useAccountsPayableGrid } from "./useAccountsPayableGrid";
import { useAccountsPayablePage } from "./useAccountsPayablePage";

export function useToolbar() {
  const {
    state,
    useAccountsPayable,
    handlePanoramaChange,
    handleClearButtonClick,
    openBulkTerminateModal,
    openExportSheetFealqFormModal,
    openExportSheetFuspFormModal,
    openGenerateFeeModal,
    openGenerateRemittancesModal,
    clearSelection,
    reload,
    canExportFealqSheet,
    canExportFuspSheet,
  } = useAccountsPayablePage();

  const {
    storeAccountsPayablePanoamaId,
    getStoredAccountsPayablePanoramaId,
    listAccountsPayablePanoramas,
    saveAccountsPayablePanorama,
    deleteAccountsPayablePanorama,
    exportAccountsPayable,
    removeBulkTerminateAccounts,
  } = useAccountsPayable;

  /** Esta é a representação do panorama padrão contas a pagar */
  const defaultPanorama = useMemo(() => {
    return PanoramaEntity.create({
      id: "default-panorama",
      name: "Padrão do sistema",
      systemDefault: true,
    });
  }, []);

  /** Estados do Panorama */
  const [panoramaOptions, setPanoramaOptions] = useState<IPanoramaEntity[]>();
  const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
  const [selectedPanorama, setSelectedPanorama] =
    useState<IPanoramaEntity>(defaultPanorama);

  const [loading, setLoading] = useState(true);

  const { selection } = state;

  /** Abre modal p/ salvar um novo panorama */
  const handleSavePanoramaButtonClick = useCallback(() => {
    setIsPanoramaModalOpen(true);
  }, []);

  const dialog = useSoulDialog();

  const debounceTime = useDebounceTimeAsync();

  /** reseta o panorama selecionado para o panorama default */
  const resetToPanoramaDefault = useCallback(() => {
    const panoId = defaultPanorama.id;

    storeAccountsPayablePanoamaId(panoId);
    setSelectedPanorama(defaultPanorama);
    handlePanoramaChange(defaultPanorama);
  }, [defaultPanorama, handlePanoramaChange, storeAccountsPayablePanoamaId]);

  /** lida com o evento de troca de valor do dropdown de panoramas */
  const handlePanoramaDropdownChange = ({ value }: DropdownChangeParams) => {
    const panorama = value as IPanoramaEntity;
    const panoramaId = panorama.id;

    storeAccountsPayablePanoamaId(panoramaId);
    setSelectedPanorama(panorama);
    handlePanoramaChange(panorama);
  };

  /** Deleta o panorama cujo botão "Excluir" foi clicado pelo usuario. */
  const handleDeletePanoramaButtonOnClick = useCallback(
    async (option: IPanoramaEntity, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const { name } = option;

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            await deleteAccountsPayablePanorama(option.id);
          } finally {
            dialog.close();
          }

          return true;
        },
        html: (
          <>
            O panorama <b>{name}</b> será excluído permanentemente.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      setPanoramaOptions(prevPanOptions => {
        const index = prevPanOptions?.findIndex(pan => pan.id === option.id);

        if (index && prevPanOptions) {
          prevPanOptions?.splice(index, 1);
          return [...prevPanOptions];
        }

        return prevPanOptions;
      });

      resetToPanoramaDefault();

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            O panorama <b>{name}</b> foi excluído com sucesso.
          </>
        ),
      });
    },
    [deleteAccountsPayablePanorama, dialog, resetToPanoramaDefault],
  );

  /** lida com o render das opcoes de panorama do dropdown */
  const renderPanoramaOption = useCallback(
    (option: IPanoramaEntity) => {
      const { name, systemDefault } = option;
      return (
        <PanoramaOption title={name}>
          <span>{name}</span>
          {!systemDefault && (
            <button
              title={`Excluir ${name}`}
              onClick={event =>
                handleDeletePanoramaButtonOnClick(option, event)
              }
              type="button"
            >
              <FaTrash />
            </button>
          )}
        </PanoramaOption>
      );
    },
    [handleDeletePanoramaButtonOnClick],
  );

  const { user } = useUserLocal();
  const { columns } = useAccountsPayableGrid({ useAccountsPayable });

  /** lida com o evento de salvamento de um novo panorama */
  const handlePanoramaSave = useCallback(
    async (panoramaData: IPanoramaEntity) => {
      const panoData = {
        ...panoramaData,
        panoramaDefinition: {
          ...panoramaData.panoramaDefinition,
          selectedColumns: state.orderedColNames
            .map(ordColName => {
              const index =
                panoramaData.panoramaDefinition.selectedColumns.findIndex(
                  selCol => {
                    return selCol.field === ordColName;
                  },
                );

              if (index > -1) {
                const [colDef] =
                  panoramaData.panoramaDefinition.selectedColumns.splice(
                    index,
                    1,
                  );

                return colDef;
              }

              return undefined;
            })
            .filter((col): col is IAdvTableColumn => !!col),
        },
      };

      try {
        const newPanorama = await saveAccountsPayablePanorama(
          columns,
          panoData,
        );

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Panorama adicionado com sucesso.",
        });

        setPanoramaOptions(prevPanoramaOptions => {
          if (prevPanoramaOptions) {
            return [...prevPanoramaOptions, newPanorama];
          }

          return [newPanorama];
        });
        setSelectedPanorama(newPanorama);
        storeAccountsPayablePanoamaId(newPanorama.id);
      } finally {
        dialog.close();
      }
    },
    [
      columns,
      dialog,
      saveAccountsPayablePanorama,
      state.orderedColNames,
      storeAccountsPayablePanoamaId,
    ],
  );

  /**
   * Obtem lista de panoramaas para preencher o dropdown de panoramas,
   * tambem resgata o ultimo panorama utilizado por este usuário e escolhe
   * este no dropdown automaticaemente
   */
  const fetchPanoramas = useCallback(async () => {
    const { userId } = user;

    setLoading(true);

    const panoramas = await listAccountsPayablePanoramas(userId, columns);

    const panoramaList = [defaultPanorama, ...panoramas];
    setPanoramaOptions(panoramaList);

    setLoading(false);

    const panoramaId =
      getStoredAccountsPayablePanoramaId() || defaultPanorama.id;

    if (panoramaId) {
      const panorama = panoramaList.find(pano => pano.id === panoramaId);

      if (panorama) {
        setSelectedPanorama(panorama);
        handlePanoramaChange(panorama);
      }
    }
  }, [
    columns,
    defaultPanorama,
    getStoredAccountsPayablePanoramaId,
    handlePanoramaChange,
    listAccountsPayablePanoramas,
    user,
  ]);

  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** lida com o evento de click do botao de exportar contas a pagar */
  const handleOnExportButtonClick = async () => {
    if (!state.pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ProgressModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const currentCompanyGroupId = currentCompanyGroup.id;

    try {
      await exportAccountsPayable(
        currentCompanyGroupId,
        state.pfsEvent,
        state.selectedColumns as ISimpleColumn[],
        state.orderedColNames,
      );
    } finally {
      dialog.close();
    }
  };

  /** lida com o evento de botao de baixar contas a pagar em lote */
  const handleBulkTerminateButtonClick = () => {
    if (!state.selection?.length) {
      return;
    }

    openBulkTerminateModal();
  };

  const hasSelectedData = !!state.selection?.length;

  const hasNoOpenAccountSelected = !!state.selection?.every(
    a => a.status !== EAccountPayableStatus.Open,
  );

  const hasNoPaidAccountSelected = !!state.selection?.every(
    a => a.status !== EAccountPayableStatus.Paid,
  );

  /** Lida com o evento de clique no botão "Retirar baixa selecionado(s)" */
  const handleRemoveBulkTerminateButtonClick = async () => {
    if (!selection) {
      return;
    }

    const parcelsIds = selection.map(selectedAccount => {
      return selectedAccount.id;
    });

    await dialog.fire({
      icon: "question",
      title: "Você está certo disso?",
      html: (
        <>
          <p>
            Serão retiradas as <strong>baixas</strong> dos lançamentos
            selecionados.
          </p>
          <br />
          <p>Deseja prosseguir?</p>
        </>
      ),
      showCancelButton: true,
      cancelButtonText: "Não, cancelar",
      confirmButtonText: "Sim",
      async preConfirm() {
        dialog.update({
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        dialog.showLoading();

        try {
          await removeBulkTerminateAccounts(parcelsIds);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            html: <p>As baixas foram retiradas com sucesso.</p>,
            showCancelButton: false,
            confirmButtonText: "Sim",
          });

          clearSelection();
          reload();
        } catch (error) {
          const messageHTMLError = (error as IApiError<IErrorResponseEntity>)
            .response.data.MessageHTML;

          const messageError = (error as IApiError<IErrorResponseEntity>)
            .response.data.Details;

          const listErrors = (
            <ul>
              {messageError.map(detailsError => (
                <li>{detailsError}</li>
              ))}
            </ul>
          );

          // UGLY esse timeout foi necessario para chamar a dialog após o interceptor,
          // aparentemente o interceptor está acontecendo após o catch

          await debounceTime(1);

          await dialog.fire({
            icon: "error",
            title: "Opa!",
            html: messageHTMLError || listErrors,
            showCancelButton: false,
          });
        }
        return true;
      },
    });
  };

  const tooltipContainerRef = useRef<HTMLButtonElement>(null);

  const showTooltip = () => {
    if (tooltipContainerRef.current) {
      const tooltipElement = tooltipContainerRef.current;
      ReactTooltip.show(tooltipElement);
    }
  };

  const hideTooltip = () => {
    if (tooltipContainerRef.current) {
      const tooltipElement = tooltipContainerRef.current;
      ReactTooltip.hide(tooltipElement);
    }
  };

  const shouldDisableTerminateButton = useMemo(() => {
    return !hasSelectedData || hasNoOpenAccountSelected;
  }, [hasNoOpenAccountSelected, hasSelectedData]);

  const shouldDisableRemoveTerminateButton = useMemo(() => {
    return !hasSelectedData || hasNoPaidAccountSelected;
  }, [hasSelectedData, hasNoPaidAccountSelected]);

  const shouldShowRemittanceButton = user.profile !== EUserProfile.internal;

  useEffect(() => {
    fetchPanoramas();
    // REVIEW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Quando houver dados selecionados na tabela,
   * exibe o tooltip que orienta as acoes em lote
   */
  useEffect(() => {
    if (hasSelectedData) {
      showTooltip();
    } else {
      hideTooltip();
    }
  }, [hasSelectedData]);

  const currentCompanyGroupId = useMemo(
    () => currentCompanyGroup.id,
    [currentCompanyGroup.id],
  );

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      resetToPanoramaDefault();
    }
  }, [currentCompanyGroupId, resetToPanoramaDefault]);

  return {
    loading,
    panoramaOptions,
    selectedPanorama,
    isPanoramaModalOpen,
    setIsPanoramaModalOpen,
    hasSelectedData,
    renderPanoramaOption,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
    showClearButton: state.filtered,
    handleClearButtonClick,
    pfsEvent: state.pfsEvent,
    selectedColumns: state.selectedColumns,
    orderedColNames: state.orderedColNames,
    useAccountsPayable,
    handlePanoramaSave,
    handleOnExportButtonClick,
    openExportSheetFealqFormModal,
    openExportSheetFuspFormModal,
    handleBulkTerminateButtonClick,
    tooltipContainerRef,
    hideTooltip,
    openGenerateFeeModal,
    openGenerateRemittancesModal,
    handleRemoveBulkTerminateButtonClick,
    shouldShowRemittanceButton,
    shouldDisableTerminateButton,
    shouldDisableRemoveTerminateButton,
    canExportFealqSheet,
    canExportFuspSheet,
  };
}
