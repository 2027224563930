import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListSupervisorCompanyGroupsContract } from "../../domain/contracts/listCompanyGroupsForSupervisorContract";
import { ILinkedCompanyGroupEntity } from "../../domain/entities/linkedCompanyGroupEntity";

export class ListSupervisorCompanyGroupsService
  implements IListSupervisorCompanyGroupsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listSupervisorCompanyGroups(payload: IPayloadEntity) {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/Actives/Supervisor?${params}`;

    return this.api.get<IServerSideResponseModel<ILinkedCompanyGroupEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );
  }
}
