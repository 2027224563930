export interface IDeleteCostCenterReportPanoramaService {
  deleteCostCenterReportPanorama(panoramaId: string): Promise<void>;
}

export interface IDeleteCostCenterReportPanoramaUseCase {
  deleteCostCenterReportPanorama(panoramaId: string): Promise<void>;
}

export class DeleteCostCenterReportPanoramaUseCase
  implements IDeleteCostCenterReportPanoramaUseCase
{
  constructor(
    private deleteCostCenterReportPanoramaService: IDeleteCostCenterReportPanoramaService,
  ) {}

  deleteCostCenterReportPanorama(panoramaId: string): Promise<void> {
    return this.deleteCostCenterReportPanoramaService.deleteCostCenterReportPanorama(
      panoramaId,
    );
  }
}
