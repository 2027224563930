import { useState } from "react";
import {
  FaCheckCircle,
  FaFileExcel,
  FaSpinner,
  FaTimesCircle,
} from "react-icons/fa";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IConciliationEntity } from "../../../domain/entitites/conciliationEntity";
import { IConciliationResultEntity } from "../../../domain/entitites/conciliationResultEntity";
import { useConciliationContext } from "../../hooks/useConciliationContext";
import { ResultSuccessDialog } from "../ResultSuccessDialog";
import { Container } from "./styles";
import { EConciliationStep } from "../ConciliationPage";

interface IConciliationStepResultState {
  sending: boolean;
}

interface IConciliationStepResultProps {
  result: IConciliationResultEntity | null;
  conciliation: IConciliationEntity[];
}

export function ConciliationStepResult({
  result,
  conciliation,
}: IConciliationStepResultProps) {
  const { backButtonClickHandler, useConciliation } = useConciliationContext();

  const [state, setState] = useState<IConciliationStepResultState>({
    sending: false,
  });

  const handleBackButtonClick = () => {
    backButtonClickHandler();
  };

  const { downloadConciliationResult } = useConciliation;

  const dialog = useSoulDialog();

  const conciliationContext = useConciliationContext();
  const teste = conciliationContext.setState;
  const { setFiles } = conciliationContext;

  /**
   * Retorna o usuario para a tela de onde ele veio
   * caso escolha "Não" na dialog de sucesso
   */
  const handleNotButtonClick = () => {
    window.history.back();
  };

  /**
   * Conduz o usuario de volta para a modal de periodo de conciliacao
   * e limpa somente o upload caso ele tenha escolhido continuar
   * conciliando com os mesmos filtros
   */
  const handleSameFiltersButtonClick = () => {
    setFiles([]);
    teste(prevState => ({
      ...prevState,
      step: EConciliationStep.TermModal,
    }));
  };

  /**
   * Conduz o usuario de volta para a modal de periodo de conciliacao
   * e limpa o formulario e o upload caso ele tenha escolhido continuar
   * conciliando com os outrosfiltros
   */
  const handleOtherFiltersButtonClick = () => {
    setFiles([]);
    teste(prevState => ({
      ...prevState,
      step: EConciliationStep.TermModal,
      formData: undefined,
    }));
  };

  /** Exibe uma dialog para conduzir o usuario para proximos passos */
  const showResultSuccessDialog = () => {
    return dialog.fire({
      icon: "success",
      title: "Feito!",
      html: (
        <ResultSuccessDialog
          onNotButtonClick={handleNotButtonClick}
          onSameFiltersButtonClick={handleSameFiltersButtonClick}
          onOtherFiltersButtonClick={handleOtherFiltersButtonClick}
        />
      ),
      showCancelButton: false,
      showDenyButton: false,
      showConfirmButton: false,
      showCloseButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  };

  /**
   * Lida com o pressionar do botao "Exportar
   * faltantes", faz o download dos resultados
   */
  const handleDownloadResultsButtonClick = async () => {
    if (result?.missing === 0) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      sending: true,
    }));

    try {
      await downloadConciliationResult(conciliation);
    } finally {
      setState(prevState => ({
        ...prevState,
        sending: false,
      }));
    }
  };

  /**
   * Lida com o pressionar do botao "Concluir", exibe uma
   * dialog conduzindo o usuario para proximos passos
   */
  const handleFinishButtonClick = () => {
    showResultSuccessDialog();
  };

  return (
    <Container>
      <div className="body">
        <div className="panel">
          <div>
            <div>
              {result?.entries}
              <FaFileExcel />
            </div>
            <div>{result && result.entries > 1 ? "Registros" : "Registro"}</div>
          </div>
          <div>
            <div>
              {result?.conciliated}
              <FaCheckCircle className="conciliated" />
            </div>
            <div>
              {result && result.conciliated > 1 ? "Conciliados" : "Conciliado"}
            </div>
          </div>
          <div>
            <div>
              {result?.missing}
              <FaTimesCircle className="missing" />
            </div>
            <div>{result && result.missing > 1 ? "Faltantes" : "Faltante"}</div>
          </div>
        </div>
      </div>

      <div className="footer">
        <button
          type="button"
          id="btn-close"
          data-testid="btn-close"
          disabled={state.sending}
          className="form-button red-bkg back-button"
          onClick={handleBackButtonClick}
        >
          Voltar
        </button>
        <button
          type="button"
          id="btn-export-missing"
          data-testid="btn-export-missing"
          disabled={result?.missing === 0 || state.sending}
          className="form-button next-button green-bkg"
          onClick={handleDownloadResultsButtonClick}
        >
          Exportar faltantes{" "}
          {state.sending && <FaSpinner className="spinner" />}
        </button>
        <button
          type="button"
          id="btn-finish"
          data-testid="btn-finish"
          className="form-button next-button green-bkg"
          onClick={handleFinishButtonClick}
        >
          Concluir
        </button>
      </div>
    </Container>
  );
}
