import { ELocalStorageKeys } from "../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../core/domain/entities/stringfied";
import { IGetStoredUspIncomeStatementReportPanoramaIdContract } from "../../domain/contracts/getStoredUspIncomeStatementReportPanoramaIdContract";

export class GetStoredUspIncomeStatementReportPanoramaIdService
  implements IGetStoredUspIncomeStatementReportPanoramaIdContract
{
  getStoredUspIncomeStatementReportPanoramaId(): string {
    const key = ELocalStorageKeys.UspIncomeStatementReportId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
