import { createContext, ReactNode, useMemo, useContext } from "react";
import { MakeAdvTableType } from "../../../advTable/main/makeAdvTable";
import { IPFSEventEntity } from "../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { MakeSimpleTableType } from "../../../simpleTable/main/makeSimpleTable";

interface ContextData {
  generatePayload(
    pfsEvent: IPFSEventEntity,
    columns: ISimpleColumn[],
  ): IPayloadEntity;
  advGeneratePayload(
    pfsEvent: IPFSEventEntity,
    columns: ISimpleColumn[],
  ): IPayloadEntity;
}

const TablesContext = createContext({} as ContextData);

interface TablesProviderProps {
  children: ReactNode;
  simpleTable: MakeSimpleTableType;
  advTable: MakeAdvTableType;
}

export function TablesProvider({
  children,
  simpleTable,
  advTable,
}: TablesProviderProps) {
  const value = useMemo(() => {
    return {
      generatePayload: simpleTable.generatePayload,
      advGeneratePayload: advTable.generatePayload,
    };
  }, [advTable.generatePayload, simpleTable.generatePayload]);

  return (
    <TablesContext.Provider value={value}>{children}</TablesContext.Provider>
  );
}

export function useTables() {
  return useContext(TablesContext);
}
