import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IPFSEventEntity } from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGeneratePayloadUseCase } from "../../../../../simpleTable/domain/usecases/generatePayloadUseCase";

interface IURLSearchParamsOptions {
  body: string;
  CompanyGroupId: string;
}

type URLSearchParamsType = Partial<
  Record<keyof IURLSearchParamsOptions, string>
>;

export class ExportAccountsPayableService {
  constructor(
    private advGeneratePayloadExportUseCase: IGeneratePayloadUseCase,
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async exportAccountsPayable(
    currentCompanyGroupId: string,
    pfsEvent: IPFSEventEntity,
    selectedColumns: ISimpleColumn[],
    orderedColumnNames: string[],
  ): Promise<void> {
    // primeiro colocamos no payload somente as colunas que NAO SAO hidden
    // é importante percorrer as colunas selecionadas retirando as hidden
    // pois o orderedColumnNames vem com as hidden inclusas
    let payloadColumns = selectedColumns
      .reduce((accumulatorArray, selCol) => {
        const col = selCol as unknown as ISimpleHiddenColumn;
        const index = orderedColumnNames.findIndex(
          name => name === col.field && !col.hidden,
        );
        const retArr = [...accumulatorArray];

        if (index !== -1) {
          retArr[index] = selCol;
        }

        return retArr;
      }, [] as ISimpleColumn[])
      .filter((col: ISimpleColumn): col is ISimpleColumn => !!col);

    // Aqui nao precisamos de todas as colunas que estao configuradas como
    // obrigatorias (hidden) para a UI, só precisamos das que vao aparecer
    // no relatorio
    const hiddenColumns = [
      { field: "type", hidden: true },
      { field: "isTax", hidden: true },
      { field: "status", hidden: true },
      { field: "returnStatus", hidden: true },
      { field: "providerRetainFee", hidden: true },
      { field: "isFeeRetention", hidden: true },

      /** @deprecated remover essas colunas comentadas futuramente */
      // { field: "remittanceDate", hidden: true },
      // { field: "remittanceUserId", hidden: true },
      // { field: "remittanceUserName", hidden: true },
    ];

    payloadColumns = [
      ...payloadColumns,
      ...(hiddenColumns as unknown as ISimpleColumn[]),
    ];

    const payload = this.advGeneratePayloadExportUseCase.generatePayload(
      pfsEvent,
      payloadColumns,
    ) as unknown as Record<string, object>;

    // este bloco de codigo insere o mapa de colunas que ajuda a
    // API a saber valor de qual coluna deve utilizar no excel
    payload.orderableStringField = {
      valueString: "value",
      statusDescription: "status",
      returnStatusDescription: "returnStatus",
      issueDateString: "issueDate",
      payUntilString: "payUntil",
      terminationDateString: "terminationDate",
      chargebackDateString: "chargebackDate",
      paymentMethodDescription: "paymentMethod",
      documentStatusDescription: "documentStatus",

      dateCreatedString: "dateCreated",
      dateModifiedString: "dateModified",
      deleteDateString: "deleteDate",
      approvedDateString: "approvedDate",

      returnDateString: "returnDate",
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      payload as unknown as IPayloadEntity,
    );

    const paramsOptions: URLSearchParamsType = {
      body: serverSideId,
      CompanyGroupId: currentCompanyGroupId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/Downloads/AccountsPayable/SpreadSheets?${params}`;
    const userEntity = this.getUserLocalService.get();

    // TODO retirar marcador opcional deste método após corrigir todos os mocks dos testes
    const response = await this.api.download?.(url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers["content-disposition"] ||
      `attachment; filename=Download; filename*=UTF-8''Download`;

    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();
    fileName = fileName.replace(/"/g, "");

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
