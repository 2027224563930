import styled from "styled-components";

export const Container = styled.div`
  &.form-container > .form-row > .form-control {
    flex-direction: row;

    > span.prepend {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      border-radius: 0.25rem 0 0 0.25rem;
      border: 1px solid var(--input-border-color);
      margin-bottom: 0;
      box-sizing: border-box;
      height: 100%;
      padding: 0 0.5rem;
      color: #b1bac4;
      border-right: none;
    }

    > input.src-fld {
      padding-left: 0 !important;
      border-left: none;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
`;
