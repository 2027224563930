import { ChangeEvent, useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import ReactTooltip from "react-tooltip";
import { FaSpinner } from "react-icons/fa";
import { AutoCompleteChangeParams } from "primereact/autocomplete";
import { Container } from "./style";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { InputBalance } from "../../../../core/presentation/components/InputBalance";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { MakeCore } from "../../../../core/main/makeCore";
import { MakePaymentAccounts } from "../../../main/makePaymentAccounts";
import {
  IPaymentAccountFormEntity,
  PaymentAccountFormEntity,
} from "../../../domain/entities/paymentAccountFormEntity";
import { usePaymentAccountForm } from "../../hooks/usePaymentAccountForm";
import { MakeCompany } from "../../../../company/main/makeCompany";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICompanyEntity } from "../../../../company/domain/entities/companyEntity";
import { IBankEntity } from "../../../../core/domain/entities/bankEntity";
import { IBankAccountType } from "../../../../provider/domain/entities/providerEntity";
import { SearchBanksField } from "../SearchBanksField";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";

export interface PaymentAccountFormModalProps {
  readonly?: true;
  isOpen: boolean;
  currentId: string;
  useCore: MakeCore;
  usePaymentAccounts: MakePaymentAccounts;
  listCompanies: MakeCompany["listCompanies"];
  onClose: (shouldRefresh: boolean | unknown) => void;
}

export function PaymentAccountFormModal(props: PaymentAccountFormModalProps) {
  const {
    isOpen,
    useCore,
    onClose,
    readonly,
    currentId,
    listCompanies,
    usePaymentAccounts,
  } = props;

  const { listBanks } = useCore;
  const { getPaymentAccount, savePaymentAccount, updatePaymentAccount } =
    usePaymentAccounts;

  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();
  const createPaymentAccountForm = usePaymentAccountForm();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const isDisabled = useMemo(() => {
    if (readonly) return true;
    return !allowedProfiles(
      EUserProfile.financialAccounting,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles, readonly]);

  const formMethods = useForm<IPaymentAccountFormEntity>({
    mode: "all",
    defaultValues: PaymentAccountFormEntity.create(),
  });

  const {
    watch,
    reset,
    control,
    register,
    setValue,
    resetField,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = formMethods;

  /** Observa o valor do campo "Tipo de Conta". */
  const bankAccountTypeWatcher = watch("bankAccountType");

  /** Estado de carregamento das informações do Project */
  const [isLoading, setIsLoading] = useState(false);
  /** Armazena lista de opções bancárias. */
  const [bankList, setBankList] = useState<IBankEntity[]>([]);
  /** Armazena lista de empresas. */
  const [companyList, setCompanyList] = useState<ICompanyEntity[]>([]);
  /** Armazena o valor exibido no campo "Banco" */
  const [currentBank, setCurrentBank] = useState<IBankEntity | null>(null);
  /** Indica se o centro de custo está ativo ou não. */
  const [isActive, setIsActive] = useState(true);

  /**
   * Reseta todos os campos bancários.
   */
  const resetBankFields = useCallback(() => {
    resetField("bank", { defaultValue: null });
    resetField("bankDescription", { defaultValue: "" });
    resetField("bankAccount", { defaultValue: "" });
    resetField("bankAccountDigit", { defaultValue: "" });
    resetField("bankBranch", { defaultValue: "" });
    resetField("bankBranchDigit", { defaultValue: "" });
    setCurrentBank(null);
  }, [resetField]);

  /**
   * Lida com a abertura do modal.
   */
  const handleModalOpening = useCallback(async () => {
    setIsLoading(true);

    try {
      const banks = await listBanks();
      const companies = await listCompanies(
        new PayloadEntity({
          draw: 0,
          length: 0,
        }),
        currentCompanyGroup.id,
        !currentId,
      );

      setBankList(banks);
      setCompanyList(companies.data);

      if (!currentId) return;

      const paymentAccount = await getPaymentAccount(currentId);
      const formValues = createPaymentAccountForm(paymentAccount, banks);

      if (formValues.bank) {
        setCurrentBank({
          key: formValues.bank,
          value: formValues.bankDescription,
        });
      }

      reset(formValues);
      setIsActive(paymentAccount.active);
    } catch (err) {
      onClose(false);
    } finally {
      setIsLoading(false);
      ReactTooltip.rebuild();
    }
  }, [
    createPaymentAccountForm,
    currentCompanyGroup.id,
    currentId,
    getPaymentAccount,
    listBanks,
    listCompanies,
    onClose,
    reset,
  ]);

  /**
   * Lida com a submissão do formulário.
   */
  const submitForm = useCallback(
    async (formValues: IPaymentAccountFormEntity) => {
      const payload = {
        ...formValues,
        id: currentId,
        active: isActive,
        companyGroupId: currentCompanyGroup.id,
      };

      const actionMessage = currentId ? "atualizada" : "cadastrada";

      if (!currentId) {
        await savePaymentAccount(payload);
      } else {
        await updatePaymentAccount(payload);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Conta de Pagamento ${actionMessage} com sucesso.`,
      });

      onClose(true);
    },
    [
      dialog,
      onClose,
      isActive,
      currentId,
      savePaymentAccount,
      updatePaymentAccount,
      currentCompanyGroup.id,
    ],
  );

  /**
   * Lida com o fechamento do modal. resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    setCurrentBank(null);
    reset(PaymentAccountFormEntity.create());
  }, [reset]);

  const handleSearchBankFieldChange = useCallback(
    (e: AutoCompleteChangeParams) => {
      const inputValue = e.value !== "EMPTY" ? e.value : null;
      setCurrentBank(inputValue);
      if (inputValue === null) return;
      const selectedBank = bankList.find(b => b.key === inputValue?.key);
      setValue("bank", selectedBank?.key || null, {
        shouldValidate: true,
      });
      setValue("bankDescription", selectedBank?.value || "", {
        shouldValidate: true,
      });
    },
    [bankList, setValue],
  );

  /** Define o título a ser exibido no modal. */
  const handleModalTitle = useCallback(() => {
    if (readonly) return "Visualizando Conta de Pagamento";
    return currentId ? "Editar Conta de Pagamento" : "Nova Conta de Pagamento";
  }, [currentId, readonly]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isLoading}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{handleModalTitle()}</h4>
          {!isLoading ? (
            <button
              type="button"
              onClick={onClose}
              id="btn-crud-cross"
              data-testid="btn-crud-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="crud-header">
              <p>
                Este registro {currentId ? "está" : "será"} vinculado ao grupo
                de empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <FormProvider {...formMethods}>
                <form
                  className="form-container"
                  id="payment-account-crud-form"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Empresa</span>
                      <select
                        defaultValue=""
                        id="txt-companyId"
                        disabled={isDisabled}
                        data-testid="txt-companyId"
                        className={errors?.companyId ? "isInvalid" : ""}
                        {...register("companyId", { required: true })}
                      >
                        <option value="" hidden disabled>
                          Selecione uma Empresa
                        </option>
                        {companyList.map(company => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.assumedName}
                            </option>
                          );
                        })}
                      </select>
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.companyId?.type === "required"}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Nome</span>
                      <input
                        id="txt-name"
                        placeholder="Nome"
                        disabled={isDisabled}
                        data-testid="txt-name"
                        className={errors?.name ? "isInvalid" : ""}
                        {...register("name", { required: true })}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.name?.type === "required"}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Saldo Inicial</span>
                      <Controller
                        control={control}
                        name="openingBalance"
                        render={({ field }) => (
                          <InputBalance
                            {...field}
                            resetToZero
                            placeholder="000,00"
                            disabled={isDisabled}
                            id="txt-openingBalance"
                            data-testid="txt-openingBalance"
                          />
                        )}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>
                        Tipo de conta bancária <small>(opcional)</small>
                      </span>
                      <Controller
                        control={control}
                        name="bankAccountType"
                        render={({ field }) => {
                          const onChange = (
                            e: ChangeEvent<HTMLSelectElement>,
                          ) => {
                            if (!e.target.value) resetBankFields();
                            field.onChange(e);
                          };

                          return (
                            <select
                              {...field}
                              onChange={onChange}
                              disabled={isDisabled}
                              id="txt-bankAccountType"
                              data-testid="txt-bankAccountType"
                            >
                              <option value="">Tipo de conta bancária</option>
                              <option value={IBankAccountType.checkingAccount}>
                                Conta Corrente
                              </option>
                              <option value={IBankAccountType.savingAccount}>
                                Conta Poupança
                              </option>
                            </select>
                          );
                        }}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>
                        Banco <small>(opcional)</small>
                      </span>
                      <Controller
                        name="bank"
                        control={control}
                        rules={{ required: !!bankAccountTypeWatcher }}
                        render={() => (
                          <SearchBanksField
                            bankList={bankList}
                            currentBank={currentBank}
                            isInvalid={!!errors.bank}
                            onChange={handleSearchBankFieldChange}
                            isDisabled={isDisabled || !bankAccountTypeWatcher}
                          />
                        )}
                      />
                      <InvalidFeedback
                        message="Banco inválido"
                        condition={!!errors?.bank}
                      />
                    </label>
                    <label className="col-2 form-control">
                      <span>Agência</span>
                      <InputMask
                        slotChar=""
                        mask="99999"
                        data-place="top"
                        autoClear={false}
                        autoComplete="off"
                        id="txt-bankBranch"
                        placeholder="Agência"
                        data-event="click focus"
                        data-event-off="focusout"
                        data-testid="txt-bankBranch"
                        data-tip="Não é necessário<br />informar dígito<br />verificador da<br />agência."
                        disabled={isDisabled || !bankAccountTypeWatcher}
                        className={errors?.bankBranch ? "isInvalid" : ""}
                        {...register("bankBranch", {
                          required: !!bankAccountTypeWatcher,
                        })}
                      />
                      <InvalidFeedback
                        message="Agência obrigatória"
                        condition={errors?.bankBranch?.type === "required"}
                      />
                    </label>
                    <label className="col-1 form-control">
                      <span>DV</span>
                      <InputMask
                        mask="9"
                        slotChar=""
                        placeholder="DV"
                        autoClear={false}
                        id="txt-bankBranchDigit"
                        data-testid="txt-bankBranchDigit"
                        {...register("bankBranchDigit")}
                        disabled={isDisabled || !bankAccountTypeWatcher}
                      />
                    </label>
                    <label className="col-2 form-control">
                      <span>Conta</span>
                      <input
                        id="bankAccount"
                        autoComplete="off"
                        placeholder="Conta"
                        data-testid="bankAccount"
                        disabled={isDisabled || !bankAccountTypeWatcher}
                        className={errors?.bankAccount ? "isInvalid" : ""}
                        {...register("bankAccount", {
                          required: !!bankAccountTypeWatcher,
                        })}
                        onChange={e => {
                          const { value } = e.target;
                          const onlyNumbers = value.replace(/\D/g, "");
                          setValue("bankAccount", onlyNumbers);
                        }}
                      />
                      <InvalidFeedback
                        message="Conta obrigatória"
                        condition={errors?.bankAccount?.type === "required"}
                      />
                    </label>
                    <label className="col-1 form-control">
                      <span>DV</span>
                      <InputMask
                        mask="9"
                        slotChar=""
                        placeholder="DV"
                        autoClear={false}
                        id="bankAccountDigit"
                        data-testid="bankAccountDigit"
                        disabled={isDisabled || !bankAccountTypeWatcher}
                        className={errors?.bankAccountDigit ? "isInvalid" : ""}
                        {...register("bankAccountDigit", {
                          required: !!bankAccountTypeWatcher,
                        })}
                      />
                      <InvalidFeedback
                        message="Dígito obrigatório"
                        condition={
                          errors?.bankAccountDigit?.type === "required"
                        }
                      />
                    </label>
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-crud-close"
                data-testid="btn-crud-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                data-testid="btn-crud-save"
                form="payment-account-crud-form"
                disabled={isSubmitting || isDisabled}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
