import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { NavItemContainer } from "./styles";

interface NavItemProps {
  id: string;
  title: string;
  sideBarExpanded?: boolean;
  to?: string;
  icon?: ReactNode;
  children?: ReactNode;
  isExpanded?: boolean;
  onExpand?(): void;
}

export function NavItem({
  id,
  icon,
  title,
  to,
  children,
  sideBarExpanded,
  isExpanded,
  onExpand,
}: NavItemProps) {
  return (
    <NavItemContainer
      navItemExpanded={isExpanded}
      sideBarExpanded={sideBarExpanded}
    >
      {to ? (
        <NavLink
          className="nav-link"
          id={id}
          to={to}
          onClick={() => onExpand?.()}
          data-soul-route={to}
        >
          {icon}
          <span title={title}>{title}</span>
        </NavLink>
      ) : (
        <button
          type="button"
          className="nav-link"
          id={id}
          onClick={() => onExpand?.()}
        >
          {icon}
          <span title={title}>{title}</span>
          <span className="caret" />
        </button>
      )}
      <ul className="children">{children}</ul>
    </NavItemContainer>
  );
}

NavItem.defaultProps = {
  to: "",
  icon: "",
  isExpanded: false,
};
