import { IApiService } from "../../../../../core/data/services/apiService";
import {
  IFetchRpaParamOptionsContract,
  IFetchRpaParamOptionsReturn,
} from "../../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";

export class FetchRpaParamOptionsService
  implements IFetchRpaParamOptionsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async fetchRpaParamOptions(): Promise<IFetchRpaParamOptionsReturn> {
    const userEntity = this.getUserLocalService.get();

    // obtem a lista de tipos de solicitacao
    let url = `/Enums/RequestTypeRpa`;
    const requestTypes = await this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    // obtem a lista de tipos de servico
    url = `/Enums/ServiceTypeAttachRpa`;
    const serviceTypes = await this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    // obtem a lista de tipos de pagamento
    url = `/Enums/paymentDetailsRpa`;
    const detailsTypes = await this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return { requestTypes, serviceTypes, detailsTypes };
  }
}
