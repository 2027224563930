import { format } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { CostCenterEntity } from "../../domain/entities/costCenterEntity";
import {
  CostCenterReportInputEntity,
  ICostCenterReportInputEntity,
} from "../../domain/entities/costCenterReportInputEntity";
import { ICostCenterReportEntity } from "../../domain/entities/costCenterReportEntity";
import { IGetCostCenterReportervice } from "../../domain/usecases/getCostCenterReportUseCase";

export class GetCostCenterReportService implements IGetCostCenterReportervice {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getCostCenterReport(
    costCenterReportId: string,
  ): Promise<ICostCenterReportInputEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/CostCenterReport/${costCenterReportId}`;

    const response = await this.api.get<ICostCenterReportEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const costCenterReportEntity = new CostCenterReportInputEntity({
      id: response.id,
      companyGroupId: response.companyGroupId,
      costCenterOrigin: new CostCenterEntity({
        id: response.id,
      }),
      costCenterDestination: new CostCenterEntity({
        id: response.id,
      }),
      transactionDate: format(new Date(response.dateModified), "ddMMyyyy"),
      value: response.operationValue,
      description: response.description,
      active: response.active,
    });

    return costCenterReportEntity;
  }
}
