import { ICostCenterTransactionEntity } from "../entities/costCenterTransactionsEntity";

export interface IToggleCostCenterTransactionService {
  toggleCostCenterTransaction(
    costCenterTransactionId: string,
  ): Promise<ICostCenterTransactionEntity>;
}

export interface IToggleCostCenterTransactionUseCase {
  toggleCostCenterTransaction(
    costCenterTransactionId: string,
  ): Promise<ICostCenterTransactionEntity>;
}

export class ToggleCostCenterTransactionsUseCase
  implements IToggleCostCenterTransactionUseCase
{
  constructor(
    private toggleCostCenterTransactionsService: IToggleCostCenterTransactionService,
  ) {}

  toggleCostCenterTransaction(
    costCenterTransactionId: string,
  ): Promise<ICostCenterTransactionEntity> {
    return this.toggleCostCenterTransactionsService.toggleCostCenterTransaction(
      costCenterTransactionId,
    );
  }
}
