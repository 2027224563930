import { ProgressSpinner } from "primereact/progressspinner";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { InputSearch } from "../../../../../../core/presentation/components/InputSearch";
import { useAssessmentModal } from "../../hooks/useAssessmentModal";
import { Container } from "./styles";

export function AssessmentModal() {
  const {
    assessmentModalOpen,
    handleClose,
    state,
    handleModalAfterOpen,
    handleModalAfterClose,
    setState,
    columns,
  } = useAssessmentModal();

  return (
    <Modal
      isOpen={assessmentModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.loading}
      onAfterOpen={handleModalAfterOpen}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Listagem de Rateio</h4>
          {!state.loading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {state.loading && (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        )}

        {!state.loading && (
          <>
            <div className="react-modal-body">
              <div className="searchbar">
                <InputSearch
                  value={state.search}
                  id="txt-taxes-search"
                  data-testid="txt-taxes-search"
                  onChange={event => {
                    const search = event.target.value || "";

                    setState(prevState => ({
                      ...prevState,
                      search,
                    }));
                  }}
                />
              </div>
              <ClientSoulTable
                globalFilter={state.search}
                autoLayout={false}
                emptyMessage="Nenhum registro encontrado."
                data={state.assessmentList}
                columns={columns}
              />
            </div>
            <div className="react-modal-footer">
              <button
                id="btn-close"
                type="button"
                className="form-button red-bkg"
                onClick={handleClose}
              >
                Voltar
              </button>
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
}
