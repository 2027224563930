export enum ExecutionDeadLine {
  Class = 0,
  Courseware = 1,
}

export enum ServiceProposalObjectType {
  Class = 0,
  Courseware = 1,
}

export enum ProvisionLocation {
  PecegeStudiosPiracicaba = 0,
  PecegeStudiosSaoPaulo = 1,
  InPerson = 2,
  Remote = 3,
}

export interface IGenerateCommercialProposalForm {
  classQuantity: number | null;
  proposalValidityPeriod: number;
  provisionLocation: ProvisionLocation;
  executionDeadline: ExecutionDeadLine;
  serviceProposalObjectType: ServiceProposalObjectType;
}
