import { IPaymentAccountTransactionInputEntity } from "../entities/paymentAccountTransactionInputEntity";

export interface IGetPaymentAccountTransactionService {
  getPaymentAccountTransaction(
    PaymentAccountTransactionsId: string,
  ): Promise<IPaymentAccountTransactionInputEntity>;
}

export interface IGetPaymentAccountTransactionUseCase {
  getPaymentAccountTransaction(
    PaymentAccountTransactionsId: string,
  ): Promise<IPaymentAccountTransactionInputEntity>;
}

export class GetPaymentAccountTransactionUseCase
  implements IGetPaymentAccountTransactionUseCase
{
  constructor(
    private getPaymentAccountTransactionsService: IGetPaymentAccountTransactionService,
  ) {}

  getPaymentAccountTransaction(
    PaymentAccountTransactionsId: string,
  ): Promise<IPaymentAccountTransactionInputEntity> {
    return this.getPaymentAccountTransactionsService.getPaymentAccountTransaction(
      PaymentAccountTransactionsId,
    );
  }
}
