import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import {
  IOnAttachmentTypeChangeParams,
  IUpdateAttachmentsBarcodeResult,
  IValidadeMeasurementAttachmentResult,
} from "../../../../../../core/domain/entities/attachmentsGridTypes";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { ISolicitImportAttachmentEntity } from "../../../domain/entities/solicitImportAttachmentEntity";
import { ISolicitImportEntity } from "../../../domain/entities/solicitImportEntity";
import { Container } from "./styles";

interface IAttachmentsModalProps {
  onClose(): void;
  isOpen: boolean;
  selectionCount?: number;
  rowData: ISolicitImportEntity | null;
  onSubmit(attachments: ISolicitImportAttachmentEntity[]): Promise<void>;
  listAttachmentTypes(): Promise<IEnum[]>;
  getStorageFilebyId(id: string): Promise<string>;
  attachmentTypeCheck(
    params: IOnAttachmentTypeChangeParams<ISolicitImportAttachmentEntity>,
  ): Promise<boolean>;
  updateAttachmentsBarcode?(
    billetAttachment: ISolicitImportAttachmentEntity,
  ): Promise<IUpdateAttachmentsBarcodeResult<ISolicitImportAttachmentEntity>>;
  validateMeasurementAttachment?(
    measurementAttachment: ISolicitImportAttachmentEntity,
  ): Promise<
    IValidadeMeasurementAttachmentResult<ISolicitImportAttachmentEntity>
  >;
}

interface IAttachmentsModalState {
  isSubmitting: boolean;
  attachments: ISolicitImportAttachmentEntity[];
}

export function AttachmentsModal(props: IAttachmentsModalProps) {
  const {
    isOpen,
    onClose,
    rowData,
    onSubmit,
    selectionCount,
    getStorageFilebyId,
    attachmentTypeCheck,
    listAttachmentTypes,
    updateAttachmentsBarcode,
    validateMeasurementAttachment,
  } = props;

  const [state, setState] = useState<IAttachmentsModalState>({
    attachments: [],
    isSubmitting: false,
  });

  const handleAfterOpen = () => {
    setState({
      isSubmitting: false,
      attachments: rowData?.storageFiles || [],
    });
  };

  const handleAfterClose = () => {
    setState({
      attachments: [],
      isSubmitting: false,
    });
  };

  const handleAttachmentsUpdate = (
    updatedAttachments: ISolicitImportAttachmentEntity[],
  ) => {
    setState(old => ({ ...old, attachments: updatedAttachments }));
  };

  const handleSubmitAttachments = async () => {
    setState(old => ({ ...old, isSubmitting: true }));

    try {
      await onSubmit(state.attachments);
    } finally {
      setState(old => ({ ...old, isSubmitting: false }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      shouldCloseOnEsc={state.isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar anexos</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            disabled={state.isSubmitting}
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <div className="col-12 react-modal-body">
          <div className="card">
            {selectionCount ? (
              <p>
                <strong>Lançamentos selecionados</strong>: {selectionCount}
              </p>
            ) : (
              <>
                <p>
                  <strong>Descrição</strong>:{" "}
                  {rowData?.description || "(sem descrição)"}
                </p>
                <p>
                  <strong>Nº documento</strong>:{" "}
                  {rowData?.documentNumber || "(sem número de documento)"}
                </p>
              </>
            )}
          </div>
          <AttachmentsGrid
            getStorageFilebyId={getStorageFilebyId}
            attachmentTypeCheck={attachmentTypeCheck}
            attachments={rowData?.storageFiles || []}
            listAttachmentTypes={listAttachmentTypes}
            onAttachmentsChange={handleAttachmentsUpdate}
            updateAttachmentsBarcode={updateAttachmentsBarcode}
            validateMeasurementAttachment={validateMeasurementAttachment}
          />
        </div>
        <div className="col-12 react-modal-footer">
          <button
            type="button"
            id="btn-close"
            data-testid="btn-close"
            className="form-button red-bkg"
            disabled={state.isSubmitting}
            onClick={onClose}
          >
            Fechar
          </button>
          <button
            type="submit"
            id="btn-submit"
            data-testid="btn-submit"
            disabled={state.isSubmitting}
            className="form-button green-bkg"
            onClick={handleSubmitAttachments}
          >
            Tudo certo{" "}
            {state.isSubmitting && <i className="pi pi-spin pi-spinner" />}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
