import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPecegePayAccountReceivableFeeTaxesContract } from "../../domain/contracts/getPecegePayAccountReceivableFeeTaxesContract";
import { IPecegePayAccountReceivableFeeTaxEntity } from "../../domain/entities/pecegePayAccountReceivableFeeTaxEntity";

interface IPecegePayAccountReceivableFeeTaxResponse {
  id: string;
  description: string;
  fees: Array<IPecegePayAccountReceivableFeeTaxEntity>;
}

export class GetPecegePayAccountReceivableFeeTaxesService
  implements IGetPecegePayAccountReceivableFeeTaxesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getPecegePayAccountReceivableFeeTaxes(feeId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = "/PecegePay/BillingCompanies/Wallets";

    const params = new URLSearchParams({
      pageSize: "100",
      idPecegePayAccountReceivableFee: feeId,
    });

    const response = await this.api.get<
      Array<IPecegePayAccountReceivableFeeTaxResponse>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (!response[0]?.fees || !response[0].fees.length) {
      return [];
    }

    const { fees } = response[0];

    const sortedFees = fees.sort((a, b) => {
      if (!a?.creditCardBrand) {
        return -1;
      }

      if (!b?.creditCardBrand) {
        return 1;
      }

      return (
        a.creditCardBrand.localeCompare(b.creditCardBrand) ||
        a.name.localeCompare(b.name)
      );
    });

    return sortedFees;
  }
}
