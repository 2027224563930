import { ICheckDocumentEntity } from "../entities/providerEntity";

export interface ICheckProviderCnpjUseCase {
  checkCnpj(cnpj: string, id?: string): Promise<ICheckDocumentEntity>;
}

export interface ICheckProviderCnpjService {
  checkCnpj(cnpj: string, id?: string): Promise<ICheckDocumentEntity>;
}

export class CheckProviderCnpjUseCase implements ICheckProviderCnpjUseCase {
  constructor(private checkProviderCnpjService: ICheckProviderCnpjService) {}

  checkCnpj(cnpj: string, id?: string) {
    return this.checkProviderCnpjService.checkCnpj(cnpj, id);
  }
}
