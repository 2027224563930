import { ColumnEditorOptions, ColumnProps } from "primereact/column";
import { useFormContext } from "react-hook-form";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";
import { IAccountReceivableParcelEntity } from "../../../domain/entities/accountReceivableParcelEntity";
import { IconTooltip } from "../../../../../../core/presentation/components/IconTooltip";
import { InputDate } from "../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { ParcelTableContainer } from "./styles";
import { EAccountReceivableStatus } from "../../../domain/entities/accountReceivableEnums";

export function SectionParcels() {
  const invalidDate = useDateValidator();

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<IAccountReceivableFormEntity>();

  const numberOfParcels = watch("numberOfParcels");
  const accountReceivableParcels = watch("accountReceivableParcels");

  const isInconsistent = accountReceivableParcels.some(parcel => {
    return parcel.status === EAccountReceivableStatus.Inconsistent;
  });

  const handleEditReceiveUntil = (options: ColumnEditorOptions) => {
    if (
      isInconsistent ||
      options.rowData.status !== EAccountReceivableStatus.Open
    ) {
      return options.value;
    }

    const index = options.rowIndex;
    const fieldName = `accountReceivableParcels.${index}.receiveUntil` as const;

    return (
      <label className="form-control">
        <InputDate
          type="text"
          id={`txt-${fieldName}`}
          placeholder="00/00/0000"
          data-testid={`txt-${fieldName}`}
          className={
            errors?.accountReceivableParcels?.[index]?.receiveUntil
              ? "isInvalid"
              : ""
          }
          {...register(fieldName, {
            validate: {
              required: dtValue => !!dtValue,
              validDate: dtValue => !invalidDate(dtValue || "", "dd/MM/yyyy"),
            },
          })}
        />
        <InvalidFeedback
          condition={
            errors?.accountReceivableParcels?.[index]?.receiveUntil?.type ===
            "required"
          }
          message="Este campo é obrigatório"
        />
        <InvalidFeedback
          condition={
            errors?.accountReceivableParcels?.[index]?.receiveUntil?.type ===
            "validDate"
          }
          message="Formato de data inválida"
        />
      </label>
    );
  };

  const handleEditTerminationDate = (options: ColumnEditorOptions) => {
    if (
      isInconsistent ||
      options.rowData.status !== EAccountReceivableStatus.Open
    ) {
      return options.value;
    }

    const index = options.rowIndex;
    const fieldName =
      `accountReceivableParcels.${index}.terminationDate` as const;

    return (
      <label className="form-control">
        <InputDate
          type="text"
          id={`txt-${fieldName}`}
          placeholder="00/00/0000"
          data-testid={`txt-${fieldName}`}
          className={
            errors?.accountReceivableParcels?.[index]?.terminationDate
              ? "isInvalid"
              : ""
          }
          {...register(fieldName, {
            validate: {
              validDate: dtValue => !invalidDate(dtValue || "", "dd/MM/yyyy"),
            },
          })}
        />
        <InvalidFeedback
          condition={
            errors?.accountReceivableParcels?.[index]?.terminationDate?.type ===
            "validDate"
          }
          message="Formato de data inválida"
        />
      </label>
    );
  };

  const columns: ColumnProps[] = [
    {
      field: "parcelNumber",
      header: "Número da parcela",
      style: {
        width: "25%",
      },
      body(data: IAccountReceivableParcelEntity) {
        return `${data.parcelNumber}/${numberOfParcels || ""}`;
      },
    },
    {
      field: "value",
      header: "Valor",
      style: {
        width: "25%",
      },
      body(data: IAccountReceivableParcelEntity) {
        const brlFormat = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format;

        return brlFormat(data.value);
      },
    },
    {
      field: "receiveUntil",
      editor: handleEditReceiveUntil,
      style: {
        width: "25%",
      },
      header: (
        <span className="with-tooltip">
          Receber em
          <IconTooltip position="top" icon="pi pi-question-circle">
            <small>
              Os valores desta coluna podem ser editados diretamente na tabela.
            </small>
          </IconTooltip>
        </span>
      ),
      onCellEditComplete(event) {
        if (
          isInconsistent ||
          event.rowData.status !== EAccountReceivableStatus.Open
        ) {
          return;
        }

        const index = event.rowIndex;
        const fieldName =
          `accountReceivableParcels.${index}.receiveUntil` as const;

        const hasErrors =
          !!errors?.accountReceivableParcels?.[index]?.receiveUntil;

        if (hasErrors) {
          setValue(fieldName, "");
        }
      },
    },
    {
      field: "terminationDate",
      editor: handleEditTerminationDate,
      style: {
        width: "25%",
      },
      header: (
        <span className="with-tooltip">
          Data de baixa
          <IconTooltip position="top" icon="pi pi-question-circle">
            <small>
              Os valores desta coluna podem ser editados diretamente na tabela.
            </small>
          </IconTooltip>
        </span>
      ),
      onCellEditComplete(event) {
        if (
          isInconsistent ||
          event.rowData.status !== EAccountReceivableStatus.Open
        ) {
          return;
        }

        const index = event.rowIndex;
        const fieldName =
          `accountReceivableParcels.${index}.terminationDate` as const;

        const hasErrors =
          !!errors?.accountReceivableParcels?.[index]?.terminationDate;

        if (hasErrors) {
          setValue(fieldName, "");
        }
      },
    },
  ];

  const handleRowClassName = (data: IAccountReceivableParcelEntity) => {
    const { status } = data;

    /**
     * O status "inconsistente" deve ser considerado como "pago".
     */
    const isPaid = [
      EAccountReceivableStatus.Paid,
      EAccountReceivableStatus.Inconsistent,
    ].includes(status);

    return {
      "row-paid": isPaid,
      "row-open": status === EAccountReceivableStatus.Open,
      "row-canceled": status === EAccountReceivableStatus.Canceled,
    };
  };

  return (
    <Card>
      <header>Parcelas a receber</header>
      <ParcelTableContainer>
        <ClientSoulTable
          rowHover
          columns={columns}
          paginator={false}
          className="rounded-bottom"
          data={accountReceivableParcels}
          rowClassName={handleRowClassName}
          emptyMessage="Para gerar especificações de parcelas digite o valor, número de parcelas, e data de recebimento"
        />
      </ParcelTableContainer>
    </Card>
  );
}
