import { ProgressSpinner } from "primereact/progressspinner";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { useAttachmentModal } from "../../hooks/useAttachmentModal";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";

export function AttachmentModal() {
  const {
    attachmentModalOpen,
    handleCloseButtonOnClick,
    handleAfterOpen,
    handleAfterClose,
    state,
    contextMenuData,
    listAttachmentTypes,
    handleUpdateAttachmentsBarcode,
    handleAttachmentListChange,
    handleSubmit,
    handleGenerateVariableAdditional,
    handleGenerateRpaAttachment,
    fetchRpaParamOptions,
    getStorageFilebyId,
    handleAttachmentTypeChange,
    fetchCommercialProposalEnums,
    handleGenerateCommercialProposal,
  } = useAttachmentModal();

  return (
    <Modal
      isOpen={attachmentModalOpen}
      onRequestClose={handleCloseButtonOnClick}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar anexos</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={handleCloseButtonOnClick}
          >
            <IoMdClose />
          </button>
        </div>

        {state.loading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : (
          <>
            <div className="col-12 react-modal-body">
              <div className="card">
                <p className="description">
                  <b>Descrição</b>: {contextMenuData?.description || ""}
                </p>
                <p className="document-number">
                  <b>Nº documento</b>: {contextMenuData?.documentNumber || ""}
                </p>
              </div>
              <AttachmentsGrid
                attachments={state.attachments}
                attachmentTypeCheck={handleAttachmentTypeChange}
                getStorageFilebyId={getStorageFilebyId}
                listAttachmentTypes={listAttachmentTypes}
                fetchRpaParamOptions={fetchRpaParamOptions}
                generateRpaAttachment={handleGenerateRpaAttachment}
                onAttachmentsChange={handleAttachmentListChange}
                updateAttachmentsBarcode={handleUpdateAttachmentsBarcode}
                generateVariableAdditionalAttachment={
                  handleGenerateVariableAdditional
                }
                fetchCommercialProposalEnums={fetchCommercialProposalEnums}
                generateCommercialProposalAttachment={
                  handleGenerateCommercialProposal
                }
              />
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                id="btn-close"
                data-testid="btn-close"
                className="form-button red-bkg"
                disabled={state.isSubmiting}
                onClick={handleCloseButtonOnClick}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-submit"
                data-testid="btn-submit"
                className="form-button green-bkg"
                disabled={state.isSubmiting || !state.attachments.length}
                onClick={handleSubmit}
              >
                Tudo certo{" "}
                {state.isSubmiting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>

            <RequestProgressModal
              loaded={state.uploadModal.loaded}
              total={state.uploadModal.total}
              isOpen={state.uploadModal.isOpen}
            />
          </>
        )}
      </Container>
    </Modal>
  );
}
