import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ILinkedCompanyGroupEntity } from "../../domain/entities/linkedCompanyGroupEntity";
import { IListLinkedCompanyGroupsService } from "../../domain/usecases/listLinkedCompanyGroupsUseCase";

export class ListLinkedCompanyGroupsService
  implements IListLinkedCompanyGroupsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private soulApi: IApiService,
  ) {}

  async listLinkedCompanyGroups(
    userId: string,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Users/${userId}/CompanyGroups/Actives`;

    const response = await this.soulApi.get<
      IServerSideResponseModel<ILinkedCompanyGroupEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
