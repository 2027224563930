import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IListProjectTransactionAttachmentsContract } from "../../domain/contracts/listProjectTransactionAttachmentsContract";
import { IProjectTransactionAttachmentEntity } from "../../domain/entities/projectTransactionAttachmentEntity";

export class ListProjectTransactionAttachmentsService
  implements IListProjectTransactionAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listProjectTransactionAttachments(ProjectTransactionId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `ProjectTransactions/${ProjectTransactionId}/Attachments`;

    return this.api.get<IProjectTransactionAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
