import styled from "styled-components";

export const Container = styled.div`
  bottom: 0;
  position: sticky;

  gap: 0.75rem;
  display: flex;
  justify-content: end;

  padding: 0.5rem 0;

  pointer-events: none;

  #btn-back.red-bkg {
    margin-right: unset;

    &:enabled:hover {
      background-color: #b82828;
    }
  }

  .p-splitbutton {
    transition: all 0.15s ease;

    &:hover {
      transform: translateY(-1px);
    }

    button {
      border: none;
      transform: none;
      margin-right: unset;
      font-size: 0.8333125rem;
      font-family: Arial, Helvetica, sans-serif;

      &.form-button:hover {
        transform: none;
      }

      &:enabled:hover {
        background-color: #28b87a;
      }

      &:first-child {
        margin-right: unset;
        border-radius: 0.375rem 0 0 0.375rem;
        padding-right: 1rem;

        span {
          gap: 0.5rem;
          display: flex;
          align-items: center;
        }
      }

      &:last-child {
        border-radius: 0 0.375rem 0.375rem 0;
        background-color: var(--soul-green-button);
      }

      &.p-disabled {
        opacity: 0.5;
        background-color: var(--soul-green-button) !important;
      }
    }
  }
`;

export const DestinationContainer = styled.div`
  padding: 1rem;

  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > button.form-button {
    &.darkBlue-bkg {
      margin-right: unset;
      background-color: var(--soul-color01);
    }

    &.yellow-bkg {
      margin-right: unset;
      background-color: #f6b93b;
    }
  }
`;

export const StatusContainer = styled.div`
  padding: 1rem 0;
  & > button.form-button {
    &.orange-bkg {
      margin-right: 0.5rem;
      background-color: #e58e26;
    }

    &.green-bkg {
      background-color: #05a42f;
    }
  }
`;
