import { format } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetProviderContract } from "../../domain/contracts/getProviderContract";
import { IProviderEntity } from "../../domain/entities/providerEntity";

export class GetProviderService implements IGetProviderContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getProvider(providerId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Providers/${providerId}`;

    const response = await this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    response.birthDate = response?.birthDate
      ? format(new Date(response.birthDate ?? ""), "ddMMyyyy").toString()
      : "";

    response.validityRNE = response?.validityRNE
      ? format(new Date(response.validityRNE ?? ""), "ddMMyyyy").toString()
      : "";

    response.email = response?.email ?? "";

    return response;
  }
}
