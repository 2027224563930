import { ParsePanoramaAngularService } from "../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../advTable/data/services/transformPanoramaAngularService";
import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { SearchClassificationAccountService } from "../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationAssessmentService } from "../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../core/data/services/searchCostCenterService";
import { SearchCustomerProviderByCorporationNameService } from "../../../core/data/services/searchCustomerProviderByCorporationNameService";
import { SearchCustomerProviderService } from "../../../core/data/services/searchCustomerProviderService";
import { SearchPaymentAccountService } from "../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../core/data/services/searchProjectService";
import { SearchUserService } from "../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../core/data/services/setServerSideService";
import { ISearchClassificationAccountContract } from "../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationAssessmentContract } from "../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../core/domain/contracts/searchCostCenterContract";
import { ISearchCustomerProviderByCorporationNameContract } from "../../../core/domain/contracts/searchCustomerProviderByCorporationNameContract";
import { ISearchCustomerProviderContract } from "../../../core/domain/contracts/searchCustomerProviderContract";
import { ISearchPaymentAccountContract } from "../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../core/domain/contracts/searchProjectContract";
import { ISearchUserContract } from "../../../core/domain/contracts/searchUserContract";
import { DeletePaymentAccountReportPanoramaService } from "../data/services/deletePaymentAccountReportPanoramaService";
import { ExportAllAttachmentsService } from "../data/services/exportAllAttachmentsService";
import { ExportPaymentAccountReportService } from "../data/services/exportPaymentAccountReportService";
import { ExportSelectedAttachmentsService } from "../data/services/exportSelectedAttachmentsService";
import { GetAttachmentTypesService } from "../data/services/getAttachmentTypesService";
import { GetStoredPaymentAccountReportPanoramaIdService } from "../data/services/getStoredPaymentAccountReportPanoramaIdService";
import { ListCostCentersService } from "../data/services/listCostCentersService";
import { ListPaymentAccountReportPanoramasService } from "../data/services/listPaymentAccountReportPanoramasService";
import { ListPaymentAccountReportService } from "../data/services/listPaymentAccountReportService";
import { SavePaymentAccountReportPanoramaService } from "../data/services/savePaymentAccountReportPanoramaService";
import { StorePaymentAccountReportPanoramaIdService } from "../data/services/storePaymentAccountReportPanoramaIdService";
import { AdvGeneratePayloadExportUseCase } from "../domain/contracts/advGeneratePayloadExportUseCase";
import { IDeletePaymentAccountReportPanoramaContract } from "../domain/contracts/deletePaymentAccountReportPanoramaContract";
import { IExportAllAttachmentsContract } from "../domain/contracts/exportAllAttachmentsContract";
import { IExportPaymentAccountReportContract } from "../domain/contracts/exportPaymentAccountReportContract";
import { IExportSelectedAttachmentsContract } from "../domain/contracts/exportSelectedAttachmentsContract";
import { IGetAttachmentTypesContract } from "../domain/contracts/getAttachmentTypesContract";
import { IGetStoredPaymentAccountReportPanoramaIdContract } from "../domain/contracts/getStoredPaymentAccountReportPanoramaIdContract";
import { IListCostCentersContract } from "../domain/contracts/listCostCenterContract";
import { IListPaymentAccountReportContract } from "../domain/contracts/listPaymentAccountReportContract";
import { IListPaymentAccountReportPanoramasContract } from "../domain/contracts/listPaymentAccountReportPanoramasContract";
import { ISavePaymentAccountReportPanoramaContract } from "../domain/contracts/savePaymentAccountReportPanoramaContract";
import { IStorePaymentAccountReportPanoramaIdContract } from "../domain/contracts/storePaymentAccountReportPanoramaIdContract";

export type MakePaymentAccountReport = IListPaymentAccountReportContract &
  IListCostCentersContract &
  IListPaymentAccountReportPanoramasContract &
  ISavePaymentAccountReportPanoramaContract &
  IGetStoredPaymentAccountReportPanoramaIdContract &
  IStorePaymentAccountReportPanoramaIdContract &
  IExportPaymentAccountReportContract &
  IDeletePaymentAccountReportPanoramaContract &
  IExportAllAttachmentsContract &
  IExportSelectedAttachmentsContract &
  IGetAttachmentTypesContract &
  ISearchClassificationAccountContract &
  ISearchClassificationAssessmentContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchCustomerProviderByCorporationNameContract &
  ISearchCustomerProviderContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract;

export function makePaymentAccountReport(
  api: IApiService,
  authApi: IApiService,
): MakePaymentAccountReport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const parsePanoramaAngularService = new ParsePanoramaAngularService();
  const transformPanoramaAngularService = new TransformPanoramaAngularService();
  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listPaymentAccountReportService = new ListPaymentAccountReportService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const listCostCentersService = new ListCostCentersService(
    getUserLocalService,
    api,
  );

  const listPaymentAccountReportPanoramasService =
    new ListPaymentAccountReportPanoramasService(
      parsePanoramaAngularService,
      getUserLocalService,
      api,
    );

  const savePaymentAccountReportPanoramaService =
    new SavePaymentAccountReportPanoramaService(
      transformPanoramaAngularService,
      getUserLocalService,
      api,
    );

  const getStoredPaymentAccountReportPanoramaIdService =
    new GetStoredPaymentAccountReportPanoramaIdService();

  const storePaymentAccountReportPanoramaIdService =
    new StorePaymentAccountReportPanoramaIdService();

  const deletePaymentAccountReportPanoramaService =
    new DeletePaymentAccountReportPanoramaService(getUserLocalService, api);

  const exportPaymentAccountReportService =
    new ExportPaymentAccountReportService(
      advGeneratePayloadExportUseCase,
      setServerSideService,
      getUserLocalService,
      api,
    );

  const exportAllAttachmentsService = new ExportAllAttachmentsService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const exportSelectedAttachmentsService = new ExportSelectedAttachmentsService(
    getUserLocalService,
    api,
  );

  const getAttachmentTypesService = new GetAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchCustomerProviderByCorporationNameService =
    new SearchCustomerProviderByCorporationNameService(
      getUserLocalService,
      api,
    );

  const searchCustomerProviderService = new SearchCustomerProviderService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  return {
    listPaymentAccountReport(companyGroupId, payload, filters) {
      return listPaymentAccountReportService.listPaymentAccountReport(
        companyGroupId,
        payload,
        filters,
      );
    },
    listCostCenters(companyGroupId, actives) {
      return listCostCentersService.listCostCenters(companyGroupId, actives);
    },
    listPaymentAccountReportPanoramas(userId, columns) {
      return listPaymentAccountReportPanoramasService.listPaymentAccountReportPanoramas(
        userId,
        columns,
      );
    },
    savePaymentAccountReportPanorama(columns, panorama) {
      return savePaymentAccountReportPanoramaService.savePaymentAccountReportPanorama(
        columns,
        panorama,
      );
    },
    getStoredPaymentAccountReportPanoramaId() {
      return getStoredPaymentAccountReportPanoramaIdService.getStoredPaymentAccountReportPanoramaId();
    },
    storePaymentAccountReportPanoramaId(panoramaId) {
      return storePaymentAccountReportPanoramaIdService.storePaymentAccountReportPanoramaId(
        panoramaId,
      );
    },
    exportPaymentAccountReport(
      companyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
    ) {
      return exportPaymentAccountReportService.exportPaymentAccountReport(
        companyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColumnNames,
        filters,
      );
    },
    deletePaymentAccountReportPanorama(panoramaId) {
      return deletePaymentAccountReportPanoramaService.deletePaymentAccountReportPanorama(
        panoramaId,
      );
    },
    exportAllAttachments(companyGroupId, payload, filters, attachmentTypes) {
      return exportAllAttachmentsService.exportAllAttachments(
        companyGroupId,
        payload,
        filters,
        attachmentTypes,
      );
    },
    exportSelectedAttachments(selectedReports, attachmentTypes) {
      return exportSelectedAttachmentsService.exportSelectedAttachments(
        selectedReports,
        attachmentTypes,
      );
    },
    getAttachmentTypes() {
      return getAttachmentTypesService.getAttachmentTypes();
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchCustomerProviderByCorporationName(params) {
      return searchCustomerProviderByCorporationNameService.searchCustomerProviderByCorporationName(
        params,
      );
    },
    searchCustomerProvider(params) {
      return searchCustomerProviderService.searchCustomerProvider(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
  };
}
