import BigNumber from "bignumber.js";
import { ColumnProps } from "primereact/column";
import { useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { IGridAssessment } from "../components/FinalStepExpandedTemplate";

interface UseFinalStepExpandedTemplateGridProps {
  handleOpenAssessmentModal(assessmentFormId: string): void;
}

export function useFinalStepExpandedTemplateGrid({
  handleOpenAssessmentModal,
}: UseFinalStepExpandedTemplateGridProps) {
  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        header: "Classificação",
        field: "classificationAssessment",
        sortable: true,
        sortField: "classificationAssessment.label",
        body(data: IGridAssessment) {
          return data.classificationAssessment?.label || "";
        },
      },
      {
        header: "Centro de Custo",
        field: "costCenter",
        sortable: true,
        sortField: "costCenter.label",
        body(data: IGridAssessment) {
          return data.costCenter?.label || "";
        },
      },
      {
        header: "Valor",
        field: "value",
        sortable: true,
        body(data: IGridAssessment) {
          const brlFmt = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format;

          const value = new BigNumber(data?.value || 0)
            .decimalPlaces(2)
            .toNumber();

          return brlFmt(value);
        },
      },
      {
        header: "%",
        field: "percentage",
        sortable: true,
        body(data: IGridAssessment) {
          const percentageFmt = new Intl.NumberFormat("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format;

          const percentageMask = new BigNumber(data?.percentage || 0)
            .multipliedBy(100)
            .decimalPlaces(2)
            .toNumber();

          return `${percentageFmt(percentageMask)}%`;
        },
      },
      {
        field: "edit-row",
        style: {
          width: "3%",
        },
        body(data: IGridAssessment, { rowIndex }) {
          return (
            <button
              type="button"
              data-effect="solid"
              data-tip="Editar rateio"
              className="p-link p-row-toggler"
              id={`btn-edit-assessment-row-${rowIndex}`}
              data-testid={`btn-edit-assessment-row-${rowIndex}`}
              onClick={() => {
                handleOpenAssessmentModal(data.id);
              }}
            >
              <FaEdit />
            </button>
          );
        },
      },
    ],
    [handleOpenAssessmentModal],
  );
  return {
    columns,
  };
}
