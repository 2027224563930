import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetSafraTaxesRemittanceTemplateContract } from "../../domain/contracts/getSafraTaxesRemittanceTemplateContract";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";

export class GetSafraTaxesRemittanceTemplateService
  implements IGetSafraTaxesRemittanceTemplateContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getSafraTaxesRemittanceTemplate() {
    const url = `/Remittances/${ERemittanceBankCode.Safra}/${ERemittanceType.Taxes}/Template`;
    const userEntity = this.getUserLocalService.get();

    return this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
