import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IListMacroCategoriesContract } from "../../domain/contracts/listMacroCategoriesContract";

interface IMacroCategoriesResponse {
  id: string;
  name: string;
}

export class ListMacroCategoriesService
  implements IListMacroCategoriesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listMacroCategories() {
    const userEntity = this.getUserLocalService.get();

    const url = "/MacroBoxes/Actives";

    const response = await this.api.get<IMacroCategoriesResponse[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.map(macroCategoryRequest => {
      return {
        label: macroCategoryRequest.name,
        rawValue: macroCategoryRequest.id,
        metadata: macroCategoryRequest,
      };
    });
  }
}
