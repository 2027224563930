import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import { IConciliationEntity } from "../../domain/entitites/conciliationEntity";
import { IConciliationFormInputEntity } from "../../domain/entitites/conciliationFormInputEntity";
import { IConciliationResultEntity } from "../../domain/entitites/conciliationResultEntity";
import { MakeConciliation } from "../../main/makeConciliation";
import { EConciliationStep } from "../components/ConciliationPage";
import { IPaymentAccountEntity } from "../../domain/entitites/paymentAccountEntity";

interface IConciliationPageState {
  step: EConciliationStep;
  resultEntity: IConciliationResultEntity | null;
  formData: IConciliationFormInputEntity | undefined;
  paymentAccounts: IPaymentAccountEntity[] | undefined;
}

interface IConciliationContext {
  state: IConciliationPageState;
  useConciliation: MakeConciliation;
  files: File[];
  paymentAccounts: IPaymentAccountEntity[] | undefined;
  setPaymentAccounts(
    paymentAccounts: IPaymentAccountEntity[] | undefined,
  ): void;
  setFiles: Dispatch<SetStateAction<File[]>>;
  dataImportedHandler(conciliation: IConciliationEntity[]): void;
  submitHandler(formData: IConciliationFormInputEntity): void;
  resultHandler(resultEntity: IConciliationResultEntity): void;
  backButtonClickHandler(): void;
  setState: Dispatch<SetStateAction<IConciliationPageState>>;
}

const ConciliationContext = createContext({} as IConciliationContext);

interface IConciliationProviderProps {
  children: ReactNode;
  useConciliation: MakeConciliation;
}

export function ConciliationProvider({
  children,
  useConciliation,
}: IConciliationProviderProps) {
  const [state, setState] = useState<IConciliationPageState>({
    resultEntity: null,
    formData: undefined,
    step: EConciliationStep.TermModal,
    paymentAccounts: undefined,
  });

  const [files, setFiles] = useState<File[]>([]);

  const submitHandler = (formData: IConciliationFormInputEntity) => {
    setState(prevState => ({
      ...prevState,
      step: EConciliationStep.Importation,
      formData,
    }));
  };

  const dataImportedHandler = () => {
    setState(prevState => ({
      ...prevState,
      step: EConciliationStep.Conciliation,
    }));
  };

  const dialog = useSoulDialog();

  const resultHandler = (resultEntity: IConciliationResultEntity) => {
    setState(prevState => {
      return {
        ...prevState,
        resultEntity,
        step: EConciliationStep.Results,
      };
    });
  };

  const value = useMemo(() => {
    const backButtonClickHandler = async () => {
      const { step } = state;

      let result;
      switch (step) {
        // passo 1: importacao
        case EConciliationStep.Importation:
          setState(prevState => ({
            ...prevState,
            step: EConciliationStep.TermModal,
          }));
          break;

        // passo 2: conciliacao
        case EConciliationStep.Conciliation:
          result = await dialog.fire({
            icon: "question",
            title: "Você está certo disso?",
            html: (
              <>
                Você está no meio do processo de conciliação. Se sair agora,
                todo o seu progresso será perdido!
              </>
            ),
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
          });

          if (result.dismiss) {
            return;
          }

          setFiles([]);
          setState(prevState => ({
            ...prevState,
            step: EConciliationStep.Importation,
          }));
          break;

        // passo 3: resultados
        case EConciliationStep.Results:
          setState(prevState => ({
            ...prevState,
            step: EConciliationStep.Conciliation,
          }));
          break;
        // modal de empresa e periodo de conciliacao
        default:
          window.history.back();
          break;
      }
    };

    return {
      state,
      useConciliation,
      files,
      paymentAccounts: state.paymentAccounts,
      setPaymentAccounts(
        _paymentAccounts: IPaymentAccountEntity[] | undefined,
      ) {
        setState(prevState => ({
          ...prevState,
          paymentAccounts: _paymentAccounts,
        }));
      },
      setFiles,
      submitHandler,
      dataImportedHandler,
      backButtonClickHandler,
      resultHandler,
      setState,
    };
  }, [dialog, files, state, useConciliation]);

  return (
    <ConciliationContext.Provider value={value}>
      {children}
    </ConciliationContext.Provider>
  );
}

export function useConciliationContext() {
  const context = useContext(ConciliationContext);
  return context;
}
