import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetAddressByZipCodeService } from "../../core/data/services/getAddressByZipCodeService";
import { GetCnpjDataService } from "../../core/data/services/getCnpjDataService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { ListBanksService } from "../../core/data/services/listBanksService";
import { SearchBrazilianCityService } from "../../core/data/services/searchBrazilianCityService";
import { SearchBrazilianStateService } from "../../core/data/services/searchBrazilianStateService";
import { SearchCountriesService } from "../../core/data/services/searchCountriesService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { IGetAddressByZipCodeContract } from "../../core/domain/contracts/getAddressByZipCodeContract";
import { IGetCnpjDataContract } from "../../core/domain/contracts/getCnpjDataContract";
import { IListBanksContract } from "../../core/domain/contracts/listBanksContract";
import { ISearchBrazilianCityContract } from "../../core/domain/contracts/searchBrazilianCityContract";
import { ISearchBrazilianStateContract } from "../../core/domain/contracts/searchBrazilianStateContract";
import { ISearchCountriesContract } from "../../core/domain/contracts/searchCountriesContract";
import { CheckProviderCnpjService } from "../data/services/checkProviderCnpjService";
import { CheckProviderCpfService } from "../data/services/checkProviderCpfService";
import { ExportProvidersService } from "../data/services/exportProvidersService";
import { GetProviderEnumsService } from "../data/services/getProviderEnumsService";
import { GetProviderImportationTemplateService } from "../data/services/getProviderImportationTemplateService";
import { GetProviderService } from "../data/services/getProviderService";
import { LinkClassificationsAccountService } from "../data/services/linkClassificationsAccountService";
import { ListClassificationsAccountService } from "../data/services/listClassificationsAccountService";
import { ListLinkedClassificationsAccountService } from "../data/services/listLinkedClassificationsAccountService";
import { ListProvidersService } from "../data/services/listProvidersService";
import { SaveProviderImportationService } from "../data/services/saveProviderImportationService";
import { SaveProviderService } from "../data/services/saveProviderService";
import { ToggleProviderStatusService } from "../data/services/toggleProviderStatusService";
import { UpdateProviderBankDataService } from "../data/services/updateProviderBankDataService";
import { IExportProvidersContract } from "../domain/contracts/exportProvidersContract";
import { IGetProviderContract } from "../domain/contracts/getProviderContract";
import { IGetProviderEnumsContract } from "../domain/contracts/getProviderEnumsContract";
import { IListProvidersContract } from "../domain/contracts/listProvidersContract";
import { ISaveProviderContract } from "../domain/contracts/saveProviderContract";
import { IUpdateProviderBankDataContract } from "../domain/contracts/updateProviderBankDataContract";
import {
  CheckProviderCnpjUseCase,
  ICheckProviderCnpjUseCase,
} from "../domain/usecases/checkProviderCnpjUseCase";
import {
  CheckProviderCpfUseCase,
  ICheckProviderCpfUseCase,
} from "../domain/usecases/checkProviderCpfUseCase";
import {
  GetProviderImportationTemplateUseCase,
  IGetProviderImportationTemplateUseCase,
} from "../domain/usecases/getProviderImportationTemplateUseCase";
import {
  ILinkClassificationsAccountUseCase,
  LinkClassificationsAccountUseCase,
} from "../domain/usecases/linkClassificationsAccountUseCase";
import {
  IListClassificationsAccountUseCase,
  ListClassificationsAccountUseCase,
} from "../domain/usecases/listClassificationsAccountUseCase";
import {
  IListLinkedClassificationsAccountUseCase,
  ListLinkedClassificationsAccountUseCase,
} from "../domain/usecases/listLinkedClassificationsAccountUseCase";
import {
  ISaveProviderImportationUseCase,
  SaveProviderImportationUseCase,
} from "../domain/usecases/saveProviderImportationUseCase";
import {
  IToggleProviderStatusUseCase,
  ToggleProviderStatusUseCase,
} from "../domain/usecases/toggleProviderStatusUseCase";

export type MakeProvider = IToggleProviderStatusUseCase &
  ICheckProviderCpfUseCase &
  IListClassificationsAccountUseCase &
  IListLinkedClassificationsAccountUseCase &
  ILinkClassificationsAccountUseCase &
  ICheckProviderCnpjUseCase &
  IGetProviderImportationTemplateUseCase &
  ISaveProviderImportationUseCase &
  IGetProviderEnumsContract &
  ISearchBrazilianCityContract &
  ISearchBrazilianStateContract &
  ISearchCountriesContract &
  IGetAddressByZipCodeContract &
  IGetCnpjDataContract &
  IListBanksContract &
  IGetProviderContract &
  ISaveProviderContract &
  IUpdateProviderBankDataContract &
  IListProvidersContract &
  IExportProvidersContract;

export function makeProvider(
  api: IApiService,
  viaCepApi: IApiService,
): MakeProvider {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const toggleProviderStatusService = new ToggleProviderStatusService(
    getUserLocalService,
    api,
  );
  const toggleProviderStatusUseCase = new ToggleProviderStatusUseCase(
    toggleProviderStatusService,
  );

  const checkProviderCpfService = new CheckProviderCpfService(
    getUserLocalService,
    api,
  );
  const checkProviderCpfUseCase = new CheckProviderCpfUseCase(
    checkProviderCpfService,
  );

  const checkProviderCnpjService = new CheckProviderCnpjService(
    getUserLocalService,
    api,
  );
  const checkProviderCnpjUseCase = new CheckProviderCnpjUseCase(
    checkProviderCnpjService,
  );

  const listClsAccountService = new ListClassificationsAccountService(
    getUserLocalService,
    api,
  );
  const listClsAccountUseCase = new ListClassificationsAccountUseCase(
    listClsAccountService,
  );
  const listLinkedClsAccountService =
    new ListLinkedClassificationsAccountService(getUserLocalService, api);
  const listLinkedClsAccountUseCase =
    new ListLinkedClassificationsAccountUseCase(listLinkedClsAccountService);

  const linkClsAccountService = new LinkClassificationsAccountService(
    getUserLocalService,
    api,
  );
  const linkClsAccountUseCase = new LinkClassificationsAccountUseCase(
    linkClsAccountService,
  );

  const getProviderImportationTemplateService =
    new GetProviderImportationTemplateService(getUserLocalService, api);

  const getProviderImportationTemplateUseCase =
    new GetProviderImportationTemplateUseCase(
      getProviderImportationTemplateService,
    );

  const saveProviderImportationService = new SaveProviderImportationService(
    getUserLocalService,
    api,
  );

  const saveProviderImportationUseCase = new SaveProviderImportationUseCase(
    saveProviderImportationService,
  );

  const geProviderEnumsService = new GetProviderEnumsService(
    getUserLocalService,
    api,
  );

  const searchBrazilianCityService = new SearchBrazilianCityService(
    getUserLocalService,
    api,
  );

  const searchBrazilianStateService = new SearchBrazilianStateService(
    getUserLocalService,
    api,
  );

  const searchCountriesService = new SearchCountriesService(
    getUserLocalService,
    api,
  );

  const getAddressByZipCodeService = new GetAddressByZipCodeService(
    getUserLocalService,
    api,
    viaCepApi,
  );

  const getCnpjDataService = new GetCnpjDataService();

  const listBanksService = new ListBanksService(getUserLocalService, api);

  const getProviderService = new GetProviderService(getUserLocalService, api);

  const saveProviderService = new SaveProviderService(getUserLocalService, api);

  const updateProviderBankData = new UpdateProviderBankDataService(
    getUserLocalService,
    api,
  );

  const listProviderService = new ListProvidersService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  const exportProvidersService = new ExportProvidersService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  return {
    listProviders(params) {
      return listProviderService.listProviders(params);
    },
    toggleProviderStatus(id) {
      return toggleProviderStatusUseCase.toggleProviderStatus(id);
    },
    checkCpf(cpf, id?) {
      return checkProviderCpfUseCase.checkCpf(cpf, id);
    },
    checkCnpj(cnpj, id?) {
      return checkProviderCnpjUseCase.checkCnpj(cnpj, id);
    },
    listClassificationsAccount(companyGroupId, payload) {
      return listClsAccountUseCase.listClassificationsAccount(
        companyGroupId,
        payload,
      );
    },
    listLinkedClassificationsAccount(companyGroupId, providerId) {
      return listLinkedClsAccountUseCase.listLinkedClassificationsAccount(
        companyGroupId,
        providerId,
      );
    },
    linkClassificationsAccount(providerId, classificationAccountEntity) {
      return linkClsAccountUseCase.linkClassificationsAccount(
        providerId,
        classificationAccountEntity,
      );
    },
    getProviderImportationTemplate() {
      return getProviderImportationTemplateUseCase.getProviderImportationTemplate();
    },
    saveProviderImportationService(file) {
      return saveProviderImportationUseCase.saveProviderImportationService(
        file,
      );
    },
    getProviderEnums() {
      return geProviderEnumsService.getProviderEnums();
    },
    searchBrazilianCity(params) {
      return searchBrazilianCityService.searchBrazilianCity(params);
    },
    searchBrazilianState(params) {
      return searchBrazilianStateService.searchBrazilianState(params);
    },
    searchCountries(params) {
      return searchCountriesService.searchCountries(params);
    },
    getAddressByZipCode(zipCode) {
      return getAddressByZipCodeService.getAddressByZipCode(zipCode);
    },
    getCnpjData(cnpj) {
      return getCnpjDataService.getCnpjData(cnpj);
    },
    listBanks() {
      return listBanksService.listBanks();
    },
    getProvider(providerId) {
      return getProviderService.getProvider(providerId);
    },
    saveProvider(data) {
      return saveProviderService.saveProvider(data);
    },
    updateProviderBankData(data) {
      return updateProviderBankData.updateProviderBankData(data);
    },
    exportProviders(params) {
      return exportProvidersService.exportProviders(params);
    },
  };
}
