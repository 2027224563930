import { ILegalPersonForm } from "../../data/models/legalPersonForm";

export interface IGetDataFromReceitaUseCase {
  fetchByCnpj(cnpj: string): Promise<ILegalPersonForm | null>;
}

export interface IGetDataFromReceitaService {
  fetchByCnpj(cnpj: string): Promise<ILegalPersonForm | null>;
}

export class GetDataFromReceitaUseCase implements IGetDataFromReceitaUseCase {
  constructor(private getDataFromReceitaService: IGetDataFromReceitaService) {}

  fetchByCnpj(cnpj: string) {
    return this.getDataFromReceitaService.fetchByCnpj(cnpj);
  }
}
