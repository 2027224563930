import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IBulkDisapproveService {
  bulkDisapprove(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity>;
}

export interface IBulkDisapproveUseCase {
  bulkDisapprove(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity>;
}

export class BulkDisapproveUseCase implements IBulkDisapproveUseCase {
  constructor(private approveService: IBulkDisapproveService) {}

  bulkDisapprove(
    costCenterReportIds: string[],
  ): Promise<ICostCenterReportEntity> {
    return this.approveService.bulkDisapprove(costCenterReportIds);
  }
}
