export interface ICostCenterTransactionInputModel {
  id: string;
  companyGroupId: string;
  costCenterOriginId: string;
  costCenterDestinationId: string;
  transactionDate: string;
  value: number;
  description: string;
  active: boolean;
}

export class CostCenterTransactionInputModel
  implements ICostCenterTransactionInputModel
{
  id = "";
  companyGroupId = "";
  costCenterOriginId = "";
  costCenterDestinationId = "";
  transactionDate = "";
  value = 0;
  description = "";
  active = true;

  constructor(params?: Partial<ICostCenterTransactionInputModel>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterTransactionInputModel>) {
    return new CostCenterTransactionInputModel(params);
  }
}
