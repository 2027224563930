import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { EClassificationAssessmentType } from "../../../domain/entities/classificationAssessment";
import { MakeClassificationAssessment } from "../../../main/makeClassificationAssessment";
import { Container, Loading } from "./styles";
import { SoulTypeahead } from "../../../../core/presentation/components/SoulTypeahead";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import {
  ClassificationAssessmentFormEntity,
  IClassificationAssessmentFormEntity,
} from "../../../domain/entities/classificationAssessmentFormEntity";

interface ClassificationAssessmentFormModalProps {
  isOpen: boolean;
  currentId: string;
  useClassificationAssessment: MakeClassificationAssessment;
  onRequestClose: () => void;
}

interface IClassificationAssessmentModalState {
  macroCategoriesList: ITypeaheadOption[];
}

export function ClassificationAssessmentFormModal({
  isOpen,
  currentId,
  useClassificationAssessment,
  onRequestClose,
}: ClassificationAssessmentFormModalProps) {
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const {
    getClassificationAssessment,
    saveClassificationAssessment,
    listPaymentType,
    listMacroCategories,
  } = useClassificationAssessment;

  const dialog = useSoulDialog();

  const [isCreateMode, setIsCreateMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IClassificationAssessmentFormEntity>({
    mode: "onChange",
    defaultValues: {
      macroCategory: {
        rawValue: "",
        label: "",
      },
    },
  });
  const [paymentTypeList, setPaymentTypeList] = useState<
    { key: number; value: string }[]
  >([]);
  const [state, setState] = useState<IClassificationAssessmentModalState>({
    macroCategoriesList: [],
  });

  const loadListPaymentType = async () => {
    const res = await listPaymentType();
    setPaymentTypeList(res);
  };

  const loadMacroCategories = async () => {
    const res = await listMacroCategories();
    setState(prevState => ({
      ...prevState,
      macroCategoriesList: res,
    }));
  };

  const getCurrentClassificationAssessment = async () => {
    const formValues = await getClassificationAssessment(currentId);
    reset({
      ...formValues,
      macroCategory: formValues.macroCategory ? formValues.macroCategory : null,
    });
  };

  const handleAfterOpen = async () => {
    const isNew = currentId === "";
    setIsCreateMode(isNew);
    setIsLoading(true);
    await loadListPaymentType();
    await loadMacroCategories();

    if (isNew) {
      reset(
        ClassificationAssessmentFormEntity.create({
          companyGroupId: currentCompanyGroup.id,
        }),
      );
    } else {
      await getCurrentClassificationAssessment();
    }

    setIsLoading(false);
  };

  const requestClose = useCallback(() => {
    setIsLoadingButton(false);
    reset();
    onRequestClose();
  }, [onRequestClose, reset]);

  const allowedProfiles = useAllowedProfiles();
  const readonly = useMemo(() => {
    return !allowedProfiles(EUserProfile.master, EUserProfile.supervisor);
  }, [allowedProfiles]);

  const save = useCallback(
    async (data: IClassificationAssessmentFormEntity) => {
      if (readonly) {
        return;
      }

      setIsLoadingButton(true);

      const msg = isCreateMode
        ? "Classificação de Rateio cadastrada com sucesso."
        : "Classificação de Rateio atualizada com sucesso.";

      try {
        await saveClassificationAssessment(data);

        await dialog.fire({
          title: "Feito!",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        });

        requestClose();
      } catch (err) {
        dialog.close();
      }

      setIsLoadingButton(false);
    },
    [
      dialog,
      isCreateMode,
      readonly,
      requestClose,
      saveClassificationAssessment,
    ],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      onAfterOpen={handleAfterOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            {isCreateMode
              ? "Nova Classificação de Rateio"
              : "Editar Classificação de Rateio"}
          </h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {isLoading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              <div className="card card-header-border">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{currentCompanyGroup.name}</h3>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Nome</span>
                  <input
                    {...register("name", { required: true })}
                    id="name"
                    data-testid="txt-name"
                    placeholder="Nome"
                    type="text"
                    disabled={readonly}
                    className={
                      errors.name?.type === "required" ? "isInvalid" : ""
                    }
                  />
                  <InvalidFeedback
                    condition={errors.name?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Macrocategoria</span>
                  <Controller
                    name="macroCategory"
                    control={control}
                    // é necessário garantir essa regra de validação
                    // por causa que o estado do isValid
                    // não estava alterando conforme o elemento do Controller
                    rules={{
                      required: true,
                      validate: value => {
                        return value && value.rawValue && value.label
                          ? true
                          : "Este campo é de preenchimento obrigatório";
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <SoulTypeahead
                            id="sel-macroCategories"
                            data-testid="sel-macroCategories"
                            openOnFocus
                            onChange={field.onChange}
                            value={field.value}
                            options={state.macroCategoriesList}
                            placeholder="Selecione uma macrocategoria"
                            className={fieldState?.error ? "isInvalid" : ""}
                            disabled={readonly}
                          />
                          <InvalidFeedback
                            condition={!!fieldState?.error}
                            message="Este campo é de preenchimento obrigatório"
                          />
                        </>
                      );
                    }}
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Tipo</span>
                  <select
                    id="sel-type"
                    data-testid="sel-type"
                    disabled={readonly}
                    {...register("type", { required: true })}
                  >
                    <option
                      value={EClassificationAssessmentType.empty}
                      disabled
                    >
                      Tipo
                    </option>
                    <option value={EClassificationAssessmentType.expense}>
                      Despesa
                    </option>
                    <option value={EClassificationAssessmentType.revenue}>
                      Receita
                    </option>
                  </select>
                  <InvalidFeedback
                    condition={errors.type?.type === "required"}
                    message="Este campo é de preenchimento obrigatório"
                  />
                </label>
                <label className="col-6 form-control">
                  <span>
                    Tipo de Pagamento <small>(opcional)</small>
                  </span>
                  <select
                    defaultValue=""
                    id="sel-payment-type"
                    data-testid="sel-payment-type"
                    disabled={readonly}
                    {...register("paymentType", { required: false })}
                  >
                    <option value="" disabled>
                      Tipo de Pagamento
                    </option>
                    {paymentTypeList.map(item => (
                      <option key={item.key} value={item.key}>
                        {item.key.toString().padStart(4, "0")} - {item.value}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
              <button
                type="submit"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
                id="btn-save"
                disabled={readonly || isLoadingButton}
                onClick={handleSubmit(save)}
              >
                Salvar {isLoadingButton && <FaSpinner className="spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
