import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 45rem;

  .react-modal-body {
    padding-top: 0 !important;

    .card {
      padding: 1rem;
      background: #090081;
      color: white;
      border-radius: 0.375rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .description,
      .document-number {
        text-align: center;
      }

      & + div {
        margin-top: 1rem;
      }
    }

    .form-container {
      max-height: 25rem;
      border-radius: 0.375rem;
      border: 0.0625rem solid rgb(82, 95, 127, 0.1);

      div + div {
        margin-top: 0.75rem;
      }

      .form-container__header {
        display: flex;
        justify-content: end;
      }

      .form-container__body {
        padding: 1rem;
      }
    }
  }

  .isValid {
    border-color: var(--soul-green-button) !important;

    :focus,
    :focus-within {
      border-color: var(--soul-green-button) !important;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }
  }

  .isEdited {
    border-color: #ff9c00 !important;

    :focus,
    :focus-within {
      border-color: #ff9c00 !important;
      box-shadow: 0 0 0 0.2rem rgba(255, 156, 0, 0.25) !important;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }
`;
