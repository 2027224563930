import { ELocalStorageKeys } from "../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../core/domain/entities/stringfied";
import { IStoreCostCenterReportPanoamaIdService } from "../../domain/usecases/storeCostCenterReportPanoamaIdUseCase";

export class StoreCostCenterReportPanoamaIdService
  implements IStoreCostCenterReportPanoamaIdService
{
  storeCostCenterReportPanoamaId(panoramaId: string): void {
    const key = ELocalStorageKeys.CostCenterReportPanoramaId;

    localStorage.setItem(key, Stringified.stringify(panoramaId) as string);
  }
}
