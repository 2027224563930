import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IProviderEntity } from "../../domain/entities/providerEntity";
import { IToggleProviderStatusService } from "../../domain/usecases/toggleProviderStatusUseCase";

export class ToggleProviderStatusService
  implements IToggleProviderStatusService
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleProviderStatus(id: string): Promise<IProviderEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `Providers/${id}`;

    const response = await this.api.delete<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
