import { InputMask } from "primereact/inputmask";
import { useCallback, useEffect, useState } from "react";
import { FaMinusSquare, FaPlusSquare, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InputCurrency } from "../../../../../core/presentation/components/InputCurrency";
import { ICostCenterTransactionInputEntity } from "../../../../../transactions/costCenterTransactions/domain/entities/costCenterTransactionInputEntity";
import { MakeCostCenterReportManager } from "../../../main/makeCostCenterReportManager";
import { Container, Loading } from "./styles";

interface CostCenterTransactionReadonlyModalProps {
  isOpen: boolean;
  currentId: string;
  useCostCenterReportManager: MakeCostCenterReportManager;
  onRequestClose: () => void;
}

export function CostCenterTransactionReadonlyModal({
  isOpen,
  currentId,
  useCostCenterReportManager,
  onRequestClose,
}: CostCenterTransactionReadonlyModalProps) {
  const { getCostCenterTransaction } = useCostCenterReportManager;
  const [isLoading, setIsLoading] = useState(true);
  const [costCenterTransactionEntity, setCostCenterTransactionEntity] =
    useState<ICostCenterTransactionInputEntity>();

  /** Obtem da API os dados da movimentacao desejada */
  const fetchCostCenterTransaction = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getCostCenterTransaction(currentId);

      setCostCenterTransactionEntity(response);
    } finally {
      setIsLoading(false);
    }
  }, [currentId, getCostCenterTransaction]);

  /** Fecha a modal de consulta de movimentacao de centro de custo */
  const requestClose = useCallback(() => {
    setCostCenterTransactionEntity(undefined);
    onRequestClose();
  }, [onRequestClose]);

  useEffect(() => {
    if (!costCenterTransactionEntity) {
      fetchCostCenterTransaction();
    }
  }, [costCenterTransactionEntity, fetchCostCenterTransaction]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Consulta Movimentação de Centros de Custo</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={() => requestClose()}
          >
            <IoMdClose />
          </button>
        </div>

        {isLoading ? (
          <Loading>
            <FaSpinner className="spinner" />
          </Loading>
        ) : (
          <form className="form-container row">
            <div className="col-12 react-modal-body">
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    Centro de Custo de Origem <FaMinusSquare />
                  </span>
                  <input
                    id="sel-origin"
                    data-testid="sel-origin"
                    type="text"
                    autoComplete="off"
                    value={
                      costCenterTransactionEntity?.costCenterOrigin?.acronym
                    }
                    readOnly
                    disabled
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>
                    Centro de Custo de Destino <FaPlusSquare />
                  </span>
                  <input
                    id="sel-destination"
                    data-testid="sel-destination"
                    type="text"
                    autoComplete="off"
                    value={
                      costCenterTransactionEntity?.costCenterDestination
                        ?.acronym
                    }
                    readOnly
                    disabled
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Data da Movimentação</span>
                  <InputMask
                    data-testid="txt-date"
                    unmask
                    placeholder="dd/mm/aaaa"
                    mask="99/99/9999"
                    value={costCenterTransactionEntity?.transactionDate}
                    readOnly
                    disabled
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-6 form-control">
                  <span>Valor</span>
                  <InputCurrency
                    id="txt-value"
                    data-testid="txt-value"
                    placeholder="Valor"
                    value={costCenterTransactionEntity?.value}
                    disabled
                  />
                </label>
              </div>
              <div className="form-row">
                <label className="col-12 form-control">
                  <span>Descrição</span>
                  <input
                    id="txt-name"
                    data-testid="txt-name"
                    placeholder="Descrição"
                    type="text"
                    autoComplete="off"
                    value={costCenterTransactionEntity?.description}
                    readOnly
                    disabled
                  />
                </label>
              </div>
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                className="form-button red-bkg"
                id="btn-close"
                onClick={() => requestClose()}
              >
                Fechar
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
