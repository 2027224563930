import { Container } from "./styles";

interface InvalidFeedbackProps {
  condition: boolean;
  message: string;
}

export function InvalidFeedback({ condition, message }: InvalidFeedbackProps) {
  return condition ? <Container>{condition && message}</Container> : null;
}

interface InvalidFeedbackListProps {
  errorList: {
    condition: boolean;
    message: string;
  }[];
}

/**
 * TODO - refatorar invalid feedback para objeto que recebe
 * multiplas mensagens e retorna a mensagem referente ao erro
 * ativo.
 *
 * @param param0
 * @returns
 */
export function InvalidFeedbackList({ errorList }: InvalidFeedbackListProps) {
  return (
    <>
      {errorList.map(error => (
        <Container key={error.message}>
          {error.condition && error.message}
        </Container>
      ))}
    </>
  );
}
