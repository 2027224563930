import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  CompanyGroupEntity,
  ICompanyGroupEntity,
} from "../../domain/entities/companyGroupEntity";
import { IToggleCompanyGroupService } from "../../domain/usecases/toggleCompanyGroupUseCase";

export class ToggleCompanyGroupService implements IToggleCompanyGroupService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleCompanyGroup(
    companyGroupId: string,
  ): Promise<ICompanyGroupEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/CompanyGroups/${companyGroupId}`;

    const response = await this.api.delete<ICompanyGroupEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new CompanyGroupEntity(response);
  }
}
