import { ICustomerEntity } from "../entities/customerEntity";

export interface IGetCustomerUseCase {
  getCustomer(customerId: string): Promise<ICustomerEntity>;
}

export interface IGetCustomerService {
  getCustomer(customerId: string): Promise<ICustomerEntity>;
}

export class GetCustomerUseCase implements IGetCustomerUseCase {
  constructor(private getCustomerService: IGetCustomerService) {}

  getCustomer(customerId: string) {
    return this.getCustomerService.getCustomer(customerId);
  }
}
