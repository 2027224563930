import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { ICustomerTypeEntity } from "../../domain/entities/customerTypeEntity";
import { IGetCustomerTypesService } from "../../domain/usecases/getCustomerTypesUseCase";

export class GetCustomerTypesService implements IGetCustomerTypesService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getCustomerTypes(): Promise<ICustomerTypeEntity[]> {
    const userEntity = this.getUserLocalService.get();

    const url = "Enums/SupplierTypes";

    const response = await this.api.get<ICustomerTypeEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
