import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import {
  IFormComponentProps,
  ITypeaheadState,
  ISolicitImportForm,
} from "../../../../domain/entities/solicitImportTypes";
import { useSolicitImportPage } from "../../../hooks/useSolicitImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function CompanyField(props: IFormComponentProps) {
  const { formIndex } = props;

  const formPrefix = `imports.${formIndex}` as const;

  const [state, setState] = useState<ITypeaheadState>({
    options: [],
    loading: false,
  });

  const asyncDebounce = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { useDebtImport, handleInputClassName } = useSolicitImportPage();

  const { searchCompany } = useDebtImport;

  const { clearErrors } = useFormContext<ISolicitImportForm>();

  const handleSearchCompanyChange = async (search = "") => {
    await asyncDebounce(750);

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompany({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        options: companies,
        loading: false,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return (
    <Controller
      name={`${formPrefix}.company`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ISoulTypeaheadChangeEvent) => {
          field.onBlur();
          field.onChange(event);
          clearErrors(`${formPrefix}.payUntil`);
        };
        return (
          <label className="form-control">
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                value={field.value}
                onChange={onChange}
                placeholder="Empresa"
                options={state.options}
                loading={state.loading}
                id={`txt-company-${formIndex}`}
                data-testid={`txt-company-${formIndex}`}
                onSearchChange={handleSearchCompanyChange}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
