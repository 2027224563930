import { useMemo, useRef } from "react";
import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import { ButtomItem } from "./styles";

export enum EAccountSubmitMode {
  Default,
  AddMore,
  Duplicate,
}

interface IAccountFormSplitButtonProps {
  isSubmitting: boolean;
  onClick: (submitMode: EAccountSubmitMode) => void;
}

export function AccountFormSplitButton(props: IAccountFormSplitButtonProps) {
  const { isSubmitting, onClick } = props;

  const splitButtonRef = useRef<SplitButton>(null);

  const onMouseEnter = () => {
    splitButtonRef.current?.show();
  };

  const onMouseLeave = () => {
    splitButtonRef.current?.hide();
  };

  const renderCompleteButton = () => {
    return (
      <span>
        Concluir
        {isSubmitting && <i className="pi pi-spin pi-spinner" />}
      </span>
    );
  };

  const memoizedModel = useMemo<MenuItem[]>(() => {
    const saveAndAddMoreTemplate = () => {
      return (
        <ButtomItem
          type="button"
          className="p-menuitem-link"
          id="btn-complete-add-more"
          data-testid="btn-complete-add-more"
          aria-label="Concluir e adicionar +1"
          onClick={() => {
            onClick(EAccountSubmitMode.AddMore);
          }}
        >
          Concluir e adicionar +1
        </ButtomItem>
      );
    };

    const saveAndDuplicateTemplate = () => {
      return (
        <ButtomItem
          type="button"
          className="p-menuitem-link"
          id="btn-complete-duplicate"
          aria-label="Concluir e duplicar"
          data-testid="btn-complete-duplicate"
          onClick={() => {
            onClick(EAccountSubmitMode.Duplicate);
          }}
        >
          Concluir e duplicar
        </ButtomItem>
      );
    };

    return [
      {
        disabled: isSubmitting,
        template: saveAndAddMoreTemplate,
      },
      {
        disabled: isSubmitting,
        template: saveAndDuplicateTemplate,
      },
    ];
  }, [isSubmitting, onClick]);

  return (
    <SplitButton
      ref={splitButtonRef}
      model={memoizedModel}
      disabled={isSubmitting}
      onMouseLeave={onMouseLeave}
      dropdownIcon="pi pi-chevron-up"
      buttonTemplate={renderCompleteButton}
      buttonClassName="form-button green-bkg"
      onClick={() => {
        onClick(EAccountSubmitMode.Default);
      }}
      menuButtonProps={{
        onMouseEnter,
        onClick: undefined,
      }}
    />
  );
}
