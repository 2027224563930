import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IProviderHasBankingDataContract } from "../../domain/contracts/providerHasBankingDataContract";
import { IProviderEntity } from "../../domain/entities/providerEntity";

export class ProviderHasBankingDataService
  implements IProviderHasBankingDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async providerHasBankingData(providerId: string): Promise<boolean> {
    const url = `/Providers/${providerId}`;

    const userEntity = this.getUserLocalService.get();
    const provider = await this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return !!provider.bank && !!provider.bankBranch && !!provider.bankAccount;
  }
}
