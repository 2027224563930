import { useState } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";
import { usePaymentRequestPage } from "./usePaymentRequestPage";

export function usePaymentRequestsAttachmentsHandler() {
  const {
    reload,
    usePaymentRequests,
    handleCloseAttachmentModal,
    state: {
      attachmentsModal: { rowData, isOpen },
    },
  } = usePaymentRequestPage();

  const dialog = useSoulDialog();
  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const [progressModalState, setProgressModalState] = useState({
    total: 0,
    loaded: 0,
    isOpen: false,
  });

  const {
    getAttachments,
    uploadAttachments,
    getStorageFilebyId,
    listAttachmentTypes,
    updateAttachmentsBarcode,
  } = usePaymentRequests;

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IPaymentRequestAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        companyGroupId,
        billetAttachment,
      );

      return {
        updatedAttachment,
        preventSubmit: false,
      };
    } catch {
      dialog.fire({
        icon: "warning",
        title: "Aviso",
        html: (
          <>
            Não foi possível obter o código de barras desse boleto. Faça a
            inclusão por dentro da solicitação de pagamento e preencha o código
            de barras
          </>
        ),
      });

      return {
        preventSubmit: true,
        updatedAttachment: billetAttachment,
      };
    }
  };

  const onUploadProgress = (event: ProgressEvent) => {
    setProgressModalState({
      isOpen: true,
      total: event.total,
      loaded: event.loaded,
    });
  };

  const handleAttachmentsSubmission = async (
    formAttachments: IPaymentRequestAttachmentEntity[],
  ) => {
    const paymentRequestId = rowData?.id;

    setProgressModalState({
      total: 0,
      loaded: 0,
      isOpen: true,
    });

    if (!paymentRequestId) {
      return;
    }

    try {
      await uploadAttachments({
        onUploadProgress,
        paymentRequestId,
        attachments: formAttachments,
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            Lançamento de Solicitação de Pagamento para o{" "}
            <strong>{rowData.destinationDescription}</strong> atualizado com
            sucesso.
          </>
        ),
      });

      reload();
    } finally {
      setProgressModalState({
        total: 0,
        loaded: 0,
        isOpen: false,
      });

      handleCloseAttachmentModal();
    }
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IPaymentRequestAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck !== EAttachmentType.RPA &&
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.VariableAdditional
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.RPA]: "RPA",
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.VariableAdditional]: "Adicional Variável",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      const documentType = typesName[typeToCheck];

      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{documentType}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const handleAttachmentsModalAfterOpen = async () => {
    const paymentRequestId = rowData?.id;

    if (!paymentRequestId) {
      handleCloseAttachmentModal();
      return [];
    }

    return getAttachments(paymentRequestId);
  };

  return {
    isOpen,
    rowData,
    progressModalState,
    getStorageFilebyId,
    listAttachmentTypes,
    handleCloseAttachmentModal,
    handleAttachmentsSubmission,
    handleAttachmentsModalAfterOpen,
    attachmentTypeCheck: handleAttachmentTypeChange,
    updateAttachmentsBarcode: handleUpdateAttachmentsBarcode,
  };
}
