import { ProgressSpinner } from "primereact/progressspinner";
import { FormProvider } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useAccountPayableFeeFormModal } from "../../hooks/useAccountPayableFeeFormModal";
import { AccountPayableFeeFormInput } from "../AccountPayableFeeFormInput";
import { Container, ReadOnlyFormControl } from "./styles";

export function AccountPayableFeeFormModal() {
  const {
    feeModalOpen,
    handleRequestClose,
    handleAfterOpen,
    handleAfterClose,
    state,
    form,
    handleSubmit,
    submit,
    format,
    disableClearButton,
    handleClearFeesButtonClick,
    isValid,
    noFeeIsEditable,
  } = useAccountPayableFeeFormModal();

  return (
    <Modal
      isOpen={feeModalOpen}
      onRequestClose={handleRequestClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Impostos</h4>
          <button
            className="react-modal-close"
            id="btn-cross"
            data-testid="btn-cross"
            type="button"
            onClick={handleRequestClose}
          >
            <IoMdClose />
          </button>
        </div>

        {state.loading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : (
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(submit)}>
              <div className="react-modal-body pt-0">
                <div className="card">
                  <div className="card-body">
                    Preencha o valor do(s) imposto(s) contido(s) nesse
                    lançamento.
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>Imposto</th>
                      <th>Valor (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!state.fees?.length && (
                      <tr>
                        <td className="no-fee-row" colSpan={2}>
                          Nenhum imposto encontrado
                        </td>
                      </tr>
                    )}
                    {!!state.fees.length &&
                      state.fees.map((fee, index) => {
                        return (
                          <tr key={fee.feeId}>
                            <td>{fee.name}</td>
                            <td>
                              {fee.editable && (
                                <AccountPayableFeeFormInput index={index} />
                              )}
                              {!fee.editable && (
                                <ReadOnlyFormControl>
                                  {format(fee.value)}
                                </ReadOnlyFormControl>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div className="react-modal-footer">
                <button
                  type="button"
                  className="form-button clear-button"
                  id="btn-clear"
                  data-testid="btn-clear"
                  disabled={disableClearButton}
                  onClick={handleClearFeesButtonClick}
                >
                  Limpar{" "}
                  {state.deletingFees && (
                    <i className="pi pi-spin pi-spinner" />
                  )}
                </button>
                <button
                  type="button"
                  className="form-button red-bkg"
                  id="btn-close"
                  data-testid="btn-close"
                  onClick={handleRequestClose}
                >
                  Fechar
                </button>

                {!state.isCanceledAndHasFeeRetention && (
                  <button
                    type="submit"
                    className={`form-button ${
                      isValid ? "green-bkg" : "invalid-bkg"
                    }`}
                    id="btn-submit"
                    data-testid="btn-submit"
                    disabled={
                      state.submitting || state.deletingFees || noFeeIsEditable
                    }
                  >
                    Confirmar{" "}
                    {state.submitting && (
                      <i className="pi pi-spin pi-spinner" />
                    )}
                  </button>
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </Container>
    </Modal>
  );
}
