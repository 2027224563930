import { InputMask } from "primereact/inputmask";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useEmailValidator } from "../../../../core/presentation/hooks/useEmailValidator";
import { Container } from "./styles";

export function ForeignPersonForm() {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const emailValidator = useEmailValidator();

  const validateEmail = (email: string) => {
    if (email.length > 0) {
      const invalidEmail = emailValidator(email);

      return invalidEmail ? "invalidEmail" : true;
    }
    return true;
  };

  return (
    <Container>
      <div className="form-header">Dados Estrangeiro</div>
      <div className="form-container">
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Documento <small>(opcional)</small>
            </span>
            <input
              {...register("document", { maxLength: 100 })}
              placeholder="Documento"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>Nome</span>
            <input
              {...register("name", { required: true, maxLength: 100 })}
              className={errors.name?.type === "required" ? "isInvalid" : ""}
              maxLength={100}
              placeholder="Nome"
              data-testid="foreigner-name"
            />
            <InvalidFeedback
              condition={errors?.name?.type === "required"}
              message="Este campo é de preenchimento obrigatório"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Telefone <small>(opcional)</small>
            </span>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                maxLength: 11,
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  onChange={onChange}
                  autoClear={false}
                  placeholder="Telefone"
                  unmask
                  mask="(99) 99999-9999"
                  value={value}
                  maxLength={11}
                />
              )}
            />
          </label>
          <label className="col-6 form-control">
            <span>
              E-mail <small>(opcional)</small>
            </span>
            <input
              {...register("email", {
                maxLength: 100,
                validate: (v: string) => validateEmail(v),
              })}
              className={
                errors?.email?.message === "invalidEmail" ? "isInvalid" : ""
              }
              placeholder="E-mail"
            />
            <InvalidFeedback
              condition={errors?.email?.message === "invalidEmail"}
              message="Formato de e-mail inválido"
            />
          </label>
        </div>
      </div>
    </Container>
  );
}
