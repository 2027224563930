import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IVerifyHasGeneratedRemittancesContract } from "../../domain/contracts/verifyHasGeneratedRemittancesContract";
import { IAccountPayableListItemEntity } from "../../domain/entities/accountPayableListItemEntity";

export class VerifyHasGeneratedRemittancesService
  implements IVerifyHasGeneratedRemittancesContract
{
  constructor(
    private api: IApiService,
    private getUserLocalService: IGetUserLocalService,
  ) {}

  async verifyHasGeneratedRemittances(
    selectedAccounts: IAccountPayableListItemEntity[],
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = "Remittances/HasGeneratedRemittances";

    const uniqueMap = new Map(
      selectedAccounts.map(account => {
        const uniqueKey = `${account.companyId}-${account.paymentAccountId}`;
        return [uniqueKey, account];
      }),
    );

    const uniqueAccounts = Array.from(uniqueMap.values());

    const payload = {
      payers: uniqueAccounts.map(account => ({
        companyId: account.companyId,
        paymentAccountId: account.paymentAccountId,
        releases: selectedAccounts
          .filter(
            release =>
              release.companyId === account.companyId &&
              release.paymentAccountId === account.paymentAccountId,
          )
          .map(release => {
            return {
              value: release.value,
              payUntil: release.payUntil,
              accountPayableId: release.id,
              providerId: release.providerId,
            };
          }),
      })),
    };

    const response = await this.api.post<boolean>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return Boolean(response);
  }
}
