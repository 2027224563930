import styled from "styled-components";

export const Card = styled.div`
  border-radius: calc(0.375rem - 0.0625rem);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);

  * + & {
    margin-top: 1rem;
  }

  header {
    border-radius: calc(0.375rem - 0.0625rem) calc(0.375rem - 0.0625rem) 0 0;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.05);
    padding: 0.5rem 0.9375rem;
    display: flex;
  }

  section {
    border-radius: 0 0 calc(0.375rem - 0.0625rem) calc(0.375rem - 0.0625rem);
    padding: 0.5rem 0.9375rem;
  }
`;
