import { ProgressBar, ProgressBarModeType } from "primereact/progressbar";
import styled from "styled-components";
import Modal from "react-modal";
import { ReactNode } from "react";

const Container = styled.div`
  padding: 2rem;
  font-size: 0.975rem;

  .progress-modal__body,
  .progress-bar {
    margin: 1rem 0;
  }

  .export-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
    height: 2em;

    i.pi {
      margin-left: 0.5rem;
    }
  }
`;

interface CommonProgressModalProps {
  children?: ReactNode;
  isOpen?: boolean;
}

interface DeterminateProgressModalProps {
  total: number;
  loaded: number;
  mode?: Extract<ProgressBarModeType, "determinate">;
}

interface IndeterminateProgressModalProps {
  total?: never;
  loaded?: never;
  mode: Extract<ProgressBarModeType, "indeterminate">;
}

type IRequestProgressModalProps = CommonProgressModalProps &
  (DeterminateProgressModalProps | IndeterminateProgressModalProps);

export function RequestProgressModal({
  total,
  loaded,
  isOpen = false,
  mode = "determinate",
  children = <p>Por favor, não feche a página até o fim da operação</p>,
}: IRequestProgressModalProps) {
  const value = loaded && total ? Math.round((loaded / total) * 100) : null;

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <div className="progress-modal__body">{children}</div>
        <div className="progress-bar">
          <ProgressBar
            mode={mode}
            value={value}
            showValue={false}
            style={{ height: "0.375rem" }}
          />
        </div>
        <div className="export-modal-footer">
          Processando dados <i className="pi pi-spin pi-spinner" />
        </div>
      </Container>
    </Modal>
  );
}
