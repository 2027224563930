import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";

export interface IGenerateInvoiceIListItemEntity {
  rowIndex: number;
  id: string;
  companyName: string;
  documentNumber: string;
  customerCorporationName: string;
  totalValue: number;
  serviceFramework: ITypeaheadOption | null;
  cnae: ITypeaheadOption | null;
  issAliquot: number;
  invoiceDescription: string;

  isIssRetainedAtSource: boolean;
  serviceSupplyState: ITypeaheadOption | null;
  serviceSupplyCity: ITypeaheadOption | null;
  irAliquot: number;
  csllAliquot: number;
  pisAliquot: number;
  cofinsAliquot: number;
}

export class GenerateInvoiceIListItemEntity
  implements IGenerateInvoiceIListItemEntity
{
  rowIndex = 0;
  id = "";
  companyName = "";
  documentNumber = "";
  customerCorporationName = "";
  totalValue = 0;
  serviceFramework = null;
  cnae = null;
  issAliquot = 0;
  invoiceDescription = "";
  isIssRetainedAtSource = false;
  serviceSupplyState = null;
  serviceSupplyCity = null;
  irAliquot = 0;
  csllAliquot = 0;
  pisAliquot = 0;
  cofinsAliquot = 0;

  constructor(params?: Partial<IGenerateInvoiceIListItemEntity>) {
    Object.assign(this, params);
  }
}
