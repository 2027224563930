import {
  ICheckEmailAlreadyExistsPayloadModel,
  ICheckEmailAlreadyExistsResponseModel,
} from "../../data/models/checkEmailAlreadyExistsModel";

export interface ICheckEmailAlreadyExistsService {
  checkEmailAlreadyExists(
    checkEmailAlreadyExistPayloadModel: ICheckEmailAlreadyExistsPayloadModel,
  ): Promise<ICheckEmailAlreadyExistsResponseModel>;
}

export interface ICheckEmailAlreadyExistsUseCase {
  checkEmailAlreadyExists(
    checkEmailAlreadyExistPayloadModel: ICheckEmailAlreadyExistsPayloadModel,
  ): Promise<ICheckEmailAlreadyExistsResponseModel>;
}

export class CheckEmailAlreadyExistsUseCase
  implements ICheckEmailAlreadyExistsUseCase
{
  constructor(
    private checkEmailAlreadyExistsService: ICheckEmailAlreadyExistsService,
  ) {}

  checkEmailAlreadyExists(
    checkEmailAlreadyExistPayloadModel: ICheckEmailAlreadyExistsPayloadModel,
  ): Promise<ICheckEmailAlreadyExistsResponseModel> {
    return this.checkEmailAlreadyExistsService.checkEmailAlreadyExists(
      checkEmailAlreadyExistPayloadModel,
    );
  }
}
