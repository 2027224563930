import { IApiService } from "../../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { IGetCostCentersTransactionsImportationTemplateService } from "../../domain/usecases/getCostCentersTransactionsImportationTemplateUseCase";

export class GetCostCentersTransactionsImportationTemplateService
  implements IGetCostCentersTransactionsImportationTemplateService
{
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getCostCentersTransactionsImportationTemplate() {
    const userEntity = this.getUserLocalService.get();

    const url = "CostCenterTransactionsImportation/Template";

    const response = await this.api.get<string>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
