import { isValid, parse } from "date-fns";
import { InputMaskChangeParams } from "primereact/inputmask";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";

enum EInvalidFeedback {
  None = "",
  Required = "Este campo é obrigatório",
  InvalidFormat = "Formato de data inválido",
}

interface IBulkTerminateModalState {
  search: string;
  submitting: boolean;
  terminationDate: string;
  isInvalid: boolean;
  invalidFeedback: EInvalidFeedback;
  usePayUntil: boolean;
}

export function useBulkTerminateModal() {
  // REVIEW substiuir os campos do form que estão no state pelo react-hook-forms
  // para clarificar as validacoes do form e faclitar a manutencao futura
  const [state, setState] = useState<IBulkTerminateModalState>({
    search: "",
    submitting: false,
    terminationDate: "",
    isInvalid: false,
    usePayUntil: false,
    invalidFeedback: EInvalidFeedback.None,
  });

  const {
    useAccountsPayable,
    handleBulkTerminateModalClose,
    clearSelection,
    reload,
    ...rest
  } = useAccountsPayablePage();

  const { selection, terminateModalOpen, bulkTerminateModalOpen } = rest.state;

  const { bulkTerminateAccounts } = useAccountsPayable;

  const dialog = useSoulDialog();

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar os estados da modal
   */
  const handleModalAfterClose = () => {
    setState({
      search: "",
      submitting: false,
      terminationDate: "",
      isInvalid: false,
      invalidFeedback: EInvalidFeedback.None,
      usePayUntil: false,
    });
  };

  /** Lida com o evento de click do botao fechar da modal */
  const handleCloseButtonOnClick = useCallback(() => {
    handleBulkTerminateModalClose();
  }, [handleBulkTerminateModalClose]);

  /** Lida com a validacao do campo de data */
  const validateTerminationDate = (value: string, usePayUntil: boolean) => {
    let isInvalid = false;
    let invalidFeedback = EInvalidFeedback.None;

    if (usePayUntil) {
      return { isInvalid: false, invalidFeedback: EInvalidFeedback.None };
    }

    if (value === "") {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.Required;

      return { isInvalid, invalidFeedback };
    }

    const dtTerminationDate = parse(
      value.replace(/\D/g, ""),
      "ddMMyyyy",
      new Date(),
    );

    if (value.length < 8 || !isValid(dtTerminationDate)) {
      isInvalid = true;
      invalidFeedback = EInvalidFeedback.InvalidFormat;

      return { isInvalid, invalidFeedback };
    }

    return { isInvalid: false, invalidFeedback: EInvalidFeedback.None };
  };

  /**
   * Baixa as contas a pagar selecionadas.
   */
  const terminateAccounts = async (terminationDate: string) => {
    if (!selection) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      submitting: true,
    }));

    const accountPayableIds = selection.map(selectedAccount => {
      return selectedAccount.id;
    });

    try {
      await bulkTerminateAccounts({
        accountPayableIds,
        terminationDate,
        usePayUntil: state.usePayUntil,
      });

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamentos baixados com sucesso!",
      });

      handleBulkTerminateModalClose();
      clearSelection();

      reload();
    } finally {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }));
    }
  };

  /** Lida como submit dos dados da modal */
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const { terminationDate, usePayUntil } = state;

    const { isInvalid, invalidFeedback } = validateTerminationDate(
      terminationDate,
      usePayUntil,
    );

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
    }));

    if (isInvalid) {
      return;
    }

    terminateAccounts(terminationDate);
  };

  /** Lida com o evento de troca de valor do campo de data */
  const handleDateChange = (event: InputMaskChangeParams) => {
    const terminationDate = event.target.value || "";

    const { isInvalid, invalidFeedback } = validateTerminationDate(
      terminationDate,
      state.usePayUntil,
    );

    setState(prevState => ({
      ...prevState,
      isInvalid,
      invalidFeedback,
      terminationDate,
    }));
  };

  /** Lida com o evento de troca do valor do campo de pesquisa da grid de anexos */
  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    setState(prevState => ({
      ...prevState,
      search,
    }));
  };

  /**
   * Decide se o form esta invalido ou nao, usado
   * para determinar a aparencia do botao submit
   */
  const isInvalid = useMemo(() => {
    return (
      state.isInvalid || (state.terminationDate === "" && !state.usePayUntil)
    );
  }, [state.isInvalid, state.terminationDate, state.usePayUntil]);

  const isAnyAccountAlreadyTerminated =
    selection?.some(
      selectedAccount => selectedAccount.status !== EAccountPayableStatus.Open,
    ) || false;

  /**
   * Lida com a alteração no checkbox de "Data pagar em".
   * Caso esteja marcado, o formulário passa a ser válido.
   * Independente do valor, sempre limpa o campo de data.
   */
  const handlePayUntilChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;

      setState(old => ({
        ...old,
        isInvalid: !value,
        usePayUntil: value,
        terminationDate: "",
        invalidFeedback: EInvalidFeedback[value ? "None" : "Required"],
      }));
    },
    [],
  );

  return {
    terminateModalOpen,
    handleCloseButtonOnClick,
    state,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    handleInputSearchChange,
    isInvalid,
    bulkTerminateModalOpen,
    handlePayUntilChange,
    isAnyAccountAlreadyTerminated,
  };
}
