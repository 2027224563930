import { useCallback, useMemo } from "react";
import { FaList } from "react-icons/fa";
import { SimpleColumn } from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IImportationMatchesPaymentAccountEntity } from "../../domain/entities/importationMatchesPaymentAccountEntitty";
import { EImportationMatchesOrigin } from "../../domain/entities/importationMatchesPayloadEntity";

interface UseImportationMatchesPaymentAccountsGridProps {
  origin: EImportationMatchesOrigin;
  openMatchModal: (
    paymentAccount: IImportationMatchesPaymentAccountEntity,
  ) => void;
}

export function useImportationMatchesPaymentAccountsGrid(
  props: UseImportationMatchesPaymentAccountsGridProps,
) {
  const { origin, openMatchModal } = props;

  /** Constrói a célula que indica quando uma conta de pagamento está ativa. */
  const activeBodyTemplate = useCallback(
    (paymentAccount: IImportationMatchesPaymentAccountEntity) => {
      const active = paymentAccount?.active;
      return (
        <span
          className={`importation-badge ${active ? "blue-bkg" : "red-bkg"}`}
        >
          {active ? "Ativo" : "Inativo"}
        </span>
      );
    },
    [],
  );

  /** Constrói a célula para abrir o modal de vínculos de bancos LMS. */
  const matchBankBodyTemplate = useCallback(
    (paymentAccount: IImportationMatchesPaymentAccountEntity) => {
      const originName =
        origin === EImportationMatchesOrigin.LMS ? "LMS" : "Solution";

      return (
        <div className="table-actionbar">
          <button
            type="button"
            data-place="left"
            data-effect="solid"
            className="outline-button tool"
            data-tip={`Vincular Banco do ${originName}`}
            onClick={() => {
              openMatchModal(paymentAccount);
            }}
          >
            <FaList />
          </button>
        </div>
      );
    },
    [openMatchModal, origin],
  );

  const columns = useMemo(() => {
    return [
      {
        field: "companyName",
        header: "Empresa",
        searchable: true,
        orderable: true,
      },
      { field: "name", header: "Nome", searchable: true, orderable: true },
      {
        field: "active",
        header: "Ativo",
        orderable: true,
        searchable: false,
        bodyTemplate: activeBodyTemplate,
      },
      {
        header: "",
        searchable: false,
        orderable: false,
        bodyTemplate: matchBankBodyTemplate,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, matchBankBodyTemplate]);

  return {
    columns,
  };
}
