import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { GetPaymentAccountTransactionService } from "../data/services/getPaymentAccountTransactionsService";
import { ListCompaniesService } from "../data/services/listCompaniesService";
import { ListPaymentAccountTransactionsService } from "../data/services/listCostCenterTransactionssService";
import { ListPaymentAccountsByCompanyService } from "../data/services/listPaymentAccountsByCompanyService";
import { SearchUsersService } from "../data/services/listUsersService";
import { SavePaymentAccountTransactionService } from "../data/services/savePaymentAccountTransactionService";
import { SearchPaymentAccountsService } from "../data/services/searchPaymentAccountsService";
import { TogglePaymentAccountTransactionService } from "../data/services/togglePaymentAccountTransactionService";
import {
  IGetPaymentAccountTransactionUseCase,
  GetPaymentAccountTransactionUseCase,
} from "../domain/usecases/getPaymentAccountTransactionUseCase";
import {
  IListCompaniesUseCase,
  ListCompaniesUseCase,
} from "../domain/usecases/listCompaniesUseCase";
import {
  IListPaymentAccountTransactionsUseCase,
  ListPaymentAccountTransactionsUseCase,
} from "../domain/usecases/listPaymentAccountTransactionsUseCase";
import {
  IListPaymentAccountsByCompanyUseCase,
  ListPaymentAccountsByCompanyUseCase,
} from "../domain/usecases/listPaymentAccountByCompanyUseCase";
import {
  ISavePaymentAccountTransactionUseCase,
  SavePaymentAccountTransactionUseCase,
} from "../domain/usecases/savePaymentAccountTransactionUseCase";
import {
  ISearchPaymentAccountsUseCase,
  SearchPaymentAccountsUseCase,
} from "../domain/usecases/searchPaymentAccountsUseCase";
import {
  ISearchUsersUseCase,
  SearchUsersUseCase,
} from "../domain/usecases/searchUsersUseCase";
import {
  ITogglePaymentAccountTransactionUseCase,
  TogglePaymentAccountTransactionUseCase,
} from "../domain/usecases/togglePaymentAccountTransactionUseCase";
import { IListAttachmentTypesContract } from "../../../core/domain/contracts/listAttachmentTypesContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFileByIdContract";
import { IListPaymentAccountTransactionAttachmentsContract } from "../domain/contracts/listPaymentAccountTransactionAttachmentsContract";
import { IUploadPaymentAccountTransactionAttachmentsContract } from "../domain/contracts/uploadPaymentAccountTransactionAttachmentsContract";
import { ListAttachmentTypesService } from "../../../core/data/services/listAttachmentTypesService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFileByIdService";
import { ListPaymentAccountTransactionAttachmentsService } from "../data/services/listPaymentAccountTransactionAttachmentsService";
import { UploadPaymentAccountTransactionAttachmentsService } from "../data/services/uploadPaymentAccountTransactionAttachmentsService";

export type MakePaymentAccountTransactions =
  IGetPaymentAccountTransactionUseCase &
    IListPaymentAccountTransactionsUseCase &
    ITogglePaymentAccountTransactionUseCase &
    IListPaymentAccountsByCompanyUseCase &
    ISearchUsersUseCase &
    ISearchPaymentAccountsUseCase &
    IListCompaniesUseCase &
    ISavePaymentAccountTransactionUseCase &
    IListAttachmentTypesContract &
    IGetStorageFilebyIdContract &
    IListPaymentAccountTransactionAttachmentsContract &
    IUploadPaymentAccountTransactionAttachmentsContract;

export function makePaymentAccountTransactions(
  api: IApiService,
  authApi: IApiService,
): MakePaymentAccountTransactions {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getPaymentAccountTransactionService =
    new GetPaymentAccountTransactionService(getUserLocalService, api);
  const getPaymentAccountTransactionsUseCase =
    new GetPaymentAccountTransactionUseCase(
      getPaymentAccountTransactionService,
    );

  const listPaymentAccountTransactionsService =
    new ListPaymentAccountTransactionsService(getUserLocalService, api);
  const listPaymentAccountTransactionsUseCase =
    new ListPaymentAccountTransactionsUseCase(
      listPaymentAccountTransactionsService,
    );

  const togglePaymentAccountTransactionService =
    new TogglePaymentAccountTransactionService(getUserLocalService, api);
  const togglePaymentAccountTransactionUseCase =
    new TogglePaymentAccountTransactionUseCase(
      togglePaymentAccountTransactionService,
    );

  const savePaymentAccountTransactionService =
    new SavePaymentAccountTransactionService(getUserLocalService, api);
  const savePaymentAccountTransactionUseCase =
    new SavePaymentAccountTransactionUseCase(
      savePaymentAccountTransactionService,
    );

  const listPaymentAccountsByCompanySvc =
    new ListPaymentAccountsByCompanyService(getUserLocalService, api);

  const listPaymentAccountsByCompanyUseCase =
    new ListPaymentAccountsByCompanyUseCase(listPaymentAccountsByCompanySvc);

  const searchUsersService = new SearchUsersService(
    getUserLocalService,
    authApi,
  );
  const searchUsersUseCase = new SearchUsersUseCase(searchUsersService);

  const searchPaymentAccountsService = new SearchPaymentAccountsService(
    getUserLocalService,
    api,
  );
  const searchPaymentAccountsUseCase = new SearchPaymentAccountsUseCase(
    searchPaymentAccountsService,
  );

  const listCompaniesService = new ListCompaniesService(
    getUserLocalService,
    api,
  );
  const listCompaniesUseCase = new ListCompaniesUseCase(listCompaniesService);

  const listAttachmentTypeService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listPaymentAccountTransactionAttachmentsService =
    new ListPaymentAccountTransactionAttachmentsService(
      getUserLocalService,
      api,
    );

  const uploadPaymentAccountTransactionAttachmentsService =
    new UploadPaymentAccountTransactionAttachmentsService(
      getUserLocalService,
      api,
    );

  return {
    getPaymentAccountTransaction(PaymentAccountTransactionsId) {
      return getPaymentAccountTransactionsUseCase.getPaymentAccountTransaction(
        PaymentAccountTransactionsId,
      );
    },
    listPaymentAccountTransactions(companyGroupId, payload) {
      return listPaymentAccountTransactionsUseCase.listPaymentAccountTransactions(
        companyGroupId,
        payload,
      );
    },
    togglePaymentAccountTransaction(PaymentAccountTransactionsId: string) {
      return togglePaymentAccountTransactionUseCase.togglePaymentAccountTransaction(
        PaymentAccountTransactionsId,
      );
    },
    savePaymentAccountTransaction(PaymentAccountTransactions) {
      return savePaymentAccountTransactionUseCase.savePaymentAccountTransaction(
        PaymentAccountTransactions,
      );
    },
    listPaymentAccountsByCompany(companyGroupId, companyId, actives?) {
      return listPaymentAccountsByCompanyUseCase.listPaymentAccountsByCompany(
        companyGroupId,
        companyId,
        actives,
      );
    },
    searchUsers(search, fetchMinLength) {
      return searchUsersUseCase.searchUsers(search, fetchMinLength);
    },
    searchPaymentAccounts(companyGroupId, search?, fetchMinLength?) {
      return searchPaymentAccountsUseCase.searchPaymentAccounts(
        companyGroupId,
        search,
        fetchMinLength,
      );
    },
    listCompanies(companyGroupId, actives) {
      return listCompaniesUseCase.listCompanies(companyGroupId, actives);
    },
    listAttachmentTypes() {
      return listAttachmentTypeService.listAttachmentTypes();
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listPaymentAccountTransactionAttachments(PaymentAccountTransactionId) {
      return listPaymentAccountTransactionAttachmentsService.listPaymentAccountTransactionAttachments(
        PaymentAccountTransactionId,
      );
    },
    uploadPaymentAccountTransactionAttachments(params) {
      return uploadPaymentAccountTransactionAttachmentsService.uploadPaymentAccountTransactionAttachments(
        params,
      );
    },
  };
}
