import { format, parse } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IProjectTransactionEntity,
  ProjectTransactionEntity,
} from "../../domain/entities/projectTransactionEntity";
import { IProjectTransactionInputEntity } from "../../domain/entities/projectTransactionInputEntity";
import { ISaveProjectTransactionervice } from "../../domain/usecases/saveProjectTransactionUseCase";
import { ProjectTransactionInputModel } from "../models/projectTransactionInputModel";

export class SaveProjectTransactionervice
  implements ISaveProjectTransactionervice
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveProjectTransaction(
    projectTransaction: IProjectTransactionInputEntity,
  ): Promise<IProjectTransactionEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/ProjectTransactions`;

    const { transactionDate } = projectTransaction;

    const payload = new ProjectTransactionInputModel({
      id: projectTransaction.id,
      companyGroupId: projectTransaction.companyGroupId,
      projectOriginId: projectTransaction.projectOrigin?.id,
      projectDestinationId: projectTransaction.projectDestination?.id,
      transactionDate: format(
        parse(transactionDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      ),
      value: projectTransaction.value,
      description: projectTransaction.description,
      active: projectTransaction.active,
    });

    let response;

    if (projectTransaction.id === "") {
      response = await this.api.post<IProjectTransactionEntity>(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    } else if (projectTransaction.id !== "") {
      response = await this.api.put<IProjectTransactionEntity>(url, payload, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return new ProjectTransactionEntity(response);
  }
}
