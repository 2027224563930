import { SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
} from "primereact/autocomplete";
import { FaExclamationCircle } from "react-icons/fa";
import { IBankEntity } from "../../../../core/domain/entities/bankEntity";

interface SearchBanksFieldProps {
  isInvalid: boolean;
  isDisabled: boolean;
  bankList: IBankEntity[];
  currentBank: IBankEntity | null;
  onChange(e: AutoCompleteChangeParams): void;
}

export function SearchBanksField(props: SearchBanksFieldProps) {
  const { currentBank, isDisabled, isInvalid, bankList, onChange } = props;

  const bankSuggestions = useMemo(
    () => bankList.map(b => ({ ...b, value: `${b.key} - ${b.value}` })),
    [bankList],
  );

  const autoCompleteRef = useRef<AutoComplete | null>();

  const [inputWidth, setInputWidth] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<IBankEntity[]>(
    [],
  );

  /**
   * Ao completar a digitação, realiza a filtragem das sugestões.
   */
  const handleComplete = useCallback(
    async (e: AutoCompleteCompleteMethodParams) => {
      const completeResult = bankSuggestions.filter(suggestion => {
        const lowerCase = e.query.toLowerCase();
        return suggestion.value?.toLowerCase()?.includes(lowerCase);
      });
      setFilteredSuggestions(completeResult.length ? completeResult : []);
    },
    [bankSuggestions],
  );

  /**
   * Destaca o item que estiver selecionado. Exibe opção personalizada para o
   * caso de filtros sem resultados.
   */
  const handleItemTemplate = useCallback(
    (suggestion: IBankEntity | "EMPTY") => {
      if (suggestion === "EMPTY") {
        return (
          <span className="no-registers p-highlight">
            <FaExclamationCircle />
            <span>Nenhuma opção disponível</span>
          </span>
        );
      }

      const isSelected = currentBank?.key === suggestion.key || false;

      return (
        <span className={isSelected ? "selected" : ""}>{suggestion.value}</span>
      );
    },
    [currentBank],
  );

  /**
   * Exibe o dropdown de maneira imperativa.
   */
  const openDropdown = useCallback((e: SyntheticEvent) => {
    if (!["click", "change", "focus"].includes(e.type)) return;
    if (!autoCompleteRef.current) return;
    const currentValue = (e.target as HTMLInputElement).value;
    autoCompleteRef.current.search(e, currentValue, "dropdown");
  }, []);

  return (
    <AutoComplete
      field="value"
      id="sel-bank"
      forceSelection
      value={currentBank}
      onChange={onChange}
      disabled={isDisabled}
      data-testid="sel-bank"
      onClick={openDropdown}
      onClear={openDropdown}
      onFocus={openDropdown}
      completeMethod={handleComplete}
      placeholder="Selecione um Banco"
      itemTemplate={handleItemTemplate}
      panelStyle={{ width: inputWidth }}
      panelClassName="soul-auto-complete"
      inputClassName={isInvalid ? "isInvalid" : ""}
      suggestions={filteredSuggestions.length ? filteredSuggestions : ["EMPTY"]}
      ref={originalRef => {
        autoCompleteRef.current = originalRef;
      }}
      inputRef={ref => setInputWidth(ref?.getBoundingClientRect().width || 0)}
    />
  );
}
