import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGetCityByNameService } from "../../domain/usecases/getCityByNameUseCase";
import { ICityResponse } from "../models/cityResponse";

export class GetCityByNameService implements IGetCityByNameService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getCityByName(
    stateId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ICityResponse[]>> {
    const userEntity = this.getUserLocalService.get();

    let url = "";

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    url = `States/${stateId}/Cities?${params}`;

    const res = await this.api.get<IResponseEntity<ICityResponse[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return res;
  }
}
