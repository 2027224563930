import { ICompetencyEntity } from "../entities/competencyEntity";

export interface IGetCompetencyService {
  getCompetency(comptencyId: string): Promise<ICompetencyEntity>;
}

export interface IGetCompetencyUseCase {
  getCompetency(comptencyId: string): Promise<ICompetencyEntity>;
}

export class GetCompetencyUseCase implements IGetCompetencyUseCase {
  constructor(private getCompetencyService: IGetCompetencyService) {}

  getCompetency(comptencyId: string): Promise<ICompetencyEntity> {
    return this.getCompetencyService.getCompetency(comptencyId);
  }
}
