import { ColumnFilterOperationType } from "../../domain/entities/advTableColumn";
import { Stringified } from "../../../core/domain/entities/stringfied";

export enum EColumnFilterType {
  Relationship = "relationship",
  Enum = "enum",
  Text = "text",
  Date = "date",
  Numeric = "numeric",
  Decimal = "decimal",
}

export type OrderType = "asc" | "desc" | "";

export interface IPanoramaColumnAngularEntity {
  defaultWidth: number;
  field: string;
  filtered: boolean;
  filters: {
    exceptSearchStr: string;
    exceptSearchValue: string;
    field: string;
    valueField: string;
    operation: ColumnFilterOperationType;
    order: OrderType;
    searchStr: string;
    searchStr2: string;
    searchTypeahead: string;
    searchValue: string;
    searchValue2: string;
    type: EColumnFilterType;
  } | null;
  hidden: boolean;
  searchable: boolean;
  selected: boolean;
  userWidth: number | null;
  visible: boolean;
}

export interface IPanoramaAngularDefEntity {
  selectedColumns: IPanoramaColumnAngularEntity[];
}

export interface IPanoramaAngularModel {
  id?: string;
  name: string;
  userId: string;
  active: boolean;
  objectBody: Stringified<IPanoramaAngularDefEntity>;
}
