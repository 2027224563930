import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IValidateTokenService } from "../../domain/usecases/validateTokenUseCase";
import { IValidateTokenModel } from "../models/validateTokenModel";

export class ValidateTokenService implements IValidateTokenService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async validateToken(): Promise<IValidateTokenModel> {
    const userEntity = this.getUserLocalService.get();

    const url = "/Validations/ValidateToken";

    const response = await this.authApi.post<IValidateTokenModel>(url, {
      token: userEntity?.token,
    });

    return response;
  }
}
