import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 540px;

  .react-modal-body {
    padding-top: 0 !important;

    .truncate {
      width: 25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .form-row + .grid-row {
      margin-top: 1.5rem;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  #receivable__terminate-modal__disclaimer {
    display: block;
    color: var(--color-danger);
  }
`;
