import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react";
import {
  IGenericAssessmentEntity,
  IGenericAssessmentFormEntity,
} from "../../domain/entities/genericAssessmentEntitty";

BigNumber.set({ DECIMAL_PLACES: 15 });

interface UseCalculateAssessmentsValuesParams {
  accountValue: number;
  editAssessmentIndex: number | null;
  originalAssessments: IGenericAssessmentEntity[];
}

interface ICalculateTotalValueParams {
  ignoreIndex?: number | null;
  list: IGenericAssessmentEntity[];
}

interface ICalculateValuesFromPercetangeMaskParams {
  index: number;
  percentage: number;
  currentFormValuesList: IGenericAssessmentFormEntity[];
}

const brlFmt = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
}).format;

const percentFmt = new Intl.NumberFormat("pt-BR", {
  signDisplay: "never",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  trailingZeroDisplay: "stripIfInteger",
} as object).format;

export function useCalculateAssessmentsValues({
  accountValue,
  originalAssessments,
  editAssessmentIndex,
}: UseCalculateAssessmentsValuesParams) {
  const calculateListTotalValue = useCallback(
    (params: ICalculateTotalValueParams) => {
      const { list, ignoreIndex } = params;

      return list.reduce((acc, curr, currentIndex) => {
        if (currentIndex === ignoreIndex) {
          return acc;
        }

        return acc.plus(curr.value);
      }, new BigNumber(0));
    },
    [],
  );

  const originalTotalValue = useMemo(() => {
    return calculateListTotalValue({
      list: originalAssessments,
      ignoreIndex: editAssessmentIndex,
    });
  }, [originalAssessments, calculateListTotalValue, editAssessmentIndex]);

  const getRemaining = useCallback(
    (formValues: IGenericAssessmentEntity[]) => {
      const formTotalValue = calculateListTotalValue({ list: formValues });

      let remainingValue = new BigNumber(accountValue)
        .minus(originalTotalValue)
        .minus(formTotalValue);

      if (
        !remainingValue.isZero() &&
        remainingValue.isLessThan(0.01) &&
        remainingValue.isGreaterThan(-0.01)
      ) {
        remainingValue = new BigNumber(0);
      }

      const remainingPercentage = remainingValue.dividedBy(accountValue);

      const maskedRemainingPercentage = remainingPercentage
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber();

      return {
        remainingValue,
        remainingPercentage,
        formattedRemainingValue: brlFmt(remainingValue.toNumber()),
        formattedRemainingPercentage: percentFmt(maskedRemainingPercentage),
      };
    },
    [accountValue, calculateListTotalValue, originalTotalValue],
  );

  const calculatePercentageFromValue = (value: number) => {
    const calculatedPercentage = new BigNumber(value).dividedBy(accountValue);

    const maskedPercentage = calculatedPercentage
      .multipliedBy(100)
      .decimalPlaces(2);

    return { calculatedPercentage, maskedPercentage };
  };

  const calculateValuesFromPercentageMask = (
    params: ICalculateValuesFromPercetangeMaskParams,
  ) => {
    const { percentage, currentFormValuesList, index } = params;

    let calculatedValue = new BigNumber(percentage)
      .dividedBy(100)
      .multipliedBy(accountValue)
      .decimalPlaces(2);

    let calculatedPercentage = calculatedValue.dividedBy(accountValue);

    currentFormValuesList[index].percentage = calculatedPercentage.toJSON();
    currentFormValuesList[index].value = calculatedValue.toJSON();

    const { remainingPercentage, remainingValue } = getRemaining(
      currentFormValuesList,
    );

    if (
      !remainingValue.isZero() &&
      remainingPercentage.isLessThan(0.0001) &&
      remainingPercentage.isGreaterThan(-0.0001)
    ) {
      calculatedValue = calculatedValue.plus(remainingValue).decimalPlaces(2);
      calculatedPercentage = calculatedValue.dividedBy(accountValue);
    }

    return {
      calculatedValue,
      calculatedPercentage,
    };
  };

  return {
    getRemaining,
    calculatePercentageFromValue,
    calculateValuesFromPercentageMask,
    formattedAccountValue: brlFmt(accountValue),
  };
}
