import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationAccountService } from "../../domain/usecases/getClassificationAccountUseCase";

export class GetClassificationAccountService
  implements IGetClassificationAccountService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getClassificationAccount(id: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/ClassificationAccounts/${id}`;

    return this.api.get<IClassificationAccountEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
