import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListCountriesService } from "../../domain/usecases/listCountriesUseCase";
import { ICountryResponse } from "../models/countryResponse";

export class ListCountriesService implements IListCountriesService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCountriesByName(
    activeOnly = false,
    payload: PayloadEntity | null = null,
  ) {
    const userEntity = this.getUserLocalService.get();

    let url = "";
    let params;

    if (activeOnly) url = "/Countries/Actives";

    if (payload !== null) {
      params = new URLSearchParams({
        body: JSON.stringify(payload),
      });

      url = `/Countries?${params ?? ""}`;
    }

    const response = await this.api.get<IResponseEntity<ICountryResponse[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response;
  }
}
