export interface IPaymentRequestFeeEntity {
  feeName: string;
  feeId: string;
  value: number;
  active: boolean;
}

export class PaymentRequestFeeEntity implements IPaymentRequestFeeEntity {
  active = true;
  feeId = "";
  feeName = "";
  value = 0;

  constructor(init?: Partial<IPaymentRequestFeeEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<IPaymentRequestFeeEntity>) {
    return new PaymentRequestFeeEntity(init);
  }
}
