import styled from "styled-components";

export const Container = styled.div`
  width: 294px;

  .p-inputgroup {
    input {
      padding: 0.25rem 0.5rem;
    }

    button {
      padding: 0.25rem;
      width: auto;
    }

    .initial-date {
      border-right: none;
      text-align: right;
    }

    .separator {
      display: inline-flex;
      border: 1px solid #ced4da;
      justify-content: center;
      align-items: center;
      border-left: none;
      border-right: none;
      padding: 0 0.12rem;
    }

    .final-date {
      border-left: none;
    }
  }

  .done-button {
    display: block;
    background: red;
  }
`;

export const Wrapper = styled.div`
  .p-datepicker {
    --fontSize: 0.8rem;

    &.p-datepicker-inline {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-datepicker-title {
      .p-dropdown .p-dropdown-label {
        font-size: var(--fontSize);
        padding: 0.05em 0.4em;
      }
    }

    td,
    td.p-datepicker-today {
      font-size: var(--fontSize);
      padding: 0;

      span.p-highlight {
        background: #aeb8f1;
        color: white;
      }

      &.p-datepicker-today {
        span {
          background: #798cc1;
          color: white;

          &.p-highlight {
            background: #aeb8f1;
          }
        }
      }
    }
  }

  .p-inputgroup {
    .p-button.done-button {
      width: 100%;
      justify-content: center;
      font-weight: 600;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;
