import styled from "styled-components";

export const Container = styled.div`
  width: 1000px;

  .taxes-modal-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .smaller-label {
    font-size: 13px !important;

    small {
      font-size: 10px !important;
    }
  }

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }
`;
