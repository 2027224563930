import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: end;
  }

  > div + div {
    margin-top: 0.5rem;

    @media (min-width: 1200px) {
      margin-top: unset;
    }
  }

  .page-tools {
    width: 100%;

    @media (min-width: 1200px) {
      width: unset;
      order: 10;
      margin-left: 0.5rem;
    }
  }
`;

export const FilterToolsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;

    & + div {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: end;

    > div {
      & + div {
        margin-top: unset;
        margin-left: 0.5rem;
      }
    }
  }

  @media (min-width: 1200px) {
    /* limpar filtro */
    > div.clr-filter-btn-wrapper {
      flex: 0 0 7.5rem;
    }
  }

  input {
    &.isInvalid {
      border-color: var(--invalid);

      :focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }
  }

  select,
  .p-component.p-inputtext,
  .p-component.p-dropdown {
    font-size: 0.8333rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-radius: 0.25rem;

    color: #6c757d;
    border: 0.0625rem solid #ced4da;

    .p-dropdown-label.p-inputtext.p-placeholder {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      padding-right: 0.5rem;
    }

    .p-dropdown-label.p-inputtext {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      display: inline-block;
      max-width: 9.375rem;
    }
  }

  i.pi.pi-spin.pi-spinner {
    line-height: 1.7rem;
    margin-left: 0.4rem;
  }

  .clear-filter-button {
    line-height: normal;
    margin: unset;
    background-color: var(--soul-red-button);
    border-color: var(--soul-red-button);
    width: 100%;
    justify-content: center;
  }

  .filter-button {
    line-height: normal;
    margin: unset;
    background-color: var(--soul-color02);
    border-color: var(--soul-color02);
    width: 100%;
    justify-content: center;
  }
`;

export const PageToolsContainer = styled.div`
  display: flex;

  > div {
    display: flex;
    flex: 1 1;

    & + div {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    justify-content: end;

    > div {
      flex: unset;
    }
  }

  .p-component.p-inputtext,
  .p-component.p-dropdown {
    font-size: 0.8333rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-radius: 0.25rem;

    .p-dropdown-label.p-inputtext.p-placeholder {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      padding-right: 0.5rem;
    }

    .p-dropdown-label.p-inputtext {
      font-size: 0.8333rem;
      line-height: 1.0333rem;
      display: inline-block;
      max-width: 9.375rem;
    }
  }

  i.pi.pi-spin.pi-spinner {
    line-height: 1.7rem;
    margin-left: 0.4rem;
  }

  .dropdown-item {
    &:not(.btn-terminate) {
      color: var(--soul-color02);
    }

    &.btn-terminate {
      &:not(.btn-active) {
        background-color: #a5b2c0;
        color: #5a6d81;
      }

      &.btn-active {
        background-color: var(--soul-green-button);
        color: white;
        filter: drop-shadow(0 2px 6px var(--soul-green-button));
      }
    }
  }

  .options-context {
    margin: unset;
    background-color: var(--soul-color02);
    border-color: var(--soul-color02);
    width: 100%;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }
`;

export const PanoramaOption = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background-color: transparent;

    svg {
      fill: var(--soul-red-button);
    }
  }
`;
