import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetPaymentAccountService } from "../data/services/getPaymentAccountService";
import { ListPaymentAccountsService } from "../data/services/listPaymentAccountsService";
import { SavePaymentAccountService } from "../data/services/savePaymentAccountService";
import { TogglePaymentAccountService } from "../data/services/togglePaymentAccountService";
import { UpdatePaymentAccountService } from "../data/services/updatePaymentAccountService";
import { IGetPaymentAccountContract } from "../domain/contracts/getPaymentAccountContract";
import { IListPaymentAccountsContract } from "../domain/contracts/listPaymentAccountsContract";
import { ISavePaymentAccountContract } from "../domain/contracts/savePaymentAccountContract";
import { ITogglePaymentAccountContract } from "../domain/contracts/togglePaymentAccountContract";
import { IUpdatePaymentAccountContract } from "../domain/contracts/updatePaymentAccountContract";

export type MakePaymentAccounts = IListPaymentAccountsContract &
  ITogglePaymentAccountContract &
  IGetPaymentAccountContract &
  ISavePaymentAccountContract &
  IUpdatePaymentAccountContract;

export function makePaymentAccounts(api: IApiService): MakePaymentAccounts {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listPaymentAccountsService = new ListPaymentAccountsService(
    getUserLocalService,
    api,
  );

  const togglePaymentAccountService = new TogglePaymentAccountService(
    getUserLocalService,
    api,
  );

  const getPaymentAccountService = new GetPaymentAccountService(
    getUserLocalService,
    api,
  );

  const savePaymentAccountService = new SavePaymentAccountService(
    getUserLocalService,
    api,
  );

  const updatePaymentAccountService = new UpdatePaymentAccountService(
    getUserLocalService,
    api,
  );

  return {
    listPaymentAccounts(payload, currentCompanyGroupId, actives) {
      return listPaymentAccountsService.listPaymentAccounts(
        payload,
        currentCompanyGroupId,
        actives,
      );
    },
    togglePaymentAccount(paymentAccountId) {
      return togglePaymentAccountService.togglePaymentAccount(paymentAccountId);
    },
    getPaymentAccount(paymentAccountId) {
      return getPaymentAccountService.getPaymentAccount(paymentAccountId);
    },
    savePaymentAccount(payload) {
      return savePaymentAccountService.savePaymentAccount(payload);
    },
    updatePaymentAccount(payload) {
      return updatePaymentAccountService.updatePaymentAccount(payload);
    },
  };
}
