import { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ICostCenterFormEntity } from "../../../domain/entities/costCenterFormEntity";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ISearchParams } from "../../../../core/domain/entities/searchParams";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../core/presentation/components/SoulTypeahead";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";

interface SearchCostCenterFieldProps {
  disabled: boolean;
  required: boolean;
  currentCostCenterId: string;
  searchCostCenter(
    params: ISearchParams,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

interface SearchCostCenterFieldState {
  costCenter: {
    loading: boolean;
    options?: ITypeaheadOption[];
  };
}

export function SearchCostCenterField(props: SearchCostCenterFieldProps) {
  const { required, disabled, currentCostCenterId, searchCostCenter } = props;

  const asyncDebounce = useDebounceTimeAsync();

  const [state, setState] = useState<SearchCostCenterFieldState>({
    costCenter: {
      options: [],
      loading: false,
    },
  });

  const {
    currentCompanyGroup: { id: currentCompanyGroupId },
  } = useCurrentCompanyGroup();

  const { control, setValue, getValues } =
    useFormContext<ICostCenterFormEntity>();

  const handleSearchCostCenter = async (search: string) => {
    await asyncDebounce(750);

    setState(prevState => ({
      costCenter: {
        ...prevState.costCenter,
        loading: true,
      },
    }));

    try {
      const { data } = await searchCostCenter({
        search,
        activesOnly: true,
        companyGroupId: currentCompanyGroupId,
      });

      setState(() => ({
        costCenter: {
          options: data,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        costCenter: {
          ...prevState.costCenter,
          loading: false,
        },
      }));
    }
  };

  const validateReplacedCostCenter = useCallback(
    (value: string) => {
      /** Por ser um campo opcional, o campo é validado caso não haja valor. */
      if (!value) return true;
      return currentCostCenterId !== value;
    },
    [currentCostCenterId],
  );

  return (
    <Controller
      control={control}
      name="costCenterReplacedId"
      rules={{
        required,
        validate: {
          differentCostCenter: validateReplacedCostCenter,
        },
      }}
      render={({ field, fieldState }) => {
        const value = {
          rawValue: field.value,
          label: getValues("costCenterReplacedName"),
        };

        const handleChange = (event: ISoulTypeaheadChangeEvent) => {
          const costCenterReplacedId = event.target?.value?.rawValue || "";
          const costCenterReplacedName = event?.target?.value?.label || "";

          field.onChange(costCenterReplacedId);
          setValue("costCenterReplacedName", costCenterReplacedName);
        };

        return (
          <SoulTypeahead
            serverSide
            openOnFocus
            value={value}
            disabled={disabled}
            onChange={handleChange}
            id="txt-costCenterReplacedId"
            loading={state.costCenter.loading}
            options={state.costCenter.options}
            data-testid="txt-costCenterReplacedId"
            onSearchChange={handleSearchCostCenter}
            placeholder="Centro de Custo Substituto"
            className={fieldState?.error ? "isInvalid" : ""}
          />
        );
      }}
    />
  );
}
