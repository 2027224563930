import { IViaCepMetaData } from "../../data/models/viaCepResponse";

export interface IGetViaCepUseCase {
  getByZipCode(zipCode: string): Promise<IViaCepMetaData | null>;
}

export interface IGetViaCepService {
  getByZipCode(zipCode: string): Promise<IViaCepMetaData | null>;
}

export class GetViaCepUseCase implements IGetViaCepUseCase {
  constructor(private getByZipCodeService: IGetViaCepService) {}

  getByZipCode(zipCode: string) {
    return this.getByZipCodeService.getByZipCode(zipCode);
  }
}
