import { ICostCenterEntity } from "./costCenterEntity";

export type ICostCenterFormEntity = Pick<
  ICostCenterEntity,
  | "acronym"
  | "description"
  | "costCenterReplacedId"
  | "costCenterReplacedName"
  | "openingBalance"
  | "responsible"
  | "lockReleasesUntil"
  | "isDefaultForAccountReceivableImportation"
>;

export class CostCenterFormEntity implements ICostCenterFormEntity {
  acronym = "";
  description = "";
  costCenterReplacedId = "";
  costCenterReplacedName = "";
  openingBalance = 0;
  responsible = "";
  lockReleasesUntil = "";
  isDefaultForAccountReceivableImportation = false;

  constructor(init?: Partial<ICostCenterFormEntity>) {
    Object.assign(this, init);
  }

  static create(params?: Partial<ICostCenterFormEntity>) {
    return new CostCenterFormEntity(params);
  }
}
