import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ILowerCaseErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ICheckCostCenterAcronymContract } from "../../domain/contracts/checkCostCenterAcronymContract";

export class CheckCostCenterAcronymService
  implements ICheckCostCenterAcronymContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  checkCostCenterAcronym(
    acronym: string,
    costCenterId: string,
    companyGroupId: string,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = "CostCenters/CheckAcronym";

    return this.api.post<ILowerCaseErrorResponseEntity>(
      url,
      {
        acronym,
        id: costCenterId,
        companyGroupId,
      },
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );
  }
}
