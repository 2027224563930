import { IServerSideResponseModel } from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IListAuthUsersOptions,
  IListAuthUsersContract,
} from "../../domain/contracts/listAuthUsersContract";
import { IAuthUserModel } from "../models/authUserModel";
import { AuthUserEntity } from "../../domain/entities/authUserEntity";

export class ListAuthUsersService implements IListAuthUsersContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listAuthUsers(options: IListAuthUsersOptions) {
    const { payload, actives, isSupervisor } = options;
    const userEntity = this.getUserLocalService.get();
    const url = isSupervisor ? "Users/AuthUsers/Supervisor" : "Users/AuthUsers";

    const endpoint = actives ? `${url}/Actives` : url;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const finalEndpoint = `${endpoint}?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IAuthUserModel[]>
    >(finalEndpoint, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return {
      ...response,
      data: response.data.map(
        user =>
          new AuthUserEntity({
            id: user.id || "",
            active: !!user.active,
            name: user.name || "",
            email: user.email || "",
            profile: user.profile || 0,
            partnerId: user.partnerId || "",
            profileDescription: user.profileDescription || "",
          }),
      ),
    };
  }
}
