import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateCostCenterContract } from "../../domain/contracts/updateCostCenterContract";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";
import { ICostCenterFormEntity } from "../../domain/entities/costCenterFormEntity";

export class UpdateCostCenterService implements IUpdateCostCenterContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateCostCenter(payload: ICostCenterFormEntity, costCenterId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = "/CostCenters";

    const formatDate = (date: string) => {
      if (!date) return "";
      return format(parse(date, "ddMMyyyy", new Date()), "yyyy-MM-dd");
    };

    const finalPayload = {
      ...payload,
      id: costCenterId,
      lockReleasesUntil: formatDate(payload.lockReleasesUntil),
    };

    return this.api.put<ICostCenterEntity>(url, finalPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
