import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { InputPercentage } from "../../../../core/presentation/components/InputPercentage";
import { ProjectTaxesForm } from "../ProjectTaxesModal";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { Container } from "./styles";

interface TaxFieldProps {
  taxIndex: number;
  projectIndex: number;
  control: Control<ProjectTaxesForm>;
}

export function TaxField(props: TaxFieldProps) {
  const { control, projectIndex, taxIndex } = props;

  const [disabled, setDisabled] = useState(true);

  const handleEditButtonClick = (hasError: boolean) => {
    if (hasError) {
      return;
    }

    setDisabled(prevState => !prevState);
  };

  return (
    <Controller
      control={control}
      rules={{ min: 0.01, required: true }}
      name={`taxesList.${projectIndex}.projectTaxes.${taxIndex}.tax`}
      render={({ field, fieldState }) => {
        return (
          <div className="form-row">
            <label className="col-12 form-control">
              <Container>
                <InputPercentage
                  {...field}
                  precision={2}
                  placeholder="0,00%"
                  disabled={disabled}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <button
                  type="button"
                  data-tip="Alterar"
                  id={`btn-tax-${projectIndex}-edit-${taxIndex}`}
                  data-testid={`btn-tax-${projectIndex}-edit-${taxIndex}`}
                  onClick={() => {
                    handleEditButtonClick(!!fieldState.error);
                  }}
                >
                  <FaEdit />
                </button>
              </Container>
              <InvalidFeedback
                message="Este campo é obrigatório"
                condition={fieldState?.error?.type === "required"}
              />
              <InvalidFeedback
                message="Insira uma porcentagem válida"
                condition={fieldState?.error?.type === "min"}
              />
            </label>
          </div>
        );
      }}
    />
  );
}
