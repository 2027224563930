import styled from "styled-components";

export const LoadingContainer = styled.div`
  top: 0;
  left: 12.75ch;
  color: #525f7f;

  position: absolute;
`;

export const Container = styled.div`
  width: 100%;

  .costCenters-multiselect {
    width: inherit;
    min-height: 3rem;

    :hover {
      border-color: var(--input-border-color);
    }

    :focus-within {
      border-color: rgba(50, 151, 211, 0.25);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    &.isInvalid {
      border-style: solid;
      border-width: 1px;
      border-color: var(--invalid);

      :focus-within {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        gap: 0.3rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        height: 100%;

        .p-multiselect-token {
          color: #ffffff;
          background: #4a69bd;

          margin: 0;
          padding: 0 0.5rem;
          border-radius: 0.25rem;

          .p-multiselect-token-icon {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0.5rem 0.8rem;

  color: #333;
  background: #fff;

  border-bottom: 1px solid #eaeaea;

  border-radius: 6px 6px 0 0;

  div:first-of-type {
    margin-right: 0.5rem;
  }

  button {
    display: flex;
    padding: 0.45rem;

    font-weight: 400;
    font-size: 0.75rem;

    color: #adb5bd;
    background-color: #fff;

    height: calc(1.5em + 0.5rem + 2px);

    border-radius: 0 0.25rem 0.25rem 0;
    border: 1px solid #cad1d7;
    border-left: none;
  }

  input {
    flex: 1;

    position: relative;

    line-height: 1.5;
    font-size: 0.75rem;

    padding: 0.25rem 0.5rem;

    border-radius: 0.25rem 0 0 0.25rem;
    border: 1px solid var(--input-border-color);
    border-right: none;

    height: calc(1.5em + 0.5rem + 2px);

    color: #495057;
    background-color: #fff;

    :focus {
      outline: unset;
      border-color: rgba(50, 151, 211, 0.25);
      border-right: 1px solid rgba(50, 151, 211, 0.25);
      box-shadow: 0 0 0 0.15rem rgb(0 123 255 / 25%);
    }

    ::placeholder {
      color: #d1d1d9;
    }
  }
`;
