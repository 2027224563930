import { IPanoramaAngularModel } from "../../../../../advTable/data/models/panoramaAngularModel";
import { IParsePanoramaAngularService } from "../../../../../advTable/data/services/parsePanoramaAngularService";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IListPaymentRequestPanoramasContract } from "../../domain/contracts/listPaymentRequestPanoramasContract";

export class ListPaymentRequestPanoramasService
  implements IListPaymentRequestPanoramasContract
{
  constructor(
    private parsePanoramaAngularService: IParsePanoramaAngularService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listPaymentRequestPanoramas(
    userId: string,
    columns: IAdvTableColumn[],
  ): Promise<IPanoramaEntity[]> {
    const url = `/Users/${userId}/PaymentRequestPanoramas`;
    const userEntity = this.getUserLocalService.get();

    const panoramas = await this.api.get<IPanoramaAngularModel[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const paymentRequestsPanoramas = panoramas.map(panorama => {
      return this.parsePanoramaAngularService.parsePanoramaAngular(
        columns,
        panorama,
      );
    });

    return paymentRequestsPanoramas;
  }
}
