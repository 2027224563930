import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  --background: #e4edf6;

  display: flex;
  align-items: stretch;

  & + & {
    margin-top: 1rem;
  }

  .raw-card,
  .release-cards {
    display: flex;

    flex: 1 1;
  }

  .raw-card {
    align-items: start;

    .chk-box {
      height: 88px;
      display: flex;
      align-items: center;
      margin-right: 1rem;

      > div {
        width: 1.375rem;
        height: 1.375rem;
        border-radius: 0.375rem;
        background: var(--background);
      }
    }

    .card {
      width: 100%;
      height: 88px;
      border-radius: 0.75rem;
      background: var(--background);
    }
  }

  > .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
