import FileSaver from "file-saver";
import { ColumnProps } from "primereact/column";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { IFetchRpaParamOptionsReturn } from "../../../domain/contracts/fetchRpaParamOptionsContract";
import {
  EAttachmentType,
  IAttachmentGridEntity,
  IAttachmentGridFileGeneration,
  IAttachmentsGridForm,
  IAttachmentsGridFormField,
  IOnAttachmentTypeChangeParams,
  IUpdateAttachmentsBarcodeResult,
  IValidadeMeasurementAttachmentResult,
} from "../../../domain/entities/attachmentsGridTypes";
import { IEnum } from "../../../domain/entities/enum";
import { IGenerateRPAModalInputEntity } from "../../../domain/entities/generateRpaModalInputEntity";
import { useSoulDialog } from "../../hooks/useSoulDialog";
import { AsyncButton } from "../AsyncButton";
import { ButtonDelete } from "../ButtonDelete";
import { Card } from "../Card/styles";
import { ClientSoulTable } from "../ClientSoulTable";
import { InputSearch } from "../InputSearch";
import { AttachmentsGridFormModal } from "../AttachmentsGridFormModal";
import { CommercialProposalEnums } from "../../../domain/contracts/fetchCommercialProposalEnumsContract";
import { IGenerateCommercialProposalForm } from "../../../domain/entities/generateCommercialProposalForm";

const Container = styled.div`
  .searchbar {
    padding: 0.5rem 1rem;
  }
`;

interface IAttachmentsGridState {
  search: string;
  isFormModalOpen: boolean;
  bulkGeneratedTypes: EAttachmentType[];
  editAttachment: IAttachmentsGridFormField | null;
}

interface IAttachmentsGridProps<T extends IAttachmentGridEntity> {
  attachments: T[];
  disabled?: boolean;
  emptyMessage?: string;
  listAttachmentTypes(): Promise<IEnum[]>;
  canDeleteAttachment?(attachment: T): boolean;
  getStorageFilebyId(id: string): Promise<string | void>;
  onAttachmentsChange(updatedAttachments: T[]): void;
  fetchRpaParamOptions?(): Promise<IFetchRpaParamOptionsReturn>;
  attachmentTypeCheck?(
    params: IOnAttachmentTypeChangeParams<T>,
  ): Promise<boolean>;
  generateVariableAdditionalAttachment?(): Promise<IAttachmentGridFileGeneration>;
  updateAttachmentsBarcode?(
    billetAttachment: T,
  ): Promise<IUpdateAttachmentsBarcodeResult<T>>;
  validateMeasurementAttachment?(
    measurementAttachment: T,
  ): Promise<IValidadeMeasurementAttachmentResult<T>>;
  generateRpaAttachment?(
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ): Promise<IAttachmentGridFileGeneration>;
  fetchCommercialProposalEnums?(): Promise<
    Record<CommercialProposalEnums, IEnum[]>
  >;
  generateCommercialProposalAttachment?(
    params: IGenerateCommercialProposalForm,
  ): Promise<IAttachmentGridFileGeneration>;
}

export function AttachmentsGrid<T extends IAttachmentGridEntity>(
  props: IAttachmentsGridProps<T>,
) {
  const {
    disabled,
    attachments,
    getStorageFilebyId,
    onAttachmentsChange,
    canDeleteAttachment,
    emptyMessage = "Nenhum anexo encontrado.",
    ...rest
  } = props;

  const dialog = useSoulDialog();

  const form = useForm<IAttachmentsGridForm>({
    defaultValues: {
      attachments,
    },
  });

  const { control, getValues } = form;

  const { fields, update, append, remove } = useFieldArray({
    control,
    keyName: "formId",
    name: "attachments",
  });

  const [state, setState] = useState<IAttachmentsGridState>(() => {
    return {
      search: "",
      editAttachment: null,
      isFormModalOpen: false,
      bulkGeneratedTypes: [],
    };
  });

  const activeAttachments = fields.filter(attachment => attachment.active);

  const handleAttachmentsChange = useCallback(() => {
    const formValues = getValues("attachments");
    onAttachmentsChange(formValues as T[]);
  }, [getValues, onAttachmentsChange]);

  const handleAddAttachmentButtonClick = () => {
    setState(prevState => ({
      ...prevState,
      editAttachment: null,
      isFormModalOpen: true,
    }));
  };

  const handleAttachmentFormModalRequestClose = () => {
    setState(prevState => ({
      ...prevState,
      editAttachment: null,
      isFormModalOpen: false,
    }));
  };

  const handleShowDeleteButton = useCallback(
    (attachmentFormId: string) => {
      if (canDeleteAttachment) {
        const index = fields.findIndex(a => a.formId === attachmentFormId);
        const attachment = getValues(`attachments.${index}`) as T;
        return canDeleteAttachment(attachment);
      }

      return !disabled;
    },
    [canDeleteAttachment, disabled, fields, getValues],
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState(old => ({
      ...old,
      search: event.target.value,
    }));
  };

  const handleViewAttachment = useCallback(
    async (attachment: IAttachmentsGridFormField) => {
      if (attachment?.fileUrl) {
        window.open(attachment.fileUrl);
        return;
      }

      if (attachment?.storageFileId) {
        const url = await getStorageFilebyId(attachment.storageFileId);

        if (url && typeof url === "string") {
          window.open(url);
        }

        return;
      }

      const { file, name } = attachment;
      FileSaver.saveAs(file as Blob, name);
    },
    [getStorageFilebyId],
  );

  const handleDeleteAttachment = useCallback(
    async (attachment: IAttachmentsGridFormField) => {
      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        html: (
          <>
            O anexo será excluído.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      const attachmentIndex = fields.findIndex(a => {
        return a.formId === attachment.formId;
      });

      /**
       * Caso o arquivo já possua um ID que o identifique no servidor, ele só
       * precisa ser desativado.
       */
      if (attachment.storageFileId) {
        const deletedAttachment = {
          ...attachment,
          active: false,
        };

        update(attachmentIndex, deletedAttachment);
      } else {
        remove(attachmentIndex);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: <>Anexo deletado com sucesso!</>,
      });

      handleAttachmentsChange();
    },
    [dialog, fields, handleAttachmentsChange, remove, update],
  );

  const renderActions = useCallback(
    (attachment: IAttachmentsGridFormField) => {
      const isBulkGeneration = state.bulkGeneratedTypes.includes(
        attachment.type,
      );

      return (
        <div className="table-actionbar">
          {isBulkGeneration ? null : (
            <AsyncButton
              data-tip="Visualizar anexo"
              type="button"
              className="outline-button tool"
              id={`btn-view-acc-pay-att-${" "}`}
              data-testid={`btn-view-acc-pay-att-${" "}`}
              onClick={async () => {
                await handleViewAttachment(attachment);
              }}
            >
              <FaEye />
            </AsyncButton>
          )}
          {handleShowDeleteButton(attachment.formId) ? (
            <ButtonDelete
              data-tip="Excluir anexo"
              className="outline-button tool"
              id={`btn-del-acc-pay-att-${" "}`}
              data-testid={`btn-del-acc-pay-att-${" "}`}
              onClick={() => {
                handleDeleteAttachment(attachment);
              }}
            />
          ) : null}
          {isBulkGeneration || disabled ? null : (
            <button
              type="button"
              data-tip="Editar anexo"
              className="outline-button tool"
              id={`btn-edit-acc-pay-att-${" "}`}
              data-testid={`btn-edit-acc-pay-att-${" "}`}
              onClick={() => {
                setState(old => ({
                  ...old,
                  isFormModalOpen: true,
                  editAttachment: attachment,
                }));
              }}
            >
              <FaEdit />
            </button>
          )}
        </div>
      );
    },
    [
      disabled,
      handleViewAttachment,
      handleDeleteAttachment,
      handleShowDeleteButton,
      state.bulkGeneratedTypes,
    ],
  );

  const handleAddNewAttachments = useCallback(
    (newAttachments: T[], bulkGeneratedTypes: EAttachmentType[]) => {
      append(newAttachments);

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Anexos adicionados com sucesso.",
      });

      setState(old => ({
        ...old,
        bulkGeneratedTypes,
        editAttachment: null,
        isFormModalOpen: false,
      }));

      handleAttachmentsChange();
    },
    [append, dialog, handleAttachmentsChange],
  );

  const handleEditAttachment = useCallback(
    (newAttachments: IAttachmentGridEntity[]) => {
      const attachmentIndex = fields.findIndex(a => {
        return a.formId === state.editAttachment?.formId;
      });

      update(attachmentIndex, newAttachments[0]);

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Anexo editado com sucesso.",
      });

      setState(old => ({
        ...old,
        editAttachment: null,
        isFormModalOpen: false,
      }));

      handleAttachmentsChange();
    },
    [
      update,
      dialog,
      fields,
      handleAttachmentsChange,
      state.editAttachment?.formId,
    ],
  );

  const columns = useMemo<ColumnProps[]>(() => {
    const renderNameBody = (rowData: IAttachmentGridEntity) => {
      return (
        <div title={rowData.name} className="truncate">
          {rowData.name}
        </div>
      );
    };

    return [
      {
        header: "Nome",
        field: "name",
        style: { minWidth: "380px" },
        body: renderNameBody,
      },
      {
        header: "Tipo",
        field: "typeDescription",
        style: { minWidth: "2px" },
      },
      {
        header: "",
        field: "actions",
        style: { minWidth: "130px" },
        body: renderActions,
      },
    ];
  }, [renderActions]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Card className="modal-table">
      <Container>
        <header>
          Anexos
          <button
            id="btn-add"
            type="button"
            disabled={disabled}
            className="default-button"
            onClick={handleAddAttachmentButtonClick}
          >
            <FaPlus /> Adicionar anexo
          </button>
        </header>
        <div className="searchbar">
          <InputSearch
            value={state.search}
            id="txt-taxes-search"
            data-testid="txt-taxes-search"
            onChange={handleSearchChange}
          />
        </div>
        <ClientSoulTable
          pageLinkSize={3}
          columns={columns}
          data={activeAttachments}
          globalFilter={state.search}
          emptyMessage={emptyMessage}
        />
        <AttachmentsGridFormModal
          {...rest}
          onEdit={handleEditAttachment}
          isOpen={state.isFormModalOpen}
          onSubmit={handleAddNewAttachments}
          activeAttachments={activeAttachments}
          editAttachment={state.editAttachment}
          onRequestClose={handleAttachmentFormModalRequestClose}
        />
      </Container>
    </Card>
  );
}
