import { ColumnProps } from "primereact/column";
import {
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormRegisterReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { IApiError } from "../../../../../../core/data/services/apiService";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { InputPercentage } from "../../../../../../core/presentation/components/InputPercentage";
import { InputSearch } from "../../../../../../core/presentation/components/InputSearch";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { SoulTypeahead } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTime } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { useImportFileErrorHandlers } from "../../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { IErrorResponseEntity } from "../../../../../../simpleTable/domain/entities/responseEntity";
import { IAccountsReceivableGenerateInvoiceModalForm } from "../../../domain/entities/formt";
import { IGenerateInvoiceIListItemEntity } from "../../../domain/entities/generateInvoiceIListItemEntity";
import { useAccountsReceivablePage } from "../../hooks/useAccountsReceivablePage";
import {
  AccountReceivableGenerateInvoiceExtendedForm,
  IFieldsWithPreRegistration,
} from "../AccountReceivableGenerateInvoiceExtendedForm";
import { Container } from "./styles";

interface IAccountsReceivableGenerateInvoiceModalState {
  search: string;
  globalFilter: string;
  submitting: boolean;
  loading: boolean;
  cnaeList: ITypeaheadOption[];
  expandedRows: DataTableExpandedRows;
  serviceFrameworkList: ITypeaheadOption[];
  stateList: ITypeaheadOption[];
}

export function AccountsReceivableGenerateInvoiceModal() {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dialog = useSoulDialog();
  const debounceTime = useDebounceTime();
  const importFileErrorHandler = useImportFileErrorHandlers();

  const {
    state: { generateInvoiceIListItems },
    useAccountsReceivable,
    reload,
  } = useAccountsReceivablePage();

  const {
    fetchCnae,
    fetchServiceFramework,
    generateAccountReceivableInvoices,
    searchBrazilianState,
    searchBrazilianCity,
  } = useAccountsReceivable;

  const fieldsPreRegistrationRef = useRef<
    Record<IFieldsWithPreRegistration, UseFormRegisterReturn>[]
  >([]);

  const form = useForm<IAccountsReceivableGenerateInvoiceModalForm>({
    defaultValues: {
      data: [],
    },
    mode: "all",
  });

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = form;

  const { fields } = useFieldArray<IAccountsReceivableGenerateInvoiceModalForm>(
    {
      control,
      name: "data",
    },
  );

  const [state, setState] =
    useState<IAccountsReceivableGenerateInvoiceModalState>({
      search: "",
      globalFilter: "",
      submitting: false,
      loading: true,
      cnaeList: [],
      serviceFrameworkList: [],
      expandedRows: {},
      stateList: [],
    });

  const isOpen = hash.includes("#generate-invoice");

  /** Fecha a modal de importacao */
  const closeModal = () => {
    navigate(SoulRoutes.ACCOUNTS_RECEIVABLE.path);
  };

  /**
   * Lida com o evento de click no botao "fechar"
   */
  const handleRequestClose = async () => {
    if (state.loading) {
      closeModal();
      return;
    }

    const result = await dialog.fire({
      icon: "question",
      title: "Atenção!",
      html: (
        <div>
          <div>
            Você está saindo <b>sem gerar nota fiscal</b> para o(s)
            lançamento(s). Caso queira fazer isso <b>mais tarde</b>, será
            preciso <b>selecionar</b> os lançamentos pela{" "}
            <b>página de Contas a Receber</b> e realizar o processo por lá.
          </div>
          <br />
          <div>Deseja prosseguir?</div>
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Ok",
    });

    if (result.isDismissed) {
      return;
    }

    closeModal();
  };

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target?.value || "";

    setState(prevState => ({
      ...prevState,
      search,
    }));

    debounceTime(() => {
      setState(prevState => ({
        ...prevState,
        globalFilter: search,
      }));
    }, 700);
  };

  /**
   * Lida com evento de abertura da modal. Responsavel
   * por resetar os estados e form da modal
   */
  const handleAfterClose = () => {
    setState({
      globalFilter: "",
      search: "",
      submitting: false,
      loading: false,
      cnaeList: [],
      serviceFrameworkList: [],
      expandedRows: {},
      stateList: [],
    });

    setValue("data", []);
  };

  /**
   * Mapeia uma lista NFs a gerar atualizando seus respectivos campos de cnae e
   * enquadramento de servicos com o objeto completo obtido nas respectivas listas.
   *
   * O servico de importacao nos retorna somente os campos cnae e enquadramento
   * de servico como ids, por isso precisamos deste mapping para que as labels
   * correspondentes aparecam nos campos na UI
   */
  const mapGenerateInvoiceIListItems = (
    generateInvoiceIList: IGenerateInvoiceIListItemEntity[],
    cnaeList: ITypeaheadOption[],
    serviceFrameworkList: ITypeaheadOption[],
  ) => {
    const mappedGenerateInvoiceIListItems = generateInvoiceIList.map(
      genInvoiceItem => {
        const mappedGenInvoiceItem = genInvoiceItem;
        const cnaeOption = genInvoiceItem.cnae;
        const svcFrameWorkOption = genInvoiceItem.serviceFramework;

        if (cnaeOption) {
          mappedGenInvoiceItem.cnae =
            cnaeList?.find(cnae => cnae.rawValue === cnaeOption.rawValue) ||
            null;
        }

        if (svcFrameWorkOption) {
          mappedGenInvoiceItem.serviceFramework =
            serviceFrameworkList?.find(
              svcFramework =>
                svcFramework.rawValue === svcFrameWorkOption.rawValue,
            ) || null;
        }

        return mappedGenInvoiceItem;
      },
    );

    return mappedGenerateInvoiceIListItems;
  };

  /** Lida com evento de abertura da modal. Responsavel por inicializar o form */
  const handleAfterOpen = async () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    let mappedGenerateInvoiceIListItems: IGenerateInvoiceIListItemEntity[];

    try {
      const cnaeList = await fetchCnae();
      const serviceFrameworkList = await fetchServiceFramework();
      const stateList = await searchBrazilianState({ search: "" });

      mappedGenerateInvoiceIListItems = mapGenerateInvoiceIListItems(
        generateInvoiceIListItems,
        cnaeList,
        serviceFrameworkList,
      );

      setState(prevState => ({
        ...prevState,
        cnaeList,
        serviceFrameworkList,
        stateList,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }

    reset({ data: mappedGenerateInvoiceIListItems });

    /**
     * Campos presente no template de expansão devem ser registrados
     * manualmente para que suas validações ocorram independente dos
     * dos campos estarem renderizados ou não.
     *
     * Por padrão, o template de expansão não é renderizado até que uma
     * linha seja expandida.
     */
    mappedGenerateInvoiceIListItems.map(({ rowIndex }) => {
      fieldsPreRegistrationRef.current.splice(rowIndex, 0, {
        invoiceDescription: register(`data.${rowIndex}.invoiceDescription`, {
          required: true,
        }),
      });
      return null;
    });
  };

  const submitHandler = async (
    data: IAccountsReceivableGenerateInvoiceModalForm,
  ) => {
    dialog.fire({
      title: "Aguarde",
      html: <div>Realizando emissão das notas fiscais</div>,
      didOpen() {
        dialog.showLoading();
      },
    });

    try {
      await generateAccountReceivableInvoices(data);

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <div>
            <div>
              O processo para gerar a(s) nota(s) fiscal(is) foi iniciado!
            </div>
            <br />
            <div>
              Assim que for concluído, o <b>número de documento</b> do(s)
              lançamento(s) será <b>atualizado</b>, assim como seu(s){" "}
              <b>anexo(s)</b>.
            </div>
          </div>
        ),
        didOpen() {
          dialog.hideLoading();
        },
      });

      closeModal();
      reload();
    } catch (error) {
      const apiError = error as IApiError<IErrorResponseEntity>;
      const errorResponse = apiError.response;

      importFileErrorHandler(errorResponse);
    } finally {
      dialog.close();
    }
  };

  /** Configuracao das colunas a serem exibidas na grid de anexos */
  const columns = useMemo<ColumnProps[]>(() => {
    /** renderiza a coluna valor total */
    const renderTotalValueBody = (data: IGenerateInvoiceIListItemEntity) => {
      const fmt = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format;

      const formatted = fmt(data.totalValue);

      return formatted;
    };

    /** renderiza a coluna enquadramento de servico */
    const renderServiceFramework = (data: IGenerateInvoiceIListItemEntity) => {
      const { rowIndex } = data;
      const fieldName = `data.${rowIndex}.serviceFramework` as const;

      return (
        <Controller
          control={control}
          name={fieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <>
              <SoulTypeahead
                id={field.name}
                name={field.name}
                options={state.serviceFrameworkList}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                placeholder="Enq. Serv."
                openOnFocus
                className={fieldState.error ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={fieldState.error?.type === "required"}
                message="Campo obrigatório"
              />
            </>
          )}
        />
      );
    };

    /** renderiza a coluna cnae */
    const renderCnaeBody = (data: IGenerateInvoiceIListItemEntity) => {
      const { rowIndex } = data;
      const fieldName = `data.${rowIndex}.cnae` as const;

      return (
        <Controller
          control={control}
          name={fieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <>
              <SoulTypeahead
                id={field.name}
                name={field.name}
                options={state.cnaeList}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                placeholder="CNAE"
                openOnFocus
                className={fieldState.error ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={fieldState.error?.type === "required"}
                message="Campo obrigatório"
              />
            </>
          )}
        />
      );
    };

    /** renderiza a coluna aliquota ISS */
    const renderIssAliquot = (data: IGenerateInvoiceIListItemEntity) => {
      const { rowIndex } = data;
      const fieldName = `data.${rowIndex}.issAliquot` as const;

      return (
        <Controller
          control={control}
          name={fieldName}
          rules={{
            min: 0.0001,
            required: true,
          }}
          render={({ field, fieldState }) => (
            <>
              <InputPercentage
                id={fieldName}
                name={fieldName}
                data-testid={fieldName}
                placeholder="Porcentagem"
                value={field.value}
                onChange={field.onChange}
                precision={4}
                className={fieldState.error ? "isInvalid" : ""}
              />
              <InvalidFeedback
                condition={fieldState.error?.type === "required"}
                message="Campo obrigatório"
              />
              <InvalidFeedback
                condition={fieldState.error?.type === "min"}
                message="Valor deve ser diferente de zero"
              />
            </>
          )}
        />
      );
    };

    return [
      {
        expander: true,
        field: "expander",
      },
      {
        header: "Empresa",
        field: "companyName",
        style: { minWidth: `64px`, maxWidth: `128px` },
        sortable: true,
        className: "text-truncate",
      },
      {
        header: "Nº do documento",
        field: "documentNumber",
        style: { minWidth: `172px`, maxWidth: `172px` },
        sortable: true,
        className: "text-truncate",
      },
      {
        header: "Razão social do cliente",
        field: "customerCorporationName",
        style: { minWidth: `208px`, maxWidth: `208px` },
        sortable: true,
        className: "text-truncate",
      },
      {
        header: "Valor total",
        field: "totalValue",
        style: { minWidth: `136px` },
        sortable: true,
        className: "text-truncate",
        body: renderTotalValueBody,
      },
      {
        header: "Enquadramento de Serviço",
        field: "serviceFramework",
        style: { minWidth: `100px` },
        body: renderServiceFramework,
      },
      {
        header: "Cnae",
        field: "cnae",
        style: { minWidth: `80px` },
        body: renderCnaeBody,
      },
      {
        header: "Alíquota ISS (%)",
        field: "issAliquot",
        style: { minWidth: `136px` },
        sortable: true,
        body: renderIssAliquot,
      },
    ];
  }, [control, state.cnaeList, state.serviceFrameworkList]);

  const isFormValid = isValid;

  const handleRowToggle = (event: DataTableRowToggleParams) => {
    setState(old => ({
      ...old,
      expandedRows: event.data as DataTableExpandedRows,
    }));
  };

  const rowExpansionTemplate = ({
    rowIndex,
  }: IGenerateInvoiceIListItemEntity) => {
    return (
      <AccountReceivableGenerateInvoiceExtendedForm
        rowIndex={rowIndex}
        stateList={state.stateList}
        searchBrazilianCity={searchBrazilianCity}
        fieldsPreRegistration={fieldsPreRegistrationRef.current}
      />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={handleRequestClose}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Gerar Notas Fiscais</h4>

          <button
            type="button"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={handleRequestClose}
          >
            <IoMdClose />
          </button>
        </div>

        {state.loading && (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        )}

        {!state.loading && (
          <>
            <div className="crud-header">
              <p>
                A(s) seguinte(s) Conta(s) a Receber fora(m) importada(s) e ainda
                não tivera(m) nota fiscal gerada
              </p>

              <p className="highlight-color">
                Selecione a(s) Conta(s) a Receber e depois clique em
                &ldquo;Gerar&rdquo; para iniciar o processo de geração da nota
                fiscal
              </p>
            </div>

            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="table-wrapper">
                <div className="generate-invoice-modal__table-wrapper__header">
                  <div>Contas a Receber</div>
                  <div className="txt-search-wrapper">
                    <InputSearch
                      value={state.search}
                      id="txt-search"
                      data-testid="txt-search"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div>
                  <FormProvider {...form}>
                    <ClientSoulTable
                      dataKey="id"
                      globalFilter={state.globalFilter}
                      data={fields}
                      columns={columns}
                      emptyMessage="Nenhum registro encontrado."
                      rowsPerPageOptions={[5, 10, 50]}
                      rowHover
                      onRowToggle={handleRowToggle}
                      expandedRows={state.expandedRows}
                      rowExpansionTemplate={rowExpansionTemplate}
                    />
                  </FormProvider>
                </div>
              </div>

              <div className="react-modal-footer">
                <button
                  id="btn-close"
                  type="button"
                  className="form-button red-bkg"
                  onClick={handleRequestClose}
                >
                  Fazer isso depois
                </button>

                <button
                  id="btn-submit"
                  type="submit"
                  disabled={state.submitting}
                  className={`form-button ${
                    isFormValid ? "green-bkg" : "invalid-bkg"
                  }`}
                >
                  Gerar{" "}
                  {state.submitting && <i className="pi pi-spin pi-spinner" />}
                </button>
              </div>
            </form>
          </>
        )}
      </Container>
    </Modal>
  );
}
