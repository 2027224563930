import { ContextMenu } from "primereact/contextmenu";
import { useRequesterContextMenu } from "../../hooks/useRequesterContextMenu";

export function RequesterContextMenu() {
  const { menuModel, menuRef, handleHideContextMenu } =
    useRequesterContextMenu();

  return (
    <ContextMenu
      ref={menuRef}
      model={menuModel}
      style={{ width: "230px" }}
      onHide={handleHideContextMenu}
    />
  );
}
