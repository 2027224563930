import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IClassificationAssessmentEntity } from "../entities/classificationAssessment";

export interface IListClassificationAssessmentsService {
  listClassificationAssessments(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>>;
}

export interface IListClassificationAssessmentsUseCase {
  listClassificationAssessments(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>>;
}

export class ListClassificationAssessmentsUseCase
  implements IListClassificationAssessmentsUseCase
{
  constructor(
    private listClassificationAssessmentsService: IListClassificationAssessmentsService,
  ) {}

  listClassificationAssessments(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>> {
    return this.listClassificationAssessmentsService.listClassificationAssessments(
      companyGroupId,
      payload,
    );
  }
}
