import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  ISearchCostCenterByProjectContract,
  ISearchCostCenterByProjectParams,
} from "../../domain/contracts/searchCostCenterByProjectContract";

interface ICostCenterResponse {
  id: string;
  acronym: string;
}

export class SearchCostCenterByProjectService
  implements ISearchCostCenterByProjectContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCostCenterByProject({
    search = "",
    projectId,
    companyGroupId,
  }: ISearchCostCenterByProjectParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      draw: 0,
      start: 0,
      order: [],
      length: 0,
      search: {
        regex: false,
        value: search,
      },
      columns: [
        {
          name: "",
          data: "acronym",
          orderable: true,
          searchable: true,
          search: { value: "", regex: false },
        },
        {
          name: "",
          data: "active",
          orderable: false,
          searchable: true,
          search: { value: "true", regex: false },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const endpoint = `/CompanyGroups/${companyGroupId}/Projects/${projectId}/CostCenters`;

    const url = `${endpoint}?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<ICostCenterResponse[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.data.map(costCenter => {
      return {
        metadata: costCenter,
        rawValue: costCenter.id,
        label: costCenter.acronym,
      };
    });
  }
}
