import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IGetStoredPaymentRequestPanoramaIdContract } from "../../domain/contracts/getStoredPaymentRequestPanoramaIdContract";

export class GetStoredPaymentRequestPanoramaIdService
  implements IGetStoredPaymentRequestPanoramaIdContract
{
  getStoredPaymentRequestPanoramaId(): string {
    const key = ELocalStorageKeys.PaymentRequestPanoramaId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
