import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";

export interface ISearchClassificationAccountsService {
  searchClassificationAccounts(
    providerId: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationAccountEntity[]>>;
}

export interface ISearchClassificationAccountsUseCase {
  searchClassificationAccounts(
    providerId: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationAccountEntity[]>>;
}

export class SearchClassificationAccountsUseCase
  implements ISearchClassificationAccountsUseCase
{
  constructor(
    private searchClassificationAccountsService: ISearchClassificationAccountsService,
  ) {}

  searchClassificationAccounts(
    providerId: string,
    companyGroupId: string,
    active?: boolean,
  ) {
    return this.searchClassificationAccountsService.searchClassificationAccounts(
      providerId,
      companyGroupId,
      active,
    );
  }
}
