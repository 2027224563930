import { format, isValid, parse } from "date-fns";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { OverlayPanel } from "primereact/overlaypanel";
import { useCallback, useEffect, useRef, useState } from "react";
import { FilterDateValue } from "../../../domain/entities/advTableColumn";
import { useColumnFilterUtils } from "../../hooks/columnFilterUtils";
import { useColumnFilter } from "../../hooks/useColumnFilter";
import { Container, Wrapper } from "./styles";

export type FilterDateValueType = FilterDateValue;

interface ColumnFilterDateProps {
  setIsFilterDisabled(a: boolean): void;
}

export function ColumnFilterDate({
  setIsFilterDisabled,
}: ColumnFilterDateProps) {
  const overlayPanelRef = useRef<OverlayPanel>(null);

  const { filterValue, setFilterValue, onFilterButtonClick } =
    useColumnFilter();

  const [initialDate, setInitialDate] = useState(() => {
    let startDate: Date | string | null = (
      filterValue as FilterDateValueType
    )?.[0];

    startDate =
      startDate && isValid(startDate) ? format(startDate, "dd/MM/yyyy") : "";

    return startDate;
  });
  const [finalDate, setFinalDate] = useState(() => {
    let endDate: Date | string | null = (
      filterValue as FilterDateValueType
    )?.[1];

    endDate = endDate && isValid(endDate) ? format(endDate, "dd/MM/yyyy") : "";

    return endDate;
  });

  const [calendarValue, setCalendarValue] = useState<Date | Date[] | undefined>(
    () => {
      const startDate = initialDate
        ? parse(initialDate, "dd/MM/yyyy", new Date())
        : null;

      const endDate = finalDate
        ? parse(finalDate, "dd/MM/yyyy", new Date())
        : null;

      const calendarValueInitialState = [];

      if (startDate) {
        calendarValueInitialState[0] = startDate;
      }

      if (endDate) {
        calendarValueInitialState[1] = endDate;
      }

      return calendarValueInitialState;
    },
  );

  const {
    renderCalendarMonthNavigatorTemplate,
    renderCalendarYearNavigatorTemplate,
  } = useColumnFilterUtils();

  const isDoneButtonDisabled = useCallback(
    (initialDateStr: string, finalDateStr: string) => {
      const startDate = parse(initialDateStr, "dd/MM/yyyy", new Date());
      const endDate = parse(finalDateStr, "dd/MM/yyyy", new Date());

      return !isValid(startDate) || !isValid(endDate);
    },
    [],
  );

  const handleInitialDateChange = useCallback(
    ({ target: { value } }) => {
      const startDate = parse(value, "dd/MM/yyyy", new Date());

      if (isValid(startDate)) {
        const [, endDate] = calendarValue as Date[];

        setCalendarValue([startDate, endDate ?? null]);
      }

      setInitialDate(value);

      const doneButtonDisabled = isDoneButtonDisabled(value, finalDate);

      setIsFilterDisabled(doneButtonDisabled);
    },
    [calendarValue, finalDate, isDoneButtonDisabled, setIsFilterDisabled],
  );

  const handleFinalDateChange = useCallback(
    ({ target: { value } }) => {
      const endDate = parse(value, "dd/MM/yyyy", new Date());

      if (isValid(endDate)) {
        const [startDate] = calendarValue as Date[];
        setCalendarValue([startDate ?? null, endDate]);
      }

      setFinalDate(value);

      const doneButtonDisabled = isDoneButtonDisabled(initialDate, value);

      setIsFilterDisabled(doneButtonDisabled);
    },
    [calendarValue, initialDate, isDoneButtonDisabled, setIsFilterDisabled],
  );

  const handleCalendarChange = useCallback(({ value }) => {
    const [startDate, endDate] = value;

    if (isValid(startDate)) {
      const startDateStr = format(startDate, "dd/MM/yyyy");
      setInitialDate(startDateStr);
    }

    if (isValid(endDate)) {
      const endDateStr = format(endDate, "dd/MM/yyyy");
      setFinalDate(endDateStr);
    }

    setCalendarValue(value);
  }, []);

  const hidePanel = useCallback(() => {
    overlayPanelRef?.current?.hide();
  }, []);

  const handleOnDoneButtonClick = useCallback(() => {
    onFilterButtonClick();
    hidePanel();
  }, [hidePanel, onFilterButtonClick]);

  useEffect(() => {
    const dateValue: FilterDateValueType = [
      parse(initialDate, "dd/MM/yyyy", new Date()),
      parse(finalDate, "dd/MM/yyyy", new Date()),
    ];

    setFilterValue?.(dateValue);
  }, [finalDate, initialDate, setFilterValue]);

  useEffect(() => {
    const doneButtonDisabled = isDoneButtonDisabled(initialDate, finalDate);
    setIsFilterDisabled(doneButtonDisabled);
  }, [finalDate, initialDate, isDoneButtonDisabled, setIsFilterDisabled]);

  const inputRef = useRef<HTMLInputElement>();

  // neste caso precisamos manter o useEffect para que este foco aconteça
  // somente na montagem do componente. Se mantivermos no ref callback, o
  // script executa também no onBlur, dae ele acaba dando foco no input de
  // novo e nunca passa para o segundo campo
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Container>
      <div className="p-inputgroup">
        <InputMask
          ref={ref => {
            inputRef.current = ref as unknown as HTMLInputElement;
          }}
          value={initialDate}
          className="p-inputtext p-component initial-date"
          mask="99/99/9999"
          placeholder="DD/MM/AAAA"
          onChange={handleInitialDateChange}
          onFocus={hidePanel}
        />
        <span className="separator">a</span>
        <InputMask
          value={finalDate}
          className="p-inputtext p-component final-date"
          mask="99/99/9999"
          placeholder="DD/MM/AAAA"
          onChange={handleFinalDateChange}
          onFocus={hidePanel}
        />
      </div>
      <OverlayPanel ref={overlayPanelRef}>
        <Wrapper>
          <Calendar
            inline
            numberOfMonths={2}
            selectionMode="range"
            monthNavigator
            yearNavigator
            yearRange={`2010:${new Date().getFullYear() + 100}`}
            value={calendarValue}
            onChange={handleCalendarChange}
            monthNavigatorTemplate={renderCalendarMonthNavigatorTemplate}
            yearNavigatorTemplate={renderCalendarYearNavigatorTemplate}
            locale="pt-br"
          />
          <div className="p-inputgroup">
            <button
              type="button"
              className="p-button p-button-sm done-button"
              onClick={handleOnDoneButtonClick}
              disabled={isDoneButtonDisabled(initialDate, finalDate)}
            >
              Feito!
            </button>
          </div>
        </Wrapper>
      </OverlayPanel>
    </Container>
  );
}
