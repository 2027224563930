import { ContextMenu } from "primereact/contextmenu";
import { usePaymentRequestContextMenu } from "../../hooks/usePaymentRequestContextMenu";

export function PaymentRequestContextMenu() {
  const { menuModel, menuRef, handleHideContextMenu } =
    usePaymentRequestContextMenu();

  return (
    <ContextMenu
      ref={menuRef}
      model={menuModel}
      style={{ width: "230px" }}
      onHide={handleHideContextMenu}
    />
  );
}
