import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";

interface ISectionProjectInfoState {
  project: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
  classificationUsp: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface IReviewSectionProjectInfoProps {
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function ReviewSectionProjectInfo(
  props: IReviewSectionProjectInfoProps,
) {
  const { usePaymentRequestForm } = props;

  const { searchProject, searchClassificationUsp } = usePaymentRequestForm;

  const [state, setState] = useState<ISectionProjectInfoState>({
    project: {
      loading: false,
      options: [],
    },
    classificationUsp: {
      loading: false,
      options: [],
    },
  });

  const form = useFormContext();
  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = form;

  const project = watch("project");
  const classificationUsp = watch("classificationUsp");

  const company = watch("company") as ITypeaheadOption<{
    hasProject: boolean;
  }> | null;

  const competency = watch("competency") as ITypeaheadOption<{
    isUsp: boolean;
  }> | null;

  const isUsp = competency?.metadata?.isUsp;
  const hasProject = company?.metadata?.hasProject || false;

  const handleSearchProjectChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchProject({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          order: [],
          length: 100,
        },
      });

      const projects = response.data;

      setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          options: projects,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          loading: false,
        },
      }));
    }
  };

  const handleProjectChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;

    setValue("project", value, { shouldValidate: true });
  };

  const handleSearchClassificationUspChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      classificationUsp: {
        ...prevState.classificationUsp,
        loading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;

      const clsUsps = await searchClassificationUsp({
        search,
        companyGroupId,
        activesOnly: true,
        payloadOptions: {
          length: 100,
        },
      });

      setState(prevState => ({
        ...prevState,
        classificationUsp: {
          ...prevState.classificationUsp,
          loading: false,
          options: clsUsps.data,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        classificationUsp: {
          ...prevState.classificationUsp,
          loading: false,
        },
      }));
    }
  };

  const handleClassificationUspChange = (event: ISoulTypeaheadChangeEvent) => {
    const value = event?.target?.value;
    setValue("classificationUsp", value, { shouldValidate: true });
  };

  useEffect(() => {
    register("project", { required: hasProject });
    register("classificationUsp", { required: isUsp });
  }, [hasProject, isUsp, register]);

  return (
    <Card>
      <header>Informações do Projeto</header>
      <section>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              Projeto{" "}
              {state.project.loading && <i className="pi pi-spin pi-spinner" />}
            </span>
            <SoulTypeahead
              serverSide
              openOnFocus
              id="project"
              value={project}
              placeholder="Projeto"
              data-testid="txt-project"
              onChange={handleProjectChange}
              options={state.project.options}
              loading={state.project.loading}
              onSearchChange={handleSearchProjectChange}
              className={errors?.project ? "isInvalid" : undefined}
            />
            <InvalidFeedback
              condition={(errors?.project as FieldError)?.type === "required"}
              message="Este campo é obrigatório"
            />
          </label>
          {isUsp && (
            <label className="col-6 form-control">
              <span>
                Classificação USP{" "}
                {state.classificationUsp.loading && (
                  <i className="pi pi-spin pi-spinner" />
                )}
              </span>
              <SoulTypeahead
                serverSide
                openOnFocus
                id="classificationUsp"
                value={classificationUsp}
                onChange={handleClassificationUspChange}
                placeholder="Classificação USP"
                data-testid="txt-classificationUsp"
                onSearchChange={handleSearchClassificationUspChange}
                options={state.classificationUsp.options}
                loading={state.classificationUsp.loading}
                className={errors?.classificationUsp ? "isInvalid" : undefined}
              />
              <InvalidFeedback
                condition={
                  (errors?.classificationUsp as FieldError)?.type === "required"
                }
                message="Este campo é obrigatório"
              />
            </label>
          )}
        </div>
      </section>
    </Card>
  );
}
