import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import {
  FeeFormEntity,
  IFeeFormEntity,
} from "../../../domain/entities/feeFormEntity";
import { MakeFee } from "../../../main/makeFee";
import { Container } from "./styles";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { SearchClassificationAssessmentField } from "../SearchClassificationAssessmentField";
import { IFeeExpirationType } from "../../../domain/entities/feeExpirationTypeEnum";
import { ExpirationTypeField } from "../ExpirationTypeField";
import { ExpirationDayField } from "../ExpirationDayField";
import { useFeeForm } from "../../hooks/useFeeForm";
import { SearchProviderField } from "../SearchProviderField";
import { IProviderEntity } from "../../../../provider/domain/entities/providerEntity";
import { IClassificationAssessmentEntity } from "../../../../classificationAssessment/domain/entities/classificationAssessment";
import { MakeClassificationAssessment } from "../../../../classificationAssessment/main/makeClassificationAssessment";

interface IFeeFormModalProps {
  useFee: MakeFee;
  isOpen: boolean;
  currentId?: string;
  readonly: boolean;
  useProvider: MakeProvider;
  onClose: (shouldRefresh: unknown) => void;
  getClassificationAssessment: MakeClassificationAssessment["getClassificationAssessment"];
}

export function FeeFormModal(props: IFeeFormModalProps) {
  const {
    useFee,
    isOpen,
    onClose,
    readonly,
    currentId,
    useProvider,
    getClassificationAssessment,
  } = props;

  const {
    getFee,
    saveFee,
    updateFee,
    searchProviders,
    listFeeExpirationTypes,
    searchClassificationAssessment,
  } = useFee;

  const { getProvider } = useProvider;

  const [isActive, setIsActive] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [providerValue, setProviderValue] = useState<IProviderEntity>();

  const [classificationAssessmentValue, setClassificationAssessmentValue] =
    useState<IClassificationAssessmentEntity>();

  const [expirationTypesList, setExpirationTypesList] = useState<
    IFeeExpirationType[]
  >([]);

  const dialog = useSoulDialog();
  const createFeeForm = useFeeForm();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  const feeForm = useForm<IFeeFormEntity>({
    mode: "all",
    defaultValues: FeeFormEntity.create(),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = feeForm;

  /**
   * Lida com o envio dos dados do formulário para o servidor.
   * @param payload - Objeto contendo os dados do imposto
   */
  const submitFee = useCallback(
    async (formFields: IFeeFormEntity) => {
      const payload = {
        ...formFields,
        active: isActive,
        id: currentId || "",
        companyGroupId: currentCompanyGroup.id,
      };

      const actionText = currentId ? "atualizado" : "cadastrado";

      if (!currentId) {
        await saveFee(payload);
      } else {
        await updateFee(payload);
      }

      dialog.fire({
        title: "Feito!",
        icon: "success",
        text: `Imposto ${actionText} com sucesso`,
      });
      onClose(true);
    },
    [
      dialog,
      onClose,
      saveFee,
      isActive,
      updateFee,
      currentId,
      currentCompanyGroup.id,
    ],
  );

  /**
   * Lida com a abertura do modal.
   */
  const handleModalOpen = useCallback(async () => {
    setIsLoading(true);

    try {
      const expirationTypesResponse = await listFeeExpirationTypes();
      setExpirationTypesList(expirationTypesResponse);

      if (!currentId) {
        setIsLoading(false);
        return;
      }

      const response = await getFee(currentId);

      if (response.providerId) {
        const providerResponse = await getProvider(response.providerId);
        setProviderValue(providerResponse);
      }

      if (response.classificationAssessmentId) {
        const assessmentResponse = await getClassificationAssessment(
          response.classificationAssessmentId,
        );
        const finalPayload: IClassificationAssessmentEntity = {
          ...assessmentResponse,
          macroBoxId:
            assessmentResponse.macroCategory?.rawValue.toString() || "",
          macroBoxName:
            assessmentResponse.macroCategory?.label.toString() || "",
        };

        setClassificationAssessmentValue(finalPayload);
      }

      const formValues = createFeeForm(response);
      reset(formValues);
      setIsActive(response.active);
    } catch {
      onClose(false);
    } finally {
      setIsLoading(false);
    }
  }, [
    reset,
    getFee,
    onClose,
    currentId,
    getProvider,
    createFeeForm,
    listFeeExpirationTypes,
    getClassificationAssessment,
  ]);

  /**
   * Lida com o fechamento do modal resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    setProviderValue(undefined);
    setClassificationAssessmentValue(undefined);
    reset(FeeFormEntity.create());
  }, [reset]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={handleModalOpen}
      shouldCloseOnEsc={!isLoading}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={!isLoading}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>{currentId ? "Editar Imposto" : "Novo Imposto"}</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={() => onClose(false)}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="crud-header">
              <p>Este registro será vinculado ao grupo de empresa</p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <FormProvider {...feeForm}>
                <form
                  id="fee-crud-form"
                  className="form-container"
                  onSubmit={handleSubmit(submitFee)}
                >
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Nome</span>
                      <input
                        id="name"
                        type="text"
                        placeholder="Nome"
                        disabled={readonly}
                        data-testid="txt-name"
                        {...register("name", { required: true })}
                        className={errors?.name ? "isInvalid" : ""}
                      />
                      <InvalidFeedback
                        condition={errors.name?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <SearchProviderField
                        readonly={readonly}
                        value={providerValue}
                        useProvider={useProvider}
                        isInvalid={!!errors?.providerId}
                        searchProviders={searchProviders}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <SearchClassificationAssessmentField
                        readonly={readonly}
                        value={classificationAssessmentValue}
                        search={searchClassificationAssessment}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <ExpirationTypeField
                        readonly={readonly}
                        expirationTypesList={expirationTypesList}
                      />
                    </label>
                    <label className="col-6 form-control">
                      <ExpirationDayField readonly={readonly} />
                    </label>
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-close"
                className="form-button red-bkg"
                onClick={() => onClose(false)}
              >
                Fechar
              </button>
              <button
                id="btn-save"
                type="submit"
                form="fee-crud-form"
                disabled={readonly || isSubmitting}
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Salvar {isSubmitting && <FaSpinner className="spinner" />}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
