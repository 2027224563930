import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IGetStateByUfService } from "../../domain/usecases/getStateByUfUseCase";
import { IStateResponse } from "../models/stateResponse";

export class GetStateByUfService implements IGetStateByUfService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async getStateByUf(uf: string): Promise<IStateResponse | null> {
    const userEntity = this.getUserLocalService.get();
    const url = `/States/UF/${uf}`;
    const { id, name }: IStateResponse = await this.api.get<IStateResponse>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const res: IStateResponse = {
      id,
      name,
    };

    return res;
  }
}
