import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function PaymentMethodField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.paymentMethod` as const;

  const { state, handleInputClassName } = useDebtImportPage();

  const { paymentMethodsEnums } = state;

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
          const { value } = event.target;

          const enumValue =
            paymentMethodsEnums?.find(e => e.key === Number(value)) || null;

          field.onBlur();
          field.onChange(enumValue);
        };

        return (
          <label className="form-control">
            <span>Método de Pagamento</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <select
                {...field}
                onChange={onChange}
                value={field.value?.key || ""}
                id={`slc-paymentMethod-${formIndex}`}
                data-testid={`slc-paymentMethod-${formIndex}`}
              >
                <option value="" hidden disabled>
                  Selecione um método
                </option>
                {paymentMethodsEnums?.map(enumObject => {
                  return (
                    <option
                      value={enumObject.key}
                      key={`${enumObject.key}-${enumObject.value}`}
                    >
                      {enumObject.value}
                    </option>
                  );
                })}
              </select>
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
