import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IApprovePaymentRequestContract } from "../../domain/contracts/approvePaymentRequestContract";
import { IPaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";

export class ApprovePaymentRequestService
  implements IApprovePaymentRequestContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async approvePaymentRequest(formValues: IPaymentRequestFormEntity) {
    const userEntity = this.getUserLocalService.get();
    const url = "AccountsPayable";

    const issueDate = formValues.issueDate
      ? format(
          parse(formValues.issueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const payUntil = formValues.payUntil
      ? format(
          parse(formValues.payUntil, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const terminationDate = formValues.terminationDate
      ? format(
          parse(formValues.terminationDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd",
        )
      : "";

    const payload = {
      active: true,
      assessments: formValues.assessments.map(assessmentObject => {
        return {
          id: assessmentObject.id || "",
          classificationAssessmentId: assessmentObject.classificationAssessment
            ?.rawValue as string,
          costCenterId: assessmentObject.costCenter?.rawValue as string,
          observation: assessmentObject.observation.replace(/\s+/g, " ").trim(),
          percentage: assessmentObject.percentage,
          value: assessmentObject.value,
        };
      }),
      classificationAccountId: formValues.classificationAccount
        ?.rawValue as string,
      classificationUspId:
        (formValues.classificationUsp?.rawValue as string) || null,
      companyId: formValues.company?.rawValue as string,
      competencyId: (formValues.competency?.rawValue as string) || null,
      description: formValues.description.replace(/\s+/g, " ").trim(),
      documentNumber: formValues.documentNumber,
      documentStatus: formValues.documentStatus?.key,
      issueAsPaid: formValues.issueAsPaid,
      issueDate,
      observation: formValues.observation.replace(/\s+/g, " ").trim(),
      paymentAccountId: formValues.paymentAccount?.rawValue as string,
      paymentMethod: formValues.paymentMethod?.key,
      paymentRequestId: formValues.id,
      payUntil,
      projectId: (formValues.project?.rawValue as string) || null,
      providerId: formValues.provider?.rawValue as string,
      status: formValues.status,
      terminationDate,
      value: formValues.value,
      fees: formValues.fees,
      generateFeeRetention: formValues.generateFeeRetention,
      fuspPurchaseOrderId: formValues.fuspPurchaseOrderId,
    };

    const response = await this.api.post<{ id: string }>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response.id;
  }
}
