export interface IGetStoredCostCenterReportPanoramaIdService {
  getStoredCostCenterReportPanoramaId(): string;
}

export interface IGetStoredCostCenterReportPanoramaIdUseCase {
  getStoredCostCenterReportPanoramaId(): string;
}

export class GetStoredCostCenterReportPanoramaIdUseCase
  implements IGetStoredCostCenterReportPanoramaIdUseCase
{
  constructor(
    private getStoredCostCenterReportPanoramaIdService: IGetStoredCostCenterReportPanoramaIdService,
  ) {}

  getStoredCostCenterReportPanoramaId(): string {
    return this.getStoredCostCenterReportPanoramaIdService.getStoredCostCenterReportPanoramaId();
  }
}
