import { ITypeaheadOption } from "../../../core/domain/entities/typeaheadOption";
import { ICostCenterResumedEntity } from "./costCenterEntity";
import { IProjectEntity } from "./projectEntity";

export type IProjectFormEntity = Pick<
  IProjectEntity,
  | "id"
  | "active"
  | "activitiesEndDate"
  | "activitiesStartDate"
  | "companyGroupId"
  | "courseStartDate"
  | "description"
  | "fullName"
  | "fuspHash"
  | "openingBalance"
> & {
  competency: ITypeaheadOption | null;
  coordinator: ITypeaheadOption | null;
  costCenters: ICostCenterResumedEntity[];
  deputyCoordinator: ITypeaheadOption | null;
};

export class ProjectFormEntity implements IProjectFormEntity {
  id = "";
  active = false;
  activitiesEndDate = "";
  activitiesStartDate = "";
  companyGroupId = "";
  competency = null;
  coordinator = null;
  costCenters = [];
  courseStartDate = "";
  deputyCoordinator = null;
  description = "";
  fullName = "";
  fuspHash = "";
  openingBalance = 0;

  constructor(init?: Partial<IProjectFormEntity>) {
    Object.assign(this, init);
  }

  static create(init?: Partial<IProjectFormEntity>) {
    return new ProjectFormEntity(init);
  }
}
