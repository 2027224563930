import { useMemo } from "react";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { useFinalStepGrid } from "../../hooks/useFinalStepGrid";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";

interface IFinalStepGridProps {
  handleFormsRemoval(formIds: string[]): boolean;
  fields: FieldArrayWithId<ISolicitImportForm, "imports", "formId">[];
}

export function FinalStepGrid(props: IFinalStepGridProps) {
  const { fields, handleFormsRemoval } = props;

  const {
    handleRowToggle,
    state: { showInvalidOnly, expandedRows },
  } = useSolicitImportPage();

  const { getFieldState } = useFormContext<ISolicitImportForm>();

  const { columns, handleRowExpansionTemplate, handleRowClassName } =
    useFinalStepGrid(handleFormsRemoval);

  const tableData = useMemo(() => {
    if (showInvalidOnly) {
      return fields.filter((_, index) => {
        const fieldState = getFieldState(`imports.${index}`);
        return !!fieldState.error;
      });
    }
    return fields;
  }, [showInvalidOnly, fields, getFieldState]);

  return (
    <ClientSoulTable
      rowHover
      dataKey="id"
      data={tableData}
      columns={columns}
      expandedRows={expandedRows}
      onRowToggle={handleRowToggle}
      rowsPerPageOptions={[5, 10, 50]}
      tableClassName="assessment-table"
      rowClassName={handleRowClassName}
      emptyMessage="Nenhum registro encontrado"
      rowExpansionTemplate={handleRowExpansionTemplate}
    />
  );
}
