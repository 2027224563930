import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../../../core/domain/entities/userEntity";
import { useApiResponseErrorHandlers } from "../../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useUserLocal } from "../../../../../../core/presentation/hooks/useUserLocal";
import { makePaymentRequests } from "../../../main/makePaymentRequests";
import { PaymentRequestPageProvider } from "../../hooks/usePaymentRequestPage";
import { RequesterPageProvider } from "../../hooks/useRequesterPage";
import { PaymentRequestPage } from "../PaymentRequestPage";
import { RequesterPage } from "../RequesterPage";

interface IPaymentRequestsPageFactoryProps {
  api: IApiService;
}

export function PaymentRequestsPageFactory({
  api,
}: IPaymentRequestsPageFactoryProps) {
  const {
    REACT_APP_SERVER_URL,
    REACT_APP_PECEGE_AUTH_API_URL,
    REACT_APP_API_VERSION,
  } = process.env;

  const { user } = useUserLocal();
  const apiResponseErrorHandlers = useApiResponseErrorHandlers();
  const authBaseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;
  const authApi = new ApiService(authBaseUrl, apiResponseErrorHandlers);

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const usePaymentRequests = makePaymentRequests(api, authApi, barcodeApi);

  const isRequesterOrManager = [
    EUserProfile.requester,
    EUserProfile.manager,
  ].includes(user.profile);

  if (isRequesterOrManager) {
    return (
      <RequesterPageProvider usePaymentRequests={usePaymentRequests}>
        <RequesterPage />
      </RequesterPageProvider>
    );
  }

  return (
    <PaymentRequestPageProvider usePaymentRequests={usePaymentRequests}>
      <PaymentRequestPage />
    </PaymentRequestPageProvider>
  );
}
