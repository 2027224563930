import { IEntity } from "../../../../core/domain/entities/entity";

export interface IPaymentAccountEntity extends IEntity {
  name: string;
}

export class PaymentAccountEntity implements IPaymentAccountEntity {
  id = "";
  name = "";
  active = true;

  constructor(params?: Partial<IPaymentAccountEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IPaymentAccountEntity>) {
    return new PaymentAccountEntity(params);
  }
}
