import styled from "styled-components";

export const Container = styled.div`
  width: 25rem;

  div.react-modal-body {
    padding-top: unset;
  }

  .react-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ConfirmDialogContainer = styled.div`
  overflow-y: auto;
  margin-top: 1rem;
  max-height: 25rem;

  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    font-size: 0.875rem;
    color: var(--color-danger);

    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    border-radius: 0.375rem;
    background-color: #f8d7da;

    span:first-child {
      flex-basis: 40%;
    }

    span:last-child {
      flex-basis: 60%;
    }
  }
`;
