import { ProgressBar } from "primereact/progressbar";
import { FaSpinner } from "react-icons/fa";
import styled from "styled-components";

const ExportingModalContentContainer = styled.div`
  font-size: 0.975rem;

  p,
  .progress-bar {
    margin: 1rem 0;
  }

  p:first-of-type {
    margin-top: 0;
  }

  .export-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
    height: 2em;

    svg {
      margin-left: 0.5rem;
    }
  }

  .final-message {
    p:first-of-type {
      font-size: 1rem;
      margin-bottom: 0;
    }
    strong {
      font-size: 0.85rem;
    }
  }
`;

interface ExportinAttachmentsModalContentProps {
  message?: string;
}

export function ExportingAttachmentsModalContent(
  props: ExportinAttachmentsModalContentProps,
) {
  const { message } = props;

  return (
    <ExportingModalContentContainer>
      <p>
        O link para download dos anexos será enviado para seu e-mail em alguns
        minutos.
      </p>
      <p>
        Se não encontrar o e-mail, verifique sua caixa de spam ou faça a
        exportação novamente.
      </p>
      {message ? (
        <div className="final-message">
          <p>{message}</p>
          <strong>Você já pode fechar esta mensagem.</strong>
        </div>
      ) : null}
      {!message ? (
        <>
          <div className="progress-bar">
            <ProgressBar mode="indeterminate" style={{ height: "0.375rem" }} />
          </div>
          <div className="export-modal-footer">
            Processando dados
            <FaSpinner className="spinner" />
          </div>
        </>
      ) : null}
    </ExportingModalContentContainer>
  );
}
