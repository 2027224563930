import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchLastFuspSheetExportedContract } from "../../domain/contracts/fetchLastFuspSheetExportedContract";
import { ILastFuspSheetExportedEntity } from "../../domain/entities/lastFuspSheetExportedEntity";
import { ILastFuspSheetExportedModel } from "../models/lastFuspSheetExportedModel";

export class FetchLastFuspSheetExportedService
  implements IFetchLastFuspSheetExportedContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async fetchLastFuspSheetExported(): Promise<ILastFuspSheetExportedEntity> {
    const url = `/Fusp/LastAccountPayableExportation`;
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.get<ILastFuspSheetExportedModel>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return {
      currentDate: response.currentDate,
      endDate: response.finalDate,
      lastExportedDate: response.exportationDate,
      lastExportedUserName: response.userNameCreated,
    };
  }
}
