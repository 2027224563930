import styled from "styled-components";

export const Container = styled.div`
  .form-header {
    padding: 1.5rem 1.5rem 0;

    /* TODO - levar isso aos outros titulos do projeto que forem com o mesmo padrao */
    font-size: 0.875rem;
    font-weight: 600;
    color: #525f7f;
  }

  .form-container {
    padding: 1.5rem;

    .custom-form-control {
      > div {
        display: flex;
        flex-wrap: nowrap;

        .custom-form-input {
          display: flex;
          flex-direction: column;
          padding-left: 0.9375rem;

          .form-input {
            display: flex;
            width: 100%;
          }

          .isInvalid {
            box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
            border-color: var(--invalid);

            :focus {
              box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
              border-color: var(--invalid);
            }
          }
        }
      }
    }
  }
`;
