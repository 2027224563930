export enum EPaymentRequestDestination {
  Financial = 1,
  PersonnelDepartment = 2,
}

export const paymentRequestDestinationLang: Record<
  keyof typeof EPaymentRequestDestination,
  string
> = {
  Financial: "Financeiro",
  PersonnelDepartment: "Dep. Pessoal",
};

export enum EPaymentRequestDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export const paymentRequestDocumentStatusLang: Record<
  keyof typeof EPaymentRequestDocumentStatus,
  string
> = {
  Complete: "Completo",
  Incomplete: "Incompleto",
};

export enum EPaymentRequestStatus {
  NotRequested = 0,
  Requested = 1,
  Canceled = 2,
  Approved = 3,
  Rejected = 4,
  Paid = 5,
}

export const paymentRequestStatusLang: Record<
  keyof typeof EPaymentRequestStatus,
  string
> = {
  NotRequested: "Não solicitado",
  Requested: "Solicitado",
  Canceled: "Cancelado",
  Approved: "Aprovado",
  Rejected: "Reprovado",
  Paid: "Pago",
};

export enum EPaymentRequestPaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
  Pix = 7,
}

export const paymentRequestPaymentMethodLang: Record<
  keyof typeof EPaymentRequestPaymentMethod,
  string
> = {
  Billet: "Boleto",
  CreditCard: "Cartão de Crédito",
  CurrentAccountTransfer: "Transferência em Conta Corrente",
  Cash: "Dinheiro",
  Check: "Cheque",
  DirectDebit: "Débito em Conta Corrente",
  Pix: "Pix",
};
