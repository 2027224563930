import { useCallback, useMemo } from "react";
import { FaList } from "react-icons/fa";
import { SimpleColumn } from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IImportationMatchesCostCenterEntity } from "../../domain/entities/importationMatchesCostCenterEntity";
import { EImportationMatchesOrigin } from "../../domain/entities/importationMatchesPayloadEntity";

interface UseImportationMatchesCostCentersGridProps {
  origin: EImportationMatchesOrigin;
  openMatchModal: (costCenter: IImportationMatchesCostCenterEntity) => void;
}

export function useImportationMatchesCostCentersGrid(
  props: UseImportationMatchesCostCentersGridProps,
) {
  const { origin, openMatchModal } = props;

  /** Constrói a célula que indica quando um centro de custo está ativo. */
  const activeBodyTemplate = useCallback(
    (costCenter: IImportationMatchesCostCenterEntity) => {
      const active = costCenter?.active;
      return (
        <span
          className={`importation-badge ${active ? "blue-bkg" : "red-bkg"}`}
        >
          {active ? "Ativo" : "Inativo"}
        </span>
      );
    },
    [],
  );

  /** Constrói a célula para abrir o modal de vínculos de classes LMS. */
  const matchClassesBodyTemplate = useCallback(
    (costCenter: IImportationMatchesCostCenterEntity) => {
      const originName =
        origin === EImportationMatchesOrigin.LMS ? "LMS" : "Solution";

      return (
        <div className="table-actionbar">
          <button
            type="button"
            data-place="left"
            data-effect="solid"
            className="outline-button tool"
            data-tip={`Vincular Turma do ${originName} `}
            onClick={() => {
              openMatchModal(costCenter);
            }}
          >
            <FaList />
          </button>
        </div>
      );
    },
    [openMatchModal, origin],
  );

  /** Constrói a célula que indica se há vínculo com alguma turma. */
  const hasLinkWithClassBodyTemplate = useCallback(
    (costCenter: IImportationMatchesCostCenterEntity) => {
      return (
        <span className="table-hasLink">
          {costCenter.hasLinkWithClass ? "Sim" : "Não"}
        </span>
      );
    },
    [],
  );

  const columns = useMemo(() => {
    return [
      { field: "acronym", header: "Sigla", searchable: true, orderable: true },
      {
        field: "description",
        header: "Descrição",
        searchable: true,
        orderable: true,
      },
      {
        field: "active",
        header: "Ativo",
        orderable: true,
        searchable: false,
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "hasLinkWithClass",
        header: "Possui vínculo?",
        searchable: false,
        orderable: true,
        bodyTemplate: hasLinkWithClassBodyTemplate,
      },

      {
        header: "",
        searchable: false,
        orderable: false,
        bodyTemplate: matchClassesBodyTemplate,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [
    activeBodyTemplate,
    matchClassesBodyTemplate,
    hasLinkWithClassBodyTemplate,
  ]);

  return {
    columns,
  };
}
