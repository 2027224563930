import { useFormContext } from "react-hook-form";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { IAccountPayableFormEntity } from "../../../domain/entities/accountPayableFormEntity";

interface ISectionFuspInfoProps {
  readonly: boolean;
}

export function SectionFuspInfo({ readonly }: ISectionFuspInfoProps) {
  const form = useFormContext<IAccountPayableFormEntity>();
  const {
    watch,
    register,
    formState: { errors },
  } = form;

  const company = watch("company") as ITypeaheadOption<{
    hasProject: boolean;
    assumedName: string;
  }> | null;

  const isFusp = company?.label?.toUpperCase().includes("FUSP") || false;

  if (!isFusp) {
    return null;
  }

  return (
    <Card>
      <header>Informações FUSP</header>
      <section>
        <div className="form-row">
          <label className="col-6 form-control">
            <span>
              N° pedido de compra <small>(opcional)</small>
            </span>

            <input
              {...register("fuspPurchaseOrderId", {
                validate: {
                  onlyDigit: val => val?.replace(/\d/g, "") === "",
                  maxLength: val => val.length <= 16,
                  max: val => BigInt(val || "") <= Number.MAX_SAFE_INTEGER,
                },
              })}
              id="txt-fusp-purchase-order-id"
              data-testid="txt-fusp-purchase-order-id"
              placeholder="N° pedido de compra (opcional)"
              type="text"
              className={errors?.fuspPurchaseOrderId ? "isInvalid" : undefined}
              disabled={readonly}
            />
            <InvalidFeedback
              condition={errors.fuspPurchaseOrderId?.type === "onlyDigit"}
              message="O n° pedido de compra deve conter apenas números."
            />
            <InvalidFeedback
              condition={
                errors.fuspPurchaseOrderId?.type === "max" ||
                errors.fuspPurchaseOrderId?.type === "maxLength"
              }
              message="O número do pedido deve ter no máximo 16 dígitos"
            />
          </label>
        </div>
      </section>
    </Card>
  );
}
