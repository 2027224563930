import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICancelPaymentRequestContract } from "../../domain/contracts/cancelPaymentRequestContract";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";

export class CancelPaymentRequestService
  implements ICancelPaymentRequestContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  cancelPaymentRequest(paymentRequestId: string, deleteReason: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentRequests/${paymentRequestId}/Status`;

    const data = {
      status: EPaymentRequestStatus.Canceled,
      reason: deleteReason,
    };

    return this.api.put(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
