import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPecegePayBillingCompaniesContract } from "../../domain/contracts/getPecegePayBillingCompaniesContract";
import { IPecegePayBillingCompany } from "../../domain/entities/pecegePayBillingCompanyEntity";

export class GetPecegePayBillingCompaniesService
  implements IGetPecegePayBillingCompaniesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPecegePayBillingCompanies() {
    const userEntity = this.getUserLocalService.get();

    const url = `/PecegePay/BillingCompanies`;

    return this.api.get<IPecegePayBillingCompany[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
