import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompanyEntity } from "../../domain/entities/companyEntity";
import { ICompanyTaxFormEntity } from "../../domain/entities/companyTaxFormEntity";
import { ISaveCompanyTaxesService } from "../../domain/usecases/saveCompanyTaxesUseCase";

export class SaveCompanyTaxesService implements ISaveCompanyTaxesService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveCompanyTaxes(companyId: string, payload: ICompanyTaxFormEntity[]) {
    const userEntity = this.getUserLocalService.get();
    const url = `/Companies/${companyId}/taxes`;

    const formattedPayload = payload.map(t => {
      const parsedStartDate = parse(t.startDate, "ddMMyyyy", new Date());
      const startDate = format(parsedStartDate, "yyyy-MM-dd");

      let endDate = "";

      if (t.endDate) {
        const parsedEndDate = parse(t.endDate, "ddMMyyyy", new Date());
        endDate = format(parsedEndDate, "yyyy-MM-dd");
      }

      return {
        ...t,
        endDate,
        startDate,
      };
    });

    return this.api.post<ICompanyEntity>(url, formattedPayload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
