import { IClassificationAccountEntity } from "../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";

export interface IListClassificationsAccountService {
  listClassificationsAccount(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>>;
}

export interface IListClassificationsAccountUseCase {
  listClassificationsAccount(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>>;
}

export class ListClassificationsAccountUseCase
  implements IListClassificationsAccountUseCase
{
  constructor(
    private listClassificationsAccountService: IListClassificationsAccountService,
  ) {}

  listClassificationsAccount(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IClassificationAccountEntity[]>> {
    return this.listClassificationsAccountService.listClassificationsAccount(
      companyGroupId,
      payload,
    );
  }
}
