import { format, isAfter, isBefore, isValid, parse, subDays } from "date-fns";
import { DropdownChangeParams } from "primereact/dropdown";
import {
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaTrash } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  IPanoramaEntity,
  PanoramaEntity,
} from "../../../../advTable/domain/entities/panoramaEntity";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { IFiltersValue } from "../../domain/contracts/listUspIncomeStatementReportContract";
import { IUseToolbarParams } from "../components/Toolbar";
import { PanoramaOption } from "../components/Toolbar/styles";
import { useUspIncomeStatementReportGrid } from "./useUspIncomeStatementReportGrid";

export function useToolbar(params: IUseToolbarParams) {
  const {
    isGridFiltered,
    useUspIncomeStatementReport,
    filters: { startDate, endDate },
    onPanoramaChange,
    onFiltersChange,
  } = params;

  const {
    currentCompanyGroup: { id: currentCompanyGroupId },
  } = useCurrentCompanyGroup();

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  /** Esta é a representação do panorama padrão de relatoriod e cc */
  const defaultPanorama = useMemo(() => {
    return PanoramaEntity.create({
      id: "default-panorama",
      name: "Padrão do sistema",
      systemDefault: true,
    });
  }, []);

  const [loading, setLoading] = useState({
    panoramas: true,
    entityOptions: true,
  });

  /** Estados de formulário. */
  const [dates, setDates] = useState({ startDate, endDate });

  /** Estados do Panorama */
  const [panoramaOptions, setPanoramaOptions] = useState<IPanoramaEntity[]>();
  const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
  const [selectedPanorama, setSelectedPanorama] =
    useState<IPanoramaEntity>(defaultPanorama);

  /** Funções relacionadas aos campos de data. */
  const parsedStartDate = useMemo(() => {
    const { startDate: _startDate } = dates;
    return parse(_startDate, "dd/MM/yyyy", new Date());
  }, [dates]);

  const parsedEndDate = useMemo(() => {
    const { endDate: _endDate } = dates;
    return parse(_endDate, "dd/MM/yyyy", new Date());
  }, [dates]);

  const isStartDateValid = useMemo<boolean>(() => {
    if (dates.startDate === "") {
      return true;
    }

    if (!isValid(parsedStartDate)) {
      return false;
    }

    if (isAfter(parsedStartDate, parsedEndDate)) {
      return false;
    }

    return true;
  }, [dates.startDate, parsedEndDate, parsedStartDate]);

  const isEndDateValid = useMemo<boolean>(() => {
    if (dates.endDate === "") {
      return true;
    }

    if (!isValid(parsedEndDate)) {
      return false;
    }

    if (isBefore(parsedEndDate, parsedStartDate)) {
      return false;
    }

    return true;
  }, [dates.endDate, parsedEndDate, parsedStartDate]);

  const dialog = useSoulDialog();

  /**
   * Executado quando o usuario clica em "Filtrar". Responsável por disparar
   * o evento que desencadeia uma nova requisição e novo render na grid.
   * Pois páginas de relatório começam sem dados na grid, aguardando que
   * o usuario faça ao menos algum tipo de filtro na UI.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isStartDateValid || !isEndDateValid) {
      return;
    }

    const filtersValue: IFiltersValue = {
      endDate: dates.endDate,
      startDate: dates.startDate,
    };

    if (!dates.startDate && !dates.endDate && !isGridFiltered) {
      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Nenhum filtro foi selecionado. <br />
            Apenas registros dos últimos 30 dias serão exibidos. <br />
            Deseja continuar?
          </>
        ),
        cancelButtonText: "Não",
        showCancelButton: true,
        confirmButtonText: "Sim",
      });

      if (result.dismiss) {
        return;
      }

      const today = new Date();
      filtersValue.endDate = format(today, "dd/MM/yyyy");
      filtersValue.startDate = format(subDays(today, 30), "dd/MM/yyyy");
    }

    setDates(prevDates => {
      return {
        ...prevDates,
        startDate: filtersValue.startDate,
        endDate: filtersValue.endDate,
      };
    });

    onFiltersChange(filtersValue);
  };

  const {
    listUspIncomeStatementReportPanoramas,
    deleteUspIncomeStatementReportPanorama,
    storeUspIncomeStatementReportPanoramaId,
    getStoredUspIncomeStatementReportPanoramaId,
  } = useUspIncomeStatementReport;

  const { user } = useUserLocal();

  const { columns } = useUspIncomeStatementReportGrid({
    useUspIncomeStatementReport,
  });

  /**
   * Obtem lista de panoramaas para preencher o dropdown de panoramas,
   * tambem resgata o ultimo panorama utilizado por este usuário e escolhe
   * este no dropdown automaticaemente
   */
  const fetchPanoramas = useCallback(async () => {
    const { userId } = user;

    setLoading(prevLoading => {
      return { ...prevLoading, panoramas: true };
    });

    const panoramas = await listUspIncomeStatementReportPanoramas(
      userId,
      columns,
    );

    const panoramaList = [defaultPanorama, ...panoramas];
    setPanoramaOptions(panoramaList);

    setLoading(prevLoading => {
      return { ...prevLoading, panoramas: false };
    });

    const panoramaId = getStoredUspIncomeStatementReportPanoramaId();

    if (panoramaId) {
      const panorama = panoramaList.find(pano => pano.id === panoramaId);

      if (panorama) {
        setSelectedPanorama(panorama);
        onPanoramaChange(panorama, false);
      }
    }
  }, [
    user,
    columns,
    defaultPanorama,
    onPanoramaChange,
    listUspIncomeStatementReportPanoramas,
    getStoredUspIncomeStatementReportPanoramaId,
  ]);

  useEffect(() => {
    fetchPanoramas();
    // REVIEW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Deleta o panorama cujo botão "Excluir" foi clicado pelo usuario. */
  const handleDeletePanoramaButtonOnClick = useCallback(
    async (option: IPanoramaEntity, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const { name } = option;

      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          try {
            await deleteUspIncomeStatementReportPanorama(option.id);
          } finally {
            dialog.close();
          }

          return true;
        },
        html: (
          <>
            O panorama <b>{name}</b> será excluído permanentemente.
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      if (result.dismiss) {
        return;
      }

      setPanoramaOptions(prevPanOptions => {
        const index = prevPanOptions?.findIndex(pan => pan.id === option.id);

        if (index && prevPanOptions) {
          prevPanOptions?.splice(index, 1);
          return [...prevPanOptions];
        }

        return prevPanOptions;
      });

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            O panorama <b>{name}</b> foi excluído com sucesso.
          </>
        ),
      });
    },
    [deleteUspIncomeStatementReportPanorama, dialog],
  );

  /**
   * Renderiza o jsx de uma opção do dropdown de
   * panoramas, incluir o botão exlcuir
   */
  const renderPanoramaOption = useCallback(
    (option: IPanoramaEntity) => {
      const { name, systemDefault } = option;
      return (
        <PanoramaOption title={name}>
          <span>{name}</span>
          {!systemDefault && (
            <button
              title={`Excluir ${name}`}
              onClick={event =>
                handleDeletePanoramaButtonOnClick(option, event)
              }
              type="button"
            >
              <FaTrash />
            </button>
          )}
        </PanoramaOption>
      );
    },
    [handleDeletePanoramaButtonOnClick],
  );

  /** Abre modal p/ salvar um novo panorama */
  const handleSavePanoramaButtonOnClick = useCallback(() => {
    setIsPanoramaModalOpen(true);
  }, []);

  /**
   * Define o panorama atual quando o usuário escolhe um panorama no dropdown.
   * Também é responsável por armazenar o último panorama escolhido em
   * localStorage e disparar evento que faz nova consulta e novo render na grid.
   */
  const handlePanoramaDropdownOnChange = ({ value }: DropdownChangeParams) => {
    const panorama = value as IPanoramaEntity;
    const panoramaId = panorama.id;

    storeUspIncomeStatementReportPanoramaId(panoramaId);
    setSelectedPanorama(panorama);
    onPanoramaChange(panorama);
  };

  /** Atualiza os estados da UI após salvar um novo panorama (post na API). */
  const handleOnPanoramaSave = (newPanorama: IPanoramaEntity) => {
    setPanoramaOptions(prevPanoramaOptions => {
      if (prevPanoramaOptions) {
        return [...prevPanoramaOptions, newPanorama];
      }

      return [newPanorama];
    });
    setSelectedPanorama(newPanorama);
    storeUspIncomeStatementReportPanoramaId(newPanorama.id);
  };

  /**
   * REVIEW - Esse efeito colateral é necessário para que os filtros e seletores
   * da toolbar sejam "reiniciados" quando houver alteração no valor do atual
   * do grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== currentCompanyGroupId) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = currentCompanyGroupId;

      setDates({
        startDate: "",
        endDate: "",
      });
    }
  }, [currentCompanyGroupId, defaultPanorama]);

  return {
    dates,
    loading,
    isEndDateValid,
    panoramaOptions,
    isStartDateValid,
    selectedPanorama,
    isPanoramaModalOpen,
    handleSavePanoramaButtonOnClick,
    handlePanoramaDropdownOnChange,
    setIsPanoramaModalOpen,
    renderPanoramaOption,
    handleOnPanoramaSave,
    setPanoramaOptions,
    handleSubmit,
    setDates,
  };
}
