import { InputMask } from "primereact/inputmask";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useAccountReceivableTerminateModal } from "../../hooks/useAccountReceivableTerminateModal";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";

export function AccountsReceivableTerminateModal() {
  const {
    terminateModalOpen,
    handleCloseButtonOnClick,
    state,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    isInvalid,
  } = useAccountReceivableTerminateModal();

  return (
    <Modal
      isOpen={terminateModalOpen}
      onRequestClose={handleCloseButtonOnClick}
      shouldCloseOnEsc={!state.submitting}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Baixar lançamento</h4>
          {!state.submitting ? (
            <button
              type="button"
              id="btn-cross"
              data-testid="btn-cross"
              className="react-modal-close"
              onClick={handleCloseButtonOnClick}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>

        <form
          className="form-container"
          id="terminate-modal__form"
          onSubmit={handleSubmit}
        >
          <div className="col-12 react-modal-body">
            <div className="form-row">
              <label className="col-6 form-control">
                <span>Data da baixa</span>
                <InputMask
                  value={state.terminationDate}
                  onChange={handleDateChange}
                  unmask
                  mask="99/99/9999"
                  disabled={state.submitting}
                  id="txt-termination-date"
                  data-testid="txt-termination-date"
                  placeholder="Data da baixa"
                  className={state.isInvalid ? "isInvalid" : undefined}
                />
                <InvalidFeedback
                  condition={state.isInvalid}
                  message={state.invalidFeedback}
                />
              </label>
            </div>

            <div className="form-row">
              <div
                id="receivable__terminate-modal__disclaimer"
                className="col-12 form-control"
              >
                Apenas parcelas com status <b>aberto</b> serão consideradas
              </div>
            </div>
          </div>

          <div className="col-12 react-modal-footer">
            <button
              type="button"
              id="btn-close"
              data-testid="btn-close"
              className="form-button red-bkg"
              disabled={state.submitting}
              onClick={handleCloseButtonOnClick}
            >
              Fechar
            </button>
            <button
              type="submit"
              id="terminate-modal__form__btn-submit"
              form="terminate-modal__form"
              data-testid="btn-submit"
              className={`form-button ${
                isInvalid ? "invalid-bkg" : "green-bkg"
              }`}
              disabled={state.submitting}
            >
              Baixar{" "}
              {state.submitting && <i className="pi pi-spin pi-spinner" />}
            </button>
          </div>

          <RequestProgressModal
            loaded={state.progressModal.loaded}
            total={state.progressModal.total}
            isOpen={state.progressModal.isOpen}
          />
        </form>
      </Container>
    </Modal>
  );
}
