import { IEntity } from "../../../../core/domain/entities/entity";

export interface ICostCenterEntity extends IEntity {
  acronym: string;
}

export class CostCenterEntity implements ICostCenterEntity {
  id = "";
  acronym = "";
  active = true;

  constructor(params?: Partial<ICostCenterEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterEntity>) {
    return new CostCenterEntity(params);
  }
}
