import { IAttachmentReportEntity } from "../entities/attachmentEntity";
import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IGetAttachmentsService {
  getAttachments(
    costCenterReportEntity: ICostCenterReportEntity,
  ): Promise<IAttachmentReportEntity[]>;
}

export interface IGetAttachmentsUseCase {
  getAttachments(
    costCenterReportEntity: ICostCenterReportEntity,
  ): Promise<IAttachmentReportEntity[]>;
}

export class GetAttachmentsUseCase implements IGetAttachmentsUseCase {
  constructor(private getAttachmentsService: IGetAttachmentsService) {}

  getAttachments(
    costCenterReportEntity: ICostCenterReportEntity,
  ): Promise<IAttachmentReportEntity[]> {
    return this.getAttachmentsService.getAttachments(costCenterReportEntity);
  }
}
