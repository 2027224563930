import { useCallback } from "react";
import { EUserProfile } from "../../domain/entities/userEntity";
import { useUserLocal } from "./useUserLocal";

export function useAllowedProfiles() {
  const { user } = useUserLocal();

  return useCallback(
    (...allowedProfiles: EUserProfile[]) => {
      const currentProfile = user.profile;
      return allowedProfiles.some(
        p => currentProfile === p || currentProfile === EUserProfile.master,
      );
    },
    [user.profile],
  );
}
