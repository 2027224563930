import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchPaymentRequestDestinationsContract } from "../../domain/contracts/fetchPaymentRequestDestinationsContract";
import { EPaymentRequestDestination } from "../../domain/entities/paymentRequestEnums";

export class FetchPaymentRequestsDestinations
  implements IFetchPaymentRequestDestinationsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  fetchPaymentRequestsDestinations() {
    const userEntity = this.getUserLocalService.get();
    const url = "/Users/UserPaymentRequestDestination";

    return this.api.get<EPaymentRequestDestination[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
