import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPecegePaySalesReportContract } from "../../domain/contracts/getPecegePaySalesReportContract";
import { IPecegePaySalesReportFormEntity } from "../../domain/entities/pecegePaySalesReportFormEntity";

export class GetPecegePaySalesReportService
  implements IGetPecegePaySalesReportContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getPecegePaySalesReport(payload: IPecegePaySalesReportFormEntity) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Downloads/PecegePaySalesReport/SpreadSheets`;

    const formatDate = (date: string) => {
      return format(parse(date, "ddMMyyyy", new Date()), "yyyy-MM-dd");
    };

    const params = {
      FinalFilterDate: formatDate(payload.finalDate),
      ConsumerSystemNames: payload.productTypes.toString(),
      InitialFilterDate: formatDate(payload.initialDate),
    };

    const response = await this.api.download?.(url, {
      params,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  private downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers["content-disposition"] ||
      `attachment; filename=Download; filename*=UTF-8''Download`;

    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();
    fileName = fileName.replace(/"/g, "");

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
