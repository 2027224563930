import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 26.75rem;

  .react-modal-body {
    padding-top: 0 !important;

    .truncate {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .form-row + .grid-row {
      margin-top: 1.5rem;
    }

    .pay-until-mt {
      margin-top: 2.1rem;
    }

    .pay-until-warning {
      color: #a77267;
      background-color: #f1e0b2;

      padding: 0.5rem;

      text-align: center;
      font-size: 0.8rem;
      margin-top: 1rem;

      border-radius: 0.25rem;
      border: 1px solid #cad1d7;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }

  * + .disclaimer {
    margin-top: 1rem;
    color: var(--color-danger);
  }
`;
