import { InputMask } from "primereact/inputmask";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { useBulkTerminateModal } from "../../hooks/useBulkTerminateModal";
import { Container } from "./styles";
import { Checkbox } from "../../../../../../core/presentation/components/Checkbox";

export function BulkTerminateModal() {
  const {
    handleCloseButtonOnClick,
    state,
    handleModalAfterClose,
    handleSubmit,
    handleDateChange,
    isInvalid,
    bulkTerminateModalOpen,
    handlePayUntilChange,
    isAnyAccountAlreadyTerminated,
  } = useBulkTerminateModal();

  return (
    <Modal
      isOpen={bulkTerminateModalOpen}
      onRequestClose={handleCloseButtonOnClick}
      onAfterClose={handleModalAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Baixar lançamentos</h4>

          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={handleCloseButtonOnClick}
          >
            <IoMdClose />
          </button>
        </div>

        <form className="form-container" onSubmit={handleSubmit}>
          <div className="col-12 react-modal-body">
            <div className="form-row">
              <label className="col-6 form-control">
                <span>Data da baixa</span>
                <InputMask
                  value={state.terminationDate}
                  onChange={handleDateChange}
                  unmask
                  mask="99/99/9999"
                  disabled={state.submitting || state.usePayUntil}
                  id="txt-termination-date"
                  data-testid="txt-termination-date"
                  placeholder="Data da baixa"
                  className={state.isInvalid ? "isInvalid" : undefined}
                />
                <InvalidFeedback
                  condition={state.isInvalid}
                  message={state.invalidFeedback}
                />
              </label>
              <label className="col-6 form-control pay-until-mt">
                <Checkbox
                  label="Data pagar em"
                  id="chk-usePayUntil"
                  data-testid="chk-usePayUntil"
                  onChange={handlePayUntilChange}
                />
              </label>
            </div>
            {state.usePayUntil ? (
              <div className="pay-until-warning">
                A data de baixa dos lançamentos serão realizadas conforme a data
                &quot;Pagar em&quot;
              </div>
            ) : null}

            {isAnyAccountAlreadyTerminated && (
              <div className="disclaimer">
                Apenas parcelas com status <b>aberto</b> serão consideradas
              </div>
            )}
          </div>

          <div className="col-12 react-modal-footer">
            <button
              type="button"
              id="btn-close"
              data-testid="btn-close"
              className="form-button red-bkg"
              disabled={state.submitting}
              onClick={handleCloseButtonOnClick}
            >
              Fechar
            </button>
            <button
              type="submit"
              id="btn-submit"
              data-testid="btn-submit"
              className={`form-button ${
                isInvalid ? "invalid-bkg" : "green-bkg"
              }`.trim()}
              disabled={state.submitting}
            >
              Tudo certo{" "}
              {state.submitting && <i className="pi pi-spin pi-spinner" />}
            </button>
          </div>
        </form>
      </Container>
    </Modal>
  );
}
