import { useMemo } from "react";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";

export function useCompanyGroupGrid() {
  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        selectionMode: "multiple",
      },
      {
        field: "name",
        header: "Nome",
        orderable: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, []);

  return { columns };
}
