import { Controller } from "react-hook-form";
import { InputBalance } from "../../../../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function ValueField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.value` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true, min: 0.01 }}
      render={({ field, fieldState }) => {
        const handleValueChange = (eventValue: number | null) => {
          field.onBlur();
          field.onChange(eventValue);
        };
        return (
          <label className="form-control">
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputBalance
                {...field}
                placeholder="0,00"
                className="numeric-field"
                onChange={handleValueChange}
                id={`txt-value-${formIndex}`}
                data-testid={`txt-value-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="O valor deve ser superior a zero"
              condition={fieldState?.error?.type === "min"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
