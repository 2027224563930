import { IEntity } from "../../../../core/domain/entities/entity";

export interface ICompanyEntity extends IEntity {
  assumedName: string;
}

export class CompanyEntity implements ICompanyEntity {
  assumedName = "";
  id = "";
  active = true;

  constructor(params?: Partial<ICompanyEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICompanyEntity>) {
    return new CompanyEntity(params);
  }
}
