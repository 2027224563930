import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPaymentAccountContract } from "../../domain/contracts/getPaymentAccountContract";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";

export class GetPaymentAccountService implements IGetPaymentAccountContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPaymentAccount(paymentAccountId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentAccounts/${paymentAccountId}`;

    return this.api.get<IPaymentAccountEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
