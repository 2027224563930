import { PropsWithChildren } from "react";
import styled from "styled-components";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";

interface IFieldWrapperProps {
  className?: string;
  isLoading?: boolean;
}

const Container = styled.div<IFieldWrapperProps>`
  position: relative;

  width: 100%;

  color: #495057;
  font-size: 0.875rem;

  border: 1px solid #cad1d7;
  border-radius: 0.25rem;

  background-color: #fff;

  > svg {
    top: 25%;
    right: 0.35rem;
    position: absolute;
    display: ${props => (props.isLoading ? "none" : "")};
  }

  div,
  input,
  select {
    border: none;
    border-radius: inherit;

    width: 100%;
    color: #495057;
    font-size: 0.875rem;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;

    ::placeholder {
      color: #d1d1d1;
    }

    &.no-right-radius {
      padding-right: 1.5rem;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }

    &.disabled-field {
      color: #495057;
      cursor: not-allowed;
      background-color: #e9ecef;
    }

    :focus,
    :focus-within,
    :focus-visible {
      border: none;
      outline: unset;
      box-shadow: unset;
    }
  }

  &:has(select) {
    svg {
      right: 1.25rem;
    }
  }

  &:has(div) {
    svg {
      right: 1.75rem;
    }

    div {
      padding: unset;
    }
  }

  &:has(.numeric-field) {
    svg {
      right: 0.5rem;
    }
  }

  input {
    padding-right: 1.5rem;
  }

  &.isValid {
    border-color: var(--soul-green-button);

    &:focus-within {
      box-shadow: "0 0 0 0.2rem rgba(40, 167, 69, 0.25)";
    }

    svg {
      color: var(--soul-green-button);
    }

    select {
      padding-right: 2.5rem;
    }

    div > input {
      padding-right: 3rem;
    }

    &:has(.numeric-field) div > input {
      padding-right: 1.25rem !important;
    }
  }

  &.isInvalid {
    border-color: var(--invalid);
    &:focus-within {
      box-shadow: "0 0 0 0.2rem rgb(220, 53, 69, 0.25)";
    }

    svg {
      color: var(--invalid);
    }

    select {
      padding-right: 2.5rem;
    }

    div > input {
      padding-right: 3rem;
    }

    &:has(.numeric-field) div > input {
      padding-right: 1.25rem !important;
    }
  }

  &.isEdited {
    border-color: #ff9c00;
    &:focus-within {
      box-shadow: "0 0 0 0.2rem rgba(255, 156, 0, 0.25) !important";
    }

    select {
      padding-right: unset;
    }

    div > input {
      padding-right: 1.7rem;
    }

    &:has(.numeric-field) div > input {
      padding-right: unset;
    }
  }

  &.document-number-container {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;

    input {
      padding-right: ${props => (props.isLoading ? "0.5rem" : "1.1rem")};
    }
  }

  &:has(.no-right-radius) {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
`;

export function FieldWrapper(props: PropsWithChildren<IFieldWrapperProps>) {
  const { children, className, isLoading } = props;

  return (
    <Container className={className} isLoading={isLoading}>
      {children}
      {className?.includes("isValid") ? <FaCheck /> : null}
      {className?.includes("isInvalid") ? <FaExclamationCircle /> : null}
    </Container>
  );
}
