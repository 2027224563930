import { useCallback, useMemo } from "react";
import { FaEdit, FaLink } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  ClassificationAccountEntity,
  IClassificationAccountEntity,
} from "../../domain/entities/classificationAccountEntity";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";

export interface UseClassificationAccountsGridProps {
  openClassificationAccountModal(info: IClassificationAccountEntity): void;
  toggle(classificationAccountId: string, actionText: string): Promise<void>;
}

export function useClassificationAccountsGrid({
  toggle,
  openClassificationAccountModal,
}: UseClassificationAccountsGridProps) {
  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();

  /**
   * Indica se o usuário atual pode realizar o "soft delete", ou seja, inativar
   * um projeto.
   */
  const canDeleteClassificationAccount = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  /**
   * Constrói a célula de edição de uma classificação contábil.
   */
  const editBodyTemplate = useCallback(
    (classificationAccount: IClassificationAccountEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            type="button"
            id={`btn-edit-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-edit-${rowIndex}`}
            data-tip="Editar Classificação Contábil"
            onClick={() => {
              openClassificationAccountModal(classificationAccount);
            }}
          >
            <FaEdit />
          </button>
          <button
            type="button"
            id={`btn-copy-id-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-copy-id-${rowIndex}`}
            data-tip="Copiar ID da Classificação Contábil"
            onClick={() => {
              navigator.clipboard.writeText(classificationAccount.id);
              toast.success(
                "O ID da Classificação Contábil foi copiado para sua área de transferência",
                {
                  autoClose: 2000,
                },
              );
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [openClassificationAccountModal],
  );

  /**
   * Constrói a célula de ativar/desativar uma classificação contábil.
   */
  const activeBodyTemplate = useCallback(
    ({ id, active }: IClassificationAccountEntity, { rowIndex }) => {
      return (
        <Toggle
          value={active}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          readonly={!canDeleteClassificationAccount}
          data-testid={`btn-tgl-status-${rowIndex}`}
          onChange={async _active => {
            const actionText = !_active ? "inativada" : "ativada";
            const result = await dialog.fire({
              icon: "question",
              title: "Você está certo disso?",
              html: `A Classificação Contábil será ${actionText}. <br /> Deseja prosseguir?`,
              showCancelButton: true,
              cancelButtonText: "Não",
              async preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                await toggle(id, actionText);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [canDeleteClassificationAccount, dialog, toggle],
  );

  const columns = useMemo<SimpleTableColumn[]>(
    () =>
      [
        {
          field: "companyGroupName",
          header: "Grupo de Empresa",
          searchable: false,
          orderable: false,
        },
        { field: "name", header: "Nome", searchable: true, orderable: true },
        {
          header: "Tipo",
          field: "type",
          searchable: true,
          orderable: true,
          bodyTemplate: (item: ClassificationAccountEntity) =>
            item.typeDescription,
        },
        {
          header: "Lançamento",
          field: "issueType",
          searchable: true,
          orderable: true,
          bodyTemplate: (item: ClassificationAccountEntity) =>
            item.issueTypeDescription,
        },

        { field: "code", header: "Código", searchable: true, orderable: true },
        { header: "", bodyTemplate: editBodyTemplate },
        {
          field: "active",
          header: "Ativo",
          searchable: false,
          orderable: false,
          bodyTemplate: activeBodyTemplate,
        },
        {
          hidden: true,
          field: "typeDescription",
          searchable: false,
          orderable: false,
        },
        {
          hidden: true,
          field: "issueTypeDescription",
          searchable: false,
          orderable: false,
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [editBodyTemplate, activeBodyTemplate],
  );

  return { columns };
}
