interface IErrorMessage {
  message: string;
}

export enum EUserProfile {
  master = 0,
  financial = 1,
  supervisor = 2,
  requester = 3,
  financialAccounting = 4,
  financialManagement = 5,
  manager = 6,
  internal = 7,
}

export const userProfileDictionary: Record<EUserProfile, string> = {
  [EUserProfile.master]: "Master",
  [EUserProfile.financial]: "Financeiro",
  [EUserProfile.requester]: "Solicitante",
  [EUserProfile.financialAccounting]: "Financeiro Contabilidade",
  [EUserProfile.financialManagement]: "Financeiro Gerencial",
  [EUserProfile.manager]: "Gestor",
  [EUserProfile.supervisor]: "Supervisor",
  [EUserProfile.internal]: "Interno",
};

export interface IUserEntity {
  userId: string;
  name: string;
  email: string;
  active: boolean;
  error?: IErrorMessage;
  profile: EUserProfile;
  passwordReset?: boolean;
  success?: boolean;
  token: string;
}
