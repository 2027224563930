import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../../../../core/domain/entities/userEntity";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import { useDocumentFormatter } from "../../../../../../../core/presentation/hooks/useDocumentFormatter";
import { useUserLocal } from "../../../../../../../core/presentation/hooks/useUserLocal";
import { ClassificationAccountLinkFormModal } from "../../../../../../../provider/presentation/components/ClassificationAccountLinkFormModal";
import { ProviderFormModal } from "../../../../../../../provider/presentation/components/ProviderFormModal";
import { IProviderEntity } from "../../../../../form/domain/entities/providerEntity";
import { EProviderDocumentType } from "../../../../domain/entities/debtImportEnums";
import {
  IDebtImportForm,
  IFormComponentProps,
  ITypeaheadState,
} from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";

interface IProviderFieldState extends ITypeaheadState {
  providerModalOpen: boolean;
  classificationModalOpen: boolean;
  newlyCreatedProvider?: IProviderEntity;
}

interface IProviderFieldProps extends IFormComponentProps {
  accountAlreadyPaid: boolean;
  accountAlreadyExists: boolean;
  accountAlreadyExistsForAnotherUser: boolean;
}

export function ProviderField(props: IProviderFieldProps) {
  const {
    formIndex,
    accountAlreadyPaid,
    accountAlreadyExists,
    accountAlreadyExistsForAnotherUser,
  } = props;

  const {
    user: { profile },
  } = useUserLocal();

  const formPrefix = `imports.${formIndex}` as const;

  const [state, setState] = useState<IProviderFieldState>({
    loading: false,
    providerModalOpen: false,
    classificationModalOpen: false,
  });

  const asyncDebounce = useDebounceTimeAsync();
  const documentFormatter = useDocumentFormatter();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const {
    useProvider,
    useDebtImport,
    handleInputClassName,
    validateDocumentNumber,
  } = useDebtImportPage();

  const { listLinkedClassificationsAccount } = useProvider;
  const { searchProviders, getProviderById, providerHasClassificationAccount } =
    useDebtImport;

  const { setValue, getValues, trigger } = useFormContext<IDebtImportForm>();

  const openProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: true,
    }));
  };

  const closeProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: false,
    }));
  };

  const handleProviderModalRequestClose = () => {
    closeProviderModal();
  };

  const openClassificationAccountLinkModal = (
    newlyCreatedProvider: IProviderEntity,
  ) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: true,
      newlyCreatedProvider,
    }));
  };

  const handleCloseClassificationModal = (hasLinked = false) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: false,
      newlyCreatedProvider: undefined,
    }));

    if (!hasLinked) {
      setValue(`${formPrefix}.provider`, null, {
        shouldValidate: true,
      });
      setValue(`${formPrefix}.classificationAccount`, null, {
        shouldValidate: true,
      });
      setValue(`${formPrefix}.correspondingProviderName`, "", {
        shouldValidate: true,
      });
    }
  };

  const handleNotFoundOptionSelected = () => {
    openProviderModal();
  };

  const handleSearchProviderChange = async (search = "") => {
    await asyncDebounce(750);
    const companyGroupId = currentCompanyGroup.id;

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await searchProviders(search, companyGroupId, true);

      setState(prevState => ({
        ...prevState,
        options: response,
        loading: false,
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleProviderChange = async (event: ISoulTypeaheadChangeEvent) => {
    const companyGroupId = currentCompanyGroup.id;

    const selectedProvider = event?.target?.value;

    setValue(`${formPrefix}.classificationAccount`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (!selectedProvider) {
      setValue(`${formPrefix}.correspondingProviderName`, "", {
        shouldValidate: true,
      });
      return;
    }

    const documentNumberValue = getValues(`${formPrefix}.documentNumber`);
    const selectedProviderDocumentType = Number(
      (selectedProvider.metadata as IProviderEntity)?.documentType,
    );

    if (selectedProviderDocumentType !== EProviderDocumentType.CNPJ) {
      setValue(`${formPrefix}.correspondingProviderName`, "", {
        shouldValidate: true,
      });
    }

    if (documentNumberValue) {
      await trigger(`${formPrefix}.documentNumber`);
      await validateDocumentNumber(documentNumberValue, formIndex);
    }

    const selectedProviderId = selectedProvider.rawValue as string;

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const providerHasClsAccount = await providerHasClassificationAccount(
        companyGroupId,
        selectedProviderId,
      );

      if (providerHasClsAccount) {
        return;
      }

      const providerEntity = await getProviderById(selectedProviderId);

      openClassificationAccountLinkModal(providerEntity);
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleProviderCreated = async (newProvider: IProviderEntity) => {
    const companyGroupId = currentCompanyGroup.id;

    const { id, documentType, document, name } = newProvider;

    const isOtherDocType = Number(documentType) === EProviderDocumentType.Other;
    const isCnpjType = Number(documentType) === EProviderDocumentType.CNPJ;

    const docValue = isOtherDocType ? document : documentFormatter(document);

    const label = `${name} ${document ? `- ${docValue}` : ""}`.trim();

    setValue(
      `${formPrefix}.provider`,
      {
        label,
        rawValue: id,
        metadata: newProvider,
      },
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      },
    );

    if (!isCnpjType) {
      setValue(`${formPrefix}.correspondingProviderName`, "", {
        shouldValidate: true,
      });
    }

    const clsAccountList = await listLinkedClassificationsAccount(
      companyGroupId,
      newProvider.id,
    );

    if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
      return;
    }

    openClassificationAccountLinkModal(newProvider);
  };

  let shouldBeDisabled = accountAlreadyExists && !accountAlreadyPaid;

  if (profile === EUserProfile.internal) {
    shouldBeDisabled =
      accountAlreadyExists &&
      !accountAlreadyPaid &&
      !accountAlreadyExistsForAnotherUser;
  }

  if (shouldBeDisabled) {
    const provider = getValues(`${formPrefix}.provider`);
    const value = provider?.label || "";
    const disabledTip = `Essa conta já existe e será alterada.<br />Portanto, não é possível<br />trocar o valor deste campo.`;

    return (
      <FieldWrapper>
        <input
          readOnly
          value={value}
          data-effect="solid"
          data-tip={disabledTip}
          className="disabled-field"
          id={`txt-provider-${formIndex}`}
          data-testid={`txt-provider-${formIndex}`}
        />
      </FieldWrapper>
    );
  }

  return (
    <Controller
      name={`${formPrefix}.provider`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ISoulTypeaheadChangeEvent) => {
          field.onBlur();
          field.onChange(event);
          handleProviderChange(event);
        };

        return (
          <label className="form-control">
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                onChange={onChange}
                value={field.value}
                loading={state.loading}
                options={state.options}
                placeholder="Fornecedor"
                id={`txt-provider-${formIndex}`}
                data-testid={`txt-provider-${formIndex}`}
                onSearchChange={handleSearchProviderChange}
                notFoundOptionLabel="&#43; Adicionar Fornecedor"
                onNotFoundOptionSelected={handleNotFoundOptionSelected}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
            <ProviderFormModal
              currentId=""
              useProvider={useProvider}
              isOpen={state.providerModalOpen}
              onProviderCreated={handleProviderCreated}
              onRequestClose={handleProviderModalRequestClose}
            />
            <ClassificationAccountLinkFormModal
              required
              useProvider={useProvider}
              isOpen={state.classificationModalOpen}
              providerEntity={state.newlyCreatedProvider}
              onRequestClose={handleCloseClassificationModal}
            />
          </label>
        );
      }}
    />
  );
}
