import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AssessmentGrid } from "../../../../../../core/presentation/components/AssessmentGrid";
import { IAccountPayableAssessmentEntity } from "../../../domain/entities/accountPayableAssessmentEntity";
import {
  EAccountPayableStatus,
  EReturnStatus,
  IAccountPayableFormEntity,
} from "../../../domain/entities/accountPayableFormEntity";
import { MakeAccountsPayableForm } from "../../../main/makeAccountPayableForm";
import { EFormMode } from "../AccountsPayableFormPage";

interface ISectionAssessmentProps {
  mode: EFormMode;
  readonly: boolean;
  useAccountsPayableForm: MakeAccountsPayableForm;
}

export function SectionAssessment(props: ISectionAssessmentProps) {
  const { readonly, useAccountsPayableForm, mode } = props;

  const {
    searchCostCenter,
    listActiveCostCenters,
    searchClassificationAssessment,
  } = useAccountsPayableForm;

  const form = useFormContext<IAccountPayableFormEntity>();

  const { watch, register, getValues, setValue } = form;

  const value = watch("value");
  const assessments = watch("assessments");

  const shouldShowCalculateAssessmentButton = useMemo(() => {
    const isTax = getValues("isTax");
    const status = getValues("status");
    const returnStatus = getValues("returnStatus");

    if (status === EAccountPayableStatus.Open && assessments.length > 0) {
      const sum = assessments.reduce((acc, cur) => {
        return acc.plus(cur.value);
      }, new BigNumber(0));

      return !sum.isEqualTo(value);
    }

    if (mode === EFormMode.Edit) {
      if (isTax) {
        return false;
      }

      if (returnStatus !== EReturnStatus.NotReturned) {
        return false;
      }
    }

    return false;
  }, [getValues, assessments, mode, value]);

  const validateAssessments = () => {
    if (readonly) {
      return true;
    }

    const totalAssessmentValue = assessments.reduce((accumulated, current) => {
      return accumulated.plus(current.value);
    }, new BigNumber(0));

    const remainingValue = new BigNumber(value).minus(totalAssessmentValue);

    const isRemainingZero = remainingValue.isZero();

    if (
      !isRemainingZero &&
      remainingValue.isLessThan(0.01) &&
      remainingValue.isGreaterThan(-0.01)
    ) {
      return true;
    }

    return isRemainingZero;
  };

  register("assessments", {
    validate: {
      assessmentValue: validateAssessments,
    },
  });

  const handleAssessmentsChange = (
    newAssessments: IAccountPayableAssessmentEntity[],
  ) => {
    setValue("assessments", newAssessments);
  };

  return (
    <AssessmentGrid
      readonly={readonly}
      accountValue={value}
      assessments={assessments}
      searchCostCenter={searchCostCenter}
      listActiveCostCenters={listActiveCostCenters}
      onAssessmentsChange={handleAssessmentsChange}
      searchClassificationAssessment={searchClassificationAssessment}
      shouldShowCalculateAssessmentButton={shouldShowCalculateAssessmentButton}
      observationMaxLength={500}
    />
  );
}
