import { FaChevronRight } from "react-icons/fa";
import { useWatch } from "react-hook-form";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";
import { Container } from "./styles";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { ESolicitImportSteps } from "../../../domain/entities/solicitImportEnums";

export function SolicitImportPageHeader() {
  const {
    form: { control },
    state: { currentStep },
  } = useSolicitImportPage();

  const formValues = useWatch<ISolicitImportForm, "imports">({
    control,
    name: "imports",
  });

  const stepStates = {
    isFileStep: currentStep === ESolicitImportSteps.FILE,
    isValidationStep: currentStep === ESolicitImportSteps.VALIDATION,
    isFinalStep: currentStep === ESolicitImportSteps.FINAL,
  };

  return (
    <Container>
      <div>
        <span className={stepStates.isFileStep ? "active-step" : ""}>
          1. Importação
        </span>
        <FaChevronRight />
        <span className={stepStates.isValidationStep ? "active-step" : ""}>
          2. Informações das Solicitações de Pagamento
        </span>
        <FaChevronRight />
        <span className={stepStates.isFinalStep ? "active-step" : ""}>
          3. Informações dos Rateios
        </span>
      </div>
      {stepStates.isFileStep ? null : (
        <span>Total de Lançamentos: {formValues.length}</span>
      )}
    </Container>
  );
}
