import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetFeeContract } from "../../domain/contracts/getFeeContract";
import { FeeEntity, IFeeEntity } from "../../domain/entities/feeEntity";

export class GetFeeService implements IGetFeeContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getFee(feeId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Fee/${feeId}`;

    const response = await this.api.get<IFeeEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const feeEntity = new FeeEntity(response);

    return feeEntity;
  }
}
