import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListClassificationAccountsContract } from "../../domain/contracts/listClassificationAccountsContract";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";

export class ListClassificationAccountsService
  implements IListClassificationAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listClassificationAccounts(
    payload: IPayloadEntity,
    companyGroupId: string,
    actives = false,
  ) {
    const userEntity = this.getUserLocalService.get();
    const companyGroupUrl = `CompanyGroups/${companyGroupId}`;
    const endpoint = `${companyGroupUrl}/ClassificationAccounts${
      actives ? "/Actives" : ""
    }`;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `${endpoint}?${params}`;

    return this.api.get<IResponseEntity<IClassificationAccountEntity[]>>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
