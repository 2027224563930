import { ICompanyGroupEntity } from "../entities/companyGroupEntity";

export interface IToggleCompanyGroupService {
  toggleCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity>;
}

export interface IToggleCompanyGroupUseCase {
  toggleCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity>;
}

export class ToggleCompanyGroupUseCase implements IToggleCompanyGroupUseCase {
  constructor(private toggleCompanyGroupService: IToggleCompanyGroupService) {}

  toggleCompanyGroup(CompanyGroupId: string): Promise<ICompanyGroupEntity> {
    return this.toggleCompanyGroupService.toggleCompanyGroup(CompanyGroupId);
  }
}
