import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { FaPlus } from "react-icons/fa";
import { ClientSoulTable } from "../../../../core/presentation/components/ClientSoulTable";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { ICompanyTaxFormEntity } from "../../../domain/entities/companyTaxFormEntity";
import { MakeCompany } from "../../../main/makeCompany";
import { useCompanyTaxesGrid } from "../../hooks/useCompanyTaxesGrid";
import { Container, UspCheckContainer } from "./style";
import { CompanyTaxFormModal } from "../CompanyTaxFormModal";
import { OnCloseModalAction } from "../CompanyPage";

type TaxModalState = {
  isOpen: boolean;
  taxIndex: number;
  mode: "NEW" | "EDIT";
};

interface CompanyTaxesGridProps {
  companyId: string;
  useCompany: MakeCompany;
  useProvider: MakeProvider;
  formData: ICompanyTaxFormEntity[];
  modalClose: (action?: OnCloseModalAction) => void;
  submitState: [boolean, Dispatch<SetStateAction<boolean>>];
  editedState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export function CompanyTaxesGrid(props: CompanyTaxesGridProps) {
  const {
    formData,
    companyId,
    modalClose,
    useCompany,
    submitState,
    editedState,
    useProvider,
  } = props;

  const { saveCompanyTaxes } = useCompany;

  const dialog = useSoulDialog();

  const [tableData, setTableData] = useState([...formData]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [taxModalState, setTaxModalState] = useState<TaxModalState>();

  const [isEdited, setIsEdited] = editedState;
  const [, setIsSubmitting] = submitState;
  const taxesNames = tableData.map(f => f.name);

  /**
   * Realiza a remoção de uma taxa do formulário.
   */
  const deleteTax = useCallback(
    async (taxIndex: number, hasId: boolean) => {
      const result = await dialog.fire({
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        title: "Você está certo disso?",
        text: hasId
          ? "Deseja realmente excluir a taxa? A partir desse momento, as baixas em contas a receber passarão a não considerá-la."
          : "Deseja realmente excluir a taxa?",
      });

      if (result.dismiss) return;

      setTableData(old => {
        const newData = old.filter((_, index) => index !== taxIndex);
        return newData;
      });

      setIsEdited(true);

      dialog.fire({
        icon: "success",
        title: "Feito!",
        html: 'Taxa excluída.<br />Pressione o botão "Salvar" para efetivar as mudanças.',
      });
    },
    [dialog, setIsEdited],
  );

  const openTaxEditModal = useCallback((taxIndex: number) => {
    setTaxModalState({ isOpen: true, mode: "EDIT", taxIndex });
  }, []);

  const { columns } = useCompanyTaxesGrid({
    deleteTax,
    openTaxEditModal,
  });

  /**
   * Envia a lista de taxas para o servidor.
   */
  const submitTaxes = useCallback(
    async (formValues: ICompanyTaxFormEntity[]) => {
      setIsSubmitting(true);

      try {
        await saveCompanyTaxes(companyId, formValues);
      } catch (err) {
        return;
      } finally {
        setIsSubmitting(false);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Operação realizada com sucesso!",
      });

      setIsEdited(false);
      modalClose(OnCloseModalAction.Refresh);
    },
    [
      dialog,
      companyId,
      modalClose,
      setIsEdited,
      setIsSubmitting,
      saveCompanyTaxes,
    ],
  );

  /**
   * Realiza verificações com o usuário antes de submeter o formulário.
   */
  const submissionCheck = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isEdited) {
        const firstCheck = await dialog.fire({
          icon: "question",
          title: "Atenção!",
          showCancelButton: true,
          cancelButtonText: "Não",
          text: `Deseja realmente salvar os registros? As informações serão
            atualizadas para as taxas de todos os projetos que não possuem
            taxas cadastradas especificamente.`,
        });

        if (firstCheck.dismiss) return;

        const uspCheck = await dialog.fire({
          icon: "warning",
          title: "Atenção!",
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
          html: (
            <UspCheckContainer>
              <p>
                Classificações USP que tiverem relação com o cadastro de alguma
                taxa <strong>serão vinculadas</strong> a todos os Projetos.
              </p>
              <br />
              <p>
                Classificações USP que antes tinham relação com alguma taxa, ao
                deixarem de ter, <strong>serão removidas</strong> de todos os
                Projetos que tiveram o vínculo feito pelo cadastro de taxas.
              </p>
              <br />
              <p className="teste">Deseja continuar?</p>
            </UspCheckContainer>
          ),
        });

        if (uspCheck.dismiss) return;
      }

      /** Caso o usuário confirme todas os alertas, segue com a submissão do formulário */
      submitTaxes(tableData);
    },
    [isEdited, submitTaxes, tableData, dialog],
  );

  /**
   * Lida com o fechamento do modal de criação de taxa. Caso novos valores
   * sejam passados, adiciona a nova taxa na lista.
   */
  const handleTaxModalClose = useCallback(
    (newTax?: ICompanyTaxFormEntity) => {
      if (!taxModalState) return;
      const { mode, taxIndex } = taxModalState;

      setTableData(d => {
        const oldData = [...d];
        if (mode === "NEW" && newTax) {
          oldData.unshift(newTax);
        } else if (mode === "EDIT" && newTax) {
          oldData[taxIndex] = newTax;
        }
        return oldData;
      });

      setIsEdited(edited => {
        if (edited) return true;
        return !!newTax;
      });

      setTaxModalState(undefined);
    },
    [setIsEdited, taxModalState],
  );

  return (
    <Container>
      <div className="table-header">
        <InputSearch
          value={globalFilter}
          id="txt-taxes-search"
          data-testid="txt-taxes-search"
          onChange={e => {
            setGlobalFilter(e.target.value);
          }}
        />
        <button
          type="button"
          id="btn-taxes-add"
          className="default-button"
          data-testid="btn-taxes-add"
          onClick={() => {
            setTaxModalState({ isOpen: true, mode: "NEW", taxIndex: 0 });
          }}
        >
          <FaPlus /> Adicionar taxa
        </button>
      </div>
      <form id="company-taxes-form" onSubmit={submissionCheck} />
      <ClientSoulTable
        rowHover
        data={tableData}
        columns={columns}
        globalFilter={globalFilter}
        rowsPerPageOptions={[5, 10, 25, 50]}
        emptyMessage="Nenhuma taxa cadastrada para esta empresa"
      />
      {taxModalState ? (
        <CompanyTaxFormModal
          companyId={companyId}
          taxesNames={taxesNames}
          useCompany={useCompany}
          useProvider={useProvider}
          isOpen={taxModalState.isOpen}
          formMode={taxModalState.mode}
          onClose={handleTaxModalClose}
          currentTax={{ ...tableData[taxModalState.taxIndex] }}
        />
      ) : null}
    </Container>
  );
}
