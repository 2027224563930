import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";

export interface ISearchCostCentersService {
  searchCostCenters(
    companyGroupId: string,
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export interface ISearchCostCentersUseCase {
  searchCostCenters(
    companyGroupId: string,
    search?: string,
    fetchMinLength?: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export class SearchCostCentersUseCase implements ISearchCostCentersUseCase {
  constructor(private searchCostCentersService: ISearchCostCentersService) {}

  searchCostCenters(
    companyGroupId: string,
    search = "",
    fetchMinLength = 10,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    return this.searchCostCentersService.searchCostCenters(
      companyGroupId,
      search,
      fetchMinLength,
    );
  }
}
