export interface IEncryptUseCase {
  encrypt(data: unknown): string;
}

export interface IEncryptService {
  encrypt(data: unknown): string;
}

export class EncryptUseCase implements IEncryptUseCase {
  constructor(private encryptService: IEncryptService) {}

  encrypt(data: unknown): string {
    return this.encryptService.encrypt(data);
  }
}
