import { IApiService } from "../../../../core/data/services/apiService";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { Breadcrumb } from "../Breadcrumb";
import { makeConciliation } from "../../../main/makeConciliation";
import {
  ConciliationProvider,
  useConciliationContext,
} from "../../hooks/useConciliationContext";
import { ConciliationSteps } from "../ConciliationSteps";
import { ConciliationTermFormModal } from "../ConciliationTermFormModal";
import { Container } from "./styles";

export enum EConciliationStep {
  TermModal,
  Importation,
  Conciliation,
  Results,
}

function ConciliationPage() {
  const { state, backButtonClickHandler } = useConciliationContext();

  return (
    <Container>
      <Page>
        <header>
          <Breadcrumb />
        </header>
        <article className="no-padding">
          <ConciliationSteps />
        </article>
      </Page>
      {state.step === EConciliationStep.TermModal && (
        <ConciliationTermFormModal onRequestClose={backButtonClickHandler} />
      )}
    </Container>
  );
}

interface IConciliationPageFactoryProps {
  api: IApiService;
}

export function ConciliationPageFactory({
  api,
}: IConciliationPageFactoryProps) {
  const useConciliation = makeConciliation(api);

  return (
    <ConciliationProvider useConciliation={useConciliation}>
      <ConciliationPage />
    </ConciliationProvider>
  );
}
