import { IApiService } from "../../core/data/services/apiService";
import { IGetUserLocalService } from "../../core/domain/usecases/getUserLocalUseCase";
import { IListImportationMatchesClassesContract } from "../domain/contracts/listImportationMatchesClassesContract";
import { EImportationMatchesOrigin } from "../domain/entities/importationMatchesPayloadEntity";
import { IImportationMatchesClassEntity } from "../domain/entities/importationMatchesClassEntity";

export class ListImportationMatchesClassesService
  implements IListImportationMatchesClassesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  listImportationMatchesClasses(
    origin: EImportationMatchesOrigin,
    filter = "0",
  ) {
    const userEntity = this.getUserLocalService.get();
    const endpoint = `/ImportationMatches/${origin}/Classes`;

    const payload = {
      filter,
      skip: "0",
      take: "10000",
    };

    const params = new URLSearchParams(payload);

    const url = `${endpoint}?${params}`;

    return this.api.get<IImportationMatchesClassEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
