import styled from "styled-components";

export const Container = styled.div`
  .form-header {
    padding: 1.5rem 1.5rem 0;

    font-size: 0.875rem;
    font-weight: 600;
    color: #525f7f;
  }

  .form-container {
    padding: 1.5rem;
  }
`;
