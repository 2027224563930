import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > div > input {
    border-right: unset;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .btn-edit-provider {
    border: none;
    border-radius: 0 0.25rem 0.25rem 0;

    display: flex;
    align-items: center;

    padding: 0.47rem;

    color: white;
    background-color: #5e72e4;

    font-size: 1rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

    &:hover {
      background-color: #324cdd;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
