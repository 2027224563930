import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { SoulSpinner } from "../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IUploadPaymentAccountTransactionAttachmentsParams } from "../../../domain/contracts/uploadPaymentAccountTransactionAttachmentsContract";
import { IPaymentAccountTransactionAttachmentEntity } from "../../../domain/entities/paymentAccountTransactionAttachmentEntity";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";
import { IPaymentAccountTransactionEntity } from "../../../domain/entities/paymentAccountTransactionEntity";
import { AttachmentsGrid } from "../../../../../core/presentation/components/AttachmentsGrid";

interface IPaymentAccountTransactionAttachmentsModalProps {
  onClose(): void;
  isOpen: boolean;
  listAttachmentTypes(): Promise<IEnum[]>;
  rowData: IPaymentAccountTransactionEntity | null;
  getStorageFilebyId(id: string): Promise<string>;
  uploadPaymentAccountTransactionAttachments(
    params: IUploadPaymentAccountTransactionAttachmentsParams,
  ): Promise<unknown>;
  listPaymentAccountTransactionAttachments(
    PaymentAccountTransactionId: string,
  ): Promise<IPaymentAccountTransactionAttachmentEntity[]>;
}

interface IPaymentAccountTransactionAttachmentsModalState {
  isLoading: boolean;
  isSubmitting: boolean;
  attachments: IPaymentAccountTransactionAttachmentEntity[];
  progressModal: {
    total: number;
    loaded: number;
    isOpen: boolean;
  };
}

export function PaymentAccountTransactionAttachmentsModal(
  props: IPaymentAccountTransactionAttachmentsModalProps,
) {
  const {
    isOpen,
    onClose,
    rowData,
    getStorageFilebyId,
    listAttachmentTypes,
    listPaymentAccountTransactionAttachments,
    uploadPaymentAccountTransactionAttachments,
  } = props;

  const dialog = useSoulDialog();

  const [state, setState] =
    useState<IPaymentAccountTransactionAttachmentsModalState>({
      isLoading: false,
      attachments: [],
      isSubmitting: false,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: false,
      },
    });

  if (!rowData) {
    return null;
  }

  const handleAfterOpen = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const attachments = await listPaymentAccountTransactionAttachments(
        rowData.id,
      );

      setState(prevState => ({
        ...prevState,
        attachments,
        isSubmitting: false,
      }));
    } catch {
      onClose();
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleAfterClose = () => {
    setState({
      attachments: [],
      isLoading: false,
      isSubmitting: false,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: false,
      },
    });
  };

  const handleAttachmentsUpdate = (
    updatedAttachments: IPaymentAccountTransactionAttachmentEntity[],
  ) => {
    setState(old => ({ ...old, attachments: updatedAttachments }));
  };

  const handleSubmitAttachments = async () => {
    if (!state.attachments.length) {
      return;
    }

    setState(old => ({ ...old, isSubmitting: true }));

    try {
      if (state.attachments.length) {
        await uploadPaymentAccountTransactionAttachments({
          attachments: state.attachments,
          paymentAccountTransactionId: rowData.id,
          onUploadProgress(progressEvent) {
            setState(old => ({
              ...old,
              progressModal: {
                isOpen: true,
                total: progressEvent.total,
                loaded: progressEvent.loaded,
              },
            }));
          },
        });
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Os anexos foram atualizados com sucesso.",
      });

      onClose();
    } finally {
      setState(old => ({
        ...old,
        isSubmitting: false,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      overlayClassName="react-modal-overlay"
      shouldCloseOnEsc={!state.isSubmitting}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Alterar anexos</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            disabled={state.isSubmitting}
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <div className="col-12 react-modal-body">
              <div className="card">
                <p>
                  <strong>Descrição</strong>:{" "}
                  {rowData?.description || "(sem descrição)"}
                </p>
              </div>
              <AttachmentsGrid
                attachments={state.attachments}
                getStorageFilebyId={getStorageFilebyId}
                listAttachmentTypes={listAttachmentTypes}
                onAttachmentsChange={handleAttachmentsUpdate}
              />
              <RequestProgressModal
                mode="determinate"
                {...state.progressModal}
              />
            </div>
            <div className="col-12 react-modal-footer">
              <button
                type="button"
                id="btn-close"
                data-testid="btn-close"
                className="form-button red-bkg"
                disabled={state.isSubmitting}
                onClick={onClose}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-submit"
                data-testid="btn-submit"
                disabled={state.isSubmitting}
                className={`form-button ${
                  state.attachments.length ? "green-bkg" : "invalid-bkg"
                }`}
                onClick={handleSubmitAttachments}
              >
                Tudo certo{" "}
                {state.isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
