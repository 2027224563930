import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IUpdateProviderRetainFeeContract,
  IUpdateProviderRetainFeeParams,
} from "../../domain/contracts/updateProviderRetainFeeContract";

export class UpdateProviderRetainFeeService
  implements IUpdateProviderRetainFeeContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateProviderRetainFee(params: IUpdateProviderRetainFeeParams) {
    const userEntity = this.getUserLocalService.get();
    const url = "/Providers";

    const payload = {
      id: params.providerId,
      retainFee: params.retainFee,
    };

    return this.api.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
