import { Dropdown } from "primereact/dropdown";
import { FaCaretDown, FaDatabase, FaFileExcel } from "react-icons/fa";
import { RiFilterOffFill } from "react-icons/ri";
import { useState } from "react";
import { PanoramaFormModal } from "../../../../../../advTable/presentation/components/PanoramaFormModal";
import { SoulDropdown } from "../../../../../../core/presentation/components/SoulDropdown";
import { Container, FilterToolsContainer, PageToolsContainer } from "./styles";
import { useToolbar } from "../../hooks/useToolbar";
import { usePaymentRequestPage } from "../../hooks/usePaymentRequestPage";
import { ReportsByCostCenterModal } from "../ReportsByCostCenterModal";
import {
  Balance,
  EBalanceVariant,
} from "../../../../../../core/presentation/components/Balance";

export function PaymentRequestToolbar() {
  const {
    state,
    columns,
    usePaymentRequests,
    handlePanoramaChange,
    handleClearButtonClick,
  } = usePaymentRequestPage();

  const { generateReportByCostCenter } = usePaymentRequests;

  const {
    data,
    pfsEvent,
    selectedColumns,
    orderedColNames,
    filtered: showClearButton,
  } = state;

  const {
    loading,
    panoramaOptions,
    selectedPanorama,
    handlePanoramaSave,
    isPanoramaModalOpen,
    renderPanoramaOption,
    setIsPanoramaModalOpen,
    handlePanoramaDropdownChange,
    handleSavePanoramaButtonClick,
    handleOnExportButtonClick,
  } = useToolbar({
    columns,
    orderedColNames,
    usePaymentRequests,
    handlePanoramaChange,
  });

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleReportModalOpen = () => {
    setIsReportModalOpen(true);
  };

  const handleReportModalClose = () => {
    setIsReportModalOpen(false);
  };

  return (
    <Container>
      <div className="page-tools">
        <PageToolsContainer>
          {/* Total */}
          <div className="balance-tool">
            <Balance value={data?.balance} variant={EBalanceVariant.Red} />
          </div>

          {/* Panoramas */}
          <div>
            <Dropdown
              id="sel-panorama"
              data-testid="sel-panorama"
              optionLabel="name"
              value={selectedPanorama}
              options={panoramaOptions}
              panelStyle={{ width: "230px" }}
              placeholder="Padrão do sistema"
              filterPlaceholder="Pesquise um panorama"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              itemTemplate={renderPanoramaOption}
              onChange={handlePanoramaDropdownChange}
              filter
            />
            {loading && <i className="pi pi-spin pi-spinner" />}
          </div>

          {/* Opções */}
          <div>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-testid="btn-options"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              {/* Salvar Panorama */}
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleSavePanoramaButtonClick}
              >
                <FaDatabase />
                <span title="Panorama">Salvar Panorama</span>
              </button>

              {/* Gerar Relatório por C.C. */}
              <button
                type="button"
                id="btn-generate-report"
                className="dropdown-item"
                data-testid="btn-generate-report"
                onClick={handleReportModalOpen}
              >
                <FaFileExcel />
                <span title="Gerar Relatório por C.C.">
                  Gerar Relatório por C.C.
                </span>
              </button>

              {/* Exportar para excel */}
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleOnExportButtonClick}
              >
                <FaFileExcel />
                <span title="Exportar para excel">Exportar para excel</span>
              </button>
            </SoulDropdown>
          </div>
        </PageToolsContainer>
      </div>
      <div className="filter-tools">
        <FilterToolsContainer>
          {showClearButton && (
            <div className="clr-filter-btn-wrapper">
              <button
                id="btn-clear"
                data-testid="btn-clear"
                className="default-button clear-filter-button"
                type="button"
                onClick={handleClearButtonClick}
              >
                <RiFilterOffFill />
                Limpar filtros
              </button>
            </div>
          )}
        </FilterToolsContainer>
      </div>
      {isPanoramaModalOpen && (
        <PanoramaFormModal
          pfsEvent={pfsEvent}
          isOpen={isPanoramaModalOpen}
          selectedColumns={selectedColumns}
          onRequestClose={() => {
            setIsPanoramaModalOpen(false);
          }}
          onPanoramaSave={handlePanoramaSave}
        />
      )}
      <ReportsByCostCenterModal
        isOpen={isReportModalOpen}
        onClose={handleReportModalClose}
        generateReportByCostCenter={generateReportByCostCenter}
      />
    </Container>
  );
}
