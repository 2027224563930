import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetAttachmentsContract } from "../../domain/contracts/getAttachmentsContract";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntity";
import { IAccountPayableListItemEntity } from "../../domain/entities/accountPayableListItemEntity";

export class GetAttachmentsService implements IGetAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getAttachments(accountPayableListItem: IAccountPayableListItemEntity) {
    const userEntity = this.getUserLocalService.get();
    const { id } = accountPayableListItem;

    const url = `/AccountsPayable/${id}/Attachments`;

    return this.api.get<IAccountPayableAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
