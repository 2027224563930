import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IPaymentAccountEntity } from "../../domain/entities/paymentAccountEntity";
import { IListPaymentAccountsByCompanyService } from "../../domain/usecases/listPaymentAccountByCompanyUseCase";

export class ListPaymentAccountsByCompanyService
  implements IListPaymentAccountsByCompanyService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listPaymentAccountsByCompany(
    companyGroupId: string,
    companyId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    let url = `/CompanyGroups/${companyGroupId}/Companies/${companyId}/PaymentAccounts`;

    if (actives) {
      url = `${url}/Actives`;
    }

    const response = await this.api.get<
      IServerSideResponseModel<IPaymentAccountEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
