import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ECustomerDocumentType,
  ECustomerType,
  ICustomerEntity,
} from "../../domain/entities/customerEntity";
import { ISaveCustomerService } from "../../domain/usecases/saveCustomerUseCase";

export class SaveCustomerService implements ISaveCustomerService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveCustomer(customerData: ICustomerEntity) {
    const userEntity = this.getUserLocalService.get();

    const url = "/Customers";
    const data = customerData;
    let response;

    if (Number(data.type) === ECustomerType.foreign) {
      data.documentType = ECustomerDocumentType.other;
    } else if (Number(data.type) === ECustomerType.individualPerson) {
      data.documentType = ECustomerDocumentType.CPF;
      if (data.birthDate) {
        data.birthDate =
          data.birthDate !== ""
            ? format(
                parse(data.birthDate ?? "", "ddMMyyyy", new Date()),
                "yyyy-MM-dd",
              ).toString()
            : "";
      }
    } else if (Number(data.type) === ECustomerType.legalPerson) {
      data.documentType = ECustomerDocumentType.CNPJ;
    }

    if (customerData.id === "") {
      response = await this.api.post<ICustomerEntity>(url, data, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    } else if (customerData.id !== "") {
      response = await this.api.put<ICustomerEntity>(url, data, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return response as ICustomerEntity;
  }
}
