import styled from "styled-components";

interface ContainerProps {
  filtered: boolean;
}

export const Container = styled.div<ContainerProps>`
  --fore-default: #8898aa;
  --fore-filtered: white;
  --back-default: #f6f9fc;
  --back-filtered: #525f7f;
  --highlight-from: rgb(218, 220, 255);
  --highlight-to: #e1e2fe00;

  @keyframes headerHighlight {
    from {
      box-shadow: 1px 1px 200px var(--highlight-from) inset;
    }

    to {
      box-shadow: 1px 1px 200px var(--highlight-to) inset;
    }
  }

  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.65rem;
  line-height: 0.8rem;
  padding: 0.375rem 0.75rem;
  color: ${props =>
    props.filtered ? "var(--fore-filtered)" : "var(--fore-default)"};
  background: ${props =>
    props.filtered ? "var(--back-filtered)" : "var(--back-default)"};
  transition: filter var(--transitionTime);
  height: 100%;

  &:first-child {
    animation: headerHighlight 1s ease-out !important;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.2rem;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    small {
      font-size: 0.75em;
    }
  }

  &:hover {
    filter: saturate(2) brightness(0.95);
  }

  i {
    font-size: 0.8rem;

    &.filter-icon {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
  }

  button.sort-button {
    display: flex;
    margin-left: auto;
    border: 1px solid #95a4b4;
    color: #95a4b4;
    background: transparent;
    border-radius: 0.375rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
    transition: background var(--transitionTime), color var(--transitionTime);

    &:hover {
      background: #172b4d;
      color: #fffd;
      box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 16%);
    }
  }
`;
