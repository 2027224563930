import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRequestPaymentRequestReviewContract } from "../../domain/contracts/requestPaymentRequestReviewContract";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";

export class RequestPaymentRequestReviewService
  implements IRequestPaymentRequestReviewContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  requestPaymentRequestReview(paymentRequestId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentRequests/${paymentRequestId}/Status`;

    const data = {
      status: EPaymentRequestStatus.Requested,
    };

    return this.api.put(url, data, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
