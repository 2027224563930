import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IImportConciliationContract } from "../../domain/contracts/importConciliationContract";
import {
  ConciliationEntity,
  ConciliationEntryEntity,
  EConciliationOrigin,
  IConciliationEntity,
} from "../../domain/entitites/conciliationEntity";
import { IConciliationPayloadEntity } from "../../domain/entitites/conciliationPayloadEntity";
import { IAccountConciliationResponsehModel } from "../models/accountConciliationResponseModel";
import { IConciliationPayloadModel } from "../models/conciliationPayloadModel";

export class ImportConciliationService implements IImportConciliationContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async importConciliation(
    payloadEntity: IConciliationPayloadEntity,
  ): Promise<IConciliationEntity[]> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsConciliation/Conciliate`;

    const payload: IConciliationPayloadModel = {
      initialFilterDate: format(
        parse(payloadEntity.startDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      ),
      finalFilterDate: format(
        parse(payloadEntity.endDate, "ddMMyyyy", new Date()),
        "yyyy-MM-dd",
      ),
      companyList: payloadEntity.company.map(({ id }) => id),
      paymentAccountList: payloadEntity.paymentAccount.map(({ id }) => id),
      file: payloadEntity.file,
    };

    const formData = new FormData();

    Object.entries(payload).map(([key, fieldValue]) => {
      if (Array.isArray(fieldValue)) {
        return fieldValue.map((value, index) => {
          return formData.append(`${key}[${index}]`, value);
        });
      }

      return formData.append(key, fieldValue);
    });

    const response = await this.api.post<IAccountConciliationResponsehModel[]>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const result = response.map(
      (
        {
          foundAccounts,
          providedAccount: { parcelNumber, totalParcels, ...rest },
        },
        index,
      ) => {
        let providedValue = null;

        if (rest.value !== null) {
          providedValue =
            rest.origin === EConciliationOrigin.revenue
              ? rest.value
              : -rest.value;
        }

        return new ConciliationEntity({
          rowIndex: index,
          providedAccount: new ConciliationEntryEntity({
            id: rest.accountId || `not-found-${index}`,
            companyName: rest.companyAssumedName,
            customerProviderDocument: rest.customerProviderDocument,
            customerProviderName: rest.customerProviderName,
            documentNumber: rest.documentNumber,
            issueDate: rest.issueDate ? new Date(rest.issueDate) : null,
            origin: rest.origin,
            parcelNumber: `${parcelNumber}/${totalParcels}`,
            value: providedValue,
            sheetRowNumber: rest.rowNumber,

            terminationDate: rest.paymentReceiptDate
              ? new Date(rest.paymentReceiptDate)
              : null,
            paymentMethod: rest.paymentMethod,
            classificationAssessmentName: rest.classificationAssessmentName,
            costCenterName: rest.costCenterName,
            projectName: rest.projectName,
            description: rest.description,
            observation: rest.observation,
          }),
          foundAccounts: foundAccounts
            .map(foundAccount => {
              const foundParcelNumber = foundAccount.parcelNumber;
              const foundtotalParcels = foundAccount.totalParcels;
              const { origin, value } = foundAccount;
              let foundValue = null;

              if (value !== null) {
                foundValue =
                  origin === EConciliationOrigin.revenue ? value : -value;
              }

              return new ConciliationEntryEntity({
                id: foundAccount.accountId || null,
                companyName: foundAccount.companyAssumedName,
                customerProviderDocument: foundAccount.customerProviderDocument,
                customerProviderName: foundAccount.customerProviderName,
                documentNumber: foundAccount.documentNumber,
                issueDate: foundAccount.issueDate
                  ? new Date(foundAccount.issueDate)
                  : null,
                origin,
                parcelNumber: `${foundParcelNumber}/${foundtotalParcels}`,
                value: foundValue,

                terminationDate: foundAccount.paymentReceiptDate
                  ? new Date(foundAccount.paymentReceiptDate)
                  : null,
                paymentMethod: foundAccount.paymentMethod,
                classificationAssessmentName:
                  foundAccount.classificationAssessmentName,
                costCenterName: foundAccount.costCenterName,
                projectName: foundAccount.projectName,
                description: foundAccount.description,
                observation: foundAccount.observation,
              });
            })
            .sort((itemA, itemB) => {
              if (itemA.documentNumber > itemB.documentNumber) {
                return 1;
              }

              if (itemB.documentNumber > itemA.documentNumber) {
                return -1;
              }

              if (itemA.parcelNumber > itemB.parcelNumber) {
                return 1;
              }

              if (itemB.parcelNumber > itemA.parcelNumber) {
                return -1;
              }

              return 0;
            }),
        });
      },
    );

    return result;
  }
}
