import { ContextMenu } from "primereact/contextmenu";
import { useCallback, useRef, useState } from "react";
import { FaCaretDown, FaFileUpload, FaPlus } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  AdvTable,
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import {
  ApiService,
  IApiError,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { ButtonClearFilter } from "../../../../../core/presentation/components/ButtonClearFilter";
import { GridOptionsContainer } from "../../../../../core/presentation/components/GridOptionsContainer";
import { ImportFileModal } from "../../../../../core/presentation/components/ImportFileModal";
import { InputSearch } from "../../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { SoulDropdown } from "../../../../../core/presentation/components/SoulDropdown";
import { useAllowedProfiles } from "../../../../../core/presentation/hooks/useAllowedProfiles";
import { useApiResponseErrorHandlers } from "../../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useDebounceTime } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useImportFileErrorHandlers } from "../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IErrorResponseEntity,
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import { ICostCenterTransactionEntity } from "../../../domain/entities/costCenterTransactionsEntity";
import {
  MakeCostCenterTransactions,
  makeCostCenterTransactions,
} from "../../../main/makeCostCenterTransactions";
import {
  EEditModalMode,
  useCostCenterTransactionsGrid,
} from "../../hooks/useCostCenterTransactionsGrid";
import { CostCenterTransactionAttachmentsModal } from "../CostCenterTransactionAttachmentsModal";
import { CostCenterTransactionFormModal } from "../CostCenterTransactionFormModal";
import { CostCenterTransactionsContextMenu } from "../CostCenterTransactionsContextMenu";
import { Container } from "./styles";

interface CostCenterTransactionsPageProps {
  useCostCenterTransactions: MakeCostCenterTransactions;
}

interface ICostCenterTransactionsPageState {
  isAttachmentsModalOpen: boolean;
  rowData: ICostCenterTransactionEntity | null;
}

function CostCenterTransactionsPage({
  useCostCenterTransactions,
}: CostCenterTransactionsPageProps) {
  const { hash } = useLocation();
  const importFileErrorHandler = useImportFileErrorHandlers();

  const table = useRef<IAdvTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [
    isCostCenterTransactionModalOpen,
    setIsCostCenterTransactionsModalOpen,
  ] = useState<boolean>(hash.includes("add"));
  const [currentCostCenterTransactionId, setCurrentCostCenterTransactionsId] =
    useState("");
  const [modalMode, setModalMode] = useState(EEditModalMode.edit);
  const [filtered, setFiltered] = useState(false);

  const [importFiles, setImportFiles] = useState<File[]>([]);
  const [isOpenImportFileModal, setIsOpenImportFileModal] = useState(
    hash.includes("import"),
  );

  const [state, setState] = useState<ICostCenterTransactionsPageState>({
    rowData: null,
    isAttachmentsModalOpen: false,
  });

  const menuRef = useRef<ContextMenu>(null);

  const openCostCenterTransactionsFormModal = useCallback(
    (currentId = "", mode: EEditModalMode = EEditModalMode.edit) => {
      setCurrentCostCenterTransactionsId(currentId);
      setIsCostCenterTransactionsModalOpen(true);
      setModalMode(mode);
    },
    [],
  );

  const closeCostCenterTransactionFormModal = useCallback(() => {
    setIsCostCenterTransactionsModalOpen(false);
    setCurrentCostCenterTransactionsId("");
    table.current?.reload();
  }, []);

  const {
    getStorageFilebyId,
    listAttachmentTypes,
    listCostCenterTransactions,
    toggleCostCenterTransaction,
    listCostCenterTransactionAttachments,
    saveCostCentersTransactionsImportation,
    uploadCostCenterTransactionAttachments,
    getCostCentersTransactionsImportationTemplate,
  } = useCostCenterTransactions;

  const dialog = useSoulDialog();

  const toggle = useCallback(
    async currentId => {
      try {
        await toggleCostCenterTransaction(currentId);

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Movimentação excluída com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleCostCenterTransaction],
  );

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useCostCenterTransactionsGrid({
    useCostCenterTransactions,
    openEditModal: openCostCenterTransactionsFormModal,
    toggle,
  });
  const { advGeneratePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IResponseEntity<ICostCenterTransactionEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setLoading(false);
        return;
      }

      setLoading(true);

      const isFiltered = ((_pfsEvent.filters &&
        Object.keys(_pfsEvent.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (_pfsEvent.multiSortMeta &&
          Object.keys(_pfsEvent.multiSortMeta).length > 0)) as boolean;

      setFiltered(isFiltered);

      // Clone do objeto de evento para que as
      // referências dos filtros não sejam perdidas
      const eventClone: IPFSEventEntity = JSON.parse(JSON.stringify(_pfsEvent));

      try {
        const payload = advGeneratePayload(
          eventClone,
          columns as ISimpleColumn[],
        );

        const costCenterTransactionsList = await listCostCenterTransactions(
          id,
          payload,
        );

        if (!mountedRef.current) {
          return;
        }

        setData(costCenterTransactionsList);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setLoading(false);

        // Isso é necessário pois temos elementos dinamicos
        // com tooltip e o ReactTooltip precisa escanea-los
        ReactTooltip.rebuild();
      }
    },
    [advGeneratePayload, columns, id, listCostCenterTransactions, mountedRef],
  );

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const allowedProfiles = useAllowedProfiles();

  const clearFilters = useCallback(() => {
    table.current?.resetFilters();
  }, []);

  /**
   * Lida com a confirmação de importação da planilha de Movimentações de
   * Centros de Custo.
   */
  const handleImportConfirmation = useCallback(async () => {
    try {
      await saveCostCentersTransactionsImportation(importFiles[0]);

      setIsOpenImportFileModal(false);

      dialog.fire({
        title: "Feito!",
        icon: "success",
        confirmButtonText: "OK",
        text: "Movimentações realizadas com sucesso.",
      });

      table.current?.reload();
    } catch (error) {
      const errorResponse = (error as IApiError<IErrorResponseEntity>).response;
      importFileErrorHandler(errorResponse);
    } finally {
      setImportFiles([]);
    }
  }, [
    dialog,
    importFiles,
    importFileErrorHandler,
    saveCostCentersTransactionsImportation,
  ]);

  /**
   * Exibe alerta em relação ao processo de importação de planilha de
   * Movimentações de Centros de Custo.
   */
  const alertImportProcess = useCallback(() => {
    dialog.fire({
      icon: "warning",
      title: "Atenção!",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Ok",
      html: `<p>Se a <strong>planilha</strong> informada for <strong>válida</strong>,
        as movimentações de Centros de Custo serão <strong>realizadas</strong>.</p>
        <br />
        <p>Deseja prosseguir?</p>`,
      preConfirm() {
        dialog.fire({
          title: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          text: "Realizando as movimentações dos Centros de Custo",
          didOpen: () => {
            dialog.showLoading();
          },
        });
        handleImportConfirmation();
      },
    });
  }, [dialog, handleImportConfirmation]);

  /**
   * Realiza a requisição para o template de importação de Movimentações.
   * Em caso de sucesso, abre uma nova aba e realiza o download do arquivo.
   */
  const templateImportationHandler = useCallback(async () => {
    const url = await getCostCentersTransactionsImportationTemplate();
    window.open(url);
  }, [getCostCentersTransactionsImportationTemplate]);

  const handleRowClick = (
    event: IDataTableRowClickEventParams<ICostCenterTransactionEntity>,
  ) => {
    menuRef.current?.show(event?.originalEvent);
    setState(prevState => ({ ...prevState, rowData: event.data }));
  };

  const handleOpenAttachmentsModal = () => {
    setState(prevState => ({ ...prevState, isAttachmentsModalOpen: true }));
  };

  const handleCloseAttachmentsModal = () => {
    setState(prevState => ({
      ...prevState,
      rowData: null,
      isAttachmentsModalOpen: false,
    }));
  };

  return (
    <Container>
      <Page className="full-page">
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          {filtered && <ButtonClearFilter onClick={() => clearFilters()} />}
          {allowedProfiles(...SoulRoutes.TRANSACTIONS_COST_CENTER.profile) ? (
            <GridOptionsContainer>
              <SoulDropdown
                toggler={
                  <button
                    type="button"
                    className="default-button options-context"
                  >
                    <span>Opções</span>
                    <FaCaretDown className="context-dropdown-icon" />
                  </button>
                }
              >
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    setIsOpenImportFileModal(true);
                  }}
                >
                  <FaFileUpload />
                  <span title="Importar Movimentações">
                    Importar Movimentações de CC
                  </span>
                </button>
              </SoulDropdown>
              <button
                type="button"
                className="default-button"
                id="btn-add"
                onClick={() => openCostCenterTransactionsFormModal()}
              >
                <FaPlus /> Realizar Movimentação
              </button>
            </GridOptionsContainer>
          ) : null}
          {isCostCenterTransactionModalOpen && (
            <CostCenterTransactionFormModal
              currentId={currentCostCenterTransactionId}
              isOpen={isCostCenterTransactionModalOpen}
              useCostCenterTransactions={useCostCenterTransactions}
              modalMode={modalMode}
              onRequestClose={closeCostCenterTransactionFormModal}
            />
          )}
          <ImportFileModal
            files={importFiles}
            setFiles={setImportFiles}
            isOpen={isOpenImportFileModal}
            filesHandler={alertImportProcess}
            templateHandler={templateImportationHandler}
            modalTitle="Importação das Movimentações de Centros de Custo"
            onRequestClose={() => {
              setImportFiles([]);
              setIsOpenImportFileModal(false);
            }}
          />
          <CostCenterTransactionAttachmentsModal
            rowData={state.rowData}
            isOpen={state.isAttachmentsModalOpen}
            onClose={handleCloseAttachmentsModal}
            getStorageFilebyId={getStorageFilebyId}
            listAttachmentTypes={listAttachmentTypes}
            uploadCostCenterTransactionAttachments={
              uploadCostCenterTransactionAttachments
            }
            listCostCenterTransactionAttachments={
              listCostCenterTransactionAttachments
            }
          />
        </header>
        <CostCenterTransactionsContextMenu
          menuRef={menuRef}
          onOpenAttachmentsModal={handleOpenAttachmentsModal}
        />
        <article className="no-padding fill-height">
          <AdvTable<ICostCenterTransactionEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            rowsDefault={50}
            rowsPerPageOptions={[10, 50, 100]}
            onRowClick={handleRowClick}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface CostCenterTransactionsPageFactoryProps {
  api: IApiService;
}

export function CostCenterTransactionsPageFactory({
  api,
}: CostCenterTransactionsPageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;

  const apiResponseErrorHandlers = useApiResponseErrorHandlers();

  /**
   * REVIEW talvez possamos fazer essa inicialização
   * em outro lugar, por ora deixaremos aqui
   */
  const authApi = new ApiService(baseUrl, apiResponseErrorHandlers);

  return (
    <CostCenterTransactionsPage
      useCostCenterTransactions={makeCostCenterTransactions(api, authApi)}
    />
  );
}
