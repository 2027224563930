import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { IApiService } from "../../../../core/data/services/apiService";
import { Page } from "../../../../core/presentation/components/Page/styles";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { Container } from "./style";
import {
  MakeClassificationAccounts,
  makeClassificationAccounts,
} from "../../../main/makeClassificationAccounts";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { useClassificationAccountsGrid } from "../../hooks/useClassificationAccountsGrid";
import { ClassificationAccountFormModal } from "../ClassificationAccountFormModal";
import { MakeCore, makeCore } from "../../../../core/main/makeCore";

interface ClassificationAccountsPageProps {
  useCore: MakeCore;
  useClassficationAccounts: MakeClassificationAccounts;
}

function ClassificationAccountsPage({
  useCore,
  useClassficationAccounts,
}: ClassificationAccountsPageProps) {
  const { listClassificationAccounts, toggleClassificationAccount } =
    useClassficationAccounts;

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();
  const {
    companyGroupList,
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  /**
   * Permissão que verifica se o usuário pode adicionar uma classificação.
   * */
  const canAdd = useMemo(
    () =>
      companyGroupList.length &&
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles, companyGroupList.length],
  );

  /** Referência da tabela */
  const table = useRef<ISimpleTableHandle>(null);

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Estado de carregamento da tabela */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da classificação selecionada */
  const [currentClassificationAccount, setCurrentClassificationAccount] =
    useState<Partial<IClassificationAccountEntity>>();

  /** Armazena os dados da tabela */
  const [data, setData] =
    useState<IServerSideResponseModel<IClassificationAccountEntity[]>>();

  /**
   * Alterna o status de uma classificação contábil. (soft delete)
   */
  const toggle = useCallback(
    async (companyId: string, actionText: string) => {
      try {
        await toggleClassificationAccount(companyId);

        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Classificação Contábil ${actionText} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleClassificationAccount],
  );

  /**
   * Lida com a abertura dos modais de edição/criação de uma classificação
   * contábil.
   */
  const openClassificationAccountModal = useCallback(
    (info: IClassificationAccountEntity) => {
      setCurrentClassificationAccount(info);
    },
    [],
  );

  /**
   * Lida com a ação de fechar o modal ativo.
   */
  const closeClassificationAccountModal = useCallback(
    (shouldRefresh: boolean | unknown) => {
      if (typeof shouldRefresh === "boolean" && shouldRefresh)
        table.current?.reload();

      setCurrentClassificationAccount(undefined);
    },
    [],
  );

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  const { columns } = useClassificationAccountsGrid({
    toggle,
    openClassificationAccountModal,
  });

  /**
   * Realiza a requisição para buscar os dados da tabela. Lida com os eventos
   * monitorados pela SimpleTable.
   */
  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      /**
       * TODO - Isso DEVE ser removido após as atualizações na dinâmica de
       * carregamento do grupo de empresa do usuário.
       */
      if (!id) return;

      setIsLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listClassificationAccounts(payload, id);
        setData(response);
      } catch (err) {
        return;
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [columns, generatePayload, id, listClassificationAccounts, mountedRef],
  );

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            value={search}
            id="txt-search"
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          {canAdd ? (
            <button
              id="btn-add"
              type="button"
              data-testid="btn-add"
              className="default-button"
              onClick={() => {
                setCurrentClassificationAccount({ id: "", active: true });
              }}
            >
              <FaPlus /> Adicionar Classificação Contábil
            </button>
          ) : null}
          <ClassificationAccountFormModal
            useCore={useCore}
            isOpen={!!currentClassificationAccount}
            onClose={closeClassificationAccountModal}
            useClassificationAccounts={useClassficationAccounts}
            currentClassificationAccount={currentClassificationAccount}
          />
        </header>
        <article className="no-padding fill-height">
          <SimpleTable
            data={data}
            tableRef={table}
            columns={columns}
            loading={isLoading}
            getList={getList}
            globalFilter={globalFilter}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ClassificationAccountsPageFactoryProps {
  api: IApiService;
}

export function ClassificationAccountsPageFactory({
  api,
}: ClassificationAccountsPageFactoryProps) {
  return (
    <ClassificationAccountsPage
      useCore={makeCore(api)}
      useClassficationAccounts={makeClassificationAccounts(api)}
    />
  );
}
