import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { CheckCustomerCnpjService } from "../data/services/checkCustomerCnpjService";
import { CheckCustomerCpfService } from "../data/services/checkCustomerCpfService";
import { GetBanksService } from "../data/services/getBanksService";
import { GetCityByIbgeService } from "../data/services/getCityByIbgeService";
import { GetCityByNameService } from "../data/services/getCityByNameService";
import { GetCustomerService } from "../data/services/getCustomerService";
import { GetCustomerTypesService } from "../data/services/getCustomerTypesService";
import { GetDataFromReceitaService } from "../data/services/getDataFromReceitaService";
import { GetStateByNameService } from "../data/services/getStateByNameService";
import { GetStateByUfService } from "../data/services/getStateByUfService";
import { GetViaCepService } from "../data/services/getViaCepService";
import { LinkClassificationsAccountService } from "../data/services/linkClassificationsAccountService";
import { ListClassificationsAccountService } from "../data/services/listClassificationsAccountService";
import { ListCountriesService } from "../data/services/listCountriesService";
import { ListCustomersService } from "../data/services/listCustomersService";
import { ListLinkedClassificationsAccountService } from "../data/services/listLinkedClassificationsAccountService";
import { SaveCustomerService } from "../data/services/saveCustomerService";
import { ToggleCustomerStatusService } from "../data/services/toggleCustomerService";
import {
  CheckCustomerCnpjUseCase,
  ICheckCustomerCnpjUseCase,
} from "../domain/usecases/checkCustomerCnpjUseCase";
import {
  CheckCustomerCpfUseCase,
  ICheckCustomerCpfUseCase,
} from "../domain/usecases/checkCustomerCpfUseCase";
import {
  GetBanksUseCase,
  IGetBanksUseCase,
} from "../domain/usecases/getBanksUseCase";
import {
  GetCityByIbgeUseCase,
  IGetCityByIbgeUseCase,
} from "../domain/usecases/getCityByIbgeUseCase";
import {
  GetCityByNameUseCase,
  IGetCityByNameUseCase,
} from "../domain/usecases/getCityByNameUseCase";
import {
  GetCustomerTypesUseCase,
  IGetCustomerTypesUseCase,
} from "../domain/usecases/getCustomerTypesUseCase";
import {
  GetCustomerUseCase,
  IGetCustomerUseCase,
} from "../domain/usecases/getCustomerUseCase";
import {
  GetDataFromReceitaUseCase,
  IGetDataFromReceitaUseCase,
} from "../domain/usecases/getDataFromReceitaUseCase";
import {
  GetStateByNameUseCase,
  IGetStateByNameUseCase,
} from "../domain/usecases/getStateByNameUseCase";
import {
  GetStateByUfUseCase,
  IGetStateByUfUseCase,
} from "../domain/usecases/getStateByUfUseCase";
import {
  GetViaCepUseCase,
  IGetViaCepUseCase,
} from "../domain/usecases/getViaCepUseCase";
import {
  ILinkClassificationsAccountUseCase,
  LinkClassificationsAccountUseCase,
} from "../domain/usecases/linkClassificationsAccountUseCase";
import {
  IListClassificationsAccountUseCase,
  ListClassificationsAccountUseCase,
} from "../domain/usecases/listClassificationsAccountUseCase";
import {
  IListCountriesUseCase,
  ListCountriesUseCase,
} from "../domain/usecases/listCountriesUseCase";
import {
  IListCustomersUseCase,
  ListCustomersUseCase,
} from "../domain/usecases/listCustomersUseCase";
import {
  IListLinkedClassificationsAccountUseCase,
  ListLinkedClassificationsAccountUseCase,
} from "../domain/usecases/listLinkedClassificationsAccountUseCase";
import {
  ISaveCustomerUseCase,
  SaveCustomerUseCase,
} from "../domain/usecases/saveCustomerUseCase";
import {
  IToggleCustomerStatusUseCase,
  ToggleCustomerStatusUseCase,
} from "../domain/usecases/toggleCustomerUseCase";

export type MakeCustomer = IGetCustomerTypesUseCase &
  IListCustomersUseCase &
  IToggleCustomerStatusUseCase &
  ICheckCustomerCpfUseCase &
  IListClassificationsAccountUseCase &
  IListLinkedClassificationsAccountUseCase &
  ILinkClassificationsAccountUseCase &
  IGetBanksUseCase &
  ICheckCustomerCnpjUseCase &
  IGetBanksUseCase &
  IGetDataFromReceitaUseCase &
  IGetViaCepUseCase &
  IGetStateByUfUseCase &
  IGetCityByIbgeUseCase &
  IListCountriesUseCase &
  IGetStateByNameUseCase &
  IGetCityByNameUseCase &
  ISaveCustomerUseCase &
  IGetCustomerUseCase;

export function makeCustomer(
  api: IApiService,
  viaCepApi: IApiService,
): MakeCustomer {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listCustomerService = new ListCustomersService(
    getUserLocalService,
    api,
  );
  const listCustomerUseCase = new ListCustomersUseCase(listCustomerService);

  const toggleCustomerStatusService = new ToggleCustomerStatusService(
    getUserLocalService,
    api,
  );
  const toggleCustomerStatusUseCase = new ToggleCustomerStatusUseCase(
    toggleCustomerStatusService,
  );

  const linkClsAccountService = new LinkClassificationsAccountService(
    getUserLocalService,
    api,
  );
  const linkClsAccountUseCase = new LinkClassificationsAccountUseCase(
    linkClsAccountService,
  );

  const listClsAccountService = new ListClassificationsAccountService(
    getUserLocalService,
    api,
  );
  const listClsAccountUseCase = new ListClassificationsAccountUseCase(
    listClsAccountService,
  );

  const checkProviderCpfService = new CheckCustomerCpfService(
    getUserLocalService,
    api,
  );
  const checkCustomerCpfUseCase = new CheckCustomerCpfUseCase(
    checkProviderCpfService,
  );

  const checkCustomerCnpjService = new CheckCustomerCnpjService(
    getUserLocalService,
    api,
  );
  const checkCustomerCnpjUseCase = new CheckCustomerCnpjUseCase(
    checkCustomerCnpjService,
  );

  const listLinkedClsAccountService =
    new ListLinkedClassificationsAccountService(getUserLocalService, api);
  const listLinkedClsAccountUseCase =
    new ListLinkedClassificationsAccountUseCase(listLinkedClsAccountService);

  const getDataFromReceitaService = new GetDataFromReceitaService();
  const getDataFromReceitaUserCase = new GetDataFromReceitaUseCase(
    getDataFromReceitaService,
  );

  const getViaCepService = new GetViaCepService(viaCepApi);
  const getViaCepUseCase = new GetViaCepUseCase(getViaCepService);

  const getCityByIbgeService = new GetCityByIbgeService(
    getUserLocalService,
    api,
  );
  const getCityByIbgeUseCase = new GetCityByIbgeUseCase(getCityByIbgeService);

  const getCityByNameService = new GetCityByNameService(
    getUserLocalService,
    api,
  );
  const getCityByNameUseCase = new GetCityByNameUseCase(getCityByNameService);

  const getCustomerService = new GetCustomerService(getUserLocalService, api);
  const getCustomerUseCase = new GetCustomerUseCase(getCustomerService);

  const getStateByNameService = new GetStateByNameService(
    getUserLocalService,
    api,
  );
  const getStateByNameUseCase = new GetStateByNameUseCase(
    getStateByNameService,
  );

  const getStateByUfService = new GetStateByUfService(getUserLocalService, api);
  const getStateByUfUseCase = new GetStateByUfUseCase(getStateByUfService);

  const listCountriesService = new ListCountriesService(
    getUserLocalService,
    api,
  );
  const listCountriesUseCase = new ListCountriesUseCase(listCountriesService);

  const getBanksService = new GetBanksService(getUserLocalService, api);
  const getBanksUseCase = new GetBanksUseCase(getBanksService);

  const getCustomerTypesService = new GetCustomerTypesService(
    getUserLocalService,
    api,
  );
  const getCustomerTypesUseCase = new GetCustomerTypesUseCase(
    getCustomerTypesService,
  );

  const saveCustomerService = new SaveCustomerService(getUserLocalService, api);
  const saveCustomerUseCase = new SaveCustomerUseCase(saveCustomerService);

  return {
    listCustomers(companyGroupId, payload) {
      return listCustomerUseCase.listCustomers(companyGroupId, payload);
    },
    toggleCustomerStatus(id) {
      return toggleCustomerStatusUseCase.toggleCustomerStatus(id);
    },
    linkClassificationsAccount(customerId, classificationAccountEntity) {
      return linkClsAccountUseCase.linkClassificationsAccount(
        customerId,
        classificationAccountEntity,
      );
    },
    listClassificationsAccount(companyGroupId, payload) {
      return listClsAccountUseCase.listClassificationsAccount(
        companyGroupId,
        payload,
      );
    },
    listLinkedClassificationsAccount(companyGroupId, customerId) {
      return listLinkedClsAccountUseCase.listLinkedClassificationsAccount(
        companyGroupId,
        customerId,
      );
    },
    checkCnpj(cnpj, id?) {
      return checkCustomerCnpjUseCase.checkCnpj(cnpj, id);
    },
    checkCpf(cpf, id?) {
      return checkCustomerCpfUseCase.checkCpf(cpf, id);
    },
    fetchByCnpj(cnpj) {
      return getDataFromReceitaUserCase.fetchByCnpj(cnpj);
    },
    getByZipCode(zipCode) {
      return getViaCepUseCase.getByZipCode(zipCode);
    },
    getCityByIbge(ibge) {
      return getCityByIbgeUseCase.getCityByIbge(ibge);
    },
    getCityByName(stateId, payload) {
      return getCityByNameUseCase.getCityByName(stateId, payload);
    },
    getCustomer(customerId) {
      return getCustomerUseCase.getCustomer(customerId);
    },
    getStateByName(countryId, payload) {
      return getStateByNameUseCase.getStateByName(countryId, payload);
    },
    getStateByUf(uf) {
      return getStateByUfUseCase.getStateByUf(uf);
    },
    listCountriesByName(activeOnly?, payload?) {
      return listCountriesUseCase.listCountriesByName(activeOnly, payload);
    },
    getBanks() {
      return getBanksUseCase.getBanks();
    },
    getCustomerTypes() {
      return getCustomerTypesUseCase.getCustomerTypes();
    },
    saveCustomer(customerData) {
      return saveCustomerUseCase.saveCustomer(customerData);
    },
  };
}
