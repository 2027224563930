import { useState } from "react";
import { FaCaretDown, FaCheck, FaSpinner } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import {
  IConciliationEntity,
  IConciliationEntryEntity,
} from "../../../domain/entitites/conciliationEntity";
import {
  ConciliationResultEntity,
  IConciliationResultEntity,
} from "../../../domain/entitites/conciliationResultEntity";
import { useConciliationContext } from "../../hooks/useConciliationContext";
import { ConciliationRow } from "../ConciliationRow";
import { ConciliationRowEmpty } from "../ConciliationRowEmpty";
import { ConciliationRowHeader } from "../ConciliationRowHeader";
import { Toggle } from "../Toggle";
import { Container } from "./styles";

interface IConciliationStepConciliationState {
  selectedAll: boolean;
  onlyNotFound: boolean;
  onlyConciliated: boolean;
  sending: boolean;
}

interface IConciliationStepConciliationProps {
  conciliation: IConciliationEntity[];
  rowCheckboxOnChange(index: number, checked: boolean): void;
  rowConciliationOnChange(
    row: number,
    index: number,
    conciliationRow: IConciliationEntryEntity,
  ): void;
  onSelectAll(): void;
  onDeselectAll(): void;
  onConciliateSelectedClick(): void;
  onDeconciliateSelectedClick(): void;
  onlyNotFoundToggleOnChange(value: boolean): void;
  onlyConciliatedToggleOnChange(value: boolean): void;
  onResult(resultEntity: IConciliationResultEntity): void;
}

export function ConciliationStepConciliation({
  conciliation,
  onResult,
  onSelectAll,
  onDeselectAll,
  rowCheckboxOnChange,
  rowConciliationOnChange,
  onConciliateSelectedClick,
  onlyNotFoundToggleOnChange,
  onDeconciliateSelectedClick,
  onlyConciliatedToggleOnChange,
}: IConciliationStepConciliationProps) {
  const { backButtonClickHandler } = useConciliationContext();

  const [state, setState] = useState<IConciliationStepConciliationState>({
    selectedAll: false,
    onlyConciliated: false,
    onlyNotFound: false,
    sending: false,
  });

  const handleBackButtonClick = () => {
    backButtonClickHandler();
  };

  const isMountedRef = useIsMounted();

  const handleNextButtonClick = async () => {
    const entries = conciliation.length;

    const conciliated = conciliation.filter(con =>
      con.foundAccounts.some(item => item.conciliated),
    ).length;

    const missing = conciliation.filter(
      con =>
        con.foundAccounts.length === 0 ||
        con.foundAccounts.every(item => !item.conciliated),
    ).length;

    const conciliationResultEntity = new ConciliationResultEntity({
      entries,
      conciliated,
      missing,
    });

    if (isMountedRef.current) {
      onResult(conciliationResultEntity);
    }
  };

  const handleCheckboxChange = (rowIndex: number, checked: boolean) => {
    rowCheckboxOnChange(rowIndex, checked);
  };

  const handleConciliateButtonClick = (
    row: number,
    index: number,
    conciliationRow: IConciliationEntryEntity,
  ) => {
    rowConciliationOnChange(row, index, conciliationRow);
  };

  const handleShowOnlyNotFoundToggleChange = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      onlyNotFound: value,
      onlyConciliated: false,
    }));

    onlyNotFoundToggleOnChange(value);

    ReactTooltip.rebuild();

    return value;
  };

  const handleShowOnlyConciliatedToggleChange = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      onlyNotFound: false,
      onlyConciliated: value,
    }));

    onlyConciliatedToggleOnChange(value);

    ReactTooltip.rebuild();

    return value;
  };

  const selectAll = () => {
    onSelectAll();
  };

  const deselectAll = () => {
    onDeselectAll();
  };

  const handleSelectAllChange = (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      selectedAll: value,
    }));

    if (value) {
      selectAll();
    } else {
      deselectAll();
    }

    ReactTooltip.rebuild();
  };

  const checkedRows = conciliation.filter(con => con.checked);

  const conciliateSelectedDisabled =
    checkedRows.length === 0 ||
    checkedRows.every(row => row.foundAccounts.some(e => e.conciliated));

  const deConciliateSelectedDisabled =
    checkedRows.length === 0 ||
    checkedRows.every(row => row.foundAccounts.every(e => !e.conciliated));

  const handleConciliateSelectedClick = () => {
    onConciliateSelectedClick();
  };

  const handleDeconciliateSelectedClick = () => {
    onDeconciliateSelectedClick();
  };

  return (
    <Container>
      <div className="header">
        <div>
          <label title="Exibir somente não encontrados">
            <Toggle
              disabled={state.sending}
              value={state.onlyNotFound}
              onChange={handleShowOnlyNotFoundToggleChange}
            />{" "}
            <span>Exibir somente não encontrados</span>
          </label>
        </div>
        <div className="tgl">
          <label title="Exibir somente conciliados">
            <Toggle
              disabled={state.sending}
              value={state.onlyConciliated}
              onChange={handleShowOnlyConciliatedToggleChange}
            />{" "}
            <span>Exibir somente conciliados</span>
          </label>
        </div>
        <div className="dropdown">
          <SoulDropdown
            toggler={
              <button
                type="button"
                disabled={state.sending}
                className="default-button options-context"
              >
                <span>Opções</span>
                <FaCaretDown className="context-dropdown-icon" />
              </button>
            }
          >
            <button
              type="button"
              className="dropdown-item"
              disabled={conciliateSelectedDisabled || state.sending}
              onClick={handleConciliateSelectedClick}
            >
              <FaCheck />
              <span title="Conciliar selecionados">Conciliar selecionados</span>
            </button>
            <button
              type="button"
              className="dropdown-item"
              disabled={deConciliateSelectedDisabled || state.sending}
              onClick={handleDeconciliateSelectedClick}
            >
              <FaCheck />
              <span title="Desconciliar selecionados">
                Desconciliar selecionados
              </span>
            </button>
          </SoulDropdown>
        </div>
      </div>
      <div className="body">
        <div className="panel">
          <ConciliationRowHeader
            disabled={state.sending}
            selectAllChecked={state.selectedAll}
            onSelectAllChange={handleSelectAllChange}
          />
          <div className="wrapper">
            {conciliation.length === 0 && (
              <ConciliationRowEmpty className="row-empty">
                {state.onlyNotFound && (
                  <span className="not-found-msg">
                    Não há nenhum lançamento não encontrado.
                  </span>
                )}
                {state.onlyConciliated && (
                  <span className="not-found-msg">
                    Ainda não há nenhum lançamento conciliado.
                  </span>
                )}
                {!state.onlyConciliated && !state.onlyNotFound && (
                  <span className="not-found-msg">
                    Não há lançamentos a serem conciliados.
                  </span>
                )}
              </ConciliationRowEmpty>
            )}
            {conciliation.length > 0 &&
              conciliation.map((row, index) => {
                return (
                  <ConciliationRow
                    key={row.providedAccount.id}
                    rowIndex={index}
                    conciliation={row}
                    disabled={state.sending}
                    checkboxOnChange={handleCheckboxChange}
                    conciliateButtonOnClick={handleConciliateButtonClick}
                  />
                );
              })}
            <div className="spacer" />
          </div>
        </div>
      </div>
      <div className="footer">
        <button
          type="button"
          data-testid="btn-close"
          disabled={state.sending}
          className="form-button red-bkg back-button"
          onClick={handleBackButtonClick}
        >
          Voltar
        </button>
        <button
          type="button"
          data-testid="btn-finish"
          disabled={state.sending}
          className="form-button next-button green-bkg"
          onClick={handleNextButtonClick}
        >
          Próximo {state.sending && <FaSpinner className="spinner" />}
        </button>
      </div>
    </Container>
  );
}
