import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";
import { IToggleUserService } from "../../domain/usecases/toggleUserUseCase";

export class ToggleUserService implements IToggleUserService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async toggleUser(userId: string): Promise<IUserEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Users/${userId}`;

    const response = await this.authApi.delete<IUserEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new UserEntity(response);
  }
}
