import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IUserEntity } from "../../../reports/costCenterReport/domain/entities/userEntity";
import {
  ISearchUserContract,
  ISearchUserParams,
} from "../../domain/contracts/searchUserContract";

export class SearchUserService implements ISearchUserContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async searchUser({
    search = "",
    payloadOptions,
    showMetadata = false,
  }: ISearchUserParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      order: payloadOptions?.order || [{ column: 1, dir: "asc" }],
      search: payloadOptions?.search || { regex: false, value: search },
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: true,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const { REACT_APP_PARTNER_ID } = process.env;

    const url = `/Partners/${REACT_APP_PARTNER_ID}/Users`;

    const response = await this.authApi.get<
      IServerSideResponseModel<IUserEntity[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(user => {
        return new RelationshipFilterOption({
          label: user.name,
          rawValue: user.id,
          metadata: showMetadata ? user : undefined,
        });
      }),
    });
  }
}
