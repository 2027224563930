import { useCallback, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiService } from "../../../../core/data/services/apiService";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { ICompanyGroupEntity } from "../../../domain/entities/companyGroupEntity";
import {
  makeCompanyGroup,
  MakeCompanyGroup,
} from "../../../main/makeCompanyGroup";
import { useCompanyGroupGrid } from "../../hooks/useCompanyGroupGrid";
import { CompanyGroupFormModal } from "../CompanyGroupFormModal";
import { Container } from "./styles";

interface CompanyGroupPageProps {
  useCompanyGroup: MakeCompanyGroup;
}

function CompanyGroupPage({ useCompanyGroup }: CompanyGroupPageProps) {
  const table = useRef<ISimpleTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [isCompanyGroupModalOpen, setIsCompanyGroupModalOpen] =
    useState<boolean>(false);
  const [currentCompanyGroupId, setCurrentCompanyGroupId] = useState("");

  const openCompanyGroupFormModal = useCallback((currentId = "") => {
    setCurrentCompanyGroupId(currentId);
    setIsCompanyGroupModalOpen(true);
  }, []);

  const closeCompanyGroupFormModal = useCallback(() => {
    setIsCompanyGroupModalOpen(false);
    setCurrentCompanyGroupId("");
    table.current?.reload();
  }, []);

  const { listCompanyGroups, toggleCompanyGroup } = useCompanyGroup;

  const dialog = useSoulDialog();

  const { listCompanyGroups: listLocalUserCompanyGroups } =
    useCurrentCompanyGroup();

  const toggle = useCallback(
    async companyGroupId => {
      try {
        const { active } = await toggleCompanyGroup(companyGroupId);
        const text = active ? "ativado" : "inativado";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Grupo de empresa ${text} com sucesso!`,
        });

        table.current?.reload();
        listLocalUserCompanyGroups();
      } catch {
        dialog.close();
      }
    },
    [dialog, listLocalUserCompanyGroups, toggleCompanyGroup],
  );

  const { columns } = useCompanyGroupGrid({
    openEditModal: openCompanyGroupFormModal,
    toggle,
  });
  const { generatePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IResponseEntity<ICompanyGroupEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }
      setLoading(true);

      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
      const companyGroupList = await listCompanyGroups(payload);
      if (!companyGroupList?.data?.length) {
        setData(undefined);
      } else {
        setData(companyGroupList);
      }
      setLoading(false);

      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    },
    [columns, generatePayload, listCompanyGroups, mountedRef],
  );

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />

          <button
            type="button"
            className="default-button"
            id="btn-add"
            onClick={() => openCompanyGroupFormModal()}
          >
            <FaPlus /> Adicionar Grupo de empresa
          </button>

          <CompanyGroupFormModal
            currentId={currentCompanyGroupId}
            isOpen={isCompanyGroupModalOpen}
            useCompanyGroup={useCompanyGroup}
            onRequestClose={closeCompanyGroupFormModal}
          />
        </header>

        <article className="no-padding">
          <SimpleTable<ICompanyGroupEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface CompanyGroupPageFactoryProps {
  api: IApiService;
}

export function CompanyGroupPageFactory({ api }: CompanyGroupPageFactoryProps) {
  return <CompanyGroupPage useCompanyGroup={makeCompanyGroup(api)} />;
}
