export interface IProjectTransactionInputModel {
  id: string;
  companyGroupId: string;
  projectOriginId: string;
  projectDestinationId: string;
  transactionDate: string;
  value: number;
  description: string;
  active: boolean;
}

export class ProjectTransactionInputModel
  implements IProjectTransactionInputModel
{
  id = "";
  companyGroupId = "";
  projectOriginId = "";
  projectDestinationId = "";
  transactionDate = "";
  value = 0;
  description = "";
  active = true;

  constructor(params?: Partial<IProjectTransactionInputModel>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IProjectTransactionInputModel>) {
    return new ProjectTransactionInputModel(params);
  }
}
