import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IBulkApproveService {
  bulkApprove(costCenterReportIds: string[]): Promise<ICostCenterReportEntity>;
}

export interface IBulkApproveUseCase {
  bulkApprove(costCenterReportIds: string[]): Promise<ICostCenterReportEntity>;
}

export class BulkApproveUseCase implements IBulkApproveUseCase {
  constructor(private approveService: IBulkApproveService) {}

  bulkApprove(costCenterReportIds: string[]): Promise<ICostCenterReportEntity> {
    return this.approveService.bulkApprove(costCenterReportIds);
  }
}
