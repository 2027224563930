import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";

interface InputCurrencyProps {
  id: string;
  "data-testid": string;
  className?: string;
  value?: number;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function InputCurrencyInner(
  {
    id = "",
    "data-testid": dataTestId = "",
    className = "",
    value = 0,
    placeholder = "",
    disabled = false,
    onChange,
  }: InputCurrencyProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) {
  const [numValue, setNumValue] = useState(value);

  const formatNumber = useCallback((numVal: number) => {
    const formatedNumber = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numVal);

    return formatedNumber;
  }, []);

  const handleOnChange = useCallback(
    ({ target: { value: _value } }: ChangeEvent<HTMLInputElement>) => {
      const strVal = _value.replace(/\D/g, "");
      const numVal = Number(strVal) / 100;

      setNumValue(numVal);
      onChange?.({
        target: { value: numVal },
      } as unknown as ChangeEvent<HTMLInputElement>);
    },
    [onChange],
  );

  const handleOnFocus = useCallback(
    event => event.target.setSelectionRange(0, event.target.value.length),
    [],
  );

  useEffect(() => {
    const ref = forwardedRef as MutableRefObject<HTMLInputElement>;
    ref?.current?.setSelectionRange(0, ref?.current?.value.length);
  }, [forwardedRef]);

  const formatedValue = formatNumber(numValue);

  return (
    <input
      ref={forwardedRef}
      id={id}
      data-testid={dataTestId}
      className={className}
      type="text"
      value={formatedValue}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
    />
  );
}

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  InputCurrencyInner,
);

InputCurrency.defaultProps = {
  id: "",
  className: "",
  value: 0,
  placeholder: "",
  disabled: false,
};
