import { DataTableSelectionChangeParams } from "primereact/datatable";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { useMemo } from "react";
import { ClientSoulTable } from "../../../../../../core/presentation/components/ClientSoulTable";
import { useValidationStepGrid } from "../../hooks/useValidationStepGrid";
import { ISolicitImportEntity } from "../../../domain/entities/solicitImportEntity";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";

interface IValidationGridProps {
  selection: ISolicitImportEntity[];
  handleFormsRemoval(formIds: string[]): boolean;
  handleOpenAttachmentModal(formIndex: number): void;
  fields: FieldArrayWithId<ISolicitImportForm, "imports", "formId">[];
  handleSelectionChange(event: DataTableSelectionChangeParams): void;
}

export function ValidationGrid(props: IValidationGridProps) {
  const {
    fields,
    selection,
    handleFormsRemoval,
    handleSelectionChange,
    handleOpenAttachmentModal,
  } = props;

  const {
    handleRowToggle,
    state: { showInvalidOnly, expandedRows },
  } = useSolicitImportPage();

  const { getFieldState } = useFormContext<ISolicitImportForm>();

  const { columns, handleRowExpansionTemplate, handleRowClassName } =
    useValidationStepGrid({
      handleFormsRemoval,
      handleOpenAttachmentModal,
    });

  const tableData = useMemo(() => {
    if (showInvalidOnly) {
      return fields.filter((_, index) => {
        const fieldState = getFieldState(`imports.${index}`);
        return !!fieldState.error;
      });
    }
    return fields;
  }, [showInvalidOnly, fields, getFieldState]);

  return (
    <ClientSoulTable
      rowHover
      selectable
      dataKey="id"
      data={tableData}
      columns={columns}
      selection={selection}
      selectionMode="checkbox"
      selectionAutoFocus={false}
      expandedRows={expandedRows}
      onRowToggle={handleRowToggle}
      rowsPerPageOptions={[5, 10, 50]}
      rowClassName={handleRowClassName}
      onSelectionChange={handleSelectionChange}
      emptyMessage="Nenhum registro encontrado"
      rowExpansionTemplate={handleRowExpansionTemplate}
    />
  );
}
