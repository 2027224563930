import styled from "styled-components";
import { EAccountPayableStatus } from "../../../domain/entities/accountPayableListItemEntity";

interface BadgeStatusProps {
  status: EAccountPayableStatus;
  pill?: boolean;
}

export const BadgeStatus = styled.div<BadgeStatusProps>`
  --open: #ff6f00;
  --paid: #2dce89;
  --canceled: #dcd6d6;

  width: 100%;
  max-width: 5.75rem;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${props => {
    if (props.status === EAccountPayableStatus.Open) {
      return "var(--open)";
    }

    if (props.status === EAccountPayableStatus.Paid) {
      return "var(--paid)";
    }

    return "var(--canceled)";
  }};

  ${props => {
    if (props.status !== EAccountPayableStatus.Canceled) {
      return `color: white;`;
    }

    return "color: #495057 !important;";
  }}

  padding: 0.12em 1em;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5em;
`;
