import { IPaymentAccountTransactionEntity } from "../entities/paymentAccountTransactionEntity";

export interface ITogglePaymentAccountTransactionService {
  togglePaymentAccountTransaction(
    paymentAccountTransactionId: string,
  ): Promise<IPaymentAccountTransactionEntity>;
}

export interface ITogglePaymentAccountTransactionUseCase {
  togglePaymentAccountTransaction(
    paymentAccountTransactionId: string,
  ): Promise<IPaymentAccountTransactionEntity>;
}

export class TogglePaymentAccountTransactionUseCase
  implements ITogglePaymentAccountTransactionUseCase
{
  constructor(
    private togglePaymentAccountTransactionService: ITogglePaymentAccountTransactionService,
  ) {}

  togglePaymentAccountTransaction(
    paymentAccountTransactionId: string,
  ): Promise<IPaymentAccountTransactionEntity> {
    return this.togglePaymentAccountTransactionService.togglePaymentAccountTransaction(
      paymentAccountTransactionId,
    );
  }
}
