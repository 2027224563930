export enum EDebtImportDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export enum EDebtImportStatus {
  Open = 1,
  Paid = 2,
  Canceled = 3,
}

export enum EReturnStatus {
  NotReturned = 1,
  PartiallyReturned = 2,
  FullyReturned = 3,
}

export enum EDebtImportPaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
}

export enum EDebtImportSteps {
  FILE,
  VALIDATION,
  FINAL,
}

export enum EProviderDocumentType {
  Other = 0,
  CPF = 1,
  CNPJ = 2,
}
