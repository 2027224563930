import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ISearchCompanyContract } from "../../domain/contracts/searchCompanyContract";
import { ISearchParams } from "../../domain/entities/searchParams";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface ICompanyResponse {
  id: string;
  cnpj: string;
  assumedName: string;
  corporationName: string;
}

export class SearchCompanyService implements ISearchCompanyContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCompany({
    search = "",
    activesOnly,
    companyGroupId,
    payloadOptions,
    showMetadata = false,
  }: ISearchParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      order: payloadOptions?.order || [{ column: 1, dir: "asc" }],
      search: payloadOptions?.search || {
        value: search,
        regex: false,
      },
      columns: payloadOptions?.columns || [
        {
          data: "cnpj",
          name: "cnpj",
          orderable: true,
          searchable: true,
          search: { regex: false, value: "" },
        },
        {
          data: "assumedName",
          name: "assumedName",
          orderable: true,
          searchable: true,
          search: { regex: false, value: "" },
        },
        {
          data: "corporationName",
          name: "corporationName",
          orderable: true,
          searchable: true,
          search: { regex: false, value: "" },
        },
        {
          data: "hasProject",
          name: "",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/Companies`;

    if (activesOnly) {
      url += `/Actives`;
    }

    const response = await this.api.get<
      IServerSideResponseModel<ICompanyResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    let companies = response.data.map(company => {
      return new RelationshipFilterOption({
        rawValue: company.id,
        label: company.assumedName,
        metadata: showMetadata ? company : undefined,
      });
    });

    const pcgIdx = companies.findIndex(c =>
      c.label.toUpperCase().includes("IPECEGE"),
    );

    const pecege = companies.splice(pcgIdx, 1) ?? [];

    const fealqIdx = companies.findIndex(c =>
      c.label.toUpperCase().includes("FEALQ"),
    );

    const fealq = companies.splice(fealqIdx, 1) ?? [];

    companies = [
      ...pecege,
      ...fealq,
      ...companies.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }

        if (a.label < b.label) {
          return -1;
        }

        return 0;
      }),
    ];

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: companies,
    });
  }
}
