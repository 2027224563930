import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IPaymentAccountTransactionEntity } from "../../domain/entities/paymentAccountTransactionEntity";
import { IListPaymentAccountTransactionsService } from "../../domain/usecases/listPaymentAccountTransactionsUseCase";

export class ListPaymentAccountTransactionsService
  implements IListPaymentAccountTransactionsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listPaymentAccountTransactions(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IServerSideResponseModel<IPaymentAccountTransactionEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/PaymentAccountTransactions?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IPaymentAccountTransactionEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
