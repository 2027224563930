import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IClassificationAssessmentEntity } from "../../../classificationAssessment/domain/entities/classificationAssessment";

export interface ISearchClassificationAssessmentsService {
  searchClassificationAssessments(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>>;
}

export interface ISearchClassificationAssessmentsUseCase {
  searchClassificationAssessments(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>>;
}

export class SearchClassificationAssessmentsUseCase
  implements ISearchClassificationAssessmentsUseCase
{
  constructor(
    private searchClassificationAsssesmentsService: ISearchClassificationAssessmentsService,
  ) {}

  searchClassificationAssessments(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ) {
    return this.searchClassificationAsssesmentsService.searchClassificationAssessments(
      search,
      companyGroupId,
      active,
    );
  }
}
