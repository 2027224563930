import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICompetencyEntity } from "../../domain/entities/competencyEntity";
import { IToggleCompetencyService } from "../../domain/usecases/toggleCompetencyUseCase";

export class ToggleCompetencyService implements IToggleCompetencyService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async toggleCompetency(id: string): Promise<ICompetencyEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/Competencies/${id}`;

    const response = await this.api.delete<ICompetencyEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
