import styled from "styled-components";

export const Container = styled.div`
  width: 800px;

  .card-header-border {
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }

    h5 {
      font-size: 0.8125rem;
      color: #5e72e4;
      font-weight: 600;
    }

    & + section {
      margin-top: 1.5rem;
    }
  }

  .custom-margin {
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .no-padding {
    padding: 0;
  }

  div.react-modal-body {
    padding-top: unset;

    .required-warning {
      gap: 1rem;
      display: flex;
      align-items: center;

      flex: 1;

      color: #a23b3b;
      font-size: 0.875rem;

      border-radius: 0.375rem;
      background-color: #ffcdcd;
      border: 0.0625rem solid #ff9c9c;

      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
    }
  }
`;
