import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IRemoveBulkTerminateAccountsContract } from "../../domain/contracts/removeBulkTerminateAccountsContract";

export class RemoveBulkTerminateAccountsService
  implements IRemoveBulkTerminateAccountsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async removeBulkTerminateAccounts(
    accountPayableIds: string[],
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/UndoTermination`;

    const payload = { accountPayableIds };

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
