import { ReactNode } from "react";
import { Container } from "./styles";
import { Toggle } from "../../../../../../core/presentation/components/Toggle";
import { useSolicitImportPage } from "../../hooks/useSolicitImportPage";

interface IStepsToolbarProps {
  children?: ReactNode;
}

export function StepsToolbar(props: IStepsToolbarProps) {
  const { children } = props;

  const { state, handleExpandAllChange, handleInvalidOnlyChange } =
    useSolicitImportPage();

  return (
    <Container>
      <div className="toggler-container">
        <label>
          <Toggle
            id="btn-toggle-invalid"
            value={state.showInvalidOnly}
            data-testid="btn-toggle-invalid"
            onChange={handleInvalidOnlyChange}
          />
          Exibir somente inconsistentes
        </label>
        <label>
          <Toggle
            id="btn-toggle-expand-all"
            data-testid="btn-toggle-expand-all"
            onChange={handleExpandAllChange}
          />
          Expandir todos
        </label>
      </div>
      {children}
    </Container>
  );
}
