import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IClassificationAssessmentEntity } from "../../domain/entities/classificationAssessment";
import { IClassificationAssessmentFormEntity } from "../../domain/entities/classificationAssessmentFormEntity";
import { IGetClassificationAssessmentService } from "../../domain/usecases/getClassificationAssessmentUseCase";

export class GetClassificationAssessmentService
  implements IGetClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getClassificationAssessment(
    classificationAssessmentId: string,
  ): Promise<IClassificationAssessmentFormEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/ClassificationAssessments/${classificationAssessmentId}`;

    const response = await this.api.get<IClassificationAssessmentEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const classificationAssessmentFormEntity: IClassificationAssessmentFormEntity =
      {
        id: response.id,
        name: response.name,
        type: response.type,
        active: response.active,
        companyGroupId: response.companyGroupId,
        companyGroupName: response?.companyGroupName || "",
        paymentType: response.paymentType,
        macroCategory: {
          rawValue: response.macroBoxId || "",
          label: response.macroBoxName,
        },
      };

    return classificationAssessmentFormEntity;
  }
}
