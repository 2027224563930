import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetAccountPayableByIdContract } from "../../domain/contracts/getAccountPayableByIdContract";
import { IAccountPayableAssessmentEntity } from "../../domain/entities/accountPayableAssessmentEntity";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntitty";
import {
  AccountPayableFormEntity,
  IAccountPayableFormEntity,
} from "../../domain/entities/accountPayableFormEntity";
import {
  IAccountPayableAssessmentGetModel,
  IAccountPayableGetModel,
} from "../models/accountPayableModel";

export class GetAccountPayableByIdService
  implements IGetAccountPayableByIdContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getAccountPayableById(
    accountPayableId: string,
  ): Promise<IAccountPayableFormEntity> {
    const accntPayableData = await this.getAccountPayableData(accountPayableId);

    const assessmentData = await this.getAccountPayableAssessments(
      accountPayableId,
    );

    const attachmentData = await this.getAccountPayableAttachments(
      accountPayableId,
    );

    const attBillet = attachmentData.find(
      att => att.type === EAttachmentType.Billet,
    );

    const hasBillet = !!attBillet;

    const hasProject = !!accntPayableData.projectId;
    const isUsp = !!accntPayableData.classificationUspId;

    let competency: ITypeaheadOption | null = null;
    let project: ITypeaheadOption | null = null;
    let classificationUsp: ITypeaheadOption | null = null;

    if (hasProject) {
      competency = {
        label: accntPayableData.competencyName as string,
        rawValue: accntPayableData.competencyId as string,
        metadata: {
          isUsp,
        },
      };

      project = {
        label: accntPayableData.projectName as string,
        rawValue: accntPayableData.projectId as string,
      };
    }

    if (isUsp) {
      classificationUsp = {
        label: accntPayableData.classificationUspName as string,
        rawValue: accntPayableData.classificationUspId as string,
      };
    }

    const dtIssueDate = new Date(accntPayableData.issueDate);
    const dtPayUntil = new Date(accntPayableData.payUntil);

    const issueAsPaid = !!accntPayableData.terminationDate;

    const dtTerminationDate = new Date(accntPayableData.terminationDate);

    const accountPayableFormData: IAccountPayableFormEntity =
      new AccountPayableFormEntity({
        id: accntPayableData.id,
        documentNumber: accntPayableData.documentNumber,
        description: accntPayableData?.description || "",
        company: {
          label: accntPayableData.companyName,
          rawValue: accntPayableData.companyId,
          metadata: {
            hasProject,
          },
        },
        competency,
        provider: {
          label: accntPayableData.providerName,
          rawValue: accntPayableData.providerId,
          metadata: {
            documentType: accntPayableData.providerDocumentType,
          },
        },
        value: accntPayableData.value,
        paymentMethod: {
          key: Number(accntPayableData.paymentMethod),
          value: accntPayableData.paymentMethodDescription,
        },
        documentStatus: {
          key: Number(accntPayableData.documentStatus),
          value: accntPayableData.documentStatusDescription,
        },
        issueDate: format(dtIssueDate, "dd/MM/yyyy"),
        payUntil: format(dtPayUntil, "dd/MM/yyyy"),
        issueAsPaid,
        terminationDate: issueAsPaid
          ? format(dtTerminationDate, "dd/MM/yyyy")
          : null,
        observation: accntPayableData?.observation || "",
        project,
        classificationUsp,
        classificationAccount: {
          label: accntPayableData.classificationAccountName,
          rawValue: accntPayableData.classificationAccountId,
        },
        paymentAccount: {
          label: accntPayableData.paymentAccountName,
          rawValue: accntPayableData.paymentAccountId,
        },
        paymentRequestId: accntPayableData.paymentRequestId,
        assessments: assessmentData,
        attachments: attachmentData,
        barcode: hasBillet ? attBillet.barcode || null : null,
        isTax: accntPayableData.isTax || false,
        returnStatus: accntPayableData.returnStatus,
        status: accntPayableData.status,
        fuspPurchaseOrderId:
          accntPayableData.fuspPurchaseOrderId?.toString() || "",
        fuspReimbursement: accntPayableData.fuspReimbursement || false,
        correspondingProviderName:
          accntPayableData?.correspondingProviderName || "",
      });

    if (accntPayableData?.pecegePayFeeId) {
      accountPayableFormData.pecegePayFeeId = accntPayableData.pecegePayFeeId;
    }

    return accountPayableFormData;
  }

  private async getAccountPayableData(accountPayableId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/${accountPayableId}`;

    const response = this.api.get<IAccountPayableGetModel>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }

  private async getAccountPayableAssessments(accountPayableId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/${accountPayableId}/Assessments`;

    const response = await this.api.get<IAccountPayableAssessmentGetModel[]>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response.map<IAccountPayableAssessmentEntity>(ass => {
      return {
        id: ass.id,
        observation: ass?.observation || "",
        accountPayableId: ass.accountPayableId,
        value: new BigNumber(ass.value).toJSON(),
        percentage: new BigNumber(ass.percentage).toJSON(),
        classificationAssessment: {
          label: ass.classificationAssessmentName,
          rawValue: ass.classificationAssessmentId,
        },
        costCenter: {
          label: ass.costCenterName,
          rawValue: ass.costCenterId,
        },
      };
    });
  }

  private async getAccountPayableAttachments(accountPayableId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsPayable/${accountPayableId}/Attachments`;

    return this.api.get<IAccountPayableAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
