import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import { StatusCodes } from "http-status-codes";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IExportSheetFuspContract } from "../../domain/contracts/exportSheetFuspContract";
import { IExportSheetFuspErrorEntity } from "../../domain/entities/exportSheetFuspErrorEntity";
import { IFuspExportationEntity } from "../../domain/entities/fuspExportationEntity";
import { IFuspExportationPayloadModel } from "../models/fuspExportationPayloadModel";

export class ExportSheetFuspService implements IExportSheetFuspContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async exportSheetFusp(
    exportSheeFuspEntity: IFuspExportationEntity,
  ): Promise<void> {
    const url = `/Fusp/AccountPayableExportation`;
    const userEntity = this.getUserLocalService.get();

    const dtInitialDate = parse(
      exportSheeFuspEntity.startDate,
      "ddMMyyyy HH:mm:ss",
      new Date(),
    );

    const dtFinalDate = parse(
      exportSheeFuspEntity.endDate,
      "ddMMyyyy HH:mm:ss",
      new Date(),
    );

    const initialDate = format(dtInitialDate, "yyyy-MM-dd HH:mm:ss");
    const finalDate = format(dtFinalDate, "yyyy-MM-dd HH:mm:ss");

    const payload: IFuspExportationPayloadModel = {
      initialDate,
      finalDate,
    };

    // TODO retirar marcador opcional deste método
    // após corrigir todos os mocks dos testes
    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    // se response vier status 204 No Content,entao para ficar semantico
    // na camada de exibicao rejeitamos a promise com o response da api
    if (response?.status === StatusCodes.NO_CONTENT) {
      const error: IExportSheetFuspErrorEntity = {
        noDataToExport: true,
      };

      await Promise.reject(error);
      return;
    }

    if (response) {
      this.downloadFile(response);
    }
  }

  private downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
