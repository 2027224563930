import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUploadAttachmentsContract } from "../../domain/contracts/uploadAttachmentContract";
import { IAccountReceivableAttachmentEntity } from "../../domain/entities/accountReceivableAttachmentEntity";
import { RequestProgressCallback } from "../../domain/entities/requestProgressCallbackType";

interface IProgressEvent {
  loaded: number;
  total: number;
}

export class UploadAttachmentService implements IUploadAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async uploadAttachments(
    accountReceivableId: string,
    attachmentList: IAccountReceivableAttachmentEntity[],
    uploadProgressCallback: RequestProgressCallback,
  ): Promise<void> {
    const formData = new FormData();

    for (let index = 0; index < attachmentList.length; index += 1) {
      const attachment = attachmentList[index];

      formData.append(`Uploads[${index}].Name`, attachment.name.toString());
      formData.append(`Uploads[${index}].Active`, attachment.active.toString());
      formData.append(`Uploads[${index}].Type`, attachment.type.toString());

      if (attachment.file) {
        formData.append(`Uploads[${index}].File`, attachment.file);
      }

      if (attachment.barcode) {
        formData.append(`Uploads[${index}].barcode`, attachment.barcode);
      }

      if (attachment.storageFileId) {
        formData.append(
          `Uploads[${index}].storageFileId`,
          attachment.storageFileId,
        );
      }
    }

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsReceivable/${accountReceivableId}/Attachments`;

    await this.api.post(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
      onUploadProgress(progressEvent: IProgressEvent) {
        const { loaded, total } = progressEvent;
        uploadProgressCallback(loaded, total);
      },
    });
  }
}
