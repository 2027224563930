import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPaymentAccountEntity } from "../../../transactions/paymentAccountTransactions/domain/entities/paymentAccountEntity";
import { IGetPaymentAccountService } from "../../domain/usecases/getPaymentAccountUseCase";

export class GetPaymentAccountService implements IGetPaymentAccountService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPaymentAccount(id: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/PaymentAccounts/${id}`;

    return this.api.get<IPaymentAccountEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
