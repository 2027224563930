import { Link } from "react-router-dom";
import forbiddenImg from "../../../../assets/fobidden.svg";
import { Page } from "../Page/styles";
import { Container } from "./styles";

export function ForbiddenPage() {
  return (
    <Container>
      <div className="admin-header bg-gradient-soul" />
      <div className="page-content">
        <Page>
          <header>403 Acesso proibido</header>

          <article>
            <img src={forbiddenImg} alt="404" />

            <br />

            <p>
              Desculpe, mas parece que você não tem direitos para ver esta
              página.
            </p>

            <p>
              Reporte seu problema para a equipe do Soul e mencione esta
              mensagem de erro e o que você estava tentando fazer quando isso
              aconteceu.
            </p>

            <br />

            <div className="action-buttons">
              <Link className="default-button back-button" to="/login">
                Sair
              </Link>
            </div>
          </article>
        </Page>
      </div>
    </Container>
  );
}
