import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetClassificationAccountService } from "../data/services/getClassificationAccountService";
import { ListClassificationAccountsService } from "../data/services/listClassificationAccountsService";
import { SaveClassificationAccountService } from "../data/services/saveClassificationAccountService";
import { ToggleClassificationAccountService } from "../data/services/toggleClassificationAccountService";
import { UpdateClassificationAccountService } from "../data/services/updateClassificationAccountService";
import { IGetClassificationAccountContract } from "../domain/contracts/getClassificationAccountContract";
import { IListClassificationAccountsContract } from "../domain/contracts/listClassificationAccountsContract";
import { ISaveClassificationAccountContract } from "../domain/contracts/saveClassificationAccountContract";
import { IToggleClassificationAccountContract } from "../domain/contracts/toggleClassificationAccountContract";
import { IUpdateClassificationAccountContract } from "../domain/contracts/updateClassificationAccountContract";

export type MakeClassificationAccounts = IListClassificationAccountsContract &
  IGetClassificationAccountContract &
  ISaveClassificationAccountContract &
  IUpdateClassificationAccountContract &
  IToggleClassificationAccountContract;

export function makeClassificationAccounts(
  api: IApiService,
): MakeClassificationAccounts {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listClassificationAccountsService =
    new ListClassificationAccountsService(getUserLocalService, api);

  const getClassificationAccountsService = new GetClassificationAccountService(
    getUserLocalService,
    api,
  );
  const saveClassificationAccountsService =
    new SaveClassificationAccountService(getUserLocalService, api);

  const updateClassificationAccountsService =
    new UpdateClassificationAccountService(getUserLocalService, api);

  const toggleClassificationAccountsService =
    new ToggleClassificationAccountService(getUserLocalService, api);

  return {
    listClassificationAccounts(payload, companyGroupId, actives) {
      return listClassificationAccountsService.listClassificationAccounts(
        payload,
        companyGroupId,
        actives,
      );
    },
    getClassificationAccount(classificationAccountId) {
      return getClassificationAccountsService.getClassificationAccount(
        classificationAccountId,
      );
    },
    saveClassificationAccount(payload) {
      return saveClassificationAccountsService.saveClassificationAccount(
        payload,
      );
    },
    updateClassificationAccount(payload) {
      return updateClassificationAccountsService.updateClassificationAccount(
        payload,
      );
    },
    toggleClassificationAccount(classificationAccountId) {
      return toggleClassificationAccountsService.toggleClassificationAccount(
        classificationAccountId,
      );
    },
  };
}
