import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IGetStoredAccountsReceivablePanoramaIdContract } from "../../domain/contracts/getStoredAccountsReceivablePanoramaIdContract";

export class GetStoredAccountsReceivablePanoramaIdService
  implements IGetStoredAccountsReceivablePanoramaIdContract
{
  getStoredAccountsReceivablePanoramaId(): string {
    const key = ELocalStorageKeys.AccountsReceivablePanoramaId;
    const value = localStorage.getItem(key);

    if (value) {
      return Stringified.parse(value);
    }

    return "";
  }
}
