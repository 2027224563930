import { ICompanyEntity } from "../entities/companyEntity";

export interface IToggleCompanyService {
  toggleCompany(companyId: string): Promise<ICompanyEntity>;
}

export interface IToggleCompanyUseCase {
  toggleCompany(companyId: string): Promise<ICompanyEntity>;
}

export class ToggleCompanyUseCase implements IToggleCompanyUseCase {
  constructor(private toggleCompanyService: IToggleCompanyService) {}

  toggleCompany(companyId: string) {
    return this.toggleCompanyService.toggleCompany(companyId);
  }
}
