import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";

type InputDateProps = InputHTMLAttributes<HTMLInputElement>;

export const InputDate = forwardRef(function InputDate(
  props: InputDateProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { value, onChange, ...rest } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [cursor, setCursor] = useState<number | null>(null);

  const formatValue = (strValue: string) => {
    return strValue
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1");
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const currentCaretIndex = target.selectionStart || 0;

    target.value = formatValue(target.value);

    const isForwardSlash = target.value.at(currentCaretIndex - 1) === "/";

    setCursor(isForwardSlash ? currentCaretIndex + 1 : currentCaretIndex);

    const newEvt: ChangeEvent<HTMLInputElement> = {
      ...event,
      target,
    };

    onChange?.(newEvt);
  };

  const refCallback = (originalRef: HTMLInputElement | null) => {
    if (ref instanceof Function) {
      ref(originalRef);
    }

    inputRef.current = originalRef;
  };

  useEffect(() => {
    inputRef.current?.setSelectionRange(cursor, cursor);
  }, [cursor, value]);

  return (
    <input ref={refCallback} value={value} onChange={handleChange} {...rest} />
  );
});
