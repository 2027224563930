import BigNumber from "bignumber.js";
import { Control, Controller } from "react-hook-form";
import { InputBalance } from "../../../../../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../../../../../core/presentation/components/InvalidFeedback";
import { useDebtImportPage } from "../../../../hooks/useDebtImportPage";
import { IEditAssessmentForm } from "../../../EditAssessmentFormModal";
import { FieldWrapper } from "../../../FieldWrapper";

interface IAssessmentValueFieldProps {
  onChange(value: number): void;
  control: Control<IEditAssessmentForm>;
}

export function AssessmentValueField(props: IAssessmentValueFieldProps) {
  const { control, onChange } = props;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      control={control}
      name="value"
      rules={{
        required: true,
        validate: {
          min: value => new BigNumber(value).isGreaterThanOrEqualTo(0.01),
        },
      }}
      render={({ field, fieldState }) => {
        const handleChange = (value: number | null) => {
          onChange(value || 0);
          field.onChange(value?.toString() || "0");
          field.onBlur();
        };
        return (
          <label className="col-6 form-control">
            <span>Valor (R$)</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputBalance
                {...field}
                id="txt-value"
                placeholder="Valor"
                data-testid="txt-value"
                className="numeric-field"
                value={Number(field.value)}
                onChange={handleChange}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={
                fieldState?.error?.type === "min" ||
                fieldState?.error?.type === "required"
              }
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
