import { format } from "date-fns";
import { useCallback } from "react";
import { ICostCenterEntity } from "../../domain/entities/costCenterEntity";
import { CostCenterFormEntity } from "../../domain/entities/costCenterFormEntity";

export function useCostCenterForm() {
  const formatDate = useCallback((date: string) => {
    if (!date) return "";
    return format(new Date(date), "ddMMyyyy");
  }, []);

  return useCallback(
    (costCenter: ICostCenterEntity) => {
      const costCenterForm = new CostCenterFormEntity({
        acronym: costCenter?.acronym ?? "",
        responsible: costCenter?.responsible ?? "",
        description: costCenter?.description ?? "",
        costCenterReplacedId: costCenter?.costCenterReplacedId ?? "",
        costCenterReplacedName: costCenter?.costCenterReplacedName ?? "",
        openingBalance: costCenter?.openingBalance ?? 0,
        lockReleasesUntil: costCenter?.lockReleasesUntil
          ? formatDate(costCenter.lockReleasesUntil)
          : "",
        isDefaultForAccountReceivableImportation:
          costCenter?.isDefaultForAccountReceivableImportation ?? false,
      });

      return {
        costCenterForm,
      };
    },
    [formatDate],
  );
}
