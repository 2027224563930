import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPecegePayProductTypes } from "../../domain/contracts/getPecegePayProductTypesContract";
import { IPecegePayProductTypeEntity } from "../../domain/entities/pecegePayProductTypeEntity";

export class GetPecegePayProductTypesService
  implements IGetPecegePayProductTypes
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPecegePayProductTypes() {
    const userEntity = this.getUserLocalService.get();

    const url = `/PecegePay/ConsumerSystems`;

    return this.api.get<IPecegePayProductTypeEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
