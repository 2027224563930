import { ContextMenu } from "primereact/contextmenu";
import { RefObject } from "react";
import { FaPaperclip } from "react-icons/fa";

interface IProjectTransactionsContextMenuProps {
  menuRef: RefObject<ContextMenu>;
  onOpenAttachmentsModal(): void;
}

export function ProjectTransactionsContextMenu(
  props: IProjectTransactionsContextMenuProps,
) {
  const { menuRef, onOpenAttachmentsModal } = props;

  const model = [
    {
      label: "Alterar anexos",
      command: onOpenAttachmentsModal,
      icon: (
        <FaPaperclip
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    },
  ];

  return <ContextMenu model={model} ref={menuRef} style={{ width: "220px" }} />;
}
