import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ILinkedCompanyGroupEntity } from "../entities/linkedCompanyGroupEntity";

export interface IListCompanyGroupsService {
  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>>;
}

export interface IListCompanyGroupsUseCase {
  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>>;
}

export class ListCompanyGroupsUseCase implements IListCompanyGroupsUseCase {
  constructor(private listCompanyGroupsService: IListCompanyGroupsService) {}

  listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>> {
    return this.listCompanyGroupsService.listCompanyGroups(payload);
  }
}
