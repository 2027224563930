import { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountReceivableAttachmentEntity } from "../../domain/entities/accountReceivableAttachmentEntity";
import { useAccountsReceivablePage } from "./useAccountsReceivablePage";

interface IAttachmentModalState {
  loading: boolean;
  isSubmiting: boolean;
  attachments: IAccountReceivableAttachmentEntity[];
  search: string;
  uploadModal: {
    isOpen: boolean;
    loaded: number;
    total: number;
  };
}

export function useAccountsReceivableAttachmentModal() {
  const { useAccountsReceivable, handleAttachmentModalClose, ...rest } =
    useAccountsReceivablePage();

  const { contextMenuData, attachmentModalOpen } = rest.state;

  const {
    getAttachments,
    getStorageFilebyId,
    uploadAttachments,
    listAttachmentTypes,
  } = useAccountsReceivable;

  const [state, setState] = useState<IAttachmentModalState>({
    loading: false,
    isSubmiting: false,
    attachments: [],
    search: "",
    uploadModal: {
      isOpen: false,
      loaded: 0,
      total: 0,
    },
  });

  /** Lida com o evento de click do botao fechar da modal */
  const handleCloseButtonOnClick = useCallback(() => {
    handleAttachmentModalClose();
  }, [handleAttachmentModalClose]);

  const mountedRef = useIsMounted();
  const dialog = useSoulDialog();

  /** Obtem a lista de arquivos anexados a conta a pagar selecionada */
  const fetchAttachments = useCallback(async () => {
    if (!contextMenuData) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const attachmentsResponse = await getAttachments(contextMenuData);

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        loading: false,
        attachments: attachmentsResponse,
      }));
    } catch {
      if (mountedRef.current) {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    } finally {
      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    }
  }, [contextMenuData, getAttachments, mountedRef]);

  /**
   * Lida com o evento de abertura da modal, responsavel
   * por chamar a funcao que obtem a lista de anexos
   */
  const handleAfterOpen = () => {
    fetchAttachments();
  };

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar os estados da modal
   */
  const handleAfterClose = () => {
    setState({
      loading: false,
      isSubmiting: false,
      attachments: [],
      search: "",
      uploadModal: {
        isOpen: false,
        loaded: 0,
        total: 0,
      },
    });
  };

  /**
   * Lida com o evento de progresso de upload dos anexos
   */
  const handleUploadProgress = (loaded: number, total: number) => {
    setState(prevState => ({
      ...prevState,
      uploadModal: {
        ...prevState.uploadModal,
        loaded,
        total,
      },
    }));
  };

  /**
   * Atualiza os anexos na api. Rewliza o post dos arquivos e
   * dos novos dados dos anexos que foram atualizados/removidos
   */
  const updateAttachments = async (
    accountReceivableId: string,
    attachmentList: IAccountReceivableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      isSubmiting: true,
      uploadModal: {
        ...prevState.uploadModal,
        isOpen: true,
      },
    }));

    try {
      await uploadAttachments(
        accountReceivableId,
        attachmentList,
        handleUploadProgress,
      );

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento de contas a pagar atualizado com sucesso.",
      });

      setState(prevState => ({
        ...prevState,
        isSubmiting: false,
        uploadModal: {
          ...prevState.uploadModal,
          isOpen: false,
        },
      }));

      handleAttachmentModalClose();
    } catch {
      setState(prevState => ({
        ...prevState,
        isSubmiting: false,
        uploadModal: {
          ...prevState.uploadModal,
          isOpen: false,
        },
      }));
    }
  };

  /** Lida como submit dos dados da modal */
  const handleSubmit = () => {
    const accountReceivableId = contextMenuData?.accountReceivableId;
    const attachmentList = state.attachments;

    if (!accountReceivableId) {
      return;
    }

    updateAttachments(accountReceivableId, attachmentList);
  };

  /**
   * LIda com o evento de troca do valor dos anexos emitido pela grid,
   * responsavel por atualizar a lista de anexos em memoria conforme as
   * edicoes realizadas pelo usuario
   */
  const handleAttachmentListChange = (
    attachments: IAccountReceivableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      attachments: [...attachments],
    }));
  };

  return {
    attachmentModalOpen,
    handleCloseButtonOnClick,
    handleAfterOpen,
    handleAfterClose,
    state,
    contextMenuData,
    handleSubmit,
    getStorageFilebyId,
    listAttachmentTypes,
    handleAttachmentListChange,
  };
}
