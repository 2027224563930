import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { ProgressSpinner } from "primereact/progressspinner";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { Container } from "./styles";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { IPaymentRequestFeeEntity } from "../../../domain/entities/paymentRequestFeeEntity";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";

interface IPaymentRequestFeeFormEntity {
  fees: IPaymentRequestFeeEntity[];
}

export interface IFeeFormOnSubmitParams {
  generateFeeRetention: boolean;
  fees: IPaymentRequestFeeEntity[];
}

interface IFeeFormModalProps {
  isOpen: boolean;
  onClose(): void;
  onSubmit(params: IFeeFormOnSubmitParams): void;
  listCompanyGroupActiveFees: MakePaymentRequestForm["listCompanyGroupActiveFees"];
}

export function FeeFormModal(props: IFeeFormModalProps) {
  const { isOpen, listCompanyGroupActiveFees, onClose, onSubmit } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dialog = useSoulDialog();

  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const { control, formState, handleSubmit, reset, watch } =
    useForm<IPaymentRequestFeeFormEntity>({
      defaultValues: {
        fees: [],
      },
    });

  const fees = watch("fees");

  const { isSubmitting, isValid } = formState;

  const onValid = async (formValues: IPaymentRequestFeeFormEntity) => {
    const isAllFeeZero = formValues.fees.every(fee => fee.value === 0);

    let generateFeeRetention = true;

    if (!isAllFeeZero) {
      const result = await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        showCancelButton: true,
        cancelButtonText: "Não, gerar depois",
        html: (
          <>
            Os impostos serão gerados em Contas a Pagar.
            <br />
            <br />
            Deseja prosseguir?
          </>
        ),
      });

      generateFeeRetention = result.isConfirmed;
    }

    const valuesWithoutZero = formValues.fees.filter(fee => {
      return fee.value !== 0;
    });

    onSubmit({
      generateFeeRetention,
      fees: valuesWithoutZero,
    });
  };

  const handleAfterOpen = async () => {
    setIsLoading(true);

    try {
      const feeList = await listCompanyGroupActiveFees(companyGroupId);
      reset({ fees: feeList });
    } catch {
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestClose = async () => {
    const result = await dialog.fire({
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      cancelButtonText: "Não, cancelar",
      html: (
        <>
          O fornecedor da solicitação está marcado para reter impostos.
          <br />
          <br />
          Se continuar, os impostos não serão registrados e só será possível
          realizar essa ação quando a Conta a Pagar for gerada.
          <br />
          <br />
          Deseja prosseguir?
        </>
      ),
    });

    if (result.isConfirmed) {
      const valuesWithoutZero = fees.filter(fee => {
        return fee.value !== 0;
      });

      onSubmit({
        fees: valuesWithoutZero,
        generateFeeRetention: false,
      });
    }
  };

  const handleAfterClose = () => {
    reset({ fees: [] });
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>Impostos</h4>
          <button
            type="button"
            id="btn-cross"
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={handleRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        {isLoading ? (
          <div className="loading-container">
            <ProgressSpinner strokeWidth="6" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onValid)}>
            <div className="react-modal-body pt-0">
              <div className="card">
                <div className="card-body">
                  Preencha o valor dos impostos contidos neste lançamento.
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Imposto</th>
                    <th>Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {fees.length ? (
                    fees.map((fee, index) => {
                      return (
                        <tr key={fee.feeId}>
                          <td>{fee.feeName}</td>
                          <td>
                            <Controller
                              control={control}
                              name={`fees.${index}.value`}
                              rules={{ required: true, min: 0 }}
                              render={({ field, fieldState }) => {
                                return (
                                  <>
                                    <InputBalance
                                      {...field}
                                      resetToZero
                                      placeholder="000,00"
                                      id="txt-openingBalance"
                                      data-testid="txt-openingBalance"
                                      className={
                                        fieldState?.error ? "isInvalid" : ""
                                      }
                                    />
                                    <InvalidFeedback
                                      message="Este campo é obrigatório"
                                      condition={
                                        fieldState?.error?.type === "required"
                                      }
                                    />
                                  </>
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="no-fee-row" colSpan={2}>
                        Nenhum imposto encontrado
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-close"
                data-testid="btn-close"
                className="form-button red-bkg"
                onClick={handleRequestClose}
              >
                Aprovar sem preencher
              </button>
              <button
                type="submit"
                id="btn-submit"
                disabled={isSubmitting}
                data-testid="btn-submit"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Confirmar{" "}
                {isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </form>
        )}
      </Container>
    </Modal>
  );
}
