import { DependencyList, useCallback, useMemo } from "react";

type UseClassNameConfig = Record<string, boolean> | (() => string);

export function useClassName(
  config: UseClassNameConfig,
  deps: DependencyList,
): string {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(config as () => string, [
    config,
    ...deps,
  ]);

  return useMemo(() => {
    if (typeof config === "function") {
      return memoizedCallback();
    }

    return Object.entries(config)
      .filter(([, value]) => value)
      .map(([k]) => k)
      .join(" ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, ...deps]);
}
