import { ParsePanoramaAngularService } from "../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../advTable/data/services/transformPanoramaAngularService";
import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { SearchClassificationAccountService } from "../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationAssessmentService } from "../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../core/data/services/searchCostCenterService";
import { SearchCustomerProviderByCorporationNameService } from "../../../core/data/services/searchCustomerProviderByCorporationNameService";
import { SearchCustomerProviderService } from "../../../core/data/services/searchCustomerProviderService";
import { SearchMacroCategoriesService } from "../../../core/data/services/searchMacroCategoriesService";
import { SearchPaymentAccountService } from "../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../core/data/services/searchProjectService";
import { SearchUserService } from "../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../core/data/services/setServerSideService";
import { ISearchClassificationAccountContract } from "../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationAssessmentContract } from "../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../core/domain/contracts/searchCostCenterContract";
import { ISearchCustomerProviderByCorporationNameContract } from "../../../core/domain/contracts/searchCustomerProviderByCorporationNameContract";
import { ISearchCustomerProviderContract } from "../../../core/domain/contracts/searchCustomerProviderContract";
import { ISearchMacroCategoriesContract } from "../../../core/domain/contracts/searchMacroCategoriesContract";
import { ISearchPaymentAccountContract } from "../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../core/domain/contracts/searchProjectContract";
import { ISearchUserContract } from "../../../core/domain/contracts/searchUserContract";
import { DeleteCostCenterReportPanoramaService } from "../data/services/deleteCostCenterReportPanoramaService";
import { ExportCostCenterReportService } from "../data/services/exportCostCenterReportService";
import { GetCostCenterReportService } from "../data/services/getcostCenterReportService";
import { GetStoredCostCenterReportPanoramaIdService } from "../data/services/getStoredCostCenterReportPanoramaIdService";
import { ListCostCenterReportPanoramasService } from "../data/services/listCostCenterReportPanoramaService";
import { ListCostCenterReportService } from "../data/services/listCostCenterReportService";
import { ListCostCentersService } from "../data/services/listCostCentersService";
import { SaveCostCenterReportPanoramaService } from "../data/services/saveCostCenterReportPanoramaService";
import { StoreCostCenterReportPanoamaIdService } from "../data/services/storeCostCenterReportPanoamaIdService";
import { ToggleCostCenterReportService } from "../data/services/toggleCostCenterReportService";
import { AdvGeneratePayloadExportUseCase } from "../domain/usecases/advGeneratePayloadExportUseCase";
import {
  DeleteCostCenterReportPanoramaUseCase,
  IDeleteCostCenterReportPanoramaUseCase,
} from "../domain/usecases/deleteCostCenterReportPanoramaUseCase";
import {
  ExportCostCenterReportUseCase,
  IExportCostCenterReportUseCase,
} from "../domain/usecases/exportCostCenterReportUseCase";
import {
  GetCostCenterReportUseCase,
  IGetCostCenterReportUseCase,
} from "../domain/usecases/getCostCenterReportUseCase";
import {
  GetStoredCostCenterReportPanoramaIdUseCase,
  IGetStoredCostCenterReportPanoramaIdUseCase,
} from "../domain/usecases/getStoredCostCenterReportPanoramaIdUseCase";
import {
  IListCostCenterReportPanoramasUseCase,
  ListCostCenterReportPanoramasUseCase,
} from "../domain/usecases/listCostCenterReportPanoramasUseCase";
import {
  IListCostCenterReportUseCase,
  ListCostCenterReportUseCase,
} from "../domain/usecases/listCostCenterReportUseCase";
import {
  IListCostCentersUseCase,
  ListCostCentersUseCase,
} from "../domain/usecases/listCostCenterUseCase";
import {
  ISaveCostCenterReportPanoramaUseCase,
  SaveCostCenterReportPanoramaUseCase,
} from "../domain/usecases/saveCostCenterReportPanoramaUseCase";
import {
  IStoreCostCenterReportPanoamaIdUseCase,
  StoreCostCenterReportPanoamaIdUseCase,
} from "../domain/usecases/storeCostCenterReportPanoamaIdUseCase";
import {
  IToggleCostCenterReportUseCase,
  ToggleCostCenterReportUseCase,
} from "../domain/usecases/toggleCostCenterReportUseCase";

export type MakeCostCenterReport = IGetCostCenterReportUseCase &
  IListCostCenterReportUseCase &
  IToggleCostCenterReportUseCase &
  IListCostCentersUseCase &
  IExportCostCenterReportUseCase &
  IListCostCenterReportPanoramasUseCase &
  IDeleteCostCenterReportPanoramaUseCase &
  ISaveCostCenterReportPanoramaUseCase &
  IGetStoredCostCenterReportPanoramaIdUseCase &
  IStoreCostCenterReportPanoamaIdUseCase &
  ISearchClassificationAccountContract &
  ISearchClassificationAssessmentContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchCustomerProviderByCorporationNameContract &
  ISearchCustomerProviderContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract &
  ISearchMacroCategoriesContract;

export function makeCostCenterReport(
  api: IApiService,
  authApi: IApiService,
): MakeCostCenterReport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSide = new SetServerSideService(getUserLocalService, api);

  const getCostCenterReportService = new GetCostCenterReportService(
    getUserLocalService,
    api,
  );
  const getCostCenterReportUseCase = new GetCostCenterReportUseCase(
    getCostCenterReportService,
  );

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listCostCenterReportService = new ListCostCenterReportService(
    setServerSideService,
    getUserLocalService,
    api,
  );
  const listCostCenterReportUseCase = new ListCostCenterReportUseCase(
    listCostCenterReportService,
  );

  const toggleCostCenterReportService = new ToggleCostCenterReportService(
    getUserLocalService,
    api,
  );
  const toggleCostCenterReportUseCase = new ToggleCostCenterReportUseCase(
    toggleCostCenterReportService,
  );

  const listCostCentersSvc = new ListCostCentersService(
    getUserLocalService,
    api,
  );
  const listCostCentersUseCase = new ListCostCentersUseCase(listCostCentersSvc);

  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const exportCostCenterReportSvc = new ExportCostCenterReportService(
    advGeneratePayloadExportUseCase,
    setServerSideService,
    getUserLocalService,
    api,
  );
  const exportCostCenterReportUseCase = new ExportCostCenterReportUseCase(
    exportCostCenterReportSvc,
  );

  const parsePanoramaAngularService = new ParsePanoramaAngularService();
  const listCostCenterReportPanoramasSvc =
    new ListCostCenterReportPanoramasService(
      parsePanoramaAngularService,
      getUserLocalService,
      api,
    );
  const listCostCenterReportPanoramasUseCase =
    new ListCostCenterReportPanoramasUseCase(listCostCenterReportPanoramasSvc);

  const deleteCostCenterReportPanoramaSvc =
    new DeleteCostCenterReportPanoramaService(getUserLocalService, api);
  const deleteCostCenterReportPanoramaUseCase =
    new DeleteCostCenterReportPanoramaUseCase(
      deleteCostCenterReportPanoramaSvc,
    );

  const transformPanoramaAngularService = new TransformPanoramaAngularService();
  const saveCostCenterReportPanoramaSvc =
    new SaveCostCenterReportPanoramaService(
      transformPanoramaAngularService,
      getUserLocalService,
      api,
    );
  const saveCostCenterReportPanoramaUseCase =
    new SaveCostCenterReportPanoramaUseCase(saveCostCenterReportPanoramaSvc);

  const getStoredCostCenterReportPanoramaIdSvc =
    new GetStoredCostCenterReportPanoramaIdService();
  const getStoredCostCenterReportPanoramaIdUseCase =
    new GetStoredCostCenterReportPanoramaIdUseCase(
      getStoredCostCenterReportPanoramaIdSvc,
    );

  const storeCostCenterReportPanoamaIdSvc =
    new StoreCostCenterReportPanoamaIdService();
  const storeCostCenterReportPanoamaIdUseCase =
    new StoreCostCenterReportPanoamaIdUseCase(
      storeCostCenterReportPanoamaIdSvc,
    );

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCentersService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchCustomerProviderByCorporationNameService =
    new SearchCustomerProviderByCorporationNameService(
      getUserLocalService,
      api,
    );

  const searchCustomerProviderService = new SearchCustomerProviderService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  const searchMacroCategoriesService = new SearchMacroCategoriesService(
    getUserLocalService,
    api,
    setServerSide,
  );

  return {
    getCostCenterReport(CostCenterReportId) {
      return getCostCenterReportUseCase.getCostCenterReport(CostCenterReportId);
    },
    listCostCenterReport(companyGroupId, payload, filters) {
      return listCostCenterReportUseCase.listCostCenterReport(
        companyGroupId,
        payload,
        filters,
      );
    },
    toggleCostCenterReport(CostCenterReportId: string) {
      return toggleCostCenterReportUseCase.toggleCostCenterReport(
        CostCenterReportId,
      );
    },
    listCostCenters(companyGroupId, actives?) {
      return listCostCentersUseCase.listCostCenters(companyGroupId, actives);
    },
    exportCostCenterReportUseCase(
      companyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColumnNames,
      filters,
    ) {
      return exportCostCenterReportUseCase.exportCostCenterReportUseCase(
        companyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColumnNames,
        filters,
      );
    },
    listCostCenterReportPanoramas(userId, columns) {
      return listCostCenterReportPanoramasUseCase.listCostCenterReportPanoramas(
        userId,
        columns,
      );
    },
    deleteCostCenterReportPanorama(panoramaId) {
      return deleteCostCenterReportPanoramaUseCase.deleteCostCenterReportPanorama(
        panoramaId,
      );
    },
    saveCostCenterReportPanorama(columns, panorama) {
      return saveCostCenterReportPanoramaUseCase.saveCostCenterReportPanorama(
        columns,
        panorama,
      );
    },
    getStoredCostCenterReportPanoramaId() {
      return getStoredCostCenterReportPanoramaIdUseCase.getStoredCostCenterReportPanoramaId();
    },
    storeCostCenterReportPanoamaId(panoramaId) {
      return storeCostCenterReportPanoamaIdUseCase.storeCostCenterReportPanoamaId(
        panoramaId,
      );
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCentersService.searchCostCenter(params);
    },
    searchCustomerProviderByCorporationName(params) {
      return searchCustomerProviderByCorporationNameService.searchCustomerProviderByCorporationName(
        params,
      );
    },
    searchCustomerProvider(params) {
      return searchCustomerProviderService.searchCustomerProvider(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchMacroCategories(params) {
      return searchMacroCategoriesService.searchMacroCategories(params);
    },
  };
}
