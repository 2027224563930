import styled from "styled-components";

export const Container = styled.div`
  .p-datatable tr.p-datatable-row-expansion {
    & > td {
      padding: 1rem;
      background-color: #f6f9fc;
      & > div {
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }

  .assessment-expanded-table.p-datatable {
    td {
      text-align: left;
      padding: 1rem !important;
    }
    tr {
      height: unset;
    }
  }
`;
