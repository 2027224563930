import styled from "styled-components";

interface ContainerProps {
  selectable?: boolean;
}

export const ContainerOld = styled.div<ContainerProps>`
  width: 100%;
  height: inherit;

  .p-datatable {
    display: flex;
    flex-direction: column;

    .p-datatable-wrapper {
      display: flex;
      flex: 1 1 auto;
    }

    .p-datatable-thead > tr > th {
      padding: 0;

      .p-column-header-content {
        height: 100%;
        width: 100%;

        .p-column-title {
          height: 100%;
          width: 100%;
        }
      }
    }

    span.p-column-resizer::after {
      content: "";
      display: block;
      position: absolute;
      border: none;
      border-right: 2px solid rgba(142, 157, 175, 0.5);
      height: 1.2rem;
      top: 0;
      right: 0;
      margin-top: 0.3125rem;
      margin-right: -0.0625rem;
      pointer-events: none;
    }

    .p-datatable-tbody > tr {
      &.p-highlight,
      &.p-row-odd.p-highlight {
        background: ${({ selectable }) => (selectable ? "#525f7f" : "#c4f1de")};

        td {
          color: ${({ selectable }) => (selectable ? "#525f7f" : "#485370")};
        }
      }

      &.p-row-odd {
        /* TODO criar uma variavel de css no global.ts para este valor */
        background: #f6f9fc;
      }

      & > td {
        padding: 0.375rem 0.75rem;
        /* TODO criar uma variavel de css no global.ts para este valor */
        color: #485370;

        .table-actionbar {
          display: flex;
          justify-content: center;
          flex-grow: 1;
        }
      }
    }

    tr.p-datatable-emptymessage {
      td {
        text-align: center;
        vertical-align: middle;
      }
    }

    .p-paginator {
      font-size: 1rem;

      .p-paginator-page {
        border: 1px solid #e2e5e9;
        font-size: 1rem;

        &.p-highlight {
          background: #090081;
          color: white;
        }
      }

      .p-paginator-element {
        width: 3rem;
        height: 3rem;
      }

      .current-page-report {
        display: flex;
        height: 2.375rem;
        justify-content: start;
        align-items: center;
        flex-grow: 1;
      }

      .p-dropdown-label {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }

      .rows-per-page-dropdown-wrapper,
      .rows-per-page-dropdown-wrapper .p-dropdown-label.p-inputtext {
        display: flex;
        align-items: center;
      }
    }
  }
`;

interface ContainerProps {
  removeableColumns: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  flex: 1 1;

  height: ${({ removeableColumns }) => {
    return removeableColumns ? "calc(100% - 2.7rem)" : "100%";
  }};

  .p-datatable-resizable .p-datatable-tbody > tr > td {
    overflow: unset;
    white-space: unset;
  }

  .p-datatable {
    th {
      span.p-column-resizer::after {
        content: "";
        display: block;
        position: absolute;
        border: none;
        border-right: 2px solid rgba(142, 157, 175, 0.5);
        height: 1.2rem;
        top: 0;
        right: 0;
        margin-top: 0.3125rem;
        margin-right: -0.0625rem;
        pointer-events: none;
        z-index: 5;
      }

      span.p-column-resizer {
        display: none;
      }

      &.p-resizable-column {
        span.p-column-resizer {
          display: block;
        }
      }
    }

    .p-datatable-tbody {
      & > tr {
        td {
          .table-actionbar {
            display: flex;
            justify-content: center;
            flex-grow: 1;
          }
        }
      }
    }

    .p-datatable-thead > tr {
      > th {
        padding-top: 0;
        padding-bottom: 0;

        &:not(.soul-chkbox-col) {
          padding: 0;
        }

        align-items: stretch;

        .p-column-header-content {
          width: 100%;

          .p-column-title {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  tr.p-datatable-emptymessage {
    td {
      flex: 1 1 auto;
    }
  }

  .p-paginator {
    font-size: 1rem;

    &.p-paginator-bottom {
      border-width: 0;
    }

    .p-paginator-page {
      border: 1px solid #e2e5e9;
      font-size: 1rem;

      &.p-highlight {
        background: #090081;
        color: white;
      }
    }

    .p-paginator-element {
      width: 3rem;
      height: 3rem;
    }

    .current-page-report {
      display: flex;
      height: 2.375rem;
      justify-content: start;
      align-items: center;
      flex-grow: 1;
    }

    .p-dropdown-label {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }

    .rows-per-page-dropdown-wrapper,
    .rows-per-page-dropdown-wrapper .p-dropdown-label.p-inputtext {
      display: flex;
      align-items: center;
    }
  }
`;
