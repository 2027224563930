import { ELocalStorageKeys } from "../../../../../core/data/utils/localStorageKeys";
import { Stringified } from "../../../../../core/domain/entities/stringfied";
import { IStoreAccountsPayablePanoamaIdContract } from "../../domain/contracts/storeAccountsPayablePanoamaIdContract";

export class StoreAccountsPayablePanoamaIdService
  implements IStoreAccountsPayablePanoamaIdContract
{
  storeAccountsPayablePanoamaId(panoramaId: string): void {
    const key = ELocalStorageKeys.AccountsPayablePanoramaId;

    localStorage.setItem(key, Stringified.stringify(panoramaId) as string);
  }
}
