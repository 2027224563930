import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../advTable/domain/entities/advTableColumn";
import { PayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  ISearchProviderClassificationAccountContract,
  ISearchProviderClassificationAccountParams,
} from "../../domain/contracts/searchProviderClassificationAccountContract";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../models/serverSideResponseModel";
import { IApiService } from "./apiService";

interface IClassificationAccountResponse {
  id: string;
  name: string;
}

export class SearchProviderClassificationAccountService
  implements ISearchProviderClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchProviderClassificationAccount({
    providerId,
    activesOnly,
    search = "",
    companyGroupId,
    payloadOptions,
    showMetadata = false,
  }: ISearchProviderClassificationAccountParams) {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      ...payloadOptions,
      length: search ? undefined : payloadOptions?.length,
      order: payloadOptions?.order || [{ column: 1, dir: "asc" }],
      search: payloadOptions?.search || {
        regex: false,
        value: search,
      },
      columns: payloadOptions?.columns || [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: "", regex: false },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: "", regex: false },
        },
        {
          data: "active",
          name: "",
          searchable: false,
          orderable: false,
          search: { value: "", regex: false },
        },
      ],
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/${companyGroupId}/Providers/${providerId}/ClassificationAccounts`;

    if (activesOnly) {
      url += "/Actives";
    }

    const response = await this.api.get<
      IServerSideResponseModel<IClassificationAccountResponse[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(classificationAccount => {
        return new RelationshipFilterOption({
          label: classificationAccount.name,
          rawValue: classificationAccount.id,
          metadata: showMetadata ? classificationAccount : undefined,
        });
      }),
    });
  }
}
