import styled from "styled-components";

export const Container = styled.div`
  --sidebar-horizontal-width: 60px;

  display: flex;
  height: 100%;
  margin-left: var(--sidebar-horizontal-width);
  position: relative;
  flex-direction: column;

  .admin-header {
    position: relative;
    padding-bottom: 10rem !important;
    width: 100%;
  }

  @media (min-width: 768px) {
    .admin-header {
      padding-top: 8rem !important;
    }
  }

  @media (min-width: 576px) {
    .admin-header {
      padding-top: 4.5rem !important;
    }
  }

  .bg-gradient-soul {
    background: linear-gradient(90deg, #090081, #445fb5) !important;
  }

  .page-content {
    display: flex;
    padding-left: 2.4375rem;
    padding-right: 2.4375rem;
    margin-top: -10rem;
    position: relative;
    height: 100%;
  }
`;

export const PageTitle = styled.span`
  margin-right: auto;
  color: #fff;
  font-weight: 600;
  font-size: 0.9375rem;
`;
