import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICancelAccountPayableContract } from "../../domain/contracts/cancelAccountPayableContract";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";

export class CancelAccountPayableService
  implements ICancelAccountPayableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async cancelAccountPayableContract(
    accountPayableId: string,
    reason: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsPayable/${accountPayableId}/Status`;

    const payload = {
      status: EAccountPayableStatus.Canceled,
      deleteReason: reason,
    };

    await this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
