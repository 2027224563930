import { IClassificationUspEntity } from "../../../classificationUsp/domain/entities/classificationUspEntity";
import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";

export interface ISearchClassificationUspService {
  searchClassificationUsp(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationUspEntity[]>>;
}

export interface ISearchClassificationUspUseCase {
  searchClassificationUsp(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IClassificationUspEntity[]>>;
}

export class SearchClassificationUspUseCase
  implements ISearchClassificationUspUseCase
{
  constructor(
    private searchClassificationUspService: ISearchClassificationUspService,
  ) {}

  searchClassificationUsp(
    search: string,
    companyGroupId: string,
    active?: boolean,
  ) {
    return this.searchClassificationUspService.searchClassificationUsp(
      search,
      companyGroupId,
      active,
    );
  }
}
