import { Navigate } from "react-router-dom";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";

interface IMasterGaurdedRouteProps {
  children: JSX.Element;
}

export function MasterGaurdedRoute({ children }: IMasterGaurdedRouteProps) {
  const { user } = useUserLocal();

  const isUserForbidden = user.profile === EUserProfile.master;

  if (isUserForbidden) {
    return <Navigate to="/forbidden" replace />;
  }

  return children;
}
