import {
  useMemo,
  forwardRef,
  useCallback,
  ChangeEvent,
  InputHTMLAttributes,
} from "react";
import { Container } from "./style";

interface InputPercentageProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  max?: number;
  className?: string;
  precision?: number;
  disabled?: boolean;
  placeholder?: string;
  value: number | null;
  // TODO incluir uma prop de flag (ex.: useDecimal) para parametrizar a unidade numerica (% ou decimal) da prop value e do onChange
  onChange: (value: number | null, event: unknown) => void;
}

/**
 * Exibe valores percentuais, variando de 0% a 100%.
 * @param precision - a quantidade de casas decimais que devem ser consideradas
 */
export const InputPercentage = forwardRef<
  HTMLInputElement,
  InputPercentageProps
>((props, ref) => {
  const {
    value,
    onChange,
    disabled,
    max = 100,
    placeholder,
    precision = 0,
    className = "",
    ...rest
  } = props;

  /** Adiciona casas decimais de acordo com  a precisão indicada */
  const addPrecisionDigits = useCallback(
    (numberValue: number) => {
      if (numberValue === max) {
        return max.toString();
      }

      /** Quantidade de dígitos significativos, considerando se o valor é menor que 10 */
      const totalPrecision = precision + (numberValue < 10 ? 1 : 2);

      /** Tamanho total do valor a ser exibido, evitando zeros à esquerda para números menores que 1 */
      const totalLenght = precision + (numberValue < 1 ? 2 : 3);

      return numberValue
        .toPrecision(totalPrecision)
        .replace(".", ",")
        .slice(0, totalLenght);
    },
    [max, precision],
  );

  /** Armazena o valor da porcentagem em formato string. */
  const inputValue = useMemo(() => {
    if (value !== 0 && !value) return "";
    if (value > max) return max.toString().replace(".", ",");
    return addPrecisionDigits(value);
  }, [value, max, addPrecisionDigits]);

  /** Lida com o evento de change do input */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: eventValue } = e.target;

      if (eventValue === "") {
        onChange(null, e);
        return;
      }

      /** Regex para o valor "0,0" considerando a precisão fornecida. */
      const discardZero = new RegExp(`(0,(0{${precision}}))$`);

      const filteredValue = eventValue
        .replace(discardZero, "")
        .replace(/\D/g, "");

      const valueAsNumber = Number(filteredValue) / 10 ** precision;

      const finalValue = valueAsNumber > max ? max : valueAsNumber;

      onChange(finalValue, e);
    },
    [max, onChange, precision],
  );

  return (
    <Container
      isEmpty={inputValue === ""}
      className={`${className} ${disabled && "disabled"}`}
      suffixSpace={inputValue.length + (inputValue.includes(",") ? 0.75 : 1.25)}
    >
      <input
        ref={ref}
        {...rest}
        type="text"
        autoComplete="off"
        value={inputValue}
        disabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Container>
  );
});
