import { InputMask, InputMaskCompleteParams } from "primereact/inputmask";
import { ChangeEvent, useCallback, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { StateRegistrationLabel } from "./styles";
import { ProviderType } from "../../../domain/entities/providerEntity";

interface LegalPersonFormProps {
  useProvider: MakeProvider;
}

export function LegalPersonForm({ useProvider }: LegalPersonFormProps) {
  const { checkCnpj, getCnpjData } = useProvider;

  const {
    watch,
    register,
    resetField,
    getFieldState,
    formState: { errors },
  } = useFormContext<ProviderForm<"legalPerson">>();

  const refPrevDocument = useRef("");

  const providerId = watch("id");
  const isCheckedStateRegistrationExempt = watch("isStateRegistrationExempt");

  const onCompleteDocument = async (event: InputMaskCompleteParams) => {
    if (!event.value) {
      return;
    }

    const res = await getCnpjData(event.value);

    if (!res) {
      return;
    }

    resetField("name", { defaultValue: res.name });
    resetField("email", { defaultValue: res.email });
    resetField("document", { defaultValue: res.document });
    resetField("phoneNumber", { defaultValue: res.phoneNumber });
    resetField("corporationName", { defaultValue: res.corporationName });
    resetField("stateRegistration", { defaultValue: res.stateRegistration });
    resetField("municipalRegistration", {
      defaultValue: res.municipalRegistration,
    });
  };

  const validateDocument = useCallback(
    async (document: string) => {
      const { error } = getFieldState("document");
      const defaultDocLength = 14;

      if (
        document.length === defaultDocLength &&
        refPrevDocument.current !== document
      ) {
        refPrevDocument.current = document;
        const { Code, success } = await checkCnpj(document, providerId);

        if (success === false) {
          return "existingDocument";
        }
        if (Code === 100) {
          return "invalidDocument";
        }
        return true;
      }

      if (
        document.length < defaultDocLength &&
        refPrevDocument.current !== document
      ) {
        refPrevDocument.current = document;
        return "required";
      }

      if (error) return error.message;

      return true;
    },
    [checkCnpj, getFieldState, providerId],
  );

  const type = watch("type");

  if (type !== ProviderType.legalPerson) {
    return null;
  }

  return (
    <div className="form-section">
      <p>Dados Pessoa Jurídica</p>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>CNPJ</span>
          <Controller
            name="document"
            shouldUnregister
            rules={{
              required: true,
              maxLength: 14,
              validate: v => validateDocument(v),
            }}
            render={({ field, fieldState }) => (
              <>
                <InputMask
                  unmask
                  autoClear={false}
                  placeholder="CNPJ"
                  value={field.value}
                  mask="99.999.999/9999-99"
                  onChange={field.onChange}
                  data-testid="txt-document"
                  onComplete={onCompleteDocument}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  message="Este campo é obrigatório"
                  condition={fieldState?.error?.type === "required"}
                />
                <InvalidFeedback
                  message="Já temos uma pessoa jurídica cadastrada com este CNPJ"
                  condition={fieldState?.error?.type === "existingDocument"}
                />
                <InvalidFeedback
                  message="CNPJ inválido"
                  condition={fieldState?.error?.type === "invalidDocument"}
                />
              </>
            )}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Razão Social</span>
          <input
            maxLength={100}
            placeholder="Razão Social"
            data-testid="txt-corporation"
            {...register("corporationName", {
              required: true,
              shouldUnregister: true,
            })}
            className={errors?.corporationName ? "isInvalid" : ""}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.corporationName?.type === "required"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-12 form-control">
          <span>Nome Fantasia</span>
          <input
            maxLength={100}
            data-testid="txt-name"
            placeholder="Nome Fantasia"
            {...register("name", { required: true, shouldUnregister: true })}
            className={errors?.name ? "isInvalid" : ""}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.name?.type === "required"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Telefone <small>(opcional)</small>
          </span>
          <Controller
            name="phoneNumber"
            shouldUnregister
            rules={{
              maxLength: 11,
              minLength: 10,
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <InputMask
                    unmask
                    maxLength={11}
                    autoClear={false}
                    value={field.value}
                    placeholder="Telefone"
                    mask="(99) 99999-9999"
                    onChange={field.onChange}
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    condition={!!fieldState?.error}
                    message="Formato de telefone inválido"
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-6 form-control">
          <span>
            E-mail <small>(opcional)</small>
          </span>
          <input
            maxLength={100}
            placeholder="E-mail"
            className={errors?.email ? "isInvalid" : ""}
            {...register("email", {
              shouldUnregister: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Formato de e-mail inválido",
              },
            })}
          />
          <InvalidFeedback
            condition={errors?.email?.type === "pattern"}
            message={errors?.email?.message || "E-mail inválido"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Inscrição Municipal <small>(opcional)</small>
          </span>
          <input
            maxLength={100}
            placeholder="Inscrição Municipal"
            {...register("municipalRegistration", { shouldUnregister: true })}
          />
        </label>
        <StateRegistrationLabel
          htmlFor="txt-stateRegistration"
          className="col-6 form-control"
        >
          <span>Inscrição Estadual</span>
          <label>
            <Controller
              name="isStateRegistrationExempt"
              shouldUnregister
              render={({ field }) => {
                const checkboxField = {
                  ...field,
                  checked: !!field.value,
                  value: String(!!field.value),
                  onChange(e: ChangeEvent<HTMLInputElement>) {
                    field.onChange(e);

                    if (!e.target.checked) {
                      return;
                    }

                    resetField("stateRegistration", { defaultValue: "" });
                  },
                };

                return <Checkbox {...checkboxField} label="Isento" />;
              }}
            />
          </label>
          <input
            maxLength={100}
            id="txt-stateRegistration"
            placeholder="Inscrição Estadual"
            data-testid="txt-stateRegistration"
            className={errors?.stateRegistration ? "isInvalid" : ""}
            {...register("stateRegistration", {
              shouldUnregister: true,
              required: !isCheckedStateRegistrationExempt,
              disabled: isCheckedStateRegistrationExempt,
            })}
          />
          <InvalidFeedback
            condition={errors?.stateRegistration?.type === "required"}
            message="Este campo é obrigatório"
          />
        </StateRegistrationLabel>
      </div>
    </div>
  );
}
