import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableSelectionChangeParams,
  DataTableSortMeta,
  DataTableSortOrderType,
} from "primereact/datatable";
import {
  Paginator,
  PaginatorCurrentPageReportOptions,
  PaginatorPageState,
  PaginatorTemplateOptions,
} from "primereact/paginator";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useCostCenterUsersGrid } from "../../hooks/useCostCenterUsersGrid";
import { ICostCenterUsersForm } from "../CostCenterUsersModal";
import { Container } from "./style";
import { MakeCore } from "../../../../core/main/makeCore";
import { PFSEventEntity } from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import { IAuthUserEntity } from "../../../../core/domain/entities/authUserEntity";

export interface IUserListData {
  totalRecords: number;
  list: IAuthUserEntity[];
}

interface ICostCenterUserGridEvent {
  rows?: number;
  first?: number;
  search?: string;
  sortField?: string;
  selectedIds?: string[];
  profilesToShow?: EUserProfile[];
  sortOrder?: DataTableSortOrderType;
}

export interface CostCenterUsersGridProps {
  isSupervisor: boolean;
  tableData: IUserListData;
  listAuthUsers: MakeCore["listAuthUsers"];
}

export function CostCenterUsersGrid(props: CostCenterUsersGridProps) {
  const { tableData, listAuthUsers, isSupervisor } = props;

  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { columns, payloadColumns } = useCostCenterUsersGrid();
  const { generatePayload } = useTables();

  const { setValue, getValues } = useFormContext<ICostCenterUsersForm>();

  /** Indica quando a tabela deve exibir apenas os selecionados. */
  const [selectedOnly, setSelectedOnly] = useState(false);

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Estado de carregamento da tabela */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da tabela */
  const [data, setData] = useState<IAuthUserEntity[]>(tableData.list);
  const [totalRecords, setTotalRecords] = useState(tableData.totalRecords);

  /** Controla a quantidade de linhas mostradas na tabela */
  const [rows, setRows] = useState(10);
  /** Controla a página atual da tabela */
  const [first, setFirst] = useState(0);
  /** Controla a ordenação da coluna. */
  const [sortMeta, setSortMeta] = useState<DataTableSortMeta>();

  /** Armazena as linhas selecionadas */
  const [selection, setSelection] = useState<Pick<IAuthUserEntity, "id">[]>(
    () => {
      const selectedUsers = getValues("users");
      return selectedUsers;
    },
  );

  /**
   * Função que realiza a busca no servidor, atualizando os dados da tabela
   * de acordo com o payload atual.
   */
  const getList = useCallback(
    async (_payload: ICostCenterUserGridEvent) => {
      if (!mountedRef.current) {
        return;
      }

      const joinedIds = _payload.selectedIds?.join(",");

      /**
       * A lista de ID's selecionados igual a `undefined` indica que o usuário
       * selecionou o checkbox "Mostrar apenas selecionados" quando não existem
       * usuários selecionados.
       * Assim, não há necessidade de se realizar uma requisição.
       */
      if (joinedIds === undefined) {
        setTotalRecords(0);
        setData([]);
        return;
      }

      setIsLoading(true);

      const pfsEvent = new PFSEventEntity({
        rows: _payload.rows,
        first: _payload.first,
        sortField: _payload.sortField,
        sortOrder: _payload.sortOrder,
        globalFilter: _payload.search,
      });

      const payload = generatePayload(pfsEvent, payloadColumns);

      const idColumn = payload.columns.find(c => c.name === "id");

      if (idColumn) {
        idColumn.search = {
          regex: false,
          value: joinedIds,
        };
      }

      try {
        const response = await listAuthUsers({
          payload,
          isSupervisor,
          actives: true,
        });
        setTotalRecords(response.recordsFiltered);
        setData(response.data);
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [mountedRef, generatePayload, payloadColumns, listAuthUsers, isSupervisor],
  );

  /**
   * Define qual valor a propriedade `selectedIds` deve assumir quando passada o
   * serviço de listagem.
   */
  const defineSelectedIdsValue = useCallback(
    (isSelectedOnlyChecked: boolean) => {
      const selectionIds = selection.map(({ id }) => id);
      const selectedIdsValue =
        selectionIds.length && isSelectedOnlyChecked ? selectionIds : undefined;

      return isSelectedOnlyChecked ? selectedIdsValue : [];
    },
    [selection],
  );

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
        getList({
          rows,
          first,
          search: e.target.value,
          sortField: sortMeta?.field,
          sortOrder: sortMeta?.order,
          selectedIds: defineSelectedIdsValue(selectedOnly),
        });
      }, 700);
    },
    [
      rows,
      first,
      getList,
      debounceTime,
      selectedOnly,
      sortMeta?.field,
      sortMeta?.order,
      defineSelectedIdsValue,
    ],
  );

  /**
   * Lida com as mudanças no componente de paginação.
   */
  const handleOnPageChange = useCallback(
    (e: PaginatorPageState) => {
      setFirst(e.first);
      getList({
        rows,
        first: e.first,
        search: globalFilter,
        sortField: sortMeta?.field,
        sortOrder: sortMeta?.order,
        selectedIds: defineSelectedIdsValue(selectedOnly),
      });
    },
    [
      rows,
      getList,
      selectedOnly,
      globalFilter,
      sortMeta?.order,
      sortMeta?.field,
      defineSelectedIdsValue,
    ],
  );

  /**
   * Atualiza a quantidade de linhas a serem mostradas na tabela.
   */
  const handlePerPageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const numberRows = Number(e.target.value);
      setRows(numberRows);
      setFirst(0);
    },
    [],
  );

  /**
   * Lida com o evento de ordenação.
   */
  const handleSort = useCallback(
    (e: DataTablePFSEvent) => {
      setSortMeta({
        field: e.sortField,
        order: e.sortOrder,
      });
      getList({
        rows,
        first,
        search: globalFilter,
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        selectedIds: defineSelectedIdsValue(selectedOnly),
      });
    },
    [first, getList, globalFilter, rows, selectedOnly, defineSelectedIdsValue],
  );

  /**
   * Lida com o evento de seleção de linhas. Através desse evento, apenas os
   * IDs das classificações são definidos no formulário.
   */
  const handleSelection = useCallback(
    (e: DataTableSelectionChangeParams) => {
      const eventSelected = e.value as IAuthUserEntity[];
      const idOnly = eventSelected.map(({ id }) => ({ id }));
      setSelection(idOnly);
      setValue("users", idOnly, { shouldDirty: true });
    },
    [setValue],
  );

  /**
   * Template que exibe um texto informando a quantidade de páginas.
   */
  const currentPageReportTemplate = useCallback(
    ({ currentPage, totalPages }: PaginatorCurrentPageReportOptions) => {
      return (
        <div className="current-page-report">
          Exibindo página {currentPage} de {totalPages}
        </div>
      );
    },
    [],
  );

  /**
   * Template da paginação a ser exibido.
   */
  const paginatorTemplate: PaginatorTemplateOptions = useMemo(
    () => ({
      layout:
        "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
      PageLinks: "",
      PrevPageLink: "",
      LastPageLink: "",
      NextPageLink: "",
      FirstPageLink: "",
      JumpToPageInput: "",
      CurrentPageReport: currentPageReportTemplate,
    }),
    [currentPageReportTemplate],
  );

  /**
   * Lida com o evento de seleção do checkbox que exibe apenas os selecionados.
   */
  const handleShowSelectedOnlyChange = useCallback(
    (e: CheckboxChangeParams) => {
      setSelectedOnly(e.checked);
      setFirst(0);

      getList({
        rows,
        first: 0,
        search: globalFilter,
        sortField: sortMeta?.field,
        sortOrder: sortMeta?.order,
        selectedIds: defineSelectedIdsValue(e.checked),
      });
    },
    [
      rows,
      getList,
      globalFilter,
      sortMeta?.field,
      sortMeta?.order,
      defineSelectedIdsValue,
    ],
  );

  return (
    <Container>
      <div className="table-header">
        <div className="table-perPage">
          <span>Mostrar</span>
          <select defaultValue="10" onChange={handlePerPageChange}>
            {[10, 25, 50, 100].map(value => {
              return (
                <option value={value} key={`${value}-rows`}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <label className="table-selectedOnly">
          <Checkbox
            checked={selectedOnly}
            onChange={handleShowSelectedOnlyChange}
          />
          Mostrar apenas selecionados
        </label>
        <div className="table-filter">
          <FaSearch />
          <input
            value={search}
            placeholder="Pesquisar"
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <DataTable
        lazy
        rowHover
        paginator
        scrollable
        rows={rows}
        dataKey="id"
        value={data}
        removableSort
        loading={isLoading}
        onPage={() => null}
        onSort={handleSort}
        paginatorTemplate=""
        scrollHeight="450px"
        selection={selection}
        metaKeySelection={false}
        selectionMode="multiple"
        responsiveLayout="scroll"
        sortField={sortMeta?.field}
        sortOrder={sortMeta?.order}
        onSelectionChange={handleSelection}
        emptyMessage="Nenhum registro encontrado"
      >
        {columns.map(column => (
          <Column {...column} key={column.field} />
        ))}
      </DataTable>
      <Paginator
        rows={rows}
        first={first}
        template={paginatorTemplate}
        totalRecords={totalRecords || 1}
        onPageChange={handleOnPageChange}
      />
    </Container>
  );
}
