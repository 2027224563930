import { ICostCenterTransactionInputEntity } from "../entities/costCenterTransactionInputEntity";

export interface IGetCostCenterTransactionService {
  getCostCenterTransaction(
    CostCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionInputEntity>;
}

export interface IGetCostCenterTransactionUseCase {
  getCostCenterTransaction(
    CostCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionInputEntity>;
}

export class GetCostCenterTransactionUseCase
  implements IGetCostCenterTransactionUseCase
{
  constructor(
    private getCostCenterTransactionsService: IGetCostCenterTransactionService,
  ) {}

  getCostCenterTransaction(
    CostCenterTransactionsId: string,
  ): Promise<ICostCenterTransactionInputEntity> {
    return this.getCostCenterTransactionsService.getCostCenterTransaction(
      CostCenterTransactionsId,
    );
  }
}
