import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICheckEmailAlreadyExistsService } from "../../domain/usecases/checkEmailAlreadyExistsUseCase";
import {
  CheckEmailAlreadyExistsResponseModel,
  ICheckEmailAlreadyExistsPayloadModel,
  ICheckEmailAlreadyExistsResponseModel,
} from "../models/checkEmailAlreadyExistsModel";

export class CheckEmailAlreadyExistsService
  implements ICheckEmailAlreadyExistsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async checkEmailAlreadyExists(
    checkEmailAlreadyExistPayloadModel: ICheckEmailAlreadyExistsPayloadModel,
  ): Promise<ICheckEmailAlreadyExistsResponseModel> {
    const userEntity = this.getUserLocalService.get();
    const url = `/Users/checkEmail`;

    const response =
      await this.authApi.post<ICheckEmailAlreadyExistsResponseModel>(
        url,
        checkEmailAlreadyExistPayloadModel,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );

    return new CheckEmailAlreadyExistsResponseModel(response);
  }
}
