import { ICompanyTaxEntity } from "../entities/companyTaxEntity";

export interface IGetCompanyTaxesService {
  getCompanyTaxes(companyId: string): Promise<ICompanyTaxEntity[]>;
}

export interface IGetCompanyTaxesUseCase {
  getCompanyTaxes(companyId: string): Promise<ICompanyTaxEntity[]>;
}

export class GetCompanyTaxesUseCase implements IGetCompanyTaxesUseCase {
  constructor(private getCompanyTaxesService: IGetCompanyTaxesService) {}

  getCompanyTaxes(companyId: string) {
    return this.getCompanyTaxesService.getCompanyTaxes(companyId);
  }
}
