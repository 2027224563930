import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { ICompanyEntity } from "../entities/companyEntity";

export interface IListCompaniesService {
  listCompanies(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<ICompanyEntity[]>>;
}

export interface IListCompaniesUseCase {
  listCompanies(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<ICompanyEntity[]>>;
}

export class ListCompaniesUseCase implements IListCompaniesUseCase {
  constructor(private listCompaniesService: IListCompaniesService) {}

  listCompanies(
    companyGroupId: string,
    actives: boolean,
  ): Promise<IServerSideResponseModel<ICompanyEntity[]>> {
    return this.listCompaniesService.listCompanies(companyGroupId, actives);
  }
}
