import { IUserEntity } from "../entities/userEntity";

export interface ISaveUserService {
  saveUser(user: IUserEntity): Promise<IUserEntity>;
}

export interface ISaveUserUseCase {
  saveUser(user: IUserEntity): Promise<IUserEntity>;
}

export class SaveUserUseCase implements ISaveUserUseCase {
  constructor(private saveUserService: ISaveUserService) {}

  saveUser(user: IUserEntity): Promise<IUserEntity> {
    return this.saveUserService.saveUser(user);
  }
}
