import { useCallback, useMemo, useState } from "react";
import { FieldError, FieldErrors, useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { IApiError } from "../../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../../core/domain/entities/attachmentsGridTypes";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { NOT_FOUND_OPTION_ID } from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../../../provider/main/makeProvider";
import { ProviderFormModal } from "../../../../../../provider/presentation/components/ProviderFormModal";
import { ILowerCaseErrorResponseEntity } from "../../../../../../simpleTable/domain/entities/responseEntity";
import { IPaymentRequestAttachmentEntity } from "../../../domain/entities/paymentRequestAttachmentEntity";
import { IPaymentRequestFormEntity } from "../../../domain/entities/paymentRequestFormEntity";
import { IProviderEntity } from "../../../domain/entities/providerEntity";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { FeeFormModal, IFeeFormOnSubmitParams } from "../FeeFormModal";
import { RequestProgressModal } from "../RequestProgressModal";
import { Container } from "./styles";

interface IReviewSectionFooterState {
  isReproving: boolean;
  isFeeFormModalOpen: boolean;
  isProviderModalOpen: boolean;
  progressModal: {
    total: number;
    loaded: number;
    isOpen: boolean;
  };
}

interface IReviewSectionFooterProps {
  useProvider: MakeProvider;
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function ReviewSectionFooter(props: IReviewSectionFooterProps) {
  const { useProvider, usePaymentRequestForm } = props;
  const [state, setState] = useState<IReviewSectionFooterState>({
    isReproving: false,
    isFeeFormModalOpen: false,
    isProviderModalOpen: false,
    progressModal: {
      total: 0,
      loaded: 0,
      isOpen: false,
    },
  });

  const {
    validateAttachments,
    reprovePaymentRequest,
    approvePaymentRequest,
    updateProviderRetainFee,
    uploadReviewAttachments,
    listCompanyGroupActiveFees,
    validateMeasurementAttachment,
  } = usePaymentRequestForm;

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const debounceTime = useDebounceTimeAsync();
  const form = useFormContext<IPaymentRequestFormEntity>();
  const { paymentRequestId } = useParams<"paymentRequestId">();

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const provider = watch("provider") as ITypeaheadOption<IProviderEntity>;
  const providerId = provider?.rawValue as string | undefined;
  const providerRetainsFee = provider?.metadata?.retainFee;

  const memoizedProviderId = useMemo(() => {
    if (providerId === NOT_FOUND_OPTION_ID) {
      return "";
    }

    return providerId;
  }, [providerId]);

  const handleOpenProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: true,
    }));
  };

  const handleCloseProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      isProviderModalOpen: false,
    }));
  };

  const handleFeeFormModalClose = () => {
    setState(prevState => ({ ...prevState, isFeeFormModalOpen: false }));
  };

  const onUploadProgress = (event: ProgressEvent) => {
    setState(prevState => ({
      ...prevState,
      progressModal: {
        ...prevState.progressModal,
        total: event.total,
        loaded: event.loaded,
      },
    }));
  };

  const handleAttachmentsUpload = useCallback(
    async (newAccountId: string, formData: IPaymentRequestFormEntity) => {
      const attachments = formData?.storageFiles;

      if (attachments?.length === 0) {
        return;
      }

      await uploadReviewAttachments({
        newAccountId,
        attachments,
        onUploadProgress,
      });
    },
    [uploadReviewAttachments],
  );

  const checkIfProviderHasFeeRetentionInfo = async () => {
    if (providerRetainsFee !== null) {
      return providerRetainsFee;
    }

    const result = await dialog.fire({
      icon: "question",
      title: "Opa!",
      allowEscapeKey: false,
      showCancelButton: true,
      allowOutsideClick: false,
      cancelButtonText: "Não reter",
      confirmButtonText: "Deve reter",
      html: (
        <>
          O cadastro desse fornecedor não diz se ele{" "}
          <strong>deve reter impostos ou não</strong>.
          <br />
          <br />
          Conta pra gente! Assim, <strong>
            atualizaremos o cadastro dele
          </strong>{" "}
          para você!
        </>
      ),
    });

    dialog.showLoading();

    const shouldRetainFee = result.isConfirmed;

    await updateProviderRetainFee({
      retainFee: shouldRetainFee,
      providerId: memoizedProviderId || "",
    });

    return shouldRetainFee;
  };

  const handleFormSubmit = async (formData: IPaymentRequestFormEntity) => {
    const attachments = formData.storageFiles;

    setState(prevState => ({
      ...prevState,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: true,
      },
    }));

    try {
      if (attachments.length !== 0 && attachments.some(a => !!a.file)) {
        await validateAttachments(attachments);
      }

      const newAccountId = await approvePaymentRequest(formData);

      await handleAttachmentsUpload(newAccountId, formData);

      setState(prevState => ({
        ...prevState,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        showCancelButton: false,
        confirmButtonText: "Ok",
        html: "Lançamento de conta a pagar cadastrado com sucesso.",
      });

      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`);
    } catch (error) {
      const typedError = error as Record<string, string>;

      if (typedError?.type === "invalid-attachments") {
        dialog.fire({
          icon: "error",
          title: "Erro",
          text:
            typedError?.message ||
            "Algo deu errado. Por favor, verifique os anexos e tente novamente.",
        });
      }
    } finally {
      setState(prevState => ({
        ...prevState,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));
    }
  };

  const validateMeasurementAttachmentCheck = useCallback(
    async (value: number, allAttachment: IPaymentRequestAttachmentEntity[]) => {
      const measurementAttachmentIndex = allAttachment.findIndex(attachment => {
        return (
          attachment.active && attachment.type === EAttachmentType.Measurement
        );
      });

      if (measurementAttachmentIndex === -1) {
        return {
          preventSubmit: false,
        };
      }

      const measurementAttachment = allAttachment[measurementAttachmentIndex];

      try {
        const response = await validateMeasurementAttachment(
          value,
          measurementAttachment,
        );

        if (response.success === true) {
          return {
            preventSubmit: false,
          };
        }

        if (response.success === false) {
          await debounceTime(1);

          await dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: response.message,
          });
        }

        return {
          preventSubmit: true,
        };
      } catch (error) {
        await debounceTime(1);

        const messageHTMLError = (
          error as IApiError<ILowerCaseErrorResponseEntity>
        ).response.data.messageHTML;

        const messageError = (error as IApiError<ILowerCaseErrorResponseEntity>)
          .response.data.message;

        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: messageHTMLError || messageError,
        });

        return {
          preventSubmit: true,
        };
      }
    },
    [debounceTime, dialog, validateMeasurementAttachment],
  );

  const handleOnValid = async (formData: IPaymentRequestFormEntity) => {
    const retainsFee = await checkIfProviderHasFeeRetentionInfo();

    if (retainsFee) {
      setState(prevState => ({ ...prevState, isFeeFormModalOpen: true }));
      return;
    }

    const { value } = formData;
    const allAttachment = formData.storageFiles;

    const validateMeasurement = await validateMeasurementAttachmentCheck(
      value,
      allAttachment,
    );

    if (validateMeasurement.preventSubmit) {
      return;
    }

    handleFormSubmit(formData);
  };

  const handleOnInvalid = useCallback(
    async (errors: FieldErrors<IPaymentRequestFormEntity>) => {
      const errorsValues = Object.values(errors);
      const errorsTypes = errorsValues.map(error => (error as FieldError).type);

      if (errorsTypes.includes("required")) {
        dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Existem campos obrigatórios não preenchidos. Preencha-os para
              aprovar o lançamento de solicitação de pagamento.
            </>
          ),
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

        return;
      }

      if (errors.barcode) {
        const isMaxLength = errors?.barcode?.type === "maxLength";

        dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Houve erros na validação da requisição.
              <br />
              Código de barras deve ter{" "}
              {isMaxLength ? "no máximo 48 dígitos." : "no minimo 44 dígitos."}
            </>
          ),
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

        return;
      }

      if (errors.assessments) {
        const assessmentError = errors.assessments as unknown as FieldError;

        if (assessmentError.type === "assessmentValue") {
          dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Os valores de rateio devem bater com o valor da solicitação de
                pagamento.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      if (errors.provider) {
        const providerError = errors.provider as FieldError;

        if (providerError.type === "bankDataRequired") {
          await dialog.fire({
            icon: "warning",
            title: "Atenção",
            html: (
              <>
                Para prosseguir será necessário preencher os dados bancários do
                fornecedor selecionado.
              </>
            ),
            showCancelButton: false,
            confirmButtonText: "Ok",
          });

          handleOpenProviderModal();
        }
      }
    },
    [dialog],
  );

  const handleApprovePaymentRequest = () => {
    handleSubmit(handleOnValid, handleOnInvalid)();
  };

  const handleReprovePaymentRequest = async () => {
    const { value: reason } = await dialog.fire({
      icon: "question",
      input: "textarea",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      inputPlaceholder: "Digite aqui o motivo da reprovação...",
      inputValidator: value => {
        return value ? null : "É obrigatório informar um motivo.";
      },
      html: (
        <>
          A Solicitação será reprovada.
          <br />
          Deseja prosseguir?
        </>
      ),
    });

    if (!reason || !paymentRequestId) {
      return;
    }

    setState(old => ({
      ...old,
      isReproving: true,
    }));

    try {
      await reprovePaymentRequest(paymentRequestId, reason);

      setState(old => ({
        ...old,
        isReproving: false,
      }));

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Solicitação reprovada com sucesso.",
      });

      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`);
    } catch {
      setState(old => ({
        ...old,
        isReproving: false,
      }));
    }
  };

  const handleFeeFormSubmission = (params: IFeeFormOnSubmitParams) => {
    handleFeeFormModalClose();

    setValue("fees", params.fees);
    setValue("generateFeeRetention", params.generateFeeRetention);

    handleSubmit(handleFormSubmit)();
  };

  return (
    <Container>
      <button
        type="button"
        id="btn-reprove"
        data-testid="btn-reprove"
        className="form-button red-bkg"
        onClick={handleReprovePaymentRequest}
        disabled={state.isReproving || isSubmitting}
      >
        Reprovar{" "}
        {state.isReproving ? <i className="pi pi-spin pi-spinner" /> : null}
      </button>
      <button
        type="button"
        id="btn-approve"
        data-testid="btn-approve"
        className="form-button green-bkg"
        onClick={handleApprovePaymentRequest}
        disabled={state.isReproving || isSubmitting}
      >
        Aprovar {isSubmitting ? <i className="pi pi-spin pi-spinner" /> : null}
      </button>
      <RequestProgressModal
        total={state.progressModal.total}
        loaded={state.progressModal.loaded}
        isOpen={state.progressModal.isOpen}
      />
      <ProviderFormModal
        bankDataRequired
        useProvider={useProvider}
        isOpen={state.isProviderModalOpen}
        currentId={memoizedProviderId || ""}
        onRequestClose={handleCloseProviderModal}
      />
      <FeeFormModal
        isOpen={state.isFeeFormModalOpen}
        onClose={handleFeeFormModalClose}
        onSubmit={handleFeeFormSubmission}
        listCompanyGroupActiveFees={listCompanyGroupActiveFees}
      />
    </Container>
  );
}
