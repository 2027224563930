import { IEntity } from "../../../../../core/domain/entities/entity";

export enum EAccountReceivableDocumentStatus {
  Complete = 1,
  Incomplete = 2,
}

export const accountReceivableDocumentStatusLang: Record<
  keyof typeof EAccountReceivableDocumentStatus,
  string
> = {
  Complete: "Completo",
  Incomplete: "Incompleto",
};

export enum EAccountReceivableStatus {
  Open = 1,
  Paid = 2,
  Canceled = 3,
  Inconsistent = 4,
}

export const accountReceivableStatusLang: Record<
  keyof typeof EAccountReceivableStatus,
  string
> = {
  Open: "Aberto",
  Paid: "Pago",
  Canceled: "Cancelado",
  Inconsistent: "Inconsistente",
};

export enum EReturnStatus {
  NotReturned = 1,
  PartiallyReturned = 2,
  FullyReturned = 3,
}

export const returnStatusLang: Record<keyof typeof EReturnStatus, string> = {
  NotReturned: "Não devolvido",
  PartiallyReturned: "Parcialmente devolvido ",
  FullyReturned: "Totalmente devolvido",
};

export enum EInvoiceStatus {
  NotGenerated = 1,
  Processing = 2,
  Generated = 3,
  Denied = 4,
}

export const invoiceStatusLang: Record<keyof typeof EInvoiceStatus, string> = {
  NotGenerated: "Não gerado",
  Processing: "Processando",
  Generated: "Gerado",
  Denied: "Negado",
};

export enum EAccountReceivablePaymentMethod {
  Billet = 1,
  CreditCard = 2,
  CurrentAccountTransfer = 3,
  Cash = 4,
  Check = 5,
  DirectDebit = 6,
  Pix = 7,
}

export const accountReceivablePaymentMethodLang: Record<
  keyof typeof EAccountReceivablePaymentMethod,
  string
> = {
  Billet: "Boleto",
  CreditCard: "Cartão de Crédito",
  CurrentAccountTransfer: "Transferência em Conta Corrente",
  Cash: "Dinheiro",
  Check: "Cheque",
  DirectDebit: "Débito em Conta Corrente",
  Pix: "Pix",
};

export interface IAccountReceivableParcelListItemEntity extends IEntity {
  // id da conta a receber que originou esta parcela
  accountReceivableId: string;

  // id que referencia que esta conta veio a partir de uma importacao
  accountReceivableImportationDetailId: string | null;

  documentNumber: string;
  serial: string;

  // valor
  value: number;
  valueString: string;

  // valor do lancamento
  launchValue: number;
  launchValueString: string;

  parcelNumber: number;

  documentStatus: EAccountReceivableDocumentStatus | null;
  documentStatusDescription: string;

  // data emissão (timestamp)
  issueDate: string;
  // data emissão (formatada server-side)
  issueDateString: string;

  // pagar ate (timestamp)
  receiveUntil: string;
  // pagar ate (formatada server-side)
  receiveUntilString: string;

  status: EAccountReceivableStatus;
  statusDescription: string;

  returnStatus: EReturnStatus;
  returnStatusDescription: string;

  invoiceStatus: EInvoiceStatus;
  invoiceStatusString: string;
  invoiceServiceDescription: string;
  invoiceDeniedReason: string; // motivo da negacao da NF

  paymentMethod: EAccountReceivablePaymentMethod | null;
  paymentMethodDescription: string;

  description: string;
  observation: string;

  companyId: string;
  companyName: string;

  costCenterId: string;
  costCenterName: string;

  classificationAssessmentId: string;
  classificationAssessmentName: string;

  competencyId: string;
  competencyName: string;

  projectId: string;
  projectName: string;

  customerId: string;
  customerName: string;
  customerDocument: string;

  classificationUspId: string;
  classificationUspName: string;

  classificationAccountId: string;
  classificationAccountName: string;

  paymentAccountId: string;
  paymentAccountName: string;

  // Data de ret. baixa (timestamp)
  chargebackDate: string;
  // Data de ret. baixa (formatada server-side)
  chargebackDateString: string;

  // usuario que retirou a baixa
  chargebackUserId: string;
  chargebackUserName: string;

  // Data de baixa (timestamp)
  terminationDate: string;
  // Data de baixa (formatada server-side)
  terminationDateString: string;

  // Nome do usuário que baixou o lançamento
  terminationUserName: string;
  // Id do usuário que baixou o lançamento
  terminationUserId: string;

  // data de devolucao (timestamp)
  devolutionDate: string;
  // data de devolucao (formtada server-side)
  devolutionDateString: string;

  anyParcelPaid: boolean;
  allParcelsPaid: boolean;
  numberOfParcels: number;

  companyGroupId: string;
  companyGroupName: string;
  isLmsImportation: boolean;

  // propriedades utilizadas na geracao de NFs, ocultas na UI
  // aliquota ISS (%)
  issAliquot: number;

  // ID do Estado prestação de serviço
  serviceSupplyStateId: string;

  // Nome do Estado prestação de serviço
  serviceSupplyStateName: string;

  // ID da Cidade prestação de serviço
  serviceSupplyCityId: string;

  // Nome da Cidade prestação de serviço
  serviceSupplyCityName: string;

  // alíquota IR (%)
  irAliquot: number;

  // alíquota CSLL (%)
  csllAliquot: number;

  // alíquota PIS (%)
  pisAliquot: number;

  // alíquota COFINS (%)
  cofinsAliquot: number;

  // id do CNAE
  cnaeClassificationId: string;

  // Codigo do CNAE
  cnaeClassificationCode: string;

  // id do Enquadramento do Serviço
  municipalActivityClassificationId: string;

  // Codigo do Enquadramento do Servico
  municipalActivityClassifictionCode: string;

  // Data de lancamento (timestamp)
  dateCreated: string;
  // Data de lancamento (formatada server-side)
  dateCreatedString: string;

  userCreated: string;
  userNameCreated: string;

  // Data de modificação (timestamp)
  dateModified: string;
  // Data de modificação (formatada server-side)
  dateModifiedString: string;

  userModified: string;
  userNameModified: string;

  // data de exclusao (timestamp)
  deleteDate: string;
  // data de exclusao (formatada server-side)
  deleteDateString: string;
  // motivo da exclusao
  deleteReason: string;

  deleteUserId: string;
  deleteUserName: string;

  returnValue: number;
  returnValueString: string;

  // data devolucao (timestamp)
  returnDate: string;
  // data devolucao (formatada server-side)
  returnDateString: string;

  returnUserName: string;
  returnUserId: string;
}

export class AccountReceivableListItemEntity
  implements IAccountReceivableParcelListItemEntity
{
  accountReceivableId = "";
  accountReceivableImportationDetailId = null;
  documentNumber = "";
  serial = "";
  value = 0;
  valueString = "";
  launchValue = 0;
  launchValueString = "";
  parcelNumber = 1;
  documentStatus = null;
  documentStatusDescription = "";
  issueDate = "";
  issueDateString = "";
  receiveUntil = "";
  receiveUntilString = "";
  status = EAccountReceivableStatus.Open;
  statusDescription = "";
  returnStatus = EReturnStatus.NotReturned;
  returnStatusDescription = "";
  invoiceStatus = EInvoiceStatus.NotGenerated;
  invoiceStatusString = "";
  invoiceServiceDescription = "";
  invoiceDeniedReason = "";
  paymentMethod = null;
  paymentMethodDescription = "";
  description = "";
  observation = "";
  companyId = "";
  companyName = "";
  costCenterId = "";
  classificationAssessmentId = "";
  classificationAssessmentName = "";
  costCenterName = "";
  competencyId = "";
  competencyName = "";
  projectId = "";
  projectName = "";
  customerId = "";
  customerName = "";
  customerDocument = "";
  classificationUspId = "";
  classificationUspName = "";
  classificationAccountId = "";
  classificationAccountName = "";
  paymentAccountId = "";
  paymentAccountName = "";
  chargebackDate = "";
  chargebackDateString = "";
  chargebackUserId = "";
  chargebackUserName = "";
  terminationDate = "";
  terminationDateString = "";
  terminationUserName = "";
  terminationUserId = "";
  devolutionDate = "";
  devolutionDateString = "";
  anyParcelPaid = false;
  allParcelsPaid = false;
  numberOfParcels = 1;
  companyGroupId = "";
  companyGroupName = "";
  isLmsImportation = false;
  issAliquot = 0;
  cnaeClassificationId = "";
  cnaeClassificationCode = "";
  municipalActivityClassificationId = "";
  municipalActivityClassifictionCode = "";
  dateCreated = "";
  dateCreatedString = "";
  userCreated = "";
  userNameCreated = "";
  dateModified = "";
  dateModifiedString = "";
  userModified = "";
  userNameModified = "";
  deleteDate = "";
  deleteDateString = "";
  deleteReason = "";
  deleteUserId = "";
  deleteUserName = "";
  returnDate = "";
  returnDateString = "";
  returnUserName = "";
  returnUserId = "";
  returnValue = 0;
  returnValueString = "";
  id = "";
  active = true;
  serviceSupplyStateId = "";
  serviceSupplyStateName = "";
  serviceSupplyCityId = "";
  serviceSupplyCityName = "";
  irAliquot = 0;
  csllAliquot = 0;
  pisAliquot = 0;
  cofinsAliquot = 0;

  constructor(init?: Partial<IAccountReceivableParcelListItemEntity>) {
    Object.assign(this, init);
  }
}
