import {
  IRelationshipFilterOption,
  RelationshipFilterOption,
} from "../../../../advTable/domain/entities/advTableColumn";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IUserEntity } from "../../domain/entities/userEntity";
import { ISearchUsersService } from "../../domain/usecases/searchUsersUseCase";

export class SearchUsersService implements ISearchUsersService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private authApi: IApiService,
  ) {}

  async searchUsers(
    search: string,
    fetchMinLength: number,
  ): Promise<IServerSideResponseModel<IRelationshipFilterOption[]>> {
    const userEntity = this.getUserLocalService.get();

    const payload = new PayloadEntity({
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          search: { regex: false, value: "" },
        },
        {
          data: "name",
          name: "name",
          orderable: true,
          searchable: false,
          search: { regex: false, value: "" },
        },
      ],
      order: [
        {
          column: 1,
          dir: "asc",
        },
      ],
      search: {
        regex: false,
        value: search,
      },
      length: fetchMinLength,
    });

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const { REACT_APP_PARTNER_ID } = process.env;

    const url = `/Partners/${REACT_APP_PARTNER_ID}/Users?${params}`;

    const response = await this.authApi.get<
      IServerSideResponseModel<IUserEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel<IRelationshipFilterOption[]>({
      ...response,
      data: response.data.map(user => {
        return new RelationshipFilterOption({
          rawValue: user.id,
          label: user.name,
        });
      }),
    });
  }
}
