import { format } from "date-fns";
import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListExportedFuspAccountsContract } from "../../domain/contracts/listExportedFuspAccountsContract";
import {
  EAccountPayablePaymentMethod,
  EFuspSendingStatus,
  accountPayablePaymentMethodLang,
  fuspSendingStatusLang,
} from "../../domain/entities/accountPayableListItemEntity";
import { IFuspAccountPayableIntegrationEntity } from "../../domain/entities/fuspAccountPayableIntegrationEntity";
import { IFuspAccountPayableIntegrationModel } from "../models/fuspAccountPayableIntegrationModel";

export class ListExportedFuspAccountsService
  implements IListExportedFuspAccountsContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listExportedFuspAccounts(
    companyGroupId: string,
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<IFuspAccountPayableIntegrationEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      orderableStringField: {
        totalValueString: "totalValue",
        operationValueString: "operationValue",
        operationDateString: "operationDate",
        issueDateString: "issueDate",
        documentStatusDescription: "documentStatus",
        statusDescription: "status",
        returnStatusDescription: "returnStatus",
        percentageString: "percentage",
        dateCreatedString: "dateCreated",
        dateModifiedString: "dateModified",
        deleteDateString: "deleteDate",
        typeDescription: "type",
      },
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsOptions = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/Fusp/AccountPayableReadyToSend?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IFuspAccountPayableIntegrationModel[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const brlFmt = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    const mappedResponse: IResponseEntity<
      IFuspAccountPayableIntegrationEntity[]
    > = {
      data: response.data.map<IFuspAccountPayableIntegrationEntity>(row => {
        const dtPayUntil = new Date(row.payUntil);

        const paymentMethodKey = EAccountPayablePaymentMethod[
          row.paymentMethod
        ] as keyof typeof EAccountPayablePaymentMethod;

        const paymentMethodDescription =
          accountPayablePaymentMethodLang[paymentMethodKey] || "";

        const key = EFuspSendingStatus[
          row.fuspSendingStatus || EFuspSendingStatus.PendingSending
        ] as keyof typeof EFuspSendingStatus;

        const fuspSendingStatusDescription = fuspSendingStatusLang[key] || "";

        return {
          ...row,
          valueString: brlFmt.format(row.value),
          payUntilString: format(dtPayUntil, "dd/MM/yyyy"),
          paymentMethodDescription,
          fuspSendingStatusDescription,
          selected: false,
        };
      }),
      recordsFiltered: response.recordsFiltered,
      recordsTotal: response.recordsTotal,
      error: response.error,
    };

    return mappedResponse;
  }
}
