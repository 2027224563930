import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IPaymentAccountEntity } from "../../../transactions/paymentAccountTransactions/domain/entities/paymentAccountEntity";

export interface ISearchPaymentAccountsService {
  searchPaymentAccounts(
    search: string,
    companyGroupId: string,
    companyId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>>;
}

export interface ISearchPaymentAccountsUseCase {
  searchPaymentAccounts(
    search: string,
    companyGroupId: string,
    companyId: string,
    active?: boolean,
  ): Promise<IServerSideResponseModel<IPaymentAccountEntity[]>>;
}

export class SearchPaymentAccountsUseCase
  implements ISearchPaymentAccountsUseCase
{
  constructor(
    private searchPaymentAccountsService: ISearchPaymentAccountsService,
  ) {}

  searchPaymentAccounts(
    search: string,
    companyGroupId: string,
    companyId: string,
    active?: boolean,
  ) {
    return this.searchPaymentAccountsService.searchPaymentAccounts(
      search,
      companyGroupId,
      companyId,
      active,
    );
  }
}
