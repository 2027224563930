import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  ClassificationAssessmentEntity,
  IClassificationAssessmentEntity,
} from "../../domain/entities/classificationAssessment";
import { IClassificationAssessmentFormEntity } from "../../domain/entities/classificationAssessmentFormEntity";
import { ISaveClassificationAssessmentService } from "../../domain/usecases/saveClassificationAssessmentUseCase";
import { IClassificationAssessmentModel } from "../models/classificationAssessmentModel";

export class SaveClassificationAssessmentService
  implements ISaveClassificationAssessmentService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async saveClassificationAssessment(
    classificationAssessmentEntity: IClassificationAssessmentFormEntity,
  ): Promise<IClassificationAssessmentEntity> {
    const userEntity = this.getUserLocalService.get();
    const url = `/ClassificationAssessments`;

    let response;

    const payload: IClassificationAssessmentModel = {
      id: classificationAssessmentEntity.id,
      active: classificationAssessmentEntity.active,
      name: classificationAssessmentEntity.name,
      type: classificationAssessmentEntity.type,
      paymentType: classificationAssessmentEntity.paymentType,
      companyGroupId: classificationAssessmentEntity.companyGroupId,
      companyGroupName: classificationAssessmentEntity.companyGroupName,
      macroBoxId:
        classificationAssessmentEntity.macroCategory?.rawValue.toString() || "",
      macroBoxName: classificationAssessmentEntity.macroCategory?.label || "",
    };

    if (payload.id === "") {
      response = await this.api.post<IClassificationAssessmentEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    } else if (payload.id !== "") {
      response = await this.api.put<IClassificationAssessmentEntity>(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userEntity?.token}`,
          },
        },
      );
    }

    return new ClassificationAssessmentEntity(response);
  }
}
