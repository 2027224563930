import { IApiService } from "../../../core/data/services/apiService";
import { IEnum } from "../../../core/domain/entities/enum";
import { IUserEntity } from "../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetProviderEnumsContract } from "../../domain/contracts/getProviderEnumsContract";

export class GetProviderEnumsService implements IGetProviderEnumsContract {
  private readonly endpoint = "/Enums";
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async getProviderEnums() {
    const [
      banks,
      sexTypes,
      providerTypes,
      maritalStatus,
      professorTypes,
      teacherRegimes,
      bankAccountTypes,
    ] = await Promise.all([
      this.getBanks(),
      this.getSexTypes(),
      this.getProviderTypes(),
      this.getMaritalStatus(),
      this.getProfessorTypes(),
      this.getTeacherRegimes(),
      this.getBankAccountTypes(),
    ]);

    return {
      banks,
      sexTypes,
      maritalStatus,
      providerTypes,
      professorTypes,
      teacherRegimes,
      bankAccountTypes,
    };
  }

  private getBanks() {
    const url = `${this.endpoint}/Banks`;

    return this.api.get<{ key: number; value: string }[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getSexTypes() {
    const url = `${this.endpoint}/SexTypes`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getMaritalStatus() {
    const url = `${this.endpoint}/MaritalStatus`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getBankAccountTypes() {
    const url = `${this.endpoint}/BankAccountTypes`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getProviderTypes() {
    const url = `${this.endpoint}/SupplierTypes`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getProfessorTypes() {
    const url = `${this.endpoint}/ProfessorTypes`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getTeacherRegimes() {
    const url = `${this.endpoint}/TeacherRegime`;

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }
}
