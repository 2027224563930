import { Controller } from "react-hook-form";
import { ChangeEvent } from "react";
import { useSolicitImportPage } from "../../../hooks/useSolicitImportPage";
import { IFormComponentProps } from "../../../../domain/entities/solicitImportTypes";
import { FieldWrapper } from "../../FieldWrapper";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";

export function DescriptionField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.description` as const;

  const { handleInputClassName } = useSolicitImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true, maxLength: 255 }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          const eventClone = { ...event };
          const cursorEnd = event.target.selectionEnd;
          const eventValue = event.target.value?.toUpperCase() || "";

          eventClone.target.value = eventValue;
          eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

          field.onBlur();
          field.onChange(eventClone);
        };
        return (
          <label className="form-control">
            <span>Descrição</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <input
                {...field}
                onChange={onChange}
                placeholder="Descrição"
                id={`txt-description-${formIndex}`}
                data-testid={`txt-description-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="Este campo tem um limite de 255 caracteres."
              condition={fieldState?.error?.type === "maxLength"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
