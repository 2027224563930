import { useMemo } from "react";
import {
  ISimpleHiddenColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";

export function useClassificationAccountGrid() {
  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "code",
        header: "Código",
        orderable: true,
      },
      {
        field: "name",
        header: "Nome",
        orderable: true,
      },
      {
        field: "typeDescription",
        header: "Tipo",
        orderable: true,
        sortField: "type",
      },
      {
        field: "type",
        hidden: true,
      } as ISimpleHiddenColumn,
    ].map(colParams => new SimpleColumn(colParams));
  }, []);

  return { columns };
}
