import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { ColumnProps } from "primereact/column";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { MakeImportationMatches } from "../../../main/makeImportationMatches";
import { IImportationMatchesPaymentAccountEntity } from "../../../domain/entities/importationMatchesPaymentAccountEntitty";
import { Container } from "./style";
import { IImportationMatchesBankEntity } from "../../../domain/entities/importationMatchesBankEntity";
import {
  EImportationMatchesOrigin,
  EImportationMatchesType,
} from "../../../domain/entities/importationMatchesPayloadEntity";
import { BanksGrid } from "../BanksGrid";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";

export interface IPaymentAccountBanksForm {
  banks: IImportationMatchesBankEntity[];
}

export type PaymentAccountBanksModalProps = {
  isOpen: boolean;
  origin: EImportationMatchesOrigin;
  useImportationMatches: MakeImportationMatches;
  onClose: (shoulRefresh: boolean | unknown) => void;
  currentPaymentAccount?: IImportationMatchesPaymentAccountEntity;
};

export function PaymentAccountBanksModal(props: PaymentAccountBanksModalProps) {
  const {
    origin,
    isOpen,
    onClose,
    useImportationMatches,
    currentPaymentAccount,
  } = props;

  const {
    saveImportationMatches,
    getPaymentAccountBanks,
    listImportationMatchesBanks,
  } = useImportationMatches;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const currentId = currentPaymentAccount?.id ?? "";
  const name = currentPaymentAccount?.name ?? "";

  const dialog = useSoulDialog();

  const [isLoading, setIsLoading] = useState(false);

  /** Armazena dados iniciais da tabela. */
  const [banksData, setBanksData] = useState({
    list: [] as IImportationMatchesBankEntity[],
    selected: [] as IImportationMatchesBankEntity[],
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<IPaymentAccountBanksForm>({
    defaultValues: {
      banks: [],
    },
  });

  const columns = useMemo(() => {
    const temp: ColumnProps[] = [
      {
        header: "",
        field: "company",
        sortable: false,
        style: {
          width: "34%",
        },
      },
      {
        header: "",
        field: "bank",
        sortable: false,
        style: {
          width: "33%",
        },
      },
      {
        header: "",
        field: "bankBranch",
        sortable: false,
        style: {
          width: "33%",
        },
        body(data: IImportationMatchesBankEntity) {
          return `${data.bankBranch} / ${data.bankAccount}`;
        },
      },
    ];
    return temp;
  }, []);

  /**
   * Lida com a abertura do modal, carregando os dados da tabela e de bancos
   * vinculados à atual conta de pagamento.
   */
  const handleModalOpening = useCallback(async () => {
    if (!currentId) return;

    setIsLoading(true);

    try {
      const banksListResponse = await listImportationMatchesBanks(origin);
      const matchedBanksResponse = await getPaymentAccountBanks(
        currentId,
        origin,
      );

      const selectedBanks = banksListResponse.filter(bank => {
        return matchedBanksResponse.find(
          matched => matched.sourceId === bank.id,
        );
      });

      setBanksData({
        selected: selectedBanks,
        list: banksListResponse,
      });

      reset({ banks: selectedBanks });
    } catch (error) {
      onClose(false);
    } finally {
      setIsLoading(false);
    }
  }, [
    reset,
    origin,
    onClose,
    currentId,
    getPaymentAccountBanks,
    listImportationMatchesBanks,
  ]);

  /**
   * Envia os dados de vínculos para o servidor.
   */
  const submitMatches = useCallback(
    async (value: IPaymentAccountBanksForm) => {
      const sourceData = value.banks.map(bank => ({
        sourceId: bank.id,
        sourceName: bank.bank,
      }));

      const payload = {
        companyGroupId: id,
        origin,
        sourceData,
        destinationName: name,
        destinationId: currentId,
        type: EImportationMatchesType.PAYMENT_ACCOUNT,
      };

      await saveImportationMatches(payload);

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Vínculo(s) atualizado(s) com sucesso!",
      });

      onClose(true);
    },
    [currentId, dialog, id, name, onClose, origin, saveImportationMatches],
  );

  /**
   * Lida com o fechamento do modal, resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    reset({ banks: [] });
    setBanksData({
      list: [],
      selected: [],
    });
    onClose(false);
  }, [onClose, reset]);

  /**
   * Lida com a seleção da tabela, atualizando os valores do formulário.
   */
  const handleSelection = useCallback(
    (selection: IImportationMatchesBankEntity[]) => {
      setValue("banks", selection, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isLoading}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>
            Vínculo de Conta de Pagamento (Soul) e Banco (
            {origin === EImportationMatchesOrigin.LMS ? "LMS" : "Solution"})
          </h4>
          {!isLoading ? (
            <button
              type="button"
              onClick={onClose}
              id="btn-banks-cross"
              data-testid="btn-banks-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="banks-header">
              <h3>{name}</h3>
              <p>
                Clique sobre o banco que deseja vincular à esta conta e depois
                clique em &quot;Salvar&quot;.
              </p>
            </div>
            <div className="react-modal-body">
              <form
                id="payment-account-banks-form"
                onSubmit={handleSubmit(submitMatches)}
              />
              <BanksGrid
                columns={columns}
                tableData={banksData}
                onSelect={handleSelection}
              />
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-banks-close"
                data-testid="btn-banks-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-banks-save"
                data-testid="btn-banks-save"
                form="payment-account-banks-form"
                disabled={isSubmitting || !isDirty}
                className={`form-button ${
                  isDirty ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
