import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  background: #f6f9fc;
  z-index: 1;

  > .wrapper {
    display: flex;
    width: 71.25rem;
    margin: 0 auto;
    padding: 1rem;
  }

  .raw-col,
  .release-col {
    flex: 1 1;

    .content {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      svg {
        fill: var(--soul-color01);
        width: 5rem;
        height: 5rem;
        margin-top: 1rem;
      }

      img {
        width: 6.5rem;
      }
    }
  }

  .raw-col {
    display: flex;
    align-items: stretch;
    height: 100%;

    .chk-box {
      display: flex;
      width: 1.375rem;
      margin-right: 1rem;
      justify-content: end;
      align-items: end;
    }
  }

  .release-col {
    display: flex;
    align-items: start;

    .wrapper {
      display: flex;
      flex: 1;

      .buttons {
        width: 1.665rem;
        margin: 0 0.5rem;
      }
    }
  }
`;
