import { useCallback, useState } from "react";
import { useColumnFilter } from "../../hooks/useColumnFilter";
import { Container } from "./styles";

export function ColumnFilterNumeric() {
  const { setFilterValue, filterValue } = useColumnFilter();

  const [numValue, setNumValue] = useState(() => {
    return (filterValue as number) ?? 0;
  });

  const formatNumber = useCallback((numVal: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numVal);
  }, []);

  const handleOnChange = useCallback(
    ({ target: { value } }) => {
      const strVal = value.replace(/\D/g, "");
      const numVal = Number(strVal) / 100;

      setNumValue(numVal);
      setFilterValue?.(numVal);
    },
    [setFilterValue],
  );

  const handleOnFocus = useCallback(
    event => event.target.setSelectionRange(0, event.target.value.length),
    [],
  );

  return (
    <Container>
      <div className="p-inputgroup">
        <input
          ref={ref => {
            ref?.focus?.();
          }}
          className="p-inputtext p-component"
          value={formatNumber(numValue)}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          placeholder="Digite algo..."
        />
      </div>
    </Container>
  );
}
