import { IApiService } from "../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateCommercialProposalForm } from "../../../../../core/domain/entities/generateCommercialProposalForm";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../../../../provider/domain/entities/providerEntity";
import { IGenerateMultipleCommercialProposalAttachmentsContract } from "../../domain/contracts/generateMultipleCommercialProposalAttachmentsContract";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { IDebtImportAttachmentModel } from "../models/debtImportAttachmentModel";
import { MainDebtImportService } from "./mainDebtImportService";

export class GenerateMultipleCommercialProposalAttachmentsService
  extends MainDebtImportService
  implements IGenerateMultipleCommercialProposalAttachmentsContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async generateMultipleCommercialProposalAttachments(
    params: IGenerateCommercialProposalForm,
    importData: IDebtImportEntity[],
  ) {
    const url =
      "/AccountPayableImportations/Attachments/CommercialProposalFusp";
    const userEntity = this.getUserLocalService.get();

    const formData = new FormData();

    importData
      .filter(data => {
        const provider = data?.provider?.metadata as IProviderEntity;

        return provider?.documentType === ProviderDocumentType.CPF;
      })
      .map((data, index) => {
        formData.append(`Uploads[${index}].Name`, "");
        formData.append(`Uploads[${index}].Active`, "true");
        formData.append(
          `Uploads[${index}].Type`,
          EAttachmentType.CommercialProposal.toString(),
        );
        formData.append(
          `Uploads[${index}].TypeDescription`,
          "Proposta Comercial",
        );
        formData.append(`Uploads[${index}].AccountPayableId`, data.id);
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ProjectId]`,
          (data?.project?.rawValue as string) || "",
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ProviderId]`,
          (data?.provider?.rawValue as string) || "",
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ValueMoney]`,
          String(data?.value || 0),
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ClassQuantity]`,
          `${params?.classQuantity || ""}`,
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ExecutionDeadline]`,
          `${params.executionDeadline}`,
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ProposalValidityPeriod]`,
          `${params.proposalValidityPeriod}`,
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ProvisionLocation]`,
          `${params.provisionLocation}`,
        );
        formData.append(
          `Uploads[${index}].CommercialProposalFuspData[ServiceProposalObjectType]`,
          `${params.serviceProposalObjectType}`,
        );
        return data;
      });

    return this.api.post<IDebtImportAttachmentModel[]>(url, formData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });
  }
}
