import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { IDeleteUspIncomeStatementReportPanoramaContract } from "../../domain/contracts/deleteUspIncomeStatementReportPanoramaContract";

export class DeleteUspIncomeStatementReportPanoramaService
  implements IDeleteUspIncomeStatementReportPanoramaContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async deleteUspIncomeStatementReportPanorama(panoramaId: string) {
    const url = `/UspIncomeStatementReportPanoramas/${panoramaId}`;
    const userEntity = this.getUserLocalService.get();

    await this.api.delete(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
