import { ICostCenterReportEntity } from "../entities/costCenterReportEntity";

export interface IToggleCostCenterReportervice {
  toggleCostCenterReport(
    costCenterReportId: string,
  ): Promise<ICostCenterReportEntity>;
}

export interface IToggleCostCenterReportUseCase {
  toggleCostCenterReport(
    costCenterReportId: string,
  ): Promise<ICostCenterReportEntity>;
}

export class ToggleCostCenterReportUseCase
  implements IToggleCostCenterReportUseCase
{
  constructor(
    private toggleCostCenterReportService: IToggleCostCenterReportervice,
  ) {}

  toggleCostCenterReport(
    costCenterReportId: string,
  ): Promise<ICostCenterReportEntity> {
    return this.toggleCostCenterReportService.toggleCostCenterReport(
      costCenterReportId,
    );
  }
}
