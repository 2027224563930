import { FaRunning } from "react-icons/fa";
import { Link } from "react-router-dom";
import logoSoul from "../../../../assets/logo-soul-160x154.png";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { PageTitle } from "../AdminPage/styles";
import { Container } from "./styles";
import { CompanyGroupDropdown } from "../CompanyGroupDropdown";

interface NavbarProps {
  title: string;
}

export function Navbar({ title }: NavbarProps) {
  const { user } = useUserLocal();

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <PageTitle>{title}</PageTitle>
          <CompanyGroupDropdown />

          <SoulDropdown
            toggler={
              <div className="context-selector">
                <div className="context-logo">
                  <img src={logoSoul} alt="ícone Soul" />
                </div>
                <span title={user?.name} className="context-title">
                  {user?.name}
                </span>
              </div>
            }
            headerText="Bem-vindo(a)!"
          >
            <Link to="/login" className="dropdown-item" id="btn-logout">
              <FaRunning />
              <span>Sair</span>
            </Link>
          </SoulDropdown>
        </div>
      </div>
    </Container>
  );
}
