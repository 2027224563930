import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import {
  EConciliationOrigin,
  IConciliationEntity,
  IConciliationEntryEntity,
} from "../../../domain/entitites/conciliationEntity";
import { ConciliationButtons } from "../ConciliationButtons";
import { ConciliationCard } from "../ConciliationCard";
import { ConciliationCardEmpty } from "../ConciliationCardEmpty";
import { Container } from "./styles";

interface IConciliationRowProps {
  rowIndex: number;
  disabled?: boolean;
  conciliation: IConciliationEntity;
  checkboxOnChange(row: number, checked: boolean): void;
  conciliateButtonOnClick(
    row: number,
    index: number,
    conciliationRow: IConciliationEntryEntity,
  ): void;
}

export function ConciliationRow({
  rowIndex,
  disabled = false,
  conciliation: {
    foundAccounts: conciliationEntryList,
    providedAccount: conciliationEntryRaw,
    ...rest
  },
  checkboxOnChange,
  conciliateButtonOnClick,
}: IConciliationRowProps) {
  const handleCheckboxChange = ({ checked }: CheckboxChangeParams) => {
    checkboxOnChange(rowIndex, checked);
  };

  const handleViewButtonClick = (id: string, origin: EConciliationOrigin) => {
    let path = `/accountsPayable/${id}`;

    if (origin === EConciliationOrigin.revenue) {
      path = `/accountsReceivable/${id}`;
    }

    window.open(path);
  };

  const handleConciliateButtonClick = (
    row: number,
    index: number,
    conciliationReleaseEntry: IConciliationEntryEntity,
  ) => {
    conciliateButtonOnClick(row, index, conciliationReleaseEntry);
  };

  return (
    <Container>
      <div className="raw-card">
        <div
          className={`chk-box ${
            conciliationEntryList.length === 0 ? "hidden" : undefined
          }`.trim()}
        >
          <Checkbox
            disabled={disabled}
            checked={rest.checked}
            onChange={handleCheckboxChange}
          />
        </div>
        <ConciliationCard
          rowNumber={conciliationEntryRaw.sheetRowNumber}
          conciliationEntry={conciliationEntryRaw}
          hideParcelNumber
        />
      </div>
      <div className="release-cards">
        {conciliationEntryList.length === 0 && (
          <div className="wrapper">
            <ConciliationButtons
              disabled={disabled}
              className="buttons hidden"
            />
            <ConciliationCardEmpty />
          </div>
        )}
        {conciliationEntryList.length > 0 &&
          conciliationEntryList.map((conciliationReleaseEntry, index) => {
            const count = index;

            return (
              <div
                key={`${conciliationReleaseEntry.id}-${count}`}
                className="wrapper"
              >
                <ConciliationButtons
                  row={rest.rowIndex}
                  index={index}
                  disabled={disabled}
                  conciliationReleaseEntry={conciliationReleaseEntry}
                  conciliateButtonOnClick={handleConciliateButtonClick}
                  viewButtonOnClick={handleViewButtonClick}
                  className="buttons"
                />
                <ConciliationCard
                  conciliationEntry={conciliationReleaseEntry}
                />
              </div>
            );
          })}
      </div>
    </Container>
  );
}
