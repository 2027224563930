import { IGetAddressByZipCodeContract } from "../../domain/contracts/getAddressByZipCodeContract";
import { IUserEntity } from "../../domain/entities/userEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { IApiService } from "./apiService";

interface IViaCepResponse {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  unidade?: string;
  ibge?: string;
  gia?: string;
  erro?: string;
}

interface IAddressEntityResponse {
  id: string;
  name: string;
}

export class GetAddressByZipCodeService
  implements IGetAddressByZipCodeContract
{
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
    private viaCepApi: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async getAddressByZipCode(zipCode: string) {
    const url = `/ws/${zipCode}/json/`;

    const viaCepData = await this.viaCepApi.get<IViaCepResponse>(url);

    if (viaCepData?.erro || !viaCepData?.uf || !viaCepData?.ibge) {
      return null;
    }

    const stateUrl = `/States/UF/${viaCepData.uf}`;
    const cityUrl = `/Cities/IBGE/${viaCepData.ibge}`;

    const state = await this.getAddressEntity(stateUrl);
    const city = await this.getAddressEntity(cityUrl);

    if (!state || !city) {
      return null;
    }

    return {
      city,
      state,
      street: viaCepData?.logradouro || "",
      neighborhood: viaCepData?.bairro || "",
    };
  }

  private async getAddressEntity(url: string) {
    const data = await this.api.get<IAddressEntityResponse>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      label: data.name,
      rawValue: data.id,
    };
  }
}
