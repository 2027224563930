import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IGenerateReportByCostCenterContract,
  IGenerateReportByCostCenterParams,
} from "../../domain/contracts/generateReportByCostCenterContract";

export class GenerateReportByCostCenterService
  implements IGenerateReportByCostCenterContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateReportByCostCenter({
    formValues,
    onDownloadProgress,
  }: IGenerateReportByCostCenterParams): Promise<void> {
    const userEntity = this.getUserLocalService.get();
    const url = "/Downloads/PaymentRequestReport/SpreadSheets";

    const dateInitialDate = parse(
      formValues.InitialFilterDate,
      "ddMMyyyy",
      new Date(),
    );

    const dateFinalDate = parse(
      formValues.FinalFilterDate,
      "ddMMyyyy",
      new Date(),
    );

    const InitialFilterDate = format(dateInitialDate, "yyyy-MM-dd");
    const FinalFilterDate = format(dateFinalDate, "yyyy-MM-dd");

    const params = {
      ...formValues,
      InitialFilterDate,
      FinalFilterDate,
    };

    const response = await this.api.download?.(url, {
      params,
      onDownloadProgress,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  private downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    FileSaver.saveAs(blob, fileName);
  }
}
