import { IEntity } from "../../../core/domain/entities/entity";

export enum EProfessorType {
  Empty = "",
  Usp = 0,
  NotUsp = 1,
}

export enum ProviderType {
  foreign = 0, // estrangeiro
  individualPerson = 1, // pessoa fisica
  legalPerson = 2, // pessoa juridica
}

export enum ProviderDocumentType {
  other = 0,
  CPF = 1,
  CNPJ = 2,
}

export enum IBankAccountType {
  checkingAccount = 1,
  savingAccount = 2,
}

export enum ETeacherRegime {
  EightHours = 1,
  TwelveHours = 2,
  Retiree = 3,
  RDIDP = 4,
  RTC = 5,
  RTP = 6,
}

export enum ESexType {
  Male = 1,
  Female = 2,
}

export enum EMaritalStatus {
  Unmarried = 1,
  Married = 2,
  Divorced = 3,
  Widower = 4,
  Others = 5,
}

export interface IProviderEntity extends IEntity {
  retainFee: boolean;

  typeDescription?: string;
  type: ProviderType; // tipo fornecedor
  professorType: EProfessorType; // tipo de professor

  document: string; // numero do documento
  documentType: ProviderDocumentType; // tipo do documento (RG | CNPJ)

  name: string; // nome | nome fantasia
  corporationName?: string; // razao social

  phoneNumber: string;
  email: string;
  municipalRegistration: string; // inscricao municipal
  stateRegistration: string; // inscricao estadual
  isStateRegistrationExempt?: boolean; // inscricao estadual isento

  birthDate?: string;
  identityCard?: string;
  pisPasep?: string;
  profession?: string;

  // Dados bancarios
  documentHolder?: string;
  bank?: number; // numero do banco
  bankDescription?: string;
  bankBranch?: string; // numero da agencia
  bankBranchDigit?: string; // digito da agencia
  bankAccountType?: IBankAccountType; // tipo de conta bancaria
  bankAccount?: string; // numero da conta
  bankAccountDigit?: string; // digito da conta

  countryId: string;
  countryName: string;

  zipCode: string; // cep
  street: string; // rua
  placeNumber: string; // numero

  stateId: string;
  stateName: string;

  cityId: string;
  cityName: string;

  neighborhood: string; // bairro
  complement: string; // complemento

  companyGroupId: string;
  companyGroupName?: string;
  hasCompanyTax?: boolean;

  sexType?: ESexType;
  validityRNE?: string;
  issuingAuthority?: string;
  landlinePhoneNumber?: string;
  maritalStatus?: EMaritalStatus;
  teacherRegime?: ETeacherRegime;
}

export interface ICheckDocumentEntity {
  Code?: number;
  message?: string;
  success?: boolean;
}
