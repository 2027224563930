import { FilterMatchMode } from "primereact/api";
import { Column, ColumnBodyOptions } from "primereact/column";
import {
  DataTable,
  DataTableColReorderParams,
  DataTableColumnResizeEndParams,
  DataTableColumnResizeModeType,
  DataTableDataSelectableParams,
  DataTableEmptyMessageType,
  DataTableRowClassNameOptions,
  DataTableRowClickEventParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import {
  ForwardedRef,
  forwardRef,
  Ref,
  UIEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IEntity } from "../../../../core/domain/entities/entity";
import {
  DataTableFilterMeta,
  DataTableMultiSortMetaType,
  DataTableSortMeta,
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleCustomColumn,
  ISimpleHiddenColumn,
  ISimpleSearchableColumn,
  ISimpleSortableColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  columnFilterOperationTypeDict,
  IColumnData,
  IColumnFilterData,
  IResizeableColumn,
  ITypedColumn,
} from "../../../domain/entities/advTableColumn";
import { useAdvTableSettings } from "../../hooks/useAdvTable";
import { useCurrentFilterOverlayPanelRef } from "../../hooks/useCurrentFilterOverlayPanelRef";
import { AdvTableHeader } from "../AdvHeader";
import { Container } from "./styles";

type SelectionModeType = "single" | "multiple" | "checkbox" | "radiobutton";

export interface IDataTableRowClickEventParams<T>
  extends DataTableRowClickEventParams {
  data: T;
}

interface BaseAdvTableProps<T> {
  selection?: T[];
  loading: boolean;
  selectable?: boolean;
  rowsDefault?: number;
  columns: SimpleTableColumn[];
  rowsPerPageOptions?: number[];
  selectionMode?: SelectionModeType;
  globalFilter?: string | undefined;
  panoramaFilters?: DataTableFilterMeta;
  panoramaSort?: DataTableMultiSortMetaType;
  data: IResponseEntity<T[]> | undefined;
  emptyMessage?: DataTableEmptyMessageType;
  columnResizeMode?: DataTableColumnResizeModeType;
  onSelectionChange?(e: DataTableSelectionChangeParams): void;
  onRowDoubleClick?(e: DataTableRowClickEventParams): void;
  onColReorder?(e: DataTableColReorderParams): void;
  getList?(params: IPFSEventEntity): void;
  onPage?(params: IPFSEventEntity): void;
  onFilter?(params: IPFSEventEntity): void;
  onClear?(params: IPFSEventEntity): void;
  onSort?(params: IPFSEventEntity): void;
  onRowClick?(event: IDataTableRowClickEventParams<T>): void;
  rowClassName?(
    data: T,
    options?: DataTableRowClassNameOptions,
  ): object | string;
  isDataSelectable?(
    event: DataTableDataSelectableParams,
  ): boolean | undefined | null;
  selectionAutoFocus?: boolean;
}

type RemovealbeAdvTableProps =
  | {
      removeableColumns: boolean;
      selectedColumns: SimpleTableColumn[];
      onColumnRemove(column: SimpleTableColumn): void;
    }
  | {
      removeableColumns?: never;
      selectedColumns?: never;
      onColumnRemove?: never;
    };

type AdvTableProps<T> = BaseAdvTableProps<T> & RemovealbeAdvTableProps;

export interface IAdvTableHandle {
  reload(): void;
  resetFilters(): void;
  applyPanorama(): void;
}

// forwardRef é necessário para podermos usar ref e acessar o imperativeHandle
const AdvTableWrapper = forwardRef(function AdvTableRef<T>(
  {
    data,
    columns,
    loading,
    panoramaSort,
    selection = [],
    panoramaFilters,
    rowsDefault = 10,
    selectable = false,
    rowsPerPageOptions = [10, 25, 50],
    globalFilter = undefined,
    removeableColumns = false,
    selectionMode = "multiple",
    columnResizeMode = "expand",
    selectedColumns = [...columns],
    emptyMessage = "Nenhum registro encontrado.",
    onSelectionChange,
    isDataSelectable,
    onRowDoubleClick,
    onColumnRemove,
    rowClassName,
    onColReorder,
    onRowClick,
    onFilter,
    getList,
    onClear,
    onPage,
    onSort,
    selectionAutoFocus = true,
  }: AdvTableProps<T>,
  forwardedRef: ForwardedRef<IAdvTableHandle>,
) {
  const { paginatorTemplate } = useAdvTableSettings();
  const [rows, setRows] = useState(() => {
    return rowsDefault ?? rowsPerPageOptions?.[0];
  });

  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(() => {
    let filters = {
      global: {
        value: globalFilter ?? "",
        matchMode: FilterMatchMode.CONTAINS,
      },
    };

    let multiSortMeta;

    if (panoramaFilters) {
      filters = { ...filters, ...panoramaFilters };
    }

    if (panoramaSort) {
      multiSortMeta = [...panoramaSort];
    }

    return new PFSEventEntity({
      globalFilter: globalFilter ?? "",
      multiSortMeta,
      first: 0,
      filters,
      rows,
    });
  });

  const rowClass = useCallback((rowData: IEntity) => {
    const { active } = rowData;
    return {
      "row-disabled": !active,
    };
  }, []);

  const handleOnPage = useCallback(
    pageEvent => {
      setRows(pageEvent.rows);

      const computedPfsEvent = {
        ...pfsEvent,
        first: pageEvent.first,
        rows: pageEvent.rows,
        globalFilter: globalFilter ?? "",
        filters: {
          ...pfsEvent.filters,
          global: {
            ...pfsEvent.filters.global,
            value: globalFilter ?? "",
          },
        },
      };

      setPfsEvent(computedPfsEvent);
      onPage?.(computedPfsEvent);
    },
    [globalFilter, onPage, pfsEvent],
  );

  const handleOnFilter = useCallback(
    ({ column, operation, value }: IColumnFilterData) => {
      const { filters } = pfsEvent;
      const searchField = column.searchField || column.field;

      filters[searchField] = {
        matchMode: columnFilterOperationTypeDict[operation],
        value,
      };

      const computedPfsEvent = {
        ...pfsEvent,
        globalFilter: globalFilter ?? "",
        filters: {
          ...filters,
        },
      };

      setPfsEvent(computedPfsEvent);
      onFilter?.(computedPfsEvent);
    },
    [globalFilter, onFilter, pfsEvent],
  );

  const handleOnSort = useCallback(
    (fieldName, order) => {
      let { multiSortMeta } = pfsEvent;

      const hasField = multiSortMeta?.some(({ field }) => field === fieldName);

      // se o campo ja esta presente
      if (hasField) {
        // e a ordem for diferente de 0, atualizamos a ordem do campo
        if (order !== 0) {
          const sortMeta = multiSortMeta?.find(
            ({ field }) => field === fieldName,
          );

          if (sortMeta) {
            sortMeta.order = order;
          }
        }
        // e a ordem for igual a zero removemos o campo
        else {
          const sortMetaIndex = multiSortMeta?.findIndex(
            ({ field }) => field === fieldName,
          );

          if (sortMetaIndex !== -1 && sortMetaIndex !== null) {
            multiSortMeta?.splice(sortMetaIndex as number, 1);
          }
        }
      }
      // se o campo nao esta presente, adicionamos ele
      else {
        const sortField = {
          field: fieldName,
          order,
        };

        // se ja temos campos ordenados, incluimos o novo no array
        if (multiSortMeta?.length) {
          multiSortMeta = [...multiSortMeta, sortField];
        }
        // senao, comecamos um novo array de campos ordenados
        else {
          multiSortMeta = [sortField];
        }
      }

      // se nao ha campos a serem ordenados removemos a propriedade
      if (multiSortMeta?.length === 0) {
        multiSortMeta = undefined;
      }

      const computedPfsEvent = {
        ...pfsEvent,
        globalFilter: globalFilter ?? "",
        multiSortMeta,
        filters: {
          ...pfsEvent.filters,
          global: {
            ...pfsEvent.filters.global,
            value: globalFilter ?? "",
          },
        },
      };

      setPfsEvent(computedPfsEvent);
      onSort?.(computedPfsEvent);
    },
    [globalFilter, onSort, pfsEvent],
  );

  const handleOnClear = useCallback(
    (column: IColumnData) => {
      const searchedColumn = column as ISimpleSearchableColumn;
      const sortedColumn = column as ISimpleSortableColumn;

      const { filters } = pfsEvent;
      let { multiSortMeta } = pfsEvent;

      const searchField = searchedColumn.searchField || searchedColumn.field;
      const hasSearchedField =
        (filters &&
          Object.keys(filters).some(fieldName => searchField === fieldName)) ??
        false;

      // se tem campo com valor filtrado removemos
      if (hasSearchedField) {
        delete filters[searchField];
      }

      const sortField = sortedColumn.sortField || sortedColumn.field;
      const hasSortedField =
        multiSortMeta?.some(({ field }) => field === sortField) ?? false;

      // se tem coluna ordenada removemos a ordenacao
      if (hasSortedField) {
        const sortMetaIndex = multiSortMeta?.findIndex(
          ({ field }) => field === sortField,
        );

        if (sortMetaIndex !== -1 && sortMetaIndex !== null) {
          multiSortMeta?.splice(sortMetaIndex as number, 1);
        }
      }

      // se nao ha campos a serem ordenados removemos a propriedade
      if (multiSortMeta?.length === 0) {
        multiSortMeta = undefined;
      }

      const computedPfsEvent = {
        ...pfsEvent,
        globalFilter: globalFilter ?? "",
        multiSortMeta,
        filters: {
          ...filters,
          global: {
            ...filters.global,
            value: globalFilter ?? "",
          },
        },
      };

      setPfsEvent(computedPfsEvent);
      onClear?.(computedPfsEvent);
    },
    [globalFilter, onClear, pfsEvent],
  );

  const renderBody = useCallback(
    (rowData: Record<string, unknown>, { field }: ColumnBodyOptions) => {
      const value = rowData[field] ?? "";
      const content = `${value}`;
      return <span title={content}>{content}</span>;
    },
    [],
  );

  const { currentFilterOverlayPanelRef, setCurrentFilterOverlayPanelRef } =
    useCurrentFilterOverlayPanelRef();

  const tableRef = useRef<HTMLElement>();

  const renderHeader = useCallback(
    (columnDef: SimpleTableColumn) => {
      const { header, field } = columnDef as ISimpleColumn;
      const { sortField } = columnDef as ISimpleSortableColumn;
      const { searchField } = columnDef as ISimpleSearchableColumn;
      const { multiSortMeta, filters } = pfsEvent;

      const sortedField = sortField || field;
      const searchedField = searchField || field;

      const sortMeta = multiSortMeta?.find(
        ({ field: _field }) => _field === sortedField,
      );

      const sort = sortMeta?.order ?? 0;
      const filter = filters[searchedField] ?? undefined;

      const onRemove = () => {
        onColumnRemove?.(columnDef);
      };

      return header ? (
        <AdvTableHeader
          key={field}
          sort={sort}
          tableRef={tableRef}
          filter={filter}
          column={columnDef}
          removable={removeableColumns}
          onFilter={handleOnFilter}
          onRemove={onRemove}
          onClear={handleOnClear}
          onSort={handleOnSort}
          onOpen={overlayPanelRef => {
            setCurrentFilterOverlayPanelRef(overlayPanelRef);
          }}
        />
      ) : (
        ""
      );
    },
    [
      pfsEvent,
      removeableColumns,
      handleOnFilter,
      handleOnClear,
      handleOnSort,
      onColumnRemove,
      setCurrentFilterOverlayPanelRef,
    ],
  );

  const handleOnColumnResizeEnd = ({
    element: { offsetWidth },
    column: {
      props: { field },
    },
  }: DataTableColumnResizeEndParams) => {
    const resizedCol = selectedColumns.find(selCol => selCol.field === field);

    if (resizedCol) {
      const simpleColumn = resizedCol as ISimpleColumn;

      simpleColumn.width = `${offsetWidth}px`;
    }
  };

  const handleOnRowClick = (event: IDataTableRowClickEventParams<T>) => {
    const element = event.originalEvent.target as HTMLElement;

    if (element.closest(".soul-chkbox-col")) {
      return;
    }

    onRowClick?.(event);
  };

  const handleOnScrollCapture = ({ target }: UIEvent<HTMLDivElement>) => {
    const div = target as HTMLDivElement;

    // Isto foi necessário para evitar que qualquer scroll do documento execute
    // o fechamento do overlayPanel, no entanto, parece uma solução pouco elegante
    // pois precisamos deixar o nome da classe que identifica o elemento que
    // scrolla dentro da grid hard-coded no codigo, isso pode compativel com
    // futuras versoes do primereact
    // REVIEW
    if (div.className.includes("p-datatable-wrapper")) {
      currentFilterOverlayPanelRef?.current?.hide();
    }
  };

  const dtRef = useRef<DataTable>(null);

  useImperativeHandle(forwardedRef, () => ({
    /** Recarregaos dados da grid */
    reload() {
      getList?.(pfsEvent);
    },

    /** Reseta alguns dados mutáveis do estado da table.  */
    resetFilters() {
      // Isto é necessário pois filtros de relationship utilizam o filterData p/
      // guardar o operation e estamos salvando este estado na propria coluna.
      // Poderiamos pensar em outra forma de guardar este estado pois aqui
      // estamos infringindo a regra da imutabilidade
      // REVIEW
      for (let i = 0; i < selectedColumns.length; i += 1) {
        const typedCol = selectedColumns[i] as ITypedColumn;

        typedCol.filterData = null;
      }

      const defualtPfsEvent = new PFSEventEntity({
        globalFilter: globalFilter ?? "",
        filters: {
          global: {
            value: globalFilter ?? "",
            matchMode: FilterMatchMode.CONTAINS,
          },
        },
        rows,
        first: 0,
      });

      setPfsEvent(defualtPfsEvent);
      onFilter?.(defualtPfsEvent);
    },

    /**
     * Reseta a ordenação feita pelo usuário na UI e aplica a ordenação conforme
     * a sequencia que as colunas estão inseridas no array. Utilizar sempre que
     * um panorama for aplicado.
     */
    applyPanorama() {
      // esse metodo é necessario pois o prime gerencia o reposicionamento das
      // colunas na UI fora do life cycle do react (utiliando refs mutáveis)
      // por isso quando alteramos o panorama (ou a lista de colunas selecionadas)
      // precisamos resetar o columnOrder para que a sequencia que as colunas estão
      // no array prevalescam (obrigado primereact 🙏)
      dtRef.current?.resetColumnOrder();
    },
  }));

  // aqui atualizamos os filtros internos
  // sempre que o filtro global é atualizado
  useEffect(() => {
    if (globalFilter !== undefined) {
      setPfsEvent(prevPfsEvent => {
        const computedPsfEvent = {
          ...prevPfsEvent,
          globalFilter,
          filters: {
            ...prevPfsEvent.filters,
            global: {
              ...prevPfsEvent.filters.global,
              value: globalFilter,
            },
          },
        };

        return computedPsfEvent;
      });
    }
  }, [globalFilter]);

  // aqui atualizamos os filtros internos sempre que
  // filtros de panormaa sao atribuidos a grid
  useEffect(() => {
    setPfsEvent(prevPfsEvent => {
      const computedPsfEvent = {
        ...prevPfsEvent,
        filters: {
          ...panoramaFilters,
        },
      };

      return computedPsfEvent;
    });
  }, [panoramaFilters]);

  // aqui atualizamos as ordenacoes internas das colunas sempre
  // que ordenacoes de panorma sao atribuidas a grid
  useEffect(() => {
    const sort = panoramaSort as DataTableSortMeta[];

    let multiSortMeta: DataTableSortMeta[] | undefined;

    if (sort) {
      multiSortMeta = [...sort];
    }

    setPfsEvent(prevPfsEvent => {
      const computedPsfEvent = {
        ...prevPfsEvent,
        multiSortMeta,
      };

      return computedPsfEvent;
    });
  }, [panoramaSort]);

  // aqui executamos a função de listagem sempre que os estados
  // internos de filtro/ordenação/paginação são alterados
  useEffect(() => {
    getList?.(pfsEvent);
  }, [getList, pfsEvent]);

  return (
    <Container
      ref={instance => {
        // UGLY rever regra de herança para esse caso, na teoria HTMLDivElement
        // deveria poder ser atribuido a um HTMLElement pois é uma classe descendente
        // A ideia é que tableRef seja o mais generico possivel (HTMLElement)
        // pra caso no futuro precisemos mudar essa ref de elemento possa ser
        // feito de forma fácil
        tableRef.current = instance as unknown as HTMLElement;
      }}
      removeableColumns={removeableColumns}
    >
      <DataTable
        ref={dtRef}
        rows={rows}
        dataKey="id"
        onPage={handleOnPage}
        loading={loading}
        value={data?.data}
        selection={selection}
        first={pfsEvent.first}
        filters={pfsEvent.filters}
        emptyMessage={emptyMessage}
        selectionMode={selectionMode}
        sortField={pfsEvent.sortField}
        sortOrder={pfsEvent.sortOrder}
        columnResizeMode={columnResizeMode}
        paginatorTemplate={paginatorTemplate}
        multiSortMeta={pfsEvent.multiSortMeta}
        rowsPerPageOptions={rowsPerPageOptions}
        rowClassName={rowClassName ?? rowClass}
        totalRecords={data?.recordsFiltered || 1}
        onColumnResizeEnd={handleOnColumnResizeEnd}
        onScrollCapture={handleOnScrollCapture}
        onSelectionChange={onSelectionChange}
        onRowDoubleClick={onRowDoubleClick}
        isDataSelectable={isDataSelectable}
        onRowClick={handleOnRowClick}
        onColReorder={onColReorder}
        responsiveLayout="scroll"
        scrollDirection="both"
        scrollHeight="flex"
        reorderableColumns
        sortMode="single"
        resizableColumns
        stripedRows
        scrollable
        paginator
        rowHover
        lazy
        selectionAutoFocus={selectionAutoFocus}
      >
        {selectable && (
          <Column selectionMode="multiple" className="soul-chkbox-col" />
        )}
        {selectedColumns
          .filter(selColumn => {
            const { hidden } = selColumn as ISimpleHiddenColumn;
            return !hidden;
          })
          .map(selColumn => {
            const { field, className, width } = selColumn as ISimpleColumn;
            const { bodyTemplate } = selColumn as ISimpleCustomColumn;
            const { resizeable = true } = selColumn as IResizeableColumn;
            const colStyle = width ? { width } : undefined;

            return (
              <Column
                key={field}
                field={field}
                style={colStyle}
                className={className}
                header={renderHeader(selColumn)}
                body={bodyTemplate ?? renderBody}
                resizeable={resizeable}
              />
            );
          })}
      </DataTable>
    </Container>
  );
});

type AdvTableRefProps<T> = AdvTableProps<T> & {
  tableRef?: Ref<IAdvTableHandle>;
};

// esse wrapper é necessário para podermos manter a abstração
// da SimpleTable fazendo uso de TypeScript Generics
export function AdvTable<T>({ tableRef, ...props }: AdvTableRefProps<T>) {
  return <AdvTableWrapper ref={tableRef} {...props} />;
}
