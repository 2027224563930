import { Checkbox } from "primereact/checkbox";
import { ListBox, ListBoxItemTemplateType } from "primereact/listbox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IEnumColumnEntity } from "../../../domain/entities/advTableColumn";
import {
  ColumnFilterContextData,
  useColumnFilter,
} from "../../hooks/useColumnFilter";
import { RemoveButton, SelectedToken } from "../SelectedToken";
import { Container } from "./styles";

type TemplateCallback = (option: unknown) => React.ReactNode;

export interface EnumColumn extends IEnumColumnEntity {
  filterItemTemplate?: ListBoxItemTemplateType;
}

export enum EEnumListBoxOptions {
  selectAll = "selectAll",
  empty = "empty",
}

export function ColumnFilterEnum() {
  const {
    column: { header, filterItemTemplate, enumLang, enumObject },
    setFilterValue,
    filterValue,
  } = useColumnFilter() as ColumnFilterContextData<EnumColumn>;

  const [selectedOptions, setSelectedOptions] = useState<
    Array<string | number>
  >((filterValue as Array<string | number>) ?? []);

  const options = useMemo(() => {
    return [
      {
        value: EEnumListBoxOptions.selectAll,
        label: "Selecionar todos",
      },
      {
        value: EEnumListBoxOptions.empty,
        label: "Vazios",
      },
      ...Object.keys(enumObject as object)
        .filter(key => !Number.isNaN(Number(key)))
        .map(numKey => {
          const key = (enumObject as Record<number, string>)[Number(numKey)];

          return {
            value: Number(numKey),
            label: (enumLang as Record<string, string>)[key],
          };
        })
        .sort((optionA, optionB) => {
          if (optionA.label < optionB.label) {
            return -1;
          }

          if (optionA.label > optionB.label) {
            return 1;
          }

          return 0;
        }),
    ];
  }, [enumObject, enumLang]);

  const handleOnChange = useCallback(
    ({ value }) => {
      if (selectedOptions.includes(EEnumListBoxOptions.selectAll)) {
        // Marcou/desmarcou um item qualquer quando está tudo selecionado
        if (value.includes(EEnumListBoxOptions.selectAll)) {
          value.splice(value.indexOf(EEnumListBoxOptions.selectAll), 1);
          setSelectedOptions([...value]);
        }
        // Desmarcou "Selecionar todos"
        else {
          setSelectedOptions([]);
        }
      }
      // Marcou "Selecionar todos"
      else if (value.includes(EEnumListBoxOptions.selectAll)) {
        setSelectedOptions(options.map(option => option.value));
      }
      // Marcou/desmarcou um item individualmente
      else {
        setSelectedOptions(value);
      }
    },
    [options, selectedOptions],
  );

  const handleOnRemove = useCallback(
    optionValue => {
      selectedOptions.splice(selectedOptions.indexOf(optionValue), 1);

      setSelectedOptions([...selectedOptions]);
    },
    [selectedOptions],
  );

  const renderSelectedOptions = useCallback(() => {
    if (!selectedOptions.length) {
      return <div className="filter-display-placeholder">{header}</div>;
    }

    if (selectedOptions.length < 3) {
      return selectedOptions.map(optionValue => (
        <SelectedToken
          key={optionValue}
          value={optionValue}
          label={
            options.find(option => option.value === optionValue)?.label ?? ""
          }
          onRemove={handleOnRemove}
        >
          <RemoveButton />
        </SelectedToken>
      ));
    }

    const filteredSelectedOptions = selectedOptions.filter(
      selOption =>
        selOption !== EEnumListBoxOptions.selectAll &&
        selOption !== EEnumListBoxOptions.empty,
    );

    const { length } = filteredSelectedOptions;
    return <SelectedToken label={`${length} itens`} />;
  }, [handleOnRemove, header, options, selectedOptions]);

  const renderItemTemplate = useCallback(
    option => {
      return (
        <div title={option.label} className="item-wrapper">
          <Checkbox checked={selectedOptions.includes(option.value)} />
          {filterItemTemplate
            ? (filterItemTemplate as TemplateCallback)(option)
            : option.label}
        </div>
      );
    },
    [filterItemTemplate, selectedOptions],
  );

  const refCallback = (ref: ListBox) => {
    const element = ref?.getElement();
    const input = element?.querySelector("input");

    if (input) {
      input.onfocus = (event: FocusEvent) => {
        const target = event.target as HTMLInputElement;
        target.setSelectionRange(0, target.value.length);
      };

      input.focus();
    }
  };

  useEffect(() => {
    setFilterValue?.(selectedOptions);
  }, [selectedOptions, setFilterValue]);

  return (
    <Container>
      <div className="filter-display">{renderSelectedOptions()}</div>
      <ListBox
        ref={refCallback}
        value={selectedOptions}
        options={options}
        filterPlaceholder="Digite algo..."
        optionLabel="label"
        optionValue="value"
        dataKey="value"
        listClassName="app-listbox-list"
        onChange={handleOnChange}
        itemTemplate={renderItemTemplate}
        multiple
        filter
      />
    </Container>
  );
}
