import styled from "styled-components";

export const Container = styled.div`
  width: calc(100vw - 2rem);
  max-width: 45rem;

  .react-modal-body {
    padding-top: 0 !important;

    > .card {
      padding: 1rem;
      background: #090081;
      color: white;
      border-radius: 0.375rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .description,
      .document-number {
        text-align: center;
      }

      & + div {
        margin-top: 1rem;
      }
    }

    .card-list {
      max-height: 25rem;
      overflow: auto;
    }

    .btn-add-more {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: end;
  }

  .loading-container {
    display: flex;
    padding-bottom: 2rem;
  }
`;

export const CardContainer = styled.div`
  & + & {
    margin-top: 0.75rem;
  }

  .card {
    border: 0.0625rem solid rgb(82, 95, 127, 0.1);
  }

  &:not(:last-child) {
    .card {
      border-radius: 0.375rem;
    }
  }

  &:last-child {
    .card {
      border-top-left-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
    }
  }

  .form-container {
    .form-container__header {
      display: flex;
      justify-content: end;
    }

    .form-container__body {
      padding: 1rem;

      .default-button.gen-button {
        margin-left: 0;
        height: calc(1.5em + 0.5rem + 2px);
      }
    }

    .btn-remove {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
