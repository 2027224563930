import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import {
  IListProvidersContract,
  IListProvidersParams,
} from "../../domain/contracts/listProvidersContract";
import { IProviderEntity } from "../../domain/entities/providerEntity";

export class ListProvidersService implements IListProvidersContract {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
    private setServerSideService: ISetServerSideService,
  ) {}

  async listProviders({
    payload,
    companyGroupId,
    showActivesOnly,
  }: IListProvidersParams): Promise<IResponseEntity<IProviderEntity[]>> {
    const userEntity = this.getUserLocalService.get();
    let url = `/CompanyGroups/${companyGroupId}/Providers`;

    if (showActivesOnly) {
      url += "/Actives";
    }

    const serverSideBody = await this.setServerSideService.setServerSide(
      payload,
    );

    const params = new URLSearchParams({
      body: serverSideBody,
    });

    const response = await this.api.get<
      IServerSideResponseModel<IProviderEntity[]>
    >(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
