import { IPaymentTypeEntity } from "../entities/paymentTypeEntity";

export interface IListPaymentTypeUseCase {
  listPaymentType(): Promise<IPaymentTypeEntity[]>;
}

export interface IListPaymentTypeService {
  listPaymentType(): Promise<IPaymentTypeEntity[]>;
}

export class ListPaymentTypeUseCase implements IListPaymentTypeUseCase {
  constructor(private listPaymentTypeService: IListPaymentTypeService) {}

  listPaymentType(): Promise<IPaymentTypeEntity[]> {
    return this.listPaymentTypeService.listPaymentType();
  }
}
