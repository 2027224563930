import { ChangeEvent, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/solicitImportTypes";
import { useSolicitImportPage } from "../../../hooks/useSolicitImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function DocumentNumberField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.documentNumber` as const;

  const { getValues } = useFormContext();

  const { state, validateDocumentNumber, handleInputClassName } =
    useSolicitImportPage();

  const { formsValidatingDocumentNumber } = state;

  const currentValue = useRef<string>(getValues(fieldKey));

  const isLoading = formsValidatingDocumentNumber.includes(formIndex);

  const handleDocumentNumberBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (currentValue.current !== value) {
      currentValue.current = value;
      validateDocumentNumber(value, formIndex);
    }
  };

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
          handleDocumentNumberBlur(event);
        };

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="col-4 form-control">
            <FieldWrapper
              isLoading={isLoading}
              className={`${handleInputClassName(
                fieldState,
              )} document-number-container`}
            >
              <input
                {...field}
                onBlur={onBlur}
                onChange={onChange}
                placeholder="N° documento"
                id={`txt-documentNumber-${formIndex}`}
                data-testid={`txt-documentNumber-${formIndex}`}
              />
              {isLoading ? <i className="pi pi-spin pi-spinner" /> : null}
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="Este nº de documento já existe para esse fornecedor."
              condition={fieldState?.error?.type === "validDocument"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
