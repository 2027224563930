import { IAdvTableColumn } from "./advTableColumn";
import { IEntity } from "../../../core/domain/entities/entity";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../simpleTable/domain/entities/PSFEventEntity";
// import { IPanoramaDefEntity } from "./panoramaDefEntity";

export interface IPanoramaEntity extends IEntity {
  name: string;
  userId: string;
  panoramaDefinition: {
    pfsEventEntity: IPFSEventEntity;
    selectedColumns: IAdvTableColumn[];
  };
  systemDefault?: boolean;
}

export class PanoramaEntity implements IPanoramaEntity {
  id = "";
  name = "";
  userId = "";
  active = true;
  systemDefault = false;
  panoramaDefinition = {
    pfsEventEntity: new PFSEventEntity(),
    selectedColumns: [],
  };

  constructor(params?: Partial<IPanoramaEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IPanoramaEntity>) {
    return new PanoramaEntity(params);
  }
}
