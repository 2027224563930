import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IToggleMacroCategoryContract } from "../../domain/contracts/toggleMacroCategoryContract";
import { IMacroCategoryEntity } from "../../domain/entities/macroCategoryEntity";

export class ToggleMacroCategoryService
  implements IToggleMacroCategoryContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  toggleMacroCategory(id: string, toggleActive: boolean) {
    const userEntity = this.getUserLocalService.get();
    const toActivate = toggleActive ? "/Activate" : "/Inactivate";

    const url = `/MacroBoxes${toActivate}/${id}`;

    return this.api.patch<IMacroCategoryEntity>(url, undefined, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
