import { Controller } from "react-hook-form";
import { ChangeEvent } from "react";
import { InputDate } from "../../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { FieldWrapper } from "../../FieldWrapper";
import { useDateValidator } from "../../../../../../../core/presentation/hooks/useDateValidator";

export function IssueDateField(props: IFormComponentProps) {
  const { formIndex } = props;

  const invalidDate = useDateValidator();

  const fieldKey = `imports.${formIndex}.issueDate` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{
        required: true,
        validate: { validDate: v => !invalidDate(v || "", "dd/MM/yyyy") },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span>Data de Emissão</span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputDate
                {...field}
                onChange={onChange}
                placeholder="00/00/0000"
                id={`txt-issueDate-${formIndex}`}
                data-testid={`txt-issueDate-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message="Insira uma data válida"
              condition={fieldState?.error?.type === "validDate"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
