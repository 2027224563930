import { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IGenerateRPAModalInputEntity } from "../../../../../core/domain/entities/generateRpaModalInputEntity";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IAccountPayableAttachmentEntity } from "../../domain/entities/accountPayableAttachmentEntity";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import {
  EAttachmentType,
  IOnAttachmentTypeChangeParams,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGenerateCommercialProposalForm } from "../../../../../core/domain/entities/generateCommercialProposalForm";
import { ProviderDocumentType } from "../../../../../provider/domain/entities/providerEntity";

interface IAttachmentModalState {
  loading: boolean;
  isSubmiting: boolean;
  attachments: IAccountPayableAttachmentEntity[];
  search: string;
  uploadModal: {
    isOpen: boolean;
    loaded: number;
    total: number;
  };
}

export function useAttachmentModal() {
  const { useAccountsPayable, handleAttachmentModalClose, ...rest } =
    useAccountsPayablePage();

  const { contextMenuData, attachmentModalOpen } = rest.state;

  const {
    getAttachments,
    getStorageFilebyId,
    uploadAttachments,
    listAttachmentTypes,
    updateAttachmentsBarcode,
    generateAttachmentVariableAdditional,
    generateAttachmentRpa,
    fetchRpaParamOptions,
    fetchCommercialProposalEnums,
    generateCommercialProposalAttachment,
  } = useAccountsPayable;

  const [state, setState] = useState<IAttachmentModalState>({
    loading: false,
    isSubmiting: false,
    attachments: [],
    search: "",
    uploadModal: {
      isOpen: false,
      loaded: 0,
      total: 0,
    },
  });

  /** Lida com o evento de click do botao fechar da modal */
  const handleCloseButtonOnClick = useCallback(() => {
    handleAttachmentModalClose();
  }, [handleAttachmentModalClose]);

  const mountedRef = useIsMounted();
  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /** Obtem a lista de arquivos anexados a conta a pagar selecionada */
  const fetchAttachments = useCallback(async () => {
    if (!contextMenuData) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const attachmentsResponse = await getAttachments(contextMenuData);

      if (!mountedRef.current) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        loading: false,
        attachments: attachmentsResponse,
      }));
    } catch {
      if (mountedRef.current) {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    } finally {
      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    }
  }, [contextMenuData, getAttachments, mountedRef]);

  /**
   * Lida com o evento de abertura da modal, responsavel
   * por chamar a funcao que obtem a lista de anexos
   */
  const handleAfterOpen = () => {
    fetchAttachments();
  };

  /**
   * Lida com o evento de fechamento da modal, responsavel
   * por resetar os estados da modal
   */
  const handleAfterClose = () => {
    setState({
      loading: false,
      isSubmiting: false,
      attachments: [],
      search: "",
      uploadModal: {
        isOpen: false,
        loaded: 0,
        total: 0,
      },
    });
  };

  /**
   * Lida com o evento de progresso de upload dos anexos
   */
  const handleUploadProgress = (loaded: number, total: number) => {
    setState(prevState => ({
      ...prevState,
      uploadModal: {
        ...prevState.uploadModal,
        loaded,
        total,
      },
    }));
  };

  /**
   * Atualiza os anexos na api. Rewliza o post dos arquivos e
   * dos novos dados dos anexos que foram atualizados/removidos
   */
  const updateAttachments = async (
    accountPayableId: string,
    attachmentList: IAccountPayableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      isSubmiting: true,
      uploadModal: {
        ...prevState.uploadModal,
        isOpen: true,
      },
    }));

    try {
      await uploadAttachments(
        accountPayableId,
        attachmentList,
        handleUploadProgress,
      );

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        text: "Lançamento de contas a pagar atualizado com sucesso.",
      });

      setState(prevState => ({
        ...prevState,
        isSubmiting: false,
        uploadModal: {
          ...prevState.uploadModal,
          isOpen: false,
        },
      }));

      handleAttachmentModalClose();
    } catch {
      setState(prevState => ({
        ...prevState,
        isSubmiting: false,
        uploadModal: {
          ...prevState.uploadModal,
          isOpen: false,
        },
      }));
    }
  };

  /** Lida como submit dos dados da modal */
  const handleSubmit = () => {
    const accountPayableId = contextMenuData?.id;
    const attachmentList = state.attachments;

    if (!accountPayableId) {
      return;
    }

    updateAttachments(accountPayableId, attachmentList);
  };

  /** Lida com o evento de gerar anexo de adicional variavel */
  const handleGenerateVariableAdditional = async () => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Adicional variável.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();
      const accountPayableListItem = contextMenuData;

      const generatedFile = await generateAttachmentVariableAdditional(
        accountPayableListItem,
      );

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  /** Lida com o evento de gerar anexo de RPA */
  const handleGenerateRpaAttachment = async (
    genRPAModalInput: IGenerateRPAModalInputEntity,
  ) => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de RPA.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const accountPayableListItem = contextMenuData;
      const generateRPAModalInput = genRPAModalInput;

      const generatedFile = await generateAttachmentRpa({
        accountPayableListItem,
        generateRPAModalInput,
      });

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleGenerateCommercialProposal = async (
    modalParams: IGenerateCommercialProposalForm,
  ) => {
    if (contextMenuData === undefined) {
      return Promise.reject();
    }

    dialog.fire({
      title: "Aguarde",
      html: `Estamos gerando o anexo de Proposta Comercial.`,
      showCancelButton: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    try {
      dialog.showLoading();

      const params = {
        ...modalParams,
        valueMoney: contextMenuData?.value || 0,
        projectId: contextMenuData?.projectId || "",
        providerId: contextMenuData?.providerId || "",
      };

      const generatedFile = await generateCommercialProposalAttachment(params);

      return {
        generatedFile,
        isBulkGeneration: false,
      };
    } finally {
      dialog.close();
    }
  };

  const handleUpdateAttachmentsBarcode = async (
    billetAttachment: IAccountPayableAttachmentEntity,
  ) => {
    try {
      const updatedAttachment = await updateAttachmentsBarcode(
        currentCompanyGroup.id,
        billetAttachment,
      );

      return { updatedAttachment };
    } catch {
      return { updatedAttachment: billetAttachment };
    }
  };

  /**
   * LIda com o evento de troca do valor dos anexos emitido pela grid,
   * responsavel por atualizar a lista de anexos em memoria conforme as
   * edicoes realizadas pelo usuario
   */
  const handleAttachmentListChange = (
    attachments: IAccountPayableAttachmentEntity[],
  ) => {
    setState(prevState => ({
      ...prevState,
      attachments: [...attachments],
    }));
  };

  const handleAttachmentTypeChange = async ({
    typeToCheck,
    editAttachment,
    modalAttachments,
  }: IOnAttachmentTypeChangeParams<IAccountPayableAttachmentEntity>) => {
    if (editAttachment && editAttachment.type === typeToCheck) {
      return true;
    }

    if (
      typeToCheck === EAttachmentType.CommercialProposal &&
      contextMenuData?.providerDocumentType !== ProviderDocumentType.CPF
    ) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        html: (
          <>
            Este lançamento é destinado a um fornecedor do tipo pessoa jurídica
            ou pessoa estrangeira.
            <br />
            <br />
            Neste caso, o arquivo <strong>não será gerado</strong>, pois a
            proposta comercial é permitida{" "}
            <strong>somente para pessoas físicas</strong>.
          </>
        ),
      });

      return false;
    }

    if (
      typeToCheck !== EAttachmentType.Billet &&
      typeToCheck !== EAttachmentType.Measurement
    ) {
      return true;
    }

    const typesName = {
      [EAttachmentType.Billet]: "Boleto",
      [EAttachmentType.Measurement]: "Medição",
    };

    const hasTypeToBeAdded = modalAttachments.some(
      a => Number(a.type) === typeToCheck && a.active,
    );

    if (hasTypeToBeAdded) {
      await dialog.fire({
        icon: "error",
        title: "Erro",
        html: (
          <>
            Não é possível adicionar múltiplos anexos do tipo{" "}
            <strong>{typesName[typeToCheck]}</strong>. Para prosseguir, remova o
            respectivo anexo e tente novamente.
          </>
        ),
      });

      return false;
    }

    return true;
  };

  const handleGetStorageFileById = async (storageFileId: string) => {
    const attachment = state.attachments.find(
      attachmentData => attachmentData.storageFileId === storageFileId,
    );

    if (!attachment) {
      return;
    }

    await getStorageFilebyId(attachment);
  };

  return {
    attachmentModalOpen,
    handleCloseButtonOnClick,
    handleAfterOpen,
    handleAfterClose,
    state,
    contextMenuData,
    listAttachmentTypes,
    handleAttachmentListChange,
    handleSubmit,
    handleUpdateAttachmentsBarcode,
    handleGenerateVariableAdditional,
    handleGenerateRpaAttachment,
    fetchRpaParamOptions,
    handleAttachmentTypeChange,
    fetchCommercialProposalEnums,
    handleGenerateCommercialProposal,
    getStorageFilebyId: handleGetStorageFileById,
  };
}
