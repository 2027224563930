import styled from "styled-components";

export const Container = styled.div`
  .modal-table__header__span {
    margin-right: auto;
  }

  header {
    justify-content: end;

    button {
      margin-left: unset;

      & + button {
        margin-left: 0.5rem;
      }
    }

    #btn-recalculate {
      gap: 0.5rem;
      background: var(--soul-green-button);
      border-color: var(--soul-green-button);
    }
  }

  .searchbar {
    padding: 0.5rem 1rem;
  }
`;
