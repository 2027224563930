import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { ITypeaheadOption } from "../../../core/domain/entities/typeaheadOption";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IListCnaeContract } from "../../domain/contracts/listCnaeContract";
import { IGenericClassificationEntity } from "../../domain/entities/genericClassificationEntity";

export class ListCnaeService implements IListCnaeContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCnae(actives?: boolean) {
    const userEntity = this.getUserLocalService.get();
    let url = "/CnaeClassifications";

    if (actives) {
      url += "/Actives";
    }

    const response = await this.api.get<
      IServerSideResponseModel<IGenericClassificationEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const typeaheadOptionList = response.data.map<
      ITypeaheadOption<IGenericClassificationEntity>
    >(cnae => {
      const label = cnae.code.replace(/\D/g, "");

      return {
        label,
        metadata: cnae,
        rawValue: cnae.id,
      };
    });

    return typeaheadOptionList;
  }
}
