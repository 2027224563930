import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetPecegePayAccountReceivableFeeContract } from "../../domain/contracts/getPecegePayAccountReceivableFeeContract";
import { IPecegePayAccountReceivableFeeEntity } from "../../domain/entities/pecegePayAccountReceivableFeeEntity";

export class GetPecegePayAccountReceivableFeeService
  implements IGetPecegePayAccountReceivableFeeContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  getPecegePayAccountReceivableFee(feeId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PecegePayAccountReceivableFees/${feeId}`;

    return this.api.get<IPecegePayAccountReceivableFeeEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
