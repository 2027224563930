import jsonp from "jsonp";
import { IGetDataFromReceitaService } from "../../domain/usecases/getDataFromReceitaUseCase";
import { ILegalPersonForm } from "../models/legalPersonForm";

export class GetDataFromReceitaService implements IGetDataFromReceitaService {
  fetchByCnpj(cnpj: string): Promise<ILegalPersonForm | null> {
    const url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;

    const customPromise = new Promise<ILegalPersonForm | null>(resolve => {
      // foi necessário gerenciar uma promise que se auto completa após
      // 3 segundos pois a extensão do jsonp não retorna uma promise.
      const timeout = setTimeout(() => {
        resolve(null);
      }, 3000);

      jsonp(url, undefined, (err, res) => {
        if (err) {
          resolve(null);
        }
        clearTimeout(timeout);

        resolve({
          document: res.cnpj.replace(/\D/g, ""),
          name: res.fantasia,
          corporationName: res.nome,
          phoneNumber: res.telefone.replace(/\D/g, ""),
          email: res.email,
          municipalRegistration: "",
          stateRegistration: "",
        });
      });
    });

    return customPromise;
  }
}
