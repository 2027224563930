import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IGenerateVariableAdditionalAttachmentContract,
  IGenerateVariableAdditionalAttachmentPayload,
} from "../../domain/contracts/generateVariableAdditionalAttachmentContract";
import { MainDebtImportService } from "./mainDebtImportService";

export class GenerateVariableAdditionalAttachmentService
  extends MainDebtImportService
  implements IGenerateVariableAdditionalAttachmentContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async generateVariableAdditionalAttachment(
    payload: IGenerateVariableAdditionalAttachmentPayload,
  ): Promise<File> {
    const url = "/Downloads/GenerateAttachmentVariableAdditional";
    const userEntity = this.getUserLocalService.get();

    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
        "Accept-Language": "pt-BR",
      },
    });

    if (response) {
      const file = this.generateFile(response);

      return file;
    }

    return Promise.reject();
  }
}
