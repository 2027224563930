import { ICheckDocumentEntity } from "../entities/providerEntity";

export interface ICheckProviderCpfUseCase {
  checkCpf(cpf: string, id?: string): Promise<ICheckDocumentEntity>;
}

export interface ICheckProviderCpfService {
  checkCpf(cpf: string, id?: string): Promise<ICheckDocumentEntity>;
}

export class CheckProviderCpfUseCase implements ICheckProviderCpfUseCase {
  constructor(private checkProviderCpfService: ICheckProviderCpfService) {}

  checkCpf(cpf: string, id?: string) {
    return this.checkProviderCpfService.checkCpf(cpf, id);
  }
}
