import { ContextMenu } from "primereact/contextmenu";
import {
  DataTableColReorderParams,
  DataTableDataSelectableParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FaArrowAltCircleLeft,
  FaCheckCircle,
  FaEye,
  FaPaperclip,
  FaTimesCircle,
} from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import {
  AdvTable,
  IAdvTableHandle,
  IDataTableRowClickEventParams,
} from "../../../../../advTable/presentation/components/AdvTable";
import { ColumnSelector } from "../../../../../advTable/presentation/components/ColumnSelector";
import { Page } from "../../../../../core/presentation/components/Page/styles";
import { useIsMounted } from "../../../../../core/presentation/hooks/useIsMounted";
import { useOnClickOutside } from "../../../../../core/presentation/hooks/useOnClickOutside";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EApprovalStatus,
  ECostCenterReportType,
  EReportOrigin,
  ICostCenterReportEntity,
} from "../../../domain/entities/costCenterReportEntity";
import { IFiltersValue } from "../../../domain/usecases/listCostCenterReportUseCase";
import { MakeCostCenterReportManager } from "../../../main/makeCostCenterReportManager";
import { useCostCenterReportGridManager } from "../../hooks/useCostCenterReportGridManager";
import { CostCenterReportAttachmentModal } from "../CostCenterReportAttachmentModal";
import { CostCenterTransactionReadonlyModal } from "../CostCenterTransactionReadonlyModal";
import { ExportingModalContent } from "../ExportingModalContent";
import { ToolbarManager } from "../ToolbarManager";
import { Container, ContextMenuItem } from "./styles";
import { MakeCostCenterReport } from "../../../main/makeCostCenterReport";

interface IFetchDataProps {
  filtersValue: IFiltersValue;
  pfsEventEntity: IPFSEventEntity;
  uiSelectedColumns: IAdvTableColumn[];
  orderedColumnNames: string[];
}

interface CostCenterReportManagerPageProps {
  useCostCenterReport: MakeCostCenterReport;
  useCostCenterReportManager: MakeCostCenterReportManager;
}

export function CostCenterReportManagerPage({
  useCostCenterReport,
  useCostCenterReportManager,
}: CostCenterReportManagerPageProps) {
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useCostCenterReportGridManager({
    useCostCenterReportManager,
  });
  const { listCostCenterReport, exportCostCenterReportUseCase } =
    useCostCenterReportManager;

  const mountedRef = useIsMounted();
  const table = useRef<IAdvTableHandle>(null);

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(id);

  const [selectedColumns, setSelectedColumns] = useState(() => {
    // comecamos com uma copia da lista de colunas pois alguns estados estão
    // sendo salvos na propria abstracao da coluna de forma mutável e se nao
    // fizermos isso ppodemos causar algumas inconsistencias
    // REVIEW
    return columns.map(col => ({ ...col }));
  });
  const [orderedColNames, setOrderedColNames] = useState<string[]>(
    columns.map(col => col.field),
  );

  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity({ rows: 50 }),
  );
  const [filtered, setFiltered] = useState(false);
  const [filters, setFilters] = useState<IFiltersValue>({
    startDate: "",
    endDate: "",
  });
  const [selection, setSelection] = useState<
    ICostCenterReportEntity[] | undefined
  >();
  const [rowFocusedData, setRowFocusedData] = useState<
    ICostCenterReportEntity | undefined
  >();
  const [
    isCostCenterTransactionModalOpen,
    setIsCostCenterTransactionsModalOpen,
  ] = useState(false);
  const [
    isCostCenterReportAttachmentModalOpen,
    setIsCostCenterReportAttachmentModalOpen,
  ] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] =
    useState<IResponseEntity<ICostCenterReportEntity[]>>();

  /* Indica quando a opção de 'Exportar para Excel' deve ser desabilitada */
  const disableSheetExportation = !data?.data?.length;

  const { advGeneratePayload } = useTables();

  /**
   * Responsavel por fazer o fetch que obtem a lista de dados p/ exibir na tela
   */
  const fetchData = useCallback(
    async ({
      filtersValue,
      orderedColumnNames,
      pfsEventEntity,
      uiSelectedColumns,
    }: IFetchDataProps) => {
      if (!mountedRef.current) {
        return;
      }

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setIsFetching(false);
        return;
      }
      const isFiltered = ((pfsEventEntity.filters &&
        Object.keys(pfsEventEntity.filters).filter(fKey => fKey !== "global")
          .length > 0) ||
        (pfsEventEntity.multiSortMeta &&
          pfsEventEntity.multiSortMeta.length > 0)) as boolean;

      setFiltered(isFiltered);
      setIsFetching(true);

      const selectedCols = orderedColumnNames
        .map(fieldName => {
          return uiSelectedColumns.find(
            uiSelCol => uiSelCol.field === fieldName,
          );
        })
        .filter((col): col is IAdvTableColumn => col !== undefined);

      try {
        const payload = advGeneratePayload(
          pfsEventEntity,
          selectedCols as ISimpleColumn[],
        );

        const response = await listCostCenterReport(id, payload, filtersValue);
        if (!mountedRef.current) {
          return;
        }

        setData(response);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setIsFetching(false);
      }
    },
    [advGeneratePayload, id, listCostCenterReport, mountedRef],
  );

  /** recarrega a grid com o estado atual e limpa a seleção atual */
  const reload = useCallback(() => {
    setSelection([]);

    fetchData({
      filtersValue: filters,
      orderedColumnNames: orderedColNames,
      pfsEventEntity: pfsEvent,
      uiSelectedColumns: selectedColumns,
    });
  }, [fetchData, filters, orderedColNames, pfsEvent, selectedColumns]);

  const handleFiltersChange = useCallback(
    (event: IFiltersValue) => {
      const newFilters = { ...event };
      setFilters(newFilters);

      fetchData({
        filtersValue: newFilters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, orderedColNames, pfsEvent, selectedColumns],
  );

  const handleGetList = useCallback(
    (_pfsEvent: IPFSEventEntity) => {
      setPfsEvent(_pfsEvent);

      fetchData({
        filtersValue: filters,
        orderedColumnNames: orderedColNames,
        pfsEventEntity: _pfsEvent,
        uiSelectedColumns: selectedColumns,
      });
    },
    [fetchData, filters, orderedColNames, selectedColumns],
  );

  const rowClassName = useCallback((rowData: ICostCenterReportEntity) => {
    const { type, origin } = rowData;

    return {
      "row-expense": type === ECostCenterReportType.expense,
      "row-revenue": type === ECostCenterReportType.revenue,
      "soul-row-not-selectable": origin === EReportOrigin.OpeningBalance,
    };
  }, []);

  const renderEmptyMessage = useCallback(() => {
    return (
      <div className="empty-message">
        <div>
          Clique em <b>&ldquo;Mostrar&rdquo;</b> para carregar os registros.
        </div>
      </div>
    );
  }, []);

  const handleOnClearButtonClick = useCallback(() => {
    table.current?.resetFilters();
  }, []);

  const dialog = useSoulDialog();

  const showDifferentStatusSelectedAlert = async () => {
    await dialog.fire({
      icon: "error",
      title: "Opa!",
      html: (
        <div>
          Não é <b>possível fazer</b> essa ação para lançamentos selecionados!
          <br />
          <br />
          Para <b>realizar ações</b> em lote selecione somente lançamentos com{" "}
          <b>&ldquo;status aprovação&rdquo;</b> iguais.
        </div>
      ),
    });
  };

  const {
    approve,
    disapprove,
    undoConfirm,
    bulkApprove,
    bulkDisapprove,
    bulkUndoConfirm,
  } = useCostCenterReportManager;

  /** Aprova (em lote) os lçtos selecionados */
  const handleOnApproveSelectedClick = async () => {
    // acoes em lote nao podem acontecer se
    // somente 1 lancamento estiver selecionado
    if (selection && selection?.length < 1) {
      return;
    }

    // acoes em lote nao podem acontecer se
    // diferentes status estiverem selecionados
    // somente pendentes podem ser aprovados
    if (
      selection?.some(sel => sel.approvalStatus !== EApprovalStatus.Pending)
    ) {
      showDifferentStatusSelectedAlert();
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            Os lançamentos selecionados serão marcados como{" "}
            <b>&quot;Aprovado&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          const costCenterReportIds = selection?.map(sel => sel.id) || [];
          await bulkApprove(costCenterReportIds);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Lançamentos aprovados com sucesso.",
          });

          setSelection(undefined);
          reload();
        },
      });
    } finally {
      dialog.close();
    }
  };

  /** Reprova (em lote) os lçtos selecionados */
  const handleOnDisapproveSelectedClick = async () => {
    // acoes em lote nao podem acontecer se
    // somente 1 lancamento estiver selecionado
    if (selection && selection?.length < 1) {
      return;
    }

    // acoes em lote nao podem acontecer se
    // diferentes status estiverem selecionados
    // somente pendentes podem ser reprovados
    if (
      selection?.some(sel => sel.approvalStatus !== EApprovalStatus.Pending)
    ) {
      showDifferentStatusSelectedAlert();
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            Os lançamentos selecionados serão marcados como{" "}
            <b>&quot;Reprovado&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          const costCenterReportIds = selection?.map(sel => sel.id) || [];
          await bulkDisapprove(costCenterReportIds);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Lançamentos reprovados com sucesso.",
          });

          setSelection(undefined);
          reload();
        },
      });
    } finally {
      dialog.close();
    }
  };

  /** Desfaz confirmação (em lote) dos lçtos selecionados */
  const handleOnUndoConfirmSelectedClick = async () => {
    // acoes em lote nao podem acontecer se
    // somente 1 lancamento estiver selecionado
    if (selection && selection?.length < 1) {
      return;
    }

    // acoes em lote nao podem acontecer se
    // diferentes status estiverem selecionados
    // somente lancamentos diferentes de pendente podem ser desfeitos
    const canUndo =
      selection?.every(
        sel => sel.approvalStatus === EApprovalStatus.Approved,
      ) ||
      selection?.every(
        sel => sel.approvalStatus === EApprovalStatus.Disapproved,
      );

    if (!canUndo) {
      showDifferentStatusSelectedAlert();
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            Os lançamentos selecionados serão marcados como{" "}
            <b>&quot;Pendente&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          const costCenterReportIds = selection?.map(sel => sel.id) || [];
          await bulkUndoConfirm(costCenterReportIds);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Confirmação do lançamento desfeita com sucesso.",
          });

          setSelection(undefined);
          reload();
        },
      });
    } finally {
      dialog.close();
    }
  };

  const menuRef = useRef<ContextMenu>(null);
  const ref = useRef(null);

  useOnClickOutside(ref, (target, event) => {
    const tar = target as HTMLElement;

    // REVIEW o onClickOutside serve para limpar o estado do item selecionado da
    // grid quando o usuario clicar "fora", no entanto, as libs ReactModal e
    // SweetAlert bem como o overlay do componente Dropdown do primereact utilizam
    // um react portal para renderizar alguns elementos na raiz do documento e
    // nao aninhado no elemento onde os estamos chamando, por isso esse tratamento
    // foi necessario para evitar limpar o estado quando clicamos em alguns elementos
    // das modais, dialogs ou dropdowns. O problema dessa abordagem é que ela é
    // fraca por depender de classes css que sao definidas por essas libs de terceiros
    // desta forma estamos muito vulneravais a breaking changes
    if (
      !tar.closest(".ReactModal__Overlay") &&
      !tar.closest(".p-dropdown-items-wrapper") &&
      !tar.closest(".swal2-container")
    ) {
      setRowFocusedData(undefined);
    }

    if (event) {
      menuRef?.current?.hide(event as unknown as SyntheticEvent);
    }
  });

  const handleIsDataSelectable = (event: DataTableDataSelectableParams) => {
    const rowData = event.data as ICostCenterReportEntity;

    return rowData.origin !== EReportOrigin.OpeningBalance;
  };

  const handleSelectionChange = ({ value }: DataTableSelectionChangeParams) => {
    setSelection(value);
  };

  /** Aprova o lançamento clicado */
  const handleApproveCommand = useCallback(async () => {
    if (!rowFocusedData) {
      return;
    }

    const { approvalStatus: approvingStatus } = rowFocusedData;
    const costCenterReportId = rowFocusedData.id;

    if (approvingStatus !== EApprovalStatus.Pending) {
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            O lançamento será marcado como <b>&quot;Aprovado&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          await approve(costCenterReportId);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Lançamento aprovado com sucesso.",
          });

          reload();
        },
      });
    } finally {
      dialog.close();
    }
  }, [approve, dialog, reload, rowFocusedData]);

  /** Reprova o lançamento clicado */
  const handleDisapproveCommand = useCallback(async () => {
    if (!rowFocusedData) {
      return;
    }

    const { approvalStatus: approvingStatus } = rowFocusedData;
    const costCenterReportId = rowFocusedData.id;

    if (approvingStatus !== EApprovalStatus.Pending) {
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            O lançamento será marcado como <b>&quot;Reprovado&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          await disapprove(costCenterReportId);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Lançamento reprovado com sucesso.",
          });

          reload();
        },
      });
    } finally {
      dialog.close();
    }
  }, [dialog, disapprove, reload, rowFocusedData]);

  /** Desfaz a confirmação do lançamento clicado */
  const handleUndoConfirmCommand = useCallback(async () => {
    if (!rowFocusedData) {
      return;
    }

    const { approvalStatus: approvingStatus } = rowFocusedData;
    const costCenterReportId = rowFocusedData.id;

    if (approvingStatus === EApprovalStatus.Pending) {
      return;
    }

    try {
      await dialog.fire({
        icon: "question",
        title: "Você está certo disso?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        html: (
          <>
            O lançamento será marcado como <b>&quot;Pendente&quot;</b>.
            <br /> Deseja prosseguir?
          </>
        ),
        async preConfirm() {
          dialog.update({
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          dialog.showLoading();

          await undoConfirm(costCenterReportId);

          await dialog.fire({
            icon: "success",
            title: "Feito!",
            text: "Confirmação do lançamento desfeita com sucesso.",
          });

          reload();
        },
      });
    } finally {
      dialog.close();
    }
  }, [dialog, reload, rowFocusedData, undoConfirm]);

  /** Exibe a origem de lancamento selecionado na grid de acordo com seu tipo */
  const handleViewCommand = useCallback(() => {
    if (!rowFocusedData) {
      return;
    }

    const item = rowFocusedData;
    const { origin } = item;

    if (origin !== EReportOrigin.transaction) {
      return;
    }

    setIsCostCenterTransactionsModalOpen(true);
  }, [rowFocusedData]);

  /** Visualiza a lista de anexos do lançamento clicado */
  const handleViewAttachmentCommand = useCallback(() => {
    if (!rowFocusedData) {
      return;
    }

    const item = rowFocusedData;
    const { origin } = item;

    if (
      origin !== EReportOrigin.accountPayable &&
      origin !== EReportOrigin.accountReceivable
    ) {
      return;
    }

    setIsCostCenterReportAttachmentModalOpen(true);
  }, [rowFocusedData]);

  /**
   * Renderiza o item "Aprovar" do menu de contexto
   */
  const renderApproveMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaCheckCircle className="green-icon" />
            Aprovar
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  /**
   * renderiza o item "Reprovar" do menu de contexto
   */
  const renderDisapproveMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaTimesCircle className="red-icon" />
            Reprovar
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  /**
   * renderiza o item "Desfazer Confirmação" do menu de contexto
   */
  const renderUndoConfirmMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaArrowAltCircleLeft className="yellow-icon" />
            Desfazer
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  /**
   * renderiza o item "Ver" do menu de contexto
   */
  const renderViewMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaEye />
            Ver
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  /**
   * renderiza o item "Anexos" do menu de contexto
   */
  const renderViewAttachmentMenuItem = useCallback(
    (_item: MenuItem, { onClick }: MenuItemOptions) => {
      return (
        <ContextMenuItem onClick={onClick} className="p-menuitem-link">
          <span className="p-menuitem-text">
            <FaPaperclip />
            Anexos
          </span>
        </ContextMenuItem>
      );
    },
    [],
  );

  const menuModel = useMemo<MenuItem[]>(() => {
    let approvalItems = [
      // item aprovar
      {
        template: renderApproveMenuItem,
        command: handleApproveCommand,
        className:
          rowFocusedData?.approvalStatus !== EApprovalStatus.Pending
            ? "disabled"
            : undefined,
        disabled: rowFocusedData?.approvalStatus !== EApprovalStatus.Pending,
      },
      // item reprovar
      {
        template: renderDisapproveMenuItem,
        command: handleDisapproveCommand,
        className:
          rowFocusedData?.approvalStatus !== EApprovalStatus.Pending
            ? "disabled"
            : undefined,
        disabled: rowFocusedData?.approvalStatus !== EApprovalStatus.Pending,
      },
      // item desfazer
      {
        template: renderUndoConfirmMenuItem,
        command: handleUndoConfirmCommand,
        className:
          rowFocusedData?.approvalStatus === EApprovalStatus.Pending
            ? "disabled"
            : undefined,
        disabled: rowFocusedData?.approvalStatus === EApprovalStatus.Pending,
      },
      { separator: true },
    ];

    if (rowFocusedData?.origin === EReportOrigin.OpeningBalance) {
      approvalItems = [];
    }

    return [
      ...approvalItems,
      {
        template: renderViewMenuItem,
        command: handleViewCommand,
        className:
          rowFocusedData?.origin !== EReportOrigin.transaction
            ? "disabled"
            : undefined,
        disabled: rowFocusedData?.origin !== EReportOrigin.transaction,
      },
      {
        template: renderViewAttachmentMenuItem,
        command: handleViewAttachmentCommand,
        className:
          rowFocusedData?.origin !== EReportOrigin.accountPayable &&
          rowFocusedData?.origin !== EReportOrigin.accountReceivable
            ? "disabled"
            : undefined,
        disabled:
          rowFocusedData?.origin !== EReportOrigin.accountPayable &&
          rowFocusedData?.origin !== EReportOrigin.accountReceivable,
      },
    ];
  }, [
    handleApproveCommand,
    handleDisapproveCommand,
    handleUndoConfirmCommand,
    handleViewAttachmentCommand,
    handleViewCommand,
    renderApproveMenuItem,
    renderDisapproveMenuItem,
    renderUndoConfirmMenuItem,
    renderViewAttachmentMenuItem,
    renderViewMenuItem,
    rowFocusedData?.approvalStatus,
    rowFocusedData?.origin,
  ]);

  const handleOnColumnSelect = (column: IAdvTableColumn) => {
    const newColumns = [column, ...selectedColumns];
    const newOrderedColNames = newColumns.map(col => col.field);

    setSelectedColumns(newColumns);
    setOrderedColNames(newOrderedColNames);

    // UGLY esse timeout foi necessario pois chamar o applyPanorama diretamente
    // na sequencia do update do estado nao tem efeito, nao queremos usar um
    // side-effect selectedColumns pois outros eventos atualizam esse estado e
    // nao queremos que que o applyPanorama seja executado em todos eles
    setTimeout(() => {
      table.current?.applyPanorama();

      fetchData({
        filtersValue: filters,
        orderedColumnNames: newOrderedColNames,
        pfsEventEntity: pfsEvent,
        uiSelectedColumns: newColumns,
      });
    });
  };

  const handleOnColumnRemove = (column: IAdvTableColumn) => {
    const { field } = column;

    setSelectedColumns(prevSelectedColumns => {
      return prevSelectedColumns.filter(selCol => selCol.field !== field);
    });
  };

  const handleOnColReorder = (colReorderParams: DataTableColReorderParams) => {
    const cols = colReorderParams.columns as unknown as Array<{
      props: { field: string };
    }>;

    const newOrderedColNames = cols
      .map(col => col.props.field)
      .filter((col: string): col is string => !!col);

    // por ora precisamos incluir as hidden tbm, pois sem elas após
    // reordenar as colunas na UI elas nao sao mais enviadas na request
    const hidden = columns
      .filter(col => {
        const c = col as ISimpleHiddenColumn;
        return c.hidden;
      })
      .map(c => c.field);

    setOrderedColNames([...newOrderedColNames, ...hidden]);
  };

  const handleOnRowClick = (
    event: IDataTableRowClickEventParams<ICostCenterReportEntity>,
  ) => {
    const focusedData = event.data;
    setRowFocusedData(focusedData);
    menuRef.current?.show(event.originalEvent);
  };

  const handleOnExportButtonClick = async () => {
    if (!pfsEvent) {
      return;
    }

    dialog.fire({
      html: <ExportingModalContent />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      await exportCostCenterReportUseCase(
        id,
        pfsEvent,
        selectedColumns as ISimpleColumn[],
        orderedColNames,
        filters,
      );
    } finally {
      dialog.close();
    }
  };

  const handleOnRequestCloseCostCenterTransactionFormModal = useCallback(() => {
    setIsCostCenterTransactionsModalOpen(false);
  }, []);

  const handleOnRequestCloseCostCenterReportAttachmentModal =
    useCallback(() => {
      setIsCostCenterReportAttachmentModalOpen(false);
    }, []);

  const [selectedPanorama, setSelectedPanorama] = useState<IPanoramaEntity>();

  const handlePanoramaOnChange = (
    _selectedPanorama: IPanoramaEntity,
    shouldUpdate = true,
  ) => {
    const { systemDefault, panoramaDefinition } = _selectedPanorama;

    // este map é necessario pois a prop filterData da coluna é usada como um
    // state mutável (podemos rever isso e utilizar uma abordagem imutável).
    // Mas neste caso nao queremos que ele modifique a coluna original salva no
    // panorama, entao criamos uma nova lista com clones das colunas do panorama
    // para que quando o filterData seja manipulado, aconteca apenas em memoria
    // e nao no que está salvo no panorama
    // REVIEW
    let selectedPanoramaCols = panoramaDefinition.selectedColumns.map(
      selCol => ({
        ...selCol,
      }),
    );

    let pfsEventEntity = {
      ...panoramaDefinition.pfsEventEntity,
      rows: pfsEvent.rows,
    };

    if (systemDefault) {
      selectedPanoramaCols = columns.map(col => ({
        ...col,
      }));
      pfsEventEntity = new PFSEventEntity({ rows: pfsEvent.rows });
    }

    setPfsEvent(prevPfsEvent => {
      return {
        ...pfsEventEntity,
        rows: prevPfsEvent.rows,
      };
    });

    const newOrderedColNames = selectedPanoramaCols.map(selPanCol => {
      return selPanCol.field;
    });

    setOrderedColNames(newOrderedColNames);
    setSelectedColumns(selectedPanoramaCols);
    setSelectedPanorama(_selectedPanorama);

    if (!shouldUpdate) {
      return;
    }

    // refaz a request para garantir que os resultados que o usuario
    // verá estarão de acordo com os filtros do panorama
    fetchData({
      filtersValue: filters,
      orderedColumnNames: newOrderedColNames,
      pfsEventEntity,
      uiSelectedColumns: selectedPanoramaCols,
    });
  };

  // este useEffect é necessario pois o primereact/table gerencia o sequenciamento
  // das colunas internamente de forma mutável, para isso sempre que alteramos
  // states que influenciam o sequenciamento das colunas, precisamos chamar
  // imperativamente o applyPanorama (que por sua vez executa o resetColumnOrder
  // do primereact). Desta forma conseguimos manter sincronizados o estado
  // interno do prime e o render na UI
  useEffect(() => {
    table.current?.applyPanorama();
  }, [selectedPanorama]);

  /**
   * REVIEW - Esse efeito colateral é necessário para que a tela "reinicie" quando
   * houver alteração no valor do atual grupo de empresa.
   * No entanto, podem haver maneiras melhores para que esse comportamento seja
   * executado.
   */
  useEffect(() => {
    if (oldCompanyGroupId.current !== id) {
      /** Atualiza o valor do antigo para o mais atual. */
      oldCompanyGroupId.current = id;
      setData(new ResponseEntity({ data: [] }));
      setSelection(undefined);
    }
  }, [id]);

  return (
    <Container ref={ref}>
      <Page className="full-page">
        <header>
          <ToolbarManager
            filters={filters}
            pfsEvent={pfsEvent}
            selection={selection}
            balance={data?.balance}
            showClearButton={filtered}
            selectedColumns={selectedColumns}
            orderedColumnNames={orderedColNames}
            onFiltersChange={handleFiltersChange}
            onPanoramaChange={handlePanoramaOnChange}
            useCostCenterReport={useCostCenterReport}
            onClearButtonClick={handleOnClearButtonClick}
            onExportButtonClick={handleOnExportButtonClick}
            disableSheetExportation={disableSheetExportation}
            onApproveSelectedClick={handleOnApproveSelectedClick}
            useCostCenterReportManager={useCostCenterReportManager}
            onDisapproveSelectedClick={handleOnDisapproveSelectedClick}
            onUndoConfirmSelectedClick={handleOnUndoConfirmSelectedClick}
          />
        </header>
        <article className="no-padding fill-height">
          <ColumnSelector
            columns={columns}
            selectedColumns={selectedColumns}
            onSelect={handleOnColumnSelect}
          />
          <AdvTable<ICostCenterReportEntity>
            selectable
            data={data}
            tableRef={table}
            rowsDefault={50}
            columns={columns}
            removeableColumns
            loading={isFetching}
            selection={selection}
            selectionMode="checkbox"
            selectedColumns={selectedColumns}
            panoramaFilters={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.filters
            }
            panoramaSort={
              selectedPanorama?.panoramaDefinition.pfsEventEntity.multiSortMeta
            }
            rowsPerPageOptions={[10, 50, 100]}
            onSelectionChange={handleSelectionChange}
            isDataSelectable={handleIsDataSelectable}
            onColumnRemove={handleOnColumnRemove}
            onColReorder={handleOnColReorder}
            emptyMessage={renderEmptyMessage}
            onRowClick={handleOnRowClick}
            rowClassName={rowClassName}
            onFilter={handleGetList}
            onClear={handleGetList}
            onPage={handleGetList}
            onSort={handleGetList}
          />
          {isCostCenterTransactionModalOpen && (
            <CostCenterTransactionReadonlyModal
              currentId={rowFocusedData?.originId ?? ""}
              isOpen={isCostCenterTransactionModalOpen}
              useCostCenterReportManager={useCostCenterReportManager}
              onRequestClose={
                handleOnRequestCloseCostCenterTransactionFormModal
              }
            />
          )}
          {rowFocusedData && isCostCenterReportAttachmentModalOpen && (
            <CostCenterReportAttachmentModal
              reportData={rowFocusedData}
              isOpen={isCostCenterReportAttachmentModalOpen}
              useCostCenterReportManager={useCostCenterReportManager}
              onRequestClose={
                handleOnRequestCloseCostCenterReportAttachmentModal
              }
            />
          )}
          <ContextMenu
            className="cost-center-report-manager-context-menu"
            model={menuModel}
            ref={menuRef}
            style={{ width: "14.375rem" }}
          />
        </article>
      </Page>
    </Container>
  );
}
