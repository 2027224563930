import { IEntity } from "../../../../core/domain/entities/entity";
import { ICostCenterEntity } from "./costCenterEntity";
import { ICostCenterTransactionAttachmentEntity } from "./costCenterTransactionAttachmentEntity";

export interface ICostCenterTransactionInputEntity extends IEntity {
  companyGroupId: string;
  costCenterOrigin: ICostCenterEntity | undefined;
  costCenterDestination: ICostCenterEntity | undefined;
  transactionDate: string;
  value: number;
  description: string;
  attachments: ICostCenterTransactionAttachmentEntity[];
}

export class CostCenterTransactionInputEntity
  implements ICostCenterTransactionInputEntity
{
  id = "";
  companyGroupId = "";
  costCenterOrigin = undefined;
  costCenterDestination = undefined;
  transactionDate = "";
  value = 0;
  description = "";
  active = true;
  attachments = [];

  constructor(params?: Partial<ICostCenterTransactionInputEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICostCenterTransactionInputEntity>) {
    return new CostCenterTransactionInputEntity(params);
  }
}
