import { ICompetencyEntity } from "../entities/competencyEntity";

export interface ISaveCompetencyService {
  saveCompetency(competency: ICompetencyEntity): Promise<ICompetencyEntity>;
}

export interface ISaveCompetencyUseCase {
  saveCompetency(competency: ICompetencyEntity): Promise<ICompetencyEntity>;
}

export class SaveCompetencyUseCase implements ISaveCompetencyUseCase {
  constructor(private saveCompetencyService: ISaveCompetencyService) {}

  saveCompetency(competency: ICompetencyEntity): Promise<ICompetencyEntity> {
    return this.saveCompetencyService.saveCompetency(competency);
  }
}
