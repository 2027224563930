import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiService,
} from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";

import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import {
  IExportProvidersContract,
  IExportProvidersParams,
} from "../../domain/contracts/exportProvidersContract";

export class ExportProvidersService implements IExportProvidersContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
    private setServerSideService: ISetServerSideService,
  ) {}

  async exportProviders(params: IExportProvidersParams) {
    const { search, showActivesOnly } = params;

    const userEntity = this.getUserLocalService.get();
    const url = `/Downloads/Providers/Spreadsheets`;

    const serverSidePayload = this.generateServerSidePayload(search);

    const serverSideBody = await this.setServerSideService.setServerSide(
      serverSidePayload,
    );

    const payload = {
      serverSideBody,
      onlyActives: showActivesOnly,
    };

    const response = await this.api.postAndDownload?.(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (response) {
      this.downloadFile(response);
    }
  }

  private downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=UTF-8''Download`;

    const contentType =
      headers?.["content-type"] ||
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";

    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();
    fileName = fileName.replace(/"/g, "");

    const blob = new Blob([data], {
      type: contentType,
    });

    FileSaver.saveAs(blob, fileName);
  }

  private generateServerSidePayload(search: string) {
    return {
      draw: 10,
      start: 0,
      length: 10,
      order: [],
      search: {
        regex: false,
        value: search || "",
      },
      columns: [
        {
          data: "companyGroupId",
          name: "companyGroupId",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "companyGroupName",
          name: "companyGroupName",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "name",
          name: "name",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "corporationName",
          name: "corporationName",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "document",
          name: "document",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "documentType",
          name: "documentType",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "documentTypeDescription",
          name: "documentTypeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "type",
          name: "type",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "typeDescription",
          name: "typeDescription",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "municipalRegistration",
          name: "municipalRegistration",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "stateRegistration",
          name: "stateRegistration",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "phoneNumber",
          name: "phoneNumber",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "landlinePhoneNumber",
          name: "landlinePhoneNumber",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "email",
          name: "email",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "retainFee",
          name: "retainFee",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "retainFeeDescription",
          name: "retainFeeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "professorType",
          name: "professorType",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "professorTypeDescription",
          name: "professorTypeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "teacherRegime",
          name: "teacherRegime",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "teacherRegimeDescription",
          name: "teacherRegimeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "sexType",
          name: "sexType",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "sexTypeDescription",
          name: "sexTypeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "maritalStatus",
          name: "maritalStatus",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "maritalStatusDescription",
          name: "maritalStatusDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "identityCard",
          name: "identityCard",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "issuingAuthority",
          name: "issuingAuthority",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "validityRne",
          name: "validityRne",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "birthDate",
          name: "birthDate",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "pisPasep",
          name: "pisPasep",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "profession",
          name: "profession",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },

        // Dados bancários
        {
          data: "bankAccountType",
          name: "bankAccountType",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankAccountTypeDescription",
          name: "bankAccountTypeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankCode",
          name: "bankCode",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bank",
          name: "bank",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankDescription",
          name: "bankDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankBranch",
          name: "bankBranch",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankBranchDigit",
          name: "bankBranchDigit",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankAccount",
          name: "bankAccount",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "bankAccountDigit",
          name: "bankAccountDigit",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "documentHolder",
          name: "documentHolder",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },

        // Endereço
        {
          data: "countryName",
          name: "countryName",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "zipcode",
          name: "zipcode",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "street",
          name: "street",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "placeNumber",
          name: "placeNumber",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "stateName",
          name: "stateName",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "cityName",
          name: "cityName",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "neighborhood",
          name: "neighborhood",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "complement",
          name: "complement",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "active",
          name: "active",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "activeDescription",
          name: "activeDescription",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "isStateRegistrationExempt",
          name: "isStateRegistrationExempt",
          searchable: false,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
      ],
    };
  }
}
