import { ProgressBar } from "primereact/progressbar";
import { FaSpinner } from "react-icons/fa";
import styled from "styled-components";

const ExportingModalContentContainer = styled.div`
  font-size: 0.975rem;

  p,
  .progress-bar {
    margin: 1rem 0;
  }

  .export-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
    height: 2em;

    svg {
      margin-left: 0.5rem;
    }
  }
`;

export function ExportingModalContent() {
  return (
    <ExportingModalContentContainer>
      <p>Por favor, aguarde enquanto processamos o seu arquivo...</p>
      <div className="progress-bar">
        <ProgressBar mode="indeterminate" style={{ height: "0.375rem" }} />
      </div>
      <div className="export-modal-footer">
        Processando dados
        <FaSpinner className="spinner" />
      </div>
    </ExportingModalContentContainer>
  );
}
