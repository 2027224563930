import { StatusCodes } from "http-status-codes";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IApiError } from "../../../../../core/data/services/apiService";
import { FileUpload } from "../../../../../core/presentation/components/FileUpload";
import { useImportFileErrorHandlers } from "../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { IErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";
import {
  IPayrollRemittanceForm,
  PayrollRemittanceTypeModalContent,
} from "../components/PayrollRemmitanceTypeModalContent";
import {
  remittanceValue,
  SelectRemmitanceTypeModalContent,
} from "../components/SelectRemmitanceTypeModalContent";
import { useAccountsPayablePage } from "./useAccountsPayablePage";
import { EAccountPayableStatus } from "../../domain/entities/accountPayableListItemEntity";
import {
  IPaymentToSuppliersRemittanceForm,
  PaymentToSuppliersRemittanceTypeModalContent,
} from "../components/PaymentToSuppliersRemittanceTypeModalContent";

/**
 * Objeto com o tipo padrão de arquivo aceito (no caso, planilhas Excel)
 */
const sheetType = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel": [".xls"],
};

enum EGenerateRemittanceStep {
  Select,
  Generate,
}
interface IGenerateRemittanceState {
  files: File[];
  selectValue: string;
  isSubmitting: boolean;
  isDownloadingTemplate: boolean;
  currentStep: EGenerateRemittanceStep;
  progressModal: {
    total: number;
    loaded: number;
    isOpen: boolean;
  };
}

export function useGenerateRemittancesModal() {
  const { state, useAccountsPayable, closeGenerateRemittancesModal } =
    useAccountsPayablePage();

  const { generateRemittancesModalOpen, selection } = state;

  const {
    importTaxesRemittanceFile,
    getTaxesRemittanceTemplate,
    generatePayrollRemittanceFile,
    importSafraTaxesRemittanceFile,
    getSafraTaxesRemittanceTemplate,
    importSafraPayrollRemittanceFile,
    getSafraPayrollRemittanceTemplate,
    importSafraProviderRemittanceFile,
    getSafraProviderRemittanceTemplate,
    generatePaymentToSuppliersFile,
    verifyHasGeneratedRemittances,
  } = useAccountsPayable;

  const dialog = useSoulDialog();
  const importFileErrorHandlers = useImportFileErrorHandlers();

  const payrollFormProps = useForm<IPayrollRemittanceForm>({
    mode: "all",
    defaultValues: {
      payroll: [],
    },
  });

  const paymentToSuppliersFormProps =
    useForm<IPaymentToSuppliersRemittanceForm>({
      mode: "all",
      defaultValues: {
        paymentToSuppliers: [],
      },
    });

  const [modalState, setModalState] = useState<IGenerateRemittanceState>({
    files: [],
    selectValue: "",
    isSubmitting: false,
    isDownloadingTemplate: false,
    currentStep: EGenerateRemittanceStep.Select,
    progressModal: {
      total: 0,
      loaded: 0,
      isOpen: false,
    },
  });

  const { selectValue, currentStep, files } = modalState;

  const isGenerateStep = currentStep === EGenerateRemittanceStep.Generate;
  const isSantanderPayroll = selectValue === remittanceValue.SantanderPayroll;
  const isSantanderProvider = selectValue === remittanceValue.SantanderProvider;

  const isTemplateButtonVisible =
    isGenerateStep && !isSantanderPayroll && !isSantanderProvider;

  /**
   * Gera os dados da tabela de folha de pagamento.
   */
  const tableData = useMemo(() => {
    if (!selection || !selection.length) {
      return [];
    }

    if (isSantanderPayroll || isSantanderProvider) {
      const uniqueCompanies = selection
        ?.map(account => ({
          agreementCode: "",
          companyId: account.companyId,
          companyName: account.companyName,
        }))
        .filter(
          (account, i, arr) =>
            arr.findIndex(acc => acc.companyId === account.companyId) === i,
        );

      if (isSantanderPayroll) {
        payrollFormProps.setValue("payroll", uniqueCompanies);
      }

      if (isSantanderProvider) {
        paymentToSuppliersFormProps.setValue(
          "paymentToSuppliers",
          uniqueCompanies,
        );
      }

      return uniqueCompanies;
    }

    return [];
  }, [
    isSantanderPayroll,
    selection,
    payrollFormProps,
    isSantanderProvider,
    paymentToSuppliersFormProps,
  ]);

  /**
   * Indica se o passo atual é válido.
   */
  const isCurrentStepValid = useCallback((): boolean => {
    if (isSantanderPayroll) {
      return isGenerateStep
        ? !!payrollFormProps.formState.isValid
        : !!state.selection?.length;
    }

    if (isSantanderProvider) {
      return isGenerateStep
        ? !!paymentToSuppliersFormProps.formState.isValid
        : !!state.selection?.length;
    }

    return isGenerateStep ? !!files.length : !!selectValue;
  }, [
    selectValue,
    files.length,
    isGenerateStep,
    isSantanderPayroll,
    state.selection?.length,
    payrollFormProps.formState.isValid,
    isSantanderProvider,
    paymentToSuppliersFormProps,
  ]);

  /**
   *  Reseta o estado gerenciado pelo hook para configurações iniciais.
   */
  const resetState = useCallback(() => {
    setModalState({
      files: [],
      selectValue: "",
      isSubmitting: false,
      isDownloadingTemplate: false,
      currentStep: EGenerateRemittanceStep.Select,
      progressModal: {
        total: 0,
        loaded: 0,
        isOpen: false,
      },
    });
    payrollFormProps.reset({});
    paymentToSuppliersFormProps.reset({});
  }, [payrollFormProps, paymentToSuppliersFormProps]);

  /**
   *  Lida com o fechamento do modal de geração de remessas.
   */
  const handleCloseGenerateRemittancesModal = useCallback(() => {
    resetState();
    closeGenerateRemittancesModal();
  }, [closeGenerateRemittancesModal, resetState]);

  /**
   *  Lida com arquivos aceitos após inserção de um arquivo.
   */
  const handleDropAccepted = useCallback((acceptedFiles: File[]) => {
    setModalState(old => ({
      ...old,
      files: acceptedFiles,
    }));
  }, []);

  /**
   * Lida com arquivos rejeitados após inserção de um arquivo.
   */
  const handleDropRejected = useCallback(
    (errorText: string) => {
      dialog.fire({
        icon: "error",
        title: "Formato inválido",
        text: errorText,
      });
    },
    [dialog],
  );

  /**
   * Lida com a remoção de arquivos.
   */
  const handleRemoveFile = useCallback(filteredList => {
    setModalState(old => ({
      ...old,
      files: filteredList,
    }));
  }, []);

  /**
   * Lida com o download de um modelo de planilha de importação.
   */
  const handleTemplateDownload = useCallback(async () => {
    if (
      !isGenerateStep ||
      isSantanderPayroll ||
      !selectValue ||
      isSantanderProvider
    ) {
      return;
    }

    setModalState(old => ({ ...old, isDownloadingTemplate: true }));

    const templateServices = {
      [remittanceValue.SantanderTaxes]: getTaxesRemittanceTemplate,
      [remittanceValue.SafraTaxes]: getSafraTaxesRemittanceTemplate,
      [remittanceValue.SafraPayroll]: getSafraPayrollRemittanceTemplate,
      [remittanceValue.SafraProvider]: getSafraProviderRemittanceTemplate,
    };

    const fileService = templateServices[selectValue];

    try {
      const fileUrl = await fileService();
      window.open(fileUrl);
    } finally {
      setModalState(old => ({
        ...old,
        isDownloadingTemplate: false,
      }));
    }
  }, [
    selectValue,
    isGenerateStep,
    isSantanderPayroll,
    isSantanderProvider,
    getTaxesRemittanceTemplate,
    getSafraTaxesRemittanceTemplate,
    getSafraPayrollRemittanceTemplate,
    getSafraProviderRemittanceTemplate,
  ]);

  /**
   * Lida com o evento de progresso de upload/download de requisições.
   */
  const handleRequestProgress = (loaded: number, total: number) => {
    setModalState(prevState => ({
      ...prevState,
      progressModal: {
        ...prevState.progressModal,
        loaded,
        total,
      },
    }));
  };

  /**
   * Lida com a submissão de um arquivo para a geração de uma remessa.
   */
  const handleImportRemittanceFile = useCallback(async () => {
    if (
      !isGenerateStep ||
      !selectValue ||
      isSantanderPayroll ||
      isSantanderProvider
    ) {
      return;
    }

    if (!files.length) {
      return;
    }

    setModalState(prevState => ({
      ...prevState,
      isSubmitting: true,
      progressModal: {
        ...prevState.progressModal,
        isOpen: true,
      },
    }));

    const remittanceServices = {
      [remittanceValue.SantanderTaxes]: importTaxesRemittanceFile,
      [remittanceValue.SafraPayroll]: importSafraPayrollRemittanceFile,
      [remittanceValue.SafraProvider]: importSafraProviderRemittanceFile,
      [remittanceValue.SafraTaxes]: importSafraTaxesRemittanceFile,
    };

    const importService = remittanceServices[selectValue];

    try {
      await importService(files[0], handleRequestProgress);
      dialog.fire({
        icon: "success",
        title: "Sucesso",
        text: "Remessa gerada com sucesso!",
      });
    } catch (error) {
      const errorData = error as IApiError<IErrorResponseEntity>;
      const errorResponse = errorData.response;
      if (errorData.response.status !== StatusCodes.BAD_REQUEST) return;
      const customMessage = (
        <>
          Essa remessa <strong>não pôde</strong> ser gerada.
          <br />
          Corrija os problemas e tente novamente
        </>
      );
      importFileErrorHandlers(errorResponse, customMessage);
    } finally {
      setModalState(prevState => ({
        ...prevState,
        isSubmitting: false,
        progressModal: {
          total: 0,
          loaded: 0,
          isOpen: false,
        },
      }));
    }
  }, [
    files,
    dialog,
    selectValue,
    isGenerateStep,
    isSantanderPayroll,
    importFileErrorHandlers,
    importTaxesRemittanceFile,
    importSafraTaxesRemittanceFile,
    importSafraPayrollRemittanceFile,
    importSafraProviderRemittanceFile,
    isSantanderProvider,
  ]);

  /**
   * Verifica se alguma conta já possui remessa gerada.
   */
  const verifyGeneratedRemittances = useCallback(async () => {
    if (!selection) {
      return [];
    }

    const hasGeneratedRemittances = await verifyHasGeneratedRemittances(
      selection,
    );

    if (hasGeneratedRemittances) {
      const response = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        html: (
          <>
            Estes registros <strong>já foram gerados</strong> em uma remessa.
            <br />
            Deseja mesmo continuar?
          </>
        ),
      });

      if (response.isDismissed) {
        return [];
      }
    }

    return selection;
  }, [dialog, selection, verifyHasGeneratedRemittances]);

  /**
   * Lida com a submissão do formulário geração de remessas de Folha de Pagamento.
   */
  const handleGeneratePayrollRemittance = useCallback(
    async (formValues: IPayrollRemittanceForm) => {
      const verifiedSelection = await verifyGeneratedRemittances();

      if (!verifiedSelection.length) return;

      setModalState(prevState => ({
        ...prevState,
        isSubmitting: true,
        progressModal: {
          ...prevState.progressModal,
          isOpen: true,
        },
      }));

      try {
        await generatePayrollRemittanceFile(
          formValues,
          verifiedSelection,
          handleRequestProgress,
        );
        dialog.fire({
          icon: "success",
          title: "Sucesso",
          text: "Remessa gerada com sucesso!",
        });
      } catch (error) {
        const errorData = error as IApiError<IErrorResponseEntity>;
        const errorResponse = errorData.response;

        if (errorData.response.status !== StatusCodes.BAD_REQUEST) {
          return;
        }

        const customErrorMessage = (
          <>
            Esses lançamentos <strong>não podem</strong> ser gerados.
            <br />
            Corrija os erros e tente novamente.
          </>
        );

        importFileErrorHandlers(errorResponse, customErrorMessage);
      } finally {
        setModalState(prevState => ({
          ...prevState,
          isSubmitting: false,
          progressModal: {
            total: 0,
            loaded: 0,
            isOpen: false,
          },
        }));
      }
    },
    [
      dialog,
      importFileErrorHandlers,
      verifyGeneratedRemittances,
      generatePayrollRemittanceFile,
    ],
  );

  /**
   * Lida com a submissão do formulário geração de remessas de Pagamento a fornecedores.
   */
  const handleGeneratePaymentToSuppliersRemittance = useCallback(
    async (formValues: IPaymentToSuppliersRemittanceForm) => {
      const verifiedSelection = await verifyGeneratedRemittances();

      if (!verifiedSelection.length) return;

      setModalState(prevState => ({
        ...prevState,
        isSubmitting: true,
        progressModal: {
          ...prevState.progressModal,
          isOpen: true,
        },
      }));

      try {
        await generatePaymentToSuppliersFile(
          formValues,
          verifiedSelection,
          handleRequestProgress,
        );
        dialog.fire({
          icon: "success",
          title: "Sucesso",
          text: "Remessa gerada com sucesso!",
        });
      } catch (error) {
        const errorData = error as IApiError<IErrorResponseEntity>;
        const errorResponse = errorData.response;

        if (errorData.response.status !== StatusCodes.BAD_REQUEST) {
          return;
        }

        const customErrorMessage = (
          <>
            Esses lançamentos <strong>não podem</strong> ser gerados.
            <br />
            Corrija os erros e tente novamente.
          </>
        );

        importFileErrorHandlers(errorResponse, customErrorMessage);
      } finally {
        setModalState(prevState => ({
          ...prevState,
          isSubmitting: false,
          progressModal: {
            total: 0,
            loaded: 0,
            isOpen: false,
          },
        }));
      }
    },
    [
      dialog,
      importFileErrorHandlers,
      verifyGeneratedRemittances,
      generatePaymentToSuppliersFile,
    ],
  );

  /**
   * Lida com o click no botão de confirmar. As ações variam de acordo com o
   * tipo selecionado no momento.
   */
  const handleConfirmButton = useCallback(async () => {
    if (isGenerateStep) {
      if (isSantanderPayroll) {
        payrollFormProps.handleSubmit(handleGeneratePayrollRemittance)();
        return;
      }

      if (isSantanderProvider) {
        paymentToSuppliersFormProps.handleSubmit(
          handleGeneratePaymentToSuppliersRemittance,
        )();
        return;
      }

      handleImportRemittanceFile();
      return;
    }

    if (!selectValue) {
      return;
    }

    if (
      (isSantanderPayroll || isSantanderProvider) &&
      !state.selection?.length
    ) {
      return;
    }

    setModalState(old => {
      return {
        ...old,
        currentStep: EGenerateRemittanceStep.Generate,
      };
    });
  }, [
    selectValue,
    isGenerateStep,
    payrollFormProps,
    isSantanderPayroll,
    state.selection?.length,
    handleImportRemittanceFile,
    handleGeneratePayrollRemittance,
    isSantanderProvider,
    paymentToSuppliersFormProps,
    handleGeneratePaymentToSuppliersRemittance,
  ]);

  /**
   * Renderiza o cabeçalho da modal de acordo com o tipo de remessa selecionado.
   */
  const renderModalHeader = useCallback(() => {
    const [selectedType, selectedBank] = selectValue.split("-") as [
      ERemittanceType,
      ERemittanceBankCode,
    ];

    const isBankSafra = selectedBank === ERemittanceBankCode.Safra;
    const bank = isBankSafra ? "Safra" : "Santander";

    const modalTitles = {
      [ERemittanceType.Payroll]: `Geração de Remessas - Folha de Pagamento (${bank})`,
      [ERemittanceType.Taxes]: `Importação de Remessa - Pagamento de Tributos (${bank})`,
      [ERemittanceType.Provider]: `Geração de Remessas - Pagamento a Fornecedores (${bank})`,
    };

    return (
      <>
        <span>
          {isGenerateStep ? (
            <button
              type="button"
              className="react-modal-close"
              onClick={resetState}
            >
              <FaChevronLeft />
            </button>
          ) : null}
          <h4>
            {isGenerateStep && selectedType
              ? modalTitles[selectedType]
              : "Geração de Remessas"}
          </h4>
        </span>
        <button
          type="button"
          id="btn-cross"
          data-testid="btn-cross"
          className="react-modal-close"
          onClick={handleCloseGenerateRemittancesModal}
        >
          <IoMdClose />
        </button>
      </>
    );
  }, [
    resetState,
    selectValue,
    isGenerateStep,
    handleCloseGenerateRemittancesModal,
  ]);

  const hasSelectedPaidData = !!state.selection?.some(
    data => data.status === EAccountPayableStatus.Paid,
  );

  /**
   * Renderiza o conteúdo da modal de acordo com o tipo de remessa selecionado.
   */
  const renderModalBody = useCallback(() => {
    if (!isGenerateStep) {
      const handleSelectChange = (value: string) => {
        setModalState(old => ({
          ...old,
          selectValue: value,
        }));
      };

      const hasPayrollOrProvider = isSantanderPayroll || isSantanderProvider;

      return (
        <SelectRemmitanceTypeModalContent
          handleSelectChange={handleSelectChange}
          showWarning={hasPayrollOrProvider && !state.selection?.length}
          hasSelectedPaidData={hasSelectedPaidData}
        />
      );
    }

    if (isSantanderPayroll) {
      return (
        <FormProvider {...payrollFormProps}>
          <PayrollRemittanceTypeModalContent tableData={tableData} />
        </FormProvider>
      );
    }

    if (isSantanderProvider) {
      return (
        <FormProvider {...paymentToSuppliersFormProps}>
          <PaymentToSuppliersRemittanceTypeModalContent tableData={tableData} />
        </FormProvider>
      );
    }

    return (
      <FileUpload
        files={files}
        accept={sheetType}
        onRemoveFile={handleRemoveFile}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
      />
    );
  }, [
    files,
    handleDropAccepted,
    handleDropRejected,
    handleRemoveFile,
    hasSelectedPaidData,
    isGenerateStep,
    isSantanderPayroll,
    payrollFormProps,
    state.selection?.length,
    tableData,
    isSantanderProvider,
    paymentToSuppliersFormProps,
  ]);

  return {
    modalState,
    resetState,
    renderModalBody,
    handleRemoveFile,
    renderModalHeader,
    handleDropAccepted,
    isCurrentStepValid,
    handleDropRejected,
    handleConfirmButton,
    handleTemplateDownload,
    isTemplateButtonVisible,
    generateRemittancesModalOpen,
    handleCloseGenerateRemittancesModal,
  };
}
