import styled from "styled-components";

export const ContainerForm = styled.form`
  width: calc(100vw - 2rem);
  max-width: 40rem;

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;
