import { ReactNode, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { FaChevronDown } from "react-icons/fa";
import { Container } from "./styles";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";

interface BasicLinkProps {
  linkTitle: string;
  linkPath: string;
  linkIcon: ReactNode;
}

export interface LinkCardProps {
  path: string;
  color: string;
  title: string;
  icon: ReactNode;
  dropdown?: BasicLinkProps[];
}

interface NavItemProps {
  path: string;
  hasTip?: boolean;
  tooltip?: string;
  className: string;
  children: ReactNode;
}

function NavItem(props: NavItemProps) {
  const { path, className, children, hasTip, tooltip } = props;

  return (
    <Link
      to={path}
      data-tip={tooltip}
      data-effect="solid"
      className={className}
      data-enabled={!!hasTip}
    >
      {children}
    </Link>
  );
}

export function LinkCard(props: LinkCardProps) {
  const { path, title, icon, color, dropdown } = props;

  const handleDropdown = useCallback(() => {
    if (!dropdown) return null;
    if (dropdown?.length === 1) {
      const { linkIcon, linkPath, linkTitle } = dropdown[0];
      return (
        <NavItem
          hasTip
          path={linkPath}
          tooltip={linkTitle}
          className="single-button"
        >
          {linkIcon}
        </NavItem>
      );
    }
    return (
      <SoulDropdown toggler={<FaChevronDown />}>
        {dropdown.map(link => {
          const { linkIcon, linkPath, linkTitle } = link;
          return (
            <NavItem key={linkTitle} path={linkPath} className="dropdown-item">
              {linkIcon}
              {linkTitle}
            </NavItem>
          );
        })}
      </SoulDropdown>
    );
  }, [dropdown]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Container color={color}>
      <NavItem path={path} className="main-button">
        <p>{title}</p>
        <div>{icon}</div>
      </NavItem>
      {handleDropdown()}
    </Container>
  );
}
