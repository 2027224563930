import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
} from "primereact/autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import { FaEdit, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IProviderEntity } from "../../../../provider/domain/entities/providerEntity";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { ProviderFormModal } from "../../../../provider/presentation/components/ProviderFormModal";
import { useDocumentFormatter } from "../../../../core/presentation/hooks/useDocumentFormatter";
import { ClassificationAccountLinkFormModal } from "../../../../provider/presentation/components/ClassificationAccountLinkFormModal";
import { MakeFee } from "../../../main/makeFee";
import { IFeeFormEntity } from "../../../domain/entities/feeFormEntity";
import { Container } from "./style";

interface SearchProviderFieldProps {
  readonly: boolean;
  isInvalid: boolean;
  value?: IProviderEntity;
  useProvider: MakeProvider;
  searchProviders: MakeFee["searchProviders"];
}

export function SearchProviderField(props: SearchProviderFieldProps) {
  const { value, readonly, isInvalid, useProvider, searchProviders } = props;

  const { listLinkedClassificationsAccount } = useProvider;

  const autoCompleteRef = useRef<AutoComplete | null>();

  const documentFormatter = useDocumentFormatter();
  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
  const [isClassificationModalOpen, setIsClassificationModalOpen] =
    useState(false);

  const [inputWidth, setInputWidth] = useState(0);
  const [suggestions, setSuggestions] = useState<IProviderEntity[]>([]);
  const [inputValue, setInputValue] = useState<IProviderEntity | null>(() => {
    if (!value) return null;
    const formattedDocument = documentFormatter(value.document);
    return {
      ...value,
      name: `${value.name} - ${formattedDocument || "Sem documento"}`,
    };
  });

  const { watch, control, trigger, setValue, resetField } =
    useFormContext<IFeeFormEntity>();

  /** Observa o valor do campo de Fornecedor. */
  const providerWatcher = watch("providerId");

  /**
   * Lida com o preenchimento de sugestões do campo de Fornecedores.
   * @param query - Texto que será utilizado como termo de busca.
   */
  const handleSearch = useCallback(
    async ({ query }: AutoCompleteCompleteMethodParams) => {
      const { data } = await searchProviders(query, companyGroupId);
      setSuggestions(
        data.map(d => {
          const formattedDocument = documentFormatter(d.document);
          return {
            ...d,
            name: `${d.name} - ${formattedDocument || "Sem documento"}`,
          };
        }),
      );
    },
    [companyGroupId, documentFormatter, searchProviders],
  );

  /**
   * Após a criação/edição, atualiza o campo de fornecedor com os dados
   * novos/atualizados. Força revalidação do campo para evitar erros.
   */
  const handleOnProviderCreated = useCallback(
    async (provider: IProviderEntity) => {
      const { id: providerId } = provider;
      setValue("providerId", providerId);
      setInputValue(provider);

      const clsAccountList = await listLinkedClassificationsAccount(
        companyGroupId,
        providerId,
      );

      if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
        return;
      }

      setIsClassificationModalOpen(true);
    },
    [setValue, companyGroupId, listLinkedClassificationsAccount],
  );

  const itemTemplate = useCallback(
    (item: IProviderEntity | "EMPTY") => {
      if (item === "EMPTY" || item.id === inputValue?.id) {
        return (
          <button
            type="button"
            id="btn-add-provider"
            data-testid="btn-add-provider"
            onClick={() => {
              resetField("providerId");
              setInputValue(null);
              setIsProviderModalOpen(true);
            }}
          >
            <FaPlus /> Adicionar Fornecedor
          </button>
        );
      }

      return <span>{item.name}</span>;
    },
    [inputValue?.id, resetField],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (!inputValue) return;
      autoCompleteRef.current?.search(e, inputValue.name, "dropdown");
    },
    [inputValue],
  );

  /**
   * Lida com o evento de mudança do input, definindo o valor correto no
   * formulário e atualizando o estado do valor selecionado.
   */
  const handleOnChange = useCallback(
    (e: AutoCompleteChangeParams, formOnChange: (value: unknown) => void) => {
      const isString = typeof e.value === "string";
      setInputValue(e.value);
      formOnChange(isString ? null : e.value?.id);
    },
    [],
  );

  /** Sincroniza o ReactTooltip quando o componente é montado */
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <span>Fornecedor</span>
      <Controller
        name="providerId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          const refCallback = field.ref;
          const formOnChange = field.onChange;
          return (
            <Container>
              <AutoComplete
                {...field}
                delay={700}
                field="name"
                minLength={3}
                forceSelection
                id="sel-provider"
                value={inputValue}
                disabled={readonly}
                onClick={handleClick}
                data-testid="sel-provider"
                itemTemplate={itemTemplate}
                completeMethod={handleSearch}
                panelStyle={{ width: inputWidth }}
                placeholder="Pesquise um Fornecedor"
                panelClassName="input-search-providers"
                inputClassName={isInvalid ? "isInvalid" : ""}
                suggestions={suggestions.length ? suggestions : ["EMPTY"]}
                onChange={inputEvent => {
                  handleOnChange(inputEvent, formOnChange);
                }}
                ref={originalRef => {
                  autoCompleteRef.current = originalRef;
                  refCallback(originalRef);
                }}
                inputRef={ref =>
                  setInputWidth(ref?.getBoundingClientRect().width || 0)
                }
              />
              <button
                type="button"
                id="btn-edit-provider"
                data-tip="Editar Fornecedor"
                data-testid="btn-edit-provider"
                data-tip-disable={readonly || !providerWatcher || !field.value}
                className={
                  readonly || !providerWatcher || !field.value ? "disabled" : ""
                }
                onClick={() => {
                  if (readonly || !providerWatcher || !field.value) return;
                  setIsProviderModalOpen(true);
                }}
              >
                <FaEdit />
              </button>
            </Container>
          );
        }}
      />
      <InvalidFeedback
        condition={isInvalid}
        message="Este campo é obrigatório"
      />
      <ProviderFormModal
        useProvider={useProvider}
        isOpen={isProviderModalOpen}
        onProviderCreated={handleOnProviderCreated}
        currentId={
          isProviderModalOpen && providerWatcher ? providerWatcher : ""
        }
        onRequestClose={() => {
          setIsProviderModalOpen(false);
        }}
      />
      <ClassificationAccountLinkFormModal
        useProvider={useProvider}
        isOpen={isClassificationModalOpen}
        providerEntity={
          isClassificationModalOpen && inputValue ? inputValue : undefined
        }
        onRequestClose={() => {
          trigger("providerId");
          setIsClassificationModalOpen(false);
        }}
      />
    </>
  );
}
