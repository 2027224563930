import { AttachmentsGrid } from "../../../../../../core/presentation/components/AttachmentsGrid";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { useAttachmentsHandler } from "../../hooks/useAttachmentsHandler";

interface ISectionAttachmentProps {
  isReview?: boolean;
  readonly?: boolean;
  isRequesterOrManager?: boolean;
  usePaymentRequestForm: MakePaymentRequestForm;
}

export function SectionAttachment({
  isReview,
  readonly,
  isRequesterOrManager,
  usePaymentRequestForm,
}: ISectionAttachmentProps) {
  const {
    attachmentsWatcher,
    getStorageFilebyId,
    listAttachmentTypes,
    attachmentTypeCheck,
    fetchRpaParamOptions,
    generateRpaAttachment,
    updateAttachmentsBarcode,
    validateMeasurementAttachment,
    handleAttachmentListChange,
    generateVariableAdditionalAttachment,
  } = useAttachmentsHandler({
    isReview,
    isRequesterOrManager,
    usePaymentRequestForm,
  });

  return (
    <AttachmentsGrid
      disabled={readonly}
      attachments={attachmentsWatcher}
      getStorageFilebyId={getStorageFilebyId}
      listAttachmentTypes={listAttachmentTypes}
      attachmentTypeCheck={attachmentTypeCheck}
      fetchRpaParamOptions={fetchRpaParamOptions}
      generateRpaAttachment={generateRpaAttachment}
      updateAttachmentsBarcode={updateAttachmentsBarcode}
      validateMeasurementAttachment={validateMeasurementAttachment}
      onAttachmentsChange={handleAttachmentListChange}
      generateVariableAdditionalAttachment={
        generateVariableAdditionalAttachment
      }
    />
  );
}
