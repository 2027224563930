import { ICheckDocumentEntity } from "../entities/customerEntity";

export interface ICheckCustomerCpfUseCase {
  checkCpf(cpf: string, id?: string): Promise<ICheckDocumentEntity>;
}

export interface ICheckCustomerCpfService {
  checkCpf(cpf: string, id?: string): Promise<ICheckDocumentEntity>;
}

export class CheckCustomerCpfUseCase implements ICheckCustomerCpfUseCase {
  constructor(private checkCustomerCpfService: ICheckCustomerCpfService) {}

  checkCpf(cpf: string, id?: string) {
    return this.checkCustomerCpfService.checkCpf(cpf, id);
  }
}
