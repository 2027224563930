import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  user-select: none;
  height: 1.9rem;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;

    & + div {
      cursor: pointer;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid var(--input-border-color);
      border-radius: var(--border-radius);

      & + span {
        cursor: pointer;
        margin-left: 0.5rem;
        font-size: 0.875rem;
      }
    }

    &:checked {
      & + div {
        background-color: #5e72e4;
        border-color: transparent;
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          position: relative;
          bottom: 0.05rem;
          content: "";
          display: block;
          height: 0.5rem;
          width: 0.9rem;
          box-sizing: border-box;
          border-bottom: 0.3rem solid white;
          border-left: 0.3rem solid white;
          transform: rotate(-45deg) scale(0.9);
        }
      }
    }

    &:disabled {
      & + div {
        cursor: not-allowed;
        opacity: 0.65;

        & + span {
          cursor: not-allowed;
          opacity: 0.65;
        }
      }
    }
  }
`;
