import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import {
  IUploadAttachmentsContract,
  IUploadAttachmentsParams,
} from "../../domain/contracts/uploadAttachmentContract";

export class UploadAttachmentService implements IUploadAttachmentsContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async uploadAttachments({
    attachments,
    paymentRequestId,
    onUploadProgress,
  }: IUploadAttachmentsParams) {
    const userEntity = this.getUserLocalService.get();
    const url = `/PaymentRequests/${paymentRequestId}/Attachments`;

    const formData = new FormData();

    for (let index = 0; index < attachments.length; index += 1) {
      const attachment = attachments[index];

      formData.append(`Uploads[${index}].Name`, attachment.name.toString());
      formData.append(`Uploads[${index}].Active`, attachment.active.toString());
      formData.append(`Uploads[${index}].Type`, attachment.type.toString());

      if (attachment.file) {
        formData.append(`Uploads[${index}].File`, attachment.file);
      }

      if (attachment.barcode) {
        formData.append(`Uploads[${index}].barcode`, attachment.barcode);
      }

      if (attachment.storageFileId) {
        formData.append(
          `Uploads[${index}].storageFileId`,
          attachment.storageFileId,
        );
      }
    }

    await this.api.post(url, formData, {
      onUploadProgress,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
