import { IFeeEntity } from "./feeEntity";

export type IFeeFormEntity = Pick<
  IFeeEntity,
  | "name"
  | "providerId"
  | "classificationAssessmentId"
  | "expirationDay"
  | "expirationType"
>;

export class FeeFormEntity implements IFeeFormEntity {
  name = "";
  providerId = "";
  expirationDay = null;
  expirationType = null;
  classificationAssessmentId = "";

  constructor(init?: Partial<IFeeFormEntity>) {
    Object.assign(this, init);
  }

  static create(init?: Partial<IFeeFormEntity>) {
    return new FeeFormEntity(init);
  }
}
