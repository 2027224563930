import { ITypeaheadOption } from "../../../core/domain/entities/typeaheadOption";
import {
  EClassificationAssessmentType,
  IClassificationAssessmentEntity,
} from "./classificationAssessment";

export interface IClassificationAssessmentFormEntity
  extends Omit<IClassificationAssessmentEntity, "macroBoxId" | "macroBoxName"> {
  macroCategory: ITypeaheadOption | null;
}

export class ClassificationAssessmentFormEntity
  implements IClassificationAssessmentFormEntity
{
  name = "";
  type = EClassificationAssessmentType.empty;
  companyGroupId = "";
  id = "";
  active = true;
  paymentType = undefined;
  macroCategory = null;

  constructor(params?: Partial<IClassificationAssessmentFormEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<IClassificationAssessmentFormEntity>) {
    return new ClassificationAssessmentFormEntity(params);
  }
}
