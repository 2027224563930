import { IApiService } from "../../../../../core/data/services/apiService";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IFetchDocumentStatusContract } from "../../domain/contracts/fetchDocumentStatusContract";

export class FetchDocumentStatusService
  implements IFetchDocumentStatusContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  fetchDocumentStatus() {
    const userEntity = this.getUserLocalService.get();
    const url = "/Enums/AccountPayableDocumentStatus";

    return this.api.get<IEnum[]>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
