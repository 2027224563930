import { ICostCenterReportInputEntity } from "../entities/costCenterReportInputEntity";

export interface IGetCostCenterReportervice {
  getCostCenterReport(
    CostCenterReportId: string,
  ): Promise<ICostCenterReportInputEntity>;
}

export interface IGetCostCenterReportUseCase {
  getCostCenterReport(
    CostCenterReportId: string,
  ): Promise<ICostCenterReportInputEntity>;
}

export class GetCostCenterReportUseCase implements IGetCostCenterReportUseCase {
  constructor(private getCostCenterReportService: IGetCostCenterReportervice) {}

  getCostCenterReport(
    CostCenterReportId: string,
  ): Promise<ICostCenterReportInputEntity> {
    return this.getCostCenterReportService.getCostCenterReport(
      CostCenterReportId,
    );
  }
}
