export interface IServerSideResponseModel<T = unknown> {
  data: T;
  draw?: number;
  recordsTotal: number;
  recordsFiltered: number;
  error?: string;
}

export class ServerSideResponseModel<T> implements IServerSideResponseModel<T> {
  data = [] as unknown as T;
  draw = 0;
  recordsTotal = 0;
  recordsFiltered = 0;

  constructor(params?: Partial<IServerSideResponseModel<T>>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
