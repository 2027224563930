import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IGetPecegePayProductTypes } from "../domain/contracts/getPecegePayProductTypesContract";
import { GetPecegePayProductTypesService } from "../data/services/getPecegePayProductTypesService";
import { IGetPecegePaySalesReportContract } from "../domain/contracts/getPecegePaySalesReportContract";
import { GetPecegePaySalesReportService } from "../data/services/getPecegePaySalesReportService";

export type MakePecegePaySalesReport = IGetPecegePayProductTypes &
  IGetPecegePaySalesReportContract;

export function makePecegePaySalesReport(
  api: IApiService,
): MakePecegePaySalesReport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getPecegePayProductTypesService = new GetPecegePayProductTypesService(
    getUserLocalService,
    api,
  );

  const getPecegePaySalesReportService = new GetPecegePaySalesReportService(
    getUserLocalService,
    api,
  );

  return {
    getPecegePayProductTypes() {
      return getPecegePayProductTypesService.getPecegePayProductTypes();
    },
    getPecegePaySalesReport(payload) {
      return getPecegePaySalesReportService.getPecegePaySalesReport(payload);
    },
  };
}
