import styled from "styled-components";

const Container = styled.div`
  div + div {
    margin-top: 0.5rem;
  }

  .options-row {
    display: flex;
    gap: 0.5rem;
  }

  .dismiss-row {
    display: flex;
  }

  .form-button {
    flex: 1 1;

    &.partial {
      background: #d5ce0f;
    }

    &.dismiss {
      background: var(--color-danger);
    }

    &.total {
      background: var(--soul-color01);
    }
  }
`;

interface IAccountsReceivableReturnConfirmProps {
  onPartialButtonClick(): void;
  onTotalButtonClick(): void;
  onBackButtonClick(): void;
}

export function AccountsReceivableReturnConfirm({
  onPartialButtonClick,
  onTotalButtonClick,
  onBackButtonClick,
}: IAccountsReceivableReturnConfirmProps) {
  return (
    <Container>
      <div>Selecione o tipo de devolução que você deseja realizar?</div>

      <div className="options-row">
        <button
          type="button"
          className="form-button partial"
          onClick={onPartialButtonClick}
        >
          Parcial
        </button>
        <button
          type="button"
          className="form-button total"
          onClick={onTotalButtonClick}
        >
          Total
        </button>
      </div>

      <div className="dismiss-row">
        <button
          type="button"
          className="form-button dismiss"
          onClick={onBackButtonClick}
        >
          Ops, me enganei. Quero voltar!
        </button>
      </div>
    </Container>
  );
}
