import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IListCompanyGroupActiveFeesContract } from "../../domain/contracts/listCompanyGroupActiveFeesContract";
import { PaymentRequestFeeEntity } from "../../domain/entities/paymentRequestFeeEntity";
import { IPaymentRequestFeeModel } from "../models/paymentRequestFeeModel";

export class ListCompanyGroupActiveFeesService
  implements IListCompanyGroupActiveFeesContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listCompanyGroupActiveFees(companyGroupId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/CompanyGroups/${companyGroupId}/Fees/Actives`;

    const response = await this.api.get<
      IServerSideResponseModel<IPaymentRequestFeeModel[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const feeList = response.data.map(feeModel => {
      return new PaymentRequestFeeEntity({
        value: 0,
        feeId: feeModel.id,
        feeName: feeModel.name,
        active: feeModel.active,
      });
    });

    return feeList;
  }
}
