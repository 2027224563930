import FileSaver from "file-saver";
import {
  HttpResponseType,
  IApiError,
  IApiService,
} from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IGeneratePaymentToSuppliersRemittanceFileContract } from "../../domain/contracts/generatePaymentToSuppliersRemittanceFileContract";
import { IAccountPayableListItemEntity } from "../../domain/entities/accountPayableListItemEntity";
import {
  ERemittanceBankCode,
  ERemittanceType,
} from "../../domain/entities/remittancesEnums";
import { RequestProgressCallback } from "../../domain/entities/requestProgressCallbackType";
import { IPaymentToSuppliersRemittanceForm } from "../../presentation/components/PaymentToSuppliersRemittanceTypeModalContent";

export class GeneratePaymentToSuppliersRemittanceFileService
  implements IGeneratePaymentToSuppliersRemittanceFileContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generatePaymentToSuppliersFile(
    formValues: IPaymentToSuppliersRemittanceForm,
    selectedAccounts: IAccountPayableListItemEntity[],
    downloadProgressCallback: RequestProgressCallback,
  ) {
    const url = `/Remittances/${ERemittanceBankCode.Santander}/${ERemittanceType.Provider}/List`;
    const userEntity = this.getUserLocalService.get();

    const uniqueMap = new Map(
      selectedAccounts.map(account => {
        const uniqueKey = `${account.companyId}-${account.paymentAccountId}`;
        return [uniqueKey, account];
      }),
    );

    const uniqueAccounts = Array.from(uniqueMap.values());

    const payload = {
      payers: uniqueAccounts.map(account => ({
        companyId: account.companyId,
        paymentAccountId: account.paymentAccountId,
        agreementCode: formValues.paymentToSuppliers.find(
          row => row.companyId === account.companyId,
        )?.agreementCode,
        releases: selectedAccounts
          .filter(
            release =>
              release.companyId === account.companyId &&
              release.paymentAccountId === account.paymentAccountId,
          )
          .map(release => {
            return {
              value: release.value,
              payUntil: release.payUntil,
              accountPayableId: release.id,
              providerId: release.providerId,
            };
          }),
      })),
    };

    try {
      const response = await this.api.postAndDownload?.(url, payload, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
          "Accept-Language": "pt-BR",
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          downloadProgressCallback(progressEvent.loaded, progressEvent.total);
        },
      });

      if (response) {
        this.downloadFile(response);
      }
    } catch (error) {
      const errorData = error as IApiError<unknown>;
      const errorResponseData = errorData.response.data;
      const isBlobError = errorResponseData instanceof Blob;

      if (!isBlobError) {
        throw error;
      }

      const blobToText = await errorResponseData.text();
      const requestError = JSON.parse(blobToText);

      errorData.response = {
        ...errorData.response,
        data: requestError,
      };

      throw errorData;
    }
  }

  downloadFile(httpResponse: HttpResponseType) {
    const { headers, data } = httpResponse;

    const contentDisposition: string =
      headers?.["content-disposition"] ||
      `attachment; filename=Download; filename*=Download`;

    const matches = /filename\*=([^;]+)/gi.exec(contentDisposition);
    let fileName = (matches?.[1] || "untitled").trim();

    fileName = fileName.replace(/^.*''/g, "");
    fileName = fileName.replace(/"/g, "");
    fileName = decodeURI(fileName);

    FileSaver.saveAs(data, fileName);
  }
}
