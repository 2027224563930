import { Controller, useFormContext } from "react-hook-form";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { IAccountPayableFeeFormInputEntity } from "../../../domain/entities/accountPayableFeeFormInputEntity";

interface IAccountPayableFeeFormInputProps {
  required?: boolean;
  disabled?: boolean;
  index: number;
}

export function AccountPayableFeeFormInput({
  index,
  disabled = false,
  required = false,
}: IAccountPayableFeeFormInputProps) {
  const form = useFormContext<IAccountPayableFeeFormInputEntity>();
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <Controller
        control={control}
        name={`fees.${index}.value`}
        rules={{ required }}
        render={({ field }) => {
          return (
            <InputBalance
              {...field}
              resetToZero
              placeholder="000,00"
              disabled={disabled}
              id="txt-openingBalance"
              data-testid="txt-openingBalance"
              className={errors?.fees?.[index].value ? "isInvalid" : ""}
            />
          );
        }}
      />
      <InvalidFeedback
        message="Este campo é obrigatório"
        condition={errors?.fees?.[index].value?.type === "required"}
      />
    </>
  );
}
