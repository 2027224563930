import { IEntity } from "../../../../../core/domain/entities/entity";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IPaymentRequestsAssessmentEntity } from "./paymentRequestAssessmentEntity";
import { IPaymentRequestAttachmentEntity } from "./paymentRequestAttachmentEntity";
import {
  EPaymentRequestDestination,
  EPaymentRequestStatus,
} from "./paymentRequestEnums";
import { IPaymentRequestFeeEntity } from "./paymentRequestFeeEntity";

export interface IPaymentRequestFormEntity extends IEntity {
  assessments: IPaymentRequestsAssessmentEntity[];
  barcode: string | null;
  company: ITypeaheadOption | null;
  description: string;
  destination: EPaymentRequestDestination | null;
  documentNumber: string;
  documentStatus: IEnum | null;
  duplicatePaymentRequestId: string | null;
  issueDate: string;
  observation: string;
  paymentMethod: IEnum | null;
  payUntil: string;
  provider: ITypeaheadOption | null;
  status: EPaymentRequestStatus;
  storageFiles: IPaymentRequestAttachmentEntity[];
  value: number;
  competency: ITypeaheadOption | null;
  issueAsPaid: boolean;
  terminationDate: string | null;
  project: ITypeaheadOption | null;
  classificationUsp: ITypeaheadOption | null;
  classificationAccount: ITypeaheadOption | null;
  paymentAccount: ITypeaheadOption | null;
  fees?: IPaymentRequestFeeEntity[];
  generateFeeRetention?: boolean;
  fuspPurchaseOrderId?: string;
}

export class PaymentRequestFormEntity implements IPaymentRequestFormEntity {
  paymentRequestId = "";
  duplicatePaymentRequestId = "";
  documentNumber = "";
  value = 0;
  issueDate = "";
  payUntil = "";
  description = "";
  destination = null;
  observation = "";
  company = null;
  provider = null;
  id = "";
  active = false;
  competency = null;
  issueAsPaid = false;
  terminationDate = null;
  project = null;
  classificationUsp = null;
  classificationAccount = null;
  paymentAccount = null;
  barcode = null;
  assessments = [];
  storageFiles = [];
  status = EPaymentRequestStatus.NotRequested;
  documentStatus = null;
  paymentMethod = null;
  fuspPurchaseOrderId = "";

  constructor(init?: Partial<IPaymentRequestFormEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<IPaymentRequestFormEntity>) {
    return new PaymentRequestFormEntity(init);
  }
}
