import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0px 2px 5px 1px #00000022;
    padding: 1rem;
    color: #888686;

    span {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.1rem;
    }
  }
`;

export function ConciliationCardEmpty() {
  return (
    <Container title="Não encontrado">
      <div className="card">
        <span>Não encontrado</span>
      </div>
    </Container>
  );
}
