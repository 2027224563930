import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IUndoReturnAccountPayableContract } from "../../domain/contracts/undoReturnAccountPayableContract";

export class UndoReturnAccountPayableService
  implements IUndoReturnAccountPayableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async undoReturn(
    companyGroupId: string,
    accountPayableId: string,
  ): Promise<void> {
    const userEntity = this.getUserLocalService.get();

    const url = `/AccountsPayable/${accountPayableId}/UndoReturn`;

    await this.api.put(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
